import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QuantityButtomComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/quantity/quantity-control';
import { QuantityControlService } from '../../../services-internal/quantity-control/quantity-control.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserMaintenanceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/user-maintenance.service';
import { Observable, map, startWith } from 'rxjs';
@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuantityComponent implements OnInit {
  isAll = false;
  apiFromSearch = false;
  alertMessage = 'Select a plant'
  rowindexselectes: any
  colindexselectes: any
  selectedpackagePackage: any
  selectedDunnage1: any
  selectedDunnage2: any
  errorMessages: any

  plants: any[] = [];
  radioOptions = [
    { label: 'Move', value: 0 },
    { label: 'Purchase', value: 1 },
    { label: 'Dispose', value: 2 },
    { label: 'Maintenance', value: 3 },
    { label: 'Adjust', value: 4 },
  ];
  legend = [
    { label: 'Warn', color: '#FEFF9C' },
    { label: 'Deficit', color: '#FECFD0' },
  ];
  legend2 = [
    { label: 'Move To', color: '#32CB00' },
    { label: 'Not Confirmed(Move To Date-3)', color: '#FF0000' },
  ];
  columnDefs: any = [];
  displayRowData: any = [];
  defaultColDef: any;
  gridApi: any;
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  domLayout: any = 'autoHeight';
  public filterForm:FormGroup = new FormGroup({
    plant: new FormControl(''),
    supplier: new FormControl(''),
    partNumber: new FormControl(''),
    packageCode: new FormControl(''),
    dunnageCode: new FormControl(''),
    type: new FormControl(''),
  });

  cont1Form: FormGroup = new FormGroup({
    plant: new FormControl(''),
    supplier: new FormControl(''),
    partNumber: new FormControl(''),
    packageCode: new FormControl(''),
    dunnage1Code: new FormControl(''),
    dunnage2Code: new FormControl(''),
    week: new FormControl(''),
  });
  cont2Form: FormGroup = new FormGroup({
    supplier: new FormControl(''),
    week: new FormControl(''),
    partnumber: new FormControl(''),
    plant: new FormControl(''),
  });
  hideForms = false;
  haveAccess: any;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  rowCount: any;
  clickedData: any[] = [];
  userInfo: any;
  disableOk = true;
  filterOptions: Observable<any> | undefined;
  plantdata: any;

  constructor(
    public readonly router: Router,
    private readonly quantityService: QuantityControlService,
    private readonly state: ExportStateService,
    private readonly userService: UserMaintenanceService,
    public loader: NgxUiLoaderService,
  
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      headerClass: 'child-header-color',
      autoHeight: true,
      flex:1,
    };
  }

  ngOnInit(): void {

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.loader.start();
          this.userInfo = res;
          this.haveAccess = res.role?.p_qtyview;
          this.plantdata = this.quantityService.getplant();
          this.dataFilter();
          this.haveAccess && this.getPlantDropDown();
          !this.haveAccess && this.loader.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.loader.stop();
      },
    });
  }


  dataFilter() {
    if (this.plantdata) {
      const filterdata = this.quantityService.getmodelFilters();
      if (filterdata) {
        this.plants = this.plantdata;
        this.filterForm.get('plant')?.setValue((filterdata?.PlantCode?.length > 1) ? '' : filterdata?.PlantCode[0]);
        this.filterForm.get('packageCode')?.setValue(filterdata?.PackageCode);
        this.filterForm.get('dunnageCode')?.setValue(filterdata?.DunnageCode);
        this.filterForm.get('partNumber')?.setValue((filterdata?.Part1 ?? '') + (filterdata?.Part2 ?? ''));
        this.filterForm.get('supplier')?.setValue(filterdata?.SupplierCode);
      }
    }
  }

  getPlantDropDown() {
    this.haveAccess && this.userService.loadAllPlants().subscribe({
      next: (res: any) => {
        this.plants = [{code:'', name:'ALL'}, ...res, { code: 'All', name: 'ALI' }];
        if(!this.plantdata){
          this.filterForm.patchValue({ plant: this.userInfo.info.plantcode });
          res?.length > 0 && this.setDefaultValues(this.userInfo.info.plantcode);
          }
        this.filterOptions = 
        this.filterForm.get('plant')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value)),
        )
        this.displayFn(this.plants);
        this.quantityService.setplant(this.plants);
        this.columnDefs = this.setColumnDefs();
        res?.length > 0 && this.apiCall();
      },
      error: (_err: any) => {
        this.loader.stop();
      },
    });
  }

  displayFn(selectId:any){
    const selectedOption = this.plants?.find((option:any) => 
    option.code === selectId);
    return selectedOption ? selectedOption.name : []; 
   }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.plants.filter((option:any) =>
      option.name?.toLowerCase().includes(filterValue),
    )
  }

  apiCall() {
    const body = {
      PlantCode: this.filterForm.controls['plant'].value,
      PackageCode: this.filterForm.controls['packageCode'].value,
      DunnageCode: this.filterForm.controls['dunnageCode'].value,
      Part1: this.filterForm.controls['partNumber'].value.substring(0, 5),
      Part2: this.filterForm.controls['partNumber'].value.substring(5, 10),
      SupplierCode: this.filterForm.controls['supplier'].value,
      UserSupplierType: this.userInfo?.role?.supType,
      PageNo: this.cPage,
      RecordsPerPage: this.rpPage
    };
    this.loader.start();
    this.quantityService.setmodelFilters(body);
    this.gridApi?.showLoadingOverlay();
    this.haveAccess && this.quantityService.loadQuantityData(body).subscribe({
      next: (_res: any) => {
        if (_res && !_res.errMsg) {
          if (this.isAll) {
            const data = _res.Data?.length > 0 && _res.Data
            this.displayRowData = [...this.displayRowData, ...data]
          } else {
            this.displayRowData = _res.Data?.length > 0 && _res.Data;
          }
          this.rowCount = this.displayRowData?.length;
          this.totalRecords = _res.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi.setPinnedBottomRowData([this.generatePinnedRow(_res.ReqTotal, _res.ActTotal)])
        } else {
          this.displayRowData = [];
          this.rowCount = 0;
          this.totalRecords = 0;
          this.tPage = 0;
          this.gridApi.setPinnedBottomRowData([]);
        }
        this.loader.stop();
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = 0;
        this.loader.stop();
        this.gridApi?.hideOverlay();
      },
    });
  }

  generatePinnedRow(req: number[], act: number[]) {
    const result: any = {};
    for (let index = 0; index < 14; index++) {
      result[index < 10 ? `reqqty0${index + 1}` : `reqqty${index + 1}`] = req[index];
      result[index < 10 ? `actqty0${index + 1}` : `actqty${index + 1}`] = act[index];
    }
    result['dunnage2code'] = 'TOTAL'
    return result;
  }

  setDefaultValues(defaultPlant: any) {
    this.filterForm.setValue({
      plant: defaultPlant,
      supplier: '',
      partNumber: '',
      packageCode: '',
      dunnageCode: '',
      type: 0,
    });
    this.filterForm.updateValueAndValidity();
    if (this.filterForm.controls['type'].value === 0) {
      this.hideForms = true;
    }
    this.cont1Form.disable();
    this.cont2Form.disable();
  }

  clickOk() {
    if ([0, 1, 2, 3, 4].includes(this.filterForm.controls['type'].value)) {
      const value = this.filterForm.controls['type'].value;
      switch (value) {
        case 0:
          this.navigateToMovepackage()
          break;
       case 1:
          this.navigateToCreateRcaf()
          break;
        case 2:
          this.navigateToDisposepackage()
          break;
        case 3:
          this.navigateToMaintenance()
          break;
        case 4:
          this.navigateToAdjustquantity()
          break;
        default:
          break;
      }
    }
  }

  navigateToMovepackage() {

    if (this.clickedData?.length === 2) {
      const data = {
        fplant: this.clickedData[0]?.plantcode,
        fsupplier: this.clickedData[0]?.suppliercode,
        container: this.clickedData[0]?.packagecode,
        dunnage1: this.clickedData[0]?.dunnagecode,
        dunnage2: this.clickedData[0]?.dunnage2code,
        fyear: this.clickedData[0]?.year,
        fweek: this.clickedData[0]?.week,
        tplant: this.clickedData[1]?.plantcode,
        tsupplier: this.clickedData[1]?.suppliercode,
        tyear: this.clickedData[1]?.year,
        tweek: this.clickedData[1]?.week,
        qtytype: 2,
        Auth: "-"
      }
      this.router.navigate([`quantityConrtol/move-package/${data.fplant}/${data.fsupplier}/${data.container}/${data.dunnage1}/${data.dunnage2}/${data.fyear}/${data.fweek}/${data.tplant}/${data.tsupplier}/${data.tyear}/${data.tweek}/${data.qtytype}/${data.Auth}`]);
    } else {
      if (this.clickedData?.length === 1) {
        alert('Select To Plant Details');
      } else {
        alert('Select From & To Plant Details');
      }
    }

  }

  navigateToCreateRcaf() {

    const specialistcode = this.userInfo.info?.p_specialistcode;
    const plantcode = this.clickedData[0].plantcode;
    const partnumber = this.clickedData[0].partnumber;
    const suppliercode = this.clickedData[0].suppliercode;
    if (this.clickedData?.length > 0) {
      const obj = {
        specialistCode: specialistcode,
        plantCode: plantcode,
        partNumber: partnumber,
        suppliercode: suppliercode,
        year: this.clickedData[0]?.week ?? '',
        Week: this.clickedData[0]?.year ?? '',
        PackageCode: this.clickedData[0]?.packagecode ?? '',
        Dunnage1Code: this.clickedData[0]?.dunnagecode ?? '',
        Dunnage2Code: this.clickedData[0]?.dunnage2code ?? '',
        mode: 1
      }
      sessionStorage.setItem('createRCAF', JSON.stringify(obj))
      this.router.navigateByUrl('/quantityConrtol/create-rcaf');
     // this.router.navigate([`quantityConrtol/create-rcaf/${specialistcode}/${plantcode}/${partnumber}/${suppliercode}/${screen}`])
    } else {
      alert('Select a plant value');
    }

  }
  navigateToDisposepackage() {

    if (this.clickedData?.length === 1) {
      const data2 = {
        PlantCode: this.clickedData[0]?.plantcode,
        SupplierCode: this.clickedData[0]?.suppliercode,
        Part: this.clickedData[0]?.partnumber,
        Year: this.clickedData[0]?.year,
        Week: this.clickedData[0]?.week,
        Container: this.clickedData[0]?.packagecode,
        dunnage1: this.clickedData[0]?.dunnagecode,
        dunnage2: this.clickedData[0]?.dunnage2code,
        qty_type: 2,
        Auth: "-"
      }
      this.router.navigate([`quantityConrtol/dispose-package/${data2.PlantCode}/${data2.SupplierCode}/${data2.Container}/${data2.dunnage1}/${data2.dunnage2}/${data2.Year}/${data2.Week}/${data2.Part}/${data2.qty_type}/${data2.Auth}`]);
    } else {
      this.seterrorMessages(this.alertMessage)
    }

  }



  navigateToMaintenance() {
    if (this.clickedData?.length === 1) {
      const data3 = {
        PlantCode: this.clickedData[0]?.plantcode,
        SupplierCode: this.clickedData[0]?.suppliercode,
        PackageCode: this.clickedData[0]?.packagecode,
        Container: this.clickedData[0]?.packagecode,
        Part: this.clickedData[0]?.partnumber,
        Year: this.clickedData[0]?.year,
        Week: this.clickedData[0]?.week,
        Dunnage1Code: this.clickedData[0]?.dunnagecode,
        Dunnage2Code: this.clickedData[0]?.dunnage2code,
        qty_type: 2,
        Auth: "-"
      }
      this.router.navigate([`quantityConrtol/maintenance-package/${data3.PlantCode}/${data3.SupplierCode}/${data3.PackageCode}/${data3.Container}/${data3.Dunnage1Code}/${data3.Dunnage2Code}/${data3.Year}/${data3.Week}/${data3.Part}/${data3.qty_type}/${data3.Auth}`]);
    } else {
      this.seterrorMessages(this.alertMessage)
    }
  }

  navigateToAdjustquantity() {
    if (this.clickedData?.length === 1) {
      const data4 = {
        PlantCode: this.clickedData[0]?.plantcode,
        SupplierCode: this.clickedData[0]?.suppliercode,
        PackageCode: this.clickedData[0]?.packagecode,
        Part: this.clickedData[0]?.partnumber,
        Year: this.clickedData[0]?.year,
        Week: this.clickedData[0]?.week,
        Dunnage1Code: this.clickedData[0]?.dunnagecode,
        Dunnage2Code: this.clickedData[0]?.dunnage2code,
        qty_type: 2,
        Auth: "-",
      }
      this.router.navigate([`quantityConrtol/adjust-quantity/${data4.PlantCode}/${data4.SupplierCode}/${data4.PackageCode}/${data4.Dunnage1Code}/${data4.Dunnage2Code}/${data4.Year}/${data4.Week}/${data4.Part}/${data4.qty_type}/${data4.Auth}`]);
    } else {
      this.seterrorMessages(this.alertMessage)
    }
  }

  setColumnDefs() {
    const columnDefs: any[] = [
      { headerName: 'PLANT', field: 'plant', minWidth: 80 },
      { headerName: 'SUPPLIER', field: 'supplier', minWidth: 80 },
      { headerName: 'PART', field: 'partnumber', minWidth: 80 },
      { headerName: 'PACKAGE', field: 'packagecode', minWidth: 80},
      { headerName: 'DUNNAGE1', field: 'dunnagecode', minWidth: 100 },
      { headerName: 'DUNNAGE2', field: 'dunnage2code', minWidth: 100 },
      {
        headerName: 'DETAIL',
        field: 'detail',
        minWidth: 80,
        cellRendererFramework: QuantityButtomComponent,
        cellRendererParams: {
          clicked: (_data: any) => {
            this.routeToDetail(_data);
          },
        },
      },
      {
        headerName: 'LOG',
        field: 'log',
        minWidth: 80,
        cellRendererFramework: QuantityButtomComponent,
        cellRendererParams: {
          clicked: (_data: any) => {
            this.routeToLog(_data);
          },
        },
      },
    ];

    for (let i = 7; i <= 20; i++) {
      const subHeader = {
        headerName: `W+${i - 7}` === 'W+0' ? 'W' : `W+${i - 7}`,
        children: [
          {
            headerName: 'R',
            field: i - 7 <= 9 ? `reqqty0${i - 6}` : `reqqty${i - 6}`,
            minWidth: 50,
            cellRendererFramework: QuantityButtomComponent,
            cellRendererParams: {
              clicked: (data: any) => {
                this.clickLink(data);
              },
            },
          },
          {
            headerName: 'A',
            field: i - 7 < 9 ? `actqty0${i - 6}` : `actqty${i - 6}`,
            minWidth: 50,
            cellRendererFramework: QuantityButtomComponent,
            cellRendererParams: {
              clicked: (data: any) => {
                this.clickLink(data);
              },
            },
          },
        ],
      };
      columnDefs.splice(i - 1, 0, subHeader);
    }
    return columnDefs;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.loader.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
          this.cPage = i + 1;
          this.tPage = 1;
          this.rpPage = 5000;
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall();
    }
  }

  async onLoadAll() {
    this.apiCall();
    if (this.apiFromSearch) {
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.apiFromSearch = false;
    }
    return ''
  }

  routeToDetail(data: any) {
    const { plantcode, suppliercode, packagecode, dunnagecode, dunnage2code } =
      data;
    this.router.navigate([
      `quantityConrtol/detail/${plantcode}/${suppliercode}/${packagecode}/${dunnagecode}/${dunnage2code}`,
    ]);
  }

  routeToLog(data: any) {
    const { plantcode, suppliercode, packagecode, dunnagecode, dunnage2code } =
      data;
    this.router.navigate([
      `quantityConrtol/log/${plantcode}/${suppliercode}/${packagecode}/${dunnagecode}/${dunnage2code}`,
    ]);
  }

  radioChange(event: any) {
    this.hideForms = event.value === 0 ? true : false;
    this.clickedData = [];
    this.resetForm();
    this.disableOk = true;
  }

  resetForm() {
    this.clickedData = [];
    this.disableOk = true;
    this.cont1Form.patchValue({
      plant: '',
      supplier: '',
      partNumber: '',
      packageCode: '',
      dunnage1Code: '',
      dunnage2Code: '',
      week: '',
    });
    this.cont2Form.patchValue({
      supplier: '',
      partNumber: '',
      packageCode: '',
      dunnage1Code: '',
    })
  }

  seterrorMessages(message: any) {
    this.errorMessages = message
    setTimeout(() => {
      this.errorMessages = ''
    }, 5000);
  }

  getLength() {
    return this.errorMessages?.length
  }

  clickLink(data: any) {
    if (data) {
      if (this.clickedData?.length === 0) {
        this.colindexselectes = data.colid
        this.rowindexselectes = data.rowindex
        this.selectedpackagePackage = data.packagecode
        this.selectedDunnage1 = data.dunnagecode
        this.selectedDunnage2 = data.dunnage2code
      }
      const type = this.filterForm.controls['type'].value;
      if (type === 0) {
        this.onTypeZero(data)
      } else {
        this.clickedData = [];
        this.clickedData[0] = data;
        this.disableOk = this.clickedData?.length === 1 ? false : true;
        this.cont1Form.setValue({
          plant: data?.plant,
          supplier: data?.supplier,
          partNumber: data?.partnumber,
          packageCode: data?.packagecode,
          dunnage1Code: data?.dunnagecode,
          dunnage2Code: data?.dunnage2code,
          week: data?.week,
        });
      }
    }
  }

  onTypeZero(data: any) {
    if (this.clickedData?.length === 0) {
      this.setDataForZero(data)
    } else {
      if (this.colindexselectes === data.colid && this.rowindexselectes === data.rowindex) {
        this.seterrorMessages('Please select [to] ')
      } else if (this.rowindexselectes === data.rowindex) {
        this.seterrorMessages('same Place')
      } else if (this.selectedpackagePackage !== data.packagecode ||
        this.selectedDunnage1 !== data.dunnagecode ||
        this.selectedDunnage2 !== data.dunnage2code) {
        this.seterrorMessages('Difference Package and Dunnage')
      } else {
        this.clickedData[1] = data;
        this.cont2Form.setValue({
          supplier: data?.supplier,
          partnumber: data?.partnumber,
          week: data?.week,
          plant: data?.plant,
        })
      }
    }
    this.disableOk = this.clickedData?.length === 2 ? false : true;
  }

  setDataForZero(data: any) {
    this.clickedData[0] = data;
    this.cont1Form.setValue({
      plant: data?.plant,
      supplier: data?.supplier,
      partNumber: data?.partnumber,
      packageCode: data?.packagecode,
      dunnage1Code: data?.dunnagecode,
      dunnage2Code: data?.dunnage2code,
      week: data?.week,
    })
  }

  help() {
    this.router.navigate(['./master-help', 4, "Quantity Control", 70])
  }
}
