import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'backup-auth-link-cell-renderer',
  template: `
  <a (click)="openDialog()" style="font-weight:500">{{ text }}</a>
  `,
})

export class BackupAuthRouterLinkCellRenderer implements ICellRendererAngularComp {
  ppfArray: any=[];
  ppfcode: any="";
  ppfver: any="";
  partnumber: any="";

  constructor(public dialog: MatDialog, private readonly router: Router, private readonly route: ActivatedRoute,    private readonly service: BackupAuthlistService,private readonly constants: ConstantsService,
    ) { }

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }

  private params: any;
  text: any;

  agInit(params: any): void {
    this.params = params;
   this.ppfArray = this.service.getPpfArray();
    if (params.colDef.headerName === 'AUTH#') {
      this.text = this.params.data.authnumber;
    }
    if (params.colDef.headerName === 'PART NUMBER') {
      this.text = this.params.data.partnumber;
    }
  }

  openDialog() {
    
    if (this.params.colDef.headerName === 'AUTH#') {
      this.router.navigateByUrl('/backup-auth-list/backup-auth-details?auth=' + this.params.data.authnumber);
    }
    if (this.params.colDef.headerName === 'PART NUMBER') {
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data.plantcode)
      this.ppfArray.forEach((item:any) => {
        if (item.partnumber === this.params.data.partnumber) {
          this.ppfcode=item.ppfcode
          this.ppfver=item.ppfver
        }
      });            
      if (plantCode === 'SERVICE') {       
        this.router.navigate(['/backup-auth-list/serviceparts-pdf',this.params.data.partnumber, this.ppfcode,this.ppfver, 1, 2])
      } else if(plantCode === 'ASSY'){
        this.router.navigate(['/backup-auth-list/accessoryParts-pdf',this.params.data.partnumber, this.ppfcode,this.ppfver, 1, 2])
      }
      else{ 
        this.router.navigate(['/backup-auth-list/ppf',this.ppfcode,this.ppfver,1,2]);
      }
    }
  }
}




