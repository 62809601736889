import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-est-fix-btn-cell-render',
  template: `
    <button 
    class="vpacs-btn-table"
    style="width:80px; height:30px; color:#676a6e; font-size: 13px" [class.red-image] = 'params.data.estver === "1"'
    (click)="btnClickedHandler($event)"  [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'">
    {{params.data.estver === '0' ? 'EST FIX' : 'FIX CANCEL'}} </button>
  `,
  styles: ['.red-image { background-image: linear-gradient(rgb(251 3 3) 0%, rgb(254 0 0) 100%); color: white !important;}']

})

export class EstFixBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly state: ExportStateService){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  params: any;
  disableFlag = false;
  dullFlag = false;
  agInit(params: any): void {
    this.params = params;  
    this.dullFlag = this.disableFlag = ![0,1].includes(Number(params.data.estver)); 
    this.getUserData()
  }

  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        if (!data.role.p_costsave) {
            this.disableFlag = true;
            this.dullFlag = true;
        }
      }
    });
  }

  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




