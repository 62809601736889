import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';

@Component({
  selector: 'app-register-package-estimate',
  templateUrl: './register-package-estimate.component.html',
  styleUrls: ['./register-package-estimate.component.scss']
})
export class RegisterPackageEstimateComponent implements OnInit {
  cost: any;
  submitFlag = false
  plantType: any;
  costs = [{
    Id: 0, Name: "$(US)"
  },
  {
    Id: 1, Name: "$(CAN)"
  },
  {
    Id: 2, Name: "(P)"
  },
  ]
  costRate: any;
  costValue: any;
  imageSrc: any;
  gridApi: any;
  contactForm: any
  packageCode: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: { suppressMovable: boolean; wrapText: boolean; suppressPaginationPanel: boolean; autoHeight: boolean; suppressHorizontalScroll: boolean; };
  icons: { sortUnSort: string; };
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
    <div>
      <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
      <p class="fw-bold fs-5">No data available</p>
      <span>Please select the above filters to get the results.</span>
    </div>
    `;
  response: any;
  message: any;
  pValue: any;
  numberFlag = false

  constructor(public dialogRef: MatDialogRef<RegisterPackageEstimateComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly pckgService: PackageMaintananceService,
    private readonly loader: NgxUiLoaderService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: false,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
    this.packageCode = this.data.pckgCode;
    this.pValue = this.data.pValue ///have to provide plant value
    setTimeout(() => {
      this.loader.start()
     this.regMethod();
    });
    this.displayRowData = [this.columnDefs];
    this.columnDefs = [];
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  change(event: any) {
    this.numberFlag = isNaN(event.target.value)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  regMethod() {
   
    const obj = {
      "PackageCode": this.packageCode,
      "PlantType": this.pValue
    }
    this.pckgService.registerAPi(obj)
      ?.subscribe({
        next: (res: any) => {
          this.response = res;
          this.loader.stop()
          if (this.response[0] && this.response[0][0] && this.response[0][0].cost) {
            this.costRate = this.response[0][0].cost;
            this.costValue = Number(this.response[0][0].monetaryunit)
          }
          this.plantType = this.response[1]
        },
        error: (_err: any) => {
          this.loader.stop()
        },
      })
  }

  addReg() {
    const obj = {
      "PackageCode": this.packageCode,
      "PlantType": this.pValue,
      "Cost": this.costRate,
      "MonetaryUnit": this.costValue,
    }
    this.pckgService.registerAdd(obj)
      ?.subscribe({
        next: (res: any) => {
          this.response = res;
          this.loader.stop()
          this.dialogRef.close({ event: 'addReg' });
          if (res.status === 'Success') {
            const message = 'Cost was Added Successfully'
            this.data.parent.Onsuccess(message)
          }
        },
        error: (_err: any) => {
          this.loader.stop()
          const message = 'Unable to Add Cost'
          this.data.parent.onDelete(message)
        },
      })
  }

  onSubmit(form: any) {
    this.submitFlag = true
    if (this.submitFlag && form.valid && (!this.numberFlag)) {
      this.loader.start()
      this.addReg()
    }
  }

}
