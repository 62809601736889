import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import {
  material,
} from 'src/app/core/dialogs/select-package-ppf/selectpackageDropdowm.const';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface';
import { PackageCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/package-maintenance-btn-cell-render';
import { PPFSelectRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/ppf/ppf-select-render';
import { KgToPound, toNumber } from 'src/app/internal-vpacs/internal-vpacs/tracking/part-list/part-list-ppf/ppfCalculations';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrls: ['./select-package.component.scss'],
  providers: [RegexFilter,BreadcrumbComponent],
})
export class SelectPackageComponent implements OnInit {
  public gridApi: any;
  onloadFlag=true
  public rowHeight = 50;
  public defaultColDef = {};
  public columnDefs: any[] = [];
  public domLayout: any = '';
  public displayRowData: any[] = [];
  public searchPackage: any;
  public validForm = false;
  public currentPageNo: any;
  public currentPageBoolean = false;
  public totalPage = 0;
  public currentPageForTotalPag = 0;
  public isLastPageFound!: boolean;
  public paginationPageSize: any = 50;
  public rowCount!: number;
  rowData: any;
  public typeDropdown: any[] = [{packagetype :"All", packagetypecode:""}];
  public materialDropdown: any[] = [];
  supplierCode: any;
  public overlayLoadingTemplate = `<div class="spinner-border text-danger" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rownum: any;
  gridColumnApi: any;
  sortModel: any[] = [];
  totalPageCheck: any;
  currentPageCheck: any=1;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  unit:any='0'
  fab:any=""
  packageType:any=""
  gridOptions: any;
  userInfo:any
  package: any=true;
  childHeadNoBorder='child-header-color no-border';
  icons = {
    sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
  }
  rowClassRules = {
    'row-even': 'node.rowIndex % 2 === 0',
    'row-odd': 'node.rowIndex % 2 !== 0',
  };
  errorMessage !: ErrorMessage;

  isAll=false;
  apiFromSearch = false;
  errMsg = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly router: Router,
    public dialogRef: MatDialogRef<SelectPackageComponent>,
    public formbuilder: FormBuilder,
    public ppfService: PpfServiceInternal,
    public route: ActivatedRoute,
    public regex: RegexFilter,
    private readonly spinner: NgxUiLoaderService,
    private readonly breadcrumb:BreadcrumbComponent,
    private readonly state: ExportStateService
  ) {
    this.dialogRef.disableClose = true;
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    };
  }

  ngOnInit(): void {
    this.onloadFlag=true
    setTimeout(() => {
    this.getState()
    }, 0);
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;
    this.materialDropdown = material;
    this.rownum = this.data?.rownumber;
    this.ppfService.setPpfPkgData(this.rownum)
    this.fab=this.data.fab;
    
    switch (this.rownum) {
      case 1:
        this.packageType = "Container";
        break;
      case 2:
        this.packageType = "Dunnage";
        break;
      case 3:
        this.packageType = "Dunnage";
        break;
      case 4:
        this.packageType = "Top Cap";
        break;
      case 5:
        this.packageType = "Pallet";
        break;
      case 6:
        this.packageType = "Strech Wrap/Strap";
        break;
      case 7:
        this.packageType = "Kanban Holder";
        break;
      default:
        this.packageType = "Other";
        break;
    }
    this.searchPackage = this.formbuilder.group({
      code: [''],
      type: ['All'],
      material: [''],
      sn: [''],
      dimension: ['outside'],
      lengthOver: [''],
      widthOver: [''],
      heightOver: [''],
      lengthUnder: [''],
      widthUnder: [''],
      heightUnder: [''],
    });
    setTimeout(() => {
      this.apiCall();  
    });  
  }

  getState() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.spinner.start();
          this.userInfo = res
          this.unit = this.userInfo?.info?.unit;
          //  this.unit='0'
          this.getColumnDefs();
        }
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
    });
  }

  dimensionformat(params: any, field: string) {
    if (this.unit === '1') {
      if (params.data[`${field}`] !== null && params.data[`${field}`] !== '') {
        return Math.round(params.data[`${field}`] / 25.4 * 20) / 20
      } else {
        return ""
      }
    } else {
      if (params.data[`${field}`] !== null && params.data[`${field}`] !== '') {
        return Number(params.data[`${field}`]).toFixed(0)
      } else {
        return ""
      }
    }
  }

  weightDimension(params: any) {
    if (params.data.weight !== null && params.data.weight !== '') {
      if (this.unit === '1') {
        return Number(KgToPound(toNumber(params.data.weight))).toFixed(2)
      } else {
        return Number(params.data.weight).toFixed(2)
      }
    } else {
      return null
    }
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: 'Select',headerClass: this.childHeadNoBorder,
        field: 'select',
        
            cellRendererFramework: PPFSelectRender,
            cellRendererParams: {
              clicked: (field: any) => {
                this.btnSelected(field);
              },
            },
            minWidth: 80, maxWidth: 100,
      },
      {
        headerName: 'Code',headerClass: this.childHeadNoBorder,
       minWidth: 80, maxWidth: 100,  field: 'packagecode' ,
      },
      {
        headerName: 'type',headerClass: this.childHeadNoBorder,
         minWidth: 80, maxWidth: 100,  field: 'packagetypecode',
      },
      {
        headerName: 'description',headerClass: this.childHeadNoBorder,
        minWidth: 100, maxWidth: 140,  field: 'description'
      },
      {
        headerName:  this.unit === '0' ? "outside dimension(mm)" : "outside dimension(inch)" ,headerClass: this.childHeadNoBorder,
        field: 'outside dimension',
        children: [
          { headerName: 'length', field: 'outerlength', minWidth: 80, maxWidth: 100, headerClass: this.childHeadNoBorder,
            valueGetter: (params: any) => {
              return this.dimensionformat(params, 'outerlength')
            }   },
          { headerName: 'width', field: 'outerwidth', minWidth: 80, maxWidth: 100 ,headerClass: this.childHeadNoBorder,
          valueGetter:(params: any) => {
            return this.dimensionformat(params, 'outerwidth')
          }    },
          { headerName: 'height', field: 'outerheight', minWidth: 80, maxWidth: 100 ,headerClass: this.childHeadNoBorder,
          valueGetter:(params: any) => {
            return this.dimensionformat(params, 'outerheight')
          }  },
        ],
      },
      {
        headerName: this.unit === '0' ? "inside dimension(mm)" : "inside dimension(inch)",headerClass: this.childHeadNoBorder,
        field: 'inside dimension',
        children: [
          { headerName: 'length', field: 'innerlength', minWidth: 80, maxWidth: 100 ,headerClass: this.childHeadNoBorder,
          valueGetter:(params: any) => {
            return this.dimensionformat(params, 'innerlength')
          }    },
          { headerName: 'width', field: 'innerwidth', minWidth: 80, maxWidth: 100,headerClass: this.childHeadNoBorder,
          valueGetter:(params: any) => {
            return this.dimensionformat(params, 'innerwidth')
          }    },
          { headerName: 'height', field: 'innerheight', minWidth: 80, maxWidth: 100,headerClass: this.childHeadNoBorder,
          valueGetter: (params: any) => {
            return this.dimensionformat(params, 'innerheight')
          }   }
        ],
      },
      {
        headerName: this.unit === '0' ? "weight(kg)" : "weight(IB)",headerClass: this.childHeadNoBorder,
       minWidth: 100, maxWidth: 140,  field: 'weight',
       valueGetter: (params:any)=>{
        return this.weightDimension(params)
       }   },
      {
        headerName: 'material',headerClass: this.childHeadNoBorder,
        minWidth: 100, maxWidth: 170,  field: 'material' ,
      },
      {
        headerName:  this.unit === '0' ? "nest(mm)" : "nest(inch)",headerClass: this.childHeadNoBorder,
        minWidth: 80, maxWidth: 100,  field: 'nesting' ,
        valueGetter: (params: any) => {
          return this.dimensionformat(params, 'nesting')
        } },
      {
        headerName: this.unit === '0' ? "return height(mm)" : "return height(inch)",headerClass: this.childHeadNoBorder,
        minWidth: 100, maxWidth: 130,  field: 'retheight' ,
        valueGetter:  (params: any) => {
          return this.dimensionformat(params, 'retheight')
        } },
      {
        headerName: 'S/N',headerClass: this.childHeadNoBorder,
        minWidth: 80, maxWidth: 100,  field: 'standard' ,
      },
      {
        headerName: 'r/e',headerClass: this.childHeadNoBorder,
        minWidth: 80, maxWidth: 100,  field: 'returnable',
      },
      {
        headerName: 'quality k holder',headerClass: this.childHeadNoBorder,
        minWidth: 130, maxWidth: 160,  field: 'qtykholder',
      },
      {
        headerName: "COST", field: "cost",headerClass: this.childHeadNoBorder,floatingFilterComponentParams:false,cellRendererFramework:PackageCellRenderer,
        floatingFilter: false,
        filter: false,minWidth: 110,
      },
      {
        headerName: "DRAW", field: "draw",headerClass: this.childHeadNoBorder,floatingFilterComponentParams:false,cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false,minWidth: 110,
      },
      {
        headerName: "PHOTO", field: "photo",headerClass: this.childHeadNoBorder,floatingFilterComponentParams:false,cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false,minWidth: 110,
      },
    ];
  }
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinner.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 3000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 3000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearch();
    }
  }
  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.apiCall();
      if (this.apiFromSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiFromSearch = false;
      }
      resolve();
    });
  }
  apiCall() {
    this.errMsg = ''
    const body = {
      sort: 'null',
      Size:this.searchPackage?.get('dimension').value==='outside'?"0":"1",
      limit:this.rpPage,
      page:this.cPage,
      PkgCode: this.searchPackage?.get('code').value==='All'?"":this.searchPackage?.get('code').value,
      PkgTypeCode: this.searchPackage?.get('type').value==='All'?"":this.searchPackage?.get('type').value,
      Material: this.searchPackage?.get('material').value,
      unit: this.unit?this.unit:"",
      SN: this.searchPackage?.get('sn').value,
      url_row: this.rownum,
      url_fab: this.fab ?? '',
      HeightU: this.searchPackage?.get('heightUnder').value,
      HeightO: this.searchPackage?.get('heightOver').value,
      WidthU: this.searchPackage?.get('widthUnder').value,
      WidthO: this.searchPackage?.get('widthOver').value,
      LengthU: this.searchPackage?.get('lengthUnder').value,
      LengthO: this.searchPackage?.get('lengthOver').value,
    };
    this.spinner.start();
    this.ppfService.PackageOnload(body).subscribe({
      next: (res: any) => {
        if(this.onloadFlag){
          this.searchPackage.controls['type'].setValue('')
        }
        this.typeDropdown.push(...res.TypeDropDown)
        const data = res.data;
        this.rowData = data;
        this.displayRowData = data;
        this.rowCount = res.length;
        this.totalRecords = res?.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.redrawRows();
        if(data.length <=0){
          this.errMsg = 'No Data Found';
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.rowData = [];
        this.displayRowData = [];
        this.rowCount = 0;
        this.errorMessage = {errorCode : `API-${_err.status}`,childErrorCode : _err.status};
        this.spinner.stop();
      },
    });
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: params });
  }

  onSearch() {
    this.onloadFlag=false
    if (this.isAll) {
      this.apiFromSearch = true;
      const values = {
        cPage: 1,
        tPage: 1,
        rpPage: 'ALL',
      }
      this.changePaginationValues(values);
    }
    this.apiCall()
  }

  closeDialog() {
    this.dialogRef.close();
  }

  newPackage() {
    this.closeDialog();
    const newPackage = {
      row : this.rownum,
      packagecode : ''
    }
    this.ppfService.newPackage.row = this.rownum - 1;
    this.router.navigate([this.breadcrumb.breadcrumbs.at(-1)?.url+'/add-package/true']);
  }

  help(){
    this.closeDialog();
    this.router.navigate(['./master-help',2,"Tracking ",25])
  }
}