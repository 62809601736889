<div class="d-flex justify-content-between align-items-center m-2">
  <p class="page-title mx-2 px-2">User Maintenance</p>   
  <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<!-- Error Message -->
<div class="alert alert-danger mx-2" *ngIf="haveErrors === true && errorList.length > 0">
  <span *ngFor="let message of errorList">{{message}}</span>
</div>
<!-- Success Message -->
<div class="alert alert-success show mx-2" role="alert" *ngIf="successMessage">
  <span>{{successMessage}}</span>
</div>
<!-- Top Filters -->
<div class="m-2 p-2" *ngIf="haveAccess">
  <div class="filter mx-2 d-flex flex-wrap gap-4" [formGroup]="userFilters" (keyup.enter)="onSearch()">
    <div class="d-flex align-items-center gap-2">
      <label for="code">Code</label>
      <input type="text" id="code" formControlName="code" />
    </div>
    <div class="d-flex align-items-center gap-2">
      <label for="fname">First Name</label>
      <input type="text" id="fname" formControlName="fname" />
    </div>
    <div class="d-flex align-items-center gap-2">
      <label for="lname">Last Name</label>
      <input type="text" id="lname" formControlName="lname" />
    </div>
    <div class="d-flex align-items-center gap-2">
      <label for="plant">Plant</label>
      <input type="text" matInput formControlName="plant" class="mat-input me-1" [placeholder]="'--Select Plant--'"
      aria-label="text" [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let x of plant | async" [value]="x.code">{{x.name}}
      </mat-option>
    </mat-autocomplete>
    </div>

    <div class="d-flex gap-4 col-lg-4 col-md-12 ">
      <button (click)="onSearch()" class="black-button" style="height: 30px;">SEARCH</button>
      <button class="vpacs-btn-table" (click)="openDialog('add')" [disabled]="!addAccess" style="height: 30px;">ADD</button>
    </div>
  </div>
</div>
<!-- Grid -->
<div class="grid-agg mt-3 grid-height grid-height-user-maintenance mb-5" *ngIf="haveAccess">
  <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
    [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true" [gridOptions]="gridOptions"
    [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
    [headerHeight]="headerHeight" [overlayLoadingTemplate]="overlayLoadingTemplate"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40"
    (filterChanged)="onFilterChanged($event)">
  </ag-grid-angular>
  <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
    (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
  </app-pagination>
</div>