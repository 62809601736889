<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Dept. Approved Items</label>
        </div>
    </header>

    <div class="popup-contents">
        <div class="row m-3 content-row">
            <div class="col-md-6 align-items-center">
                <div class="row">
                    <div class="col-md-4 titleColumn">
                        <div *ngFor="let title of info" class="contents ps-4 py-2">
                            {{title}}
                        </div>
                    </div>
                    <div class="col-md-8 contentColumn p-0">
                        <div *ngFor="let title of infoContents; let i=index" class="ps-4 py-2"
                            [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                            {{title}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex ">
                <label class="col-3">Comment</label>
                <textarea rows="2" class="comment col-9 ms-2" [(ngModel)]="comment" [disabled]="CommentflagDsbld"></textarea>
            </div>
        </div>


        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 roboto-bold" (click) = "close()"> CANCEL </button>
            <button class="submit black-button me-3 roboto-bold" (click) = "downloadApproved()">DOWNLOAD ATTACHED</button>
            <button class="submit black-button me-3 roboto-bold" (click) = "markAsUnRead()">MARKS AS UNREAD</button>
        </div>
    </div>
</div>