<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Ringi List</p>
    <div>
      <button class="black-button mx-2" (click)="addRingi()" [disabled]="!haveAddAccess">ADD</button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
</div>
<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
        <div class="message-container">
            <span class="message-text">{{ message }}</span>
            <button type="button" class="btn-close" (click)="close()"></button>
        </div>
    </div>
</div>
<div class="" *ngIf="haveAccess">
<div class="vpacs-shadow m-2 p-2">
    <div class="filter mx-1 d-flex" (keyup.enter)="onSearch()">
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
            <label for="ringinumber" class="col-4">Ringi #</label>
            <input type="text" id="ringinumber" style="width: 100%;" [(ngModel)]="ringinumber" />
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
            <label for="plant" class="col-4">Plant</label>
            <input type="text"  style="width: 100%;" aria-label="text" matInput [(ngModel)]="plant" (input)="filterOptions()"
            placeholder="--Select Plant--" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{plants.plant}}
            </mat-option>
        </mat-autocomplete>
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
            <label for="familycode" class="col-4">Family Code</label>
            <input type="text" id="familycode" style="width: 100%;" [(ngModel)]="familycode" />
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
            <label for="modelcode" class="col-4">Model Code</label>
            <input type="text" id="modelcode" style="width: 100%;" [(ngModel)]="modelcode" />
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <div class="d-flex col-lg-8 me-2">
                <button class="black-button" (click)="uploadRingiFile()" [disabled]="!haveAddAccess">IMPORT RINGI</button>
            </div>
            <div class="d-flex col-lg-12">
                <button class="black-button" (click)="uploadEstimateFile()" [disabled]="!haveAddAccess">IMPORT ESTIMATION</button>
            </div>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-2">
            <label for="startDateFrom" class="col-4">Start Date From</label>
            <input type="date" id="startDateFrom" style="width: 100%;" [(ngModel)]="startDateFrom" (keydown)="onKeyDown($event,'startDateFrom')"  />
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-2">
            <label for="endDateTo" class="col-4">Start Date To</label>
            <input type="date" id="endDateTo" style="width: 100%;" [(ngModel)]="endDateTo" (keydown)="onKeyDown($event,'endDateTo')"  />
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-2">
            <label for="endDateFrom" class="col-4">End Date From</label>
            <input type="date" id="endDateFrom" style="width: 100%;" [(ngModel)]="endDateFrom" (keydown)="onKeyDown($event,'endDateFrom')" />
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-2">
            <label for="startDateTo" class="col-4">End Date To</label>
            <input type="date" id="startDateTo" style="width: 100%;" [(ngModel)]="startDateTo" (keydown)="onKeyDown($event,'startDateTo')" />
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12">
            <div class="d-flex col-lg-8 me-2">
                <button class="black-button" (click)="onSearch()">Search</button>
            </div>
            <div class="d-flex col-lg-12">
                <button class="vpacs-btn-table" style="width:30px;height:30px" (click)="openLegends()">?</button>
            </div>
        </div>
    </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
    <div class="grid-agg tracking-summary grid-height-ringi-list mb-5">
        <ag-grid-angular style="width:100%;height:100%;" 
            class="ag-theme-balham" 
            (gridReady)="onGridReady($event);"
            (filterChanged)="onFilterChanged($event)"
            (window:resize)="sizeToFit()" 
            [rowHeight]="50" 
            [animateRows]="true" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData" 
            [suppressCellSelection]="true"
            [suppressHorizontalScroll]="false" 
            [pagination]="true" 
            [paginationPageSize]="rpPage"
            [suppressPaginationPanel]="true" 
            [icons]="icons" 
            [rowClassRules]="rowClassRules"
            [floatingFiltersHeight]="40"
            [overlayLoadingTemplate]="overlayLoadingTemplate" 
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="domLayout" 
            >
        </ag-grid-angular>
        <app-pagination 
            [cPage]="cPage" 
            [tPage]="tPage" 
            [rpPage]="rpPage" 
            [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)"
            *ngIf="totalRecords > 0"
            >
        </app-pagination> 
    </div>
</div>
    
</div>




