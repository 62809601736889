<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Backup Auth Details</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
<div class="alert alert-danger mx-3 d-flex justify-content-between" *ngIf="errorList.length > 0">
    <span *ngFor="let message of errorList">{{message}}</span>
    <span class="close-css" (click)="close()">X</span>
  </div>
<div class="accordion-container m-3">
    <mat-accordion hideToggle multi>
        <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">PART INFORMATION</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row my-3 content-row mx-0">
                <div class="col-md-4 align-items-center">
                  <div class="row">
                    <div class="col-md-4 titleColumn">
                      <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                        {{ title }}
                      </div>
                    </div>
                    <div class="col-md-8 contentColumn p-0">
                      <div *ngFor="let title of partInfo; let i=index" class="ps-4 py-2" [ngClass]="{ 'row-even': i % 2 === 0, 'row-odd': i % 2 !== 0 }" [style.min-height.px]="getRowHeight()">
                        {{ partInfoContents[i] || '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              
              
        </mat-expansion-panel>
        <div class="row m-0">
            <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
                (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
                <mat-expansion-panel-header class="header-container me-3">
                    <mat-panel-title class="roboto-bold me-0">BACKUP PACKAGE SPECIFICATION</mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row m-3 ms-0 content-row box-shadow">
                    <div class="col-md-12 ">
                        <div class="row d-flex" *ngFor="let title of backupSpecification">
                            <div class="col-md-2 titleColumn  ps-2 py-2">
                                {{title.title}}
                            </div>
                            <div class="col-md-10 d-block p-0" >
                                <div class="d-flex" *ngFor="let subHeads of title.subHeads; let i=index">
                                    <div class="col-md-4 titleColumn subHeads px-2 ">
                                        <div class="d-block p-2 px-0 border-bottom"
                                            >
                                            {{subHeads.name}}
                                        </div>
                                    </div>
                                    <div class="col-md-8 gap-2 d-flex contentColumn subContents p-0" [ngClass]="i % 3 == 0? 'row-even' : 'row-odd'">
                                        <div class=" p-2 col-2 pe-0" *ngFor="let value of subHeads.value; let j=index"
                                            ><div style="white-space: nowrap" [ngClass]="{'bg-class': value.css === 'yes'}">{{value.val}}</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
                (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="roboto-bold me-0">PPF SPECIFICATION</mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row my-3 content-row  box-shadow mx-0">
                    <div class="col-md-12">
                        <div class="row d-flex" *ngFor="let title of backupSpecification1">
                            <div class="col-md-2 titleColumn  ps-2 py-2">
                                {{title.title}}
                            </div>
                            <div class="col-md-10 d-block p-0" >
                                <div class="d-flex" *ngFor="let subHeads of title.subHeads; let i=index">
                                    <div class="col-md-4 titleColumn subHeads px-2 ">
                                        <div class="d-block p-2 px-0 border-bottom"
                                            >
                                            {{subHeads.name}}
                                        </div>
                                    </div>
                                    <div class="col-md-8 gap-2 d-flex contentColumn subContents p-0" [ngClass]="i % 3 == 0? 'row-even' : 'row-odd'">
                                        <div class=" p-2 col-2 pe-0" *ngFor="let value of subHeads.value; let j=index"
                                            ><div style="white-space: nowrap" [ngClass]="{'bg-class': value.css === 'yes'}">{{value.val}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>

    </mat-accordion>

    <div class="reason">
        <div class="row m-3 content-row mx-0 mt-2">
            <div class="col-md-5 align-items-center ps-0 pe-3">
                <table class="table table-bordered" aria-describedby="reason">
                    <thead>
                        <tr class="titleColumn">
                            <th scope="col">Responsibility</th>
                            <th scope="col">Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="contentColumn">
                            <td>{{responsibility}}</td>
                            <td>{{reason}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-3 align-items-center px-3" *ngIf="costBudgetFlag">
                <div class="row">
                    <div class="col-md-5 titleColumn px-0">
                        <div class="contents ps-4 py-2">
                            Cost Center
                        </div>
                        <div class="contents ps-4 py-2">
                            Budget
                        </div>
                    </div>
                    <div class="col-md-7 contentColumn p-0 rowClass">
                        <div class="py-2" *ngIf="selCostCenter">
                            <select style="width: '150px'" (change)="onSelectChange($event)" id="plant" class="mat-sel pt-2 ms-1" placeholder="Select"  [(ngModel)]="costCenterVal" >
                                <option *ngFor="let cost of constCenterArr" [value]="cost.costcenter">{{cost.costcenter}}</option>
                            </select> 
                        </div>
                        <div class="py-2 px-2" *ngIf="!selCostCenter">   
                            {{costcenter}}
                        </div>
                        <div class="py-2" *ngIf="selBudjet"> 
                            <select style="width: '150px'" id="plant" class="mat-sel pt-2 ms-1" placeholder="Select"  [(ngModel)]="budgetVal">
                                <option *ngFor="let bug of budgetArr" [value]="bug.budget">{{bug.budget}}</option>
                            </select>
                        </div>
                        <div class="py-2 px-2" *ngIf="!selBudjet"> 
                            {{budget}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 align-items-center ps-3 pe-1" *ngIf="invoiceFlag">
                <table class="table table-bordered text-center" aria-describedby="invoice">
                    <thead>
                        <tr class="titleColumn">
                            <th scope="col">Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="contentColumn">
                            <td class="d-flex justify-content-center"><button class="vpacs-btn-table" (click)="downloadInvoice()">DOWNLOAD</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-2 align-items-center ps-3 pe-0">
                <table class="table table-bordered text-center" aria-describedby="date">
                    <thead>
                        <tr class="titleColumn">
                            <th scope="col">Usage Start Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="contentColumn">
                            <td>{{UserDate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row m-3 mt-0 content-row mx-0" *ngIf="gridFlag">
        <div class="grid mt-2 d-flex p-0">
            <ag-grid-angular style="width: 100%; height:280px" class="ag-theme-balham" (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()" [rowHeight]="rowHeight" [animateRows]="true"
                [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                [rowData]="rowData" [suppressCellSelection]="true" [pagination]="true"
                [suppressPaginationPanel]="true" [headerHeight]="40" [domLayout]="domLayout"
                [rowClassRules]="rowClassRules">
            </ag-grid-angular>
        </div>
        <div class="ppf-footer col-12 d-flex align-items-center flex-wrap p-2">
            <span class="col-lg-4 col-md-6 col-12 my-1"></span>
            <span class="col-lg-4 col-md-6 col-12 my-1"> </span>
            <span class="col-lg-2 col-md-4 col-12 my-1 text-end pe-4">Grand Total
                </span>
            <span class="col-lg-2 col-md-4 col-12 my-1">{{grandTotal}}</span>
        </div>
    </div>

    <div class="row m-3 mt-4 content-row mx-0">
        <div class="d-flex col-md-6 align-items-baseline ps-0" *ngIf=commntFlag>
            <label for="details" class="control-label col-md-3">Comment For Authorization Form(Supplier)</label>
            <textarea name="" id="details" cols="170" rows="3" class="form-control detail_area ms-3 pt-0" [(ngModel)]="comment"></textarea>
        </div>
    </div>
    <div class="row m-3 mt-4 content-row mx-0">
        <div class="d-flex col-md-6 align-items-baseline ps-0" *ngIf=rejectReasonFlag>
            <label for="details" class="control-label col-md-3">Reject Reason</label>
            <textarea name="" id="details" cols="170" rows="3" class="form-control detail_area ms-3 pt-0" [(ngModel)]="rejectreason"></textarea>
        </div>
    </div>
</div>
<div class="create_footer d-flex justify-content-end align-items-center ">
    <div (click)="onBack()" class="mx-3 text-primary link">BACK</div>
    <button class="cancel me-3 roboto-bold" (click)="reject()" *ngIf="rejectFlag"> REJECT </button>
    <button class="black-button me-3 roboto-medium" (click)="approve()" *ngIf="approveFlag">APPROVE</button>
</div>