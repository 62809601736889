<div class="d-flex justify-content-between align-items-center m-2 mx-3">
  <p class="page-title">Supplier Plant Maintenance</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>

<div class="alert alert-danger m-3" *ngIf="errorList.length > 0">
  <div *ngFor="let message of errorList" class="errormsg d-flex justify-content-between">
    <span class="message-text">{{ message }}</span>
    <button class="btn-close" (click)="close()"></button>
  </div>
</div>

<div class="alert alert-success m-3" *ngIf="successMessage !== ''">
  <div class="success d-flex justify-content-between">
    <span class="message-text">{{ successMessage }}</span>
    <button class="btn-close" (click)="close()"></button>
  </div>
</div>

<div class="vpacs-shadow m-2 p-3 d-flex flex-wrap mx-3">
  <div class="filter mx-1 d-flex col-12 mb-2">
    <div class="d-flex align-items-center col-lg-8 col-md-6 col-12">
      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label class="col-4">Supplier</label>
        <input type="text" [(ngModel)]="supplierCode" (keyup.enter)="getSupplierPlantInfo()"/>
      </div>
      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 ms-3">
        <label class="col-4">Specialist</label>
        <input type="text" [(ngModel)]="specialistCode" (keyup.enter)="getSupplierPlantInfo()"/>
      </div>
    </div>
    <div class="d-flex justify-content-end gap-4 col-lg-4 col-md-6 col-12">
      <button class="black-button" (click)="getSupplierPlantInfo()">
        SEARCH
      </button>
      <button class="black-button" (click)="importData()" [disabled]="!haveAddAccess">IMPORT DATA</button>
      <button class="black-button" (click)="downloadSupplierPlant()">
        EXPORT DATA
      </button>
    </div>
  </div>
  <div class="filter m-1 mt-3 d-flex">
    <label>Plant</label>
    <div class="d-flex flex-wrap ps-5 ms-4">
      <label class="checkcontainer " *ngFor="let plant of plants">
        <span>{{ plant.plant }}</span>
        <input type="checkbox" [(ngModel)]="plant.checked" (keyup.enter)="getSupplierPlantInfo()" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>

<div class="row m-3 content-row wrapper pb-2 ps-1">
  <table class="vpacs-shadow p-0 col-md-6" aria-describedby="supplier-plant">
    <tbody *ngIf="tableData?.length !== 0 && plantLength !== 0">
      <tr>
        <th id="sup-plant" colspan="1" class="sticky-col table2 w-10">Supplier Code</th>
        <th id="sup-plant" colspan="1" class="sticky-col table2 w-10">Supplier Short Name</th>
        <th id="sup-plant" colspan="1" class="sticky-col table2 w-10"></th>
        <th id="sup-plant" colspan="1" class="table2 w-20" *ngFor="let plant of checkedPlants">
          {{ plant?.plant }}
        </th>
      </tr>
      <ng-container *ngFor="let row of tableData">
        <tr>
          <td class="sticky-col table2 w-10">{{ row?.suppliercode }}</td>
          <td class="sticky-col table2 w-10">{{ row?.suppliername }}</td>
          <td class="sticky-col table2 p-0 w-10">
            <table class="border-0 w-100" aria-describedby="sup-plant">
              <th  class="d-none"></th>
              <tr>
                <td rowspan="3" class="table2">Toyota</td>
              </tr>
              <tr>
                <td colspan="1" class="table2">Ope</td>
              </tr>
              <tr>
                <td colspan="1" class="table2">Dev</td>
              </tr>
              <tr>
                <td colspan="2" class="table2">Supplier</td>
              </tr>
              <tr>
                <td colspan="2" class="table2">Calculation</td>
              </tr>
            </table>
          </td>
          <td class="table2 p-0 w-20" *ngFor="let plantDet of row?.plancodes; let i = index">
            <table class="border-0 w-100" aria-describedby="sup-plant">
              <th  class="d-none"></th>
              <tr>
                <td class="table2" (click)="updateData(row, plantDet?.plantcode, i)" *ngIf="haveAddAccess">
                  <a class="link">
                    {{ (checkSupplier(plantDet?.supplierinfo) && (plantDet?.supplierinfo?.opecode || plantDet?.supplierinfo?.opecode!== '')) ? plantDet?.supplierinfo?.opecode : '-'}}
                  </a>
                </td>
                <td class="table2" *ngIf="!haveAddAccess">
                  <span *ngIf="!haveAddAccess">
                    {{ (checkSupplier(plantDet?.supplierinfo) && (plantDet?.supplierinfo?.opecode || plantDet?.supplierinfo?.opecode!== '')) ? plantDet?.supplierinfo?.opecode : '-'}}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table2" (click)="updateData(row, plantDet?.plantcode, i)" *ngIf="haveAddAccess">
                  <a class="link">
                    {{ (checkSupplier(plantDet?.supplierinfo) && (plantDet?.supplierinfo?.devcode || plantDet?.supplierinfo?.devcode!== '')) ? plantDet?.supplierinfo?.devcode : '-'}}
                  </a>
                </td>
                <td class="table2" *ngIf="!haveAddAccess">
                  <span *ngIf="!haveAddAccess">
                    {{ (checkSupplier(plantDet?.supplierinfo) && (plantDet?.supplierinfo?.devcode || plantDet?.supplierinfo?.devcode!== '')) ? plantDet?.supplierinfo?.devcode : '-'}}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table2" (click)="updateData(row, plantDet?.plantcode, i)" *ngIf="haveAddAccess">
                  <a class="link">
                    {{ plantDet?.supplierinfo?.suppliercontact ?? '-' }}
                  </a>
                </td>
                <td class="table2" *ngIf="!haveAddAccess">
                  <span *ngIf="!haveAddAccess">{{ plantDet?.supplierinfo?.suppliercontact ?? '-' }}</span>
                </td>
              </tr>
              <tr>
                <td class="table2 d-flex justify-content-center p-1">
                  <button class="vpacs-btn-table mx-5" (click)="calculation(row)">
                    CALCULATION
                  </button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tbody
      class="text-center"
      *ngIf="tableData?.length === 0 || plantLength === 0"
    >
    <tr>
      <th id="sup-plant" colspan="1" class="table2 w-10">Supplier Code</th>
      <th id="sup-plant" colspan="1" class="table2 w-10">Supplier Short Name</th>
      <th id="sup-plant" colspan="1" class="table2 w-10"></th>
      <th id="sup-plant" colspan="1" class="table2 w-20" >
        Plant
      </th>
    </tr>
      <div class="p-3">
        <span class="text-danger">No Data Found</span>
      </div>
    </tbody>
  </table>
</div>

<app-pagination class="fixed-bottom"
  [cPage]="cPage"
  [tPage]="tPage"
  [rpPage]="rpPage"
  [totalRecords]="totalRecords"
  (changePaginationValues)="changePaginationValues($event)"
  *ngIf="totalRecords > 0"
></app-pagination>