<div class="popup-container"*ngIf="header === 'Update' || header === 'Add'">
    <div class="d-flex justify-content-between align-items-center header">
        <div class="page-title">{{dataStore.headerName}} Division </div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
      <div *ngFor="let message of errorList" class="errormsg">
        <div class="message-container">
          <span class="message-text">{{ message }}</span>
          <button type="button" class="btn-close" (click)="close()"></button>
        </div>
      </div>
    </div>
   
      <div class="p-2">
        <div class="col-12 mt-2" >
            <label class="col-2">Division<small>*</small></label>
            <input type="text" maxlength="2"  [(ngModel)]="newdiv" [ngClass]="{'error-border': divReq}" (keyup)="changediv()"/>
        </div>
        <div class="col-12 d-flex" >
            <label class="col-2 invisible"></label>
            <small *ngIf="divReq">
                Division code is required
            </small>
        </div>
     </div>
      <div class="foot">
        <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
        <button class="submit black-button me-3 roboto-bold" (click)="updatedelete()">{{dataStore.headerName}}</button>
      </div>
      <button class="close black-button" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div> 
  <div class="popup-container"*ngIf="header === 'Delete'">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete [{{newdiv}}]?</p>
    </div>
    <div mat-dialog-actions style="display:flex; justify-content:flex-end">
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
        <button class="black-button mx-3" (click)="delete()">Yes</button>
    </div>
</div>
<div class="popup-container"*ngIf="header === 'decisionDelete'">
  <h2 mat-dialog-title class="p-2 shadow-sm">Decision confirmation</h2>
  <div mat-dialog-content>
      <p class="p-2">Are you sure?</p>
  </div>
  <div mat-dialog-actions style="display:flex; justify-content:flex-end">
      <div mat-dialog-close class="text-primary mx-3 ">No</div>
      <button class="black-button mx-3" (click)="deleteDecision()">Yes</button>
  </div>
</div>
<div class="popup-container" *ngIf="header === 'SPDF'">
  <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
  <div mat-dialog-content>
    <p class="p-2">Are you sure? you want to delete the part number</p>
  </div>
  <div mat-dialog-actions style="display:flex; justify-content:flex-end">
    <div mat-dialog-close class="text-primary mx-3 ">Cancel</div>
    <button class="black-button mx-3" (click)="partDelete()">OK</button>
  </div>
</div>