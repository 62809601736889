<div class="wrapper bg-white">  
    <h2  class="p-4 shadow-sm">Update PPF Reason</h2>
    <div class="mat-typography content">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">INFORMATIONS</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Plant<span>{{details?.PLANT}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span>{{details?.PARTNUMBER}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Supplier<span>{{details?.SUPPLIER}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Fabricator<span>{{details?.FABCODE}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Package Start Date<span>{{details?.PSD}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0" *ngIf="reasonText">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">REJECT REASON</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <textarea disabled="true" rows="3" class="col-12">{{rejectReason}}</textarea>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel8 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">Reason</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel8.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-3 d-flex flex-wrap col-12" [formGroup]="updateForm">
                <div class="my-2 col-md-6 col-12">
                    <div class="d-flex align-items-center">
                        <label for="reasonwhat" class="col-3">Reason (What)</label>
                        <mat-select formControlName="reasonwhat" class="mat-sel pt-2 col-6" disableOptionCentering>
                            <mat-option *ngFor="let x of whatDropdown" [value]="x.CODE">{{x.CHECKITEM}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="d-flex my-3">
                        <span class="col-3"></span>
                        <input type="text" class="col-6" formControlName="reasonwhatcomment"/>
                    </div>
                </div>
                <div class="my-2 col-md-6 col-12">
                    <div class="d-flex align-items-center">
                        <label for="reasonwhy" class="col-3">Reason (Why)</label>
                        <mat-select formControlName="reasonwhy" class="mat-sel pt-2 col-6" disableOptionCentering>
                            <mat-option *ngFor="let x of whyDropdown" [value]="x.CODE">{{x.CHECKITEM}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="d-flex my-3">
                        <span class="col-3"></span>
                        <input type="text" class="col-6" formControlName="reasonwhycomment"/>
                    </div>
                </div>
                <div class="my-2 col-md-6 col-12">
                    <label for="pkgstartdate" class="col-3">Package Start Date</label>
                    <input type="date" class="col-6" id="pkgstartdate" formControlName="pkgstartdate"/>
                </div>
            </div>     
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex p-2">
    <div mat-dialog-close class="cancel">Cancel</div>
    <button class="black-button mx-3" (click)="routeToPPF()">Ok</button>
</div>
</div>
