<div class="wrapper mx-3">  
    <div class="page-title m-2">{{pageName}}</div>
    <div class="mat-typography content">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">PART INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span class="text-black">{{metaData.partNumber}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Plant<span class="text-black">{{metaData.plantName}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">PKG Start Date<span class="text-black">{{metaData.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Model<span class="text-black">{{metaData.modelCode}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">CONTAINER INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData.CONTCODE}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.CONTL | number: '1.2-4'}}/{{accData.CONTW | number: '1.2-4'}}/{{accData.CONTH | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData.CONTCOST | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData.CONTWEIGHT | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">QPC<span class="text-black">{{accData.QPC}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">DUNNAGE INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData.DUNCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.DUNL | number: '1.2-4'}}/{{accData.DUNW | number: '1.2-4'}}/{{accData.DUNH | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData.DUNCOST | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData.DUNWEIGHT | number: '1.2-4'}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">TOP CAP INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData.LIDCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.LIDL | number: '1.2-4'}}/{{accData.LIDW | number: '1.2-4'}}/{{accData.LIDH | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData.LIDCOST | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData.LIDWEIGHT | number: '1.2-4'}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">PALLET INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData.PLTCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.PLTL | number: '1.2-4'}}/{{accData.PLTW | number: '1.2-4'}}/{{accData.PLTH | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData.PLTCOST | number: '1.2-4'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData.PLTWEIGHT | number: '1.2-4'}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panelReject class="mat-elevation-z0" *ngIf="rejectReasonFlag">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">REJECT REASON</mat-panel-title>
                <mat-icon class="toggleIcon">{{panelReject.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="details-pane my-2 d-grid">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">
                    <textarea disabled>{{this.tableData.auth.REJECTREASON}}</textarea>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel8 class="mat-elevation-z0" *ngIf = "pageName === 'Date Start of Use' ">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold align-items-center">EXPENDABLE QUANTITY USED: Note cost per item used & quantity used daily</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel8.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <table class="tg" aria-describedby="test">
                <thead>
                <tr>
                    <th class="tg-i1q2" colspan="2"></th>
                    <th class="tg-i1q2" colspan="5">{{this.dateArray[0]}}</th>
                    <th class="tg-i1q2" colspan="5">{{this.dateArray[1]}}</th>
                    <th class="tg-i1q2" colspan="5">{{this.dateArray[2]}}</th>
                    <th class="tg-i1q2" colspan="5">{{this.dateArray[3]}}</th>
                    <th class="tg-i1q2"></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="tg-kusv">Package Type</td>
                    <td class="tg-kusv">Costs</td>
                    <td class="tg-kusv">MON</td>
                    <td class="tg-kusv">TUE</td>
                    <td class="tg-kusv">WED</td>
                    <td class="tg-kusv">THU</td>
                    <td class="tg-kusv">FRI</td>
                    <td class="tg-kusv">MON</td>
                    <td class="tg-kusv">TUE</td>
                    <td class="tg-kusv">WED</td>
                    <td class="tg-kusv">THU</td>
                    <td class="tg-kusv">FRI</td>
                    <td class="tg-kusv">MON</td>
                    <td class="tg-kusv">TUE</td>
                    <td class="tg-kusv">WED</td>
                    <td class="tg-kusv">THU</td>
                    <td class="tg-kusv">FRI</td>
                    <td class="tg-kusv">MON</td>
                    <td class="tg-kusv">TUE</td>
                    <td class="tg-kusv">WED</td>
                    <td class="tg-kusv">THU</td>
                    <td class="tg-kusv">FRI</td>
                    <td class="tg-kusv">TOTAL</td>
                </tr>
                <tr *ngFor="let row of rows;let i = index" [formGroup]="form">
                    <td class="tg-0pky" *ngFor="let col of columns;let j = index">
                        <ng-container *ngIf="j === 0 || j === 22">{{form.get('row'+i+'col'+j).value}}</ng-container>
                        <ng-container *ngIf="![22,0].includes(j)">
                            <input 
                                type="number" 
                                style="width: 50px;" 
                                [formControlName]="'row' + i + 'col' + j"
                                (change)="onInputChange(i,j,$event)"
                                [step]="j > 1 ? 1 : 0.01"
                            />
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td class="tg-0pky" colspan="22"></td>
                    <td class="tg-0pky">{{htmlGrandTotal}}</td>
                </tr>
                </tbody>
                </table>
            </div>     
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex">
    <div class="mx-3 cancel" (click)="navigatePrevious()">Cancel</div>
    <button class="black-button mx-3" (click)="onSubmit()" *ngIf="showSaveButton">Save</button>
</div>
</div>
