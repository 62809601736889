<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Select Leader</label>
        </div>
    </header>

    <div class="popup-contents">
        <div class="top-container">
            <div class="row mt-3 ms-3 me-3 heading-row">
                <div class="col-3 ps-0 pe-0">
                    <label>Model</label>
                    <input type="text" [(ngModel)]="model" readonly>
                </div>
                <div class="col-3  pe-0">
                    <label>Plant</label>
                    <input type="text" [(ngModel)]="plant" readonly>
                </div>
                <div class="col-3 pe-0">
                    <label>Part Number</label>
                    <input type="text" [(ngModel)]="part" readonly>
                </div>
                <div class="col-3 pe-0">
                    <label>Supplier</label>
                    <input type="text" [(ngModel)]="supplier" readonly>
                </div>
            </div>

            <div class="row mt-3 ms-3 me-3 heading-row">
                <div class="col-3 ps-0 pe-0">
                    <label>Package Start Date</label>
                    <input type="text" [(ngModel)]="pgkdate" readonly>
                </div>
                <div class="col-3" *ngIf="currLeaderVisible">
                    <label>Current Leader</label>
                    <input type="text" [(ngModel)]="currentLeader" readonly>
                </div>
                <div class="col-3 align-self-end" *ngIf="currLeaderVisible">
                    <button class="black-button me-3 roboto-bold" (click)="release()">Release Leader</button>
                </div>
            </div>
        </div>
    </div>


    <div class="vpacs-shadow m-3 ">
        <div class="grid-agg px-0">
            <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height: 200px"
                (gridReady)="onGridReady($event)" [animateRows]="true" [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs" [rowData]="displayRowData" [headerHeight]="headerHeight"
                [rowSelection]="rowSelection" [rowClassRules]="rowClassRules" [suppressCellSelection]="true">
            </ag-grid-angular>
        </div>
    </div>

</div>