import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-move-package',
  templateUrl: './move-package.component.html',
  styleUrls: ['./move-package.component.scss'],
})
export class MovePackageComponent implements OnInit {
  errorText="Something went wrong, Please contact System Admininstrator"
  sample = "$%'\{}*_"
  packgeArray:any
  qtyArray:any
  costArray:any
  submitted = false
  haveAccess = false;
  routeParams: any;
  ringiDropDown: any[] = [];
  reasonDropDown: any[] = [];
  moveForm = new FormGroup({
    ringi: new FormControl( '',[Validators.required]),
    reason: new FormControl('select',[Validators.required]),
    fromDate: new FormControl('',[Validators.required]),
    toDate: new FormControl('',[Validators.required]),
    package2: new FormControl(),
    container2: new FormControl(),
    container3: new FormControl(),
    container4: new FormControl({value : 0, disabled : true}),
    dunnage12: new FormControl(),
    dunnage13: new FormControl(),
    dunnage14: new FormControl({value : 0, disabled : true}),
    dunnage22: new FormControl(),
    dunnage23: new FormControl(),
    dunnage24: new FormControl({value : 0, disabled : true}),
    label2: new FormControl('',[Validators.max(99999),   ]),
    label3: new FormControl('',[Validators.max(99999999),   ]),
    label4: new FormControl({value : 0, disabled : true}),
    other4: new FormControl(0),
    grandTotal: new FormControl({value : 0, disabled : true}),
    commentSupplier: new FormControl(''),
    internal : new FormControl(''),
  })

  loading = true;
  response: any;
  userInfo: any;
  package1: any;
  container1: any;
  dunnage11: any;
  dunnage21: any;
  top1: any;
  pallet1: any;
  stretch1: any;
  kanban1: any;
  otherpkg1: any;
  errorList:any

  constructor(
    private readonly state: ExportStateService,
    private readonly route: ActivatedRoute,
    private readonly service: QuantityControlService,
    public loader: NgxUiLoaderService,
    protected router : Router
  ) {}

  ngOnInit(): void {
    this.loader.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {          
          this.haveAccess = res.role?.p_qtyview;
          this.userInfo = res.info
          this.haveAccess && this.getRouteValues();
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false;
      },
    });
  }
  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.apiCall();
      }
    });
  }

  ipChange() {
    this.checkInput()
    this.changeTotal();
  }


  calcPackage() {
    const qty = this.response && Number(this.response?.AuthDetail[0]?.qty);
    this.changeContQty(qty);
  }

  changeContQty(qty: any) {
    
    this.moveForm.patchValue({
      container2: Number(qty),
      dunnage12: Math.ceil(Number(qty) * Number(this.moveForm.get('dunnage12')?.value || 0)),
      dunnage22: Math.ceil(Number(qty) * Number(this.moveForm.get('dunnage22')?.value || 0)),
      label2: Math.ceil(Number(qty) * Number(this.moveForm.get('label2')?.value || 0)) as any,
    });
    this.changeTotal();
  }

  changeTotal() {
    this.moveForm.patchValue({
      container4: Number(this.moveForm.get('container2')?.value || 0) * Number(this.moveForm.get('container3')?.value || 0),
      dunnage14: Number(this.moveForm.get('dunnage12')?.value || 0) * Number(this.moveForm.get('dunnage13')?.value || 0),
      dunnage24: Number(this.moveForm.get('dunnage22')?.value || 0) * Number(this.moveForm.get('dunnage23')?.value || 0),
      label4: Number(this.moveForm.get('label2')?.value || 0) * Number(this.moveForm.get('label3')?.value || 0),
    })
    this.calcGrandTotal();
  }

  calcGrandTotal() {
    this.moveForm.patchValue({
      grandTotal: (this.moveForm.get('container4')?.value || 0) + (this.moveForm.get('dunnage14')?.value || 0) + (this.moveForm.get('dunnage24')?.value || 0) + (this.moveForm.get('label4')?.value || 0)
    })
  }


  apiCall() {
    const body = {
      fplant: this.routeParams.fplant,
      fsupplier: this.routeParams.fsupplier,
      container: this.routeParams.container,
      dunnage1: this.routeParams.dunnage1,
      dunnage2: this.routeParams.dunnage2,
      fyear: this.routeParams.fyear,
      fweek: this.routeParams.fweek,
      tplant: this.routeParams.tplant,
      tsupplier: this.routeParams.tsupplier,
      tyear: this.routeParams.tyear,
      tweek: this.routeParams.tweek,
      qtytype: this.routeParams.qtytype,
      Auth: this.routeParams.auth
    }
    this.loader.start();
    this.service.movePackageOnload(body).subscribe({
      next: (res: any) => {
        if (res && !res.errMsg) {
          this.reasonDropDown = res.ReasonData ? res.ReasonData : [];
          this.ringiDropDown = res.RingiDropdown ? res.RingiDropdown : [];
          this.response = res;
          this.setFormValues(res?.AuthDetail);
          this.showMessage([])  
        }else if(res.errMsg){
          this.showMessage( [res.errMsg]);
        }
        this.loader.stop();
      },
      error: (err: any) => {
        this.showMessage([err]);
        this.showMessage([this.errorText]);
        this.loader.stop();
      }
    })
  }
  setFormValues(data:any) {
    this.package1 = data[0]?.packagecode
    this.container1 = data[1]?.packagecode
    this.dunnage11 = data[2]?.packagecode
    this.dunnage21 = data[3]?.packagecode
    this.top1 = data[4]?.packagecode
    this.pallet1 = data[5]?.packagecode
    this.stretch1 = data[6]?.packagecode
    this.kanban1 = data[7]?.packagecode
    this.otherpkg1 = data[8]?.packagecode
    this.moveForm.patchValue({
      ringi: '',
      reason: "",
      package2: data[0] && Number(data[0].qty),
      container2: data[1] && Number(data[1].qty),
      dunnage12: data[2] && Number(data[2].qty),
      dunnage22: data[3] && Number(data[3].qty),
      label2: data.hidLabelQPP && Number(data.hidLabelQPP),
      container3: data[1] && Number(data[1].unitcost),
      dunnage13: data[2] && Number(data[2].unitcost),
      dunnage23: data[3] && Number(data[3].unitcost),
      label3: data.txtLabelCPP && Number(data.txtLabelCPP),
      container4: Number(this.moveForm.get('container2')?.value || 0) * Number(this.moveForm.get('container3')?.value || 0),
      dunnage14: Number(this.moveForm.get('dunnage12')?.value || 0) * Number(this.moveForm.get('dunnage13')?.value || 0),
      dunnage24: Number(this.moveForm.get('dunnage22')?.value || 0) * Number(this.moveForm.get('dunnage23')?.value || 0),
      label4: Number(this.moveForm.get('label2')?.value) * Number(this.moveForm.get('label3')?.value)
    });
    this.changeTotal();
    this.checkInput()

  }

  showMessage(message: string[]) {
    this.errorList = message
    setTimeout(() => {
      this.errorList = []
    }, 8000);
  }

  get isValid() {
    return this.moveForm.controls
  }


  onSubmit() {
    this.submitted=true
    const body = this.generatePayload()
    let flag=true
    for (const index of this.sample ) {
      if (this.moveForm.controls['commentSupplier']?.value?.includes(index)) {
        flag =false
        break;
      }
    }
    for (const index of this.sample ) {
      if (this.moveForm.controls['internal']?.value?.includes(index)) {
        flag =false
        break;
      }
    }
    if(this.moveForm.valid && flag){
      this.actualApiCall(body)
  }else {
    this.popupMessage()
  }
}

generatePayload(){
  const halfPayload=this.generateHalfPayload()
  return {
    ...halfPayload,
    "ReasonCode": this.moveForm.get('reason')?.value ?? '', 
    "LabelQty": this.moveForm.get('label2')?.value ?? '',
    "LabelCPP": this.moveForm.get('label3')?.value ?? '',
    "OtherTotal": isNaN(Number(this.moveForm.get('other4')?.value)) ? 0 : Number(this.moveForm.get('other4')?.value),
    "Comment1": this.moveForm.get('commentSupplier')?.value ?? '',
    "Comment2": this.moveForm.get('internal')?.value ?? '',
    "Total": isNaN( Number(this.moveForm.get('grandTotal')?.value))? 0 : Number(this.moveForm.get('grandTotal')?.value),
    "Dun1Qty": this.moveForm.get('dunnage12')?.value ?? '',
    "Dun2Qty": this.moveForm.get('dunnage22')?.value ?? '',
    "ContCPP": this.moveForm.get('container3')?.value ?? '',
    "Dun1CPP": this.moveForm.get('dunnage13')?.value ?? '',
    "Dun2CPP": this.moveForm.get('dunnage23')?.value ?? '',
    "Year": isNaN(Number(this.routeParams.fyear)) ? 0 : Number(this.routeParams.fyear),                     
     "Week": isNaN( Number(this.routeParams.fweek))? 0 : Number(this.routeParams.fweek),
  };
}

generateHalfPayload(){
  return {
    "FromPlantCode": this.routeParams.fplant ?? '',
    "FromSupplierCode": this.response?.FromSupplierName[0]?.shortname ?? '' ,
    "ContainerCode": this.routeParams.container ?? '',
    "Dunnage1Code": this.routeParams.dunnage ?? '', 
    "Dunnage2Code": this.routeParams.dunnage2 ?? '',
    "PlantCode": this.userInfo?.plantcode ?? '',
    "ToPlantCode": this.routeParams.tplant ?? '',
    "ToSupplierCode": this.response?.ToSupplierName[0]?.shortname ?? '',
    "qty": this.moveForm.get('package2')?.value ?? '',
    "ContQty": this.moveForm.get('container2')?.value ?? '',
    "UserSpecialistCode": this.userInfo?.specialistcode ?? '',
    "Mode": isNaN( Number(this.response.Mode))? 0 : Number(this.response.Mode),
    "ToDate": this.moveForm.get('toDate')?.value ?? '',
    "FromDate": this.moveForm.get('fromDate')?.value ?? '',
    "RingiNumber": this.moveForm.get('ringi')?.value ?? '',
  
  }
}

actualApiCall(body:any){
  this.loader.start();
  this.service.movePackageAuthorize(body).subscribe({
    next: (_res: any) => {
      this.loader.stop();
      if(_res){
        if(_res.status === 0){
        this.showMessage(_res.message.split('\n'))
        }else{
          this.onBack();
        }
      }else{
        this.showMessage([this.errorText]);
      }
    },
    error: (_err: any) => {
      this.showMessage([this.errorText]);
      this.loader.stop();
    }
  })
}

popupMessage(){

  const arr = []
  for (const index of this.sample ) {
    if (this.moveForm.controls['commentSupplier']?.value?.includes(index)) {
      arr.push(`Data Format Error [Comment for Supplier] has prohibited character.["$%'\{}*_]`)
      break;
    }
  }
  for (const index of this.sample ) {
    if (this.moveForm.controls['internal']?.value?.includes(index)) {
      arr.push(`Data Format Error [Internal use only] has prohibited character.["$%'\{}*_]`)
      break;
    }
  }

  if (this.moveForm.controls['ringi']?.invalid) {
    arr.push('Required Item Error [Ringi #] is not selected')
  }
  if (this.moveForm.controls['reason']?.invalid) {
    arr.push('Required Item Error [Reason] is not selected.')
  }
  if (this.moveForm.controls['fromDate']?.invalid) {
    arr.push('Required Item Error [fromDate] is not selected.')
  }
  if (this.moveForm.controls['toDate']?.invalid) {
    arr.push('Required Item Error [toDate] is not selected.')
  }
  this.showMessage(arr)
}


checkInput() {
  this.packgeArray = [
    { setA: 'container2', setB: 'container3', value: this.container1 },
    { setA: 'dunnage12', setB: 'dunnage13', value: this.dunnage11 },
    { setA: 'dunnage22', setB: 'dunnage23', value: this.dunnage21 },
  ]

  this.qtyArray = [
    this.moveForm.controls['container2'].value,
    this.moveForm.controls['dunnage12'].value,
    this.moveForm.controls['dunnage22'].value,
  ]

  this.costArray = [
    this.moveForm.controls['container3'].value,
    this.moveForm.controls['dunnage13'].value,
    this.moveForm.controls['dunnage23'].value,
  ]

  this.packgeArray.map((data: any) => {
    if (data.value && data.value.length > 0) {
      this.checkRequired(data.setA, data.setB)
    }
  })
}


checkRequired(qty: any, unit: any) {
  this.moveForm.get(qty)?.setValidators([Validators.required, Validators.max(99999), this.validate]);
  this.moveForm.get(qty)?.updateValueAndValidity();

  this.moveForm.get(unit)?.setValidators([Validators.required, Validators.max(99999999), this.validate]);
  this.moveForm.get(unit)?.updateValueAndValidity();
}

validate(control: AbstractControl): { [key: string]: any } | null {
  
  if (control.value === null) {
    return null;
  }
  if (isNaN(control.value)) {
    return { 'pattern': true };
  }
  return null
}


  onBack(){
    let uri = ''
    if (this.router.url.includes('quantityConrtol')) {
      uri = '/quantityConrtol'
    } 
    else if (this.router.url.includes('tracking-list')) {
      uri = '/tracking-list'
    }
    else if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    }
    else {
     uri='/quantityConrtol'
    }
    this.router.navigateByUrl(uri);
  }

  help(){
    this.router.navigate(['./master-help',4,"Quantity Control",72])
  }
}
