import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ImportCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/import-radio-btn-cell-render';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-new-part-confirmation',
  templateUrl: './new-part-confirmation.component.html',
  styleUrls: ['./new-part-confirmation.component.scss']
})
export class NewPartConfirmationComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 25;
  isAccessible = false;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  paginationPageSize = 50;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  rowCount = 0;
  row: any;
  index: any;
  item: any;
  partInfo: any;
  partInfoContents: any;
  selectedItemId: number | null = null;
  tables: any[] = [
    {

      Total: [
        {
          column1: 'Model Code', column2: 'TEST', column3: 'Total New Part', column4: 'TEST'
        }
      ],
    }
  ]
  importCode: any;
  partnumber: any;
  value: any;
  value2: any;
  value3: any;
  gridColumnApi: any;
  onSortChanged: any;
  result: any;
  res: any;
  currSupplierCode: any;
  psd: any;
  partNumber: any;
  line: any;
  selectedRowData: any;
  IdValue: any;
  supplierCode: any;
  supplierName: any;
  routeParams: any;
  viewAccess: any;
  errorMessage !: { errorCode: string; childErrorCode: string; };
  childHeader = 'child-header-color';
  childHeaderNoBorder = 'child-header-color no-border';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: ModelListService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialog: MatDialog,
    public state : ExportStateService
    ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }

  }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.checkAccess();
      }
    })

    this.partInfo = [
      'Model Code', 'Total New Part'
    ]
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.apiCall()
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit("autoWidth");
  }

  navigateToModelList() {
    this.router.navigate(['/model-list'])
  }

  checkAccess(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.viewAccess = res.role?.p_trackingview;
        if (this.viewAccess) {
          this.getColumnDefs();
          this.apiCall();
        } else {
          this.errorMessage = {errorCode : `API-403`,childErrorCode : '400'};
        }
      },
      error: (_err: any) => {
        this.viewAccess = false;
        this.errorMessage = {errorCode : `API-099`,childErrorCode : '400'};
      },
    });
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: 'ECI #', field: 'AUTHREAD', minWidth: 80, headerClass: this.childHeader,
        children: [
          { headerName: "", field: 'eci', sortable: false, unSortIcon: false, headerClass: this.childHeaderNoBorder, minWidth: 80 }
        ]
      },
      {
        headerName: 'current supplier', field: '', minWidth: 120, headerClass: this.childHeader,
        children: [
          { headerName: 'Code', field: 'oldSupCode', minWidth: 70, headerClass: this.childHeader },
          { headerName: 'name', field: 'oldSupName', minWidth: 70, headerClass: this.childHeader },
        ]
      },
      {
        headerName: 'new supplier', field: '', minWidth: 120, headerClass: this.childHeader,
        children: [
          { headerName: 'Code', field: 'newSupCode', minWidth: 70, headerClass: this.childHeader },
          { headerName: 'name', field: 'newSupName', minWidth: 70, headerClass: this.childHeader },
        ]
      },
      {
        headerName: 'part #', field: '', minWidth: 100, headerClass: this.childHeader,
        children: [
          { headerName: "", field: 'partNumber', sortable: false, unSortIcon: false, headerClass: this.childHeaderNoBorder, minWidth: 100 }
        ]
      },
      {
        headerName: 'replace supplier', field: 'resupplier', minWidth: 120, headerClass: this.childHeader,
        children: [
          { headerName: "", field: 'replaceSupplier', floatingFilter: false, sortable: false, unSortIcon: false, headerClass: this.childHeaderNoBorder, minWidth: 120, cellRendererFramework: ImportCellRendererComponent, }
        ]
      },
      {
        headerName: 'replace part', field: 'replacePart', minWidth: 120, headerClass: this.childHeader, cellRendererFramework: ImportCellRendererComponent,
        children: [
          { headerName: "", field: 'replacePart', sortable: false, floatingFilter: false, unSortIcon: false, headerClass: this.childHeaderNoBorder, minWidth: 120, cellRendererFramework: ImportCellRendererComponent, }
        ]
      },
      {
        headerName: 'add', field: 'add', minWidth: 80, headerClass: this.childHeader, cellRendererFramework: ImportCellRendererComponent,
        children: [
          { headerName: "", field: 'add', sortable: false, unSortIcon: false, floatingFilter: false, cellRendererFramework: ImportCellRendererComponent, headerClass: this.childHeaderNoBorder, minWidth: 80, }
        ]
      },
      {
        headerName: 'ignore', field: 'ignore', minWidth: 80, headerClass: this.childHeader, cellRendererFramework: ImportCellRendererComponent,
        children: [
          { headerName: "", field: 'ignore', sortable: false, unSortIcon: false, floatingFilter: false, cellRendererFramework: ImportCellRendererComponent, headerClass: this.childHeaderNoBorder, minWidth: 80 }
        ]
      },
    ];
  }
  apiCall() {
    const newpartObject = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode,
      "limit": this.rpPage,
      "page": this.cPage,
    }
    this.spinnerService.start();
    this.gridApi?.showLoadingOverlay()
    this.service.newpartOnload(newpartObject).subscribe({
      next: (res: any) => {
        const result = res.result.response;
        const transformedData = this.getTransformedData(result);
        this.displayRowData = transformedData;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = this.displayRowData.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.totalRecords = this.displayRowData.length;
        this.rowCount = 0;
        this.spinnerService.stop();
      },
    })
  }

  getTransformedData(result:any){
    return result.map((item: any) => {
      const replaceSupplier = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidPSD_"));
      const replaceSupplierFlag = replaceSupplier.fieldText ? 1 : 0;
      const supplierData = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidCrrSupplierCode_"));
      const supplierCode = supplierData?.fieldValue ? supplierData.fieldValue : '-';
      const supplierNameData = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidCrrSupplierName_"));
      const supplierName = supplierNameData?.fieldValue ? supplierNameData.fieldValue : '-';
      const partData = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidPartNumber_"));
      const partNumber = partData?.fieldValue ? partData.fieldValue : '-';
      const psdData = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidPSD_"));
      const psd = psdData?.fieldValue ? psdData.fieldValue : '-';
      const ecidata = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("eci_"));
      const eci = ecidata?.fieldValue ? ecidata.fieldValue : '-';
      const lineNumberData = item.data.find((dataItem: any) => dataItem.fieldID.startsWith("hidLineNumber_"));
      const lineNumber = lineNumberData?.fieldValue ? lineNumberData.fieldValue : '-';
      return {
        id: item.id,
        newSupCode: item.newSupCode,
        newSupName: item.newSupName,
        rank: item.rank,
        oldSupCode: supplierCode,
        oldSupName: supplierName,
        replaceSupplier: 'replaceSupplier',
        replaceSupplierFlag: replaceSupplierFlag ? 1 : 0,
        replacePart: 'replacePart',
        add: 'add',
        ignore: 'ignore',
        partNumber: partNumber,
        psd: psd,
        eci: eci,
        lineNumber: lineNumber,
        selectedValue: 'ignore'
      }
    })

  }

  getRdoName(item: any) {
    let returnValue: any = ''
    if (item.ignore === 1) {
      returnValue = 'Ignore';
    } else if (item.add === 1) {
      returnValue = 'Add';
    } else if (item.replacePart === 1) {
      returnValue = 'ReplacePart'
    } else if (item.replaceSupplier) {
      returnValue = 'ReplaceSupplier'
    }
    return returnValue;
  }

  handleOkClick() {
    const rowData: any[] = []
    this.gridApi.forEachNode((node: any) => {
      rowData.push(node.data);
    });
    const params = ['add', 'ignore', 'replaceSupplier', 'replacePart'];
    rowData.forEach((item: any) => {
      params.forEach((param: any) => {
        item[param] = item.selectedValue === param ? 1 : 0
      });
      delete item.selectedValue;
    })
    const body: any = {};
    rowData.forEach((item: any, index: any) => {
      body[`hidPartNumber_${index}`] = item.partNumber;
      body[`hidPSD_${index}`] = item.psd;
      body[`hidLineNumber_${index}`] = item.lineNumber;
      body[`rdoName_${index}`] = this.getRdoName(item);
      body[`hidCrrSupplierCode_${index}`] = item.oldSupCode;
      body.ID = this.routeParams.id;
      body.PlantCode = this.routeParams.plantcode;
      body.ModelCode = this.routeParams.modelcode;
    })
    const replace = rowData.filter((x: any) => x.replacePart === 1)
    sessionStorage.setItem('replacePart', JSON.stringify(replace));
    this.spinnerService.start();
    this.service.newPartBtn(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          const { ID, ModelCode, PlantCode } = res.response
          if (res.redirect === 'DeletePartConfirmation') {
            this.router.navigate([`/model-list/delete-part/${ID}/${ModelCode}/${PlantCode}`]);
          } else if (res.redirect === 'ReplacePartConfirmation') {
            this.router.navigate([`/model-list/replace-part/${ID}/${ModelCode}/${PlantCode}`]);
          } else if (res.redirect === 'ImpPartConfirm') {
            this.router.navigate([`/model-list/confirm-import/${ID}/${ModelCode}/${PlantCode}`]);
          }
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    })
  }
}
