<div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Temporary Container</div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
    </div>
  </div>
<div class="mat-container m-3">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel1 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">PART INFORMATION</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel1.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>

            <div class="row row-cols-7 m-2 heading-row">
                <div class="col"><label>Plant</label></div>
                <div class="col"><label>Family Code</label></div>
                <div class="col"><label>Model Code</label></div>
                <div class="col"><label>Part Number</label></div>
                <div class="col"><label>Supplier</label></div>
                <div class="col"><label>Status</label></div>
                <div class="col"><label>Image</label></div>
            </div>

            <div class="row row-cols-7 m-2 content-row">
                <div class="col"><label>{{backupPart?.plantCode}}</label></div>
                <div class="col"><label>{{backupPart?.familyCode}}</label></div>
                <div class="col"><label>{{backupPart?.modelCode}}</label></div>
                <div class="col"><label>{{backupPart?.partNumber}}</label></div>
                <div class="col"><label>{{backupPart?.supplier}}</label></div>
                <div class="col"><label>{{getResult(result?.approvalstatus)}}</label></div>
                <div class="col"><img id="partImg" alt= "" src="{{convertedImg}}" /></div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">BACKUP PACKAGE SPECIFICATION</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>

            <div class="col-12 m-2 content-row">
                <label> Container </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>

                <div class="col"><label>QPC</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{result?.contcode}}</label></div>
                <div class="col">
                    <label class="col-4">{{result?.contl}}</label>
                    <label class="col-4">{{result?.contw}}</label>
                    <label class="col-4">{{result?.conth}}</label>
                </div>
                <div class="col-2"><label>{{result?.contweight}}</label></div>
                <div class="col-3"><label>{{result?.contprice}}</label></div>
                <div class="col"><label>{{result?.qpc}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Dunnage </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
            </div>

            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{result?.duncode}}</label></div>
                <div class="col">
                    <label class="col-4">{{result?.dunl}}</label>
                    <label class="col-4">{{result?.dunw}}</label>
                    <label class="col-4">{{result?.dunh}}</label>
                </div>
                <div class="col-2"><label>{{result?.dunweight}}</label></div>
                <div class="col-3"><label>{{result?.dunprice}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Top Cap </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{result?.lidcode}}</label></div>
                <div class="col">
                    <label class="col-4">{{result?.lidl}}</label>
                    <label class="col-4">{{result?.lidw}}</label>
                    <label class="col-4">{{result?.lidh}}</label>
                </div>
                <div class="col-2"><label>{{result?.lidweight}}</label></div>
                <div class="col-3"><label>{{result?.lidprice}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Pallet </label>
            </div>

            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
                <div class="col"><label>Container Gross(Include Parts)</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{result?.pltcode}}</label></div>
                <div class="col">
                    <label class="col-4">{{result?.pltl}}</label>
                    <label class="col-4">{{result?.pltw}}</label>
                    <label class="col-4">{{result?.plth}}</label>
                </div>
                <div class="col-2"><label>{{result?.pltweight}}</label></div>
                <div class="col-3"><label>{{result?.pltprice}}</label></div>
                <div class="col"><label></label></div>
            </div>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">PPF SPECIFICATION</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>

            <div class="col-12 m-2 content-row">
                <label> Container </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
                <div class="col"><label>QPC</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{ppfResult?.containercode}}</label></div>
                <div class="col">
                    <label class="col-4">{{ppfResult?.conl}}</label>
                    <label class="col-4">{{ppfResult?.conw}}</label>
                    <label class="col-4">{{ppfResult?.conh}}</label>
                </div>
                <div class="col-2"><label>{{ppfResult?.conweight}}</label></div>
                <div class="col-3"><label>{{ppfResult?.concpp}}</label></div>
                <div class="col"><label>{{ppfResult?.qpc}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Dunnage </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{ppfResult?.dunnage1code}}</label></div>
                <div class="col">
                    <label class="col-4">{{ppfResult?.dunl}}</label>
                    <label class="col-4">{{ppfResult?.dunw}}</label>
                    <label class="col-4">{{ppfResult?.dunh}}</label>
                </div>
                <div class="col-2"><label>{{ppfResult?.dunweight}}</label></div>
                <div class="col-3"><label>{{ppfResult?.duncpp}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Top Cap </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{ppfResult?.topcapcode}}</label></div>
                <div class="col">
                    <label class="col-4">{{ppfResult?.lidl}}</label>
                    <label class="col-4">{{ppfResult?.lidw}}</label>
                    <label class="col-4">{{ppfResult?.lidh}}</label>
                </div>
                <div class="col-2"><label>{{ppfResult?.lidweight}}</label></div>
                <div class="col-3"><label>{{ppfResult?.lidcpp}}</label></div>
            </div>
            <div class="col-12 m-2 content-row">
                <label> Pallet </label>
            </div>
            <div class="row row-cols-5 mt-2 mx-2 heading-row">
                <div class="col-2"><label>Package Code</label></div>
                <div class="col"><label>LWH(Inch)</label></div>
                <div class="col-2"><label>Weight(lb)</label></div>
                <div class="col-3"><label>Estimated Cost/Package Piece$(Us)</label></div>
                <div class="col"><label>Container Gross(Include Parts)</label></div>
            </div>
            <div class="row row-cols-5 mt-1 mb-2 mx-2 content-row">
                <div class="col-2"><label>{{ppfResult?.palletcode}}</label></div>
                <div class="col">
                    <label class="col-4">{{ppfResult?.pltl}}</label>
                    <label class="col-4">{{ppfResult?.pltw}}</label>
                    <label class="col-4">{{ppfResult?.plth}}</label>
                </div>
                <div class="col-2"><label>{{ppfResult?.pltweight}}</label></div>
                <div class="col-3"><label>{{ppfResult?.pltcpp}}</label></div>
                <div class="col"><label></label></div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
