import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { TrackingListBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/tracking-list-btn-cell-renderer';
import { formatDate } from '@angular/common';
import { ConstantsService } from 'src/app/services/constant.service';


@Component({
  selector: 'app-hazmat-approval',
  templateUrl: './hazmat-approval.component.html',
  styleUrls: ['./hazmat-approval.component.scss']
})
export class HazmatApprovalComponent implements OnInit {
  leaderPlant: any[] = [];
  leaderPlantContents: any[] = [];
  qpc: any[] = [];
  qpcContents: any[] = [];
  work: any[] = [];
  workContents: any[] = [];
  radioVal: any;
  radioValWork = 2;
  columnDefs: any[] = [];
  defaultColDef: any;
  gridApi: any;
  rowClassRules: any;
  displayRowData: any[] = []
  columnDefs1: any[] = [];
  defaultColDef1: any;
  gridApi1: any;
  displayRowData1: any[] = []
  public rowSelection: 'single' | 'multiple' = 'multiple';
  paramData: any;
  userInfo: any;
  roleInfo: any;
  details: any;
  dateSentVal: any;
  crrStep: any;
  stepStatus: any;
  stepStatusArray: any[] = [];
  leaderFlg: any;
  displayFlg: any;
  flagDisplay: any;
  urlVer: any;
  showWorkTable = false;
  rdoContFabWorkChecked = false;
  rdoSupWorkChecked = true;
  rdoDun1FabWorkChecked = false;
  rdoContFabWorkDisabled = true;
  rdoSupWorkDisabled = true;
  rdoDun1FabWorkDisabled = true;
  trSupplierConfirmDisplay = false;
  hideControlbtnRejectBM = false;
  hideControlbtnRejectSup = false;
  hideControlbtnReject = false;
  hideControlbtnApprove = false;
  hideControlbtnSend = false;
  btnSendDisabled = false;
  tdSharePlant: any;
  hideControltblReqAsFinal = false;
  isConceptCompareDisabled = false;
  taNoteValue: any;
  taIntComment: any;
  hazmatComment='';
  taExtComment: any;
  taIntCommentDisable = false;
  taExtCommentDisable = false;
  tdDock1: any;
  tdCrrDept1: any;
  tdDock2: any;
  tdCrrDept2: any;
  tdDock3: any;
  tdCrrDept3: any;
  tdDock4: any;
  tdCrrDept4: any;
  tdDock5: any;
  tdCrrDept5: any;
  dvDock: any;
  dvSpec: any;
  alDock: any;
  defaultApprover: any;
  rdoYesDisabled = false;
  rdoNoDisabled = false;
  rdoYesChecked = false;
  rdoNoChecked = false;
  rdoTrialYesChecked = false
  rdoTrialYesDisabled = false
  rdoTrialNoChecked = false
  rdoTrialNoDisabled = false
  rdoAsFinalChecked = false
  rdoAsFinalDisabled = false
  reqChangesChkList = [
    { code: 0, description: 'Container Type', checked: false },
    { code: 1, description: 'Qty. per Container', checked: false },
    { code: 2, description: 'Container Size', checked: false },
    { code: 3, description: 'Dunnage', checked: false },
    { code: 4, description: 'Part Orientation', checked: false },
    { code: 5, description: 'Other', checked: false },
  ]
  reasonReqArr = [
    { code: 0, description: 'Safety', checked: false },
    { code: 1, description: 'Quality', checked: false },
    { code: 2, description: 'Workability', checked: false },
    { code: 3, description: 'Over Weight', checked: false },
    { code: 4, description: 'Poor Efficiency', checked: false },
    { code: 5, description: 'Non Standard', checked: false },
    { code: 6, description: 'Other', checked: false },
  ]
  txtParentPartDisabled = false
  taTriFinalReasonDisabled = false
  tblTrialDisplay = false
  taTriFinalReason: any
  tblProposalDisplay = false;
  chkSelDock1Checked = false;
  chkSelDock1Disabled = false;
  selDock1Disabled = false;
  btnselDock1Disabled = false;
  chkSelDock2Checked = false;
  chkSelDock2Disabled = false;
  selDock2Disabled = false;
  btnselDock2Disabled = false;
  chkSelDock3Checked = false;
  chkSelDock3Disabled = false;
  selDock3Disabled = false;
  btnselDock3Disabled = false;
  chkSelDock4Checked = false;
  chkSelDock4Disabled = false;
  selDock4Disabled = false;
  btnselDock4Disabled = false;
  chkSelDock5Checked = false;
  chkSelDock5Disabled = false;
  selDock5Disabled = false;
  btnselDock5Disabled = false;
  selectDvSpec1: any;
  chkSelDock1: any;
  selDock1: any;
  selectdvSpec2: any;
  chkSelDock2 = false;
  selDock2: any;
  selectDvSpec3: any;
  chkSelDock3 = false;
  selDock3: any;
  selectDvSpec4: any;
  chkSelDock4 = false;
  selDock4: any;
  selectDvSpec5: any;
  chkSelDock5 = false;
  selDock5: any;
  sharePlants: any;
  dvChld: any;
  tdSharePlantOK: any;
  tdSharePlantNG: any;
  btnSharePlantsDisabled = false;
  btnParentPartDisabled = false
  chkReqAsFinal = false;
  taFinalReason: any;
  chkReqAsFinalDisabled = false;
  taFinalReasonDisabled = false;
  spCode: any;
  errorMessage:any = null;
  fileName: any;
  trial: any[] = [];
  taRequestChanges: any;
  taReasonRequested: any;
  cblRequested: any
  cblReason: any
  taRequestChangesDisabled = false
  taReasonRequestedDisabled = false
  tblRequestReasonDisplay = false
  reqList = [
    { "Value": 'Container Type', "Selected": false },
    { "Value": 'Qty. per Container', "Selected": false },
    { "Value": 'Container Size', "Selected": false },
    { "Value": 'Dunnage', "Selected": false },
    { "Value": 'Part Orientation', "Selected": false },
    { "Value": 'other', "Selected": false },
  ];

  resList = [
    { "Value": 'Safety', "Selected": false },
    { "Value": 'Quality', "Selected": false },
    { "Value": 'Workability', "Selected": false },
    { "Value": 'Over Weight', "Selected": false },
    { "Value": 'Poor Efficiency', "Selected": false },
    { "Value": 'Non Standard', "Selected": false },
    { "Value": 'Other', "Selected": false },
  ]
  successMessage = "";
  paramHazmatData: any;
  previousUrl = "";
  currentUrl = "";
  currentStep: any
  currentHazmatStep: any;
  urlCheck = '/tracking-list';
  urlHazmat = '/hazmat-approve-list'
  err = '099  - 001 :Runtime Error : Contact a System Administrator.'

  constructor(
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly trackingListService: TrackingListService,
    private readonly constants: ConstantsService
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.currentStep = sessionStorage.getItem('currentStep');
    this.currentHazmatStep = sessionStorage.getItem('currentHazmatStep');
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info
        this.roleInfo = res?.role
        this.spCode = res?.info?.specialistcode;

        if (res) {
          if (this.router.url.includes(this.urlCheck)) {
            this.paramData = JSON.parse(sessionStorage.getItem('hazmatApprove') || '{}');
            this.siDeptApprDetails();
          }
          else {
            this.paramHazmatData = JSON.parse(sessionStorage.getItem('hazmatApprovementList') || '{}');
            this.siDeptApprDetails();
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    })
    this.leaderPlant = [
      'Leader Plant', 'Plant', 'Dock', 'Model', 'Supplier', 'Part Number', 'Specialist', 'Date sent'
    ]

    this.qpc = [
      'QPC', 'Full cost. Weight'
    ]

    this.work = [
      'Supplier', 'Cont Fab', 'Dun1 Fab'
    ]
    this.trial = ['Trial', 'Sibling Part', 'Reason']
    this.columnDefs = [
      {
        headerName: 'SHARE PLANT', field: 'sharePlant'
      },
      {
        headerName: 'OK', field: 'ok'
      },
      {
        headerName: 'NG', field: 'ng'
      },
      {
        headerName: 'DETAIL', field: 'detail', cellRendererFramework: TrackingListBtnCellRenderer
      }
    ]

    this.displayRowData = [{ sharePlant: '-', ok: '-', ng: '-' }]

    this.displayRowData1 = [
      { field: '5A', status: '-' },
      { field: '5A', status: '-' },
      { field: '5A', status: '-' },
    ]
  }

  siDeptApprDetails() {
    if (this.router.url.includes(this.urlCheck)) {
      const loadObj = {
        PlantCode: this.paramData?.plantcode,
        PartNumber: this.paramData?.partnumber,
        SupplierCode: this.paramData?.suppliercode,
        PkgStartDate: this.paramData?.pkgstartdate,
        PPFCode: this.paramData?.ppfcode,
        PPFver: this.paramData?.ppfver,
        step: '1',
      }
      this.trackingListService.sendDepartmentApproval(loadObj).subscribe({
        next: (res: any) => {
          this.details = res
          
          this.dateSent()
          const items = [
            this.paramData?.leaderplant,
            this.paramData?.plantname,
            this.details?.dv_tblInfo[0]?.dockcode,
            this.paramData?.modelcode,
            this.paramData?.suppliername,
            this.paramData?.partnumber,
            this.paramData?.specialist,
            this.dateSentVal
          ]
          this.leaderPlantContents.push(...items)

          const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
            && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
              this.changeSize(this.details?.tdFullCont, 1),
              1,
            ) : ""
  
          const qpcItems = [
            this.details?.qtyPartCont,
            fullContStore === "" ? "-" : fullContStore
          ]
          this.qpcContents.push(...qpcItems)

          this.loadParameter(res)
          this.createHtml_dispControl(res)

          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
      })
    }
    else {
      const loadObj = {
        PlantCode: this.paramHazmatData?.plant,
        PartNumber: this.paramHazmatData?.part,
        SupplierCode: this.paramHazmatData?.supplier,
        PkgStartDate: this.paramHazmatData?.psd,
        PPFCode: this.paramHazmatData?.ppfcode,
        PPFver: this.paramHazmatData?.ppfver,
        step: '1',
      }
      this.trackingListService.sendDepartmentApproval(loadObj).subscribe({
        next: (res: any) => {
          this.details = res
          this.dateSent()
          const items = [
            this.paramHazmatData?.leaderPlant,
            this.paramHazmatData?.plantName,
            this.details?.dv_tblInfo[0]?.dockcode,
            this.paramHazmatData?.modelCode,
            this.paramHazmatData?.supplierName,
            this.paramHazmatData?.part,
            this.paramHazmatData?.specialist,
            this.dateSentVal
          ]
          this.leaderPlantContents.push(...items)

          const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
            && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
              this.changeSize(this.details?.tdFullCont, 1),
              1,
            ) : ""
  
          const qpcItems = [
            this.details?.qtyPartCont,
            fullContStore === "" ? "-" : fullContStore
          ]
          this.qpcContents.push(...qpcItems)
          this.taFinalReasonDisabled = this.chkReqAsFinal ? false : true
          this.loadParameter(res)
          this.createHtml_dispControl(res)

          this.spinnerService.stop();
        },
        error: () => {
          this.spinnerService.stop();
        },
      })
    }
  }

  loadParameter(data: any) {
    this.crrStep = data?.getCurrentStepApprove
    this.stepStatus = data?.stepStatus
    this.stepStatusArray = Object.entries(this.stepStatus[0])

    this.leaderFlg = data?.leaderFlag //will receive from API padam
    this.displayFlg = data?.displayFlag
    this.urlVer = data?.url_ver
  }

  createHtml_dispControl(data: any) {
    this.flagDisplay = this.details?.displayFlag
    //change display flag once api is updated
    this.flagDisplay = [
      1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 1, 0
    ]
    // working
    if (this.flagDisplay[2] === 1) {
      this.showWorkTable = true
      this.createHtml_tblWork(data)
    }

    // concept compare
    if (this.crrStep === 2) {
      this.isConceptCompareDisabled = true
    }

    if (!this.roleInfo?.p_costview) {
      this.isConceptCompareDisabled = true
    }

    if (this.crrStep === 4 || this.crrStep === 7 || this.crrStep === 9) {
      this.taNoteValue = this.details?.t_Note_GetComment
    }
    // requested changes/reason requested
    if (this.flagDisplay[8] === 1 && this.flagDisplay[9] === 1) {
      this.tblRequestReasonDisplay = true
      this.createHtml_RequestedComment()
    } else if (this.flagDisplay[8] === 0 && this.flagDisplay[9] === 0) {
      this.tblRequestReasonDisplay = false
    }

    // trial
    if (this.flagDisplay[10] === 1) {
      this.tblTrialDisplay = true
      this.createHtml_tblTrial()
    } else {
      this.tblTrialDisplay = false
    }
    // request as final
    if (this.flagDisplay[11] === 1) {
      this.createHtml_RequestAsFinal(data)
    } else {
      this.hideControltblReqAsFinal = true
    }

    this.createHtml_intappDock(data)
    this.createHtml_sharePlants(data)
    this.createHtml_commentIntSup(data)

    // send button
    if (this.flagDisplay[16] === 1) {
      // display

      if (this.tdSharePlant === '-' && this.leaderFlg !== 1) {
        this.btnSendDisabled = true
      }
    } else {
      this.hideControlbtnSend = true
    }

    // approve button
    if (this.flagDisplay[17] === 1) {
      this.hideControlbtnApprove = false
    } else {
      this.hideControlbtnApprove = true
    }

    // reject button
    if (this.flagDisplay[18] === 1) {
      this.hideControlbtnReject = false
    } else {
      this.hideControlbtnReject = true
    }

    // reject to bm/supplier button
    if (this.flagDisplay[19] === 1 && this.flagDisplay[20] === 1) {
      this.hideControlbtnRejectBM = false
      this.hideControlbtnRejectSup = false
    } else if (this.flagDisplay[19] === 0 && this.flagDisplay[20] === 0) {
      this.hideControlbtnRejectBM = true
      this.hideControlbtnRejectSup = true
    }

  }

  createHtml_RequestedComment() {
    const dv: any = this.details?.createHtml_RequestedComment
    if (dv === null || dv === '') {
      return
    }
    if (dv.length < 1) {
      return
    }

    const alChk = dv?.alChk
    // ArrayList alChk = TTool.delimiterDivision(dv[0]["PkgReviewCheck"], ",");
    for (let i = 0; i < alChk?.length; i++) {
      if (this.nullInt(alChk[i]) === 1) {
        this.reqChangesChkList[0].checked = true
      } else if (this.nullInt(alChk[i]) === 2) {
        this.reqChangesChkList[1].checked = true
      } else if (this.nullInt(alChk[i]) === 3) {
        this.reqChangesChkList[2].checked = true
      } else if (this.nullInt(alChk[i]) === 4) {
        this.reqChangesChkList[3].checked = true
      } else if (this.nullInt(alChk[i]) === 5) {
        this.reqChangesChkList[4].checked = true
      } else if (this.nullInt(alChk[i]) === 6) {
        this.reqChangesChkList[5].checked = true
      } else if (this.nullInt(alChk[i]) === 7) {
        this.reasonReqArr[0].checked = true
      } else if (this.nullInt(alChk[i]) === 8) {
        this.reasonReqArr[1].checked = true
      } else if (this.nullInt(alChk[i]) === 9) {
        this.reasonReqArr[2].checked = true
      } else if (this.nullInt(alChk[i]) === 10) {
        this.reasonReqArr[3].checked = true
      } else if (this.nullInt(alChk[i]) === 11) {
        this.reasonReqArr[4].checked = true
      } else if (this.nullInt(alChk[i]) === 12) {
        this.reasonReqArr[5].checked = true
      } else if (this.nullInt(alChk[i]) === 13) {
        this.reasonReqArr[6].checked = true
      }
    }

    this.taRequestChanges = this.nullStr(dv?.commentRequested)
    this.taReasonRequested = this.nullStr(dv?.commentReason)

    if (this.stepStatusArray[this.crrStep - 1][1] !== 1 || this.leaderFlg === 1) {
      this.cblRequested = false
      this.cblReason = false

      this.taRequestChangesDisabled = true
      this.taReasonRequestedDisabled = true
    }
  }

  createHtml_tblTrial() {
    if (this.rdoTrialNoChecked === true) {
      this.txtParentPartDisabled = false
      this.btnParentPartDisabled = false
      this.taTriFinalReasonDisabled = true
    } else if (this.rdoTrialYesChecked === true) {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = true
    } else {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = false
    }
  }

  createHtml_commentIntSup(data: any) {
    try {
      // input data

      const objCommentIntSup = data?.createHtml_commentIntSup

      this.taIntComment = this.nullStr(objCommentIntSup['dv_taIntComment'])
      this.taExtComment = this.nullStr(objCommentIntSup['dv_taExtComment'])

      if (
        this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
        this.leaderFlg === 1
      ) {
        this.taExtCommentDisable = true
      }
    } catch (error) {
      
      return
    }
  }

  createHtml_RequestAsFinal(data: any) {
    try {
      const dv: any = data?.createHtml_RequestAsFinal

      if (this.leaderFlg === 1) {
        if (this.nullInt(dv?.finalFlag) === 1) {
          this.chkReqAsFinal = true;
        }
        if (this.nullStr(dv?.finalReason) !== '') {
          this.taFinalReason = this.nullStr(dv?.finalReason)
        }

        this.chkReqAsFinalDisabled = true;
        this.taFinalReasonDisabled = true;
      }
    } catch (error) {
      
      return
    }
  }

  onChange(event: any) {
    if (!event.target.checked) {
      this.chkReqAsFinal = false;
      this.taFinalReasonDisabled = true;
    }
    else {
      this.chkReqAsFinal = true;
      this.taFinalReasonDisabled = false;
    }
  }
  onRequestChange(event: any, value: any, _desc: any) {
    if (event.target.checked) {
      this.reqChangesChkList[value].checked = true
      this.reqList[value].Selected = true;
    }
    else {
      this.reqChangesChkList[value].checked = false
      this.reqList[value].Selected = false;
    }
  }

  onReasonChange(event: any, value: any, _desc: any) {
    if (event.target.checked) {
      this.reasonReqArr[value].checked = true
      this.resList[value].Selected = true;
    }
    else {
      this.reasonReqArr[value].checked = false
      this.resList[value].Selected = false;
    }
  }
  createHtml_intappDock(data: any) {
    try {
      // start assignment
      // set value
      this.tdDock1 = '-'
      this.tdCrrDept1 = '-'

      this.tdDock2 = '-'
      this.tdCrrDept2 = '-'

      this.tdDock3 = '-'
      this.tdCrrDept3 = '-'

      this.tdDock4 = '-'
      this.tdCrrDept4 = '-'

      this.tdDock5 = '-'
      this.tdCrrDept5 = '-'

      this.dvDock = data?.dv_dock
      this.dvSpec = data?.dv_spec.result
      this.alDock = data?.al_dock
      this.defaultApprover = data?.defaultApprover
      // end assignment

      const strDocks = data?.getIntApproveDocks

      if (strDocks === '') {
        this.radioVal = 0
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true
        this.rdoYesChecked = false
        this.rdoNoChecked = true
        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnselDock1Disabled = true

        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnselDock2Disabled = true

        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnselDock3Disabled = true

        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnselDock4Disabled = true

        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnselDock5Disabled = true

        // this.taIntCommentDisable = true

        return
        //here to start tomorrow
      } else if (strDocks === null) {
        return
      }

      let dockStatus = 0

      // dock - row 1
      if (this.alDock && this.alDock.length >= 1) {
        // dock name
        this.tdDock1 = this.nullStr(this.alDock[0])

        // dataview

        for (const iterator of this.dvSpec) {
          if (this.nullStr(iterator['divcode']) === 'CV'){
            this.selectDvSpec1.push({
              specialist: iterator['specialist'],
              specialistcode: iterator['specialistcode'],
            })
          }
        }

        if (!this.checkNull(this.dvDock)) {
          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock1Checked = false
              this.tdCrrDept1 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 0) {
                this.tdCrrDept1 = '-'
              } else if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept1 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept1 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept1 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept1 = 'Complete'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec1) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock1 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec1) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock1 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec1.length < 2) {
            this.chkSelDock1Checked = false
            this.chkSelDock1Disabled = true
            this.selDock1Disabled = true
            this.btnselDock1Disabled = true
          }
          else{
            this.chkSelDock1Checked = true
          }
        } else {
          this.chkSelDock1Checked = false
          this.chkSelDock1Disabled = true
          this.selDock1Disabled = true
          this.btnselDock1Disabled = true
        }
      } else {
        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnselDock1Disabled = true
      }

      // dock - row2
      if (this.alDock && this.alDock.length >= 2) {
        // dock name
        this.tdDock2 = this.nullStr(this.alDock[1])



        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){
              this.selectdvSpec2.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock2Checked = false
              this.tdCrrDept2 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept2 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept2 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept2 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept2 = 'Complete'
              } else {
                this.tdCrrDept2 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvSpec2) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock2 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvSpec2) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock2 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvSpec2.length < 2) {
            this.chkSelDock2Checked = false
            this.chkSelDock2Disabled = true
            this.selDock2Disabled = true
            this.btnselDock2Disabled = true
          }
          else{
            this.chkSelDock2Checked = true
          }
        } else {
          this.chkSelDock2Checked = false
          this.chkSelDock2Disabled = true
          this.selDock2Disabled = true
          this.btnselDock2Disabled = true
        }
      } else {
        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnselDock2Disabled = true
      }

      // dock - row3
      if (this.alDock && this.alDock.length >= 3) {
        // dock name
        this.tdDock3 = this.nullStr(this.alDock[2])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){
              this.selectDvSpec3.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock3Checked = false
              this.tdCrrDept3 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept3 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept3 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept3 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept3 = 'Complete'
              } else {
                this.tdCrrDept3 = '-'
              }
            }

            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec3) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock3 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec3) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock3 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec3.length < 2) {
            this.chkSelDock3Checked = false
            this.chkSelDock3Disabled = true
            this.selDock3Disabled = true
            this.btnselDock3Disabled = true
          }
          else{
            this.chkSelDock3Checked = true
          }
        } else {
          this.chkSelDock3Checked = false
          this.chkSelDock3Disabled = true
          this.selDock3Disabled = true
          this.btnselDock3Disabled = true
        }
      } else {
        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnselDock3Disabled = true
      }

      // dock - row4
      if (this.alDock && this.alDock.length >= 4) {
        // dock name
        this.tdDock4 = this.nullStr(this.alDock[3])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){
              this.selectDvSpec4.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock4Checked = false
              this.tdCrrDept4 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept4 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept4 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept4 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept4 = 'Complete'
              } else {
                this.tdCrrDept4 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec4) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock4 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec4) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock4 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec4.length < 2) {
            this.chkSelDock4Checked = false
            this.chkSelDock4Disabled = true
            this.selDock4Disabled = true
            this.btnselDock4Disabled = true
          }
          else{
            this.chkSelDock4Checked = true
          }
        } else {
          this.chkSelDock4Checked = false
          this.chkSelDock4Disabled = true
          this.selDock4Disabled = true
          this.btnselDock4Disabled = true
        }
      } else {
        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnselDock4Disabled = true
      }

      // dock - row5
      if (this.alDock && this.alDock.length >= 5) {
        // dock name
        this.tdDock5 = this.nullStr(this.alDock[4])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver
          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){
              this.selectDvSpec5.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock5Checked = false
              this.tdCrrDept5 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept5 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept5 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept5 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept5 = 'Complete'
              } else {
                this.tdCrrDept5 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec5) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock5 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec5) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock5 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec5.length < 2) {
            this.chkSelDock5Checked = false
            this.chkSelDock5Disabled = true
            this.selDock5Disabled = true
            this.btnselDock5Disabled = true
          }
          else{
            this.chkSelDock5Checked = true
          }
        } else {
          this.chkSelDock5Checked = false
          this.chkSelDock5Disabled = true
          this.selDock5Disabled = true
          this.btnselDock5Disabled = true
        }
      } else {
        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnselDock5Disabled = true
      }

      // set disable(send)

      if (this.stepStatusArray[this.crrStep - 1][1] === '13') {
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true

        this.chkSelDock1Disabled = true
        this.chkSelDock2Disabled = true
        this.chkSelDock3Disabled = true
        this.chkSelDock4Disabled = true
        this.chkSelDock5Disabled = true

        this.selDock1Disabled = true
        this.selDock2Disabled = true
        this.selDock3Disabled = true
        this.selDock4Disabled = true
        this.selDock5Disabled = true

        // internal approvement y/n
        if (
          (this.tdCrrDept1 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept2 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept3 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept4 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept5 === '-' || this.tdCrrDept1 === 'Skip')
        ) {
          this.radioVal = 0
          this.rdoNoChecked = true
          this.rdoYesChecked = false
        } else {
          this.radioVal = 1
          this.rdoYesChecked = true
          this.rdoNoChecked = false
        }
      }

      if (this.stepStatusArray[this.crrStep - 1][1] === '1') {
        this.btnselDock1Disabled = true
        this.btnselDock2Disabled = true
        this.btnselDock3Disabled = true
        this.btnselDock4Disabled = true
        this.btnselDock5Disabled = true
      }
    } catch (error) {
      
    }
  }
  onRadioChange(event: any) {
    if (event.value === 0) {
      this.rdoTrialNoChecked = true
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 1) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = true
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 2) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = true
      this.createHtml_tblTrial()
    }
  }

  createHtml_sharePlants(data: any) {
    try {
      this.sharePlants = data?.createHtml_sharePlants

      const countOK = this.sharePlants?.countOK
      const countNG = this.sharePlants?.countNG
      const cntWorking = this.sharePlants?.cntWorking

      // child plants
      this.dvChld = data?.dv_chld

      if (this.checkNull(this.dvChld)) {
        this.tdSharePlant = '-'
        this.tdSharePlantOK = '-'
        this.tdSharePlantNG = '-'
        this.btnSharePlantsDisabled = true
        return
      }

      if (
        this.leaderFlg === 1 ||
        this.stepStatusArray[this.crrStep - 1][1] === '1'
      ) {
        this.btnSharePlantsDisabled = true
      }

      this.tdSharePlant = this.nullStr(this.dvChld.length)

      // count OK or NG

      if (cntWorking > 0 || this.dvChld.length === countNG + countOK) {
        this.btnSendDisabled = true
      }

      this.tdSharePlantOK = countOK.toString()
      this.tdSharePlantNG = countNG.toString()
    } catch (error) {
      
      return
    }
  }

  createHtml_tblWork(_data: any) {
    const working = Number(this.details?.nowWorking)

    switch (working) {
      case 2:
        this.rdoContFabWorkChecked = true
        break
      case 6:
        this.rdoSupWorkChecked = true
        break
      case 8:
        this.rdoDun1FabWorkChecked = true
        break
      default:
        break
    }
    if (
      this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
      this.leaderFlg === 1
    ) {
      this.rdoContFabWorkDisabled = true
      this.rdoSupWorkDisabled = true
      this.rdoDun1FabWorkDisabled = true
    }
  }

  checkNull(value: any) {
    try {
      if (Object.prototype.toString.call(value) === '[object Array]'){
        return false
      }
      else if (Object.prototype.toString.call(value) === '[object Object]'){
        return false
      }
      else if (
        value === null ||
        value === undefined ||
        value.trim() === '' ||
        value === 'undefined' ||
        value === 'null'
      ){
        return true
      }
      else {return false}
    } catch (error) {
      
      return false
    }
  }

  nullInt(obj: any) {
    if (obj == null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  nullStr(value: any): any {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  changeSize(obj: any, type: any) {
    if (obj === null) {
      return 0
    } else {
      const size = this.nullDouble(obj) 
      if (this.userInfo?.unit === '0') {
        return size
      } else {
        if (type === 0) {
          return this.changeInch(size)
        } else if (type === 1) {
          return this.changePound(size)
        }
        return 0
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj.toString())
        } else {
          return parseInt(obj.toString())
        }
      } catch {
        return 0
      }
    }
  }

  changeInch(size: any) {
    return Math.round((size / 25.4) * 2) / 2
  }

  changePound(size: any) {
    return (size * 1000) / 453.59237
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size);
      }
      else {
        return this.formatPoundNull(size);
      }
    }
    else {
      if (type === 0) {
        return this.formatMmNull(size);
      }
      else {
        return this.formatkgNull(size);
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return '0'
    }
    return size.toFixed(2)
  }

  formatPoundNull(round: any) {
    if (round === 0) {
      return ''
    }
    return round.toFixed(2)
  }

  formatMmNull(convert: any) {
    if (convert === 0) {
      return ''
    }
    return convert.toFixed(2)
  }

  formatkgNull(abs: any) {
    if (abs === 0) {
      return ''
    }
    return abs.toFixed(2)
  }


  dateSent() {
    if (this.details?.dv_date !== null && this.details?.dv_date.length > 0) {
      this.dateSentVal = '-'
      if (this.nullStr(this.details?.dv_date[0]['datesent']) !== '') {
        this.dateSentVal = formatDate(this.details?.dv_date[0]['datesent'], 'MM/dd/yyyy', 'en-US' , 'UTC')
      }
    } else {
      this.dateSentVal = '-'
    }
  }

  internalAppChange(event: any) {
    if (event.value === 0) {
      this.rdoYesChecked = false;
      this.rdoNoChecked = true;
    }
    else if (event.value === 1) {
      this.rdoYesChecked = true;
      this.rdoNoChecked = false;
    }
  }

  async onFileSelected(_event: any) {
  }

  approve() {
    const stepStatus = Object.values(this.stepStatus[0]).map(Number);
    this.errorMessage = null;
      if (this.router.url.includes(this.urlCheck)) {
        const obj = {
          leaderFlg: this.nullInt(this.leaderFlg),  // (variable) page load API will assign this value 
          crrStep: this.currentStep, // (variable) page load API will assign this value 
          stepStatus: stepStatus, // (variable) page load API will assign this value 
          PlantCode: this.paramData?.plantcode, // query param
          PartNumber: this.paramData?.partnumber, // query param
          SupplierCode: this.paramData?.suppliercode, // query param
          PkgStartDate: this.paramData?.pkgstartdate, // query param 
          PPFCode: this.paramData?.ppfcode, // query param
          UserName: this.spCode, // userInfo  Specialist Name
          ModelCode: this.paramData?.modelcode,  // (variable) page load API will assign this value 
          rdoSupWork : this.radioValWork === 2 ? true : false,  // Work radio button
          rdoContFabWork : this.radioValWork === 1 ? true : false, // Work radio button
          rdoDun1FabWork : this.radioValWork === 3 ? true : false, // Work radio button
          chkReqAsFinal: this.chkReqAsFinal, // Request as Final checkbox
          HZcomment: this.hazmatComment,  // Internal Comment textArea
          rdoTrialYes: this.rdoTrialYesChecked,  // keep it false | not applicable for SI SE 
          rdoAsFinal: this.rdoAsFinalChecked, // keep it false | not applicable for SI SE
          updatePpf: this.stepStatus[0].step09status === '4' ? 1 : 0,
          SpecialistCode: this.spCode,
          cblRequested : [],
          cblReason : []
        }
        this.spinnerService.start();
        this.trackingListService.hazmatApprove(obj).subscribe({
          next: (res: any) => {
            if (res.status === 'SUCCESS') {
              this.spinnerService.stop();
              this.successMessage = res.message;
              setTimeout(() => {
                this.successMessage = ''
                this.router.navigateByUrl(this.urlCheck);
              }, 3000)
            }
            else{
              this.errorMessage = res?.message;
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
            this.errorMessage = _err.error?.error?.message ?? this.err
          },
        })
      }
      else {
        const obj = {
          leaderFlg: this.nullInt(this.leaderFlg),  // (variable) page load API will assign this value 
          crrStep: Number(this.currentHazmatStep), // (variable) page load API will assign this value 
          stepStatus: stepStatus, // (variable) page load API will assign this value 
          PlantCode: this.paramHazmatData?.plant, // query param
          PartNumber: this.paramHazmatData?.part, // query param
          SupplierCode: this.paramHazmatData?.supplier, // query param
          PkgStartDate: this.paramHazmatData?.psd, // query param 
          PPFCode: this.paramHazmatData?.ppfcode, // query param
          UserName: this.spCode, // userInfo  Specialist Name
          ModelCode: this.paramHazmatData?.modelCode,  // (variable) page load API will assign this value 
          rdoSupWork: this.rdoSupWorkChecked,  // Work radio button
          rdoContFabWork: this.rdoContFabWorkChecked, // Work radio button
          rdoDun1FabWork: this.rdoDun1FabWorkChecked, // Work radio button
          chkReqAsFinal: this.chkReqAsFinal, // Request as Final checkbox
          HZcomment: this.hazmatComment,  // Internal Comment textArea
          rdoTrialYes: this.rdoTrialYesChecked,  // keep it false | not applicable for SI SE 
          rdoAsFinal: this.rdoAsFinalChecked, // keep it false | not applicable for SI SE
          updatePpf: this.stepStatus[0].step09status === '4' ? 1 : 0,
          SpecialistCode: this.spCode,
          cblRequested : [],
          cblReason : []
        }

        this.spinnerService.start();
        this.trackingListService.hazmatApprove(obj).subscribe({
          next: (res: any) => {
            if (res.status === 'SUCCESS') {
              this.spinnerService.stop();
              this.successMessage = res.message;
              setTimeout(() => {
                this.successMessage = ''
                this.router.navigateByUrl(this.urlHazmat);
              }, 3000)
            }
            else{
              this.errorMessage = res?.message;
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
            this.errorMessage = _err.error?.error?.message ?? this.err
          },
        })
      }
  }

  reject() {
    this.errorMessage = null;
    if (this.hazmatComment.length ===0 || this.hazmatComment === '' || this.hazmatComment === null) {
      this.errorMessage = "Please provide Hazmat Comment";
    }
    else {
      if (this.router.url.includes(this.urlCheck)) {
        const obj = {
          leaderFlg: this.leaderFlg,
          PkgStartDate: this.paramData?.pkgstartdate,
          PartNumber: this.paramData?.partnumber,
          PlantCode: this.paramData?.plantcode,
          PPFCode: this.paramData?.ppfcode,
          UserName: this.spCode,
          SupplierCode: this.paramData?.suppliercode,
          ModelCode: this.paramData?.modelcode,
          crrStep: this.currentStep,
          rdoSupWork: this.rdoSupWorkChecked,
          rdoContFabWork: this.rdoContFabWorkChecked,
          rdoDun1FabWork: this.rdoDun1FabWorkChecked,
          HZcomment: this.hazmatComment,
          chkReqAsFinal: this.chkReqAsFinal,
          SpecialistCode : this.spCode,
          taExtComment:this.taExtComment,// this.details.createHtml_commentIntSup.dv_taExtComment   ,
          cblRequested : [],
          cblReason : [],
          updatePpf: this.stepStatus[0].step09status === '4' ? 1 : 0
        }


        this.spinnerService.start();
        this.trackingListService.hazmatReject(obj).subscribe({
          next: (res: any) => {
            if (res.status === 'SUCCESS') {
              this.spinnerService.stop();
              this.successMessage = res.message;
              setTimeout(() => {
                this.successMessage = ''
                this.router.navigateByUrl(this.urlCheck);
              }, 3000)
            }
            else{
              this.errorMessage = res?.message;
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
            this.errorMessage = _err.error?.error?.message ?? this.err
          },
        })
      }
      else {
        const obj = {
          leaderFlg: this.leaderFlg,
          PkgStartDate: this.paramHazmatData?.psd,
          PartNumber: this.paramHazmatData?.part,
          PlantCode: this.paramHazmatData?.plant,
          PPFCode: this.paramHazmatData?.ppfcode,
          UserName: this.spCode,
          SupplierCode: this.paramHazmatData?.supplier,
          ModelCode: this.paramHazmatData?.modelCode,
          crrStep: Number(this.currentHazmatStep),
          rdoSupWork: this.rdoSupWorkChecked,
          rdoContFabWork: this.rdoContFabWorkChecked,
          rdoDun1FabWork: this.rdoDun1FabWorkChecked,
          HZcomment: this.hazmatComment,
          chkReqAsFinal: this.chkReqAsFinal,
          cblRequested : [],
          cblReason : [],
          updatePpf: this.stepStatus[0].step09status === '4' ? 1 : 0
        }
        this.spinnerService.start();
        this.trackingListService.hazmatReject(obj).subscribe({
          next: (res: any) => {
            if (res.status === 'SUCCESS') {
              this.spinnerService.stop();
              this.successMessage = res.message;
              setTimeout(() => {
                this.successMessage = ''
                this.router.navigateByUrl(this.urlHazmat);
              }, 3000)
            }
            else{
              this.errorMessage = res?.message;
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
            this.errorMessage = _err.error?.error?.message ?? this.err

          },
        })
      }
    }
  }

  cancel() {
    if (this.router.url.includes(this.urlCheck)) {
      this.router.navigate([this.urlCheck]);
    }
    else {
      this.router.navigate([this.urlHazmat]);
    }
  }

  PPF() {
    if (this.router.url.includes(this.urlCheck)) {
      const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
      if(plantCode === 'SERVICE'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        this.router.navigate(['/tracking-list/hazmat-approval/serviceparts-pdf',this.paramData.ppfcode, -1, 1, 2])
      }
      else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber)
        this.router.navigate(['/tracking-list/hazmat-approval/accessoryParts-pdf', this.paramData.ppfcode, -1, 1, 2])
      }
      else{
        this.router.navigate(['/tracking-list/hazmat-approval/ppf', this.paramData.ppfcode, -1, 1, 2])
      }
    }
    else {
      const plantCode = this.constants.getTypeFromPlantCode(this.paramHazmatData?.plant)
      if(plantCode === 'SERVICE'){
        sessionStorage.setItem('partnumber', this.paramHazmatData?.part);
        this.router.navigate(['/hazmat-approve-list/hazmat-approval/serviceparts-pdf',this.paramHazmatData.ppfcode, -1, 1, 2])
      }
      else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramHazmatData?.part)
        this.router.navigate(['/hazmat-approve-list/hazmat-approval/accessoryParts-pdf', this.paramHazmatData.ppfcode, -1, 1, 2])
      }
      else{
        this.router.navigate(['/hazmat-approve-list/hazmat-approval/ppf', this.paramHazmatData.ppfcode, -1, 1, 2])
      }
    }
  }

  conceptCompare() {
    if (this.router.url.includes(this.urlCheck)) {
      const params = {
        ppfcode1: this.paramData?.ppfcode,
        ppfver1: this.details?.url_ver,
        ppfcode2: this.paramData?.ppfcode,
        ppfver2: -1
      }
      sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
      this.router.navigateByUrl('/tracking-list/hazmat-approval/concept-compare');
    }
    else {
      const params = {
        ppfcode1: this.paramHazmatData?.ppfcode,
        ppfver1: this.details?.url_ver,
        ppfcode2: this.paramHazmatData?.ppfcode,
        ppfver2: -1
      }
      sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
      this.router.navigateByUrl('/hazmat-approve-list/hazmat-approval/concept-compare');
    }
  }

  goToDeptAppr() {
    if (this.router.url.includes(this.urlCheck)) {
      const params = {
        leaderPlant: this.paramData?.leadername,
        plant: this.paramData?.plantcode,
        plantName: this.paramData?.plantname,
        dock: this.paramData?.dockcode,
        modelCode: this.paramData?.modelcode,
        supplier: this.paramData?.suppliercode,
        supplierName: this.paramData?.suppliername,
        specialist: this.paramData?.specialist,
        part: this.paramData?.partnumber,
        psd: this.paramData?.pkgstartdate,
        dateSent: this.paramData?.datesent,
        mode: 1,
        step: 2,
        currentIntNo: this.paramData?.currentintno,
        currentIntVer: this.paramData?.currentintver
      }
      sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
      this.router.navigateByUrl('/tracking-list/hazmat-approval/dept-approve');
    }
    else {
      const params = {
        leaderPlant: this.paramHazmatData?.leaderName,
        plant: this.paramHazmatData?.plant,
        plantName: this.paramHazmatData?.plantName,
        dock: this.paramHazmatData?.dock,
        modelCode: this.paramHazmatData?.modelCode,
        supplier: this.paramHazmatData?.supplier,
        supplierName: this.paramHazmatData?.supplierName,
        specialist: this.paramHazmatData?.specialist,
        part: this.paramHazmatData?.part,
        psd: this.paramHazmatData?.psd,
        dateSent: this.paramHazmatData?.dateSent,
        mode: 1,
        step: 2,
        currentIntNo: this.paramHazmatData?.currentIntNo,
        currentIntVer: this.paramHazmatData?.currentIntVer
      }
      sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
      this.router.navigateByUrl('/hazmat-approve-list/hazmat-approval/dept-approve');
    }

  }

  goToIntAppr() {
    this.router.navigateByUrl('/internal-approvement')
  }

  goToSelectPart() {
    //GOTO SELECT PART SCREEN
  }
  close(){
    this.errorMessage = null;
  }
}
