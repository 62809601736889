<div class="wrapper mx-3">
    <div class="page-title py-2 d-flex align-items-center">Package Authorization Do</div>
    <div class="mat-typography content">
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">RECEIVE FROM TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Due Date
                        <span class="text-black">
                            {{dateData}}
                        </span>
                    </div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Auth Date<span
                            class="text-black">{{authData}}</span></div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Type<span
                            class="text-black">{{htmlData?.type}}</span></div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2" *ngIf="supplierForecast">Supplier
                        Forecast Date<span class="text-black">{{supplierForecastDate}}</span></div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Status<span
                            class="text-black">{{dopkgData?.status}}</span></div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Part Number<span
                            class="text-black">{{htmlData?.partNumber}}</span></div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Message From<span
                            class="text-black">{{dopkgData?.message}}</span></div>
                    <div class="my-2 col-lg-3 col-md-6 col-12">
                        Authorization Message
                        <textarea disabled="true" rows="3" class="col-12">{{htmlData?.reason}}</textarea>
                    </div>
                    <div class="d-flex flex-column my-2 col-lg-3 col-md-6 col-12" style="padding-left: 18px;"
                        *ngIf="pacakgeReview">
                        Message
                        <textarea disabled="true" rows="3" class="col-12">{{htmlData?.message}}</textarea>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0" *ngIf="pacakgeReview">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title>PACKAGE REVIEW </mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Result
                        <span class="text-black">{{getResult(result)}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested
                        <span>{{htmlData.packageReview.Requested}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested Changes
                        <span>{{packageRequestMessage}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason
                        <span>{{htmlData.packageReview.Reason}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason Requested
                        <span>{{packageReason}}</span>
                    </div>

                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">ORDER INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Container Code
                        <span class="text-black">
                            {{htmlData?.orderInformation?.containerCode=='*'||htmlData?.orderInformation?.containerCode==null?'-':htmlData?.orderInformation?.containerCode}}
                        </span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage1 Code
                        <span class="text-black">
                            {{htmlData?.orderInformation?.dunnage1Code=='*'||htmlData?.orderInformation?.dunnage1Code==null?'-':htmlData?.orderInformation?.dunnage1Code}}
                        </span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage2 Code<span
                            class="text-black">{{(htmlData?.orderInformation?.dunnage2Code=='*'||htmlData?.orderInformation?.dunnage2Code==null)?'-':htmlData?.orderInformation?.dunnage2Code}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Top Cap Code<span
                            class="text-black">{{htmlData?.orderInformation?.topCapCode=='*' || htmlData?.orderInformation?.topCapCode==null?'-':htmlData?.orderInformation?.topCapCode}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Container<span
                            class="text-black">{{htmlData?.orderInformation?.qtyContainer=='*'||htmlData?.orderInformation?.qtyContainer==null?'0':htmlData?.orderInformation?.qtyContainer}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage1<span
                            class="text-black">{{htmlData?.orderInformation?.qtyDunnage1=='*'||htmlData?.orderInformation?.qtyDunnage1==null?'0':htmlData?.orderInformation?.qtyDunnage1}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage2<span
                            class="text-black">{{htmlData?.orderInformation?.qtyDunnage2=="*"||htmlData?.orderInformation?.qtyDunnage2==null?'0':htmlData?.orderInformation?.qtyDunnage2}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Top Code<span
                            class="text-black">{{htmlData?.orderInformation?.qtyTopCap=='*' || htmlData?.orderInformation?.qtyTopCap==null?'0':htmlData?.orderInformation?.qtyTopCap}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Pallet Code<span
                            class="text-black">{{htmlData?.orderInformation?.palletCode=='*'||htmlData?.orderInformation?.palletCode==null?'-':htmlData?.orderInformation?.palletCode}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Pallet<span
                        class="text-black">{{htmlData?.orderInformation?.qtyPallet=='*'||htmlData?.orderInformation?.qtyPallet==null?'-':htmlData?.orderInformation?.qtyPallet}}</span></div>

                            <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Stretch Code <span
                            class="text-black">{{htmlData?.orderInformation?.StretchCode=='*'||htmlData?.orderInformation?.StretchCode==null?'-':htmlData?.orderInformation?.StretchCode}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Kanban Holder Code<span
                            class="text-black">{{htmlData?.orderInformation?.kanbanHolderCode=='*'||htmlData?.orderInformation?.kanbanHolderCode==null?'-':htmlData?.orderInformation?.kanbanHolderCode}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Other Pkg Code<span
                            class="text-black">{{htmlData?.orderInformation?.otherPkgCode=='*'||htmlData?.orderInformation?.otherPkgCode==null?'-':htmlData?.orderInformation?.otherPkgCode}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Stretch<span
                            class="text-black">{{htmlData?.orderInformation?.qtyStretch=='*'||htmlData?.orderInformation?.qtyStretch==null?'0':htmlData?.orderInformation?.qtyStretch}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Kanban Holder<span
                            class="text-black">{{htmlData?.orderInformation?.qtykanbanHolder=='*' || htmlData?.orderInformation?.qtykanbanHolder==null?'0':htmlData?.orderInformation?.qtykanbanHolder}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Other Pkg<span
                            class="text-black">{{htmlData?.orderInformation?.qtyOtherPkg=='*'|| htmlData?.orderInformation?.qtyOtherPkg==null?'0':htmlData?.orderInformation?.qtyOtherPkg}}</span></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">REASON</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-5 col-12 my-2">Reason<span
                            class="text-black">{{htmlData?.orderInformation?.reason}}</span>
                    </div>

                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Reason Detail<span
                            class="text-black">{{htmlData?.orderInformation?.reasonDetail}}</span></div>
                </div>

            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0" *ngIf="!cancelAuth">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap" *ngIf="!cancelAuth">
                    <span>
                        <button class="vpacs-btn-table" style="margin-top: 12px;" (click)="authDownload()">AUTH
                            DOWNLOAD</button>
                    </span>&nbsp;&nbsp;
                    <span *ngIf="showTargetAndSerialBtns">
                        <button class="vpacs-btn-table" style="margin-top: 12px;" (click)="deliveryTarget()">DELIVERY
                            TARGET</button>
                    </span>&nbsp;&nbsp;
                    <span *ngIf="showTargetAndSerialBtns">
                        <button class="vpacs-btn-table" [disabled]="!serialButton" style="margin-top: 12px;"
                            (click)="serial()">SERIAL#</button>
                    </span>
                </div>
                <div class="d-flex flex-column col-lg-2 col-md-6 col-12 my-2" *ngIf="displayForecastDate || disableForecastDate">
                    Forecast Ready Date
                    <span>
                        <input type="date" placeholder="MM/DD/YYYY" [(ngModel)]="forecastDate" [disabled]="disableForecastDate">
                    </span>
                </div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2" *ngIf="displayReadinessDate || disableReadinessDate">
                    Readiness Date
                    <span>
                        <input type="date" placeholder="MM/DD/YYYY" [(ngModel)]="toReadyDate" [disabled]="disableReadinessDate">
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0" *ngIf="cancelAuth">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="d-flex flex-column col-lg-2 col-md-6 col-12 my-2" *ngIf="displayForecastDate || disableForecastDate">
                    Forecast Ready Date
                    <span>
                        <input type="date" placeholder="MM/DD/YYYY" [(ngModel)]="forecastDate" [disabled]="disableForecastDate">
                    </span>
                </div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2" *ngIf="displayReadinessDate || disableReadinessDate">
                    Readiness Date
                    <span>
                        <input type="date" placeholder="MM/DD/YYYY" [(ngModel)]="toReadyDate" [disabled]="disableReadinessDate">
                    </span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="footer-dialog d-flex" *ngIf="!cancelAuth && showTargetAndSerialBtns">
        <div class="mx-3 cancel" (click)="goBack()">Cancel</div>
        <button class="black-button mx-3" (click)="saveClick()" [disabled]="okButtonDisabled">OK</button>
    </div>
    <div class="footer-dialog d-flex" *ngIf="!cancelAuth && !showTargetAndSerialBtns">
        <div class="mx-3 cancel" (click)="goBack()">Cancel</div>
        <button class="black-button mx-3" [disabled]="okButtonDisabled" (click)="saveOK()">OK</button>
    </div>
    <div class="footer-dialog d-flex" *ngIf="cancelAuth">
        <div class="mx-3 cancel" (click)="cancelOk()" [class.disabled]="cancelOKNGDisabled">Cancel OK</div>
        <div class="mx-3 cancel" (click)="cancelNg()" [class.disabled]="cancelOKNGDisabled">Cancel NG</div>
        <div class="mx-3 cancel" (click)="goBack()">Cancel</div>
    </div>
</div>