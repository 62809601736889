import { Component } from '@angular/core'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'sq-btn-cell-renderer',
  template: `
    <div class="d-flex align-items-center gap-1" *ngIf="isSq">
      <span>{{ text }}</span>
      <button
        class="vpacs-btn-table"
        (click)="navigate()"
        style="width:30px; height:30px; background: #000084; color:#fff; font-size: 11px"
      >
        {{ step4btn }}
      </button>
    </div>

    <div class="d-flex align-items-center gap-1" *ngIf="!isSq">
      <button
        class="vpacs-btn-table"
        (click)="navigate()"
        style="width:30px; height:30px; background: #000084; color:#fff; font-size: 11px"
      >
        {{ step4btn }}
      </button>
    </div>
  `,
})
export class SqBtnCellRenderer implements ICellRendererAngularComp {
  text: any
  step4btn: any
  isSq = false

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`)
  }

  private params: any

  agInit(params: any): void {
    this.params = params
    const field = this.params.colDef.field
    this.text = this.params.data[field]
    if (
      params.data['Step04Status'] === '1' ||
      params.data['Step04Status'] === '14'
    ) {
      this.step4btn = 'SI'
      this.isSq = false
    } else {
      this.step4btn = 'SE'
      this.isSq = false
    }
    if (!params.data['leaderplant']) {
      this.step4btn = 'SQ'
      this.isSq = true
    }
    this.step4btn = 'SI'
    this.isSq = true
  }

  navigate() {
    if (this.step4btn === 'SQ') {
      sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data))
      this.router.navigate(['/tracking-list/sq-dept-approval'])
      // this.router.navigate(['/tracking-list/sq-dept-approval'], {
      //   queryParams: { data: JSON.stringify(this.params.data) },
      // })
    } else if (this.step4btn === 'SE') {
      this.router.navigate(['/tracking-list/send-ppf'], {
        queryParams: { data: JSON.stringify(this.params.data) },
      })
    } else if (this.step4btn === 'SI') {
      if (this.params.colDef.field === 'step02date') {
        sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data));
        this.router.navigate(['/tracking-list/send-dept-approval']);
        // this.router.navigate(['/tracking-list/sI-preppf-dept-approval'], {
        //   queryParams: { data: JSON.stringify(this.params.data) },
        // })
      }
      if (this.params.colDef.field === 'step04date') {
        sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data))
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval'])
        // this.router.navigate(['/tracking-list/sI-preppf-dept-approval'], {
        //   queryParams: { data: JSON.stringify(this.params.data) },
        // })
      }
      if (this.params.colDef.field === 'step09date') {
        sessionStorage.setItem('deptApprove', JSON.stringify(this.params.data))
        this.router.navigate(['/tracking-list/sI-final-dept-approval'])
        // this.router.navigate(['/tracking-list/sI-final-dept-approval'], {
        //   queryParams: { data: JSON.stringify(this.params.data) },
        // })
      }
    }
  }
}
