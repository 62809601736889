import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ShipmentService } from 'src/app/services/shipment.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from '../message-popup-toast/message-popup-toast.component';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'shipment-action-btn-cell-render',
  template: `<div class="d-flex align-items-center">
    <mat-icon
      class="mx-2 d-flex justify-content-center fs-5 vpacs-btn-table align-items-center "
      style=" width: 26px; height: 26px;"
      title="View Detail"
      (click)="goToViewDetailAction()"
      >visibility</mat-icon
    >
    <mat-icon
      class="mx-2 d-flex justify-content-center fs-5 vpacs-btn-table align-items-center "
      style=" width: 26px; height: 26px;"
      title="Print"
      (click)="printShipment()"
      >print</mat-icon
    >
  </div> `,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ShipmentActionBtnCellRender implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }
  private params: any;
  constructor(private readonly route: Router, public shipmentService: ShipmentService, public message: MatSnackBar) { }

  agInit(params: any): void {
    this.params = params;
  }

  goToViewDetailAction() {
    sessionStorage.setItem(
      'detailShipmentNumber',
      JSON.stringify({
        detailShipmentNumber: this.params.data.SHIPMENTNUMBER,
        status: this.params.data.STATUS,
         currentUser: this.params.data.CURRENTUSER,
         cancelFlag: this.params.data.CANCELFLAG,
         supplierCode: this.params.data.SUPPLIERCODE,
         shipperCode: this.params.data.SHIPPERCODE,
         authNumber: this.params.data.AUTHNUMBER,   
      })
      
    );
    this.route.navigate(['/shipment/detail']);
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  printShipment() {
    this.shipmentService
      .downloadprintPdf(this.params.data.SHIPMENTNUMBER)
      .subscribe({

        next: (data: any) => {
          const docDefinition: any = this.pdfStructure(data);
          pdfMake.createPdf(docDefinition).download(`shipmentlist_.pdf`);
          this.openMessageBox('File downloaded successfully', 'success')
        },
        error: (_err: any) => {
          this.openMessageBox('Failed to download', 'error')
        },
      });
  }

  pdfStructure(downloadData: any) {
   
    const dd: any = {
      pageSize: 'a4',
      pageOrientation: 'portrait',
      pageMargins: [35, 35, 35, 35],
      content: [
        {
          text: 'NOTICE OF PACKAGING SHIPMENT - TMMWV',
          alignment: 'center',
          fontSize: 23,
          bold: true,
          style: 'header',
          marginBottom: 10,
        },
        { text: 'Reason', fontSize: 18, bold: true, marginBottom: 0 },
        {
          text: downloadData.createAuthList[0]?.REASON,
          alignment: 'left',
          style: 'data',
          absolutePosition: { x: 150, y: 75 },
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 508,
              y1: 0,
              x2: 108,
              y2: 0,
              lineWidth: 1,
              lineColor: 'black',
            },
          ],
          marginBottom: 10,
        },
        {
          table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                [
                  {
                    margin: [-4, 0, 0, 0],
                    text: 'Model',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createAuthList[0]?.MODELCODE,
                    style: 'data',
                    absolutePosition: { x: -210, y: 108 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  { text: 'Dock', fontSize: 18, bold: true, marginBottom: 5 },
                  {
                    text: downloadData.createAuthList[0]?.DOCKCODE,
                    style: 'data',
                    absolutePosition: { x: 310, y: 108 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
              ],

              [
                [
                  {
                    margin: [-4, 0, 0, 0],
                    text: 'Ship Number',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.SHIPMENTNUMBER,
                    fontSize: 16,
                    style: 'data',
                    absolutePosition: { x: -135, y: 141 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  {
                    text: 'Tracking #',
                    fontSize: 18,
                    bold: true,
                    marginBottom: 11,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.TRACKINGNO,
                    style: 'data',
                    absolutePosition: { x: 310, y: 141 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
              ],
            ],
          },

          layout: {
            hLineWidth: function (_i: any, _node: any) {
              return 0;
            },
            vLineWidth: function (_i: any, _node: any) {
              return 0;
            },
          },
        },

        [
          {
            margin: [0, 0, 0, 0],
            text: 'Ship Date',
            fontSize: 18,
            bold: true,
            style: 'header',
          },
          {
            text: downloadData.createShipmentDetail[0]?.SHIPDATE,
            style: 'data',
            absolutePosition: { x: -150, y: 177 },
          },
        ],
        {
          canvas: [
            {
              type: 'line',
              x1: 245,
              y1: 0,
              x2: 109,
              y2: 0,
              lineWidth: 1,
              lineColor: 'black',
            },
          ],
          marginBottom: 25,
        },

        {
          text: 'Receiver - Attention:',
          fontSize: 18,
          bold: true,
          style: 'header',
          marginBottom: 10,
        },
        {
          table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                [
                  {
                    margin: [10, 0, 0, 0],
                    text: 'Company',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.RECVCOMPANY,
                    style: 'data',
                    absolutePosition: { x: -210, y: 255 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  {
                    margin: [3, 0, 0, 0],
                    text: 'Company',
                    fontSize: 18,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.RECVCOMPANY,
                    style: 'data',
                    absolutePosition: { x: 360, y: 255 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
              ],

              [
                [
                  {
                    margin: [10, 0, 0, 0],
                    text: 'Contact',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0].RECVCPERSON1,
                    style: 'data',
                    absolutePosition: { x: -140, y: 289 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  {
                    margin: [3, 0, 0, 0],
                    text: 'Contact',
                    fontSize: 18,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.RECVCPERSON2,
                    style: 'data',
                    absolutePosition: { x: 400, y: 289 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
              ],

              [
                [
                  {
                    margin: [10, 0, 0, 0],
                    text: 'Phone',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]
                      ?.RECVPHONENUMBER1,
                    style: 'data',
                    absolutePosition: { x: -118, y: 324 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  {
                    margin: [3, 0, 0, 0],
                    text: 'Phone',
                    fontSize: 18,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]
                      ?.RECVPHONENUMBER2,
                    style: 'data',
                    absolutePosition: { x: 400, y: 324 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
              ],

              [
                [
                  {
                    margin: [10, 0, 0, 0],
                    text: 'Email',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.RECVEMAIL1,
                    fontSize: 11,
                    style: 'data',
                    absolutePosition: { x: -130, y: 360 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                [
                  {
                    margin: [3, 0, 0, 0],
                    text: 'Email',
                    fontSize: 18,
                    bold: true,
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.RECVEMAIL2,
                    fontSize: 11,
                    style: 'data',
                    absolutPosition: { x: 390, y: 360 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 20,
                },
              ],
            ],
          },

          layout: {
            hLineWidth: function (_i: any, _node: any) {
              return 0;
            },
            vLineWidth: function (_i: any, _node: any) {
              return 0;
            },
          },
        },

        {
          text: 'Shipper',
          fontSize: 18,
          bold: true,
          style: 'header',
          marginBottom: 10,
        },
        {
          table: {
            widths: ['25%', '25%', '50%'],
            body: [
              [
                [
                  {
                    margin: [10, 0, 0, 0],
                    text: 'Company',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.SHIPCOMPANY,
                    style: 'data',
                    absolutePosition: { x: -150, y: 255 },
                  },
                ],
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 110,
                      y1: 20,
                      x2: -27,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                {
                  margin: [0, 20],
                  table: {
                    widths: ['50%', '50%'],
                    body: [
                      [
                        {
                          text: 'Shipment Summary',
                          style: 'tableHeader',
                          colSpan: 2,
                          alignment: 'center',
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Total m3',
                          fontSize: 18,
                          bold: true,
                          style: 'header',
                          alignment: 'center',
                        },
                        {
                          text: downloadData.createAuthList[0]?.PKGWEIGHT,
                          style: 'data',
                        },
                      ],
                      [
                        {
                          text: 'Total kg',
                          fontSize: 18,
                          bold: true,
                          style: 'header',
                          alignment: 'center',
                        },
                        {
                          text: downloadData.TotalWeight,
                          style: 'data',
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                [
                  {
                    margin: [10, -90, 0, 0],
                    text: 'Contact',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.SHIPCPERSON,
                    style: 'data',
                    absolutePosition: { x: -140, y: 466 },
                  },
                ],
                {
                  margin: [10, -90, 0, 0],
                  canvas: [
                    {
                      type: 'line',
                      x1: 100,
                      y1: 20,
                      x2: -36,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                {},
              ],
              [
                [
                  {
                    margin: [10, -60, 0, 0],
                    text: 'Phone',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.SHIPPHONENUMBER,
                    style: 'data',
                    absolutePosition: { x: -118, y: 500 },
                  },
                ],
                {
                  margin: [10, -60, 0, 0],
                  canvas: [
                    {
                      type: 'line',
                      x1: 100,
                      y1: 20,
                      x2: -36,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 10,
                },
                {},
              ],
              [
                [
                  {
                    margin: [10, -30, 0, 0],
                    text: 'Email',
                    fontSize: 18,
                    bold: true,
                    style: 'header',
                    marginBottom: 5,
                  },
                  {
                    text: downloadData.createShipmentDetail[0]?.SHIPEMAIL,
                    fontSize: 11,
                    style: 'data',
                    absolutePosition: { x: -130, y: 536 },
                  },
                ],
                {
                  margin: [10, -30, 0, 0],
                  canvas: [
                    {
                      type: 'line',
                      x1: 100,
                      y1: 20,
                      x2: -36,
                      y2: 20,
                      lineWidth: 1,
                      lineColor: 'black',
                    },
                  ],
                  marginBottom: 20,
                },
                {},
              ],
            ],
          },

          layout: {
            hLineWidth: function (_i: any, _node: any) {
              return 0;
            },
            vLineWidth: function (_i: any, _node: any) {
              return 0;
            },
          },
        },

        {
          text: 'Shipment Detail',
          fontSize: 18,
          bold: true,
          style: 'header',
          marginBottom: 15,
        },
        {
          fontSize: 10,
          bold: true,
          table: {
            body: [
              [
                'Authorization',
                'PO#',
                'Supplier',
                'Package Code',
                'Dunnage Code',
                'L',
                'W',
                'H',
                'Weight(kg)',
                'Part Number',
                'Description',
                'Ship.',
                'Recv.',
              ],
              [
                {
                  text: downloadData.createAuthList[0]?.AUTHNUMBER,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.PONUMBER,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.SUPPLIERNAME,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.PACKAGECODE,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.DUN1CODE,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.OUTERLENGRH,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.OUTERWIDTH,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.OUTERHEIGHT,
                  style: 'tabledata',
                },
                {
                  text: downloadData.TotalWeight,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.PARTNUMBER,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.PARTDESCRIPTION,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.SHIPQTY,
                  style: 'tabledata',
                },
                {
                  text: downloadData.createAuthList[0]?.RECVQTY,
                  style: 'tabledata',
                },
              ],
            ],
          },
        },

        {
          text: 'SHIPMENT RACK SERIAL NUMBER NOTICE ',
          pageBreak: 'before',
          alignment: 'center',
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10],
        },

        [
          [
            { text: 'Auth Number : ', style: 'tableHeader', bold: true },
            {
              text: downloadData.createAuthList[0]?.AUTHNUMBER,
              style: 'data',
              absolutePosition: { x: -100, y: 74 },
            },
          ],
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
            marginBottom: 5,
          },
          [
            { text: 'PO Number :', style: 'tableHeader', bold: true },
            {
              text: downloadData.createAuthList[0]?.PONUMBER,
              style: 'data',
              absolutePosition: { x: -100, y: 100 },
            },
          ],
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
            marginBottom: 5,
          },
          [
            { text: 'Package Code :', style: 'tableHeader', bold: true },
            {
              text: downloadData.createAuthList[0]?.PACKAGECODE,
              style: 'data',
              absolutePosition: { x: -90, y: 126 },
            },
          ],
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],

            marginBottom: 20,
          },

          {
            style: 'tableHeader',
            table: {
              headerRows: 1,
              body: [
                ['No', 'Rack Serial Number', 'Auth Number', 'PO Number'],
                ...(Array.isArray(downloadData?.rackSerial[0]?.serialString) ?
                  downloadData?.rackSerial[0]?.serialString.map((item: any, index: any) =>
                    [index + 1, item?.SERIALSTRING || '', downloadData.rackSerial[0].authNumber || '', downloadData.createAuthList[0]?.PONUMBER || '']) :
                  [])
              ]
            },

            layout: {
              hLineWidth: function (_i: any, _node: any) {
                return 0;
              },
              vLineWidth: function (_i: any, _node: any) {
                return 0;
              },
              hLineColor: function (_i: any, _node: any) {
                return 'black';
              },
              vLineColor: function (_i: any, _node: any) {
                return 'black';
              },
            },
          },
        ],
      ],
      styles: {
        data: {
          fontSize: 15,
          bold: false,
          alignment: 'center',
        },
        tabledata: {
          fontSize: 6,
        },
        tableHeader: {
          fontSize: 18,
          bold: true,
        },

      },
    };
    return dd;
  }
}
