import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'exchange-rate-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" [disabled]="isDisabed"
    [style.opacity]="isDisabed ? '0.5' : '1'"
    style="height:30px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class ExchangeRateBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute,) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  isDisabed =  false;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'UPDATE'){
      this.buttonName = 'UPDATE';
      this.isDisabed =  !this.params.data.p_usersave;
    }
    else if (params.colDef.headerName === 'DELETE'){
      this.buttonName = 'DELETE'; 
      this.isDisabed =  !this.params.data.p_userdelete;
    }
     
   
  }

  btnClickedHandler(_event: any) {
    if (this.isDisabed){
       return;
    }else{
    this.params.clicked(this.params.data)
    }
  }
}