import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateFabComponent } from '../../internal-vpacs-dialogs/authorize/update-fab/update-fab.component';
@Component({
  selector: 'app-pur-confab-list-cell-renderer',
  template: `
    <a *ngIf='params?.userInfo?.info?.title !=="4"'(click)="openDialog()" style="text-decoration:underline">{{ text }}</a>
    <span *ngIf='params?.userInfo?.info?.title === "4"' >{{ text }}</span>
  `,
})
export class PurConfabLinkCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  params: any;
  text: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'Cont Fab') {
      this.text = this.params.data.alphacode ? this.params.data.alphacode : '-';
    }
  }

  openDialog() {
    if (this.params.colDef.headerName === 'Cont Fab') {
      const dialogRef = this.dialog.open(UpdateFabComponent, {
        data: {
          id: this.params.data.authnumber,
          id1: this.params.data.alphacode,
        },
      });

      dialogRef.afterClosed()?.subscribe((result: any) => {
        if (result?.event === 'SaveEvent') {
          if (result?.responseType === "Success") {
            this.btnClickedHandler(this.params.data)

          } 
        } else if (result?.event === 'onDeletePress') {
          if (result?.responseType === true) {
            this.btnClickedHandler(this.params.data)
          }
        }
      });
    }
  }
  btnClickedHandler(_event: any) {
    this.params?.clicked(this.params.data);
  }
}
