import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { PlantService } from '../../services-internal/master-maintenance/plant.service'

@Component({
  selector: 'app-add-dock',
  templateUrl: './add-dock.component.html',
  styleUrls: ['./add-dock.component.scss'],
})
export class AddDockComponent implements OnInit {
  component = 'Select'
  dockCodeFlag = false
  dcodeFlag = false
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'
  dcode = ''
  plantCode = ''
  plantName = ''
  plantId = ''
  address: any
  isDockError = false
  errorList: any[] = []
  plant: any
  supplier: any

  constructor(
    public spinner : NgxUiLoaderService,
    public plantService: PlantService,
    public dialogRef: MatDialogRef<AddDockComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.dataStore.mode === 'ADD') {
      this.modalHeader = 'Add Dock'
      this.buttonValue = 'ADD'
      this.dockCodeFlag = true
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore.plantId
      this.address = this.dataStore.address
    } else if (this.dataStore.mode === 'UPDATE') {
      this.modalHeader = 'Update Dock'
      this.buttonValue = 'UPDATE'
      this.dcodeFlag = true
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore.plantId
      this.dcode = this.dataStore.field.dockcode
      this.address = this.dataStore?.field?.address
    }
  }

  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg, mode: 'X' })
  }

  checkValidations() {
    this.errorList = []
    this.isDockError = false

    if (!this.dcode) {
      this.isDockError = true
    }
  }

  saveData() {
    this.checkValidations()
    if (this.isDockError) {
      return
    }

    if (this.buttonValue === 'ADD') {
      setTimeout(() => {this.spinner.start();})
      const loadObj = {
        plantCode: this.plantCode,
        dockCode: this.dcode,
        address: this.address,
      }
      this.plantService.dockAdd(loadObj).subscribe({
        next: (data: any) => {
          if (data.rslt.error) {
            this.errorList.push(data.rslt.error)
          } else {
            this.dialogRef.close({ data: { result: 'SUCCESS', mode: 'Add', data } })
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();

        },
      })
    } else if (this.buttonValue === 'UPDATE') {
      const loadObj = {
        PlantCode: this.plantCode,
        dockCode: this.dcode,
        address: this.address,
      }
      setTimeout(() => {this.spinner.start()});
      this.plantService.dockUpdate(loadObj).subscribe({
        next: (data) => {
          const result = data?.rslt ? 'SUCCESS' : '';
          this.dialogRef.close({ data: { result: result, mode: 'Update', data } })
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        },
      })
    } else {
      this.close('')
    }
  }

  help() {
    this.dialogRef.close()
    if (this.modalHeader === 'Add Dock') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 132])
    } else if (this.modalHeader === 'Update Dock') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 133])
    }
  }
}
