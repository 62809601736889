import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  savedPPFs: any = {}
  public validUser: any;
  public authorityListUpdateData: any[] = [];
  public authorityListData: any[] = [];
  public adminFilters: any;
  trackingFilters: any;
  pkgAuthFilters: any;
  shipmentFilters: any;
  suppliercode: any[] = [];
  //ppf new package
  newPackage: any;
  //column
  trackingFilterModel: any;
  trackingSortModel: any;
  pkgFilterModel: any;
  ubModel: any;
  authFilter: any;
  securityInfo: any[] = [];
  trackingRowData: { [key: string]: any } = {};
  trackingSteps: any[] = [];
  adminAccessData: any[] = [];
  trackingSubmitted = false;
  grid2Data: any;
  grid1Data: any;
  imgNum: any[] = [];
  imgData: any[] = [];
  hazmat: any;
  previousURL = '';
  currentURL = '';

  constructor(private readonly router: Router) {
    this.currentURL = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.previousURL = this.currentURL;
      this.currentURL = event.url
    })
  }

  getPreviousURL():string{
    return this.previousURL;
  }

  getAdminAccessData() {
    return this.adminAccessData;
  }
  setAdminAccessData(data: any[]) {
    this.adminAccessData = data;
  }
  getSecurityInfo() {
    return this.securityInfo;
  }
  setTrackingRowData(data: any[], supplier: string) {
    this.trackingRowData[supplier] = data;
  }
  setTrackingSteps(data: any[]) {
    this.trackingSteps = data;
  }
  getTrackingSteps() {
    return this.trackingSteps;
  }
  getTrackingRowData(supplier: string) {
    return this.trackingRowData[supplier];
  }
  setSecurityInfo(data: any[]) {
    this.securityInfo = data;
  }
  getSuppliercode() {
    return this.suppliercode;
  }
  setSupplierCode(data: any[]) {
    this.suppliercode = data;
  }
  setValidUser(value: any) {
    this.validUser = value;
  }

  checkValidUser() {
    return this.validUser;
  }

  getAuthorityListUpdateData() {
    return this.authorityListUpdateData;
  }

  checkIfAlreadyexists(data: any) {
    const index = this.authorityListUpdateData.findIndex((x: any) => x.TOYOTAGUID === data.TOYOTAGUID && x.type === data.type && x.SUPPLIERCODE === data.SUPPLIERCODE);
    if (index !== -1) {
      this.authorityListUpdateData[index] = data;
    } else {
      this.authorityListUpdateData.push(data)
    }
  }

  setAuthorityListUpdateData(stateData: any) {
    this.checkIfAlreadyexists(stateData);
  }

  getAuthorityData() {
    return this.authorityListData;
  }

  setAuthorityData(ddata: any[]) {
    this.authorityListData = ddata;
  }

  clearAuthorityListUpdateData() {
    this.authorityListUpdateData = [];
  }

  //trigger onload tracking API if the data is submitted
  setTrackingSubmitted(data: boolean) {
    this.trackingSubmitted = data;
  }
  getTrackingSubmitted() {
    return this.trackingSubmitted
  }

  resetFilters() {
    this.trackingFilters = '';
    this.pkgAuthFilters = '';
    this.shipmentFilters = '';
    this.trackingFilterModel = '';
    this.pkgFilterModel = '';
    this.ubModel = '';
    this.authFilter = '';
  }

  setAdminFilters(adminFilters: any) {
    this.adminFilters = adminFilters;
  }
  getAdminAccessFilters() {
    this.trackingFilters = '';
    return this.adminFilters;
  }

  setTrackingFilters(trackingFilters: any) {
    this.trackingFilters = trackingFilters;
  }
  getTrackingFilters() {
    return this.trackingFilters;
  }

  setPkgAuthFilters(pkgAuthFilters: any) {
    this.pkgAuthFilters = pkgAuthFilters;
  }
  getPkgAuthFilters() {
    return this.pkgAuthFilters;
  }

  setShipmentFilters(shipmentFilters: any) {
    this.shipmentFilters = shipmentFilters;
  }
  getShipmentFilters() {
    return this.shipmentFilters;
  }

  setNewPackagePPF(data: any) {
    this.newPackage = data;
  }
  getNewPackagePPF() {
    return this.newPackage;
  }
  resetNewPackagePPF() {
    this.newPackage = '';
  }

  //column filters
  trackingPage(filterModel: any) {
    this.trackingSortModel = filterModel;
  }
  getTrackingPage() {
    return this.trackingSortModel;
  }

  setTrackingSort(filterModel: any) {
    this.trackingFilterModel = filterModel;
  }
  getTrackingSort() {
    return this.trackingFilterModel;
  }

  ubPage(filterModel: any) {
    this.ubModel = filterModel;
  }
  getUbpage() {
    return this.ubModel;
  }

  pkgPage(filterModel: any) {
    this.pkgFilterModel = filterModel;
  }
  getPkgPage() {
    return this.pkgFilterModel;
  }

  authListPage(filterModel: any) {
    this.authFilter = filterModel;
  }
  getAuthListPage() {
    return this.authFilter;
  }

  getSavedPPFs() {
    return this.savedPPFs
  }
  setSavedPPFs(id: string | number, body: any) {
    this.savedPPFs[id] = body
  }

  //ppf
  setGrid2Data(data: any) {
    this.grid2Data = data;
  }
  getGrid2Data() {
    return this.grid2Data;
  }
  setGrid1Data(data: any) {
    this.grid1Data = data;
  }
  getGrid1Data() {
    return this.grid1Data;
  }
  resetgrid1() {
    this.grid1Data = ''
  }
  resetgrid2() {
    this.grid2Data = ''
  }
  setImgNum(data: any) {
    this.imgNum.push(data);
  }
  getImgNum() {
    return this.imgNum;
  }
  setImgData(data: any) {
    this.imgData?.push(data);
  }
  getImgData() {
    return this.imgData;
  }
  setHazmat(data: any) {
    this.hazmat = data;
  }
  getHazmat() {
    return this.hazmat;
  }
}
