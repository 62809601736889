<div class="wrapper bg-white">
    <p class="page-title p-1 px-3">Rack Shipment</p>
    <div class="content">
        <div class="d-flex flex-wrap px-3 py-3">
            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
                <label for="packageCode" class="me-3">Package Code</label>
                <input type="text" id="packageCode" [(ngModel)]="fieldData.packagecode" />
            </div>
            <div class="d-flex align-items-center  col-lg-4 col-md-6 col-12 my-2">
                <label for="dunnage" class="me-3">Dunnage</label>
                <input type="text" id="dunnage" [(ngModel)]="fieldData.dunnage1code" />
            </div>
            <div class="d-flex align-items-center justify-content-end col-lg-4 col-md-6 col-12">
                <button class="vpacs-btn-table mx-2" (click)="dialogOpen()">ADD</button>
            </div>
        </div>
        <div class="grid-agg grid-height col-12 px-3">
            <ag-grid-angular style="width:100%;height:100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true"
                [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40" [floatingFiltersHeight]="40">
            </ag-grid-angular>
        </div>
    </div>
    <div class="footer-dialog d-flex p-2">
        <div class="mx-3 cancel" (click)="cancel()">Cancel</div>
        <button class=" mx-3 save" (click)="save()">Save</button>
    </div>
</div>