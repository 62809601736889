import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImportModelListComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/import-model-list/import-model-list.component';
import { ModelListAddComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/model-list-add/model-list-add.component';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver-es';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-model-list-cell-renderer',
  template: `
    <div class="d-flex align-items-center gap-1" *ngIf="this.params.data">
      <button
        [disabled]="isButtonDisabled"
        class="vpacs-btn-table"
        style="height:30px;"
        (click)="btnClickedHandler($event)"
      >
        {{ buttonName }}
      </button>
    </div>
  `,
})
export class ModelCellRendererComponent implements ICellRendererAngularComp {
  ringinumber: any;
  ringi: any;
  displayRowData: any[] = [];
  res: any;
  rowCount: any;
  isButtonDisabled = false;
  deleteBtn: any;
  haveAccess: any;
  haveAddAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  costReport = 'COST REPORT';

  constructor(
    private readonly router: Router,
    public message: MatSnackBar,
    private readonly state: ExportStateService,
    private readonly service: ModelListService,
    public dialog: MatDialog,
    private readonly spinnerService: NgxUiLoaderService
  ) {
  }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  public params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    this.getUserData();
    this.deleteBtnDisable();
    if (this.params.colDef.headerName === 'CONNECT') {
      this.buttonName = 'CONNECT';
    } else if (this.params.colDef.headerName === 'PART LIST') {
      this.buttonName = 'LIST';
    } else if (this.params.colDef.headerName === this.costReport) {
      this.buttonName = 'REPORT';
    } else if (this.params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
    } else if (this.params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
    } else if (this.params.colDef.headerName === 'IMPORT') {
      this.buttonName = 'IMPORT';
    } else if (this.params.colDef.headerName === 'SEND ALL') {
      this.buttonName = 'SEND ALL';
      this.isButtonDisabled = true;
    } else if (this.params.colDef.headerName === 'COMPARE') {
      this.buttonName = 'COMPARE';
    } else if (this.params.colDef.headerName === 'SELECT') {
      this.buttonName = 'SELECT';
      this.isButtonDisabled = true;
    }
  }

  deleteBtnDisable() {
    if (
      Number(
        this.params.node?.data
          ? this.params.node.data?.used
          : this.params.node?.allLeafChildren[0]?.data?.used
      ) > 0
    ) {
      if (this.params.colDef.headerName === 'DELETE') {
        this.isButtonDisabled = !this.deleteAccess;
      }
    }
  }
  getUserData() {
    const list = 'PART LIST';
    const report = this.costReport;
    const connect = 'CONNECT';
    const update = 'UPDATE';
    const importbtn = 'IMPORT';
    const deletebtn = 'DELETE';
    const sendall = 'SEND ALL';
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.haveAccess = data.role?.p_trackingview;
        this.haveAddAccess = data.role?.p_trackingsave;
        this.modifyAccess = data.role?.p_trackingcorrect;
        this.deleteAccess = data.role?.p_trackingdelete;
        if ((this.haveAccess && this.haveAddAccess) || (this.haveAccess && this.haveAddAccess && this.modifyAccess)) {
          if (this.params.colDef.headerName === connect || this.params.colDef.headerName === update ||
            this.params.colDef.headerName === list || this.params.colDef.headerName === report ||
            this.params.colDef.headerName === importbtn) {
            this.isButtonDisabled = false;
          }
        } 
        else if((this.haveAccess && this.deleteAccess) || (this.haveAccess && this.deleteAccess && this.modifyAccess)){
          if (this.params.colDef.headerName === connect || this.params.colDef.headerName === update ||
            this.params.colDef.headerName === sendall || this.params.colDef.headerName === importbtn) {
            this.isButtonDisabled = true;
          }
        }
        
        else if ((this.haveAccess) || (this.haveAccess && this.modifyAccess)) {
            if (this.params.colDef.headerName === connect || this.params.colDef.headerName === update ||
              this.params.colDef.headerName === deletebtn || this.params.colDef.headerName === importbtn) {
              this.isButtonDisabled = true;
            }
          }
      }
    });
  }

  btnClickedHandler(_$event: any) {
    const report = this.costReport;
    if (this.params.colDef.headerName === 'CONNECT') {
      const connectParams = {
        modelCode: this.params.data.modelcode,
        plantCode: this.params.data.plantcode,
      };
      sessionStorage.setItem('connectParams', JSON.stringify(connectParams));
      const connectURL = '/model-list/connect';
      this.router.navigateByUrl(connectURL);
    } else if (this.params.colDef.headerName === 'PART LIST') {
      this.spinnerService.start();
      const partlistObject = {
        modelCode: this.params.data.modelcode,
        plantCode: this.params.data.plantcode,
      };
      const modelcode = this.params.data.modelcode;
      this.service.partListDownload(partlistObject).subscribe({
        next: (response: any) => {
          const toConvert = response.data;
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
          setTimeout(() => {
            fileSaver.saveAs(file, 'PartList_' + modelcode);
          });
          this.spinnerService.stop();
        },
        error: () => {
          this.spinnerService.stop();
        },
      });
    } else if (this.params.colDef.headerName === report) {
      this.spinnerService.start();
      this.service.reportListDownload(this.params.data.ringinumber).subscribe({
        next: (response: any) => {
          this.res = response;
          const modelcode = this.params.data.modelcode;
          const plantcode = this.params.data.plantcode;
          const toConvert = this.res.data;
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
          setTimeout(() => {
            fileSaver.saveAs(file, `${modelcode}${plantcode}_CostReport`);
          });
          this.spinnerService.stop();
        },
        error: () => {
          this.spinnerService.stop();
        },
      });
    }
    this.btnCellRenderer();
   }


btnCellRenderer(){
 if (this.params.colDef.headerName === 'UPDATE') {
    const dialogRef = this.dialog.open(ModelListAddComponent, {
      data: {
        mode: 'Update',
        result: this.params.data,
      },
    });
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'SaveEvent') {
        if (result?.responseType > 0) {
          this.params.reloadData();
        }
      }
    });
  }
  else if (this.params.colDef.headerName === 'DELETE') {
    this.spinnerService.start();
    const dialogRef = this.dialog.open(ModelListAddComponent, {
      data: {
        mode: 'Delete',
        result: this.params.data,
      },
    });
    this.spinnerService.stop();
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result.data === 'SUCCESS') {
        this.params.reloadData();
      }
    });
  } else if (this.params.colDef.headerName === 'IMPORT') {
    this.spinnerService.start();
    this.dialog.open(ImportModelListComponent, {
      data: {
        modelcode: this.params.data.modelcode,
        plantcode: this.params.data.plantcode,
      },
    });
    this.spinnerService.stop();
  } else if (this.params.colDef.headerName === 'SEND ALL') {
    this.isButtonDisabled = true;
  }

}
}
