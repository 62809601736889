<div class="d-flex justify-content-between align-items-center m-3">
  <div class="page-title">Dispose Package</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="mx-3 p-2 header">AUTHORIZATION FORM</div>
<div class="alert alert-danger m-3" *ngIf="errorList.length>0">
  <ng-container *ngFor="let error of errorList ">
    <div>{{error}}</div>
  </ng-container>
</div>
<div class="form" [formGroup]="disposeForm">
  <div class="top-arr d-flex flex-wrap m-3">
    <div class="top-arr-left col-md-4 col-12 d-flex align">
      <div class="left d-flex flex-column col-5 header">
        <span>Plant</span>
        <span>Part #</span>
        <span>Supplier</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>{{response?.PlantName[0]?.name}}</span>
        <span>{{routeParams?.part}}</span>
        <span>{{response?.SupplierName[0]?.shortname}}</span>
      </div>
    </div>
  </div>
  <div class="main-content d-flex flex-wrap m-3">
    <div class="main-left col-lg-4 col-md-6 col-12 d-flex">
      <div class="left d-flex flex-column col-5 header">
        <span>Ringi #</span>
        <span>Ringi Type</span>
        <span>Auth Type</span>
        <span>Reason</span>
        <span>Actual Date (mm/dd/yyyy)</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering formControlName="ringi">
            <mat-option value="">Select One</mat-option>
            <mat-option *ngFor="let x of ringiList" [value]="x.ringinumber">{{x.ringinumber}}</mat-option>
          </mat-select>
        </span>
        <span>-</span>
        <span>Dispose</span>
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering formControlName="reason">
            <mat-option value="">Select One</mat-option>
            <mat-option *ngFor="let x of reasonList" [value]="x.id">{{x.reason}}</mat-option>
          </mat-select>
        </span>
        <span><input type="date" style="width: 100%" formControlName="actualDate"/></span>
      </div>
    </div>
    <div class="right d-flex flex-column col-lg-8 col-md-12 col-12 ps-4">
      <div class="main-right" style="overflow-x: auto;">
        <table class="tg w-100" aria-describedby="table1">
          <thead>
            <tr>
              <th class="tg-mg6y"></th>
              <th class="tg-mg6y">Package Code</th>
              <th class="tg-mg6y">Move Qty</th>
              <th class="tg-mg6y">Cost/Piece</th>
              <th class="tg-mg6y">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tg-mg6y">Package</td>
              <td class="tg-z5to">{{package1}}</td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="package2"/>
              </td>
              <td class="tg-z5to" colspan="2">
                <button class="vpacs-btn-table" (click)="calcPackage()">Calc Package</button>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Container</td>
              <td class="tg-eydr">{{container1}}</td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container2"/>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container3"/>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container4"/>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Dunnage1</td>
              <td class="tg-z5to">{{dunnage11}}</td>
              <td class="tg-z5to">-</td>
              <td class="tg-z5to">-</td>
              <td class="tg-z5to">-</td>
            </tr>
            <tr>
              <td class="tg-mg6y">Dunnage 2</td>
              <td class="tg-eydr">{{dunnage21}}</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
            </tr>
            <tr>
              <td class="tg-mg6y">Other</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="other4"/>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="ppf-footer col-12 d-flex align-items-center flex-wrap p-2">
          <span>
            Total
            <input type="text" formControlName="grandTotal" class="mx-3"/>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="mx-3">
    <div class="d-flex col-md-4 align-items-baseline px-0 pe-1">
      <label for="details" class="control-label col-md-4"
        >Comment For Supplier</label
      >
      <textarea
        name=""
        id="details"
        cols="170"
        rows="3"
        class="form-control detail_area ms-3 pt-0"
        formControlName="commentSupplier"
      ></textarea>
    </div>
    <div class="d-flex col-md-4 align-items-baseline px-0 pe-1 mt-3 mb-5">
      <label for="details" class="control-label col-md-4"
        >Internal Use Only</label
      >
      <textarea
        name=""
        id="details"
        cols="170"
        rows="3"
        class="form-control detail_area ms-3 pt-0"
        formControlName="internal"
      ></textarea>
    </div>
  </div>
</div>

<div
  class="
    create_footer
    d-flex
    justify-content-end
    align-items-center
    fixed-bottom
  "
>
  <div (click)="onBack()" class="mx-3 text-primary link">BACK</div>
  <button class="black-button me-3 roboto-medium" (click)="onSubmit()">
    AUTHORIZE
  </button>
</div>
