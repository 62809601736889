<div class="popup-container">
    <header>
        <label class="close black-button" (click)="onNoClick()">X</label>

        <div class="d-flex justify-content-between align-items-center m-2 header">
            <div class="page-title">Add Part</div>
            <div>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
        <div  id='err' class="alert alert-danger mx-2 mt-2" *ngIf="errorList?.length > 0">
            <span  >{{errorList}}</span>
          </div>
    </header>

    <div class="popup-contents">
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label for="model" class="catlabel col-5">Model<span style="color:#ff0000">*</span></label>

                <div class="mandatory-message">
                    <input type="text" id="model" name="model" [(ngModel)]="model" [required] ="showRequiredMessage" >


                    <small *ngIf="showRequiredMessage">Model is required</small>
                </div>
            </div>

            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partnumber">Part Number<span style="color:#ff0000">*</span></label>
                <input type="text" id="partnumber" [(ngModel)]="partnumber" disabled>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partlength">Part Length(mm)</label>
                <input type="text" id="partlength" [(ngModel)]="partlength">

            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partwidth">Part Width(mm)</label>
                <input type="text" id="partwidth" [(ngModel)]="partwidth">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partheight">Part Height(mm)</label>
                <input type="text" id="partheight" [(ngModel)]="partheight">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partweight">Part Weight(kg)</label>
                <input type="text" id="partweight" [(ngModel)]="partweight">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="suppliercode">Supplier Code<span
                        style="color:#ff0000">*</span></label>
                <input type="text" id="suppliercode" [(ngModel)]="suppliercode" disabled>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partdesc">Part Description</label>
                <input type="text" id="partdesc" [(ngModel)]="partdesc">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partrouting">Part Routing</label>
                <input type="text" id="partrouting" [(ngModel)]="partrouting">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="preppf">Pre PPF Due</label>
                <input type="date" id="preppf" [(ngModel)]="preppf" (keydown)="onKeyDown($event,'preppf')">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="pkgtrail">Package Trial Due</label>
                <input type="date" id="pkgtrail" [(ngModel)]="pkgtrail" (keydown)="onKeyDown($event,'pkgtrail')">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="pkgauthorised">Package Authorized Due</label>
                <input type="date" id="pkgauthorised" [(ngModel)]="pkgauthorised" (keydown)="onKeyDown($event,'pkgauthorised')">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="readinessdue">Readiness Due</label>
                <input type="date" id="readinessdue" [(ngModel)]="readinessdue" (keydown)="onKeyDown($event,'readinessdue')">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="pkgstart">Package Start Date</label>
                <input type="date" id="pkgstart" [(ngModel)]="pkgstart" (keydown)="onKeyDown($event,'pkgstart')">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="qpu">QPU<span style="color:#ff0000">*</span></label>
                <div class="mandatory-message">
                    <input type="text" id="qpu" name="qpu" [(ngModel)]="qpu"  [required] ="showRequiredMessage">
                    <small *ngIf="showRequiredMessage">QPU is required</small>
                </div>
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="specialistcode">Specialist Code</label>
                <input type="text" id="specialistcode" [(ngModel)]="specialistcode">
                <button mat-icon-button>
                    <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
                </button>
            </div>

        </div>


        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
            <button class="submit black-button me-3 roboto-bold" (click)="onLoad()">ADD</button>
        </div>
    </div>

</div>