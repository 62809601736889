<div class="col-12">
  <table class="tg mx-3 col-11" aria-describedby="confirm-import">
    <thead>
      <tr>
        <th class="tg-mg6y" colspan="3">Total Records</th>
        <th class="tg-z5to">x</th>
        <th class="tg-z5to" colspan="2"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-mg6y" rowspan="4">New</td>
        <td class="tg-v2d4" rowspan="2">isn't exist in application</td>
        <td class="tg-mg6y">Part add</td>
        <td class="tg-gau3">x</td>
        <td class="tg-gau3"></td>
      </tr>
      <tr>
        <td class="tg-mg6y">part ignore</td>
        <td class="tg-z5to">x</td>
        <td class="tg-z5to"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" rowspan="2">exist in other model</td>
        <td class="tg-mg6y">same part pkg date</td>
        <td class="tg-gau3">x</td>
        <td class="tg-gau3">
          y
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y">diff pkg start date</td>
        <td class="tg-z5to">x</td>
        <td class="tg-z5to">
          y
        </td>
      </tr>
      <tr>
        <td class="tg-n2z5" rowspan="4">Update</td>
        <td class="tg-mg6y" colspan="2">part replacement</td>
        <td class="tg-gau3">x</td>
        <td class="tg-phrp"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Supplier Replacement</td>
        <td class="tg-z5to">x</td>
        <td class="tg-z5to"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Same Pkg Start Date</td>
        <td class="tg-gau3">x</td>
        <td class="tg-gau3"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Diff Pkg Start Date</td>
        <td class="tg-z5to">x</td>
        <td class="tg-z5to" >
          y
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="3">
          Delete part (Not exist in part list)
        </td>
        <td class="tg-gau3">x</td>
        <td class="tg-gau3"></td>
      </tr>
    </tbody>
  </table>
</div>