import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TrackingService } from 'src/app/services/tracking.service';
import { UpdatePpfComponent } from '../dialogs/update-ppf/update-ppf.component';
import { MessagePopupToastComponent } from '../message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
	selector: 'tracking-action-btn-cell-render',
	template: `
    <div class="d-flex align-items-center gap-1">
        <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center " 
        style="font-size: 21px !important;" title="Do"
        *ngIf = "this.params.data.doFlag"
        (click)="doTracking()"
        >check_circle</mat-icon>

        <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center"
        style="font-size: 21px !important;" title="Submit"
        *ngIf = "this.params.data.submitFlag"
        (click)="submitTracking()"
        >upload</mat-icon>

        <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center"
        style="font-size: 21px !important;" title="PPF"
        (click)="openPPF()"
        *ngIf = "this.params.data.updatePPFFlag"
        >description</mat-icon>
    </div>
    `,
})

export class TrackingActionBtnCellRender implements ICellRendererAngularComp {
    whichRequest:any = 0;
    constructor(public router: Router, 
        public _location: Location,  
        private readonly dialog: MatDialog,
        public route: ActivatedRoute,
        public trackingService:TrackingService,
        public message: MatSnackBar,
        public globalState : GlobalStateService
        ){}
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	public params: any;

	agInit(params: any): void {
		this.params = params;
        if(params.data.stepNumber !== 11){
            if(params.data.supOrFab === "0") {
                if(params.data?.SUPPLIERCODE === params.data?.REQUEST_TO) {
                    this.whichRequest = 0;
                } else {
                    this.whichRequest = 1;
                }
            } else {
                if(params.data?.FABCODE === params.data?.REQUEST_TO) {
                    this.whichRequest = 1;
                }
            }
        }
	}

    openPPF(){  
        const query:any = {
            'plantCode': this.params.data.PLANTCODE, 
            'partNumber': this.params.data.PARTNUMBER,
            'supplierCode' : this.params.data.SUPPLIERCODE,  
            'pkgStartDate': this.params.data.PKGSTARTDATE,  
          };
        sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
        this.dialog.open(UpdatePpfComponent,{data: this.params.data, width: '75%'});   
    }

    openMessageBox(message:string, type: string){
        this.message.openFromComponent(MessagePopupToastComponent, {
          duration: 3000,
          data: {
            type: type,
            message: message
          }
        })
      }

    submitTracking(){
        const queryParams:any = {}; 
        const body:any = {}
        if(localStorage.getItem('toyotaGUID')){
            body['guid'] = localStorage.getItem('toyotaGUID');
        }
        const {PARTNUMBER,PLANTCODE,StepNo, SUPPLIERCODE, PKGSTARTDATE,MODELCODE} = this.params.data
            queryParams['partNumber'] = PARTNUMBER;
            queryParams['pkgStartDate'] = PKGSTARTDATE;
            queryParams['supplierCode'] = SUPPLIERCODE;
            queryParams['modelCode'] = MODELCODE;
            queryParams['plantCode'] = PLANTCODE;
            queryParams['stepNumber'] = StepNo;
        
        body.guid && this.trackingService.trackingSubmit(body,queryParams).subscribe({
            next: (_response:any) =>{
                this.globalState.setTrackingSubmitted(true);
                this.openMessageBox('Submitted Successfully','success')
            },
            error: (_error) => {
                this.openMessageBox('Error while submitting','error')
            },
            complete: ()=>{
                this.router.navigateByUrl('/', {skipLocationChange : true}).then(()=>{
                    this.router.navigate(['/tracking'])
                })  
            }
        })
    }
    doTracking() {
        const step = this.params.data.stepNumber;
        sessionStorage.setItem('PartNumberPkg',this.params.data.PARTNUMBER);
        sessionStorage.setItem('ModelCodePkg',this.params.data.MODELCODE);  
        switch (step) {
            case 12:
                const query12: any = {
                    'plantCode': this.params.data.PLANTCODE,
                    'partNumber': this.params.data.PARTNUMBER,
                    'supplierCode': this.params.data.SUPPLIERCODE,
                    'pkgStartDate': this.params.data.PKGSTARTDATE,
                    'modelCode': this.params.data.MODELCODE,
                    'ppfNumber': this.params.data.PPFCODE,
                    'authStatus': this.params.data.READINESSSTATUS,
                    'cancelFlag': this.params.data.VALIDFLAG,
                    'supOrFab': this.params.data.supOrFab,
                    'whichRequest': this.params.data.whichRequest,
                    'ISUPDATEPPF': this.params.data?.ISUPDATEPPF,
                    'stepNumber': this.params.data?.stepNumber,
                    'authNumber': this.params.data.AUTHNUMBER,
                    'fabcode': this.params.data.FABSUP,
                    'dun1code': this.params.data.DUN1FABSUP,
                };
                sessionStorage.setItem('queryParamsReadiness', JSON.stringify(query12));
                this.router.navigate(['/tracking/do-readiness']);
                break;
            case 3:                
                const query3: any = {
                    'plantCode': this.params.data.PLANTCODE,
                    'partNumber': this.params.data.PARTNUMBER,
                    'supplierCode': this.params.data.SUPPLIERCODE,
                    'pkgStartDate': this.params.data.PKGSTARTDATE,
                    'modelCode': this.params.data.MODELCODE,
                    'ppfNumber': this.params.data.PPFCODE,
                    'gridDate': this.params.data.gridDate,
                    'ISUPDATEPPF': this.params.data?.ISUPDATEPPF,
                    'stepNumber': this.params.data?.stepNumber,
                    'statusNumber': this.params.data?.statusNumber,
                    'WORKING': this.params.data?.WORKING,
                    'whichRequest': this.params.data.whichRequest,
                    'supOrFab': this.params.data?.supOrFab,
                    'cancelFlag': this.params.data.VALIDFLAG,
                    'authNumber': this.params.data.AUTHNUMBER,
                    'fabcode': this.params.data.FABSUP,
                    'dun1code': this.params.data.DUN1FABSUP,
                };
                sessionStorage.setItem('queryParamsSendPrePpf', JSON.stringify(query3));
                this.router.navigate(['/tracking/do-sendPrePpf']);
                break;
            case 6:
                const query6: any = {
                    'plantCode': this.params.data.PLANTCODE,
                    'partNumber': this.params.data.PARTNUMBER,
                    'supplierCode': this.params.data.SUPPLIERCODE,
                    'pkgStartDate': this.params.data.PKGSTARTDATE,
                    'modelCode': this.params.data.MODELCODE,
                    'ppfNumber': this.params.data.PPFCODE,
                    'ISUPDATEPPF': this.params.data?.ISUPDATEPPF,
                    'statusNumber': this.params.data?.statusNumber,
                    'WORKING': this.params.data?.WORKING,
                    'whichRequest': this.params.data.whichRequest,
                    'supOrFab': this.params.data?.supOrFab,
                    'cancelFlag': this.params.data.VALIDFLAG,
                    'authNumber': this.params.data.AUTHNUMBER,
                    'stepNumber': this.params.data?.stepNumber,
                    'fabcode': this.params.data.FABSUP,
                    'dun1code': this.params.data.DUN1FABSUP,
                };
                sessionStorage.setItem('queryParamsShipTrial', JSON.stringify(query6));
                this.router.navigate(['/tracking/do-shipTrail']);
                break;
            case 11: 
                const query11: any = {
                    'plantCode': this.params.data.PLANTCODE,
                    'partNumber': this.params.data.PARTNUMBER,
                    'supplierCode': this.params.data.SUPPLIERCODE,
                    'pkgStartDate': this.params.data.PKGSTARTDATE,
                    'modelCode': this.params.data.MODELCODE,
                    'ppfNumber': this.params.data.PPFCODE,
                    'gridDate': this.params.data.gridDate,
                    'authStatus': this.params.data.FORECASTSTATUS,
                    'cancelFlag': this.params.data.VALIDFLAG,
                    'supOrFab': this.params.data.supOrFab,
                    'whichRequest': this.params.data.whichRequest,
                    'validFlag': this.params.data.VALIDFLAG,
                    'authNumber': this.params.data.AUTHNUMBER,
                    'ISUPDATEPPF': this.params.data?.ISUPDATEPPF,
                    'stepNumber': this.params.data?.stepNumber,
                };
                sessionStorage.setItem('queryParamsForecast', JSON.stringify(query11));
                this.router.navigate(['/tracking/do-forecast']);
                break;
            case 8:

            const query8: any = {
                    'plantCode': this.params.data.PLANTCODE,
                    'partNumber': this.params.data.PARTNUMBER,
                    'supplierCode': this.params.data.SUPPLIERCODE,
                    'pkgStartDate': this.params.data.PKGSTARTDATE,
                    'modelCode': this.params.data.MODELCODE,
                    'ppfNumber': this.params.data.PPFCODE,
                    'ISUPDATEPPF': this.params.data?.ISUPDATEPPF,
                    'gridDate': this.params.data.gridDate,
                    'stepNumber': this.params.data?.stepNumber,
                    'statusNumber': this.params.data?.statusNumber,
                    'WORKING': this.params.data?.WORKING,
                    'cancelFlag': this.params.data.VALIDFLAG,
                    'whichRequest': this.params.data.whichRequest,
                    'supOrFab': this.params.data?.supOrFab,
                    'authNumber': this.params.data.AUTHNUMBER,
                    'STEP03STATUS': this.params.data.STEP03STATUS,
                    'fabcode': this.params.data.FABSUP,
                    'dun1code': this.params.data.DUN1FABSUP,
                    'loggedIn' : this.params.data.loggedIn
                };
                sessionStorage.setItem('queryParamsFinalPpf', JSON.stringify(query8));
                this.router.navigate(['/tracking/do-finalPpf']);
                break;
            default: 
        }
        
    }

  }