import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UseBackupServiceService } from 'src/app/services/use-backup-service.service';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
@Component({
  selector: 'app-notify-update-tracking',
  templateUrl: './notify-update-tracking.component.html',
  styleUrls: ['./notify-update-tracking.component.scss']
})
export class NotifyUpdateTrackingComponent implements OnInit {
  protected openModal = false;
  public ERR_ARR:any[]=[]
  public dropDownReason: any ;
  public metaData: any = {} ;
  public accData:any = {};
  public supplierDetails:any = {};
  public returnablePackageData:any = {};
  public pageName ='';
  public navData:any ;
  public submit:any ;
  public enhancementForm:any;
  public validForm = true;
  public validEnhancementForm = true;
  prePopulatedData: any;
  queryParams:any;
  childData:any ;
  authdata: any;
  dateValidationError:any = ''
  q3Check = false;
  q4Check = false;
  q6Check = false;
  q7Check = false;
  q8Check = false;
  q9Check = false;
  q10Check = false;
  q11Check = false;
  q13Check = false;
  q14Check = false;
  q15Check = false;
  supplierSubmission : any;
  supplierCode: any;
  supplierName: any;
  suppliercontactName: any;
  suppliercontactNo: any;
  suppliercontactEmail: any;
  supplierPlantCode: any;
  returnablePkgCode: any;
  returnableDunnage: any;
  returnablePartNumber: any;
  constructor(public router: Router, 
    public route: ActivatedRoute, 
    public _location: Location, 
    public useBackupService: UseBackupServiceService,
    public formbuilder : FormBuilder,
    public message: MatSnackBar) {
    this.submit = this.formbuilder.group(
      {
        responsibility: ['',Validators.required],
        reason: ['',Validators.required],
        reasonDetail: ['',Validators.required],
        startDate: ['',[Validators.required]],
      }
    )

    this.submit.valueChanges.subscribe((_value:any)=>{
      this.validForm = !this.submit.valid;
    })
  }

  ngOnInit(): void {
    this.metaData = JSON.parse(sessionStorage.getItem('queryParams') || '{}');
    this.authdata = JSON.parse(sessionStorage.getItem('authData') || '{}'); 
    this.pageName = this.route.snapshot.params['pn'];              
    this.metaData['pkgStartDate'] = new Date(this.metaData.pkgStartDate).toLocaleDateString('fr-CA').replace(/-/g, '/');

    if(this.pageName !== 'update'){
      this.useBackupService.notifyOnLoad(this.metaData)
      .subscribe({
        next: (data)=>{     
            this.navData = data; 
            this.accData = this.navData.ppf;
            this.dropDownReason = this.navData.reasons;
            this.supplierDetails = this.navData.SupplierInfo;
            this.returnablePackageData = this.navData.ReturnablePackageData;
            this.setSupplierReturnablePackageValues();         
        },
        error: (_err)=>{
          this.openMessageBox('Error while loading data','error')
        },
      });
      this.setDefaultValues();
    }else{
      this.useBackupService.updateOnLoad(this.authdata)
      .subscribe({
       next: (data)=>{       
          this.navData = data; 
          this.accData = this.navData.ppf;
          this.dropDownReason = this.navData.reasons;
          this.prePopulatedData = this.navData;
          this.supplierDetails = this.navData.SupplierInfo;
          this.returnablePackageData = this.navData.ReturnablePackageData;
          this.setSupplierReturnablePackageValues();
          this.setValues(this.prePopulatedData);
        },
        error: (_err)=>{
           this.openMessageBox('Error while loading data','error');      
        },
    });
    }
  }

  setSupplierReturnablePackageValues(){
    this.supplierSubmission = this.supplierDetails?.submissiondate ? this.supplierDetails?.submissiondate: '-';
    this.supplierCode = this.supplierDetails?.suppliercode ? this.supplierDetails?.suppliercode: '-';
    this.supplierName = this.supplierDetails?.suppliername ? this.supplierDetails?.suppliername : '-';
    this.suppliercontactName = this.supplierDetails?.suppliercontactname ? this.supplierDetails?.suppliercontactname: '-' ;
    this.suppliercontactNo = this.supplierDetails?.suppliercontactphonenumber ? this.supplierDetails?.suppliercontactphonenumber : '-';
    this.suppliercontactEmail = this.supplierDetails?.suppliercontactemail ? this.supplierDetails?.suppliercontactemail: '-';
    this.supplierPlantCode = this.supplierDetails?.plantcode ?  this.supplierDetails?.plantcode : '-';
    this.returnablePkgCode = this.returnablePackageData?.retpkgcode ? this.returnablePackageData?.retpkgcode : '-';
    this.returnableDunnage = this.returnablePackageData?.dunnagecode1 ? this.returnablePackageData?.dunnagecode1 : this.returnablePackageData?.dunnagecode2;
    this.returnablePartNumber = this.returnablePackageData?.partnumber ? this.returnablePackageData?.partnumber : '-';
  }
  
  setDefaultValues(){
      this.submit.controls.responsibility.setValue('toyota')
  }
  setValues(data:any){
    this.submit.controls.reasonDetail.setValue(data.auth.REASON);
    this.submit.controls.reason.setValue(data.auth.REASONCODE);
    this.submit.controls.responsibility.setValue(data.auth.RESPONSIBILITY === '0' ? 'supplier' : 'toyota');
    const date = new Date(data.auth.SD).toLocaleDateString('fr-CA')
    this.submit.controls.startDate.setValue(date);
  }

  navigatePrevious(){
    this._location.back();
  }

  validateDate(date:any){
    let diffInDays:any;
    if(date){
      const selectedDate:any = new Date(date);
      const today:any = new Date();
      diffInDays = Math.ceil((selectedDate  - today) / (1000 * 60 * 60 * 24));
      if(diffInDays <= 7 && diffInDays >= -7){
        return true;
      }else{
        alert('Start Date should be +/- 7 from today')
        return false     
      }
    }else{
      return false;
    } 
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }
  addquestion3Validation(){
    this.enhancementForm.controls.question3a.enable();
    this.enhancementForm.get('question3a').addValidators([Validators.required]);
    this.enhancementForm.controls.question3a.updateValueAndValidity();
    this.enhancementForm.controls.question3a.enable();
    this.enhancementForm.get('question3b').addValidators([Validators.required]);
    this.enhancementForm.controls.question3a.updateValueAndValidity();
  }
  clearquestion3Validation(){
    this.enhancementForm.controls.question3a.clearValidators();
    this.enhancementForm.controls.question3a.updateValueAndValidity();
    this.enhancementForm.controls.question3b.clearValidators();
    this.enhancementForm.controls.question3b.updateValueAndValidity();
  }
  addquestion4Validation(){
    this.enhancementForm.controls.question4a.enable();
    this.enhancementForm.get('question4a').addValidators([Validators.required]);
    this.enhancementForm.controls.question4a.updateValueAndValidity();
  }
  clearquestion4Validation(){
    this.enhancementForm.controls.question4a.clearValidators();
    this.enhancementForm.controls.question4a.updateValueAndValidity();
  }
  addquestion6Validation(){
    this.enhancementForm.controls.question6a.enable();
    this.enhancementForm.get('question6a').addValidators([Validators.required]);
    this.enhancementForm.controls.question6a.updateValueAndValidity();
  }
  clearquestion6Validation(){
    this.enhancementForm.controls.question6a.clearValidators();
    this.enhancementForm.controls.question6a.updateValueAndValidity();
  }
  addquestion7Validation(){
    this.enhancementForm.controls.question7a.enable();
    this.enhancementForm.get('question7a').addValidators([Validators.required]);
    this.enhancementForm.controls.question7a.updateValueAndValidity();
  }
  clearquestion7Validation(){
    this.enhancementForm.controls.question7a.clearValidators();
    this.enhancementForm.controls.question7a.updateValueAndValidity();
  }
  addquestion8Validation(){
    this.enhancementForm.controls.question8a.enable();
    this.enhancementForm.get('question8a').addValidators([Validators.required]);
    this.enhancementForm.controls.question8a.updateValueAndValidity();
  }
  clearquestion8Validation(){
    this.enhancementForm.controls.question8a.clearValidators();
    this.enhancementForm.controls.question8a.updateValueAndValidity();
  }
  addquestion9Validation(){
    this.enhancementForm.controls.question9a.enable();
    this.enhancementForm.get('question9a').addValidators([Validators.required]);
    this.enhancementForm.controls.question9a.updateValueAndValidity();
    this.enhancementForm.controls.question9b.enable();
    this.enhancementForm.get('question9b').addValidators([Validators.required]);
    this.enhancementForm.controls.question9b.updateValueAndValidity();
  }
  clearquestion9Validation(){
    this.enhancementForm.controls.question9a.clearValidators();
    this.enhancementForm.controls.question9a.updateValueAndValidity();
    this.enhancementForm.controls.question9b.clearValidators();
    this.enhancementForm.controls.question9b.updateValueAndValidity();
  }
  addquestion10Validation(){
    this.enhancementForm.controls.question10a.enable();
    this.enhancementForm.get('question10a').addValidators([Validators.required]);
    this.enhancementForm.controls.question10a.updateValueAndValidity();
    this.enhancementForm.controls.question10b.enable();
    this.enhancementForm.get('question10b').addValidators([Validators.required]);
    this.enhancementForm.controls.question10b.updateValueAndValidity();
  }
  clearquestion10Validation(){
    this.enhancementForm.controls.question10a.clearValidators();
    this.enhancementForm.controls.question10a.updateValueAndValidity();
    this.enhancementForm.controls.question10b.clearValidators();
    this.enhancementForm.controls.question10b.updateValueAndValidity();
  }
  addquestion11Validation(){
    this.enhancementForm.controls.question11a.enable();
    this.enhancementForm.get('question11a').addValidators([Validators.required]);
    this.enhancementForm.controls.question11a.updateValueAndValidity();
    this.enhancementForm.controls.question11b.enable();
    this.enhancementForm.get('question11b').addValidators([Validators.required]);
    this.enhancementForm.controls.question11b.updateValueAndValidity();
    this.enhancementForm.controls.radio10.enable();
    this.enhancementForm.get('radio10').addValidators([Validators.required]);
    this.enhancementForm.controls.radio10.updateValueAndValidity();
  }
  clearquestion11Validation(){
    this.enhancementForm.controls.question11a.clearValidators();
    this.enhancementForm.controls.question11a.updateValueAndValidity();
    this.enhancementForm.controls.question11b.clearValidators();
    this.enhancementForm.controls.question11b.updateValueAndValidity();
    this.enhancementForm.controls.radio10.clearValidators();
    this.enhancementForm.controls.radio10.updateValueAndValidity();
  }
  addquestion13Validation(){
    this.enhancementForm.controls.question13a.enable();
    this.enhancementForm.get('question13a').addValidators([Validators.required]);
    this.enhancementForm.controls.question13a.updateValueAndValidity();
  }
  clearquestion13Validation(){
    this.enhancementForm.controls.question13a.clearValidators();
    this.enhancementForm.controls.question13a.updateValueAndValidity();
  }
  addquestion14Validation(){
    this.enhancementForm.controls.q14date1.enable();
    this.enhancementForm.get('q14date1').addValidators([Validators.required]);
    this.enhancementForm.controls.q14date1.updateValueAndValidity();
    this.enhancementForm.controls.q14shipment1.enable();
    this.enhancementForm.get('q14shipment1').addValidators([Validators.required]);
    this.enhancementForm.controls.q14shipment1.updateValueAndValidity();
    this.enhancementForm.controls.q14date2.enable();
    this.enhancementForm.get('q14date2').addValidators([Validators.required]);
    this.enhancementForm.controls.q14date2.updateValueAndValidity();
    this.enhancementForm.controls.q14shipment2.enable();
    this.enhancementForm.get('q14shipment2').addValidators([Validators.required]);
    this.enhancementForm.controls.q14shipment2.updateValueAndValidity();
    this.enhancementForm.controls.q14date3.enable();
    this.enhancementForm.get('q14date3').addValidators([Validators.required]);
    this.enhancementForm.controls.q14date3.updateValueAndValidity();
    this.enhancementForm.controls.q14shipment3.enable();
    this.enhancementForm.get('q14shipment3').addValidators([Validators.required]);
    this.enhancementForm.controls.q14shipment3.updateValueAndValidity();
  }
  clearquestion14Validation(){
      this.enhancementForm.controls.q14date1.clearValidators();
      this.enhancementForm.controls.q14date1.updateValueAndValidity();
      this.enhancementForm.controls.q14date2.clearValidators();
      this.enhancementForm.controls.q14date2.updateValueAndValidity();
      this.enhancementForm.controls.q14date3.clearValidators();
      this.enhancementForm.controls.q14date3.updateValueAndValidity();
      this.enhancementForm.controls.q14shipment1.clearValidators();
      this.enhancementForm.controls.q14shipment1.updateValueAndValidity();
      this.enhancementForm.controls.q14shipment2.clearValidators();
      this.enhancementForm.controls.q14shipment2.updateValueAndValidity();
      this.enhancementForm.controls.q14shipment3.clearValidators();
      this.enhancementForm.controls.q14shipment3.updateValueAndValidity();
  }
  addquestion15Validation(){
    this.enhancementForm.controls.q15date1.enable();
    this.enhancementForm.get('q15date1').addValidators([Validators.required]);
    this.enhancementForm.controls.q15date1.updateValueAndValidity();
    this.enhancementForm.controls.q15shipment1.enable();
    this.enhancementForm.get('q15shipment1').addValidators([Validators.required]);
    this.enhancementForm.controls.q15shipment1.updateValueAndValidity();
    this.enhancementForm.controls.q15date2.enable();
    this.enhancementForm.get('q15date2').addValidators([Validators.required]);
    this.enhancementForm.controls.q15date2.updateValueAndValidity();
    this.enhancementForm.controls.q15shipment2.enable();
    this.enhancementForm.get('q15shipment2').addValidators([Validators.required]);
    this.enhancementForm.controls.q15shipment2.updateValueAndValidity();
    this.enhancementForm.controls.q15date3.enable();
    this.enhancementForm.get('q15date3').addValidators([Validators.required]);
    this.enhancementForm.controls.q15date3.updateValueAndValidity();
    this.enhancementForm.controls.q15shipment3.enable();
    this.enhancementForm.get('q15shipment3').addValidators([Validators.required]);
    this.enhancementForm.controls.q15shipment3.updateValueAndValidity();
  }
  clearquestion15Validation(){
      this.enhancementForm.controls.q15date1.clearValidators();
      this.enhancementForm.controls.q15date1.updateValueAndValidity();
      this.enhancementForm.controls.q15date2.clearValidators();
      this.enhancementForm.controls.q15date2.updateValueAndValidity();
      this.enhancementForm.controls.q15date3.clearValidators();
      this.enhancementForm.controls.q15date3.updateValueAndValidity();
      this.enhancementForm.controls.q15shipment1.clearValidators();
      this.enhancementForm.controls.q15shipment1.updateValueAndValidity();
      this.enhancementForm.controls.q15shipment2.clearValidators();
      this.enhancementForm.controls.q15shipment2.updateValueAndValidity();
      this.enhancementForm.controls.q15shipment3.clearValidators();
      this.enhancementForm.controls.q15shipment3.updateValueAndValidity();
  }
 onRadio1Change(event:any){
  this.q3Check = event.target.value==='question3' ? true : false;
  if(this.q3Check === true){
    this.addquestion3Validation();
  }
  else{
   this.clearquestion3Validation();
  }
 }
 onRadio2Change(event:any){
  this.q4Check = event.target.value==='question4' ? true : false;
  if(this.q4Check === true){
    this.addquestion4Validation();
  }
  else{
    this.clearquestion4Validation();
  }
 }
 onRadio4Change(event:any){
  this.q6Check = event.target.value==='question6' ? true : false;
  if(this.q6Check === true){
   this.addquestion6Validation();
  }
  else{
   this.clearquestion6Validation();
  }
 }
 onRadio5Change(event:any){
  this.q7Check = event.target.value==='question7' ? true : false;
  if(this.q7Check === true){
   this.addquestion7Validation();
  }
  else{
    this.clearquestion7Validation();
  }
 }
 onRadio6Change(event:any){
  this.q8Check = event.target.value==='question8' ? true : false;
  if(this.q8Check === true){
    this.addquestion8Validation();
  }
  else{
    this.clearquestion8Validation();
  }
 }
 onRadio7Change(event:any){
  this.q9Check = event.target.value==='question9' ? true : false;
  if(this.q9Check === true){
    this.addquestion9Validation();
  }
  else{
    this.clearquestion9Validation();
  }
 }
 onRadio8Change(event:any){
  this.q10Check = event.target.value==='question10' ? true : false;
  if(this.q10Check === true){
    this.addquestion10Validation();
  }
  else{
    this.clearquestion10Validation();
  }
 }
 onRadio9Change(event:any){
  this.q11Check = event.target.value==='question11' ? true : false;
  if(this.q11Check === true){
   this.addquestion11Validation();
  }
  else{
    this.clearquestion11Validation();
  }
 }
 onRadio12Change(event:any){
  this.q13Check = event.target.value==='question13' ? true : false;
  if(this.q13Check === true){
    this.addquestion13Validation();
  }
  else{
   this.clearquestion13Validation();
  }
 }
 onRadio13Change(event:any){
  this.q14Check = event.target.value==='question14' ? true : false;
  if(this.q14Check === true){
      this.addquestion14Validation();
    }
    else{
      this.clearquestion14Validation();
    }
 }
 onRadio14Change(event:any){
  this.q15Check = event.target.value==='question15' ? true : false;
  if(this.q15Check === true){
    this.addquestion15Validation();
}
else{
  this.clearquestion15Validation();
}
 }
 
 sendPayload(){
  let guid = '';
  if (localStorage.getItem('toyotaGUID')) {
    guid = JSON.parse(localStorage.getItem('toyotaGUID') ?? '');
  }
  return{
      reasonDetail: this.submit.controls.reasonDetail.value,
      reason: this.submit.controls.reason.value,
      responsibility: this.submit.controls.responsibility.value === 'supplier' ? '0': '1',
      startDate: this.submit.controls.startDate.value.replace(/-/g, '/'),
      guid : guid
  }
 }

 getPayload() {
  const payload = this.sendPayload();
  return {
    ...payload
  }
}
  onSubmit(){   
    this.submit.markAllAsTouched()
      if(this.submit.valid){
      if(this.pageName === 'notify of start use'){
       const body = this.getPayload();
        const dateValid = this.validateDate(this.submit.controls.startDate.value);
        dateValid && this.useBackupService.notifySubmit(body,this.metaData).subscribe({
          next: (res: any) => {
            if (res.result === 'Success') {
              this.openMessageBox('Value added successfully', 'success');
              // setTimeout(()=> {
                this.navigatePrevious();
              // }, 2000);
            }
            else{
              this.openMessageBox('Error while adding data', 'error')
            }    
          },
          error: (_error) => {
            this.openMessageBox('Error while adding data', 'error')
           },
        })
      }else if (this.pageName === 'update'){
        const authNumber = this.authdata.authNumber;
        const body = this.getPayload();
        const dateValid = this.validateDate(this.submit.controls.startDate.value);
        dateValid && this.useBackupService.updateSubmit(body, authNumber, this.metaData.partNumber, this.metaData.plantCode).subscribe({
          next: (res:any) =>{
            if (res.result === 'Success') {
              this.openMessageBox('Value updated successfully', 'success');
              // setTimeout(()=> {
                this.navigatePrevious();
              // }, 2000);
            }
            else{
              this.openMessageBox('Error while updating data', 'error')
            }       
          },
          error: (_error:any) =>{
            this.openMessageBox('Error while updating data', 'error')
          },
        })
      }
    }else{
      this.openMessageBox('Please Scroll Up/Down to fill the mandatory fields','error');
    }
  }
}
