import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { DiscrepancyListAddPartComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-list-add-part/discrepancy-list-add-part.component';
import { DiscrepencyBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-discrepancy/descripency';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { formatDate } from '@angular/common';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import * as fileSaver from 'file-saver-es'

@Component({
  selector: 'app-discrepancy-list',
  templateUrl: './discrepancy-list.component.html',
  styleUrls: ['./discrepancy-list.component.scss']
})
export class DiscrepancyListComponent implements OnInit {

  gridApi: any;
  isAll = false;
  columnDefs: any[] = [];
  currentPageNo = 1;
  sortModel: any[] = []
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  plantDropdown: any;
  discDropdown: any = "1";
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 10
  isAccessible = false;
  supplierCode: any = "";
  spCode: any;
  plants: any[] = [];
  discrepancyList: any[] = [];
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  originalPage: any;
  partNumber = '';
  part1 = '';
  part2 = '';
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  context: any;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal: any = 1;
  gridColumnApi: any;
  message: any;
  errorList: any;
  haveAccess: any;
  supType: any = '';
  userInfo: any;
  unit: any = '';
  routeParams: any;
  apiFromSearch = false;
  response1: any;
  filteredOptions: any[] = [];

  constructor(public route: ActivatedRoute, public dialog: MatDialog, private readonly router: Router, private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService, private readonly DiscrepancyListService: DiscrepancyListService) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      filterParams: { newRowsAction: 'keep' },
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.context = {
      componentParent: this,
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.discrepancyList = [{ name: 'No PartNumber', code: "1" }, { name: 'No Supplier', code: "2" }, { name: 'No Temporary Container', code: "3" }];
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        const plantdata = this.DiscrepancyListService.getplant();
        this.spinnerService.start();
        if (res) {
          this.userInfo = res.info;
          this.unit = res.info?.unit;
          this.supType = res.role.supType;
          this.haveAccess = res.role?.p_trackingview;
          this.haveAccess && this.getColumnDefs();
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
            this.spCode = res.info.specialistcode;
            this.plant();
          }
        }
        if (plantdata) {
          const filterdata = this.DiscrepancyListService.getpartFilters();
          if (filterdata) {
            this.selectedPlant = plantdata;
            this.plantDropdown = (filterdata?.PlantCode.length > 1) ? '(All)' : filterdata?.PlantCode[0];
            this.supplierCode = filterdata?.SupplierCode;
            this.partNumber = (filterdata?.PartNumber1 ?? '') + (filterdata?.PartNumber2 ?? '');
            this.discDropdown = filterdata?.DiscType;
            this.DiscrepancyListService.setpartFilters(null);
            return;
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
  }

  changefunc(event: any) {
    this.discDropdown = event;
    this.getColumnDefs();
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "PLANT", field: "plant", minWidth: 120, maxWidth: 150,
      },
      {
        headerName: "SUPPLIER CODE", field: "suppliercode", minWidth: 120, maxWidth: 150
      },
      {
        headerName: "PART NUMBER", field: "partnumber"
      },
      {
        headerName: "PACKAGE START DATE", field: "fromdate", valueFormatter: this.nullToDashFormatter, minWidth: 100, maxWidth: 150,
        comparator: dateComparator,
      },
      {
        headerName: "SPECIALIST", field: "specialist", valueFormatter: this.nullToDashFormatter
      },
      {
        headerName: "REPLACED PART", field: "backup", width: 100, sortable: false, unSortIcon: false, minWidth: 120, maxWidth: 150,
        cellRendererParams: {
          buttonFlag: this.discDropdown
        },
        cellRendererFramework: DiscrepencyBtnCellRender, cellStyle: { cursor: 'pointer' },
        floatingFilterComponentParams: false,
        floatingFilter: false,
        filter: false,
      },
      {
        headerName: "NEW PART", field: "history", width: 100, sortable: false, unSortIcon: false,
        cellRendererParams: {
          buttonFlag: this.discDropdown
        },
        cellRendererFramework: DiscrepencyBtnCellRender, cellStyle: { cursor: 'pointer' },
        floatingFilterComponentParams: false,
        floatingFilter: false,
        filter: false,
      },
    ]
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  filterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.DiscrepancyListService.setfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response1?.pagination?.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    if (this.DiscrepancyListService.getfilter()) {
      const retrivedValue = this.DiscrepancyListService.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 5000;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onLoad();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.onLoad();
      if (this.apiFromSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiFromSearch = false;
      }
      resolve();
    });
  }

  nullToDashFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null || params.value === '') {
      return '-';
    } else {
      return params?.value ? params.value.substring(0, 10) : '';
    }
  }

  onSearch() {
    this.apiFromSearch = true;
    this.spinnerService.start();
    this.onLoad();
}

  openAdd(body: any) {
    this.dialog.open(DiscrepancyListAddPartComponent, {
      data: { addData: body }
    })
  }

  dateFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null || params.value === "") {
      return '';
    } else {
      return params?.value
        ? formatDate(params.value, 'MM/dd/YYYY', 'en-US', 'UTC')
        : '';
    }
  }

  onLoad() {
    let partValue = this.partNumber;

    if (partValue !== '' && partValue.length < 10) {
      partValue = partValue + '*';
    }
    this.part1 = partValue.slice(0, 5)
    this.part2 = partValue.slice(5, 11)
    this.spinnerService.start();
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plantDropdown?.toLowerCase()) || (item.plantcode.toLowerCase() === this.plantDropdown?.toLowerCase())))
    this.plantDropdown = plant[0]?.plantcode
    const updateObject = {
      SupplierCode: this.supplierCode,
      PartNumber1: this.part1,
      PartNumber2: this.part2,
      DiscType: this.discDropdown,
      PlantCode: this.plantDropdown === '(All)' ? this.resPlant : [this.plantDropdown],
      page: this.cPage,
      limit: this.rpPage
    }
    this.DiscrepancyListService.setpartFilters(updateObject);
    if (this.apiFromSearch) {
      this.cPage = 1;
    }
    this.DiscrepancyListService.onLoad(updateObject).subscribe({
      next: (res: any) => {
        const data = res.result.Result
        this.response1 = res;
        data.forEach((element: any) => {
          element.fromdate = this.dateFormatter({ value: element.fromdate })
        });
        this.displayRowData = res.result.Result;
        this.rowCount = res.pagination.rows;
        this.totalRecords = res.pagination.TotalRecords;
        this.spinnerService.stop();
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        if (this.apiFromSearch) {
          this.cPage = 1;
          if (this.isAll) {
            const values = {
              cPage: 1,
              tPage: 1,
              rpPage: 'ALL'
            }
            this.changePaginationValues(values)
          }
        }
        this.apiFromSearch = false;
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.spinnerService.stop();
      },
    })
  }

  close() {
    this.errorList = []
  }

  download() {
    this.DiscrepancyListService.download(this.plantDropdown, this.discDropdown).subscribe(response => {
      if (response.message === 'No Data Found' && this.plantDropdown !== '(All)') {
        this.message = `No Data Found for ${this.plantDropdown} Plant`
        setTimeout(() => {
          this.message = ''
        }, 4000)
      }

      else if (this.plantDropdown !== '(All)') {
        const base64Data = response.Data;
        const fileName = response.Filename;
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
        fileSaver.saveAs(file, fileName);
      } else {
        this.message = '304-203:Required Item Error [Plant]'
        setTimeout(() => {
          this.message = ''
        }, 4000)
      }
    })
  }

  plant() {
    this.spCode = this.userInfo.specialistcode;
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };
    this.DiscrepancyListService.plant(obj).subscribe(response => {
      this.resPlant = response.map((item: { plantcode: any; }) => item.plantcode)
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response]
      this.plantDropdown = this.plantDropdown ?? this.userInfo.plantcode;
      this.filteredOptions = this.selectedPlant
      this.displayFn(this.selectedPlant);
      this.DiscrepancyListService.setplant(this.selectedPlant);
      this.onLoad();
    })
  }

  filterOptions() {
    this.filteredOptions = this.selectedPlant.filter(option => option.plant?.toLowerCase().includes(this.plantDropdown?.toLowerCase()))
  }


  displayFn(selectId: any) {
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 26])
  }
}