import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {  Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'discrepancy-list-hyperLink-btn-cell-render',
  template: `
  <a (click)="openDialog()" style="text-decoration:underline">{{ text }}</a>

  `,
})

export class DiscrepancyListHyperLinkBtnCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, 
    public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;

  agInit(params: any): void {
    this.params = params;

    if (params.colDef.field === 'partnumber') {
      this.text = `${this.params?.data?.partnumber?.slice(0, 5)}-${this.params?.data?.partnumber?.slice(5)}`;
    }
    
  }

  openDialog() {
      
    if (this.params.colDef.field ==='partnumber' ){
      if (this.router.url.includes('tracking-list/sI-preppf-dept-approval/select')) {
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval/select/ppf',this.params.data.ppfcode,2]);
      } else if (this.router.url.includes('discripency-list')) {
        this.router.navigate(['/discripency-list/ppf',this.params.data.ppfcode,2]);
      }
    
    }

}}
