<div class="wrapper bg-white">
  <div class="d-flex justify-content-between align-items-center mx-3">
    <p class="page-title">Comparing Part</p>
    <div>
      <div (click)="onBack()" class="help-btn">BACK</div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <app-loader [lines]=4 [table]=4 *ngIf="loading"></app-loader>
  <div class="alert alert-danger mx-2" *ngIf="haveErrors === true && errorList.length > 0">
    <span *ngFor="let message of errorList">{{ message }}</span>
  </div>
  <div class="vpacs-shadow mx-2 p-2" *ngIf="!loading" (keyup.enter)="onSearch()">
    <div class="filter mx-2 d-flex">
      <div class="d-flex align-items-center col-lg-3 col-md-6 col-12">
        <label for="plant" class="col-5">Connection Level</label>
        <mat-select id="plant" class="mat-sel" [(ngModel)]="level" placeholder="Part10 + Plant" style="width: 45%;">
          <mat-option *ngFor="let level of levels" [value]="level.Id">{{level.Name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
        <label for="part-number" class="col-5">Part Number</label>
        <input type="text" id="partNumber" [(ngModel)]="partNumber" style="width: 100%;">
      </div>
      <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
        <label for="supplierCode" class="col-5">Supplier</label>
        <input type="text" id="supplierCode" [(ngModel)]="supplierCode" style="width: 100%;">
      </div>
      <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
        <label for="plant" class="col-5">Plant</label>
        <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
        (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
        style="width: 80%">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
        </mat-option>
      </mat-autocomplete>

      </div>
      <div class="align-items-end justify-content-end">
        <input type="button" value="SEARCH" (click)="onSearch()" class="black-button" />
      </div>
    </div>
  </div>
  <div class="vpacs-shadow m-2 py-2 px-2" *ngIf="!loading">
    <table style="width: 35%; height:100px; border: 1px solid #bcd1e0" aria-describedby="table1"
      class="vpacs-shadow m-2 py-2 px-2">
      <th class="d-none" id="model"></th>
      <tr>
        <td rowspan="3" class="table-align">New Part</td>
        <td colspan="2" class="table-align">Proto/Production Part#</td>
        <td>{{partNumberConnect}} </td>
      </tr>
      <tr>
        <td colspan="2" class="table-align">Supplier Code</td>
        <td colspan="2">{{supplierCodeConnect}}</td>
      </tr>
    </table>

    <div class="grid-agg mt-3 grid-height-model-compare-list tracking-summary mb-5">
      <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40" [domLayout]="domLayout"
        (filterChanged)="onFilterChanged($event)">
      </ag-grid-angular>
      <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)">
      </app-pagination>
    </div>
  </div>
</div>