import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import * as fileSaver from 'file-saver-es'
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface'
import {
  IPlantType,
  ISupplierPlant,
  ISupplierSpecialist,
} from 'src/app/internal-vpacs/interface/ISupplierPlant'
import { CalculationComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/calculation/calculation.component'
import { ImportDataComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/supplier-plant/import-data/import-data.component'
import { UpdateDataComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/supplier-plant/update-data/update-data.component'
import { SupplierPlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier-plant.service'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-supplier-plant',
  templateUrl: './supplier-plant.component.html',
  styleUrls: ['./supplier-plant.component.scss'],
})
export class SupplierPlantComponent implements OnInit {
  cPage = 1
  tPage = 0
  rpPage = 50
  totalRecords = 0
  shownPages: { text: number | string; value: number }[] = []
  panelOpenState2 = true
  plants: IPlantType[] = []

  supplierCode: any
  specialistCode: any
  tableData: any[] = []
  errorList: string[] = []
  haveAccess = false
  haveAddAccess = false
  errorMessage!: ErrorMessage
  supplierType: any
  plantLength: any
  currentPlant: IPlantType | undefined
  checkedPlants: any[] = []
  successMessage = ''
  isAll=false;
  spCode: any
  plant: any

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly supplierPlantService: SupplierPlantService,
    private readonly spinner: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly message: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.spinner.start()
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview;
        this.haveAddAccess = res.role?.p_usersave;
        this.supplierType = res.role?.supType;
        this.plant = res.info?.plantcode;
        this.spCode = res.info?.specialistcode;

        if (this.haveAccess) {
          this.spCode = res?.info.specialistcode;

          this.getPlantList()
        } else {
          if (res) {
            this.errorList = ["You're not authorized to access this page"]
            this.spinner.stop()
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.spinner.stop()
      },
    })
  }

  getPlantList() {
    const plantBody = {
      SpecialistCode: this.spCode,
      Type: 1,
    }

    this.supplierPlantService.getPlantList(plantBody).subscribe({
      next: (res: IPlantType[]) => {
        this.plants = res?.map((plant) => {
          return { ...plant, checked: false }
        })

        this.currentPlant = this.findPlantByCode(this.getPlantCode())
        if (this.currentPlant) {
          this.currentPlant.checked = true
        }
        this.getSupplierPlantInfo()
      },
      error: (_err: any) => {
        this.spinner.stop()
      },
    })
  }

  close() {
    this.errorList = []
    this.successMessage = ''
  }

  downloadSupplierPlant() {
    this.errorList = []
    this.successMessage = ''
    const body = {
      supplierType: this.supplierType,
      plantList: this.getPlantCodes(this.getCheckedPlants()),
      specialist: this.specialistCode ?? '',
      suppliercode: this.supplierCode ?? '',
    }

    if (body?.plantList.length === 0 || body?.plantList.length > 1) {
      this.errorList.push('302-299:Data Format Error Choose only one plant.')
    } else {
      this.spinner.start()

      this.supplierPlantService.downloadSupplierPlant(body).subscribe({
        next: (res: any) => {
          if (res?.data) {
            const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`
            setTimeout(() => {
              this.spinner.stop()
              fileSaver.saveAs(file, 'SupplierSpecialist')
            })
            this.openMessageBox('File downloaded successfully', 'success')
          } else {
            this.spinner.stop()
            this.errorList = ['File download failed !!!']
          }
        },
        error: (_err: any) => {
          this.errorList = [_err?.error?.error]
          this.spinner.stop()
        },
      })
    }
  }

  counter(num: number | undefined) {
    if (num) {
      return new Array(num)
    }
    return []
  }

  createCurrentPagesArray(start: number, end: number) {
    this.shownPages = []
    for (let i = start; i < end; i++) {
      this.shownPages.push({ text: i + 1, value: i })
    }
  }

  addFirstPage() {
    this.shownPages.unshift({ text: 'First', value: 0 })
  }

  addPrevPage() {
    this.shownPages.unshift({
      text: '<< Prev',
      value: this.cPage - 1,
    })
  }

  addNextPage() {
    this.shownPages.push({
      text: 'Next >>',
      value: this.cPage + 1,
    })
  }

  addLastPage() {
    if (!this.tPage) {
      throw new Error('Called before Total Pages are set.')
    }

    this.shownPages.push({ text: 'Last', value: this.tPage - 1 })
  }

  handlePagination() {
    if (!this.tPage) {
      throw new Error('Called before Total Pages are set.')
    }

    if (this.tPage <= 10) {
      this.createCurrentPagesArray(0, 10)

      return
    }

    // Total pages are more than 10 now
    // And current page is less than 10
    if (this.cPage < 10) {
      this.createCurrentPagesArray(0, 10)

      if (this.cPage !== 0) {
        this.addPrevPage()
      }

      if (this.cPage !== this.tPage - 1) {
        this.addNextPage()
      }

      this.addLastPage()

      return
    }

    // Current page is greater than 9
    let startPage = this.cPage - 5
    let endPage = this.cPage + 5

    if (this.cPage > this.tPage - 5) {
      startPage -= 5 - (this.tPage - 1 - this.cPage)
      endPage = this.cPage + (this.tPage - this.cPage)
    }

    this.createCurrentPagesArray(startPage, endPage)

    this.addPrevPage()

    this.addFirstPage()

    const lastPage = this.cPage + 1 >= this.tPage

    if (lastPage) {
      return
    }

    this.addNextPage()

    this.addLastPage()
  }

  getTableData(){
    return this.tableData.sort((s1, s2) => {
      return s1.suppliercode < s2.suppliercode ? -1 : 1;
    });
  }
  checkSupplier(supplierinfo: any){
    if (supplierinfo === null || supplierinfo === '' || supplierinfo === undefined) {
      return false
    }
    if(Object.keys(supplierinfo)?.length !== 0){
      return true
    }
    else{
      return false
    }
  }

  getBody(){
    return {
      supplierType: this.supplierType,
      plantlist: this.getPlantCodes(this.checkedPlants),
      suppliercode: this.supplierCode,
      userSpecialistCode: this.spCode,
      specialist: this.specialistCode,
    }
  }
  getSupplierPlantInfo(_page?: any) {
    this.spinner.start()
    this.errorList = []
    this.totalRecords = 0
    this.plantLength = this.getCheckedPlants()?.length
    this.checkedPlants = this.getCheckedPlants()
    if (this.plantLength !== 0) {
      const body =this.getBody();
      this.supplierPlantService.getSupplierPlantInfo(body).subscribe({
        next: (res: any) => {
          if (res?.status) {
            if (res?.response?.length > 0) {
              this.tableData = res?.response;
              this.tableData = this.getTableData();
              this.totalRecords = this.tableData?.length
              this.tPage = Math.ceil(this.totalRecords / this.rpPage)
              const start = (this.cPage - 1) * this.rpPage
              const end = this.cPage * this.rpPage
              this.tableData = this.tableData.slice(start, end)
              this.handlePagination()
            } else {
              this.tableData = []
            }
          }

          this.spinner.stop()
        },
        error: (err: any) => {
          this.errorList = [err?.error?.error]
          this.spinner?.stop()
        },
      })
    } else {
      this.spinner.stop()
    }
  }

  getSupplierCode(row: ISupplierSpecialist) {
    const supplierType = this.supplierType

    const { suppliercode, nummicode, tmmccode } = row

    switch (supplierType) {
      case '0':
        return suppliercode
      case '1':
        if (tmmccode) {
          return tmmccode
        }
        return suppliercode
      case '2':
        if (nummicode) {
          return nummicode
        }
        return suppliercode
      default:
        return ''
    }
  }


  getPlantCode() {
    return JSON.parse(sessionStorage.getItem('plantCode') || '{}')
  }

  getCheckedPlants(): IPlantType[] {
    return this.plants.filter((plant) => plant.checked)
  }

  getPlantNames(plantList: IPlantType[]) {
    return plantList.map((plant) => plant.plant)
  }

  getPlantCodes(plantList: IPlantType[]) {
    return plantList.map((plant) => plant.plantcode)
  }

  findPlantByCode = (plantCode: string) => {
    return this.plants.find((plant) => plant.plantcode === plantCode)
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    })
  }

  importData() {
    this.dialog
      .open(ImportDataComponent, { data: { supplierType: this.supplierType } })
      .afterClosed()
      .subscribe((result: any) => {
        if (result?.success) {
          this.openMessageBox(
            'Supplier Specialist imported successfully!',
            'success',
          )

          this.getSupplierPlantInfo()
        }
      })
  }

  updateData(row: any, _plantCode: any, plCodeIndex: any) {
    const data = {
      ...row,
      plantlist: this.plants,
      supplierType: this.supplierType,
      plCodeIndex: plCodeIndex
    }

    this.dialog
      .open(UpdateDataComponent, { data })
      .afterClosed()
      .subscribe((result: any) => {
        if (result?.success) {
          this.getSupplierPlantInfo()
        }
      })
  }

  calculation(row: ISupplierPlant) {
    const data = {
      ...row,
      plantlist: this.plants,
      haveAccess: this.haveAddAccess,
    }

    this.dialog
      .open(CalculationComponent, { data })
      .afterClosed()
      .subscribe((result: any) => {
        if (result?.success) {
          this.openMessageBox(
            'Supplier Specialist calculation updated successfully!',
            'success',
          )
          this.getSupplierPlantInfo()
        }
      })
  }

  //pagination issues
  async changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.isAll=values?.rpPage==='ALL' ? true: false;
    if(this.isAll){
      this.tableData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 3000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 3000
        await this.onLoadAll()
      }
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
    }
    else{
      this.getSupplierPlantInfo();
    }
  }

  async onLoadAll()
  {
    this.spinner.start();
    const body=this.getBody();
      this.supplierPlantService.getSupplierPlantInfo(body).subscribe({
        next: (res: any) => {
          if (res?.status) {
            if (res?.response?.length > 0) {
              this.tableData = res?.response;
              this.tableData = this.getTableData();
            } else {
              this.tableData = []
            }
          }
          this.spinner.stop()
        },
        error: (err: any) => {
          this.errorList = [err?.error?.error]
          this.spinner?.stop()
        },
      })
    }

  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 118])
  }
}
