<div class="wrapper bg-white">  
    <div class="page-title px-3 py-2">Message</div>
    <div class="mat-typography content px-3">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">RECEIVE FROM TOYOTA</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Date<span class="text-black">{{data?.gridDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Status<span class="text-black">{{receiveFromToyota?.status}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span class="text-black">{{receiveFromToyota?.partNumber}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Start Date<span class="text-black">{{receiveFromToyota?.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Message From<br/><span class="text-black">{{receiveFromToyota?.msgFrom}}</span></div>
                <div class="d-grid align-items-center my-2 col-lg-3 col-md-6 col-12">
                    <label class="col-6">Message</label>
                    <textarea rows="3" readonly  class="col-11">{{receiveFromToyota?.data[0]?.NOTE}}</textarea>
                </div>
                <div class="d-grid align-items-center my-2 col-lg-3 col-md-6 col-12" *ngIf="hazmatComment">
                    <label class="col-6">HazmatMessage</label>
                    <textarea rows="3" readonly  class="col-11">{{hazmatComment}}</textarea>
                </div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0" *ngIf="pkgReviewFlag">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">PACKAGE REVIEW</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Result<span class="text-black">{{pkgData?.result}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Requested<span class="text-black">{{pkgData?.requested}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Requested Changes<span class="text-black">{{pkgData?.requestChanges}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Reason<span class="text-black">{{pkgData?.reason}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Reason Requested<span class="text-black">{{pkgData?.reasonChanges}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap" [formGroup]="sendPrePpf">
                <button class="vpacs-btn-table" style="margin-top: 12px;" (click)="ppf()">PPF</button>
                <div class="col-lg-12 col-md-12 col-12 my-2"><span class="text-black">PART DATA EVALUATION</span></div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">1) Is part number and description correct?</label>
                    <span class="col-6">
                        <mat-radio-group aria-label="Select an option" formControlName="q1_1_yn">
                            <mat-radio-button value="Y"  class="me-2">Yes</mat-radio-button>
                            <mat-radio-button value="N" >No</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">If not, please enter the correct part number or description</label>
                    <input class="col-6" type="text" formControlName="q1_1_note">
                </div>
                <div class="col-lg-12 col-md-16 col-12 my-2">
                    <label class="col-6">2) Is this the correct supplier code?</label>
                    <span class="col-6">
                        <mat-radio-group aria-label="Select an option" formControlName="q1_2_yn">
                            <mat-radio-button value="1"  class="me-2">Yes</mat-radio-button>
                            <mat-radio-button value="0" >No</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">If not, please identify the correct code if possible</label>
                    <input class="col-6" type="text" formControlName="q1_2_note">
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">3) Part data availability date</label>
                    <input class="col-6" type="date" formControlName="q1_3_date">
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">4) Sample part availability date</label>
                    <input class="col-6" type="date" formControlName="q1_4_date">
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2"><span class="text-black">PART DATA EVALUATION</span></div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">1) Expected concept evaluation date</label>
                    <input class="col-6" type="date" formControlName="q2_1_date">
                </div>

                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">2) Evaluation method</label>
                    <select class="col-6" formControlName="q2_2_select">
                        <option value="" selected disabled hidden >Select</option>
                        <option value=1>Drawing / Data</option>
                        <option value=2>Sample Part</option>
                        <option value=3>Others</option>
                    </select>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">3) Concept evaluation</label>
                    <select class="col-6" formControlName="q2_3_select" (change)="valueChange($event)">
                        <option value="" selected disabled hidden >Select</option>
                        <option value=1>Accept - Exact Pkg</option>
                        <option value=2>Accept - Modify Pkg</option>
                        <option value=3>New Proposal</option>
                    </select>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6 ">4) Reason for modification or new proposal?</label>
                    <select class="col-6" formControlName="q2_4_select">
                        <option value="" selected disabled hidden >Select</option>
                        <option value=1>Part Shape</option>
                        <option value=2>Part Size</option>
                        <option value=3>Part Weight</option>
                        <option value=4>Material Change</option>
                        <option value=5>New Part Introduction</option>
                        <option value=6>Other</option>
                    </select>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6 ">5) If modification or new concept is needed can another exact Toyota packaging design be used?</label>
                    <span class="col-6">
                        <mat-radio-group aria-label="Select an option" formControlName="q2_5_yn" (change)="radioChange2($event)">
                            <mat-radio-button value="Y"  class="me-2">Yes</mat-radio-button>
                            <mat-radio-button value="N">No</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6 ">6) Was the above part copied into the PPF?</label>
                    <span class="col-6">
                        <mat-radio-group aria-label="Select an option"  formControlName="q2_6_yn" (change)="radioChange($event)">
                            <mat-radio-button value="Y" class="me-2">Yes</mat-radio-button>
                            <mat-radio-button value="N">No</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>
                <div class="d-grid col-lg-6 col-md-12 col-12 my-2 ">
                    <label>Part</label>
                    <input type="text" formControlName="q2_6_part">
                </div>
                <div class="d-grid col-lg-6 col-md-12 col-12 my-2 ">
                    <label>Model</label>
                    <input type="text" formControlName="q2_6_model">
                </div>
                
                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-6">7) Based on the revised proposal, is a new dunnage design needed?</label>
                    <span class="col-6 gap-4">
                        <mat-radio-group aria-label="Select an option" formControlName="q2_7_yn">
                            <mat-radio-button value="1"  class="me-2">Yes</mat-radio-button>
                            <mat-radio-button value="0" >No</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>

                <div class="col-lg-12 col-md-12 col-12 my-2">
                    <label class="col-12 ">{{rejectFlag ? 'Reject Reason' : 'Comments'}}</label>
                    <textarea rows="4" class="col-6" formControlName="comments"></textarea><br/>
                    <small *ngIf="sendPrePpf.get('comments').hasError('maxlength')">Maximum 250 Characters allowed</small>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex fixed-bottom">
    <button class="mx-3 black-button" *ngIf="okFlag" [disabled]="btnDisabled  || sendPrePpf.invalid" (click)="submit()">OK</button>
    <div class="mx-3 cancel" (click)="locationBack()">Cancel</div>
    <button class="black-button mx-3" [disabled]="sendPrePpf.invalid" (click)="holdInformation()" *ngIf="holdFlag">Hold Information</button>
    <button class="black-button mx-3" [disabled]="sendPrePpf.invalid" *ngIf="approvalFlag" (click)="approveReject(1)">Approve</button>
    <button class="black-button mx-3" [disabled]="sendPrePpf.invalid" *ngIf="rejectFlag" (click)="approveReject(2)">Reject</button>
</div>
</div>
