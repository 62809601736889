import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { PPFSelectBtnRender } from '../../gridrender/ppf-select-btn-render';
import { RegexFilter } from '../../regexFilter/regex-filter';
import { commodity } from '../select-package-ppf/selectpackageDropdowm.const';

@Component({
  selector: 'app-select-fabricator',
  templateUrl: './select-fabricator.component.html',
  styleUrls: ['./select-fabricator.component.scss'],
  providers: [RegexFilter]
})
export class SelectFabricatorComponent implements OnInit {
  public gridApi: any;
  public rowHeight = 50;
  public defaultColDef = {};
  public columnDefs: any[] = [];
  public domLayout:any ='';
  public displayRowData:any;
  public searchPackage: any;
  public validForm = false;
  public currentPageNo: any;
  public currentPageBoolean = false;
  public totalPage = 0;
  public currentPageForTotalPage  = 0;
  public isLastPageFound !: boolean;
  public paginationPageSize: any = 5;
  public rowCount !: number;
  public searchFabricator: any;
  protected commodityDropdown: { PACKAGETYPECODE: string; PACKAGETYPE: string; }[] = []
  public rowData:any = [];
  public filteredData:any = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private readonly formbuilder : FormBuilder, 
    public dialogRef: MatDialogRef<SelectFabricatorComponent>,
    protected ppfService: PpfService,
    public regex:RegexFilter
    ) {
    this.dialogRef.disableClose = true;
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,
      
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
   }

  ngOnInit(): void {
    let {row,PKGCODE} = this.data.params.data
    if(!PKGCODE){
      PKGCODE = ''
    }
    this.ppfService.selectFabricatorOnLoad(row, PKGCODE).subscribe((res)=>{
      this.rowData = res;
      this.displayRowData = res;
      this.rowCount = this.displayRowData.length;
    })
    this.commodityDropdown = commodity
    this.columnDefs = [
      {
        headerName: "Select", field: "select",cellRendererFramework: PPFSelectBtnRender,cellRendererParams: {
          clicked: (field:any)=>{
            this.btnSelected(field)
          }
        },
      },
      {
        headerName: "Code", field: "PKGSUPCODE",
      },
      {
        headerName: "Alpha code", field: "ALPHACODE",
      },
      {
        headerName: "Name", field: "NAME",
        cellStyle: (_params:any) => {return {'white-space':'break-spaces','word-break':'break-word','line-height': '20px','display':'flex','justify-content': 'flex-start', 'height': '40px','padding':'0 10px'};}
      },
      {
        headerName: "Commodity", field: "COMMODITY", 
        cellStyle: (_params:any) => {return {'white-space':'break-spaces','word-break':'break-word','line-height': '20px','display':'flex','justify-content': 'flex-start', 'height': '40px','padding':'0 10px'};}
      },
    ] ;
    
    this.searchFabricator = this.formbuilder.group(
      {
        code: [''],
        alphaCode: [''],
        name: [''],
        commodity: [''],
      }
    ) 
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage()}
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1}
  goToFirstPage() { this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();   
    if (this.currentPageNo === 0) { 
      this.currentPageBoolean = true;
      return this.currentPageBoolean;
    }
    else { 
      this.currentPageBoolean = false; 
      return this.currentPageBoolean;
    }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }


  onPaginationChanged() {
    if (this.gridApi) {
      this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }
  
  onPageSizeChanged(event: any) {
    if (event.target.value === "ALL"){
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    }else{
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params:any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi.sizeColumnsToFit();
  }
  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }


  btnSelected(params:any){
    this.dialogRef.close({data: params});   
  }

  onSearch(){
    const {code,alphaCode,name,commodity} = this.searchFabricator.value
    this.filteredData = this.rowData;   
    const filterParams: any = {
      ALPHACODE: alphaCode ? alphaCode : '*', 
      NAME: name ? name : '*',
      PACKAGETYPECODE: commodity ? commodity : '*',
      PKGSUPCODE: code ? code : '*'
    };
    
    if(filterParams.PACKAGETYPECODE === ''){
      filterParams.PACKAGETYPECODE = '*'
    }
      
    this.filteredData = this.regex.regexFilter(this.filteredData,filterParams)
    this.gridApi.setRowData(this.filteredData);
    this.rowCount = this.filteredData.length; 
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
