import { Component } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-log-renderer',
  templateUrl: './log-renderer.component.html',
  styleUrls: ['./log-renderer.component.scss']
})
export class LogRendererComponent {
  params: any;
  detail: any;
  date: any;

  agInit(params: any): void {
    this.params = params;
    if (params?.data.rowHeader !== '') {
      if (params?.data.lognumber === '101') {
        this.detail = 'Plant was added.';
      }
      else if (params?.data.lognumber === '102') {
        this.detail = 'User was added.'
      }
      else if (params?.data.lognumber === '103') {
        this.detail = 'Supplier was added.'
      }
      else if (params?.data.lognumber === '104') {
        this.detail = 'Supplier Contact was added.';
      }
      else if (params?.data.lognumber === '201') {
        this.detail = 'Plant was changed.';
      }
      else if (params?.data.lognumber === '202') {
        this.detail = 'User was changed.'
      }
      else if (params?.data.lognumber === '203') {
        this.detail = 'Supplier was changed.'
      }
      else if (params?.data.lognumber === '204') {
        this.detail = 'Supplier Contact was changed.';
      }
      else if (params?.data.lognumber === '301') {
        this.detail = 'Plant was deleted.';
      }
      else if (params?.data.lognumber === '302') {
        this.detail = 'User was deleted.'
      }
      else{
        this.load(params)
      }
      this.date = formatDate(params.data?.ins_date, 'MM/dd/YYYY', 'en-US');
    }
  }

  load(params:any){
    if (params?.data.lognumber === '303') {
      this.detail = 'Supplier was deleted.'
    }
    else if (params?.data.lognumber === '304') {
      this.detail = 'Supplier Contact was deleted.';
    }
    else if (params?.data.lognumber === '401') {
      this.detail = 'Part List was imported.';
    }
    else if (params?.data.lognumber === '402') {
      this.detail = 'Part Size was changed.'
    }
    else if (params?.data.lognumber === '403') {
      this.detail = 'Tracking Due was changed.'
    }
    else if (params?.data.lognumber === '601') {
      this.detail = 'There is part discrepancy.';
    }
    else if (params?.data.lognumber === '501') {
      this.detail = "You don't have confirmation to move package by supplier.";
    }
    else if (params?.data.lognumber === '602') {
      this.detail = 'There is no [Supplier] data.';
    }
    else if (params?.data.lognumber === '702') {
      this.detail = "File does not exist.";
    }
  }

}
