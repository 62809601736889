import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageConversionService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-conversion.service';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { PackageCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/package-conversion-btn-cell-render';
import { PackageMaintainDeletePopComponentComponent } from '../package-maintain-delete-pop-component/package-maintain-delete-pop-component.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-package-conversion',
  templateUrl: './package-conversion.component.html',
  styleUrls: ['./package-conversion.component.scss'],
})
export class PackageConversionComponent implements OnInit {
  isAll = false;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  gridColumnApi: any;
  disableConversion = true;
  totalRecords = 0;
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowClassRules: any;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  public domLayout: any = '';
  successMessage = '';
  errorList = '';
  plants = [] as any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rowCount = 0;
  context: any;
  response: any;
  radioVal: any = 0;
  bpackagecode = '';
  apackagecode = '';
  childHeader = 'child-header-grid'
  childHeaderNoBorder = 'child-header-grid no-border'
  originalPage: any;

  conversionForm:FormGroup = new FormGroup({
    plant : new FormControl('ALL'),
    weight: new FormControl(true),
    weightBasic: new FormControl({value : '', disabled : true}),
    weightRange: new FormControl({value : '', disabled : true}),
    Length: new FormControl(true),
    LengthBasic: new FormControl({value : '', disabled : true}),
    LengthRange: new FormControl({value : '', disabled : true}),
    Width: new FormControl(true),
    WidthBasic: new FormControl({value : '', disabled : true}),
    WidthRange: new FormControl({value : '', disabled : true}),
    Height: new FormControl(true),
    HeightBasic: new FormControl({value : '', disabled : true}),
    HeightRange: new FormControl({value : '', disabled : true}),
    PackageType: new FormControl(true),
    Standard: new FormControl(true),
    RET_EXP: new FormControl(true),
    Material: new FormControl(true),
    Nesting: new FormControl(true),
    ReturnHeight: new FormControl(true),
    Qty_ContLayer: new FormControl(true),
    Qty_LayerPallet: new FormControl(true),
    QtyKholder: new FormControl(true),
    QtyLabel: new FormControl(true),
    Label_Cost_Piece: new FormControl(true),
  })
  haveAccess: any;
  apiFromSearch = false;
  response2: any;

  constructor(
    public service: PackageConversionService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public constants: ConstantsService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService
  ) {
    this.context = { componentParent: this };
    const nullToDash = this.constants.nullToDashFormatter
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      unSortIcon: true,
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return nullToDash(params)
        } else {
          return null;
        }
      }
    };
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

 

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.spinnerService.start();
          this.haveAccess = res.role?.p_pkgview;
          this.haveAccess && this.setColumnDefs();
          this.haveAccess && this.getPlantDropdown();
          !this.haveAccess && this.spinnerService.stop();
          if (res.role.p_pkgmodify && res.role.p_pkgdelete) {
            this.disableConversion = false;
          } else {
            this.disableConversion = true;
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'SELECT', field: '', headerClass: this.childHeaderNoBorder, minWidth: 80, cellRendererFramework: PackageCellRendererComponent, cellStyle: { cursor: 'pointer' },
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 150, headerClass: this.childHeader,
        children: [
          {
            headerName: 'CODE', field: 'packagecode', headerClass: this.childHeaderNoBorder, minWidth: 150,
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'TYPE', field: 'packagetypecode', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'DESCRIPTION', field: 'description', headerClass: this.childHeaderNoBorder, minWidth: 150,
          },
        ],
      },

      {
        headerName: 'Outside Dimension(mm)', field: '', minWidth: 300, headerClass: this.childHeader,
        children: [
          {
            headerName: 'Length', field: 'outerlength', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.outerlength != null) {
                if (params.data.outerlength.trim().length !== 0) {
                  return parseInt(params.data.outerlength).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
          {
            headerName: 'Width', field: 'outerwidth', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.outerwidth != null) {
                if (params.data.outerwidth.trim().length !== 0) {
                  return parseInt(params.data.outerwidth).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
          {
            headerName: 'Height', field: 'outerheight', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.outerheight != null) {
                if (params.data.outerheight.trim().length !== 0) {
                  return parseInt(params.data.outerheight).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
        ],
      },

      {
        headerName: 'Inside  Dimension(mm)', field: '', minWidth: 240, headerClass: this.childHeader,
        children: [
          {
            headerName: 'Length', field: 'innerlength', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.innerlength != null) {
                if (params.data.innerlength.trim().length !== 0) {
                  return parseInt(params.data.innerlength).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
          {
            headerName: 'Width', field: 'innerwidth', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.innerwidth != null) {
                if (params.data.innerwidth.trim().length !== 0) {
                  return parseInt(params.data.innerwidth).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
          {
            headerName: 'Height', field: 'innerheight', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.innerheight != null) {
                if (params.data.innerheight.trim().length !== 0) {
                  return parseInt(params.data.innerheight).toString();
                } else {
                  return '';
                }
              } else {
                return '';
              }
            },
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'WEIGHT(KG)', field: 'weight', minWidth: 80, headerClass: this.childHeaderNoBorder,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.weight != null) {
                if (params.data.weight === '0.0000000') {
                  return '0';
                }
                if (params.data.weight.trim().length !== 0) {
                  return Number(params.data.weight).toFixed(2).toString();
                } else {
                  return '0';
                }
              } else {
                return '0';
              }
            },
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'MATERIAL', field: 'material', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'NEST(mm)', field: 'nesting', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.nesting != null) {
                if (params.data.nesting.trim().length !== 0) {
                  return Number(params.data.nesting).toFixed(1).toString();
                } else {
                  return '0';
                }
              } else {
                return '0';
              }
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        headerClass: this.childHeader,
        children: [
          {
            headerName: 'RETURN HEIGHT(mm)',
            field: 'retheight',
            headerClass: this.childHeaderNoBorder,
            minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.retheight != null) {
                if (params.data.retheight.trim().length !== 0) {
                  return Number(params.data.retheight).toFixed(1).toString();
                } else {
                  return '0';
                }
              } else {
                return '0';
              }
            },
          },
        ],
      },
      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'S/N', field: 'standard', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },

      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'R/E', field: 'returnable', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },

      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'QTY CONT/LAYER', field: 'qtycontlayer', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },

      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'QTY LAYER/PALLET', field: 'qtylayerpallet', headerClass: this.childHeaderNoBorder, minWidth: 80,
          },
        ],
      },

      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'QTY KHOLDER', field: 'qtykholder', headerClass: this.childHeaderNoBorder,minWidth: 80,
          },
        ],
      },

      {
        headerName: '', field: '', minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'QTY OF LABEL', field: 'qtylabel', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.qtylabel != null) {
                if (params.data.qtylabel === '0.0000000') {
                  return '0';
                }
                if (params.data.qtylabel.trim().length !== 0) {
                  return Number(params.data.qtylabel).toFixed(0).toString();
                } else {
                  return '0';
                }
              } else {
                return '0';
              }
            },
          },
        ],
      },

      {
        headerName: '', field: '',  minWidth: 80, headerClass: this.childHeader,
        children: [
          {
            headerName: 'LABEL COST/PIECE',field: 'costlabel', headerClass: this.childHeaderNoBorder, minWidth: 80,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.costlabel != null) {
                if (params.data.costlabel === '0.000') {
                  return '0';
                }
                if (params.data.costlabel.trim().length !== 0) {
                  return Number(params.data.costlabel).toFixed(2).toString();
                } else {
                  return '0';
                }
              } else {
                return '0';
              }
            },
          },
        ],
      },
    ];
  }

  onFailure(message: string) {
    this.errorList = message;
    setTimeout(() => {
      this.errorList = '';
    }, 4000);
  }

  Onsuccess(message: any) {
    this.successMessage = message;
    setTimeout(() => {
      this.successMessage = '';
    }, 4000);
  }

  getPlantDropdown() {
    this.spinnerService.start();
    this.service.getDropDownData().subscribe({
      next: (response: any) => {
        this.response = response;
        this.plants = [];
        response.result.map((obj: any) => {
          this.plants.push({
            code: obj.packagetypecode,
            type: obj.packagetype,
          });
        });
        this.plants.sort((a: any, b: any) => {
          const anum = a.code.toUpperCase();
          const bnum = b.code.toUpperCase();
          if (anum > bnum) {
            return 1;
          } else if (anum < bnum) {
            return -1;
          } else {
            return 0;
          }
        });
        this.onload();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.onFailure(_err);
      },
    });
  }

  getBody(){
    return {
      limit: this.rpPage,
      offset: this.cPage,
      selType: this.conversionForm.get('plant')?.value === 'ALL' ? '' : this.conversionForm.get('plant')?.value,
      OLength: this.conversionForm.get('Length')?.value ? '1' : '0',
      OWidth: this.conversionForm.get('Width')?.value ? '1' : '0',
      OHeight: this.conversionForm.get('Height')?.value ? '1' : '0',
      Weight: this.conversionForm.get('weight')?.value ? '1' : '0',
      Type: '1',
      Material: this.conversionForm.get('Material')?.value ? '1' : '0',
      Nest: this.conversionForm.get('Nesting')?.value ? '1' : '0',
      RetH: this.conversionForm.get('returnHeight')?.value ? '1' : '0',
      SN: this.conversionForm.get('Standard')?.value ? '1' : '0',
      RetExp: this.conversionForm.get('RET_EXP')?.value ? '1' : '0',
      ContLayer: this.conversionForm.get('Qty_ContLayer')?.value ? '1' : '0',
      LayerPallet: this.conversionForm.get('Qty_LayerPallet')?.value ? '1' : '0',
      KHOL: this.conversionForm.get('QtyKholder')?.value ? '1' : '0',
      QtyLab: this.conversionForm.get('QtyLabel')?.value ? '1' : '0',
      CostLab: this.conversionForm.get('Label_Cost_Piece')?.value ? '1' : '0',
      OLenBasic: this.conversionForm.get('LengthBasic')?.value,
      OWidBasic: this.conversionForm.get('WidthBasic')?.value,
      OHeiBasic: this.conversionForm.get('HeightBasic')?.value,
      WeiBasic: this.conversionForm.get('weightBasic')?.value,
      WeiRange: this.conversionForm.get('weightRange')?.value,
      OHeiRange: this.conversionForm.get('HeightRange')?.value,
      OWidRange: this.conversionForm.get('WidthRange')?.value,
      OLenRange: this.conversionForm.get('LengthRange')?.value,
    };
  }
  
  onload() {
    this.spinnerService.start();
    if(this.apiFromSearch){
      this.cPage = 1;
    }
    const data = this.getBody()
    this.service.fetchData(data).subscribe({
      next: (res: any) => {
        this.response2 = res;
        this.displayRowData = res.data;
        this.totalRecords = res.paginationCount.TotalRecords;
        this.rowCount = this.response2.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if(this.cPage > this.tPage){
          this.cPage = 1;
        }
        if(this.apiFromSearch){
          this.cPage = 1
        }
        this.apiFromSearch = false;
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.onFailure(_err);
      },
    });
  }

  onSearch() {
    if(this.isAll){
      this.apiFromSearch = true;
      const values = {
         cPage: 1,
         tPage: 1,
         rpPage: 'ALL',
       }
       this.changePaginationValues(values);
     }
     else{
       this.onload();
     }
  }

  reverseCall(packagecode: any) {
    if (this.radioVal === 0) {
      this.bpackagecode = packagecode.packagecode;
      this.radioVal = 1;
    } else {
      this.apackagecode = packagecode.packagecode;
    }
  }

  doConversion() {
    if (this.bpackagecode.trim() !== '' && this.apackagecode.trim() !== '') {
      if (this.bpackagecode.trim() !== this.apackagecode.trim()) {
        this.doConversionCall();
      } else {
        const msg = this.constants.beforePkgCodeEqualAfterPkgCode;
        this.onFailure(msg);
      }
    } else {
      const msg = this.constants.PkgCodesMustSelect;
      this.onFailure(msg);
    }
  }

  doConversionCall() {
    const demo = {
      bCode: this.bpackagecode,
      aCode: this.apackagecode,
      specialistcode: sessionStorage.getItem('specialistCode'),
    };
    const data = {
      message: `Are you sure you want to convert ${this.bpackagecode} into ${this.apackagecode} `,
      buttonName: 'Convert',
      headerName: 'Confirm Conversion',
    };
    if (
      this.bpackagecode.trim().length !== 0 &&
      this.apackagecode.trim().length !== 0
    ) {
      this.openDialog(data, demo);
    }
  }

  openDialog(data: any, demo: any) {
    const dialogRef = this.dialog.open(
      PackageMaintainDeletePopComponentComponent,
      { data, height: '300', width: '500px' }
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result === 'success') {
          this.spinnerService.start();
          this.service.doConversion(demo).subscribe({
            next: (res: any) => {
              if (res.status===1) {
                this.Onsuccess(res.result);
                this.resetselected();
                this.onSearch();
              } else if (res.status===0){
                this.onFailure(res.result);
              }else{
                this.onFailure('something went wrong')
              }
              this.spinnerService.stop();
            },
            error: (_err: any) => {
              this.onFailure(_err.error.error);
              this.spinnerService.stop();
            },
          });
        }
      }
    });
  }

  resetselected() {
    this.radioVal = 0;
    this.bpackagecode = '';
    this.apackagecode = '';
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    
    this.spinnerService.start();
    if(this.isAll){
      this.displayRowData = []

      for (let i=0; i<Math.ceil(this.totalRecords/5000); i++){
        this.cPage = i+1;
        this.tPage = 1;
        this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else{
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onload();
    }
  }
onLoadAll(){
this.onload()
if(this.apiFromSearch){
  this.totalRecords = this.gridApi.getDisplayedRowCount();
  this.apiFromSearch = false;
}
}
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 601]);
  }

  onlengthChange(event: any) {
    if (!event.target.checked) {
      this.conversionForm.controls['LengthBasic'].enable();
      this.conversionForm.controls['LengthRange'].enable();
    }else{
      this.conversionForm.controls['LengthBasic'].setValue('');
      this.conversionForm.controls['LengthRange'].setValue('');
      this.conversionForm.controls['LengthBasic'].disable();
      this.conversionForm.controls['LengthRange'].disable();
    }
  }

  onWidthChange(event: any) {
    if (!event.target.checked) {
      this.conversionForm.controls['WidthBasic'].enable();
      this.conversionForm.controls['WidthRange'].enable();
    }else{
      this.conversionForm.controls['WidthBasic'].setValue('');
      this.conversionForm.controls['WidthRange'].setValue('');
      this.conversionForm.controls['WidthBasic'].disable();
      this.conversionForm.controls['WidthRange'].disable();
    }
  }

  onHightChange(event: any) {
    if (!event.target.checked) {
      this.conversionForm.controls['HeightBasic'].enable();
      this.conversionForm.controls['HeightRange'].enable();
    }else{
      this.conversionForm.controls['HeightBasic'].setValue('');
      this.conversionForm.controls['HeightRange'].setValue('');
      this.conversionForm.controls['HeightBasic'].disable();
      this.conversionForm.controls['HeightRange'].disable();
    }
  }

  onWeightChange(event: any) {
    if (!event.target.checked) {
      this.conversionForm.controls['weightBasic'].enable();
      this.conversionForm.controls['weightRange'].enable();
    }else{
      this.conversionForm.controls['weightBasic'].setValue('');
      this.conversionForm.controls['weightRange'].setValue('');
      this.conversionForm.controls['weightBasic'].disable();
      this.conversionForm.controls['weightRange'].disable();
    }
  }
  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response2.paginationCount.TotalRecords;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }
}