<div class="d-flex justify-content-between align-items-center mx-3">
    <div class="page-title">Package Conversion</div>
    <button class="help-btn" (click)="help()">Help</button>
</div>
<div class="message pt-4"><span class="dot"></span>Only mm and kg unit</div>
<div id='err' class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <span>{{errorList}}</span>
</div>
<div id='err' class="alert alert-success mx-2" *ngIf="successMessage.length > 0">
    <span>{{successMessage}}</span>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
</div>

<div class="vpacs-shadow m-3 p-2" *ngIf="haveAccess">
    <div class="filter m-2 d-flex" [formGroup]="conversionForm" (keyup.enter)="onSearch()">
        <div class="row row-cols-7 align-items-center" >
            <div class="col d-flex align-items-center gap-1"  >
                <label>Type</label>
                <mat-select id="plant" class="mat-sel ml-2 pt-2 ms-2" disableOptionCentering formControlName="plant">
                    <mat-option value='ALL'> All</mat-option>
                    <mat-option *ngFor="let p of plants" [value]="p.code">{{p.code}} : {{p.type}}</mat-option>
                </mat-select>
            </div>
            <div class="col custom-col d-flex">
                <label class="align-items-center">Outside Dimension</label>
            </div>
            <div class="col d-flex align-items-center gap-2">
                <label class="checkcontainer mb-0">Length
                    <input type="checkbox" formControlName="Length" (change)=onlengthChange($event)>
                    <span class="checkmark"></span>
                </label>
                <input type="text" class="align-items-center" placeholder="Basic" formControlName="LengthBasic">
                <input type="text" placeholder="Range"  formControlName="LengthRange">
            </div>
            <div class="col d-flex  align-items-center gap-2">
                <label class="checkcontainer mb-0">Width
                    <input type="checkbox" formControlName="Width" (change)=onWidthChange($event)>
                    <span class="checkmark"></span>
                </label>
                <input type="text" placeholder="Basic" formControlName="WidthBasic">
                <input type="text" placeholder="Range" formControlName="WidthRange">
            </div>
            <div class="col custom-row d-flex  align-items-center gap-2">
                <label class="checkcontainer mb-0">Height
                    <input type="checkbox" formControlName="Height" (change)=onHightChange($event)>
                    <span class="checkmark"></span>
                </label>
                <input type="text" placeholder="Basic" formControlName="HeightBasic">
                <input type="text" placeholder="Range" formControlName="HeightRange">
            </div>
            <div class="col d-flex align-items-center gap-2">
                <label class="checkcontainer mb-0">Weight
                    <input type="checkbox" formControlName='weight' (change)=onWeightChange($event)>
                    <span class="checkmark"></span>
                </label>
                <input type="text" class="align-items-center" placeholder="Basic" formControlName="weightBasic">
                <input type="text" placeholder="Range" formControlName="weightRange">
            </div>
        </div>
        <div class="col align-items-center " >
            <label class="checkcontainer mb-0">Package Type
                <input type="checkbox" formControlName="PackageType">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Standard
                <input type="checkbox" formControlName="Standard">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">RET/EXP
                <input type="checkbox" formControlName="RET_EXP">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Material
                <input type="checkbox" formControlName="Material">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Nesting
                <input type="checkbox" formControlName="Nesting">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Return Height
                <input type="checkbox" formControlName="ReturnHeight">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Qty Cont/Layer
                <input type="checkbox" formControlName="Qty_ContLayer">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Qty Layer/Pallet
                <input type="checkbox" formControlName="Qty_LayerPallet">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Qty Kholder
                <input type="checkbox" formControlName="QtyKholder">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Qty Label
                <input type="checkbox" formControlName="QtyLabel">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col align-items-center ">
            <label class="checkcontainer mb-0">Label Cost/Piece
                <input type="checkbox" formControlName="Label_Cost_Piece">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
    <div class=" m-2 d-flex flex-wrap align-items-center">
        <div class="d-flex gap-2 align-items-center">
            <label>Before Package Code<span style="color:#ff0000">*</span></label>
            <div class="col d-flex">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group "
                    [(ngModel)]="radioVal">
                    <mat-radio-button class="example-radio-button" [value]='0'></mat-radio-button>
                </mat-radio-group>
                <input id="before" type="text" [(ngModel)]="bpackagecode">
            </div>
        </div>
        <input type="image" img src="../../../../assets/images/right-arrow.svg" alt="rightArrow" class="mx-3 image-icon">
        <div class="d-flex gap-2 align-items-center">
            <label>After Package Code<span style="color:#ff0000">*</span></label>
            <div class="col d-flex">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group "
                    [(ngModel)]="radioVal">
                    <mat-radio-button class="example-radio-button" [value]='1'></mat-radio-button>
                </mat-radio-group>
                <input id="after" type="text" [(ngModel)]="apackagecode">
            </div>
        </div>
        <div class="d-flex mx-2 gap-3 align-items-center">
            <button class="vpacs-btn-table" (click)="doConversion()" [disabled]="disableConversion">CONVERSION</button>
            <button class="black-button" (click)='onSearch()'>Search</button>
        </div>
    </div>
</div>
<div class="vpacs-shadow m-3 p-0 mb-0 mb-5" *ngIf="haveAccess">
    <div class="grid-agg px-0 grid-height-package-conversion">
        <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height: 100%"
            (gridReady)="onGridReady($event)" (window:resize)="sizeToFit()" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
            [suppressCellSelection]="true" [pagination]="true" [suppressPaginationPanel]="true" [icons]="icons"
            [headerHeight]="40" [gridOptions]="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" [domLayout]="domLayout"
            [rowSelection]="rowSelection" [rowClassRules]="rowClassRules" (filterChanged)="onFilterChanged($event)">
        </ag-grid-angular>
        <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
        </app-pagination>
    </div>
</div>