import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() cPage:any;
  @Input() tPage:any;
  @Input() rpPage:any;
  @Input() totalRecords:any;
  @Output() changePaginationValues:EventEmitter<any> = new EventEmitter();
  pgDropDown = [
    {value: 50 , selected : true},
    {value: 100 , selected : false},
    {value: 200 , selected : false},
    {value: 'ALL' , selected : false},
  ]

  goToFirstPage(){
    if(this.cPage > 1){
      this.cPage = 1;
      const values = {
        cPage: this.cPage,
        tPage: this.tPage,
        rpPage: this.rpPage,
      }
      this.changePaginationValues.emit(values)
    }
  }

  goToPreviousPage(){
    if(this.cPage > 1){
      this.cPage--;
      const values = {
        cPage: this.cPage,
        tPage: this.tPage,
        rpPage: this.rpPage,
      }
      this.changePaginationValues.emit(values)    
    }
  }

  goToNextPage(){
    if(this.cPage < this.tPage){
      this.cPage++;
      const values = {
        cPage: this.cPage,
        tPage: this.tPage,
        rpPage: this.rpPage,
      }
      this.changePaginationValues.emit(values)    
    }
  }

  goToLastPage(){
    if(this.cPage < this.tPage){
      this.cPage = this.tPage;
      const values = {
        cPage: this.cPage,
        tPage: this.tPage,
        rpPage: this.rpPage,
      }
      this.changePaginationValues.emit(values)    
    }
  }

  onlyOnePage():boolean{
    return this.tPage === 1;
  }

  isLastPage():boolean{
    return this.cPage === this.tPage;
  }

  rppChanged(_event:any){
    this.cPage = 1;
    this.tPage = this.rpPage !== this.totalRecords ? Math.ceil(this.totalRecords / this.rpPage) : 1;
    const values = {
      cPage: this.cPage,
      tPage: this.tPage,
      rpPage: this.rpPage!== this.totalRecords ? this.rpPage : 'ALL',
    }
    this.changePaginationValues?.emit(values)  
  }

}
