import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuard, MsalService } from '../msal';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../core/dialogs/logout/logout.component';
 
@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTimeout: any;
  private readonly logoutTime = 58 * 60 * 1000; // 58 minutes
 
  constructor(private readonly ngZone: NgZone,public authService: MsalService, private readonly dialog: MatDialog,public router:Router,public msal:MsalGuard) {
    this.startMonitoring();
  }
 
  startMonitoring(): void {
    this.resetTimeout(); // Start monitoring immediately
    ['mousemove', 'keydown', 'click'].forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }
 
  resetTimeout(): void {
    // Clear the existing timer
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
 
    // Set a new timer
    this.inactivityTimeout = setTimeout(() => {
      this.ngZone.run(() => {
        this.logoutUser();
      });
    }, this.logoutTime);
  }
 
  logoutUser(): void {
    const dialogRef = this.dialog.open(LogoutComponent,{disableClose : true});
    dialogRef.afterClosed().subscribe((result:any)=>{
      if(result){
        setTimeout(() => {
          sessionStorage.removeItem('idToken');
          sessionStorage.removeItem('exp');
          localStorage.removeItem('tracking');
          localStorage.removeItem('roles');
          localStorage.removeItem('toyotaGUID');
          this.router.navigate(['/']);
          this.authService.logout();
        }, 0);
      }else{
        this.msal.setUserInfo();
      }
    })
  }
}
