import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/update-user/update-user.component';
import { UserBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/user-btn-cell-render';
import { ExportStateService } from 'src/app/services/export-state.service';
import { UserMaintenanceService } from '../../../services-internal/master-maintenance/user-maintenance.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormControl, FormGroup } from '@angular/forms';
import { ValueGetterParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constant.service';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit {
  isAll=false;
  apiFromSearch=false;
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  cPage = 1;
  tPage = 0;
  rpPage =50;
  totalRecords = 0;
  originalPage: any;
  plantValue: any;
  plant: Observable<any[]> | undefined;
  plants: any;


  public userFilters = new FormGroup({
    code: new FormControl(''),
    fname: new FormControl(''),
    lname: new FormControl(''),
    plant: new FormControl(''),
  });
  haveErrors = false;
  errorList: string[] = [];
  userInfo: any;
  haveAccess: any;
  noOfRecords: any;
  response: any;
  addAccess: any;
  deleteAccess: any;
  successMessage = '';

  constructor(
    private readonly dialog: MatDialog,
    private readonly userService: UserMaintenanceService,
    public state: ExportStateService,
    private readonly spinner: NgxUiLoaderService,
    private readonly router: Router,
    public constants: ConstantsService
  ) {
    const nullToDash = this.constants.nullToDashFormatter;
    const cellStyleColumn = this.constants.cellStyle;
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      cellStyle:cellStyleColumn,
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return nullToDash(params)
        } else {
          return null;
        }
      }
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res && res.role?.p_userview;
        this.addAccess = res && res.role?.p_usersave;
        this.deleteAccess = res && res.role?.p_usersave;
        if (res && this.haveAccess) {
          this.spinner.start();
          this.haveErrors = false;
          this.userFilters.patchValue({ plant: res.info.plantcode });
          this.userInfo = res.info;
          this.getColumnDefs();
          this.getPlantDropDown();
        } else {
          if (res) {
            this.haveErrors = true;
            this.errorList = ["You're not authorized to access this page"];
            this.spinner.stop();
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.errorList.push(_err.message)
        this.spinner.stop();
      }
    });
  }

 getPlantDropDown() {
    this.userService.loadAllPlants().subscribe({
      next: (res: any) => {
        this.plants = res;
        this.plantValue = this.userFilters.patchValue({ plant: this.userInfo.plantcode });
        const obj = res.find((obj:any) => obj.code === '');
        if (!obj) {
          res.unshift({ name: 'ALL', code: '' });
        }
        this.displayFn(this.plants)
        this.plant = this.plant ?? 
        this.userFilters.get('plant')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value)),
        )
        res.length > 0 && this.apiCall();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    })
  }

  displayFn(selectId:any){
    const selectedOption = this.plants?.find((option:any) => 
    option.code === selectId);
    return selectedOption ? selectedOption.name : ''; 
   }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.plants.filter((option:any) =>
      option.name.toLowerCase().includes(filterValue),
    )
  }
  
  

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "Code", field: "specialistcode", minWidth: 120, filter: 'agTextColumnFilter',
      },
      {
        headerName: "First Name", field: "firstname", minWidth: 120, maxWidth:160
      },
      {
        headerName: "Last Name", field: "lastname", minWidth: 120, maxWidth:160
      },
      {
        headerName: "Middle Initial", field: "middleinitial", minWidth: 120, maxWidth:160
      },
      {
        headerName: "Plant", field: "plantname", minWidth: 120,
      },
      {
        headerName: "Division", field: "divcode", minWidth: 90,
      },
      {
        headerName: "Supervisor Name", field: "boss", minWidth: 120, maxWidth:160
      },
      {
        headerName: "Title", field: "title", minWidth: 100,
      },
      {
        headerName: "Phone", field: "phone", minWidth: 150,
      },
      {
        headerName: "FAX", field: "fax", minWidth: 150,
      },
      {
        headerName: "Ext", field: "ext", minWidth: 150,
      },
      {
        headerName: "E-mail", field: "email", minWidth: 190, maxWidth:200
      },
      {
        headerName: "Back Up", field: "substitute", minWidth: 150,
      },
      {
        headerName: "Valid", field: "validflag", minWidth: 100, sortable: false, floatingFilter: false, unSortIcon: false,
        valueGetter: (params: any) => this.valueGetter(params)
      },
      {
        headerName: "Group User", field: "groupUser", minWidth: 100, sortable: false, floatingFilter: false, unSortIcon: false,
        valueGetter: (params: any) => this.valueGetter1(params)
      },
      {
        headerName: "UPDATE", field: 'update', sortable: false, floatingFilter: false, unSortIcon: false, minWidth: 110, cellRendererFramework: UserBtnCellRender,
        cellRendererParams: {
          updateaccess: this.addAccess,
          clicked: (colId: string, data: any) => {
            this.openDialog(colId, data)
          },
        },
      },
      {
        headerName: "Delete", field: "delete", sortable: false, floatingFilter: false, unSortIcon: false, minWidth: 110, cellRendererFramework: UserBtnCellRender,
        cellRendererParams: {
          deleteaccess: this.deleteAccess,
          clicked: (colId: string, data: any) => {
            this.openDialog(colId, data)
          },
        },
      },
    ]
  }

  valueGetter(params: ValueGetterParams) {
    if (params.data.validflag === "1") {
      return 'Yes'
    } else {
      return 'No'
    }
  }

  valueGetter1(params:ValueGetterParams){
    if(params.data.isgroupuser === 'Y'){
      return "Yes"
    }else{
      return 'No'
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinner.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 3000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 3000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearch();
    }
  }

  async onLoadAll() {
    this.spinner.start();
    const plantCheck = this.plants.filter((item: any) => (item.code?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()) ||
    (item.name?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()));
   this.userFilters.get('plant')?.setValue(plantCheck[0]?.code)
    const loadObj = {
      "page": this.cPage,
      "limit": this.rpPage,
      "code": this.userFilters.get('code')?.value,
      "firstName": this.userFilters.get('fname')?.value,
      "lastName": this.userFilters.get('lname')?.value,
      "plant": this.userFilters.get('plant')?.value === 'All' ? '' : this.userFilters.get('plant')?.value
    }
    this.userService.loadUserData(loadObj).subscribe({
      next: (supplierData: any) => {
        const gridData: any[] = []
          gridData.push(...supplierData.result.data)
          this.displayRowData.push(...gridData)
          this.gridApi.setRowData(this.displayRowData)
          if (this.apiFromSearch) {
            this.totalRecords = this.gridApi.getDisplayedRowCount();
            this.apiFromSearch = false;
          }
      },
      error: () => {
        this.spinner.stop();
      },
      complete: () => {
        this.spinner.stop();
      }
    });
  }

  apiCall() {
    const plantCheck = this.plants.filter((item: any) => (item.code?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()) ||
     (item.name?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()));
    this.userFilters.get('plant')?.setValue(plantCheck[0]?.code)
    const body = {
      "page": this.cPage,
      "limit": this.rpPage,
      "code": this.userFilters.get('code')?.value,
      "firstName": this.userFilters.get('fname')?.value,
      "lastName": this.userFilters.get('lname')?.value,
      "plant": this.userFilters.get('plant')?.value === 'All' ? '' : this.userFilters.get('plant')?.value
    }
    this.spinner.start();
    this.gridApi?.showLoadingOverlay();
    this.userService.loadUserData(body).subscribe({
      next: (res: any) => {
        if (res && Array.isArray(res.result.data) && res.result.status) {
          this.response = res;
          this.displayRowData = res.result.data;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = res.pagination.TotalRecords;
          this.noOfRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinner.stop();
        } else {
          this.displayRowData = [];
          this.rowCount = this.displayRowData.length;
          this.totalRecords = 0;
          this.noOfRecords = 0;
          this.tPage = 0;
          this.spinner.stop();
          this.gridApi?.hideOverlay();
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinner.stop();
      },
    })
  }

  onSearch(){
    if (this.isAll) {
      this.apiFromSearch = true;
      const values = {
        cPage: 1,
        tPage: 1,
        rpPage: 'ALL',
      }
      this.changePaginationValues(values);
    }
    const plantCheck = this.plants.find((item: any) => (item.code?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()) ||
     (item.name?.toLowerCase() === this.userFilters.get('plant')?.value?.toLowerCase()));
    if ((!plantCheck)) {
      this.haveErrors = true;
      this.errorList = ["Please select valid plant name"];
    }
    else {
    this.errorList = []
    setTimeout(() => {
      this.apiCall();
    });
  }
  }

  openDialog(pageName: string, data?: any) {
    const plantData = this.plants.slice(1);
    const dialogRef = this.dialog.open(UpdateUserComponent, { data: { pageName: pageName, row: data, plants: plantData, userInfo: this.userInfo }, width: '80%' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'success') {
          this.successMessage = result.data.message;
          setTimeout(() => {
            this.successMessage = '';
          },5000);
          this.apiCall()
        } else {
          if (pageName === 'delete') {
            this.haveErrors = true;
            this.errorList = ["Unable to Delete the User"]
            setTimeout(() => {
              this.haveErrors = false;
              this.errorList = [];
            }, 3000);
          }
        }
      }
    })
  }

  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response.pagination.TotalRecords;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }
  help() {
    this.router.navigate(['./master-help', 5, "Master Maintenance", 115])
  }
}