<div class="doc">
  <div
    class="d-flex justify-content-between align-items-center"
    style="margin: 0 5px"
  >
    <p class="page-title">Decision List</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
    </div>
  </div>
  <div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
    <span>{{ successMessage }}</span>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
  </div>
  <div class="vpacs-shadow m-2 px-2 py-2" *ngIf="haveAccess">
    <div class="filter mx-1 d-flex">
      <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="specialist" class="col-3">Specialist</label>
        <input
          type="text"
          class="ms-3"
          id="specialist"
          [(ngModel)]="specialist"
          [ngModelOptions]="{ standalone: true }" 
          style="width: 100%"
          (keyup.enter)="onSearch()"
        />
        <button mat-icon-button>
          <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()"
            >more_vert</mat-icon
          >
        </button>
      </div>
      <div class="d-flex flex-wrap event-checkbox col-lg-2 col-12 me-4">
        <div class="d-flex align-items-center gap-3" (keyup.enter)="onSearch()">
          <label class="checkcontainer mb-0"
            >Tracking
            <input type="checkbox" [(ngModel)]="tracking" />
            <span class="checkmark"></span>
          </label>
          <label class="checkcontainer mb-0"
            >Cost
            <input type="checkbox" [(ngModel)]="cost" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="d-flex align-items-center me-3">
        <button class="black-button" (click)="onSearch()">Search</button>
      </div>
      <div class="d-flex align-items-center">
        <button class="black-button" (click)="do()">DO</button>
      </div>
    </div>
  </div>

  <div *ngIf="haveAccess">
    <div class="grid-agg mt-3 tracking-summary grid-height-decision-list mb-5">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [suppressCellSelection]="true"
        [pagination]="true"
        [paginationPageSize]="rpPage"
        [suppressPaginationPanel]="true"
        [icons]="icons"
        [headerHeight]="20"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40"
        (filterChanged)="onFilterChanged($event)"
        [enableBrowserTooltips]="true"
      >
      </ag-grid-angular>
      <app-pagination
        [cPage]="cPage"
        [tPage]="tPage"
        [rpPage]="rpPage"
        [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)"
        *ngIf="totalRecords > 0"
      >
      </app-pagination>
    </div>
  </div>
</div>

