<div class="page-title p-1 px-3">Shipment</div>
<div class="vpacs-shadow m-2 py-1 px-2">
<div class="filter d-flex" [formGroup]="searchParameters" (keyup.enter)="onSearch()">
  <div class="d-flex pe-5 align-items-center">
    <label for="shipment-no">Shipment#</label>
    <input type="text" id="shipment-no" formControlName="shipment" />
  </div>
  <div class="d-flex pe-5 align-items-center">
    <label for="receiver">Receiver</label>
    <input type="text" id="receiver" formControlName="receiver" />
  </div>
  <div class="d-flex pe-5 align-items-center">
    <label for="pickup">Pickup</label>
    <input type="date" id="pickup" formControlName="pickup" />
  </div>

  <div class="d-flex pe-5 align-items-center">
    <label for="supplier">Supplier</label>
    <select
      formControlName="supplier"
      id="supplier"
      (valueChange)="valueChange($event)"
      class="mat-sel"
      disableOptionCentering
    >
      <option *ngFor="let s of supplierNumber; index as i" [value]="i">
        {{ s }}
      </option>
    </select>
  </div>

  <div class="d-flex pe-5 align-items-center">
    <label for="shipper">Shipper</label>
    <input type="text" id="shipper" formControlName="shipper" />
  </div>

  <div class="d-flex pe-5 align-items-center">
    <label for="shipstep">Status</label>
    <mat-select formControlName="status" class="mat-sel pt-2" disableOptionCentering>
      <mat-option value="" disabled hidden selected>Select</mat-option>
      <mat-option *ngFor="let sp of shipmentstep" [value]="sp.value">
        {{ sp.value }}
      </mat-option>
    </mat-select>
  </div>

  <div class="d-flex pe-5 align-items-center">
    <label for="delivery">Delivery</label>
    <input type="date" id="delivery" formControlName="delivery" />
  </div>

  <div class="d-flex align-items-center gap-2">
    <button class="black-button" (click)="onSearch()">Search</button>
    <button class="black-button" (click)="callNewShipment()">
      New Shipment
    </button>
  </div>
</div>
</div>
<div class="grid-agg grid-height-shipment p-2">
  <ag-grid-angular
    style="width: 100%; height:100%"
    class="ag-theme-balham"
    (gridReady)="onGridReady($event)"
    (window:resize)="sizeToFit()"
    [animateRows]="true"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [rowData]="displayRowData"
    [suppressCellSelection]="true"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [suppressPaginationPanel]="true"
    [icons]="icons"
    [headerHeight]="40"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [floatingFiltersHeight]="40"
    [domLayout]="domLayout"
    (paginationChanged)="onPaginationChanged()"
  >
  </ag-grid-angular>
  <div
    class="d-flex pagination align-items-center justify-content-between"
    *ngIf="rowCount > 0"
  >
    <div>
      <div>
        <label for="pagination" class="page-drpdwn-label mx-2">Records/Page: </label>
        <select
          style="height: 30px"
          (change)="onPageSizeChanged($event)"
          id="pagination"
        >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50" selected>50</option>
          <option value="{{ rowCount }}">ALL</option>
        </select>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <div>Viewing Records</div>
      <mat-icon
        (click)="goToFirstPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: getCurrentPage() === true,
          enable_icon: getCurrentPage() !== true
        }"
        >first_page</mat-icon
      >
      <mat-icon
        (click)="goToPreviousPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: getCurrentPage() === true,
          enable_icon: getCurrentPage() !== true
        }"
        >fast_rewind</mat-icon
      >
      <div>
        (
        <span class="value" id="lbCurrentPage"></span>-
        <span class="value" id="lbTotalPages"></span>)
        <span>OF {{ rowCount }}</span>
      </div>
      <mat-icon
        (click)="goToNexpage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: isLastPage() === true,
          enable_icon: isLastPage() !== true
        }"
        >fast_forward</mat-icon
      >
      <mat-icon
        (click)="goToLastPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: isLastPage() === true,
          enable_icon: isLastPage() !== true
        }"
        >last_page</mat-icon
      >
    </div>
  </div>
</div>

<router-outlet></router-outlet>