<div class="wrapper bg-white">
    <br><p class="page-title p-1 px-3">Add Rack Shipment</p>
    <br>
    <div class="mat-typography content">
        <div class="d-flex flex-wrap col px-3 py-3">
            <div class="d-flex align-items-center  col-lg-6 col-md-6 col-12">
                <label for="Serial" class="col-2">Serial Number</label>
                <input type="text"  id="auth#"/>-<input type="text"  id="modelCode"/>
            </div>
            <div class="d-flex justify-content-end align-items-center col-lg-5 col-md-6 col-12 my-2">
                <button class="black-button mx-1" >Search</button>
            </div>
        </div>
         <div class="filter d-flex">
            <div class="grid-agg">
                <ag-grid-angular style="width:100%;height:100px;" 
                class="ag-theme-balham" 
                (gridReady)="onGridReady($event);"
                [animateRows]="true" 
                [defaultColDef]="defaultColDef" 
                [columnDefs]="columnDefs"                
                [rowData]="displayRowData" 
                [suppressCellSelection]="true"
                [rowSelection]="rowSelection"
                [suppressPaginationPanel]="true" 
                [icons]="icons"
                [headerHeight]="40"
                [domLayout]="domLayout"
                (selectionChanged)="onSelectionChanged($event)"
                [floatingFiltersHeight]="40">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="footer-dialog d-flex">
        <div (click)="onCancel()" class="mx-3 cancel">Cancel</div>
        <button class=" mx-3 add" (click)="onAdd()">ADD</button>
    </div>   
</div>
    

