import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Column, SortChangedEvent } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConnectallModelListComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/connectall-model-list/connectall-model-list.component';
import { CompareBtnCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/compare-btn-cell-render';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';

@Component({
  selector: 'app-modellist-connect',
  templateUrl: './modellist-connect.component.html',
  styleUrls: ['./modellist-connect.component.scss'],
})
export class ModellistConnectComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  sortModel: any[] = [];
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 30;
  isAccessible = false;
  Supplier = '';
  modelcode = '';
  plantcode = '';
  partnumber: any = '';
  suppliercode = '';
  sort = '';
  page = '';
  limit = '';
  gridColumnApi: any;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  paginationPageSize = 50;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber: any;
  lastRowIndex = 0;
  importCode: any;
  connect: any;
  plantCode: any;
  modelCode: any;
  connectlevel: any;
  connectLevel: any;
  supplierCode: any;
  connectParams: any;
  errorList: string[] = [];
  successMessage: string[] | string = [];
  apiSearch = false;
  originalPage: any;
  isAll = false;
  loadResponse: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly service: ModelListService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      unSortIcon: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      onSortChanged: this.onSortChanged.bind(this),
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
    this.connectParams = JSON.parse(
      sessionStorage.getItem('connectParams') || '{}'
    );
    this.getColumnDefs();
    const filterdata2 = this.service.getmodelConnectFilters();
    this.partnumber = filterdata2?.partNumber;
    this.Supplier = filterdata2?.supplier;
    this.apiCall();
    this.service.setmodelConnectFilters(null)
  }

  getColumnDefs() {
    const headerColor = 'child-header-color';
    this.columnDefs = [
      {
        headerName: 'NEW PART',
        minWidth: 180,
        children: [
          {
            headerName: 'PROTO/PRODUCTION PART#',
            field: 'partnumber',
            sortable: true,
            unSortIcon: true,
            minWidth: 150,
            headerClass: headerColor,
          },
          {
            headerName: 'SUPPLIER CODE',
            field: 'suppliercode',
            minWidth: 130,
            headerClass: headerColor,
          },
        ],
      },

      {
        headerName: 'MATCHING CURRENT',
        field: 'AUTHREAD',
        minWidth: 180,
        class: 'model-border',
        children: [
          {
            headerName: 'PART #',
            field: 'tmmccode',
            minWidth: 130,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'SUPPLIER CODE',
            field: 'AUTHREAD',
            minWidth: 120,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'PLANT',
            field: 'plant',
            minWidth: 120,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'QPC',
            field: 'qpc',
            minWidth: 120,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'M3/PART',
            field: 'm3part',
            sortable: true,
            unSortIcon: true,
            minWidth: 90,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'DESCRIPTION(PART NAME)',
            minWidth: 250,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'Package',
            minWidth: 250,
            headerClass: headerColor,
            children: [
              {
                headerName: 'Code',
                field: 'pkgcode',
                minWidth: 120,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
              {
                headerName: 'Cost',
                field: 'pkgcost',
                minWidth: 100,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
            ],
          },
          {
            headerName: 'Dunnage 1',
            minWidth: 250,
            headerClass: headerColor,
            children: [
              {
                headerName: 'Code',
                field: 'dun1code',
                minWidth: 120,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
              {
                headerName: 'Cost',
                field: 'dun1cost',
                minWidth: 100,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
            ],
          },
          {
            headerName: 'Dunnage 2',
            minWidth: 250,
            headerClass: headerColor,
            children: [
              {
                headerName: 'Code',
                field: 'dun2code',
                minWidth: 120,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
              {
                headerName: 'Cost',
                field: 'dun2cost',
                minWidth: 100,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
            ],
          },
          {
            headerName: 'Pallet',
            minWidth: 250,
            headerClass: headerColor,
            children: [
              {
                headerName: 'Code',
                field: 'palcode',
                minWidth: 120,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
              {
                headerName: 'Cost',
                field: 'palcost',
                minWidth: 100,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
            ],
          },
          {
            headerName: 'Top',
            minWidth: 250,
            headerClass: headerColor,
            children: [
              {
                headerName: 'Code',
                field: 'topcode',
                minWidth: 120,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
              {
                headerName: 'Cost',
                field: 'topcost',
                minWidth: 100,
                headerClass: headerColor,
                valueFormatter: this.dollarFormatter,
              },
            ],
          },
          {
            headerName: 'CONNECT LEVEL',
            field: 'connectlevel',
            minWidth: 130,
            headerClass: headerColor,
            valueFormatter: this.nullToDashFormatter,
          },
          {
            headerName: 'COMPARE',
            field: 'compare',
            sortable: false,
            unSortIcon: false,
            minWidth: 120,
            headerClass: headerColor,
            filter: false,
            cellRendererFramework: CompareBtnCellRendererComponent,
          },
        ],
      },
    ];
  }

  apiCall() {
    this.spinnerService.start();
    const connectObject = {
      ModelCode: this.connectParams.modelCode,
      PlantCode: this.connectParams.plantCode,
      partNumber: this.partnumber,
      supplier: this.Supplier,
      limit: this.rpPage,
      page: this.cPage,
      sort: ''
    };
    this.service.setmodelConnectFilters(connectObject);
    if (this.apiSearch) {
      this.cPage = 1;
    }
    this.service.connectOnload(connectObject).subscribe({
      next: (res: any) => {
        this.loadResponse = res;
        this.displayRowData = res.result === -1 ? [] : res.result;
        this.rowCount = res.pagination.rows;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        if (this.apiSearch) {
          this.cPage = 1;
        }
        this.apiSearch = false;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.rowCount = 0;
        this.spinnerService.stop();
      },
    });
  }

  connectAll() {
    const dialogRef = this.dialog.open(ConnectallModelListComponent, {
      data: {
        data: this.displayRowData
      },
    });
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'SaveEvent') {
        this.apiCall();
        this.successMessage = "Added/Updated Successfully"
        setTimeout(() => {
          this.successMessage = [];
        }, 5000);
      } else {
        return;
      }
    });
  }

  Close() {
    this.errorList = [];
    this.successMessage = [];
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;

    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = [];
      for (let i = 0; i < Math.ceil(this.totalRecords / 100); i++) {
        (this.cPage = i + 1); (this.tPage = 1); (this.rpPage = 100);
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    } else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.apiCall();
      if (this.apiSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiSearch = false;
      }
      resolve();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    this.gridApi?.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.gridColumnApi?.applyColumnState(this.sortModel);
    if (this.service.getconnectfilter()) {
      const retrivedValue = this.service.getconnectfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  filterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.service.setconnectfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.loadResponse.pagination.TotalRecords;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  onBack() {
    this.service.setconnectfilter(null);
    this.service.setmodelConnectFilters(null)
    this.router.navigate(['/model-list'])
  }

  nullToDashFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null) {
      return '-';
    } else {
      return params?.value;
    }
  }

  dollarFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null) {
      return '-';
    } else {
      return '$' + params?.value;
    }
  }

  onSortChanged(event: SortChangedEvent) {
    this.gridApi?.showLoadingOverlay();
    const sortedColumns: Column[] | undefined = event.columnApi
      ?.getAllColumns()
      ?.filter((col) => col.getSort());
    if (sortedColumns) {
      for (const column of sortedColumns) {
        const colId = column.getColDef()?.field;
        const direction = column.getSort()?.toUpperCase();
        const existingSort = this.sortModel.find(
          (sort) => sort.colId === colId
        );
        if (existingSort) {
          if (existingSort.sort !== direction) {
            existingSort.sort = direction;
          }
        } else {
          this.sortModel.push({ colId, sort: direction });
        }
      }
      this.gridColumnApi?.applyColumnState(this.sortModel);
      this.sortModel.forEach((sort) => {
        const coldef = this.columnDefs.find((col) => col.field === sort.colId);
        const index = this.columnDefs.findIndex(
          (col: any) => col.field === sort.colId
        );
        if (coldef) {
          coldef.sort = sort.sort.toLowerCase();
        }
        this.columnDefs[index] = coldef;
      });
      this.gridApi?.setColumnDefs(this.columnDefs);
      this.sizeToFit();
    }
  }

  onSearch() {
    this.apiSearch = true;
    this.apiCall();
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 33])
  }
}
