import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  loadMaterialData() : Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'material/getAllMaterialInfo';
    return this.http.get(URL, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  deleteMaterial(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'material/DeleteMaterial';
    return this.http.delete(URL,{headers: this.constantsService.internalHttpOptions,body:body}).pipe(catchError(this.handleError))
  }

  addMaterial(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'material/Addmaterial';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  updateMaterial(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'material/Updatematerial';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}
