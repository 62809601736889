import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { Router } from '@angular/router'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-set-po-btn',
  templateUrl: './set-po-btn.component.html',
  styleUrls: ['./set-po-btn.component.scss'],
})
export class SetPoBtnComponent implements OnInit {
  auth: any
  po: any
  ponum: any
  errorMessage: any
  errorList = ''

  constructor(
    public dialogRef: MatDialogRef<SetPoBtnComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly authListSvc: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router:Router,
    public message: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.auth = this.dataStore.auth
    this.po = this.dataStore.ponum === '-' ? '' : this.dataStore.ponum
  }

  loadData(authNum: any) {
    this.authListSvc.setPo(authNum).subscribe({
      next: (res: any) => {
        this.po = res?.ponumber
          ? res?.ponumber
          : this.ponum === '-'
          ? ''
          : this.ponum
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent', data: 'close' })
  }

  ok() {
    if (this.po !== '' && this.po !== null) {
      this.spinnerService.start()
      const params = {
        Upflag: 0,
        PONumber: this.po,
        AuthNumber: this.auth,
      }
      this.authListSvc.updatePo(params).subscribe({
        next: (res: any) => {
          if (res.status === 'SUCCESS') {
            this.openMessageBox('Updated Successfully','success')
            this.dialogRef.close({ event: 'CloseEvent', data: params })
          } 
          else {
            this.errorList = res.message
            setTimeout(() => {
              this.errorList = ''
            }, 4000)
          }
          this.spinnerService.stop()
        },
        error: (err: any) => {
          this.spinnerService.stop()
          this.errorList = err.error?.error ?? 'Contact a System Administrator'
          setTimeout(() => {
            this.errorList = ''
          }, 4000)
        },
      })
    } 
    else {
      this.errorList = '304-401:Required Item Error [PO#]'
      setTimeout(() => {
        this.errorList = ''
      }, 4000)
    }
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }
  
  help(){
    this.dialogRef.close();
    this.router.navigate(['./master-help',3,"Cost Management",51])
  }
}
