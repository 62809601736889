import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';
import * as FileSaver from 'file-saver-es';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'backup-auth-list-cell-renderer',
  styles: [`
  button:disabled,
  button[disabled] {
  color: #bcc0c6 !important;
  cursor: no-drop;
}

`],
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table"
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)" *ngIf="setFlag" [disabled]="setPo">{{buttonName}}</button>

    <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" id="button"
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)" *ngIf="deleteFlag" [disabled]="deletePo">{{buttonName}}</button>

    <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" id="button"
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)" *ngIf="downloadFlag" [disabled]="disableDownload">{{buttonName}}</button>
  `,
})

export class BackupAuthListBtnCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    private readonly service: BackupAuthlistService,
    public readonly dialog: MatDialog, 
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService
    ) { }

    refresh(_params: ICellRendererParams): boolean {
      return true;
    }

  private params: any;
  buttonName: any;
  spCode: any;
  setFlag = false;
  setPo  = false;
  downloadFlag = false;
  disableDownload = false;
  deletePo = true;
  deleteFlag = false;
  pcostsave: any;
  userInfo: any;


  agInit(params: any): void {
    this.getRoles();

    this.params = params;
    if (params.colDef.headerName === 'DELETE') { 
      this.buttonName = 'DELETE'; 
      this.deleteFlag = true;
      this.deletePo = true;
    }
     else if (params.colDef.headerName === 'PO #SET') {
      this.buttonName = 'SET PO#';
      this.setFlag = true;
      this.downloadFlag = false;
      this.deleteFlag = false;
      if (params.data.flag === '1') {
        if (this.pcostsave === false) {
          this.setPo = true;
        }
      } else {
        this.setPo = true;
      }
    }
    else if (params.colDef.headerName === 'EXCEL DOWNLOAD') { 
      this.buttonName = 'DOWNLOAD';
      this.setFlag = false;
      this.downloadFlag = true;
      this.deleteFlag = false;
      if (params.data.flag === '0' || params.data.resapproval !== '3' 
      || params.data.responsibility === '0' || params.data.pcstatus === ""
      || params.data.authdate === "-") {
        this.disableDownload = true;
      }
     }
  }

  btnClickedHandler(_event: any) {
    if (this.params.colDef.headerName === 'PO #SET') {
      this.params.clicked(this.params.data);
    }
    else if (this.params.colDef.headerName === 'EXCEL DOWNLOAD') {
      this.downloadExcel(this.params.data);
    }
  }

  downloadExcel(data: any) {
    this.spinnerService.start();
    const dataObj = {
      "authNumber":data.authnumber,
      "userPlantCode":data.plantcode,
      userUnit: this.userInfo.unit
    }
    this.service.backupAuthDownload(dataObj)
      .subscribe({
        next: (res: any) => {
          const toConvert = res.Data;
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
          FileSaver.saveAs(file, res.Filename);
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        }
      });
  }
  getRoles(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info;
        this.pcostsave = res.role?.p_costsave;
      }
    });
  }
}




