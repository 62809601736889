import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class DocTrackingService {


  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  loadDocData() : Observable<any>{
    return this.http.get(this.constantsService.EXTERNAL + 'documentation/onLoad', {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  downloadFile(id:any, filename:any):any{
    const downloadURL = this.constantsService.EXTERNAL + `documentation/downloadFile?id=${id}&fileName=${filename}`
		return this.http.get(downloadURL,{ observe: 'response', responseType: 'text', headers: this.constantsService.httpOptions} ).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return (error.message || "Server Error");
	}
}
