<div class="popup-container">
  <header>
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <div class="d-flex justify-content-between align-items-center header">
      <div class="page-title">Import Supplier Specialist</div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
    </div>
  </header>

  <div class="alert alert-danger m-3" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg d-flex justify-content-between">
      <span class="message-text">{{ message }}</span>
    </div>
  </div>

  <div class="popup-contents">
    <div>
      <div class="d-flex flex-wrap align-items-center p-2 px-4 col-12">
        <span class="col-12 my-1">File<small>*</small></span>
        <label
          for="supplierspecialist"
          class="vpacs-btn-table col-4"
          (change)="handleUpload($event)"
        >
          CHOOSE FILE
          <input
            type="file"
            id="supplierspecialist"
            style="display: none"
            accept=".xlsx"
            size="60"
            (change)="handleUpload($event)"
          />
        </label>
        <span class="col-8">{{ fileName }}</span>
      </div>
    </div>

    <div
      class="
        footer
        d-flex
        justify-content-end
        align-items-center
        fixed-bottom
        mt-2
        pt-2
        pb-2
      "
    >
      <div mat-dialog-close class="mx-3 cancel">Cancel</div>
      <button class="black-button mx-3" (click)="nextBtn()">IMPORT</button>
    </div>
  </div>
</div>
