import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'

@Component({
  selector: 'app-add-cost',
  templateUrl: './add-cost.component.html',
  styleUrls: ['./add-cost.component.scss'],
})
export class AddCostComponent implements OnInit {
  component = 'Select'
  familycodeFlag = false
  familyFlag = false
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'
  isEditMode = false
  costCenter = ''
  plantCode = ''
  plantName = ''
  plantId = ''
  plantResult = ''
  PlantCode = ''
  CostCenter = ''
  oldCostCenter = ''
  isBudgetError = false
  errorList: any[] = []
  plant: any
  supplier: any
  addFlag = ''
  PltName: any
  PltID: any
  PltCode: any

  constructor(
    public plantService: PlantService,
    private readonly spinner: NgxUiLoaderService,
    public dialogRef: MatDialogRef<AddCostComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.addcostOnload()
    if (this.dataStore.mode === 'ADD') {
      this.modalHeader = 'Add Cost Center'
      this.buttonValue = 'ADD'
      this.familycodeFlag = true
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore.plantId
      this.isEditMode = false
    } else if (this.dataStore.mode === 'UPDATE') {
      this.modalHeader = 'Update Cost Center'
      this.familyFlag = true
      this.buttonValue = 'UPDATE'
      this.oldCostCenter = this.dataStore?.field?.costcenter
      this.costCenter = this.dataStore.field.costcenter
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore.plantId
      this.isEditMode = true
    }
  }

  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg, mode: 'X' })
  }

  checkValidations() {
    this.errorList = []
    this.isBudgetError = false
    if (!this.costCenter) {
      this.isBudgetError = true
    }
  }
  saveData() {
    this.checkValidations()
    if (this.isBudgetError) {
      return
    }
    if (this.buttonValue === 'ADD') {
      const loadObj = {
        PlantCode: this.plantCode,
        CostCenter: this.costCenter,
        PlantName: this.plantName,
        PlantId: this.plantId,
      }
      this.plantService.addcostCenter(loadObj).subscribe({
        next: (data: any) => {
          if (!data.status) {
            this.errorList.push(data.message)
          } else {
            this.dialogRef.close({ data: { result: 'SUCCESS', mode: 'Add',data } })
          }
        },
        error: () => {
          this.spinner.stop()
        },
      })
    } else if (this.buttonValue === 'UPDATE') {
      const loadObj = {
        CostCenter: this.costCenter,
        PlantCode: this.plantCode,
        PlantName: this.plantName,
        PlantId: this.plantId,
        OldCostCenter: this.oldCostCenter,
      }
      this.plantService.updateCostCenter(loadObj).subscribe({
        next: (data) => {
          const result = data?.status ? 'SUCCESS' : '';
          this.dialogRef.close({ data: { result: result, mode: 'Update', data } })
        },
      })
    } else {
      this.close('')
    }
  }

  addcostOnload(): void {
    const loadObj = {
      PlantCode: this.dataStore.plantCode,
      CostCenter: this.dataStore.field?.costcenter || '',
    }
    this.plantService.addcostOnload(loadObj).subscribe({
      next: (res: any) => {
        this.plantResult = res.PlantResult[0]
      },
    })
  }

  closee() {
    this.errorList = []
  }

  help() {
    this.dialogRef.close()
    if (this.modalHeader === 'Add Cost Center') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 171])
    } else if (this.modalHeader === 'Update Cost Center') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 172])
    }
  }
}
