<div class="d-flex pagination align-items-center justify-content-between" >
    <div class="d-flex gap-1 align-items-center">
      <span>RECORDS/PAGE : </span>
      <select style="height: 30px" (change)="rppChanged($event)" [(ngModel)]="rpPage">
        <option *ngFor="let x of pgDropDown" [ngValue]="x.value==='ALL'?totalRecords:x.value">{{x.value}}</option>
      </select>
    </div>
    <div class="right">
      <span>VIEWING RECORDS</span>
      <mat-icon (click)="goToFirstPage()" class="text-primary"
        [ngClass]="{'disable_icon': onlyOnePage() || cPage === 1}"
      >first_page</mat-icon>
      <mat-icon (click)="goToPreviousPage()" class="text-primary"
        [ngClass]="{'disable_icon': onlyOnePage() || cPage === 1}"
      >fast_rewind</mat-icon>
      (<span>{{cPage}}</span>- 
      <span>{{tPage}}</span>) &nbsp;OF
      <span>{{totalRecords}}</span>
      <mat-icon (click)="goToNextPage()" class="text-primary"
      [ngClass]="{'disable_icon' : onlyOnePage() || isLastPage() || totalRecords === 0}"
      >fast_forward</mat-icon>
      <mat-icon (click)="goToLastPage()" class="text-primary"
        [ngClass]="{'disable_icon': onlyOnePage() || isLastPage() || totalRecords === 0}"
      >last_page</mat-icon>
    </div>
  </div>