import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddRackShipmentComponent } from 'src/app/core/dialogs/add-rack-shipment/add-rack-shipment.component';
import { HistoryShipmentComponent } from 'src/app/core/dialogs/history-shipment/history-shipment.component';
import { SelectAuthComponent } from 'src/app/core/dialogs/select-auth/select-auth.component';
import { SelectCompanyComponent } from 'src/app/core/dialogs/select-company/select-company.component';
import { DeleteBtnCellRender } from 'src/app/core/gridrender/delete-btn-cell-render';
import { HistoryBtnCellRender } from 'src/app/core/gridrender/history-btn-cell-render';
import { InputCellRender } from 'src/app/core/gridrender/input-cell-render';
import { RBtnCellRender } from 'src/app/core/gridrender/R-btn-cell-render';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';

@Component({
  selector: 'app-newshipment',
  templateUrl: './newshipment.component.html',
  styleUrls: ['./newshipment.component.scss'],
})
export class NewShipmentComponent implements OnInit {

  lpcodes = ['Select', 'BMK', 'KIM', 'ORB', 'SSI', 'STI', 'VCI', 'ADS', 'BGM', 'CSI', 'GEN', 'HEC', 'KRG', 'MOR',
    'OXF', 'PFX', 'PIQ', 'PMA', 'PVP', 'SRT', 'INF', 'MCL', 'PKF', 'KEN', 'TRG', 'BRD', 'IPK', 'MMI', 'LMT', 'CKT',
    'HLT', 'MSB', 'BAP', 'DQS', 'KIT', 'WDT', 'ATO', 'PWI', 'MWG', 'VIS', 'MFI', 'UPK'];
  timeArray = new Array(24);
  public rowData: any;
  weight: any = '';
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  shipDate: any;
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageName: any;
  company = '';
  supplierNumber: any;
  shipmentReadyData: any = {};
  deliveryData: any = {};
  ShipNumber: any;
  rackShipmentCount: any = '';
  authnumber: any = [];
  deliveryDate: any;
  SPECIALIST: any;
  searchParameters: any;
  errorMessage: any;

  public newShipmentForm = new FormGroup({
    weight: new FormControl({ value: '0.0000', disabled: true }),
    numberOfPallet: new FormControl('', Validators.required),
    lp: new FormControl('', Validators.required),
    currency: new FormControl('USD'),
    noShipment: new FormControl(''),
  });

  public actualShipment = new FormGroup({
    shipmentDate: new FormControl('', Validators.required),
    receivedDate: new FormControl(''),
    actualCost: new FormControl(''),
    currency: new FormControl('USD'),
  });
  public planShipment = new FormGroup({
    pickupDate: new FormControl(''),
    pickupTime: new FormControl('0'),
    deliveryDate: new FormControl(''),
    deliveryTime: new FormControl('0'),
    carrier: new FormControl(''),
    trackingNumber: new FormControl(''),
    costEstimate: new FormControl(''),
    currency: new FormControl('USD'),
  });

  public requestShipmentReady = new FormGroup({
    company: new FormControl({ value: '', disabled: true }),
    contactPerson: new FormControl('', Validators.required),
    contactPhone: new FormControl('', Validators.required),
    contactEmail: new FormControl('', Validators.required),
    contactAddress: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    windowTimeHH: new FormControl('0'),
    windowTimeMM: new FormControl('0'),
    shippingClass: new FormControl(''),
  });

  public requestLastDelivery = new FormGroup({
    receiver: new FormControl(''),
    company: new FormControl({ value: '', disabled: true }),
    contactPerson: new FormControl('', Validators.required),
    contactPhone: new FormControl('', Validators.required),
    contactEmail: new FormControl('', Validators.required),
    contactAddress: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    windowTimeHH: new FormControl({ value: '0', disabled: true }),
    windowTimeMM: new FormControl({ value: '0', disabled: true }),
  });
  rejectFlag = false;
  supplierCodeList: any[] = [];
  supplierCode: any;
  saveFlag = false;
  sendFlag = false;
  waitReceiverFlag = false;
  shipmentNumber: any;
  companySelectDisabled = false;
  receiverUpdateFlag = false;
  detailPage: any;
  cancelShipFlag = false;
  addFlag = false;
  res: any;
  moreVertFlag = false;
  draftFlag = false;
  partFlag = false;
  noshipFlag = false;
  receiveFlag = false;
  detailData: any;
  params: any;
  sendConfirmFlag = false;
  result: any;
  selected: string = 'supplier' || 'contfab' || 'dun1fab' || 'namc';
  refreshCells: any;
  shipmentData: any;

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private readonly newShipmentService: NewshipmentService,
    public router: Router,
    public message: MatSnackBar
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: false,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.shipCompanySelected = this.shipCompanySelected.bind(this);
    this.deliveryCompanySelected = this.deliveryCompanySelected.bind(this);
  }

  ngOnInit(): void {
    this.pageName = this.route.snapshot.data['breadcrumb'];
    if (localStorage.hasOwnProperty('tracking')) {
      this.supplierNumber = JSON.parse(localStorage.getItem('tracking') || '');
      if (history.state?.supplierCode !== undefined) {
        if (history.state.supplierCode.length === 5) {
          this.supplierCodeList.push(history.state.supplierCode);
        } else {
          for (let i = 0; i < 10; i++) {
            this.supplierCodeList.push(history.state.supplierCode + i);
          }
        }
      } else {
        if (this.supplierNumber[0].length === 5) {
          this.supplierCodeList.push(this.supplierNumber[0]);
        } else {
          for (let i = 0; i < 10; i++) {
            this.supplierCodeList.push(this.supplierNumber[0] + i);
          }
        }
      }
      this.defaultSearchFilter(0);


      if (sessionStorage.getItem('authNumber')) {
        this.newShipmentService
          .addButtonData(sessionStorage.getItem('authNumber') || '')
          .subscribe((result) => {

            this.displayRowData = [
              { ...result?.createAuthData[0], ...result?.weight[0], ...result?.setDefaultCompanyInfo[0] },
            ];
            this.ShipNumber = result?.temp;
            sessionStorage.setItem('shipNumber', this.ShipNumber);
          });
      }

      if (this.pageName === 'Detail') {
        if (sessionStorage.getItem('detailShipmentNumber')) {
          this.detailPage = JSON.parse(
            sessionStorage.getItem('detailShipmentNumber') || ''
          );

          if (sessionStorage.getItem('authNumber')) {
            this.newShipmentService
              .addDetailButtonData(sessionStorage.getItem('authNumber') || '')
              .subscribe((result) => {
                this.displayRowData = [
                  { ...result?.createAuthData[0], ...result?.weight[0], ...result?.setDefaultCompanyInfo[0] },
                ];
                this.ShipNumber = result?.temp;
                sessionStorage.setItem('shipNumber', this.ShipNumber);
              });
          };

          if (this.detailPage.currentUser === this.detailPage.supplierCode) {
            if (
              [11, 12, 13].includes(this.detailPage.status) &&
              this.detailPage.cancelFlag !== 1
            ) {
              this.rejectFlag = true;
            }

            if ([11, 12, 13, 21].includes(this.detailPage.status)) {
              if (
                this.detailPage.cancelFlag !== 1 &&
                this.detailPage.shipperCode === this.detailPage.supplierCode
              ) {
                this.cancelShipFlag = true;
              }
            }

            if (
              this.detailPage.status !== 4 &&
              this.detailPage.cancelFlag !== 1
            ) {
              if (this.detailPage.status === 13) {
                this.sendConfirmFlag = true;
              } else {
                this.sendFlag = true;
              }
            }

            if (
              this.detailPage.status !== 4 &&
              this.detailPage.status !== 13 &&
              this.detailPage.cancelFlag !== 1
            ) {
              if (
                this.detailPage.status !== 13 &&
                this.detailPage.cancelFlag !== 1
              ) {
                this.saveFlag = true;
              }            
            }
            if (this.detailPage.currentUser === this.detailPage.supplierCode) {
              if(this.detailPage.status === 11){             
                this.waitReceiverFlag = true;
             }
            } 
            
          };        

          this.newShipmentService
            .loadDetailShipment(this.detailPage.detailShipmentNumber)
            .subscribe((res: any) => {
              sessionStorage.setItem('buttonFlag', JSON.stringify({ cancelFlag: this.detailPage.cancelFlag , status: this.detailPage.status }))
              this.detailOnLoad(res.result); 
              this.displayRowData = this.res; 
              this.shipmentData = res.result; 
              this.res = res.result;
            
              if (this.detailPage.currentUser === this.detailPage.supplierCode) {
                 if(this.detailPage.status === 11){
                  this.detailOnLoad(res.result);              
                   this.waitReceiverFlag = true;
                }              
                
               if(this.detailPage.status === 0) {
                this.setReadOnlyReceiver2( res.result[1][0].RECVTYPE ? res.result[1][0].RECVTYPE : res.result[2][0].RECVTYPE);             
                this.addFlag = true;
                this.draftFlag = true;
                this.moreVertFlag = true;
                this.noshipFlag = true;
                this.receiveFlag = true;
                this.detailOnLoad(res.result);
              }
            }
              if (this.detailPage.currentUser !== this.detailPage.supplierCode) {
                this.detailOnLoad(res.result);
              }
            });
        }
      } else {
        this.apiCall(0);
      }
    }

    this.loadStorage();

    this.columnDefs = [
      { headerName: 'AUTH NUMBER', field: 'AUTHNUMBER', width: 190 },
      { headerName: 'MODEL CODE', field: 'MODELCODE', width: 100 },
      { headerName: 'SUPPLIER', field: 'SUPPLIERNAME', width: 150 },
      { headerName: 'PART NUMBER', field: 'PARTNUMBER', width: 150 },
      { headerName: 'PART DESCRIPTION', field: 'PARTDESCRIPTION', width: 160 },
      { headerName: 'PACKAGE CODE', field: 'PACKAGECODE', width: 150 },
      { headerName: 'PACKAGING TYPE', field: 'PACKAGETYPE', width: 100 },
      { headerName: 'DUNNAGE1 CODE', field: 'DUN1FAB', width: 100 },
      { headerName: 'LENGTH', field: 'OUTERLENGRH', width: 100 },
      { headerName: 'WIDTH', field: 'OUTERWIDTH', width: 100 },
      { headerName: 'HEIGHT', field: 'OUTERHEIGHT', width: 100 },
      {
        headerName: 'NUMBER OF PARTS',
        field: 'NUMOFPARTS',
        width: 100,
        cellRendererFramework: InputCellRender,
        cellRendererParams: {
          changed: (field?: any, value?: number) => {
            field.userNoOfParts = value;
            this.calcTotalWeight();
          },
        },
      },
      { headerName: 'QTY AVAILABLE', field: 'QTYAVAILABLE', width: 100 },
      {
        headerName: 'PLANQTY',
        field: 'SHIPQTY',
        width: 100,
        cellRendererFramework: RBtnCellRender,
        cellRendererParams: {
          count: this.rackShipmentCount > 0 ? this.rackShipmentCount : '',
          disabled: (field: any) => {
            if (field?.planDisabled) {
              return true;
            } else {
              return false;
            }
          },
          clicked: (field?: any) => {
            this.dialogOpenRackComponent(field);
          },
          changed: (field?: any, value?: number) => {
            field.userPlanQty = value;
            this.calcTotalWeight();
          },
        },
      },
      {
        headerName: 'ACTUAL SHIPQTY',
        field: 'actualShipqty',
        width: 100,
        cellRendererFramework: RBtnCellRender,
        cellRendererParams: {
          disabled: (field: any) => {
            if (field?.shipDisabled) {
              return true;
            } else {
              return false;
            }
          },
          clicked: (field?: any) => {
            this.dialogOpenRackComponent(field);
          },
          changed: (field?: any, value?: number) => {
            field.ActualShipQty = value;
            this.calcTotalWeight();
          },
        },
      },
      {
        headerName: 'ACTUAL RECIEVEQTY',
        field: 'actualReceiveqty',
        width: 100,
        cellRendererFramework: RBtnCellRender,
        cellRendererParams: {
          disabled: (field: any) => {
            if (field?.receiveDisabled) {
              return true;
            } else {
              return false;
            }
          },
          clicked: (_field?: any) => {
            this.dialogOpenReceivementComponent(_field);
          },
          changed: (field?: any, value?: number) => {
            field.userActualReceiveQty = value;
            this.calcTotalWeight();
          },
        },
      },
      {
        headerName: 'HISTORY',
        field: 'history',
        width: 100,
        cellRendererFramework: HistoryBtnCellRender,
        cellRendererParams: {
          disabled: (field: any) => {
            if (field?.historyDisabled) {
              return true;
            } else {
              return false;
            }
          },
          clicked: (field?: any) => {
            this.dialogOpenHistoryComponent(field?.AUTHNUMBER);
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        width: 100,
        cellRendererFramework: DeleteBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            if (field?.AUTHNUMBER) {
              const AUTHNUMBER = field?.AUTHNUMBER;
              this.delete(AUTHNUMBER);
              this.calcTotalWeight();
            }
          },
        },
      },
    ];
  }
  loadStorage() {
    const data = JSON.parse(sessionStorage.getItem('newshipment') || '[]');
    const rc = parseInt(sessionStorage.getItem('rackcount') || '-1');
    this.displayRowData = data;
    this.rowCount = this.displayRowData.length;
    if (rc !== -1) {
      this.rackShipmentCount = rc;
    }
  }

  setReadOnlyReceiver2(RECVTYPE: any) {
    if (RECVTYPE === '4') {
      this.requestLastDelivery.controls.contactPerson.enable();
      this.requestLastDelivery.controls.contactPhone.enable();
      this.requestLastDelivery.controls.contactEmail.enable();
    } else {
      this.requestLastDelivery.controls.contactPerson.disable();
      this.requestLastDelivery.controls.contactPhone.disable();
      this.requestLastDelivery.controls.contactEmail.disable();
    }
  }

  setOnLoadValues(result: any) {
    const data = result?.isFab?.result ? result?.radioResult : result?.result?.shipmentReady;
    const { ShipCompany, ShipAddress, ShipCPerson, ShipEmail, ShipPhoneNumber, pickupDate  } = data  || {};
    const { RecvCompany, RecvAddress, RecvPhoneNumber1, RecvEmail1, RecvDate, RecvCPerson1, ActualCostCurrency } = result?.result?.delivery || {}
    const {CostCurrency } = result?.result?.shipmentReady ||  {};

    this.requestShipmentReady.patchValue({
      company: ShipCompany,
      contactAddress: ShipAddress,
      contactPerson: ShipCPerson,
      contactEmail: ShipEmail,
      contactPhone: ShipPhoneNumber,
      date: pickupDate,
    });
    this.requestLastDelivery.patchValue({
      company: RecvCompany,
      contactAddress: RecvAddress,
      contactEmail: RecvEmail1,
      contactPerson: RecvCPerson1,
      contactPhone: RecvPhoneNumber1,
      date: RecvDate,
    });
    this.planShipment.patchValue({
      currency: CostCurrency,
    });
    this.actualShipment.patchValue({
      currency: ActualCostCurrency,
    });
  }
 
  setDetailOnLoadValues(result: any) { 
    let index = 1 
    if(result[2]){
      index = 2
    }
    const DATE_FORMAT = 'YYYY-MM-dd';
    this.requestShipmentReady.patchValue({
      company:  result[index][0].SHIPCOMPANY,
      contactPerson: result[index][0].SHIPCPERSON ,
      contactEmail: result[index][0].SHIPEMAIL ,
      contactPhone: result[index][0].SHIPPHONENUMBER,
      contactAddress: result[index][0].SHIPADDRESS ,
      date: result[index][0].SHIPDATE && formatDate(result[index][0]?.SHIPDATE, DATE_FORMAT, 'en-US'),
      windowTimeHH: result[index][0].SHIPWINTIMEF  && this.sliceString(result[index][0]?.SHIPWINTIMEF.slice(0, 2)) || '0' ,
      windowTimeMM:  result[index][0].SHIPWINTIMET   && this.sliceString(result[index][0]?.SHIPWINTIMET.slice(0, 2)) || '0' ,
      shippingClass:  result[index][0].SHIPSHIPCLASS ,
    });

    this.requestLastDelivery.patchValue({
      receiver: 'supplier'
    })
  
    if(result[0][0]?.AUTHNUMBER){ 

    this.requestLastDelivery.patchValue({    
      company: result[index][0]?.RECVCOMPANY ,
      contactAddress: result[index][0]?.RECVADDRES,
      contactEmail: result[index][0]?.RECVEMAIL1,
      contactPerson: result[index][0]?.RECVCPERSON1,
      contactPhone: result[index][0]?.RECVPHONENUMBER1,
      date: result[index][0]?.RECVDATE  && formatDate( result[index][0]?.RECVDATE, DATE_FORMAT, 'en-US'),
      windowTimeHH: result[index][0]?.RECVWINTIMEF && this.sliceString(result[index][0]?.RECVWINTIMEF.slice(0, 2)) || '0',
      windowTimeMM: result[index][0]?.RECVWINTIMET && this.sliceString(result[index][0]?.RECVWINTIMET.slice(0, 2)) || '0',
      receiver: 'supplier'
    });

    if(this.selected === 'contfab'){
    this.requestLastDelivery.patchValue({    
      company: result ? result[1][0]?.getContFabData?.ALPHACODE : '',
      contactAddress: result ? result[1][0]?.getContFabData?.ADDRESS : '',
      contactEmail: result ? result[1][0]?.getContFabData?.EMAIL : '',
      contactPerson:  result ? this.res[1][0]?.getContFabData?.FULLNAME : '',
      contactPhone: result ? this.res[1][0]?.getContFabData?.PHONE : '',
      date:result[index][0]?.RECVDATE && formatDate(result[index][0]?.RECVDATE, DATE_FORMAT, 'en-US'),
      windowTimeHH: result[index][0]?.RECVWINTIMEF && this.sliceString(result[index][0]?.RECVWINTIMEF.slice(0, 2)) || '0',
      windowTimeMM: result[index][0]?.RECVWINTIMET && this.sliceString(result[index][0]?.RECVWINTIMET.slice(0, 2)) || '0',
      receiver: 'contfab'
    });
  }
    if(this.selected === 'dun1fab'){
    this.requestLastDelivery.patchValue({    
      company: result ? result[1][0]?.getDun1FabData?.ALPHACODE : '',
      contactAddress: result ? result[1][0]?.getDun1FabData?.ADDRESS : '',
      contactEmail: result ? result[1][0]?.getDun1FabData?.EMAIL : '',
      contactPerson: result ? result[1][0]?.getDun1FabData?.FULLNAME : '',
      contactPhone: result ? result[1][0]?.getDun1FabData?.PHONE : '',
      date:result[index][0]?.RECVDATE && formatDate(result[index][0]?.RECVDATE, DATE_FORMAT, 'en-US'),
      windowTimeHH: result[index][0]?.RECVWINTIMEF && this.sliceString(result[index][0]?.RECVWINTIMEF.slice(0, 2)) || '0',
      windowTimeMM: result[index][0]?.RECVWINTIMET && this.sliceString(result[index][0]?.RECVWINTIMET.slice(0, 2)) || '0',
      receiver: 'dun1fab'
    });
  }

   if(this.selected === 'namc'){ 
    this.requestLastDelivery.patchValue({  
      company: `${result[1][0]?.getPlantData[0]?.PLANTNAME} - ${ result[1][0]?.getPlantData[0]?.DOCKCODE}` || '',
      contactAddress:  result ? result[1][0]?.getPlantData[0]?.ADDRESS : '',
      contactEmail:  result ? result[1][0]?.getPlantData[0]?.EMAIL : '',
      contactPerson:  result ? result[1][0]?.getPlantData[0]?.FULLNAME : '',
      contactPhone: result ? result[1][0]?.getPlantData[0]?.PHONE : '',
      date:result[index][0]?.RECVDATE && formatDate(result[index][0]?.RECVDATE, DATE_FORMAT, 'en-US'),
      windowTimeHH: result[index][0]?.RECVWINTIMEF && this.sliceString(result[index][0]?.RECVWINTIMEF.slice(0, 2)) || '0',
      windowTimeMM: result[index][0]?.RECVWINTIMET && this.sliceString(result[index][0]?.RECVWINTIMET.slice(0, 2)) || '0',
      receiver: 'namc'
    });
  }
    }

    this.newShipmentForm.patchValue({
      numberOfPallet: result?.NUMOFPALLET,
      lp: result[index][0]?.LPCODE || 'Select'

    });

    this.planShipment.patchValue({
      currency: result[index][0]?.PLANCOSTUNIT || 'USD',
      pickupDate: result[index][0]?.PICKUPDATE && formatDate(result[index][0]?.PICKUPDATE, DATE_FORMAT, 'en-US'),
      deliveryDate: result[index][0]?.PICKUPDATE && formatDate(result[index][0]?.DELIVDATE, DATE_FORMAT, 'en-US'),
      pickupTime: result[index][0]?.PICKUPTIME && this.sliceString(result[index][0]?.PICKUPTIME.slice(0, 2)) || '0',
      deliveryTime: result[index][0]?.DELIVTIME && this.sliceString(result[index][0]?.DELIVTIME.slice(0, 2)) || '0',
      carrier: result[index][0]?.CARRIERCODE,
      trackingNumber: result[index][0]?.TRACKINGNO,
      costEstimate: result[index][0]?.PLANCOST
    });
    this.actualShipment.patchValue({
      currency: result[index][0]?.ACTUALCOSTUNIT || 'USD',
      actualCost: result[index][0]?.ACTUALCOSTUNIT,
      shipmentDate: result[index][0]?.ACTUALSHIPDATE && formatDate(result[index][0]?.ACTUALSHIPDATE, DATE_FORMAT, 'en-US'),
      receivedDate: result[index][0]?.ACTUALRECVDATE && formatDate(result[index][0]?.ACTUALRECVDATE, DATE_FORMAT, 'en-US'),
    });
  }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  updateStorage(data: any) {
    sessionStorage.setItem('newshipment', JSON.stringify(data));
  }

  openMessageBox(message: string, type: string) {
    this.message?.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    });
  }

  reject() {
    this.detailPage = JSON.parse(
      sessionStorage.getItem('detailShipmentNumber') || ''
    );
    this.newShipmentService
      .ShipmentReject(this.detailPage.detailShipmentNumber)
      .subscribe({
        next: (_data) => {
          this.openMessageBox('Reject successfull', 'success');
          this.router.navigate(['/shipment']);
        },
        error: (_error) => {
          this.openMessageBox('Reject Failure', 'error');
        },
      });
  }

  cancelShipment() {
    this.detailPage = JSON.parse(
      sessionStorage.getItem('detailShipmentNumber') || ''
    );
    this.newShipmentService
      .cancelShipmentBtn(this.detailPage.detailShipmentNumber)
      .subscribe({
        next: (_data) => {
          this.openMessageBox('Shipment cancelled Successfully', 'success');
          this.router.navigate(['/shipment']);
        },
        error: (_error) => {
          this.openMessageBox('Failed to Cancel', 'error');
        },
      });
  }

  delete(AUTHNUMBER: string) {
    sessionStorage.removeItem('authNumber');
    this.displayRowData = this.displayRowData.filter(
      (r: any) => r.AUTHNUMBER !== AUTHNUMBER
    );
    this.receiverUpdated(null);
  }

  receiverUpdated(event: any) {

    this.requestLastDelivery.patchValue({
      company: '',
      contactAddress: '',
      contactEmail: '',
      contactPerson: '',
      contactPhone: ''
    }); 
    
    const selectedReceiver = event?.target?.value; 
    if (selectedReceiver === 'supplier') { 
      this.requestLastDelivery.patchValue({ 
        company:  this.res ? this.res[2][0]?.SHIPCOMPANY  : this.displayRowData[0]?.getSupplierdata[2]?.SHORTNAME,
        contactAddress: this.res ? this.res[2][0]?.SHIPADDRESS : this.displayRowData[0]?.getSupplierdata[0][0]?.ADDRESS ,
        contactEmail: this.res  ? this.res[2][0]?.SHIPEMAIL  : this.displayRowData[0]?.getSupplierdata[2]?.EMAIL,
        contactPerson: this.res ? this.res[2][0]?.SHIPCPERSON  : this.displayRowData[0]?.getSupplierdata[2]?.FULLNAME ,
        contactPhone: this.res ? this.res[2][0]?.SHIPPHONENUMBER  : this.displayRowData[0]?.getSupplierdata[2]?.PHONE

     });
    } else if (selectedReceiver === 'contfab') {
      this.requestLastDelivery.patchValue({
        company: this.res ? this.res[1][0]?.getContFabData?.ALPHACODE : this.displayRowData[0]?.getContFabData?.ALPHACODE,
        contactAddress: this.res ? this.res[1][0]?.getContFabData?.ADDRESS : this.displayRowData[0]?.getContFabData?.ADDRESS,
        contactEmail: this.res ? this.res[1][0]?.getContFabData?.EMAIL : this.displayRowData[0]?.getContFabData?.EMAIL,
        contactPerson: this.res ? this.res[1][0]?.getContFabData?.NAME :  this.displayRowData[0]?.getContFabData?.NAME,
        contactPhone: this.res ? this.res[1][0]?.getContFabData?.PHONE : this.displayRowData[0]?.getContFabData?.PHONE
      });
    } else if (selectedReceiver === 'dun1fab') {
      this.requestLastDelivery.patchValue({
        company:  this.res ? this.res[1][0]?.getDun1FabData?.ALPHACODE :  this.displayRowData[0]?.getDun1FabData?.ALPHACODE,
        contactAddress:  this.res ? this.res[1][0]?.getDun1FabData?.ADDRESS : this.displayRowData[0]?.getDun1FabData?.ADDRESS,
        contactEmail:  this.res ? this.res[1][0]?.getDun1FabData?.EMAIL : this.displayRowData[0]?.getDun1FabData?.EMAIL,
        contactPerson:  this.res ? this.res[1][0]?.getDun1FabData?.NAME : this.displayRowData[0]?.getDun1FabData?.NAME,
        contactPhone:  this.res ? this.res[1][0]?.getDun1FabData?.PHONE : this.displayRowData[0]?.getDun1FabData?.PHONE
      });
    } else if (selectedReceiver === 'namc') {
      
      this.requestLastDelivery.patchValue({
        company:  this.res ? `${this.res[0][0].PLANTNAME} - ${this.res[0][0].DOCKCODE}` :  `${this.displayRowData[0]?.getPlantData[0]?.PLANTNAME} - ${this.displayRowData[0]?.getPlantData[0]?.DOCKCODE}`,
        contactAddress: this.res ? this.res[1][0]?.getPlantData[0]?.ADDRESS : this.displayRowData[0]?.getPlantData[0]?.ADDRESS,
        contactEmail: this.res ? this.res[1][0]?.getPlantData[0]?.CONVEMAIL : this.displayRowData[0]?.getPlantData[0]?.CONVEMAIL,
        contactPerson: this.res ? this.res[1][0]?.getPlantData[0]?.CONVPERSONNAME : this.displayRowData[0]?.getPlantData[0]?.CONVPERSONNAME,
        contactPhone: this.res ? this.res[1][0]?.getPlantData[0]?.CONVPHONE : this.displayRowData[0]?.getPlantData[0]?.CONVPHONE
      });
    }
 
    if (this.receiveFlag) {
      this.requestLastDelivery.controls.windowTimeHH.disable();
      this.requestLastDelivery.controls.windowTimeMM.disable();
     }
   }

  sliceString(input: string): string {
    let start = 0;
    while (start < input.length && input[start] === '0') {
      start++;
    }
    return input.substring(start)
  }

  detailOnLoad(result: any) {

    let index = 1
    if(result[2]){
      index = 2
    }
    this.requestShipmentReady.disable();
    this.planShipment.disable();
    this.actualShipment.disable();
    this.newShipmentForm.disable();
    this.requestLastDelivery.disable();
    this.ShipNumber =  result[index][0].SHIPMENTNUMBER;
    this.displayRowData = result[0];
    this.newShipmentForm.patchValue({
      weight: result[3] ? result[3][1]?.TotalWeight.toFixed(4) : 0.0000
    });
    this.setDetailOnLoadValues(result)
   
      if (this.waitReceiverFlag) {
      this.requestLastDelivery.controls.contactAddress.enable();
      this.requestLastDelivery.controls.contactEmail.enable();
      this.requestLastDelivery.controls.contactPerson.enable();
      this.requestLastDelivery.controls.contactPhone.enable();
      this.requestLastDelivery.controls.windowTimeHH.enable();
      this.requestLastDelivery.controls.windowTimeMM.enable();
      this.requestShipmentReady.disable();
      this.planShipment.disable();
      this.actualShipment.disable();
      this.newShipmentForm.disable();
      this.ShipNumber = result[1][0].SHIPMENTNUMBER ? result[1][0].SHIPMENTNUMBER : result[2][0].SHIPMENTNUMBER;
      this.displayRowData = result[0];
      this.newShipmentForm.patchValue({
        weight: result[3] ? result[3][1]?.TotalWeight.toFixed(4) : 0.0000
      });
      this.setDetailOnLoadValues(result);
    }

    if (this.noshipFlag) {
      this.receiveFlag = true;
      this.newShipmentForm.controls.lp.enable();
      this.newShipmentForm.controls.noShipment.enable();
      this.companySelectDisabled = false;
      this.newShipmentForm.enable();
      this.newShipmentForm.controls.weight.disable();
      this.requestShipmentReady.enable();
      this.requestLastDelivery.enable();
      this.requestLastDelivery.controls.windowTimeHH.disable();
      this.requestLastDelivery.controls.windowTimeMM.disable();
      this.actualShipment.controls.shipmentDate.disable();
      this.ShipNumber = result[1][0].SHIPMENTNUMBER ? result[1][0].SHIPMENTNUMBER : result[2][0].SHIPMENTNUMBER;
      this.setDetailOnLoadValues(result);
      this.displayRowData = result[0];
      this.newShipmentForm.patchValue({
        weight: result[3] ? result[3][1]?.TotalWeight.toFixed(4) : 0.0000,
        lp: this.res[2][0].LPCODE || 'Select',
      });
    }
    sessionStorage.setItem('shipNumber', this.ShipNumber);
   }

  updateRStatus(params: any, type: string) {
    if (params.PACKAGETYPECODE === 'R' || params.PACKAGETYPECODE === 'B') {
      if (this.shipmentReadyData.CancelFlag === 1) {
        params.planDisabled =
          this.deliveryData.Status.toString() !== '0' && type === 'planqty';
        params.shipDisabled =
          this.deliveryData.Status.toString() !== '21' &&
          type === 'actualShipqty';
        params.receiveDisabled =
          this.deliveryData.Status.toString() !== '22' &&
          type === 'actualReceiveqty';
      }
    } else {
      params.planDisabled = true;
      params.shipDisabled = true;
      params.receiveDisabled = true;
    }
    
  }
  calcTotalWeight() {
    const count = this.displayRowData.length;
    let total = 0;
    let partweight = 0;
    let partqty = 0;
    let ppfweight = 0;
    let ppfqty = 0;
    let palletqty = 0;
    if (count === 0) {
      this.newShipmentForm.patchValue({
        numberOfPallet: '',
      });
    }
    palletqty = Number(this.newShipmentForm.controls.numberOfPallet.value || 0);
    for (let i = 0; i < count; i++) {
      partqty = this.displayRowData[i].userNoOfParts || 0;
      partweight = this.displayRowData[i].PARTWEIGHT || 0;
      if (this.pageName === 'Detail') {
        ppfweight =
          (this.res[2][0]?.CONTWEIGHT || 0) +
          (this.res[2][0]?.DUNNAGE1WEIGHT || 0) +
          (this.res[2][0]?.DUNNAGE2WEIGHT || 0); 
      };
      if (this.pageName !== 'Detail') {
        ppfweight =
          (this.displayRowData[i].CONTWEIGHT || 0) +
          (this.displayRowData[i].DUNNAGE1WEIGHT || 0) +
          (this.displayRowData[i].DUNNAGE2WEIGHT || 0); 
      }
      if (this.pageName === 'Detail') {
        if (
          ['0'].includes(this.detailPage.status) ||
          this.newShipmentForm.controls.noShipment.value
        ) {
          ppfqty = this.displayRowData[i].ActualShipQty || 0;
        } else {
          ppfqty = this.displayRowData[i].userPlanQty || 0;
        }

        total = total + ppfweight * ppfqty + partweight * partqty;

        this.newShipmentForm.patchValue({
          weight: total?.toFixed(4),
        });
      }

      if (this.pageName !== 'Detail') {
        if (
          ['21', '22', '4'].includes(this.deliveryData?.Status.toString()) ||
          this.newShipmentForm.controls.noShipment.value
        ) {
          ppfqty = this.displayRowData[i].ActualShipQty || 0;
        } else {
          ppfqty = this.displayRowData[i].userPlanQty || 0;
        }

        total = total + ppfweight * ppfqty + partweight * partqty;
      }
    }
    total = total + 14.06 * palletqty;
    if (total < 0) {
      total = 0;
    }
    this.newShipmentForm.patchValue({
      weight: total?.toFixed(4),
    });
  }

  addShipment() {
    const dialogRef = this.dialog.open(SelectAuthComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const AUTHNUMBER = result.data;
        sessionStorage.setItem('authNumber', AUTHNUMBER);
        this.newShipmentService
          .addButtonData(AUTHNUMBER)
          .subscribe((result) => {
            this.displayRowData = [
              ...this.displayRowData,
              { ...result?.createAuthData[0], ...result?.weight[0], ...result?.setDefaultCompanyInfo[0] },
            ];
            this.ShipNumber = result?.temp;
            sessionStorage.setItem('shipNumber', this.ShipNumber);
          });
      }
    });
  }

  dialogOpen1() {
    this.dialog.open(SelectCompanyComponent, {
      width: '100%',
      data: {
        clicked: this.shipCompanySelected,
      },
    });
  }

  shipCompanySelected(data: any) {
    if (data.selectedSuplier === 'fabricator') {
      this.requestShipmentReady.patchValue({
        company: data.PKGSUPCODE,
        contactAddress: data.ADDRESS,
        contactEmail: data.EMAIL,
        contactPerson: data.NAME,
        contactPhone: data.PHONE,
      });
    }
    if (data.selectedSuplier === 'namc') {
      this.requestShipmentReady.patchValue({
        company: `${data?.PLANTNAME} - ${data.DOCKCODE}`,
        contactAddress: data.ADDRESS,
        contactEmail: data.CONVEMAIL,
        contactPerson: data.CONVPERSONNAME,
        contactPhone: data.CONVPHONE,
      });
    }
    if (data.selectedSuplier === 'supplier') {
      this.requestShipmentReady.patchValue({
        company: data.SHORTNAME,
        contactAddress: data.ADDRESS,
        contactEmail: data.EMAIL,
        contactPerson: data.FULLNAME,
        contactPhone: data.PHONE,
      });
    }
  }

  deliveryCompanySelected(data: any) {
    if (data.selectedSuplier === 'fabricator') {
      this.requestLastDelivery.patchValue({
        company: data.PKGSUPCODE,       
        contactAddress: data.ADDRESS,
        contactEmail: data.EMAIL,
        contactPerson: data.NAME,
        contactPhone: data.PHONE,
        receiver: 'dun1fab'
      });
    }
    if (data.selectedSuplier === 'namc') {
      this.requestLastDelivery.patchValue({
        company: `${data?.PLANTNAME} - ${data.DOCKCODE}`,
        contactAddress: data.ADDRESS,
        contactEmail: data.CONVEMAIL,
        contactPerson: data.CONVPERSONNAME,
        contactPhone: data.CONVPHONE,
        receiver: 'namc'
      });
    }
    if (data.selectedSuplier === 'supplier') {
      this.requestLastDelivery.patchValue({
        company: data.SHORTNAME,
        contactAddress: data.ADDRESS,
        contactEmail: data.EMAIL,
        contactPerson: data.FULLNAME,
        contactPhone: data.PHONE,
        receiver: 'supplier'
      });
    }
  }

  dialogOpen2() {
    this.dialog.open(SelectCompanyComponent, {
      width: '100%',
      data: {
        clicked: this.deliveryCompanySelected,
      },
    });
  }

  checkBoxOnChange(event: any) {
    if (event.currentTarget.checked) {
      this.requestLastDelivery.patchValue({
        receiver: 'supplier',
        company: this.res ? this.res[2][0]?.SHIPCOMPANY : this.displayRowData[0]?.getSupplierdata[2]?.SHORTNAME,
        contactAddress: this.res ? this.res[2][0]?.SHIPADDRESS :this.displayRowData[0]?.getSupplierdata[0][0]?.ADDRESS,
        contactEmail: this.res ? this.res[2][0]?.SHIPEMAIL :this.displayRowData[0]?.getSupplierdata[2]?.EMAIL,
        contactPerson:this.res ? this.res[2][0]?.SHIPCPERSON : this.displayRowData[0]?.getSupplierdata[2]?.FULLNAME,
        contactPhone: this.res ? this.res[2][0]?.SHIPPHONENUMBER : this.displayRowData[0]?.getSupplierdata[2]?.PHONE
      });
      this.companySelectDisabled = true;
      this.newShipmentForm.disable();
      this.newShipmentForm.controls.noShipment.enable();
      this.requestShipmentReady.disable();
      this.requestShipmentReady.controls.company.enable();
      this.requestLastDelivery.enable();
      this.requestLastDelivery.controls.date.disable();
      this.requestLastDelivery.controls.windowTimeHH.disable();
      this.requestLastDelivery.controls.windowTimeMM.disable();
      this.actualShipment.controls.shipmentDate.enable();
    } else {
      this.companySelectDisabled = false;
      this.newShipmentForm.enable();
      this.newShipmentForm.controls.weight.disable();
      this.requestShipmentReady.enable();
      this.requestLastDelivery.controls.date.enable();
      this.requestLastDelivery.controls.windowTimeHH.enable();
      this.requestLastDelivery.controls.windowTimeMM.enable();
      this.actualShipment.controls.shipmentDate.disable();
    }
    for (let i = 0; i < this.displayRowData.length; i++) {
      this.gridApi.getRowNode(i.toString()).data['planDisabled'] = event.currentTarget.checked;
      this.gridApi.getRowNode(i.toString()).data['shipDisabled'] = event.currentTarget.checked;
      this.gridApi.getRowNode(i.toString()).data['receiveDisabled'] = event.currentTarget.checked;
      this.gridApi.getRowNode(i.toString()).data['historyDisabled'] = event.currentTarget.checked;
    }
    this.gridApi.refreshCells({ force: true });


    if (this.draftFlag) {
      if (event.currentTarget.checked) {
        this.requestLastDelivery.patchValue({
          receiver: 'supplier',
          company: this.res[2][0]?.SHIPCOMPANY,
          contactAddress: this.res[2][0]?.SHIPADDRESS,
          contactEmail:  this.res[2][0]?.SHIPEMAIL ,
          contactPerson:this.res[2][0]?.SHIPCPERSON,
          contactPhone:  this.res[2][0]?.SHIPPHONENUMBER 
        });
        this.companySelectDisabled = true;
        this.newShipmentForm.disable();
        this.newShipmentForm.controls.noShipment.enable();
        this.requestShipmentReady.disable();
        this.requestShipmentReady.controls.company.enable();
        this.requestLastDelivery.enable();
        this.requestLastDelivery.controls.date.disable();
        this.requestLastDelivery.controls.windowTimeHH.disable();
        this.requestLastDelivery.controls.windowTimeMM.disable();
        this.actualShipment.controls.shipmentDate.enable();
      } else {
        this.companySelectDisabled = false;
        this.newShipmentForm.enable();
        this.newShipmentForm.controls.weight.disable();
        this.requestShipmentReady.enable();
        this.requestLastDelivery.controls.date.enable();
        this.requestLastDelivery.controls.windowTimeHH.disable();
        this.requestLastDelivery.controls.windowTimeMM.disable();
        this.actualShipment.controls.shipmentDate.disable();
      }
      for (let i = 0; i < this.displayRowData.length; i++) {
        this.gridApi.getRowNode(i.toString()).data['planDisabled'] =
          event.currentTarget.checked;
        this.gridApi.getRowNode(i.toString()).data['receiveDisabled'] =
          event.currentTarget.checked;
        this.gridApi.getRowNode(i.toString()).data['historyDisabled'] =
          event.currentTarget.checked;
      }
      this.gridApi.refreshCells({ force: true });
    }
  }


  getBody() {
    const rowData: any = [];
    const authNumbers: string[] = [];
    this.gridApi?.forEachNode((x: any) => rowData.push(x?.data));
    authNumbers.push(rowData[0]?.AUTHNUMBER);
    return {
      shipmentNumber: this.ShipNumber,
      authNumbers: authNumbers,
      LPTYPE: this.shipmentReadyData?.LPType,
      LP: this.newShipmentForm.controls.lp.value,
      LPPLANTCODE: this.displayRowData[0]?.PLANTCODE,
      LPDOCKCODE: this.displayRowData[0]?.DOCKCODE,
      LPSUPCODE: this.displayRowData[0]?.SUPPLIERCODE,
      LPFABCODE: this.displayRowData[0]?.CONTFAB,
      LPUSERCODE: '',
      LPCOMMENT: '',
      SHIPTYPE: this.shipmentReadyData?.ShipType,
      SHIPPLANTCODE: this.shipmentReadyData?.ShipPlantCode,
      SHIPDOCKCODE: '',
      SHIPSUPCODE: '',
      SHIPFABCODE: '',
      SHIPUSERCODE: '',
      SHIPADDRESS: this.requestShipmentReady.controls.contactAddress.value,
      SHIPCPERSON: this.requestShipmentReady.controls.contactPerson.value,
      SHIPPHONENUMBER: this.requestShipmentReady.controls.contactPhone.value,
      SHIPEMAIL: this.requestShipmentReady.controls.contactEmail.value,
      SHIPDATE: this.requestShipmentReady.controls?.date.value,
      SHIPWINTIMEF: this.requestShipmentReady.controls.windowTimeHH.value,
      SHIPWINTIMET: this.requestShipmentReady.controls.windowTimeMM.value,
      SHIPSHIPCLASS: this.requestShipmentReady.controls.shippingClass.value,
      SHIPCOMMENT: '',
      RECVTYPE: this.requestLastDelivery.controls.receiver.value,
      RECVPLANTCODE: this.deliveryData?.RecvPlantCode,
      RECVDOCKCODE: this.deliveryData?.RecvDockCode,
      RECVSUPCODE: this.deliveryData?.RecvSupCode,
      RECVFABCODE: this.deliveryData?.RecvFabCode,
      RECVUSERCODE: '',
      RECVWINTIMEF: this.requestLastDelivery.controls.windowTimeHH.value,
      RECVWINTIMET: this.requestLastDelivery.controls.windowTimeMM.value,
      RECVADDRES: this.requestLastDelivery.controls.contactAddress.value,
      RECVCPERSON1: this.requestLastDelivery.controls.contactPerson.value,
      RECVPHONENUMBER1: this.requestLastDelivery.controls.contactPhone.value,
      RECVEMAIL1: this.requestLastDelivery.controls.contactEmail.value,
      RECVCPERSON2: this.deliveryData?.RecvCPerson2,
      RECVPHONENUMBER2: this.deliveryData?.RecvPhoneNumber2,
      RECVEMAIL2: this.deliveryData?.RecvEmail2,
      RECVDATE: this.requestLastDelivery.controls.date.value,
      NUMOFPALLET: this.newShipmentForm.controls.numberOfPallet.value,
      TotalWeight: this.newShipmentForm.controls.weight.value,
      STATUS: '11',
      CANCELFLAG: this.shipmentReadyData?.CancelFlag,
      ACTUALSHIPDATE: '',
      SPECIALISTCODE: this.SPECIALIST,
      NUMOFPARTS: '',
      SHIPQTY: '',
      CURRENTQTY: 1,
      RECVQTY: '',
      ACTUALSHIPQTY: '',
      ACTUALRECVQTY: '',
      PICKUPDATE: this.planShipment.controls.pickupDate.value,
      PICKUPTIME: this.planShipment.controls.pickupTime.value,
      DELIVDATE: this.planShipment.controls.deliveryDate.value,
      DELIVTIME: this.planShipment.controls.deliveryTime.value,
      CARRIERCODE: this.planShipment.controls.carrier.value,
      TRACKINGNO: this.planShipment.controls.trackingNumber.value,
      PLANCOST: this.planShipment.controls.costEstimate.value,
      ACTUALCOST: this.actualShipment.controls.actualCost.value,
      PLANCOSTUNIT: this.planShipment.controls.currency.value,
      ACTUALCOSTUNIT: this.actualShipment.controls.currency.value,
      ACTUALRECVDATE: this.actualShipment.controls.receivedDate.value,
    };
  }

  save() {
    if (this.pageName !== 'Detail') {
      if (!sessionStorage.getItem('authNumber')) {
        this.errorMessage = '[Auth] is required item.';
        alert(this.errorMessage);
        return;
      }
      let errorMessage = '';
      errorMessage += this.newShipmentForm.invalid
        ? this.getFormGroupErrorMessages(this.newShipmentForm, '')
        : '';
      errorMessage += this.requestShipmentReady.invalid
        ? this.getFormGroupErrorMessages(this.requestShipmentReady, 'Shipper')
        : '';
      errorMessage += this.requestLastDelivery.invalid
        ? this.getFormGroupErrorMessages(this.requestLastDelivery, 'Reciever')
        : '';
      errorMessage += this.planShipment.invalid
        ? this.getFormGroupErrorMessages(this.planShipment, 'Plan Shipment')
        : '';
      errorMessage += this.actualShipment.invalid
        ? this.getFormGroupErrorMessages(this.actualShipment, 'Actual')
        : '';

      if (
        this.requestShipmentReady?.value?.date &&
        this.requestLastDelivery?.value?.date &&
        new Date(this.requestShipmentReady.value.date) >
        new Date(this.requestLastDelivery.value.date)
      ) {
        errorMessage += 'Last delivery date must be after shipment date';
      }

      if (errorMessage) {
        alert(errorMessage);
      } else {
        const body = this.getBody();
        this.newShipmentService.ShipmentSave(body).subscribe({
          next: (_res) => {
            this.openMessageBox('Saved successfully', 'success');
          },
          error: (_error) => {
            this.openMessageBox('Failed to save', 'error');
          },
        });
      }
    }
    if (this.pageName === 'Detail') {
      const body = this.getBody();
      this.newShipmentService.ShipmentListSave(body).subscribe({
        next: (_res) => {
          this.router.navigate(['/shipment']);
          this.openMessageBox('Saved successfully', 'success');
        },
        error: (_error) => {
          this.openMessageBox('Failed to save', 'error');
        },
      });
    }
  }

  getFormGroupErrorMessages(form: any, formName: string) {
    let errorMessage = '';
    if (form.enabled && form.invalid) {
      const controls: any = form.controls;
      for (const control of Object.keys(controls)) {
        if (controls[control].enabled && controls[control].invalid) {
            errorMessage += `${formName} ${control} is a required item. \n`;
        }
    }
    }
    return errorMessage;
  }

  send() {
    if (this.pageName !== 'Detail') {
      if (!sessionStorage.getItem('authNumber')) {
        this.errorMessage = '[Auth] is required item.';
        alert(this.errorMessage);
      }
      let errorMessage = '';
      errorMessage += this.newShipmentForm.invalid
        ? this.getFormGroupErrorMessages(this.newShipmentForm, '')
        : '';
      errorMessage += this.requestShipmentReady.invalid
        ? this.getFormGroupErrorMessages(this.requestShipmentReady, 'Shipper')
        : '';
      errorMessage += this.requestLastDelivery.invalid
        ? this.getFormGroupErrorMessages(this.requestLastDelivery, 'Reciever')
        : '';
      errorMessage += this.planShipment.invalid
        ? this.getFormGroupErrorMessages(this.planShipment, 'Plan Shipment')
        : '';
      errorMessage += this.actualShipment.invalid
        ? this.getFormGroupErrorMessages(this.actualShipment, 'Actual')
        : '';

      if (
        this.requestShipmentReady?.value?.date &&
        this.requestLastDelivery?.value?.date &&
        new Date(this.requestShipmentReady.value.date) >
        new Date(this.requestLastDelivery.value.date)
      ) {
        errorMessage += 'Last delivery date must be after shipment date';
      }

      if (errorMessage) {
        alert(errorMessage);
      } else {
        const body: any = this.getBody();
        body['packagecode'] = this.displayRowData[0]?.PACKAGECODE;
        body['pkgTypeCode'] = this.displayRowData[0]?.PACKAGETYPECODE;

        this.newShipmentService.ShipmentSend(body).subscribe({
          next: (_res: any) => {
            this.router.navigate(['/shipment']);
            this.openMessageBox('Sent successfully', 'success');
          },
          error: (_error) => {
            this.openMessageBox('Failed to send', 'error');
          },
        });
      }
    }

    if (this.pageName === 'Detail') {
      const body = this.getBody();
      this.newShipmentService.ShipmentListSend(body).subscribe({
        next: (_res) => {
          this.router.navigate(['/shipment']);
          this.openMessageBox('Sent successfully', 'success');
        },
        error: (_error) => {
          this.openMessageBox('Failed to send', 'error');
        },
      });
    }
  }

  dialogOpenRackComponent(field:any) {
    if(this.pageName !== 'Detail'){
    sessionStorage.setItem('rackData2', JSON.stringify(this.shipmentReadyData?.ShipType));
    this.router.navigate(['/shipment/rackShipment']);
  } else if(this.pageName === 'Detail') {
    sessionStorage.setItem('rackData3', JSON.stringify(this.shipmentData));
    this.router.navigate(['/shipment/rackShipmentDetail']);
  }
     sessionStorage.setItem('rackData', JSON.stringify(field));
  }  

  dialogOpenHistoryComponent(AUTHNUMBER: string) {
    this.dialog.open(HistoryShipmentComponent, {
      width: '75%',
      data: { AUTHNUMBER: AUTHNUMBER },
    });
  }

  dialogOpenReceivementComponent(field:any) {
    sessionStorage.setItem('rackData', JSON.stringify(field));
    if(this.pageName !== 'Detail'){
    this.router.navigate(['/shipment/rackReceivement']);
    } else {
      this.router.navigate(['/shipment/rackReceivementDetail'])
    }
  }

  cancel() {
    this.router.navigate(['/shipment']);
  }

  dialogOpenAddRackComponent() {
    this.dialog.open(AddRackShipmentComponent);
  }

  valueChange(event: any) {
    this.defaultSearchFilter(event);
    this.apiCall(event);
  }

  defaultSearchFilter(supplier: any) {
    this.searchParameters = new FormGroup({
      supplier: new FormControl(supplier),
    });
  }

  apiCall(event: any) {
    const body = {
      FullName: '',
      Phone: '',
      guid: '',
      SupplierCode: history.state?.supplierCode
        ? history.state.supplierCode.length === 5
          ? history.state.supplierCode
          : history.state.supplierCode + event
        : this.supplierNumber[0].length === 5
          ? this.supplierNumber[0]
          : this.supplierNumber[0] + event,
      Email: '',
    };

    this.supplierNumber &&
      this.newShipmentService
        .loadDNewShipmentData(body)
        .subscribe((result: any) => {
          sessionStorage.setItem('buttonFlag', JSON.stringify({ cancelFlag: result?.shipmentReady?.CancelFlag, status: result?.delivery?.Status }))
          this.shipmentReadyData = result?.isFab?.result ? result?.radioResult : result?.result?.shipmentReady;
          this.deliveryData = result?.result?.delivery;
          this.actualShipment.disable();
          this.planShipment.disable();
          if (result) {
            if (
              result?.shipmentReady?.PickupYear &&
              result?.shipmentReady?.PickupMonth &&
              result?.shipmentReady?.PickupDay
            ) {
              const { PickupDay, PickupYear, PickupMonth } =
                result?.shipmentReady;
              result.shipmentReady['pickupDate'] = `${PickupYear}-${PickupMonth}-${PickupDay}`;
            }
            if (
              result.delivery?.RecvYear &&
              result.delivery?.RecvMonth &&
              result.delivery?.RecvDay
            ) {
              const { RecvDay, RecvYear, RecvMonth } = result.delivery;
              result.delivery['RecvDate'] = `${RecvYear}-${RecvMonth}-${RecvDay}`;

            }
          }
          result && this.setOnLoadValues(result);
        });
  }
}
