import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class UseBackupServiceService {

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  loadUseBackupPage(queryData:any) : Observable<any>{
    const queryParams = new HttpParams()
      .set('partNumber', queryData.partNumber)
      .set('pkgStartDate', queryData.pkgStartDate)
      .set('plantCode',queryData.plantCode)
      .set('supplierCode', queryData.supplierCode)
      .set('modelCode',queryData.modelCode);      
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }

  notifyOnLoad(queryData:any){
    const queryParams = new HttpParams()
      .set('partNumber', queryData.partNumber)
      .set('pkgStartDate', queryData.pkgStartDate)
      .set('plantCode',queryData.plantCode)
      .set('supplierCode', queryData.supplierCode)
      .set('modelCode',queryData.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use/notify', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }
  notifySubmit(body:any,queryData:any){
    const url = this.constantsService.EXTERNAL + `backup/use/notify?partNumber=${queryData.partNumber}&pkgStartDate=${queryData.pkgStartDate}&plantCode=${queryData.plantCode}&supplierCode=${queryData.supplierCode}&modelCode=${queryData.modelCode}`
    return this.http.post<any>(url, body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  updateOnLoad(queryData:any){
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
      .set('modelCode',queryData.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use/update', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }
  updateSubmit(body:any,authNumber:any,partNumber:any,plantCode:any){
    const url = this.constantsService.EXTERNAL + `backup/use/update?authNumber=${authNumber}&partNumber=${partNumber}&plantCode=${plantCode}`
    return this.http.put<any>(url, body,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  endUseOnLoad(queryData:any){
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
      .set('modelCode',queryData.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use/enduse', {params: queryParams,headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }

  endUseSubmit(body:any, authNumber:any){
    const url = this.constantsService.EXTERNAL + `backup/use/enduse?authNumber=${authNumber}`;
    return this.http.put<any>(url,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError));
  }

  trackingOnLoad(queryData:any){
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
      .set('modelCode', queryData.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use/tracking', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }
  trackingSubmit(body:any, authNumber:any){
    const url = this.constantsService.EXTERNAL + `backup/use/tracking?authNumber=${authNumber}`;
    return this.http.put<any>(url,body,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError));
  }
  
  downloadPdf(queryData:any){
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNum)
    return this.http.get(this.constantsService.EXTERNAL + 'backup/use/download', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }
  invoiceDownload(authNumber:any,invoice:any): Observable<any> {
    const queryParams = new HttpParams()
        .set('authNumber', authNumber)
        .set('filename',invoice);
    return this.http
        .get(this.constantsService.EXTERNAL + 'backup/use/invoice',{ params: queryParams,observe: 'response', responseType: 'text', headers: this.constantsService.httpOptions})
        .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return (error.message || "Server Error");
	}
}
