<app-loader [lines]=8 [table]=2 *ngIf="asyncLoading"></app-loader>
<div class="alert alert-warning mx-2" *ngIf = "haveAccess === false">
    <span *ngFor="let x of errorList">{{x}}<br></span>
</div>
<div id="err" class="alert alert-danger m-3" *ngIf="errorMessage !== null">
    <span>{{ errorMessage }}</span>
    <button type="button" class="btn-close" (click)="close()" data-bs-dismiss="alert" aria-label="close"></button>
</div>
<div id="specialerr" class="alert alert-danger m-3" *ngIf="specialCharError.length">
    <div *ngFor="let x of specialCharError">
        {{ x }}<br>
    </div>
</div>

<div class="ppf" id="print" *ngIf="!asyncLoading && haveAccess">
    <div class="top-control-buttons gap-2 pe-3" id="control-bar">
        <button (click)="savePPF()" class="vpacs-btn-table" *ngIf="mode === 1">SAVE</button>
        <button class="btn btn-link" (click)="downloadPPF()">DOWNLOAD</button>
        <button class="btn btn-link" (click)="navigatePrevious()">CLOSE</button>
        <button class="btn btn-link" (click)="selectPart()" *ngIf="mode === 1">COPY</button>
        <button class="help-btn" (click)="help()">help</button>
    </div>
    <div class="ppf-content m-3" id="PPF" #PPF="" [formGroup]="ppfForm">
        <div class="container-list d-flex flex-wrap">
            <div class="card1 col-lg-3 col-md-6 col-6">
                <div class="card-head d-flex justify-content-center align-items-center fs-4 border-bottom">
                    {{isMazda?"Mazda":"TOYOTA"}}</div>
                <div class="d-flex flex-wrap list">
                    <span class="col-5 px-2" title="Supplier Name:">Supplier Name:</span>
                    <span class="col-7 px-2" title={{ppfData?.suppliername}}>{{ppfData?.suppliername !=='null'?ppfData?.suppliername:'-'}}</span>
                    <span class="col-5 px-2" title="Supplier Code:">Supplier Code:</span>
                    <span class="col-7 px-2" title={{ppfData?.suppliercode}}>{{supplierCode}}</span>
                    <span class="col-5 px-2" title="Supplier Pickup Location:">Supplier Pickup Location:</span>
                    <span class="col-7 px-2" title={{ppfData?.supplierpickup}}>{{ppfData?.supplierpickup!=='null'?ppfData?.supplierpickup:'-'}}</span>
                    <span class="col-5 px-2" title="Tlms code:">Tlms code:</span>
                    <span class="col-7 px-2" title={{ppfData?.tlms_code}}>{{ppfData?.tlms_code!=='null'?ppfData?.tlms_code:'-'}}</span>
                </div>
                <div class="d-flex img-upload">
                    <span class="col-1 d-flex align-items-center justify-content-center" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">DIGITAL IMAGE</span>
                    <div class="col-11">
                        <h5 class="py-2">PART ( IN PACKAGING DIRECTION)</h5>
                        <div class="img-cont col d-flex px-3 gap-2">
                            <div class="left col">
                                <div class="img-preview">
                                    <div *ngIf="!image[0]"
                                        class="d-flex h-100 align-items-center justify-content-center">
                                        <mat-icon class="text-secondary">panorama</mat-icon>
                                    </div>
                                    <a target="_blank" (click)="openWindow(zoomImage[0])">
                                        <img *ngIf="image[0]" [src]="image[0]" alt="partImage" />
                                    </a>
                                </div>
                                <div class="d-flex align-items-center my-2 justify-content-between">
                                    <label *ngIf="mode === 1" for="file-upload1" class="vpacs-btn-table col-10"
                                        (change)="handleUpload($event,1)">
                                        CHOOSE FILE
                                        <input type="file" id="file-upload1" style="display: none;">
                                    </label>
                                    
                                    <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                        *ngIf="mode === 1" alt="D" class="vpacs-btn-table" (click)="handleDelete(1)" />
                                </div>
                            </div>

                        </div>
                        <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[0]">
                            Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                        </div>
                        <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[0]">{{formatError[0]}}
                        </div>
                    </div>
                </div>
                <div class="cont1-radio d-flex p-2 ps-5">
                    <span class="pe-4">HAZMAT : </span>
                    <span *ngIf="mode === 2">{{ppfData?.hazmat}}</span>
                    <div class="d-flex align-items-center" *ngIf="mode === 1">
                        <input type="radio" value="Y" class="mx-1" formControlName="hazmat" />Yes
                        <input type="radio" value="N" class="mx-1" formControlName="hazmat" />No
                    </div>
                </div>
            </div>
            <div class="card2 col-lg-6 col-md-12 col-12">
                <div class="card-head d-flex text-center border-bottom">
                    <span class="col-6 fs-4 zone">{{ppfData?.plant}}</span>
                    <div class="col-6 py-2">
                        <div class="fs-6 fw-bold">PACKAGING PROPOSAL</div>
                        <span class="tmna">TOYOTA MOTOR MANUFACTURING NORTH AMERICA, INC.</span>
                    </div>
                </div>
                <div class="sub-cards col d-flex">
                    <div class="subcard-1 col-6 pe-1">
                        <div class="d-flex flex-wrap list">
                            <span class="col-5 px-2" title="Packaging Contact">Packaging Contact:</span>
                            <span class="col-7 px-2"
                                title={{ppfData?.suppliercontact}}>{{ppfData?.suppliercontact!=='null'?ppfData?.suppliercontact:'-'}}</span>
                            <span class="col-5 px-2" title="Phone Number">Phone Number:</span>
                            <span class="col-7 px-2"
                                title={{ppfData?.supplierphonenumber}}>{{ppfData?.supplierphonenumber!=='null'?ppfData?.supplierphonenumber:'-'}}</span>
                            <span class="col-5 px-2" title="Fax Number">Fax Number:</span>
                            <span class="col-7 px-2"
                                title={{ppfData?.supplierfaxnumber}}>{{ppfData?.supplierfaxnumber!=='null'?ppfData?.supplierfaxnumber:'-'}}</span>
                            <span class="col-5 px-2" title="Email Address">E-Mail Address:</span>
                            <span class="col-7 px-2" title={{ppfData?.suppliermail}}>{{ppfData?.suppliermail!=='null'?ppfData?.suppliermail:'-'}}</span>
                        </div>
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">DUNNAGE(IN CONTAINER USE DIRECTION)</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col-12">
                                        <div class="img-preview">
                                            <div *ngIf="!image[1]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[1])">
                                                <img *ngIf="image[1]" [src]="image[1]" alt="partImage" />
                                            </a>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center my-2">
                                            <label *ngIf="mode === 1" for="file-upload2" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,2)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload2" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D"
                                                *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(2)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[1]">
                                    Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                                </div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[1]">
                                    {{formatError[1]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="subcard-2 col-6 ps-1">
                        <div class="d-flex flex-wrap list">
                            <span class="col-5" title="Model Number">Model Code:</span>
                            <span class="col-7" title={{response?.modelCodes}}  *ngIf="!model">{{ppfData?.modelcode!=='null'?ppfData?.modelcode:'-'}}</span>
                            <span class="col-7" title={{response?.modelCodes} *ngIf="model">{{model}}</span>
                            <span class="col-5" title="Parts Description">Parts Description:</span>
                            <span class="col-7" title={{ppfData?.description}} *ngIf="!partdesc">{{ppfData?.description!=='null'?ppfData?.description:'-'}}</span>
                            <span class="col-7" title={{ppfData?.description}} *ngIf="partdesc">{{partdesc}}</span>
                            <span class="col-5" title="Replaces Part Number">Replaces Part #:</span>
                            <span class="col-7" title={{ppfData?.replacespartnumber}} >{{ppfData?.replacespartnumber!=='null'?ppfData?.replacespartnumber:'-' }}</span>
                            <span class="col-5" title="ECI Number">ECI #:</span>
                            <span class="col-7" title={{ppfData?.ecinumber}}>{{ppfData?.ecinumber!=='null'?ppfData?.ecinumber:'-'}}</span>
                        </div>
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">CONT/RACK (INCLUDE KANBAN LOCATION)</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col-12">
                                        <div class="img-preview">
                                            <div *ngIf="!image[2]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[2])">
                                                <img *ngIf="image[2]" [src]="image[2]" alt="partImage" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between my-2">
                                            <label *ngIf="mode === 1" for="file-upload3" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,3)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload3" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D"
                                                *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(3)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[2]">
                                    Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                                </div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[2]">
                                    {{formatError[2]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card3 col-lg col-md-4 col-6">
                <div class="card-head card3-header px-3 py-2">
                    <span class="col-3">PART NUMBER</span>
                    <span class="col-9">PRELIMINARY OR FINAL</span>
                </div>
                <div class="d-flex flex-wrap list">
                    <div class="col-3 d-flex flex-wrap fw-bold mx-1 ">
                        <span class="col-12">{{constPartNumber}}</span>
                    </div>
                    <div class="list-right col-8 d-flex flex-wrap">
                        <span class="col-10 fw-bold">{{ppfData?.preorfinal === '1' ? 'PRELIMINARY' : 'FINAL'}}</span>
                        <span class="col-6">Date:</span>
                        <span class="col-6">{{regdate | date: 'MM/dd/yyyy'}}</span>
                        <span class="col-6">Dock Code:</span>
                        <span *ngIf="mode===2">{{ppfData?.dockcode === null ? '-' : ppfData?.dockcode}}</span>
                        <mat-select class="mat-sel" style="width: 60px; height : 25px" formControlName="dock" *ngIf="mode===1">
                            <mat-option *ngFor="let x of dockDropDown" [value]="x.dockcode">{{x.dockcode}}</mat-option>
                        </mat-select>
                        <span class="col-6">Broadcast Part:</span>
                        <span *ngIf="mode===2" class="col-6">{{ppfForm.controls.broadcastPart.value === '0' ? 'No' : 'Yes'}}</span>
                        <div class="d-flex align-items-center col-6" *ngIf="mode === 1">
                            <input type="radio" value='1' class="mx-1" formControlName="broadcastPart"/>Yes
                            <input type="radio" value='0' class="mx-1" formControlName="broadcastPart"/>No
                        </div>
                    </div>
                </div>
                <div class="d-flex img-upload">
                    <div class="col">
                        <h5 class="py-2">PALLET LOAD (AS SHIPPED)</h5>
                        <div class="img-cont col d-flex px-3 gap-2">
                            <div class="left col">
                                <div class="img-preview">
                                    <div *ngIf="!image[3]"
                                        class="d-flex h-100 align-items-center justify-content-center">
                                        <mat-icon class="text-secondary">panorama</mat-icon>
                                    </div>
                                    <a target="_blank" (click)="openWindow(zoomImage[3])">
                                        <img *ngIf="image[3]" [src]="image[3]" alt="partImage" />
                                    </a>
                                </div>
                                <div class="d-flex align-items-center justify-content-between my-2">
                                    <label *ngIf="mode === 1" for="file-upload4" class="vpacs-btn-table col-10"
                                        (change)="handleUpload($event,4)">
                                        CHOOSE FILE
                                        <input type="file" id="file-upload4" style="display: none;">
                                    </label>
                                    <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D" *ngIf="mode === 1"
                                        class="vpacs-btn-table" (click)="handleDelete(4)" />
                                </div>
                            </div>
                        </div>
                        <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[3]">
                            Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                        </div>
                        <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[3]">{{formatError[3]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- grid1 -->
        <div class="grid1 d-flex mt-2">
            <span class="d-flex align-items-center justify-content-center px-2" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">PACKAGE MATERIAL</span>
            <div class="w-100" [formGroup]="form">
                <table class="tg w-100" aria-describedby="table1">
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows; let i = index">
                            <td *ngFor="let col of columns; let j = index" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                <ng-container *ngIf="(j === 9 || j === 7 || j === 8) && (mode === 1)">

                                    <input 
                                         [ngClass]="checkText('row' + i + 'col' + j,1)"  
                                        type="text" 
                                        style="width: 100px; height: 25px;" 
                                        [formControlName]="'row' + i + 'col' + j"
                                        (change)="onInputChange(i, j, $event)"
                                    />
                                </ng-container>
                                <ng-container *ngIf="(j === 2 || j === 4) && (mode === 1)">
                                    <div class="w-100 d-flex gap-1">
                                        
                                        <input 
                                            [ngClass]="checkText('row' + i + 'col' + j,1)"
                                            type="text" 
                                            style="width: 100px; height: 25px;" 
                                            [formControlName]="'row' + i + 'col' + j"
                                            readonly
                                        />
                                        <mat-icon style="height:25px; font-size: 21px"
                                        (click)="j === 4 ? selectPackage(i+1) : menuClick(i, j)" 
                                            class="vpacs-btn-table d-flex justify-content-center">more_vert</mat-icon>
                                        <img src="../../../../../../assets/images/noun_edit_1570019.svg"
                                            style="height:25px;" alt="D" class="vpacs-btn-table" 
                                            (click)="onButtonClick(i,j)"
                                        />
                                    </div>
                                </ng-container>

                                
                                
                                <ng-container  *ngIf="(j !== 2 && j !== 4 && j !== 9 && j !== 7 && j !== 8) && (mode === 1)">
                                    <div [ngClass]="checkText(i+'$'+(j+1) ,0)">
                                    {{ data[i]['col' + (j+1) ] }}
                                </div >
                                </ng-container>
                                <ng-container  *ngIf="(mode === 2)">
                                    <div [ngClass]="checkText(i+'$'+(j+1) ,0)">

                                    {{ data[i]['col' + (j+1) ] }}
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- grid2 -->
        <div class="grid2 mt-2 d-flex">
            <span class="d-flex align-items-center justify-content-center px-2" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">PACKAGE DATA</span>
            <div class="w-100" [formGroup]="form2">
                <table class="tg w-100" aria-describedby="table2">
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers2" [attr.colspan]="header.colspan">
                                {{ header.label }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows2; let i = index">
                            <td *ngFor="let col of columns2; let j = index" [ngClass]="([0,4,6].includes(col) || (col===7 && i===3)) ? 'tg-mg6y' : 'tg-z5to'">
                                <ng-container *ngIf="(((j === 5 && i!==0) || (j === 7 && i!==3) || (j === 3 && i === 5)) && (mode === 1)); else desc">
                                    <input 
                                        type="text" 
                                        style="width: 100px; height: 25px;" 
                                        [formControlName]="'row' + i + 'col' + j"
                                        (change)="onInputChange2(i, j, $event)" [ngClass]="contGross(i,j,0)"
                                    /> 
                                       
                                        </ng-container>
                                        <ng-template #desc>
                                            <div [ngClass]="contGross(i,j,1)">
                                                {{ tableData[i]['column' + (j+1) ] }}
                                            </div>

                                        </ng-template>
                                        <ng-template *ngIf="mode === 2">
                                            <div [ngClass]="contGross(i,j,1)">
                                                {{ tableData[i]['column' + (j+1) ] }}
                                            </div>
                                        </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <!-- footer -->
        <div class="footerclr col-12 d-flex align-items-center flex-wrap p-2">
            <span class="col-lg-6 col-md-4 col-12 my-1">SUPPLIER SIGNATURE: {{ppfData?.suppliercontact}}</span>
            <span class="col-lg-2 col-md-4 col-12 my-1">DATE: {{supplierdate | date:
                'MM/dd/yyyy'}}</span>
            <span class="col-lg-4 col-md-4 col-12 my-1">PLEASE INDICATE OTHER AFFILIATE THIS PART/PKG IS SHIPPED TO:
                {{ppfData?.affiliate}}</span>
            <span class="col-lg-4 col-md-6 col-12 my-1">TOYOTA APPROVAL SIGNATURE: {{ppfData?.approver}}</span>
            <span class="col-lg-2 col-md-4 col-12 my-1">APPROVED: {{(ppfData?.approver !=="" && ppfData?.approver !==null) ?'YES':'NO'}}</span>
            <span class="col-lg-2 col-md-4 col-12 my-1">DATE: {{approvedate | date:
                'MM/dd/yyyy'}}</span>
            <span class="col-lg-4 col-md-4 col-12 my-1">COMMENTS: {{ppfData?.note}}</span>
        </div>
        
    </div>
</div>
<router-outlet></router-outlet>