<div class="popup-container" *ngIf="pageName !== 'delete'">
  <header>
    <label class="close black-button" (click)="close()">X</label>
    <div class="d-flex justify-content-between align-items-center header">
      <p class="page-title m-0">{{ modalHeader }}</p>
      <div>
        <button class="help-btn" (click)="help()">Help</button>
      </div>
    </div>
  </header>

  <div class="alert alert-danger m-2" *ngIf="errorMessage">
    <span class="message-text">{{ errorMessage }}</span>
  </div>

  <div class="popup-contents" [formGroup]="addPkgForm">
    <div class="row m-3 align-items-start">
      <div class="form-group col-12 my-1">
        <label for="code" class="catlabel col-5"
          >Code<small class="text-danger">*</small></label
        >
        <input
          type="text"
          formControlName="code"
          [ngClass]="{ 'is-invalid': submitted && f['code'].errors }"
          maxlength="1"
        />
        <div
          class="invalid-feedback col-12"
          *ngIf="f['code']?.touched && !f['code']?.valid"
        >
          <div *ngIf="f['code']?.errors?.['required']">Code is required</div>
          <div *ngIf="f['code']?.errors?.['maxlength']">
            Code should not be greater than 1 character
          </div>
        </div>
      </div>

      <br />
      <div class="form-group col-12 my-1">
        <label class="catlabel col-5" for="input2"
          >Name <small class="text-danger">*</small></label
        >
        <input
          type="text"
          formControlName="name"
          [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
          maxlength="20"
        />
        <div
          class="invalid-feedback col-12"
          *ngIf="f['name']?.touched && !f['name']?.valid"
        >
          <div *ngIf="f['name']?.errors?.['required']">Name is required</div>
          <div *ngIf="f['name']?.errors?.['maxlength']">
            Name should not be greater than 20 characters
          </div>
        </div>
      </div>
      <br />
      <div>
        <label class="catlabel col-5"
          >Component <small class="text-danger">*</small></label
        >
        <mat-select
          id="supplier"
          class="mat-sel pt-2 col-7"
          disableOptionCentering
          formControlName="component"
          [ngClass]="{ 'is-invalid': submitted && f['component'].errors }"
        >
          <mat-option
            *ngFor="let component of components"
            [value]="component.code"
            >{{ component.desc }}</mat-option
          >
        </mat-select>

        <div
          class="invalid-feedback col-12"
          *ngIf="f['component']?.touched && !f['component']?.valid"
        >
          <div *ngIf="f['component']?.errors?.['required']">
            Please select component
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        footer
        d-flex
        justify-content-end
        align-items-center
        fixed-bottom
        mt-2
        pt-2
        pb-2
      "
    >
      <button class="cancel me-3 roboto-bold" (click)="close()">CANCEL</button>
      <button
        class="submit black-button me-3 roboto-bold"
        (click)="updateBtn()"
      >
        {{ buttonValue }}
      </button>
    </div>
    <button type="button" class="close black-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="popup-container" *ngIf="pageName === 'delete'">
  <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
  <div mat-dialog-content>
    <p class="p-2">
      Are you sure that you want to delete [{{
        dataStore?.field?.packagetypecode
      }}]
    </p>
  </div>
  <div mat-dialog-actions class="btn-align">
    <div mat-dialog-close class="text-primary mx-3">No</div>
    <button class="black-button mx-3" (click)="deleteMaterial()">Yes</button>
  </div>
</div>
