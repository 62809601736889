import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HomeService } from 'src/app/internal-vpacs/services-internal/home/home.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent implements OnInit {
  routeParams: any;
  userInfo: any;
  specialistCode: any
  plantCode: any;
  displayRowData: any[] = [];
  errorList: any[] = [];
  mode = 0;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly state: ExportStateService,
    private readonly homeService: HomeService,
    private readonly spinnerService: NgxUiLoaderService) {}

  ngOnInit(): void {
    this.route.params.subscribe((data:any)=>{
      this.routeParams = data;
    })
    this.mode = Number(this.routeParams.code);
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.userInfo = data.info
        this.specialistCode = this.userInfo?.p_specialistcode
        sessionStorage.setItem(
          'specialistCode',
          JSON.stringify(this.specialistCode),
        )
        this.plantCode = this.userInfo?.p_plantcode
        sessionStorage.setItem('plantCode', JSON.stringify(this.plantCode))
        if (this.specialistCode && this.plantCode) {
          this.logDetails(this.specialistCode, this.plantCode, this.mode)
        }
      }
    })
  }

  logDetails(spCode: any, plCode: any, mode:number) {
    setTimeout(() => {this.spinnerService.start()})
    const params = {
      SpecialistCode: spCode,
      PlantCode: plCode,
      Mode: mode,
    }
    this.homeService.logDetails(params).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.displayRowData = res;
      },
      error: (_err: any) => {
        this.errorList = [`API-${_err.status}`];
        this.spinnerService.stop();
      },
    })
  }

}
