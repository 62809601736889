import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CheckBoxFab } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/checkBoxFab';
import { SupplierService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-update-contact',
  templateUrl: './update-contact.component.html',
  styleUrls: ['./update-contact.component.scss'],
  providers : [BreadcrumbComponent]
})
export class UpdateContactComponent implements OnInit {
  plant: any;
  cPage = 1;
  tPage = 0;
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any
  icons: {};
  rowClassRules: any;
  private guid: any = '';
  admin: any;
  valid: any;
  authvalid: any;
  suppliercode: any;
  fullName: any;
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
    <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
    </div>
    `;
  editAccess: any;
  viewAccess: any;
  userInfo: any;
  id: any;
  errorMessage:any;
  errorList: any[] = [];
  headerNoBorder = 'child-header-color no-border'
  headerCls = 'child-header-color';
  constructor(
    public supplierService: SupplierService,
    private readonly spinnerService: NgxUiLoaderService,
    public route: ActivatedRoute,
    public router: Router,
    protected location: Location,
    public state: ExportStateService,
    public breadcrumb : BreadcrumbComponent
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: false,
      autoHeight: true,
    };

    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };

  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res.info;
          this.editAccess = res?.role?.p_usersave;
          this.viewAccess = res?.role?.p_userview;
          if (this.viewAccess) {
            this.spinnerService.start();
            this.route.params.subscribe((params: any) => {
              if (params.suppliercode && params.id) {
                this.suppliercode = params.suppliercode;
                this.guid = params.id;
                this.id = params.id;
                this.getColumnDefs();
                this.updateSupplierContactOnload();
              } else {
                this.spinnerService.stop();
              }
            });
          }else{
            this.errorMessage = {errorCode : `API-403`,childErrorCode : '400'};
          }
        }
      },
      error: (_err: any) => {
        this.editAccess = false;
        this.viewAccess = false;
      }
    })
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: '', field: 'plantname', sortable: true, unSortIcon: true, minWidth: 120, children:
          [
            { headerName: 'Plant', field: 'plantname', headerClass: this.headerNoBorder, minWidth: 120 }
          ],
      },
      {
        headerName: '', field: 'Read', sortable: true, unSortIcon: true, minWidth: 90, children:
          [
            {
              headerName: 'Read', field: 'Read', minWidth: 90, headerClass: this.headerNoBorder,
              cellRendererFramework: CheckBoxFab,
            }
          ],
      },
      {
        headerName: '', field: 'Write', sortable: true, unSortIcon: true, minWidth: 90, children:
          [
            {
              headerName: 'Write', field: 'Write', minWidth: 90, headerClass: this.headerNoBorder,
              cellRendererFramework: CheckBoxFab,
            }
          ],
      },
      {
        headerName: '', field: 'send', sortable: true, unSortIcon: true, minWidth: 120, children:
          [
            {
              headerName: 'Submit', field: 'send', minWidth: 90, headerClass: this.headerNoBorder,
              cellRendererFramework: CheckBoxFab,
            }
          ],
      },
      {
        headerName: 'PreProd', field: 'PREPROD', children:
          [
            {
              headerName: 'Leader', headerClass: this.headerCls,
              field: 'preprod', minWidth: 120, cellRendererFramework: CheckBoxFab,
            },
            {
              headerName: 'Email', field: 'needemailpre', headerClass: this.headerCls,
              minWidth: 120,
              cellRendererFramework: CheckBoxFab,
            },
          ],
      },
      {
        headerName: 'MassProd', field: 'MASSPROD', children:
          [
            {
              headerName: 'Leader', field: 'massprod', headerClass: this.headerCls, minWidth: 120,
              cellRendererFramework: CheckBoxFab
            },
            {
              headerName: 'Email', field: 'needemailmass', headerClass: this.headerCls, minWidth: 120,
              cellRendererFramework: CheckBoxFab
            },
          ],
      },
    ]
  }

  handleChkClick(ev: any, key: string) {
    const val = ev.target.checked ? 1 : 0;
    switch (key) {
      case "Valid":
        this.valid = val
        break;
      case "Admin":
        this.admin = val
        break;
      case "authValid":
        this.authvalid = val
        break;
    }
  }

  updateSupplierContactOnload(): void {
    const loadObj = {
      "supplierCode": this.suppliercode,
      "guid": this.id
    };
    this.spinnerService.start();
    this.supplierService.updateSupplierContactOnload(loadObj)
      .subscribe({
        next: (res: any) => {
          this.displayRowData = res;
          this.admin = res[0].Admin;
          this.valid = res[0].Valid;
          this.authvalid = res[0].authvalid;
          this.suppliercode = res[0].suppliercode5
          this.fullName = res[0].fullname
        },
        error: (_err: any) => {
          this.errorMessage = {errorCode : `API-EMPTY`,childErrorCode : '400'};
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      })
  }

  updateSupplierContact() {
    const gridData:any = [];
    this.gridApi.forEachNode((node:any) => gridData.push(node.data));
    const read = gridData.map((x:any) => Number(x.Read));
    const write = gridData.map((x:any) => Number(x.Write));
    const submit = gridData.map((x:any) => Number(x.send));
    const loadObj = {
      "TotalRows": this.displayRowData.length.toString(),
      "Admin": Number(this.admin),
      "Valid": Number(this.valid),
      "chk5thValid": Number(this.authvalid),
      "plantCodes": this.displayRowData.map(a => a.plantcode),
      "Read": this.authvalid === '1' ? read : read.fill(0),
      "Write": this.authvalid === '1' ? write : write.fill(0),
      "Submit": this.authvalid === '1' ? submit : submit.fill(0),
      "supplierCode": this.suppliercode,
      "guid": this.guid
    };

    this.spinnerService.start();
    this.supplierService.updateSupplierContact(loadObj)
      .subscribe({
        next: (res: any) => {
          if(res.error){
            this.errorList = res.Message || 'Unable to perform this operation'
          }else{
            this.location.back();
          }
        },
        error: (_err: any) => {
          this.errorMessage = {errorCode : `API-EMPTY`,childErrorCode : '400'};
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      })

  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  help() {
    this.router.navigate(['./master-help', 5, "Master Maintenance", 129])
  }

  backBtn() {
    const url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    this.router.navigate([url]);
  }

}
