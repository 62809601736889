<div class="popup-container">
  <div class="mx-3 header d-flex align-items-center justify-content-between">
    <span>Replace Part Confirmation</span>
    <div class="d-flex" *ngIf="viewAccess">
      <button class="black-button mx-3" (click)="addBtn('Add')">Add</button>
      <button class="black-button mx-3" (click)="addBtn('Ignore')">Ignore</button>
      <button class="black-button mx-3" (click)="routeToModelList()">Back</button>
    </div>
  </div>
  <app-notification [codes]="errorMessage" *ngIf="viewAccess===false && errorMessage"></app-notification>
  <h5 class="mx-3" *ngIf="viewAccess">New</h5>
  <div class="filter d-flex m-3" *ngIf="viewAccess">
    <div class="d-flex col-4 flex-wrap">
      <span class="col-6">ECI #</span>
      <span class="col-6">{{ sessionReplacePart[sessionIndex]?.eci }}</span>
      <span class="col-6">Supplier Code</span>
      <span class="col-6">{{ sessionReplacePart[sessionIndex]?.newSupCode }}</span>
      <span class="col-6">Supplier name</span>
      <span class="col-6">{{ sessionReplacePart[sessionIndex]?.newSupName }}</span>
      <span class="col-6">Part #</span>
      <span class="col-6">{{ sessionReplacePart[sessionIndex]?.partNumber }}</span>
    </div>
  </div>
  <div class="alert alert-danger mx-3" role="alert" *ngIf = "errorArray.length > 0">
    <span *ngFor="let message of errorArray">{{message}}</span>
  </div>
  <div class="grid-agg mt-3" *ngIf="viewAccess">
    <h5>Current</h5>
    <ag-grid-angular
      style="width: 100%; height: 300px"
      class="ag-theme-balham"
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [rowData]="displayRowData"
      [gridOptions]="gridOptions"
      [suppressCellSelection]="true"
      [icons]="icons"
      [headerHeight]="headerHeight"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40"
      [domLayout]="domLayout"
    >
    </ag-grid-angular>
    <app-pagination
      [cPage]="cPage"
      [tPage]="tPage"
      [rpPage]="rpPage"
      [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)"
      *ngIf="totalRecords > 0"
    >
    </app-pagination>
  </div>
</div>
