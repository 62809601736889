import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AddRoleMasterComponent } from '../../internal-vpacs-dialogs/master-maintenance/role-maintenance/add-role-master/add-role-master.component';
import { MatDialog } from '@angular/material/dialog';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'update-role--master-btn-cell-render',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button  [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'"
    class="vpacs-btn-table"
    style="height:30px;"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class UpdateRoleMaterBtnCellRender implements ICellRendererAngularComp {
  constructor(
    public dialog: MatDialog,
    private readonly state: ExportStateService,
    ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  dullFlag = false;
  disableFlag = false;

  agInit(params: any): void {
    this.params = params;
    this.getUserData();
    
    if (this.params.colDef.headerName === 'UPDATE'){
    this.buttonName = 'UPDATE';}
    else if (this.params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
      this.dullFlag = this.disableFlag = Number(params.data.used) > 0; 
    }
  }
  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        if (
          (!data.role.p_userdelete && this.buttonName === 'DELETE') ||
          (!data.role.p_usersave && this.buttonName === 'UPDATE')
        ) {
          this.disableFlag = true;
          this.dullFlag = true;
        }
      }  
    })
  }

  btnClickedHandler(_event: any) {
    const dialogRef = this.dialog.open(AddRoleMasterComponent, {
      width: this.buttonName === 'UPDATE' ? '1062px':'',
      data: {
        headerName: this.buttonName === 'UPDATE' ? 'Update' : 'Delete',
        rolecode: this.params.data.rolecode,
        rolename: this.params.data.rolename,
        oldrolename: this.params.data.rolename,
        checkflag: "1"
      }
    });
  
    dialogRef.afterClosed().subscribe((data: any) => {
      this.params.dialogClose(data);
    });
  }
  
}




