import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { NecessaryApprovedItemsComponent } from '../../internal-vpacs-dialogs/home/necessary-approved-items/necessary-approved-items.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'approved-items-cell-renderer',
  template: `
  <a (click)="openDialog()" style="font-weight:500">
  {{ dates }} : {{ text }}</a>
  `,
})

export class ApprovedItemsCellRenderer implements ICellRendererAngularComp {

  constructor( public readonly dialog: MatDialog,
    public datepipe: DatePipe,
    ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;
  dates: any;

  agInit(params: any): void {
    this.params = params;
    const field = this.params.colDef.field;
    this.text = this.params.data[field];
    this.dates = this.datepipe.transform(this.params.data.senddate,'MM/dd/YYYY');
  }

  openDialog() {
    if (this.params.colDef.headerName === 'NECESSARY APPROVED ITEMS') {
      this.dialog.open(NecessaryApprovedItemsComponent, {
        data: this.params.data
      })
    }
  }
}




