<div class="popup-container">

    <form #myForm="ngForm" (ngSubmit)="saveData()">
        <header>
            <button type="button" class="close black-button" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
           
            <div class="d-flex justify-content-between align-items-center header">
                <div class="page-title">Update Due Date</div>
              <div>
                <button class="help-btn" (click)="help()">Help</button>
              </div>
              </div>
        </header>
        <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
            <span *ngFor="let message of errorList">{{message}}</span>
        </div>
        <div class="popup-contents">
            <div class="row m-3 align-items-start">
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Family Code</label>
                    <input type="text" id="familyCode" [(ngModel)]="familyCode" name="familyCode" readonly>
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Model Code</label>
                    <input type="text" id="modelCode" [(ngModel)]="modelCode" name="modelCode" readonly>
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5" for="plant">Plant</label>
                    <input type="text" id="plant" [(ngModel)]="plant" name="plant" readonly>
                </div>
            </div>
            <div class="row mx-3 mb-3 align-items-start">
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Supplier</label>
                    <input type="text" id="supplier" [(ngModel)]="supplier" name="supplier" readonly>
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Part number</label>
                    <input type="text" id="partNumber" [(ngModel)]="partNumber" name="partNumber" readonly>
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Pre PPF Due</label>
                    <input type="date" id="prePPFDue" [(ngModel)]="prePPFDue" name="prePPFDue" (keydown)="onKeyDown($event,'prePPFDue')">
                </div>
            </div>
            <div class="row mx-3 mb-3 align-items-start">
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Pkg Trial Due</label>
                    <input type="date" id="pkgTrialDue" [(ngModel)]="pkgTrialDue" name="pkgTrialDue" (keydown)="onKeyDown($event,'pkgTrialDue')">
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5" for="input2">Package Authorized Due</label>
                    <input type="date" id="packageAuthorisedDue" [(ngModel)]="packageAuthorisedDue"
                        name="packageAuthorisedDue" (keydown)="onKeyDown($event,'packageAuthorisedDue')">
                </div>
                <div class="col-4 d-flex">
                    <label class="catlabel col-5" for="input3">Readiness Due</label>
                    <input type="date" id="readinessDue" [(ngModel)]="readinessDue" name="readinessDue" (keydown)="onKeyDown($event,'readinessDue')">
                </div>
            </div>
            <div class="row mx-3 mb-3 align-items-start">
                <div class="col-4 d-flex">
                    <label class="catlabel col-5">Package Start Date</label>
                    <input type="date" id="packageStartDate" [(ngModel)]="packageStartDate" name="packageStartDate" (keydown)="onKeyDown($event,'packageStartDate')">
                </div>
                <div class="col-4 d-flex">
                </div>
                <div class="col-4 d-flex">
                </div>
            </div>


            <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
                <button type="button" class="cancel me-3 roboto-bold" (click)="close()"> CANCEL </button>
                <button type="submit" class="submit black-button me-3 roboto-bold">UPDATE</button>
            </div>
        </div>
    </form>
</div>