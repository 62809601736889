import { Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-spinner',
  template: '',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{

constructor(private readonly spinnerService: NgxUiLoaderService) { }

 showSpinner(){
    setTimeout(() => {
      this.spinnerService.start()
    });
 }

 hideSpinner(){
    setTimeout(() => {
      this.spinnerService.stop()
    });
 }
}
