import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class QuantityControlService {
  URL: string;
  filterdata: any;
  modelFilter: any;
  plantdata: any;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) {
    this.URL = this.constantsService.INTERNAL + 'quantityControl/'
  }

  loadQuantityData(body:any) : Observable<any>{
    return this.http.post(this.URL+`OnLoad`,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  detailData(body:any) : Observable<any>{
  
    return this.http.post(this.URL+`Detail/OnLoad`, body ,{headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  logData(body:any) : Observable<any>{
    return this.http.post(this.URL+`Log`,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  movePackageOnload(body:any):Observable<any>{
    return this.http.post(this.URL + `MovePackage/onLoad`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  disposePackageOnload(body:any):Observable<any>{
    return this.http.post(this.URL+`DisposePackage/onLoad` ,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  adjustPackageOnload(body:any):Observable<any>{
    return this.http.post(this.URL+`AdjustQuantity/onLoad` ,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  maintenancePackageOnload(body:any):Observable<any>{
    return this.http.post(this.URL+`MaintenancePackage/onLoad`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  //AUthorize
  movePackageAuthorize(body:any){
    return this.http.post(this.URL+`MovePackage/Authorize`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  disposeAuthorize(body:any){
    return this.http.post(this.URL+`DisposePackage/Authorize`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  adjustAuthorize(body:any){
    return this.http.post(this.URL+`AdjustQuantity/Authorize`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  maintenanceAuthorize(body:any){
    return this.http.post(this.URL+`MaintenancePackage/Authorize`,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  setfilter(filter:any){
    this.filterdata = filter;
  }

  getfilter() {
    return this.filterdata;
  }

  setmodelFilters(modelFilter: any) {
    this.modelFilter = modelFilter;
  }
  
  getmodelFilters() {
    return this.modelFilter;
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  public handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}

