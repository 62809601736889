import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PurDecListService {
  URL = this.constantsService.INTERNAL;
  plantdata: any;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }
  
  plant(obj: any): Observable<any> {
    return this.http.post(this.URL + 'common/getPlant', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }
  onloadPurDecisionList(loadObj:any): Observable<any> {
    return this.http.post((this.URL + 'authorize/puronload'), loadObj,
    { responseType: 'json', headers: this.constantsService.internalHttpOptions})
    .pipe(catchError(this.handleError))
  }
  getFabricators(authnumber:any) : Observable<any>{
    const URL = this.constantsService.INTERNAL + `authorize/getFabricators/${authnumber}`;
    return this.http.get(URL, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }
  updateFabricator(body:any):Observable<any>{
    const URL = this.constantsService.INTERNAL + 'authorize/updateFabricator';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }
  deleteFabricator(body:any):Observable<any>{
    const URL = this.constantsService.INTERNAL + 'authorize/deleteFabricator';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }
  doPurDecision(body:any):Observable<any>{
    const URL = this.constantsService.INTERNAL + 'authorize/doPurDecision';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }
  doPurBossDecision(body:any):Observable<any>{
    const URL = this.constantsService.INTERNAL + 'purBossDo';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  onloadAuthDetail() : Observable<any>{
    const URL = this.constantsService.INTERNAL + 'cost/onloadAuthDetail/PD130213-0068/21TMC';
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
 

  purDecListFilters:any;
  purDecGridFilters:any;
  setPurDecListFilters(data:any){
    this.purDecListFilters=data
  }

  getPurDecListFilters(){
    return this.purDecListFilters;
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  clearPutDecListFilters(){
    this.purDecListFilters='';
  }

  setPurDecGridFilters(data:any){
    this.purDecGridFilters = data
  }

  getPurDecGridFilters(){
    return this.purDecGridFilters
  }
}
export const PurDecdataComparator = (valueA:any, valueB:any) => {
  if (!valueA) { return -1};     
  if (!valueB) { return 1};     
  const numberA = Number(valueA);    
  const numberB = Number(valueB);     
  if (numberA === numberB) { return 0 };     
  return (numberA > numberB) ? 1 : -1;
};
export const dollarComparator = (valueA:any, valueB:any) => {
  if (!valueA) { return -1};     
  if (!valueB) { return 1};     
  const numberA = Number(valueA.replace('$',''));    
  const numberB = Number(valueB.replace('$',''));     
  if (numberA === numberB) { return 0 };     
  return (numberA > numberB) ? 1 : -1;
};