import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'app-ringi-fix-btn-cell-render',
  template: `
    <button 
    class="vpacs-btn-table"
    style="width:80px; height:30px; color:#676a6e; font-size: 13px" [class.red-image] = 'params.data.ringiver === "1"'
    (click)="btnClickedHandler($event)"  [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'">{{params.data.ringiver === '0' ? 'RINGI FIX' : 'FIX CANCEL' }}</button>
  `,
  styles: ['.red-image { background-image: linear-gradient(rgb(251 3 3) 0%, rgb(254 0 0) 100%); color: white !important;}']
})

export class RingiFixBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute,private readonly state: ExportStateService ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  params: any;
  disableFlag = false;
  dullFlag = false;
  userDelete: any;
  userUpdate: any;
  agInit(params: any): void {
    this.params = params;  
    this.getUserData();  
  }
  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.userDelete = data.role.p_costdelete;
        this.userUpdate = data.role.p_costsave
          if (!this.userUpdate)  {
            this.disableFlag = true;
            this.dullFlag = true;
          }
          else{
            this.disableFlag = false;
            this.dullFlag = false;

          }
      }
    })
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




