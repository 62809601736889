import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'rack-btn-cell-renderer',
  template: `
  <div class="d-flex gap-1">
  <input  style="width:50px;height:30px" [disabled] = "true" [value]="params.value ?? '' "  type="text"  id="input" />
  <button 
class="vpacs-btn-table"
style="height:30px;"    [disabled] = "true"  
(click)="btnClickedHandler($event)">R</button>
</div>
  `,
})

export class ShipmentRackCellRenderer implements ICellRendererAngularComp {

  params: any;
  bufferDays = '';

  agInit(params: any): void {
    this.params = params;
  }

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  buttonName: any;
  btnClickedHandler(_$event: any) {
    if (this.params.colDef.headerName === 'PLANQTY') {
      this.router.navigate([`/shipment-list/rackShipment/${this.params.data.authnumber}/${this.params.shipmentNumber}`]);
    }
    if (this.params.colDef.headerName === 'ACTUAL RECIEVEQTY') {
      this.router.navigate([`/shipment-list/rackReceivement/${this.params.data.authnumber}/${this.params.shipmentNumber}`]);
    }
    if (this.params.colDef.headerName === 'ACTUAL SHIPQTY') {
      this.router.navigate([`/shipment-list/rackShipment/${this.params.data.authnumber}/${this.params.shipmentNumber}`]);
    }
  }
}
