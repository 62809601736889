import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DoPkgAuthorizationService } from 'src/app/services/do-pkg-authorization.service';
import {columnDefs1} from './delivery-target-constants'
@Component({
  selector: 'app-delivery-target',
  templateUrl: './delivery-target.component.html',
  styleUrls: ['./delivery-target.component.scss']
})

export class DeliveryTargetComponent implements OnInit {
  private readonly amountOfWeeks = 16
  public rowData: any;
  gridApi: any;
  columnDefs1: any[] = [...columnDefs1];
  columnDefs2: any[] = [];
  displayRowData: any[] = [];
  displayRowData1: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any
  icons: {};
  isAccessible = false;
  public domLayout: any = ''
  public cellStyle = {
    'box-shadow': 'inset 0px 1px 6px #00000029',
    'border':'1px solid #D7DCE1',
    'color': '#676A6E',
}
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  dopkgData: any;
  deliveryDo: any;
  private deliveryTargetDate: any;
  deliveryTarget: any;
  acc: any
  authContainer: any;
  rowStyle:any={}
  sum: any
  
  constructor(public dialogRef: MatDialogRef<DeliveryTargetComponent>, @Inject(MAT_DIALOG_DATA) public dialog: MatDialog, public datepipe: DatePipe, private readonly doPkgService: DoPkgAuthorizationService, public router: Router) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      wrapHeaderText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
      singleClickEdit: true
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit() {
    if(sessionStorage.getItem('queryParamsDelv')){
      this.dopkgData = JSON.parse(sessionStorage.getItem('queryParamsDelv') || '');
    }
    this.doPkgService.deliveryTargetOnload(this.dopkgData).subscribe((res: any) => {
      this.deliveryDo = res
      this.deliveryTargetDate = this.deliveryDo.deliveryDate
      this.authContainer = res?.authorized[0]
      this.displayRowData = [{
        "name": "Authorized", "container": res?.authorized[0], "dunnage1": res.authorized[1], "dunnage2": res.authorized[2],
        "topCap": res.authorized[3], "pallet": res.authorized[4], "kanbanHolder": res.authorized[5], "label": res.authorized[6],
        "other": res.authorized[7]
      },{
        "name": "Shipped", "container": res.shipped[0], "dunnage1": res.shipped[1], "dunnage2": res.shipped[2], "topCap": res.shipped[3], "pallet": res.shipped[4],
        "kanbanHolder": res.shipped[5], "label": res.shipped[6], "other": res.shipped[7]
      },{
        "name": "Received", "container": res.received[0], "dunnage1": res.received[1], "dunnage2": res.received[2], "topCap": res.received[3], "pallet": res.received[4],
        "kanbanHolder": res.received[5], "label": res.received[6], "other": res.received[7]
      },{
        "name": "Remaining", "container": res.remaining[0], "dunnage1": res.remaining[1], "dunnage2": res.remaining[2], "topCap": res.remaining[3], "pallet": res.remaining[4],
        "kanbanHolder": res.remaining[5], "label": res.remaining[6], "other": res.remaining[7]
      }];


      this.displayRowData1 = [{
        "name": "Shipment", "flag": true, "target": "Target", "row1": res.target[0].target, "row2": res.target[1].target, "row3": res.target[2].target, "row4": res.target[3].target, "row5": res.target[4].target,
        "row6": res.target[5].target, "row7": res.target[6].target, "row8": res.target[7].target, "row9": res.target[8].target, "row10": res.target[9].target, "row11": res.target[10].target, "row12": res.target[11].target, "row13": res.target[12].target, "row14": res.target[13].target, "row15": res.target[14].target,
        "row16": res.target[15].target, "deliveryDo" : this.deliveryDo?.deliveryDate
      },{
        "name": " ", "flag": false, "target": "Plan", "row1": res.planAndActual[0].shipment_plan, "row2": res.planAndActual[1].shipment_plan, "row3": res.planAndActual[2].shipment_plan, "row4": res.planAndActual[3].shipment_plan, "row5": res.planAndActual[4].shipment_plan,
        "row6": res.planAndActual[5].shipment_plan, "row7": res.planAndActual[6].shipment_plan, "row8": res.planAndActual[7].shipment_plan, "row9": res.planAndActual[8].shipment_plan, "row10": res.planAndActual[9].shipment_plan, "row11": res.planAndActual[10].shipment_plan, "row12": res.planAndActual[11].shipment_plan, "row13": res.planAndActual[12].shipment_plan, "row14": res.planAndActual[13].shipment_plan, "row15": res.planAndActual[14].shipment_plan,
        "row16": res.planAndActual[15].shipment_plan, "deliveryDo" : this.deliveryDo?.deliveryDate
      },{
        "name": "", "flag": false, "target": "Actual", "row1": res.planAndActual[0].shipment_actual, "row2": res.planAndActual[1].shipment_actual, "row3": res.planAndActual[2].shipment_actual, "row4": res.planAndActual[3].shipment_actual, "row5": res.planAndActual[4].shipment_actual,
        "row6": res.planAndActual[5].shipment_actual, "row7": res.planAndActual[6].shipment_actual, "row8": res.planAndActual[7].shipment_actual, "row9": res.planAndActual[8].shipment_actual, "row10": res.planAndActual[9].shipment_actual, "row11": res.planAndActual[10].shipment_actual, "row12": res.planAndActual[11].shipment_actual, "row13": res.planAndActual[12].shipment_actual, "row14": res.planAndActual[13].shipment_actual, "row15": res.planAndActual[14].shipment_actual,
        "row16": res.planAndActual[15].shipment_actual, "deliveryDo" : this.deliveryDo?.deliveryDate
      },{
        "name": "Receivement", "flag": false, "target": "Plan", "row1": res.planAndActual[0].receivement_plan, "row2": res.planAndActual[1].receivement_plan, "row3": res.planAndActual[2].receivement_plan, "row4": res.planAndActual[3].receivement_plan, "row5": res.planAndActual[4].receivement_plan,
        "row6": res.planAndActual[5].receivement_plan, "row7": res.planAndActual[6].receivement_plan, "row8": res.planAndActual[7].receivement_plan, "row9": res.planAndActual[8].receivement_plan, "row10": res.planAndActual[9].receivement_plan, "row11": res.planAndActual[10].receivement_plan, "row12": res.planAndActual[11].receivement_plan, "row13": res.planAndActual[12].receivement_plan, "row14": res.planAndActual[13].receivement_plan, "row15": res.planAndActual[14].receivement_plan,
        "row16": res.planAndActual[15].receivement_plan, "deliveryDo" : this.deliveryDo?.deliveryDate
      },{
        "name": "", "flag": false, "target": "Actual", "row1": res.planAndActual[0].receivement_actual, "row2": res.planAndActual[1].receivement_actual, "row3": res.planAndActual[2].receivement_actual, "row4": res.planAndActual[3].receivement_actual, "row5": res.planAndActual[4].receivement_actual,
        "row6": res.planAndActual[5].receivement_actual, "row7": res.planAndActual[6].receivement_actual, "row8": res.planAndActual[7].receivement_actual, "row9": res.planAndActual[8].receivement_actual, "row10": res.planAndActual[9].receivement_actual, "row11": res.planAndActual[10].receivement_actual, "row12": res.planAndActual[11].receivement_actual, "row13": res.planAndActual[12].receivement_actual, "row14": res.planAndActual[13].receivement_actual, "row15": res.planAndActual[14].receivement_actual,
        "row16": res.planAndActual[15].receivement_actual, "deliveryDo" : this.deliveryDo?.deliveryDate
      }];

      this.columnDefs2 = [{
        headerName: "Schedule", field: "name", width: 100, pinned: 'left'
      },
      {
        headerName: "", field: "target", width: 80, pinned: 'left'
      }].concat([...this.generateHeaderDates(),{
        headerName: "Total", field: "total", autoHeight: true, valueGetter: this.getTotal.bind(this),pinned: 'right', width:100,
      }])
    });

    this.gridOptions={
      onCellKeyDown:(params:any)=>{
        this.onCellValueChanged(params)
    },
    stopEditingWhenCellsLoseFocus:true,
  }
  }

  generateHeaderDates():any{
    return Array.from({ length: this.amountOfWeeks }, (_, index:number) => index).map((_x:any, idx:number)=>{
      return {headerName: this.getHeaderName(idx+1,true), field: `row${idx+1}`, autoHeight: true,cellStyle:this.getCellClass,editable : this.checkEditable,
      width: 100}  
    })
  }

  getCellClass(params:any){
    const style =  {
      'box-shadow': 'inset 0px 1px 6px #00000029',
      'border':'1px solid #D7DCE1',
      'color': '#676A6E',
      'height': '30px',
      'width': '80px',
      'margin-left': '5px',
    }

    const headerDate = new Date(params.data?.deliveryDo);
    const day = headerDate.getDay();
    const diff = headerDate.getDate() - day + (day === 0 ? -6:1);
    const match =  new Date(headerDate.setDate(diff));
    if(match.toLocaleDateString() === new Date(params.colDef.headerName).toLocaleDateString()){
      return   {
        'background-color' : '#FFFF00'
      }
    }else{
      return params.rowIndex === 0 ? style : ''
    }
  }

  checkEditable(params:any){            
    const focusedCell =  params.api.getFocusedCell();
    return focusedCell.rowIndex === 0 ? true : false;
  }

  onCellValueChanged(params: any) {
    if (params.node.rowIndex === 0) {
      const colId = params.column.getColId();
      if (colId.startsWith('row')) {
        const row = params.data
        this.sum = Object.keys(row).reduce((acc, key) => {
          if (key.startsWith('row')) {
            return acc + Number(row[key]);
          }
          return acc
        }, 0)
        row.total = Number(this.sum)
        params.api.refreshCells({ rowNodes: [params.node], columns: ['total'] })
      }
    }
  }

  getTotal(params: any) {
    const row = params.data
    if (params.node.rowIndex === 0) {
      this.sum = Object.keys(row).reduce((acc, key) => {
        if (key.startsWith('row')) {
          return acc + Number(row[key]);
        }
        return acc
      }, 0)
      return this.sum
    } else {
      return 0
    }
  }

  public getHeaderName(index: number, addYear:any): string {
    const targetDate = new Date(this.deliveryTargetDate);
    const targetDay = targetDate.getDay();
    const daysToMon = (targetDay + 6) % 7;
    targetDate.setDate(targetDate.getDate() - daysToMon - (12 - index) * 7);
    if (addYear) {
      return targetDate.toLocaleDateString()
    }
    return targetDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit'})
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }
  closeDialog(){
    this.dialogRef.close()
  }
  saveClick() {
    const targetValues: any = []
    for (let i = 0; i < this.amountOfWeeks; i++) {
      targetValues?.push({ targetDate: this.datepipe.transform(this.getHeaderName(i, true), 'yyyy-MM-dd'), targetqty: Number(this.displayRowData1[0][`row${i + 1}`]) })
    }
    if (this.authContainer !== this.sum) {    
      alert('[Target Total] must be equal to [Authorized Container Qty].')
    } else {     
      const updateObject = {
        authNumber: this.dopkgData.authNumber,
        targetValues: targetValues
      }
      this.doPkgService.saveClick(updateObject).subscribe({
        next: () =>{
          this.dialogRef.close('success')
        }
      })
    }
  } 
}
