import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[disableCharacter]'
})
export class DisableCharacters {

  constructor(private readonly el:ElementRef) { }
  
  @HostListener('keydown',['$event'])
  onKeyDown(event:KeyboardEvent){
    const data=/[^a-zA-Z0-9]{1,5}$/
    if(data.test(event.key)){
      event.preventDefault();
    }
  }

}
