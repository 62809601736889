import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DlstatusComponent } from './dlstatus/dlstatus.component';
import { TrackingService } from 'src/app/services/tracking.service';
import { TrackingBackupBtnCellRender } from 'src/app/core/gridrender/tracking-backup-btn-cell-render';
import { TrackingActionBtnCellRender } from 'src/app/core/gridrender/tracking-action-btn-cell-render';
import { Router } from '@angular/router';
import { DocumentDownload } from 'src/app/core/gridrender/document-download';
import { SpinnerComponent } from 'src/app/core/spinner/spinner.component';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [SpinnerComponent, RegexFilter],
})
export class TrackingComponent implements OnInit {
  protected allTrackingData = 0;
  protected totalRowCount = 0;
  public step: any[] = [];
  public rowData: any[] = [];
  tenYearsBack = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
  today = new Date();
  public searchParameters: any = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    partnum: new FormControl(''),
    model: new FormControl(''),
    step: new FormControl(''),
    supplier: new FormControl(),
    from: new FormControl(''),
    check: new FormControl('Do'),
  });
  public isFetching = true;
  public stepNumber = 0;
  public supplierNumber: any;

  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  displayRowData: any[] = [];
  rowClassRules: any;
  totalPage = 0;
  currentPageForTotalPage = 0;
  isLastPageFound!: boolean;
  rowCount!: number;
  defaultColDef: {};
  icons: {};
  rowHeight = 40;
  paginationPageSize = 20;
  public domLayout = '';
  public overlayLoadingTemplate = `<div class="spinner-border text-danger" role="status"></div>`;

  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  supplierArrayIndex: any;
  validUser: any;
  toyotaGUID: any;
  roles: any[] = [];
  adminFlag = false;
  loading = true;
  asyncLoading = true;
  pkgStartDate:any;
  pkgChangeFlag = false;
  apiFilters = false;
  columnApi: any;
    constructor(
    private readonly dialog: MatDialog,
    private readonly trackingService: TrackingService,
    public router: Router,
    public regex: RegexFilter,
    public globalState: GlobalStateService,
    public state: ExportStateService,
    public constants: ConstantsService
  ) {
    this.defaultColDef = {
      suppressMovable: false,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: false,
      wrapText: true,
      enableBrowserTooltips: true,
      filterParams: { newRowsAction: 'keep' },
      flex : 1 //occupy entire width in page
    };

    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-pink': 'data.VALIDFLAG === "2" || data.TRIALCANCEL === "2"',
      'row-green': 'data.ISUPDATEPPF === 2 || data.P_COUNT === "2"',
      'row-bold': this.setBold,
    };
    const savedFilters = this.globalState.getTrackingFilters();
    if(savedFilters){
      this.searchParameters.patchValue({
        fromDate: savedFilters.fromDate,
        toDate: savedFilters.toDate,
        partnum: savedFilters.partnum,
        model: savedFilters.model,
        step: savedFilters.step,
        supplier: savedFilters.supplier,
        from: savedFilters.from,
        check: savedFilters.check,
      })
    }

  }

  ngOnInit(): void {
    this.trackingService.loadSteps().subscribe((res: any) => {
      this.globalState.setTrackingSteps(res);
      this.step = res;
    });

    this.state.roleState.subscribe({
      next: (res: any) => {
        if (res) {
          this.roles = res;
          if (this.constants.allRoles.includes(this.roles[0])) {
            if (!this.constants.adminSystemRoles.includes(this.roles[0])) {
              this.getSupplierCodes();
            } else {
              this.adminFlag = true;
              this.performInitialOperation();
            }
          } else {
            this.router.navigate(['/'])
          }
        }
      },
    });

    this.searchParameters.controls['fromDate'].valueChanges.subscribe(() => {
      this.updateToDateValidators();
    });
  }

  updateToDateValidators() {
    const fromDate = this.searchParameters.controls['fromDate'].value;
    const toDateControl = this.searchParameters.controls['toDate'];
 
    if (fromDate) {
      toDateControl.setValidators([this.minDateValidator(fromDate)]);
    } else {
      toDateControl.setValidators([]);
    }
    toDateControl.updateValueAndValidity();
  }
 
  minDateValidator(minDate: string) {
    return (control: any) => {
      const selectedDate = new Date(control.value);
      if (!control.value || selectedDate >= new Date(minDate)) {
        return null;
      }
      return { minDate: { valid: false } };
    };
  }

  getSupplierCodes() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          const data = this.state.setSupplierCode(res.supplierCode);
          this.globalState.setSupplierCode(data);
          this.toyotaGUID = res.info[0].TOYOTAGUID;
          if (data.length > 0) {
            this.performInitialOperation(data);
          }
        }
      },
    });
  }

  performInitialOperation(inputData?: any[]) {
    if (localStorage.getItem('tracking')) {
      this.supplierNumber = JSON.parse(localStorage.getItem('tracking') || '');
    } else {
      this.supplierNumber = inputData;
      this.isFetching = false;
    }
    this.globalState.setSupplierCode(this.supplierNumber);
    this.searchParameters.patchValue({ supplier: this.supplierNumber })
    this.getColumnDefs();
    this.globalState.ubModel = '';
    
    const savedFilters = this.globalState.getTrackingFilters();
    if (!savedFilters) {
      if (this.supplierNumber.length > 1) {
        this.defaultSearchFilter('ALL');
      } else {
        this.defaultSearchFilter(0);
      }
      this.apiCall(this.supplierNumber);
    }else{
      this.supplierArrayIndex = savedFilters.supplier;
      this.defaultSearchFilter2(savedFilters);
      this.onSearch('supchange')
    }
  }
  setPkgStartDate(event:any){
    this.pkgStartDate = event;
    this.pkgChangeFlag = true;
  }

  apiFiltersChange(_event:any,_type:string){
    if(['partnumber','model'].includes(_type)){
      if([null,undefined,''].includes(_event.target.value)){
        this.searchParameters.patchValue({
          fromDate : this.tenYearsBack.toISOString().split('T')[0],
        });
      }else{
        this.searchParameters.patchValue({
          fromDate : '',
        });
      }
    }
    this.updateToDateValidators();
    this.apiFilters = true;
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Date',
        field: 'gridDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        maxWidth: 100,
        // valueFormatter: this.dateFormatter,
      },
      {
        headerName: 'Progress',
        field: 'status',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        maxWidth: 100,
        cellStyle: this.cellStyle,
        valueGetter: this.dateDifference,
      },
      {
        headerName: 'Status',
        field: 'progress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
        maxWidth: 130,
      },
      {
        headerName: 'From',
        field: 'SPECIALISTCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
        maxWidth: 110,
        filterParams: { newRowsAction: 'keep' },
      },
      {
        headerName: 'Plant',
        field: 'PLANTNAME',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: 'Supplier',
        field: 'SUPPLIERCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        maxWidth: 100,
      },
      {
        headerName: 'Container Fabricator',
        field: 'FABCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
        maxWidth: 230,
      },
      {
        headerName: 'Dunnage1 Fabricator',
        field: 'DUN1FABCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
        maxWidth: 230,
      },
      {
        headerName: 'Family',
        field: 'FAMILYCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        maxWidth: 100,
      },
      {
        headerName: 'Model',
        field: 'MODELCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: 'Part Number',
        field: 'PARTNUMBER',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 150,
        maxWidth: 200,
        cellRendererFramework: DocumentDownload,
      },
      {
        headerName: 'Part Name',
        field: 'DESCRIPTION',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        tooltipValueGetter: (params: any) => params.value,
        sortable: true,
        unSortIcon: true,
        minWidth: 200,
        cellStyle: this.cellStyleColumn,
      },
      {
        headerName: 'Backup',
        field: 'backup',
        minWidth: 100,
        maxWidth: 110,
        cellRendererFramework: TrackingBackupBtnCellRender,
        cellStyle: this.cellStyleActions,
      },
      {
        headerName: 'Action',
        field: 'action',
        minWidth: 100,
        maxWidth: 110,
        cellRendererFramework: TrackingActionBtnCellRender,
        cellStyle: this.cellStyleActions,
      },
    ];
  }

  filterChanged(_event: any) {
    const filter = this.gridApi?.getFilterModel();
    this.globalState.trackingPage(filter);
    this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
    this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
    this.rowCount = this.gridApi.getDisplayedRowCount();
  }

  sortChanged(_event:any){
    this.globalState.setTrackingSort(this.columnApi.getColumnState());
  }

  valueChange(_event: any) {
    this.supplierArrayIndex = _event;
    this.onSearch('supchange');
  }

  dateConverter(input:any){
    if(![null,undefined,'','null','undefined'].includes(input)){
      return new Date(input).toISOString().slice(0,10).replace(/-/g, '/');
    }
    return null
  }

  cleanString(input:string) {
    function isSpecialChar(char:any) {
        return /[^a-zA-Z0-9]/.test(char);
    }
    const firstSpecialCharIndex = input.split('').findIndex(isSpecialChar);
    if (firstSpecialCharIndex !== -1) {
        input = input.substring(0, firstSpecialCharIndex);
    }
    const cleaned = input.replace(/[^a-zA-Z0-9]/g, '');
    return cleaned.substring(0, 10);
  }

  async apiCall(suppCodes: any[]) {
    this.displayRowData = [];
    this.rowData = [];
    this.rowCount = 0;
    this.loading = true;
    this.gridApi?.showLoadingOverlay();
    let loopCount = suppCodes.length;
    try {
      if (this.supplierNumber && this.supplierNumber?.length <= 1) {
        this.searchParameters.controls.supplier.disable();
      }else{
        this.globalState.trackingRowData = {};
      }
      for (const code of suppCodes) {
        if (this.globalState.getTrackingRowData(code) && !this.globalState.getTrackingSubmitted()) {
          setTimeout(() => {
            let updateRecord: any;
            if (sessionStorage.getItem('doPpfSaved')) {
              updateRecord = JSON.parse(sessionStorage.getItem('doPpfSaved') || '');
              updateRecord.rec.data[0] = this.getProcessedElement(updateRecord.rec.data[0], updateRecord.rec.supOrFab);
              const index = this.globalState.getTrackingRowData(code).findIndex((d: { PPFCODE: any; }) => d.PPFCODE === updateRecord.rec.data[0].PPFCODE);
              this.globalState.getTrackingRowData(code)[index] = updateRecord.rec.data[0];
              sessionStorage.removeItem('doPpfSaved');
            }
            this.displayRowData = this.globalState.getTrackingRowData(code);
            this.rowData = [...this.displayRowData];
            this.rowCount = this.displayRowData.length;
            this.asyncLoading = false;
            this.loading = false;
            this.onSearch('ui');
          }, 500);
        } else {
          this.globalState.trackingRowData = {};
          this.globalState.setTrackingFilters(this.searchParameters.value);
          this.globalState.setTrackingSubmitted(false);
          let { count, supOrFab } = await this.trackingService
            .getTrackingCount(
              code,
              this.dateConverter(this.searchParameters.controls.fromDate.value),
              this.dateConverter(this.searchParameters.controls.toDate.value),
              this.searchParameters.controls.check.value === 'Do' ? 1 : 0,
              this.cleanString(this.searchParameters.controls.partnum.value),
              this.cleanString(this.searchParameters.controls.model.value)
              )
            .toPromise();

            sessionStorage.setItem('checkSupFab', supOrFab);
          this.allTrackingData += count;
          const lambdaSize = 3800;
          let rowRangeMin = 0;
          if(count === 0){
            this.gridApi.hideOverlay();
            this.asyncLoading = false;
            this.loading = false;
            this.displayRowData = [];
          }
          while (count > 0) {
            try {
              this.asyncLoading = true;
              this.loading = true;
              this.gridApi?.showLoadingOverlay();
              const res: any = await this.trackingService
                .loadTrackingData(
                  code, 
                  lambdaSize, 
                  rowRangeMin,
                  this.dateConverter(this.searchParameters.controls.fromDate.value),
                  this.dateConverter(this.searchParameters.controls.toDate.value),
                  this.searchParameters.controls.check.value === 'Do' ? 1 : 0,
                  this.cleanString(this.searchParameters.controls.partnum.value),
                  this.cleanString(this.searchParameters.controls.model.value)
                  )
                .toPromise();
              const results = res.map((element: any) => {
                return this.getProcessedElement(element, supOrFab);
              });
              this.totalRowCount += results.length;
              this.rowData = [...this.rowData, ...results];
              this.rowCount = this.displayRowData.length;
              this.loading = false;
              this.gridApi?.showLoadingOverlay();
            } catch (err) {
              this.displayRowData = [];
              this.rowData = [];
              this.rowCount = 0;
              this.totalRowCount = 0;
              this.allTrackingData = 0;
              this.gridApi?.hideOverlay();
              this.loading = false;
              this.asyncLoading = false;
            }
            rowRangeMin += lambdaSize;
            count -= lambdaSize;
          }
          this.globalState.setTrackingRowData(this.rowData, code);
          loopCount--;
        }
        this.globalState.setTrackingFilters(this.searchParameters.value)
      }
      if (loopCount <= 0) {
        this.asyncLoading = false;
      }
    } catch (error) {
      this.asyncLoading = false;
      this.loading = false;
    }
    const data = this.globalState.getTrackingFilters();
    const filteredData = this.regex.checkValueEqualsStar(data);
    if (filteredData) {
      this.defaultSearchFilter2(filteredData);
      if (this.rowData.length > 0) {
        this.onSearch('ui');
      }
    } else {
      this.displayRowData = this.rowData;
      this.rowCount = this.displayRowData.length;
    }
    this.apiFilters = false;
  }

  getProcessedElement(element: any, supOrFab: any) {

    const value = this.getProgressDescription(element);
    let value2: any;
    if (this.adminFlag) {
      value2 = {
        AUTHREAD: 1,
        AUTHWRITE: 1,
        AUTHSEND: 1,
      };
    } else {
      value2 = this.getAuthDetails(element, supOrFab);
    }
    const modifiedElement = {
      loggedIn : this.supplierNumber,
      ...element,
      ...value,
      ...value2,
      supOrFab: supOrFab,
      getSupOrFab: supOrFab,
      status: '',
    };
    const modifiedElementRequest = {
      ...modifiedElement,
      whichRequest: this.setWhichRequest(modifiedElement),
    };
    const button = this.buttonLogic(modifiedElementRequest);
    return { ...modifiedElementRequest, ...button };

  }

  getAuthDetails(params: any, supOrFab: any) {
    let found: any = null;
    let result: any = {};
    let sup: any = '';
    if (supOrFab === 0) {
      sup = params.SUPPLIERCODE;
    }
    else {
      if (params.WORKING === 8) {
        sup = params.DUN1FABSUP;
      }
      else {
        sup = params.FABSUP;
      }
    }
    if (this.globalState.getSecurityInfo()) {
      const data = this.globalState.getSecurityInfo();
      for (const iterator of data) {
        if (
          iterator.PLANTCODE === params.PLANTCODE &&
          iterator.SUPPLIERCODE5 === sup
        ) {
          found = iterator;
        }
      }
    }
    if (!found) {
      result = {
        AUTHSEND: 0,
        AUTHWRITE: 0,
        AUTHREAD: 0,
      };
    } else {
      result = {
        AUTHSEND: found.AUTHSEND,
        AUTHWRITE: found.AUTHWRITE,
        AUTHREAD: found.AUTHREAD,
      };
    }
    return result;
  }

  cellStyle(params: any) {
    if (params.value === 'Delay') {
      return {
        'background-color': '#FF9966',
        color: '#FFFFFF',
        'border-radius': '10px',
        height: '30px',
      };
    } else if (params.value === 'Warn') {
      return {
        'background-color': '#ffff66',
        color: '#FFFFFF',
        'border-radius': '10px',
        height: '30px',
      };
    } else {
      return {
        'background-color': '#66ccff',
        color: '#FFFFFF',
        'border-radius': '10px',
        height: '30px',
      };
    }
  }

  //set Bold Row
  setBold(params: any) {
    const data = params.data;
    let bold = false;
    if (
      (data.statusNumber === 1 ||
        data.statusNumber === 8 ||
        data.statusNumber === 12) &&
      data.supOrToyota === 'S'
    ) {
      if (data.WORKING === 3) {
        bold = true;
      } else if (data.supOrFab === 0) {
        if (data.stepNumber === 6) {
          if (data.whichRequest === 0 || data.statusNumber === 8) {
            bold = true;
          }
        } else {
          if (
            data.statusNumber === 8 ||
            data.statusNumber === 12 ||
            data.WORKING === 6 ||
            data.WORKING === 0
          ) {
            bold = true;
          }
        }
      } else if (data.stepNumber === 6 && data.whichRequest === 1) {
        bold = true;
      } else {
        if (
          (data.WORKING === 2 && data.statusNumber === 1) ||
          (data.WORKING === 8 && data.statusNumber === 1)
        ) {
          bold = true;
        }
      }
    }
    return bold;
  }
  //End of setting Bold Row

  //setting which_request
  checkWhichRequest(params: any, whichRequest: any) {
    if (
      params.SUPPLIERCODE === params.REQUEST_CC ||
      params.SUPPLIERCODE === params.REQUEST_TO
    ) {
      if (params.SUPPLIERCODE === params.REQUEST_TO) {
        whichRequest = 0;
      } else {
        whichRequest = 1;
      }
    } else if (
      params.statusNumber === 8 ||
      params.statusNumber === 9 ||
      params.statusNumber === 10 ||
      params.statusNumber === 11
    ) {
      whichRequest = 1;
    } else if (params.FABCODE === params.REQUEST_TO || params.DUN1FABCODE === params.REQUEST_TO){
      whichRequest = 1;
    }
    return whichRequest;
  }

  setWhichRequest(params: any) {
    let whichRequest = 0;
    if (params.stepNumber === 6 || params.stepNumber === 11 || params.stepNumber === 12) {
      if (params.getSupOrFab === 0) {
        whichRequest = this.checkWhichRequest(params, whichRequest);
      } else {
        if (params.WORKING === 8 || [11, 12].includes(params.stepNumber)) {
          if (params.DUN1FABCODE === params.REQUEST_CC || params.DUN1FABCODE === params.REQUEST_TO) {
            whichRequest = 1;
          }
          if (params.FABCODE === params.REQUEST_CC || params.FABCODE === params.REQUEST_TO) {
            whichRequest = 1;
          }
        } else {
          if (params.FABCODE === params.REQUEST_CC || params.FABCODE === params.REQUEST_TO) {
            whichRequest = 1;
          }
        }
      }
    }
    return whichRequest;
  }
  //End of setting which_request

  buttonLogic(params: any) {
    let submitButton, ppfButton, doButton, useBackupButton, backUpDefineButton;
    let buttons: any = { doFlag: null };
    let outputbutton = false;
    let outputdo = false;
    let outputbackup = false;
    let trackingbutton = false;
    let updateppfbutton = false;
    let disableBackup = false;
    let {
      WORKING,
      supOrFab,
      supOrToyota,
      stepNumber,
      statusNumber,
      whichRequest,
      AUTHSEND,
      P_COUNT,
      FABSUP,
      DUN1FABSUP,
      SUPPLIERCODE,
      FABCODE, REQUEST_TO,DUN1FABCODE
    } = params;
    statusNumber = statusNumber?.toString();
    if (WORKING === 3) {
      trackingbutton = true;
    } else if (supOrFab === 0 && (WORKING === 6 || WORKING === 0)) {
      trackingbutton = true;
    } else if (
      (supOrFab === 1 && WORKING === 2) ||
      (supOrFab === 1 && WORKING === 8)
    ) {
      if (['8', '9', '10', '11', '12', '13'].includes(statusNumber)) {
        // n.o.p
      } else {
        trackingbutton = true;
      }
    } else if (supOrFab === 0 && (WORKING === 2 || WORKING === 8)) {
      if (['8', '9', '10', '11', '12', '13'].includes(String(statusNumber))) {
        trackingbutton = true;
      }
    }

    if (supOrFab === 0) {
      outputbackup = true;
    }

    if (trackingbutton) {
      outputbutton = true;
      outputdo = true;
      updateppfbutton = true;
    }

    if ([6, 11, 12].includes(stepNumber)) {
      outputdo = true;
      if (supOrFab === 0 && whichRequest === 0) {
        outputbutton = true;
      } else if (supOrFab === 1 && whichRequest === 1) {
        outputbutton = true;
      } else {
        outputbutton = false;
      }
      if (
        stepNumber === 6 &&
        supOrFab === 0 &&
        (statusNumber === '10' || statusNumber === '11')
      ) {
        outputbutton = true;
      }
    }

    if (outputbackup) {
      backUpDefineButton = true;
      if ([10, 11, 12, 13].includes(stepNumber)) {
        useBackupButton = true;
        if (P_COUNT !== "1") {
          disableBackup = true;
        }
      }
    }

    if (outputdo) {
      if ( stepNumber === 6 && supOrFab === 1 && ['8', '9', '10', '11'].includes(statusNumber)) {
        doButton = false;
      } else  if (supOrFab === 1 && supOrToyota === 'S' && this.supplierNumber.includes(SUPPLIERCODE)) {
        doButton = true;// fab
      }else  if (supOrFab === 0 && supOrToyota === 'S' && this.supplierNumber.includes(SUPPLIERCODE.substring(0,4))) {
        doButton = true;// supplier
      }else if (this.state.checkExternalAccess(this.roles[0]) && supOrToyota === 'S' && this.supplierNumber.includes(SUPPLIERCODE)){
        doButton = true// comes from admin access
      }else if ((WORKING === 2 && this.supplierNumber.includes(FABSUP) && supOrToyota === 'S')|| ([11,12].includes(stepNumber) && FABCODE === REQUEST_TO && this.supplierNumber.includes(FABSUP))) {
        doButton = true;
      } else if ((WORKING === 8 && this.supplierNumber.includes(DUN1FABSUP) && supOrToyota === 'S') || (([11,12].includes(stepNumber) && DUN1FABCODE === REQUEST_TO && this.supplierNumber.includes(DUN1FABSUP)))) {
        doButton = true;
      }
    }

    if (outputbutton) {
      if (
        stepNumber === 6 &&
        supOrFab === 1 &&
        (statusNumber === '8' ||
          statusNumber === '9' ||
          statusNumber === '10' ||
          statusNumber === '11')
      ) {
        // n.o.p
      } else {
        if (
          supOrToyota === 'S' &&
          AUTHSEND &&
          (statusNumber === '7' || statusNumber === '10' || statusNumber === '11')
        ) {
          submitButton = true;
        }
      }
    }

    if (updateppfbutton) {
      if (
        AUTHSEND &&
        (stepNumber === 10 ||
          stepNumber === 11 ||
          stepNumber === 12 ||
          stepNumber === 13)
      ) {
        ppfButton = true;
      }
    }
    buttons = {
      trackingFlag: trackingbutton,
      doFlag: doButton,
      backupFlag: useBackupButton,
      backupDefineFlag: backUpDefineButton,
      submitFlag: submitButton,
      updatePPFFlag: ppfButton,
      disableBackup: disableBackup,
    };
    return buttons;
  }

  dateFormatter(params: any) {
    return (![null,'',undefined,'undefined','null'].includes(params.value)) ? new Date(params.value).toLocaleDateString() : '';
  }

  dateDifference(params: any) {
    const date = new Date(params.data.TODAY);
    const date2 = new Date(params.data.dueDate);
    const dateDifference =
      (date2.getTime() - date.getTime()) / (1000 * 3600 * 24);
    if (dateDifference < 0) {
      return 'Delay';
    } else if (dateDifference >= 0 && dateDifference <= 5) {
      return 'Warn';
    } else {
      return 'Good';
    }
  }

  getProgressDescription(params: any) {
    let progress: string,
      stepNumber: number,
      statusNumber: string,
      supOrToyota: string,
      dueDate: string,
      value: any = {},
      gridDate: any;
    if (params?.STEP01STATUS !== 4 && params?.STEP01STATUS !== 5) {
      progress = 'Initial';
      stepNumber = 1;
      statusNumber = params?.STEP01STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP03DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP02STATUS !== 4 && params?.STEP02STATUS !== 5) {
      progress = 'Recive Pre. PPF';
      stepNumber = 2;
      statusNumber = params?.STEP02STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP03DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP03STATUS !== 4 && params?.STEP03STATUS !== 5) {
      progress = 'Send Pre. PPF';
      stepNumber = 3;
      statusNumber = params?.STEP03STATUS;
      supOrToyota = 'S';
      dueDate = params?.STEP03DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP04STATUS !== 4 && params?.STEP04STATUS !== 5) {
      progress = 'Approval Pre. PPF';
      stepNumber = 4;
      statusNumber = params?.STEP04STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP06DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP05STATUS !== 4 && params?.STEP05STATUS !== 5) {
      progress = 'Trial Order';
      stepNumber = 5;
      statusNumber = params?.STEP05STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP06DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP06STATUS !== 4 && params?.STEP06STATUS !== 5) {
      progress = 'Ship Trail';
      stepNumber = 6;
      statusNumber = params?.STEP06STATUS;
      supOrToyota = 'S';
      dueDate = params?.STEP06DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP07STATUS !== 4 && params?.STEP07STATUS !== 5) {
      progress = 'Trial';
      stepNumber = 7;
      statusNumber = params?.STEP07STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP09DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP08STATUS !== 4 && params?.STEP08STATUS !== 5) {
      progress = 'Send Final PPF';
      stepNumber = 8;
      statusNumber = params?.STEP08STATUS;
      supOrToyota = 'S';
      dueDate = params?.STEP09DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.STEP09STATUS !== 4 && params?.STEP09STATUS !== 5) {
      progress = 'Approval Final';
      stepNumber = 9;
      statusNumber = params?.STEP09STATUS;
      supOrToyota = 'T';
      dueDate = params?.STEP09DUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.PKGAUTHSTATUS !== 4 && params?.PKGAUTHSTATUS !== 5) {
      progress = 'Pkg Auth';
      stepNumber = 10;
      statusNumber = params?.PKGAUTHSTATUS;
      supOrToyota = 'T';
      dueDate = params?.READINESSDUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.FORECASTSTATUS !== 4 && params?.FORECASTSTATUS !== 5) {
      progress = 'Forecast';
      stepNumber = 11;
      statusNumber = params?.FORECASTSTATUS;
      supOrToyota = 'S';
      dueDate = params?.READINESSDUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else if (params?.READINESSSTATUS !== 4 && params?.READINESSSTATUS !== 5) {
      progress = 'Readiness';
      stepNumber = 12;
      statusNumber = params?.READINESSSTATUS;
      supOrToyota = 'S';
      dueDate = params?.READINESSDUE;
      gridDate = this.dateFormatter({ value: params?.Sdate });
    } else {
      progress = 'Finished';
      stepNumber = 13;
      statusNumber = '0';
      supOrToyota = 'T';
      dueDate = '9999/12/31';
      gridDate = this.dateFormatter({ value: params?.Sdate });
    }

    value = {
      progress: progress,
      stepNumber: stepNumber,
      statusNumber: statusNumber,
      supOrToyota: supOrToyota,
      dueDate: dueDate,
      gridDate: gridDate,
    };
    return value;
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  goToNexpage() {
    this.gridApi?.paginationGoToNextPage();
  }
  goToPreviousPage() {
    return this.gridApi?.paginationGoToPreviousPage() + 1;
  }
  goToFirstPage() {
    this.gridApi?.paginationGoToFirstPage();
  }
  goToLastPage() {
    return this.gridApi?.paginationGoToLastPage() + 1;
  }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      return true;
    } else {
      return false;
    }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.rowCount === 0) {
      this.currentPageForTotalPage =
        Number(this.gridApi?.paginationGetCurrentPage());
    }
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText(
        '#lbCurrentPage',
        this.gridApi.paginationGetCurrentPage() + 1
      );
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === 'ALL') {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  cellStyleColumn(_params: any) {
		return {
		  'display': 'inline-block',
		  'text-overflow': 'ellipsis',
		  'white-space': 'nowrap',
		  'overflow': 'hidden',
		  'vertical-align': 'middle',
		  'width' : _params.colDef.maxWidth || _params.colDef.minWidth || _params.colDef.width,
		  'padding-left' : '5px',
		};
	}

  cellStyleActions(_params: any) {
    return {
      display: 'flex',
      'justify-content': 'flex-start',
      height: '40px',
      'padding-left': '20px',
    };
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.getCurrentPage();
    if (this.globalState.getTrackingPage()) {
      const retrivedValue = this.globalState.getTrackingPage();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
    if (this.globalState.getTrackingSort()) {
      const retrivedValue = this.globalState.getTrackingSort();
      this.columnApi?.applyColumnState({
        state : retrivedValue,
        applyOrder : true
      });
    }
  }

  setText(selector: string, text: any) {
    const element = document.querySelector(selector);
    if (element !== null) {
      element.innerHTML = this.rowCount > 0 ? text : '0';
    }
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  //Open DL status dialog box
  openDialog() {
    this.dialog.open(DlstatusComponent, {
      data: { supplierCode: { supplierCode: this.supplierNumber }, fromDate : this.searchParameters.controls.fromDate.value, todate : this.searchParameters.controls.toDate.value },
    });
  }

  defaultSearchFilter(supplier: any) {
    this.searchParameters = new FormGroup({
      fromDate: new FormControl(this.tenYearsBack.toISOString().split('T')[0]),
      toDate: new FormControl(this.today.toISOString().split('T')[0]),
      partnum: new FormControl(''),
      model: new FormControl(''),
      step: new FormControl('All'),
      supplier: new FormControl(supplier),
      from: new FormControl(''),
      check: new FormControl('Do'),
    });
    this.globalState.setTrackingFilters(this.searchParameters.value);
  }

  defaultSearchFilter2(data: any) {
    this.searchParameters = new FormGroup({
      fromDate: new FormControl(data?.fromDate),
      toDate: new FormControl(data?.toDate),
      partnum: new FormControl(data?.partnum),
      model: new FormControl(data?.model),
      step: new FormControl(data?.step),
      supplier: new FormControl(data?.supplier || 0),
      from: new FormControl(data?.from),
      check: new FormControl(data?.check),
    });
    this.globalState.setTrackingFilters(this.searchParameters.value);
  }

  dateFilter(filteredData: any, searchParameters: any) {
    if (searchParameters.value.fromDate && !searchParameters.value.toDate) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item.gridDate.replace(/\//g, '-')) >=
            new Date(searchParameters.value.fromDate)
          );
        } else {
          return null;
        }
      });
    }

    if (!searchParameters.value.fromDate && searchParameters.value.toDate) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item.gridDate.replace(/\//g, '-')) <=
            new Date(searchParameters.value.toDate)
          );
        } else {
          return null;
        }
      });
    }

    if (searchParameters.value.fromDate && searchParameters.value.toDate) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item?.gridDate.replace(/\//g, '-')) >=
            new Date(searchParameters.value.fromDate) &&
            new Date(item?.gridDate.replace(/\//g, '-')) <=
            new Date(searchParameters.value.toDate)
          );
        } else {
          return null;
        }
      });
    }
    return filteredData;
  }

  dateOnChange(event:any, type: any) {
    this.updateToDateValidators();
    if ((event.target as HTMLInputElement).validity.valid) {
      if (type === 'from') {
        this.searchParameters.controls.fromDate.setErrors(null);
      } else {
        this.searchParameters.controls.toDate.setErrors(null);
      }
    } else {
      if (type === 'from') {
        this.searchParameters.controls.fromDate.setErrors({ incorrect: true });
      } else {
        this.searchParameters.controls.toDate.setErrors({ incorrect: true });
      }
    }
  }

  onSearch(type:string) {
    if(type === 'supchange'){
      this.globalState.trackingRowData = {};
      [undefined,null,'ALL'].includes(this.supplierArrayIndex) ? this.apiCall(this.supplierNumber) : this.apiCall([this.supplierNumber[this.supplierArrayIndex]]); 
    }
    else if(type === 'api') {
      this.globalState.trackingRowData = {};
      this.apiCall(this.supplierNumber); 
    }else{
      let filteredData: any[] = this.rowData;
      this.globalState.setTrackingFilters({
        ...this.searchParameters.value,
      });
      if (this.searchParameters.value.check === 'Do') {
        filteredData = filteredData.filter((data) => {
          return data.doFlag;
        });
      }
  
      const searchParams: any = {
        MODELCODE: this.searchParameters.value.model
          ? this.searchParameters.value.model
          : '*',
        PARTNUMBER: this.searchParameters.value.partnum
          ? this.regex.addStarForPartNumber(this.searchParameters.value.partnum)
          : '*',
        progress: this.searchParameters.value.step
          ? this.searchParameters.value.step
          : '*',
        SPECIALISTCODE: this.searchParameters.value.from
          ? this.searchParameters.value.from
          : '*',
      };
      if (searchParams.progress === 'All') {
        searchParams.progress = '*';
      }
  
      filteredData = this.regex.regexFilter(filteredData, searchParams);
      this.displayRowData = filteredData;
      this.gridApi?.setRowData(this.displayRowData);
      this.rowCount = filteredData.length;
    }
    }
}