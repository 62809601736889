import { Component } from '@angular/core';

@Component({
  selector: 'app-rcaf-cost',
  templateUrl: './rcaf-cost.component.html',
  styleUrls: ['./rcaf-cost.component.scss']
})
export class RcafCostComponent {
  params: any;
  isText=false;
  text: any;

  agInit(params: any): void {
    this.params = params;
    
    if (params.colDef.headerName === 'COST') {
      if(params.data.rownumber === '1'){
        this.isText = false;
      }
      else{
        this.isText = true;
        this.text = Number(params.data.unitcost) * Number(params.data.qty);
      }
    }
  }

}
