export const environment = {
	production: false,	
	EXTERNAL: 'https://external-int.api.qa.vpacs.cloud.toyota.com/api/', // external pg
	INTERNAL:'https://internal.api.qa.vpacs.cloud.toyota.com/api/', // internal vpacs	
	MASTERCOST:'https://mastercost.api.qa.vpacs.cloud.toyota.com/api/',// master cost
	TRACKING:'https://tracking.api.qa.vpacs.cloud.toyota.com/api/', // tracking
	TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
	CLIENT_ID: '777ab753-7eb1-4e33-8ff9-b39d644f32b3',
	REDIRECT_URL: 'https://qa.vpacs.cloud.toyota.com',
	VPACSHOMEURL: 'https://qa.vpacs.cloud.toyota.com',
	API_CALL: 'hFLGY3ecsB8JU2Wos1OC13KL2d5McOG57toKG2U1'
  

};
