import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'select-leader-renderer',
  template: `
  
  <div class="d-flex align-items-center gap-1" >
    <button 
    class="vpacs-btn-table" (click)="select()"
    style="height:30px; font-size: 11px"
    >{{text}}</button>
  </div>
  `,
})

export class SelectLeaderRenderer implements ICellRendererAngularComp {
  text: any;

  constructor(
    public readonly dialog: MatDialog
  ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;

  agInit(params: any): void {
    this.params = params;
    const field = this.params.colDef.field;
    if(field === 'release'){
      this.text = 'RELEASE';
    }
    else{
      this.text = 'SELECT';
    }
  }

  select() {
    this.params.clicked(this.params.column.colId, this.params.data);
  }
}




