<div>
  <div class="d-flex justify-content-between align-items-center m-2">
    <p class="page-title">Package Type Maintenance</p>
    <div>
      <button
        class="black-button"
        [disabled]="!haveAddAccess"
        (click)="openDialog('ADD', {})"
      >
        Add
      </button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <app-notification
    [codes]="errorMessage"
    *ngIf="errorMessage"
  ></app-notification>
  <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
  </div>
  <div *ngIf="haveAccess" class="m-2 py-2 px-2">
    <div class="grid-agg grid-h grid-height-package-type mb-5">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [paginationPageSize]="rpPage"
        [pagination]="true"
        [suppressPaginationPanel]="true"
        [icons]="icons"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40"
        (filterChanged)="onFilterChanged()"
        [suppressHorizontalScroll]="true"
        [suppressCellSelection]="true"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [floatingFiltersHeight]="40"
        [headerHeight]="40"
        [domLayout]="domLayout"
      >
      </ag-grid-angular>
      <app-pagination
        [cPage]="cPage"
        [tPage]="tPage"
        [rpPage]="rpPage"
        [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)"
      >
      </app-pagination>
    </div>
  </div>
</div>