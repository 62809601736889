<div class="wrapper bg-white">
    <p class="page-title mx-2 px-2">Shipment Detail</p>
    <div class="alert alert-danger mt-3" *ngIf="errorList.length > 0">
        <span *ngFor="let message of errorList">{{ message }}</span>
    </div>

    <div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
        <span>{{ successMessage }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="mat-typography content" *ngIf="haveAccess">
        <div class="vpacs-shadow m-3 px-2 py-1 mx-3">
            <div class="d-flex justify-content-between pb-3 px-3 align-items-center">
                <span class="fs-6">Shipment:- {{ detailPage }}</span>

                <button class="vpacs-btn-table mx-4" [disabled]="addflag" (click)="addShipment()">
                    ADD
                </button>
            </div>
        </div>
        <div class="vpacs-shadow">
            <div class="grid-agg grid-height-shipment-details-list">
                <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" [animateRows]="true"
                    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
                    (gridReady)="onGridReady($event)" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
                    [icons]="icons" [overlayLoadingTemplate]="overlayLoadingTemplate"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [headerHeight]="60">
                </ag-grid-angular>
            </div>
        </div>

        <div class="d-flex flex-wrap col mx-3">
            <div class="d-flex align-items-center col-lg-3 col-md-6 col-10 my-2">
                <label for="TotalNoPallet" class="col-3">Total # of Pallet</label>
                <input type="text" id="TotalNoPallet" disabled [(ngModel)]="numberOfPallet" />
            </div>
            <div class="d-flex align-items-center col-lg-3 col-md-6 col-10 my-2">
                <label for="Weight" class="col-4">Total Weight</label>
                <input type="text" id="Weight" disabled [(ngModel)]="Weight" />
            </div>
        </div>
        <div class="d-flex flex-wrap col mx-3">
            <div class="d-flex align-items-center col-lg-3 col-md-6 col-10 my-2">
                <label for="text1" class="col-3">LP</label>
                <mat-select class="mat-sel pt-1" [(ngModel)]="lp" disabled>
                    <mat-option *ngFor="let lp of lpcodes" [value]="lp">{{
                        lp
                        }}</mat-option>
                </mat-select>
            </div>
            <div class="d-flex align-items-center col-lg-3 col-md-6 col-10 my-2">
                <input type="checkbox" [checked]="chknoshipment" [disabled]="chknoshipment === false" class="col-2" />
                <label for="contact Person" class="col-4">No Shipment</label>
            </div>
        </div>
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel6 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3 my-1">
                    <mat-panel-title class="fw-bold">REQUEST</mat-panel-title>
                    <mat-icon class="toggleIcon">{{
                        panel6.expanded ? "remove" : "add"
                        }}</mat-icon>
                </mat-expansion-panel-header>
                <div>
                    <div class="d-flex flex-wrap col mx-1">
                        <div class="fw-bold col-12">Shipment Ready</div>

                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="company" class="col-4">Company</label>
                            <input type="text" id="company" [disabled]="companydis1" [(ngModel)]="company" />
                            <button [disabled]="companySelectDisabled" (click)="dialogOpen1()" mat-icon-button>
                                <mat-icon class="col-1 toggleIcon ms-1">more_vert</mat-icon>
                            </button>
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="address" class="col-4">Address</label>
                            <input type="text" id="address" [disabled]="addressdis1" [(ngModel)]="contactAddress" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="contact Person" class="col-4">Contact Person</label>
                            <input type="text" id="contactPerson" [disabled]="contactPersondis1"
                                [(ngModel)]="contactPerson" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="contactPhone" class="col-4">Contact Phone#</label>
                            <input type="text" id="contactPhone" [disabled]="contactPhonedis1"
                                [(ngModel)]="contactPhone" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="contactEmail" class="col-4">Contact Email</label>
                            <input type="text" id="contactEmail" [disabled]="contactEmaildis1"
                                [(ngModel)]="contactEmail" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="date" class="col-4">Date</label>
                            <input type="date" id="date" [disabled]="datedis1" [(ngModel)]="date" class="col-7" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="windowTime" class="col-4">Window Time</label>
                            <div class="d-flex align-items-center gap-2">
                                <select [disabled]="windowTimeHHdis1" [(ngModel)]="windowTimeHH" style="width: 77px">
                                    <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                        {{ index + 0 }}
                                    </option>
                                </select>
                                <select [disabled]="windowTimeMMdis1" [(ngModel)]="windowTimeMM" style="width: 77px">
                                    <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                        {{ index + 0 }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="shippingClass" class="col-4">Shipping Class</label>
                            <input type="text" id="shippingClass" [disabled]="shippingClassdis"
                                [(ngModel)]="shippingClass" />
                        </div>
                    </div>
                    <div class="d-flex flex-wrap col mx-1">
                        <div class="fw-bold col-12">Last Delivery Date</div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <span>Select Receiver</span>&nbsp;
                            <input type="radio" value="supplier" name="receiver" disabled [(ngModel)]="receiver"
                                class="ms-3 me-1" />
                            <label>Supplier</label>
                            <input type="radio" value="contfab" name="receiver" disabled [(ngModel)]="receiver"
                                class="ms-3 me-1" />
                            <label>Cont Fab</label>
                            <input type="radio" value="dun1fab" name="receiver" disabled [(ngModel)]="receiver"
                                class="ms-3 me-1" />
                            <label>Dun1 Fab</label>
                            <input type="radio" value="namc" name="receiver" disabled [(ngModel)]="receiver"
                                class="ms-3 me-1" />
                            <label>NAMC</label>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="company2" class="col-4">Company</label>
                                <input type="text" id="company2" [disabled]="companydis2" [(ngModel)]="company2" />
                                <button [disabled]="companySelectDisabled" (click)="dialogOpen2()" mat-icon-button>
                                    <mat-icon class="col-1 toggleIcon ms-1">more_vert</mat-icon>
                                </button>
                            </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="address" class="col-4">Address</label>
                                <input type="text" [disabled]="addressdis2" [(ngModel)]="contactAddress2"
                                    id="address" />
                            </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="contact Person" class="col-4">Contact Person</label>
                                <div class="d-flex align-items-center gap-2">
                                <input type="text" id="contactPerson" [disabled]="contactPersondis2"
                                    [(ngModel)]="contactPerson2" style="width: 77px" />
                                    <input type="text" id="contactPerson" [disabled]="contactPersondis23"
                                    [(ngModel)]="recvperson" style="width: 77px"/>
                                </div>
                            </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="contactPhone" class="col-4">Contact Phone#</label>
                                <div class="d-flex align-items-center gap-2">
                                <input type="text" id="contactPhone" [disabled]="contactPhonedis2"
                                    [(ngModel)]="contactPhone2" style="width: 77px" />
                                    <input type="text" id="contactPhone" [disabled]="contactPhonedis23"
                                    [(ngModel)]="recvphone" style="width: 77px" />
                                    </div>
                            </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="contactEmail" class="col-4">Contact Email</label>
                                <div class="d-flex align-items-center gap-2">
                                <input type="text" id="contactEmail" [disabled]="contactEmaildis2"
                                    [(ngModel)]="contactEmail2" style="width: 77px" />
                                    <input type="text" id="contactEmail" [disabled]="contactEmaildis23"
                                    [(ngModel)]="recvemail" style="width: 77px" />
                            </div>
                           </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="date" class="col-4">Date</label>
                                <input type="date" id="date" [disabled]="datedis2" [(ngModel)]="date2" class="col-7" />
                            </div>
                            <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                                <label for="windowTime" class="col-4">Window Time</label>
                                <div class="d-flex align-items-center gap-2">
                                    <select [disabled]="windowTimeHHdis2" [(ngModel)]="windowTimeHH2"
                                        style="width: 77px">
                                        <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                            {{ index + 0 }}
                                        </option>
                                    </select>
                                    <select [disabled]="windowTimeMMdis2" [(ngModel)]="windowTimeMM2"
                                        style="width: 77px">
                                        <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                            {{ index + 0 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel7 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3 my-1">
                    <mat-panel-title class="fw-bold">PLAN</mat-panel-title>
                    <mat-icon class="toggleIcon">{{
                        panel7.expanded ? "remove" : "add"
                        }}</mat-icon>
                </mat-expansion-panel-header><br />
                <div>
                    <div class="d-flex flex-wrap col mx-3">
                        <div class="fw-bold col-12">Pick Up</div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="Pickup Date" class="col-4">Date</label>
                            <input type="date" id="Pickup Date" [disabled]="pickupdatedis" [(ngModel)]="pickupDate"
                                class="col-7" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="Pickup Time" class="col-4">Window Time</label>
                            <div class="d-flex align-items-center gap-2">
                                <select [disabled]="pickuptimedis" [(ngModel)]="pickupTime" style="width: 160px">
                                    <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                        {{ index + 0 }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap col mx-3">
                        <div class="fw-bold col-12">Delivery</div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="DeliveryDate" class="col-4">Date</label>
                            <input type="date" id="DeliveryDate" [disabled]="deliveryDatedis" [(ngModel)]="deliveryDate"
                                class="col-7" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="Delivery Time" class="col-4">Window Time</label>
                            <div class="d-flex align-items-center gap-2">
                                <select [disabled]="deliveryTimedis" [(ngModel)]="deliveryTime" style="width: 160px">
                                    <option *ngFor="let a of timeArray; let index = index" [value]="index + 0">
                                        {{ index + 0 }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap col mx-3">
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="carrier" class="col-4">Carrier</label>
                            <input type="text" id="carrier" [disabled]="carrierdis" [(ngModel)]="carrier" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="Tracking No." class="col-4">Tracking# </label>
                            <input type="text" id="Tracking No." [disabled]="trackingNumberdis"
                                [(ngModel)]="trackingNumber" />
                        </div>
                        <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                            <label for="Cost Estimate" class="col-4">Cost Estimate</label>
                            <input type="text" id="Cost Estimate" [disabled]="costEstimatedis"
                                [(ngModel)]="costEstimate" />
                            <select [(ngModel)]="currency" [disabled]="currencydis" style="height: 30px"
                                class="mat-sel mx-2">
                                <option value="USD">USD</option>
                                <option value="CAD">CAD</option>
                            </select>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel8 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3 my-1">
                    <mat-panel-title class="fw-bold">ACTUAL</mat-panel-title>
                    <mat-icon class="toggleIcon">{{
                        panel8.expanded ? "remove" : "add"
                        }}</mat-icon>
                </mat-expansion-panel-header><br />
                <div class="d-flex flex-wrap col mx-3">
                    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                        <label for="shipmentDate" class="col-4">Pickup Date</label>
                        <input type="date" id="shipmentDate" [disabled]="shipmentDatedis" [(ngModel)]="shipmentDate" class="col-7"  />
                    </div>
                    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                        <label for="Received Date" class="col-4">Delivery Date</label>
                        <input type="date" id="Received Date" [disabled]="receivedDatedis" [(ngModel)]="receivedDate" class="col-7"  />
                    </div>
                    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12 my-2">
                        <label for="Cost Estimate" class="col-4">Actual Cost</label>
                        <input type="text" id="Cost Estimate" [disabled]="actualCostdis" [(ngModel)]="actualCost" />
                        <select [(ngModel)]="currency2" [disabled]="currencydis2" style="height: 30px"
                            class="mat-sel mx-2">
                            <option value="USD">USD</option>
                            <option value="CAD">CAD</option>
                        </select>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="footer-dialog d-flex px-3" style="height: 50px" *ngIf="haveAccess">
        <div class="mx-3 cancel" (click)="cancel()">Cancel</div>

        <button class="vpacs-btn-table mx-3" [disabled]="savebtn" *ngIf="saveFlag" (click)="save()">
            SAVE
        </button>
        <button class="black-button mx-3" [disabled]="sendbtn" *ngIf="sendFlag" (click)="send()">
            SEND
        </button>
        <button class="black-button mx-3" [disabled]="rejectbtn" *ngIf="rejectFlag" (click)="reject()">
            REJECT
        </button>
        <button class="black-button mx-3" [disabled]="rejectcancelbtn" *ngIf="cancelRejectFlag"
            (click)="rejectCancel()">
            REJECT CANCEL
        </button>
        <button class="black-button mx-3" [disabled]="shipcancelbtn" *ngIf="cancelShipFlag" (click)="cancelShipment()">
            CANCEL SHIPMENT
        </button>
        <button class="black-button mx-3" [disabled]="approvebtn" *ngIf="approveFlag" (click)="approve()">
            Approve
        </button>
    </div>
</div>