import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PartListSelectComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/tracking-part-list/part-list-select/part-list-select.component';
import { DiscrepencySelectBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-discrepancy/descripency-select';
import { DiscrepancyListHyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/discrepancy-list-hyperlink';
import { PartListHyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/part-list-hyperLink';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-descripency-select',
  templateUrl: './descripency-select.component.html',
  styleUrls: ['./descripency-select.component.scss']
})
export class DescripencySelectComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 10
  isAccessible = false;
  plants: any[] = [];
  totalRecords = 0;
  partNumber = '';
  part1 = '';
  part2 = '';
  sortModel: any[] = [];
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  cPage = 1;
  tPage = 0;
  rpPage = 50;

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal: any = 1;
  spCode: any;
  suppliercode: any;
  familycode: any = '';
  partnumber: any;
  fromdate: any;
  plantcode: any;
  plantss: any;
  rn: any;
  modelcode: any = '';
  partdescription: any = '';
  container: any;
  specialistcode: any = '';
  over: any = '';
  under: any = '';
  toDate: any = '';
  develop = false;
  complete = "checked";
  gridColumnApi: any;
  haveAccess: any;
  supType: any = '';
  userInfo: any;
  unit: any = '';
  isAll = false;
  apiFromSearch = false;
  originalPage: any;
  response1: any;
  totalOver: any;
  totalUnder: any;
  filteredOptions: any;
  plantcodes: any;
  plant: any;

  constructor(private readonly router: Router, private readonly spinnerService: NgxUiLoaderService, public dialog: MatDialog, public datepipe: DatePipe,
    private readonly state: ExportStateService, private readonly DiscrepancyListService: DiscrepancyListService) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      filterParams: { newRowsAction: 'keep' },
      valueFormatter: this.nullToDashFormatter
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        const plantdata = this.DiscrepancyListService.getplantSelect();
        this.spinnerService.start();
        if (res) {
          this.userInfo = res.info;
          this.unit = res.info?.unit;
          this.supType = res.role.supType;
          this.haveAccess = res.role?.p_trackingview;
          this.haveAccess && this.getColumnDefs();
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
            this.spCode = res.info.specialistcode;
            this.getPlant();
          }
        }
        const filterdata = this.DiscrepancyListService.getpartFiltersSelect();
        if (filterdata) {
          this.familycode = filterdata?.FamilyCode;
          this.plant = (filterdata?.PlantCode.length > 1) ? '(All)' : filterdata?.PlantCode[0];
          this.selectedPlant = plantdata;
          this.suppliercode = filterdata?.SupCode;
          this.fromdate = filterdata.PkgStartDateFrom === "null" ? undefined : this.datepipe.transform(filterdata.PkgStartDateFrom, "YYYY-MM-dd");
          this.over = filterdata?.PartOver;
          this.under = filterdata?.PartUnder;
          this.modelcode = filterdata?.ModelCode;
          this.partNumber = (filterdata?.Part1 ?? '') + (filterdata?.Part2 ?? '');
          this.partdescription = filterdata?.Description;
          this.toDate = filterdata.pkgToDate === "null" ? undefined : this.datepipe.transform(filterdata.pkgToDate, "YYYY-MM-dd");
          this.specialistcode = filterdata?.SpecialistCode;
          this.develop = filterdata?.Develop;
          this.complete = filterdata?.Complete;
          this.totalOver = filterdata?.TWOver;
          this.totalUnder = filterdata?.TWUnder;
          this.DiscrepancyListService.setpartFiltersSelect(null);
          return;
        };
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
    if (localStorage.getItem('suppliercode')) {
      this.suppliercode = localStorage.getItem('suppliercode');
      this.partnumber = localStorage.getItem('partnumber');
      this.fromdate = localStorage.getItem('fromdate');
      this.plantcodes = localStorage.getItem('plantcode');
      this.plantss = localStorage.getItem('plantss');
      this.rn = localStorage.getItem('rn');
    }
    localStorage.setItem('suppliercode', this.suppliercode);
    localStorage.setItem('partnumber', this.partnumber);

  }
  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "SELECT", field: "history", minWidth: 90, sortable: false, unSortIcon: false,
        cellRendererFramework: DiscrepencySelectBtnCellRender, cellStyle: { cursor: 'pointer' },
        floatingFilterComponentParams: false,
        floatingFilter: false,
        filter: false,
      },
      {
        headerName: "FAMILY CODE", field: "familycode", minWidth: 100
      },
      {
        headerName: "MODEL CODE", field: "modelcode", minWidth: 100
      },
      {
        headerName: "SUPPLIER CODE", field: "supplier", minWidth: 100,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "SUPPLIER SHORT NAME", field: "supplier", minWidth: 120,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "PART NUMBER", field: "partnumber", minWidth: 100,
        cellRendererFramework: DiscrepancyListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "PART DESCRIPTION", field: "partdescription", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, cellStyle: { whiteSpace: 'normal', lineHeight: 'normal' },
        filter: 'agTextColumnFilter', minWidth: 170
      },
      {
        headerName: "PACKAGE START DATE", field: "pkgstartdate", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, valueFormatter: this.dateFormatter,
        filter: 'agTextColumnFilter', minWidth: 120,
        comparator: (valueA: any, valueB: any, _nodeA: any, _nodeB: any, _isInverted: any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: "TOTAL WEIGHT", field: "totalweight", minWidth: 100
      },
      {
        headerName: "PARTM3", field: "partm3", minWidth: 100
      },
      {
        headerName: "QPC", field: "qtybox", minWidth: 100
      },
      {
        headerName: "QPU", field: "qpu", minWidth: 100
      },
      {
        headerName: "CONTAINER", field: "container", minWidth: 100
      },
      {
        headerName: "DUNNAGE 1", field: "dunnage1", minWidth: 100
      },
      {
        headerName: "DUNNAGE 2", field: "dunnage2", minWidth: 100
      },
      {
        headerName: "HAZMAT", field: "hazmat", minWidth: 100
      },
      {
        headerName: "SPECIALIST", field: "specialist", minWidth: 120
      },
      {
        headerName: "PLANT", field: "plant", minWidth: 120
      },
      {
        headerName: "PART ROUTING", field: "routing", minWidth: 120
      },
      {
        headerName: "ECI#", field: "ecino", minWidth: 120
      },

    ]
  }

  nullToDashFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null || params.value === "") {
      return '-';
    } else {
      return params?.value;
    }
  }


  dateFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null || params.value === "") {
      return '-';
    } else {
      return params?.value
        ? formatDate(params.value, 'MM/dd/YYYY', 'en-US', 'UTC')
        : '';
    }
  }

  setText(selector: string, text: any) {
    if (this.rowCount > 0) {
      const element = document.querySelector(selector);
      if (element !== null) {
        (element as HTMLElement).innerHTML = text;
      }
    }
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  backBtn() {
    this.DiscrepancyListService.setfilterSelect(null);
    this.DiscrepancyListService.setpartFiltersSelect(null);
    this.removeData();
    if (this.router.url.includes('discripency-list')) {
      this.router.navigate(['/discripency-list'])
    }
    else if (this.router.url.includes('sI-preppf-dept-approval')) {
      this.router.navigate(['/tracking-list/sI-preppf-dept-approval'])
    }
    else if (this.router.url.includes('sI-final-dept-approval')) {
      this.router.navigate(['/tracking-list/sI-final-dept-approval'])
    }
    else if (this.router.url.includes('si-pkgtrial-dept-approval')) {
      this.router.navigate(['/tracking-list/si-pkgtrial-dept-approval'])
    }
    else if (this.router.url.includes('send-ppf')) {
      this.router.navigate(['/tracking-list/send-ppf'])
    }
  }

  removeData() {
    localStorage.removeItem('suppliercode');
    localStorage.removeItem('partnumber');
    localStorage.removeItem('fromdate');
    localStorage.removeItem('plantcode');
    localStorage.removeItem('plantss');
    localStorage.removeItem('rn');
    localStorage.removeItem('specialist');
    localStorage.removeItem('isTracking');
    localStorage.removeItem('url');
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 36])
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    if (this.DiscrepancyListService.getfilterSelect()) {
      const retrivedValue = this.DiscrepancyListService.getfilterSelect();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  filterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.DiscrepancyListService.setfilterSelect(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response1?.pagination?.TotalRecords;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []

      for (let i = 0; i < Math.ceil(this.totalRecords / 100); i++) {
        this.cPage = i + 1;
          this.tPage = 1;
          this.rpPage = 100
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onLoad();
    }
  }


  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.onLoad();
      if (this.apiFromSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiFromSearch = false;
      }
      resolve();
    });
  }


  onSearch() {
    this.onLoad();
  }

  onLoad() {
    this.apiFromSearch = true;
    this.spinnerService.start();
    let partValue = this.partnumber;
    if (partValue ? partValue.length < 10 : []) {
      partValue = partValue + '*';
    }
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode
    const updateObject = {
      "PlantCode": this.plant === '(All)' ? this.resPlant : [this.plant],
      "SupplierCode": this.suppliercode,
      "FamilyCode": this.familycode,
      "ModelCode": this.modelcode,
      "PartNumber1": partValue.slice(0, 5),
      "PartNumber2": partValue.slice(5, 12),
      "Description": this.partdescription,
      "PkgStartDateFrom": this.fromdate === "null" ? '' : this.fromdate,
      "PkgStartDateTo": this.toDate === "null" ? '' : this.toDate,
      "Container": this.container ? this.container : '',
      "SpecialistCode": this.specialistcode,
      "PartOver": this.over,
      "PartUnder": this.under,
      "Develop": this.develop ? "checked" : '',
      "Complete": this.complete ? "checked" : '',
      "SupplierType": Number(this.supType),
      "Unit": Number(this.unit),
      "page": this.cPage,
      "limit": this.rpPage,
      "TWOver": this.totalOver,
      "TWUnder": this.totalUnder
    }
    this.DiscrepancyListService.setpartFiltersSelect(updateObject);
    if (this.apiFromSearch) {
      this.cPage = 1;
    }
    this.selectOnload(updateObject)
  }
  private selectOnload(payload: any) {
    this.DiscrepancyListService.selectOnload(payload).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        const data = res.result.Result;
        data.forEach((element: any) => {
          element.pkgstartdate = this.dateFormatter({ value: element.pkgstartdate })
        })
        this.displayRowData = res.result?.Result;
        this.rowCount = res.pagination.rows;
        this.totalRecords = res.pagination?.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        if (this.apiFromSearch) {
          this.cPage = 1;
        }
        this.apiFromSearch = false;
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
      },

    })
  }
  selectSpecialist() {
    const dialogRef = this.dialog.open(PartListSelectComponent, {
      data: { spCode: this.specialistcode, all: this.resPlant }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistcode = result
    })
  }

  getPlant() {
    this.spinnerService.start();
    this.spCode = this.userInfo.specialistcode;
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };

    this.DiscrepancyListService.plant(obj).subscribe(response => {
      this.resPlant = response.map((item: { plantcode: any; }) => item.plantcode)
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response];
      this.plant = this.plantcodes ?? this.plant;
      this.filteredOptions = this.selectedPlant;
      this.onLoad();
      this.DiscrepancyListService.setplantSelect(this.selectedPlant);
    })
  }

  filterOptions() {
    this.filteredOptions = this.selectedPlant.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'fromdate') {
        this.fromdate = '';
      }
      if (id === 'toDate') {
        this.toDate = '';
      }
    }
  }
}
