import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentListService {
    URL = this.constantsService.INTERNAL;
  authData: any[] = [];

  //grid filter changes
  filterdata: any;
  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  shipmentListOnload(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'shipmentList/OnLoad', params,
    { headers: this.constantsService.internalHttpOptions,responseType: 'json' }).pipe(catchError(this.handleError))
    }
  shipmentDetailOnload(bodyParams: any): Observable<any> {
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetailOnLoad', bodyParams, 
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}

DeliveryHistory(params: any): Observable<any> {
  return this.http
  .post(this.constantsService.INTERNAL + 'shipmentList/shipmentDetail/DeliveryHistory/', params, {
    headers: this.constantsService.internalHttpOptions,
  })
  .pipe(catchError(this.handleError))
}
addOnload(addParams:any): Observable<any> {
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/ADD', addParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
setAuthData(authData: any[]) {
  this.authData = authData;
}
fabData(){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/SelectCompanyFabData', 
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
supplierData(supplierParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/SelectCompanySupplierData',supplierParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
plantData(plantParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/createPlantDock',plantParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
reject(rejectParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/Reject',rejectParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
shipmentCancel(cancelParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/ShipmentCancel',cancelParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
rejectCancel(rejparParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/RejectCancel',rejparParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
approve(approveParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/Approve',approveParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
save(saveParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/Save',saveParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
send(sendParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/shipmentDetail/Send',sendParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
rackShip(rackParams:any){
  return this.http.post<any>(this.URL + 'shipmentlist/rackShipment',rackParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
delete(delParams:any){
  return this.http.put<any>(this.URL + 'shipmentlist/deleteSerial',delParams,
   { headers: this.constantsService.httpOptions,responseType: 'json'}).pipe(catchError(this.handleError))
}
addDataOnload(dataParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/addbtnonload',dataParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
rackRecv(rackParams:any){
  return this.http.post<any>(this.URL + 'shipmentlist/rackrecvShipment',rackParams,
   { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
okBtn(okParams:any){
  return this.http.post<any>(this.URL + 'shipmentlist/rackrecvShipmentok',okParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
addBtn(addBtnParams:any){
  return this.http.post<any>(this.URL + 'shipmentList/addbtn',addBtnParams,
  { headers: this.constantsService.httpOptions, responseType: 'json' }).pipe(catchError(this.handleError))
}
getAllPlant(plantObj: any): Observable<any> {
  return this.http.post(this.URL + 'common/getPlant',
    plantObj, { headers: this.constantsService.httpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
}
getShipmentListFilters()//filterchanges
{
  return this.shipmentListRetainedFilters;
}

shipmentListRetainedFilters:any;
setShipmentListFilters(data:any)
{
  this.shipmentListRetainedFilters=data;
}
 
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}

  //grid filter changes
  setfilter(filter:any){
    this.filterdata = filter;
  }

  getfilter() {
    return this.filterdata;
  }
}