<div class="log px-2">
  <div class="px-2">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="page-title">Quantity Detail</div>
      <div>
      <button (click)="onBack()" class="help-btn">Back</button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
    </div>
   
    <div class="top">
      <div class="top-data">
        <span>Package Code</span>
        <span>{{response?.supplierInfo[0]?.packagecode}}</span>
      </div>
      <div class="top-data">
        <span>Dunnage1 Code</span>
        <span>{{response?.supplierInfo[0]?.dunnag1code}}</span>
      </div>
      <div class="top-data">
        <span>Dunnage2 Code</span>
        <span>{{response?.supplierInfo[0]?.dunnage2code}}</span>
      </div>
      <div class="top-data">
        <span>Plant</span>
        <span>{{response?.supplierInfo[0]?.plantcode}}</span>
      </div>
      <div class="top-data">
        <span>Supplier Code</span>
        <span>{{response?.supplierInfo[0]?.suppliercode}}</span>
      </div>
      <div class="top-data">
        <span>Short Name</span>
        <span>{{response?.supplierInfo[0]?.shortname}}</span>
      </div>
      <div class="top-data">
        <span>Name</span>
        <span>{{response?.supplierInfo[0]?.name}}</span>
      </div>
    </div>
  </div>
  <div>
    <div class="grid-agg grid-height-quantity-detail mb-5">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        [animateRows]="true"
        [headerHeight]="50"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [suppressCellSelection]="true"
        [paginationPageSize]="rpPage"
        [pagination]="true"
        [suppressPaginationPanel]="true"
        [icons]="icons"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      >
      </ag-grid-angular>
      <app-pagination
        [cPage]="cPage"
        [tPage]="tPage"
        [rpPage]="rpPage"
        [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)"
        *ngIf="totalRecords > 0"
      >
      </app-pagination>
    </div>
  </div>
</div>
