import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss']
})
export class LegendsComponent {
  blueBtnCls = 'blue-button'
  authLegends:any[]=[
    { css: 'btn2', label: '', desc: 'Negative auth'},
    { css: 'btn4', label: '', desc: 'Wait cancel auth'},
  ]
  legends: any[] = [
    { css: this.blueBtnCls, label: 'BM', desc: 'Benchmark' },
    { css: this.blueBtnCls, label: 'SI', desc: 'Send to Internal' },
    { css: 'vpacs black vpacs-btn-table', label: 'IA', desc: 'Approving by Internal Docks ' },
    { css: this.blueBtnCls, label: 'SE', desc: 'Send to Supplier / Fabricator' },
    { css: this.blueBtnCls, label: 'R', desc: 'RCAF' },
    { css: this.blueBtnCls, label: 'BU', desc: 'Backup' },
    { css: 'orange-btn', label: 'BU', desc: 'Backup' },
    { css: this.blueBtnCls, label: 'H', desc: 'History' },
    { css: this.blueBtnCls, label: 'TC', desc: 'Tracking Correction' },
    { css: this.blueBtnCls, label: 'AU', desc: 'Authorize' },
    { css: 'green-button', label: 'PPF', desc: 'Update Ppf / Part# Cng' },
    { css: 'orange-btn', label: 'PPF', desc: 'Update Ppf / Part# Cng' },
    { css: 'red-button', label: 'D', desc: 'Delete' },
    { css: this.blueBtnCls, label: 'CL', desc: 'Broadcast Calc' },
    { css: this.blueBtnCls, label: 'HZ', desc: 'Hazmat Approval' },
  ];
  ringiLegend:any[]=[
    { css: 'btn1', label: '', desc: 'fixed ringi'},    
  ]
  purDecisionList:any[]=[
    {css: 'no1', label: '', desc: 'To Supplier'},
    {css: 'no2', label: '', desc: 'To Container'},
    {css: 'no3', label: '', desc: 'To Dunnage1'}
  ]
  data:any;
  screenName:any;
  authorizeName:any
  constructor(public dialogRef: MatDialogRef<LegendsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any
  ) {
    this.data=dataStore.data;
    this.screenName = dataStore.screenName;
    this.authorizeName=dataStore.data
   }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }
}
