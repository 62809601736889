import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExchangeRateService } from 'src/app/internal-vpacs/services-internal/master-maintenance/exchange-rate.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-exchange',
  templateUrl: './add-exchange.component.html',
  styleUrls: ['./add-exchange.component.scss']
})
export class AddExchangeComponent implements OnInit {
  countries = ['Select', 'CANADA', 'MEXICO'];
  component = 'Select'
  modalHeader = '';
  buttonValue = '';
  defaultVal = 'Inital';
  country = 'Select';
  fromDate = '';
  exchangeRate = '';
  isEditMode = false;
  supplier: any;
  oldToDate = '';
  isCountryError = false;
  isFromDateError = false;
  isERError = false;
  errorList: any[] = [];
  dateFormat = 'MM-dd-yyyy';
  constructor(
    public dialogRef: MatDialogRef<AddExchangeComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any, private readonly fb: FormBuilder, private readonly router: Router,
    public exchangerateService: ExchangeRateService,
    private readonly spinner: NgxUiLoaderService,
    private readonly message: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.dataStore.mode === "ADD") {
      this.modalHeader = 'Add Exchange Rate';
      this.buttonValue = 'ADD';
      this.isEditMode = false;
    }
    else if (this.dataStore.mode === "UPDATE") {
      this.modalHeader = 'Update Exchange Rate';
      this.buttonValue = 'UPDATE';
      this.country = this.dataStore.field.countryid;
      this.fromDate = formatDate(this.dataStore.field.fromdate, 'yyyy-MM-dd', 'en-US');
      this.oldToDate = formatDate(this.dataStore.field.todate, 'yyyy-MM-dd', 'en-US');
      this.exchangeRate = this.dataStore.field.exchangerate;
      this.isEditMode = true;
    }
  }

  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg });
  }

  saveData() {
    this.errorList = [];
    this.isCountryError = false;
    this.isFromDateError = false;
    this.isERError = false;
    if (this.country === "Select") {
      this.isCountryError = true;
    }
    if (!this.fromDate) {
      this.isFromDateError = true;
    }
    if (!this.exchangeRate) {
      this.isERError = true;
    }
    if (this.isCountryError || this.isFromDateError || this.isERError) {
      return;
    }
    if (!this.isNumber(this.exchangeRate)) {
      this.errorList = ["Data Format Error [Exchange Rate] must be numeric."];
      return;
    }
    if ((Number(this.exchangeRate) <= 0) || (Number(this.exchangeRate) >= 9999)) {
      this.errorList = ["Range Error [Exchange Rate] is out of range. ( 0 < [Exchange Rate] < 9999 )."];
      return;
    }
    this.spinner.start();
    if (this.buttonValue === "ADD") {
      this.saveAdd();
    }
    else if (this.buttonValue === "UPDATE") {
     this.saveUpdate();
    }
    else {
      this.close('');
    }
  }

saveAdd(){
    const param = {
      Country: (this.country === "CANADA") ? "0" : "1",
      FromDate: formatDate(this.fromDate, this.dateFormat, 'en-US'),
      Rate: this.exchangeRate,
    }
    this.exchangerateService.addExchangeRate(param).subscribe({
      next: _data => {
        if(_data?.status === "SUCCESS"){
          this.close('SUCCESS');
        }
        else{
          this.errorList = [_data?.message];
        }
      },
      error: err => {
        this.spinner.stop();
        this.errorList = [err?.error?.error];
        this.message.dismiss();
      }
    });
}
saveUpdate(){
    const param = {
      Country: (this.country === "CANADA") ? "0" : "1",
      FromDate: formatDate(this.fromDate, this.dateFormat, 'en-US'),
      Rate: this.exchangeRate,
      OldCountryID: (this.country === "CANADA") ? "0" : "1",
      OldFromDate: formatDate(this.fromDate, this.dateFormat, 'en-US'),
      OldToDate: formatDate(this.oldToDate, this.dateFormat, 'en-US'),
    }
    this.exchangerateService.updateExchangeRate(param).subscribe({
      next: _data => {
        this.close('SUCCESS');
      },
      error: err => {
        this.spinner.stop();
        this.errorList = [err?.error?.error];
        this.message.dismiss();
      }
    });
}
  deleteData() {
    const param = {
      Country: (this.dataStore.field.countryid === "CANADA") ? "0" : "1",
      FromDate: formatDate(this.dataStore.field.fromdate, this.dateFormat, 'en-US'),
      ToDate: formatDate(this.dataStore.field.todate, this.dateFormat, 'en-US'),
    }
    this.spinner.start();
    this.exchangerateService.deleteExchangeRate(param).subscribe({
      next: _data => {
        this.close('SUCCESS');
      },
      error: err => {
        this.spinner.stop();
        this.errorList = [err?.error?.error];
        this.message.dismiss();
      }
    });
  }

  isNumber(input: any) {
    return /^\d+(\.\d+)?$/.test(input);
  }

  help() {
    this.dialogRef.close();
    if (this.modalHeader === "Add Exchange Rate"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 141])
    }else if (this.modalHeader === "Update Exchange Rate"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 142])
    }
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'fromDate') {
        this.fromDate = '';
      }
    }
  }
}
