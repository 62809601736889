<div class="popup-container">
  <div class="header text-middle">
    {{ data.mode }}
  </div>
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="m-3 details">Details: {{ displayMessage }}</div>
  <div class="m-2 p-2">
    <div class="grid-agg mt-3 grid-height">
      <ag-grid-angular
        style="width: 100%; height : 100% "
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [gridOptions]="gridOptions"
        [suppressCellSelection]="true"
        [icons]="icons"
        [headerHeight]="headerHeight"
        [floatingFiltersHeight]="40"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
