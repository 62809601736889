import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
	selector: 'tracking-backup-btn-cell-render',
	template: `
    <div class="d-flex align-items-center gap-2">
        <mat-icon class="d-flex vpacs-btn-table" 
        style= "font-size: 21px !important;" *ngIf ="this.params.data.backupDefineFlag"
        [ngClass] = "{'vpacs-btn-table-disabled': disableBackupPlants.includes(this.params.data.PLANTCODE)}"
        title="Define"(click)="goToDefineBackup()">
        my_location</mat-icon>

        <mat-icon class="d-flex vpacs-btn-table"
        style="font-size: 21px !important;" title="Use"
        (click)="goToUseBackup()"
        [ngClass] = "{'vpacs-btn-table-disabled':(this.params.data.disableBackup === true || disableBackupPlants.includes(this.params.data.PLANTCODE))}"
        *ngIf ="this.params.data.backupFlag"
        >pan_tool_alt</mat-icon>
    </div>
    `,
})

export class TrackingBackupBtnCellRender implements ICellRendererAngularComp {
	  disableBackupPlants:string[] = []
    constructor(private readonly router: Router,private readonly constants:ConstantsService){
      this.disableBackupPlants = this.constants.disableBackupPlants;
    }
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	public params: any;

	agInit(params: any): void {
		this.params = params;
        
	}

    goToDefineBackup(){   
        const query:any = {
            'plantCode': this.params.data.PLANTCODE, 
            'partNumber': this.params.data.PARTNUMBER,
            'supplierCode' : this.params.data.SUPPLIERCODE,  
            'pkgStartDate': this.params.data.PKGSTARTDATE,  
            'modelCode' : this.params.data.MODELCODE,
            'backUp':this.params.data.backupFlag,
            'plantName' : this.params.data.PLANTNAME
            
          };
        sessionStorage.setItem('queryParams', JSON.stringify(query))
        this.router.navigate(['/tracking/define-backup']);
      }
    goToUseBackup(){   
        const query:any = { 
            'partNumber': this.params.data.PARTNUMBER, 'pkgStartDate': this.params.data.PKGSTARTDATE, 'plantCode': this.params.data.PLANTCODE,
            'supplierCode' : this.params.data.SUPPLIERCODE, 'modelCode' : this.params.data.MODELCODE, 'plantName' : this.params.data.PLANTNAME
          };
        sessionStorage.setItem('queryParams', JSON.stringify(query))
        this.router.navigate(['/tracking/use-backup', this.params.data.SUPPLIERCODE]);
      }

  }