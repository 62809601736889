import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DiscrepancyListAddPartComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-list-add-part/discrepancy-list-add-part.component';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'discrepency-btn-cell-renderer',
  template: `
    <div class="d-flex align-items-center gap-1">
      <button [disabled]="!this.haveAddAccess" 
        *ngIf="show && params.column.colId==='backup'"
        class="vpacs-btn-table"
        style="height:30px"
        (click)="btnClickedHandler($event)"
      >
        SELECT
      </button>
      <button  [disabled]="!this.haveAddAccess" 
        *ngIf="showNew && params.column.colId==='history'"
        class="vpacs-btn-table"
        style="height:30px" 
        (click)="btnClickedHandler($event)"
      >
        NEW
      </button>
    </div>
  `,
})
export class DiscrepencyBtnCellRender implements ICellRendererAngularComp {
  spCode: any;
  haveAccess: any;
  haveAddAccess: any;
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,private readonly state: ExportStateService,
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  params: any;
  buttonName: any;
  show = true;
  showNew =true;

  agInit(params: any): void {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '');
    this.show = true;
    this.buttonName = 'SELECT';
    this.params = params;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
            this.haveAccess = res.role?.p_trackingview;
            this.haveAddAccess = res.role?.p_trackingsave;
      },
    });
    
    if (params.buttonFlag === '1') {
      this.show = true;
      this.showNew=true;
      if (params.colDef.headerName === 'REPLACED PART'){
        this.buttonName = 'SELECT';
      }
      else if (params.colDef.headerName === 'NEW PART'){ 
        this.buttonName = 'NEW'
      }
    }
    else if(params.buttonFlag==='2'){
      this.showNew=false;
      this.show=true;
    } 
    else if(params.buttonFlag==='3'){
      this.showNew=false;
      if(this.spCode===this.params.data.specialistcode){
      this.show=true;
      }else{
        this.show=false;
      }
    } 
    
  }

  data(){
    localStorage.setItem('suppliercode', this.params.data.suppliercode);
      localStorage.setItem('partnumber', this.params.data.partnumber.slice(0,5));
      localStorage.setItem('fromdate', this.params.data.fromdate);
      localStorage.setItem('plantcode', this.params.data.plantcode);
      localStorage.setItem('plantss', this.params.data.plant);
      localStorage.setItem('rn', this.params.data.rn);
      localStorage.setItem('specialist', this.params.data.specialistcode);
  }

  btnClickedHandler(_event: any) {
    if (this.buttonName === 'SELECT') {
      if(this.params.buttonFlag==='1'){
        this.data();
      this.router.navigate(['/discripency-list/select']);
      }else if(this.params.buttonFlag==='2'){
        this.router.navigate(['/supplier-maintenance'])
      }else if(this.params.buttonFlag==='3'){
        this.data();
        this.router.navigate(['/discripency-list/temporary-container'])
      }
    } else if (this.buttonName === 'NEW') {
      this.dialog.open(DiscrepancyListAddPartComponent, {
        data: { addData: this.params.data },
      });
    }
  }
}
