export const columnDefs1 = [
  {
    headerName: '',
    field: 'name',
    autoHeight: true,
    pinned: 'left',
    minWidth: 50,
    maxWidth: 150,
  },
  {
    headerName: 'Container',
    field: 'container',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Dunnage1',
    field: 'dunnage1',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Dunnage2',
    field: 'dunnage2',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Top Cap',
    field: 'topCap',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Pallet',
    field: 'pallet',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Kanban Holder',
    field: 'kanbanHolder',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Label',
    field: 'label',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
  {
    headerName: 'Other',
    field: 'other',
    autoHeight: true,
    sortable: true,
    unSortIcon: true,
    minWidth: 70,
    maxWidth: 120,
  },
];
