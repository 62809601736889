import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'history-btn-cell-renderer',
  template: ` <div class="d-flex align-items-center gap-1">
  <button
  class="vpacs-btn-table"
    style="height:30px;"
    (click)="btnClickedHandler($event)">HISTORY
        </button>
  `,
})

export class HistoryBtnauthCellRender implements ICellRendererAngularComp {

  disabled =false;
    refresh(params: ICellRendererParams): boolean {
      this.agInit(params);
      return true;
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;   

    this.disabled=this.params.disabled(params.data);
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




