import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-plant-sysdays',
  templateUrl: './plant-sysdays.component.html',
  styleUrls: ['./plant-sysdays.component.scss']
})
export class PlantSysdaysComponent implements OnInit {

  plantName: any;
  result: any;
  plante1 = '';  plante2 = '';  plante3 = ''; plante4 = ''; plante5 = ''; plante6 = ''; plante7 = ''; plante8 = '';
  plantf1 = ''; plantf2 = ''; plantf3 = ''; plantf4 = ''; plantf5 = ''; plantf6 = ''; plantf7 = ''; plantf8 = '';
  supf1 = ''; supf2 = ''; supf3 = ''; supf4 = ''; supf5 = ''; supf6 = ''; supf7 = ''; supf8 = '';
  supe1 = ''; supe2 = ''; supe3 = ''; supe4 = ''; supe5 = ''; supe6 = ''; supe7 = ''; supe8 = '';
  haveAccess: any;
  haveAddAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  saveAccess: any;
  plantCode: any;
  constructor(
    private readonly state: ExportStateService,
    public route: ActivatedRoute,
    public dialog:MatDialog, 
    public router: Router,
    public plantService : PlantService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<PlantSysdaysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      this.plantCode = data?.plantcode;
    }


  ngOnInit() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview;
        this.haveAddAccess = res.role?.p_usersave;
        this.deleteAccess = res.role?.p_userdelete;
        this.haveAccess = true;
        if (this.haveAccess) {
        this.calconload();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
      },
    });
  }

  calconload() { 
    setTimeout(() => {this.spinnerService.start()})
    this.plantService.calconload(this.plantCode).subscribe( {next: (res:any) => {
      this.plantName =res.PlantName;
      this.result = res.Result
      this.plante1 = res.Result[0]?.plante1; this.plante2 = res.Result[0]?.plante2; this.plante3 = res.Result[0]?.plante3; this.plante4 = res.Result[0]?.plante4;
      this.plante5 = res.Result[0]?.plante5; this.plante6 = res.Result[0]?.plante6; this.plante7 = res.Result[0]?.plante7; this.plante8 = res.Result[0]?.plante8;
      this.plantf1 = res.Result[0]?.plantf1; this.plantf2 = res.Result[0]?.plantf2; this.plantf3 = res.Result[0]?.plantf3; this.plantf4 = res.Result[0]?.plantf4;
      this.plantf5 = res.Result[0]?.plantf5; this.plantf6 = res.Result[0]?.plantf6; this.plantf7 = res.Result[0]?.plantf7; this.plantf8 = res.Result[0]?.plantf8;
      this.supe1 = res.Result[0]?.supe1; this.supe2 = res.Result[0]?.supe2; this.supe3 = res.Result[0]?.supe3; this.supe4 = res.Result[0]?.supe4;
      this.supe5 = res.Result[0]?.supe5; this.supe6 = res.Result[0]?.supe6; this.supe7 = res.Result[0]?.supe7; this.supe8 = res.Result[0]?.supe8;
      this.supf1 = res.Result[0]?.supf1; this.supf2 = res.Result[0]?.supf2; this.supf3 = res.Result[0]?.supf3; this.supf4 = res.Result[0]?.supf4;
      this.supf5 = res.Result[0]?.supf5; this.supf6 = res.Result[0]?.supf6; this.supf7 = res.Result[0]?.supf7; this.supf8 = res.Result[0]?.supf8;
    } ,
    error: () => {
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
  }

  handlePlantChange(event: any) {
    const val =  event.currentTarget?.value;
    const res =  val?.replace('.', '')?.match(/\D/)
    if(res) {
      return "0.00";
    }
    return event.currentTarget?.value;
  }

  calcInsert() { 
    const loadObj = {
      PlantCode: this.plantCode,
      "PlantFull":[Number(this.plantf1?.replace(/(\.\d{2})\d/,'$1')),Number(this.plantf2?.replace(/(\.\d{2})\d/,'$1')),Number(this.plantf3?.replace(/(\.\d{2})\d/,'$1')), Number(this.plantf4?.replace(/(\.\d{2})\d/,'$1')), 
      Number(this.plantf5?.replace(/(\.\d{2})\d/,'$1')), Number(this.plantf6?.replace(/(\.\d{2})\d/,'$1')), Number(this.plantf7?.replace(/(\.\d{2})\d/,'$1')), Number(this.plantf8?.replace(/(\.\d{2})\d/,'$1'))],
      "SupFull": [Number(this.supf1?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf2?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf3?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf4?.replace(/(\.\d{2})\d/,'$1')), 
        Number(this.supf5?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf6?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf7?.replace(/(\.\d{2})\d/,'$1')), Number(this.supf8?.replace(/(\.\d{2})\d/,'$1'))],
      "SupEmpty": [Number(this.supe1?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe2?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe3?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe4?.replace(/(\.\d{2})\d/,'$1')),
        Number(this.supe5?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe6?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe7?.replace(/(\.\d{2})\d/,'$1')), Number(this.supe8?.replace(/(\.\d{2})\d/,'$1'))],
      "PlantEmpty":[Number(this.plante1?.replace(/(\.\d{2})\d/,'$1')),Number(this.plante2?.replace(/(\.\d{2})\d/,'$1')),Number(this.plante3?.replace(/(\.\d{2})\d/,'$1')), Number(this.plante4?.replace(/(\.\d{2})\d/,'$1')), 
        Number(this.plante5?.replace(/(\.\d{2})\d/,'$1')), Number(this.plante6?.replace(/(\.\d{2})\d/,'$1')), Number(this.plante7?.replace(/(\.\d{2})\d/,'$1')), Number(this.plante8?.replace(/(\.\d{2})\d/,'$1'))],
      }
      this.spinnerService.start();
      this.plantService.calcInsert(loadObj).subscribe( {next: (res:any) => {
      this.plantName =res.PlantName;
      this.result = res.Result
      this.close('SUCCESS', res);
    } ,
    error: () => {
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
  }

  closeDialog(){
    this.dialogRef.close()
  }
  close(msg: string, res: any = {}): void {
    this.dialogRef.close({ event: 'CloseEvent', msg, res });
  }
  help(){
    this.dialogRef.close();
    this.router.navigate(['./master-help',5,"Master Maintenance",138])
  }
}
