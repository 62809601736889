<div class="d-flex justify-content-between align-items-center m-3">
  <div class="page-title">Maintenance Package</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="mx-3 p-2 header">AUTHORIZATION FORM</div>
<div class="alert alert-danger m-3" *ngIf="errorList?.length>0">
  <ng-container *ngFor="let error of errorList ">
    <div>{{error}}</div>
  </ng-container>
</div>
<div class="d-flex flex-wrap top-arr m-3" [formGroup]="maintenanceForm">
  <div class="col-md-4 d-block p-0">
    <div class="top-arr-left col-12 d-flex">
      <div class="left d-flex flex-column col-5 header">
        <span>Plant</span>
        <span>Part #</span>
        <span>Supplier</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>{{response?.PlantName[0]?.name}}</span>
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering formControlName="partNumber">
            <mat-option *ngFor="let x of partNumberDropDown" [value]="x.partnumber">{{x.partnumber}}</mat-option>
          </mat-select>
        </span>
        <span>{{response?.SupplierName[0]?.shortname}}</span>
      </div>
    </div>
    <div class="main-left col-12 d-flex mt-3">
      <div class="left d-flex flex-column col-5 header">
        <span>Ringi #</span>
        <span>Ringi Type</span>
        <span>Auth Type</span>
        <span>Reason</span>
        <span>Actual Date (mm/dd/yyyy)</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering formControlName="ringi">
            <mat-option *ngFor="let x of ringiList" [value]="x.ringinumber">{{x.ringinumber}}</mat-option>
          </mat-select>
        </span>
        <span>{{response?.RingiType}}</span>
        <span>Maintenance</span>
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering formControlName="reason">
            <mat-option *ngFor="let x of reasonList" [value]="x.id">{{x.reason}}</mat-option>
          </mat-select>
        </span>
        <span><input type="date" style="width: 100%" formControlName="actualDate"/></span>
      </div>
    </div>
    <div class="d-block mt-4 pb-5 content-row me-0">
        <div class="d-flex align-items-baseline pe-0">
          <label for="details" class="control-label col-md-4"
            >Comment For Supplier</label
          >
          <textarea
            name=""
            id="details"
            cols="170"
            rows="3"
            class="form-control detail_area ms-3 pt-0"
            formControlName="commentSupplier"
          ></textarea>
        </div>
        <div class="d-flex align-items-baseline mt-3 pe-0 mb-5">
          <label for="details" class="control-label col-md-4"
            >Internal Use Only</label
          >
          <textarea
            name=""
            id="details"
            cols="170"
            rows="3"
            class="form-control detail_area ms-3 pt-0"
            formControlName="internal"
          ></textarea>
        </div>
    </div>
  </div>
  <div class="col-lg-8 col-md-12 pe-0 ps-4" style="overflow-x: auto;">
    <table class="tg w-100" aria-describedby="table1">
      <thead>
        <tr>
          <th class="tg-mg6y"></th>
          <th class="tg-mg6y">Package Code</th>
          <th class="tg-mg6y">Move Qty</th>
          <th class="tg-mg6y">Cost/Piece</th>
          <th class="tg-mg6y">Total Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tg-mg6y">Package</td>
          <td class="tg-z5to">{{package1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="package2"/>
          </td>
          <td class="tg-z5to" colspan="2">
            <button class="vpacs-btn-table" (click)="calcPackage()">Calc Package</button>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Container</td>
          <td class="tg-eydr">{{container1}}</td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="container2"/>
            <div
              *ngIf="isValid['container2']?.invalid && (isValid['container2']?.dirty || isValid['container2']?.touched || submitted)">
              <div *ngIf="isValid['container2'].errors?.['required']  " class="errorValiid"> required field
              </div>
              <div *ngIf="isValid['container2'].errors?.['pattern'] && !isValid['container2'].errors?.['required']  " class="errorValiid"> please provide a number</div>
              <div *ngIf="isValid['container2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
            </div>
          </td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="container3"/>
            <div
              *ngIf="isValid['container3']?.invalid && (isValid['container3']?.dirty || isValid['container3']?.touched || submitted)">
              <div *ngIf="isValid['container3'].errors?.['required']  " class="errorValiid"> required field
              </div>
              <div *ngIf="isValid['container3'].errors?.['pattern']  && !isValid['container3'].errors?.['required'] " class="errorValiid"> please provide a number</div>
              <div *ngIf="isValid['container3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
            </div>
          </td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="container4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Dunnage1</td>
          <td class="tg-z5to">{{dunnage11}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="dunnage12"/>
            <div
            *ngIf="isValid['dunnage12']?.invalid && (isValid['dunnage12']?.dirty || isValid['dunnage12']?.touched || submitted)">
            <div *ngIf="isValid['dunnage12'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['dunnage12'].errors?.['pattern']  && !isValid['dunnage12'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['dunnage12'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="dunnage13"/>
            <div
            *ngIf="isValid['dunnage13']?.invalid && (isValid['dunnage13']?.dirty || isValid['dunnage13']?.touched || submitted)">
            <div *ngIf="isValid['dunnage13'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['dunnage13'].errors?.['pattern'] && !isValid['dunnage13'].errors?.['required'] " class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['dunnage13'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="dunnage14"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Dunnage 2</td>
          <td class="tg-eydr">{{dunnage21}}</td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="dunnage22"/>
            <div
            *ngIf="isValid['dunnage22']?.invalid && (isValid['dunnage22']?.dirty || isValid['dunnage22']?.touched || submitted)">
            <div *ngIf="isValid['dunnage22'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['dunnage22'].errors?.['pattern']  && !isValid['dunnage22'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['dunnage22'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="dunnage23"/>
            <div
            *ngIf="isValid['dunnage23']?.invalid && (isValid['dunnage23']?.dirty || isValid['dunnage23']?.touched || submitted)">
            <div *ngIf="isValid['dunnage23'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['dunnage23'].errors?.['pattern']  && !isValid['dunnage23'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['dunnage23'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="dunnage24"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Topcap</td>
          <td class="tg-z5to">{{top1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="top2"/>
            <div
            *ngIf="isValid['top2']?.invalid && (isValid['top2']?.dirty || isValid['top2']?.touched || submitted)">
            <div *ngIf="isValid['top2'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['top2'].errors?.['pattern']  && !isValid['top2'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['top2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="top3"/>
            <div
            *ngIf="isValid['top3']?.invalid && (isValid['top3']?.dirty || isValid['top3']?.touched || submitted)">
            <div *ngIf="isValid['top3'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['top3'].errors?.['pattern']  && !isValid['top3'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['top3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="top4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Pallet</td>
          <td class="tg-z5to">{{pallet1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="pallet2"/>
            <div
            *ngIf="isValid['pallet2']?.invalid && (isValid['pallet2']?.dirty || isValid['pallet2']?.touched || submitted)">
            <div *ngIf="isValid['pallet2'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['pallet2'].errors?.['pattern']  && !isValid['pallet2'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['pallet2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="pallet3"/>
            <div
            *ngIf="isValid['pallet3']?.invalid && (isValid['pallet3']?.dirty || isValid['pallet3']?.touched || submitted)">
            <div *ngIf="isValid['pallet3'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['pallet3'].errors?.['pattern']  && !isValid['pallet3'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['pallet3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="pallet4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Stretch Wrap/ Strap</td>
          <td class="tg-z5to">{{stretch1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="stretch2"/>
            <div
            *ngIf="isValid['stretch2']?.invalid && (isValid['stretch2']?.dirty || isValid['stretch2']?.touched || submitted)">
            <div *ngIf="isValid['stretch2'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['stretch2'].errors?.['pattern']  && !isValid['stretch2'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['stretch2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="stretch3"/>
            <div
            *ngIf="isValid['stretch3']?.invalid && (isValid['stretch3']?.dirty || isValid['stretch3']?.touched || submitted)">
            <div *ngIf="isValid['stretch3'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['stretch3'].errors?.['pattern']  && !isValid['stretch3'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['stretch3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="stretch4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Kanban Card Holder</td>
          <td class="tg-z5to">{{kanban1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="kanban2"/>
            <div
            *ngIf="isValid['kanban2']?.invalid && (isValid['kanban2']?.dirty || isValid['kanban2']?.touched || submitted)">
            <div *ngIf="isValid['kanban2'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['kanban2'].errors?.['pattern']  && !isValid['kanban2'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['kanban2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="kanban3"/>
            <div
            *ngIf="isValid['kanban3']?.invalid && (isValid['kanban3']?.dirty || isValid['kanban3']?.touched || submitted)">
            <div *ngIf="isValid['kanban3'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['kanban3'].errors?.['pattern']  && !isValid['kanban3'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['kanban3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="kanban4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Other Package</td>
          <td class="tg-z5to">{{otherpkg1}}</td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="otherpkg2"/>
            <div
            *ngIf="isValid['otherpkg2']?.invalid && (isValid['otherpkg2']?.dirty || isValid['otherpkg2']?.touched || submitted)">
            <div *ngIf="isValid['otherpkg2'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['otherpkg2'].errors?.['pattern']  && !isValid['otherpkg2'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['otherpkg2'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="otherpkg3"/>
            <div
            *ngIf="isValid['otherpkg3']?.invalid && (isValid['otherpkg3']?.dirty || isValid['otherpkg3']?.touched || submitted)">
            <div *ngIf="isValid['otherpkg3'].errors?.['required']  " class="errorValiid"> required field
            </div>
            <div *ngIf="isValid['otherpkg3'].errors?.['pattern']  && !isValid['otherpkg3'].errors?.['required']" class="errorValiid"> please provide a number</div>
            <div *ngIf="isValid['otherpkg3'].errors?.['max']  " class="errorValiid"> value should br in 0 to 99999 range</div>
          </div>
          </td>
          <td class="tg-z5to">
            <input type="text" (change)="ipChange()" formControlName="otherpkg4"/>
          </td>
        </tr>
        <tr>
          <td class="tg-mg6y">Other</td>
          <td class="tg-eydr">-</td>
          <td class="tg-eydr">-</td>
          <td class="tg-eydr">-</td>
          <td class="tg-eydr">
            <input type="text" (change)="ipChange()" formControlName="other4"/>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="ppf-footer col-12 d-flex align-items-center flex-wrap p-2">
      <span>
        Cost
        <input type="text" formControlName="grandTotal" class="mx-3"/>
      </span>
    </div>
  </div>
</div>

<div
  class="
    create_footer
    d-flex
    justify-content-end
    align-items-center
    fixed-bottom
  "
>
  <div (click)="onBack()" class="mx-3 text-primary link">BACK</div>
  <button class="black-button me-3" (click)="onSubmit()">
    AUTHORIZE
  </button>
</div>
