
export function toNumber(s:any) {
    if(isNaN(s)) {
        return 0;
    } else {
        return Number(s) ;
    }
}

export function isnull(num:any){
    if(  num===undefined || num===null || num==='' || num=== "0.00" ||  num==="0.0" || num==="0"){
        return null ;
    }else{
        return num;
    }
}

export function toNumtoFixed0(s:any) {
    if(isNaN(s)) {
        return  null;
    } else {
        if(Number(s) === 0){
            return null
        }
        else{
            return Number(s).toFixed(0) ;
        }
    }
}

export function rounding(s:any) {
    if(isNaN(s)) {
        return 0;
    } else {
        return Math.round(s*20)/20;
    }
}

export  function formatCostNull(  cost:any) {
    if(cost === 0) {
        return "";
    }
    return roundEx(cost,2);
}

export function nullStr(val :any) {
    if (val === null || val === undefined ) {
        return "";
    } else {
        return val.toString()
    }
}

export function InchToMm(inch:any) {
    return Math.round(inch * 25.4);
}

export function LbToG(lb:any) {
    return lb * 453.59237 / 1000;
}

export function MmToInch(mm:any) {
    return Math.round((mm / 25.4) * 20) / 20;
}

export function KgToPound(kg:any) {
    return kg * 1000 / 453.59237;
}

export function PoundToKg(kg:any) {
    return kg * 453.59237/1000;
}

export function roundEx(num:any, digit:any) {
    if(num){
        if(isNaN( num ) || num.toString() === "") {
            return num;
        }
        let digit2 = 1;
        let decimalnum = "";
        for(let i=1; i<=digit; i++) {
            digit2 = digit2 * 10;
            decimalnum += "0";
        }
        num = num * digit2;
        num = Math.round(num);
        num = num / digit2;
        const index = num.toString().indexOf(".");
        let retnum = "";
        if(index < 0) {
            const numValue = num.toString();
            if(digit === 0) {
                retnum = numValue;
            } else {                
                retnum = `${numValue}.${decimalnum}`;
            }
        } else {
            decimalnum = num.toString().substr(index, num.toString().length);
            if((decimalnum.length - 1) < digit) {     
                const loopcount = (digit-(decimalnum.length - 1));
                for(let i=0; i<loopcount; i++) {
                    decimalnum += "0";
                }       
                if(digit === 0) {
                    const index2 = index - 1;
                    retnum = num.toString().substr(0, index2);
                } else {
                    const integernum = num.toString().substr(0, index);
                    retnum += integernum + decimalnum;
                }
            } else {
                retnum = num.toString();
            }
        }
        return retnum;
    }else{
        return 0;
    }
}