import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnInit {
  data: any;
  gridApi: any;  columnDefs: any[] = [];


  ngOnInit(): void {
    this.columnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        minWidth: 30,
        maxWidth:50,
        headerClass: 'child-header-color no-border',
        cellStyle: { cursor: 'pointer' }
      },
      {
        headerName: 'Message',
        field: 'message',
        minWidth: 50,
        headerClass: 'child-header-color no-border',
        cellStyle: { cursor: 'pointer' }
      },
    ]
    this.setDataArray()
  }

  setDataArray(){
    this.data = [
      {
        id: '001',
        message:
          'Connecting dB Error : Contact a system administrator, please.',
      },
      {
        id: '002',
        message:
          'Beginning Transaction Error : Contact a system administrator, please.',
      },
      {
        id: '003',
        message:
          'Transaction Commit Error : Contact a system administrator, please.',
      },
      {
        id: '004',
        message:
          'Rollback',
      },
      {
        id: '005',
        message:
          'Transaction Rollback Error : Contact a system administrator, please.',
      },
      {
        id: '006',
        message:
          'SQL Error : Contact a system administrator, please.',
      },
      {
        id: '021',
        message:
          'SMTP Server Error : Contact a system administrator, please.',
      },
      {
        id: '022',
        message:
          'Excel Error : Contact a system administrator, please.',
      },
      {
        id: '022',
        message:
          'Excel Error : Contact a system administrator, please.',
      },
      {
        id: '023',
        message:
          'FTP Error : Contact a system administrator, please.',
      },
      {
        id: '024',
        message:
          'FTP Error : Contact a system administrator, please.',
      },
      {
        id: '040',
        message:
          'Upload Error : Contact a system administrator, please.',
      },
      {
        id: '099',
        message:
          'Runtime Error : Contact a system administrator, please.',
      },
      {
        id: '040',
        message:
          'Upload Error : Contact a system administrator, please.',
      },
      {
        id: '201',
        message:
          'Session Timeout',
      },
      {
        id: '202',
        message:
          'URL Vaiolation',
      },
      {
        id: '203',
        message:
          'Authorization Error',
      },
      {
        id: '204',
        message:
          'Request Timeout',
      },
      {
        id: '221',
        message:
          'File Format Error',
      },
      {
        id: '222',
        message:
          'File Type Error',
      },
      {
        id: '301',
        message:
          'Duplicate Data Error',
      },
      {
        id: '302',
        message:
          'Data Format Error',
      },
      {
        id: '303',
        message:
          'Range Error',
      },
      {
        id: '304',
        message:
          'Required Item Error',
      },
      {
        id: '305',
        message:
          'Not Found in Master',
      },

      {
        id: '399',
        message:
          'Data Error.',
      },

      {
        id: '401',
        message:
          'Login',
      },

      {
        id: '421',
        message:
          'Master is changed',
      },

      {
        id: '441',
        message:
          'Sending Mail',
      },

      {
        id: '442',
        message:
          'Uploading File',
      },

      {
        id: '443',
        message:
          'Download excel sheet size Error',
      },
      {
        id: '444',
        message:
          'Download excel sheet size Error',
      },
      {
        id: '501',
        message:
          'Page Load',
      },
      {
        id: '502',
        message:
          'Constructor',
      },
      {
        id: '503',
        message:
          'Changing Status',
      },
      {
        id: '999',
        message:
          'Trace',
      },
     
    
];
  }

  rowClass: any = (params: any) => { 
    if (params?.node?.rowIndex < 13) {
      return { background: '#ff5101' };
    }
    else if (params?.node?.rowIndex > 12 &&  params?.node?.rowIndex< 26) {
      return { background: '#FFD701' };
    }else{
      return { background: 'skyblue' };
    }
  }
  
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

}
