<div class="d-flex justify-content-between align-items-center mx-2  px-2">
    <p class="page-title">Supplier Maintenance</p>
    <button class="help-btn" (click)="help()">help</button>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
</div>
<div class="alert alert-success show mx-2" *ngIf="successMessage">
    <span>{{successMessage}}</span>
</div>
<div class="vpacs-shadow m-3 p-2" *ngIf="haveAccess">
    <div class="filter d-flex" (keyup.enter)="onSearch()">
        <div class="d-flex flex-wrap align-items-center col-lg-3 col-md-6 col-12">
            <label for="suppliercode" class="col-4">Code</label>
            <input type="text" id="suppliercode" style="width: 90%;" [(ngModel)]="suppliercode"/>
        </div>
        <div class="d-flex flex-wrap align-items-center col-lg-3 col-md-6 col-12 ">
            <label for="tmmccode" class="col-4">TMMC Supplier code</label>
            <input type="text" id="tmmccode" style="width: 90%;" [(ngModel)]="tmmccode"/>
        </div>
        <div class="d-flex flex-wrap align-items-center col-lg-3 col-md-6 col-12 ">
            <label for="nummicode" class="col-4">NUMMI Supplier Code</label>
            <input type="text" id="nummicode" style="width: 90%;" [(ngModel)]="nummicode"/>
        </div>
        <div class="d-flex flex-wrap align-items-center col-lg-3 col-md-6 col-12">
            <label for="tmmccode" class="col-4">TPNA Vendor Code</label>
            <input type="text" id="tmmccode" style="width: 90%;" [(ngModel)]="vendorcode"/>
        </div>
        <div class="d-flex flex-wrap  align-items-center col-lg-3 col-md-6 col-12">
            <label for="name" class="col-4">Name</label>
            <input type="text" id="name" style="width: 90%;" [(ngModel)]="name"/>
        </div>
        <div class="d-flex flex-wrap align-items-center col-lg-3 col-md-6 col-12">
            <label for="shortname" class="col-4">Short Name</label>
            <input type="text" id="shortname" style="width: 90%;" [(ngModel)]="shortname"/>
        </div>
        <div class="d-flex flex-wrap  align-items-center col-lg-3 col-md-6 col-12 ">
            <button class="black-button" (click)="onSearch()">Search</button>
            <button class="vpacs-btn-table mx-3" [disabled]="!haveAddAccess" style="height: 30px;" (click)="addSupplier()">ADD</button>
        </div>
    </div>
</div>
<div class="grid-agg grid-height-supplier-maintenance" *ngIf="haveAccess">
    <ag-grid-angular style="width:100%;height:100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
        (window:resize)="sizeToFit()" [rowHeight]="30" [animateRows]="true" [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true" [showOpenedGroup]="true"
        [autoGroupColumnDef]="autoGroupColumnDef" [suppressHorizontalScroll]="false" [pagination]="true"
        [headerHeight]="30" [suppressPaginationPanel]="true" [icons]="icons"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [domLayout]="domLayout" [rowClassRules]="rowClassRules" [floatingFiltersHeight]="40"
        (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>

</div>
<router-outlet></router-outlet>