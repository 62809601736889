import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PkgFabMainService {

  URL = this.constantsService.MASTERCOST ;
  exchangeRate = 'master/packagefabricator';
  fabFilter: any;
  fabCommodities: any;
  filterdata: any;
  paginationValues: any;
  flag: any;
  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }


  onloadPkgFab(params:any): Observable<any> {

    return this.http.post(this.URL + 'master/packagefabricator/search', params,
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }
  onloadPkgFabContact(supcode:any): Observable<any> {
    return this.http.get(this.URL + `master/packageFabricator/contact/?SupplierCode=${supcode}`, 
      {headers: this.constantsService.httpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  addExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + this.exchangeRate), param, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }
  updateExchangeRate(param: any): Observable<any> {
    return this.http.patch((this.URL + this.exchangeRate), param, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }
  deleteExchangeRate(deleteObject: any): Observable<any> {
    return this.http.delete((this.URL + this.exchangeRate),
    {body: deleteObject,headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }
  updatePkgFabContact(guid:any,supcode:any): Observable<any> {
    return this.http.get(this.URL + `master/packagefabricator/contact/authority?SupplierCode=${supcode}&ToyotaGuid=${guid}`, 
      {headers: this.constantsService.httpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  commadityBtn():Observable<any> {
    return this.http.get(this.URL + `master/packageFabricator/getCommodities`, 
      {headers: this.constantsService.httpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  updateFabCont(params:any): Observable<any> {
    return this.http.patch((this.URL + 'master/packagefabricator/contact/authority'), params, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  setfabFilters(fabFilter: any) {
    this.fabFilter = fabFilter;
  }
  getfabFilters() {
    return this.fabFilter;
  }

  setfabCommodities(fabCommodities: any) {
    this.fabCommodities = fabCommodities;
  }
  getfabCommodities() {
    return this.fabCommodities;
  }

  setfilter(filter: any) {
    this.filterdata = filter;
  }
  getfilter() {
    return this.filterdata;
  }

  setPaginationValues(data:any){
    this.paginationValues=data
  }

  getPaginationValues(){
    return this.paginationValues;
  }

  clearPaginationValues(){
    this.paginationValues=undefined;
  }

  setNavFlag(flag: any) {
    this.flag = flag;
  }
  getNavFlag() {
    return this.flag;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

}
