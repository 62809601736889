import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { RadioBtnCellRender } from '../../gridrender/radio-btn-cell-render';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-rack-receivement',
  templateUrl: './rack-receivement.component.html',
  styleUrls: ['./rack-receivement.component.scss']
})
export class RackReceivementComponent implements OnInit {

  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  shipNumber = "";
  fieldData: any = {};
  selected: any[]=[];
  pageName: any;

    constructor(public dialog:MatDialog,public route: ActivatedRoute,private readonly newShipmentService: NewshipmentService,
      public router: Router,private readonly location: Location) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
    
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit()   {
    if (sessionStorage.hasOwnProperty("rackData")) {
      this.rackData = JSON.parse(sessionStorage.getItem("rackData") || '');
      this.shipNumber = sessionStorage.getItem("shipNumber") || ""
    }
   
    if (this.router.url.includes('/shipment/rackReceivementDetail')) {
      this.newShipmentService.ReceiveOnloadDetail(this.rackData.AUTHNUMBER, this.shipNumber).subscribe((result: any)=> {
      this.fieldData=  Object.keys(result?.data1).length === 0 ? [] : result?.data1
      this.displayRowData=result?.data2 === -1 ? [] : result.data2
      sessionStorage.setItem("packagecode",this.fieldData?.packagecode);
      this.rowCount=this.displayRowData?.length
    });
  } else {
    this.newShipmentService.ReceiveOnload(this.rackData.AUTHNUMBER, this.shipNumber).subscribe((result: any)=> {
      this.fieldData=  Object.keys(result?.data1).length === 0 ? [] : result?.data1;
      this.displayRowData=result?.data2 === -1 ? [] : result.data2
      sessionStorage.setItem("packagecode",this.fieldData?.packagecode);
      this.rowCount=this.displayRowData?.length
    });
  }

    
    this.columnDefs = [
      {
        headerName: "Serial Number", field: "SERIALNUMBER", width: 100
      },
      {
        headerName: "Receive", field: "receive",
        children:[
          {headerName: "OK", field: "ok",width:20,cellRendererFramework:RadioBtnCellRender,cellRendererParams:{
            fieldName:"ok",
            clicked:(field:any)=>{
             this.radioHandler(field)
            }
          }},
          {headerName: "NG", field: "ng",width:20,cellRendererFramework:RadioBtnCellRender,cellRendererParams:{
            fieldName:"ng",
            clicked:(field:any)=>{
              this.radioHandler(field)
            }
          }},
        ]
      },
      {
        headerName: "Reason", field: "REASON",  width: 100
      }   
    ];   
  }
  radioHandler(field:any) {
    this.selected=[...this.selected,{...field}]
  }

  
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  
  save(){
    const body:any=this.selected
   
    if (this.router.url.includes('/shipment/rackReceivementDetail')) {
      this.newShipmentService.ReceiveSaveDetail(body).subscribe(_result=>{
        this.router.navigate(['/shipment/detail'])
      })
    } else {
      this.newShipmentService.ReceiveSave(body).subscribe(_result=>{
        this.router.navigate(['/shipment/newShipment'])
      });
    }
  }

  onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
  }

    cancel() {
      this.location.back()
    }

}
