<p  class="page-title mx-3">V-Pacs User's Manual</p>
<div class="vpacs-shadow m-3 p-2">
    <div class="filter mx-2">
        <div class="d-flex flex-column" >
          <div class="d-flex align-items-center mb-2" *ngFor="let item of menu; let i = index">
            <a [routerLink]="['/master-help', item.funcid, item.funcname,'']">{{item.funcid}}.{{item.funcname}}</a>
          </div>
         
          <div class="d-flex align-items-center mb-2">
            <a [routerLink]="['/error']">Error Message List</a>
          </div>
        </div>
      </div>
      
  </div>
 