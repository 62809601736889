import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  public stepURL = '../../../assets/json/step.json';
  httpHeaderDetails: any;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) {
    this.httpHeaderDetails = new HttpHeaders({'x-api-key': environment.API_CALL});
   }

  loadSteps() : Observable<any>{
    return this.http.get(this.stepURL).pipe(catchError(this.handleError))
  }

  getTrackingCount(supplierNumber:any,fromdate:any,todate:any,onlyDo:number,partNumber:string,modelNumber:any) : Observable<any>{
    let URL = this.constantsService.EXTERNAL + `tracking/count?supplierCode=${supplierNumber}&onlyDo=${onlyDo}&partNumber=${partNumber}&modelCode=${modelNumber}`;
    if(fromdate){
      URL = URL + `&fromdate=${fromdate}`;
    }
    if(todate){
      URL = URL + `&todate=${todate}`;
    }
    return this.http.get(URL,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) ) 
  }

  trackingSubmit(body:any,queryParams:any) : Observable<any>{
    const submitURL = this.constantsService.EXTERNAL + `tracking?partNumber=${queryParams.partNumber}&pkgStartDate=${queryParams.pkgStartDate}&plantCode=${queryParams.plantCode}&supplierCode=${queryParams.supplierCode}&modelCode=${queryParams.modelCode}&stepNo=${queryParams.stepNumber}`              
    return this.http.put<any>(submitURL, body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))

  }
  
  loadTrackingData(supplierNumber:any, incrementor:number, rowRangeMin:number,fromdate:any,todate:any,onlyDo:number,partNumber:string,modelNumber:any) : Observable<any>{
    let URL = this.constantsService.EXTERNAL + `tracking?supplierCode=${supplierNumber}&onlyDo=${onlyDo}&incrementor=${incrementor}&rowMin=${rowRangeMin}&partNumber=${partNumber}&modelCode=${modelNumber}`;
    if(fromdate){
      URL = URL + `&fromdate=${fromdate}`;
    }
    if(todate){
      URL = URL + `&todate=${todate}`;
    }
    return this.http.get(URL,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) ) 
  }

  readinessOnload(queryParams:any) : Observable<any>{
    const params = new HttpParams()
      .set('hidPlantCode', queryParams.plantCode)
      .set('hidPartNumber', queryParams.partNumber)
      .set('hidSupplierCode', queryParams.supplierCode)
      .set('hidPkgStartDate',queryParams.pkgStartDate )
      .set('hidModelCode', queryParams.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'do/readiness',{ params: params, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
 
  }

  readinessUpdate(updateObject:any) : Observable<any>{
    return this.http.post((this.constantsService.EXTERNAL + 'do/readiness'), updateObject, { responseType: 'json',headers: this.constantsService.httpOptions })
    .pipe(catchError(this.handleError))
 
  }

  sendPrePpf(queryParams:any) : Observable<any>{
    const params = new HttpParams()
      .set('hidPlantCode', queryParams.plantCode)
      .set('hidPartNumber', queryParams.partNumber)
      .set('hidSupplierCode', queryParams.supplierCode)
      .set('hidPkgStartDate',queryParams.pkgStartDate )
      .set('ppfCode',queryParams.ppfNumber )
      .set('hidModelCode', queryParams.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'do/sendpreppf',{ params: params, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
 
  }

  sendFinalPpfOnload(queryParams:any){
    const params = new HttpParams()
    .set('hidPlantCode', queryParams.plantCode)
    .set('hidPartNumber', queryParams.partNumber)
    .set('hidSupplierCode', queryParams.supplierCode)
    .set('hidPkgStartDate',queryParams.pkgStartDate )
    .set('ppfCode',queryParams.ppfNumber )
    .set('hidModelCode', queryParams.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'do/sendFinalPpf', { params: params, headers: this.constantsService.httpOptions})
  }

  sendFinalPpf(body:any){
    return this.http.post((this.constantsService.EXTERNAL + 'do/sendFinalPpf'), body , {responseType: 'json',headers: this.constantsService.httpOptions })
          .pipe(catchError(this.handleError))
  }
  updatePrePpf(updateObject:any) : Observable<any>{
    return this.http.post((this.constantsService.EXTERNAL + 'do/sendpreppf'), updateObject, { responseType: 'json',headers: this.constantsService.httpOptions })
    .pipe(catchError(this.handleError))
 
  }

  shipTrialOnload(queryParams:any) : Observable<any>{
    const params = new HttpParams()
      .set('hidPlantCode', queryParams.plantCode)
      .set('hidPartNumber', queryParams.partNumber)
      .set('hidSupplierCode', queryParams.supplierCode)
      .set('hidPkgStartDate',queryParams.pkgStartDate )
      .set('hidModelCode', queryParams.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'do/shipTrial',{ params: params,headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
 
  }

  updateshipTrial(updateObject:any) : Observable<any>{
    return this.http.post((this.constantsService.EXTERNAL + 'do/shipTrial'), updateObject, { responseType: 'json',headers: this.constantsService.httpOptions})
    .pipe(catchError(this.handleError))
 
  }

  authdownloadFile(queryParams:any) : Observable<any>{
    const params = new HttpParams()
      .set('hidPlantCode', queryParams.plantCode)
    return this.http.get(this.constantsService.EXTERNAL + 'do/authDownload',{ params: params,headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
 
  }

  forcastOnload(queryParams:any) : Observable<any>{
    const params = new HttpParams()
      .set('hidPlantCode', queryParams.plantCode)
      .set('hidPartNumber', queryParams.partNumber)
      .set('hidSupplierCode', queryParams.supplierCode)
      .set('hidPkgStartDate',queryParams.pkgStartDate )
      .set('hidModelCode', queryParams.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'do/forecast',{ params: params,headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
 
  }

  updateForcast(updateObject:any) : Observable<any>{
    return this.http.post((this.constantsService.EXTERNAL + 'do/forecast'), updateObject, { responseType: 'json',headers: this.constantsService.httpOptions})
    .pipe(catchError(this.handleError))
  }

  rejectOrApproval(body:any) : Observable<any>{
    return this.http.post((this.constantsService.EXTERNAL + 'do/approvalOrRejct'), body, {headers: this.constantsService.httpOptions})
    .pipe(catchError(this.handleError))
  }

  cancelOk(authNumber:any) : Observable<any>{
    const URL = this.constantsService.EXTERNAL + `pkg/dopage/cancelok?authNumber=${authNumber}`
    return this.http.get(URL,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) ) 
  }

  cancelNg(authNumber:any) : Observable<any>{
    const URL = this.constantsService.EXTERNAL + `pkg/dopage/cancelng?authNumber=${authNumber}`
    return this.http.get(URL,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) ) 
  }

  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error.message);
	}
}

