<div class="popup-container" [formGroup]="fileDocForm">
  <header>
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex justify-content-between align-items-center header">
      <div class="page-title">{{modalHeader}} </div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
    </div>
  </header>
  <div class="alert alert-danger mx-2" *ngIf="errorMessage2.length > 0">
    <div *ngFor="let message of errorMessage2" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{message}}</span>
      </div>
    </div>
</div>
  <div class="popup-contents">
    <div>
      <div class="d-flex flex-wrap align-items-center p-2 px-4 col-12">
        <label class="fontStyle">
          File's extension must be .xlsx 
        </label>
        <span class="col-12 my-1">File<small>*</small></span>
        <label
          for="documentation"
          class="vpacs-btn-table col-4"
          (change)="handleUpload($event)"
        >
          CHOOSE FILE
          <input
            type="file"
            id="documentation"
            style="display: none"
            accept=".pdf, .xlsx, .doc"
            size="60"
            (change)="handleUpload($event)"
          />
        </label>
        <span class="col-8">{{ fileDocForm.controls["fileName"].value }}</span>
        <small>{{ errorMessage1 }}</small>
        <div *ngIf="(fileDocForm.get('fileName')?.invalid)" class="col-12">
          <small *ngIf="(fileDocForm.get('fileName')?.touched && fileDocForm.get('fileName')?.hasError('required'))">
              File is required.
          </small>
      </div>
      </div>
    </div>

    <div
      class="
        footer
        d-flex
        justify-content-end
        align-items-center
        fixed-bottom
        mt-2
        pt-2
        pb-2
      "
    >
      <div mat-dialog-close class="mx-3 cancel">Cancel</div>
      <button class="black-button mx-3" (click)="nextBtn()">IMPORT</button>
    </div>
  </div>
</div>
