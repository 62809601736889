import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'plant-maintenance-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'"
    class="vpacs-btn-table" 
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler()">{{buttonName}}</button>
  </div>
  `,
})

export class CostCenterBtnCellRender implements ICellRendererAngularComp {
  userDelete: any;
  userUpdate: any;
  constructor(
    private readonly state: ExportStateService,
    ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  isDisabed =  false;
  dullFlag = false;
  disableFlag = false;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'BUDGET'){
      this.buttonName = 'BUDGET';
    }
    else if (params.colDef.headerName === 'UPDATE'){
      this.buttonName = 'UPDATE'; 
      this.getUserData();
    }
    else if (params.colDef.headerName === 'DELETE'){
      this.buttonName = 'DELETE'; 
      this.getUserData();
    }
  }
  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.userDelete = data.role.p_userdelete;
        this.userUpdate = data.role.p_usersave
        if (this.buttonName === 'DELETE') {
          if  (!this.userDelete || Number(this.params.data.used > 0)){
            this.disableFlag = true;
            this.dullFlag = true;
          }
          else {
            this.disableFlag = false;
            this.dullFlag = false;
          }
        }
        else if (this.buttonName === 'UPDATE'){
          if (!this.userUpdate)  {
            this.disableFlag = true;
            this.dullFlag = true;
          }
          else{
            this.disableFlag = false;
            this.dullFlag = false;
          }
        }
      }
    })
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data)
  }
}