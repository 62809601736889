import { formatDate } from '@angular/common'
import { Component, OnInit, Inject } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { BudgetMainBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/budget-main-btn-cell-render'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { DeleteFabComponent } from '../../../delete-fab/delete-fab.component'
import { AddBudgetComponent } from '../add-budget/add-budget.component'

@Component({
  selector: 'app-budget-maintenance',
  templateUrl: './budget-maintenance.component.html',
  styleUrls: ['./budget-maintenance.component.scss'],
})
export class BudgetMaintenanceComponent implements OnInit {
  component = 'Select'
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'

  plant: any
  supplier: any
  plantCode: any
  costCenter: any
  plantResult: any
  budgetResult: any
  result: any
  Budget: any
  ringi: any
  haveAccess: any
  haveAddAccess: any
  modifyAccess: any
  deleteAccess: any
  saveAccess: any
  errorList: string[] = [];
  successMessage: string[] | string = [];
  //ag-grid-specs
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  paginationPageSize = 10
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : ''
  }
  constructor(
    private readonly state: ExportStateService,
    public plantService: PlantService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<BudgetMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private readonly router: Router,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
    this.plantCode = data?.plantcode
    this.costCenter = data?.costcenter
    this.Budget = this.data?.field?.budget
  }
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview
        this.haveAddAccess = res.role?.p_usersave
        this.deleteAccess = res.role?.p_userdelete
        this.haveAccess = true
        if (this.haveAccess) {
          this.budgetOnload()
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
      },
    })
    if (this.data.mode === 'ADD') {
      this.modalHeader = 'Add Plant'
      this.buttonValue = 'ADD'
    } else if (this.data.mode === 'UPDATE') {
      this.modalHeader = 'Update Plant'
      this.buttonValue = 'UPDATE'
    }
    this.columnDefs = [
      {
        headerName: 'Budget',
        field: 'budget',
        sortable: true,
        unSortIcon: true,
        minWidth: 110,
      },
      {
        headerName: 'UPDATE',
        field: 'update',
        sortable: false,
        unSortIcon: true,
        minWidth: 110,
        cellRendererFramework: BudgetMainBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.updateBtn(field)
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'history',
        sortable: true,
        minWidth: 110,
        unSortIcon: false,
        cellRendererFramework: BudgetMainBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteBudget(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
    ]
  }
  budgetOnload(): void {
    setTimeout(() => {this.spinnerService.start()})
    this.plantService.budgetOnload(this.plantCode, this.costCenter).subscribe({
      next: (res: any) => {
        this.plantResult = res.PlantResult[0]
        this.budgetResult = res.BudgetResult[0]
        this.displayRowData = res.BudgetResult
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  deleteBudget(field: any): void {
    const dialogRef = this.dialog.open(DeleteFabComponent, {
      data: { field, mode: 'Budget' },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        this.spinnerService.start()
        this.plantService
          .deleteBudget(this.plantCode, this.costCenter, field?.budget)
          .subscribe({
            next: (res: any) => {
              const result = res.status ? 'Success' : '';
              const _res = {...res, result, data: {message: res.message}}
            this.afterDialogClose(_res)
            this.gridApi?.hideOverlay()
            },
            error: () => {
              this.spinnerService.stop()
            },
          })
      } 
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    this.apiCall(this.paginationPageSize)
  }

  apiCall(_paginationPageSize: number) {
    this.rowCount = this.displayRowData.length
    this.gridApi?.redrawRows()
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  onSearch() {}

  updateBtn(field: any) {
    const dialogRef = this.dialog.open(AddBudgetComponent, {
      width: '40%',
      data: {
        mode: 'UPDATE',
        field,
        plantCode: this.plantResult?.plantcode,
        PlantName: this.plantResult?.name,
        plantId: this.plantResult?.plantid,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (
          result.data?.result === 'SUCCESS' &&
          result.data?.mode === 'Update'
        ) {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }
  dialogOpen() {
    const dialogRef = this.dialog.open(AddBudgetComponent, {
      width: '40%',
      data: {
        mode: 'ADD',
        field: 'any',
        plantCode: this.plantResult?.plantcode,
        PlantName: this.plantResult?.name,
        costCenter: this.costCenter,
        plantId: this.plantResult?.plantid,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS' && result.data?.mode === 'Add') {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }
  afterDialogClose(data: any) {
    if (data?.result === 'Success' || data?.result === 'SUCCESS') {
      this.successMessage = data.mode === 'Update' ? data?.data.message[0] : data?.data.message;
      this.budgetOnload();
    } else if (data?.result === 'Failure' || data?.result === 'FAILURE') {
      this.errorList = data?.data.message;
    } else {
      this.errorList = [];
      this.successMessage = [];
    }
    setTimeout(() => {
      this.errorList = [];
      this.successMessage = [];
    }, 5000);
  }
  Close() {
    this.errorList = [];
    this.successMessage = [];
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent', mode: 'X' })
  }
  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 173])
  }
}
