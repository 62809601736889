<div class="wrapper bg-white" [formGroup]="sendFinalForm">
    <div class="page-title p-2 text-capitalize ">MESSAGE</div>
    <div class="mat-typography content px-2">
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">RECEIVE FROM TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Date
                        <span class="text-black">{{data?.gridDate | date: 'MM/dd/yyyy'}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Status
                        <span class="text-black">{{response?.status}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number
                        <span class="text-black">{{response?.partNumber}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">PackageStartDate
                        <span class="text-black">{{response?.pkgStartDate | date: 'MM/dd/yyyy'}}</span>
                    </div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">MessageFrom
                        <span class="text-black">{{response?.msgFrom}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2 pe-4">
                        <label>Message</label>
                        <textarea readonly rows="2">{{response?.message}}</textarea>
                    </div>
                    <div *ngIf="stepTypeFlag" class="d-grid col-lg-3 col-md-6 col-12 my-2 pe-4">
                        <label>Request as Final</label>
                        <textarea readonly rows="2">{{response?.requestAsFinal}}</textarea>
                    </div>
                    <div *ngIf="hazmatComment" class="d-grid col-lg-3 col-md-6 col-12 my-2 pe-4">
                        <label>Hazmat Message</label>
                        <textarea readonly rows="2">{{hazmatComment}}</textarea>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">PACKAGE REVIEW</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Result
                        <span class="text-black">{{packageReview?.result}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Requested
                        <span class="text-black">{{packageReview?.requested}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Requested Changes
                        <span class="text-black">{{packageReview?.requestChanges}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Reason
                        <span class="text-black">{{packageReview?.reason}}</span>
                    </div>
                    <div class="d-flex flex-column col-lg-3 col-md-6 col-12 my-2">Reason Requested
                        <span class="text-black">{{packageReview?.reasonChanges}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-grid">
                    <span>
                        <button class="vpacs-btn-table" (click)="ppf()">PPF</button>
                    </span>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">
                        <span class="col-6" *ngIf="!rejectReasonFlag">Comment</span>
                        <span class="col-6" *ngIf="rejectReasonFlag">Reject Reason</span>
                        <textarea rows="4" formControlName="comment"></textarea>
                        <small *ngIf="sendFinalForm.get('comment').hasError('maxlength')">Maximum 250 Characters allowed</small>
                    </div>
                    <div *ngIf="disclaimerFlag">
                        <span>I have tested this container</span>
                        <span>I have verified that all the part and packaging specifications are correct</span>
                        <span class="col-6 d-flex align-items-center">
                            <input type="radio" id="yes" [value]=1 formControlName="q1_1_yn">&nbsp;
                            <label for="yes">Yes</label>
                            <input type="radio" id="no" [value]=0 class="ms-2" formControlName="q1_1_yn">&nbsp;
                            <label for="no">No</label>
                        </span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="footer-dialog d-flex mx-2 fixed-bottom">
        <div class="mx-3 cancel" (click)="onCancel()">Cancel</div>
        <button class="black-button mx-3" [disabled]="btnDisabled || sendFinalForm.invalid" *ngIf="okFlag" (click)="submit()">Ok</button>
        <button class="black-button mx-3" [disabled]="btnDisabled  || sendFinalForm.invalid" (click)="approveReject(1)"
            *ngIf="approvalFlag">Approve</button>
        <button class="black-button mx-3" [disabled]="btnDisabled  || sendFinalForm.invalid" (click)="approveReject(2)"
            *ngIf="rejectFlag">Reject</button>
    </div>
</div>