import { Component } from '@angular/core';

@Component({
  selector: 'app-use-backup-master',
  templateUrl: './use-backup-master.component.html',
  styleUrls: []
})
export class UseBackupMasterComponent {


}
