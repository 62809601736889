<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Broadcast Calc</p>
    <div>
      <button class="help-btn" (click)="help()">help</button>
    </div>
</div>
<div id='err' class="alert alert-danger m-3" *ngIf="errorMessage">
    <span>{{errorMessage}}</span>
  </div>
<div class="row m-3 content-row">
    <div class="col-md-4 align-items-center">
        <div class="row pe-3">
            <div class="col-md-5 titleColumn">
                <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-7 contentColumn p-0">
                <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 ">
        <div class="row pe-3">
            <div class="col-md-5 titleColumn">
                <div *ngFor="let title of partInfo1" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-7 contentColumn p-0">
                <div class="ps-4 py-2" class="row-even py-2 px-4">
                    <input type="text" [(ngModel)]="txtTrailersInSystem">
                </div>
                <div class="ps-4 py-2" class="row-odd py-2 px-4">
                    <input type="text" [(ngModel)]="txtContainerPerTrainer">
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 ">
        <div class="row titleColumn contents">
            <span class="col-md-5 ps-4 py-2">Required Quantity</span>
            <div class="ps-4 py-2 d-flex col-md-7">
                <button class="vpacs-btn-table roboto-medium col-md-3" (click) = "calc()"> CALC</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 titleColumn">
                <div *ngFor="let title of partInfo2" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-7 contentColumn p-0">
                <div class="ps-4 py-2 d-flex row-odd">
                        <mat-radio-button class="align-self-center" name="rad" (change)="onRadioValChange($event)" [checked]="rdoBroadcastCalcMethod1Checked" [value]="1"></mat-radio-button>
                        <input type="text" class="ms-3" [(ngModel)]="txtBroadcastReqSystem" readonly disabled>
                 </div>
                 <div class="ps-4 py-2 d-flex row-even">
                        <mat-radio-button class="align-self-center" name="rad" (change)="onRadioValChange($event)" [checked]="rdoBroadcastCalcMethod2Checked" [value]="2"></mat-radio-button>
                        <input type="text" class="ms-3" [(ngModel)]="txtBroadcastReqManual">
                 </div>
            </div>
        </div>
    </div>
</div>
<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="onCancel()"> BACK </button>
    <button class="black-button me-3 roboto-medium" (click)="onSubmit()" >OK</button>
</div>