<div class="popup-container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">Supplier System Days</div> 
    <div>
    <button class="help-btn"(click)="help()">Help</button>
  </div>
  </div>
  <div class="popup-contents">
    <div  id='err' class="alert alert-danger m-3" *ngIf="errorList !== ''">
      <span  >{{errorList}}</span>
    </div>
    <div id='err' class="alert alert-success m-3" *ngIf="successMessage !== ''">
      <span  >{{successMessage}}</span>
    </div>
    <div class="filter d-flex m-3 heading-row">
      <table aria-describedby="supplier-plant">
        <tr class="d-none">
          <th>Calculation</th>
        </tr>

        <tbody>
          <tr>
            <td rowspan="3" class="table1" style="padding-top: 48px">
              Supplier
            </td>
            <td rowspan="3" class="table1" style="padding-top: 48px">
              {{ supplierShortName }}
            </td>
            <td
              rowspan="2"
              colspan="2"
              class="table2"
              style="padding-top: 38px"
            >
              E
            </td>
            <td class="table1">1/W</td>
            <td class="table1">2/W</td>
            <td class="table1">3/W</td>
            <td class="table1">4/W</td>
            <td class="table1">1/D</td>
            <td class="table1">2/D</td>
            <td class="table1">4/D</td>
            <td class="table1">8/D</td>
          </tr>
          <tr>
            <td
              *ngFor="
                let eRow of supplierCalculationData.eRow | keyvalue: onCompare
              "
            >
              <input type="text" [(ngModel)]="eRow.value" disabled />
            </td>
          </tr>
          <tr>
            <td colspan="2" class="table2">F</td>
            <td
              *ngFor="
                let fRow of supplierCalculationData.fRow | keyvalue: onCompare
              "
            >
              <input type="text" [(ngModel)]="fRow.value" disabled />
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="table1" style="padding-top: 38px">Plant</td>
            <td rowspan="2" class="table1" style="padding-top: 38px">
              {{ plantName }}
            </td>
            <td colspan="2" class="table1">E</td>
            <td
              *ngFor="
                let eRow of plantCalculationData.eRow | keyvalue: onCompare
              "
            >
              <input type="text" [(ngModel)]="eRow.value" disabled />
            </td>
          </tr>

          <tr>
            <td colspan="2" class="table1">F</td>
            <td
              *ngFor="
                let fRow of plantCalculationData.fRow | keyvalue: onCompare
              "
            >
              <input type="text" [(ngModel)]="fRow.value" disabled />
            </td>
          </tr>
          <tr>
            <td colspan="4" class="table1">Other</td>
            <td><input type="text" [(ngModel)]="otherRow.o1" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o2" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o3" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o4" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o5" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o6" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o7" (change)="totalCalc(result, result2, 1)"/></td>
            <td><input type="text" [(ngModel)]="otherRow.o8" (change)="totalCalc(result, result2, 1)"/></td>
          </tr>
          <tr>
            <td colspan="4" class="table1">TOTAL</td>
            <td *ngFor="let tRow of totalRow | keyvalue: onCompare">
              <input type="text" [(ngModel)]="tRow.value" disabled />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="close black-button" (click)="onNoClick()">
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
    <div
      class="
        footer
        d-flex
        justify-content-end
        align-items-center
        fixed-bottom
        mt-2
        pt-2
        pb-2
      "
    >
      <button class="cancel me-3 roboto-bold" (click)="onNoClick()">
        CANCEL
      </button>
      <button
        class="submit black-button me-3 roboto-bold"
        (click)="updateCalculation()" [disabled]="!haveAddAccess"
      >
        OK
      </button>
    </div>
  </div>
</div>
