import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HomeService } from 'src/app/internal-vpacs/services-internal/home/home.service';
import * as FileSaver from 'file-saver-es'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-necessary-approved-items',
  templateUrl: './necessary-approved-items.component.html',
  styleUrls: ['./necessary-approved-items.component.scss']
})
export class NecessaryApprovedItemsComponent implements OnInit {
  info: any;
  infoContents: any;
  dateFormat = 'MM/dd/YYYY'
  sentDate: any;
  comment: any;
  CommentflagDsbld = false;

  constructor(
    public dialogRef: MatDialogRef<NecessaryApprovedItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly homeService: HomeService,
    private readonly spinnerService: NgxUiLoaderService,
    public datepipe: DatePipe,
    private readonly message: MatSnackBar
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.start();
    });

    this.info = ['Date', 'Title'];
    this.sentDate = this.datepipe.transform(this.dataStore.senddate.split('T')[0], this.dateFormat);
    this.infoContents = [
      this.sentDate,
      this.dataStore.title
    ];
    this.getDeptApprovedItems();
  }

  getDeptApprovedItems() {
    const params = {
      SendDate: this.sentDate,
      ToSpecialist: this.dataStore.tospecialist,
      SendCount: Number(this.dataStore.sendcount)
    }
    this.homeService.getDeptApprovedItems(params).subscribe({
      next: (res: any) => {
        if(res?.status === 'Success'){
          this.comment = res?.Result?.result[0]?.note;
          this.CommentflagDsbld = res?.Result?.result[0]?.CommentflagDsbld;
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  markAsUnRead() {
    this.spinnerService.start()
    const params = {
      SendDate: this.sentDate,
      ToSpecialist: this.dataStore.tospecialist,
      SendCount: Number(this.dataStore.sendcount)
    }
    this.homeService.markAsUnRead(params).subscribe({
      next: (res: any) => {
        if(res?.status === 'SUCCESS'){
          this.openMessageBox(res?.message, 'success')
          this.dialogRef.close({ event: 'CloseEvent' });
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  downloadApproved() {
    this.spinnerService.start()
    const params = {
      SendDate: this.sentDate,
      ToSpecialist: this.dataStore.tospecialist,
      SendCount: Number(this.dataStore.sendcount)
    }
    this.homeService.downloadApproved(params).subscribe({
      next: (res: any) => {
        if(res?.status === 'SUCCESS'){
          const toConvert = res.data;
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`
          FileSaver.saveAs(file, res.FileName)
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    })
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

}
