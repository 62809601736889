<div class="d-flex justify-content-between align-items-center mx-3">
    <p class="page-title">Trial Order</p>
</div>

<div class="alert alert-danger m-3" *ngIf="errorMsg?.length>0">
    <ng-container *ngFor="let error of errorMsg ">
      <div>{{error}}</div>
    </ng-container>
</div>

<div class="accordion-container m-3 pb-5 mt-0">
    
    <div class="row m-3 content-row mx-0 mt-0">
        <div class="col-md-4 align-items-center px-0">
            <div class="row mt-2 mx-1 heading-row">
                <table class="table" aria-describedby="plant">
                    <tbody class="d-flex">
                        <th class="col-md-5 titleColumn" id="plant">
                            <div class="contents2 ps-4 py-3">
                                Plant
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Family Code
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Model Code
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Part Number
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Supplier
                            </div>
                        </th>
                        <div class="col-md-7 contentColumn rowClass2">
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdPlant_InnerHtml}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdFamily_InnerHtml ?? familyCode}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdModel_InnerHtml ?? modelCode}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdPartNumber_InnerHtml ?? partNumber}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{ htmlData?.tdSupplier_InnerHtml?.shortname }}
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-4 ps-3">
            <div class="row mt-2 mx-1 heading-row">
                <table class="table" aria-describedby="plant">
                    <tbody class="d-flex">
                        <th class="col-md-5 titleColumn" id="plant">
                            <div class="contents2 ps-4 py-3">
                                QPC
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Container
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Dunnage1
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Dunnage2
                            </div>
                        </th>
                        <div class="col-md-7 contentColumn rowClass2">
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdQPC_InnerHtml}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdContainer_InnerHtml}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdDunnage1_InnerHtml}}
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                {{htmlData?.tdDunnage2_InnerHtml}}
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row m-3 content-row mx-0">
        <button class="black-button roboto-medium" (click)="onDisplayPPF()">Display PPF</button>
        <button class="black-button roboto-medium" (click)="onComparePPF()">Compare PPF</button>
    </div>

    <div class="row m-3 content-row mx-0">
        <div class="col-md-4 align-items-center px-0">
            <div class="row mx-1 heading-row">
                <table class="table" aria-describedby="plant">
                    <tbody class="d-flex">
                        <th class="col-md-5 titleColumn" id="plant">
                            <div class="contents2 ps-4 py-3">
                                Trial Due Date(mm/dd/yyyy)
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Qty of Part
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Trial Plant
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Dock
                            </div>
                            <div class="contents2 ps-4 py-3">
                                ATTN
                            </div>
                            <div class="contents2 ps-4 py-3">
                                Ringi# <small class="ps-2">*</small>
                            </div>
                        </th>
                        <div class="col-md-7 contentColumn rowClass2">
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <input type="date" id="trialDueDate" [(ngModel)]="trialDueDate" (keydown)="onKeyDown($event,'trialDueDate')">
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <input type="text" [(ngModel)]="qtyOfPart">
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <mat-select id="ringi" class="mat-sel pt-2" disableOptionCentering
                                [(ngModel)]="trialPlant">
                                    <mat-option *ngFor="let plant of trialPlantList" 
                                    [value]="plant.plantCode">
                                    {{plant.name}}</mat-option>
                                </mat-select>   
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <mat-select id="ringi" class="mat-sel pt-2" disableOptionCentering
                                [(ngModel)]="selectedDock">
                                    <mat-option *ngFor="let dock of dockList" 
                                    [value]="dock.dockCode">
                                    {{dock.dockCode}}</mat-option>
                                </mat-select>   
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <mat-select id="ringi" class="mat-sel pt-2" disableOptionCentering
                                    [(ngModel)]="selectedATTN">
                                    <mat-option *ngFor="let attn of attnList" 
                                        [value]="attn.SpecialistCode">
                                        {{attn.Specialist}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="ps-4 py-2 d-flex align-items-center">
                                <mat-select id="ringi" class="mat-sel pt-2" disableOptionCentering
                                    [(ngModel)]="selectedRingiNm">
                                    <mat-option *ngFor="let ringiNumber of ringNumberList" 
                                        [value]="ringiNumber.RingiNumber">
                                        {{ringiNumber.RingiNumber}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>

            <div class="row ms-1 content-row me-0">
                <div class="d-block col-md-12 align-items-baseline px-0">
                    <label for="details" class="control-label">Supplier/Fabricator Comment</label>
                    <textarea name="" id="details" cols="170" rows="7" 
                        class="form-control detail_area pt-0" [(ngModel)]="taExtComment"
                        [ngStyle]="{'border-color': taExtComment?.length > 500 ? 'red' : ''}">{{crafDetails?.Comment1}}</textarea>
                        <small *ngIf="taExtComment?.length > 500;">Must be at most 500 characters..</small>
                </div>
            </div>
        </div>

        <div class="col-md-8 ps-3 calcTable">
            <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
                <th class="d-none" id="">auth detail</th>
                <tr class="tHead">
                  <th id="" class = "text-center"></th>
                  <th id="" class = "text-center">Package Code</th>
                  <th id="" class = "text-center">Qty</th>
                  <th id="" class = "text-center">Unit $(US)</th>
                  <th id="" class = "text-center">Cost</th>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Package</td>
                    <td class = "text-center">-</td>
                    <td class = "text-center">
                        <input type="text" [(ngModel)]="txtPackageQty" (keyup)="calcPackage()" (keydown)="numValidatorForm($event)" maxlength='5'>
                    </td>
                    <td class = "text-center justify-content-center d-flex calcBtn"><button class="vpacs-btn-table" (click)="calcPackage()">CALC PACKAGE
                    </button></td>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Container</td>
                    <td class = "text-center">{{tdCalcContainer}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtContainerQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtContainerUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.contcostObj?.btnContCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.contcostObj?.btnContCost_Attributes_data_code, 1)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtContainerTotalCost === 0; else notdisable"><input type="text" disabled></td>
                    <ng-template #notdisable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtContainerTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Dunnage1</td>
                    <td class = "text-center">{{tdCalcDunnage1}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage1Qty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtDunnage1UnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.dun1costObj?.btnDun1Cost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.dun1costObj?.btnDun1Cost_Attributes_data_code, 2)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtDunnage1TotalCost === 0; else dunnage1Enable"><input type="text" disabled></td>
                    <ng-template #dunnage1Enable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage1TotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Dunnage2</td>
                    <td class = "text-center">{{tdCalcDunnage2}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage2Qty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtDunnage2UnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.dun2costObj?.btnDun2Cost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.dun2costObj?.btnDun2Cost_Attributes_data_code, 3)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtDunnage2TotalCost === 0; else dunnage2Enable"><input type="text" disabled></td>
                    <ng-template #dunnage2Enable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage2TotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Top Cap</td>
                    <td class = "text-center">{{tdCalcTopCap}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtTopCapQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtTopCapUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.topcostObj?.btnTopCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.topcostObj?.btnTopCost_Attributes_data_code, 4)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtTopCapTotalCost === 0;else topCapEnable"><input type="text" disabled></td>
                    <ng-template #topCapEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtTopCapTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Pallet</td>
                    <td class = "text-center">{{tdCalcPallet}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtPalletQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtPalletUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.palcostObj?.btnPalCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.palcostObj?.btnPalCost_Attributes_data_code, 5)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center" *ngIf="txtPalletTotalCost === 0;else palletEnable"><input type="text" disabled></td>
                    <ng-template #palletEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtPalletTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Stretch Wrap/Strap</td>
                    <td class = "text-center">{{tdCalcStretchWrapStrap}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtStretchWrapStrapQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtStretchWrapStrapUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.strcostObj?.btnStrCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.strcostObj?.btnStrCost_Attributes_data_code, 6)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtStretchWrapStrapTotalCost === 0;else stretchEnable"><input type="text" disabled></td>
                    <ng-template #stretchEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtStretchWrapStrapTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Kanban Card Holder</td>
                    <td class = "text-center">{{tdCalcKanbanCardHolder}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtKanbanCardHolderQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtKanbanCardHolderUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.khcostObj?.btnKanCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.khcostObj?.btnKanCost_Attributes_data_code, 7)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtKanbanCardHolderTotalCost === 0;else kanbanEnable"><input type="text" disabled></td>
                    <ng-template #kanbanEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtKanbanCardHolderTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Other Package</td>
                    <td class = "text-center">{{tdCalcOther}}</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtOtherQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtOtherUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.othcostObj?.btnOthCost_Visible">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.othcostObj?.btnOthCost_Attributes_data_code, 8)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center"  *ngIf="txtOtherTotalCost === 0;else otherEnable"><input type="text" disabled></td>
                    <ng-template #otherEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtOtherTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Label</td>
                    <td class = "text-center">-</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtLabelQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                    <td class = "text-center d-flex align-items-center">
                        <input type="text" [(ngModel)]="txtLabelUnitCost" (keydown)="numValidatorForm($event)" class="ms-5" maxlength='10'>
                        <button mat-icon-button *ngIf="calcUnitCost?.othcostObj?.btnOthCost_Visible" class="d-none">
                            <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(calcUnitCost?.othcostObj?.btnOthCost_Attributes_data_code, 8)">more_vert</mat-icon>
                        </button>
                    </td>
                    <td class = "text-center" *ngIf="txtLabelTotalCost === 0;else labelEnable"><input type="text" disabled></td>
                    <ng-template #labelEnable>
                        <td class = "text-center"><input type="text" [(ngModel)]="txtLabelTotalCost" maxlength='10' disabled></td>
                    </ng-template>
                </tr>
                <tr>
                    <td style=" background-color:#e4ecf0" class = "ps-4">Other</td>
                    <td class = "text-center">-</td>
                    <td class = "text-center">-</td>
                    <td class = "text-center">-</td>
                    <td class = "text-center"><input type="text" [(ngModel)]="txtOtherCost" maxlength='10' (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)"></td>
                </tr>
                <tr>
                  <td class="pe-3" colspan="4" style="background-color:#F4F0DF; text-align: right;  border: 1px solid #BCD1E0;">
                    Total
                  </td>
                  <td class="text-center" *ngIf="totalCost === '0.00';else totalEnable" colspan="22" style="background-color:#F4F0DF;  border: 1px solid #BCD1E0;"
                  >  <input type="text" disabled> </td>
                  <ng-template #totalEnable>
                    <td class="text-center" colspan="22" style="background-color:#F4F0DF;  border: 1px solid #BCD1E0;"
                    >  <input type="text" [(ngModel)]="totalCost" maxlength='10' disabled> </td>
                </ng-template>
                </tr>
              </table>
        </div>


    </div>
</div>
<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="goToPrev()">BACK</button>
    <button class="black-button me-3 roboto-medium" (click)="onSubmit()" [disabled]="taExtComment?.length > 500">AUTHORIZE</button>
</div>
