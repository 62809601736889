const pkgCodeCls = 'Package Code'
const estimCost = 'Estimated Cost/ Pkg Piece$(US)'
const weightLb = 'Weight(lb)'
const weightKg = 'Weight(kg)'
export const backupAuthListConstant = {
    rowClassRules : {
        'row-even': 'data.flag === `1` && node.rowIndex % 2 === 0',
        'row-odd': 'data.flag === `1` && node.rowIndex % 2 !== 0',
        'row-pink': 'data.flag === `0`',
        'row-yellow': 'data.flag === `2`',
        'row-reject': 'data.rejectflag === `1`'
    },
    statusList: [
        {
          name: 'ALL',
          value: 0
        },
        {
          name: 'Wait PC',
          value: 1
        },
        {
          name: 'Tracking',
          value: 2
        },
        {
          name: 'Reject',
          value: 3
        },
        {
          name: 'PC SPEC',
          value: 4
        },
        {
          name: 'PC A/M',
          value: 5
        },
        {
          name: 'PC MGR',
          value: 6
        },
        {
          name: 'PUR SPEC',
          value: 7
        },
        {
          name: 'PUR A/M',
          value: 8
        },
        {
          name: 'PUR MGR',
          value: 9
        },
        {
          name: 'Finish',
          value: 10
        },
      ],
      columns: [
        'AUTH#', 'PO#', 'RINGI#', 'SPECIALIST', 'PACKAGE CODE', 'PACKAGE TYPE', 'PLANT', 'FAMILY',
        'MODEL', 'SUPPLIER', 'FABRICATOR', 'PART NUMBER', 'REASON', 'AUTH', 'PC APPROVE DATE',
        'APPROVE', 'PUR APPROVE', 'APPROVE STATUS',
      ],
      backupSpecificationI: [
        {
          title: 'Container',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''},{val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
              
            },
            {
              name: estimCost,
              //value: ['', '', '', 'QPC', ''],
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: 'QPC', css: 'yes'}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Dunnage',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              //value: ['', '', '', '', ''],
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Top cap',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Pallet',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
      ],
      backupSpecificationM: [
        {
          title: 'Container',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''},{val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
              
            },
            {
              name: estimCost,
              //value: ['', '', '', 'QPC', ''],
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: 'QPC', css: 'yes'}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Dunnage',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              //value: ['', '', '', '', ''],
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Top cap',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Pallet',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
      ],

      backupSpecificationI1:  [
        {
          title: 'Container',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: 'QPC', css: 'yes'}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Dunnage',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Top cap',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Pallet',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(Inch)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightLb, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
      ],
      backupSpecificationM1:  [
        {
          title: 'Container',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: 'QPC', css: 'yes'}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Dunnage',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Top cap',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}],
            },
          ],
        },
        {
          title: 'Pallet',
          subHeads: [
            {
              name: pkgCodeCls,
              value: [{val: '', css: ''}],
            },
            {
              name: 'LWH(mm)',
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: weightKg, css: 'yes'}, {val: '', css: ''}],
            },
            {
              name: estimCost,
              value: [{val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}, {val: '', css: ''}],
            },
          ],
        },
      ],

}