import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SortChangedEvent, Column } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { PPFSelectRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/ppf/ppf-select-render';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-select-part',
  templateUrl: './select-part.component.html',
  styleUrls: ['./select-part.component.scss'],
  providers: [RegexFilter]

})
export class SelectPartComponent implements OnInit {
  public gridApi: any;
  public rowHeight = 40;
  public defaultColDef = {};
  public columnDefs: any[] = [];
  public domLayout: any = '';
  public displayRowData: any[] = [];
  public searchPart: any;
  public validForm = false;
  public currentPageNo: any;
  public currentPageBoolean = false;
  public totalPage = 0;
  public currentPageForTotalPage = 0;
  public isLastPageFound !: boolean;
  public paginationPageSize: any = 50;
  public rowCount !: number;
  public type = 'on_develop'
  public rowData: any;

  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  model: any = "";
  part: any = ""
  gridColumnApi: any;
  sortModel: any[] = [];
  rowClassRules: any;
  icons: any = {};

  valuesArray: any[] = [];
  selectedValue = '';
  selectedLimit = 10;
  totalPageCheck: any;
  currentPageCheck: any = 1;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  servicePDFCheck = false;
  plant: Observable<any[]> | undefined;
  plantValue: any;
  plants: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectPartComponent>,
    public formbuilder: FormBuilder,
    public ppfService: PpfServiceInternal,
    public regex: RegexFilter,
    private readonly iaService: InternalApprovementListService,
    private readonly spinner: NgxUiLoaderService,
    private readonly state: ExportStateService
  ) {
    this.dialogRef.disableClose = true;
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
      headerheight: 50
    }
    this.servicePDFCheck = this.data.serviceBoolean;
  }

  ngOnInit(): void {


    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
    this.getLoggedInuserInfo();
    this.getColumnDefs();
    this.searchPart = this.formbuilder.group(
      {
        plant: ['ALL'],
        model: [''],
        part: [''],
        type: ['on_develop'],
      }
    )
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "Select", field: "select", cellRendererFramework: PPFSelectRender, cellRendererParams: {
          clicked: (field: any) => { this.btnSelected(field) }
        }, minWidth: 80, maxWidth: 100
      },
      {
        headerName: "Model", field: "modelname", minWidth: 80, maxWidth: 100, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "Plant", field: "plantcode", minWidth: 80, maxWidth: 100, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "Part Number", field: "partnumber", minWidth: 80, maxWidth: 100, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "Part Desc", field: "partdescription", wrapText: true, minWidth: 200, maxWidth: 400, sortable: true,
        unSortIcon: true,
        cellStyle: (_params: any) => {
          return { 'white-space': 'break-spaces', 'word-break': 'break-word', 'line-height': '20px', 'display': 'flex', 'justify-content': 'flex-start', 'height': '40px', 'padding': '0 10px' };
        }
      },
      {
        headerName: "Pkg start date", field: "pkgstartdate", minWidth: 80, maxWidth: 100, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "container", field: "packagecode", minWidth: 100, maxWidth: 140, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "dunnage", field: "dunnage1code", minWidth: 100, maxWidth: 140, sortable: true,
        unSortIcon: true
      },
      {
        headerName: "dunnage2", field: "dunnage2code", minWidth: 100, maxWidth: 140, sortable: true,
        unSortIcon: true
      },
    ];
  }

  getLoggedInuserInfo() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          const plantObj: any = {
            SpecialistCode: res.info.p_specialistcode,
            Type: 1
          }
          this.getPlants(plantObj);
        }
      }
    })
  }

  getPlants(payload: any) {
    this.iaService.getAllPlant(payload)
      .subscribe({
        next: (res: any) => {
          this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
          this.plantValue = this.searchPart.patchValue({ plant: '(All)' });
          this.valuesArray = res.map((item: { plantcode: string }) => item.plantcode);
          this.searchPart.controls['part']?.setValue(this.data?.partnumber);
          this.displayFn(this.plants)
          this.plant =
            this.searchPart.get('plant')?.valueChanges.pipe(
              startWith(''),
              map((value: any) => this._filter(value)),
            );
          this.onLoad();
        },
      })
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants?.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase()
    return this.plants.filter((option: any) =>
      option.plant?.toLowerCase().includes(filterValue),
    )
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage)
    this.onLoad()

  }

  onLoad() {
    const listType = this.searchPart.get('type').value === 'on_develop' ? '0' : '1';
    const plant = this.plants.filter((item: any) => ((item.plant.toLowerCase() === this.searchPart.get('plant').value?.toLowerCase()) || 
    (item.plantcode.toLowerCase() === this.searchPart.get('plant').value?.toLowerCase())));
    this.searchPart.get('plant').setValue(plant[0]?.plantcode);

    const body = {
      "PlantCodes": this.searchPart.get('plant').value === '(All)' ? this.valuesArray : [this.searchPart.get('plant').value],
      "ModelCode": this.searchPart.get('model').value,
      "Part1": this.searchPart.get('part').value?.substring(0, 5),
      "Part2": this.searchPart.get('part').value?.substring(5),
      "ListType": listType,
      "limit": this.rpPage,
      "page": this.cPage
    }
    const serviceValue = this.searchPart.get('plant').value
    if (serviceValue === '50SER' || serviceValue === '51ACC') {
      const serviceBody = {
        "PlantCodes": [this.searchPart.get('plant').value],
        "ModelCode": this.searchPart.get('model').value,
        "Part1": this.searchPart.get('part').value ? this.searchPart.get('part').value?.substring(0, 5) : '',
        "Part2": this.searchPart.get('part').value ? this.searchPart.get('part').value?.substring(5) : '',
        "ListType": listType,
        "limit": this.rpPage,
        "page": this.cPage
      }
      this.spinner.start();
      this.ppfService.ServicePartsCopyOnload(serviceBody).subscribe({
        next: (res: any) => {
          this.rowData = res;
          this.displayRowData = res.data;
          this.rowCount = res.pagination.TotalRecords;
          this.totalRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinner.stop();
        },
        error: (_error: any) => {
          this.displayRowData = [];
          this.spinner.stop();
        }

      })
    }
    else {
      this.spinner.start();
      this.ppfService.CopyPPFData(body).subscribe({
        next: (res: any) => {
          this.rowData = res;
          this.displayRowData = res.data;
          this.rowCount = res.pagination.TotalRecords;
          this.totalRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinner.stop();
        },
        error: (_error: any) => {
          this.displayRowData = [];
          this.spinner.stop();
        }
      })
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit("autoWidth");
    this.gridApi?.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.gridColumnApi?.applyColumnState(this.sortModel)
  }
  onSortChanged(event: SortChangedEvent) {
    this.gridApi?.showLoadingOverlay();
    const sortedColumns: Column[] | undefined = event.columnApi?.getAllColumns()?.filter((col) => col.getSort())
    if (sortedColumns) {
      for (const column of sortedColumns) {
        const colId = column.getColDef()?.field;
        const direction = column.getSort()?.toUpperCase();
        const existingSort = this.sortModel.find((sort) => sort.colId === colId);
        if (existingSort) {
          if (existingSort.sort !== direction) {
            existingSort.sort = direction;
          }
        } else {
          this.sortModel.push({ colId, sort: direction })
        }
      }
      this.gridColumnApi?.applyColumnState(this.sortModel)
      this.sortModel.forEach((sort) => {
        const coldef = this.columnDefs.find((col) => col.field === sort.colId);
        const index = this.columnDefs.findIndex((col: any) => col.field === sort.colId);
        if (coldef) {
          coldef.sort = sort.sort.toLowerCase()
        }
        this.columnDefs[index] = coldef
      })
      this.gridApi?.setColumnDefs(this.columnDefs);
      this.sizeToFit()
    }
  }


  btnSelected(params: any) {
    this.dialogRef.close({ data: params });
  }

  onSearch() {
    this.onLoad()
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
