import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<InfoDialogComponent>,
    private readonly state: ExportStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}


  closeDialog() {
    this.dialogRef.close()
  }

}
