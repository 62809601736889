import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageMaintananceService } from '../../services-internal/master-maintenance/package-maintenance-service';
import { formatDate } from '@angular/common';
import { PackageMaintainDeletePopComponentComponent } from '../../internal-vpacs/master-maintenance/package-maintain-delete-pop-component/package-maintain-delete-pop-component.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-sup-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" [disabled]="isDisabed"
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class SupBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly service: PackageMaintananceService, private readonly router: Router, private readonly route: ActivatedRoute, private readonly spinnerService: NgxUiLoaderService,
    public dialog: MatDialog,
  ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  isDisabed = false;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
      if (this.params.context.componentParent.data.roles?.role.p_pkgmodify) {
        this.isDisabed = false
      } else {
        this.isDisabed = true
      }
    }
    else if (params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
      if (this.params.context.componentParent.data.roles?.role.p_pkgdelete) {
        this.isDisabed = false
      } else {
        this.isDisabed = true
      }
    }
  }

  dateFormatter(params: any) {
    return params ? formatDate(params, 'YYYY-MM-dd', 'en-US') : '';
  }

  btnClickedHandler(_event: any) {
    if (this.buttonName === 'UPDATE') {
      this.params.clicked(this.params.data)
    }
    if (this.buttonName === 'DELETE') {
      const data = {
        message: `Are you sure that you want to delete ${this.params.data.pkgsupcode}-${formatDate(this.params.data.fromdate, 'MM/dd/YYYY', 'en-US')} `,
        buttonName: 'DELETE',
        headerName: 'Delete confirmation'
      };
      const dialogRef = this.dialog.open(PackageMaintainDeletePopComponentComponent, { data, height: '400px', width: '470px' });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          if (result === 'success') {
            this.spinnerService.start()
            const data = {
              type: this.params.data.planttype,
              pkgCode: this.params.context.componentParent.pckgCode,
              pkgSup: this.params.data.pkgsupcode,
              FromDate: this.dateFormatter(this.params.data.fromdate),
              ToDate: this.dateFormatter(this.params.data.todate)
            }
            this.service.deletePackageCost(data).subscribe({
              next: (response: any) => {
                this.params.context.componentParent.Onsuccess(response.message);
                setTimeout(() => {
                  this.params.context.componentParent.search();
                }, 1500);
              },
              error: (error: any) => {
                const error1 = error.error;
                this.params.context.componentParent.onDelete(error1.error)
              },
            });
            this.spinnerService.stop()
          }
        }
      })
    }
  }
}