import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingSummaryService {
  URL = this.constantsService.INTERNAL;
  plantdata: any;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  loadDocData(loadObj:any): Observable<any>{

      return this.http.post((this.URL+'trackingsummary/onload'), loadObj,
      { responseType: 'json',headers: this.constantsService.internalHttpOptions})
        .pipe(catchError(this.handleError))
  }

  countData(childObj:any): Observable<any>{

    return this.http.post((this.URL+'trackingsummary/count'), childObj,
    { responseType: 'json',headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  childData(childObj:any): Observable<any>{

    return this.http.post((this.URL+'trackingsummary/child'), childObj,
    { responseType: 'json',headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }


  plant(obj: any): Observable<any> {
    return this.http.post(this.URL + 'common/getPlant', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
   
	}
  trackingSummaryFilters:any;

  setTrackingSummaryFilters(data:any){
    this.trackingSummaryFilters=data;
  }

  getTrackingSummaryFilters(){
    return this.trackingSummaryFilters;
  }
 

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  clearTrackingSummaryFilters(){
    this.trackingSummaryFilters='';
  }
  
}