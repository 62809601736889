import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() lines !: number;
  @Input() table !: number;
  lineCount :any[] = [];
  tableCount :any[] = [];

  ngOnInit(): void {
    this.lineCount = new Array(this.lines)
    this.tableCount = new Array(this.table)
    
  }

}
