<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">Display Reject Reason</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <div class="popup-contents vpacs m-2 p-0">
    <div class="col-11 page-title mx-3">Part Information</div>
  </div>
  
  <div class="popup-contents vpacs-shadow m-3 p-0">
    <div>
      <table aria-labelledby="rejReason">
        <th id="" class="d-none"></th>
        <tr>
          <td style="background-color:#e4ecf0">Auth#</td>
          <td class="even">{{details?.authnumber}}</td>
        </tr>
       <tr>
          <td style="background-color:#e4ecf0">Ringi#</td>
          <td class="odd">{{details?.ringinumber}}</td>
        </tr>
        <tr>
          <td style="background-color:#e4ecf0">Supplier</td>
          <td class="even">{{details?.shortname}}</td>
        </tr>
        <tr>
          <td style="background-color:#e4ecf0">Part#</td>
          <td class="odd">{{details?.partnumber}}</td>
        </tr>
      </table>
    </div>
  </div>
<!-- changes -->
  <div class="popup-contents vpacs-shadow m-3 p-0">
    <div>
      <table aria-labelledby="rejReason">
        <th id="" class="d-none"></th>
        <tr>
          <td style="background-color:#e4ecf0">Reject Person</td>
          <td class="even">{{details?.rejectperson}}</td>
        </tr>

        <tr>
          <td style="background-color:#e4ecf0">When Rejected</td>
          <td class="odd">{{details?.rejectdate | date:'MM/dd/YYYY'}}</td>
        </tr>

        <tr>
          <td style="background-color:#e4ecf0">Reject Reason</td>
          <td class="even" colspan="4"><textarea class="catlabel cols-3 rows-3" id="comment">{{details?.rejectreason}}</textarea></td>
        </tr>
       
      </table>
    </div>
  </div>
   
  <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
    <button class="cancel me-3" (click) = "close()"> CANCEL </button>
  </div>

</div>