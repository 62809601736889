import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'dock-maintenance-btn-cell-renderer',
    template: `
  <div  class="d-flex align-items-center gap-1" *ngIf= "params.data.dockcode !==null">  
   
    <mat-select *ngIf="isDDL" class="mat-sel" disableOptionCentering style="width:100px" placeholder="--Select--" [(value)]="selected">
        <mat-option *ngFor="let item of ddlValues; let i = index" [value]="item">{{item}}</mat-option>
    </mat-select>

    <button *ngIf="isBTN" class="vpacs-btn-table" style="height:30px; width:100px">{{buttonName}}</button>

    <input *ngIf="isTB" type="text" style="width:100px" [(ngModel)]="bufferDays">

  </div>  
  `,
})

export class DockMaintenanceBtnCellRender implements ICellRendererAngularComp {
    spCode: any;

    constructor(private readonly router: Router, private readonly route: ActivatedRoute,) { }

    refresh(params: ICellRendererParams): boolean {
        throw new Error(`Error while refreshing renderer component. INFO-${params}`);
    }

    params: any;
    buttonName: any;
    isDisabed = false;
    ddlValues: any[] = [];
    ddlValues1: any[] = [];
    ddlValues2: any[] = [];

    isDDL = false;
    isTB = false;
    isBTN = false;
    selected: any = '';
    bufferDays = '';
    dataFilter: any[] = [];
    select: any[] = [];
    dataFilter2: any[] = [];
    defaultSp: any;

    agInit(params: any): void {
        this.defaultSp = [];
        this.ddlSelect(params)
        this.params = params;
        const header = params.colDef.headerName.toUpperCase();
        if (header === "INTERNAL SAFETY" || header === "PROD"
            || header === "LOGISTICS" || header === "OTHER") {
            this.isTB = true;
            this.bufferDays = params.value;
        }
        else if (header === "UPDATE" || header === "DELETE") {
            this.isBTN = true;
            this.buttonName = header;
        }
        else {
            this.isDDL = true;
        }
    }


    getOptions(colId: string) {
        switch (colId) {
            case "preconvsp":
            case "massconvsp":
                return "CV";

            case "preqcsp":
            case "massqcsp":
                return "QC";

            case "premfgsp":
            case "massmfgsp":
                return "MF";

            default:
                return null;
        }
    }

    getOptions3(colId: string) {
        switch (colId) {
            case "preconvsp":
            case "preqcsp":
            case "premfgsp":
            case "prepks":
                return "1";

            default:
                return null;
        }
    }

    btnClickedHandler(_event: any) {
        this.params.clicked(this.params.data)
    }

    ddlSelect(params: any) {

        const divcode = this.getOptions(params.column.colId);
        const getColIdPre = this.getOptions3(params.column.colId)
        if (getColIdPre === "1") {
            if (params.column.colId === 'prepks') {
                this.ddlValues = params.data.leader.filter((a: any) => {
                    return (!["QC", "MF", "CV"].includes(a.divcode) && (a.pre === "1")) ? true : false
                }).map((a: any) => a.specialist);
            } else {
                this.ddlValues = params.data.leader.filter((a: any) => {
                    return ((a.divcode === divcode) && (a.pre === "1")) ? true : false
                }).map((a: any) => a.specialist);
            }
        } else {
            if (params.column.colId === 'masspks') {
                this.ddlValues = params.data.leader.filter((a: any) => {
                    return (!["QC", "MF", "CV"].includes(a.divcode) && (a.mass === "1")) ? true : false
                }).map((a: any) => a.specialist);
            } else {
                this.ddlValues = params.data.leader.filter((a: any) => {
                    return ((a.divcode === divcode) && (a.mass === "1")) ? true : false
                }).map((a: any) => a.specialist);
            }
        }

        this.spCode = params.data.leader.filter((a: any) => {
            if (this.ddlValues) {
                if (params.column.colId === "preconvsp" && a.pre === "1") {
                    if (a.specialistcode === params.data.preconvsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }
                if (params.column.colId === "preqcsp" && a.pre === "1") {
                    if (a.specialistcode === params.data.preqcsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }
                if (params.column.colId === "premfgsp" && a.pre === "1" ) {
                    if (a.specialistcode === params.data.premfgsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }
                if (params.column.colId === "prepks" && a.pre === "1" ) {
                    if (a.specialistcode === params.data.prepks) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }

                }
                if (params.column.colId === "massconvsp" && a.mass === "1" ) {
                    if (a.specialistcode === params.data.massconvsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }

                if (params.column.colId === "massqcsp" && a.mass === "1") {
                    if (a.specialistcode === params.data.massqcsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }

                if (params.column.colId === "massmfgsp" && a.mass === "1" ) {
                    if (a.specialistcode === params.data.massmfgsp) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }

                if (params.column.colId === "masspks" && a.mass === "1" ) {
                    if (a.specialistcode === params.data.masspks) {
                        const defaultSel = this.ddlValues?.find((spcode: any) => {
                            return spcode === a.specialist
                        })
                        this.selected = defaultSel
                    }
                }
            }
            return this.selected;
        })
            .map((_a: any) => this.selected)
    }
}
