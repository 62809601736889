<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <button type="button" class="btn-close" (click)="close()"></button>
    <div *ngFor="let message of errorList" class="errormsg">
        <div class="message-container">
            <span class="message-text">{{ message }}</span>
        </div>
    </div>
</div>
<div class="ppf" id="print" *ngIf="haveAccess">
    <div class="top-control-buttons gap-2 pe-3" id="control-bar" >
        <button *ngIf="mode === 1" class="vpacs-btn-table" (click)="savePPF()">SAVE</button>
        <button *ngIf="routeParams.external === '1';else elseBlock"  class="vpacs-btn-table" (click)="pdfDownload()">PRINT</button>
        <ng-template #elseBlock >
            <button class="btn btn-link" (click)="downloadPPF()">DOWNLOAD</button>       
        </ng-template>
        <button class="btn btn-link" (click)="navigatePrevious()">CLOSE</button>                
    </div>
    <div class="ppf-content m-3" id="PPF" #PPF="">
        <div class="container-list m-3" [formGroup]="form0">
            <div class="row my-2">
                <div class="card1 col px-0">
                    <div class="card-head d-flex justify-content-center align-items-center fs-4 border-bottom">
                        {{"TOYOTA"}}</div>
                    <div class="d-flex flex-wrap list">
                        <span class="col-5 px-2" title="Supplier Name:">Vendor Name:</span>
                        <span class="col-7 px-2" title=''>{{form0.controls['venName']?.value}}</span>
                        <span class="col-5 px-2">TMS Vendor Code:</span>
                        <span class="col-7 px-2" title=''>{{form0.controls['venCode']?.value}}</span>
                        <span class="col-5 px-2" title="Supplier Code:">Vendor Pickup Location:</span>
                        <input *ngIf="mode === 1" type="text" class="col-md-4 mx-2" id="venPickUpLoc" formControlName="venPickUpLoc">
                        <span *ngIf="mode === 2">
                            {{ form0.controls['venPickUpLoc']?.value }}
                          </span>
                    </div>
                </div>
                <div class="card2 col-sm-8 col-md-6 col-12 px-0">
                    <div class="card-head d-flex text-center border-bottom">
                        <div class="col-12 text-center">
                            <div class="fs-6 fw-bold ">PACKAGING PROPOSAL</div>
                            <span class="tmna">TOYOTA MOTOR MANUFACTURING NORTH AMERICA, INC.</span>
                        </div>
                    </div>
                    <div class="sub-cards col d-flex">
                        <div class="subcard-1 col-6 pe-1">
                            <div class="d-flex flex-wrap list">
                                <span class="col-5 px-2" title="part Type">Part Type:</span>
                                <mat-select id="partType" class="mat-sel-sp col-4 mt-2" placeholder="Select" disableOptionCentering *ngIf="mode === 1" [style.border] = "form0.get('partType').invalid && form0.get('partType').touched ? '2px solid red' : ''" formControlName="partType">
                                    <mat-option *ngFor="let part of partType; let i=index" [value]="part.value">
                                        {{ part.name }}
                                    </mat-option>
                                </mat-select>                                
                                <span class="col-7" *ngIf="mode === 2">
                                    {{ form0.controls['partType']?.value }}
                                  </span>
                                <span class="col-5 px-2" title="request Type">Request Type:</span>
                                <mat-select id="requestType" class="mat-sel-sp col-4 mt-2" placeholder="Select" disableOptionCentering *ngIf="mode === 1"  [style.border] = "form0.get('requestType').invalid && form0.get('requestType').touched ? '2px solid red' : ''"  formControlName="requestType">
                                    <mat-option *ngFor="let x of requestDropdown" [value]="x.requestValue">{{x.name}}</mat-option>
                                </mat-select>
                                <span class="col-7" *ngIf="mode === 2">
                                    {{ form0.controls['requestType']?.value }}
                                  </span>
                                <span class="col-5 px-2" title="Packaging Contact">Packaging Contact:</span>
                                <span class="col-7" title=''>{{ form0.controls['packagingContact']?.value }}</span>
                                <span class="col-5 px-2" title="Phone Number">Phone Number:</span>
                                <span class="col-7" title='data'>{{ form0.controls['phoneNumber']?.value }}</span>

                            </div>
                        </div>

                        <div class="subcard-2 col-6 ps-1">
                            <div class="d-flex flex-wrap list">
                                <span class="col-5" title="Project Code">Project Code:</span>
                                <span class="col-7" title='' id="project-code">{{ form0.controls['projectCode']?.value }}</span>
                                <span class="col-5" title="Parts Description">Parts Description:</span>
                                <span *ngIf="mode === 1;else viewBlock1" class="col-7" title='' id="parts-desc">{{partDesc}}</span>
                                <ng-template #viewBlock1>
                                    <span class="col-7" title='' id="parts-desc">{{form0.controls['partsDesc']?.value}}</span>
                                </ng-template>
                                <span class="col-5" title="Home Position">Home Position(HP):</span>                                
                                <span *ngIf="mode === 1; else viewBlock2" class="col-7" title='' id="parts-desc">{{homePosition}}</span>
                                <ng-template #viewBlock2>
                                    <span class="col-7" title='' id="parts-desc">{{form0.controls['homePosition']?.value}}</span>
                                </ng-template>
                                <span class="col-5" title="Email Address">E-Mail Address:</span>
                                <span class="col-7" title=''>{{ form0.controls['email']?.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card3 col px-0">
                    <div class="card-head card3-header px-3 py-2">
                        <span class="col-4">PART NUMBER</span>
                        <span class="col-9">PRELIMINARY OR FINAL</span>
                    </div>
                    <div class="d-flex flex-wrap list">
                        <div class="col-4 d-flex flex-wrap mx-1">
                            <span>10 DIGIT:<br><b>{{ tenDigitValue }}</b></span>
                            <span>11th/12th DIGIT: <br><b>{{ form0.controls['elevenOrTwelfth']?.value }}</b></span>
                        </div>
                        <div class="list-right col-7 d-flex flex-wrap">
                            <span class="col-10 fw-bold">{{ form0.controls['prelimOrFinal']?.value}}</span>
                            <span class="col-6">Date:</span>
                            <span class="col-6">{{ form0.controls['date']?.value }}</span>
                            <span class="col-6">TOTAL UNIT PKG COST(USD$):</span>
                            <input type="text" class="col-md-6 col-12" id="total-unit-pkg" formControlName="totalUnitPkg" *ngIf="mode === 1">
                            <span *ngIf="mode === 2">
                                {{ '$' + totalUnitPackagingCost }}
                              </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-2">
                <span class="col-1 d-flex align-items-center justify-content-center"
                    style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box; width: 2%;">DIGITAL
                    IMAGE</span>
                <div class="card4 col px-0">
                    <div class="d-flex img-upload">
                        <div class="col-12">
                            <h5 class="py-2">BARE PART</h5>
                            <div class="img-cont col d-flex px-3 gap-2">
                                <div class="left col">
                                    <div class="img-preview">
                                        <div  *ngIf="!image[0]" class="d-flex h-100 align-items-center justify-content-center">
                                            <mat-icon class="text-secondary">panorama</mat-icon>
                                        </div>
                                        <a target="_blank" (click)="openWindow(zoomImage[0])">
                                            <img *ngIf="image[0]" [src]="image[0]" alt="Bare Part" />
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-2 justify-content-between">
                                        <label *ngIf="mode === 1" for="file-upload1" class="vpacs-btn-table col-10" (change)="handleUpload($event,1)" [style.border]="!imageValid0 ? '2px solid red' :''">
                                            CHOOSE FILE
                                            <input type="file" id="file-upload1" style="display: none;">
                                        </label>

                                        <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                            class="vpacs-btn-table" alt="part" *ngIf="mode === 1" (click)="handleDelete(1)" />
                                    </div>
                                </div>

                            </div>
                            <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[0]">Image size must
                                be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[0]">{{formatError[0]}}</div>
                            </div>
                    </div>
                    <div class="cont1-radio p-2 ps-5 d-flex" style="white-space: nowrap;">
                        <span class="pe-4" style="white-space: nowrap;">HAZMAT :</span>
                        <span *ngIf="mode === 2">{{form0.controls['hazmat']?.value}}</span>
                        <div *ngIf="mode === 1">
                            <input type="radio" value="Y" formControlName="hazmat" class="mx-1" />Yes
                            <input type="radio" value="N" formControlName="hazmat" class="mx-1" />No
                        </div>
                    </div>
                </div>
                <div class="card5 col px-0">
                    <div class="d-flex img-upload">
                        <div class="col-12">
                            <h5 class="py-2">PKG DUNNAGE AROUND PART</h5>
                            <div class="img-cont col d-flex px-3 gap-2">
                                <div class="left col">
                                    <div class="img-preview">
                                        <div  *ngIf="!image[1]" class="d-flex h-100 align-items-center justify-content-center">
                                            <mat-icon class="text-secondary">panorama</mat-icon>
                                        </div>
                                        <a target="_blank" (click)="openWindow(zoomImage[1])">
                                            <img *ngIf="image[1]" [src]="image[1]" alt="Bare Part" />
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-2 justify-content-between">
                                        <label *ngIf="mode === 1" for="file-upload2" class="vpacs-btn-table col-10" (change)="handleUpload($event,2)" [style.border]="!imageValid1 ? '2px solid red' :''">
                                            CHOOSE FILE
                                            <input type="file" id="file-upload2" style="display: none;">
                                        </label>

                                        <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                            class="vpacs-btn-table" alt="part" *ngIf="mode === 1" (click)="handleDelete(2)" />
                                    </div>
                                </div>

                            </div>
                            <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[1]">Image size must
                                be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[1]">{{formatError[1]}}</div>
                            </div>
                    </div>
                </div>
                <div class="card6 col px-0">
                    <div class="d-flex img-upload">
                        <div class="col-12">
                            <h5 class="py-2">INTERMEDIATE PKG</h5>
                            <div class="img-cont col d-flex px-3 gap-2">
                                <div class="left col">
                                    <div class="img-preview">
                                        <div  *ngIf="!image[2]" class="d-flex h-100 align-items-center justify-content-center">
                                            <mat-icon class="text-secondary">panorama</mat-icon>
                                        </div>
                                        <a target="_blank" (click)="openWindow(zoomImage[2])">
                                            <img *ngIf="image[2]" [src]="image[2]" alt="Bare Part" />
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-2 justify-content-between">
                                        <label *ngIf="mode === 1" for="file-upload3" class="vpacs-btn-table col-10" (change)="handleUpload($event,3)">
                                            CHOOSE FILE
                                            <input type="file" id="file-upload3" style="display: none;">
                                        </label>

                                        <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                            class="vpacs-btn-table" alt="part" *ngIf="mode === 1" (click)="handleDelete(3)" />
                                    </div>
                                </div>

                            </div>
                            <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[2]">Image size must
                                be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[2]">{{formatError[2]}}</div>
                            </div>

                    </div>
                </div>
                <div class="card7 col px-0">
                    <div class="d-flex img-upload">
                        <div class="col-12">
                            <h5 class="py-2">PALLET/CASE LOAD (AS SHIPPED)</h5>
                            <div class="img-cont col d-flex px-3 gap-2">
                                <div class="left col">
                                    <div class="img-preview">
                                        <div  *ngIf="!image[3]" class="d-flex h-100 align-items-center justify-content-center">
                                            <mat-icon class="text-secondary">panorama</mat-icon>
                                        </div>
                                        <a target="_blank" (click)="openWindow(zoomImage[3])">
                                            <img *ngIf="image[3]" [src]="image[3]" alt="Bare Part" />
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-2 justify-content-between">
                                        <label *ngIf="mode === 1" for="file-upload4" class="vpacs-btn-table col-10" (change)="handleUpload($event,4)" [style.border]="!imageValid3 ? '2px solid red' :''">
                                            CHOOSE FILE
                                            <input type="file" id="file-upload4" style="display: none;">
                                        </label>

                                        <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                            class="vpacs-btn-table" alt="part" *ngIf="mode === 1" (click)="handleDelete(4)" />
                                    </div>
                                </div>

                            </div>
                            <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[3]">Image size must
                                be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[3]">{{formatError[3]}}</div>
                            </div>

                    </div>
                </div>
                <div class="card8 col px-0">
                    <div class="d-flex img-upload">
                        <div class="col">
                            <h5 class="py-2">UNIT & INTERMEDIATE LABEL</h5>
                            <div class="img-cont col d-flex px-3 gap-2">
                                <div class="left col">
                                    <div class="img-preview">
                                        <div  *ngIf="!image[4]" class="d-flex h-100 align-items-center justify-content-center">
                                            <mat-icon class="text-secondary">panorama</mat-icon>
                                        </div>
                                        <a target="_blank" (click)="openWindow(zoomImage[4])">
                                            <img *ngIf="image[4]" [src]="image[4]" alt="Bare Part" />
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-2 justify-content-between">
                                        <label *ngIf="mode === 1" for="file-upload5" class="vpacs-btn-table col-10" (change)="handleUpload($event,5)" [style.border]="!imageValid4 ? '2px solid red' :''">
                                            CHOOSE FILE
                                            <input type="file" id="file-upload5" style="display: none;">
                                        </label>

                                        <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                            class="vpacs-btn-table" alt="part" *ngIf="mode === 1" (click)="handleDelete(5)" />
                                    </div>
                                </div>

                            </div>
                            <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[4]">Image size must
                                be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[4]">{{formatError[4]}}</div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- grid1 -->
         <div class="grid1 d-flex mt-2 col">
            <span class="d-flex align-items-center justify-content-center px-2 gridName">UNIT PKG</span>
            <div class="w-100" [ngClass]="{ 'table-container': mode === 1 }"  [formGroup]="form1">
                <table class="tg w-100" aria-describedby="table1">
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers1"  [style.width.px]="header.width">{{ header.label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows1; let i = index">
                            <ng-container *ngIf="row !== 7 && row !== 8">
                                <ng-container *ngFor="let col of columns1; let j = index">
                                    <td *ngIf="j != 0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                        <ng-container *ngIf="(j !== 1 || (i !== 0 && i !== 4 && i !== 5)) && (mode === 1)">
                                            <input type="text" class="inputTms centerText"
                                                [style.width]="((j === 1) && (i >= 0 && i <= 6)) ? '130px' : ''"
                                                [formControlName]="'row' + i + 'col' + j" (change)="fixToDecimal(i,j,1,'grid1');onInputChange1()" (keydown)="numValidatorForm($event,false,0,7,3,9)"/>
                                        </ng-container>
                                        <ng-container *ngIf="(j === 1 && i === 0) && (mode === 1)">
                                            <mat-select placeholder="Select" class="mat-sel-spl" [formControlName]="'row' + i + 'col' + j"
                                                (selectionChange)="onInputChange1(); getTotalPkgCost()">
                                                <mat-option *ngFor="let pkg of unitDropdown(i)" [value]="pkg.value">{{ pkg.name }}</mat-option>
                                            </mat-select>
                                        </ng-container>
                                        <ng-container *ngIf="(j == 1 && (i === 4 || i === 5)) && (mode === 1)">
                                            {{ unitTable[i]['col' + j] }}
                                        </ng-container>
                                        <ng-container *ngIf="mode === 2">
                                            <div>{{ unitTable[i]['col' + j] }}</div>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="j === 0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                        <div>{{ unitTable[i]['col' + j] }}</div>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="(row === 7 || row === 8) && (pValue === 'Accessory Parts PDF')">
                                <ng-container *ngFor="let col of columns1; let j = index">
                                    <td *ngIf="j != 0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                        <ng-container *ngIf="(j !== 1 || (i !== 0 && i !== 4 && i !== 5)) && (mode === 1)">
                                            <input type="text" class="inputTms centerText"
                                                [style.width]="(j === 1 && (i === 7 || i === 8)) ? '130px' : ''"
                                                [formControlName]="'row' + i + 'col' + j" (change)="fixToDecimal(i,j,1,'grid1');onInputChange1();" (keydown)="numValidatorForm($event,false,0,8,3,9)"/>
                                        </ng-container>
                                        <ng-container *ngIf="mode === 2">
                                            <div>{{ unitTable[i]['col' + j] }}</div>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="j === 0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                        <div>{{ unitTable[i]['col' + j] }}</div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
        <!-- grid2 -->
        <div class="grid2 mt-2 d-flex">
            <span class="d-flex align-items-center justify-content-center px-2 gridName">INTERMEDIATE PKG</span>
            <div class="w-100 table-container" [formGroup]="form2">
                <table class="tg w-100 table-container" aria-describedby="table2">  
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers1"  [style.width.px]="header.width">{{ header.label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows2; let i = index">
                            <ng-container *ngFor="let col of columns2; let j = index">
                                <td [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                    <ng-container *ngIf="(j !== 0 && (( j !== 1) || (i!==0 && i!==4))) && (mode === 1)">
                                        <input type="text" class="centerText inputTms"
                                            [style.width]="((j===1) &&( i===0 || i===1 || i===2 || i===3 || i===4)) ? '130px' : ''"
                                            [formControlName]="'row' + i + 'col' + j" (change)="fixToDecimal(i,j,2,'grid2');onInputChange2();getTotalPkgCost()" (keydown)="numValidatorForm($event,false,0,3,3,9)"  />
                                    </ng-container>
                                    <ng-container *ngIf="(mode === 2)">
                                        <div>
                                            {{ intermediateTable[i]['col' + j ] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="j === 0 && (mode === 1)">
                                        <div>
                                            {{ intermediateTable[i]['col' + j ] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="((j === 1 && (i===0))) && (mode === 1)">
                                        <mat-select class="mat-sel-spl" name="interPackageType"
                                            id="*interPackageType" placeholder="Select" [formControlName]="'row' + i + 'col' + j" (selectionChange)="onInputChange2();getTotalPkgCost()">
                                            <mat-option *ngFor="let pkg of interDropdown(i); let i = index;"
                                                [value]="pkg.value">{{ pkg.name }}</mat-option>
                                        </mat-select>
                                    </ng-container>
                                    <ng-container *ngIf="(j==1 && (i===4)) && (mode === 1)">
                                        {{ intermediateTable[i]['col' + j ] }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- grid3 -->
        <div class="grid3 mt-2 d-flex">
            <span class="d-flex align-items-center justify-content-center px-2 gridName">PALLET/CASE LOAD AS SHIPPED</span>
            <div class="w-100 table-container" [formGroup]="form3">
                <table class="tg w-100 " aria-describedby="table3">
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers1"  [style.width.px]="header.width">{{ header.label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows3; let i = index">
                            <ng-container *ngFor="let col of columns3; let j = index">
                                <td [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'">
                                    <ng-container *ngIf="(j !== 0 && (( j !== 1) || (i!==0 && i!==1 && i!==2))) && (mode === 1)">
                                        <input type="text" class="centerText inputTms"
                                            [style.width]="((j===1) &&( i===0 || i===1 || i===2 || i===3 || i===4 ||i===5 || i===6 || i===7 || i===8 )) ? '130px' : ''"
                                            [formControlName]="'row' + i + 'col' + j" (change)="fixToDecimal(i,j,3,'grid3')"(input)="onInputChange3();getTotalPkgCost()" (keydown)="numValidatorForm($event,false,1,6,3,9)" />
                                    </ng-container>
                                    <ng-container *ngIf="(j === 0) && (mode === 1)">
                                        <div >
                                            {{ palletCaseTable[i]['col' + j ] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode === 2">
                                        <div>
                                            {{ palletCaseTable[i]['col' + j ] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="(j === 1 && (i===0 || i===1 || i===2)) && mode === 1">
                                        <mat-select class="mat-sel-spl" name="palletCaseLoad"
                                            id="palletCaseLoad" placeholder="Select" [formControlName]="'row' + i + 'col' + j" (selectionChange)="clearPalletDropdown(i);onInputChange3();getTotalPkgCost()">
                                            <mat-option *ngFor="let pkg of palletCaseDropdown(i); let i = index;"
                                                [value]="pkg.value">{{ pkg.name }}</mat-option>
                                        </mat-select>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
         <!-- grid4 -->
         <div class="grid4 mt-2 mb-3 d-flex">
            <span class="d-flex align-items-center justify-content-center px-2 gridName">PKG DATA</span>
            <div class="w-100" [formGroup]="form4">
                <table class="tg w-100" aria-describedby="table4">
                    <thead>
                        <tr class="tg-mg6y">
                            <th *ngFor="let header of headers4" [attr.colspan]="header.colspan">
                                {{ header.label }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows4; let i = index">
                            <td *ngFor="let col of columns4; let j = index" [ngClass]="([0,4,6].includes(col)) ? 'tg-mg6y' : 'tg-z5to'">
                                <ng-container *ngIf="(((j === 5) || (j === 1 && i!==0) || (j === 2 && i !== 0) || (j === 3 && i !== 0) || (j === 7)) && (mode === 1)); else desc">
                                    <input 
                                        type="text" 
                                        class="inputTms" 
                                        [formControlName]="'row' + i + 'col' + j"
                                        (change)="fixToDecimal(i,j,2,'packageData');onInputChange4(i,j)" (keydown)="numValidatorForm($event,false,0,2,1,7)" [ngClass]="palletCaseGrossWeight(i,j)"
                                    /> 
                                </ng-container>
                                <ng-template #desc>
                                    <div [ngClass]='palletCaseGrossWeight(i,j)'>
                                        {{ tableData[i]['column' + (j+1) ] }}
                                    </div>

                                </ng-template>
                                <ng-template *ngIf="mode === 2">
                                    <div [ngClass]='palletCaseGrossWeight(i,j)'>
                                        {{ tableData[i]['column' + (j+1) ] }}
                                    </div>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- TMS Part -->
        <mat-accordion class="example-headers-align mt-2" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel1 class="mat-elevation-z0 mt-2 mb-2">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">TMS PART</mat-panel-title>
                    <button *ngIf="mode===1" class="help-btn me-4" (click)="addPart($event)">Add Part</button>
                    <mat-icon class="toggleIcon">{{panel1.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="d-flex mt-2 table-container mb-2">
                    <div class="w-100" [formGroup]="form5">
                        <table class="tg w-100 table6" aria-describedby="table11">
                            <thead>
                                <tr class="tg-mg6y">
                                    <th *ngFor="let header of headers2" [style.width.px]="header.width">{{ header.label }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows5; let i = index">
                                    <td *ngFor="let col of columns5 let j = index">
                                        <ng-container *ngIf="(mode===1) && (j !== 10) && (( j !== 3))">
                                            <input type="text" class="inputTms"
                                                    [style.width]="(j===2) ? '150px' : '90px'"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="fixToDecimal(i,j,1,'tmsPart');onInputChange5()" (keydown)="numValidatorForm($event,false,0,data.length,4,8);"/>
                                        </ng-container>
                                        <ng-container *ngIf="(mode===1) && (j === 10)">
                                            <button *ngIf="deleteArray[i]" class="help-btn me-4" (click) = onPartDelete(i)>DELETE</button>
                                        </ng-container>
                                        <ng-container *ngIf="((j === 3)) && (mode===1)">
                                            <mat-select placeholder="Select" class="mat-sel-spl"
                                                name="homePositinDrop" id="homePositinDrop"  [formControlName]="'row' + i + 'col' + j" (selectionChange)="homePositionDropChange()">
                                                <mat-option *ngFor="let home of homePositinDrop; let i = index;"
                                                    [value]="home.statusValue">{{ home.name }}</mat-option>
                                            </mat-select>
                                        </ng-container>
                                        <ng-container *ngIf="mode===2" >
                                            <div class="inputTms" [style.width]="(j===0 || j===2) ? '150px' : ''" >
                                                {{ data[i]['col' + j ] }}
                                            </div>           
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>  
        <!-- footer -->
        <div class="footerclr col-12 d-flex align-items-center flex-wrap p-2">
            <span class="col-lg-6 col-md-4 col-12 my-1">SUPPLIER SIGNATURE: {{ form0.controls['packagingContact']?.value}}</span>
            <span class="col-lg-2 col-md-4 col-12 my-1">DATE: {{supplierdate | date:
                'MM/dd/yyyy'}}</span>
            <span class="col-lg-4 col-md-4 col-12 my-1">PLEASE INDICATE OTHER AFFILIATE THIS PART/PKG IS SHIPPED TO:
                {{affiliate}}</span>
            <span class="col-lg-4 col-md-6 col-12 my-1">TOYOTA APPROVAL SIGNATURE: {{approver}}</span>
            <span class="col-lg-2 col-md-4 col-12 my-1">
                APPROVED: {{(approver === "" || approver === 'null' || approver === null ||
                approver === undefined) ? 'NO' : 'YES'}}
            </span>
            <span class="col-lg-2 col-md-4 col-12 my-1">DATE: {{approvedate | date:
                'MM/dd/yyyy'}}</span>
            <span class="col-lg-4 col-md-4 col-12 my-1">COMMENTS: {{note}}</span>
        </div>
    </div>
</div>
<router-outlet></router-outlet>