import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';

@Component({
  selector: 'app-part-list-supplier',
  templateUrl: './part-list-supplier.component.html',
  styleUrls: ['./part-list-supplier.component.scss']
})
export class PartListSupplierCodeComponent implements OnInit {
  result: any;
  supplierData: any;
  
  constructor(public suplierCode: PartListService,private readonly router: Router,public dialogRef: MatDialogRef<PartListSupplierCodeComponent>, 
    @Inject(MAT_DIALOG_DATA) public dialog: MatDialog
              ) { }

  ngOnInit(): void {
    this.supplierData=JSON.parse(sessionStorage.getItem('supplierPart')||'{}')    
    this.suplierCode.supplierLoad(this.supplierData).subscribe((res: any) => {      
      this.result=res[0]
   })
  }

  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',2,"Tracking",22])
  }
  onNoClick(){
      this.dialogRef.close();
  }
}
