<div class="popup-container" *ngIf="!deleteFlag">
    <!-- supplier add/update component -->
    <form *ngIf="!redirect && !miniPopup">
        <div class="headerName text-middle justify-content-between align-items-center">
            <div class="page-title">
              <label>{{ modalHeader}}</label>
            </div>
            <div>
              <button class="help-btn" (click)="help()">help</button>
            </div>
          </div>
        <div class="alert alert-danger mx-2" *ngIf="errorFlag">
            <span *ngFor="let x of errorMessage">
                {{x}}<br />
            </span>
        </div>
        <div class="popup-contents" [formGroup]="supplierForm">
            <div class="d-flex flex-wrap m-3 align-items-start">
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">NA Supplier Code<small>*</small></label>
                    <input type="text" class="col-7" formControlName="na" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('na').invalid && (supplierForm.get('na').dirty || supplierForm.get('na').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('na').hasError('required')">
                            NA Suppliercode is required
                        </small>
                        <small class="error col-8" *ngIf="supplierForm.get('na').hasError('maxlength') || supplierForm.get('na').hasError('minlength')">
                            NA Suppliercode should have 5 Characters
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">TMMC Supplier Code</label>
                    <input type="text" class="col-7" formControlName="tmmc" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('tmmc').invalid && (supplierForm.get('tmmc').dirty || supplierForm.get('tmmc').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('tmmc').hasError('maxlength')">
                            Maximum 5 characters allowed
                        </small>
                        <small class="error col-8" *ngIf="supplierForm.get('tmmc').hasError('alphaError')">
                            {{supplierForm.controls.tmmc.errors.alphaError}}
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">NUMMI Supplier Code</label>
                    <input type="text" class="col-7" formControlName="nummi" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('nummi').invalid && (supplierForm.get('nummi').dirty || supplierForm.get('nummi').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('nummi').hasError('maxlength')">
                            Maximum 5 characters allowed
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">Name<small>*</small></label>
                    <input type="text" class="col-7" formControlName="name" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('name').invalid && (supplierForm.get('name').dirty || supplierForm.get('name').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('name').hasError('required')">
                            Name is required
                        </small>
                        <small class="error col-8" *ngIf="supplierForm.get('name').hasError('maxlength')">
                            Maximum 50 Characters allowed
                        </small>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap m-3 align-items-start">
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">Short Name<small>*</small></label>
                    <input type="text" class="col-7" formControlName="shortname" disableCharacter />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('shortname').invalid && (supplierForm.get('shortname').dirty || supplierForm.get('shortname').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('shortname').hasError('required')">
                            ShortName is required
                        </small>
                        <small class="error col-8" *ngIf="supplierForm.get('shortname').hasError('maxlength')">
                            Maximum 5 characters allowed
                        </small> 
                        <small class="error col-8" *ngIf="supplierForm.get('shortname').hasError('codeError')">
                            {{supplierForm.controls.shortname.errors.codeError}}
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">Address</label>
                    <input type="text" class="col-7" formControlName="address" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('address').invalid && (supplierForm.get('address').dirty || supplierForm.get('address').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('address').hasError('maxlength')">
                            Maximum 100 characters allowed
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">City</label>
                    <input type="text" class="col-7" formControlName="city" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('city').invalid && (supplierForm.get('city').dirty || supplierForm.get('city').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('city').hasError('maxlength')">
                            Maximum 50 characters allowed
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">State</label>
                    <input type="text" class="col-7" formControlName="state" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('state').invalid && (supplierForm.get('state').dirty || supplierForm.get('state').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('state').hasError('maxlength')">
                            Maximum 50 characters allowed
                        </small>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap m-3 align-items-start">
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">Zip Code</label>
                    <input type="text" class="col-7" formControlName="zipcode" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('zipcode').invalid && (supplierForm.get('zipcode').dirty || supplierForm.get('zipcode').touched)">
                        <label class="col-4 invisible"></label>
                        <small class="error col-8" *ngIf="supplierForm.get('zipcode').hasError('maxlength')">
                            Maximum 10 characters allowed
                        </small>
                    </div>
                </div>
                <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12">
                    <label id="name" class="col-4">Country</label>
                    <input type="text" class="col-7" formControlName="country" />
                    <div class="col-12  d-flex"
                        *ngIf="supplierForm.get('country').invalid && (supplierForm.get('country').dirty || supplierForm.get('country').touched)">
                        <label class="col-4 invisible"></label>m
                        <small class="error col-8" *ngIf="supplierForm.get('country').hasError('maxlength')">
                            Maximum 30 characters allowed
                        </small>
                    </div>
                </div>
            </div>
            <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
                <button type="button" class="cancel me-3" (click)="close('CANCEL')"> CANCEL </button>
                <button type="submit" class="submit black-button me-3" (click)="addBtn()">{{buttonValue}}</button>
            </div>
            <button type="button" class="close black-button" (click)="close('CANCEL')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </form>
    <!-- supplier confirm popup -->
    <div class="popup-container" *ngIf="miniPopup && !redirect" style="width: 500px;">
        <h2 mat-dialog-title class="p-2 shadow-sm">Supplier Update confirmation</h2>
        <div mat-dialog-content>
            <p class="p-2">Are you sure that you want to Update?</p>
        </div>
        <div mat-dialog-actions class="d-flex flex-row-reverse ">
            <button class="black-button mx-3" (click)="openUpdateSupplierConfirmation('gc')">Yes</button>
            <div class="text-primary mx-3 " (click)="openUpdateSupplierConfirmation('child')">No</div>
        </div>
    </div>
    <!-- supplier confirm component -->
    <div *ngIf="redirect">
        <div class="d-flex justify-content-between align-items-center header">
            <span class="page-title">Supplier Confirm</span>
            <button class="help-btn" type="button" (click)="help()">Help</button>
        </div>
        <div class="d-flex flex-wrap gap-3 m-2">
            <div class="d-grid mx-3">
                <span>NA Code</span>
                <span class="fw-bold">{{formValues?.SupplierCode}}</span>
            </div>
            <div class="d-grid mx-3">
                <span>TMMC Code</span>
                <span class="fw-bold">{{formValues?.TMMCCode}}</span>
            </div>
            <div class="d-grid mx-3">
                <span>NUMMI Code</span>
                <span class="fw-bold">{{formValues?.NUMMICode}}</span>
            </div>
        </div>
        <div class="main-right m-3 p-3" style="overflow-x: auto;">
            <table class="tg w-100" aria-describedby="table1">
                <thead>
                    <tr>
                        <th class="tg-mg6y"></th>
                        <th class="tg-mg6y">New</th>
                        <th class="tg-mg6y">Current</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tg-mg6y">Name</td>
                        <td class="tg-eydr">{{redirectResponse?.name}}</td>
                        <td class="tg-eydr">{{formValues?.Name}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">Short Name</td>
                        <td class="tg-z5to">{{redirectResponse?.shortname}}</td>
                        <td class="tg-z5to">{{formValues?.ShortName}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">Address</td>
                        <td class="tg-eydr">{{redirectResponse?.address}}</td>
                        <td class="tg-eydr">{{formValues?.Address}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">City</td>
                        <td class="tg-z5to">{{redirectResponse?.city}}</td>
                        <td class="tg-z5to">{{formValues?.City}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">State</td>
                        <td class="tg-eydr">{{redirectResponse?.state}}</td>
                        <td class="tg-eydr">{{formValues?.State}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">zipcode</td>
                        <td class="tg-eydr">{{redirectResponse?.zipcode}}</td>
                        <td class="tg-eydr">{{formValues?.ZipCode}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">Country</td>
                        <td class="tg-eydr">{{redirectResponse?.country}}</td>
                        <td class="tg-eydr">{{formValues?.Country}}</td>
                    </tr>
                    <tr>
                        <td class="tg-mg6y">Select</td>
                        <td class="tg-eydr"><button class="vpacs-btn-table" (click)="confirmUpdate(1)">Select</button></td>
                        <td class="tg-eydr"><button class="vpacs-btn-table" (click)="confirmUpdate(2)">Select</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button type="button" class="close black-button" (click)="close('child')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div class="popup-container" *ngIf="deleteFlag" style="width: 500px;">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
    <div class="alert alert-warning mx-2" *ngIf="errorFlag">
        {{errorMessage}}
    </div>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete {{dataStore.field?.suppliercode}}?</p>
    </div>
    <div mat-dialog-actions class="d-flex flex-row-reverse ">
        <button class="black-button mx-3" (click)="deleteSupplier()">Yes</button>
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
    </div>
</div>