<div class="popup-container bg-white">
    <div class="header text-middle">
        Delivery History
    </div>
    <button class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="mat-typography content">
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">AUTHORIZATION</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Auth Number<span
                            class="text-black">{{deliveryDetails?.authNumber}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Target Delivery Date<span
                            class="text-black">{{deliveryDetails?.deliveryDate | date:'MM/dd/YYYY'}}</span></div>
                </div>
                <div class="filter d-flex mb-3">
                    <div class="grid-agg">
                        <ag-grid-angular style="width:100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
                            [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs1"
                            [rowData]="displayRowData1" [suppressCellSelection]="true"
                            [suppressPaginationPanel]="true" [rowClassRules]="rowClassRules"
                            [icons]="icons" [rowHeight]="40" [headerHeight]="40" (window:resize)="sizeToFit()">
                        </ag-grid-angular>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel9 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container ">
                    <mat-panel-title class="fw-bold">DELIVERY</mat-panel-title>
                </mat-expansion-panel-header><br>
                <div class="filter d-flex">
                    <div class="grid-agg delivery-grid">
                        <ag-grid-angular style="width:100%" class="ag-theme-balham" (gridReady)="onGridReady($event);"
                            [animateRows]="true" [rowHeight]="40" [rowClassRules]="rowClassRules"
                            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs2" [rowData]="displayRowData"
                            [suppressPaginationPanel]="true" [suppressCellSelection]="true"
                            [suppressRowTransform]="true" [domLayout]="domLayout" [icons]="icons" [headerHeight]="40">
                        </ag-grid-angular>
                    </div><br>
                </div>

            </mat-expansion-panel>

        </mat-accordion>
    </div><br>
    <div class="footer-dialog d-flex">
        <div mat-dialog-close class="mx-3 cancel">Cancel</div>
    </div>

</div>