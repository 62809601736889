<div class="d-flex justify-content-between align-items-center m-2">
    <p class="page-title mx-2 px-2">Plant Maintenance</p>   
    <div>
        <button class="submit black-button mx-3" style="height:30px" (click)="addPlant()" [disabled]="!haveAddAccess">ADD</button>
        <button class="help-btn" (click)="help()">help</button>
      </div>
</div>
<div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
    <span>{{ successMessage }}</span>
    <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
    <div class="grid-agg tracking-summary grid-height-plant mb-5">
        <ag-grid-angular style="width:100%;height:100%;" 
            class="ag-theme-balham" 
            (gridReady)="onGridReady($event);"
            (window:resize)="sizeToFit()" 
            [rowHeight]="50" 
            [animateRows]="true" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData" 
            [suppressCellSelection]="true"
            [suppressHorizontalScroll]="false" 
            [pagination]="true" 
            [paginationPageSize]="paginationPageSize"
            [suppressPaginationPanel]="true" 
            [icons]="icons" 
            [floatingFiltersHeight]="40"
            [overlayLoadingTemplate]="overlayLoadingTemplate" 
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="domLayout" 
            [rowClassRules]="rowClassRules"
            (filterChanged)="onFilterChanged($event)"
            >
        </ag-grid-angular>    
        <app-pagination 
            [cPage]="cPage" 
            [tPage]="tPage" 
            [rpPage]="rpPage" 
            [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)"
            >
        </app-pagination>     
    </div>   
</div>
<router-outlet></router-outlet>   



