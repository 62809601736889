<div class="log px-2">
  <div class="px-2">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="page-title">Quantity Log</div>
      <div>
        <button (click)="onBack()" class="help-btn">Back</button>
        <button class="help-btn" (click)="help()">Help</button>
      </div>
    </div>
    <div class="top">
      <div class="top-data">
        <span>Part Number</span>
        <span>{{ response?.SupplierInfo[0]?.partnumber }}</span>
      </div>
      <div class="top-data">
        <span>Package Code</span>
        <span>{{ response?.SupplierInfo[0]?.packagecode }}</span>
      </div>
      <div class="top-data">
        <span>Dunnage1 Code</span>
        <span>{{ response?.SupplierInfo[0]?.dunnage1code }}</span>
      </div>
      <div class="top-data">
        <span>Dunnage2 Code</span>
        <span>{{ response?.SupplierInfo[0]?.dunnage2code }}</span>
      </div>
      <div class="top-data">
        <span>Plant</span>
        <span>{{ response?.SupplierInfo[0]?.plantcode }}</span>
      </div>
      <div class="top-data">
        <span>Supplier Code</span>
        <span>{{ response?.SupplierInfo[0]?.suppliercode }}</span>
      </div>
      <div class="top-data">
        <span>Short Name</span>
        <span>{{ response?.SupplierInfo[0]?.shortname }}</span>
      </div>
      <div class="top-data">
        <span>Name</span>
        <span>{{ response?.SupplierInfo[0]?.name }}</span>
      </div>
    </div>
  </div>
  <div>
    <div class="grid-agg grid-height-quantity-log mb-5">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [animateRows]="true"
        [headerHeight]="50"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [suppressCellSelection]="true"
        [paginationPageSize]="rpPage"
        [pagination]="true"
        [suppressPaginationPanel]="true"
        [icons]="icons"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      >
      </ag-grid-angular>
      <app-pagination
        [cPage]="cPage"
        [tPage]="tPage"
        [rpPage]="rpPage"
        [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)"
        *ngIf="totalRecords > 0"
      >
      </app-pagination>
    </div>
  </div>
  <div class="align mb-3">
    <div class="grid-agg  grid-height mb-0" >
    <ag-grid-angular
      class="ag-theme-balham grid-items"
      style="width: 520px; height : 100px"
      (gridReady)="onGridReadyTotal($event)"
      [animateRows]="true"
      [defaultColDef]="defaultColDefTotal"
      [columnDefs]="columnDefsTotal"
      [rowData]="displayRowDataTotal"
      [rowHeight]="40"
      [suppressCellSelection]="true"
      [paginationPageSize]="rpPage"
      [pagination]="true"
      [suppressPaginationPanel] = "true"
    >
    </ag-grid-angular>
  </div>
</div>
</div>

 
