<p class="page-title mx-3">Temporary Container</p>

<div class="tab" >
    <p>Part Information:</p>
    <table style="width:30% ;  border: 1px solid #BCD1E0;" aria-describedby="">
        <th  class="d-none"></th>
            <tr>
                <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Plant</td>
                <td style="border-right:1px solid #bcd1e0">{{fplant}}</td>
            </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Family Code</td>
              <td style="border-right:1px solid #bcd1e0">{{ffamily}}</td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Model Code</td>
              <td style="border-right:1px solid #bcd1e0">{{fmodel}}</td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">PartNumber</td>
              <td style="border-right:1px solid #bcd1e0">{{fpart}}</td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Supplier</td>
              <td style="border-right:1px solid #bcd1e0">{{fsupplier}}</td>
          </tr>
          
      </table><br>

      <p>Temporary Container Specification:</p>
      <table style="width:50% ;  border: 1px solid #BCD1E0;" aria-describedby="">
        <th  class="d-none"></th>
            <tr>
                <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Length <span style="color:#ff0000">*</span></td>
                <td style="border-right:1px solid #bcd1e0">
                  <input class="smlinput" type="text" [(ngModel)]="length" (input)="lengthConv()">  ( <input class="smlinput" [value]="mmlength" type="text"> mm)
                </td>
            </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Width <span style="color:#ff0000">*</span></td>
              <td style="border-right:1px solid #bcd1e0">
                <input class="smlinput" type="text" [(ngModel)]="width" (input)="widthConv()">  ( <input class="smlinput" [value]="mmwidth" type="text"> mm)
              </td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Height <span style="color:#ff0000">*</span></td>
              <td style="border-right:1px solid #bcd1e0">
                <input class="smlinput" type="text" [(ngModel)]="height" (input)="heightConv()">  ( <input class="smlinput" [value]="mmheight" type="text"> mm)
              </td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Weight <span style="color:#ff0000">*</span></td>
              <td style="border-right:1px solid #bcd1e0">
                <input class="smlinput" type="text" [(ngModel)]="pounds" (input)="kgConversion()">  ( <input class="smlinput" [value]="kg" type="text"> kg)
              </td>
          </tr>
          <tr>
              <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Qty Parts/Container <span style="color:#ff0000">*</span></td>
              <td style="border-right:1px solid #bcd1e0">
                  <input type="text" [(ngModel)]="qtyParts" [value]="fqpc">
              </td>
          </tr>
          <tr>
            <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Package Cost/Piece $(US) <span style="color:#ff0000">*</span></td>
            <td style="border-right:1px solid #bcd1e0">
                <input type="text" [(ngModel)]="cost" [value]="fcost">
            </td>
        </tr>
        <tr>
            <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Payment Responsibility <span style="color:#ff0000">*</span></td>
            <td style="border-right:1px solid #bcd1e0">
                <label><input type="radio" [(ngModel)]="fradioVal" id="op1" name="options" value="0" checked>Toyota</label>&nbsp;&nbsp;
                <label><input type="radio" [(ngModel)]="fradioVal" id="op2" name="options" value="1">Supplier</label> 
            </td>
        </tr>
          
      </table><br>
    
    <label for="reason">Reason: <span style="color:#ff0000">*</span></label>
    <textarea id="reason" name="reason" rows="3" cols="45" [(ngModel)]="freason"></textarea><br><br>

    <table style="width:70% ;  border: 1px solid #BCD1E0;" aria-describedby="">
        <th  class="d-none"></th>
            <tr>
                <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">#</td>
                <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">FileName</td>
                <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0"></td>           </tr>
          <tr>
              <td style="border-right:1px solid #bcd1e0">1</td>
              <td style="border-right:1px solid #bcd1e0">
                  <input type="file" (change)="onFileSelected($event)">
              </td>
              <td style="border-right:1px solid #bcd1e0">
                <button class="submit black-button me-3 roboto-bold" (click)="addrow()">ADD</button>
              </td>
          </tr>

          <tr *ngFor="let row of tableData; let i=index"> 
            <td style="border-right:1px solid #bcd1e0">{{i+2}}</td>
            <td style="border-right:1px solid #bcd1e0">
                <input type="file" (change)="onFileSelected($event)">
            </td>
            <td style="border-right:1px solid #bcd1e0">
              <button class="submit black-button me-3 roboto-bold" (click)="addrow()">ADD</button>
            </td>
        </tr>
                    
      </table><br>

      <div><span style="color:#ff0000">(*) REQUIRED </span></div><br>
<div >
    <button *ngIf="update" class="submit black-button me-3 roboto-bold" (click)="updateApi()">UPDATE</button>
    <button *ngIf="add" class="submit black-button me-3 roboto-bold" (click)="onSubmit()">ADD</button>
    <button class="cancel me-3 roboto-bold" (click)="cancel()"> CANCEL </button>
</div>

</div><br>
