import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver-es';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';

@Component({
  selector: 'app-import-package-cost',
  templateUrl: './import-package-cost.component.html',
  styleUrls: ['./import-package-cost.component.scss']
})
export class ImportPackageCostComponent implements OnInit {
 
  public image:any [] = [];
  isExport = false;
  plantCode:any
  errorList=''
  successMessage=''
  constructor(public dialogRef: MatDialogRef<ImportPackageCostComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public message: MatSnackBar,
  private readonly service: PackageMaintananceService, private readonly router: Router,private readonly spinnerService: NgxUiLoaderService,
  ) { }
  ngOnInit(): void {
    this.isExport = this.data.isExport;
    this.plantCode=this.data.plantCode;
  }

  onDelete(message: string) {
    this.errorList = message
    setTimeout(() => {
      this.errorList = ''
    }, 4000)
  }

  Onsuccess(message: any) {
    this.successMessage = message
    setTimeout(() => {
      this.successMessage = ''
    }, 4000)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  packageCode(){
    this.spinnerService.start()
    
    this.service.packageDownload().subscribe({
      next: (response: any) => {
        const filename = response.FileName;
        const toConvert = response.data;
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
        setTimeout(() => {
          if(response.status==="SUCCESS"){
            fileSaver.saveAs(file, filename);
            this.spinnerService.stop()
            this.Onsuccess('package exported Successfully ');
          }else{
             this.onDelete('Failed to export package');
          }
        });
      },
      error: () => {
        this.spinnerService.stop()
       this.onDelete('Failed to export package');
      },
    })
         
  }


 
  downloadCost() {   
    if(this.plantCode){
    this.spinnerService.start()

      const plantCode = {
      
        "PlantCode": this.plantCode,    
      }
      this.service.backUpStatusDownload(plantCode).subscribe({
        next: (response: any) => {  
          const filename =  response.FileName               
          const toConvert = response.data
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
          setTimeout(() => {
          if(response.status==="SUCCESS"){
            fileSaver.saveAs(file, filename);
           this.spinnerService.stop()
           this.Onsuccess('cost exported Successfully ');
          }else{
           this.spinnerService.stop()
            this.onDelete('Failed to export cost');
         }
          });
        },
        error: () => {
          this.spinnerService.stop()
          this.onDelete('Failed to export cost');
        },
      })
    }else{
      this.spinnerService.stop()
    }
  }
  
  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  help(){
    this.dialogRef.close();
this.router.navigate(['./master-help',5,"Master Maintenance",144])
  }
}
