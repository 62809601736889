import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service';
import { formatDate } from '@angular/common';
import { HazmatApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/hazmat-approvement-list/hazmat-approvement-list.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-dept-approve',
  templateUrl: './dept-approve.component.html',
  styleUrls: ['./dept-approve.component.scss']
})
export class DeptApproveComponent implements OnInit {
  sent1 = "Conv";
  sent2: any;
  sent3 = "QC";
  sentCode: any;
  person1: any;
  person2: any;
  person3: any;
  personStore: any;
  partInfo: any;
  partInfoContents: any;
  rowClassRules: any;
  deptInfo: any;
  deptInfoContents: any;
  convInfo: any;
  convInfoContents: any;
  convHeaderInfo: any;
  safety: any = 'data';

  tables: any[] = [
    {
      data: [
        { column1: 'Data 1', column2: 'Data', column3: 'Data3' }
      ],
      headers: ['column1', 'column2', 'column3']
    },

  ]
  plantCode: any;
  partNumber: any;
  supplierCode: any;
  dockCode: any;
  step: any;
  currentIntNo: any;
  currentIntVer: any;
  mode: any;
  pkgStartDate: any;
  details: any;
  leaderPlant: any;
  plantName: any;
  supplierName: any;
  specialist: any;
  modelCode: any;
  dateSent: any;
  spComment: any;
  showConceptCompare = false;
  currentDept: any;
  btnSendVisible = false;
  rdoCvSafetyOK = false;
  rdoCvSafetyNG = false;
  rdoCvProcessOK = false;
  rdoCvProcessNG = false;
  rdoCvProductivityOK = false;
  rdoCvProductivityNG = false;
  txtCvComment = "";
  txtCvProcessComment = "";
  txtCvProcessInvCost = "";
  txtCvProcessLabCost = "";
  txtCvProdComment = "";
  txtCvProdInvCost = "";
  txtCvProdLabCost = "";
  txtCvSafeComment = "";
  selDivcode = false;
  selPerson = false;
  rdoMfSafetyOK = false;
  rdoMfSafetyNG = false;
  rdoMfProcessOK = false;
  rdoMfProcessNG = false;
  rdoMfProductivityOK = false;
  rdoMfProductivityNG = false;
  txtMfComment = "";
  txtMfProcessComment = "";
  txtMfProcessInvCost = "";
  txtMfProcessLabCost = "";
  txtMfProdComment = "";
  txtMfProdInvCost = "";
  txtMfProdLabCost = "";
  txtMfSafeComment = "";
  selQcPerson = false;
  rdoQcSafetyOK = false;
  rdoQcSafetyNG = false;
  txtQcComment = "";
  txtQcQvalityComment = "";
  apprResult: any;
  divCode: any[] = [];
  paramData: any;
  rdoCvSafetyOKDisabled = false;
  rdoCvSafetyNGDisabled = false;
  rdoCvProcessOKDisabled = false;
  rdoCvProcessNGDisabled = false;
  rdoCvProductivityOKDisabled = false;
  rdoCvProductivityNGDisabled = false;
  txtCvCommentDisabled = false;
  txtCvProcessCommentDisabled = false;
  txtCvProcessInvCostDisabled = false;
  txtCvProcessLabCostDisabled = false;
  txtCvProdCommentDisabled = false;
  txtCvProdInvCostDisabled = false;
  txtCvProdLabCostDisabled = false;
  txtCvSafeCommentDisabled = false;
  selDivcodeEnabled = false;
  selPersonDisabled = false;
  rdoMfSafetyOKDisabled = false;
  rdoMfSafetyNGDisabled = false;
  rdoMfProcessOKDisabled = false;
  rdoMfProcessNGDisabled = false;
  rdoMfProductivityOKDisabled = false;
  rdoMfProductivityNGDisabled = false;
  txtMfCommentDisabled = false;
  txtMfProcessCommentDisabled = false;
  txtMfProcessInvCostDisabled = false;
  txtMfProcessLabCostDisabled = false;
  txtMfProdCommentDisabled = false;
  txtMfProdInvCostDisabled = false;
  txtMfProdLabCostDisabled = false;
  txtMfSafeCommentDisabled = false;
  selQcPersonDisabled = false;
  rdoQcSafetyOKDisabled = false;
  rdoQcSafetyNGDisabled = false;
  txtQcCommentDisabled = false;
  txtQcQvalityCommentDisabled = false;
  successMessage: any = null;
  errorMessage: any = null;
  selectedSentCode: any;
  selectedIndex: any;
  resultSpecialist: any[] = [];
  resultStore: any[] = [];
  currentDept2Check = false;
  currentDept3Check = false
  plants: any[] = [];
  resPlant: any[] = [];
  specialistList: any[] = [];
  urlInternal = '/internal-approvement';
  urlInt = 'internal-approvement';
  urlTrack = 'tracking-list';
  urlTracking = '/tracking-list';
  urlTrackInt = '/tracking-list/internal-approvement'
  sentCodeCopy: any;
  specialistCode: any;
  spCode: any;
  approve = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly iaService: InternalApprovementListService,
    private readonly hzService: HazmatApprovementListService,
    private readonly constants: ConstantsService,
    private readonly state: ExportStateService
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.paramData = JSON.parse(sessionStorage.getItem('deptApproveDetails') || '{}');
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spCode = res?.info?.specialistcode;
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    });
    this.specialistCode = JSON.parse(sessionStorage.getItem('specialistCode') || '') ? JSON.parse(sessionStorage.getItem('specialistCode') || '') : this.spCode;
    this.leaderPlant = this.paramData.leaderPlant;
    this.plantCode = this.paramData.plant;
    this.plantName = this.paramData.plantName;
    this.partNumber = this.paramData.part;
    this.supplierCode = this.paramData.supplier;
    this.supplierName = this.paramData.supplierName;
    this.specialist = this.paramData.specialist;
    this.dockCode = this.paramData.dock;
    this.modelCode = this.paramData.modelCode;
    this.step = this.paramData.step;
    this.mode = this.paramData.mode;
    this.pkgStartDate = this.paramData.psd;
    this.dateSent = this.paramData.dateSent;
    this.currentIntNo = this.paramData.currentIntNo;
    this.currentIntVer = this.paramData.currentIntVer;

    this.loadDeptApproveDetails();
    this.partInfo = [
      'Leader Plant', 'Plant', 'Dock', 'Model', 'Supplier', 'Part Number	', 'Specialist', 'Date Sent'
    ]

    this.partInfoContents = [
      (this.leaderPlant !== 'null') ? this.leaderPlant : '-',
      (this.plantName !== 'null') ? this.plantName : '-',
      (this.dockCode !== 'null') ? this.dockCode : '-',
      (this.modelCode !== 'null') ? this.modelCode : '-',
      (this.supplierName !== 'null') ? this.supplierName : '-',
      (this.partNumber !== 'null') ? this.partNumber : '-',
      (this.specialist !== 'null') ? this.specialist : '-',
      (this.dateSent !== 'null') ? formatDate(this.dateSent, 'MM/dd/YYYY', 'en-US') : '-',
    ]

    this.deptInfo = [
      'Send To', 'Person'
    ]

    this.deptInfoContents = [
      ''
    ]

    this.convInfo = [
      'Safety', 'Process', 'Connectivity'
    ]

    this.convHeaderInfo = [
      '', 'OK', 'NG', 'Cost', 'Comment'
    ]

    this.convInfoContents = [
      '', ''
    ]

    this.divCode = [
      { name: 'Mfg', code: 'MF', index: 1 },
      { name: 'Qc', code: 'QC', index: 2 }];

    if (Number(this.currentIntNo) === 0 || Number(this.currentIntNo) === 1) {
      this.showConceptCompare = false;
    } else {
      this.showConceptCompare = true;
    }

  }

  //1:Sp, 2:Conv, 3:Mfg, 4:Qc
  setDisabled(type: any) {
    if (type === 1) {
      this.setDisabledCv(true);
      this.setDisabledMf(true);
      this.setDisabledQc(true);
      this.btnSendVisible = false;
    } else if (type === 2) {
      this.setDisabledCv(false);
      this.setDisabledMf(true);
      this.setDisabledQc(true);
      this.btnSendVisible = true;
    } else if (type === 3) {
      this.setDisabledCv(true);
      this.setDisabledMf(false);
      this.setDisabledQc(true);
      this.btnSendVisible = true;
    } else if (type === 4) {
      this.setDisabledCv(true);
      this.setDisabledMf(true);
      this.setDisabledQc(false);
      this.btnSendVisible = true;
    }

    if (Number(this.currentIntNo) === 0 || Number(this.currentIntNo) === 1) {
      this.showConceptCompare = false;
    } else {
      this.showConceptCompare = true;
    }
  }

  setDisabledCv(disabled: any) {
    this.rdoCvSafetyOKDisabled = disabled;
    this.rdoCvSafetyNGDisabled = disabled;
    this.rdoCvProcessOKDisabled = disabled;
    this.rdoCvProcessNGDisabled = disabled;
    this.rdoCvProductivityOKDisabled = disabled;
    this.rdoCvProductivityNGDisabled = disabled;
    this.txtCvCommentDisabled = disabled;
    this.txtCvProcessCommentDisabled = disabled;
    this.txtCvProcessInvCostDisabled = disabled;
    this.txtCvProcessLabCostDisabled = disabled;
    this.txtCvProdCommentDisabled = disabled;
    this.txtCvProdInvCostDisabled = disabled;
    this.txtCvProdLabCostDisabled = disabled;
    this.txtCvSafeCommentDisabled = disabled;
    this.selDivcodeEnabled = !disabled;
    this.selPersonDisabled = disabled;
  }

  setDisabledMf(disabled: any) {
    this.rdoMfSafetyOKDisabled = disabled;
    this.rdoMfSafetyNGDisabled = disabled;
    this.rdoMfProcessOKDisabled = disabled;
    this.rdoMfProcessNGDisabled = disabled;
    this.rdoMfProductivityOKDisabled = disabled;
    this.rdoMfProductivityNGDisabled = disabled;
    this.txtMfCommentDisabled = disabled;
    this.txtMfProcessCommentDisabled = disabled;
    this.txtMfProcessInvCostDisabled = disabled;
    this.txtMfProcessLabCostDisabled = disabled;
    this.txtMfProdCommentDisabled = disabled;
    this.txtMfProdInvCostDisabled = disabled;
    this.txtMfProdLabCostDisabled = disabled;
    this.txtMfSafeCommentDisabled = disabled;
    this.selQcPersonDisabled = disabled;
  }

  setDisabledQc(disabled: any) {
    this.rdoQcSafetyOKDisabled = disabled;
    this.rdoQcSafetyNGDisabled = disabled;
    this.txtQcCommentDisabled = disabled;
    this.txtQcQvalityCommentDisabled = disabled;
  }

  sendPayload1() {
    return {
      currentDept: Number(this.currentDept),
      PlantCode: this.plantCode,
      PartNumber: this.partNumber,
      SupplierCode: this.supplierCode,
      PkgStartDate: this.pkgStartDate,
      IntApproveDock: this.dockCode,
      rdoCvSafetyOK: (this.rdoCvSafetyOK === true) ? "Checked" : "",
      rdoCvSafetyNG: (this.rdoCvSafetyNG === true) ? "Checked" : "",
      rdoCvProcessOK: (this.rdoCvProcessOK === true) ? "Checked" : "",
      rdoCvProcessNG: (this.rdoCvProcessNG === true) ? "Checked" : "",
      rdoCvProductivityOK: (this.rdoCvProductivityOK === true) ? "Checked" : "",
      rdoCvProductivityNG: (this.rdoCvProductivityNG === true) ? "Checked" : "",
      rdoMfSafetyOK: (this.rdoMfSafetyOK === true) ? "Checked" : "",
      rdoMfSafetyNG: (this.rdoMfSafetyNG === true) ? "Checked" : "",
      rdoMfProcessOK: (this.rdoMfProcessOK === true) ? "Checked" : "",
      rdoMfProcessNG: (this.rdoMfProcessNG === true) ? "Checked" : "",
      rdoMfProductivityOK: (this.rdoMfProductivityOK === true) ? "Checked" : "",
      rdoMfProductivityNG: (this.rdoMfProductivityNG === true) ? "Checked" : "",
      rdoQcSafetyOK: (this.rdoQcSafetyOK === true) ? "Checked" : "",
      rdoQcSafetyNG: (this.rdoQcSafetyNG === true) ? "Checked" : "",
      CvSafeComment: (this.txtCvSafeCommentDisabled === false) ? this.txtCvSafeComment : "",
    }
  }

  sendPayload2() {
    return {
      CvProcessInvCost: (this.txtCvProcessLabCostDisabled === false) ? this.txtCvProcessInvCost : "",
      CvProcessLabCost: (this.txtCvProcessLabCostDisabled === false) ? this.txtCvProcessLabCost : "",
      CvProcessComment: (this.txtCvProcessCommentDisabled === false) ? this.txtCvProcessComment : "",
      CvProdInvCost: (this.txtCvProdInvCostDisabled === false) ? this.txtCvProdInvCost : "",
      CvProdLabCost: (this.txtCvProdLabCostDisabled === false) ? this.txtCvProdLabCost : "",
      CvProdComment: (this.txtCvProdCommentDisabled === false) ? this.txtCvProdComment : "",
      CvComment: (this.txtCvCommentDisabled === false) ? this.txtCvComment : "",
      selDivcodeSelValue: this.sentCode ? this.sentCode : "",
      selPerson: (this.selPersonDisabled === false && this.person2 !== null) ? this.person2 : "",
      selConvPerson: "",
      MfSafeComment: (this.txtMfSafeCommentDisabled === false) ? this.txtMfSafeComment : "",
      MfProcessInvCost: (this.txtMfProcessInvCostDisabled === false) ? this.txtMfProcessInvCost : "",
      MfProcessLabCost: (this.txtMfProcessLabCostDisabled === false) ? this.txtMfProcessLabCost : "",
      MfProcessComment: (this.txtMfProcessCommentDisabled === false) ? this.txtMfProcessComment : "",
      MfProdInvCost: (this.txtMfProdInvCostDisabled === false) ? this.txtMfProdInvCost : "",
      MfProdLabCost: (this.txtMfProdLabCostDisabled === false) ? this.txtMfProdLabCost : "",
      MfProdComment: (this.txtMfProdCommentDisabled === false) ? this.txtMfProdComment : "",
      MfComment: (this.txtMfCommentDisabled === false) ? this.txtMfComment : "",
      selQcPerson: (this.selQcPersonDisabled === false && this.person3 !== null) ? this.person3 : "",
      QcComment: (this.txtQcCommentDisabled === false) ? this.txtQcComment : "",
      QcQvalityComment: (this.txtQcQvalityCommentDisabled === false) ? this.txtQcQvalityComment : "",
      selDivIndex: this.selectedIndex
    }
  }

  getPayload() {
    const body1 = this.sendPayload1();
    const body2 = this.sendPayload2();
    return {
      ...body1,
      ...body2
    }
  }

  send() {
    const payload = this.getPayload();
    this.spinnerService.start();
    this.iaService.sendDeptApprove(payload).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res.bool === true && res.status === 1) {
          this.approve=true;
          this.iaService.setApprove(this.approve)
          this.successMessage = "Send Approval Complete";
          setTimeout(() => {
            this.successMessage = null;
            this.router.navigate([this.urlInternal]);
          }, 4000);
        }
        else if (res.bool === false && res.status === -1) {
          this.errorMessage = res.ErrorMessage;
        }
      },
      error: (err: any) => {
        this.spinnerService.stop();
        this.errorMessage = err.error?.error?.message;
      },
    })
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }
  getAllPlant() {
    const plantObj: any = {
      SpecialistCode: this.specialistCode,
      Type: 1,
    }
    this.iaService.getAllPlant(plantObj).subscribe({
      next: (res: any) => {
        this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res]
        this.resPlant = res.map((item: { plantcode: any }) => item.plantcode)
        this.callSpecialist()
      },
      error: () => {
        this.spinnerService.stop()
      },
    })
  }

  callSpecialist() {
    const params = {
      PlantCodes: this.plantCode === '(All)' ? this.resPlant : [this.plantCode],
    }
    this.spinnerService.start();
    this.iaService.specialistCode(params).subscribe({
      next: (res: any) => {
        this.specialistList = res
        this.specialistList.forEach((val) => {
          if (this.person1 === val.specialistcode || this.person1 === val.lastname) {
            this.person1 = `${val.firstname.charAt(0)}.${val.lastname}`
          }
        });
        this.getConvPersonList();
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      }
    })
  }
  getConvPersonList() {
    const payload = {
      PlantCode: this.plantCode,
      DockCode: this.dockCode,
      divcode: this.selectedSentCode,
    }
    this.spinnerService.start();
    this.iaService.getConvPersonList(payload).subscribe({
      next: (res: any) => {
        this.resultStore = res;
        if (this.currentDept === 2 ) {  //changes
          this.currentDept2Check = true;
          this.currentDept3Check = true;
          this.resultStore.forEach((obj) => {
              this.resultSpecialist.push({ id: obj.SpecialistCode, label: obj.Specialist })
          });
        }
        else if (this.currentDept === 3  ) {  //changes
          this.currentDept2Check = false;
          this.currentDept3Check = true;
          if (this.person2 !== '') {
            this.sentCode = this.sentCodeCopy;
          }
          this.specialistList.forEach((item) => {
            if (this.person2 === item.specialistcode || this.person2 === item.lastname) {
              this.person2 = `${item.firstname.charAt(0)}.${item.lastname}`
            }
          })
          this.resultStore.forEach((obj) => {
            this.resultSpecialist.push({ id: obj.SpecialistCode, label: obj.Specialist })
          });
        }
        else {
          this.getConvPersonList2();
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = _err.error.message;
      },
    })
  }

  getConvPersonList2() {
    this.currentDept2Check = false;
    this.currentDept3Check = false;
    if (this.person2 !== '' || this.person3 !== '') {
      this.sentCode = this.sentCodeCopy;
    }
    this.specialistList.forEach((item) => {
      if (this.person2 === item.specialistcode || this.person2 === item.lastname) {
        this.person2 = `${item.firstname.charAt(0)}.${item.lastname}`
      }
      if (this.person3 === item.specialistcode || this.person3 === item.lastname) {
        this.person3 = `${item.firstname.charAt(0)}.${item.lastname}`
      }
    })
  }

  convApprove(apprResult: any) {
    if (apprResult?.cvsafeflg === '1') {
      this.rdoCvSafetyOK = true;
    } else if (apprResult?.cvsafeflg === '2') {
      this.rdoCvSafetyNG = true;
    }
    this.txtCvSafeComment = this.nullStr(apprResult?.cvsafecomment);

    if (apprResult?.cvprocflg === '1') {
      this.rdoCvProcessOK = true;
    } else if (apprResult?.cvprocflg === '2') {
      this.rdoCvProcessNG = true;
    }
    this.txtCvProcessInvCost = this.nullStr(apprResult?.cvprocinvcost);
    this.txtCvProcessLabCost = this.nullStr(apprResult?.cvproclabcost);
    this.txtCvProcessComment = this.nullStr(apprResult?.cvproccomment);

    if (apprResult?.cvprodflg === '1') {
      this.rdoCvProductivityOK = true;
    } else if (apprResult?.cvprodflg === '2') {
      this.rdoCvProductivityNG = true;
    }
    this.txtCvProdInvCost = this.nullStr(apprResult?.cvprodinvcost);
    this.txtCvProdLabCost = this.nullStr(apprResult?.cvprodlabcost);
    this.txtCvProdComment = this.nullStr(apprResult?.cvprodcomment);
    this.txtCvComment = this.nullStr(apprResult?.cvcomment);
    this.person2 = null;
    this.person3 = null;
    if (this.nullStr(apprResult?.mfcode) !== '') {
      this.person2 = apprResult?.mfcode;
      this.sentCode = '';
      this.sentCodeCopy = 'MF';
      this.selectedIndex = 1;
    }
    else if (this.nullStr(apprResult?.qccode) !== '') {
      this.person2 = apprResult?.qccode;
      this.sentCode = '';
      this.sentCodeCopy = 'QC';
      this.selectedIndex = 2;
    }
  }

  mfgApprove(apprResult: any) {
    if (apprResult?.mfsafeflg === '1') {
      this.rdoMfSafetyOK = true;
    } else if (apprResult?.mfsafeflg === '2') {
      this.rdoMfSafetyNG = true;
    }
    this.txtMfSafeComment = this.nullStr(apprResult?.mfsafecomment);

    if (apprResult?.mfprocflg === '1') {
      this.rdoMfProcessOK = true;
    } else if (apprResult?.mfprocflg === '2') {
      this.rdoMfProcessNG = true;
    }
    this.txtMfProcessInvCost = this.nullStr(apprResult?.mfprocinvcost);
    this.txtMfProcessLabCost = this.nullStr(apprResult?.mfproclabcost);
    this.txtMfProcessComment = this.nullStr(apprResult?.mfproccomment);

    if (apprResult?.mfprodflg === '1') {
      this.rdoMfProductivityOK = true;
    } else if (apprResult?.mfprodflg === '2') {
      this.rdoMfProductivityNG = true;
    }
    this.txtMfProdInvCost = this.nullStr(apprResult?.mfprodinvcost);
    this.txtMfProdLabCost = this.nullStr(apprResult?.mfprodlabcost);
    this.txtMfProdComment = this.nullStr(apprResult?.mfprodcomment);
    this.txtMfComment = this.nullStr(apprResult?.mfcomment);

    if ((this.nullStr(apprResult?.qccode) !== '') && (this.nullStr(apprResult?.mfcode) !== '')) {
      this.person3 = apprResult?.qccode;
      this.sent3 = 'QC';
    }
  }

  qcApprove(apprResult: any) {
    if (apprResult?.qcsafeflg === '1') {
      this.rdoQcSafetyOK = true;
    } else if (apprResult?.qcsafeflg === '2') {
      this.rdoQcSafetyNG = true;
    }
    this.txtQcQvalityComment = this.nullStr(apprResult?.qcsafecomment);
    this.txtQcComment = this.nullStr(apprResult?.qccomment);

    if (this.currentDept === 1) {
      this.selectedSentCode = 'Conv'
    }
    else if (this.currentDept === 2) {
      this.selectedSentCode = this.sentCode;
    }
    else if (this.currentDept === 3) {
      this.selectedSentCode = 'QC';
    }
  }

  loadDeptApproveDetails() {
    if (this.router.url.includes(this.urlInt) || this.router.url.includes(this.urlTrack)) {
      const params: any = {
        PlantCode: this.plantCode,
        PartNumber: this.partNumber,
        SupplierCode: this.supplierCode,
        DockCode: this.dockCode,
        Step: Number(this.step),
        CurrentIntNo: Number(this.currentIntNo),
        CurrentIntVer: Number(this.currentIntVer),
        PkgStartDate: this.pkgStartDate
      }
      this.iaService.loadDeptApprove(params)
        .subscribe({
          next: (res: any) => {
            this.details = res;
            this.apprResult = res.GetIntAppList?.result[0];
            this.spComment = this.apprResult?.spcomment;
            if (Number(this.mode) === 1) {
              this.setDisabled(Number(this.mode));
            } else {
              this.currentDept = Number(this.apprResult?.currentdept);
              this.setDisabled(Number(this.currentDept));
            }
            if (this.nullStr(this.apprResult?.cvcode !== '')) {
              this.person1 = this.apprResult?.cvcode;
            }
            this.convApprove(this.apprResult);
            this.mfgApprove(this.apprResult);
            this.qcApprove(this.apprResult);
            this.getAllPlant();
            this.spinnerService.stop();
          },
          error: () => {
            this.spinnerService.stop();
          },
          complete: () => {
            this.spinnerService.stop();
          }
        });

    }
  }
  onSentCodeChange(event: any) {
    this.selectedSentCode = event.value;
    if (this.selectedSentCode === 'MF') {
      this.selectedIndex = 1;
    }
    else if (this.selectedSentCode === 'QC') {
      this.selectedIndex = 2;
    }
    this.resultSpecialist = [];
    this.getConvPersonList()
  }
  onCvSafety(event: any) {
    if (event.value === 0) {
      this.rdoCvSafetyOK = true;
      this.rdoCvSafetyNG = false;
    }
    else {
      this.rdoCvSafetyOK = false;
      this.rdoCvSafetyNG = true;
    }
  }
  onCvProcess(event: any) {
    if (event.value === 0) {
      this.rdoCvProcessOK = true;
      this.rdoCvProcessNG = false;
    }
    else {
      this.rdoCvProcessOK = false;
      this.rdoCvProcessNG = true;
    }
  }
  onCvProductivity(event: any) {
    if (event.value === 0) {
      this.rdoCvProductivityOK = true;
      this.rdoCvProductivityNG = false;
    }
    else {
      this.rdoCvProductivityOK = false;
      this.rdoCvProductivityNG = true;
    }
  }
  onMfSafety(event: any) {
    if (event.value === 0) {
      this.rdoMfSafetyOK = true;
      this.rdoMfSafetyNG = false;
    }
    else {
      this.rdoMfSafetyOK = false;
      this.rdoMfSafetyNG = true;
    }
  }
  onMfProcess(event: any) {
    if (event.value === 0) {
      this.rdoMfProcessOK = true;
      this.rdoMfProcessNG = false;
    }
    else {
      this.rdoMfProcessOK = false;
      this.rdoMfProcessNG = true;
    }
  }
  onMfProductivity(event: any) {
    if (event.value === 0) {
      this.rdoMfProductivityOK = true;
      this.rdoMfProductivityNG = false;
    }
    else {
      this.rdoMfProductivityOK = false;
      this.rdoMfProductivityNG = true;
    }
  }
  onQcSafety(event: any) {
    if (event.value === 0) {
      this.rdoQcSafetyOK = true;
      this.rdoQcSafetyNG = false;
    }
    else {
      this.rdoQcSafetyOK = false;
      this.rdoQcSafetyNG = true;
    }
  }
  private nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }
  conceptCompare() {
    const params = {
      ppfcode1: this.details.GetPPFDetails.PPFCode,
      ppfver1: this.details.GetPPFDetails.PPFVer,
      ppfcode2: this.details.GetPPFDetails.PPFCode,
      ppfver2: -1
    }
    sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
    let uri = ''
    if (this.router.url === '/internal-approvement/dept-approve') {
      uri = this.urlInternal
    }
    else if (this.router.url.includes(this.urlTrack)) {
      if (this.router.url.includes('send-dept-approval')) {
        uri = '/tracking-list/send-dept-approval'
      }
      else if (this.router.url.includes('sI-final-dept-approval')) {
        uri = '/tracking-list/sI-final-dept-approval'
      }
      else if (this.router.url.includes('si-pkgtrial-dept-approval')) {
        uri = '/tracking-list/si-pkgtrial-dept-approval'
      }
      else if (this.router.url.includes('sI-preppf-dept-approval')) {
        uri = '/tracking-list/sI-preppf-dept-approval'
      }
      else if (this.router.url.includes('send-ppf')) {
        uri = '/tracking-list/send-ppf'
      }
      else if (this.router.url.includes('hazmat-approval')) {
        uri = '/tracking-list/hazmat-approval'
      }
      else if (this.router.url.includes('tracking-list/internal-approvement')) {
        uri = this.urlTrackInt
      }
      else {
        uri = this.urlTracking
      }
    }
    this.router.navigateByUrl(uri + '/dept-approve/concept-compare');
  }

  ppf() {
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plant);
    if (plantCode === 'SERVICE') {
      sessionStorage.setItem('partnumber',this.paramData.part);
      this.router.navigate(['/internal-approvement/dept-approve/serviceparts-pdf', this.paramData?.ppfcode, this.paramData?.ppfver, 1, 2])
    }  else if(plantCode === 'ASSY'){
      sessionStorage.setItem('partnumber', this.paramData?.part)
      this.router.navigate(['/internal-approvement/dept-approve/accessoryParts-pdf', this.paramData?.ppfcode, this.paramData?.ppfver, 1, 2])
    }
    else {
      sessionStorage.setItem('ppfcloseFromTracking', '0')
      window.open(`/internal-approvement/dept-approve/ppf/${this.details.GetPPFDetails.PPFCode}/${this.details.GetPPFDetails.PPFVer}/1/2`, '_blank');
      sessionStorage.setItem('ppfcloseFromTracking', '1') 
    }
  }

  cancel() {
    let uri = ''
    if (this.router.url === '/internal-approvement/dept-approve') {
      uri = this.urlInternal
    }
    else if (this.router.url.includes(this.urlTrack)) {
      if (this.router.url.includes('send-dept-approval')) {
        uri = '/tracking-list/send-dept-approval'
      }
      else if (this.router.url.includes('sI-final-dept-approval')) {
        uri = '/tracking-list/sI-final-dept-approval'
      }
      else if (this.router.url.includes('si-pkgtrial-dept-approval')) {
        uri = '/tracking-list/si-pkgtrial-dept-approval'
      }
      else if (this.router.url.includes('sI-preppf-dept-approval')) {
        uri = '/tracking-list/sI-preppf-dept-approval'
      }
      else if (this.router.url.includes('send-ppf')) {
        uri = '/tracking-list/send-ppf'
      }
      else if (this.router.url.includes('hazmat-approval')) {
        uri = '/tracking-list/hazmat-approval'
      }
      else if (this.router.url.includes('tracking-list/internal-approvement')) {
        uri = this.urlTrackInt
      }
      else {
        uri = this.urlTracking
      }
    }
    sessionStorage.removeItem('deptApproveDetails');
    this.router.navigateByUrl(uri);
  }
  close() {
    this.errorMessage = null;
  }
}
