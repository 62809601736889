import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { PPFSelectBtnRender } from '../../gridrender/ppf-select-btn-render';
import { RegexFilter } from '../../regexFilter/regex-filter';

@Component({
  selector: 'app-select-part',
  templateUrl: './select-part.component.html',
  styleUrls: ['./select-part.component.scss'],
  providers: [RegexFilter]
})
export class SelectPartComponent implements OnInit {

  public gridApi: any;
  public rowHeight = 40;
  public defaultColDef = {};
  public columnDefs: any[] = [];
  public domLayout:any ='';
  public displayRowData:any [] = [];
  public searchPart: any;
  public validForm = false;
  public currentPageNo: any;
  public currentPageBoolean = false;
  public totalPage = 0;
  public currentPageForTotalPage = 0;
  public isLastPageFound !: boolean;
  public paginationPageSize: any = 10;
  public rowCount !: number;
  public plantNames:any = [];
  public type = 'on_develop'
  public rowData:any ;
  public overlayLoadingTemplate =
  `<div class="spinner-border text-danger" role="status"></div>`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<SelectPartComponent>,
    public formbuilder : FormBuilder,
    public ppfService: PpfService,
    public regex:RegexFilter
    ) {
    this.dialogRef.disableClose = true;
    this.defaultColDef = {
      suppressMovable: true, 
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
      headerheight: 50
    }
  }

  ngOnInit(): void {
    this.ppfService.copyButtonLoadDropdown().subscribe((res)=>{
      this.plantNames = res;
    })
    this.ppfService.copyButtonOnload(this.data.supplierCode).subscribe({
      next: (res:any)=>{
        this.rowData = res;
        this.displayRowData = res[this.type];
        this.rowCount = this.displayRowData.length;
      },
      error: (_error)=>{
        this.displayRowData = [];
        this.rowCount = this.displayRowData.length;
      }
    })


    this.columnDefs = [
      {
        headerName: "Select", field: "select",cellRendererFramework: PPFSelectBtnRender,cellRendererParams: {
          clicked: (field:any)=>{ this.btnSelected(field) }
        },minWidth: 80, maxWidth: 100
      },
      {
        headerName: "Model", field: "model",minWidth: 80, maxWidth: 100
      },
      {
        headerName: "Plant", field: "plantCode",minWidth: 80, maxWidth: 100
      },
      {
        headerName: "Part Number", field: "partNumber",minWidth: 80, maxWidth: 100
      },
      {
        headerName: "Part Desc", field: "partDesc",wrapText:true,minWidth: 200, maxWidth: 400,cellStyle: (_params:any)=> {
          return {'white-space':'break-spaces','word-break':'break-word','line-height': '20px','display':'flex','justify-content': 'flex-start', 'height': '40px','padding':'0 10px'};
        }
      },
      {
        headerName: "package start date", field: "pkgStartDate", minWidth: 80, maxWidth: 100
      },
      {
        headerName: "container", field: "container", minWidth: 100, maxWidth: 140
      },      
      {
        headerName: "dunnage", field: "dunnage", minWidth: 100, maxWidth: 140
      },      
      {
        headerName: "dunnage2", field: "dunnage2", minWidth: 100, maxWidth: 140
      },
    ] ;

    this.searchPart = this.formbuilder.group(
      {
        plant: ['All'],
        model: [''],
        part: [''],
        type: ['on_develop'],
      }
    )
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage()}
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1}
  goToFirstPage() { this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();   
    if (this.currentPageNo === 0) { return true; }
    else { return false; }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }


  onPaginationChanged() {
    if (this.gridApi) {
      this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }
  
  onPageSizeChanged(event: any) {
    if (event.target.value === "ALL"){
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    }else{
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params:any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi.showLoadingOverlay()
  }

  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  btnSelected(params:any){
    let listType:number, date:any[]= [];
    if(this.searchPart.value.type === 'on_develop'){
      listType = 0;
    }else{
      listType = 1
    }
    date = params.pkgStartDate.split('/');
    const body = {
      hidPlantCode: params.plantCode,
      hidPartNumber: params.partNumber,
      hidSupplierCode: params.supplierCode,
      hidPkgStartDate: `${date[2]}/${date[0]}/${date[1]}`,
      listType: listType,
      orgPartWeight : this.data.partWeight ? this.data.partWeight : 0
    }
    this.ppfService.copySelectButton(body).subscribe((res)=>{
      this.dialogRef.close({data: res});    
    })
  }

  onSearch(){
    let {type,model,part,plant} = this.searchPart.value;
    let filteredData:any = [];
    filteredData = this.rowData[type];

    if(plant === 'All'){
      plant = '*';
    }
    const filteredParams:any = {
      model:model ? model : '*', 
      part:part ? this.regex.addStarForPartNumber(part) :'*',
      plantName:plant ? plant : '*'
    }

    filteredData = this.regex.regexFilter(filteredData,filteredParams)
    this.gridApi.setRowData(filteredData);
    this.rowCount = filteredData.length;   
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
