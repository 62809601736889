import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-adjust-quantity',
  templateUrl: './adjust-quantity.component.html',
  styleUrls: ['./adjust-quantity.component.scss'],
})
export class AdjustQuantityComponent implements OnInit {
  reasonList:any[] = [];
  routeParams: any;
  haveAccess = false;
  currentQty:any;
  toQty:any;
  selectedReason:any;
  userInfo: any;
  response: any;
  errorList:string[]=[];

  constructor(
    private readonly state: ExportStateService,
    private readonly loader: NgxUiLoaderService,
    public service: QuantityControlService,
    private readonly route: ActivatedRoute,
    public readonly router : Router
  ) {}

  ngOnInit(): void {
    this.loader.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_qtyview;
          this.userInfo = res?.info;
          this.haveAccess && this.getRouteValues();
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false;
      },
    });
  }
  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.apiCall();
      }
    });
  }

  apiCall() {
    const body = {
      PlantCode: this.routeParams.plant,
      SupplierCode: this.routeParams.supplier,
      PackageCode: this.routeParams.pkg,
      Part: this.routeParams.part,
      Year: this.routeParams.year,
      Week: this.routeParams.week,
      dunnage1: this.routeParams.dunnage1,
      dunnage2: this.routeParams.dunnage2,
      qty_type: this.routeParams.qtytype,
      Auth: "",
    };

    this.loader.start();
    this.service.adjustPackageOnload(body).subscribe({
      next: (res: any) => {
        if (res && !res.errMsg) {
          this.reasonList = res.ReasonData ? res.ReasonData : [];
          this.response = res;
          this.reasonList = res?.ReasonData;
          this.currentQty = res.CurrentQty;
          this.selectedReason = "select"
        }else{
          if(res.errMsg){
           this.showMessage([res.errMsg])

          }
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        const message = 'Something went wrong, Please contact System Admininstrator';
        this.showMessage( [message])

        this.loader.stop();
      },
    });
  }

  showMessage(message:string[]){
    this.errorList=message
    setTimeout(() => {
      this.errorList=[]
    }, 8000);
  }

  onSubmit() {

    if(this.selectedReason!== "select")
    {
    const body = {
      "PlantCode":  this.routeParams.plant ?? '',
      "SupplierCode": this.routeParams.supplier,
      "PackageCode": this.routeParams.pkg,
      "Year": this.routeParams.year,
      "Week": this.routeParams.week,
      "Dunnage1Code": this.routeParams.dunnage1,
      "Dunnage2Code": this.routeParams.dunnage2,
      "NewQty": this.toQty,
      "CurrentQty": this.currentQty,
      "ReasonCode": this.selectedReason,
      "UserSpecialistCode": this.userInfo?.specialistcode
    };
    this.loader.start();
    this.service.adjustAuthorize(body).subscribe({
      next: (_res:any)=>{
        if(_res){
              if(_res.status === 0){
                this.showMessage([_res.message])
              }else{
                this.onBack();
              }
          }else{
           const message= 'Something went wrong, Please contact System Admininstrator';
           this.showMessage([message])
          }
        this.loader.stop();
      },
      error : (_err:any)=>{
        this.loader.stop();
      }
    })
  }else{
    this.showMessage(['Reason is not selected.'])
  }
  }

  onBack(){
    this.router.navigate(['/quantityConrtol']);
  }
  help(){
    this.router.navigate(['./master-help',4,"Quantity Control",77])
  }
}
