import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { ConstantsService } from './constant.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(private readonly http: HttpClient, private readonly constantsService: ConstantsService) { }

	getUserInfo(toyotaGUID:any): Observable<any> {
		return this.http
			.get(this.constantsService.EXTERNAL + `login?toyotaGuid=${toyotaGUID}`,{headers: this.constantsService.httpOptions})
			.pipe(
				catchError(this.handleError<any>('data fails'))
			);
	}

	getUserInfoFreeze(toyotaGUID:any) {
		return this.http
		.get(this.constantsService.EXTERNAL + `login?toyotaGuid=${toyotaGUID}`,{headers: this.constantsService.httpOptions})
		.pipe(catchError(this.log))
	}

	
	getInternalUserInfo(toyotaGUID:string){
		return this.http
			.post(this.constantsService.MASTERCOST + `internal/login`,{guid : toyotaGUID},{headers: this.constantsService.internalHttpOptions})
			.pipe(catchError(this.log))
	}

	private handleError<T>(_operation:any, result?: T) {
		return (_error: any): Observable<T> => {
			return of(result as T);
		};
	}

	/** Log a message */
	log(error:HttpErrorResponse) {
		return throwError(()=> error) 
	}


}
