<div class="dialog-wrapper">
    <header>
        <button class="close black-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Select Part</label>
        </div>
    </header>
    <div class="content py-3">
        <div class="d-flex flex-wrap align-items-center gap-2 px-3" [formGroup]="searchPart" (keyup.enter)="onSearch()">
            <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
                <label for="plant">Plant</label>
                <ng-container *ngIf="!servicePDFCheck">
                    <input type="text" id="plant" class="pt-2" aria-label="text" matInput formControlName="plant" style="width: 120px;"
                    placeholder="--Select Plant--" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let x of plant | async" [value]="x.plantcode">{{ x.plant }}
                    </mat-option>
                  </mat-autocomplete>

                </ng-container> 
                <ng-container *ngIf="servicePDFCheck">
                     <input type="text" id="plant" class="pt-2" aria-label="text" matInput formControlName="plant" style="width: 120px;"
                    placeholder="--Select Plant--" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let x of plant | async" [value]="x.plantcode">{{ x.plant }}
                    </mat-option>
                  </mat-autocomplete>
                </ng-container>
            </div>
            <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
                <label for="model" >Model</label>
                <input type= "text" id="model" formControlName="model" style="width: 120px;"/>
            </div>
            <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
                <label for="part" >Part</label>
                <input type= "text" id="part" formControlName="part" style="width: 120px;"/>
            </div>
            <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
                <label for="type">Type</label>
                <mat-select formControlName="type" class="mat-sel pt-2 " disableOptionCentering>
                    <mat-option [value]="'on_develop'">On Developing</mat-option>
                    <mat-option [value]="'product'">Product</mat-option>
                </mat-select>
            </div>
            <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
                <div>&nbsp;</div>
                <button class="black-button mx-3" (click)="onSearch()" style="width: 120px;">Search</button>
            </div>
        </div>
        <div class="vpacs-shadow my-3 px-3">
            <div class="grid-agg">
                <ag-grid-angular style="width:100%;height:55vh;" 
                    class="ag-theme-balham" 
                    (gridReady)="onGridReady($event);"
                    (window:resize)="sizeToFit()" 
                    [rowHeight]="30" 
                    [animateRows]="true" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs" 
                    [rowData]="displayRowData" 
                    [suppressCellSelection]="true"
                    [showOpenedGroup]="true"
                    
                    [suppressAggFuncInHeader]='true'
                    [suppressHorizontalScroll]="false" 
                    [pagination]="true" 
                    [paginationPageSize]="paginationPageSize"
                    [suppressPaginationPanel]="true" 
                    [icons]="icons" 
                    [overlayLoadingTemplate]="overlayLoadingTemplate" 
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [domLayout]="domLayout" 
                    [rowClassRules]="rowClassRules"
                    [floatingFiltersHeight]="40" 
                    >
                </ag-grid-angular>
            <app-pagination 
            [cPage]="cPage" 
            [tPage]="tPage" 
            [rpPage]="rpPage" 
            [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)"
            *ngIf="totalRecords > 0"
            >
        </app-pagination> 
        </div>
    </div>
    </div>
    </div>