const center = 'center-center';
const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
	"bgsColor": "red",
	"bgsOpacity": 0.5,
	"bgsPosition": "bottom-right",
	"bgsSize": 60,
	"bgsType": "ball-spin-clockwise",
	"blur": 5,
	"delay": 0,
	"fastFadeOut": true,
	"fgsColor": "red",
	"fgsPosition": center,
	"fgsSize": 60,
	"fgsType": "ball-spin-clockwise",
	"gap": 24,
	"logoPosition": center,
	"logoSize": 120,
	"logoUrl": "",
	"masterLoaderId": "master",
	"overlayBorderRadius": "0",
	"overlayColor": "rgba(40, 40, 40, 0.8)",
	"pbColor": "red",
	"pbDirection": "ltr",
	"pbThickness": 3,
	"hasProgressBar": true,
	"text": "",
	"textColor": "#FFFFFF",
	"textPosition": center,
	"maxTime": -1,
	"minTime": 300
};
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';  
import { AppComponent } from './app.component';
import { ToolbarComponent } from './core/toolbar/toolbar.component';
import { FooterComponent } from './core/footer/footer.component';
import { AdminAccessComponent } from './pages/admin-access/admin-access.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { AuthorityListComponent } from './pages/authority-list/authority-list.component';
import { ShipmentComponent } from './pages/shipment/shipment.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { PkgAuthorizationComponent } from './pages/pkg-authorization/pkg-authorization.component';
import { TrackingLegendComponent } from './pages/tracking/tracking-legend/tracking-legend.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { TrackingBackupBtnCellRender } from './core/gridrender/tracking-backup-btn-cell-render';
import { TrackingActionBtnCellRender } from './core/gridrender/tracking-action-btn-cell-render';
import { UseBackupComponent } from './pages/tracking/use-backup/use-backup.component';
import { UseBackupTableRender } from './core/gridrender/use-backup-table-render';
import { PpfCellRenderer } from './core/gridrender/ppf-cell-render';
import { UseBackupDialogComponent } from './core/dialogs/use-backup-dialog/use-backup-dialog.component';
import { NotifyUpdateTrackingComponent } from './pages/tracking/notify-update-tracking/notify-update-tracking.component';
import { UseBackupTrackingGrid } from './core/gridrender/usebackup-tracking-grid';
import { PpfComponent } from './pages/tracking/ppf/ppf.component';
import { UpdatePpfComponent } from './core/dialogs/update-ppf/update-ppf.component';
import { SpinnerComponent } from './core/spinner/spinner.component';
import { HttpInterceptorInterceptor } from './core/spinner/http-interceptor.interceptor';
import { SelectFabricatorComponent } from './core/dialogs/select-fabricator/select-fabricator.component';
import { SelectPackagePPFComponent } from './core/dialogs/select-package-ppf/select-package-ppf.component';
import { NewPackageComponent } from './pages/tracking/ppf/new-package/new-package.component';
import { SelectPartComponent } from './core/dialogs/select-part/select-part.component';
import { UseBackupTrackingComponent } from './pages/tracking/use-backup-tracking/use-backup-tracking.component';
import { PackageAuthorizationDoBtnCellRender } from './core/gridrender/pkg-authorization-do-btn-cell-render';
import { PackageAuthorizationSubmitBtnCellRender } from './core/gridrender/pkg-authorization-submit-btn-cell-render';
import { ShipmentActionBtnCellRender } from './core/gridrender/shipment-action-btn-cell-render';
import {MatSelectModule} from '@angular/material/select';
import { NewShipmentComponent } from './pages/shipment/newshipment/newshipment.component';
import { MatRadioModule } from '@angular/material/radio';
import { SelectAuthComponent } from './core/dialogs/select-auth/select-auth.component';
import { SelectCompanyComponent } from './core/dialogs/select-company/select-company.component';
import { DocumentDownload } from './core/gridrender/document-download';
import { BackUpDefineTrackingComponent } from './pages/tracking/back-up-define-tracking/back-up-define-tracking.component';
import { SelectBackUpDefineComponent } from './pages/tracking/back-up-define-tracking/select-back-up-define/select-back-up-define.component';
import { TrackingDoReadinessComponent } from './pages/tracking/tracking-do-readiness/tracking-do-readiness.component';
import { TrackingDoConfirmComponent } from './pages/tracking/tracking-do-confirm/tracking-do-confirm.component';
import { TrackingDoShipTrailComponent } from './pages/tracking/tracking-do-ship-trail/tracking-do-ship-trail.component';
import { TrackingDoSendPrePdfComponent } from './pages/tracking/tracking-do-send-pre-pdf/tracking-do-send-pre-pdf.component';
import { DoPkgAuthorizationComponent } from './pages/pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';
import { DeliveryTargetComponent } from './pages/pkg-authorization/do-pkg-authorization/delivery-target/delivery-target.component';
import { AuthorityListBtnCellrender } from './core/gridrender/authoritylist-btn-cell-render';
import { TrackingDoSendFinalPdfComponent } from './pages/tracking/tracking-do-send-final-pdf/tracking-do-send-final-pdf.component';
import { TrackingDoForecastComponent } from './pages/tracking/tracking-do-forecast/tracking-do-forecast.component';
//msal
import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';
import { environment } from 'src/environments/environment';
import { ConstantsService } from './services/constant.service';
import { HistoryBtnCellRender } from './core/gridrender/history-btn-cell-render';
import { DeleteBtnCellRender } from './core/gridrender/delete-btn-cell-render';
import { HistoryShipmentComponent } from './core/dialogs/history-shipment/history-shipment.component';
import { RackReceivementComponent } from './core/dialogs/rack-receivement/rack-receivement.component';
import { RackShipmentComponent } from './core/dialogs/rack-shipment/rack-shipment.component';
import { AddRackShipmentComponent } from './core/dialogs/add-rack-shipment/add-rack-shipment.component';
import { TargetInputCellRender } from './core/gridrender/target-input-cell-render';
import { AuthorityDetailComponent } from './core/dialogs/authority-detail/authority-detail.component';
import { CheckBox } from './core/gridrender/checkBox';
import { CheckBoxDetail } from './core/gridrender/checkBoxDetail';
import { PPFInputCellRendererComponent } from './core/gridrender/ppf-input-cellRenderer';
import { RadioBtnCellRender } from './core/gridrender/radio-btn-cell-render';
import { RBtnCellRender } from './core/gridrender/R-btn-cell-render';
import { UnAuthorizedComponent } from './pages/un-authorized/un-authorized.component';
import { MessagePopupToastComponent } from './core/message-popup-toast/message-popup-toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InitialLoadComponent } from './pages/initial-load/initial-load.component';
import { SpinnerService } from './core/spinner/spinner.service';
import { UseBackupMasterComponent } from './pages/tracking/use-backup-master/use-backup-master.component';
import { ErrorInterceptorInterceptor } from './core/error-interceptor/error-interceptor.interceptor';
import { ErrorInterceptorServiceService } from './core/error-interceptor/error-interceptor-service.service';
import { DisableSpecialCharactersDirective } from './core/disable-special-characters.directive';


//Internal
import { InternalToolbarComponent } from './internal-vpacs/internal-toolbar/internal-toolbar.component';
import { InternalModule } from './internal-vpacs/internal.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DecCellRender } from './internal-vpacs/internal-Vpacs-gridrender/authorize/dec-cell-render';
import { CommentEditor } from './internal-vpacs/internal-Vpacs-gridrender/authorize/commentEditor';
import { ImportDataPopupComponentComponent } from './internal-vpacs/internal-vpacs-dialogs/master-maintenance/import-data-popup-component/import-data-popup-component.component';
import { DisableOnlyNumbers } from './pages/tracking/display-OnlyNumbers.directive';
import { DlstatusComponent } from './pages/tracking/dlstatus/dlstatus.component';
import { LogoutComponent } from './core/dialogs/logout/logout.component';

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.CLIENT_ID,
			authority: environment.TENANT_ID,
			redirectUri: environment.REDIRECT_URL
		}
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

@NgModule({
  declarations: [
    DisableSpecialCharactersDirective,
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    AdminAccessComponent,
    TrackingComponent,
    AuthorityListComponent,
    ShipmentComponent,
    DocumentationComponent,
    PkgAuthorizationComponent,
    TrackingDoForecastComponent,
    DoPkgAuthorizationComponent,
    TrackingLegendComponent,
    BreadcrumbComponent,
    TrackingBackupBtnCellRender,
    TrackingActionBtnCellRender,
    UseBackupComponent,
    UseBackupTableRender,
    UseBackupDialogComponent,
    NotifyUpdateTrackingComponent,
    UseBackupTrackingGrid,
    PpfComponent,
    UpdatePpfComponent,
    SpinnerComponent,
    PpfCellRenderer,
    SelectFabricatorComponent,
    SelectPackagePPFComponent,
    NewPackageComponent,
    SelectPartComponent,
    UseBackupTrackingComponent,
    PackageAuthorizationDoBtnCellRender,
    PackageAuthorizationSubmitBtnCellRender,
    ShipmentActionBtnCellRender,
    NewShipmentComponent,
    SelectAuthComponent,
    SelectCompanyComponent,
    DocumentDownload,
    HistoryBtnCellRender,
    DeleteBtnCellRender,
    HistoryShipmentComponent,
    RackReceivementComponent,
    RackShipmentComponent,
    AddRackShipmentComponent,
    TargetInputCellRender,
    BackUpDefineTrackingComponent,
    SelectBackUpDefineComponent,
    TrackingDoReadinessComponent,
    TrackingDoConfirmComponent,
    TrackingDoShipTrailComponent,
    TrackingDoSendPrePdfComponent,
    PkgAuthorizationComponent,
    DoPkgAuthorizationComponent,
    AuthorityDetailComponent,
    AuthorityListBtnCellrender,
    PPFInputCellRendererComponent,
    CheckBox, CheckBoxDetail,TrackingDoSendFinalPdfComponent,DeliveryTargetComponent,TrackingDoForecastComponent,
    RadioBtnCellRender,
    RBtnCellRender,
    UnAuthorizedComponent,
    MessagePopupToastComponent,
    InitialLoadComponent,
    UseBackupMasterComponent,
    DisableSpecialCharactersDirective,
    InternalToolbarComponent,
    DecCellRender,
    CommentEditor,
    ImportDataPopupComponentComponent,
    DisableOnlyNumbers,
    DlstatusComponent,
    LogoutComponent
  ],
  imports: [
    InternalModule,
    AgGridModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [ConstantsService,DatePipe,SpinnerService,SpinnerComponent,ErrorInterceptorServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi:true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorInterceptor,
      multi:true,
    },
    {
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useValue: {
				interactionType: InteractionType.Redirect
			} as MsalGuardConfiguration
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
