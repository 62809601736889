import { Component, OnInit } from "@angular/core";
import { Data, Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { ConstantsService } from "src/app/services/constant.service";
import { ExportStateService } from "src/app/services/export-state.service";
import { Breadcrumb } from "../../model/breadcrumb.model";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];
  public home ='' ;
  routerURL = '';
  unauthorized:any;
  constructor(
    public router: Router, 
    public activatedRoute: ActivatedRoute,
    public state:ExportStateService,
    public constants:ConstantsService) {
    this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);

  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);
      });
    this.getUserData();
  }

  toHome(){
    this.state.roleState.subscribe((data:any) => {
      if (data) {
        const access = this.state.checkAppType(data);
        if(access === 'Internal'){
          this.router.navigate(['/internal']);
        }else{
          //check the user has admin access
          const adminAccess = this.state.checkExternalAccess(data[0]);
          if(adminAccess){
            this.router.navigate(['/admin-access']);
          }else{
            this.router.navigate(['/tracking']);
          }
        }
      }
    })
  }

  getUserData(){
    this.state.userInfoState.subscribe((data:any)=>{
      if(data && data.info){
        this.unauthorized = false;
      }else{
        this.unauthorized = true;
      }
    })
  }

  public _buildBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url = ""
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = activatedRoute.children;
    if (children.length === 0) {
      return [];
    }

    let breadcrumbs: Breadcrumb[] = [];

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join("/");
      this.routerURL = routeURL;
      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      const routeData: Data = child.snapshot.data;

      if (routeData["breadcrumb"]) {
        breadcrumbs.push({ label: routeData["breadcrumb"], url: url });
      } else if (routeData["apiData"] && routeData["apiData"].breadcrumb) {
        breadcrumbs.push({
          label: routeData["apiData"].breadcrumb,
          url: url
        });
      }

      breadcrumbs = breadcrumbs.concat(this._buildBreadcrumbs(child, url));
    }

    return breadcrumbs;
  }
}
