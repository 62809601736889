import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { TrackingListService, dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IsRowSelectable } from 'ag-grid-community';

@Component({
  selector: 'app-update-confirm',
  templateUrl: './update-confirm.component.html',
  styleUrls: ['./update-confirm.component.scss']
})
export class UpdateConfirmComponent implements OnInit {
  gridApi: any;
  tracking=false
  columnDefs: any[] = [];
  totalPage = 0;
  displayRowData: any[] = [];
  displayRowDataDup: any[] = [];
  isLastPageFound!: boolean;
  defaultColDef: {};
  gridOptions: any;
  rowHeight = 60;
  headerHeight = 60;
  isAccessible = false;
  modelListArr: any[] = [];
  selectedRows: any[] = [];
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public isRowSelectable: IsRowSelectable = () => {
    return this.radioVal === 0 ? true : false
  };
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  radioVal = 1;
  updatePPF: any;
  result: any;
  resultHtml: any;
  partNumber: any;
  pkgDate: any;
  successMessage = ''
  errorMessage = ''
  isCheckedAllModel = false;
columnDupDefs:any[] = [];
  constructor(
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<UpdateConfirmComponent>,
    private readonly trackingListService: TrackingListService,
    @Inject(MAT_DIALOG_DATA) public dialog: MatDialog,
    private readonly PartListService: PartListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router: Router
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.start();
    });
    this.updatePPF = JSON.parse(sessionStorage.getItem('ppfUpdate') || '{}')
    this.columnDefs = [
      {
        headerName: "", field: "", minWidth: 20, maxWidth: 70,
        checkboxSelection: true
      },
      {
        headerName: "MODEL", field: "modelcode", minWidth: 60, maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "PLANT", field: "plant", minWidth: 60, maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "PART NUMBER", field: "partnumber", minWidth: 60, maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "SUPPLIER", field: "supplier", minWidth: 60, maxWidth: 142,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "PACKAGE START DATE", field: "pkgstartdate"
        , minWidth: 70, maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter',comparator: dateComparator,

      }
    ]
    this.columnDupDefs = [
     
      {
        headerName: "PLANT", field: "plant", minWidth: 60, maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "PART NUMBER", field: "partnumber", minWidth: 60, maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "SUPPLIER", field: "supplier", minWidth: 60, maxWidth: 142,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: "PACKAGE START DATE", field: "pkgstartdate"
        , minWidth: 70, maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter',comparator: dateComparator,

      }
    ]
    this.loadUpdatePpf();
  }

  loadUpdatePpf() {
    this.PartListService.updatePpfConfirm(this.updatePPF).subscribe((res: any) => {
      this.displayRowData = res[0].data
      this.displayRowDataDup=res[1]?.DuplicatePartNumber
      this.resultHtml = res[0].data[0]
      this.pkgDate = this.datepipe.transform(this.resultHtml.pkgstartdate, 'MM/dd/yyyy')
      this.spinnerService.stop();
    })
  }

  onNoClick() {
    sessionStorage.removeItem('ppfUpdate');
    this.dialogRef.close();
  }

  update() {
    this.successMessage = ''
    this.errorMessage = '';
    this.modelListArr = [];
    if (this.radioVal === 1) {
      this.isCheckedAllModel = true;
      this.displayRowData.forEach((e: any, _i: any) => {
        const modObj = {
          modelcode: e.modelcode,
          isupdate: true
        }
        this.modelListArr.push(modObj)
      });
      if(this.displayRowDataDup.length>0){
        if(this.tracking){
          this.updatePartNum();
        }
       
      }else{
      this.updatePartNum();
      }
    }
    else {
      this.isCheckedAllModel = false;
      if (this.displayRowData.length === this.selectedRows.length) {
        this.isCheckedAllModel = true;
      }
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach((e: any, _i: any) => {
          const modObj = {
            modelcode: e.modelcode,
            isupdate: true
          }
          this.modelListArr.push(modObj)
        });
        if(this.displayRowDataDup.length>0){
          if(this.tracking){
            this.updatePartNum();
          }
         
        }else{
        this.updatePartNum();
        }     
       }
      else {
        this.errorMessage = '304-001:Required Item Error [CheckBox], Select rows to update.'
      }
    }
  }

  updatePartNum() {
    this.spinnerService.start();
    const updatePartNum = {
      getmodellist: this.modelListArr,
      isexistduplicate: this.displayRowDataDup.length>0 ? true : false,
      ischeckoverwrite: this.tracking ? true : false,
      rdoallmodelchecked: this.radioVal === 1 ? true : false,
      IsCheckedAnyCheckBox: true,
      IsCheckedAllModel: this.isCheckedAllModel,
      plantcode: this.updatePPF.plantCode,
      newpart: this.updatePPF.newPartNumber,
      suppliercode: this.updatePPF.supplierCode,
      pkgstartdate: this.displayRowData[0].pkgstartdate,
      partnumber: this.updatePPF?.partNumber
    }
    this.trackingListService.updatePartConfirm(updatePartNum)
      .subscribe({
        next: (res: any) => {
          if (res.status === 'SUCCESS') {
            sessionStorage.removeItem('ppfUpdate');
            this.dialogRef.close();
            let uri = ''
            if (this.router.url.includes('tracking-list')) {
              uri = '/tracking-list';
            } else if (this.router.url.includes('part-list')) {
              uri = '/part-list';
            }

            this.router.navigateByUrl(uri);
          }
          else{
            this.errorMessage = res?.message;
          }
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  onSelectionChanged(_event: any) {
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  onGridReady(params: any) {
    this.gridApi = params.api
  }

  onRadioChange(event: any) {
    if (event?.value === 1) {
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(
        (rowNode: any) => rowNode.setRowSelectable(false)
      )
    }
    else if (event?.value === 0) {
      this.gridApi.forEachNode(
        (rowNode: any) => rowNode.setRowSelectable(this.isRowSelectable(rowNode))
      )
    }
  }

  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 2, "Tracking ", 161])

  }
}
