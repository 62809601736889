import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  URL = this.constantsService.MASTERCOST;
  onLoadFlag=true;

  searchHistory = {
    NACode: '',
    TMMCCode: '',   //used for two fields
    NUMMICode: '',
    Name: '',
    ShortName: '',
    onload:false,
    VendorCode : ''
  };

  setOnLoadFlag(val:any){
    this.onLoadFlag=val;
  }
  
  resetOnLoadFlag() {
    this.onLoadFlag = true; 
  }

  getOnLoadFlag(){
    return this.onLoadFlag;
  }

  setHistory(suppliercode:any,tmmccode:any,nummicode:any,name:any,shortname:any,onload:boolean,vendorcode:string){
    this.searchHistory.NACode =suppliercode
    this.searchHistory.TMMCCode =tmmccode
    this.searchHistory.NUMMICode =nummicode
    this.searchHistory.Name =name
    this.searchHistory.ShortName =shortname
    this.searchHistory.VendorCode = vendorcode
    this.searchHistory.onload=onload
  }

  getHistory(){
    return this.searchHistory
  }

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  supplierOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/SupplierOnload'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  supplierContactOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/SupplierContactOnload'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  updateSupplierOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/updateSupplierOnload'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  updateSupplier(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/updateSupplier'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }
  addButton(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/ADD'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }
  deleteButton(loadObj: any): Observable<any> {
    return this.http.delete((this.URL + 'Supplier/Delete'),
      { body: loadObj, headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  updateSupplierContactOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/updateSupplierContactOnload'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  updateSupplierContact(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'Supplier/updateSupplierContact'), loadObj,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  updateExistingSupplierCode(payload:any){
    return this.http.post((this.URL + 'Supplier/supplierSelectOnload'),payload,
      {headers : this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError));
  }

  confirmSupplierOld(payload:any){
    return this.http.post((this.URL + 'Supplier/btnNewServerClick'),payload,
      {headers : this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError));
  }

  confirmSupplierNew(payload:any){
    return this.http.post((this.URL + 'Supplier/btnCurrentServerClick'),payload,
      {headers : this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

}
