import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class PackageAuthorizationService {

  public stepURL = '../../../assets/json/pkgstep.json';

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  loadSteps() : Observable<any>{
    return this.http.get(this.stepURL).pipe(catchError(this.handleError))
  }

  testGetCount(supplierNumber:any){
    const URL1 = this.constantsService.EXTERNAL + `pkgAuthorisationCount?supplierCode=${supplierNumber}`
    return this.http.get(URL1,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))

  }

  loadPkgAuthorizationData(supplierCode:any,incrementor:any,rowMin:any) : Observable<any>{
    return this.http.get(this.constantsService.EXTERNAL + `pkgAuthorisation?supplierCode=${supplierCode}&rowMin=${rowMin}&incrementor=${incrementor}`, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  submitPkgAuthorizationData(authNumber:any, authStatus:any, body:any) : Observable<any>{
    return this.http.put(this.constantsService.EXTERNAL + `pkgAuthorisation?authNumber=${authNumber}&hidAuthStatus=${authStatus}`, body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return (error.message || "Server Error");
	}
}
