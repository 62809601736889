import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'select-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <select id="item" class="mat-sel pt-2 ms-3" disableOptionCentering >
      <option>xyz</option>
      <option>abc</option>
      <option>uvw</option>
    </select>
  </div>
  `,
})

export class SelectCellRenderer implements ICellRendererAngularComp {
  itemData: any;
  itemList: any[] =  ['xyz', 'abc'];

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;

  agInit(params: any): void {
    this.itemList = ['xyz', 'abc']
    this.params = params;
  }

}




