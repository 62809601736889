import { Component } from '@angular/core';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-delete-auth',
  templateUrl: './delete-auth.component.html',
  styleUrls: ['./delete-auth.component.scss']
})
export class DeleteAuthComponent {
  params: any;
  buttonName: any;
  deleteFlag = false;
  userInfo: any;
  constructor(
    private readonly state: ExportStateService
  ) { }


  agInit(params: any): void {
    this.params = params;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if(res){
          this.userInfo = res
        }
      },
      error: (_err: any) => {
      }
    })
    if (params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';

      if ((params.data.dummyflag === '0')) {
        if(params.data.specialistcode !== this.userInfo?.info?.specialistcode) {
          this.deleteFlag = true;
          for(let j=0; j < this.userInfo?.role?.backupDetails.length; j++) {
            if(params.data.specialistcode === this.userInfo?.role?.backupDetails[j]) {
              this.deleteFlag = false;
            }
          }
        }
      }
      else {
        this.deleteFlag = true;
      }
    }
  }

  btnClickedHandler() {
    if (this.params.colDef.headerName === 'DELETE') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }
  }

}
