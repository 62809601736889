import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PackageTypeService {

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  loadPackageTypeData() : Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'packageType/packageTypeoOnLoad';
    return this.http.get(URL, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  deletePackageType(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + `packageType/${body.PackageTypeCode}`;
    return this.http.delete(URL,{headers: this.constantsService.internalHttpOptions,body:body}).pipe(catchError(this.handleError))
  }

  addPackageType(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'packageType/addPackageTypes';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  updatePackageType(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST + 'packageType/updatePackageTypes';
    return this.http.put(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}
