<div class="d-flex justify-content-between align-items-center m-2">
  <p class="page-title">Update Package Fabricator Contact</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<app-notification [codes]="errorMessage" *ngIf="!viewAccess && errorMessage"></app-notification>
<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="row mx-2 mb-3 align-items-start">
    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Name<span class="text-black">{{ name }}</span>
    </div>
    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Supplier Code<span class="text-black">{{ supcode }}</span>
    </div>
    <div class="d-grid col-lg-1 col-md-6 col-12 my-2">
      Admin<label class="checkcontainer mb-0 my-1">
        <input type="checkbox" [(ngModel)]="admin" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="d-grid col-lg-1 col-md-6 col-12 my-2">
      Valid<label class="checkcontainer mb-0 my-1">
        <input type="checkbox" [(ngModel)]="valid" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Valid(# of task)<label class="checkcontainer mb-0 my-1">

        <input type="checkbox" [(ngModel)]="Authvalid" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="grid-agg tracking-summary grid-height-package-fabricator-contact">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" [rowHeight]="50" [animateRows]="true" [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true" [showOpenedGroup]="true"
      [autoGroupColumnDef]="autoGroupColumnDef" [suppressHorizontalScroll]="false" [headerHeight]="25" [icons]="icons"
      [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [domLayout]="domLayout" [rowClassRules]="rowClassRules" [floatingFiltersHeight]="40">
    </ag-grid-angular>
  </div>
</div>
<div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
  <div> <button class="help-btn me-3" style="height: 35px;" (click)="cancel()">BACK</button>
  </div>
  <button class="submit black-button me-3 roboto-bold" (click)="updatePkgFabContact()">
    Update
  </button>
</div>

<router-outlet></router-outlet>