import { Component, OnInit , Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-ringi',
  templateUrl: './update-ringi.component.html',
  styleUrls: ['./update-ringi.component.scss']
})
export class UpdateRingiComponent implements OnInit {
  type = [{Id:'2',name:"for Mainte"},{Id:'3',name:"for Proto"}];
  
  supplierCode = [];
  leaderPlant: any[] = [];
  leaderPlantContents: any[] = [];
  plant: any;
  supplier: any;
  ringi: any;
  ringiList = [];
  reason: any;
  reasonList = [];
  ringinumber= '';
  startDate='';
  endDate='';
  ringiNumber : any;
  oldRingiNumber = '';
  plantCode = '';
  plantcode = '';
  fromdate = '';
  maintenancecost: any = '';
  note = '';
  Note = '';
  todate = '';
  ringitype = '';
  modelCode = '';
  ringiType = '';
  ringitypeName = '';
  fromDate = '';
  toDate = '';
  maintenanceCost: any = '';
  estVer = '';
  ringiVer = '';
  ringiCost = '';
  res : any ='';
  params: any ='';
  ty: any ='';
  isEndDateError = false;
  isStartDateError = false;
  isPlantError = false;
  isTypeError = false;
  isRingiNumberError = false;
  isMainCostError = false;
  amNumeric = false;
  startEndDatetError = false;
  errorList : any[] = [];
  ringiNew = false;

  constructor(
    private readonly ringiListService:RingiListService,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<UpdateRingiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
      this.ringiNumber = this.data?.field?.ringinumber;
      this.oldRingiNumber = this.data?.field?.ringinumber;
      this.plantCode = this.data?.field?.plantname;
      this.modelCode = this.data?.field?.modelcode;
      this.ringiType = this.data?.field?.ringitype;
      this.ringitypeName = this.data?.field?.ringitypeName;
      this.maintenanceCost = this.data?.field?.maintenancecost;
      this.estVer = this.data?.field?.estver;
      this.ringiVer = this.data?.field?.ringiver;
      this.ringiCost = this.data?.field?.maintenancecost;
      this.Note = this.data?.field?.note;
      this.getRingi(this.ringiNumber);
    this.leaderPlant = [
      'Ringi #', 'Type', 'Plant', 'Start Date', 'End Date', 'Ringi Cost $(US)', 'Comment'
    ]
    this.leaderPlantContents = [
      'TMMC', '5A', '642L', 'TGM01', '76801-0E221', 'J.PORTILLO', '-'
    ]
  }
  closeDialog(){
    this.dialogRef.close()
  }
  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg });
  }
  checkValidations(){
    this.errorList = [];
    this.isRingiNumberError = false;
    this.isPlantError = false;
    this.isTypeError = false;
    this.isStartDateError = false;
    this.isEndDateError = false;
    this.isMainCostError = false;
    this.amNumeric = false;
    this.startEndDatetError = false;
    if(!this.ringiNumber){
      this.isRingiNumberError = true;
    }
    if(this.maintenanceCost){
      this.isNumeric('amCost');
    }
    if(!this.plant){
      this.isPlantError = true;
    }
    if(!this.ringiType){
      this.isTypeError = true;
    }
    if(!this.fromDate){
      this.isStartDateError = true;
    }
    if(!this.toDate){
      this.isEndDateError = true;
    }
    if (
      ((this.fromDate) || (this.toDate)) &&
      ((this.fromDate) > (this.toDate))
    ) {
      this.startEndDatetError = true
    }
  }
  isNumeric(name:any) {
    if(name === 'amCost')    {
      this.isMainCostError = !isNaN(parseFloat(this.maintenanceCost)) && isFinite(+this.maintenanceCost);  
      if(!this.isMainCostError){
        this.amNumeric = true;
      }
      else{
        this.amNumeric = false;
        this.isMainCostError =  false;
      }
    }
  }
  getRingi(ringiNumber: any){
    setTimeout(() => {this.spinnerService.start()});
    this.ringiListService.getRingi(ringiNumber).subscribe( {next: (res:any) => {
      this.plantcode = res.result[0].plantcode;
      this.fromDate = formatDate(res.result[0].fromdate,'yyyy-MM-dd', 'en-US','UTC');
      if(this.ringitypeName === 'New Model'){      
        this.ringiNew = true;
      }
      else{
        this.maintenanceCost = res.result[0].maintenancecost;
        this.ringiNew = false;
      }
      this.Note = res.result[0].note;
      this.ringiType = res.result[0].ringitype;
      this.toDate = formatDate(res.result[0].todate,'yyyy-MM-dd', 'en-US','UTC');
     } ,
     error: (_err: any) => {
       this.closeDialog();
       this.spinnerService.stop();
     },
     complete: () => {
       this.spinnerService.stop();
     }
    })
  }
  updateButton() { 
    this.checkValidations();
    if(this.isRingiNumberError || this.isTypeError || this.isStartDateError || this.isEndDateError || this.amNumeric || this.isMainCostError || this.startEndDatetError) {
      return;
    }
    const loadObj={
      "RingiNumber": this.ringiNumber,
      "OldRingiNumber": this.oldRingiNumber,
			"PlantCode":  this.plantCode,
			"Plant": this.plant,
			"ModelCode": this.modelCode,
			"RingiType":  Number(this.ringiType),
			"FromDate": this.fromDate,
			"ToDate": this.toDate,
			"MaintenanceCost": Number(this.maintenanceCost).toString(),
			"Note": this.Note,
			"EstVer": "0",
			"RingiVer": "0",
      "ScreenMode": "UPDATE"
    };
    this.spinnerService.start();
    this.ringiListService.addButtonRingi(loadObj).subscribe( {next: (res:any) => {
      if(res.message){
        this.errorList.push(res.message);
      }else{
        this.close('LOADRINGI');
      }
    } ,
    error: (_err: any) => {
      this.closeDialog();
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
  }
  help(){
    this.closeDialog();
    this.router.navigate(['./master-help',3,"Cost Management",48])
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'fromDate') {
        this.fromDate = '';
      }
      if (id === 'toDate') {
        this.toDate = '';
      }
    } 
  }

}
