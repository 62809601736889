import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { Router } from '@angular/router';
import { DeliveryTargetComponent } from '../../pkg-authorization/do-pkg-authorization/delivery-target/delivery-target.component';
import { MatDialog } from '@angular/material/dialog';
import { DoPkgAuthorizationComponent } from '../../pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';

@Component({
  selector: 'app-tracking-do-confirm',
  templateUrl: './tracking-do-confirm.component.html',
  styleUrls: ['./tracking-do-confirm.component.scss'],
  providers: [DoPkgAuthorizationComponent]
})
export class TrackingDoConfirmComponent implements OnInit {
  receiveFromToyota: any;
  orderInformation: any;
  reason: any;
  foreCastDate: any;
  packageReview: any;
  data: any;
  authNumber: any;
  constructor(
    private readonly trackingService: TrackingService,
    public router: Router,public dialog:MatDialog,
    private readonly doPkgAuthorizationComponent: DoPkgAuthorizationComponent
  ) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('queryParams') || '');
    this.confirmOnload();
  }

  confirmOnload() {
    this.trackingService.forcastOnload(this.data).subscribe({
      next: (res) => {
        this.receiveFromToyota = res[0].receiveFromToyota;
        this.orderInformation = res[0].orderInformation;
        this.reason = res[0].reason;
        this.packageReview = res[0].packageReview;
        this.foreCastDate = res[0].foreCastDate.date;
      }
    });
  }

  submit() {
    if(!this.foreCastDate) {
      return;
    }
    const req = {
      "hidPlantCode": this.data.plantCode,
      "hidPartNumber": this.data.partNumber,
      "hidSupplierCode": this.data.supplierCode,
      "hidPkgStartDate" :this.data.pkgStartDate,
      "hidModelCode": this.data.modelCode,
      "forecast": this.foreCastDate
    }
    this.trackingService.updateForcast(req).subscribe({
      next: (_res) => {
      },
      complete: ()=>{
        this.router.navigate(["/tracking"]);
      }
    });
  }

  onCancel() {
    this.router.navigate(["/tracking"]);
  }

  authDownload(){
    const queryParams = {
      authNumber: this.data.authNumber
    }
    sessionStorage.setItem('queryParamsDo', JSON.stringify(queryParams));
    this.doPkgAuthorizationComponent.authDownload()
  }

  deliveryTarget(){
    const queryParams = {
      authNumber: this.data.authNumber
    }
    sessionStorage.setItem('queryParamsDelv', JSON.stringify(queryParams));
    this.dialog.open(DeliveryTargetComponent, { width: '110%', height: '90%' })
  }

}
