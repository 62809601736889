import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';

@Component({
  selector: 'app-import-estimate-cost',
  templateUrl: './import-estimate-cost.component.html',
  styleUrls: ['./import-estimate-cost.component.scss']
})
export class ImportEstimateCostComponent implements OnInit {
  imageSrc: any;
  fileName = '';
  fileType:any[] = []
  fileDocForm:FormGroup = new FormGroup({
    file: new FormControl('', Validators.required),
    fileName: new FormControl('', Validators.required),
  })
  errorMessage1 = '';
  errorMessage = '';


  constructor(
    private readonly ringiListService:RingiListService,
    public readonly dialogRef:MatDialogRef<ImportEstimateCostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
   }

  ngOnInit(): void {
    this.fileType = ['xlsx','doc','docx','ppt','csv','pdf'];
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  nextBtn(){
    if(this.fileDocForm.valid && !this.errorMessage1){
      const body = this.fileDocForm.controls
      this.ringiListService.fileUpload(body).subscribe({
        next: (_res:any) =>{
          this.dialogData('add')
          this.closeDialog()
        },
      })
    }
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
    }
  }
  handleUpload(event:any) {
    const file = event.target.files[0];
    const prefix = 'application/';
    const contentType = file?.type.substring(prefix.length)
    this.fileDocForm.controls['fileName'].setValue(file?.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if(this.fileType.includes(contentType)){
        this.errorMessage1 = ''
        this.fileDocForm.controls['file'].setValue(reader.result);
      }else{
        this.errorMessage1 = `File's extension must be .xlsx , .doc , .docx , .ppt , .csv , .pdf`
      }
    };
  }
  dialogData(params:string){
    this.dialogRef.close({data: {type: params}});   
  }
  closeDialog(){
    this.dialogRef.close()
  }

}
