import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { UpdateSupplierInfoComponent } from './update-supplier-info/update-supplier-info.component';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss'],
})
export class SupplierInfoComponent implements OnInit {
  supplierCode = [];
  errorList: string[] = [];
  supName:any =''
  plant: any;
  supplier: any;
  response: any;
  naSupplierCode = '';
  tmmcCode: any = '';
  nummiSupplierCode: any = '';
  name: any = '';
  shortName: any = '';
  contactName: any = '';
  phone: any = '';
  fax: any = '';
  email: any = '';
  cell: any = '';
  zipCode: any = '';
  city: any = '';
  state: any = '';
  country: any = '';
  newSupplierCode: any = '';
  part: any = '';
  hascheck = false;
  plantC: any = '';
  supplierC: any = '';
  partC: any = '';
  userInfo: any;
  isUpdated = false;
  packageAuthSatus = false;
  constructor(
    public dialogRef: MatDialogRef<SupplierInfoComponent>,
    public dialogRef1: MatDialogRef<UpdateSupplierInfoComponent>,
    public trackingListService: TrackingListService,
    public readonly dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    public state1: ExportStateService,
    private readonly router:Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.start();
    });
    this.state1.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res.role;
        if(!this.userInfo?.p_trackingsave){
          this.hascheck=true;
        }
      },
    });
    const obj = {
      url_plant:this.dataStore.plantC,
      url_supplier:this.dataStore.supplierC,
      url_partnumber:this.dataStore.partNumber.replace('-',''),
      url_model:this.dataStore.model,
      url_pkgstartdate: this.dataStore.pkgStartDate,
      url_fromdate:this.dataStore.fromDate,
    };
    this.onLoad(obj);
    this.plantC = this.dataStore.plant;
    this.partC = this.dataStore.partNumber.replace('-', '');
    this.supplierC = this.dataStore.supplierC;
  }
  onLoad(obj: any) {
    this.errorList = [];
    this.trackingListService.shipmentOnLoad(obj).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();

        if (res && !res.Error) {
          this.response = res.data?.Data[0];
          this.naSupplierCode = this.response.suppliercode;
          this.tmmcCode = this.response.tmmccode;
          this.nummiSupplierCode = this.response.nummicode;
          this.name = this.response.name;
          this.shortName = this.response.shortname;
          this.contactName = this.response.contactFullName;
          this.phone = this.response.phone;
          this.fax = this.response.fax;
          this.email = this.response.email;
          this.cell = this.response.cellpager;
          this.zipCode = this.response.zipcode;
          this.state = this.response.state;
          this.city = this.response.city;
          this.country = this.response.country;
          this.packageAuthSatus = res.data.packageauthStatus;
          if(this.packageAuthSatus) {
            this.errorList = ['The Supplier Code cannot be changed at this state.'];
          }
        } else {
          this.errorList = res.message;
        }
      },

      error: (err: any) => {
        this.spinnerService.stop();
       this.errorList =[err.error.error];
      },
    });
  }

  updateBtn() {
    this.spinnerService.start();
    this.errorList = [];
    const body = {
      url_plant: this.dataStore.plantC,
      url_supplier: this.naSupplierCode,
      url_part:this.dataStore.partNumber.replace('-',''),
      SupplierType: this.userInfo.supType ? this.userInfo.supType : '0',
    };
    this.trackingListService.supplierUpdateOnLoad(body).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res) {
          this.dialogRef1 = this.dialog.open(UpdateSupplierInfoComponent, {
            data: {
              plant: res?.plantName,
              part: res?.partNumber,
              supplier: res.supplier,
              url_fromdate: this.dataStore.fromDate,
              plantC: this.dataStore.plantC,
              supplierC: this.dataStore.supplierC,
            },
          });
          this.dialogRef1.afterClosed().subscribe((result: any) => {
            if (result) {
              if(result.event==='SuccessEvent'){
                this.naSupplierCode = result.data.newSupplier;
                this.supName=result?.data?.name
                this.isUpdated = true;
                this.close()
              }
            }
          });
        } else {
          this.errorList = ['Not able to Load Data'];
        }
      },
      error: (err: any) => {
        this.spinnerService.stop();
        this.errorList =[err.error.error];
      },
    });
  }

  close(): void {
    if(this.isUpdated){
      this.dialogRef.close({ event: 'UpdateEvent' ,  data: { newSupplier: this.naSupplierCode, supName: this.supName },});
    }
    else{
      this.dialogRef.close({ event: 'CloseEvent' });
    }
  }

  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',2,"Tracking",22])
  }
}
