import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, of, startWith } from 'rxjs';
import { getParentErrorMessage } from 'src/app/internal-vpacs/notification/ErrorConstants';
import { UserMaintenanceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/user-maintenance.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  protected pageName = '';
  plant: any[] = [];
  filterOptions :Observable<any[]> | undefined;
  div: any[] = [];
  role: any[] = [];
  title: any = [
    { value: "0", desc: "Specialist" },
    { value: "1", desc: "Assistant Manager" },
    { value: "2", desc: "Manager" },
    { value: "3", desc: "PUR Spec" },
    { value: "4", desc: "PUR AM" },
    { value: "5", desc: "PUR MGR" },
  ];
  unit: any[] = [
    { value: "0", desc: "mm,Gram" },
    { value: "1", desc: "inch,Pound" },
  ]
  backup: any[] = [];
  bossList: any[] = [];
  dockList: any[] = [];

  public userForm: any;
  errorArray: any[] = [];
  oldSpecialistCode: any;
  onLoadResponse: any;

  group = [];
  index: any;
  groupList: any;
  selctedGroups: any
  groupUser: any;
  userInfo: any;
  roleInfo: any;
  enableRadio = true;
  codeName1 = '';
  codeName = '';
  backUpListUpdate!: Observable<any[]>;
  onLoadResponse2: any[] = [];
  profile = false;

  constructor(
    private readonly dialogRef: MatDialogRef<UpdateUserComponent>,
    public route: ActivatedRoute,
    public router: Router,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly userMaintenanceService: UserMaintenanceService,
    public loader: NgxUiLoaderService,
    public state: ExportStateService,
    public constantService: ConstantsService
  ) {
    
    this.userForm = this.fb.group({
      specialistcode: ['', [Validators.required, this.codeValidator]],
      firstname: ['', [Validators.required, this.firstNameValidator]],
      lastname: ['', [Validators.required, this.lastNameValidator]],
      middleinitial: ['', [this.middleInitialValidator]],
      plantcode: ['', Validators.required],
      title: ['', Validators.required],
      boss: ['', Validators.required],
      divcode: ['', Validators.required],
      phone: ['', [Validators.required, this.phoneValidator]],
      fax: [''],
      ext: [''],
      email: ['', [Validators.required, Validators.email, this.emailValidator]],
      backupCheck: [false],
      backup: [''],
      groupMemberCheck: [],
      groupMember: [''],
      unit: ['', Validators.required],
      roles: ['', Validators.required],
      dockcodes: [''],
      preprod: [],
      massprod: [],
      isValid: [],
    });
   
  }

  useApi() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info
        this.roleInfo = res?.role?.u_roles
         if(this.constantService.checkRolesUser(this.roleInfo)){
          this.enableRadio = false;
          this.userForm.patchValue({
            groupMemberCheck: 0
          })
          this.userForm.controls.groupMember.enable();
          if (this.pageName === 'update'|| this.pageName=== 'profile') {
            this.userForm.patchValue({
              groupMemberCheck: this.groupUser === 'Y' ? 1 : 0,
              groupMember: this.selctedGroups.length > 0 ? this.getuser(this.groupList, this.selctedGroups) : [],
            })

            if (this.groupUser === 'Y') {
              this.userForm.controls.groupMember.disable()
            }
            else {
              this.userForm.controls.groupMember.enable()
            }
          }
        }
        else {
          this.userForm.controls.groupMember.disable()
          this.enableRadio = true;
        }
      },
      error: (_err: any) => {
      }
    })
  }
  ngOnInit(): void {
    this.useApi()
    this.pageName = this.data?.pageName;
    if (this.pageName === 'add') {
      setTimeout(() => {
        this.loader.start();
        this.apiCall_Add();
        this.userForm.patchValue({
          isValid: 1,
        })
        this.userForm.controls.backup.disable();
      });
    }
    if (this.pageName === 'update') {
      setTimeout(() => {
        this.loader.start();
        this.apiCall_Update();
      });
    }
    if (this.pageName === "profile") {
      this.profile= true
      setTimeout(() => {
        this.loader.start();
        this.apiCall_Update();
      });
      this.userForm.controls.specialistcode.disable()
      this.userForm.controls.boss.disable()
      this.userForm.controls.preprod.disable()
      this.userForm.controls.massprod.disable()
      this.userForm.controls.email.disable()
      this.userForm.controls.dockcodes.disable()
    }else{
      this.userForm.controls.specialistcode.enable()
      this.userForm.controls.boss.enable()
      this.userForm.controls.email.enable()
      this.userForm.controls.preprod.enable()
      this.userForm.controls.massprod.enable()
      this.userForm.controls.dockcodes.enable()
    }

    this.userForm.controls.plantcode.valueChanges.subscribe((_res: any) => {
      const plantCheck = this.data.plants?.find((item: any) =>
      ((item.code?.toLowerCase() === this.userForm.get('plantcode').value?.toLowerCase())))
       if(plantCheck || this.pageName === 'profile'){
      this.plantChangeApiCall();
      this.divisonApiCall();
      }
    })
    this.userForm.controls.title.valueChanges.subscribe((_res: any) => {
      this.titleChangeApiCall();
    })
    this.userForm.controls.divcode.valueChanges.subscribe((_res: any) => {
      this.divisonApiCall();
    })

  }

  backupCheckChange(event: any) {
    if (event.target.checked) {
      this.userForm.controls.backup.enable();
    } else {
      this.userForm.controls.backup.disable();
    }
  }

  groupMemberCheckChange(event: any) {
    if (event.value === 1) {
      this.userForm.controls.groupMember.disable();
    } else {
      this.divisonApiCall()
      this.userForm.controls.groupMember.enable()
    }

  }

  codeChange(event: any) {
    this.codeName1 = event.target.value;
  }

  divisonApiCall() {
    this.loader.start();
    const body = {
      divCode: this.userForm.get('divcode')?.value,
      plantcode: this.userForm.get('plantcode').value,
      isgroupuser: this.userForm.get('groupMemberCheck')?.value === 1 ? 'Y' : 'N'
    }
    this.userMaintenanceService.loadGroupDropDown(body).subscribe({
      next: (res: any) => {
        const response = this.userForm.get('groupMemberCheck').value;
        if (response === 0 && this.codeName !== '' && this.pageName === 'update') {
          this.group = res.filter((item: any) =>
            (item.specialistcode[0] !== this.codeName && item.specialistcode[0] !== this.codeName1))
            .map((item: any) => item.specialistcode[0])
        }
        else {
          this.group = res.map((item: any) => item.specialistcode[0])
        }
        this.index = this.group.length;
      },
      error: (_res) => {
        this.loader.stop()
      }
    })
  }
  plantChangeApiCall() {
    this.loader.start();
    const body = {
      mode: this.profile?'UPDATE':this.pageName.toUpperCase(),
      title: Number(this.userForm.get('title')?.value),
      plantcode: this.userForm.get('plantcode')?.value,
      specialistcode: this.userForm.get('specialistcode')?.value
    }
    this.userMaintenanceService.plantChange(body).subscribe({
      next: (res: any) => {
        const { bossList, dockList, backUplist } = res.result;
        this.bossList = bossList;
        if(!this.profile){
        if (res.result.bossDisabled || this.bossList.length <= 0) {
          this.userForm.controls.boss.disable();
          this.userForm.controls.boss.clearValidators();
          this.userForm.updateValueAndValidity();
        } else {
          this.userForm.controls.boss.enable();
          this.userForm.controls.boss.addValidators(Validators.required);
          this.userForm.updateValueAndValidity();
        }
      }
        this.dockList = dockList;
        this.backUpListUpdate = of(backUplist)
        this.onLoadResponse2 = backUplist
        this.displayFnBackupUpdate(backUplist)
        this.backUpListUpdate = this.userForm.get('backup')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this.filter(value)),
        )
        const val = this.userForm.get('backup')?.value
        if (val !== '' && this.pageName === 'add') {
          this.userForm.controls.backup.setValue('')
        }
        let flag = false
        if (val !== '' && this.pageName === 'update') {
         const backup = this.onLoadResponse2.find((item: any) => 
             item.substitutecode === val
          )
          if(backup){
            flag = true
          }
          if (!flag) {
            this.userForm.controls.backup.setValue('')
          }
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  titleChangeApiCall() {
    const body = {
      mode: this.pageName.toUpperCase(),
      title: Number(this.userForm.get('title')?.value),
      plantcode: this.userForm.get('plantcode')?.value,
      specialistcode: this.userForm.get('specialistcode')?.value
    }
    if (this.userForm.get('plantcode').value) {
      this.loader.start();
      this.userMaintenanceService.titleChange(body).subscribe({
        next: (res: any) => {
          const { bossList } = res.result;
          this.bossList = bossList;
          if(!this.profile){

          if (res.result.bossDisabled || this.bossList.length <= 0) {
            this.userForm.controls.boss.disable();
            this.userForm.controls.boss.clearValidators();
            this.userForm.updateValueAndValidity();
          } else {
            this.userForm.controls.boss.enable();
            this.userForm.controls.boss.addValidators(Validators.required);
            this.userForm.updateValueAndValidity();
          }
        }
          this.loader.stop();
        },
        error: (_err: any) => {
          this.loader.stop();
        }
      })
    }
  }
  apiCall_Add() {
    this.userMaintenanceService.addOnLoad().subscribe({
      next: (res: any) => {
        this.role = res.roles;
        this.plant = res.plantCodes;
        this.div = res.divCodes.result;
        this.userForm.patchValue({
          title: "0"
        });
        this.displayFnPlant(this.plant);
        this.filterOptions = this.userForm.get('plantcode')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this.filterPlant(value)),
        )
        this.loader.stop();
      },
      error: (_err: any) => {
        this.plant = [];
        this.div = [];
        this.role = [];
        this.loader.stop();
      }
    })
  }

  getuser(array1: any, array2: any) {
    const matchingObject = array2.find((selectedGroup: any) =>
      array1.some((group: any) => group.guid === selectedGroup.guid)
    );
    return matchingObject ? matchingObject.specialistcode.map((code: string) => code.trim()) : [];
  }

  updateFlag = 0

  apiCall_Update() {
    const body = {
      specialistcode: this.profile?this.userInfo.specialistcode:this.data.row?.specialistcode,
      mode: "UPDATE"
    }
    this.userMaintenanceService.updateOnLoad(body).subscribe({
      next: (res: any) => {
        if (res.result.status !== false) {
          this.onLoadResponse = res.result;
          this.plant = this.data.plants;
          this.oldSpecialistCode = res.result.specialistcode;
          this.bossList = res.result.bossList;
          this.userForm.patchValue({
            title: res.result.title,
            specialistcode: res.result.specialistcode,
            plantcode: res.result.plantcode,
            firstname: res.result.firstname,
            lastname: res.result.lastname,
            middleinitial: res.result.middleinitial,
            // divcode: res.result.divcode, //required changes
            boss: res.result.bosscode,
            preprod: res.result.preprod === 1 ? true : false,
            massprod: res.result.massprod === 1 ? true : false,
            phone: res.result.phone,
            fax: res.result.fax,
            ext: res.result.ext,
            email: res.result.email,
            unit: res.result.unit,
            isValid: res.result.isValid,
            dockcodes: res.result.selectedDocks,
            backup: res.result.substitutecode,
            groupMemberCheck: '',
            backupCheck: res.result.substitute ? true : false,
            groupMember: [],
            roles: res.result.selectedRoles.length > 0 ? res.result.selectedRoles.map((c: any) => c.code) : []
          })
          this.updateFlag = 1
          this.groupList = res.result.groupList
          this.selctedGroups = res.result.selectedGroups
          this.groupUser = res.result.isGroupUser;
          this.codeName = this.userForm.get('specialistcode').value;
          this.useApi();
          this.displayFnPlant(this.plant);
          this.filterOptions = this.userForm.get('plantcode')?.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterPlant(value)),
          )


          if (res.result.substitute) {
            this.userForm.controls.backup.enable();
          } else {
            this.userForm.controls.backup.disable();
          }
          if(!this.profile){
          if (res.result.bossDisabled || this.bossList.length <= 0) {
            this.userForm.controls.boss.disable();
            this.userForm.controls.boss.clearValidators();
            this.userForm.updateValueAndValidity();
          } else {
            this.userForm.controls.boss.enable();
            this.userForm.controls.boss.addValidators(Validators.required);
            this.userForm.updateValueAndValidity();
          }
        }
          this.onLoadResponse2 = res.result.backUplist;
          this.role = res.result.roleList;
          this.backup = res.result.backUplist;
          this.dockList = res.result.dockList;
          this.div = res.result.divList;
          const isPresent = this.div.some((obj: any) => obj.divcode === res.result.divcode);
          if (isPresent) {
            this.userForm.patchValue({
              divcode: res.result.divcode
            })
          }
          else {
            this.userForm.patchValue({
              divcode: this.div[0].divcode
            });
          }
          this.loader.stop();
        }
        else {
          this.loader.stop();
          this.errorArray.push(getParentErrorMessage(`${'202'}`))
        }
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  displayFnPlant(selectId:any){
    const selectedOption = this.plant?.find((option:any) => 
    option.code === selectId);
    return selectedOption ? selectedOption.name : '-Select Plant-'; 
   }

  private filterPlant(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.plant.filter((option:any) =>
      option.name?.toLowerCase().includes(filterValue),
    )
  }

  displayFnBackupUpdate(selectId: any) {
    const selectedOption = this.onLoadResponse2.find((option: any) =>
      option.substitutecode === selectId);
    return selectedOption ? selectedOption.substitute : '';
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.onLoadResponse2.filter((option: any) =>
      option.substitute?.toLowerCase().includes(filterValue),
    )
  }

  deleteUser() {
    const body = {
      code: this.data.row.specialistcode
    };
    this.loader.start();
    this.userMaintenanceService.deleteUser(body).subscribe({
      next: (_res: any) => {
        this.dialogRef.close({ data: { result: 'success', message: 'User deleted Successfully' } });
        this.loader.stop();
      },
      error: (err: any) => {
        this.dialogRef.close({ data: { result: err } });
        this.loader.stop();
      }
    })
  }

  submitForm() {
    if (this.pageName === 'add') {
      this.userForm.markAllAsTouched();
      this.userForm.valid && this.addData();
    } else if (this.pageName === 'update'|| this.pageName==='profile') {
      this.userForm.markAllAsTouched();
      this.userForm.valid && this.updateData();
    }
  }

  getBody() {
    const {
      specialistcode, plantcode, firstname, lastname, middleinitial, dockcodes,
      boss, divcode, title, fax, phone, ext, email, preprod, massprod, isValid, unit, roles, groupMember
    } = this.userForm.controls;
    let body: any = {
      "oldplantcode": plantcode.value,
      "oldtitle": title.value,
      "specialistcode": specialistcode.value,
      "plantcode": plantcode.value,
      "firstname": firstname.value,
      "lastname": lastname.value,
      "mode": this.pageName === 'add' ? 'ADD' : 'UPDATE',
      "dockCodes": dockcodes.value,
      "middleinitial": middleinitial.value,
      "boss": boss.value,
      "divcode": divcode.value,
      "title": title.value,
      "fax": fax.value,
      "phone": phone.value,
      "ext": ext.value,
      "subflag": this.userForm.controls.backupCheck.value ? '1' : '0',
      "substitute": this.userForm.controls.backup.value,
      "email": email.value,
      "preprod": preprod.value === true ? 1 : 0,
      "massprod": massprod.value === true ? 1 : 0,
      "isValid": isValid.value,
      "unit": unit.value,
      "roles": roles.value,
      "groupCheckFlag": this.userForm.controls.groupMemberCheck.value === 1 ? 'Y' : 'N',
      "groupUser": groupMember.value,
    };
    if (this.pageName === 'update' || this.pageName==='profile') {
      body = {
        ...body,
        "oldplantcode": this.onLoadResponse.plantcode,
        "oldtitle": this.onLoadResponse.title,
        "title": title.value !== this.onLoadResponse.title ? title.value : this.onLoadResponse.title,
        "plantcode": plantcode.value !== this.onLoadResponse.plantcode ? plantcode.value : this.onLoadResponse.plantcode,
        loggedinSpecialistCode: this.profile?this.userInfo?.specialistcode:this.data.userInfo?.specialistcode,
        OldSpecialistCode: this.oldSpecialistCode
      }
    }
    return body;
  }

  addData() {
    this.loader.start();
    const body = this.getBody();
    this.userMaintenanceService.addUser(body).subscribe({
      next: (res: any) => {
        res.result.status && this.dialogRef.close({ data: { result: 'success', message: 'User added Successfully' } });
        this.errorArray = !res.result.status && res.result.message;
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  updateData() {
    this.loader.start();
    const body = this.getBody();
    this.userMaintenanceService.updateUser(body).subscribe({
    next: (res: any) => {
      if (res.result.status) {
        const toyotaGUID = localStorage.getItem('toyotaGUID');
        if (this.pageName === "profile") {
            this.state.loginAsInternalUser(toyotaGUID, true).then(() => {
                this.dialogRef.close({ data: { result: 'success', message: 'User updated Successfully' } });
            });
        } else if (this.pageName === "update") {
            if (body.loggedinSpecialistCode === body.specialistcode) {
                this.state.loginAsInternalUser(toyotaGUID, true).then(() => {
                    this.dialogRef.close({ data: { result: 'success', message: 'User updated Successfully' } });
                });
            } else {
                this.dialogRef.close({ data: { result: 'success', message: 'User updated Successfully' } });
            }
        }
      } else {
          this.errorArray = res.result.message;
      }
      this.loader.stop();  
      },
      error: (_err: any) => {
          this.loader.stop();
      }
    });
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

  //validators
  middleInitialValidator(control: AbstractControl) {
    if (control.value && control.value.length > 1) {
      return { middleInitialError: 'Only 1 character is accepted' }
    }
    return null;
  }

  codeValidator(control: AbstractControl) {
    if (control.value && control.value.length > 20) {
      return { codeError: 'Code length should be below 20' }
    }
    return null;
  }

  firstNameValidator(control: AbstractControl) {
    if (control.value && control.value.length > 20) {
      return { firstNameError: 'firstName length should be below 20' }
    }
    return null;
  }

  lastNameValidator(control: AbstractControl) {
    if (control.value && control.value.length > 20) {
      return { lastNameError: 'lastName length should be below 20' }
    }
    return null;
  }

  emailValidator(control: AbstractControl) {
    if (control.value) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const email = control.value as string;
      if (!emailRegex.test(email)) {
        return { emailError: 'Enter a proper email' }
      }
      return null;
    }
    return null;
  }

  phoneValidator(control: AbstractControl) {
    if (control.value) {
      const REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
      const phone = control.value as string
      if (!REGEX.test(phone)) {
        return { phoneError: 'Enter a proper Phone number' };
      }
    }
    return null;
  }

  help() {
    this.dialogRef.close();
    const masterHelp = './master-help';
    if (this.pageName === "add") {
      this.router.navigate([masterHelp, 5, "Master Maintenance", 116])
    } else if (this.pageName === "update") {
      this.router.navigate([masterHelp, 5, "Master Maintenance", 117])
    }
    else if (this.pageName === "profile") {
      this.router.navigate([masterHelp, 1, " Maintain My Profile", 4])
    }
  }
}
