import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';
import { ExchangeRateOnLoad } from '../../model/exchangerateOnLoad';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {

  URL = this.constantsService.MASTERCOST ;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }


  getExchangeRate(param: any): Observable<ExchangeRateOnLoad> {
    return this.http.post<any>(this.URL + 'master/getExchangeRate', param, 
    {headers: this.constantsService.httpOptions})
      .pipe(
        catchError(this.handleError)
      )
  }

  addExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + 'master/addExchangeRate'), param, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  updateExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + 'master/updateExchangeRate'), param, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  deleteExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + 'master/deleteExchangeRate'), param, 
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

}
