<p class="page-title mx-3">DEPARTMENT APPROVE</p>
<div id="err" class="alert alert-success m-3" *ngIf="successMessage !== null">
  <span>{{ successMessage }}</span>
</div>

<div id="err" class="alert alert-danger m-3" *ngIf="errorMessage !== null">
  <span *ngFor="let i of errorMessage">{{ i }}<br></span>
</div>
<div class="row m-3">
  <div class="col-lg-4 align-items-center">
    <div class="row">
      <div class="col-md-5 titleColumn">
        <div *ngFor="let title of partInfo" class="contents ps-4 py-2 depAppr">
          {{title}}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2 depAppr"
          [ngClass]="i % 2 === 0? 'row-even' : 'row-odd'">
          {{title}}
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4 align-items-center">
    <div class="row d-flex col-md-12 ps-0">
      <div class="col-md-6  titleColumn">
        <div *ngFor="let title of deptInfo" class="contents ps-4 py-2">
          {{title}}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div class="m-2">
          <mat-select id="plant" class="mat-sel" placeholder="Select" disabled [(ngModel)]="sent1">
            <mat-option value="Conv">Conv</mat-option>
          </mat-select>
        </div>
        <div class="m-2">
          <mat-select id="plant" class="mat-sel" placeholder="Select" [(ngModel)]="person1" disabled>
            <mat-option [value]="person1">{{person1}}</mat-option>
          </mat-select>
        </div>
      </div>

    </div>

    <div class="row d-block col-md-12 ps-0 mt-5">
      <label class="col-12 me-3 ps-0" style="color:#676A6E;">Internal Comment</label>
      <textarea rows="3" cols="30" class="col-12 w-100" [(ngModel)]="spComment" readonly></textarea>
    </div>
  </div>

  <div class="d-block col-lg-4">
  </div>

</div>


<div class="d-flex align-items-center mt-3 mx-0">
  <input type="button" value="PPF" class="black-button ms-3" (click)="ppf()" />
  <input type="button" value="CONCEPT COMPARE" class="black-button ms-3" (click)="conceptCompare()"
    *ngIf="showConceptCompare" />
</div>

<div class="row m-3">
  <div class="ps-0 mt-3">
    <span class="head">CONV</span>
  </div>
  <div class="col-md-8 mt-1 ps-0 me-5">
    <table class="vpacs-shadow w-100" aria-describedby="conv">

      <tr>
        <td class="table-border" id="empty" rowspan="2"></td>
        <td class="table-border" id="radioheader" rowspan="2">OK</td>
        <td class="table-border" id="radioheader" rowspan="2">NG</td>
        <td class="table-align" colspan="2">Cost</td>
        <td class="table-border" rowspan="2">Comment</td>
      </tr>
      <tr class="d-none">
        <th id="conv">CONV</th>
      </tr>
      <tr>
        <td class="table-border" id="costheader">Investment($)</td>
        <td class="table-border" id="costheader">Labor($/Day)</td>
      </tr>
      <tr>
        <td class="table-border2">Safety</td>
        <td>
          <mat-radio-button name="safety" [value]="0" [checked]="rdoCvSafetyOK" [disabled]="rdoCvSafetyOKDisabled" (change)="onCvSafety($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="safety" [value]="1" [checked]="rdoCvSafetyNG" [disabled]="rdoCvSafetyNGDisabled" (change)="onCvSafety($event)"></mat-radio-button>
        </td>
        <td><input type="text" placeholder="- -" disabled /></td>
        <td><input type="text" placeholder="- -" disabled /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtCvSafeComment" [disabled]="txtCvSafeCommentDisabled" /></td>
      </tr>
      <tr>
        <td class="table-border2">Process</td>
        <td>
          <mat-radio-button name="pr"  [value]="0" [checked]="rdoCvProcessOK" [disabled]="rdoCvProcessOKDisabled" (change)="onCvProcess($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="pr"  [value]="1" [checked]="rdoCvProcessNG" [disabled]="rdoCvProcessNGDisabled" (change)="onCvProcess($event)"></mat-radio-button>
        </td>
        <td><input type="text" [(ngModel)]="txtCvProcessInvCost" [disabled]="txtCvProcessInvCostDisabled" /></td>
        <td><input type="text" [(ngModel)]="txtCvProcessLabCost" [disabled]="txtCvProcessLabCostDisabled" /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtCvProcessComment" [disabled]="txtCvProcessCommentDisabled" /></td>
      </tr>
      <tr>
        <td class="table-border2">Productivity</td>
        <td>
          <mat-radio-button name="prod"  [value]="0" [checked]="rdoCvProductivityOK" [disabled]="rdoCvProductivityOKDisabled" (change)="onCvProductivity($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="prod"  [value]="1" [checked]="rdoCvProductivityNG" [disabled]="rdoCvProductivityNGDisabled" (change)="onCvProductivity($event)"></mat-radio-button>
        </td>
        <td><input type="text" [(ngModel)]="txtCvProdInvCost" [disabled]="txtCvProdInvCostDisabled" /></td>
        <td><input type="text" [(ngModel)]="txtCvProdLabCost" [disabled]="txtCvProdLabCostDisabled" /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtCvProdComment" [disabled]="txtCvProdCommentDisabled" /></td>
      </tr>
    </table>
  </div>

  <div class="col-lg-3 align-items-center mt-1 mx-3">
    <div class="d-block col-md-12 mx-2 mb-3">
      <label class="col-12 me-3" style="color:#676A6E;">Internal Comment</label>
      <textarea rows="3" cols="30" class="col-12 w-100" [(ngModel)]="txtCvComment" [disabled]="txtCvCommentDisabled"></textarea>
    </div>
    <div class="row d-flex col-md-12 ps-0 mx-2">
      <div class="col-md-6 titleColumn">
        <div *ngFor="let title of deptInfo" class="contents ps-4 py-2">
          {{title}}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div class="m-2">
          <mat-select id="plant" class="mat-sel"  placeholder="Select" [(ngModel)]="sentCode" [disabled]="!selDivcodeEnabled" (selectionChange)="onSentCodeChange($event)">
            <mat-option *ngFor="let code of divCode" [value]="code.code">{{code.name}}</mat-option>
          </mat-select>
        </div>
        <div class="m-2">
          <mat-select *ngIf="currentDept2Check; else modeCheck" id="plant" class="mat-sel" placeholder="Select Person"  [(ngModel)]="person2" [disabled]="selPersonDisabled">
            <mat-option [value]="result.id" *ngFor = "let result of resultSpecialist">{{result.label}}</mat-option>
          </mat-select>
        </div>
        <ng-template #modeCheck>
            <mat-select id="plant" class="mat-sel" placeholder="Select Person"  [(ngModel)]="person2" [disabled]="selPersonDisabled">
              <mat-option [value]="person2">{{person2}}</mat-option>
            </mat-select>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="row m-3">
  <div class="ps-0 mt-3">
    <span class="head">MFG</span>
  </div>
  <div class="col-md-8 mt-1 ps-0 me-5">
    <table class="vpacs-shadow w-100" aria-describedby="mfg">
      <tr>
        <td class="table-border" id="empty" rowspan="2"></td>
        <td class="table-border" id="radioheader" rowspan="2">OK</td>
        <td class="table-border" id="radioheader" rowspan="2">NG</td>
        <td class="table-align" colspan="2">Cost</td>
        <td class="table-border" rowspan="2">Comment</td>
      </tr>
      <tr class="d-none">
        <th id="mfg">MFG</th>
      </tr>
      <tr>
        <td class="table-border" id="costheader">Investment($)</td>
        <td class="table-border" id="costheader">Labor($/Day)</td>
      </tr>
      <tr>
        <td class="table-border2">Safety</td>
        <td>
          <mat-radio-button name="mfsafety"  [value]="0" [checked]="rdoMfSafetyOK" [disabled]="rdoMfSafetyOKDisabled" (change)="onMfSafety($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="mfsafety"  [value]="1" [checked]="rdoMfSafetyNG" [disabled]="rdoMfSafetyNGDisabled" (change)="onMfSafety($event)"></mat-radio-button>
        </td>
        <td><input type="text" placeholder="- -" disabled /></td>
        <td><input type="text" placeholder="- -" disabled /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtMfSafeComment" [disabled]="txtMfSafeCommentDisabled" /></td>
      </tr>
      <tr>
        <td class="table-border2">Process</td>
        <td>
          <mat-radio-button name="mfpro"  [value]="0" [checked]="rdoMfProcessOK" [disabled]="rdoMfProcessOKDisabled" (change)="onMfProcess($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="mfpro"  [value]="1" [checked]="rdoMfProcessNG" [disabled]="rdoMfProcessNGDisabled" (change)="onMfProcess($event)"></mat-radio-button>
        </td>
        <td><input type="text" [(ngModel)]="txtMfProcessInvCost" [disabled]="txtMfProcessInvCostDisabled" /></td>
        <td><input type="text" [(ngModel)]="txtMfProcessLabCost" [disabled]="txtMfProcessLabCostDisabled" /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtMfProcessComment" [disabled]="txtMfProcessCommentDisabled" /></td>
      </tr>
      <tr>
        <td class="table-border2">Productivity</td>
        <td>
          <mat-radio-button name="mfprod"  [value]="0" [checked]="rdoMfProductivityOK" [disabled]="rdoMfProductivityOKDisabled" (change)="onMfProductivity($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="mfprod"  [value]="1" [checked]="rdoMfProductivityNG" [disabled]="rdoMfProductivityNGDisabled" (change)="onMfProductivity($event)"></mat-radio-button>
        </td>
        <td><input type="text" [(ngModel)]="txtMfProdInvCost" [disabled]="txtMfProdInvCostDisabled" /></td>
        <td><input type="text" [(ngModel)]="txtMfProdLabCost" [disabled]="txtMfProdLabCostDisabled" /></td>
        <td><input type="text" id="commentWidth" [(ngModel)]="txtMfProdComment" [disabled]="txtMfProdCommentDisabled" /></td>
      </tr>
    </table>
  </div>

  <div class="col-lg-3 align-items-center mt-1 mx-3">

    <div class=" d-block col-md-12 mx-2 mb-3">
      <label class="col-12 me-3" style="color:#676A6E;">Internal Comment</label>
      <textarea rows="3" cols="30" class="col-12 w-100" [(ngModel)]="txtMfComment" [disabled]="txtMfCommentDisabled"></textarea>
    </div>
    <div class="row d-flex col-md-12 ps-0 mx-2">
      <div class="col-md-6 titleColumn">
        <div *ngFor="let title of deptInfo" class="contents ps-4 py-2">
          {{title}}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div class="m-2">
          <mat-select id="plant" class="mat-sel" placeholder="Select" [(ngModel)]="sent3" disabled>
            <mat-option [value]="sent3">{{sent3}}</mat-option>
          </mat-select>
        </div>
        <div class="m-2">
          <mat-select *ngIf="currentDept3Check; else modeCheckPerson" id="plant" class="mat-sel" placeholder="Select Person" [(ngModel)]="person3" [disabled]="selQcPersonDisabled">
            <mat-option [value]="result.id" *ngFor = "let result of resultSpecialist">{{result.label}}</mat-option>
          </mat-select>
        </div>
        <ng-template #modeCheckPerson>
          <mat-select id="plant" class="mat-sel" placeholder="Select Person"  [(ngModel)]="person3" [disabled]="selQcPersonDisabled">
            <mat-option [value]="person3">{{person3}}</mat-option>
          </mat-select>
      </ng-template>
      </div>
    </div>
  </div>
</div>
 <div class="row m-3 content-row pb-5 mb-5">
  <div class="ps-0 mt-3">
    <span class="head">QC</span>
  </div>
  <div class="col-md-8 ps-0 mt-2">
    <table class="vpacs-shadow w-100" aria-describedby="qc">
      <tr class="d-none">
        <th aria-labelledby="" id="">QC</th>
      </tr>
      <tr id="">
        <td class="table-border" id="empty"></td>
        <td class="table-border" id="radioheader">OK</td>
        <td class="table-border" id="radioheader">NG</td>
        <td class="table-border">Comment</td>
      </tr>

      <tr>
        <td class="table-border2">Quality</td>
        <td>
          <mat-radio-button name="qcsafety"  [value]="0" [checked]="rdoQcSafetyOK" [disabled]="rdoQcSafetyOKDisabled" (change)="onQcSafety($event)"></mat-radio-button>
        </td>
        <td>
          <mat-radio-button name="qcsafety"  [value]="1" [checked]="rdoQcSafetyNG" [disabled]="rdoQcSafetyNGDisabled" (change)="onQcSafety($event)"></mat-radio-button>
        </td>
        <td><input type="text" [(ngModel)]="txtQcQvalityComment" class="w-100" [disabled]="txtQcQvalityCommentDisabled" /></td>
      </tr>
    </table>
  </div>
  <div class="col-lg-3 align-items-center mt-1 mx-5">
    <div class="d-block col-md-12 mx-4 mb-3">
      <label class="col-12 me-3" style="color:#676A6E;">Internal Comment</label>
      <textarea rows="3" cols="30" class="col-12 w-100" [(ngModel)]="txtQcComment" [disabled]="txtQcCommentDisabled"></textarea>
  </div>
  </div>
</div> 

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
  <button class="cancel me-3" (click)="cancel()">BACK</button>
  <button class="black-button me-3" *ngIf="btnSendVisible" (click)="send()">
    SEND
  </button>
</div>