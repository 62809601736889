import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';

@Component({
  selector: 'app-import-ringi',
  templateUrl: './import-ringi.component.html',
  styleUrls: ['./import-ringi.component.scss'],
})
export class ImportRingiComponent implements OnInit {
  imageSrc: any;
  fileName = '';
  allowedFileTypes = ['xlsx'];
  fileDocForm: FormGroup = new FormGroup({
    file: new FormControl('', Validators.required),
    fileName: new FormControl('', Validators.compose([Validators.required])),
  });
  errorMessage1 = ''; 
  errorMessage2: any[] = [];
  errorMessage = '';
  modalHeader = '';
  dataValue = '';
  base64File: any = ''
  postfix = '';

  constructor(
    private readonly spinnerService: NgxUiLoaderService,
    private readonly ringiListService: RingiListService,
    private readonly router: Router,
    public readonly dialogRef: MatDialogRef<ImportRingiComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any
  ) {}

  ngOnInit(): void {
    if (this.dataStore.mode === 'IMPORT RINGI') {
      this.modalHeader = 'Import Ringi';
      this.dataValue = 'ringi';
      this.postfix = 'r.';
    } else if (this.dataStore.mode === 'IMPORT ESTIMATION') {
      this.modalHeader = 'Import Estimate Cost';
      this.dataValue = 'estimate';
      this.postfix = 'e.';
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  nextBtn() {
    this.fileDocForm.markAllAsTouched();
    const body = {
      type: this.dataValue === 'ringi' ? 'Ringi' : 'EST',
      supplierType: '0',
      file: {
        name: this.fileName,
        data: this.base64File.split(',')[1] ,
      },
    };

    if (this.fileDocForm.valid && !this.errorMessage1) {
      this.spinnerService.start();
      this.ringiListService.fileUpload(body).subscribe({
        next: (res: any) => {
          if (res.isValid) {
             this.dialogData('import');
          }
          this.errorMessage2.push(res.errorMessages[0]);
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
      });
    }
  }
 
  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if (!file) {return};
    const fileName: string = file.name;
    this.fileName = fileName;
    this.fileDocForm.controls['fileName'].setValue(fileName);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (fileName.endsWith(`_${this.postfix}xlsx`)) {
        this.errorMessage1 = '';
        this.fileDocForm.controls['file'].setValue(reader.result);
        this.base64File = reader.result;
      } else {
        this.errorMessage1 = `${fileName} does not match the expected filename RingiNumber_${this.postfix}`;
      }
    };
  }
  dialogData(params: string) {
    this.dialogRef.close({ data: { type: params } });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  help(){
    this.dialogRef.close();
    if (this.dataStore.mode === "IMPORT RINGI"){
      this.router.navigate(['./master-help',3,"Cost Management",41])
    }else if (this.dataStore.mode === "IMPORT ESTIMATION"){
      this.router.navigate(['./master-help',3,"Cost Management",50])
    }
  }
}
