import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import {
  Resolve,
  ActivatedRouteSnapshot
} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class BreadcrumbResolver implements Resolve<any> {
  public resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> {    
    const subcategoryId: string | null = route.params?.['pn'];
    return of({
      breadcrumb: subcategoryId,
    }).pipe(delay(100));
  }
}
