<div class="popup-container">
    <div >
        <button class="close black-button" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="p-3 d-flex justify-content-between align-items-center header">
            <div class="page-title">Update Part Confirm</div>
            <div>
                <button class="help-btn" (click)="help()">help</button>
            </div>
        </div>
    </div>
    <div class="popup-container content">
        <div id='err' class="alert alert-danger m-3" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
        </div>
        <div id='err' class="alert alert-success m-3" *ngIf="successMessage">
            <span>{{successMessage}}</span>
        </div>

        <div class="container p-0">
            <div class="row row-cols-6 my-3 mx-0 px-2 align-items-start">
                <div class="div col d-flex">Plant</div>
                <div class="div col d-flex">Part Number</div>
                <div class="div col d-flex">Supplier</div>
                <div class="div col d-flex">Package Start Date</div>
                <div class="div col d-flex">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group col-5"
                        [(ngModel)]="radioVal" (change)="onRadioChange($event)">
                        <mat-radio-button class="example-radio-button" [value]='1'>
                            All Model
                        </mat-radio-button>

                    </mat-radio-group>
                </div>
                <div class="div col d-flex">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group col-5"
                        [(ngModel)]="radioVal" (change)="onRadioChange($event)">
                        <mat-radio-button class="example-radio-button" [value]='0'>
                            Select Model
                        </mat-radio-button>

                    </mat-radio-group>
                </div>
                <div class="w-100"></div>
                <div class="text-black col d-flex">{{resultHtml?.plant}}</div>
                <div class="text-black col d-flex">{{resultHtml?.partnumber}}</div>
                <div class="text-black col d-flex">{{resultHtml?.supplier}}</div>
                <div class="text-black col d-flex">{{pkgDate}}</div>

            </div>
        </div>

        <div class="m-3 p-0 mb-0">

            <label class="gridcaption ps-0">Current</label>
            <div class=" vpacs-shadow grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height:150px"
                    [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
                    [rowData]="displayRowData" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
                    [headerHeight]="40" [overlayLoadingTemplate]="overlayLoadingTemplate"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40"
                    [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)" [isRowSelectable]="isRowSelectable">
                </ag-grid-angular>
            </div>

        </div>
        <div class="m-3 p-0 mb-0" *ngIf="displayRowDataDup.length>0">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <label class="gridcaption ps-0">Duplicate Part Number</label>
                </div>
                <div>
                    <label class="checkcontainer mb-0">OverWrite
                        <input type="checkbox" [(ngModel)]="tracking" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class=" vpacs-shadow grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height:150px"
                    [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDupDefs"
                    [rowData]="displayRowDataDup" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
                    [headerHeight]="40" [overlayLoadingTemplate]="overlayLoadingTemplate"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40"
                    [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)" [isRowSelectable]="isRowSelectable">
                </ag-grid-angular>
            </div>

        </div>
    </div>
    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-3 pt-2 pb-3">
        <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
        <button class="submit black-button me-3 roboto-bold" (click)="update()">UPDATE</button>
    </div>

</div>