import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'part-list-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button class="vpacs-btn-table"
        *ngIf="params.column.colId==='temp'"
        style="height:30px"
        [disabled] ="isDisabled" (click)="btnClickedHandler()"
      >
        BACK UP
      </button>
      <button class="vpacs-btn-table"
        *ngIf="params.column.colId==='ppf'"
        style="height:30px"
        [disabled] ="ppfDisabled" (click)="btnClickedHandler()"
      >
        PPF
      </button>
    </div>
  `,
})

export class PartListBtnCellRender implements ICellRendererAngularComp {
  isDisabled = false
  ppfDisabled = false
  specialistCode: any;
  trackingSave: any;
  userInfo: any;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute,  private readonly spinner: NgxUiLoaderService,

    private readonly state: ExportStateService, public PartListService: PartListService) { }

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }
  params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    const columnType = params.colDef.field
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res;
        }
      },
      error: (_err: any) => {
      },
    });
    if (columnType === 'temp') {
      if (this.params.data.temp === '0') {
        this.isDisabled = true
      }
      this.buttonName = 'BACK UP';
    }
    else if (columnType === 'ppf') {
      this.state?.userInfoState.subscribe({
        next: (res: any) => {
          this.specialistCode = res.info?.p_specialistcode;
          this.trackingSave = res.role?.p_trackingsave;
          if (this.trackingSave && this.params.data.step09status === "4" &&
            this.specialistCode === this.params.data.specialistcode) {
            this.ppfDisabled = false
          }else{
            this.ppfDisabled=true
          }
          this.buttonName = 'PPF';
        },

      });
    }
  }

  btnClickedHandler() {
    if (this.buttonName === 'BACK UP') {
      const backupParams = {
        "plantCode": this.params.data.plantcode,
        "partNumber": this.params.data.partnumber,
        "supplierCode": this.params.data.suppliercode,
        "pkgStartDate": this.params.data.pkgstartdate,
        "userPlantCode": this.params.data.plantcode,
        "userUnit": this.userInfo.info?.unit,
        "supplier": this.params.data.supplier,
        "modelCode": this.params.data.modelcode,
        "familyCode": this.params.data.familycode,
        "userinfo":this.userInfo,
        "plantName": this.params.data.plant,
        "supplierName": this.params.data.supplier
      }
      this.spinner.start()
      sessionStorage.setItem('backUp', JSON.stringify(backupParams))
      this.router.navigate(['/part-list/backup'])
    }

    else if (this.buttonName === 'PPF') {

      const PPFParams = {
        "plantCode": this.params.data.plantcode,
        "partNumber": this.params.data.partnumber,
        "supplierCode": this.params.data.suppliercode,
        "pkgStartDate": this.params.data.pkgstartdate,
        "modelCode": this.params.data.modelcode,
        "PPFCode": this.params.data.ppfcode,
        "PPFVer":this.params.data.ppfver
      }      
      sessionStorage.setItem('updatePPF', JSON.stringify(PPFParams))
      this.router.navigate(['/part-list/update-ppf-reason',this.params.data.ppfcode,this.params.data.ppfver,(this.params.data.authnumber?'1':'0'),'PL']);
    }
  }
}
