import { Component } from '@angular/core';

@Component({
  selector: 'app-rcaf-quantity',
  templateUrl: './rcaf-quantity.component.html',
  styleUrls: ['./rcaf-quantity.component.scss']
})
export class RcafQuantityComponent {
  params: any;
  isText = false;
  text: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'QUANTITY') {
      this.text = params.data.qty;
    }
  }

}
