import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HelpServiceService } from 'src/app/internal-vpacs/services-internal/help/help-service.service';

@Component({
  selector: 'app-master-help',
  templateUrl: './master-help.component.html',
  styleUrls: ['./master-help.component.scss'],
})
export class MasterHelpComponent implements OnInit {
  @ViewChild('sectionToScrollTo')
  sectionToScrollTo!: ElementRef;
  headers = [
    { label: 'ID', colspan: 2 },
    { label: 'Name', colspan: 2 },
    { label: 'Type', colspan: 2 },
    { label: 'Required', colspan: 2 },
    { label: 'Data Type', colspan: 2 },
    { label: 'Description', colspan: 2 },
  ];
  rows: any = [];
  data: any = [];
  sectionHead: any = '';
  menu: any = [];
  routeParams: any;
  constructor(
    public helpService: HelpServiceService,
    private readonly route: Router,
    public router: ActivatedRoute,
    public spinnerService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.spinnerService.start();
    this.router.params.subscribe((data: any) => {
      this.routeParams = data;
      this.submenu();
    });
  }
  submenu() {
    const obj = {
      funId: this.routeParams.funId,
    };
    this.helpService.subMenu(obj).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.data = res;
        this.menu = res[0];
        this.menu = res[0].slice().sort((a: any, b: any) => {
          const aNumericPart = parseInt(a.match(/\d+/)[0]);
          const bNumericPart = parseInt(b.match(/\d+/)[0]);
          return aNumericPart - bNumericPart;
        });
        if (this.routeParams.screenid) {
              setTimeout(() => {

          this.scrollToScreenId('screenID'+this.routeParams.screenid);
              },1000)
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }
  scrollToSection(sectionId: any) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  scrollToScreenId(screenID: any) {
    const element = document.getElementById(screenID);
    if (element) {
    
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
