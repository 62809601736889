<div class="popup-container">
    <header>
        <label class="close black-button"  (click)="onNoClick()" >X</label>
      
        <div class="d-flex justify-content-between align-items-center m-2 header">
            <div class="page-title">Add Part</div>
          <div>
            <button class="help-btn" (click)="help()">Help</button>
          </div>
          </div>
    </header>

    <div class="popup-contents">
        <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height:200px"
            (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
            [rowData]="displayRowData" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
            [headerHeight]="40" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40">
        </ag-grid-angular><br>
        <label> Changed Due(The above-mentioned part): </label><br>
        <label><input type="radio" [(ngModel)]="fradioVal" id="op1" name="check" value="0">Overwrite</label><span
            style="margin-right: 20px;"></span>
        <label><input type="radio" [(ngModel)]="fradioVal" id="op2" name="check" value="1" checked>Not Overwrite</label>
    </div>


    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
        <button class="submit black-button me-3 roboto-bold" (click)="finish()">NEXT</button>
        <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
    </div>

</div>