import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ShipmentSelectBtnCellRender } from 'src/app/core/gridrender/shipment-select-btn-cell-render';
import { ActivatedRoute } from '@angular/router';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service';


@Component({
  selector: 'app-shipment-company',
  templateUrl: './shipment-company.component.html',
  styleUrls: ['./shipment-company.component.scss'],

})
export class ShipmentCompanyComponent implements OnInit {

  public rowData: any;
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  selected = 'fabricator';
  supplierCode = '';
  supplierName = '';
  clicked: any;
  selectDef: any;
  fabCode: any = '';
  namcPlant: any;
  pageName: any;
  haveAccess:any
  spCode = ''
  plants1:any = []
  filteredOptions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public route: ActivatedRoute,
    private readonly dialogRef: MatDialogRef<ShipmentCompanyComponent>,
    public shipmentListService: ShipmentListService,
    protected state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly authListService:AuthListService

  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
      sortable: true,
    };
    this.selectDef = (name: string): any => {
      return {
        headerName: 'Action',
        field: 'select',
        filter: 'agTextColumnFilter',
        sortable: false,
        unSortIcon: false,
        minWidth: 100,
        cellRendererFramework: ShipmentSelectBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.clicked({ ...field, selectedSuplier: name });
            this.dialogRef.close();
          },
        },
      };
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.clicked = data?.clicked;
  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_pkgview;
          this.spCode=res.info?.specialistcode;
          if(this.haveAccess){
            this.loadPlants()
          }
          this.haveAccess && this.setColumnDefs()
        }
      },
    })
  }

  loadPlants() {
    setTimeout(() => {
      this.spinnerService.start()
    })
    const plantObj: any = {
      SpecialistCode:this.spCode,
      Type: 1,
    }
    this.authListService.getAllPlant(plantObj)?.subscribe({
      next: (res: any) => {
        if (res) {
          this.plants1 = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
          this.filteredOptions = this.plants1;
        }
      },
      error: () => {
        this.spinnerService.stop()
      },
    })
  }

  filterOptions() {
    this.filteredOptions = this.plants1.filter((option:any) => option.plant?.toLowerCase().includes(this.namcPlant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants1.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }


  setColumnDefs(){
    this.columnDefs = [
      {
        headerName: 'Fabricator',
        field: 'PKGSUPCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Address',
        field: 'ADDRESS',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 170,
      },
      {
        headerName: 'Person',
        field: 'NAME',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Phone',
        field: 'PHONE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 110,
      },
      {
        headerName: 'Email',
        field: 'EMAIL',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 170,
      },
      this.selectDef('fabricator'),
    ];
  }

  changeSelected(event: MatRadioChange) {
    this.selected = event.value;

    if (this.selected === 'fabricator') {
      this.columnDefs = [
        {
          headerName: 'Fabricator',
          field: 'PKGSUPCODE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Address',
          field: 'ADDRESS',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        {
          headerName: 'Person',
          field: 'NAME',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Phone',
          field: 'PHONE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Email',
          field: 'EMAIL',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('fabricator'),
      ];
    }
    if (this.selected === 'namc') {
      this.columnDefs = [
        {
          headerName: 'Plant Code',
          field: 'plantcode',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Plant Name',
          field: 'PlantName',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Dock',
          field: 'dockcode',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Address',
          field: 'address',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('namc'),
      ];
    }
    if (this.selected === 'supplier') {
      this.columnDefs = [
        {
          headerName: 'Supplier Code',
          field: 'supcode',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Supplier Name',
          field: 'supname',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Address',
          field: 'address',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 190,
        },
        {
          headerName: 'Person',
          field: 'person',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 130,
        },
        {
          headerName: 'Phone',
          field: 'phone',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Email',
          field: 'email',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('supplier'),
      ];
    }
    this.Search();
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  Search() {
    if (this.selected === 'fabricator') {
      this.shipmentListService.fabData().subscribe((_result) => {
        this.gridApi.setRowData(this.displayRowData)
      });
    }
    else if (this.selected === 'supplier') {
      const params = {
        "SupplierCode": "",
        "supplierNmae": ""

      }
      this.shipmentListService.supplierData(params).subscribe({
        next: (res: any) => {
          this.displayRowData = res.result.filter((r: any) => {
            return (
              r.supcode === this.supplierCode &&
              r.supplierNmae === this.supplierName
            )
          })
          if (!this.supplierCode && !this.supplierName) {
            this.displayRowData = res.result;
            this.rowData = res;
          }
          
          this.rowCount = this.displayRowData.length;
        }
      })
    }
    if (this.selected === 'namc') {
      const params = {
        "plantCode": ""
      }
      this.shipmentListService.plantData(params).subscribe({
        next: (res: any) => {
          
          if (this.namcPlant === '(All)' || this.namcPlant === undefined) {
            this.namcPlant='(All)'
            this.displayRowData = res.result;
          }
          else {
            this.displayRowData = res.result.filter((r: any) => {
              return r.PlantName === this.namcPlant
            });
            this.rowData = res;
            this.rowCount = this.displayRowData.length;
          }
        }
      });
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
}


