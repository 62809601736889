<p class="page-title px-3">Use Backup Package</p>
<div class="top-container">
    <div class="vpacs-shadow m-2 p-2">
        <div class="header-container px-3 d-flex align-items-center">
            Part Information
        </div>
        <div class="details-pane mx-3 px-3 d-flex col flex-wrap">
            <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span
                    class="text-black">{{metaData?.partNumber}}</span></div>
            <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Plant<span
                    class="text-black">{{metaData?.plantName}}</span></div>
            <div class="d-grid col-lg-3 col-md-6 col-12 my-2">PKG Start Date<span
                    class="text-black">{{metaData?.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
            <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Model<span
                    class="text-black">{{metaData?.modelCode}}</span></div>
        </div>
        <button class="black-button mx-3" (click)="btnClick('notify of start use')">Notify of Start Use</button>
    </div>
    <div class="grid-agg grid-height-usebackup my-2">
        <ag-grid-angular style="width:100%; height: 100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
            (window:resize)="sizeToFit()" [rowHeight]="rowHeight" [animateRows]="true" [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true"
            [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
            [headerHeight]="40" [domLayout]="domLayout" [floatingFiltersHeight]="40"
            (paginationChanged)="onPaginationChanged()" (filterChanged)="filterChanged($event)">
        </ag-grid-angular>
        <div class="d-flex pagination align-items-center justify-content-between" *ngIf="rowCount >0">
            <div>
                <div>
                    <label for="rpp" class="page-drpdwn-label mx-2">Records/Page: </label>
                    <select id="rppp" style="height: 30px;" (change)="onPageSizeChanged($event)">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50" selected>50</option>
                        <option value="{{rowCount}}">ALL</option>
                    </select>
                </div>
            </div>
            <div class="d-flex align-items-center gap-3">
                <div>Viewing Records</div>
                <mat-icon (click)="goToFirstPage()" class="text-primary"
                    [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}">first_page</mat-icon>
                <mat-icon (click)="goToPreviousPage()" class="text-primary"
                    [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}">fast_rewind</mat-icon>
                <div>
                    (
                    <span class="value" id="lbCurrentPage"></span>-
                    <span class="value" id="lbTotalPages"></span>)
                    <span>OF {{rowCount}}</span>
                </div>
                <mat-icon (click)="goToNexpage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}">fast_forward</mat-icon>
                <mat-icon (click)="goToLastPage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}">last_page</mat-icon>
            </div>
        </div>
    </div>
</div>
