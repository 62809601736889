import {  Component, OnInit } from '@angular/core';
import { DocumentDownload } from 'src/app/core/gridrender/document-download';
import { DocTrackingService } from 'src/app/services/doc-tracking.service';
import { formatDate } from '@angular/common';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';


@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {
  
  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [] ;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  paginationPageSize = 20
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  dateFormatter(data:any) {
    return data ? formatDate(data, 'MM/dd/YYYY', 'en-US') : '';
  }

  constructor(private readonly documentService: DocTrackingService) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,     
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
      flex : 1
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit()   {
    
    this.documentService.loadDocData().subscribe((res: any)=> {
      const result = res.map((item: any) => {
        const data = { ...item };
        data.LASTUPDATE = this.dateFormatter(data.LASTUPDATE);
        return data;
      })
      this.displayRowData = result;
      this.rowCount = this.displayRowData.length;
    } )
    //ag-grid-specs
    this.columnDefs = [
      {
        headerName: "FILE NAME", field: "FILENAME", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 195,cellRendererFramework: DocumentDownload,
        cellStyle:() =>{ return{ 'white-space':'normal','line-height':'15.5px'}}
      },
      {
        headerName: "LAST UPDATE", field: "LASTUPDATE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 50, comparator : dateComparator
      },
      {
        headerName: "UPDATE PERSON", field: "INS_USER", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 65,
      },
      {
        headerName: "DESCRIPTION", field: "DESCRIPTION", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', 
        cellStyle:() =>{ return{ 'white-space':'normal','line-height':'15.5px'}},
      }    
    ];   
  }
 
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage()}
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1}
  goToFirstPage() { this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      this.currentPageBoolean = true;
    }
    else { this.currentPageBoolean = false; }
    return this.currentPageBoolean;
  }
  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPageSizeChanged(event: any) {    
    if (event.target.value === "ALL"){
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    }else{
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  onGridReady(params:any) {
    this.gridApi = params.api
  }
  onFilterChanged(_params:any){
    this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
    this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());   
    this.rowCount = this.gridApi.getDisplayedRowCount(); 
  }
}


