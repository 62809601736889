<div class="d-flex justify-content-between align-items-center m-3 my-2">
    <p class="page-title">Tracking List</p>
    <div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div id='err' class="alert alert-danger m-3" *ngIf="errorMessage">
    <span>{{errorMessage}}</span>
</div>

<div class="vpacs-shadow mx-3 p-2">
    <div class="filter mx-2 d-flex justify-content-between" (keyup.enter)="searchFlag = true; onSearch();">
        <div class="row gap-2 justify-content-between">
            <div class="d-flex align-items-center col-md-2">
                <label for="plant" class="col-3">Plant</label>
                <input type="text" class="ms-3 py-0" id="plant" matInput [(ngModel)]="plant" aria-label="text" *ngIf="!plantFlag"
                    placeholder="--Select Plant--" (input)="filterOptions()" [matAutocomplete]="auto">

                <input type="text" class="ms-3 py-0" id="plant" matInput [(ngModel)]="plant" aria-label="text" *ngIf="plantFlag"
                    placeholder="--Select Plant--" (input)="filterOptions()" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let plantName of filteredOptions" [value]="plantName.plantcode">{{plantName.plant}}</mat-option>
                </mat-autocomplete>
            </div>
            <div class="d-flex align-items-center col-md-2">
                <label for="family-code" class="col-3">Family Code</label>
                <input type="text" class="ms-3" id="family-code" [(ngModel)]="familyCode">
            </div>
            <div class="d-flex align-items-center col-md-2">
                <label for="model-code" class="col-3">Model Code</label>
                <input type="text" class="ms-3" id="model-code" [(ngModel)]="modelCode">
            </div>
            <div class="d-flex align-items-center col-md-2">
                <label for="specialist" class="col-3">Specialist</label>
                <input type="text" class="ms-3" id="specialist" [(ngModel)]="specialistCode">
                <button mat-icon-button>
                    <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
                </button>
            </div>
            <div class="d-flex align-items-center col-md-2">
                <label for="partnumber" class="col-3">Part Number</label>
                <input type="text" class="ms-3" id="partnumber" [(ngModel)]="partNumber">
            </div>
            <div class="d-flex align-items-center col-md-2">
                <label for="supplier" class="col-3">Supplier</label>
                <input type="text" class="ms-3" id="supplier" [(ngModel)]="supplier">
            </div>
            <div class="d-flex align-items-center gap-2 col-md-2">
                <div class="d-flex align-items-center gap-2 col-6">
                    <label for="cfab" class="col-3">C.Fab</label>
                    <input type="text" class="ms-2 flex-grow-1" id="cfab" [(ngModel)]="fabricator">
                </div>
                <div class="d-flex align-items-center gap-2 col-6">
                    <label for="dfab" class="col-3">D.Fab</label>
                    <input type="text" class="ms-2 flex-grow-1" id="dfab" [(ngModel)]="dfabricator">
                </div>
            </div>
            <div class="d-flex  align-items-center col-md-2">
                <label for="pkg" class="col-3">Pkg Start Date</label>
                <input type="date" class="ms-3" id="pkg" [ngModel]="pkgStartDate  | date:'yyyy-MM-dd'"
                    (ngModelChange)="changePkgStartDate($event)" (keydown)="onKeyDown($event,'pkgStartDate')" [disabled]="pkgStartDate===''"/>
                <label class="checkcontainer ps-0 ms-1 pt-2 pkgCheckbox col-1">
                    <input type="checkbox" [(ngModel)]="pkgChecked" (change)="onChangePkgStartDate($event)" [checked]="pkgStartDate">
                    <span class="checkmark"></span>
                </label>
            </div>               
            <div class="d-flex align-items-center col-md-2 gap-2">
                <label class="checkcontainer mb-0">In Development
                    <input type="checkbox" [(ngModel)]="inDevelopment">
                    <span class="checkmark"></span>
                </label>
                <label class="checkcontainer mb-0">Complete
                    <input type="checkbox" [(ngModel)]="complete">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="d-flex flex-wrap event-checkbox col-md-2">
                <div class="d-flex align-items-center gap-2">
                    <label class="checkcontainer mb-0">Delay
                        <input type="checkbox" [ngModel]="delay" (change)="onChange($event, 'delay')"
                            (checked)="delay === 1">
                        <span class="checkmark"></span>
                    </label>
                    <label class="checkcontainer mb-0">Warn
                        <input type="checkbox" [ngModel]="warn" (change)="onChange($event, 'warn')"
                            (checked)="warn === 1">
                        <span class="checkmark"></span>
                    </label>
                    <label class="checkcontainer mb-0">Good
                        <input type="checkbox" [ngModel]="good" (change)="onChange($event, 'good')"
                            (checked)="good === 1">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="d-flex align-items-center gap-2 col-md-4">
                <label class="checkcontainer mb-0">Not Defined
                    <input type="checkbox" [(ngModel)]="notDefined" (change)="onChangeNotDefined($event)">
                    <span class="checkmark"></span>
                </label>
                <label class="checkcontainer mb-0">Need Approval
                    <input type="checkbox" [(ngModel)]="needApproval" (change)="onChangeNeedApproval($event)">
                    <span class="checkmark"></span>
                </label>
                <label class="checkcontainer mb-0">Back Approved
                    <input type="checkbox" [(ngModel)]="backApproval" (change)="onChangeBackApproval($event)">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="d-flex align-items-center gap-2 col-md-4">
                <label class="checkcontainer mb-0">Need Approval
                    <input type="checkbox" [ngModel]="needApproval1" (change)="onChangeApproval($event)">
                    <span class="checkmark"></span>
                </label>
                <label class="checkcontainer mb-0">No Action Needed
                    <input type="checkbox" [ngModel]="noActionRequired" (change)="onChangeAction($event)">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="d-flex align-items-center col-md-3 justify-content-between">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group "
                    [(ngModel)]="radioVal">
                    <mat-radio-button class="example-radio-button" [value]='0'>
                        All
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button ms-2" [value]='1'>
                        Latest
                    </mat-radio-button>
                </mat-radio-group>
                <input type="button" value="SEARCH" class="black-button" (click)="searchFlag = true; onSearch();" />
            </div>
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap align-items-center event-checkbox">
                    <label for="steps" class="ps-0 pe-4">STEPS :  </label>
                    <label class="checkcontainer mb-0">All
                        <input type="checkbox"  id="steps" (change)="toggleAllCheckbox()" [(ngModel)]="selectAll">
                        <span class="checkmark"></span>
                    </label>
                    <div *ngFor="let step of stepList; let i = index" class="d-flex align-items-center px-2">
                        <label class="checkcontainer mb-0">{{step.value}}
                            <input type="checkbox" [(ngModel)]="step.checked" (change)="toggleCheckBox()">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <button class="vpacs-btn-table" style="width:30px;height:30px" (click)="openLegends()">?</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="vpacs-shadow m-3 p-0 mb-0">
    <div class="grid-agg grid-height-tracking-list px-0 mb-5">
        <ag-grid-angular class="ag-theme-balham grid-items tracking-list" style="width: 100%;height: 100%"
            (gridReady)="onGridReady($event)" (window:resize)="sizeToFit()" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
            [suppressCellSelection]="true" [pagination]="true" [paginationPageSize]="rpPage"
            [suppressPaginationPanel]="true" [icons]="icons" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [floatingFiltersHeight]="40" (filterChanged)="onFilterChanged($event)" [suppressHorizontalScroll]="false"
            [headerHeight]="headerHeight" [overlayLoadingTemplate]="overlayLoadingTemplate" [domLayout]="domLayout"
            [rowSelection]="rowSelection" [rowClassRules]="rowClassRules" [enableCellTextSelection]="true"
            [suppressContextMenu]="true" (sortChanged)="sortChanged($event)" [getRowClass]="getRowClass">
        </ag-grid-angular>
        <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
        </app-pagination>
    </div>
    <div class="d-flex flex-wrap cont p-1">
        <div class="d-flex markers col-lg-2 col-md-4 col-6 m-1 align-items-center" *ngFor="let x of legends">
            <span class='{{x.css}}'>{{x.label}}</span>
            <div class="ps-2">{{x.desc}}</div>
        </div>
    </div>

</div>