<div class="row m-0">
    <div class="col-11 page-title mx-3">Backup Auth List</div>
    <div class="col">
        <button class="help-btn" (click)="help()">help</button>
    </div>
</div>

<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
        <div class="message-container">
            <span class="message-text">{{ message }}</span>
            <button type="button" class="btn-close" (click)="close()"></button>
        </div>
    </div>
</div>
<div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
    <span>{{ successMessage }}</span>
    <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
</div>

<div *ngIf="haveAccess" class="vpacs-shadow m-3 p-2">
    <div class="filter m-2 d-flex" (keyup.enter)="searchBackupAuthList()">
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="auth" class="col-3">Auth#</label>
            <input type="text" class="ms-3" id="auth" [(ngModel)]="authNum">
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="plant" class="col-3">Plant</label>
            <input type="text" id="plant" class="pt-2 ms-3" aria-label="text" matInput [(ngModel)]="plantVal" (input)="filterOptions()"
                placeholder="--Select Plant--" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{plants.plant}}
                </mat-option>
            </mat-autocomplete>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="approver" class="col-3">Approver</label>
            <input type="text" class="ms-3" id="approver" [(ngModel)]="approve" [ngModelOptions]="{ standalone: true }">
            <button mat-icon-button>
                <mat-icon class="d-flex toggleIcon" (click)="selectApprover()">more_vert</mat-icon>
            </button>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="status" class="col-3">Status</label>
            <mat-select id="status" class="mat-sel pt-2 ms-3" disableOptionCentering [(ngModel)]="statusDropdown">
                <mat-option *ngFor="let status of statusList" [value]="status.name">{{status.name}}</mat-option>
            </mat-select>
        </div>
        <div class="d-flex align-items-center  col-lg-2 col-md-6 col-12 ">
            <label for="part-number" class="col-3">Part Number</label>
            <input type="text" class="ms-3" id="part-number" [(ngModel)]="partNum1">
        </div>

        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="ringi" class="col-3">Ringi#</label>
            <input type="text" class="ms-3" id="ringi" [(ngModel)]="ringiNum">
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="specialist" class="col-3">Model</label>
            <input type="text" class="ms-3" id="specialist" [(ngModel)]="modelCode">
        </div>
        <div class="d-flex align-items-center  col-lg-2 col-md-6 col-12 me-5">
            <label for="specialist" class="col-3">Fabricator</label>
            <input type="text" class="ms-3" id="specialist" [(ngModel)]="fabCode">
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="reason" class="col-3">Reason</label>
            <mat-select id="reason" class="mat-sel pt-2 ms-3" disableOptionCentering [(ngModel)]="reason">
                <mat-option *ngFor="let reason of reasonList" [value]="reason.id">{{reason.reason}}</mat-option>
            </mat-select>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" class="col-3">Auth Cost$(US)</label>
            <div class="floating ms-3">
                <label for="over">Over</label>
                <input type="text" id="part-1" [(ngModel)]="actualOver" />
            </div>
            <div class="ms-1 floating">
                <label for="under">Under</label>
                <input type="text" id="part-1" [(ngModel)]="actualUnder" />
            </div>
        </div>

        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="pkgCode" class="col-3">Package Code</label>
            <input type="text" class="ms-3" id="pkgCode" [(ngModel)]="pkgCode">
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="supplier" class="col-3">Supplier</label>
            <input type="text" class="ms-3" id="supplier" [(ngModel)]="supplierCode">
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="specialist" class="col-3">Specialist</label>
            <input type="text" class="ms-3" id="specialist" [(ngModel)]="specCode"
                [ngModelOptions]="{ standalone: true }">
            <button mat-icon-button>
                <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
            </button>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
            <label for="authfromDate" class="col-3">Auth From Date</label>
            <input type="date" class="ms-3" id="authfromDate" [(ngModel)]="fromDate" (keydown)="onKeyDown($event,'authfromDate')">
        </div>
        <div class="d-flex align-items-center  col-lg-2 col-md-6 col-12">
            <label for="authtoDate" class="col-3">Auth To Date</label>
            <input type="date" class="ms-3" id="authtoDate" [(ngModel)]="toDate" (keydown)="onKeyDown($event,'authtoDate')">
        </div>
    </div>
    <div class="d-flex event-checkbox ms-2 me-3 justify-content-between">
        <div class="d-flex align-items-center gap-3">
            <label class="checkcontainer mb-0">Positive
                <input type="checkbox" [(ngModel)]="positive" checked>
                <span class="checkmark"></span>
            </label>
            <label class="checkcontainer mb-0">Negative
                <input type="checkbox" [(ngModel)]="negative" checked>
                <span class="checkmark"></span>
            </label>
            <label class="checkcontainer mb-0">Wait Cancel
                <input type="checkbox" [(ngModel)]="waitCancel" checked>
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="d-flex align-items-center">
            <input type="button" value="SEARCH" class="black-button ms-3" (click)="searchBackupAuthList()" />
        </div>
    </div>

    <div class="justify-content-between d-flex">
        <div class="d-flex mt-4 ms-2 col-6">
            <label class="drpdwn_period_label">Show/Hide Columns </label>
            <mat-select multiple class="mat-sel ms-3" id="selColumn" [(ngModel)]="checkList" #select>
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="checkAll" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection()" [checked]="allSelected===true">
                        Select All</mat-checkbox>
                </div>
                <mat-option (click)="optionClick()" *ngFor="let col of columns; let i=index" [value]="col">
                    {{col}}</mat-option>
            </mat-select>
            <button class="black-button ms-3" style="height: 30px;" id="btn" (click)="updateColumnInfo()">OK</button>
        </div>
        <div class="m-4">
            <button class="vpacs-btn-table " style="width:30px;height:30px" (click)="openLegends()">?</button>
        </div>
    </div>


</div>

<div *ngIf="haveAccess" class="vpacs-shadow m-3 p-0 mb-5">
    <div class="grid-agg px-0 grid-height-backup-auth-list">
        <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height:100%"
            (gridReady)="onGridReady($event)" (window:resize)="sizeToFit()" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
            [suppressCellSelection]="true" [pagination]="true" [suppressPaginationPanel]="true" [icons]="icons"
            [headerHeight]="40" [domLayout]="domLayout" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" [rowClassRules]="rowClassRules"
            [gridOptions]="gridOptions" (filterChanged)="onFilterChanged($event)" (sortChanged)="sortChanged($event)"
            [autoGroupColumnDef]="autoGroupColumnDef">
        </ag-grid-angular>
        <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)">
        </app-pagination>
    </div>
</div>