<div class="d-flex justify-content-between align-items-center header">
    <div class="page-title mx-3">{{title}}</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
  
<div class="alert alert-danger m-3" *ngIf="errorMessage?.length>0">
    <ng-container *ngFor="let error of errorMessage ">
      <div>{{error}}</div>
    </ng-container>
</div>

<div class="row m-3 content-row pb-5" [formGroup]="sendPPFform">
    <div class="col-md-4 align-items-center ps-0 pe-3">
        <table class="table" aria-describedby="leader-plant">
            <th class = "d-none"></th>

            <tbody class="d-flex">
                <div class="col-md-5 titleColumn">
                    <div class="contents ps-4 py-3">
                        Leader Plant
                    </div>
                    <div class="contents ps-4 py-2">
                        Plant
                    </div>
                    <div class="contents ps-4 py-2">
                        Dock
                    </div>
                    <div class="contents ps-4 py-2">
                        Model
                    </div>
                    <div class="contents ps-4 py-2">
                        Supplier
                    </div>
                    <div class="contents ps-4 py-2">
                        Part
                    </div>
                    <div class="contents ps-4 py-2">
                        Specialist
                    </div>
                    <div class="contents ps-4 py-2">
                        Date Sent
                    </div>
                </div>
                <div class="col-md-7 contentColumn p-0">
                    <div class="contents ps-4 py-2" class="row-even">
                        {{(tdLeaderPlant === "") ? "-" : tdLeaderPlant}}
                    </div>
                    <div class="ps-4 py-2" class="row-odd">
                        {{(tdPlant === "") ? "-" : tdPlant}}
                    </div>
                    <div class="ps-4 py-2" class="row-even">
                        {{(tdDock === "") ? "-" : tdDock}}
                    </div>
                    <div class="ps-4 py-2" class="row-odd">
                        {{(tdModel === "") ? "-" : tdModel}}
                    </div>
                    <div class="ps-4 py-2" class="row-even">
                        {{(tdSupplier === "") ? "-" : tdSupplier}}
                    </div>
                    <div class="ps-4 py-2" class="row-odd">
                        {{(tdPartNumber === "") ? "-" : tdPartNumber}}
                    </div>
                    <div class="ps-4 py-2" class="row-even">
                        {{(tdSpecialist === "") ? "-" : tdSpecialist}}
                    </div>
                    <div class="ps-4 py-2" class="row-odd">
                        {{(tdDateSent === "") ? "-" : tdDateSent}}
                    </div>
                </div>
            </tbody>
        </table>
        <div class="d-flex align-items-center">
            <button type="buton" class="black-button me-3 roboto-medium" (click)="PPF()"> PPF </button>
            <button type="buton" class="black-button me-3 roboto-medium" (click)="conceptCompare()"
            *ngIf="!isService" [disabled]="btnConceptCompareDisabled">CONCEPT COMPARE</button>
        </div>
        <div class="d-flex mt-3" *ngIf="tblProposalDisplay">
            <div class="col-md-6 titleColumn d-flex align-items-center">
                <div class="contents ps-4">
                    Proposal with Changes
                </div>
            </div>
            <div class="col-md-6 contentColumn p-0 d-flex align-items-center me-2 ps-4 py-2">
                <div id="tdProposalChanges" class="d-flex align-items-center me-3">{{tdProposalChanges}}</div>
                <button class="vpacs-btn-table ms-3" style="height: 30px; font-size: 11px;"
                    [disabled]="btnSharePlantsDisabled" (click) = "goToHistory()">
                    HISTORY
                </button>
            </div>
        </div>
        <div class="d-flex align-items-baseline ps-0 mt-3" *ngIf="!isService">
            <label for="taIntComment" class="control-label col-md-3">Internal Comment</label>
            <textarea name="" id="taIntComment" cols="170" rows="3" class="form-control detail_area" name="taIntComment"
                formControlName="taIntComment"></textarea>
        </div>
    </div>
    <div class="col-md-4 px-4">
        <div class="row">
            <div class="col-md-6 titleColumn p-0">
                <div class="contents ps-4 py-2">
                    QPC
                </div>
                <div class="contents ps-4 py-2" style="height: 45px;">
                    Full Cont. Weight <br /> (disp_weight)
                </div>
            </div>
            <div class="col-md-6 contentColumn p-0">
                <div class="ps-4 py-2 row-even">
                    {{tdQPC}}
                </div>
                <div class="ps-4 py-2 row-even" style="height: 45px;">
                    {{tdFullCont}}
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <table class="table" aria-describedby="work" *ngIf="!hideControlTblWork">
                <thead>
                    <tr class="titleColumn">
                        <th scope="col" class="ps-4">Work</th>
                    </tr>
                </thead>
                <tbody class="d-flex">
                    <div class="col-md-6 titleColumn">
                        <div *ngFor="let title of work; let i = index;" class="contents ps-4 py-2"
                            [ngClass]="i === 0? 'pt-3' : 'py-2'">
                            {{title}}
                        </div>
                    </div>
                    <div class="col-md-6 contentColumn p-0">
                            <mat-radio-group aria-labelledby="example-radio-group-label"
                                class="example-radio-group direction-vertical p-0" name="work" formControlName="work">
                                <mat-radio-button *ngFor="let title of work; let i=index"
                                    class="example-radio-button ps-4 py-2"
                                    [ngClass]="i % 2 === 0? 'row-even' : 'row-odd'" [value]='i+1'>
                                </mat-radio-button>
                            </mat-radio-group>
                    </div>
                </tbody>
            </table>
        </div>
        <div class="row" *ngIf="!hideControlTblReqAsFinal">
            <div class="d-flex align-items-center mt-2 ps-0">
                <label class="checkcontainer mb-0">Request As Final
                    <input type="checkbox" id="chkReqAsFinal" name="chkReqAsFinal" formControlName="chkReqAsFinal">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="row align-items-baseline ps-0 mt-3" *ngIf="!hideControlTblReqAsFinal">
            <label for="reason" class="control-label col-md-3 ps-0">Reason</label>
            <textarea name="" id="taFinalReason" cols="170" rows="3" class="form-control detail_area"
                name="taFinalReason" formControlName="taFinalReason"></textarea>
        </div>
        <div class="row align-items-baseline ps-0 mt-3">
            <label for="taExtComment" class="control-label col-md-3 ps-0">Supplier/ Fabricator Comment</label>
            <textarea name="" id="taExtComment" cols="170" rows="3" class="form-control detail_area" name="taExtComment"
                formControlName="taExtComment"></textarea>
        </div>
        <div class="row align-items-baseline ps-0 mt-4" *ngIf="hazmat">
            <h5 class="ps-0">Hazmat Approval</h5>
            <div class="col-md-6 titleColumn">
              <div class="contents ps-3 py-2">
                Hazmat Approval Status
              </div>
            </div>
            <div class="col-md-6 contentColumn p-0 d-flex">
              <div class="d-flex align-items-center py-2">
                  <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group align-self-stretch"
                  name="hazmatVal"
                  [(ngModel)]="hazmatVal" [ngModelOptions]="{standalone: true}"
                >
                  <mat-radio-button
                    class="example-radio-button ms-2"
                    [value]="1"
                    [disabled]="hazmatApproveDisabled"
                    [checked]="hazmatApproveChecked"
                  >
                    Approved
                  </mat-radio-button>
                  <mat-radio-button
                    class="example-radio-button ms-4"
                    [value]="0"
                    [disabled]="hazmatRejectDisabled"
                    [checked]="hazmatRejectChecked"
                  >
                    Rejected
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="row align-items-baseline ps-0 mt-3" *ngIf="hazmat">
            <label for="hazmatComment" class="control-label col-md-12 ps-0">
                Hazmat Comment
              </label>
              <textarea
                name=""
                id="hazmatComment"
                cols="170"
                rows="3"
                class="form-control detail_area"
                name="hazmatComment"
                [disabled]="hazmatCommentDisabled"
                [(ngModel)]="hazmatComment" disabled [ngModelOptions]="{standalone: true}"
              ></textarea>
              
          </div>
    </div>
    <div class="col-md-4 ps-4 pe-0">
        <div class="row" *ngIf="!isService" >
            <div class="col-md-4 titleColumn">
                <div class="contents ps-4 py-2" style="height: 45px;">
                    Internal Approval
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0 d-flex">
                <div class="d-flex align-items-center me-2 ps-4 py-2" style="height: 45px;">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group "
                        name="radioVal" formControlName="radioVal">
                        <mat-radio-button class="example-radio-button ms-5 me-5" [value]='1'>
                            Yes
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button ms-5" [value]='0'>
                            No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="!isService" >
            <table class="table" aria-describedby="label">
                <thead>
                    <tr class="titleColumn">
                        <th scope="col" class="text-center">&nbsp;&nbsp;</th>
                        <th scope="col" class="text-center">DOCK</th>
                        <th scope="col" class="text-center">STATUS</th>
                        <th scope="col" class="text-center">CONV<br />APPROVER</th>
                        <th scope="col" class="text-center">DETAIL</th>
                    </tr>
                </thead>
                <tbody class="contentColumn">
                    <tr class="row-even transparent">
                        <td class="text-center">
                            <label class="checkcontainer mb-0">
                                <input type="checkbox" name="chkSelDock1" formControlName="chkSelDock1">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdDock1}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdCrrDept1}}</label>
                        </td>
                        <td class="text-center">
                            <mat-select id="" class="mat-sel pt-2 ms-3" name="selDock1" placeholder="--Select--" formControlName="selDock1"
                                disableOptionCentering>
                                <mat-option *ngFor="let item of selectDvSpec1" [value]="item.specialistcode">
                                    {{item.specialist}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px"
                                [disabled]="btnSelDock1Disabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                    <tr class="row-odd transparent">
                        <td class="text-center">
                            <label class="checkcontainer mb-0">
                                <input type="checkbox" name="chkSelDock2" formControlName="chkSelDock2">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdDock2}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdCrrDept2}}</label>
                        </td>
                        <td class="text-center">
                            <mat-select id="" class="mat-sel pt-2 ms-3" name="selDock2" placeholder="--Select--" formControlName="selDock2"
                                disableOptionCentering>
                                <mat-option *ngFor="let item of selectDvSpec2" [value]="item.specialistcode">
                                    {{item.specialist}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px"
                                [disabled]="btnSelDock2Disabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                    <tr class="row-even transparent">
                        <td class="text-center">
                            <label class="checkcontainer mb-0">
                                <input type="checkbox" name="chkSelDock3" formControlName="chkSelDock3">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdDock3}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdCrrDept3}}</label>
                        </td>
                        <td class="text-center">
                            <mat-select id="" class="mat-sel pt-2 ms-3" name="selDock3" placeholder="--Select--" formControlName="selDock3"
                                disableOptionCentering>
                                <mat-option *ngFor="let item of selectDvSpec3" [value]="item.specialistcode">
                                    {{item.specialist}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px "
                                [disabled]="btnSelDock3Disabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                    <tr class="row-odd transparent">
                        <td class="text-center">
                            <label class="checkcontainer mb-0">
                                <input type="checkbox" name="chkSelDock4" formControlName="chkSelDock4">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdDock4}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdCrrDept4}}</label>
                        </td>
                        <td class="text-center">
                            <mat-select id="" class="mat-sel pt-2 ms-3" name="selDock4" placeholder="--Select--" formControlName="selDock4"
                                disableOptionCentering>
                                <mat-option *ngFor="let item of selectDvSpec4" [value]="item.specialistcode">
                                    {{item.specialist}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px"
                                [disabled]="btnSelDock4Disabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                    <tr class="row-even transparent">
                        <td class="text-center">
                            <label class="checkcontainer mb-0">
                                <input type="checkbox" name="chkSelDock5" formControlName="chkSelDock5">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdDock5}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdCrrDept5}}</label>
                        </td>
                        <td class="text-center">
                            <mat-select id="" class="mat-sel pt-2 ms-3" name="selDock5" placeholder="--Select--" formControlName="selDock5"
                                disableOptionCentering>
                                <mat-option *ngFor="let item of selectDvSpec5" [value]="item.specialistcode">
                                    {{item.specialist}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px"
                                [disabled]="btnSelDock5Disabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-3" *ngIf="!isService" >
            <table class="table" aria-describedby="label">
                <thead>
                    <tr class="titleColumn">
                        <th scope="col" class="text-center">SHARE PLANTS</th>
                        <th scope="col" class="text-center">OK</th>
                        <th scope="col" class="text-center">NG</th>
                        <th scope="col" class="text-center">&nbsp;</th>
                    </tr>
                </thead>
                <tbody class="contentColumn">
                    <tr class="row-even transparent">
                        <td class="text-center">
                            <label class="py-2">{{tdSharePlant}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdSharePlantOK}}</label>
                        </td>
                        <td class="text-center">
                            <label class="py-2">{{tdSharePlantNG}}</label>
                        </td>
                        <td class="flex-center">
                            <button class="vpacs-btn-table" style="height:30px; font-size: 11px"
                                [disabled]="btnSharePlantsDisabled" (click)="goToDeptAppr()">DETAIL</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div formArrayName="files" class="row" *ngIf="!isService">
            <div class="d-flex col-md-6 mt-3" *ngFor="let item of getFiles?.controls; let i = index">
                <div [formGroupName]="i">
                    <input type="file" name="uploadedFile" class="file-input" (change)="onFileSelected($event, i)"
                        #fileUpload>

                    <div class="file-upload d-grid gap-2 ps-0">
                        <div class="file text-center d-grid align-items-center hover-div">
                            <div class="d-flex align-items-center justify-content-center">
                                <img class="filesvg" src="assets/images/file.svg" alt="uploaded image" />
                                <button type="button" class="close black-button" (click)="deleteFile(i)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                            <label>
                                {{getfileName(i)}}
                            </label>
                        </div>
                        <button class="upload-btn vpacs-btn-table mb-3" (click)="fileUpload.click()">
                            Choose File
                        </button>
                    </div>

                </div>
            </div>
            <div class="d-flex col-md-6 justify-content-center align-items-center">
                <button type="button" class="black-button me-3 roboto-medium" (click)="addFiles()">ADD</button>
            </div>
        </div>

    </div>
    <div class="col-md-8 ps-0" *ngIf="displayQuestionnaire">
        <app-questionnaire #appQuestionnaire [parentdata]="childData"[displaytrQuestionnaire]="displaytrQuestionnaire"
            [trSupplierConfirmDisplay]="trSupplierConfirmDisplay" [displaytrQuestionnaireComment]="displaytrQuestionnaireComment"
            [tblRequestReasonDisplay]="tblRequestReasonDisplay" [txtParentPart]="txtParentPart" [tblTrialDisplay] = "tblTrialDisplay" [screenId]="screenID">
        </app-questionnaire>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="black-button me-3 roboto-bold" *ngIf="!hideControlBtnSend" [disabled]="btnSendDisabled" (click)="send()"> SEND
    </button>
    <button class="black-button me-3 roboto-medium" *ngIf="!hideControlBtnApprove" (click)="approve()">APPROVE</button>
    <button class="red-button me-3 roboto-medium" *ngIf="!hideControlBtnReject" (click)="reject()">REJECT</button>
    <button class="red-button me-3 roboto-medium" *ngIf="!hideControlBtnRejectBM" (click)="rejectBm()">REJECT TO BENCHMARK</button>
    <button class="red-button me-3 roboto-medium" *ngIf="!hideControlBtnRejectSup" (click)="rejectSup()">REJECT TO SUPPLIER</button>
    <button class="cancel me-3 roboto-bold" (click)="cancel()"> BACK </button>
</div>