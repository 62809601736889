
<form #contactForm="ngForm" >
    
    <div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Create RCAF</p>
    <div>
      <button type="button" class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
<div class="accordion-container m-3 pb-5">
    <div class="alert alert-danger" *ngIf="errorList?.length>0">
        <ng-container *ngFor="let error of errorList ">
          <div>{{error}}</div>
        </ng-container>
      </div>
    <mat-accordion hideToggle multi>
        <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">AUTHORIZATION FORM</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row m-3 content-row mx-0">
                <div class="col-md-4 align-items-center px-0">
                    <div class="row mt-2 mx-1 heading-row">
                        <table class="table" aria-describedby="plant">
                            <tbody class="d-flex">
                                <th class="col-md-4 titleColumn" id="plant">
                                    <div class="contents2 ps-4 py-3">
                                        Plant
                                    </div>
                                    <div class="contents2 ps-4 py-3" *ngIf="crafDetails?.Mode===0">
                                        Family Code	
                                    </div>
                                    <div class="contents2 ps-4 py-3" *ngIf="crafDetails?.Mode===0">
                                        Model Code	
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        Part Number
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        Supplier
                                    </div>
                                </th>
                                <div class="col-md-8 contentColumn rowClass2">
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        {{crafDetails?.PlantName}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode===0">
                                        {{familyCode}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode===0">
                                        {{modelCode}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode===2 || crafDetails?.Mode===0">
                                        {{crafDetails?.PartNumber ? crafDetails?.PartNumber : partNumber}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode===1">
                                        <mat-select id="ringi" name="partNumber" class="mat-sel pt-2" *ngIf="crafDetails?.PartList" disableOptionCentering
                                            [(ngModel)]="partNumber">
                                            <mat-option *ngFor="let part of crafDetails?.PartList"
                                                [value]="part.partnumber">
                                                {{part.partnumber}}</mat-option>
                                        </mat-select>
                                        <mat-select id="ringi" name="partNumber" class="mat-sel pt-2" *ngIf="!crafDetails?.PartList" disableOptionCentering
                                            [(ngModel)]="partNumber">
                                            <mat-option [value]="partNumber">{{partNumber}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        {{crafDetails?.SupplierName ?? shortName}}
                                    </div>
                                </div>
                            </tbody>
                        </table>

                    </div>

                    <div class="row mt-2 mx-1 heading-row">
                        <table class="table" aria-describedby="ringi">
                            <tbody class="d-flex">
                                <th class="col-md-4 titleColumn" id="ringi">
                                    <div class="contents2 ps-4 py-3">
                                        Ringi Number
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        Ringi Type
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        Auth Type
                                    </div>
                                    <div class="contents2 ps-4 py-3 h-85">
                                        Reason<small class="ps-2">*</small>
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        Delivery Date<small class="ps-2">*</small>
                                    </div>
                                </th>
                                <div class="col-md-8 contentColumn rowClass2">
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode!==0">
                                        <mat-select id="ringi" name="ringi" class="mat-sel pt-2" disableOptionCentering 
                                            [(ngModel)]="ringi" placeholder="-- Select --"  (selectionChange)="changeRingi($event.value)">
                                            <mat-option >-- Select --</mat-option>
                                            <mat-option *ngFor="let ringiNum of ringiList" 
                                                [value]="ringiNum.ringinumber">
                                                {{ringiNum.ringinumber}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center" *ngIf="crafDetails?.Mode===0">
                                        {{ringi ?? '-'}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        {{ringiType}}
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        {{crafDetails?.AuthType ?? crafDetails?.Lbl_PartInfo?.AuthType}}
                                    </div>
                                    <div class="ps-4 py-2 align-items-center h-85">
                                        <mat-select id="reason" name="reason" class="mat-sel pt-2" disableOptionCentering
                                            [(ngModel)]="reason" placeholder="-- Select --">
                                            <mat-option >-- Select --</mat-option>
                                            <mat-option *ngFor="let res of reasonList" [value]="res?.id">{{res?.reason}}
                                            </mat-option>
                                        </mat-select> <br />
                                        <input type="text" class="mt-2" [(ngModel)]="reasonVal" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        <input type="date" id="deliveryDate" [(ngModel)]="deliveryDate" name="deliveryDate" (keydown)="onKeyDown($event,'deliveryDate')">
                                    </div>
                                </div>
                            </tbody>
                        </table>
                    </div>

                    <div class="row mt-2 mx-1 heading-row">
                        <table class="table" aria-describedby="label">
                            <thead>
                                <tr class="titleColumn">
                                    <th scope="col" class="ps-4">Label Information</th>
                                </tr>
                            </thead>
                            <tbody class="d-flex">
                                <div class="col-md-4 titleColumn">
                                    <div *ngFor="let title of labelInfo" class="contents ps-4 py-3">
                                        {{title}}
                                    </div>
                                </div>

                                <div class="col-md-8 contentColumn rowClass">
                                    <div class="ps-4 py-2">
                                        <mat-select id="bgColor" class="mat-sel py-2" disableOptionCentering name="bgColor" name="bgColor"
                                            [(ngModel)]="bgColor" placeholder="-- Select --" >
                                            <mat-option >-- Select --</mat-option>
                                            <mat-option *ngFor="let color of bgColorList" [value]="color.id">{{color.label}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="ps-4 py-2">
                                        <mat-select id="fontColor" class="mat-sel py-2" disableOptionCentering name="fontColor"
                                            [(ngModel)]="fontColor" placeholder="-- Select --">
                                            <mat-option >-- Select --</mat-option>
                                            <mat-option *ngFor="let color of fontColorList" [value]="color.id">
                                                {{color.label}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="ps-4 py-2">
                                        <input type="text" [(ngModel)]="namc" name="namc">
                                    </div>
                                    <div class="ps-4 py-2">
                                        <input type="text" [(ngModel)]="supplier" name="supplier">
                                    </div>
                                    <div class="ps-4 py-2">
                                        <input type="text" [(ngModel)]="shortName" name="shortName">
                                    </div>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-8 ps-3 calcTable">
                    <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
                        <th class="d-none" id="">auth detail</th>
                        <tr class="tHead">
                          <th id="" class = "text-center"></th>
                          <th id="" class = "text-center">Package Code</th>
                          <th id="" class = "text-center">Qty</th>
                          <th id="" class = "text-center">Unit $(US)</th>
                          <th id="" class = "text-center">Cost</th>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Package</td>
                            <td class = "text-center">-</td>
                            <td class = "text-center">
                                <input type="text" [(ngModel)]="txtPackageQty" name="txtPackageQty" (keyup)="calcPackage()"(keydown)="numValidatorForm($event)" maxlength='5'>
                            </td>
                            <td class = "text-center justify-content-center d-flex calcBtn"><button class="vpacs-btn-table" (click)="calcPackage()">CALC PACKAGE
                            </button></td>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Container</td>
                            <td class = "text-center">{{tdCalcContainer}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtContainerQty" name="txtContainerQty" (keyup)="calcTotalCost()"(keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtContainerUnitCost" name="txtContainerUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnContCost?.BtnContCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnContCost?.BtnContCost_Attr_data_code, 1)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtContainerTotalCost === 0; else notdisable"><input type="text" disabled></td>
                            <ng-template #notdisable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtContainerTotalCost" name="txtContainerTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Dunnage1</td>
                            <td class = "text-center">{{tdCalcDunnage1}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage1Qty" name="txtDunnage1Qty" (keyup)="calcTotalCost()"(keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtDunnage1UnitCost" name="txtDunnage1UnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnDun1cost?.BtnDun1cost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnDun1cost?.BtnDun1cost_Attr_data_code, 2)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtDunnage1TotalCost === 0; else dunnage1Enable"><input type="text" disabled></td>
                            <ng-template #dunnage1Enable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtDunnage1TotalCost" name="txtDunnage1TotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Dunnage2</td>
                            <td class = "text-center">{{tdCalcDunnage2}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtDunnage2Qty" name="txtDunnage2Qty" (keyup)="calcTotalCost()"(keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtDunnage2UnitCost" name="txtDunnage2UnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnDun2cost?.BtnDun2cost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnDun2cost?.BtnDun2cost_Attr_data_code, 3)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtDunnage2TotalCost === 0; else dunnage2Enable"><input type="text" disabled></td>
                            <ng-template #dunnage2Enable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtDunnage2TotalCost" name="txtDunnage2TotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Top Cap</td>
                            <td class = "text-center">{{tdCalcTopCap}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtTopCapQty" name="txtTopCapQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtTopCapUnitCost" name="txtTopCapUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnTopCost?.BtnTopCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnTopCost?.BtnTopCost_Attr_data_code, 4)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtTopCapTotalCost === 0;else topCapEnable"><input type="text" disabled></td>
                            <ng-template #topCapEnable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtTopCapTotalCost" name="txtTopCapTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Pallet</td>
                            <td class = "text-center">{{tdCalcPallet}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtPalletQty" name="txtPalletQty" (keyup)="calcTotalCost()"(keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtPalletUnitCost" name="txtPalletUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnPalCost?.BtnPalCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnPalCost?.BtnPalCost_Attr_data_code, 5)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtPalletTotalCost === 0;else palletEnable"><input type="text" disabled></td>
                            <ng-template #palletEnable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtPalletTotalCost" name="txtPalletTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Stretch Wrap/Strap</td>
                            <td class = "text-center">{{tdCalcStretchWrapStrap}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtStretchWrapStrapQty" name="txtStretchWrapStrapQty" (keyup)="calcTotalCost()"(keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtStretchWrapStrapUnitCost" name="txtStretchWrapStrapUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnStrCost?.BtnStrCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnStrCost?.BtnStrCost_Attr_data_code, 6)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtStretchWrapStrapTotalCost === 0;else stretchEnable"><input type="text" disabled></td>
                            <ng-template #stretchEnable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtStretchWrapStrapTotalCost" name="txtStretchWrapStrapTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Kanban Card Holder</td>
                            <td class = "text-center">{{tdCalcKanbanCardHolder}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtKanbanCardHolderQty" name="txtKanbanCardHolderQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5' ></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtKanbanCardHolderUnitCost" name="txtKanbanCardHolderUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnKanCost?.BtnKanCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnKanCost?.BtnKanCost_Attr_data_code, 7)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtKanbanCardHolderTotalCost === 0;else kanbanEnable"><input type="text" disabled></td>
                            <ng-template #kanbanEnable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtKanbanCardHolderTotalCost" name="txtKanbanCardHolderTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Other Package</td>
                            <td class = "text-center">{{tdCalcOther}}</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtOtherQty" name="txtOtherQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtOtherUnitCost" name="txtOtherUnitCost" maxlength='10' class="ms-5" disabled>
                                <button mat-icon-button *ngIf="btnVisibility?.BtnOthCost?.BtnOthCost_Visible">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.BtnOthCost?.BtnOthCost_Attr_data_code, 8)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="txtOtherTotalCost === 0 && txtOtherTotalCost === 0;else otherEnable"><input type="text" disabled></td>
                            <ng-template #otherEnable>
                                <td class="text-center"><input type="text" [(ngModel)]="txtOtherTotalCost" name="txtOtherTotalCost" maxlength='10' disabled></td>
                            </ng-template>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Label</td>
                            <td class = "text-center">-</td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtLabelQty" name="txtLabelQty" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='5'></td>
                            <td class = "text-center d-flex align-items-center">
                                <input type="text" [(ngModel)]="txtLabelUnitCost" name="txtLabelUnitCost" maxlength='10' class="ms-5" (keydown)="numValidatorForm($event)" >
                                <button mat-icon-button *ngIf="btnVisibility?.othcostObj?.btnOthCost_Visible" class="d-none">
                                    <mat-icon class="d-flex toggleIcon" (click)="selectPackageCost(btnVisibility?.othcostObj?.btnOthCost_Attributes_data_code, 8)">more_vert</mat-icon>
                                </button>
                            </td>
                            <td class = "text-center"><input type="text" [(ngModel)]="txtLabelTotalCost" name="txtLabelTotalCost" maxlength='10' disabled></td>
                        </tr>
                        <tr>
                            <td style=" background-color:#e4ecf0" class = "ps-4">Other</td>
                            <td class = "text-center">-</td>
                            <td class = "text-center">-</td>
                            <td class = "text-center">-</td>
                            <td class="text-center">
                                <input type="text" [(ngModel)]="txtOtherCost" name="txtOtherCost" (keyup)="calcTotalCost()" (keydown)="numValidatorForm($event)" maxlength='10'>
                            </td>
                             </tr>
                        <tr>
                          <td class="pe-3" colspan="4" style="background-color:#F4F0DF; text-align: right;  border: 1px solid #BCD1E0;">
                            Total
                          </td>
                          <td class="text-center" colspan="22" style="background-color:#F4F0DF;  border: 1px solid #BCD1E0;"
                          > <input type="text" [(ngModel)]="totalCost" name="totalCost" maxlength='10' disabled> </td>
                        </tr>
                      </table>
                    <div class="row m-3 mt-4 content-row me-0">
                        <div class="d-flex col-md-6 align-items-baseline ps-0">
                            <label for="details" class="control-label col-md-3">Comment For Supplier</label>
                            <div>
                                <textarea name="commentforsupplier" id="details" cols="170" rows="7" [(ngModel)]="commentforsupplier"
                                [ngStyle]="{'border-color': commentforsupplier?.length > 500 ? 'red' : ''}" (keydown)="preventSpecialCharacters($event)" 
                                class="form-control detail_area ms-3 pt-0">{{crafDetails?.Comment1}}</textarea>
                                    <small class="p-3" *ngIf="commentforsupplier?.length > 500;">Must be at most 500 characters.</small>
                            </div>
                        </div>
                        <div class="d-flex col-md-6 align-items-baseline pe-0 mb-5">
                            <label for="details" class="control-label col-md-3">Internal Use Only</label>
                            <div>
                                <textarea name="internalUse" id="details" cols="170" rows="7"  [(ngModel)]="internalUse"
                                    [ngStyle]="{'border-color': internalUse?.length > 500 ? 'red' : ''}" (keydown)="preventSpecialCharacters($event)"
                                    class="form-control detail_area ms-3 pt-0">{{crafDetails?.Comment2}}</textarea>
                                    <small class="p-3" *ngIf="internalUse?.length > 500;">Must be at most 500 characters.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
    <button type="submit" class="black-button me-3 roboto-medium" [disabled]="(errorList && errorList.length > 0 && errorList[0] === 'Authority error') || commentforsupplier?.length > 500 || internalUse?.length > 500" (click)="onSubmit(contactForm)">AUTHORIZE</button>
</div>

</form>