import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { DeleteBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/delete-btn-cell-render';
import { SelectRackComponent } from '../select-rack/select-rack.component';



@Component({
  selector: 'app-shipment-rack',
  templateUrl: './shipment-rack.component.html',
  styleUrls: ['./shipment-rack.component.scss']
})
export class ShipmentRackComponent implements OnInit {

  public rowData :any;
  dunnage=''
  packageCode=''
  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  
  fieldData:any={}
  shipNumber ="";
  pageName: any;
  rackPage: any;
  shipPage: any;

  constructor(
    public dialog:MatDialog, 
    public router: Router,
    protected location: Location,
    public shipmentListService: ShipmentListService,
    private readonly route: ActivatedRoute
    ){    
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,     
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.click=this.click.bind(this)
    
  }

  ngOnInit()   {
    this.route.params.subscribe((params: any) => {
      this.rackPage = params?.authnumber
      this.shipPage=params?.shipmentNumber
      this.apiCall()
    });
    this.columnDefs = [
      { headerName: "Serial Number", field: "serialnumber", width: 100 },
      { headerName: "Auth Number", field: "createdauthnumber",  width: 100 },
      { headerName: "Delete", field: "delete",  width: 100,  cellRendererFramework: DeleteBtnCellRender}     
    ];   
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() } 
  cancel(){
    this.location.back() 
   }
  onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
  }
  click(data:any){
    const body={add: data.map((d:any)=>{ 
      return  {        "shipmentNumber": this.shipPage,
        "authNumber":this.rackPage,
        "packageCode":this.packageCode,
        "serialNumber":d.serialnumber
      
      }
      
    })
  }
     this.shipmentListService.addBtn(body).subscribe(_result=>{
      this.dialog.closeAll()
     })
  }
  dialogOpen(){
    this.dialog.open(SelectRackComponent,{data:{clicked:this.click,packagecode:this.packageCode}})
  }
 apiCall(){
  const par={
    "authNumber": this.rackPage,
    "shipmentNumber":this.shipPage
}
this.shipmentListService.rackShip(par).subscribe({
  next: (res: any) => {
    this.displayRowData=res.list
    this.dunnage=res.dunnage1
    this.packageCode=res.packageCode
    this.fieldData=res.list
  }})
 }


}
