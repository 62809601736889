import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DecListService } from 'src/app/internal-vpacs/services-internal/authorize/dec-list.service';
import { DivisionMasterService } from 'src/app/internal-vpacs/services-internal/master-maintenance/division-master.service';

@Component({
  selector: 'app-dialog-division',
  templateUrl: './dialog-division.component.html',
  styleUrls: ['./dialog-division.component.scss'],
})
export class DialogDivisionComponent implements OnInit {
  errorList: string[] = [];
  olddiv: any = '';
  newdiv: any = '';
  header: any = '';
  divReq = false;
  constructor(
    public dialogRef: MatDialogRef<DialogDivisionComponent>,
    public divService: DivisionMasterService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router: Router,
    private readonly decListService: DecListService,
    @Inject(MAT_DIALOG_DATA) public dataStore: any
  ) {}

  ngOnInit(): void {
    this.newdiv = this.dataStore.olddiv;
    this.olddiv = this.dataStore.olddiv;
    this.header = this.dataStore.headerName;
  }

  updatedelete() {
    if (this.header === 'Update') {
      this.update();
    } else if (this.header === 'Delete') {
      this.delete();
    } 
    else if(this.header==='decisionDelete'){
      this.deleteDecision()
    }else {
      this.add();
    }
  }

  update() {
    this.errorList = [];
    if (!this.newdiv) {
      this.divReq = true;
    } else {
      this.spinnerService.start();
      const obj = {
        oldDivCode: this.olddiv,
        divCode: this.newdiv,
      };
      this.divService.updateDivision(obj).subscribe({
        next: (res: any) => {
          if (!res.result.status) {
            this.errorList = res.result.message;
          } else {
            this.dialogRef.close(res.result.status);
          }
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        },
      });
    }
  }

  delete() {
    this.spinnerService.start();
    this.errorList = [];
    const obj = {
      divCode: this.newdiv,
    };
    this.divService.deleteDivision(obj).subscribe({
      next: (res: any) => {
        if (!res.result.status) {
          this.errorList = res.result.message;
        } else {
          this.dialogRef.close(res.result.status);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorList = [];
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }
deleteDecision(){
  this.spinnerService.start();
const body=this.dataStore.data;
this.decListService.updateDecisionList(body).subscribe({
  next: (res: any) => {
    this.spinnerService.stop();
      this.dialogRef.close(res);
  },
  error: (err: any) => {
    this.errorList.push(err.message);
    this.spinnerService.stop();
  },
});
}
  changediv() {
    this.divReq=this.newdiv?false:true
  }

  add() {
    this.errorList = [];
    if (!this.newdiv) {
      this.divReq = true;
    } else {
      this.spinnerService.start();
      const obj = {
        divCode: this.newdiv,
      };
      this.divService.addDivision(obj).subscribe({
        next: (res: any) => {
          if (!res.result.status) {
            this.errorList = res.result.message;
          } else {
            this.dialogRef.close(res.result.status);
          }
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.errorList = [];
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        },
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.errorList = [];
  }

  help() {
    this.dialogRef.close();
    if (this.header === 'Add'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 158]);
    }else if (this.header === 'Update'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 159]);
    }
  }
  partDelete(){
    this.dialogRef.close('ok')
  }
}
