import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class BackUpDefineService {
  public searchURL = '../../../assets/json/search.json';

  constructor(private readonly http: HttpClient,public datepipe: DatePipe, private readonly constantsService: ConstantsService) { }

  loadSearch() : Observable<any>{
    return this.http.get(this.searchURL).pipe(catchError(this.handleError))
  }

  loadSelect(queryData:any){
    const URL = this.constantsService.EXTERNAL + `backUp/define/copyOtherPart?supplierCode=${queryData.supplierCode}`
    return this.http.get(URL, this.constantsService.httpCustomOptions).pipe(catchError(this.handleError) )
  }
  defineOnLoad(queryData:any){
    const queryParams = new HttpParams()
      .set('hidPlantCode', queryData.plantCode)
      .set('hidPartNumber', queryData.partNumber)
      .set('hidSupplierCode', queryData.supplierCode)
      .set('hidPkgStartDate',queryData.pkgStartDate )
      .set('hidModelCode', queryData.modelCode);
    return this.http.get(this.constantsService.EXTERNAL + 'backUp/define', {params: queryParams, headers: this.constantsService.httpOptions},).pipe(catchError(this.handleError) )
  }

  selectOnLoad(queryData:any){
    const queryParamsSelect = new HttpParams()
      .set('hidPlantCode', queryData.plantCode)
      .set('hidPartNumber', queryData.partNumber)
      .set('hidSupplierCode', queryData.supplierCode)
      .set('hidPkgStartDate', this.datepipe.transform(queryData.pkgStartDate, 'yyyy-MM-dd') || '' )
      .set('hidModelCode', queryData.modelCode)
      .set('hidPlantCodeDefine', queryData.plantCodeDefine)
      .set('hidPartNumberDefine', queryData.partNumberDefine)
      .set('hidPkgStartDateDefine', queryData.pkgStartDateDefine)

    return this.http.get(this.constantsService.EXTERNAL + 'backUp/define/fetchDetail', {params: queryParamsSelect, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }
  imageUpload(updateObject :any): Observable<any> {
		return this.http.post(this.constantsService.EXTERNAL + 'backUp/define/imageUpload', updateObject, { headers: this.constantsService.httpOptions })
    .pipe(catchError(this.handleError))
	}

  submitObject(updateObject :any): Observable<any> {
		return this.http.post(this.constantsService.EXTERNAL + 'backUp/define/saveAndSubmit', updateObject, {headers: this.constantsService.httpOptions })
    .pipe(catchError(this.handleError))
	}

  private handleError(error: HttpErrorResponse) {
		return (error.message || "Server Error");
	}
}
