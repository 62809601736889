import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddSupComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/Supplie-Maintenance/add-sup/add-sup.component';
import { SupCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/sup-cell-render';
import { SupContactBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/sup-contact-btn-cell-render';
import { SupDelBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/sup-del-btn-cell-render';
import { SupplierService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-supplier-maintenance',
  templateUrl: './supplier-maintenance.component.html',
  styleUrls: ['./supplier-maintenance.component.scss'],
})
export class SupplierMaintenanceComponent implements OnInit {

  plant: any;
  suppliercode = '';
  tmmccode = '';
  nummicode = '';
  name = '';
  shortname = '';
  modelCode = '';
  vendorcode = '';
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  autoGroupColumnDef: any;
  isAccessible = false;
  userResponse:any;
  public domLayout = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
<div>
  <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
  <p class="fw-bold fs-5">No data available</p>
  <span>Please select the above filters to get the results.</span>
</div>
`;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  haveAccess: any;
  haveAddAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  originalPage: any;
  response: any;
  headerStyle = 'child-header-color';
  headerNoBorder = 'child-header-color no-border';
  successMessage = '';
  apiFromSearch = false;
  isAll = false;
  constructor(
    public readonly supplierService: SupplierService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly dialog: MatDialog,
    public route: ActivatedRoute,
    private readonly state: ExportStateService,
    public router: Router,
    public constants: ConstantsService
  ) {
    const nullToDash = this.constants.nullToDashFormatter;
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
      unSortIcon: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return nullToDash(params)
        } else {
          return null;
        }
      }
    };

    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.supplierService.searchHistory.onload = true
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userResponse = res;
          this.spinnerService.start();
          this.haveAccess = res.role?.p_userview;
          this.haveAddAccess = res.role?.p_usersave;
          this.deleteAccess = res.role?.p_userdelete;
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
            this.getColumnDefs();
              this.supplierOnload();
          }
          this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.spinnerService.stop();
      },
    });
  }
  //maxwidth was occuping more spaces
  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Code', sortable: false, unSortIcon: false,
        children: [
          {
            headerName: 'NA', field: 'suppliercode', headerClass: this.headerStyle, minWidth: 60,
          },
          {
            headerName: 'TMMC', field: 'tmmccode', headerClass: this.headerStyle, minWidth: 75, 
          },
          {
            headerName: 'NUMMI', field: 'nummicode', headerClass: this.headerStyle, minWidth: 75, 
          },
          {
            headerName: 'TPNA', field: 'vendorcode', headerClass: this.headerStyle, minWidth: 75, 
          },
        ],
      },
      {
        headerName: '', field: 'shortName', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Short Name', field: 'shortname', headerClass: this.headerNoBorder, minWidth: 120, 
          },
        ],
      },
      {
        headerName: '', field: 'name', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Name', field: 'name', headerClass: this.headerNoBorder, minWidth: 150, maxWidth: 200,
            cellStyle: this.constants.cellStyleColumn,
            tooltipValueGetter: (params: any) => params.value,
          },
        ],
      },
      {
        headerName: '', field: 'address', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Address', field: 'address', headerClass: this.headerNoBorder, minWidth: 180, maxWidth: 200,
            cellStyle: this.constants.cellStyleColumn,
            tooltipValueGetter: (params: any) => params.value,
          },
        ],
      },
      {
        headerName: '', field: 'city', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'City', field: 'city', headerClass: this.headerNoBorder, minWidth: 100, maxWidth: 150,
            cellStyle: this.constants.cellStyleColumn,
            tooltipValueGetter: (params: any) => params.value,
          },
        ],
      },
      {
        headerName: '', field: 'state', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'State', field: 'state', headerClass: this.headerNoBorder, minWidth: 120, 
          },
        ],
      },
      {
        headerName: '', field: 'zipcode', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Zip Code', field: 'zipcode', headerClass: this.headerNoBorder, minWidth: 90,
          },
        ],
      },
      {
        headerName: '', field: 'country', sortable: true, unSortIcon: true, headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Country', field: 'country', headerClass: this.headerNoBorder, minWidth: 120, 
          },
        ],
      },
      {
        headerName: '', field: 'Contact', headerClass: this.headerStyle,
        children: [
          {
            headerName: 'Contact', field: 'Contact', headerClass: this.headerNoBorder, sortable: false, filter: false, minWidth: 100, cellRendererFramework: SupContactBtnCellRender,
          },
        ],
      },
      {
        headerName: '', field: 'Update', headerClass: this.headerStyle,
        children: [
          {
            headerName: 'UPDATE', field: 'Update', headerClass: this.headerNoBorder, cellRendererFramework: SupCellRender, sortable: false, filter: false, minWidth: 100,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.updateBtn(field);
              },
            },
          },
        ],
      },
      {
        headerName: '', field: 'delete', headerClass: this.headerStyle,
        children: [
          {
            headerName: 'DELETE', field: 'delete', headerClass: this.headerNoBorder, cellRendererFramework: SupDelBtnCellRender, sortable: false, filter: false, minWidth: 100,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.deleteButton(field);
              },
            },
          },
        ],
      },
    ];
  }

  deleteButton(field?: any) {
    const dialogRef = this.dialog.open(AddSupComponent, {
      data: { mode: 'DELETE', field },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.data === 'SUCCESS' && result.message === 'Supplier deleted successfully') {
        this.successMessage = result.data.message;
        setTimeout(() => {
          this.successMessage = ''
        }, 5000);
        this.supplierOnload();
      }
    })
  }

  getLoadObj() {
    let loadObj = {}
    if (!this.supplierService.searchHistory.onload) {
      this.supplierService.setHistory(this.suppliercode, this.tmmccode, this.nummicode, this.name, this.shortname, false, this.vendorcode)
    }
    this.suppliercode = this.supplierService.searchHistory.NACode
    this.tmmccode = this.supplierService.searchHistory.TMMCCode
    this.nummicode = this.supplierService.searchHistory.NUMMICode
    this.name = this.supplierService.searchHistory.Name
    this.shortname = this.supplierService.searchHistory.ShortName
    this.vendorcode = this.supplierService.searchHistory.VendorCode
    loadObj = {
      NACode: this.suppliercode,
      TMMCCode: this.tmmccode,
      NUMMICode: this.nummicode,
      sort: '',
      limit: this.rpPage,
      page: this.cPage,
      Name: this.name,
      ShortName: this.shortname,
      VendorCode: this.vendorcode
    };
    this.supplierService.setHistory(this.suppliercode, this.tmmccode, this.nummicode, this.name, this.shortname, false, this.vendorcode)
    return loadObj;
  }

  supplierOnload() {
    const loadObj = this.getLoadObj();
    this.spinnerService.start();
    this.supplierService.supplierOnload(loadObj).subscribe({
      next: (res: any) => {
        this.response = res;
        this.displayRowData = res.data;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  //pagination changes
  onSearch() {
    if (this.isAll) {
      this.apiFromSearch = true;
      const values = {
        cPage: 1,
        tPage: 1,
        rpPage: 'ALL',
      }
      this.changePaginationValues(values);
    }
    setTimeout(() => {
      this.supplierOnload();
    });
  }
  ContactReg(field: any) {
    this.router.navigateByUrl(
      '/supplier-maintenance/supplier-contact-maintenance?Suppliercode=' +
      field.suppliercode
    );
  }
  addSupplier() {
    const dialogRef = this.dialog.open(AddSupComponent, {
      data: { mode: 'ADD', userInfo: this.userResponse?.info?.specialistcode},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS') {
          this.successMessage = result.data.message;
          setTimeout(() => {
            this.successMessage = ''
          }, 5000);
          this.supplierOnload();
        }
      }
    });
  }
  updateBtn(field: any) {
    const dialogRef = this.dialog.open(AddSupComponent, {
      data: { mode: 'UPDATE', field, userInfo:  this.userResponse?.info?.specialistcode },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data.result === 'SUCCESS') {
          this.successMessage = result.data.message;
          setTimeout(() => {
            this.successMessage = ''
          }, 5000);
          this.supplierOnload();
        }
      }
    });
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  //pagination changes

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 3000); i++) {
        this.cPage = i + 1;
        this.tPage = 1;
        this.rpPage = 3000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearch();
    }
  }

  async onLoadAll() {
    this.spinnerService.start();
    const loadObj = this.getLoadObj();
    this.supplierService.supplierOnload(loadObj).subscribe({
      next: (supplierData: any) => {
        const gridData: any[] = []
        gridData.push(...supplierData.data)
        this.displayRowData.push(...gridData)
        this.gridApi.setRowData(this.displayRowData)
        if (this.apiFromSearch) {
          this.totalRecords = this.gridApi.getDisplayedRowCount();
          this.apiFromSearch = false;
        }
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      }
    });
  }

  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = Number(this.response.pagination.TotalRecords);
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }
  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 112]);
  }
}
