import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class DivisionMasterService {
  URL = this.constantsService.MASTERCOST;
  decGridFilter: any;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }
  onloadDivision(loadObj: any): Observable<any> {
    const requestURL = this.URL + 'division/getDivision'; 
    return this.http.post(requestURL, loadObj, {
      responseType: 'json',
      headers: this.constantsService.internalHttpOptions
    }).pipe(catchError(this.handleError));
  }
  deleteDivision(loadObj: any): Observable<any> {
    const requestURL = this.URL + 'division/deleteDivision'; 
    return this.http.post(requestURL, loadObj, {
      responseType: 'json',
      headers: this.constantsService.internalHttpOptions
    }).pipe(catchError(this.handleError));
  }
  updateDivision(loadObj: any): Observable<any> {
    const requestURL = this.URL + 'division/updateDivision'; 
    return this.http.post(requestURL, loadObj, {
      responseType: 'json',
      headers: this.constantsService.internalHttpOptions
    }).pipe(catchError(this.handleError));
  }
  addDivision(loadObj: any): Observable<any> {
    const requestURL = this.URL + 'division/addDivision'; 
    return this.http.post(requestURL, loadObj, {
      responseType: 'json',
      headers: this.constantsService.internalHttpOptions
    }).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
  setDivGridFilter(filter:any){
    this.decGridFilter=filter;
  }
  getDivGridFilter(){
    return this.decGridFilter;
  }
}
