const foamDunnage = "Foam Dunnage"
export const container = [
    { name: 'All', value: 'All' },
    { name: 'Metal Bin', value: 'B' },
    { name: 'Stamping Pallet', value: 'D' },
    { name: 'PEND_Other', value: 'G' },
    { name: 'Corrugated', value: 'C' },
    { name: 'Knock Down Container', value: 'K' },
    { name: 'Nestable Container', value: 'N' },
    { name: 'Rack', value: 'R' },
    { name: 'Sleeve Pack', value: 'S' },
    { name: 'Vacuum Form Tray', value: 'V' },
    { name: 'Custom Tote', value: 'X' },
    { name: 'Stackable Tote', value: 'T' },
    { name: 'Folding Tote', value: 'F' }
  ];
  
export const dun12 = [
    { name: 'All', value: 'All' },
    { name: 'Bag - Dun', value: '4' },
    { name: 'Rack - Dun', value: '9' },
    { name: 'Wrap - Dun', value: '5' },
    { name: 'Cell/Partition - Dun', value: '6' },
    { name: 'Pad - Dun', value: '7' },
    { name: 'Sheet/Lid - Dun', value: '8' },
    { name: 'Insert / Tray - Dun', value: '3' },
    { name: 'Foam Dunnage', value: '2' },
    { name: 'Other-Dun', value: '0' },
    { name: 'Cushion Set - Dun', value: '1' },

];
export const topCap = [
    { name: 'All', value: 'All' },
    { name: 'Top Cap', value: 'L' },
];
export const pallet = [
    { name: 'All', value: '*' },
    { name: 'Pallet', value: 'P' },
];
export const strap = [
    { name: 'All', value: 'All' },
    { name: 'Wrap', value: 'W' },
    { name: 'Starp', value: 'Y' },
];
export const kanban =[
    { name: 'All', value: 'All' },
    { name: 'Dolly Exchange', value: 'E' },
    { name: 'Kanban Holder', value: 'H' },
];
export const other = 
[
    { name: 'All', value: 'All' },
    { name: 'Sequence Supplier', value: 'A' },
    { name: 'Labels', value: 'O' },
];

export const material = [
    { name: "All", value: "" },
    { name: "Aluminum", value: "AL" },
    { name: "Polypropylene", value: "PPO" },
    { name: "Thermoplastic Polyur", value: "TPU" },
    { name: "Polyurethane", value: "PU" },
    { name: "Nylon", value: "NY" },
    { name: "VCI Plastic", value: "VCI" },
    { name: "EXP", value: "EXP" },
    { name: "-", value: "-" },
    { name: "Bubble Wrap", value: "BW" },
    { name: "Corrugated Fiberboar", value: "CF" },
    { name: "Foam In Place", value: "FIP" },
    { name: "Foam", value: "FM" },
    { name: "Plastic Corrugated", value: "PC" },
    { name: "Plastic", value: "PL" },
    { name: "Polyethylene", value: "PE" },
    { name: "Polypropylene", value: "PP" },
    { name: "Polystyrene", value: "PS" },
    { name: "Paper Sheet", value: "PSH" },
    { name: "Steel", value: "ST" },
    { name: "Wood", value: "WD" },
    { name: "Plastic Structural Foam", value: "PSF" },
    { name: "Fabric", value: "FBR" },
    { name: "Rubber", value:'RU'},
    ]

export const type=[
    "All",
    "Bag-Dun",
    "Rack-Dun",
    "Wrap-Dun",
    "Cell/Partition-Dun",
    "Pad-Dun",
    "Sheet/Lid-Dun",
    "Insert/Tray-Dun",
    foamDunnage,
    "Other-Dun",
    "Cushion Set-Dun",
    "d-Dun"

]

export const commodity = [
	{ "PACKAGETYPECODE": "", "PACKAGETYPE": "All"},
	{ "PACKAGETYPECODE": "4", "PACKAGETYPE": "Bag - Dun"},
	{ "PACKAGETYPECODE": "9", "PACKAGETYPE": "Rack - Dun"},
	{ "PACKAGETYPECODE": "B", "PACKAGETYPE": "Metal Bin"},
	{ "PACKAGETYPECODE": "D", "PACKAGETYPE": "Stamping Pallet"},
	{ "PACKAGETYPECODE": "Z", "PACKAGETYPE": "PEND_Other"},
	{ "PACKAGETYPECODE": "5", "PACKAGETYPE": "Wrap - Dun"},
	{ "PACKAGETYPECODE": "6", "PACKAGETYPE": "Cell/Partition - Dun"},
	{ "PACKAGETYPECODE": "7", "PACKAGETYPE": "Pad - Dun"},
	{ "PACKAGETYPECODE": "8", "PACKAGETYPE": "Sheet / Lid- Dun"},
	{ "PACKAGETYPECODE": "3", "PACKAGETYPE": "Insert / Tray - Dun"},
	{ "PACKAGETYPECODE": "2", "PACKAGETYPE": foamDunnage},
	{ "PACKAGETYPECODE": "0", "PACKAGETYPE": "Other - Dun"},
	{ "PACKAGETYPECODE": "A", "PACKAGETYPE": "Sequence Supplier"},
	{ "PACKAGETYPECODE": "1", "PACKAGETYPE": "Cushion Set- Dun"},
	{ "PACKAGETYPECODE": "C", "PACKAGETYPE": "Corrugated"},
	{ "PACKAGETYPECODE": "E", "PACKAGETYPE": "Dolly Exchange"},
	{ "PACKAGETYPECODE": "K", "PACKAGETYPE": "Knock Down Container"},
	{ "PACKAGETYPECODE": "L", "PACKAGETYPE": "Top Cap"},
	{ "PACKAGETYPECODE": "N", "PACKAGETYPE": "Nestable Container"},
	{ "PACKAGETYPECODE": "O", "PACKAGETYPE": "Labels"},
	{ "PACKAGETYPECODE": "P", "PACKAGETYPE": "Pallet"},
	{ "PACKAGETYPECODE": "R", "PACKAGETYPE": "Rack"},
	{ "PACKAGETYPECODE": "S", "PACKAGETYPE": "Sleeve Pack"},
	{ "PACKAGETYPECODE": "V", "PACKAGETYPE": "Vacuum Form Tray"},
	{ "PACKAGETYPECODE": "X", "PACKAGETYPE": "Custom Tote"},
	{ "PACKAGETYPECODE": "T", "PACKAGETYPE": "Stackable Tote"},
	{ "PACKAGETYPECODE": "W", "PACKAGETYPE": "WRAP"},
	{ "PACKAGETYPECODE": "Y", "PACKAGETYPE": "STRAP"},
	{ "PACKAGETYPECODE": "H", "PACKAGETYPE": "Kanban holder"},
	{ "PACKAGETYPECODE": "F", "PACKAGETYPE": "Folding Tote"}
]