import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { CheckBoxFab } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/checkBoxFab'
import { PkgFabMainService } from 'src/app/internal-vpacs/services-internal/master-maintenance/pkgfabmain.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'

@Component({
  selector: 'app-fab-cont',
  templateUrl: './fab-cont.component.html',
  styleUrls: ['./fab-cont.component.scss'],
})
export class FabContComponent implements OnInit {
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  autoGroupColumnDef: any
  Read = true
  items: any[] = []
  paginationPageSize = 10
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
<div>
<img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
<p class="fw-bold fs-5">No data available</p>
<span>Please select the above filters to get the results.</span>
</div>
`
  supcode: any
  name: any
  admin: any
  read: any
  write: any
  send: any
  plantcode: any[] = []
  validHash = false
  headerClass = 'child-header-color no-border';
  headerClass1 = 'child-header-color';
  constructor(
    public pkgFabMainService: PkgFabMainService,
    public route: ActivatedRoute,
    public router: Router,
    protected location: Location,
    public state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: false,
      autoHeight: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }
  paramData: any
  valid: any
  Authvalid: any
  editAccess: any
  viewAccess: any
  errorMessage: any
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.editAccess = res?.role?.p_usersave
          this.viewAccess = res?.role?.p_userview
          if (this.viewAccess) {
            this.spinnerService.start()
            this.route.params.subscribe((params: any) => {
              if (params.supplierCode && params.guid) {
                this.paramData = params['guid']
                this.supcode = params['supplierCode']
                this.name = params['name']
                this.updateFabricatorContactOnload()
                this.getColumnDefs()
              } else {
                this.errorMessage = {
                  errorCode: `API-403`,
                  childErrorCode: '400',
                }
                this.spinnerService.stop()
              }
            })
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop()
        this.editAccess = false
        this.viewAccess = false
      },
    })
  }

  updateFabricatorContactOnload() {
    this.pkgFabMainService
      .updatePkgFabContact(this.paramData, this.supcode)
      .subscribe({
        next: (res: any) => {
          this.displayRowData = res.result
          this.rowCount = res.result.length
          this.admin = res.result[0].admin === '0' ? false : true
          this.valid = res.result[0].valid === '0' ? false : true
          this.Authvalid = res.result[0].authvalid === '0' ? false : true
          this.validHash = res.result[0].authvalid === '0' ? false : true
          this.read = res.result[0].read
          this.write = res.result[0].write
          this.send = res.result[0].send
          this.plantcode = res.result.map((item: any) => item.plantcode)
          res.result.map(
            (item: { plantcode: any; read: any; write: any; send: any }) => {
              this.items.push({
                Code: item.plantcode,
                Read: item.read,
                Write: item.write,
                Send: item.send,
              })
            },
          )
          this.gridApi?.hideOverlay()
          this.spinnerService.stop()
        },
      })
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: '',
        field: 'PLANTNAME',
        sortable: false,
        unSortIcon: false,
        minWidth: 120,
        children: [
          {
            headerName: 'Plant',
            field: 'plantname',
            headerClass: this.headerClass,
            minWidth: 120,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        children: [
          {
            headerName: 'Read',
            field: 'read',
            editable: false,
            minWidth: 90,
            headerClass: this.headerClass,
            cellRendererFramework: CheckBoxFab,
          },
        ],       
      },
      {
        headerName: '',
        field: 'write',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        children: [
          {
            headerName: 'Write',
            field: 'write',
            editable: false,
            minWidth: 90,
            headerClass: this.headerClass,
            cellRendererFramework: CheckBoxFab,
          },
        ],       
      },
      {
        headerName: '',
        field: 'AUTHSEND',
        sortable: true,
        unSortIcon: true,
        minWidth: 120,
        children: [
          {
            headerName: 'Submit',
            field: 'send',
            editable: false,
            minWidth: 90,
            headerClass: this.headerClass,
            cellRendererFramework: CheckBoxFab,
          },
        ],       
      },
      {
        headerName: 'PreProd',
        field: '',
        children: [
          {
            headerName: 'Leader',
            headerClass: this.headerClass1,
            field: 'preprod',
            minWidth: 120,
            cellRendererFramework: CheckBoxFab,
          },
          {
            headerName: 'Email',
            field: 'needemailpre',
            headerClass: this.headerClass1,
            minWidth: 120,
            cellRendererFramework: CheckBoxFab,
          },
        ],
      },
      {
        headerName: 'MassProd',
        field: '',
        children: [
          {
            headerName: 'Leader',
            headerClass: this.headerClass1,
            field: 'massprod',
            minWidth: 120,

            cellRendererFramework: CheckBoxFab,
          },
          {
            headerName: 'Email',
            field: 'needemailmass',
            headerClass: this.headerClass1,
            minWidth: 120,
            cellRendererFramework: CheckBoxFab,
          },
        ],
      },
    ]
  }

  cancel() {
    this.location.back()
  }

  updatePkgFabContact() {
    let payload={};
    const temp = this.displayRowData
    if (this.validHash && this.Authvalid) {
      payload=this.validData(temp)
    } else if ((this.validHash && this.Authvalid === false) || (!this.validHash && !this.Authvalid)) {
      payload= this.unValidData(temp)
    } else if (this.validHash === false && this.Authvalid) {
      payload= this.pkgFabData(temp)
    } 
    this.pkgFabMainService.updateFabCont(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.location.back()
        }
      },
    })
  }
  validData(temp:any){
    const hashData = []
    for (const data of temp) {
      hashData.push({
        Code: data.plantcode,
        Read: data.read,
        Write: data.write,
        Send: data.send,
      })
    }
    return {
      Valid: this.valid ? '1' : '0',
      Admin: this.admin ? '1' : '0',
      SupplierCode: this.supcode,
      ToyotaGuid: this.paramData,
      hashData: hashData,
      validHash: this.Authvalid ? '1' : '0',
    }
  }
  unValidData(temp:any)
{
    const hashData = []
  for (const data of temp) {
    hashData.push(data.plantcode)
  }
  return {
    Valid: this.valid ? '1' : '0',
    Admin: this.admin ? '1' : '0',
    SupplierCode: this.supcode,
    ToyotaGuid: this.paramData,
    validHash: this.Authvalid ? '1' : '0',
    PlantCodes: hashData,
  }
} 
pkgFabData(temp:any){
  const hashData = []
  for (const data of temp) {
    hashData.push({
      Code: data.plantcode,
      Read: '0',
      Write: '0',
      Send: '0',
    })
  }
  return {
    Valid: this.valid ? '1' : '0',
    Admin: this.admin ? '1' : '0',
    SupplierCode: this.supcode,
    ToyotaGuid: this.paramData,
    hashData: hashData,
    validHash: this.Authvalid ? '1' : '0',
  }
}
 sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  onGridReady(params: any) {
    this.gridApi = params.api

    this.gridApi.sizeColumnsToFit()
  }
  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 149])
  }
}
