import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-delete-btn',
  template: `
    <span 
        *ngIf = "this.fileNameFlag"
        class = "text-primary"
        style = "cursor: 'pointer'"
        (click) = "fileDownload()"
    >{{this.params.value}}</span>
    <button 
        *ngIf = "this.deleteFlag"
        class="vpacs-btn-table"
        style="height:30px"
        (click)="delete()"
    >DELETE</button>
  `,
})

export class DeleteButtonComponent implements ICellRendererAngularComp {

  public params: any;
    fileNameFlag = false;
    deleteFlag = false;

    
  refresh(params: ICellRendererParams): boolean {
	throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }
 
  agInit(params: any): void {
    this.params = params;  
    if(params.column.colId === 'filename'){
        this.fileNameFlag = true;
    }else if(params.column.colId === 'delete'){
        this.deleteFlag = true;
    }
  }

  delete() {
    this.params.clicked(this.params.data.id,this.params.data.filename);

  }

  fileDownload(){
      this.params.clicked(this.params.data.id,this.params.data.filename);
  }
}