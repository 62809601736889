import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rcaf-unit',
  templateUrl: './rcaf-unit.component.html',
  styleUrls: ['./rcaf-unit.component.scss']
})
export class RcafUnitComponent {
  params: any;
  isText = false;
  isButton= false;
  text: any;

  constructor(private readonly router: Router) { }

  agInit(params: any): void {
    this.params = params;

    if (params.colDef.headerName === 'UNIT$(US)') {
      if(params.data.rownumber === '1'){
        this.isButton = true;
        this.isText = false;
        this.text = params.data.unitcost;
      }
      else{
        this.isButton = false;
        this.isText = true;
        this.text = params.data.unitcost;
      }
    }
  }

  btnClickedHandler() {
    this.router.navigate(['/tracking-list/temporary-container']);
  }

  calcPackage(){
    this.params.clicked(this.params.column.colId, this.params);
  }

}
