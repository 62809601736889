import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { UseBackupDialogComponent } from '../dialogs/use-backup-dialog/use-backup-dialog.component'; 
import { UseBackupServiceService } from 'src/app/services/use-backup-service.service';
import { take } from 'rxjs';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UseBackupTrackingComponent } from 'src/app/pages/tracking/use-backup-tracking/use-backup-tracking.component';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


export interface DialogData {
    dialogType: 'download' | 'update' | 'tracking' | 'end use/request payment' | 'notify of start use';
    navData : ''
  }

@Component({
	selector: 'tracking-backup-btn-cell-render',
	template: `
        <div *ngIf="btnTypeA && this.params.data.INVOICE !== null">
            <span (click)= "invoiceDownload(this.params.data)" style="cursor:'pointer'"  class="text-primary text-decoration-underline">Invoice.pdf</span>
        </div>
        <div *ngIf="btnTypeD">
            <button (click)="btnClick('download')" class="vpacs-btn-table" style="height:'30px'"
                [disabled]="this.params.data.DISPLAY_STATUS !== 'Finish Approved'"
            >
            Download</button>
        </div>
        <div *ngIf="btnTypeU">
        <button (click)="btnClick('update')" class="vpacs-btn-table" style="height:'30px'"
                [disabled]="checkUpdate(this.params.data.ISTATUS)"
            >
            Update</button>
        </div>
        <div *ngIf="btnTypeX">
            <button (click)="btnClick('tracking')" class="vpacs-btn-table" style="height:'30px'"
            [disabled]="checkTracking(this.params.data.ISTATUS)"
            >
            Tracking</button>
        </div>
        <div *ngIf="btnTypeY">
            <button (click)="btnClick('end use/request payment')" class="vpacs-btn-table" style="height:'30px'"
                [disabled]="(this.params.data.ISTATUS !== 9 )"
            >
            End Use/ Request Payment</button>
        </div>
    `,
    providers: [UseBackupTrackingComponent]
})

export class UseBackupTableRender implements ICellRendererAngularComp {

    constructor(private readonly router: Router, 
        private readonly route: ActivatedRoute, 
        public dialog: MatDialog,
        private readonly useBackup: UseBackupServiceService,
        public useBackupTracking: UseBackupTrackingComponent){}
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	public params: any;
    public btnTypeA = false;
    public btnTypeD = false;
    public btnTypeU = false;
    public btnTypeX = false;
    public btnTypeY = false;
    public dialogType = '';
    public downloadData:any = {}

	agInit(params: any): void {
		this.params = params;
        if(this.params.column.colId === 'attachment') {this.btnTypeA = true;}
        if(this.params.column.colId === 'download') {this.btnTypeD = true;}
        if(this.params.column.colId === 'update') {this.btnTypeU = true;}
        if(this.params.column.colId === 'tracking') {this.btnTypeX = true;}
        if(this.params.column.colId === 'end use/request payment') {this.btnTypeY = true;}        
	}

    checkUpdate(istatus:any){
        switch (istatus) {
            case 3:
                return true
            case 4:
                return true
            case 9:
                return true            
            default:
                return false
        }
    }

    checkTracking(istatus:any){
        switch (istatus) {
            case 3:
                return false
            case 4:
                return false
            case 9:
                return false  
            case 1:
                return false          
            default:
                return true
        }
    }

    replaceNullOrUndefined(object:any){
        const entries = Object.entries(object);
        const replacedEntries = entries.map(([key,value]) =>{
            if(value === null || value === undefined){
                return [key , '']
            }
            return [key,value]
        })
        return  Object.fromEntries(replacedEntries);
    }

    replaceNullOrUndefinedArrayOfObj(array:any){
        return  array.map((obj:any)=>{
            for (const key in obj) {
                if (obj[key] === null || obj[key] === undefined) {
                    obj[key] = '';                  
                }
            }
            return obj;
        })
    }

    btnClick(btnName:any) {
        this.dialogType = btnName;
        let navData:any;
        const queryParams:any = {}
        let authData;
        switch (btnName) {
            case 'end use/request payment':
                authData = { 
                    'authNumber': this.params.data.AUTHNUMBER, 
                    'modelCode': this.params.data.model,
                    'invoiceName':this.params.data.INVOICE,
                    'sd' : this.params.data.SD
                };    

                sessionStorage.setItem('authData', JSON.stringify(authData));
                this.useBackup.endUseOnLoad(authData)
                .subscribe({
                    next: (data)=>{        
                        navData = data;       
                    },
                    complete:()=>{
                        const dialogRef = this.dialog.open(UseBackupDialogComponent,{data: {dialogType: this.dialogType, navData: navData}, width: '75%'}); 
                        dialogRef.afterClosed().subscribe((result: any) => {
                            if (result === 'success') {                                                               
                                this.params.reloadApi();
                            }
                        })     
                    }
                });
            break;
            case 'update' : 
                authData = { 
                    'authNumber': this.params.data.AUTHNUMBER, 'modelCode': this.params.data.model,
                };
                sessionStorage.setItem('authData', JSON.stringify(authData));
                this.router.navigate([`${btnName}`],{relativeTo: this.route}); 
            break;
            case 'tracking' : 
                authData = { 
                    'authNumber': this.params.data.AUTHNUMBER, 'modelCode': this.params.data.model,'SD' : this.params.data.SD
                };
                sessionStorage.setItem('authData', JSON.stringify(authData));
                this.router.navigate(['tracking/update'],{relativeTo: this.route}); 
            break;
            case 'download' : 
            queryParams['authNum'] = this.params.data.AUTHNUMBER;  
            this.useBackup.downloadPdf(queryParams)
            .subscribe({
                next: (data:any)=>{                            
                    const description = this.replaceNullOrUndefined(data?.description);                 
                    const quantity = this.replaceNullOrUndefinedArrayOfObj(data?.quantity)                      
                    this.downloadData = {description: description, quantity: quantity} 
                    const docDefinition:any = this.pdfStructure(this.downloadData)
                    pdfMake.createPdf(docDefinition).download(`backup_${this.downloadData.description.AUTHNUMBER}.pdf`);    
                },
            });
            break;
        
            default:
                break;
        }
      }

      invoiceDownload(data:any) {  
        this.useBackup
            .invoiceDownload(data.AUTHNUMBER, data.INVOICE)
            .pipe(take(1))
            .subscribe((response) => {
                window.open(response.body)
            });
      }  
      
        tableFilter(dates:any, authDetails:any,rowType:string){
            const container:any = [],pallet:any = [], topcap:any = [], dunnage:any = [];
            for(let i=0 ; i<dates.length ; i++){
            const result = authDetails?.filter( (x:any) => dates[i]?.includes( x.AUTHDATE) );
            if(result !== undefined && result !== null){
                container.push(result[0]?.BOXQTY);
                dunnage.push(result[0]?.DUNQTY);
                pallet.push(result[0]?.PLTQTY);
                topcap.push(result[0]?.LIDQTY);
            }
            }
            if(rowType === 'container'){
            return container.map((v:any) => v === undefined ? 0 : v);
            }else if(rowType === 'dunnage'){
            return dunnage.map((v:any) => v === undefined ? 0 : v);
            }else if(rowType === 'pallet'){
            return pallet.map((v:any) => v === undefined ? 0 : v);
            }else{
            return topcap.map((v:any) => v === undefined ? 0 : v);
            }
        }

      checkMonday(startDate:any){
        const date:any = new Date(startDate);
        const day = new Date(startDate).getDay();
        if(day === 0 || day === 6){
            date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7))
        }else{
            date.setDate(date.getDate() - (date.getDay() + 6) % 7)
        }
        return date;
      }

      addSevenDays(date:any, daysToAdd:number){
          return new Date(new Date(date).getTime()+(daysToAdd*24*60*60*1000)).toLocaleDateString();
          
      }


      pdfStructure(downloadData:any){
        const {description, quantity} = downloadData;
        const {SUPPLIER,SUPPLIERCODE, AUTHNUMBER,PLANT,PCMGR,PCAM,
            PCSPEC,PURMGR,PURAM,PURSPEC,CONTACTNAME,CONTACTFAX,
            AUTHDATE,MODELCODE,PARTNUMBER,PARTDESCRIPTION,COSTCENTER,
            NAMCCONTACTNAME,NAMCEMAIL,NAMCFAX,BUDGET,REASONDETAIL,COMMENTS,
            PLTCOST,LIDCOST,BOXCOST,DUNCOST, STARTDATE} = description;            
        const monday = this.checkMonday(STARTDATE).toLocaleDateString();
        const dateArray = this.useBackupTracking.calculateDatesBetween(28,monday);
        const container = this.tableFilter(dateArray,quantity,'container');
        const dunnage = this.tableFilter(dateArray,quantity,'dunnage');
        const pallet = this.tableFilter(dateArray,quantity,'pallet');
        const topcap = this.tableFilter(dateArray,quantity,'topcap');
        const containerTotal = BOXCOST * container.filter((x:any)=>x).reduce((partialSum:any, a:any) => partialSum + a, 0);
        const dunnageTotal = DUNCOST * dunnage.filter((x:any)=>x).reduce((partialSum:any, a:any) => partialSum + a, 0);
        const palletTotal = PLTCOST * pallet.filter((x:any)=>x).reduce((partialSum:any, a:any) => partialSum + a, 0);
        const topcapTotal =  LIDCOST * topcap.filter((x:any)=>x).reduce((partialSum:any, a:any) => partialSum + a, 0);;

        return  {
            pageSize: 'A4',pageOrientation: 'landscape',pageMargins: [15,15,15,15],
            content: [
                {text: 'TOYOTA', style: 'header'},
                {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 } ]},
                {text: 'Expendable CONTAINER REIMBURSEMENT', style: 'subheader'},
                'PC-Logistics',
                {
                  table: {
                      widths: [ '30%', '70%'],
                      body: [
                            [
                                {
                                    table: {
                                        widths: [ '15%','85%'],
                                        body: [
                                            ['Auth#', AUTHNUMBER],
                                            ['Plant', PLANT]
                                        ],
                                    }
                                },
                                {
                                    table: {
                                        widths: [ '7%', '10%', '15%', '15%' ,'*','*','*'],
                                        body: [
                                          ['','GM', 'SR ADV', 'MGR', 'ADV','AM','SPEC' ],
                                          ['PC','', '', PCMGR, '', PCAM,PCSPEC ],
                                          ['PUR','', '', PURMGR, '', PURAM,PURSPEC ],
                                        ]
                                    },
                                },
                            ]
                          ]
                        } ,
                       layout: {
                            hLineWidth: function (i:any, node:any) {
                                return (i === 0 || i === node.table.body.length) &&  0 ;
                            },
                            vLineWidth: function (i:any, node:any) {
                                return (i === 0 || i === node.table.body.length) && 0;
                            },
                        }
                },
                
                {
                    margin: [0, 10],
                   table: {
                        widths: [ '15%', '15%', '20%', '30%' ,'*'],
                        body: [
                                ['Supplier', {text: SUPPLIER,decoration: 'underline',bold:true},'Supplier Code', {text: SUPPLIERCODE,decoration: 'underline',bold:true},'Comment'],
                                ['Contact name', {text: CONTACTNAME,decoration: 'underline',bold:true},'Contact Fax', {text: CONTACTFAX,decoration: 'underline',bold:true},{rowSpan:6, text:COMMENTS}],
                                ['Auth Date', {text: AUTHDATE,decoration: 'underline',bold:true},'Part Number', {text: PARTNUMBER,decoration: 'underline',bold:true},''],
                                ['Model #', {text: MODELCODE,decoration: 'underline',bold:true},'Part Description', {text: PARTDESCRIPTION,decoration: 'underline',bold:true},''],
                                ['Cost Center #', {text: COSTCENTER,decoration: 'underline',bold:true},'NAMC Contact Name', {text: NAMCCONTACTNAME,decoration: 'underline',bold:true},''],
                                ['Budget #', {text: BUDGET,decoration: 'underline',bold:true},'NAMC Email Address', {text: NAMCEMAIL,decoration: 'underline',bold:true},''],
                                ['', '','NAMC FAX Address', {text: NAMCFAX,decoration: 'underline',bold:true},''],
                            ],
                    },
                    layout: {
                        hLineWidth: function (i:any, node:any) {
                            return (i === 0 || i === node.table.body.length) && 0;
                        },
                        vLineWidth: function (i:any, node:any) {
                            return (i === 0 || i === node.table.body.length) && 0;
                        },
                    }
                },
                {text: 'This authorization is to reimburse packaging for the part number(s) listed above at the authorized quantity below effective immediately.'},
                {
                    margin: [0, 10],
                    table : {
                        widths: ['*','90%'],
                        body : [
                            [{text: 'REASON', bold:true},REASONDETAIL
                           ]
                        ]
                    },
                },
                {text: 'Expendable Quantity Used: Note cost per item used and quantity used daily'},
                {
                    margin: [0, 10],
                    table: {
                        body: [
                        ['','',{colSpan:5, text:'Date : '+ monday},'','','','',{colSpan:5, text:'Date:'+this.addSevenDays(monday,7)},'','','','',{colSpan:5, text:'Date:' +this.addSevenDays(monday,14)},'','','','',{colSpan:5, text:'Date: ' +this.addSevenDays(monday,21)},'','','','',''],
                        ['','Cost','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','Totals'],
                        ['Pallet',PLTCOST ? PLTCOST.toFixed(2) : '',pallet[0],pallet[1],pallet[2],pallet[3],pallet[4],pallet[5],pallet[6],pallet[7],pallet[8],pallet[9],pallet[10],pallet[11],pallet[12],pallet[13],pallet[14],pallet[15],pallet[16],pallet[17],pallet[18],pallet[19],'$'+palletTotal.toFixed(2)],
                        ['Lid',LIDCOST ? LIDCOST.toFixed(2) : '',topcap[0],topcap[1],topcap[2],topcap[3],topcap[4],topcap[5],topcap[6],topcap[7],topcap[8],topcap[9],topcap[10],topcap[11],topcap[12],topcap[13],topcap[14],topcap[15],topcap[16],topcap[17],topcap[18],topcap[19],'$'+topcapTotal.toFixed(2)],
                        ['Box',BOXCOST ? BOXCOST.toFixed(2) : '',container[0],container[1],container[2],container[3],container[4],container[5],container[6],container[7],container[8],container[9],container[10],container[11],container[12],container[13],container[14],container[15],container[16],container[17],container[18],container[19],'$'+containerTotal.toFixed(2)],
                        ['Dunnage',DUNCOST ? DUNCOST.toFixed(2) : '',dunnage[0],dunnage[1],dunnage[2],dunnage[3],dunnage[4],dunnage[5],dunnage[6],dunnage[7],dunnage[8],dunnage[9],dunnage[10],dunnage[11],dunnage[12],dunnage[13],dunnage[14],dunnage[15],dunnage[16],dunnage[17],dunnage[18],dunnage[19],'$'+dunnageTotal.toFixed(2)],
                        [{colSpan: 23, text: (palletTotal + topcapTotal + containerTotal + dunnageTotal).toFixed(2), alignment: 'right'}]
                        ]
                    },
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
               },        
            },           
        }
      }
  }