import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DetailsBtnComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/import-model-list/details-btn/details-btn.component';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'app-confirm-import',
  templateUrl: './confirm-import.component.html',
  styleUrls: ['./confirm-import.component.scss']
})
export class ConfirmImportComponent implements OnInit {
  defaultErrMsg = ["SQL Error, Please contact System Administrator"];
  routeParams: any;
  onLoadData: any;
  rowClassRules: { 'row-even': string; 'row-odd': string; };
  confirmImportform = new FormGroup({
    leader: new FormControl('0'), 
    specialist: new FormControl('0'), 
    qpu: new FormControl('0'), 
    eci: new FormControl('0'), 
    weight: new FormControl('0'), 
    dimension: new FormControl('0'), 
    desc: new FormControl('0'), 
    due: new FormControl('0'), 
    fab: new FormControl('0'), 
    routing: new FormControl('0'), 
    newSamePkg: new FormControl('I'), 
    newDiffPkg: new FormControl('I'),
    updateDiffPkg: new FormControl('I') 
  })
  errorArray : any[] = [];
  readOnly:any;
  outArray: any;
  newSamePkg = '';
  updateDiffPkg = '';
  newDiffPkg = '';
  impModel: any;
  viewAccess: any;
  errorMessage !: { errorCode: string; childErrorCode: string; };

  constructor(
    public route: Router,
    private readonly service: ModelListService,
    public router: ActivatedRoute,
    public dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    public state : ExportStateService
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.router.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.impModel = JSON.parse(sessionStorage.getItem('impModel') || '');
        this.impModel['sop'] = this.getFormattedDate(this.impModel['sop']);
        this.checkAccess();
      }
    })
  }
  
  getFormattedDate(inputDate:any){
    if(['',null,undefined,'null','undefined'].includes(inputDate)){
      return '';
    }else{
      const date = new Date(inputDate);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
    }
  }

  checkAccess(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.viewAccess = res.role?.p_trackingview;
        if (this.viewAccess) {
          this.apiCall();
        } else {
          this.errorMessage = {errorCode : `API-403`,childErrorCode : '400'};
        }
      },
      error: (_err: any) => {
        this.viewAccess = false;
        this.errorMessage = {errorCode : `API-099`,childErrorCode : '400'};
      },
    });
  }

  confirmImport() {
    this.spinnerService.start();
    const body = {
      ID : this.routeParams.id,
      PlantCode : this.routeParams.plantcode,
      ModelCode : this.routeParams.modelcode, 
      tdAdd : this.onLoadData?.tdAdd,
      tddelete : this.onLoadData?.tddelete,
      leader: this.confirmImportform.controls.leader.value, 
      specRadio: this.confirmImportform.controls.specialist.value, 
      qpu: this.confirmImportform.controls.qpu.value, 
      eci: this.confirmImportform.controls.eci.value, 
      weight: this.confirmImportform.controls.weight.value, 
      dimension: this.confirmImportform.controls.dimension.value, 
      desc: this.confirmImportform.controls.desc.value, 
      due: this.confirmImportform.controls.due.value, 
      fab: this.confirmImportform.controls.fab.value, 
      routing: this.confirmImportform.controls.routing.value, 
      newSamePkg: this.confirmImportform.controls.newSamePkg.value, 
      newDiffPkg: this.confirmImportform.controls.newDiffPkg.value,
      updateDiffPkg: this.confirmImportform.controls.updateDiffPkg.value 
    }

    this.service.Importbutton(body).subscribe({
      next:(res:any) =>{
        if(res.status && res.response.length > 0){
          this.readOnly = true ;
          this.outArray = res.response;
          const {newSamePkg,newDiffPkg,updateDiffPkg} = this.confirmImportform.controls;
          this.getRadioValues(newSamePkg,newDiffPkg,updateDiffPkg)
        }else if(!res.status){
          if(res.message.length > 0){
            this.errorArray = res.message;
          }else{
            this.errorArray = this.defaultErrMsg
          }
        }
        this.spinnerService.stop();
      },
      error : (err:any) =>{
        this.spinnerService.stop();
        this.errorArray.push(err.message)
      }
    })
  }

  getRadioValues(newSamePkg:any,newDiffPkg:any,updateDiffPkg:any){
    if(newSamePkg.value === 'A'){
      this.newSamePkg = 'Add';
    }else{
      this.newSamePkg = 'Ignore';
    }

    if(newDiffPkg.value === 'C'){
      this.newDiffPkg = 'Carry Over';
    }else if(newDiffPkg.value === 'A'){      
      this.newDiffPkg = 'Add as new Record'
    }else{
      this.newDiffPkg = 'Ignore';
    }

    if(updateDiffPkg.value === 'U'){
      this.updateDiffPkg = 'Update';
    }else if(updateDiffPkg.value === 'A'){
      this.updateDiffPkg = 'Add as new Record'
    }else{
      this.updateDiffPkg = 'Ignore';
    }
  }

  detailsBtn(type: string, row: number) {
    let pageHeader = ''
    switch (type) {
      case 'new':
        pageHeader = 'New Tracking Details';
        break;
      case 'part-replacement':
        pageHeader = 'Part Replacement Details';
        break;
      case 'supplier-replacement':
        pageHeader = 'Supplier Replacement Details';
        break;
      case 'update':
        pageHeader = 'Update Tracking Details';
        break;
      case 'delete':
        pageHeader = 'Delete Tracking Details';
        break;
    }
    if (pageHeader) {
      const loadData = { ...this.routeParams, row: row }
      this.dialog.open(DetailsBtnComponent,
        { data: { mode: pageHeader, params: loadData }, width: '80%' })
    }
  }

  apiCall() {
    const body = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode
    }
    this.spinnerService.start();
    this.service.confirmImportOnload(body).subscribe({
      next: (res: any) => {
        if (res.status && res.message.length <= 0) {
          this.onLoadData = res.response;
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = {errorCode : `API-099`,childErrorCode : '400'};
      }
    })
  }

  routeToModelList(){
    this.route.navigate(['model-list'])
  }

  help() {
    this.route.navigate(['./master-help', 2, "Model List", 11]);
  }
}
