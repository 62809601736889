import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'sup-contact-btn-cell-renderer',
  template: `
    <button 
    class="vpacs-btn-table"
    style="height:30px;"
    (click)="btnClickedHandler($event)">CONTACT</button>    
  `,
})

export class SupContactBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  params: any;
  agInit(params: any): void {
    this.params = params;    
  }
  btnClickedHandler(_event: any) {
      this.router.navigateByUrl(`/supplier-maintenance/supplier-contact-maintenance/${this.params.data.suppliercode}`);
  }

}