import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import {
  FormGroup
} from '@angular/forms'
import { formatDate } from '@angular/common'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { ConstantsService } from 'src/app/services/constant.service'

@Component({
  selector: 'app-si-preppf-dept-approval',
  templateUrl: './si-preppf-dept-approval.component.html',
  styleUrls: ['./si-preppf-dept-approval.component.scss'],
})
export class SiPreppfDeptApprovalComponent implements OnInit {
  leaderPlant: any[] = []
  leaderPlantContents: any[] = []
  qpc: any[] = []
  qpcContents: any[] = []
  paramData: any
  details: any
  dateSentVal: any
  screenID = 721
  rdoYes11Checked = false
  rdoNo11Checked = false
  rdoYes12Checked = false
  rdoNo12Checked = false
  rdoYes25Checked = false
  rdoNo25Checked = false
  rdoYes26Checked = false
  rdoNo26Checked = false
  rdoYes27Checked = false
  rdoNo27Checked = false
  txtNote11Value: any
  txtNote12Value: any
  txtNote24Value: any
  txtPart26Value: any
  txtModel26Value: any
  sel22Items: any[] = []
  sel22SelectedIndex: any
  sel23Items: any[] = []
  sel23SelectedIndex: any
  sel24Items: any[] = []
  sel24SelectedIndex: any
  crrStep: any
  userInfo: any
  isConceptCompareDisabled = false
  work: any[] = []
  sendPPFform: FormGroup = new FormGroup({})
  leaderFlg: any
  stepStatus: any
  rdoContFabWorkChecked = false
  rdoSupWorkChecked = false
  rdoDun1FabWorkChecked = false
  rdoContFabWorkDisabled = false
  rdoSupWorkDisabled = false
  rdoDun1FabWorkDisabled = false
  showWorkTable = false
  displayQuestionnaire = false
  displaytrQuestionnaireComment = false
  taNoteValue: any
  roleInfo: any
  displaytrQuestionnaire = false
  displayFlg: any
  hideControltblReqAsFinal = false
  tdSharePlant: any
  btnSendDisabled = false
  hideControlbtnSend = false
  hideControlbtnApprove = false
  hideControlbtnReject = false
  hideControlbtnRejectBM = false
  hideControlbtnRejectSup = false
  tdCrrDept1: any
  tdDock2: any
  tdDock1: any
  tdCrrDept2: any
  tdDock3: any
  tdCrrDept3: any
  tdDock4: any
  tdCrrDept4: any
  tdDock5: any
  tdCrrDept5: any
  dvDock: any
  dvSpec: any
  alDock: any
  defaultApprover: any
  btnSelDock1Disabled = false
  btnSelDock2Disabled = false
  btnSelDock3Disabled = false
  btnSelDock4Disabled = false
  btnSelDock5Disabled = false
  selectDvSpec1: any[] = []
  selectDvSpec2: any[] = []
  selectDvSpec3: any[] = []
  selectDvSpec4: any[] = []
  selectDvSpec5: any[] = []
  sharePlants: any
  dvChld: any
  tdSharePlantOK: any
  tdSharePlantNG: any
  btnSharePlantsDisabled = false
  urlVer: any
  hazmat = false
  hazmatComment: any
  hazmatVal: any
  hazmatApproveDisabled = false
  hazmatApproveChecked = false
  hazmatRejectDisabled = false
  hazmatRejectChecked = false
  hazmatCommentDisabled = false
  taIntComment: any
  taExtComment: any
  taIntCommentDisable = false
  taExtCommentDisable = false
  radioVal: any
  radioValWork: any;
  chkSelDock1: any
  selDock1: any
  chkSelDock1Disabled = false
  selDock1Disabled = false
  chkSelDock2Disabled = false
  chkSelDock3Disabled = false
  chkSelDock4Disabled = false
  chkSelDock5Disabled = false
  selDock2Disabled = false
  selDock3Disabled = false
  selDock4Disabled = false
  selDock5Disabled = false
  chkSelDock2 = false
  selDock2: any
  chkSelDock3 = false
  selDock3: any
  chkSelDock4 = false
  selDock4: any
  chkSelDock5 = false
  selDock5: any
  rdoYesChecked = false
  rdoYesDisabled = false
  rdoNoDisabled = false
  rdoNoChecked = false
  chkSelDock1Checked = false
  chkSelDock2Checked = false
  chkSelDock3Checked = false
  chkSelDock4Checked = false
  chkSelDock5Checked = false
  stepStatusArray: any[] = []
  taRequestChanges: any
  taReasonRequested: any
  rdoTrialYesChecked = false
  rdoTrialYesDisabled = false
  rdoTrialNoChecked = false
  rdoTrialNoDisabled = false
  rdoAsFinalChecked = false
  rdoAsFinalDisabled = false
  btnParentPartDisabled = true
  reqChangesChkList = [
    { code: 1, description: 'Container Type', checked: false },
    { code: 2, description: 'Qty. per Container', checked: false },
    { code: 3, description: 'Container Size', checked: false },
    { code: 4, description: 'Dunnage', checked: false },
    { code: 5, description: 'Part Orientation', checked: false },
    { code: 6, description: 'other', checked: false },
  ]
  reasonReqArr = [
    { code: 1, description: 'Safety', checked: false },
    { code: 2, description: 'Quality', checked: false },
    { code: 3, description: 'Workability', checked: false },
    { code: 4, description: 'Over Weight', checked: false },
    { code: 5, description: 'Poor Efficiency', checked: false },
    { code: 6, description: 'Non Standard', checked: false },
    { code: 7, description: 'Other', checked: false },
  ]
  tblRequestReasonDisplay = false
  rdoYestestChecked = false
  rdoNotestChecked = false
  trial: any[] = []
  txtParentPartDisabled = true
  taTriFinalReasonDisabled = true
  tblTrialDisplay = false
  taTriFinalReason: any
  trSupplierConfirmDisplay = false
  chkReqAsFinal = false
  taFinalReason: any
  chkReqAsFinalDisabled = false
  taFinalReasonDisabled = false
  cblRequested = false
  cblReason = false
  taRequestChangesDisabled = false
  taReasonRequestedDisabled = false
  spCode: any
  errorMessage: any
  validationMessage: any = null
  txtParentPart: any
  err = '099  - 001 :Runtime Error : Contact a System Administrator.'
  q21date: any
  q13date: any
  q14date: any
  ppfVer: any
  dateFormat = 'MM/dd/yyyy'
  trackingUrl = '/tracking-list'
  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly state: ExportStateService,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.spinnerService.start();
    this.txtParentPart = sessionStorage.getItem('siPartNum') || ''
    const radio = sessionStorage.getItem('radioVal')
    this.radioVal = this.nullStr(radio) !== '' ? Number(radio) : ''
    sessionStorage.removeItem('radioVal')
    const data = sessionStorage.getItem('preppfRad')
    sessionStorage.removeItem('preppfRad')
    const data1 = sessionStorage.getItem('preppfReq')
    sessionStorage.removeItem('preppfReq')
    const data2 = sessionStorage.getItem('preppfRea')
    sessionStorage.removeItem('preppfRea')
    if (data1 !== null) {
      this.reqChangesChkList = JSON.parse(data1)
    }
    if (data2 !== null) {
      this.reasonReqArr = JSON.parse(data2)
    }
    if (data !== null) {
      const retData = JSON.parse(data)
      if (retData) {
        this.rdoTrialYesChecked = retData.yes;
        this.rdoTrialNoChecked = retData.no;
        this.rdoAsFinalChecked = retData.final
      }
    }
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info
        this.roleInfo = res?.role

        if (res) {
          this.spCode = this.userInfo.specialistcode;
          this.paramData = JSON.parse(
            sessionStorage.getItem('deptApprove') || '{}',
          )
          if (this.paramData) {
            this.siDeptApprDetails()
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.start();
      },
    })

    this.leaderPlant = [
      'Leader Plant',
      'Plant',
      'Dock',
      'Model',
      'Supplier',
      'Part Number',
      'Specialist',
      'Date Sent',
    ]

    this.qpc = ['QPC', 'Full Cont. Weight']

    this.work = ['Supplier', 'Cont Fab', 'Dun1 Fab']

    this.trial = ['Trial', 'Sibling Part', 'Reason']
  }

  siDeptApprDetails() {
    this.leaderPlantContents = []
    this.qpcContents = []
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      PPFCode: this.paramData?.ppfcode,
      PPFver: this.paramData?.ppfver,
      step: '4',
    }
    this.trackingListService.sendDepartmentApproval(loadObj).subscribe({
      next: (res: any) => {
        this.details = res
        this.dateSent()
        const items = [
          this.paramData?.leaderplant,
          this.paramData?.plantname,
          this.details?.dv_tblInfo[0]?.dockcode,
          this.paramData?.modelcode,
          this.paramData?.suppliername,
          this.paramData?.partnumber,
          this.paramData?.specialist,
          this.dateSentVal,
        ]
        this.leaderPlantContents.push(...items)

        const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
          && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
            this.changeSize(this.details?.tdFullCont, 1),
            1,
          ) : ""

        const qpcItems = [
          this.details?.qtyPartCont,
          fullContStore === "" ? "-" : fullContStore
        ]
        this.qpcContents.push(...qpcItems)

        this.taFinalReasonDisabled = this.chkReqAsFinal ? false : true
        this.loadParameter(res)

        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
    })
  }

  loadParameter(data: any) {
    this.crrStep = data?.getCurrentStepApprove
    this.stepStatus = data?.stepStatus
    this.stepStatusArray = Object.entries(this.stepStatus[0])

    this.leaderFlg = data?.leaderFlag
    this.displayFlg = data?.displayFlag // Need to check with backend
    this.displayFlg = [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0]
    this.urlVer = data?.url_ver
    this.ppfVer = -1;
    // follower
    if (this.leaderFlg === 1) {
      this.displayFlg[16] = 1;
      this.displayFlg[17] = 0;
      this.displayFlg[18] = 1;
      this.displayFlg[19] = 0;
      this.displayFlg[20] = 0;
      this.displayFlg[21] = 0;
      this.ppfVer = ""
    }

    this.createHtml_dispControl(data)
  }

  createHtml_dispControl(data: any) {
    const displayFlg = this.displayFlg

    // working
    if (displayFlg[2] === 1) {
      this.showWorkTable = true
      this.createHtml_tblWork()
    }

    // concept compare
    if (this.crrStep === 2) {
      this.isConceptCompareDisabled = true
    }

    if (!this.roleInfo?.p_costview) {
      this.isConceptCompareDisabled = true
    }

    if (this.crrStep === 4 || this.crrStep === 7 || this.crrStep === 9) {
      this.taNoteValue = this.details?.t_Note_GetComment ??
        (this.details?.createData_supQuestionnaire?.commentData !== '' ? this.details?.createData_supQuestionnaire?.commentData :
          this.details?.createData_supQuestionnaire?.questionnaire[0]?.note)
    }

    //NOT NEEDED IN THIS PAGE : IF NEEDED ADD IT FROM SI FINAL PPF DEPT

    // proposal with changes
    if (displayFlg[5] === 1) {
      //   this.tblProposal_Display = true
      //   this.createHtml_ProposalWithChanges()
      // } else {
      //   this.tblProposal_Display = false
    }

    // supplier questionnaire

    if (displayFlg[6] === 1 && displayFlg[7] === 1) {
      this.displayQuestionnaire = true
      this.displaytrQuestionnaireComment = true
      this.displaytrQuestionnaire = true
      this.createHtml_tblSupplierQuestionnaire()
    } else if (displayFlg[6] === 0 && displayFlg[7] === 1) {
      // display(comment)
      this.displaytrQuestionnaire = false
      this.displayQuestionnaire = true
      this.displaytrQuestionnaireComment = true
      this.createHtml_tblSupplierQuestionnaire()
    } else if (displayFlg[6] === 1 && displayFlg[7] === 0) {
      this.displayQuestionnaire = true
      this.displaytrQuestionnaire = true
      this.displaytrQuestionnaireComment = false
      this.createHtml_tblSupplierQuestionnaire()
    } else if (displayFlg[6] === 0 && displayFlg[7] === 0) {
      this.displayQuestionnaire = false
    }

    // requested changes/reason requested
    if (displayFlg[8] === 1 && displayFlg[9] === 1) {
      this.tblRequestReasonDisplay = true
      this.createHtml_RequestedComment()
    } else if (displayFlg[8] === 0 && displayFlg[9] === 0) {
      this.tblRequestReasonDisplay = false
    }

    // trial
    if (displayFlg[10] === 1) {
      this.tblTrialDisplay = true
      this.createHtml_tblTrial()
    } else {
      this.tblTrialDisplay = false
    }

    // request as final
    if (displayFlg[11] === 1) {
      // display
      this.createHtml_RequestAsFinal(data)
    } else {
      this.hideControltblReqAsFinal = true
    }

    this.createHtml_intappDock(data)
    this.createHtml_sharePlants(data)
    this.createHtml_commentIntSup(data)

    // send button
    if (displayFlg[16] === 1) {
      // display

      if (this.tdSharePlant === '-' && this.leaderFlg !== 1) {
        this.btnSendDisabled = true
      }
    } else {
      this.hideControlbtnSend = true
    }

    // approve button
    if (displayFlg[17] === 1) {
      this.hideControlbtnApprove = false
    } else {
      this.hideControlbtnApprove = true
    }

    // reject button
    if (displayFlg[18] === 1) {
      this.hideControlbtnReject = false
    } else {
      this.hideControlbtnReject = true
    }

    // reject to bm/supplier button
    if (displayFlg[19] === 1 && displayFlg[20] === 1) {
      this.hideControlbtnRejectBM = false
      this.hideControlbtnRejectSup = false
    } else if (displayFlg[19] === 0 && displayFlg[20] === 0) {
      this.hideControlbtnRejectBM = true
      this.hideControlbtnRejectSup = true
    }

    // questionnaire of testing
    if (displayFlg[22] === 1) {
      // display
      this.trSupplierConfirmDisplay = true
      this.createHtml_tblSupplierQuestionnaire()
    } else {
      this.trSupplierConfirmDisplay = false
    }

    //Hazmat Approval
    const hazmatCheck = data.dv_tblInfo[0].hazmat;
    const hazmatStatus = data.dv_tblInfo[0].hzapprovestatus;
    if (hazmatCheck === 'N' || hazmatCheck === null || hazmatCheck === '') {
      this.hazmat = false;
    }
    else if(this.paramData?.plantcode === '50SER'|| this.paramData?.plantcode === '51ACC'){
      this.hazmat = true;
      this.hazmatApproveDisabled = true;
      this.hazmatRejectDisabled = true;
      this.hazmatCommentDisabled = true;
      if (hazmatStatus === 'A' || hazmatStatus === 'Approved' || hazmatStatus === 'APPROVED') {
        this.hazmatVal = 1;
        this.hazmatApproveChecked = true;
        this.hazmatComment = data.dv_tblInfo[0].hzapprovercomment;
      }
      else {
        this.hazmatVal = 0;
        this.hazmatRejectChecked = true;
        this.hazmatComment = data.dv_tblInfo[0].hzapprovercomment;
      }
    }
    else{
      this.hazmat = false;
    }
  }

  createHtml_tblTrial() {
    if (this.rdoTrialNoChecked === true) {
      this.txtParentPartDisabled = false
      this.btnParentPartDisabled = false
      this.taTriFinalReasonDisabled = true
    } else if (this.rdoTrialYesChecked === true) {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = true
    } else if (this.rdoAsFinalChecked) {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = false
    } 
  }

  createHtml_RequestedComment() {
    const dv: any = this.details?.createHtml_RequestedComment
    if (dv === null || dv === '') {
      return
    }

    const alChk = dv?.al_chk
    for (let i = 0; i < alChk?.length; i++) {
      if (this.nullInt(alChk[i]) === 1) {
        this.reqChangesChkList[0].checked = true
      } else if (this.nullInt(alChk[i]) === 2) {
        this.reqChangesChkList[1].checked = true
      } else if (this.nullInt(alChk[i]) === 3) {
        this.reqChangesChkList[2].checked = true
      } else if (this.nullInt(alChk[i]) === 4) {
        this.reqChangesChkList[3].checked = true
      } else if (this.nullInt(alChk[i]) === 5) {
        this.reqChangesChkList[4].checked = true
      } else if (this.nullInt(alChk[i]) === 6) {
        this.reqChangesChkList[5].checked = true
      } else if (this.nullInt(alChk[i]) === 7) {
        this.reasonReqArr[0].checked = true
      } else if (this.nullInt(alChk[i]) === 8) {
        this.reasonReqArr[1].checked = true
      } else if (this.nullInt(alChk[i]) === 9) {
        this.reasonReqArr[2].checked = true
      } else if (this.nullInt(alChk[i]) === 10) {
        this.reasonReqArr[3].checked = true
      } else if (this.nullInt(alChk[i]) === 11) {
        this.reasonReqArr[4].checked = true
      } else if (this.nullInt(alChk[i]) === 12) {
        this.reasonReqArr[5].checked = true
      } else if (this.nullInt(alChk[i]) === 13) {
        this.reasonReqArr[6].checked = true
      }
    }

    this.taRequestChanges = this.nullStr(dv?.commentRequested)
    this.taReasonRequested = this.nullStr(dv?.commentReason)

    if (
      this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
      this.leaderFlg === 1
    ) {
      this.cblRequested = true
      this.cblReason = true

      this.taRequestChangesDisabled = true
      this.taReasonRequestedDisabled = true
    }
  }

  createHtml_tblSupplierQuestionnaire() {
    const questionnaire = this.details?.createQuestionnaire
      ? this.details?.createQuestionnaire[0]
      : (this.details?.createData_supQuestionnaire
        ? this.details?.createData_supQuestionnaire?.questionnaire[0] : '')

    if (this.nullStr(questionnaire) !== '') {
      if (questionnaire?.q1_1_yn === 'Y') {
        this.rdoYes11Checked = true
      } else if (questionnaire?.q1_1_yn === 'N') {
        this.rdoNo11Checked = true
      }

      if (questionnaire?.q1_2_yn === '1') {
        this.rdoYes12Checked = true
      } else if (questionnaire?.q1_2_yn === '0') {
        this.rdoNo12Checked = true
      }

      if (questionnaire?.q2_5_yn === 'Y') {
        this.rdoYes25Checked = true
      } else if (questionnaire?.q2_5_yn === 'N') {
        this.rdoNo25Checked = true
      }

      if (questionnaire?.q2_6_yn === 'Y') {
        this.rdoYes26Checked = true
      } else if (questionnaire?.q2_6_yn === 'N') {
        this.rdoNo26Checked = true
      }

      if (questionnaire?.q2_7_yn === '1') {
        this.rdoYes27Checked = true
      } else if (questionnaire?.q2_7_yn === '0') {
        this.rdoNo27Checked = true
      }

      this.txtNote11Value = questionnaire?.q1_1_note
      this.txtNote12Value = questionnaire?.q1_2_note
      this.txtNote24Value = questionnaire?.q2_4_note
      this.txtPart26Value = questionnaire?.q2_6_part
      this.txtModel26Value = questionnaire?.q2_6_model

      if (questionnaire?.q2_2_select === '1') {
        const options = {
          index: '1',
          value: 'Drawing / Data',
        }
        this.sel22Items?.push(options)
        this.sel22SelectedIndex = '1'
      } else if (questionnaire?.q2_2_select === '2') {
        const options = {
          index: '1',
          value: 'Sample Part',
        }
        this.sel22Items?.push(options)
        this.sel22SelectedIndex = '1'
      } else if (questionnaire?.q2_2_select === '3') {
        const options = {
          index: '1',
          value: 'Other',
        }
        this.sel22Items.push(options)
        this.sel22SelectedIndex = '1'
      } else {
        this.sel22SelectedIndex = '0'
      }

      if (questionnaire?.q2_3_select === '1') {
        const options = {
          index: '1',
          value: 'Accept - Exact Pkg',
        }
        this.sel23Items.push(options)
        this.sel23SelectedIndex = '1'
      } else if (questionnaire?.q2_3_select === '2') {
        const options = {
          index: '1',
          value: 'Accept - Modify Pkg',
        }
        this.sel23Items.push(options)
        this.sel23SelectedIndex = '1'
      } else if (questionnaire?.q2_3_select === '3') {
        const options = {
          index: '1',
          value: 'New Proposal',
        }
        this.sel23Items.push(options)
        this.sel23SelectedIndex = '1'
      } else {
        this.sel23SelectedIndex = '0'
      }

      if (questionnaire?.q2_4_select === '1') {
        const options = {
          index: '1',
          value: 'Part Shape',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else if (questionnaire?.q2_4_select === '2') {
        const options = {
          index: '1',
          value: 'Part Size',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else if (questionnaire?.q2_4_select === '3') {
        const options = {
          index: '1',
          value: 'Part Weight',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else if (questionnaire?.q2_4_select === '4') {
        const options = {
          index: '1',
          value: 'Material Change',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else if (questionnaire?.q2_4_select === '5') {
        const options = {
          index: '1',
          value: 'New Part Introduction',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else if (questionnaire?.q2_4_select === '6') {
        const options = {
          index: '1',
          value: 'Other',
        }
        this.sel24Items.push(options)
        this.sel24SelectedIndex = '1'
      } else {
        this.sel24SelectedIndex = '0'
      }
      if (questionnaire?.q1_3_date !== null && questionnaire?.q1_3_date !== undefined) {
        this.q13date = formatDate(questionnaire?.q1_3_date, this.dateFormat, 'en-US', 'UTC')
      }
      if (questionnaire?.q1_4_date !== null && questionnaire?.q1_4_date !== undefined) {
        this.q14date = formatDate(questionnaire?.q1_4_date, this.dateFormat, 'en-US', 'UTC')
      }
      if (questionnaire?.q2_1_date !== null && questionnaire?.q2_1_date !== undefined) {
        this.q21date = formatDate(questionnaire?.q2_1_date, this.dateFormat, 'en-US', 'UTC')
      }

      if (this.crrStep === 7) {
        if (this.nullStr(questionnaire['step6confirm']) === '1') {
          this.rdoYestestChecked = true
        } else if (this.nullStr(questionnaire['step6confirm']) === '0') {
          this.rdoNotestChecked = true
        }
      } else if (this.crrStep === 9) {
        if (this.nullStr(questionnaire['step8confirm']) === '1') {
          this.rdoYestestChecked = true
        } else if (this.nullStr(questionnaire['step8confirm']) === '0') {
          this.rdoNotestChecked = true
        }
      }
    }
  }

  createHtml_tblWork() {
    const working = Number(this.details?.nowWorking)

    switch (working) {
      case 2:
        this.rdoContFabWorkChecked = true
        this.radioValWork = 1;
        break
      case 6:
        this.rdoSupWorkChecked = true
        this.radioValWork = 2;
        break
      case 8:
        this.rdoDun1FabWorkChecked = true
        this.radioValWork = 3;
        break
      default:
        break
    }
    if (
      this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
      this.leaderFlg === 1
    ) {
      this.rdoContFabWorkDisabled = true
      this.rdoSupWorkDisabled = true
      this.rdoDun1FabWorkDisabled = true
    }
  }

  createHtml_RequestAsFinal(data: any) {
    try {
      const dv: any = data?.createHtml_RequestAsFinal

      if (this.screenID !== 720 || this.leaderFlg === 1) {
        if (this.nullInt(dv?.finalFlag) === 1) {
          this.chkReqAsFinal = true
        }
        if (this.nullStr(dv?.finalReason) !== '') {
          this.taFinalReason = this.nullStr(dv?.finalReason)
        }

        this.chkReqAsFinalDisabled = true
        this.taFinalReasonDisabled = true
      }
    } catch (error) {
      return
    }
  }

  createHtml_intappDock(data: any) {

    try {
      // start assignment
      // set value
      this.tdDock1 = '-'
      this.tdCrrDept1 = '-'

      this.tdDock2 = '-'
      this.tdCrrDept2 = '-'

      this.tdDock3 = '-'
      this.tdCrrDept3 = '-'

      this.tdDock4 = '-'
      this.tdCrrDept4 = '-'

      this.tdDock5 = '-'
      this.tdCrrDept5 = '-'

      this.dvDock = data?.dv_dock
      this.dvSpec = data?.dv_spec.result
      this.alDock = data?.tdDock
      this.defaultApprover = data?.defaultApprover
      // end assignment

      const strDocks = data?.getIntApproveDocks

      if (strDocks === '') {
        this.radioVal = 0
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true
        this.rdoYesChecked = false
        this.rdoNoChecked = true

        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnSelDock1Disabled = true

        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnSelDock2Disabled = true

        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnSelDock3Disabled = true

        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnSelDock4Disabled = true

        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnSelDock5Disabled = true

        this.taIntCommentDisable = true

        return
        //here to start tomorrow
      } else if (strDocks === null) {
        return
      }

      let dockStatus = 0

      // dock - row 1
      if (this.alDock && this.alDock.length >= 1) {
        // dock name
        this.tdDock1 = this.nullStr(this.alDock[0])

        // dataview

        for (const iterator of this.dvSpec) {
          if (this.nullStr(iterator['divcode']) === 'CV'){ //changes
            this.selectDvSpec1.push({
              specialist: iterator['specialist'],
              specialistcode: iterator['specialistcode'],
            })
          }
        }

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock1Checked = false
              this.tdCrrDept1 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 0) {
                this.tdCrrDept1 = '-'
              } else if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept1 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept1 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept1 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept1 = 'Complete'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec1) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock1 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec1) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock1 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec1.length < 1) {
            this.chkSelDock1Checked = false
            this.chkSelDock1Disabled = true
            this.selDock1Disabled = true
            this.btnSelDock1Disabled = true
          }
          else {
            this.chkSelDock1Checked = true
          }
        } else {
          this.chkSelDock1Checked = false
          this.chkSelDock1Disabled = true
          this.selDock1Disabled = true
          this.btnSelDock1Disabled = true
        }
      } else {
        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnSelDock1Disabled = true
      }

      // dock - row2
      if (this.alDock && this.alDock.length >= 2) {
        // dock name
        this.tdDock2 = this.nullStr(this.alDock[1])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){  //changes
              this.selectDvSpec2.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock2Checked = false
              this.tdCrrDept2 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept2 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept2 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept2 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept2 = 'Complete'
              } else {
                this.tdCrrDept2 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec2) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock2 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec2) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock2 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec2.length < 1) {
            this.chkSelDock2Checked = false
            this.chkSelDock2Disabled = true
            this.selDock2Disabled = true
            this.btnSelDock2Disabled = true
          }
          else {
            this.chkSelDock2Checked = true
          }
        } else {
          this.chkSelDock2Checked = false
          this.chkSelDock2Disabled = true
          this.selDock2Disabled = true
          this.btnSelDock2Disabled = true
        }
      } else {
        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnSelDock2Disabled = true
      }

      // dock - row3
      if (this.alDock && this.alDock.length >= 3) {
        // dock name
        this.tdDock3 = this.nullStr(this.alDock[2])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){  //changes
              this.selectDvSpec3.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock3Checked = false
              this.tdCrrDept3 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept3 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept3 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept3 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept3 = 'Complete'
              } else {
                this.tdCrrDept3 = '-'
              }
            }

            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec3) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock3 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec3) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock3 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec3.length < 1) {
            this.chkSelDock3Checked = false
            this.chkSelDock3Disabled = true
            this.selDock3Disabled = true
            this.btnSelDock3Disabled = true
          }
          else {
            this.chkSelDock3Checked = true
          }
        } else {
          this.chkSelDock3Checked = false
          this.chkSelDock3Disabled = true
          this.selDock3Disabled = true
          this.btnSelDock3Disabled = true
        }
      } else {
        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnSelDock3Disabled = true
      }

      // dock - row4
      if (this.alDock && this.alDock.length >= 4) {
        // dock name
        this.tdDock4 = this.nullStr(this.alDock[3])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver

          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){  //changes
              this.selectDvSpec4.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock4Checked = false
              this.tdCrrDept4 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept4 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept4 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept4 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept4 = 'Complete'
              } else {
                this.tdCrrDept4 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec4) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock4 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec4) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock4 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec4.length < 1) {
            this.chkSelDock4Checked = false
            this.chkSelDock4Disabled = true
            this.selDock4Disabled = true
            this.btnSelDock4Disabled = true
          }
          else {
            this.chkSelDock4Checked = true
          }
        } else {
          this.chkSelDock4Checked = false
          this.chkSelDock4Disabled = true
          this.selDock4Disabled = true
          this.btnSelDock4Disabled = true
        }
      } else {
        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnSelDock4Disabled = true
      }

      // dock - row5
      if (this.alDock && this.alDock.length >= 5) {
        // dock name
        this.tdDock5 = this.nullStr(this.alDock[4])

        // dataview

        if (!this.checkNull(this.dvSpec) && this.dvSpec.length >= 1) {
          // conv approver
          for (const iterator of this.dvSpec) {
            if (this.nullStr(iterator['divcode']) === 'CV'){  //changes
              this.selectDvSpec5.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvDock) && this.dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvDock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvDock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvDock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvDock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock5Checked = false
              this.tdCrrDept5 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept5 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept5 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept5 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept5 = 'Complete'
              } else {
                this.tdCrrDept5 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec5) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvDock[0]['cvcode'])
                ) {
                  this.selDock5 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectDvSpec5) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock5 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectDvSpec5.length < 1) {
            this.chkSelDock5Checked = false
            this.chkSelDock5Disabled = true
            this.selDock5Disabled = true
            this.btnSelDock5Disabled = true
          }
          else {
            this.chkSelDock5Checked = true
          }
        } else {
          this.chkSelDock5Checked = false
          this.chkSelDock5Disabled = true
          this.selDock5Disabled = true
          this.btnSelDock5Disabled = true
        }
      } else {
        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnSelDock5Disabled = true
      }

      // set disable(send)

      if (this.stepStatusArray[this.crrStep - 1][1] === '13') {
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true

        this.chkSelDock1Disabled = true
        this.chkSelDock2Disabled = true
        this.chkSelDock3Disabled = true
        this.chkSelDock4Disabled = true
        this.chkSelDock5Disabled = true

        this.selDock1Disabled = true
        this.selDock2Disabled = true
        this.selDock3Disabled = true
        this.selDock4Disabled = true
        this.selDock5Disabled = true

        // internal approvement y/n
        if (
          (this.tdCrrDept1 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept2 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept3 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept4 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept5 === '-' || this.tdCrrDept1 === 'Skip')
        ) {
          this.radioVal = 0
          this.rdoNoChecked = true
          this.rdoYesChecked = false
        } else {
          this.radioVal = 1
          this.rdoYesChecked = true
          this.rdoNoChecked = false
        }
      }

      if (this.stepStatusArray[this.crrStep - 1][1] === '1') {
        this.btnSelDock1Disabled = true
        this.btnSelDock2Disabled = true
        this.btnSelDock3Disabled = true
        this.btnSelDock4Disabled = true
        this.btnSelDock5Disabled = true
      }
    } catch (error) {
      return
    }
  }

  createHtml_sharePlants(data: any) {
    try {
      this.sharePlants = data?.createHtml_sharePlants

      const countOK = this.sharePlants?.countOK
      const countNG = this.sharePlants?.countNG
      const cntWorking = this.sharePlants?.cntWorking

      // child plants
      this.dvChld = data?.dv_chld

      if (this.checkNull(this.dvChld)) {
        this.tdSharePlant = '-'
        this.tdSharePlantOK = '-'
        this.tdSharePlantNG = '-'
        this.btnSharePlantsDisabled = true
        return
      }

      if (
        this.leaderFlg === 1 ||
        this.stepStatusArray[this.crrStep - 1][1] === '1'
      ) {
        this.btnSharePlantsDisabled = true
      }

      this.tdSharePlant = this.nullStr(this.dvChld.length)

      // count OK or NG

      if (cntWorking > 0 || this.dvChld.length === countNG + countOK) {
        this.btnSendDisabled = true
      }

      this.tdSharePlantOK = countOK.toString()
      this.tdSharePlantNG = countNG.toString()

    } catch (error) {
      return
    }
  }

  createHtml_commentIntSup(data: any) {
    try {
      // input data

      const objCommentIntSup = data?.createHtml_commentIntSup // padam will change

      this.taIntComment = this.nullStr(objCommentIntSup['dv_taIntComment'])
      this.taExtComment = this.nullStr(objCommentIntSup['dv_taExtComment'])

      if (
        this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
        this.leaderFlg === 1
      ) {
        this.taIntCommentDisable = true
        this.taExtCommentDisable = true
      }
    } catch (error) {
      return
    }
  }

  checkNull(value: any) {
    try {
      if (Object.prototype.toString.call(value) === '[object Array]') {
        return false
      }
      else if (Object.prototype.toString.call(value) === '[object Object]') {
        return false
      }
      else if (
        value === null ||
        value === undefined ||
        value.trim() === '' ||
        value === 'undefined' ||
        value === 'null'
      ) {
        return true
      }
      else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  nullInt(obj: any) {
    if (obj === null) {
      return 0
    } else {
      /*else if (obj === {}) {
      return 0;
    }*/
      return parseInt(obj)
    }
  }

  nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  changeSize(obj: any, type: any) {
    if (obj === null) {
      return 0
    } else {
      const size = this.nullDouble(obj) 
      if (this.userInfo?.unit === '0') {
        return size
      } else {
        if (type === 0) {
          return this.changeInch(size)
        } else if (type === 1) {
          return this.changePound(size)
        }
        return 0
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj.toString())
        } else {
          return parseInt(obj.toString())
        }
      } catch {
        return 0
      }
    }
  }

  changeInch(size: any) {
    return Math.round((size / 25.4) * 2) / 2
  }

  changePound(size: any) {
    return (size * 1000) / 453.59237
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size)
      } else {
        return this.formatPoundNull(size)
      }
    } else {
      if (type === 0) {
        return this.formatMmNull(size)
      } else {
        return this.formatkgNull(size)
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return '0'
    }
    return size.toFixed(2)
  }

  formatPoundNull(size: any) {
    if (size === 0) {
      return ''
    }
    return size.toFixed(1)
  }

  formatMmNull(mm: any) {
    if (mm === 0) {
      return ''
    }
    return mm.toFixed(2)
  }

  formatkgNull(kg: any) {
    if (kg === 0) {
      return ''
    }
    return kg.toFixed(2)
  }

  dateSent() {
    if (this.details?.dv_date !== null && this.details?.dv_date?.length > 0) {
      this.dateSentVal = '-'
      if (this.nullStr(this.details?.dv_date[0]['datesent']) !== '') {
        this.dateSentVal = formatDate(this.details?.dv_date[0]['datesent'], this.dateFormat, 'en-US', 'UTC')
      }
    } else {
      this.dateSentVal = '-'
    }
  }

  internalAppChange(event: any) {
    if (event.value === 0) {
      this.rdoYesChecked = false;
      this.rdoNoChecked = true;
    }
    else if (event.value === 1) {
      this.rdoYesChecked = true;
      this.rdoNoChecked = false;
    }
  }

  approve() {
    this.errorMessage = [];
    this.validationMessage = null;
    this.spinnerService.start();
    const stepStatus = Object.values(this.stepStatus[0]).map(Number)

    const obj = {
      txtParentPart: this.txtParentPart,
      leaderFlg: this.nullInt(this.leaderFlg), // (variable) page load API will assign this value
      crrStep: this.crrStep, // (variable) page load API will assign this value
      stepStatus: stepStatus, // (variable) page load API will assign this value
      rdoYes: this.radioVal === 1 ? true : false, // (Internal Approvement) radio button
      rdoNo: this.radioVal === 0 ? true : false,  // (Internal Approvement) radio button
      PlantCode: this.paramData?.plantcode, // query param
      PartNumber: this.paramData?.partnumber, // query param
      SupplierCode: this.paramData?.suppliercode, // query param
      PkgStartDate: this.paramData?.pkgstartdate, // query param
      ppfCode: this.paramData?.ppfcode, // query param
      UserName: this.spCode, // userInfo  Specialist Name
      ModelCode: this.paramData?.modelcode, // (variable) page load API will assign this value
      rdoSupWork: this.radioValWork === 2 ? true : false,  // Work radio button
      rdoContFabWork: this.radioValWork === 1 ? true : false, // Work radio button
      rdoDun1FabWork: this.radioValWork === 3 ? true : false, // Work radio button
      chkReqAsFinal: this.chkReqAsFinal, // Request as Final checkbox
      taFinalReason: this.taFinalReason, // Reason text Area
      selDock1_SelectedIndex: this.selDock1 ? 1 : 0, // Conv Approver Select List (index)
      selDock2_SelectedIndex: this.selDock2 ? 1 : 0, // Conv Approver Select List (index)
      selDock3_SelectedIndex: this.selDock3 ? 1 : 0, // Conv Approver Select List (index)
      selDock4_SelectedIndex: this.selDock4 ? 1 : 0,  // Conv Approver Select List (index)
      selDock5_SelectedIndex: this.selDock5 ? 1 : 0, // Conv Approver Select List (index)
      taIntComment: this.taIntComment, // Internal Comment textArea
      taExtComment: this.taExtComment, // Supplier/Fabricator Comment textArea
      chkSelDock1: this.chkSelDock1Checked, // table check box
      chkSelDock2: this.chkSelDock2Checked, // table check box
      chkSelDock3: this.chkSelDock3Checked, // table check box
      chkSelDock4: this.chkSelDock4Checked, // table check box
      chkSelDock5: this.chkSelDock5Checked, // table check box
      tdDock1: this.tdDock1, // Dock column read only
      tdDock2: this.tdDock2, // Dock column read only
      tdDock3: this.tdDock3, // Dock column read only
      tdDock4: this.tdDock4, // Dock column read only
      tdDock5: this.tdDock5, // Dock column read only
      rdoTrialYes: this.rdoTrialYesChecked, // keep it false | not applicable for SI SE
      rdoTrialNo: this.rdoTrialNoChecked,
      taTriFinalReason: this.rdoAsFinalChecked ? this.taTriFinalReason : "",
      taRequestChanges: this.taRequestChanges, // keep it empty |  not applicable for SI SE
      taReasonRequested: this.taReasonRequested, // keep it empty |  not applicable for SI SE
      chkSelDock1_Disabled: this.chkSelDock1Disabled, // table check box 1 | send disabled or not
      chkSelDock2_Disabled: this.chkSelDock2Disabled, // table check box 2 | send disabled or not
      chkSelDock3_Disabled: this.chkSelDock3Disabled, // table check box 3 | send disabled or not
      chkSelDock4_Checked: this.chkSelDock4Checked, // table check box 4 | send checked or not
      chkSelDock5_Disabled: this.chkSelDock5Disabled, // table check box 5 | send disabled or not
      selDock1: this.selDock1, // Conv Approver Select List (value)
      selDock2: this.selDock2, // Conv Approver Select List (value)
      selDock3: this.selDock3, // Conv Approver Select List (value)
      selDock4: this.selDock4, // Conv Approver Select List (value)
      selDock5: this.selDock5, // Conv Approver Select List (value)
      rdoAsFinal: this.rdoAsFinalChecked, // keep it false | not applicable for SI SE
      cblRequested: [
        {
          Selected: this.reqChangesChkList[0].checked,
          Value: this.reqChangesChkList[0].description,
        },
        {
          Selected: this.reqChangesChkList[1].checked,
          Value: this.reqChangesChkList[1].description,
        },
        {
          Selected: this.reqChangesChkList[2].checked,
          Value: this.reqChangesChkList[2].description,
        },
        {
          Selected: this.reqChangesChkList[3].checked,
          Value: this.reqChangesChkList[3].description,
        },
        {
          Selected: this.reqChangesChkList[4].checked,
          Value: this.reqChangesChkList[4].description,
        },
        {
          Selected: this.reqChangesChkList[5].checked,
          Value: this.reqChangesChkList[5].description,
        },
      ], // page load will assign  | not applicable for SI SE
      cblReason: [
        {
          Selected: this.reasonReqArr[0].checked,
          Value: this.reasonReqArr[0].description,
        },
        {
          Selected: this.reasonReqArr[1].checked,
          Value: this.reasonReqArr[1].description,
        },
        {
          Selected: this.reasonReqArr[2].checked,
          Value: this.reasonReqArr[2].description,
        },
        {
          Selected: this.reasonReqArr[3].checked,
          Value: this.reasonReqArr[3].description,
        },
        {
          Selected: this.reasonReqArr[4].checked,
          Value: this.reasonReqArr[4].description,
        },
        {
          Selected: this.reasonReqArr[5].checked,
          Value: this.reasonReqArr[5].description,
        },
        {
          Selected: this.reasonReqArr[6].checked,
          Value: this.reasonReqArr[6].description,
        },
      ], // page load will assign | not applicable for SI SE
    }
    this.trackingListService.siBtnApprove(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          sessionStorage.removeItem('deptApprove');
          sessionStorage.removeItem('preppfRad')
          sessionStorage.removeItem('preppfReq')
          sessionStorage.removeItem('preppfRea')
          sessionStorage.removeItem('siPartNum')
          sessionStorage.removeItem('partnumber');
          this.router.navigateByUrl(this.trackingUrl)
        } else{
          this.errorMessage = (res?.message)?(res?.message):[this.err];
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = (_err.error?.error?.message) ?(_err.error?.error?.message): [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  send() {
    this.errorMessage = [];
    this.validationMessage = null;

    this.spinnerService.start();

    const stepStatus = Object.values(this.stepStatus[0]).map(Number)

    const obj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      leaderFlg: this.nullInt(this.leaderFlg),
      crrStep: this.crrStep,
      stepStatus: stepStatus,
      rdoYes: this.rdoYesChecked,
      approval: true,
    }
    this.trackingListService.siBtnSend(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl(this.trackingUrl)
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  rejectBm() {
    this.errorMessage = [];
    this.validationMessage = null;
    this.spinnerService.start();

    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
    }
    this.trackingListService.siBtnRejectBm(obj).subscribe({
      next: (res: any) => {
        if (res?.result?.result) {
          this.router.navigateByUrl(this.trackingUrl)
        }
        else {
          this.errorMessage = [res?.result?.message];
          window.scrollTo(0, 0);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  rejectSup() {
    this.errorMessage = [];
    this.validationMessage = null;
    this.spinnerService.start();

    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.rdoSupWorkChecked,
      rdoContFabWork: this.rdoContFabWorkChecked,
      rdoDun1FabWork: this.rdoDun1FabWorkChecked,
      taExtComment: this.taExtComment,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      FileList: '',
      taRequestChanges: this.taRequestChanges,
      taReasonRequested: this.taReasonRequested,
      rdoAsFinal: this.rdoAsFinalChecked,
      rdoTrialNo: this.rdoTrialNoChecked,
      cblRequested: this.cblRequestedFun(),
      cblReason: this.cblReasonFun(),
      PPFCode: this.paramData?.ppfcode
    }
    this.trackingListService.siBtnRejectSup(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl(this.trackingUrl)
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  reject() {
    this.errorMessage = [];
    this.validationMessage = null;
    this.spinnerService.start();

    const obj = {
      leaderFlg: this.leaderFlg,
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.rdoSupWorkChecked,
      rdoContFabWork: this.rdoContFabWorkChecked,
      rdoDun1FabWork: this.rdoDun1FabWorkChecked,
      taExtComment: this.taExtComment,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      FileList: '',
      taRequestChanges: this.taRequestChanges,
      taReasonRequested: this.taReasonRequested,
      cblRequested: this.cblRequestedFun(),
      cblReason: this.cblReasonFun(),
      PPFCode: this.paramData?.ppfcode
    }
    this.trackingListService.siBtnReject(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl(this.trackingUrl)
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  cblRequestedFun() {
    this.reqChangesChkList.forEach((ele:any) => {
      ele.Selected = ele.checked;
      ele.Value = ele.description;
    });
    return this.reqChangesChkList;
  }

  cblReasonFun() {
    this.reasonReqArr.forEach((ele: any) => {
      ele.Selected = ele.checked;
      ele.Value = ele.description;
    })
    return this.reasonReqArr;
  }

  cancel() {
    sessionStorage.removeItem('deptApprove');
    sessionStorage.removeItem('preppfRad')
    sessionStorage.removeItem('preppfReq')
    sessionStorage.removeItem('preppfRea')
    sessionStorage.removeItem('partnumber');
    this.router.navigateByUrl('tracking-list')
    sessionStorage.removeItem('siPartNum')
  }
   
  onChange(event: any, value: any) {
    this.reqChangesChkList[value].checked = event.target.checked
  }

  onReasonChange(event: any, value: any) {
    this.reasonReqArr[value].checked = event.target.checked
  }

  onRadioChange(event: any) {
    if (event.value === 0) {
      this.rdoTrialNoChecked = true
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 1) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = true
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 2) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = true
      this.createHtml_tblTrial()
    }
    const data = {
      yes: this.rdoTrialYesChecked,
      no: this.rdoTrialNoChecked,
      final: this.rdoAsFinalChecked,
    }
    sessionStorage.setItem('preppfRad', JSON.stringify(data))
  }

  PPF() {
    //ppfver = -1 for step 4 & 9
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
    if (this.ppfVer === '') {
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval/serviceparts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 2])
      } else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval/accessoryParts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 2])
      }
      else {
        sessionStorage.setItem('ppfcloseFromTracking', '0')
        window.open(`/tracking-list/sI-preppf-dept-approval/ppf/${this.paramData.ppfcode}/${this.ppfVer}/1/2`, '_blank');
        sessionStorage.setItem('ppfcloseFromTracking', '1')
      }
    } else {
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval/serviceparts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 1])
      } else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        this.router.navigate(['/tracking-list/sI-preppf-dept-approval/accessoryParts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 1])
      }
      else {
        sessionStorage.setItem('ppfcloseFromTracking', '0')
        window.open(`/tracking-list/sI-preppf-dept-approval/ppf/${this.paramData.ppfcode}/${this.ppfVer}/1/1`, '_blank');
        sessionStorage.setItem('ppfcloseFromTracking', '1')

      }
    }
  }

  conceptCompare() {
    const params = {
      ppfcode1: this.paramData?.ppfcode,
      ppfver1: this.details?.url_ver,
      ppfcode2: this.paramData?.ppfcode,
      ppfver2: -1
    }
    sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
    const data = {
      yes: this.rdoTrialYesChecked,
      no: this.rdoTrialNoChecked,
      final: this.rdoAsFinalChecked,
    }
    sessionStorage.setItem('preppfRad', JSON.stringify(data))
    sessionStorage.setItem('radioVal', JSON.stringify(this.radioVal))
    sessionStorage.setItem('preppfReq', JSON.stringify(this.reqChangesChkList))
    sessionStorage.setItem('preppfRea', JSON.stringify(this.reasonReqArr))
    this.router.navigateByUrl('/tracking-list/sI-preppf-dept-approval/concept-compare');
  }

  goToDeptAppr() {
    const params = {
      leaderPlant: this.paramData?.leadername,
      plant: this.paramData?.plantcode,
      plantName: this.paramData?.plantname,
      dock: this.tdDock1,
      modelCode: this.paramData?.modelcode,
      supplier: this.paramData?.suppliercode,
      supplierName: this.paramData?.suppliername,
      specialist: this.paramData?.specialist,
      part: this.paramData?.partnumber,
      psd: formatDate(this.paramData?.pkgstartdate, 'yyyy-MM-dd', 'en-US', 'UTC'),
      dateSent: this.dateSentVal !== '-' ? this.dateSentVal : 'null',
      mode: 1,
      step: 2,
      currentIntNo: this.details?.getIntApprove?.result[0]?.currentintno ?? 0,
      currentIntVer: this.details?.getIntApprove?.result[0]?.currentintver ?? 0,
    }
    sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
    this.router.navigateByUrl('/tracking-list/sI-preppf-dept-approval/dept-approve');
  }

  goToIntAppr() {
    this.router.navigateByUrl('/internal-approvement')
  }

  goToSelectPart() {
    this.data();
    const data = {
      yes: this.rdoTrialYesChecked,
      no: this.rdoTrialNoChecked,
      final: this.rdoAsFinalChecked,
    }
    sessionStorage.setItem('preppfRad', JSON.stringify(data))
    sessionStorage.setItem('radioVal', JSON.stringify(this.radioVal))
    sessionStorage.setItem('preppfReq', JSON.stringify(this.reqChangesChkList))
    sessionStorage.setItem('preppfRea', JSON.stringify(this.reasonReqArr))
    this.router.navigate(['/tracking-list/sI-preppf-dept-approval/select']);
  }

  data() {
    const parentdata = {
      suppliercode: this.paramData.suppliercode,
      partnumber: this.paramData.partnumber,
      fromdate: this.paramData.pkgstartdate,
      plantcode: this.paramData.plantcode,
      plantss: this.paramData.plantname,
      rn: '',
      specialist: this.paramData.specialistcode,
      isTracking: 'true',
      url: '/tracking-list/sI-preppf-dept-approval'
    }

    sessionStorage.setItem('parentData', JSON.stringify(parentdata));

  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 721])
  }
}
