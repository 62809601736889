<div class="wrapper bg-white">
  <div class="d-flex justify-content-between align-items-center mx-3">
    <p class="page-title">Connecting Part</p>
    <div>
      <div (click)="onBack()" class="help-btn">BACK</div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
    <span>{{ successMessage }}</span>
    <button type="button" class="btn-close" (click)="Close()"></button>
  </div>
  <div class="vpacs-shadow mx-2 p-2" (keyup.enter)="onSearch()">
    <div class="filter mx-1 d-flex">
      <div class="d-flex align-items-center col-lg-3 col-md-6 col-12">
        <label for="part-number" class="col-4">Part Number</label>
        <input type="text" id="part-number" [(ngModel)]="partnumber" style="width: 50%;">
      </div>
      <div class="d-flex align-items-center col-lg-3 col-md-6 col-12">
        <label for="supplier" class="col-4">Supplier</label>
        <input type="text" [(ngModel)]="Supplier" style="width: 50%;">
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <input type="button" style="height: 30px;" value="SEARCH" (click)="onSearch()" class="black-button" />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <input type="button" style="height: 30px;" value="CONNECT ALL" (click)="connectAll()" class="black-button" />
      </div>
    </div>
  </div>

  <div class="vpacs-shadow m-2 py-2 px-2">
    <div class="grid-agg grid-height-model-list-connect mt-3 mb-5">
      <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true" [gridOptions]="gridOptions"
        [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
        [headerHeight]="headerHeight" [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" [domLayout]="domLayout"
        (filterChanged)="filterChanged($event)">
      </ag-grid-angular>
      <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
      </app-pagination>
    </div>
  </div>
</div>