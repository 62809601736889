<a class="page mx-3" [routerLink]="['/manual']">USER MANUAL TOP</a>
<p class="page-title mx-3">V-Pacs Error Message List</p>

<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="grid-agg mt-3 tracking-summary">
    <ag-grid-angular
    style="width: 50%;height:400px"
      class="ag-theme-balham grid-items"
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [rowData]="data"
      [suppressCellSelection]="true"
      [headerHeight]="20"
      [floatingFiltersHeight]="40"
      [getRowStyle]="rowClass"    >
    </ag-grid-angular>

<div class="filter mx-3 legend-details d-flex gap-3 px-2">
  <div class="d-flex gap-2 align-items-center" >
      <span class="no1"></span>System Error
  </div>
  <div class="d-flex gap-2 align-items-center" >
      <span class="no2"></span>Operation Error
  </div>
  <div class="d-flex gap-2 align-items-center" >
      <span class="no3"></span>Information
  </div>
  
</div>
