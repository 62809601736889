<div class="d-flex" *ngIf ="unauthorizedFlag === false">
    <div class="d-flex flex-wrap justify-content-between align-items-center w-100 p-3">
      <div class="fs-4">Authority List</div>
      <div class="d-flex align-items-center justify-content-end">
        <button type="cancel" class="btn text-primary" (click)="cancel()">
          CANCEL
        </button>
        <button type="update" class="vpacs-btn-table" (click)="update()">
          UPDATE
        </button>
      </div>
    </div>
  </div>
  <div class="grid-agg skeleton-loader gap-3 p-2" *ngIf="loading" style="width:100%;height:60vh;">
    <div class="spinner-border text-secondary"></div>
    Loading... Please wait
  </div>
  <div class="alert alert-warning mx-2" *ngIf ="message" >
    {{message}}
  </div>
  <div class="grid-agg grid-height-auth" *ngIf ="unauthorizedFlag === false">
    <ag-grid-angular style="width:100%;height:100%;" 
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" 
      class="{{loading ? 'opacity-0 ag-theme-balham h-25' : 'ag-theme-balham'}}" 
      [rowHeight]="rowHeight" 
      [columnDefs]="columnDefs" 
      [defaultColDef]="defaultColDef"
      [animateRows]="true"
      [gridOptions]="gridOptions"
      [rowData]="displayRowData" 
      [suppressCellSelection]="true"
      [pagination]="true" 
      [paginationPageSize]="paginationPageSize"
      [suppressPaginationPanel]="true" 
      [icons]="icons" 
      [headerHeight]="40" 
      [floatingFiltersHeight]="40"
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      (paginationChanged)="onPaginationChanged()"
      (filterChanged) = "filterChanged($event)"
    ></ag-grid-angular>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf="noOfGroups > 0">
      <div>
        <div>
          <span class="page-drpdwn-label mx-2">Records/Page: </span>
          <select class="page-drpdwn" (change)="onPageSizeChanged($event)">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20" selected>20</option>
            <option value="50">50</option>
            <option value='ALL'>ALL</option>
          </select>
        </div>
      </div>
      <div class="d-flex align-items-center gap-3">
        <div>Viewing Records</div>
        <mat-icon (click)="goToFirstPage()" class="text-primary"
          [ngClass]="{
            disable_icon: getCurrentPage() === true,
            enable_icon: getCurrentPage() !== true
          }"
          >first_page
        </mat-icon>
        <mat-icon (click)="goToPreviousPage()" class="text-primary"
          [ngClass]="{
            disable_icon: getCurrentPage() === true,
            enable_icon: getCurrentPage() !== true
          }"
          >fast_rewind
        </mat-icon>
        <span class="text-center">({{ currentStartRowCount }} - {{ currentRowCount }}) OF {{ noOfGroups }}</span>
        <mat-icon (click)="goToNexpage()" class="text-primary"
          [ngClass]="{
            disable_icon: isLastPage() === true,
            enable_icon: isLastPage() !== true
          }"
          >fast_forward</mat-icon
        >
        <mat-icon (click)="goToLastPage()" class="text-primary"
          [ngClass]="{
            disable_icon: isLastPage() === true,
            enable_icon: isLastPage() !== true
          }"
          >last_page
        </mat-icon>
      </div>
    </div>
  </div>
<div class="alert alert-danger" *ngIf ="unauthorizedFlag" >
  You're not authorized to access this page
</div>
  