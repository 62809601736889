<div class="popup-container">
  <header>
    <button class="close black-button" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
  </button>
    <div class="header d-block roboto-medium">
      <label class="ms-2 mt-2 mb-3">Select Specialist</label>
    </div>
  </header>

  
    <div class="row mt-3 pt-4">
      <div class="align-items-center col d-flex">
        <label for="part" class="col-1 mx-3">Plant</label>
        <mat-select id="supplier" class="mat-sel pt-1" [(ngModel)]="plantCode" (selectionChange)="code()">
          <mat-option *ngFor="let plant of selectedPlant" [value]="plant.plantcode">
            {{plant.plant}}</mat-option>
        </mat-select>
      </div>

      <div class="align-items-center col d-flex">
        <label for="part" class="col-3">Specialist</label>
        <mat-select id="supplier" class="mat-sel pt-1 mat-box " [(ngModel)]="codeValue">
          <mat-option *ngFor="let plant of specialistCodeValue" 
          [value]="plant.specialistcode" >{{plant.firstname}}.{{plant.lastname}}</mat-option>
        </mat-select>
      </div>
    

    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-1 pt-2 pb-2">
      <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
      <button class="submit black-button me-3 roboto-bold" (click)="ok()">OK</button>
    </div>
  </div>

</div>

