<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Temporary Container</p>
    <div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div class="alert alert-success mx-2" *ngIf="showSuccess">
    {{successMessage}}
</div>
<div class="accordion-container m-3">
    <mat-accordion hideToggle multi>
        <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">PART INFORMATION</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row m-3 content-row ms-0">
                <div class="col-md-4 align-items-center">
                    <div class="row">
                        <div class="col-md-6 titleColumn">
                            <div class="contents ps-2 py-2">Plant</div>
                            <div class="contents ps-2 py-2">Family Code</div>
                            <div class="contents ps-2 py-2">Model Code</div>
                            <div class="contents ps-2 py-2">Part Number</div>
                            <div class="contents ps-2 py-2">Supplier</div>
                            <div class="contents ps-2 py-2">Status</div>
                        </div>
                        <div class="col-md-6 contentColumn p-0">
                            <div class="ps-3 py-2 row-even">{{backupData?.plantName ?? '-'}}</div>
                            <div class="ps-3 py-2 row-odd">{{backupData?.familyCode ?? '-'}}</div>
                            <div class="ps-3 py-2 row-even">{{backupData?.modelCode ?? '-'}}</div>
                            <div class="ps-3 py-2 row-odd">{{backupData?.partNumber ?? '-'}}</div>
                            <div class="ps-3 py-2 row-even">{{backupData?.supplierName ?? '-'}}
                            </div>
                            <div class="ps-3 py-2 row-odd">
                                {{backUpResult?.approvalstatus?getResult(backUpResult?.approvalstatus):'-'}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3" style="width:160px">
                    <strong>Image</strong>
                    <div class="align-items-center d-flex justify-content-center image-wrapper mt-3" >
                        <img src="{{convertedImg}}" alt="part image">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <div class="row m-0 mb-5 pb-4">
            <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
                (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
                <mat-expansion-panel-header class="header-container me-3">
                    <mat-panel-title class="roboto-bold me-0">BACKUP PACKAGE SPECIFICATION</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3 d-flex me-3">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Container
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.contcode?backUpResult?.contcode:'-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{backUpResult?.contl}} W - {{backUpResult?.contw}} H - {{backUpResult?.conth}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents  border-bottom">
                                        {{backUpResult?.contweight?backUpResult?.contweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.contprice?(backUpResult?.contprice*rate):'-'}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">QPC</div>
                                    <div class="col-6 contentColumn subContents border-bottom">
                                        {{backUpResult?.qpc?backUpResult?.qpc:'-'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row me-3">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Dunnage
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.duncode?backUpResult?.duncode:'-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{backUpResult?.dunl}} W - {{backUpResult?.dunw}} H - {{backUpResult?.dunh}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents  border-bottom">
                                        {{backUpResult?.dunweight?backUpResult?.dunweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{(backUpResult?.dunprice)?(backUpResult?.dunprice*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row me-3">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Top cap
                    </div>
                    <div class="col-md-3 titleColumn subHeads border-bottom">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.lidcode?backUpResult?.lidcode:'-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{backUpResult?.lidl}} W - {{backUpResult?.lidw}} H - {{backUpResult?.lidh}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents  border-bottom">
                                        {{backUpResult?.lidweight?backUpResult?.lidweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.lidprice?(backUpResult?.lidprice*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row me-3">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Pallet
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.pltcode?backUpResult?.pltcode:'-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{backUpResult?.pltl}} W - {{backUpResult?.pltw}} H - {{backUpResult?.plth}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents border-bottom">
                                        {{backUpResult?.pltweight?backUpResult?.pltweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{backUpResult?.pltprice?(backUpResult?.pltprice*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row me-3">
                    <div class="col-md-5 titleColumn d-flex justify-content-center align-items-center">
                        {{ backupData.userUnit === '0' ?  'Container GROSS(INCLUDE PARTS)(kg)':'Container GROSS(INCLUDE PARTS)(lb)'}}
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{res?.BackupGrossTotal?res?.BackupGrossTotal:'-'}}</div>
                        </div>
                        </div>
                    </div>

            </mat-expansion-panel>

            <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
                (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
                <mat-expansion-panel-header class="header-container ms-3">
                    <mat-panel-title class="roboto-bold me-0">PPF SPECIFICATION</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3 d-flex ms-3 me-0">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Container
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.containercode?ppfResult?.containercode:'-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{ppfResult?.contl}} W - {{ppfResult?.contw}} H - {{ppfResult?.conth}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents border-bottom">
                                        {{ppfResult?.contweight?ppfResult?.contweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom">
                                {{ppfResult?.concpp?(ppfResult?.concpp*rate):'-'}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">QPC</div>
                                    <div class="col-6 contentColumn subContents">{{ppfResult?.qpc?ppfResult?.qpc:'-'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ms-3 me-0">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Dunnage
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.dunnage1code?ppfResult?.dunnage1code:'-'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{ppfResult?.dunl}} W - {{ppfResult?.dunw}} H - {{ppfResult?.dunh}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents border-bottom">
                                        {{ppfResult?.dunweight?ppfResult?.dunweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.dunprice?(ppfResult?.dunprice*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ms-3 me-0">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Top cap
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.topcapcode?ppfResult?.topcapcode:'-'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{ppfResult?.lidl}} W - {{ppfResult?.lidw}} H - {{ppfResult?.lidh}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents border-bottom">
                                        {{ppfResult?.lidweight?ppfResult?.lidweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.lidcpp?(ppfResult?.lidcpp*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ms-3 me-0">
                    <div class="col-md-2 titleColumn d-flex justify-content-center align-items-center">
                        Pallet
                    </div>
                    <div class="col-md-3 titleColumn subHeads">
                        <div class="border-bottom">Package code</div>
                        <div class="border-bottom" style="height:40px">{{ backupData.userUnit === '0' ? 'LWH(mm)' :
                            'LWH(Inch)' }}</div>
                        <div class="border-bottom">Estimated Cost/ Package piece$(US)</div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.palletcode?ppfResult?.palletcode:'-'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 contentColumn subContents border-bottom" style="height:40px">
                                L - {{ppfResult?.pltl}} W - {{ppfResult?.pltw}} H - {{ppfResult?.plth}}
                            </div>
                            <div class="col-6">
                                <div class="row border-bottom" style="height:40px">
                                    <div class="col-6 titleColumn subHeads">{{ backupData.userUnit === '0' ?
                                        'Weight(kg)' : 'Weight(lb)' }}</div>
                                    <div class="col-6 contentColumn subContents  border-bottom" style="height:40px">
                                        {{ppfResult?.pltweight?ppfResult?.pltweight:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{ppfResult?.pltcpp?(ppfResult?.pltcpp*rate):'-'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ms-3 me-0">
                    <div class="col-md-5 titleColumn d-flex justify-content-center align-items-center">
                        {{ backupData.userUnit === '0' ?  'Container GROSS(INCLUDE PARTS)(kg)':'Container GROSS(INCLUDE PARTS)(lb)'}}
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 contentColumn subContents border-bottom" style="height:40px">
                                {{res?.PPFGrossTotal?res?.PPFGrossTotal:'-'}}</div>
                        </div>
                        </div>
                    </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>

</div>
<div class="m-3"  *ngIf="requested">
    <mat-accordion hideToggle multi>
        <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
            (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">Requested Changes</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-3">
                <div class="col-6">
                    <div *ngFor="let req of reqChangesArr">
                        <input name="reqChange" type="checkbox" [value]="req.code" [(ngModel)]="req.checked">
                        <label for="">{{req.description?req.description:'-'}}</label>
                    </div>
                </div>
                <div class="col-6">
                    <textarea name="reqChangeText" [(ngModel)]="reqChangeText" cols="30" rows="4"></textarea>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="m-3 pb-5 mb-5" *ngIf="reason">
    <mat-accordion hideToggle multi>
        <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0 col-6 p-0"
            (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="roboto-bold me-0">Reason Requested</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-3">
                <div class="col-6">
                    <div *ngFor="let reason of reasonReqArr">
                        <input name="reason" type="checkbox" [value]="reason.code" [(ngModel)]="reason.checked">
                        <label for="">{{reason.description?reason.description:'-'}}</label>
                    </div>
                </div>
                <div class="col-6">
                    <textarea name="reqChangeText" [(ngModel)]="reasonText" cols="30" rows="4"></textarea>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</div>
<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="cancel()"> BACK </button>
    <button class="black-button me-3 roboto-medium" *ngIf="approveVisible" (click)="approve('A')"> APPROVE </button>
    <button class="black-button me-3 roboto-medium" *ngIf="rejectVisible" (click)="approve('R')">Reject</button>
</div>