<div>
  <div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">DIVISION MASTER</p>
    <div>
      <button class="black-button" style="height: 30px" (click)="add()" [disabled]="addflag">
        ADD
      </button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
    </div>
  </div>
  <div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
    <span>{{ successMessage }}</span>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
  </div>
  <div class="" *ngIf="haveAccess">
    <div class="col-6">
      <div class="grid-agg grid-h grid-height-division-master mb-5">
        <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%; height: 100%"
          (gridReady)="onGridReady($event)" (window:resize)="sizeToFit()" [animateRows]="true"
          [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
          [suppressCellSelection]="true" [icons]="icons" [headerHeight]="40"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate" [rowSelection]="rowSelection" [rowClassRules]="rowClassRules"
          [paginationPageSize]="rpPage" [suppressPaginationPanel]="true" [pagination]="true"
          [floatingFiltersHeight]="40" (filterChanged)="onFilterChanged($event)" [suppressHorizontalScroll]="true">
        </ag-grid-angular>
        <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
          (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
        </app-pagination>
      </div>
    </div>
  </div>
</div>