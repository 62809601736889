<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Release Follower</label>
        </div>
    </header>

    <div class="popup-contents">
        <div class="top-container">
            <div class="row mt-3 ms-3 me-3 heading-row">
                <div class="col-2 ps-0 pe-0">
                    <label> Model </label>
                     <input type="text"style="width:100px"  [(ngModel)]="model" readonly>
                </div>
                <div class="col-2 ps-0 pe-0">
                    <label> Plant </label>
                    <input type="text"  [(ngModel)]="plant" readonly>

                </div>
                <div class="col-3 pe-0">
                    <label> Part Number </label>
                    <input type="text"  [(ngModel)]="part" readonly>

                </div>
                <div class="col-2">
                    <label> Supplier </label>
                    <input type="text"  [(ngModel)]="supplier" readonly>

                </div>
                <div class="col-3">
                    <label> Package Start Date </label>
                    <input type="text"  [(ngModel)]="pgkdate" readonly>

                </div>
            </div>
        </div>
    </div>

    
<div class="vpacs-shadow m-3 ">
    <div class="grid-agg px-0">
        <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height: 200px"
            (gridReady)="onGridReady($event)" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
            [headerHeight]="headerHeight" [suppressCellSelection]="true"
            [rowSelection]="rowSelection" [rowClassRules]="rowClassRules">
        </ag-grid-angular>
    </div>
</div>

</div>