import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-update-btn-cell-render',
  template: `
    <button 
    class="vpacs-btn-table"
    style="height:30px;"
    (click)="btnClickedHandler($event)" [disabled]="!editAccess">UPDATE</button>
  `,
})

export class UpdateBtnCellRender implements ICellRendererAngularComp {
editAccess:any

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;
    this.editAccess=this.params.editFlag? this.params?.editFlag: this.params?.data?.editflag;
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




