import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-quantity-btn',
  template: `
    <span 
      *ngIf="!this.detailFlag && !this.logFlag && params.value" 
      (click)="btnClickedHandler()"
      class="text-primary"
      style="cursor:pointer"
    >{{params.value}}
    </span>
    <span 
      *ngIf="!this.detailFlag && !this.logFlag && !params.value" 
      class="text-secondary"
    >-</span>
    <button 
        *ngIf = "this.detailFlag"
        class="vpacs-btn-table"
        style="height:30px"
        (click)="btnClickedHandler()"
    >DETAIL</button>
    <button 
        *ngIf = "this.logFlag"
        class="vpacs-btn-table"
        style="height:30px"
        (click)="btnClickedHandler()"
    >LOG</button>
  `,
})

export class QuantityButtomComponent implements ICellRendererAngularComp {

  public params: any;
    detailFlag = false;
    logFlag= false;

    
  refresh(params: ICellRendererParams): boolean {
	throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }
 
  agInit(params: any): void {
    this.params = params; 
    if(params.column.colId === 'detail' && this.params.data.dunnage2code !== 'TOTAL'){
        this.detailFlag = true;
    }else if(params.column.colId === 'log' && this.params.data.dunnage2code !== 'TOTAL'){
        this.logFlag = true;
    }
  }

  btnClickedHandler() {
    this.params.clicked({...this.params.data,colid:this.params?.column.colId,rowindex:this.params?.node.rowIndex});
  }

}