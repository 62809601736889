<div>
  <div class="align-self-center ps-0 mt-3">
    <h5>Supplier Questionnaire</h5>
  </div>
  <div class="d-flex align-self-center titleColumn">
    <h6 class="mb-0 py-2 ps-3">Reply to Toyota</h6>
  </div>
  <div class="align-items-center questionnaire-container ps-0">
    <table class="table" aria-describedby="part-data" *ngIf="displaytrQuestionnaire">
      <thead>
        <tr class="titleColumn">
          <th scope="col" class="ps-3">PART DATA EVALUATION</th>
          <th scope="col" class="ps-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="contentColumn">
          <td class="ps-3 w-75">
            1. Is part number and description correct?
            <br />
            If not, please enter the correct part number or description.
          </td>
          <td>
            <table aria-describedby="q1">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <td class="d-flex">
                    <input value="2" name="rdoYN_1_1" type="radio" class="me-3" id="rdoYes_1_1"
                      [checked]="rdoYes11Checked" disabled />
                    Yes
                    <input value="2" name="rdoYN_1_1" type="radio" id="rdoNo_1_1" [checked]="rdoNo11Checked"
                      class="ms-5 me-3" disabled />
                    No
                  </td>
                </tr>
                <tr>
                  <td>
                    <input name="txtNote_1_1" type="text" id="txtNote_1_1" disabled [(ngModel)]="txtNote11Value" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">
            2. Is this the correct supplier code?
            <br />
            If not, please identify the correct code if possible.
          </td>
          <td>
            <table aria-describedby="q2">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <td class="d-flex">
                    <input value="2" name="rdoYN_1_2" type="radio" class="me-3" id="rdoYes_1_2"
                      [checked]="rdoYes12Checked" disabled />
                    Yes
                    <input value="2" name="rdoYN_1_2" type="radio" id="rdoNo_1_2" class="ms-5 me-3" disabled
                      [checked]="rdoNo12Checked" />
                    No
                  </td>
                </tr>
                <tr>
                  <td>
                    <input name="txtNote_1_2" type="text" id="txtNote_1_2" disabled [(ngModel)]="txtNote12Value" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">3. Part data availability date.</td>
          <td>
            <table aria-describedby="q3">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <input type="text" disabled *ngIf="q13date" [(ngModel)]="q13date"/>
                  <input type="date" disabled *ngIf="!q13date"/>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">4. Sample part availability date.</td>
          <td>
            <table aria-describedby="q4">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <input type="text" disabled *ngIf="q14date" [(ngModel)]="q14date"/>
                  <input type="date" disabled *ngIf="!q14date"/>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table mt-3" aria-describedby="pkg-evaluation" *ngIf="displaytrQuestionnaire">
      <thead>
        <tr class="titleColumn">
          <th scope="col" class="ps-3">PACKAGE EVALUATION</th>
          <th scope="col" class="ps-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="contentColumn">
          <td class="ps-3 w-75">1. Expected concept evaluation date.</td>
          <td>
            <table aria-describedby="q11">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <input type="text" disabled *ngIf="q21date" [(ngModel)]="q21date"/>
                  <input type="date" disabled *ngIf="!q21date"/>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">2. Evaluation method.</td>
          <td>
            <mat-select class="mat-sel" name="sel_2_2" id="sel_2_2" size="1" style="width: 100%;" disabled
              [(ngModel)]="sel22SelectedIndex">
              <mat-option *ngFor="let opt of sel22Items" [value]="opt.index">
                {{ opt.value }}
              </mat-option>
            </mat-select>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">3. Concept evaluation.</td>
          <td>
            <mat-select class="mat-sel" name="sel_2_3" id="sel_2_3" size="1" style="width: 100%;" disabled
              [(ngModel)]="sel23SelectedIndex">
              <mat-option *ngFor="let opt of sel23Items" [value]="opt.index">
                {{ opt.value }}
              </mat-option>
            </mat-select>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">4. Reason for modification or new proposal?</td>
          <td>
            <mat-select class="mat-sel" name="sel_2_4" id="sel_2_4" size="1" style="width: 100%;" disabled
              [(ngModel)]="sel24SelectedIndex">
              <mat-option *ngFor="let opt of sel24Items" [value]="opt.index">
                {{ opt.value }}
              </mat-option>
            </mat-select>
            <br />
            <input name="txtNote_2_4" type="text" id="txtNote_2_4" class="mt-2" disabled
              [(ngModel)]="txtNote24Value" />
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">
            5. If modification or new concept is needed, can another exact
            Toyota
            <br />
            packaging design be used?
          </td>
          <td>
            <table aria-describedby="q55">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <td>
                    <input value="2" name="rdoYN_2_5" type="radio" class="me-3" id="rdoYes_2_5"
                      [checked]="rdoYes25Checked" disabled />
                    Yes
                  </td>
                  <td>
                    <input value="2" name="rdoYN_2_5" type="radio" id="rdoNo_2_5" [checked]="rdoNo25Checked" disabled
                      class="ms-5 me-3" />
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">
        <tr>6. Was the above part copied into the PPF?</tr>
        <br />
        <tr class="d-flex ps-3">Part Number</tr>
        <br />
        <tr class="d-flex ps-3">Model Code</tr>
        </td>
        <td>
          <table aria-describedby="q66">
            <th id="empty"></th>
            <tbody>
              <tr>
                <td class="d-flex">
                  <input value="2" name="rdoYN_2_6" type="radio" class="me-3" id="rdoYes_2_6"
                    [checked]="rdoYes26Checked" disabled />
                  Yes
                  <input value="2" name="rdoYN_2_6" type="radio" id="rdoNo_2_6" [checked]="rdoNo26Checked"
                    class="ms-5 me-3" disabled />
                  No
                </td>
              </tr>
              <tr>
                <td>
                  <input name="txtPart_2_6" type="text" id="txtPart_2_6" class="mt-2" disabled
                    [(ngModel)]="txtPart26Value" />
                </td>
              </tr>
              <tr>
                <td>
                  <input name="txtModel_2_6" type="text" id="txtModel_2_6" class="mt-2" disabled
                    [(ngModel)]="txtModel26Value" />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        </tr>
        <tr class="contentColumn">
          <td class="ps-3">
            7. Based on the revised proposal, is a newdunnage design needed?
          </td>
          <td>
            <table aria-describedby="q77">
              <th id="empty"></th>
              <tbody>
                <tr>
                  <td>
                    <input value="2" name="rdoYN_2_7" type="radio" class="me-3" id="rdoYes_2_7"
                      [checked]="rdoYes27Checked" disabled />
                    Yes
                  </td>
                  <td>
                    <input value="2" name="rdoYN_2_7" type="radio" id="rdoNo_2_7" [checked]="rdoNo27Checked"
                      class="ms-5 me-3" disabled />
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

      </tbody>
    </table>
    <table class="table mt-3" aria-describedby="pkg-evaluation" *ngIf="trSupplierConfirmDisplay">
      <thead>
        <tr class="titleColumn">
          <th scope="col" class="ps-3">SUPPLIER CONFIRM</th>
          <th scope="col" class="ps-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="contentColumn" id="trSupplierConfirm">
          <td class="ps-3 fw-500">
            I have tested this container.<br />
            I have verified that all part and packaging specifications are correct.
          </td>
          <td>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group d-flex p-0"
              name="rdoYN_test">
              <mat-radio-button class="example-radio-button ps-4 py-2 align-items-center d-flex" [value]="1"
                [checked]="rdoYesTestChecked" [disabled]="true" (change)="onRadioChange($event)">
                Yes
              </mat-radio-button>
              <mat-radio-button class="example-radio-button ps-4 py-2 align-items-center d-flex" [value]="0"
                [checked]="rdoNoTestChecked" [disabled]="true" (change)="onRadioChange($event)">
                No
              </mat-radio-button>
            </mat-radio-group>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table mt-3" aria-describedby="pkg-evaluation" *ngIf="displaytrQuestionnaireComment">
      <thead>
        <tr class="titleColumn">
          <th scope="col" class="ps-3">COMMENT</th>
          <th scope="col" class="ps-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="contentColumn" id="trQuestionnaireComment">
          <td>
            <textarea rows="3"  [(ngModel)]="taNoteValue"  disabled></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="align-items-center ps-0">
    <div class="d-flex align-items-baseline ps-0" *ngIf="tblRequestReasonDisplay">
      <table class="table" aria-describedby="reqChange">
        <thead>
          <tr class="titleColumn">
            <th scope="col" class="ps-4">REQUESTED CHANGES</th>
          </tr>
        </thead>
        <tbody class="d-flex">
          <div class="col-md-4 titleColumn">
            <div *ngFor="let req of reqChangesChkList; let i = index" class="contents ps-2 py-2">
              <label class="checkcontainer">
                {{ req.description }}
                <input type="checkbox" [checked]="req.checked === true" (change)="onChange($event, i)"
                  [disabled]="cblRequested" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="col-md-8 contentColumn p-0 d-flex justify-content-center align-items-center">
            <textarea id="taRequestChanges" cols="170" rows="3" class="form-control detail_area w-90 h-90"
              name="taRequestChanges" [(ngModel)]="taRequestChanges" [disabled]="taRequestChangesDisabled"></textarea>
          </div>
        </tbody>
      </table>
    </div>
    <div class=" d-flex align-items-baseline ps-0" *ngIf="tblRequestReasonDisplay">
      <table class="table" aria-describedby="reqChange">
        <thead>
          <tr class="titleColumn">
            <th scope="col" class="ps-4">REASON REQUESTED</th>
          </tr>
        </thead>
        <tbody class="d-flex">
          <div class="col-md-4 titleColumn">
            <div *ngFor="let req of reasonReqArr; let i = index" class="contents ps-2 py-2">
              <label class="checkcontainer">
                {{ req.description }}
                <input type="checkbox" [checked]="req.checked === true" (change)="onReasonChange($event, i)"
                  [disabled]="cblReason" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="col-md-68 contentColumn p-0 d-flex justify-content-center align-items-center">
            <textarea id="taReasonRequested" cols="170" rows="3" class="form-control detail_area w-90 h-90"
              name="taReasonRequested" [(ngModel)]="taReasonRequested" [disabled]="taReasonRequestedDisabled"></textarea>
          </div>
        </tbody>
      </table>
    </div>
  </div>
  <div class="align-items-center ps-0" *ngIf="tblTrialDisplay">
    <table class="table" aria-describedby="trial">
      <thead class="d-none">
        <tr class="titleColumn">
          <th scope="col" class="ps-4">Trial</th>
        </tr>
      </thead>
      <tbody class="d-flex trial">
        <div class="col-md-4 titleColumn">
          <div
            *ngFor="let title of trial"
            class="contents ps-2 py-2 d-flex align-items-center"
          >
            {{ title }}
          </div>
        </div>
        <div class="col-md-8 contentColumn p-0">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group d-flex p-0"
            name="trial"
          >
            <mat-radio-button
              class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
              [value]="1"
              [checked]="rdoTrialYesChecked"
              [disabled]="rdoTrialYesDisabled"
              (change)="onRadioChange($event)"
            >
              Yes
            </mat-radio-button>
            <mat-radio-button
              class="example-radio-button ps-4 py-2 align-items-center d-flex"
              [value]="0"
              [checked]="rdoTrialNoChecked"
              [disabled]="rdoTrialNoDisabled"
              (change)="onRadioChange($event)"
            >
              No
            </mat-radio-button>
            <mat-radio-button
              class="example-radio-button ps-4 py-2 align-items-center d-flex"
              [value]="2"
              [checked]="rdoAsFinalChecked"
              [disabled]="rdoAsFinalDisabled"
              (change)="onRadioChange($event)"
            >
              Approve as Final
            </mat-radio-button>
          </mat-radio-group>
          <div class="row-odd d-flex">
            <input
              type="text"
              class="ps-4"
              id="txtParentPart"
              maxlength='10'
              [(ngModel)]="txtParentPart"
              [disabled]="txtParentPartDisabled"
            />
            <button
              class="vpacs-btn-table ms-3"
              style="height: 30px; font-size: 11px;"
              [disabled]="btnParentPartDisabled"
              (click)="goToSelectPart()"
            >
              SELECT
            </button>
          </div>
          <div class="row-even d-flex">
            <textarea
              id="taTriFinalReason"
              cols="170"
              rows="3"
              class="form-control detail_area w-90 h-90"
              name="taTriFinalReason"
              [(ngModel)]="taTriFinalReason"
              [disabled]="taTriFinalReasonDisabled"
            ></textarea>
          </div>
        </div>
      </tbody>
    </table>
  </div>
</div>