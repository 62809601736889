
<div class="popup-container">
    <header>
        <div class="d-flex justify-content-between align-items-center header">
            <p class="page-title mx-0 px-0">Cost Center Maintenance</p>   
            <div>
                <button class="submit black-button mx-2 " style="height: 30px;" (click)="dialogOpen()"  [disabled]="!haveAddAccess">ADD</button>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>
    <div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
        <span>{{ successMessage }}</span>
        <button type="button" class="btn-close" (click)="Close()"></button>
    </div>
    <div class="popup-contents">
        <div class="vpacs-shadow m-2 py-2 px-2">
            <div class="row mx-2 mb-3 align-items-start">
                <div class="d-grid col-lg-4 col-md-12 col-12 my-2">Plant Code<span class="text-black"><b>{{plantResult?.plantcode}}</b></span></div>
                <div class="d-grid col-lg-4 col-md-12 col-12 my-2">Plant Name<span class="text-black"><b>{{plantResult?.name}}</b></span></div>
                <div class="d-grid col-lg-4 col-md-12 col-12 my-2">Plant ID<span class="text-black"><b>{{plantResult?.plantid}}</b></span></div>
            </div>
        </div>
        <div class="vpacs-shadow m-2 py-2 px-2">
            <div class="grid-agg tracking-summary">
                <ag-grid-angular style="width:100%;height:35vh;" 
                    class="ag-theme-balham" 
                    (gridReady)="onGridReady($event);"
                    (window:resize)="sizeToFit()" 
                    [rowHeight]="50" 
                    [animateRows]="true" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs" 
                    [rowData]="displayRowData" 
                    [suppressCellSelection]="true"
                    [suppressHorizontalScroll]="false" 
                    [pagination]="false" 
                    [suppressPaginationPanel]="true" 
                    [icons]="icons" 
                    [floatingFiltersHeight]="40"
                    [overlayLoadingTemplate]="overlayLoadingTemplate" 
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [domLayout]="domLayout" 
                    >
                </ag-grid-angular>        
            </div>
            <button type="button" class="close black-button" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>    
        </div>
    </div>
</div>
