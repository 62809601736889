<div class="popup-container">
    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)"  >
        
    <div class="header text-middle"> {{data.mode}} Package Cost</div>
      
    <div class="popup-contents m-2 py-2 px-2" >
        <div >
            <table aria-describedby="add-package">
                <tr class="d-none">
                    <th>add-package</th>
                  </tr>
                <tr>
                    <td class="tab1">Package Code</td>
                    <td>{{package}}</td>
                </tr>
                <tr>
                    <td class="tab1">Package Fabricator<small >*</small></td>
                    <td><mat-select id="plant" class="mat-sel pt-2" name="fab1"   [(ngModel)]="fab"   required>
                        <mat-option *ngFor="let fab of selPkgCode" [value]="fab" >{{fab}}</mat-option>
                    </mat-select>
                    <div class="errormsg" *ngIf="(submitFlag || contactForm.controls['fab1']?.dirty || contactForm.controls['fab1']?.touched) && contactForm.controls['fab1']?.invalid " >Package Fabricator is required</div>
                </td>
                </tr>
                <tr>
                    <td class="tab1">Plant Type</td>
                    <td>{{typeCode}}</td>
                </tr>
                <tr>
                    <td class="tab1">From Date<small >*</small></td>
                    <td><input type="date" class="col-3" name="fromDate1" id="fromDate"   [(ngModel)]="fromDate"  (keydown)="onKeyDown($event,'fromDate')" required >
                        <div class="errormsg" *ngIf="(submitFlag || contactForm.controls['fromDate1']?.dirty || contactForm.controls['fromDate1']?.touched) && contactForm.controls['fromDate1']?.invalid " >From Date is required</div>
                    </td>
                    
                </tr>                
                <tr>
                    <td class="tab1">Cost<small >*</small></td>
                    <td><input type="text" class="col-3" name="costRate1"   [(ngModel)]="costRate"   required  (change)="change($event)">
                        <div class="errormsg" *ngIf="( submitFlag || contactForm.controls['costRate1']?.dirty || contactForm.controls['costRate1']?.touched) && contactForm.controls['costRate1']?.invalid " >Cost is required</div>
                        <div class="errormsg" *ngIf="numberFlag">please provide number</div> 
                    </td>
                </tr>
                <tr>
                    <td class="tab1">Monetary Unit<small >*</small></td>
                    <td><mat-select id="plant" class="mat-sel pt-2" name='unitValue1'   [(ngModel)]="unitValue" required >
                        <mat-option *ngFor="let cost of costs" [value]="cost.Id">{{cost.Name}}</mat-option>
                    </mat-select>
                <div class="errormsg" *ngIf="(submitFlag || contactForm.controls['unitValue1']?.dirty || contactForm.controls['unitValue1']?.touched) && contactForm.controls['unitValue1']?.invalid " >Monetary is required</div>

                </td>

                </tr>
            </table>
        </div>


        
    </div>
    <button type="button" class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
        <button type="button" class="cancel me-3 roboto-bold" (click)="closeDialog()"> CANCEL </button>
        <button type="submit" class="submit black-button me-3 roboto-bold"  >{{data.mode}}</button>
    </div>
</form>
</div>
