import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'package-type-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
      class="vpacs-btn-table"
      style="width:85px; height:30px;"
      (click)="btnClickedHandler()"
      [disabled]="this.updateFlag || this.deleteFlag" 
      class={{disableBtn}}
      >
      {{params.colDef.headerName}}</button>
  `,
  styles: [
    '.btn-disable { opacity: 0.5}',
    '.btn-enable { opacity: 1}'
  ]
})

export class PackageTypeBtnCellRender implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  public params: any;
  updateFlag  = false;
  deleteFlag  = false;
  btnDisable = 'btn-disable';
  btnEnable = 'btn-enable';
  disableBtn = 'btn-enable';

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'UPDATE'){
      this.updateFlag = !params.data.modifyAccess ? true : false;
      if(this.updateFlag) {
        this.disableBtn = this.btnDisable;
      } else {
        this.disableBtn = this.btnEnable;
      }
    }
    else if (params.colDef.headerName === 'DELETE'){
      const used = this.params.data.used ? parseInt(this.params.data.used) : 0;
      this.deleteFlag = (used > 0 || !params.data.deleteAccess) ? true : false;
      if(this.deleteFlag) {
        this.disableBtn = this.btnDisable;
      } else {
        this.disableBtn = this.btnEnable;
      }
    }   
  }

  btnClickedHandler() {
    this.params.clicked(this.params.column.colId, this.params.data);
  }
}
