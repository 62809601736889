<div class="ppf" id="print">
  <div class="top-control-buttons gap-2 pe-3" id="control-bar">
    <button class="btn btn-link" (click)="navigatePrevious()">CLOSE</button>
    <button *ngIf="!readOnly" class="btn btn-link" (click)="selectPart()">
      COPY
    </button>
    <button *ngIf="!readOnly" (click)="savePPF()" class="vpacs-btn-table">
      SAVE
    </button>
    <button class="vpacs-btn-table" (click)="printPPF()">PRINT</button>
  </div>
  <div id="specialerr" class="alert alert-danger m-3" *ngIf="specialCharError.length">
    <div *ngFor="let x of specialCharError">
        {{ x }}<br>
    </div>
  </div>
  <div class="ppf-content m-3" id="PPF" #PPF="" [formGroup]="ppfForm">
    <div class="container-list d-flex flex-wrap">
      <div class="card1 col-lg-3 col-md-6 col-6">
        <div
          class="
            card-head
            d-flex
            justify-content-center
            align-items-center
            fs-4
            border-bottom
          "
        >
          {{ isMazda ? "Mazda" : "TOYOTA" }}
        </div>
        <div class="d-flex flex-wrap list">
          <span class="col-5 px-2" title="Supplier Name:">Supplier Name:</span>
          <span class="col-7 px-2" title="{{ ppfData?.SUPPLIERNAME }}">{{
            ppfData?.SUPPLIERNAME
          }}</span>
          <span class="col-5 px-2" title="Supplier Code:">Supplier Code:</span>
          <span class="col-7 px-2" title="{{ ppfData?.SUPPLIERCODE }}">{{
            ppfData?.SUPPLIERCODE
          }}</span>
          <span class="col-5 px-2" title="Supplier Pickup Location:"
            >Supplier Pickup Location:</span
          >
          <span class="col-7 px-2" title="{{ ppfData?.SUPPLIERPICKUP }}">{{
            ppfData?.SUPPLIERPICKUP
          }}</span>
          <span class="col-5 px-2" title="Tlms code:">Tlms code:</span>
          <span class="col-7 px-2" title="{{ ppfData?.TLMS_CODE }}">{{
            ppfData?.TLMS_CODE
          }}</span>
        </div>
        <div class="d-flex img-upload">
          <span class="col-1 d-flex align-items-center justify-content-center" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">Digital Image</span>
          <div class="col-11">
            <h5 class="py-2">PART ( IN PACKAGING DIRECTION)</h5>
            <div class="img-cont col d-flex px-3 gap-2">
              <div class="left col">
                <div class="img-preview">
                  <div
                    *ngIf="!image[0]"
                    class="
                      d-flex
                      h-100
                      align-items-center
                      justify-content-center
                    "
                  >
                    <mat-icon class="text-secondary">panorama</mat-icon>
                  </div>
                  <img *ngIf="image[0]" [src]="image[0]" alt="partImage" />
                </div>
                <div
                  class="d-flex align-items-center my-2 justify-content-between"
                >
                  <label
                    *ngIf="!readOnly && !this.editFlagTwo"
                    for="file-upload1"
                    class="vpacs-btn-table col-10"
                    (change)="handleUpload($event, 1)"
                  >
                    CHOOSE FILE
                    <input
                      type="file"
                      id="file-upload1"
                      style="display: none"
                    />
                  </label>
                  <img
                    src="../../../../assets/images/noun_edit_1570019.svg"
                    class="col-2"
                    *ngIf="!readOnly && !this.editFlagTwo"
                    alt="D"
                    class="vpacs-btn-table"
                    (click)="handleDelete(1)"
                  />
                </div>
              </div>
            </div>
            <div
              class="text-secondary px-4 subtitle"
              *ngIf="!readOnly && !formatError[0]"
            >
              Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
            </div>
            <div
              class="text-danger px-4 subtitle"
              *ngIf="!readOnly && formatError[0]"
            >
              {{ formatError[0] }}
            </div>
          </div>
        </div>
        <div class="cont1-radio d-flex p-2 ps-5">
          <span class="pe-4">HazMat : </span>
          <span *ngIf="readOnly">{{ ppfForm.controls.hazmat.value }}</span>
          <div class="d-flex align-items-center" *ngIf="!readOnly && (!this.editFlagTwo || this.editMode === 2)">
            <input
              type="radio"
              value="Y"
              class="mx-1"
              formControlName="hazmat"
            />Yes
            <input
              type="radio"
              value="N"
              class="mx-1"
              formControlName="hazmat"
            />No
          </div>
        </div>
      </div>
      <div class="card2 col-lg-6 col-md-12 col-12">
        <div class="card-head d-flex text-center border-bottom">
          <span class="col-6 fs-4 zone">{{ ppfData?.PLANTNAME }}</span>
          <div class="col-6 py-2">
            <div class="fs-6">PACKAGING PROPOSAL</div>
            <span class="tmna">TOYOTA Engineering Manufacturing America</span>
          </div>
        </div>
        <div class="sub-cards col d-flex">
          <div class="subcard-1 col-6 pe-1">
            <div class="d-flex flex-wrap list">
              <span class="col-5 px-2" title="Packaging Contact"
                >Packaging Contact:</span
              >
              <span class="col-7 px-2" title="{{ ppfData?.SUPPLIERCONTACT }}">{{
                ppfData?.SUPPLIERCONTACT
              }}</span>
              <span class="col-5 px-2" title="Phone Number">Phone Number:</span>
              <span
                class="col-7 px-2"
                title="{{ ppfData?.SUPPLIERPHONENUMBER }}"
                >{{ ppfData?.SUPPLIERPHONENUMBER }}</span
              >
              <span class="col-5 px-2" title="Fax Number">Fax Number:</span>
              <span
                class="col-7 px-2"
                title="{{ ppfData?.SUPPLIERFAXNUMBER }}"
                >{{ ppfData?.SUPPLIERFAXNUMBER }}</span
              >
              <span class="col-5 px-2" title="Email Address"
                >E-Mail Address:</span
              >
              <span class="col-7 px-2" title="{{ ppfData?.SUPPLIERMAIL }}">{{
                ppfData?.SUPPLIERMAIL
              }}</span>
            </div>
            <div class="d-flex img-upload">
              <div class="col-12">
                <h5 class="py-2">DUNNAGE ( IN CONT/RACK USE DIRECTION)</h5>
                <div class="img-cont col d-flex px-3 gap-2">
                  <div class="left col-12">
                    <div class="img-preview">
                      <div
                        *ngIf="!image[1]"
                        class="
                          d-flex
                          h-100
                          align-items-center
                          justify-content-center
                        "
                      >
                        <mat-icon class="text-secondary">panorama</mat-icon>
                      </div>
                      <img *ngIf="image[1]" [src]="image[1]" alt="partImage" />
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        my-2
                      "
                    >
                      <label
                        *ngIf="!readOnly && !this.editFlagOne"
                        for="file-upload2"
                        class="vpacs-btn-table col-10"
                        (change)="handleUpload($event, 2)"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="file-upload2"
                          style="display: none"
                        />
                      </label>
                      <img
                        src="../../../../assets/images/noun_edit_1570019.svg"
                        alt="D"
                        *ngIf="!readOnly && !this.editFlagOne"
                        class="vpacs-btn-table"
                        (click)="handleDelete(2)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="text-secondary px-4 subtitle"
                  *ngIf="!readOnly && !formatError[1]"
                >
                  Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                </div>
                <div
                  class="text-danger px-4 subtitle"
                  *ngIf="!readOnly && formatError[1]"
                >
                  {{ formatError[1] }}
                </div>
              </div>
            </div>
          </div>
          <div class="subcard-2 col-6 ps-1">
            <div class="d-flex flex-wrap list">
              <span class="col-5" title="Model Number">Model Number:</span>
              <span class="col-7" title="{{ response?.modelCodes }}">{{
                response?.modelCodes || ppfData?.MODELCODE
              }}</span>
              <span class="col-5" title="Parts Description"
                >Parts Description:</span
              >
              <span class="col-7" title="{{ ppfData?.PARTDESCRIPTION }}">{{
                ppfData?.PARTDESCRIPTION
              }}</span>
              <span class="col-5" title="Replaces Part Number"
                >Replaces Part Number:</span
              >
              <span class="col-7" title="{{ ppfData?.REPLACESPARTNUMBER }}">{{
                ppfData?.REPLACESPARTNUMBER
              }}</span>
              <span class="col-5" title="ECI Number">ECI Number:</span>
              <span class="col-7" title="{{ ppfData?.ECINUMBER }}">{{
                ppfData?.ECINUMBER
              }}</span>
            </div>
            <div class="d-flex img-upload">
              <div class="col-12">
                <h5 class="py-2">CONT/RACK (INCLUDE KANBAN LOCATION)</h5>
                <div class="img-cont col d-flex px-3 gap-2">
                  <div class="left col-12">
                    <div class="img-preview">
                      <div
                        *ngIf="!image[2]"
                        class="
                          d-flex
                          h-100
                          align-items-center
                          justify-content-center
                        "
                      >
                        <mat-icon class="text-secondary">panorama</mat-icon>
                      </div>
                      <img *ngIf="image[2]" [src]="image[2]" alt="partImage" />
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        my-2
                      "
                    >
                      <label
                        *ngIf="!readOnly && !this.editFlagOne"
                        for="file-upload3"
                        class="vpacs-btn-table col-10"
                        (change)="handleUpload($event, 3)"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="file-upload3"
                          style="display: none"
                        />
                      </label>
                      <img
                        src="../../../../assets/images/noun_edit_1570019.svg"
                        alt="D"
                        *ngIf="!readOnly && !this.editFlagOne"
                        class="vpacs-btn-table"
                        (click)="handleDelete(3)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="text-secondary px-4 subtitle"
                  *ngIf="!readOnly && !formatError[2]"
                >
                  Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
                </div>
                <div
                  class="text-danger px-4 subtitle"
                  *ngIf="!readOnly && formatError[2]"
                >
                  {{ formatError[2] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card3 col-lg col-md-4 col-6">
        <div class="card-head card3-header px-3 py-2">
          <span class="col-3">PART NUMBER</span>
          <span class="col-9">PRELIMINARY OR FINAL</span>
        </div>
        <div class="d-flex flex-wrap list">
          <div class="col-3 d-flex flex-wrap fw-bold mx-1">
            <span class="col-12">{{ constPartNumber }}</span>
          </div>
          <div class="list-right col-8 d-flex flex-wrap">
            <span class="col-10 fw-bold">{{
              ppfData?.PREORFINAL === 1 ? "PRELIMINARY" : "FINAL"
            }}</span>
            <span class="col-6">Date:</span>
            <span class="col-6">{{
              ppfData?.REGDATE?.substring(0, 10) | date: "MM/dd/yyyy"
            }}</span>
            <span class="col-6">Dock Code:</span>
            <span class="col-6">{{ ppfData?.DOCKCODE }}</span>
            <span class="col-6">Broadcast Part:</span>
            <span *ngIf="readOnly" class="col-6">{{
              ppfForm.controls.broadcastPart.value
            }}</span>
            <div class="d-flex align-items-center col-6" *ngIf="!readOnly">
              <input
                type="radio"
                value="Y"
                class="mx-1"
                formControlName="broadcastPart"
                [attr.disabled]="true"
              />Yes
              <input
                type="radio"
                value="N"
                class="mx-1"
                formControlName="broadcastPart"
                [attr.disabled]="true"
              />No
            </div>
          </div>
        </div>
        <div class="d-flex img-upload">
          <div class="col">
            <h5 class="py-2">PALLET LOAD (AS SHIPPED)</h5>
            <div class="img-cont col d-flex px-3 gap-2">
              <div class="left col">
                <div class="img-preview">
                  <div
                    *ngIf="!image[3]"
                    class="
                      d-flex
                      h-100
                      align-items-center
                      justify-content-center
                    "
                  >
                    <mat-icon class="text-secondary">panorama</mat-icon>
                  </div>
                  <img *ngIf="image[3]" [src]="image[3]" alt="partImage" />
                </div>
                <div
                  class="d-flex align-items-center justify-content-between my-2"
                >
                  <label
                    *ngIf="!readOnly && !this.editFlagOne"
                    for="file-upload4"
                    class="vpacs-btn-table col-10"
                    (change)="handleUpload($event, 4)"
                  >
                    CHOOSE FILE
                    <input
                      type="file"
                      id="file-upload4"
                      style="display: none"
                    />
                  </label>
                  <img
                    src="../../../../assets/images/noun_edit_1570019.svg"
                    alt="D"
                    *ngIf="!readOnly && !this.editFlagOne"
                    class="vpacs-btn-table"
                    (click)="handleDelete(4)"
                  />
                </div>
              </div>
            </div>
            <div
              class="text-secondary px-4 subtitle"
              *ngIf="!readOnly && !formatError[3]"
            >
              Image size must be under 200KB. Extension must be .gif or .jpg. Filename is restricted to 50 characters
            </div>
            <div
              class="text-danger px-4 subtitle"
              *ngIf="!readOnly && formatError[3]"
            >
              {{ formatError[3] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Ag grid1 -->
    <div class="grid1 d-flex mt-2">
      <span class="d-flex align-items-center justify-content-center px-2" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">Package Material</span>
      <ag-grid-angular
        style="width: 99%"
        id="ag-theme-balham"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [rowHeight]="rowHeight"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [pagination]="true"
        [suppressPaginationPanel]="true"
        [headerHeight]="50"
        [domLayout]="domLayout"
        [gridOptions]="gridOptions"
      >
      </ag-grid-angular>
    </div>
    <!-- Ag grid2 -->
    <div class="grid2 mt-2 d-flex">
      <span class="d-flex align-items-center justify-content-center px-2" style="writing-mode: vertical-lr; background: #F4F0DF 0% 0% no-repeat padding-box;">Package Data</span>
      <ag-grid-angular
        style="width: 99%"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()"
        [rowHeight]="rowHeight"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs2"
        [gridOptions]="gridOptions2"
        [rowData]="displayRowData2"
        [pagination]="true"
        [suppressPaginationPanel]="true"
        [headerHeight]="40"
        [domLayout]="domLayout"
      >
      </ag-grid-angular>
    </div>
    <div class="ppf-footer col-12 d-flex align-items-center flex-wrap p-2">
      <span class="col-lg-6 col-md-4 col-12 my-1"
        >Supplier Signature: {{ ppfData?.SUPPLIERCONTACT }}</span
      >
      <span class="col-lg-2 col-md-4 col-12 my-1"
        >Date:
        {{ ppfData?.SUPPLIERDATE?.substring(0, 10) | date: "MM/dd/yyyy" }}</span
      >
      <span class="col-lg-4 col-md-4 col-12 my-1"
        >Please indicate other affiliate this part/pkg is shipped to:
        {{ ppfData?.AFFILIATE }}</span
      >
      <span class="col-lg-4 col-md-6 col-12 my-1"
        >Toyota Approval Signature: {{ ppfData?.APPROVER }}</span
      >
      <span class="col-lg-2 col-md-4 col-12 my-1"
        >Approved: {{ ppfData?.APPROVER ? 'Yes' : 'No' }}</span
      >
      <span class="col-lg-2 col-md-4 col-12 my-1"
        >Date:
        {{ ppfData?.APPROVEDATE?.substring(0, 10) | date: "MM/dd/yyyy" }}</span
      >
      <span class="col-lg-4 col-md-4 col-12 my-1"
        >Comments: {{ ppfData?.NOTE }}</span
      >
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" [ngClass]="{ showModal: openModal }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="openModal = false"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of ERR_ARR">{{ item }}</div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
