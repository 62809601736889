import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-update-sup',
  templateUrl: './update-sup.component.html',
  styleUrls: ['./update-sup.component.scss']
})
export class UpdateSupComponent  {


  supplierCode = [];

  supplier: any;
  public updateSupplierForm = new FormGroup({
    ringi: new FormControl('123', [Validators.required]),
    type: new FormControl('', Validators.required),
    plant: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    ringiCost: new FormControl('', Validators.required),
  });

  constructor(
    public supplierService : SupplierService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<UpdateSupComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any
  ) { }


  onSearch() {
  }
  
  updateSupplierOnload():void { 
    const loadObj={
      "SupplierCode": this.supplierCode,
      // "sort": ""
    };
    const hidSearch=''; 
    this.spinnerService.start();
    this.supplierService.updateSupplierOnload(loadObj)
    .subscribe( {next: (_res:any) => {
  
    } ,
    error: (_err: any) => {
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
  }


  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

}
