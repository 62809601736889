import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-compare-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
  <button 
  class="vpacs-btn-table"
  style="width:85px; height:30px; color:#676a6e; font-size: 11px"
  (click)="btnClickedHandler($event)">COMPARE</button>
  `,
})

export class CompareBtnCellRendererComponent implements ICellRendererAngularComp {

  constructor(private readonly router: Router, public dialog: MatDialog) { }
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(_$event: any) {
    const compareParams = {
      modelCode: this.params.data.modelcode,
      plantCode: this.params.data.plantcode,
      partNumber: this.params.data.partnumber,
      supplierCode: this.params.data.suppliercode,
      connectionLevel: this.params.data.connectlevel
    };
    sessionStorage.setItem('compareParams', JSON.stringify(compareParams));
    const connectURL = '/model-list/connect/compare';
    this.router.navigateByUrl(connectURL);
  };
}