import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalListBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/internal-approvement/button-cell-render';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service';
import { formatDate } from '@angular/common';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-select-ppf',
  templateUrl: './select-ppf.component.html',
  styleUrls: ['./select-ppf.component.scss']
})
export class SelectPpfComponent implements OnInit {

  filteredOptions: any[] = [];
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50
  isAccessible = false;

  plants: any[] = [];

  plant: any;

  PPF = [
    {
      name: 'Approved PPF',
      value: 0
    },
    {
      name: 'Received PPF',
      value: 1
    }
  ];
  ppfVal = 0;
  autoGroupColumnDef: any;

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  cPage = 1;
  tPage = 0;
  rpPage =50;
  totalRecords = 1;
  point: any;
  ppfCode: any;
  spCode: any;
  details: any;
  familyCode: any;
  modelCode: any;
  supplier: any;
  partNo: any;
  ppfVer1: any;
  ppfVer2: any;
  paramData: any;
  urlTrackInt = '/tracking-list/internal-approvement/dept-approve/concept-compare'
  haveErrors = false;
  errorList: string[] = [];
  plantsValue: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly iaService: InternalApprovementListService) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true, unSortIcon: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
  }

  ngOnInit(): void {
    this.paramData = JSON.parse(sessionStorage.getItem('selectPPF') || '{}');
    this.point = this.paramData.point;
    this.ppfCode = this.paramData.ppfcode;
    this.ppfVer1 = this.paramData.ppfVer1;
    this.ppfVer2 = this.paramData.ppfVer2;
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '')

    this.spinnerService.start();
    this.loadPlants();
    this.displayRowData = [this.columnDefs];
    this.columnDefs = [
      {
        headerName: "", field: "", sortable: false, unSortIcon: false, minWidth: 120,
        cellRendererFramework: InternalListBtnCellRender,
        cellRendererParams: {
          clicked: (_colId?: any, params?: any) => {
            let uri = ''
            if(this.router.url === '/internal-approvement/dept-approve/concept-compare/select-ppf'){
              uri = '/internal-approvement/dept-approve/concept-compare'
            } 
            else if (this.router.url.includes('auth-list')) {
              if(this.router.url.includes('/trial-order')){
               uri = '/auth-list/trial-order/concept-compare'
             }
            }
            else if (this.router.url.includes('tracking-list')) {
              if(this.router.url.includes('/hazmat-approval')){
                uri = '/tracking-list/hazmat-approval/concept-compare'
              }
              else if(this.router.url.includes('/send-dept-approval')){
                uri = '/tracking-list/send-dept-approval/concept-compare'
              }
              else if(this.router.url.includes('/sI-preppf-dept-approval')){
                uri = '/tracking-list/sI-preppf-dept-approval/concept-compare'
              }
              else if(this.router.url.includes('/si-pkgtrial-dept-approval')){
                uri = '/tracking-list/si-pkgtrial-dept-approval/concept-compare'
              }
              else if(this.router.url.includes('/sI-final-dept-approval')){
                uri = '/tracking-list/sI-final-dept-approval/concept-compare'
              }
              else if(this.router.url.includes('/trial-order')){
                uri = '/tracking-list/trial-order/concept-compare'
              }
              else if(this.router.url.includes('/send-ppf')){
                uri = '/tracking-list/send-ppf/concept-compare'
              }
              else if (this.router.url.includes('tracking-list/internal-approvement')) {
                uri = this.urlTrackInt
              }
            }
            else if (this.router.url.includes('hazmat-approve-list')) {
              uri = '/hazmat-approve-list/hazmat-approval/concept-compare'
            }
            
            if (this.point === 'first') {
              const param = {
                ppfcode1 : params.ppfcode,
                ppfver1: params.ppfver,
                ppfcode2: params.ppfcode,
                ppfver2: this.ppfVer2
              }
              sessionStorage.setItem('conceptCompareDetails', JSON.stringify(param));
              sessionStorage.removeItem('selectPPF');
              this.router.navigateByUrl(uri)
            }
            else if (this.point === 'second') {
              const param = {
                ppfcode1 : params.ppfcode,
                ppfver1: this.ppfVer1,
                ppfcode2: params.ppfcode,
                ppfver2: params.ppfver
              }
              sessionStorage.setItem('conceptCompareDetails', JSON.stringify(param));
              sessionStorage.removeItem('selectPPF');
              this.router.navigateByUrl(uri)
            }
          }
        }
      },

      {
        headerName: "PLANT", field: "plant", minWidth: 90,
      },
      {
        headerName: "FAMILY", field: "familycode", minWidth: 90,
      },
      {
        headerName: "MODEL", field: "modelcode", minWidth: 90,
      },
      {
        headerName: "PART NUMBER", field: "partnumber", minWidth: 120,
      },
      {
        headerName: "SUPPLIER", field: "supplier", minWidth: 90,
      },
      {
        headerName: "DATE", field: "regdate", minWidth: 100, valueFormatter: this.dateFormatter,comparator: dateComparator,
      },
      {
        headerName: "STEP", field: "stepName", minWidth: 100,
      },
      {
        headerName: "VERSION", field: "ppfver", minWidth: 150,
      },

    ]

    this.displayRowData = [
    ]
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }

  loadPlants() {
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 1
    }
    this.iaService.getAllPlant(plantObj)
      .subscribe({
        next: (res: any) => {
          this.plants = res;
           this.filteredOptions = this.plants
           this.displayFn(this.plants);
          const params: any = {
            PPFCode: Number(this.ppfCode),
            SelPPF: "0",
            SupplierType: 1,
            SpecialistCode: this.spCode,
            PlantCode: '',
            PartNumber1: '',
            PartNumber2: '',
            FamilyCode: '',
            ModelCode: '',
            SupplierCode: '',
            Type: 1,
            searchflg: "0",
            page: this.cPage,
            limit: this.rpPage
          }
          this.loadSelectPPFDetails(params, 0)
        },
        error: () => {
          this.spinnerService.stop()
        }
      });
  }

  loadSelectPPFDetails(params: any, searchFlag: any) {
    this.spinnerService.start();
    this.iaService.loadSelectPPF(params)
      .subscribe({
        next: (res: any) => {
          this.details = res;
          const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant?.toLowerCase()))) // lowercase search
          if (searchFlag === 0) {
            this.plant = plant[0]?.plantcode
            this.plant = this.plant ? this.plant : this.details.Output.GetKeyCode?.result[0].plant;
            this.supplier = this.supplier ? this.supplier : this.details.Output.GetKeyCode?.result[0].supplier;
            this.partNo = this.partNo ? this.partNo : this.details.Output.GetKeyCode?.result[0].partnumber;
          }
          else {
            this.plantsValue = plant[0]?.plantcode
            this.plant = params.PlantCode ?? this.plantsValue;
            this.supplier = params.SupplierCode;
            this.partNo = params.PartNumber1;
            this.ppfVal = Number(params.SelPPF);
            this.familyCode = params.FamilyCode;
            this.modelCode = params.ModelCode;
          }
          this.displayRowData = this.details.Output.GetSelectPPF.finalData;
          this.displayRowData.length > 0 && this.displayRowData.forEach((element: any) => {
            if (element.step === '1') {
              Object.assign(element, {
                stepName: 'Pre'
              })
            }
            else if (element.step === '2') {
              Object.assign(element, {
                stepName: 'Final'
              })
            }
          });
          this.rowCount = res.Pagination.rows;
          this.totalRecords = res.Pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
        },
        error: () => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }


  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  cellStyleColumn(_params: any) {
    return ({ 'display': 'flex', 'justify-content': 'flex-start', 'height': '40px' })
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;

    this.loadPlants()
  }

  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }

  searchBtn() {
    this.details = [];
    const plantCheck = this.plants.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if(!plantCheck){
      this.haveErrors = true;
      this.errorList = ["Please select valid plant name"];
    }
    else {
    this.errorList = [];
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode // lowercase search
    const params: any = {
      PPFCode: Number(this.ppfCode),
      SelPPF: this.ppfVal.toString(),
      SupplierType: 1,
      SpecialistCode: this.spCode,
      PlantCode: this.plant ? this.plant : '',
      PartNumber1: this.partNo ? this.partNo : '',
      PartNumber2: '',
      FamilyCode: this.familyCode ? this.familyCode : '',
      ModelCode: this.modelCode ? this.modelCode : '',
      SupplierCode: this.supplier ? this.supplier : '',
      Type: 1,
      searchflg: "1",
      page: this.cPage,
      limit: this.rpPage
    }
    this.loadSelectPPFDetails(params, 1)
  }
  }

  cancel() { 
    let uri = ''
    if(this.router.url === '/internal-approvement/dept-approve/concept-compare/select-ppf'){
      uri = '/internal-approvement/dept-approve/concept-compare'
    }
    else if (this.router.url.includes('auth-list')) {
      if(this.router.url.includes('/trial-order')){
       uri = '/auth-list/trial-order/concept-compare'
     }
    }
    else if (this.router.url.includes('tracking-list')) {
      if(this.router.url.includes('/hazmat-approval')){
        uri = '/tracking-list/hazmat-approval/concept-compare'
      }
      else if(this.router.url.includes('/send-dept-approval')){
        uri = '/tracking-list/send-dept-approval/concept-compare'
      }
      else if(this.router.url.includes('/sI-preppf-dept-approval')){
        uri = '/tracking-list/sI-preppf-dept-approval/concept-compare'
      }
      else if(this.router.url.includes('/si-pkgtrial-dept-approval')){
        uri = '/tracking-list/si-pkgtrial-dept-approval/concept-compare'
      }
      else if(this.router.url.includes('/sI-final-dept-approval')){
        uri = '/tracking-list/sI-final-dept-approval/concept-compare'
      }
      else if(this.router.url.includes('/trial-order')){
        uri = '/tracking-list/trial-order/concept-compare'
      }
      else if(this.router.url.includes('/send-ppf')){
        uri = '/tracking-list/send-ppf/concept-compare'
      }
      else if (this.router.url.includes('tracking-list/internal-approvement')) {
        uri = this.urlTrackInt
      }
    }
    else if (this.router.url.includes('hazmat-approve-list')) {
      uri = '/hazmat-approve-list/hazmat-approval/concept-compare'
    }       
    sessionStorage.removeItem('selectPPF');
    this.router.navigateByUrl(uri)
  }
  
  help(){
    this.router.navigate(['./master-help',2,"Tracking",32])
  }
}