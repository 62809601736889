<nav class="navbar navbar-expand-lg topnav" id="myTopnav" *ngIf="pageType === 'external'">
    <div class="container-fluid">
        <div class="logo d-flex  d-flex align-items-center px-3">
            <img src="../../../assets/images/Toyota_logo.png" width="35px" height="30px" alt="logo" /> 
            <div class="px-2">VISUAL PACKAGING CONTROL SYSTEM</div>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="unAuthorized === false">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <div routerLink="/admin-access" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show" routerLinkActive="active" *ngIf="adminFlag"  class="d-flex nav-link align-items-center" [ngClass]="{'disabled': !adminFlag}"><mat-icon>home</mat-icon></div>
            </li>
            <li class="nav-item" >
                <div routerLinkActive="active" class="d-flex nav-link align-items-center" mat-button [matMenuTriggerFor]="belowMenu" [ngClass]="{'disabled': adminActiveRoute  || unAuthorized}">List-Type <mat-icon>expand_more</mat-icon>
                    <mat-menu #belowMenu="matMenu" yPosition="below">
                        <div mat-menu-item routerLink="/tracking" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show">Tracking</div>
                        <div mat-menu-item routerLink="/pkg-authorization" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show">Pkg Authorization</div>
                    </mat-menu>
                </div>            
            </li>
            <li class="nav-item dropdown" >
                <div routerLinkActive="active" class="d-flex nav-link align-items-center" mat-button [matMenuTriggerFor]="belowMenu2" [ngClass]="{'disabled': adminActiveRoute  || unAuthorized}">Shipment<mat-icon>expand_more</mat-icon>
                    <mat-menu #belowMenu2="matMenu" yPosition="below">
                        <div mat-menu-item routerLink="/shipment" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show">Shipment List</div>
                    </mat-menu>
                </div>
            </li>
            <li class="nav-item" *ngIf="supplierFlag">
                <div routerLinkActive="active" class="d-flex nav-link align-items-center" mat-button [matMenuTriggerFor]="belowMenu3" [ngClass]="{'disabled': adminActiveRoute  || unAuthorized}">Maintenance<mat-icon>expand_more</mat-icon>
                    <mat-menu #belowMenu3="matMenu" yPosition="below">
                        <div mat-menu-item routerLink="/authority-list" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show">Authority List</div>
                    </mat-menu>
                </div>          
            </li>
            <li class="nav-item">
                <div routerLinkActive="active" class="d-flex nav-link align-items-center" mat-button [matMenuTriggerFor]="belowMenu4" [ngClass]="{'disabled': adminActiveRoute  || unAuthorized}">Other<mat-icon>expand_more</mat-icon>
                    <mat-menu #belowMenu4="matMenu" yPosition="below">
                        <div mat-menu-item routerLink="/documentation" data-bs-toggle="collapse"  data-bs-target=".navbar-collapse.show">Documentation</div>
                    </mat-menu>
                </div>         
            </li>
        </ul>
        <div class="user d-flex">
            <div class="avatar">{{userInfo?.FIRSTNAME?.substring(0,1)}}{{userInfo?.LASTNAME?.substring(0,1)}}</div>
            <div class="name_role mx-2">
                    <span class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu5">{{userInfo?.FULLNAME}}
                    <mat-icon class="mx-2 drop-ico" >expand_more</mat-icon>
                    <mat-menu #belowMenu5="matMenu" xPosition="before">
                        <div mat-menu-item *ngFor="let x of rolesFromLocal.slice(1)" (click)="changeRole(x)">{{x}}</div>
                        <button mat-menu-item (click)="logOut()"><mat-icon>logout</mat-icon>LogOut</button>
                    </mat-menu>
                </span>
                <span>{{rolesFromLocal[0]}}</span>
            </div>
        </div>
      </div>
    </div>
  </nav>

<app-internal-toolbar 
    [item] = "pageType"
    (changePageType)="changePageType($event)"
    *ngIf="pageType === 'internal'"
></app-internal-toolbar>