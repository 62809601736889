import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-import-radio-btn-cell-renderer',
  template: `
    <div>
      <input 
        type="radio" 
        [checked] = "params.value === params.data.selectedValue"
        (change)="handler($event)"
        [disabled]="this.disabledFlag"
      />
    </div>
  `,
})

export class ImportCellRendererComponent implements ICellRendererAngularComp {
  disabledFlag = false;
  checkFlag = false;
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}
  public params: any;
  agInit(params: any): void {
    this.params = params;
    if(params.column.colId === 'replaceSupplier' && params.data.replaceSupplierFlag === 0){
      this.disabledFlag = true;
    }
  }

  handler(_event: any) {
    this.params.data.selectedValue = this.params.value
  }

}











