import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-due-date',
  templateUrl: './update-due-date.component.html',
  styleUrls: ['./update-due-date.component.scss']
})
export class UpdateDueDateComponent implements OnInit  {
  
  plant?: string;
  supplier?: string;
  familyCode?: string;
  modelCode?: string;
  partNumber? : string;
  prePPFDue? : string;
  pkgTrialDue? :string;
  readinessDue? :string;
  packageAuthorisedDue? :string;
  packageStartDate? :string;
  supplierCode? : string;
  plantCode? : string;
  errorList : any[] = [];
  dateFormat = 'MM/dd/yyyy';
  dateFormat1 = 'yyyy-MM-dd';

  constructor(
    public dialogRef: MatDialogRef<UpdateDueDateComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    public trackingListService : TrackingListService,
    private readonly spinner: NgxUiLoaderService,private readonly router:Router
  ) { }

  ngOnInit(): void {    
      setTimeout(() => {
        this.spinner.start();
      });
      this.loadUpdateDue(this.dataStore);
  }

  saveData() { 
    this.errorList = [];
    this.spinner.start();
    const reqParam = {
      Step03Due: this.prePPFDue ? formatDate(this.prePPFDue, this.dateFormat, 'en-US') : undefined,
      Step06Due: this.pkgTrialDue ? formatDate(this.pkgTrialDue, this.dateFormat, 'en-US') : undefined,
      Step09Due: this.packageAuthorisedDue ? formatDate(this.packageAuthorisedDue, this.dateFormat, 'en-US') : undefined,
      Step12Due: this.readinessDue ? formatDate(this.readinessDue, this.dateFormat, 'en-US') : undefined,
      ModelCode: this.dataStore.ModelCode,
      NewPkgStartDate: this.packageStartDate ? formatDate(this.packageStartDate, this.dateFormat, 'en-US') : undefined,
      PkgStartDate: this.dataStore.PkgStartDate ? formatDate(this.dataStore.PkgStartDate, this.dateFormat, 'en-US') : undefined,
      PartNumber: this.dataStore.PartNumber.replace('-',''),
      PlantCode: this.dataStore.PlantCode,
      SupplierCode: this.dataStore.SupplierCode,
      FamilyCode: this.dataStore.FamilyCode,
  };
  
    this.trackingListService.updateUpdateDue(reqParam).subscribe({
      next: (res: any) => {
        if(res?.status === 'SUCCESS'){
          this.close('SUCCESS');
        }
        this.spinner.stop();
      },
      error : (err: any) => {
        this.errorList.push(err?.error?.error?.message);
        this.spinner.stop();
      }
    });
  }

  close(msg?: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg });
  }

  loadUpdateDue(param: any) {
    const reqParam = {          
      SupplierCode:  param.SupplierCode,
      PartNumber: param.PartNumber,
      ModelCode: param.ModelCode,
      PkgStartDate: param.PkgStartDate,
      PlantCode: param.PlantCode
    }
    

    this.trackingListService.getUpdateDue(reqParam).subscribe({
      next: (data: any) => {
        this.familyCode = param.FamilyCode;
        this.modelCode = param.ModelCode;
        this.plant = data.plantname;
        this.supplier = data.suppliername?.shortname;
        this.partNumber = data.partnumber;
        this.prePPFDue =  formatDate(data.result[0].step03due, this.dateFormat1, 'en-US' , 'UTC');
        this.pkgTrialDue =  formatDate(data.result[0].step06due, this.dateFormat1, 'en-US' , 'UTC');
        this.packageAuthorisedDue =  formatDate(data.result[0].step09due, this.dateFormat1, 'en-US' , 'UTC');
        this.readinessDue =  formatDate(data.result[0].step12due, this.dateFormat1, 'en-US' , 'UTC');
        this.packageStartDate =  formatDate(data.result[0].pkgstartdate, this.dateFormat1, 'en-US' , 'UTC');
        this.spinner.stop();
      },
      error: () => {
        this.spinner.stop();
      }
    });
  }
  
  help(){
    this.router.navigate(['./master-help',2,"Tracking",37])
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'prePPFDue') {
        this.prePPFDue = '';
      }
      if (id === 'pkgTrialDue') {
        this.pkgTrialDue = '';
      }
      if (id === 'packageAuthorisedDue') {
        this.packageAuthorisedDue = '';
      }
      if (id === 'readinessDue') {
       this.readinessDue = ''
      }
      if (id === 'packageStartDate') {
        this.packageStartDate = ''
       }
    }
  }
}
