import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';

@Component({
  selector: 'app-add-package-cost',
  templateUrl: './add-package-cost.component.html',
  styleUrls: ['./add-package-cost.component.scss']
})
export class AddPackageCostComponent implements OnInit {

  numberFlag = false
  fab: any[] = [];
  cost: any;
  unitValue = 0
  submitFlag = false
  costs = [{
    Id: 0, Name: "$(US)"
  },
  {
    Id: 1, Name: "$(CAN)"
  },
  {
    Id: 2, Name: "(P)"
  },
  ]
  imageSrc: any;
  package: any;
  selPkgCode: any;
  typeCode: any;
  fromDate: any;
  costRate: any;
  codePlant: any;
  updateCodeValue: any;
  toDate: any;
  prevSelectedValue: any;
  selectedValue: any;
  contactform: any
  format = 'yyyy-MM-dd'
  addFlag = false;
  updateFlag = false;

  constructor(public dialogRef: MatDialogRef<AddPackageCostComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly pckgService: PackageMaintananceService, public message: MatSnackBar,
    private readonly loader: NgxUiLoaderService

  ) { this.dialogRef.disableClose = true; }
  ngOnInit(): void {
    if (this.data.mode === 'Add') {
      this.package = this.data.pckgCode;
      this.typeCode = this.data.type;
      this.selPkgCode = this.data.selPkgSup;
      this.codePlant = this.data.codePlant;
    }
    else if (this.data.mode === 'Update') {
      this.fromDate = formatDate(this.data.field.fromdate, this.format, 'en-US');
      this.toDate = formatDate(this.data.field.todate, this.format, 'en-US')
      this.package = this.data.pckgCode;
      this.typeCode = this.data.type;
      this.selPkgCode = this.data.selPkgSup;
      this.fab = this.data.field.pkgsupcode;
      this.costRate = this.data.field.cost;
      this.unitValue = Number(this.data.field.monetaryunit);
      this.codePlant = this.data.codePlant;
    }

  }

  change(event: any) {
    this.numberFlag = isNaN(event.target.value)
  }

  onSubmit(form: any) {
    this.submitFlag = true
    if (this.submitFlag && form.valid && (!this.numberFlag)) {
      this.loader.start()
      this.addUpdate()
    }
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: params });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addUpdate() {
    if (this.data.mode === 'Add') {
      const obj = {
        "url_pcode": this.package,
        "selPkgSup": this.fab,
        "url_PkgSup": "",
        "FromDate": this.fromDate,
        "ToDate": "",
        "url_from": "",
        "Cost": this.costRate,
        "type": Number(this.codePlant),
        "regflag": this.data.regFlag,
        "Unit": this.unitValue,
        "old_pcode": this.package
      }
      this.pckgService.addUpdateApi(obj)
        .subscribe({
          next: (_res: any) => {
            this.loader.stop()
            if(_res.status==="Success"){
              const message = _res.message
              this.data.parent.Onsuccess(message)
              this.dialogRef.close({ event: 'addEvent' });
            }else if(_res.inputcheck && !_res.inputcheck.bool){
              const message = _res.inputcheck.ErrorMessages
              this.data.parent.onDelete(message)
              this.dialogRef.close({ event: 'addEvent' });
            }
          },
          error: (_err: any) => {
            this.loader.stop()
            const message = 'Unable to Add Cost'
            this.data.parent.onDelete(message)
            this.dialogRef.close();
          },
        })
    }
    if (this.data.mode === 'Update') {
      const obj = {
        "url_pcode": this.package,
        "selPkgSup": this.fab,
        "url_PkgSup": this.data.field.pkgsupcode,
        "FromDate": this.fromDate,
        "ToDate": this.toDate,
        "url_from": formatDate(this.data.field.fromdate, this.format, 'en-US'),
        "Cost": this.costRate,
        "type": Number(this.codePlant),
        "regflag": this.data.regFlag,
        "Unit": this.unitValue,
        "old_pcode": this.package
      }

      this.pckgService.addUpdateApi(obj)
        .subscribe({
          next: (_res: any) => {
            this.loader.stop()
            if(_res.status==="Success"){
              const message = _res.message
              this.data.parent.Onsuccess(message)
              this.dialogRef.close({ event: 'updateEvent' });
            }else if(_res.inputcheck && !_res.inputcheck.bool){
              const message = _res.inputcheck.ErrorMessages
              this.data.parent.onDelete(message)
            this.dialogRef.close({ event: 'updateEvent' });
            }
          },
          error: (_err: any) => {
            this.loader.stop()
            const message = 'Unable to Update Cost'
            this.data.parent.onDelete(message)
            this.dialogRef.close();
            this.openMessageBox('Failed to Update', 'error');
          },
        })
    }
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'fromDate') {
        this.fromDate = '';
      }
    }
  }

}
