<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">Supplier Information</div>
            <div>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>
    <div class="alert alert-danger mt-2 mx-2" *ngIf="errorList.length > 0">
        <span *ngFor="let message of errorList">{{ message }}</span>
    </div>
    <div class="popup-contents">
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5">NA Supplier Code</label>
                <input type="text" [disabled]="packageAuthSatus" [(ngModel)]="naSupplierCode" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">TMMC Code</label>
                <input type="text" [(ngModel)]="tmmcCode" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input3">NUMMI Supplier Code</label>
                <input type="text" [(ngModel)]="nummiSupplierCode" [disabled]="true" />
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5">Name</label>
                <input type="text" [(ngModel)]="name" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">Short name</label>
                <input type="text" [(ngModel)]="shortName" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input3">Contact Name</label>
                <input type="text" [(ngModel)]="contactName" [disabled]="true" />
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5">Phone</label>
                <input type="text" [(ngModel)]="phone" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">FAX</label>
                <input type="text" [(ngModel)]="fax" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="email">E-Mail</label>
                <div style="position: relative; display: inline-block">
                    <input type="text" [disabled]="true"
                        style="text-decoration: underline; color: blue;" value="{{ email }}" />
                    <a id="" href="mailto:{{ email }}" style="
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              text-decoration: none;
              color: inherit;
            "></a>
                </div>
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5">Cell/Pager</label>
                <input type="text" [(ngModel)]="cell" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">Zip Code</label>
                <input type="text" [(ngModel)]="zipCode" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input3">City</label>
                <input type="text" [(ngModel)]="city" [disabled]="true" />
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5">State</label>
                <input type="text" [(ngModel)]="state" [disabled]="true" />
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">Country</label>
                <input type="text" [(ngModel)]="country" [disabled]="true" />
            </div>
        </div>

        <div class="
        footer
        d-flex
        justify-content-end
        align-items-center
        fixed-bottom
        mt-2
        pt-2
        pb-2
      ">
            <button [disabled]="packageAuthSatus" class="submit black-button me-3 roboto-bold" (click)="updateBtn()" [disabled]="hascheck">
                UPDATE SUPPLIER CODE
            </button>
        </div>
    </div>
</div>