import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { AuthorityDetailComponent } from '../dialogs/authority-detail/authority-detail.component';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'authoritylist-btn-cell-render',
  template: `
    <div class="d-flex align-items-center" *ngIf="params.data" >
    <input
        type="checkbox"
        (click)="checkedHandler($event)"
        [checked]="this.checked"
      />
      <button
        class="vpacs-btn-table d-flex justify-content-center align-items-center "
        style="height: 30px"
        (click)="openDialog()"
        [disabled]="!this.checked"
      >
        PLANT LEVEL ACCESS
      </button>
    </div>
    `

})
export class AuthorityListBtnCellrender implements ICellRendererAngularComp {

    refresh(params: ICellRendererParams): boolean {
        throw new Error(
        `Error while refreshing renderer component. INFO-${params}`
        );
    }

    protected checked = 0;
    protected params: any;
    constructor(private readonly dialog: MatDialog, protected globalState:GlobalStateService) {
    }

    agInit(params: any): void {
        this.params = params; 
        this.checked = this.params?.data?.D_VALID          
    }

    checkedHandler(_event: any) {
      if(this.checked === 1){
        this.checked = 0
       }else{
        this.checked = 1
       }
      this.params.node.data.D_VALID = this.checked
      const x = Object.entries(this.params.data).reduce((a:any, [key, value]) => {
        a[key.toUpperCase()] = value;
        a['TYPE'] = 2
      return a;
      }, {});
      this.globalState.setAuthorityListUpdateData(x)
    }

    openDialog() {
      this.dialog.open(AuthorityDetailComponent,{ 
          width: '85%', 
          data:{name:this.params.data.FULLNAME,supplierCode:this.params.data.SUPPLIERCODE, guid:this.params.data.TOYOTAGUID} 
      });
    }

    }
