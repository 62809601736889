import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'radio-btn-cell-renderer',
  template: `
  <div *ngIf="display">
    <input type="radio" (change)="handler($event)"/>
    </div>
  `,
})

export class RadioBtnCellRender implements ICellRendererAngularComp {
  fname: any;


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
   name: any;
   display = false
  agInit(params: any): void {
    this.params = params;    
    this.fname=params.fieldName
    if(this.fname === "ok" && params.data.RECVCHECK === 1){
      this.display=true
    }
    if(this.fname === "ng" && params.data.RECVCHECK === 0){
      this.display=true
    }
  }
  handler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




