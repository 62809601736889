import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-delete-fab',
  templateUrl: './delete-fab.component.html',
  styleUrls: ['./delete-fab.component.scss'],
})
export class DeleteFabComponent implements OnInit {
  param: any
  fileName: any

  constructor(
    private readonly dialogRef: MatDialogRef<DeleteFabComponent>,
    private readonly state: ExportStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'cost') {
      this.fileName = this.data.field.costcenter
    } else if (this.data.mode === 'Budget') {
      this.fileName = this.data.field.budget
    } else if (this.data.mode === 'Plant') {
      this.fileName = this.data.field.plantcode
    } else if (this.data.mode === 'Fab') {
      this.fileName = this.data.field.pkgsupcode
    } else if (this.data.mode === 'Ringi') {
      this.fileName = this.data.field.ringinumber
    } else if (this.data.mode === 'dock') {
      this.fileName = this.data.field.dockcode
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(pNam: string) {
    this.dialogRef.close({ data: pNam })
  }
}
