import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'details-btn-cell-renderer',
  template: ` <div class="d-flex align-items-center gap-1">
  <button
  class="vpacs-btn-table"
    style="height:30px;"
  (click)="getDetailsBtn()">DETAIL</button>
</div>
 
    `
})

export class DetailsBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute,) { }
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  agInit(params: any): void {
    this.params = params;
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }
  getDetailsBtn() {
    this.router.navigate([`/shipment-list/shipmentlist-details/${this.params.data.shipmentnumber}/${this.params.data.flag}`])
  }

}
