import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelListAddComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/model-list-add/model-list-add.component';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { DatePipe, formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ModelCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/model-list-btn-cell-render';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss'],
})

export class ModelListComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  isAccessible = false;
  plants: any[] = [];
  gridColumnApi: any;
  spCode: any;
  supplier = '';
  plant: any;
  plantDropdown = '(ALL)';
  autoGroupColumnDef: any;
  selectPlant: any[] = [];
  limit = '';

  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  paginationPageSize = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  originalPage: any;
  loadResponse: any;
  rowCount = 0;
  plantcode = '';
  modelCode = '';
  familyCode = '';
  modelName = '';
  plantname = '';
  /*
    The SOPFrom Date is followed as per .NET code.
  */
  sopFrom: any = formatDate(
    new Date().setFullYear(new Date().getFullYear() - 1),
    'yyyy-MM-dd',
    'en-US'
  );
  sopTo: any = '';
  eopFrom: any = '';
  eopTo: any = '';
  productionvolume = '';
  ringinumber = '';
  previousfamily = '';
  modelyear = '';
  previousmodel = '';
  ringicost = '';
  authcost = '';
  used = '';
  sortModel: any[] = [];
  name = '';
  specialistCode: any;
  plantCode: any = '';
  plantCodeValue: any[] = [];
  ringiNumber: any = '';
  modelcode: any = '';
  getGroupHeader: any;
  getHeader: any;
  groupValue: any;
  familyCodes: any;
  rowClassRules: { 'row-even': string; 'row-odd': string };
  rowIndex: any;
  params: any;
  modifyAccess: any;
  haveAccess: any;
  deleteAccess: any;
  haveAddAccess: any;
  resPlant: any;
  frameworkComponents: any;
  data: any;
  haveErrors = false;
  errorList: string[] = [];
  successMessage: string[] | string = [];
  deleteFlag = false;
  apiSearch = false;
  isAll = false;
  accessFlag = false;
  public groupDefaultExpanded = 1;
  filteredOptions: any[] = [];
  userInfo: any;
  previousValue: any;

  constructor(
    public route: Router,
    public message: MatSnackBar,
    private readonly service: ModelListService,
    private readonly dialog: MatDialog,
    public router: ActivatedRoute,
    private readonly spinnerService: NgxUiLoaderService,
    public datepipe: DatePipe,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return this.nullToDashFormatter(params)
        } else {
          return null;
        }
      }
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
    this.autoGroupColumnDef = {
      headerName: 'FAMILY CODE',
      field: 'familycode',
      sortable: true,
      unSortIcon: true,
      width: 200,
      minWidth: 125,
      enableGroupFilter: false,
      cellStyle: this.cellStyleColumn,
      cellRenderer: 'agGroupCellRenderer',
      pinned: 'left'
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res) {
          this.haveAccess = res.role?.p_trackingview;
          this.haveAddAccess = res.role?.p_trackingsave;
          this.modifyAccess = res.role?.p_trackingcorrect;
          this.deleteAccess = res.role?.p_trackingdelete;
          if ((this.haveAccess === true && this.haveAddAccess === true) || (this.haveAccess === true && this.haveAddAccess === true && this.modifyAccess === true)) {
            this.accessFlag = false;
          } else if ((this.haveAccess && this.deleteAccess) || (this.haveAccess && this.deleteAccess && this.modifyAccess)) {
            this.accessFlag = true;
          }
          else if ((this.haveAccess === true) || (this.haveAccess === true && this.modifyAccess === true)) {
            this.accessFlag = true;
          }
          if (this.haveAccess) {
            this.spCode = res.info.specialistcode;
            this.haveErrors = false;
            this.userInfo = res;
            this.getPlant();
            this.getColumnDefs();
          } else {
            this.haveErrors = true;
            this.errorList = ["You're not authorized to access this page"];
          }
          this.dataFilter();
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
        this.deleteAccess = false;
        this.errorList.push(_err.message);
      },
    });
  }

  dataFilter() {
    const plantdata = this.service.getplant();
    if (plantdata) {
      const filterdata = this.service.getmodelFilters();
      if (filterdata) {
        const date = "YYYY-MM-dd";
        this.plant = (filterdata?.PlantCodes.length > 1) ? '(All)' : filterdata?.PlantCodes[0];
        this.familyCode = filterdata?.FamilyCode;
        this.selectPlant = plantdata;
        this.modelcode = filterdata?.ModelCode;
        this.modelName = filterdata?.ModelName;
        this.sopFrom = filterdata.SOPFrom === "null" ? undefined : this.datepipe.transform(filterdata.SOPFrom, date);
        this.sopTo = filterdata.SOPTo === "null" ? undefined : this.datepipe.transform(filterdata.SOPTo, date);
        this.eopFrom = filterdata.EOPFrom === "null" ? undefined : this.datepipe.transform(filterdata.EOPFrom, date);
        this.eopTo = filterdata.EOPTo === "null" ? undefined : this.datepipe.transform(filterdata.EOPTo, date);
        this.service.setmodelFilters(null);
        return;
      }
    }
  }


  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'sopFrom') {
        this.sopFrom = '';
      }
      if (id === 'sopTo') {
        this.sopTo = '';
      }
      if (id === 'eopFrom') {
        this.eopFrom = '';
      }
      if (id === 'eopTo') {
        this.eopTo = ''
      }
    } 
  }

  cellStyleColumn(_params: any) {
    return { display: 'flex', 'justify-content': 'flex-start', height: '40px' };
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'FAMILY CODE',
        field: 'familycode',
        minWidth: 120,
        rowGroup: true,
        hide: true,
        cellRenderer: 'agGroupCellRenderer'
      },
      {
        headerName: 'PREV. FAMILY CODE', field: 'previousfamily', minWidth: 120, pinned: 'left'
      },
      {
        headerName: 'MODEL CODE', field: 'modelcode', minWidth: 120, pinned: 'left'
      },
      {
        headerName: 'MODEL NAME', field: 'modelname', minWidth: 150
      },
      {
        headerName: 'MODEL YEAR', field: 'modelyear', minWidth: 100
      },
      {
        headerName: 'PREV. MODEL CODE', field: 'previousmodel', minWidth: 120
      },
      {
        headerName: 'PLANT', field: 'plantname', minWidth: 100
      },
      {
        headerName: 'SOP$(US)', field: 'sop', minWidth: 100
      },
      {
        headerName: 'EOP$(US)', field: 'eop', minWidth: 100,
      },
      {
        headerName: 'PRODUCTION VOLUME/DAY', field: 'productionvolume', minWidth: 150,
      },
      {
        headerName: 'RINGI COST', field: 'ringicost', minWidth: 100,
        valueFormatter: (params: any) => {
          if (!params.node.group) {
            return this.nullToZero(params)
          } else {
            return null;
          }
        }
      },
      {
        headerName: 'AUTH COST', field: 'authcost', minWidth: 100,
        valueFormatter: (params: any) => {
          if (!params.node.group) {
            return this.nullToZero(params)
          } else {
            return null;
          }
        }
      },
      {
        headerName: 'CONNECT', field: 'connect', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false
      },
      {
        headerName: 'PART LIST', field: 'partList', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false
      },
      {
        headerName: 'COST REPORT', field: 'costReport', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false
      },
      {
        headerName: 'UPDATE', field: 'update', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false,
        headerComponentFramework: undefined,
        cellRendererParams: {
          reloadData: () => {
            this.afterDialogClose();
          },
        },
      },
      {
        headerName: 'DELETE', field: 'delete', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false,
        cellRendererParams: {
          reloadData: () => {
            this.loadModellistOnload();
            this.successMessage = 'Deleted Successfully';
            setTimeout(() => {
              this.successMessage = [];
            }, 5000);
          },
        },
      },
      {
        headerName: 'IMPORT', field: 'import', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false
      },
      {
        headerName: 'SEND ALL', field: 'sendall', minWidth: 100,
        cellRendererFramework: ModelCellRendererComponent,
        floatingFilterComponentParams: { suppressFilterButton: false }, floatingFilter: false, filter: false
      },
    ];
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []

      for (let i = 0; i < Math.ceil(this.totalRecords / 1000); i++) {
        this.cPage = i + 1;
        this.tPage = 1;
        this.rpPage = 1000;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadModellistOnload();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.loadModellistOnload();
      resolve();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    this.gridColumnApi?.applyColumnState(this.sortModel);
    if (this.service.getfilter()) {
      const retrivedValue = this.service.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.service.setfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.loadResponse.pagination.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  getPlant() {
    this.spinnerService.start();
    const plantobj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };
    this.service.getAllPlant(plantobj).subscribe({
      next: (res: any) => {
        this.resPlant = res.map((item: { plantcode: any }) => item.plantcode);
        this.selectPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
        this.plant = this.plant ?? this.userInfo.info?.plantcode;
        this.filteredOptions = this.selectPlant
        this.displayFn(this.selectPlant);
        this.loadModellistOnload();
        this.service.setplant(this.selectPlant);
        this.spinnerService.stop();
      },
      error: (_er: any) => {
        this.spinnerService.stop();
      },
    });
  }

  filterOptions() {
    this.filteredOptions = this.selectPlant.filter(option => option.plant.toLowerCase().includes(this.plant.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  loadModellistOnload() {
    this.spinnerService.start();
    const date = 'MM/dd/YYYY';
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant.toLowerCase())))
    this.plant = plant[0]?.plantcode
    const modelObject = {
      PlantCodes: this.plant === '(All)' ? this.resPlant : [this.plant],
      FamilyCode: this.familyCode,
      ModelCode: this.modelcode,
      ModelName: this.modelName,
      SOPFrom: String(this.datepipe.transform(this.sopFrom, date)),
      SOPTo: String(this.datepipe.transform(this.sopTo, date)),
      EOPFrom: String(this.datepipe.transform(this.eopFrom, date)),
      EOPTo: String(this.datepipe.transform(this.eopTo, date)),
      limit: this.rpPage,
      page: this.cPage,
    };
    this.service.setmodelFilters(modelObject);
    if (this.apiSearch) {
      this.cPage = 1;
    }
    this.service.loadModellistOnload(modelObject)?.subscribe({
      next: (res: any) => {
        this.loadResponse = res;
        this.displayRowData = res.result === -1 ? [] : res.result;
        this.rowCount = res.pagination.rows;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        if (this.apiSearch) {
          this.cPage = 1;
          if (this.isAll) {
            const values = {
              cPage: 1,
              tPage: 1,
              rpPage: 'ALL'
            }
            this.changePaginationValues(values)
          }
        }
        this.apiSearch = false;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
        this.gridApi?.hideOverlay();
      },
    });
  }

  help() {
    this.route.navigate(['./master-help', 2, 'Model List', 11]);
  }

  nullToDashFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === undefined|| params.value === null || params.value === "") {
      return '-';
    } else {
      return params?.value;
    }
  }

  nullToZero(params: any) {
    if (typeof params.value === 'undefined' || params.value === undefined || params.value === null || params.value === "") {
      return '0';
    } else {
      return params?.value;
    }
  }

  onSearch() {
    const plantCheck = this.selectPlant.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((!plantCheck)) {
      this.haveErrors = true
      this.errorList = ["Please select valid plant name"];
    }
    else {
      this.apiSearch = true;
      this.haveErrors = false
      this.loadModellistOnload();
    }
  }

  addModellist() {
    const dialogRef = this.dialog.open(ModelListAddComponent, {
      data: { mode: 'Add' }
    });
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'SaveEvent') {
        this.afterDialogClose();
      } else {
        return;
      }
    });
  }

  afterDialogClose() {
    this.loadModellistOnload();
    this.successMessage = "Added/Updated Successfully"
    setTimeout(() => {
      this.successMessage = [];
    }, 5000);
  }

  Close() {
    this.errorList = [];
    this.successMessage = [];
  }
}