<div class="doc">
    <div class="d-flex justify-content-between align-items-center m-2">
        <p class="page-title">Material Maintenance</p>
        <div>
            <button class="{{haveAddAccess ? 'black-button' : 'black-button disabled'}}"
                (click)="openDialog('Add',{})" style="height: 30px;">Add</button>
            <button class="help-btn" (click)="help()">Help</button>
        </div>
    </div>
    <app-notification [codes]="errorMessage" *ngIf="errorMessage"></app-notification>
    <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
        Authority Error - Access denied
    </div>
    <div *ngIf="haveAccess">
        <div class="grid-agg grid-h grid-height-material mb-5">
            <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
                (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true"
                [paginationPageSize]="rpPage" [pagination]="true" [suppressPaginationPanel]="true" [icons]="icons"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40"
                (filterChanged)="onFilterChanged($event)" [suppressHorizontalScroll]="true">
            </ag-grid-angular>
            <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
                (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
            </app-pagination>
        </div>
    </div>