import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { formatDate } from '@angular/common'

@Component({
  selector: 'app-sq-dept-approval',
  templateUrl: './sq-dept-approval.component.html',
  styleUrls: ['./sq-dept-approval.component.scss'],
})
export class SqDeptApprovalComponent implements OnInit {
  leaderPlant: any[] = []
  leaderPlantContents: any[] = []
  qpc: any[] = []
  qpcContents: any[] = []
  paramData: any
  details: any
  dateSentVal: any
  rdoYes11Checked = false
  rdoNo11Checked = false
  rdoYes12Checked = false
  rdoNo12Checked = false
  rdoYes25Checked = false
  rdoNo25Checked = false
  rdoYes26Checked = false
  rdoNo26Checked = false
  rdoYes27Checked = false
  rdoNo27Checked = false
  txtNote11Value: any
  txtNote12Value: any
  txtNote24Value: any
  txtPart26Value: any
  txtModel26Value: any
  sel22Items: any[] = []
  sel22SelectedIndex: any
  sel23Items: any[] = []
  sel23SelectedIndex: any
  sel24Items: any[] = []
  sel24SelectedIndex: any
  crrStep: any
  userInfo: any
  q21date: any
  q13date: any
  q14date: any
  dateFormat = 'MM/dd/yyyy'

  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly state: ExportStateService,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.spinnerService.start();

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info
        this.paramData = JSON.parse(
          sessionStorage.getItem('deptApprove') || '{}',
        )
        if (this.paramData) {
          this.sqDeptApprDetails()
        }
      },
      error: (_err: any) => {
        this.spinnerService.start();
      },
    })

    this.leaderPlant = [
      'Leader Plant',
      'Plant',
      'Dock',
      'Model',
      'Supplier',
      'Part Number',
      'Specialist',
      'Date Sent',
    ]

    this.qpc = ['QPC', 'Full Cont. Weight(disp_weight)']
  }

  sqDeptApprDetails() {
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      PPFCode: this.paramData?.ppfcode,
      PPFver: this.paramData?.ppfver,
      step: '1',
    }
    this.trackingListService.sendDepartmentApproval(loadObj).subscribe({
      next: (res: any) => {
        this.details = res
        this.dateSent()
        this.leaderPlantContents = [];
        this.qpcContents = [];
        const items = [
          this.paramData?.leaderplant,
          this.paramData?.plantname,
          this.details?.dv_tblInfo[0]?.dockcode !== '' ? this.details?.dv_tblInfo[0]?.dockcode : this.details?.tdDock[0],
          this.paramData?.modelcode,
          this.paramData?.suppliername,
          this.paramData?.partnumber,
          this.paramData?.specialist,
          this.dateSentVal,
        ]
        this.leaderPlantContents.push(...items)

        const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
        && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
          this.changeSize(this.details?.tdFullCont, 1),
          1,
        ) : ""

        const qpcItems = [
          this.details?.qtyPartCont,
          fullContStore === "" ? "-" : fullContStore
        ]
        this.qpcContents.push(...qpcItems)

        const questionnaire = this.details?.createQuestionnaire
        ? this.details?.createQuestionnaire[0]
        : (this.details?.createData_supQuestionnaire 
          ? this.details?.createData_supQuestionnaire?.questionnaire[0] : '')

        if (questionnaire !== '') {
          if (questionnaire?.q1_1_yn === 'Y') {
            this.rdoYes11Checked = true
          } else if (questionnaire?.q1_1_yn === 'N') {
            this.rdoNo11Checked = true
          }

          if (questionnaire?.q1_2_yn === '1') {
            this.rdoYes12Checked = true
          } else if (questionnaire?.q1_2_yn === '0') {
            this.rdoNo12Checked = true
          }

          if (questionnaire?.q2_5_yn === 'Y') {
            this.rdoYes25Checked = true
          } else if (questionnaire?.q2_5_yn === 'N') {
            this.rdoNo25Checked = true
          }

          if (questionnaire?.q2_6_yn === 'Y') {
            this.rdoYes26Checked = true
          } else if (questionnaire?.q2_6_yn === 'N') {
            this.rdoNo26Checked = true
          }

          if (questionnaire?.q2_7_yn === '1') {
            this.rdoYes27Checked = true
          } else if (questionnaire?.q2_7_yn === '0') {
            this.rdoNo27Checked = true
          }

          this.txtNote11Value = questionnaire?.q1_1_note
          this.txtNote12Value = questionnaire?.q1_2_note
          this.txtNote24Value = questionnaire?.q2_4_note
          this.txtPart26Value = questionnaire?.q2_6_part
          this.txtModel26Value = questionnaire?.q2_6_model

          if (questionnaire?.q2_2_select === '1') {
            const options = {
              index: '1',
              value: 'Drawing / Data',
            }
            this.sel22Items?.push(options)
            this.sel22SelectedIndex = '1'
          } else if (questionnaire?.q2_2_select === '2') {
            const options = {
              index: '1',
              value: 'Sample Part',
            }
            this.sel22Items?.push(options)
            this.sel22SelectedIndex = '1'
          } else if (questionnaire?.q2_2_select === '3') {
            const options = {
              index: '1',
              value: 'Other',
            }
            this.sel22Items?.push(options)
            this.sel22SelectedIndex = '1'
          } else {
            this.sel22SelectedIndex = '0'
          }

          if (questionnaire?.q2_3_select === '1') {
            const options = {
              index: '1',
              value: 'Accept - Exact Pkg',
            }
            this.sel23Items?.push(options)
            this.sel23SelectedIndex = '1'
          } else if (questionnaire?.q2_3_select === '2') {
            const options = {
              index: '1',
              value: 'Accept - Modify Pkg',
            }
            this.sel23Items?.push(options)
            this.sel23SelectedIndex = '1'
          } else if (questionnaire?.q2_3_select === '3') {
            const options = {
              index: '1',
              value: 'New Proposal',
            }
            this.sel23Items?.push(options)
            this.sel23SelectedIndex = '1'
          } else {
            this.sel23SelectedIndex = '0'
          }

          if (questionnaire?.q2_4_select === '1') {
            const options = {
              index: '1',
              value: 'Part Shape',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else if (questionnaire?.q2_4_select === '2') {
            const options = {
              index: '1',
              value: 'Part Size',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else if (questionnaire?.q2_4_select === '3') {
            const options = {
              index: '1',
              value: 'Part Weight',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else if (questionnaire?.q2_4_select === '4') {
            const options = {
              index: '1',
              value: 'Material Change',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else if (questionnaire?.q2_4_select === '5') {
            const options = {
              index: '1',
              value: 'New Part Introduction',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else if (questionnaire?.q2_4_select === '6') {
            const options = {
              index: '1',
              value: 'Other',
            }
            this.sel24Items?.push(options)
            this.sel24SelectedIndex = '1'
          } else {
            this.sel24SelectedIndex = '0'
          }
          
          if (questionnaire?.q1_3_date !== null && questionnaire?.q1_3_date !== undefined) {
            this.q13date = formatDate(questionnaire?.q1_3_date, this.dateFormat, 'en-US', 'UTC')
          }
          if (questionnaire?.q1_4_date !== null && questionnaire?.q1_4_date !== undefined) {
            this.q14date = formatDate(questionnaire?.q1_4_date, this.dateFormat, 'en-US', 'UTC')
          }
          if (questionnaire?.q2_1_date !== null && questionnaire?.q2_1_date !== undefined) {
            this.q21date = formatDate(questionnaire?.q2_1_date, this.dateFormat, 'en-US', 'UTC')
          }
        }
        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
    })
  }

  changeSize(obj: any, type: any) {
    if (obj === null) {
      return 0
    } else {
      const size = this.nullDouble(obj) 
      if (this.userInfo?.unit === '0') {
        return size
      } else {
        if (type === 0) {
          return this.changeInch(size)
        } else if (type === 1) {
          return this.changePound(size)
        }
        return 0
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj.toString())
        } else {
          return parseInt(obj.toString())
        }
      } catch {
        return 0
      }
    }
  }

  changeInch(size: any) {
    return Math.round((size / 25.4) * 2) / 2
  }

  changePound(size: any) {
    return (size * 1000) / 453.59237
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size)
      } else {
        return this.formatPoundNull(size)
      }
    } else {
      if (type === 0) {
        return this.formatMmNull(size)
      } else {
        return this.formatkgNull(size)
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return '0'
    }
    return size.toFixed(2)
  }

  formatPoundNull(size: any) {
    if (size === 0) {
      return ''
    }
    return size.toFixed(2)
  }

  formatMmNull(mm: any) {
    if (mm === 0) {
      return ''
    }
    return mm.toFixed(2)
  }

  formatkgNull(kg: any) {
    if (kg === 0) {
      return ''
    }
    return kg.toFixed(2)
  }

  dateSent() {
    if (this.details?.dv_date !== null && this.details?.dv_date.length > 0) {
      this.dateSentVal = '-'
      if (this.details?.dv_date[0]['datesent'] !== '') {
        this.dateSentVal = formatDate(this.details?.dv_date[0]['datesent'], 'MM/dd/yyyy', 'en-US' , 'UTC')
      }
    } else {
      this.dateSentVal = '-'
    }
  }

  cancel() {
    sessionStorage.removeItem('deptApprove');
    this.router.navigateByUrl('tracking-list')
  }
  
  help(){
    this.router.navigate(['./master-help',2,"Tracking",721])
  }
}
