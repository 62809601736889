import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { ConstantsService } from 'src/app/services/constant.service'
import { catchError, throwError, Observable } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class AuthListService {
  URL = this.constantsService.MASTERCOST
  commonUrl = this.constantsService.INTERNAL
  authListFilters: any
  plantdata: any;
  authGridSate:any ={
    sort : null,
    filter : null,
    currentpage : 0,
    totalpage : 0,
    rowcount : 0,
    pagesize : 0
  }

  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService,
  ) {}

  getAllPlant(plantObj: any): Observable<any> {
    return this.http
      .post(this.URL + 'common/getPlantCost', plantObj, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  loadAuthList(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/OnLoadAuthList', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  getDataSynchronous(payload: any):Promise<any>{
    return this.loadAuthList(payload).toPromise()
  }

  loadAuthListDetails(obj: any): Observable<any> {
    const params = `${obj.authNum}/${obj.plantCode}`
    return this.http
      .get(`${this.URL}cost/onloadAuthDetail/${params}`, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  setPo(authNum: any): Observable<any> {
    return this.http
      .get(`${this.URL}cost/onloadSetPO/0/${authNum}`, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  updatePo(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/updatePO', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  cancelAuthList(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/cancelAuthList', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  deleteAuthList(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/deleteAuthList', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  getRejectReason(authNum: any): Observable<any> {
    return this.http
      .get(`${this.URL}cost/getRejectReason/${authNum}`, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  authDownload(authNum: any): Observable<any> {
    return this.http
      .get(`${this.URL}cost/authDownload/${authNum}`, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  authListDownload(params: any): Observable<any> {
    return this.http
      .post(this.URL + 'cost/authListDownload/', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  loadDeliveryDetails(params: any): Observable<any> {
    return this.http
      .post(this.commonUrl + 'shipmentList/shipmentDetail/DeliveryHistory/', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  loadCRAFDetails(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL+'cost/rcafonload', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  getDisplayColumns(spCode: any): Observable<any> {
    return this.http
      .get(`${this.URL}cost/getDisplayColumns/${spCode}`, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  updateColumnInfo(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/updateColumnInfo', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  rcafAuthorize(params: any): Observable<any> {
    return this.http
      .post<any>(this.URL + 'cost/rcafAuthorize', params, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error')
  }

  setAuthLisFilters(values:any){
    this.authListFilters = values
  }

  getAuthLisFilters(){
    return this.authListFilters
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  //filterchanges
  clearAuthFilters(){
    this.authListFilters=''
  }

}