import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-add-ringi',
  templateUrl: './add-ringi.component.html',
  styleUrls: ['./add-ringi.component.scss']
})
export class AddRingiComponent implements OnInit {
  type = [{Id:2,name:"for Mainte"},{Id:3,name:"for Proto"}];
  filteredOptions: any[] = [];
  ty: any;
  leaderPlant: any[] = [];
  leaderPlantContents: any[] = [];
  supplierCode = [];
  errorMessage: any;
  spCode: any;
  supplier = '';
  plant = "";
  plants: any[] = [];
  plantcode= '';
  plantArray: any[] = [];
  ringinumber= '';
  startDate='';
  endDate='';
  ringiCost='';
  maintenanceCost: any='';
  comment: any='';
  errorList : any[] = [];
  isEndDateError = false;
  isStartDateError = false;
  isPlantError = false;
  isTypeError = false;
  isRingiNumberError = false;
  isMainCostError = false;
  amNumeric = false;
  startEndDatetError = false;

  constructor(
    private readonly ringiListService:RingiListService,private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    public dialogRef: MatDialogRef<AddRingiComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any
  ) { }
  addRingi:any;
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res) {
          this.spCode = res.info.specialistcode;
        }}})
    this.apiCall();

    this.leaderPlant = [
      'Ringi #', 'Type', 'Plant', 'Start Date', 'End Date', 'Ringi Cost $(US)', 'Comment'
    ]

    this.leaderPlantContents = [
      'TMMC', '5A', '642L', 'TGM01', '76801-0E221', 'J.PORTILLO', '-'
    ]
  }
  
  getFormGroupErrorMessages(form: any, formName: string) {
    let errorMessage = '';
    if (form.enabled && form.invalid) {
      const controls: any = form.controls;
      for (const control in controls) {
        if (controls[control].enabled && controls[control].invalid) {
          errorMessage += `${formName} ${control} is required item. \n`;
        }
      }
    }
    return errorMessage;
  }

  closeDialog(){
    this.dialogRef.close()
  }
  apiCall() {
    const obj = {
      "SpecialistCode": this.spCode,
      "Type": 2 // Type '2' for Cost.management screens
    }
    this.ringiListService.plant(obj).subscribe({
      next : (res:any) =>{
        this.plants = res;
        this.plant = '';
        this.filteredOptions = this.plants;
        this.displayFn(this.plants);
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete : ()=>{
        this.spinnerService.stop();
      },
    })
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  checkValidations(){
    this.errorList = [];
    this.isRingiNumberError = false;
    this.isPlantError = false;
    this.isTypeError = false;
    this.isStartDateError = false;
    this.isEndDateError = false;
    this.isMainCostError = false;
    this.amNumeric = false;
    this.startEndDatetError = false;
    if(!this.ringinumber){
      this.isRingiNumberError = true;
    }
    if(!this.plant){
      this.isPlantError = true;
    }
    if(!this.ty){
      this.isTypeError = true;
    }
    if(!this.startDate){
      this.isStartDateError = true;
    }
    if(!this.endDate){
      this.isEndDateError = true;
    }
    if(!this.maintenanceCost){
      this.isMainCostError = true;
    }
    else{
      this.isNumeric('amCost');
    }
    if (
      ((this.startDate) || (this.endDate)) &&
      ((this.startDate) > (this.endDate))
    ) {
      this.startEndDatetError = true
    }
  }
  isNumeric(name:any) {
    if(name === 'amCost')    {
      this.isMainCostError = !isNaN(parseFloat(this.maintenanceCost)) && isFinite(+this.maintenanceCost);  
      if(!this.isMainCostError){
        this.amNumeric = true;
      }
      else{
        this.amNumeric = false;
        this.isMainCostError =  false;
      }
    }
  }

  addButton():void { 
    this.checkValidations();
    if(this.isRingiNumberError || this.isPlantError || this.isTypeError || this.isStartDateError || this.isEndDateError || this.amNumeric || this.startEndDatetError) {
      return;
    }
    const plantCheck = this.plants.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase())
    || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if(!plantCheck){
      this.errorList.push("Please select valid plant name");
    } else {
    const loadObj={
      "RingiNumber": this.ringinumber,
			"PlantCode": this.plant,
			"Plant": this.plant,
			"ModelCode": "",
			"RingiType": this.ty,
			"FromDate": this.startDate,
			"ToDate": this.endDate,
			"MaintenanceCost": this.maintenanceCost,
      "Comment" : this.comment,
			"Note": this.comment,
			"EstVer": "",
			"RingiVer": "",
      "ScreenMode": "ADD"
    };
    this.spinnerService.start();
    this.ringiListService.addButtonRingi(loadObj)
    .subscribe( {next: (res:any) => {
      if(res.message){
        this.errorList.push(res.message);
      }else{
        this.close('LOADRINGI');
      }
    } ,
    error: (_err: any) => {
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
}
  }
  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg });
  }
  help(){
    this.router.navigate(['./master-help',3,"Cost Management",47])
    this.dialogRef.close();
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'startDate') {
        this.startDate = '';
      }
      if (id === 'endDate') {
        this.endDate = '';
      }
    } 
  }

}