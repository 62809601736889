import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute} from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DetailsBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/details-btn-cell-render';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
})
export class ShipmentListComponent implements OnInit {
  Draft = 1;
  WaitReceiver = 1;
  ActualShipment = 1;
  WaitLP = 1;
  ActualReceivement = 1;
  WaitShipper = 1;
  Complete:any = '';
  autoGroupColumnDef: any;
  gridApi: any;
  sortModel: any[] = [];
  gridColumnApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50;
  cPage = 1;
  tPage = 0;
  isAll = false;
  rpPage = 50;
  originalPage: any;
  totalRecords = 0;
  userInfo: any;
  isAccessible = false;
  Shipment = '';
  shipper = '';
  receiver = '';
  pickup:any = '';
  delivery:any = '';
  haveAccess :any;
  response: any;
  haveAddAccess: any;
  errorList: any[] = [];
  rowClassRules:any

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>`;
  pageNumber = 0;
  lastRowIndex = 0;
  constructor(
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly shipmentListService: ShipmentListService,
    public route: ActivatedRoute,
    public datepipe: DatePipe
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      minWidth: 90,
     floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      sortable: true,
      unSortIcon: true,
      autoHeight: true,

      //grid filter changes
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      
      'row-yellow':'data.cancelflag === `1`',
   
    };
  }

 
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          if (res.info.p_plantcode && res.info.p_specialistcode) {
            this.userInfo = res.info;
            this.haveAccess = res.role?.p_trackingview;
            this.haveAddAccess = res.role?.p_trackingsave;
            if(this.haveAccess&&this.haveAddAccess) {
              this.spinnerService.start();
              this.apiCall();
              this.setColumndefs();
            } else {
              this.errorList = ["You're not authorized to access this page"];
              this.spinnerService.stop();
            }
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.haveAccess = false;
        this.haveAddAccess = false;
      },
    });
    
  }

  setColumndefs() {
    this.columnDefs = [
      {
        headerName: 'SHIPMENT#',
        field: 'shipmentnumber',
        filter: 'agTextColumnFilter',
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'SHIPPER',
        field: 'shipper',
        valueFormatter: this.fabricatorFormat,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'RECEIVER',
        field: 'receiver',
        filter: 'agTextColumnFilter',
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'LP',
        field: 'lpfabcode',
        filter: 'agTextColumnFilter',
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'PICKUP',
        field: 'pickupdate',
        valueFormatter: this.fabricatorFormat,
        filter: 'agTextColumnFilter',
        comparator:dateComparator
      },
      {
        headerName: 'DELIVERY',
        field: 'delivdate',
        valueFormatter: this.fabricatorFormat,
        filter: 'agTextColumnFilter',
        comparator:dateComparator
      },

      {
        headerName: 'STATUS',
        field: 'statusName',
        valueFormatter: this.fabricatorFormat,
        filter: 'agTextColumnFilter',
      },

      {
        headerName: 'DETAIL',
        field: '',
        sortable: false,       
        floatingFilterComponentParams: { suppressFilterButton: false },
        floatingFilter: false,
        cellRendererFramework: DetailsBtnCellRender,
       
      },
    ];
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'pickup') {
        this.pickup = '';
      }
      if (id === 'delivery') {
        this.delivery = '';
      }
    }
  }
  
  fabricatorFormat(params: any) {
    return params.value ? params.value : '-';
  }

  async changePaginationValues(values: any) {
    
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;

    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = [];
      for (let i = 0; i < Math.ceil(this.totalRecords / 100); i++) {
        this.cPage = i + 1;
         this.tPage = 1;
          this.rpPage = 100;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    } else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadPayload(); //filter changes
    }
  }

  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    //grid filter changes
    this.shipmentListService.setfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response.pagination.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  onLoadAll() {
    this.loadPayload(); //filter changes
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    //grid filter changes
    if (this.shipmentListService.getfilter()) {
      const retrivedValue = this.shipmentListService.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  
  apiCall() { //filterchanges
    const data = this.shipmentListService.getShipmentListFilters();
    if (data) {
      this.Shipment = data.ShipmentNumber
      this.shipper = data.Shipper
      this.receiver = data.Receiver
      this.pickup = data.PickupDate === '' ? '' : data.PickupDate
      this.delivery = data.DeliveryDate === '' ? '' : data.DeliveryDate
      this.Draft = data.Draft === 1 ? 1 : 0
      this.WaitReceiver = data.WaitReceiver === 1 ? 1 : 0
      this.ActualShipment = data.ActualShipment === 1 ? 1 : 0
      this.WaitLP = data.WaitLP === 1 ? 1 : 0
      this.WaitShipper = data.WaitShipper === 1 ? 1 : 0
      this.Complete = data.Complete === '' ? '' : 1
    }
    this.loadPayload();
  }


  loadPayload(){ //filterchanges
 
    const params: any = {
      sort: '',
      ShipmentNumber: this.Shipment,
      Shipper: this.shipper,
      Receiver: this.receiver,
      PickupDate: this.pickup=== '' ? '': this.pickup,
      DeliveryDate: this.delivery=== '' ? '': this.delivery,
      Draft: this.Draft === 1 ? 1 : 0,
      WaitReceiver: this.WaitReceiver === 1 ? 1 : 0,
      ActualShipment: this.ActualShipment === 1 ? 1 : 0,
      WaitLP: this.WaitLP === 1 ? 1 : 0,
      ActualReceivement: this.ActualReceivement === 1 ? 1 : 0,
      WaitShipper: this.WaitShipper === 1 ? 1 : 0,
      Complete: this.Complete === '' ? '' : 1,
      limit: this.rpPage,
      page: this.cPage,
    };
  
    this.shipmentListOnLoad(params)
  }

  shipmentListOnLoad(params:any) //filterchanges
  {
    this.spinnerService.start();
    this.shipmentListService.setShipmentListFilters(params);
    this.shipmentListService.shipmentListOnload(params).subscribe({
      next: (res: any) => {
        this.response = res
        res.data.length > 0 &&
          res.data.forEach((element: any) => {
            Object.assign(element, {
              flag: this.getFlags(element),
            });
          });

        this.displayRowData = res.data;
        this.rowCount = res.data.length;
        this.totalRecords = Number(res.pagination.TotalRecords);
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        this.spinnerService.stop();
        this.gridApi.hideOverlay();
      },
      error: (_error: any) => {
        this.displayRowData = [];
        this.gridApi.hideOverlay();
        this.spinnerService.stop();
      },
    });
  }

  getFlags(element: any) {
    let flag = '0';
    if ((element.shiptype === '4' && element.shipplantcode === this.userInfo.p_plantcode &&(element.status === 0 || element.status === 13 ||element.status === 21)) 
    ||(element.recvtype === '4' && element.recvplantcode === this.userInfo.p_plantcode && (element.status === 22 || element.status === 11))) {
      flag = '1';
    } else {
      if ( (element.shiptype === '4' &&  element.shipplantcode === this.userInfo.p_plantcode) ||  (element.recvtype === '4' && element.recvplantcode === this.userInfo.p_plantcode) ) {
        flag = '1';
      }
      if (flag === '0') {
        if (element.specialistcode === this.userInfo.p_specialistcode) {
          if (element.cancelflag === '1') {   flag = '1';  } else {  flag = '0';  }
        }
      }
    }
    return flag;
  }

  Search() {
    this.cPage = 1;
    this.loadPayload();  //filterchanges
  }

}
