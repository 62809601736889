<div class="popup-container">
    <header>
      <button class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
        <div class="d-flex justify-content-between align-items-center header">
          <div class="page-title">Plant System Days </div>
        <div>
          <button class="help-btn" (click)="help()">Help</button>
        </div>
        </div>
    </header>
    <div class="popup-contents" >
        <div class="filter d-flex m-3 heading-row">
            <table class="table table-bordered" aria-labelledby="">
                <thead>
                  <tr>
                    <th scope="col" colspan="3" class="grid-backcolor">&nbsp;</th>
                    <th scope="col" class="grid-backcolor">1/W</th>
                    <th scope="col" class="grid-backcolor">2/W</th>
                    <th scope="col" class="grid-backcolor">3/W</th>
                    <th scope="col" class="grid-backcolor">4/W</th>
                    <th scope="col" class="grid-backcolor">1/D</th>
                    <th scope="col" class="grid-backcolor">2/D</th>
                    <th scope="col" class="grid-backcolor">4/D</th>
                    <th scope="col" class="grid-backcolor">8/D</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="2" class="grid-backcolor" style="padding-top: 28px">Plant</td>
                    <td rowspan="2" class="grid-backcolor" style="padding-top: 28px">{{plantName}}</td>
                    <td class="grid-backcolor">E</td>
                    <td><input type="text" name="plante1"  [(ngModel)]="plante1" (change)="plante1 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante2"  [(ngModel)]="plante2" (change)="plante2 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante3"  [(ngModel)]="plante3" (change)="plante3 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante4"  [(ngModel)]="plante4" (change)="plante4 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante5"  [(ngModel)]="plante5" (change)="plante5 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante6"  [(ngModel)]="plante6" (change)="plante6 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante7"  [(ngModel)]="plante7" (change)="plante7 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plante8"  [(ngModel)]="plante8" (change)="plante8 = handlePlantChange($event)"></td>
                  </tr>
                  <tr>
                    <td class="grid-backcolor">F</td>
                    <td><input type="text" name="plantf1"  [(ngModel)]="plantf1" (change)="plantf1 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf2"  [(ngModel)]="plantf2" (change)="plantf2 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf3"  [(ngModel)]="plantf3" (change)="plantf3 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf4"  [(ngModel)]="plantf4" (change)="plantf4 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf5"  [(ngModel)]="plantf5" (change)="plantf5 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf6"  [(ngModel)]="plantf6" (change)="plantf6 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf7"  [(ngModel)]="plantf7" (change)="plantf7 = handlePlantChange($event)"></td>
                    <td><input type="text" name="plantf8"  [(ngModel)]="plantf8" (change)="plantf8 = handlePlantChange($event)"></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="2" class="grid-backcolor" style="padding-top: 28px">Supplier</td>
                    <td class="grid-backcolor" >E</td>
                    <td><input type="text" name="supe1"  [(ngModel)]="supe1" (change)="supe1 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe2"  [(ngModel)]="supe2" (change)="supe2 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe3"  [(ngModel)]="supe3" (change)="supe3 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe4"  [(ngModel)]="supe4" (change)="supe4 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe5"  [(ngModel)]="supe5" (change)="supe5 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe6"  [(ngModel)]="supe6" (change)="supe6 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe7"  [(ngModel)]="supe7" (change)="supe7 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supe8"  [(ngModel)]="supe8" (change)="supe8 = handlePlantChange($event)"></td>
                  </tr>
                  <tr>
                    <td class="grid-backcolor">F</td>
                    <td><input type="text" name="supf1"  [(ngModel)]="supf1" (change)="supf1 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf2"  [(ngModel)]="supf2" (change)="supf2 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf3"  [(ngModel)]="supf3" (change)="supf3 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf4"  [(ngModel)]="supf4" (change)="supf4 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf5"  [(ngModel)]="supf5" (change)="supf5 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf6"  [(ngModel)]="supf6" (change)="supf6 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf7"  [(ngModel)]="supf7" (change)="supf7 = handlePlantChange($event)"></td>
                    <td><input type="text" name="supf8"  [(ngModel)]="supf8" (change)="supf8 = handlePlantChange($event)"></td>
                  </tr>
                </tbody>
            </table>
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3" (click)="close('CANCEL')"> CANCEL </button>
            <button class="submit black-button me-3" (click)="calcInsert()" [disabled]="!haveAddAccess">OK</button>
        </div>
    </div>
</div>
