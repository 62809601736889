import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { ExportStateService } from 'src/app/services/export-state.service'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-select-specialist',
  templateUrl: './select-specialist.component.html',
  styleUrls: ['./select-specialist.component.scss'],
})
export class SelectSpecialistComponent implements OnInit {
  plants: any[] = []
  plantCode: any
  specialist: any
  specialistList: any[] = []
  spCode: any
  resPlant: any[] = []
  callUpdate = false
  errorList: any[] = [];
  myControl = new FormControl()
  filteredOptions!: Observable<any[]>
  plant: any
  plantFilteredOptions: any

  constructor(
    public dialogRef: MatDialogRef<SelectSpecialistComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly iaService: InternalApprovementListService,
    private readonly trackingListService: TrackingListService,
    private readonly spinner: NgxUiLoaderService,
    private readonly state: ExportStateService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.start()
    })
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spCode = res.info?.specialistcode
        this.plant=res.info?.plantcode
      },
      error: (_err: any) => {
        this.spinner.stop()
      },
    })
    this.loadPlants()
    if (this.dataStore !== null) {
      if (this.dataStore?.isGrid) {
        this.callUpdate = true
      }
    }
  }

  loadPlants() {
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 1,
    }
    this.iaService.getAllPlant(plantObj).subscribe({
      next: (res: any) => {
        this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res]
        this.resPlant = res.map((item: { plantcode: any }) => item.plantcode);
        this.plantCode = this.plantCode ?? this.plant;
        this.plantFilteredOptions = this.plants
        this.displayFn(this.plants)
        this.callSpecialist()
      },
      error: () => {
        this.spinner.stop()
      },
    })
  }

  filterOptions() {
    this.plantFilteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plantCode?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  callSpecialist(flag?: any) {
    if (flag) {
      this.myControl.setValue('');
    }
  
    const params = {
      PlantCodes: this.plantCode === '(All)' ? this.resPlant : [this.plantCode],
    };
    const selectedOption = this.plants.find((option: any) =>
    option.plantcode === this.plantCode);
  
    const specialistObservable = this.iaService.specialistCode(params);
    if (specialistObservable && selectedOption) {
      specialistObservable.subscribe({
        next: (res: any) => {
          this.specialistList = res;
          this.specialistList.forEach((item) => {
            item.fullName = `${item.firstname.charAt(0)}.${item.lastname}`;
          });
  
         let specialist = [];
          if (this.dataStore?.specialistcode) {
            specialist = this.specialistList.find(
              (item) => item.specialistcode === this.dataStore?.specialistcode
            );
          } else {
            specialist = this.specialistList.find(
              (item) => item.specialistcode === this.dataStore
            );
          }
         if (specialist) {
            this.myControl.setValue(specialist.fullName);
          }
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value))
          );
  
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      });
    }
  }

  close(): void {
    this.dialogRef.close(this.dataStore)
  }

  update() {
    if (this.callUpdate) {
      this.updateSpecialist()
    } else {
      this.dialogRef.close(this.findSpecialistCode(this.myControl.value))
    }
  }

  findSpecialistCode(value: any) {
    let returnValue = ''
    const specialistCode = this.specialistList.find((item) => {
      return item.fullName === value
    })
    if (specialistCode) {
      returnValue = specialistCode.specialistcode
    }
    return returnValue
  }

  updateSpecialist() {
    this.errorList = [];
    this.spinner.start()
    const updateObj: any = {
      PlantCode: this.dataStore?.PlantCode,
      PartNumber: this.dataStore?.PartNumber,
      SupplierCode: this.dataStore?.SupplierCode,
      PkgStartDate: this.dataStore?.PkgStartDate,
      SpecialistCode: this.myControl.value
        ? this.findSpecialistCode(this.myControl.value)
        : '',
    }
    this.trackingListService.updateSpecialist(updateObj).subscribe({
      next: (res: any) => {
        if (res?.status) {
          this.dialogRef.close({ event: 'Specialist CloseEvent' })
        }
        else {
          this.errorList.push(res?.msg);
          this.spinner.stop()
        }
      },
      error: () => {
        this.spinner.stop()
      }
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase()
    
    const val = this.specialistList.filter((option) =>
      option.fullName?.toLowerCase().includes(filterValue),
    )

    val.sort((a, b) => a.fullName.localeCompare(b.fullName))
    return val
  }
}
