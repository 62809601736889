import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { PPFSelectBtnRender } from '../../gridrender/ppf-select-btn-render';
import { RegexFilter } from '../../regexFilter/regex-filter';
import {
  container,
  dun12,
  kanban,
  material,
  other,
  pallet,
  strap,
  topCap,
} from './selectpackageDropdowm.const';

@Component({
  selector: 'app-select-package-ppf',
  templateUrl: './select-package-ppf.component.html',
  styleUrls: ['./select-package-ppf.component.scss'],
  providers: [RegexFilter],
})
export class SelectPackagePPFComponent implements OnInit {
  public gridApi: any;
  public rowHeight = 50;
  public defaultColDef = {};
  public columnDefs: any[] = [];
  public domLayout: any = '';
  public displayRowData: any[] = [];
  public searchPackage: any;
  public validForm = false;
  public currentPageNo: any;
  public currentPageBoolean = false;
  public totalPage = 0;
  public currentPageForTotalPage = 0;
  public isLastPageFound!: boolean;
  public paginationPageSize: any = 5;
  public rowCount!: number;
  rowData: any;
  public typeDropdown: any[] = [];
  protected materialDropdown: any[] = [];
  supplierCode: any;
  public overlayLoadingTemplate = `<div class="spinner-border text-danger" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly router: Router,
    public dialogRef: MatDialogRef<SelectPackagePPFComponent>,
    public formbuilder: FormBuilder,
    public ppfService: PpfService,
    public route: ActivatedRoute,
    public regex: RegexFilter
  ) {
    this.dialogRef.disableClose = true;
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    };
  }

  ngOnInit(): void {
    sessionStorage.removeItem('rowNumber');
    this.materialDropdown = material;
    this.supplierCode = this.data?.supplierCode;
    const { rowNumber } = this.data;
    switch (rowNumber) {
      case 1:
        this.typeDropdown = container;
        break;
      case 2:
        this.typeDropdown = dun12;
        break;
      case 3:
        this.typeDropdown = dun12;
        break;
      case 4:
        this.typeDropdown = topCap;
        break;
      case 5:
        this.typeDropdown = pallet;
        break;
      case 6:
        this.typeDropdown = strap;
        break;
      case 7:
        this.typeDropdown = kanban;
        break;
      default:
        this.typeDropdown = other;
        break;
    }

    this.apiCall();

    this.columnDefs = [
      {
        headerName: 'Select',
        field: 'select',
        children: [
          {
            cellRendererFramework: PPFSelectBtnRender,
            cellRendererParams: {
              clicked: (field: any) => {
                this.btnSelected(field);
              },
            },
            width: 140,
          },
        ],
      },
      {
        headerName: 'Code',
        children: [{ width: 150, headerName: '', field: 'PACKAGECODE' }],
      },
      {
        headerName: 'type',
        children: [{ width: 70, headerName: '', field: 'PACKAGETYPECODE' }],
      },
      {
        headerName: 'description',
        children: [{ width: 230, headerName: '', field: 'DESCRIPTION' }],
      },
      {
        headerName: 'outside dimension',
        field: 'outside dimension',
        children: [
          { headerName: 'length(mm)', field: 'OUTERLENGTH', width: 100 },
          { headerName: 'width(mm)', field: 'OUTERWIDTH', width: 100 },
          { headerName: 'height(mm)', field: 'OUTERHEIGHT', width: 100 },
        ],
      },
      {
        headerName: 'inside dimension',
        field: 'inside dimension',
        children: [
          { headerName: 'length(mm)', field: 'INNERLENGTH', width: 100 },
          { headerName: 'width(mm)', field: 'INNERWIDTH', width: 100 },
          { headerName: 'height(mm)', field: 'INNERHEIGHT', width: 100 },
        ],
      },
      {
        headerName: 'weight',
        children: [{ width: 120, headerName: '', field: 'WEIGHT' }],
      },
      {
        headerName: 'material',
        children: [{ width: 120, headerName: '', field: 'MATERIAL' }],
      },
      {
        headerName: 'nest',
        children: [{ width: 80, headerName: '', field: 'NESTING' }],
      },
      {
        headerName: 'return height(mm)',
        children: [{ width: 80, headerName: '', field: 'RETHEIGHT' }],
      },
      {
        headerName: 'S/N',
        children: [{ width: 80, headerName: '', field: 'STANDARD' }],
      },
      {
        headerName: 'r/e',
        children: [{ width: 80, headerName: '', field: 'RETURNABLE' }],
      },
      {
        headerName: 'quality k holder',
        children: [{ width: 80, headerName: '', field: 'QTYKHOLDER' }],
      },
    ];
    this.searchPackage = this.formbuilder.group({
      code: [''],
      type: ['All'],
      material: [''],
      sn: ['All'],
      dimension: ['outside'],
      lengthOver: [''],
      widthOver: [''],
      heightOver: [''],
      lengthUnder: [''],
      widthUnder: [''],
      heightUnder: [''],
    });
  }

  apiCall(){
    const lambdaSize = 500;
    const rowRangeMin = 0;
    this.ppfService
    .testGetCount(this.data.rowNumber, lambdaSize, rowRangeMin)
    .subscribe({
      next: (res: any) => {
        const data = res.flat(1)
        this.rowData = data;
        this.displayRowData = data;
        this.rowCount = this.displayRowData.length;
        if(this.rowCount === 0){
          this.gridApi?.hideOverlay();
          this.gridApi?.showNoRowsOverlay();
        }
      },
      error: (_er:any) => {
        this.gridApi?.hideOverlay();
        this.rowData = [];
        this.displayRowData = [];
        this.rowCount = 0;
      },
    });
  }
  goToNexpage() {
    this.gridApi.paginationGoToNextPage();
  }
  goToPreviousPage() {
    return this.gridApi.paginationGoToPreviousPage() + 1;
  }
  goToFirstPage() {
    this.gridApi.paginationGoToFirstPage();
  }
  goToLastPage() {
    return this.gridApi.paginationGoToLastPage() + 1;
  }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      return true;
    } else {
      return false;
    }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText(
        '#lbCurrentPage',
        this.gridApi.paginationGetCurrentPage() + 1
      );
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === 'ALL') {
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi?.showLoadingOverlay();
  }

  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: params });
  }

  onSearch() {
    let {
      code,
      dimension,
      lengthUnder,
      lengthOver,
      widthOver,
      widthUnder,
      heightOver,
      heightUnder,
      sn,
      material,
      type
    } = this.searchPackage.value;
    let filteredData = this.rowData;
    if (sn === 'All') {
      sn = '*';
    }
    if (material === 'All') {
      material = '*';
    }
    const filterParams: any = {
      PACKAGECODE: code ? code : '*',
      STANDARD: sn ? sn : '*',
      MATERIAL: material.value ? material.value : '*',
    };

    filteredData = this.regex.regexFilter(filteredData, filterParams);

    const selectedType = this.typeDropdown.find(c => c.value.trim() === type.trim());
    if (selectedType.name!=="All") {
      filteredData = filteredData.filter((item:any) => {
        const match = item.PACKAGETYPECODE === selectedType.value;
        return +match;
      });
    }else if (selectedType.name==="All"){
      this.gridApi.setRowData(this.rowData);
      this.rowCount = this.rowData.length;     
    }
    const selectedMaterial = this.materialDropdown.find(c => c.value.trim() === material.trim());
    if (selectedMaterial?.name!=="All") {
      filteredData = filteredData.filter((item:any) => {
        const match = item?.MATERIALCODE === selectedMaterial?.value;
        return +match;
      });
    }else if (selectedMaterial?.name==="All"){
      this.gridApi.setRowData(this.rowData);
      this.rowCount = this.rowData.length;     
    }



    if (dimension === 'outside') {
      if (lengthOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERLENGTH > +lengthOver;
        });
      }
      if (lengthUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERLENGTH < +lengthUnder;
        });
      }
      if (widthOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERWIDTH > +widthOver;
        });
      }
      if (widthUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERWIDTH < +widthUnder;
        });
      }
      if (heightOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERHEIGHT > +heightOver;
        });
      }
      if (heightUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.OUTERHEIGHT < +heightUnder;
        });
      }
    } else {
      if (lengthOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERLENGTH > +lengthOver;
        });
      }
      if (lengthUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERLENGTH < +lengthUnder;
        });
      }
      if (widthOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERWIDTH > +widthOver;
        });
      }
      if (widthUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERWIDTH < +widthUnder;
        });
      }
      if (heightOver) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERHEIGHT > +heightOver;
        });
      }
      if (heightUnder) {
        filteredData = filteredData.filter((item: any) => {
          return +item.INNERHEIGHT < +heightUnder;
        });
      }
    }

    this.gridApi.setRowData(filteredData);
    this.rowCount = filteredData.length;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  newPackage() {
    sessionStorage.setItem('rowNumber', this.data.rowNumber);
    this.router.navigate([`/tracking/ppf/${this.supplierCode}/new-package`], {
      relativeTo: this.route,
    });
    this.dialogRef.close();
  }
}
