import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HazmatApprovementListService {
 
  URL2 = this.constantsService.INTERNAL;
  URL5 = this.constantsService.TRACKING;
  plantdata: any;
  hazmatFilter: any;
  filterdata: any;
  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  getAllPlant(plantObj: any): Observable<any> {
    return this.http.post(this.URL2 + 'common/getPlant', plantObj, { 
      headers: this.constantsService.internalHttpOptions })
    .pipe(catchError(this.handleError))
  }

  loadHzAppListDetails(params: any): Observable<any> {
    return this.http.post<any>(this.URL5 + 'trackingList/hazmatonload', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  hzDeptAppOnload(params: any): Observable<any> {
    return this.http.post<any>(this.URL2 + 'trackingList/hzDeptAppOnload', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  sethazmatFilters(hazmatFilter: any) {
    this.hazmatFilter = hazmatFilter;
  }
  gethazmatFilters() {
    return this.hazmatFilter;
  }
  sethazmatPlant(plant: any) {
    this.plantdata = plant;
  }
  gethazmatPlant() {
    return this.plantdata;
  }
 
  sethazmatGridFilter(filter: any) {
    this.filterdata = filter;
  }
  gethazmatGridFilter() {
    return this.filterdata;
  }
  resethazmatFilters(){
    this.hazmatFilter = '';
  }
  resethazmatGridfilter(){
    this.filterdata = '';
  }
  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }
}
