
<div class="popup-container">
  <h3 mat-dialog-title class="p-2 shadow-sm">{{headerName}}</h3>
  <div mat-dialog-content>
        <p class="p-2">{{message}} ?</p>
      </div>
      <div class="d-flex flex-row-reverse align-items-center p-2">
        <button (click)="deleteEvent()" class="black-button mx-3"  >Yes</button>
 
        <div mat-dialog-close (click)="close()" class="button text-primary mx-3">No</div>
       
    </div>
</div>

 