<div class="row">
    <div class="col-md-4">
        <p class="page-title mx-3">Send Dept.Approval(Pre.PPF)</p>
    </div>
    <div class="col-md-8 d-flex justify-content-between align-items-center">
        <h5 class=" mx-3">Supplier Questionnaire</h5>
        <button class="help-btn" style="right:24px;position:relative"(click)="help()">Help</button>

    </div>
</div>

<div class="row m-3 mt-0 content-row pb-5">
    <div class="col-md-4 align-items-center px-0">
        <table class="table" aria-describedby="leader-plant">
            <th class = "d-none"></th>
            <tbody class="d-flex">
                <div class="col-md-6 titleColumn">
                    <div *ngFor="let title of leaderPlant" class="contents ps-3 py-2">
                        {{title}}
                    </div>
                </div>
                <div class="col-md-6 contentColumn p-0">
                    <div *ngFor="let title of leaderPlantContents; let i=index" class="ps-4 py-2"
                        [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                        {{title}}
                    </div>
                </div>
            </tbody>
        </table>

        <div class="d-flex align-items-baseline ps-0 mt-3">
            <div class="col-md-6 titleColumn p-0">
                <div *ngFor="let title of qpc" class="contents ps-3 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-6 contentColumn p-0">
                <div *ngFor="let title of qpcContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 align-items-center questionnaire-container">
        <div class="d-flex align-self-center titleColumn">
            <h6 class="mb-0 py-2 ps-3">Reply to Toyota</h6>
        </div>
        <table class="table mt-3" aria-describedby="part-data">
            <thead>
                <tr class="titleColumn">
                    <th scope="col" class="ps-3">Part Data Evaluation</th>
                    <th scope="col" class="ps-3"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="contentColumn">
                    <td class="ps-3 w-75">1. Is part number and description correct? <br />
                        If not, please enter the correct part number or description.
                    </td>
                    <td>
                        <table aria-describedby="q1">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <td class="d-flex ">
                                        <input value="2" name="rdoYN_1_1" type="radio" class="me-3" id="rdoYes_1_1" [checked]="rdoYes11Checked" disabled>Yes
                                        <input value="2" name="rdoYN_1_1" type="radio" id="rdoNo_1_1" [checked]="rdoNo11Checked" class="ms-5 me-3" disabled>No
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input name="txtNote_1_1" type="text" id="txtNote_1_1" disabled [(ngModel)]="txtNote11Value">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">2. Is this the correct supplier code? <br />
                        If not, please identify the correct code if possible.
                    </td>
                    <td>
                        <table aria-describedby="q2">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <td class="d-flex ">
                                        <input value="2" name="rdoYN_1_2" type="radio" class="me-3" id="rdoYes_1_2" [checked]="rdoYes12Checked" disabled>Yes
                                        <input value="2" name="rdoYN_1_2" type="radio" id="rdoNo_1_2" class="ms-5 me-3" disabled [checked]="rdoNo12Checked">No
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input name="txtNote_1_2" type="text" id="txtNote_1_2" disabled [(ngModel)]="txtNote12Value">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">3. Part data availability date.</td>
                    <td>
                        <table aria-describedby="q3">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <input type="text" disabled *ngIf="q13date" [(ngModel)]="q13date"/>
                                    <input type="date" disabled *ngIf="!q13date"/>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">4. Sample part availability date.</td>
                    <td>
                        <table aria-describedby="q4">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <input type="text" disabled *ngIf="q14date" [(ngModel)]="q14date"/>
                                    <input type="date" disabled *ngIf="!q14date"/>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table mt-3" aria-describedby="pkg-evaluation">
            <thead>
                <tr class="titleColumn">
                    <th scope="col" class="ps-3">Package Evaluation</th>
                    <th scope="col" class="ps-3"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="contentColumn">
                    <td class="ps-3 w-75">1. Expected concept evaluation date.</td>
                    <td>
                        <table aria-describedby="q11">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <input type="text" disabled *ngIf="q21date" [(ngModel)]="q21date"/>
                                    <input type="date" disabled *ngIf="!q21date"/>
                                  </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">2. Evaluation method.</td>
                    <td>
                        <mat-select class="mat-sel" name="sel_2_2" id="sel_2_2" size="1" 
                        style="width: 100%" disabled [(ngModel)]="sel22SelectedIndex">
                            <mat-option *ngFor="let opt of sel22Items" [value]="opt.index" >{{opt.value}}</mat-option>
                        </mat-select>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">3. Concept evaluation.</td>
                    <td>
                        <mat-select class="mat-sel" name="sel_2_3" id="sel_2_3" size="1" style="width: 100%" 
                        disabled [(ngModel)]="sel23SelectedIndex">
                            <mat-option *ngFor="let opt of sel23Items" [value]="opt.index" >{{opt.value}}</mat-option>
                        </mat-select>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">4. Reason for modification or new proposal?</td>
                    <td>
                        <mat-select class="mat-sel" name="sel_2_4" id="sel_2_4" size="1" style="width: 100%" 
                         disabled [(ngModel)]="sel24SelectedIndex">
                            <mat-option *ngFor="let opt of sel24Items" [value]="opt.index" >{{opt.value}}</mat-option>
                        </mat-select> <br />
                        <input name="txtNote_2_4" type="text" id="txtNote_2_4" class="mt-2" disabled [(ngModel)]="txtNote24Value">
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">5. If modification or new concept is needed, can another exact Toyota <br>
                        packaging
                        design be used?</td>
                    <td>
                        <table aria-describedby="q55">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <td>
                                        <input value="2" name="rdoYN_2_5" type="radio" class="me-3" id="rdoYes_2_5" [checked]="rdoYes25Checked" disabled>Yes</td>
                                    <td><input value="2" name="rdoYN_2_5" type="radio" id="rdoNo_2_5"  [checked]="rdoNo25Checked" disabled class="ms-5 me-3">No
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">
                <tr>6. Was the above part copied into the PPF?</tr> <br />
                <tr class="d-flex ps-3">Part Number </tr><br />
                <tr class="d-flex ps-3">Model Code</tr>
                </td>
                <td>
                    <table aria-describedby="q66">
                        <th id="empty"></th>
                        <tbody>
                            <tr>
                                <td class="d-flex ">
                                    <input value="2" name="rdoYN_2_6" type="radio" class="me-3" id="rdoYes_2_6" [checked]="rdoYes26Checked" disabled>Yes
                                    <input value="2" name="rdoYN_2_6" type="radio" id="rdoNo_2_6" [checked]="rdoNo26Checked" class="ms-5 me-3" disabled>No
                                </td>

                            </tr>
                            <tr>
                                <td><input name="txtPart_2_6" type="text" id="txtPart_2_6" class="mt-2" disabled [(ngModel)]="txtPart26Value"></td>
                            </tr>
                            <tr>
                                <td><input name="txtModel_2_6" type="text" id="txtModel_2_6" class="mt-2" disabled [(ngModel)]="txtModel26Value"></td>
                            </tr>
                        </tbody>
                    </table>
                </td>

                </tr>
                <tr class="contentColumn">
                    <td class="ps-3">7. Based on the revised proposal, is a newdunnage design needed?</td>
                    <td>
                        <table aria-describedby="q77">
                            <th id="empty"></th>
                            <tbody>
                                <tr>
                                    <td>
                                        <input value="2" name="rdoYN_2_7" type="radio" class="me-3" id="rdoYes_2_7" [checked]="rdoYes27Checked" disabled>Yes</td>
                                    <td><input value="2" name="rdoYN_2_7" type="radio" id="rdoNo_2_7"  [checked]="rdoNo27Checked" class="ms-5 me-3" disabled>No
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom-r">
    <button class="cancel me-3 roboto-bold" (click)="cancel()"> BACK </button>
</div>