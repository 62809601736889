import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DiscrepancyAddComponent } from '../discrepancy-add/discrepancy-add.component';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';


@Component({
  selector: 'app-discrepancy-list-regpartsize',
  templateUrl: './discrepancy-list-regpartsize.component.html',
  styleUrls: ['./discrepancy-list-regpartsize.component.scss']
})
export class DiscrepancyListRegpartsizeComponent implements OnInit {
  defaultColDef: {};
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  currLength='';
  currWidth='';
  currHeight='';
  currWeight='';
  // radioVal: boolean=false;
  public domLayout: any = '';
  radioVal: any = '1';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  plantcode:any;

  constructor(public dialogRef: MatDialogRef<DiscrepancyListRegpartsizeComponent>,public selectdialog:MatDialog,
    private readonly spinnerService: NgxUiLoaderService,private readonly DiscrepancyListService:DiscrepancyListService,
    @Inject(MAT_DIALOG_DATA) public dialog: any) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
    };
  }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.spinnerService.start();
    this.plantcode=localStorage.getItem('plantcode')
    const updateObject = {
      "PartNumber": this.dialog.partnumber,
      "Length": this.dialog.partlength,
      "Width": this.dialog.partwidth,
      "Height": this.dialog.partheight,
      "Weight": this.dialog.partweight,
      "SupplierCode":this.dialog.suppliercode,
      "SpecialistCode":this.dialog.specialistcode,
      "ModelCode":this.dialog.model,
      "PlantCode":this.plantcode
    }

    this.DiscrepancyListService.onloadregPart(updateObject).subscribe({
      next: (res: any) => {
        this.currLength=res.CurrentPart[0].length;
        this.currWidth=res.CurrentPart[0].width;
        this.currHeight=res.CurrentPart[0].height;
        this.currWeight=res.CurrentPart[0].weight;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },

    })

  }

  nextBt(){
    localStorage.setItem('redo',this.radioVal);
    this.selectdialog.open(DiscrepancyAddComponent,{width:'800px',data:this.dialog})
  this.dialogRef.close();
  }


  onNoClick(){
    this.dialogRef.close();
  }

}
