import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-dispose-package',
  templateUrl: './dispose-package.component.html',
  styleUrls: ['./dispose-package.component.scss'],
})
export class DisposePackageComponent implements OnInit {
  ringiList: any = [];
  reasonList: any = [];
  routeParams: any;
  haveAccess = false;
  disposeForm = new FormGroup({
    ringi: new FormControl('',[Validators.required]),
    reason: new FormControl('',[Validators.required]),
    actualDate: new FormControl('',[Validators.required]),
    commentSupplier: new FormControl(''),
    internal: new FormControl(''),
    package2: new FormControl(),
    container2: new FormControl(),
    container3: new FormControl('',[Validators.required]),
    container4: new FormControl({value : 0, disabled : true}),
    other4: new FormControl(0),
    grandTotal : new FormControl({value : 0, disabled : true})
  });
  response: any;
  userInfo: any;
  package1: any;
  container1: any;
  dunnage11: any;
  dunnage21: any;
  top1: any;
  pallet1: any;
  stretch1: any;
  kanban1: any;
  otherpkg1: any;
  errorList: string[]=[]

  constructor(
    public state: ExportStateService,
    public loader: NgxUiLoaderService,
    public service: QuantityControlService,
    public route: ActivatedRoute,
    protected router : Router
  ) { }

  ngOnInit(): void {
    this.loader.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_qtyview;
          this.userInfo = res?.info;
          this.haveAccess && this.getRouteValues();
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false;
      },
    });
  }

  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.apiCall();
      }
    });
  }

  ipChange() {
    this.changeQty();
  }

  calcPackage() {
    this.disposeForm.patchValue({
      package2 : Number(this.disposeForm.get('package2')?.value) || 0
    });
    this.changeQty();
  }

  changeQty() {
    const container2=Number(this.disposeForm.get('container2')?.value)
    const container3=Number(this.disposeForm.get('container3')?.value)
    this.disposeForm.patchValue({
      container4 :(!isNaN(container2) && !isNaN(container3)) ? container2*container3 : 0
    });
    this.calcGrandTotal();
  }

  calcGrandTotal() {
    const container4=Number(this.disposeForm.get('container4')?.value)
    const other4=Number(this.disposeForm.get('other4')?.value)
    this.disposeForm.patchValue({
      grandTotal : (!isNaN(container4) && !isNaN(other4)) ? container4 + other4 : 0
    });
  }


  apiCall() {
    const body = {
      PlantCode: this.routeParams.plant,
      SupplierCode: this.routeParams.supplier,
      Part: this.routeParams.part,
      Year: this.routeParams.year,
      Week: this.routeParams.week,
      Container: this.routeParams.container,
      dunnage1: this.routeParams.dunnage1,
      dunnage2: this.routeParams.dunnage2,
      qty_type: this.routeParams.qtytype,
      Auth: this.routeParams.auth,
    };

    this.loader.start();
    this.service.disposePackageOnload(body).subscribe({
      next: (res: any) => {
        this.loader.stop();
        if (res && !res.errMsg) {
          this.reasonList = res.ReasonData ? res.ReasonData : [];
          this.ringiList = res.RingiDropdown ? res.RingiDropdown : [];
          this.response = res;
          this.setFormValues(res?.AuthDetail)
        }else if(res.errMsg){
           this.showMessage([res.errMsg])

        }
      },
      error: (_err: any) => {
        this.loader.stop();
      },
    });
  }

  setFormValues(data: any) {
    this.package1 = data[0]?.packagecode
    this.container1 = data[1]?.packagecode
    this.dunnage11 = data[2]?.packagecode
    this.dunnage21 = data[3]?.packagecode
    this.top1 = data[4]?.packagecode
    this.pallet1 = data[5]?.packagecode
    this.stretch1 = data[6]?.packagecode
    this.kanban1 = data[7]?.packagecode
    this.otherpkg1 = data[8]?.packagecode
    const qty=Number(this.response?.AuthDetail[4]?.qty)
    const unitcost=Number(this.response?.AuthDetail[4]?.unitcost)
    
    this.disposeForm.patchValue({
      ringi: "",
      reason: "",
      package2: data[0] && Number(data[0].qty),
      container2: data[1] && Number(data[1].qty),
      container3: data[1] && Number(data[1].unitcost),
      other4 :(!isNaN(qty) && !isNaN(unitcost)) ? qty * unitcost : 0
    });
    this.changeQty()
  }


  showMessage(message:string[]){
    this.errorList=message
    setTimeout(() => {
      this.errorList=[]
    }, 8000);
  }

  onSubmit() {
    const body = {
      "PlantCode": this.routeParams?.plant ?? '',
      "SupplierCode": this.response?.SupplierName[0]?.shortname,
      "PackageCode": this.routeParams?.container,
      "ContainerCode": this.routeParams?.container,
      "Part": this.routeParams?.part,
      "Year": this.routeParams.year,
      "Week": this.routeParams.week,
      "Dunnage1Code": this.routeParams?.dunnage1,
      "Dunnage2Code": this.routeParams?.dunnage2,
      "DisposeQty": 2,
      "Mode": this.response?.Mode,
      "Auth": this.routeParams.auth,
      "UserSpecialistCode": this.userInfo?.specialistcode,
      "UserPlantCode": this.userInfo?.plantcode,
      "Comment1": this.disposeForm.get('commentSupplier')?.value,
      "Comment2": this.disposeForm.get('internal')?.value,
      "Cost": this.disposeForm.get('grandTotal')?.value,
      "Reason": this.disposeForm.get('reason')?.value,
      "selReason": this.disposeForm.get('reason')?.value,
      "RingiNumber": this.disposeForm.get('ringi')?.value,
      "ContCPP": this.disposeForm.get('container3')?.value,
      "OtherTotal": this.disposeForm.get('other4')?.value,
      "ActualDate": this.disposeForm.get('actualDate')?.value
    };
    if(this.disposeForm.valid){
    this.loader.start();
    this.service.disposeAuthorize(body).subscribe({
      next: (_res: any) => {
        this.loader.stop();
        if(_res){
          if(_res.status === 0){
            this.showMessage([_res.message])
          }else{
            this.onBack();
          }
        }else{
          const message = 'Something went wrong, Please contact System Admininstrator';
          this.showMessage([message])
        }
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }else{
    const arr=[]
    if(this.disposeForm.controls['ringi']?.invalid){
      arr.push('Required Item Error [Ringi #] is not selected')
    }
    if(this.disposeForm.controls['reason']?.invalid){
      arr.push('Required Item Error [Reason] is not selected.')     
    }
    if(this.disposeForm.controls['actualDate']?.invalid){
      arr.push('Required Item Error [actualDate]')      
    }
    if(this.disposeForm.controls['container3']?.invalid){
      arr.push('Required Item Error [Cost]')
    }
   this.showMessage(arr)
  }
  }

  onBack(){
    let uri = ''
    if (this.router.url.includes('quantityConrtol')) {
      uri = '/quantityConrtol'
    } 
    else if (this.router.url.includes('tracking-list')) {
      uri = '/tracking-list'
    }
    else if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    }else{
      uri = '/quantityConrtol'
    }
    this.router.navigateByUrl(uri);
  }

  help(){
    this.router.navigate(['./master-help',4,"Quantity Control",74])
    }
}
