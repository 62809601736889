export class RegexFilter {
    regexFilter(array:any,conditions:any){  
        return array.filter((item:any) =>{
            for(const key in conditions){
                const pattern = conditions[key].toLowerCase();                
                const regexPattern = new RegExp('^' + pattern.replace(/\*/g, '.*')+`$` );                
                if(item[key] && !regexPattern.test(item[key].toLowerCase())){
                    return false;
                }
            }
            return true;
        })
      }

    addStarForPartNumber(partNumber:any){
        if(partNumber.length <=10 && !partNumber.includes('*')){
            partNumber += '*'
        }
        return partNumber;
    }

    checkValueEqualsStar(inputObject:any){
        for (const key in inputObject) {
            if (inputObject.hasOwnProperty(key)) {
                if(inputObject[key] === '*'){
                    inputObject[key] = ''
                }                
            }
        }
        return inputObject;
    }
}
