<div id="top"></div>
<a class="page mx-3" [routerLink]="['/manual']">USER MANUAL TOP</a>
<p class="page-title mx-3">{{ routeParams.heading }}</p>
<div class="vpacs-shadow m-3 p-2">
  <div class="filter mx-2">
    <div class="d-flex flex-column">
      <div
        class="d-flex align-items-center mb-2"
        *ngFor="let menuItem of menu; let i = index"
      >
        <a
          href="javascript:void(0);"
          (click)="scrollToSection('section' + (i + 1))"
          >{{ menuItem }}</a
        >
      </div>
    </div>
  </div>
</div>
<div>
  <ng-container *ngFor="let sec of data; let i = index">
    <div [id]="'section' + i" *ngIf="i > 0">
      <div class="col-12 m-2 py-2 px-2">
        <h5 class="py-2 card-head card3-header">{{ sec.sectionHead }}</h5>
      </div>
      <div>
        <ng-container *ngFor="let section of sec.sectionData; let i = index">
          <div class="vpacs-shadow m-2 py-2 px-2 col-8">
            <table class="table" aria-describedby="mydesc">
              <ng-container>
                <thead>
                  <tr class="col-10 m-2 py-2 px-2">
                    <th colspan="3" id="screenID{{section.screenID}}" class="py-2 card3-header">
                      {{ section.sectionid }}.{{ i + 1 }}.
                      {{ section.screenname }}
                       (Screen ID = {{section.screenID}} )
                      <a href="javascript:void(0);"
                       (click)="scrollToSection('top')"
                       >[TOP]</a>
                    </th>
                  </tr>
                </thead>
              </ng-container>
              <tr class="col-10 m-2 py-2 px-2">
                <th></th>
              </tr>

              <ng-container *ngIf="section.desc">
                <thead>
                  <tr class="col-12 m-2 py-2 px-2">
                    <th colspan="3" class="py-2 card3-header">Description</th>
                  </tr>
                </thead>
                <table class="table table-bordered" aria-describedby="mydesc">
                  <th></th>
                  <tbody>
                    <tr *ngFor="let step of section.desc.split('\r\n')">
                      <td>{{ step }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <ng-container *ngIf="section.screenitem.length > 0">
                <thead>
                  <tr class="col-12 m-2 py-2 px-2">
                    <th colspan="3" class="py-2 card3-header">Screen Item</th>
                  </tr>
                </thead>
                <table class="table table-bordered" aria-describedby="mydesc">
                  <thead>
                    <tr class="col-12 m-2 py-2 px-2">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Item Type</th>
                      <th>Required</th>
                      <th>Data Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of section.screenitem">
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.itemtype }}</td>
                      <td>{{ item.required }}</td>
                      <td>{{ item.datatype }}</td>
                      <td>{{ item.desc }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <ng-container *ngIf="section.button.length > 0">
                <thead>
                  <tr class="col-12 m-2 py-2 px-2">
                    <th colspan="3" class="py-2 card3-header">Button</th>
                  </tr>
                </thead>
                <table class="table table-bordered" aria-describedby="mydesc">
                  <thead>
                    <tr class="col-12 m-2 py-2 px-2">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of section.button">
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.desc }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </table>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
