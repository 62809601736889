import { Component, OnInit } from '@angular/core';
import { UseBackupTableRender } from 'src/app/core/gridrender/use-backup-table-render';
import { UseBackupServiceService } from 'src/app/services/use-backup-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalStateService } from 'src/app/services/service/global-state.service';
@Component({
  selector: 'app-use-backup',
  templateUrl: './use-backup.component.html',
  styleUrls: ['./use-backup.component.scss']
})
export class UseBackupComponent implements OnInit {

  gridApi: any;
  columnDefs : any[] = [];
  currentPageNo  = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [] ;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  rowHeight = 40;
  paginationPageSize = 50
  supplier: any ='';
  public domLayout:any ='';
  public queryData: any;
  public useBackupData: any;
  params: any;
  metaData: any;

  constructor(
    public useBackup: UseBackupServiceService, 
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    public location :Location,
    public globalState: GlobalStateService) {
    
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,     
      suppressPaginationPanel:true,   
      autoHeight: true,   
      suppressHorizontalScroll: true,
      filterParams: { newRowsAction: 'keep' },
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    
  }

  ngOnInit(): void {    
    if(sessionStorage.hasOwnProperty("queryParams")){
      this.metaData = JSON.parse(sessionStorage.getItem('queryParams') || '');
    }
    this.metaData && this.apiCall()
   
    this.supplier = this.route.snapshot.params['supplier'];
    
    this.columnDefs = [
      {
        headerName: "Auth#", field: "AUTHNUMBER", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 70
      },
      {
        headerName: "Start Date", field: "SD", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 80,
        comparator: (valueA:any, valueB:any, _nodeA:any, _nodeB:any, _isInverted:any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: "End Date", field: "ED", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 70,
        comparator: (valueA:any, valueB:any, _nodeA:any, _nodeB:any, _isInverted:any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: "Status", field: "DISPLAY_STATUS", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 70,cellStyle: this.cellStyle
      },
      {
        headerName: "Attachment", field: "attachment", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 70,cellRendererFramework: UseBackupTableRender
      },
      {
        headerName: "Download", field: "download", unSortIcon: true, width: 70,cellRendererFramework: UseBackupTableRender
      },
      {
        headerName: "Update", field: "update", unSortIcon: true, width: 70,cellRendererFramework: UseBackupTableRender
      },
      {
        headerName: "Tracking", field: "tracking", unSortIcon: true, width: 70,cellRendererFramework: UseBackupTableRender
      },
      {
        headerName: "End Use/Request Payment",field: "end use/request payment", unSortIcon: true, width: 100,cellRendererFramework: UseBackupTableRender,
        cellRendererParams: {
          reloadApi: () => {
            this.apiCall();
          },
        },
      },
    ];
  }

  filterChanged(_event: any) {
    const filter = this.gridApi?.getFilterModel();
    this.globalState.ubPage(filter)
  }

  apiCall(){
    this.useBackup.loadUseBackupPage(this.metaData).subscribe({
      next: (data)=>{   
        this.params = data.params;
        this.useBackupData = data.meta;
        const tempRowData = data?.auth; 
        tempRowData && tempRowData.forEach((element:any) => {
          if(element.RESAPPROVAL === 0){
            element['DISPLAY_STATUS'] = 'Waiting Use Approve';
            element['VALUE'] = 0;
            element['ISTATUS'] = 0;       
          }else if (element.RESAPPROVAL === 1){
            element['DISPLAY_STATUS'] = 'Usage Approved';
            element['VALUE'] = 1;
            element['ISTATUS'] = 1; 
          }else if (element.RESAPPROVAL === 2){
            element['DISPLAY_STATUS'] = 'Usage Rejected';
            element['VALUE'] = 2;
            element['ISTATUS'] = 0; 
          }else if (element.RESAPPROVAL === 3){
            element['VALUE'] = 3;
            element['DISPLAY_STATUS'] = 'Track Use Qty';
            element['ISTATUS'] = 9;
            if(element.PCSTATUS != null || element.PCSTATUS === ''){
              element['DISPLAY_STATUS'] = element.STATUS;
              element['ISTATUS'] = element.STATUSFLAG;
            }
          }
          element['model'] = this.useBackupData.model

        });
        if(tempRowData){
          this.displayRowData = tempRowData;  
        }
        this.rowCount = this.displayRowData.length; 
      },
    });
  }

  cellStyle(params:any) {
    if (params.value === "Rejected") {
      return { 'color': '#D00000'};
    }
    else{
      return null;
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage()}
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1}
  goToFirstPage() { this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();   
    if (this.currentPageNo === 0) { return true; }
    else { return false; }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }


  onPaginationChanged() {
    if (this.gridApi) {
      this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }
  
  onPageSizeChanged(event: any) {
    if (event.target.value === "ALL"){
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    }else{
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  setText(selector: string, text: any) {
    if(this.rowCount>0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
          }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }


  onGridReady(params:any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi.sizeColumnsToFit();
    if (this.globalState.getUbpage()) {
      const retrivedValue = this.globalState.getUbpage()
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  btnClick(btnName:any) {
    this.router.navigate([`${btnName}`],{relativeTo: this.route}); 
  }
}
