import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class ModelListService {

  URL = this.constantsService.INTERNAL;
  filterdata: any;
  modelFilter: any;
  plantdata: any;
  filterdata2: any;
  modelFilter2: any;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  loadModellistOnload(modelObject: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingModelList/onload', modelObject,
      {
        headers: this.constantsService.internalHttpOptions, responseType: 'json',
      }).pipe(
        catchError(this.handleError)
      )
  }
  updateOnloadModellist(addObject: any): Observable<any> {
    return this.http.post<any>(this.URL + 'updateOnload', addObject, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  updateBtnModellist(addObject: any): Observable<any> {
    return this.http.put<any>(this.URL + 'update', addObject, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  addModellist(addObject: any): Observable<any> {
    return this.http.put<any>(this.URL + 'addModelList', addObject, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  getAllPlant(plantObj: any): Observable<any> {

    return this.http.post(this.URL + 'common/getPlant',
      plantObj, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  connectOnload(connectObject: any): Observable<any> {
    return this.http.post(this.URL + 'connectOnload', connectObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  deleteModellist(deleteObject: any): Observable<any> {
    return this.http.delete(this.URL + 'modelListDelete',
      { body: deleteObject, headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }


  compareOnload(compareObject: any): Observable<any> {
    return this.http.post(this.URL + 'modelList/compareOnLoad', compareObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  connectAllOnload(_connectAllObject: any): Observable<any> {
    return this.http.get(this.URL + 'connectAllOnLoad', 
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  connectAll(connectAllObject: any): Observable<any> {
    return this.http.put(this.URL + 'connectAll',
      connectAllObject, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  familyCode(): Observable<any> {
    return this.http.get(this.URL + 'getFamilyCode',
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  partListDownload(partlistObject: any): Observable<any> {
    return this.http.post(this.URL + 'listDownload', partlistObject,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }
  
  reportListDownload(ringinumber: any): Observable<any> {
    return this.http.get(this.URL + `modelListReportDownload?ringiNumber=${ringinumber}`,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  importNext(importObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/next', importObject,
      { headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  replaceOnload(replaceObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/replace/onload',
      replaceObject, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  replaceAdd(replaceAddObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/replace/addbutton',
      replaceAddObject, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  deleteOnload(deleteObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/delete/onload',
      deleteObject, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))

  }
  deleteBtnOnload(deleteBtnObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/delete/button',
      deleteBtnObject, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  newpartOnload(newpartObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/new/onload', newpartObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  newPartBtn(newBtnObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/new/button', newBtnObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))

  }

  replaceBtn(replaceBtnObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/replace/replacebutton', replaceBtnObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  replaceDetails(replaceDetailObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/replaceDetails', replaceDetailObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  supplierReplaceDetails(replaceDetailObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/supplierDetails', replaceDetailObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  ImportupdateDetails(updateDetailObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/updateDetails', updateDetailObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  ImportnewDetails(newDetailObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/newDetails', newDetailObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  deleteDetailsOnload(body: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/deleteDetails', body,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  Importbutton(newDetailObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/button', newDetailObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  confirmImportOnload(confirmObject: any): Observable<any> {
    return this.http.post(this.URL + 'trackingModelList/import/confirm/onload', confirmObject,
      { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  setfilter(filter:any){
    this.filterdata = filter;
  }

  getfilter() {
    return this.filterdata;
  }

  setmodelFilters(modelFilter: any) {
    this.modelFilter = modelFilter;
  }
  
  getmodelFilters() {
    return this.modelFilter;
  }
  
  //model-connect

  setconnectfilter(filter:any){
    this.filterdata2 = filter;
  }

  getconnectfilter() {
    return this.filterdata2;
  }

  setmodelConnectFilters(modelFilter: any) {
    this.modelFilter2 = modelFilter;
  }
  
  getmodelConnectFilters() {
    return this.modelFilter2;
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }
}
