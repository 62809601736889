export type ParentErrorCodeMap = {
  [key: number | string]: string;
};
export type DesiredErrorCodeMap = {
  [key: string]: string;
};
export const getParentErrorMessage = (numericValue: string) => {
  const errorCodes: ParentErrorCodeMap = {
    '021': 'SMTP Server Error : Contact a system administrator, please.',
    '022': 'Excel Error : Contact a system administrator, please.',
    '023': 'FTP Error : Contact a system administrator, please.',
    '024': 'Parameter File Error : Contact a system administrator, please.',
    '040': 'Upload Error : Contact a system administrator, please.',
    '099': 'Runtime Error : Contact a system administrator, please.',
    '201': 'Session Timeout',
    '202': 'URL Vaiolation',
    '203': 'Authorization Error',
    '221': 'File Format Error',
    '301': 'Duplicate Data Error',
    '302': 'Data Format Error',
    '303': 'Range Error',
    '304': 'Required Item Error',
    '305': 'Not Found in Master',
    '401': 'Login',
    '421': 'Master is changed',
    '441': 'Sending Mail',
    '442': 'Uploading File',
    '501': 'Page Load',
    '502': 'Constructor',
    '503': 'Changing Status',
    '999': 'Trace',
    '399': 'Data Error.',
    '222': 'File Type Error',
    '443': 'Download excel sheet size Error',
    '444': 'Not found data.',
    '204': 'Request Timeout',
    'API-401' : 'You need to log in to do this action.',
    'API-403' : 'You do not have permission to access the requested resource.',
    'API-404' : 'The requested resource does not exist.',
    'API-500' : 'Internal Server Error. Please try again',
    'API-EMPTY' : 'Something went wrong. Please try again/Contact system Admininstrator',
    'FILTER' : '  Your search returned too many results. Please refine the search criteria to limit the result to less than 1000 records.',
  };
  return errorCodes[numericValue] || 'Something went wrong. Please try again/Contact system Admininstrator';
};
export const getDesiredErrorMessage = (
  errorCode: string,
  index?: any,
  field?: string
) => {
  const errorCodes: DesiredErrorCodeMap = {
    ER0001:  `Line-${index}: ${field}`,
    EP0014:
      `Please change the name of PartList file to "PartList_ ${field}.xlsx"`,
    B_0001: '?',
    S_0001: '# of Records',
    S_0002: '# of Skip',
    S_0003: '# of Tracking tasks',
    S_0004:
      '(if proto part number already exists in Project/Plant, the system ignores new proto part)',
    S_0005: '1. Initial Part List',
    S_0006: '1/D',
    S_0007: '1/W',
    S_0008: '10. Pkg Authorized',
    S_0009: '11. Forecast Ready date',
    S_0010: '12. Supplier Readiness',
    S_0011: '1st Priority',
    S_0012: '2. Send PPF to Supplier',
    S_0013: '2/D',
    S_0014: '2/W',
    S_0015: '2001',
    S_0016: '2002',
    S_0017: '2003',
    S_0018: '2nd Priority',
    S_0019: '3. Toyota Recieves Pre PPF.',
    S_0020: '3/W',
    S_0021: '3rd Priority',
    S_0022: '4. Toyota Approves Pre. PPF',
    S_0023: '4/D',
    S_0024: '4/W',
    S_0025: '5. Trial Order',
    S_0026: '6. Ship to Toyota',
    S_0027: '7. Pkg Trial Approved',
    S_0028: '71 difference',
    S_0029: '8. Toyota Receives Final PPF',
    S_0030: '8/D',
    S_0031: '9. Final PPF Approval',
    SA0001: 'A',
    SA0002: 'Action',
    SA0003: 'Actual',
    SA0004: 'Actual Cost',
    SA0005: 'Actual Date',
    SA0006: 'Actual Qty.',
    SA0007: 'Actual Total',
    SA0008: 'Add Mode',
    SA0009: 'Add New Part Number Add missing parts to the part list.	',
    SA0010: 'Add New Proto Part Number Add missing parts to the DA part list.',
    SA0011: 'Address',
    SA0012: 'Adjust',
    SA0013: 'Adjust Package Quantity',
    SA0014: 'Admin',
    SA0015: 'After',
    SA0016: 'Alert',
    SA0017: 'ALL',
    SA0018: 'ALL Authority',
    SA0019: 'Alpha Code',
    SA0020: 'AM',
    SA0021: 'Approval',
    SA0022: 'Approval Cost',
    SA0023: 'Approve',
    SA0024: 'Approve as Final',
    SA0025: 'Approved',
    SA0026: 'Approver',
    SA0027: 'Attached Excel',
    SA0028: 'ATTN',
    SA0029: 'Auth',
    SA0030: 'Auth Cost',
    SA0031: 'Auth Date',
    SA0032: 'Auth Download',
    SA0033: 'Auth List',
    SA0034: 'Auth Skip',
    SA0035: 'Auth Type',
    SA0036: 'Auth#',
    SA0037: 'Auth. Total',
    SA0038: 'Authorization Form',
    SA0039: 'Authorize',
    SB0001: 'Back Up',
    SB0002: 'Before',
    SC0001: 'Calculation',
    SC0002: 'Cancel',
    SC0003: 'Caution',
    SC0004: 'Cell/Pager',
    SC0005: 'Change Date',
    SC0006: 'Change Password',
    SC0007: 'Change Status',
    SC0008: 'City',
    SC0009: 'Code',
    SC0010: 'Comment',
    SC0011: 'Comment1',
    SC0012: 'Comment2',
    SC0013: 'Commodity',
    SC0014: 'Complete',
    SC0015: 'Component',
    SC0016: 'COMPONENT NAME',
    SC0017: 'Conatainer',
    SC0018: 'Confirm',
    SC0019: 'Connect',
    SC0020: 'Connection Level',
    SC0021: 'Cont/Layer',
    SC0022: 'CONT/RACK (INCLUDE KANBAN LOCATION)',
    SC0023: 'Contact Person Information',
    SC0024: 'Contact',
    SC0025: 'Contact 1',
    SC0026: 'Contact 2',
    SC0027: 'Contact Name',
    SC0028: 'Container',
    SC0029: 'Container Gross',
    SC0030: 'Container GROSS(INCLUDE PARTS)',
    SC0031: 'Container Inside Dimension',
    SC0032: 'Container Outside Dimension',
    SC0033: 'Container Return Height',
    SC0034: 'Container Size',
    SC0035: 'Container Type',
    SC0036: 'Container(TARE)',
    SC0037: 'Container/Layer on Pallet',
    SC0038: 'Cost',
    SC0039: 'Cost Data',
    SC0040: 'Cost Estimate',
    SC0041: 'Cost Label',
    SC0042: 'Cost Management',
    SC0043: 'Cost of Label',
    SC0044: 'Cost Report',
    SC0045: 'Cost/Part',
    SC0046: 'Cost/Piece',
    SC0047: 'Country',
    SC0048: 'Current Qty.',
    SD0001: 'DA',
    SD0002: 'DA Code',
    SD0003: 'Date',
    SD0004: 'Decision Type',
    SD0005: 'Deficit',
    SD0006: 'Delay',
    SD0007: 'Delete',
    SD0008: 'Delivery Date',
    SD0009: 'Description',
    SD0010: 'Details',
    SD0011: 'Dev',
    SD0012: 'DIGITAL IMAGE',
    SD0013: 'Discrepancy Type',
    SD0014: 'Dispose',
    SD0015: 'Dispose Qty',
    SD0016: 'Division',
    SD0017: 'Division Code',
    SD0018: 'Dock',
    SD0019: 'Dock Code',
    SD0020: 'Download',
    SD0021: 'Draw',
    SD0022: 'Dunnage',
    SD0023: 'Dunnage Code',
    SD0024: 'DUNNAGE(IN CONTAINER USE DIRECTION)',
    SD0025: 'Dunnage(TARE)',
    SD0026: 'Dunnage1',
    SD0027: 'Dunnage1 Code',
    SD0028: 'Dunnage2',
    SD0029: 'Dunnage2 Code',
    SE0001: 'E',
    SE0002: 'ECI',
    SE0003: 'ECI #',
    SE0004: 'E-mail',
    SE0005: 'End Date',
    SE0006: 'EOP',
    SE0007: 'Est',
    SE0008: 'Est Cost',
    SE0009: 'Est Fix',
    SE0010: 'Est Total',
    SE0011: 'Excel DL',
    SE0012: 'Exchange Rate',
    SE0013: 'Expendable Package',
    SE0014: 'Ext',
    SF0001: 'F',
    SF0002: 'Family',
    SF0003: 'Family Code',
    SF0004: 'FAX',
    SF0005: 'File',
    SF0006: 'File Name',
    SF0007: 'Final',
    SF0008: 'Final PPF',
    SF0009: 'Final PPF Approval',
    SF0010: 'First Name',
    SF0011: 'Freq',
    SF0012: 'From',
    SF0013: 'From Date',
    SF0014: 'Full Cont. Weight',
    SF0015: 'Function description of Function',
    SG0001: 'Good',
    SG0002: 'Group Code',
    SH0001: 'H',
    SH0002: 'Hazmat',
    SH0003: 'Height',
    SH0004: 'History',
    SH0005: 'Hr/Box',
    SI0001: 'ID',
    SI0002: 'Image',
    SI0003: 'Import',
    SI0004: 'Import Mode',
    SI0005: 'Import PPF',
    SI0006: 'Imported',
    SI0007: 'Information',
    SI0008: 'Initial Part List',
    SI0009: 'Inside Dimension',
    SI0010: 'Inside Height',
    SI0011: 'Inside Length',
    SI0012: 'Inside Width',
    SI0013: 'Internal Cost',
    SI0014: 'Invalid',
    SK0001: 'Kanban Card Holder',
    SL0001: 'L',
    SL0002: 'L/T',
    SL0003: 'Label',
    SL0004: 'Last Name',
    SL0005: 'Last Update',
    SL0006: 'Layer/Pallet',
    SL0007: 'Lead',
    SL0008: 'Lead Time',
    SL0009: 'Length',
    SL0010: 'Log',
    SL0011: 'Login',
    SL0012: 'Logistics Cost',
    SM0001: 'M3/Part',
    SM0002: 'Mail',
    SM0003: 'Main Menu',
    SM0004: 'Maintenance',
    SM0005: 'Maintenance Cost',
    SM0006: 'Maintenance Cost/Box',
    SM0007: 'Manufacturer',
    SM0008: 'Master Maintenance',
    SM0009: 'Matching Current',
    SM0010: 'Material',
    SM0011: 'Maximum Container/Layer On Pallet',
    SM0012: 'Maximum Layers/Pallet',
    SM0013: 'MGR',
    SM0014: 'Middle Initial',
    SM0015: 'Model',
    SM0016: 'Model Code',
    SM0017: 'Model Name',
    SM0018: 'Monetary Unit',
    SM0019: 'move',
    SM0020: 'Move Qty',
    SM0021: 'Move To',
    SM0022: 'My Page',
    SN0001: 'N = Days',
    SN0002: 'NA',
    SN0003: 'NA Supplier Code',
    SN0004: 'Name',
    SN0005: 'Negative',
    SN0006: 'Negative auth',
    SN0007: 'Nest',
    SN0008: 'Nesting',
    SN0009: 'New Part',
    SN0010: 'New Qty',
    SN0011: 'NG',
    SN0012: 'No',
    SN0013: 'No skip data',
    SN0014: 'Non Standard',
    SN0015: 'Not confirmed(MoveToDate-3)',
    SN0016: 'Not yet',
    SN0017: 'Note',
    SN0018: 'Number',
    SN0019: 'NUMMI',
    SN0020: 'NUMMI Supplier Code',
    SO0001: 'OD',
    SO0002: 'OK',
    SO0003: 'On Actual',
    SO0004: 'Ope',
    SO0005: 'Other',
    SO0006: 'Other Model',
    SO0007: 'Other Package',
    SO0008: 'Outside Dimension',
    SO0009: 'Outside Height',
    SO0010: 'Outside Length',
    SO0011: 'Outside Width',
    SO0012: 'Over',
    SO0013: 'Over Weight',
    SP0001: 'Package',
    SP0002: 'Package Code',
    SP0003: 'Package Cost',
    SP0004: 'Package Cost Type',
    SP0005: 'Package Cost/Piece',
    SP0006: 'Package Data',
    SP0007: 'Package Fabricator',
    SP0008: 'Package Fabricator Information',
    SP0009: 'Package Type',
    SP0010: 'Packaging Contact',
    SP0011: 'PACKAGING PROPOSAL',
    SP0012: 'Pallet',
    SP0013: 'Pallet Load',
    SP0014: 'Pallet Load Gross',
    SP0015: 'PALLET LOAD(Include Parts)',
    SP0016: 'PALLET LOAD AS SHIPPED',
    SP0017: 'Pallet Only',
    SP0018: 'PALLET(TARE)',
    SP0019: 'Part',
    SP0020: 'Part #',
    SP0021: 'Part (In Packaging Direction)',
    SP0022: 'Part Description',
    SP0023: 'Part Height',
    SP0024: 'Part Information',
    SP0025: 'Part Length',
    SP0026: 'Part List',
    SP0027: 'Part M3',
    SP0028: 'Part Orientation',
    SP0029: 'Part Routing',
    SP0030: 'Part Size',
    SP0031: 'Part Vol',
    SP0032: 'Part Weight',
    SP0033: 'Part Width',
    SP0034: 'Part5',
    SP0035: 'PartM3',
    SP0036: 'PartM3(M3/Part)',
    SP0037: 'Pass 5 days',
    SP0038: 'Password',
    SP0039: 'Payment Responsibility',
    SP0040: 'Phone',
    SP0041: 'Photo',
    SP0042: 'Pick Up Location',
    SP0043: 'Pkg Authorized Due',
    SP0044: 'PKG DATA',
    SP0045: 'Pkg Fabrication',
    SP0046: 'Pkg Material',
    SP0047: 'PKG MATERIALS',
    SP0048: 'Pkg Qty',
    SP0049: 'Pkg Start Date',
    SP0050: 'Pkg Trial Approved',
    SP0051: 'Pkg Trial Due',
    SP0052: 'Plant',
    SP0053: 'Plant Code',
    SP0054: 'Plant ID',
    SP0055: 'Plant Name',
    SP0056: 'Plant Share',
    SP0057: 'Plant Specialist',
    SP0058: 'Plant Type',
    SP0059: 'Please change the name of PartList file to ',
    SP0086: 'Part Number',
    SP0061: 'Please check[Delete]',
    SP0062: 'Please Indicate other Affiliate This part/PKG is shipped to',
    SP0063: 'PO#',
    SP0064: 'Poor Efficiency',
    SP0065: 'Positive',
    SP0066: 'PPF',
    SP0067: 'PPF Approval',
    SP0068: 'PPF File',
    SP0069: 'Pre PPF',
    SP0070: 'Pre PPF Due',
    SP0071: 'Preliminary or Final',
    SP0072: 'Previous Model',
    SP0073: 'Priority',
    SP0074: 'Priority Type',
    SP0075: 'Production Volume/Day',
    SP0076: 'Progress',
    SP0077: 'Project Management',
    SP0078: 'Proposal',
    SP0079: 'Proposal with changes',
    SP0080: 'Proto Part #',
    SP0081: 'Proto Type',
    SP0082: 'Proto/Production Part#',
    SP0083: 'purchase',
    SP0084: 'Purpose',
    SQ0001: 'QPC',
    SQ0002: 'QPU',
    SQ0003: 'Qty',
    SQ0004: 'Qty Cont/Layer',
    SQ0005: 'Qty Kholder',
    SQ0006: 'Qty Label',
    SQ0007: 'Qty of Containers',
    SQ0008: 'Qty of Dunnage1',
    SQ0009: 'Qty of Dunnage2',
    SQ0010: 'Qty of Package',
    SQ0011: 'Qty of Part',
    SQ0012: 'Qty part/Piece',
    SQ0013: 'Qty/Container',
    SQ0014: 'Qty Layer/Pallet',
    SQ0015: 'Quality',
    SQ0016: 'Quantities',
    SQ0017: 'Quantity',
    SQ0018: 'Quantity Check',
    SQ0019: 'Quantity Control',
    SQ0020: 'Qty Parts/Container',
    SQ0021: 'Quantity/Cont/Rack',
    SQ0022: 'Quantity / Container',
    SR0001: 'R',
    SR0002: 'R/E',
    SR0003: 'RCAF',
    SR0004: 'Read',
    SR0005: 'Readiness Due',
    SR0006: 'Reason',
    SR0007: 'Reason Code',
    SR0008: 'Reason of Request as Final',
    SR0009: 'Reason Requested',
    SR0010: 'Reason Type',
    SR0011: 'Reason(what)',
    SR0012: 'Reason(why)',
    SR0013: 'Reject',
    SR0014: 'Replaced Part',
    SR0015: 'Replaces Part#',
    SR0016: 'Req',
    SR0017: 'Request as Final',
    SR0018: 'Requested Changes',
    SR0019: 'Require Qty',
    SR0020: 'Required',
    SR0021: 'Required Approval Cost',
    SR0022: 'Reserve',
    SR0023: 'Result',
    SR0024: 'Return Height',
    SR0025: 'Returnable Package',
    SR0026: 'Retype Password',
    SR0027: 'Reviewer',
    SR0028: 'RET/EXP',
    SR0029: 'Ringi',
    SR0030: 'Ringi #',
    SR0031: 'Ringi Cost',
    SR0032: 'Ringi Download',
    SR0033: 'Ringi Fix',
    SR0034: 'Ringi Total',
    SR0035: 'Ringi Type',
    SR0036: 'Role',
    SR0037: 'Role Name',
    SR0038: "Role's Authority",
    SS0001: 'S/N',
    SS0002: 'Safety',
    SS0003: 'Select',
    SS0004: 'Select excel-file for estimate cost',
    SS0005: 'Select excel-file for fingi cost',
    SS0006: 'Select Priority',
    SS0007: 'Send All',
    SS0008: 'Send PPF',
    SS0009: 'Send PPF to Supplier',
    SS0010: 'Send PrePPF',
    SS0011: 'Set PO#',
    SS0012: 'Ship to Toyota',
    SS0013: 'Short Name',
    SS0014: 'Sibling Part ',
    SS0015: 'Size',
    SS0016: 'Skip Auth',
    SS0017: 'Skip Type',
    SS0018: 'SOP',
    SS0019: 'Specialist',
    SS0020: 'Specialist Code',
    SS0021: 'Spent time(h)',
    SS0022: 'Standard Reports',
    SS0023: 'Start',
    SS0024: 'Start Date',
    SS0025: 'State',
    SS0026: 'Status',
    SS0027: 'Step',
    SS0028: 'Stretch Wrap/Strap',
    SS0029: 'Submit',
    SS0030: 'Supervisor',
    SS0031: 'Supervisor Name',
    SS0032: 'Supplier',
    SS0033: 'Supplier Code',
    SS0034: 'Supplier Code Type',
    SS0035: 'Supplier Contact',
    SS0036: 'Supplier Forecast',
    SS0037: 'Supplier Name',
    SS0038: 'Supplier Pick Up Location',
    SS0039: 'Supplier Readiness',
    SS0040: 'Supplier Short Name',
    SS0041: 'Supplier Signature',
    SS0042: 'Support dev',
    SS0043: 'Sysdays',
    ST0001: 'Task List',
    ST0002: 'Temporary Container Specification',
    ST0003: 'Title',
    ST0004: 'TLMS',
    ST0005: 'TLMS Code',
    ST0006: 'TMMC',
    ST0007: 'TMMC Code',
    ST0008: 'TMMC Supplier code',
    ST0009: 'TMMK',
    ST0010: 'To',
    ST0011: 'To Date',
    ST0012: 'Todays Date',
    ST0013: 'Top',
    ST0014: 'Top Cap',
    ST0015: 'Total',
    ST0016: 'Total Cost',
    ST0017: 'Total Weight',
    ST0018: 'Total/Part',
    ST0019: 'Toyota',
    ST0020: 'Toyota Approval Signature',
    ST0021: 'Toyota Approves Pre PPF',
    ST0022: 'Toyota Motor Manufacturing North America, Inc.',
    ST0023: 'Toyota Receives Final PPF',
    ST0024: 'Toyota Receives Pre PPF',
    ST0025: 'Tracking',
    ST0026: 'Tracking Correction',
    ST0027: 'Tracking Correction Information',
    ST0028: 'Trial',
    ST0029: 'Trial Dock',
    ST0030: 'Trial Due Date',
    ST0031: 'Trial Order',
    ST0032: 'Trial Plant/Dock',
    ST0033: 'Trial with Changes',
    ST0034: 'Try DA',
    ST0035: 'Type',
    SU0001: 'Under',
    SU0002: 'Under Developing',
    SU0003: 'Unit',
    SU0004: 'Update',
    SU0005: 'Update Mode',
    SU0006:
      'Update Parts List Add new parts, delete missing parts, overwrite with new data.',
    SU0007: 'Update PPF',
    SU0008: 'Update Reason',
    SU0009: 'Updated',
    SU0010: 'Usable Plant',
    SU0011: 'User',
    SU0012: 'User Name',
    SU0013: 'User Plant',
    SV0001: 'Valid',
    S_0032: '5th character of Supplier Code',
    SV0003: 'VCS',
    SV0004: 'VCS Update',
    SV0005: 'Version',
    SV0006: 'View',
    SV0007: 'V-PACS already contains this file. ',
    SW0001: 'W',
    SW0002: 'Wait Cancel',
    SW0003: 'Wait cancel auth',
    SW0004: 'Warn',
    SW0005: 'Week',
    SW0006: 'Weight',
    SW0007: 'Where',
    SW0008: 'Width',
    SW0009: 'WK',
    SW0010: 'Workability',
    SW0011: 'Working',
    SW0012: 'Write',
    SY0001: 'Yes',
    SZ0001: 'Zip Code',
    SU0014: 'Update Person',
    SW0013: 'Would you like to replace the existing file?',
    SA0040: 'Assistant Manager',
    SM0023: 'Manager',
    SG0003: 'Gram',
    SI0015: 'Inch',
    SP0085: 'Pound',
    SC0049: 'Current Part',
    SN0021: 'New',
    SI0016:
      'if part number already exists in Project/Plant, the system ignores new part',
    SE0015: 'example: weight, dates, specialist, supplier, dimensions, routing',
    SC0050: 'Current Leader',
    SF0016: 'File Name',
    SA0041: 'Attachment File Name',
    SR0039: 'Reason of approve as final',
    SR0040: 'Requested',
    SA0042: 'Attention Plant',
    SA0043: 'Attention Specialist',
    SA0044: 'Arrive Date',
    SC0051: 'Carrier of Name',
    SO0014: 'Operator',
    SC0052: 'Correction Information',
    SN0027: 'No PartNumber',
    SN0022: 'No Supplier',
    SN0023: 'No Temporary Container',
    SM0024: 'mm',
    SK0002: 'kg',
    SS0044: 'S : Standard',
    SN0024: 'N : Non Standard',
    SR0041: 'Replaces Number',
    SW0014: 'Wait',
    SN0025: 'not open',
    SS0045: 'skip',
    SW0015: 'wait SP',
    SW0016: 'wait AM',
    SW0017: 'wait MGR',
    SA0045: 'Approved PPF',
    SR0042: 'Received PPF',
    SP0087: 'Part Name',
    SC0053: 'Connect Level',
    SA0046: 'Act',
    SC0054: 'copied data',
    SN0026: 'not operated data',
    SP0088: 'Part10',
    SN0028: 'No Connect',
    SF0017: 'for Mainte',
    SF0018: 'for Proto',
    SQ0023: 'Qty of Label',
    SI0017: 'if you need delete "Draw" and "Photo" from Database',
    SS0046: 'Select Contact',
    ST0036: 'TOYOTA',
    SP0089: 'PALLET LOAD GROSS(INCLUDE PARTS)',
    EP0001: 'Please set the uploading file.',
    ES0001:
      'System does not created Excel file because of nothing authorization data.',
    SA0047: 'Auth From Date',
    SA0048: 'Auth To Date',
    SA0049: 'Actual From Date',
    SA0050: 'Actual To Date',
    EN0001: 'Not found package in PPF data.',
    ED0001: 'Data is not found.',
    EY0006: 'You must make Maintenance Ringi.',
    EQ0001: 'Quantity data is not found.',
    EP0002: "PPF doesn't have Container Code.",
    EA0001: 'Approve status is not found.',
    SC0055: 'Container Qty',
    SC0056: 'Container Unit Cost',
    SU0015: 'Unit Cost',
    SL0013: 'Lable Qty',
    SL0014: 'Lable Unit Cost',
    SO0015: 'Other Cost',
    SE0016:
      '\nExist fixed estimate cost. \nif you want to import cost data, please cancel fixed estimate cost!',
    SE0017:
      '\nExist fixed ringi cost. \nif you want to import cost data, please cancel fixed ringi cost!',
    SS0047: 'Start From Date',
    SS0048: 'Start To Date',
    SE0018: 'End From Date',
    SE0019: 'End To Date',
    EB0001: 'Before part and supplier.',
    EB0002: 'Before data is duplicated.',
    EA0002: 'After part and supplier.',
    EA0003: 'After data is duplicated.',
    ET0001: 'Type in correct [User Name] again.',
    ET0002: 'Type in correct [Password] again.',
    EA0004: 'Approval data is not found.',
    EN0002: 'Detail data is not found.',
    ED0002: 'Detail data is not found.',
    SC0057: 'Container Code',
    SC0058: 'Container Move Qty',
    SC0059: 'Container Cost / Piece',
    SD0030: 'Dunnage1 Move Qty',
    SD0031: 'Dunnage2 Move Qty',
    SD0032: 'Dunnage1 Cost / Piece',
    SD0033: 'Dunnage2 Cost / Piece',
    //"SD0015":'Lable Cost / Piece',
    //"SO0014":'Other Cost',
    //"SO0015":'Other Qty',
    SP0090: 'Plant Full Qty',
    SP0091: 'Plant Empty Qty',
    SS0049: 'Supplier Full Qty',
    SS0050: 'Supplier Empty Qty',
    SO0016: 'Old Password',
    SN0029: 'Now Using Password',
    SN0030: 'New Password',
    SS0051: 'Standard',
    SR0043: 'Returnable',
    SR0044: 'Row',
    EP0003: 'Plant Type is different from your Plant Type.',
    SD0034: 'Developer',
    SD0035: 'DA Type Code',
    SM0025: 'Material Code',
    SP0092: 'Package Fabricator Name',
    SC0060: 'Contact1 Name',
    SC0061: 'Contact1 Title',
    SC0062: 'Contact1 Phone',
    SC0063: 'Contact1 Fax',
    SC0064: 'Contact1 E-Mail',
    SC0065: 'Contact2 Name',
    SC0066: 'Contact2 Title',
    SC0067: 'Contact2 Phone',
    SC0068: 'Contact2 Fax',
    SC0069: 'Contact2 E-Mail',
    SP0093: 'Package Type Code',
    SA0051: 'AM Required Approval Cost',
    SM0026: 'MGR Required Approval Cost',
    EP0004: 'Plant must be checked.',
    ES0002:
      'Supplier administrator must be exist one in each supplier! Please check admin.',
    EC0001: 'Choose only one plant.',
    ES0003: 'Select Plant',
    EN0003: 'Not found data. Please change search condition &amp; try again.',
    EN0004: `Not select ${index} is the same as ${field}`,
    ER0002: `Line- ${index}: `,
      ER0003: `Line-${index} : No Tracking data in [${field}]`,
    ER0004: `Line-${index} : [${field}] already had leader.`,
    ET0014: 'There is no data on Excel.',
    ES0004: 'SOP From',
    ES0005: 'SOP To',
    EE0001: 'EOP From',
    EE0002: 'EOP To',
    EP0015: 'Pkg Start Date (From)',
    EP0016: 'Pkg Start Date (To)',
    EY0001: 'You must select 1st Priority.',
    EY0002: 'You must input Other Model on 1st Priority.',
    EY0003: 'You must select 2nd Priority.',
    EY0004: 'You must input Other Model on 2nd Priority.',
    EY0005: 'You must input Other Model on 3rd Priority.',
    ER0005: 'Reason of Request as Final',
    EA0005: 'Approval data is not found.',
    EI0001: 'Initial Part List Date',
    //"ES0005":'Send PPF to Supplier Date',
    ET0013: 'Toyota Receives Pre. PPF Date',
    ET0003: 'Toyota Approves Pre. PPF Date',
    ET0004: 'Trial Order Date',
    ES0006: 'Ship to Toyota Date',
    EP0017: 'Package Trial Approval Date',
    ET0005: 'Toyota Receives Final PPF Date',
    EF0001: 'Final PPF Approval Date',
    EI0002: 'Initial Part List completed Date',
    ES0007: 'Send PPF to Supplier completed Date',
    ES0008: 'Ship to Toyota completed Date',
    ET0006: 'Toyota Receives Pre. PPF completed Date',
    ET0007: 'Toyota Approves Pre. PPF completed Date',
    ET0008: 'Trial Order completed Date',
    ET0009: 'Toyota Receives Final PPF completed Date',
    EF0002: 'Final PPF Approval completed Date',
    EP0005: 'Package Trial Approved completed Date',
    ET0010: 'Tracking Status violation',
    ET0011: 'Tracking Status violation ( not exist [wait] status )',
    ET0012: 'Tracking Status violation ( [skip] status )',
    EI0003: 'Imported plant name data is not matching Tracking Plant data.',
    EI0004:
      'Imported Supplier Code data is not matching Tracking Supplier data.',
    EI0005: 'Imported Part# data is not matching Tracking Part# data.',
    EC0010: 'Container Outside Length',
    EC0002: 'Container Outside Width',
    EC0003: 'Container Outside Height',
    EC0004: 'Container Inside Length',
    EC0005: 'Container Inside Width',
    EC0006: 'Container Inside Height',
    EC0007: 'Container Return Height',
    EP0006: 'Pallet only Length',
    EP0007: 'Pallet only Widht',
    EP0008: 'Pallet only Height',
    EP0009: 'Pallet load Length',
    EP0010: 'Pallet load Width',
    EP0011: 'Pallet load Height',
    EP0012: 'Pallet Weight',
    EP0013: 'Pallet load Gross Weight',
    ED0003: 'Dunnage Weight',
    EC0008: 'Container Weight',
    EC0009: 'Container Gross Weight',
    ED0004: 'Duplicate Part #',
    SE0020: 'exist in other model',
    SS0052: 'Same Pkg Start Date',
    SD0036: 'Diff Pkg Start  Date',
    SD0037: 'Delete part (Not exist in partlist)',
    SC0070: 'Current Pkg Start Date',
    ST0037: 'Total Records',
    SN0031: 'New',
    SN0032: 'New Pkg Start Date',
    SI0019: 'is Not existing in V-PACS. [The below is NEW TRACKING]',
    SI0018: 'is Not existing in V-PACS. [The below is IgGNORED TRACKING]',
    SI0020: 'is existing in OTHER model. [The below is SAME Pkg Start Date]',
    SI0021: 'is existing in OTHER model. [The below is DIFF Pkg Start Date]',
    SI0022: 'is existing in SAME model. [The below is Same Pkg Start Date]',
    SI0023: 'is existing in SAME model. [The below is DIFF Pkg Start Date]',
    SL0016: 'Lastest',
    SA0052: 'Add',
    SC0071: 'Carry Over',
    SA0053: 'Add as new record',
    SI0024: 'Ignore',
    SO0017: 'Overwrite',
    SD0038: 'Dimension',
    SR0045: 'Routing Info',
    SD0039: 'Due',
    SL0015: 'Leader Plant',
    SN0033: 'Null data is NOT overwrited if you select overwrite.',
    SN0034: 'Null data is overwrited if you select overwrite.',
    SF0019: "File's extension must be ",
    EL0001:
      'LeaderPlant must be difference plant. Please modify leader-plant in partlist.',
    SI0025: 'Image must be under 300KB. ',
    SE0021: 'Extension must be .gif or .jpg.',
    EN0005:
      'Not set DB.CONNECT in ini file[pms.ini]. Please confirm the "DB.CONNECT" string in ini file.',
  };
  return errorCodes[errorCode] || 'Unknown error code';
};

export const getInputCheckErrorMessage = (
  errorCode: string,
  fieldname: string,
  data1?: any,
  data2?: any
) => {
  const errorCodes: DesiredErrorCodeMap = {
    '0001': `[${fieldname} Over] must be numeric.`,
    '0002': `[${fieldname} Under] must be numeric.`,
    '0003': `[${fieldname} (${data1})] must be before Today.`,
    '0004': `[${fieldname}] Date Format is (${data1}).`,
    '0005': `[${fieldname}] has prohibited character.[${data1}]`,
    '0006': `[${fieldname}] is not equal to [${data1}].`,
    '0007': `[${fieldname}] is not selected.`,
    '0008':
      `[${fieldname}] is out of range. ( ${data1 } < [${fieldname}] < ${data2} )`,
    '0009': `[${fieldname}] must be ( Over &lt; Under ).`,
    '0010': `[${fieldname}] must be ${data1} characters.`,
    '0011': `[${fieldname}] must be ${data1}.`,
    '0012': `[${fieldname}] must be at most [**.0 or **.5]`,
    '0013': `[${fieldname}] must be at most ${data1} characters.`,
    '0014': `[${fieldname}] must be before [${data1}].`,
    '0015': `[${fieldname}] must be numeric.`,
    '0016': `[${fieldname}] must be positive integer.`,
    '0017': `[${fieldname}] must be Positive Number.`,
    '0018': `[${fieldname}] must be small [${data1}].`,
    '0019': `${fieldname} is not valid extension.`,
    '0020': `${fieldname}[${data1}] Date Format is ({2}).`,
    '0021': `${fieldname}[${data1}] must be before [{2}].`,
    '0022': 'EMail Address',
    '0023': `${fieldname}] is equal to [${data1}].`,
  };
  return errorCodes[errorCode] || 'Unknown error code';
};

//305-500:Not Found in Master Line-2: DockCode
//304-461 : Required Item Error line-2 : part #
export const getFinalErrorMessage = (
  errorCode: string,
  childErrorCode: string,
  error?: string,
  fieldErrorCode?: string,
  field?: string,
  index?: number
) => {
  const firstpart = `${errorCode}-${childErrorCode}:`;
  const secondPart = getParentErrorMessage(errorCode);
  if (error !== undefined) {
    return `${firstpart} ${secondPart} ${error}`
  } else {
    // error not given logic
    if (
      fieldErrorCode !== undefined &&
      field !== undefined &&
      index !== undefined
    ) {
      const thirdpart = getDesiredErrorMessage(fieldErrorCode, index, field);
      return `${firstpart} ${secondPart} ${thirdpart}`
    } else {
      return firstpart + secondPart;
    }
  }
};
