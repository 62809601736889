<div class="popup-container">
    <header>
        <button class="close black-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
          </button>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">Update Fabricator</div>
          <div>
            <button class="help-btn" (click)="help()">Help</button>
          </div>
          </div>
    </header>

    <div class="popup-contents">
        
        <div class="row m-3 my-4 align-items-start">
            <div class="col-6 d-flex">
                <label class="catlabel col-4" for="plantList">Search</label>
                <input type="text" id="supplier" style="width: 100%;" (keyup)="onSearch($event)"/>

            </div>
            <div class="col-6 d-flex">
                <label class="catlabel col-4" for="specialistList">Fabricator</label>
                <mat-select id="plant" class="mat-sel pt-1" [(ngModel)]="plant">
                    <mat-option *ngFor="let currentplant of filteredPlants" [value]="currentplant.pkgsupcode">{{currentplant.pkgsupcode}}</mat-option>
                </mat-select>
            </div>
            
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 roboto-bold" (click)="deleteFabricator()"> DELETE </button>
            <button class="submit black-button me-3 roboto-bold" (click)="updateFabricator()">UPDATE</button>
        </div>
    </div>

</div>
