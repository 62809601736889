import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service';

@Component({
  selector: 'app-detail-auth',
  templateUrl: './detail-auth.component.html',
  styleUrls: ['./detail-auth.component.scss']
})
export class DetailAuthBtnComponent implements OnInit {
  supplierCode = [];
  plant: any;
  supplier: any;
  errorMessage: any;
  auth: any;
  details: any;

  constructor(
    public dialogRef: MatDialogRef<DetailAuthBtnComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly authListSvc: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router:Router
  ) { }
  addRingi: any;

  ngOnInit(): void {
    this.spinnerService.start();
    this.auth = this.dataStore.auth;
    this.loadData(this.auth);
  }

  loadData(authNum: any) {
    this.authListSvc.getRejectReason(authNum)
      .subscribe({
        next: (res: any) => {
          this.details = res;
          this.spinnerService.stop();
        },
        error: () => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

  closeDialog() {
    this.dialogRef.close()
  }
  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',3,"Cost Management",58])
  }
}
