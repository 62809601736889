<div class="popup-container">
    <h2 mat-dialog-title class="p-2 shadow-sm" style="font-size: 20px">{{data.header}}</h2>
    <div mat-dialog-content>
        <p class="p-2">{{data.message}}</p>
    </div>
    
    <div mat-dialog-actions class="d-flex flex-row-reverse ">
        <button mat-dialog-close class="black-button mx-2" style="cursor: pointer;"  (click)="closeDialog()">{{data.btnText || "OK"}}</button>
    </div>
    <br>
  </div>