import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-quantity-log',
  templateUrl: './quantity-log.component.html',
  styleUrls: ['./quantity-log.component.scss'],
  providers : [BreadcrumbComponent]
})
export class QuantityLogComponent implements OnInit {
  gridApi: any;
  gridApiTotal: any;

  columnDefs: any[] = [];
  displayRowData: any[] = [];
  columnDefsTotal: any[] = [];
  displayRowDataTotal: any[] = [];
  defaultColDefTotal!: {};
  
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 1;
  lastRowIndex = 0;
  gridColumnApi: any;
  gridColumnApiTotal: any;

  haveAccess: any;
  routeParams: any;
  response: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly state: ExportStateService,
    private readonly quantityService: QuantityControlService,
    public loader: NgxUiLoaderService,
    private readonly router : Router,
    public breadcrumb : BreadcrumbComponent
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      unSortIcon: true,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.loader.start();
          this.haveAccess = res.role?.p_qtyview;
          this.haveAccess && this.getRouteValues();
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false;
      },
    });
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'DATE',
        field: 'actualdate',
        valueGetter: this.dateFormatter,
        minWidth: 60,
        maxWidth: 100,
        comparator: dateComparator,
      },
      {
        headerName: 'QTY OF CONTAINER',
        field: 'pkgqty',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: 'QTY OF DUNNAGE1',
        field: 'dun1qty',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: 'QTY OF DUNNAGE2',
        field: 'dun2qty',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: 'REASON',
        field: 'reason',
        cellStyle: this.cellStyleColumn,
        width: 200,
        tooltipValueGetter: (params: any) => params.value,
      },
      {
        headerName: 'SPECIALIST',
        field: 'specialist',
      },
      {
        headerName: 'COMMENT',
        field: 'description',
        cellStyle: this.cellStyleColumn,
        width: 200,
        tooltipValueGetter: (params: any) => params.value,
      },
      {
        headerName: 'AUTH',
        field: 'authnumber',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: 'AUTH TYPE',
        field: 'authtype',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: 'WHERE',
        field: 'tosup',
        minWidth: 60,
        maxWidth: 100,
      },
    ];
  }
  dateFormatter(params: any) {
    if (params.data.actualdate) {
      let arr = []
      const date =  new Date(params.data.actualdate).toISOString().split('T')[0];
      arr = date.split('-');
      return `${arr[1]}/${arr[2]}/${arr[0]}`;
    }
    return '-';
  }

  cellStyleColumn(_params: any) {
    return {
      display: 'inline-block',
      height: '40px',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      overflow: 'hidden',
      'margin-top': 'auto',
      'margin-bottom': 'auto',
      'vertical-align': 'middle',
      position: 'absolute',
      top: '5px',
    };
  }

  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.getColumnDefs();
        this.getColumnDefsTotal();
        this.apiCall();
      }
    });
  }

  getColumnDefsTotal(){
       this.columnDefsTotal= [
        { headerName: '', field: 'TOTAL', width: 120  },
        { headerName: '', field: 'A', width: 120 },
        { headerName: '', field: 'B', width: 120 },
        { headerName: '', field: 'C', width: 120 },
      ];
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  onGridReadyTotal(params: any) {
    this.gridApiTotal = params.api;
    this.gridColumnApiTotal = params.columnApi;
    this.gridApiTotal.sizeColumnsToFit();
  }

  apiCall() {
    const body = {
      PlantCode: this.routeParams.plant,
      SupplierCode: this.routeParams.supplier,
      PackageCode: this.routeParams.pkg,
      DunnageCode: this.routeParams.dun,
      Dunnage2Code: this.routeParams.dun2,
    };
    this.gridApi?.showLoadingOverlay();
    this.quantityService.logData(body).subscribe({
      next: (res: any) => {
        if (res) {
          if (res.QtyTotal) {
            this.displayRowDataTotal = [{'TOTAL':'TOTAL','A':res.QtyTotal[0],'B':res.QtyTotal[1],'C':res.QtyTotal[2]}];
          }
          this.totalRecords = res.Data.length > 0 ? res.Data.length : 0;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.displayRowData = res.Data.length > 0 && res.Data;
          this.rowCount = this.displayRowData.length;
          this.gridApi?.setRowData(this.displayRowData);
          this.response = res;
        }
        this.loader.stop();
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = 0;
        this.totalRecords = 0;
        this.loader.stop();
        this.gridApi?.hideOverlay()
      },
    });
  }

  onBack(){
    const url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    this.router.navigate([url]);
  }
  help(){
this.router.navigate(['./master-help',4,"Quantity Control",73])
}
}
