<div class="popup-container">
    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div class="header text-middle"> Register Package Estimate</div>
        <div class="popup-contents  m-2 py-2 px-2">
            <div>
                <table aria-describedby="add-package">
                    <tr class="d-none">
                        <th>register package</th>
                    </tr>
                    <tr>
                        <td style="background-color:#e4ecf0">Package Code</td>
                        <td>{{packageCode}}</td>
                    </tr>
                    <tr>
                        <td style="background-color:#e4ecf0">Plant Type<small>*</small></td>
                        <td>{{plantType}}</td>
                    </tr>
                    <tr>
                        <td style="background-color:#e4ecf0">Cost<small>*</small> </td>
                        <td><input type="text" class="col-3" [(ngModel)]="costRate" name="costRate1"   required  (change)="change($event)">
                            <div class="errormsg"
                                *ngIf="( submitFlag || contactForm.controls['costRate1']?.dirty || contactForm.controls['costRate1']?.touched) && contactForm.controls['costRate1']?.invalid ">
                                Cost is required</div>
                        <div class="errormsg" *ngIf="numberFlag">please provide number</div> 

                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#e4ecf0">Monetary Unit<small>*</small></td>
                        <td>
                            <mat-select id="plant" class="mat-sel pt-2" [(ngModel)]="costValue" name="costValue1"
                                  required>
                                <mat-option *ngFor="let cost of costs" [value]="cost.Id">{{cost.Name}}</mat-option>
                            </mat-select>
                            <div class="errormsg"
                                *ngIf="( submitFlag || contactForm.controls['costValue1']?.dirty || contactForm.controls['costValue1']?.touched) && contactForm.controls['costValue1']?.invalid ">
                                Monetary is required</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <button type="button" class="close black-button" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-8 pt-2 pb-2">
            <button type="button" class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
            <button type="submit" class="submit black-button me-3 roboto-bold">OK</button>
        </div>
    </form>
</div>