import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-delete-btn-cell-render',
  template: `
    <button [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'" 
    class="vpacs-btn-table"
    style="width:75px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnClickedHandler($event)" >DELETE</button>
  `,
})

export class DeleteBtnCellRender implements ICellRendererAngularComp {
  userDelete: any;
  userUpdate: any;

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly state: ExportStateService,){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  disableFlag = false;
  dullFlag = false;
  buttonName: any;
  agInit(params: any): void {
    this.params = params;  
    this.getUserData();
  }
  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.userDelete = data.role.p_costdelete;
        this.userUpdate = data.role.p_costsave
          if  (!this.userDelete || Number(this.params.data.used > 0)){
            this.disableFlag = true;
            this.dullFlag = true;
          }
          else {
            this.disableFlag = false;
            this.dullFlag = false;
          }
      }
    })
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




