<div class="popup-container" *ngIf="heading !== 'Delete'">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
       
        <div class="d-flex justify-content-between align-items-center m-1 header">
            <p class="page-title">{{ data.headerName }} Role</p>
            <div>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>

    <div class="popup-contents">
        <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
            <div *ngFor="let message of errorList" class="errormsg">
              <div class="message-container">
                <span class="message-text">{{ message }}</span>
                <button type="button" class="btn-close" (click)="closeErrorMsg()"></button>
              </div>
            </div>
          </div>
        <div class="row row-cols-4 justify-content-space-between m-2 my-3 align-items-start">
            <div class="col-4">
                <div class="col-12 d-flex flex-column" *ngIf="data.headerName === 'Add'">
                    <label class="catlabel col-3" for="input2">Role Name<small class="text-danger">*</small></label>
                    <input type="text" id="part" [class.error-outline]="isRoleNameAddError || isRoleNameSpecialError" [value]="rolename" (change)="rolename = roleChange($event)"/>
                    <small class="col-3 offset-3 d-flex text-danger text-nowrap" *ngIf="isRoleNameAddError">
                        Role Name is required.
                    </small>
                    <small class="col-3 offset-3 d-flex text-danger text-nowrap" *ngIf="isRoleNameSpecialError">
                        [Role Name] has prohibited character.{{restrictedChars}}.
                    </small>
                </div>
                <div class="col-12" *ngIf="data.headerName === 'Update'">
                    <label class="catlabel col-3" for="input2">Role Name<small class="text-danger">*</small></label>
                    <input type="text" id="part" [class.error-outline]="isRoleNameUpdateError || isRoleNameSpecialError" [value]="data.rolename" (change)="data.rolename = roleChange($event)"/>
                    <small class="col-3 offset-3 d-flex text-danger text-nowrap" *ngIf="isRoleNameUpdateError">
                        Role Name is required.
                    </small>
                     <small class="col-3 offset-3 d-flex text-danger text-nowrap" *ngIf="isRoleNameSpecialError">
                        [Role Name] has prohibited character.{{restrictedChars}}.
                    </small>
                </div>
            </div>
            <div class="col-3">
                <!-- 1st api -->
                <label class="catlabel col-3 text-nowrap" for="input2">All Authority</label>
                <div class="d-flex flex-column">
                    <mat-select id="specialist" class="mat-sel" placeholder="Select" disableOptionCentering

                        [(ngModel)]="specialist" (selectionChange)="code(specialist)">
                        <mat-option *ngFor="let specialist of specialistList" [value]="specialist.authoritytypecode">
                            {{ specialist.authoritytypename }}</mat-option>
                    </mat-select>
                    <!-- 2nd api -->
                    <select size="3" class="mt-2" style="height: 80px" [(ngModel)]="save">
                        <option *ngFor="let authority of authorityRole" [value]="authority.authoritycode">
                            {{ authority.authorityname }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-1" style="margin-top:60px;padding-left:0px">
                <div class="col">
                    <div>
                <input type="button" class="vpacs-btn-table" value=">" (click)="addRoles()" />
            </div>
            <div style="margin-top: 12px;">
                <input type="button" class="vpacs-btn-table" value="<" (click)="deleteRoles()" />
            </div>
            </div>
            </div>
            <div class="col-4">
                <div class="col-12  d-flex flex-column" *ngIf="data.headerName === 'Update'">
                    <label for="input2">Role's Authority</label>
                    <select size="3" class="mt-2 " style="height: 110px" [(ngModel)]="roleAuth">
                        <option *ngFor="let authority of Role" [value]="authority.authoritycode">
                            {{ authority.authoritytype }}.{{ authority.authorityname }}
                        </option>
                    </select>
                </div>
                <div class="col-12  d-flex flex-column" *ngIf="data.headerName === 'Add'">
                    <label for="input2">Roles Authority</label>
                    <select size="3" class="mt-2" style=" height: 110px" [(ngModel)]="roleAuth">
                        <option *ngFor="let authority of Role" [value]="authority.authoritycode">
                            {{ authority.authoritytype }}.{{ authority.authorityname }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row m-2 my-2 align-items-start">
            <div class="col-6 d-flex">
                <div class="vpacs-shadow">
                    <small class="col-4 offset-0 d-flex text-danger text-nowrap" *ngIf="isTrackingError">
                        Plant for Tracking must be checked.
                    </small>
                    <table style="width: 100%; border: 1px solid #bcd1e0" aria-describedby="FOR TRACKING">

                        <th class="d-none"></th>
                        <tr style="background-color: #e4ecf0; border: 1px solid #bcd1e0">
                            <div class="m-1">
                                <th colspan="1" style="height: 1px; margin-right: 30px">
                                    <label class="checkcontainer mb-0 word-spacing">
                                        <input type="checkbox" checkedname="allNonTrades" [value]="nontrade"
                                            (change)="allNonTrades($event)" />SELECT ALL
                                        <span class="checkmark"></span>
                                    </label>
                                </th>
                                <th colspan="3" style="height: 2px">FOR TRACKING</th>
                            </div>
                        </tr>
                        <tr>
                            <div class="filter d-flex col-12 flex-wrap my-1">
                                <div class="col-12 flex-wrap d-flex">

                                    <ng-container *ngFor="let ntrd of nontrade" [class.error-outline]="isTrackingError" >
                                        <label class="checkcontainer mb-0" style="padding-left: 23px;">


                                            <input type="checkbox" name="nontrades" [checked]="ntrd.selected"
                                                [value]="ntrd.plantcode"
                                                (change)="changeTradesByCategory($event, ntrd)" />{{ ntrd.name }}
                                            <span class="checkmark"></span>
                                        </label>
                                    </ng-container>
                                </div>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="d-flex col-6" style="padding-left: 0px;">
                <div class="vpacs-shadow">
                    <small class="col-4 offset-0 d-flex text-danger text-nowrap" *ngIf="isPlantError">
                        Plant for Cost Estimate must be checked.
                    </small>
                    <table class="vpacs-shadow" style="width: 100%; border: 1px solid #bcd1e0"

                        aria-describedby="part-information">
                        <th class="d-none"></th>
                        <tr style="background-color: #e4ecf0; border: 1px solid #bcd1e0">
                            <div class="m-1">
                                <th colspan="1" style="height: 2px">
                                    <label class="checkcontainer mb-0 word-spacing">
                                        <input type="checkbox" checkedname="allPlantLists" [value]="nontradee"
                                            (change)="allPlantLists($event)" />SELECT ALL
                                        <span class="checkmark"></span>
                                    </label>
                                </th>
                                <th colspan="3" style="height: 2px; text-align: center">
                                    FOR COST ESTIMATE
                                </th>
                            </div>
                        </tr>
                        <tr>
                            <div class="filter d-flex col-12 flex-wrap my-1">
                                <div class="col-12 flex-wrap d-flex">
                                    <ng-container *ngFor="let ntrd of nontradee" [class.error-outline]="isPlantError">
                                        <label class="checkcontainer mb-0" style="padding-left: 23px;">
                                            <input type="checkbox" name="plantLists" [checked]="ntrd.selected"
                                                [value]="ntrd.plantcode"
                                                (change)="changeGetUsablePlantList($event, ntrd)" />{{ ntrd.name }}
                                            <span class="checkmark"></span>
                                        </label>
                                    </ng-container>
                                </div>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="
      footer
      d-flex
      justify-content-end
      align-items-center
      fixed-bottom
      mt-2
      pt-2
      pb-2
    ">
        <button class="cancel me-3 roboto-bold" (click)="close()">CANCEL</button>
        <button class="submit black-button me-3 roboto-bold" (click)="update()">
            {{ data.headerName }}
        </button>
    </div>
</div><br>
<div class="popup-container1"*ngIf="heading === 'Delete'">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete [{{data.rolename}}]?</p>
    </div>
    <div mat-dialog-actions class="flex-row-reverse">
        <button class="black-button mx-3" (click)="delete()">Yes</button>
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
    </div>
</div>
<br>