import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'benchmark-img-cell-renderer',
  template: `
    <div *ngIf="displayText">{{dataValue}}</div>
    <img *ngIf="displayImage" src={{dataValue}} style="cursor: pointer"  width="260" height="190" (click)="btnClickedHandler('img')">
    <a *ngIf="displayAncher" (click)="btnClickedHandler('link')">{{dataValue}}</a>
    <input *ngIf="displayRadio"[disabled]="rad" name="copy" type="radio" #radio (click)="btnClickedHandler('radio')">
  `,
})

export class BenchMarkImgCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;
  rowIndex = 0;
  dataValue: any;
  id: any;
  displayText = false;
  displayImage = false;
  displayAncher = false;
  displayRadio = false;
  rad=true;

  agInit(params: any): void {
    this.params = params;
    this.rowIndex = this.params.rowIndex;
    this.id = this.params.column.instanceId
    this.dataValue =  this.params.value
    const field = this.params.colDef.field; 
    this.text = this.params.data[field];

    switch (this.rowIndex) {
      case 5:
        if(this.dataValue){
        this.displayImage = true;
        }
        break;
      case 19:
        this.displayAncher = true;
        break;
      case 20:
        if(this.dataValue || this.params.colDef.headerName==="BLANK") {
        this.rad=false;
        }
        this.displayRadio = true;
        break;
      default:
        this.displayText = true;
        break;
    }

  }

  btnClickedHandler(ele: string) {
    this.params.clicked(this.params.column.colId, this.params.data, ele);
  }

}
