import { Component } from '@angular/core'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { DetailAuthBtnComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/detail-auth/detail-auth.component'
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { formatDate } from '@angular/common'
import * as FileSaver from 'file-saver-es'
import { ExportStateService } from 'src/app/services/export-state.service'
import { ShipmentHistoryComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/shipment-history/shipment-history.component'

@Component({
  selector: 'cost-auth-list-btn-cell-renderer',
  styles: [
    `
      button:disabled,
      button[disabled] {
        color: #bcc0c6 !important;
        cursor: no-drop;
      }
    `,
  ],
  template: `
    <div class="d-flex align-items-center gap-1">
      <button
        class="vpacs-btn-table"
        id="button"
        style="width:85px; height:30px; color:#676a6e; font-size: 11px"
        (click)="btnClickedHandler($event)"
        *ngIf="deliveryFlag"
        [disabled]="disableDelivery"
      >
        {{ buttonName }}
      </button>

      <div class="d-flex align-items-center gap-1">
        <button
          class="vpacs-btn-table"
          id="button"
          style="width:85px; height:30px; color:#676a6e; font-size: 11px"
          (click)="btnClickedHandler($event)"
          *ngIf="detailFlag"
          [disabled]="disableDetail"
        >
          {{ buttonName }}
        </button>

        <div class="d-flex align-items-center gap-1">
          <button
            class="vpacs-btn-table"
            id="button"
            style="width:85px; height:30px; color:#676a6e; font-size: 11px"
            (click)="btnClickedHandler($event)"
            *ngIf="updateFlag"
            [disabled]="disableUpdate"
          >
            {{ buttonName }}
          </button>

          <div class="d-flex align-items-center gap-1">
            <button
              class="vpacs-btn-table"
              id="button"
              style="width:85px; height:30px; color:#676a6e; font-size: 11px"
              (click)="btnClickedHandler($event)"
              *ngIf="poFlag"
              [disabled]="disablePO"
            >
              {{ buttonName }}
            </button>

            <div class="d-flex align-items-center gap-1">
              <button
                class="vpacs-btn-table"
                id="button"
                style="width:85px; height:30px; color:#676a6e; font-size: 11px"
                (click)="btnClickedHandler($event)"
                *ngIf="downloadFlag"
                [disabled]="disableDownload"
              >
                {{ buttonName }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class CostAuthListBtnCellRender implements ICellRendererAngularComp {
  spCode: any
  private params: any
  buttonName: any
  deliveryFlag = false
  detailFlag = false
  updateFlag = false
  poFlag = false
  downloadFlag = false
  disableDownload = false
  disableUpdate = false
  disableDetail = false
  disableDelivery = false
  disablePO = false
  pCostsave: any
  userInfo: any

  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly authListService: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
  ) {}
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`)
  }

  agInit(params: any): void {
    this.getRoles()
    this.params = params
    if (params.colDef.headerName === 'DELIVERY') {
      this.buttonName = 'DELIVERY'
      this.deliveryFlag = true
      this.detailFlag = false
      this.updateFlag = false
      this.poFlag = false
      this.downloadFlag = false
      if (params.data.rejectflag !== '1') {
        if (this.pCostsave === false) {
          this.disableDelivery = true
        }
      } else {
        this.disableDelivery = true
      }
    } else if (params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE'
      this.deliveryFlag = false
      this.detailFlag = false
      this.poFlag = false
      this.downloadFlag = false
      this.updateFlag = true
      if (params.data.flag === '1' && params.data.dummyflag === '0') {
        if (params.data.specialistcode !== this.userInfo?.info?.specialistcode) {
          this.disableUpdate = true
          for (let j = 0; j < this.userInfo?.role?.backupDetails.length; j++) {
            if (params.data.specialistcode === this.userInfo?.role?.backupDetails[j]) {
              this.disableUpdate = false
            }
          }
        }
      } 
      else {
        this.disableUpdate = true
      }
    } else if (params.colDef.headerName === 'PO #SET') {
      this.buttonName = 'SET PO#'
      this.deliveryFlag = false
      this.detailFlag = false
      this.updateFlag = false
      this.poFlag = true
      this.downloadFlag = false
      if (params.data.flag === '1') {
        if (this.pCostsave === false) {
          this.disablePO = true
        }
      } else {
        this.disablePO = true
      }
    } else if (params.colDef.headerName === 'EXCEL DOWNLOAD') {
      this.buttonName = 'DOWNLOAD'
      this.deliveryFlag = false
      this.detailFlag = false
      this.updateFlag = false
      this.poFlag = false
      this.downloadFlag = true
      if (params.data.flag === '0' || params.data.dummyflag === '0') {
        this.disableDownload = true
      }
    } else if (params.colDef.headerName === 'REJECT REASON') {
      this.buttonName = 'DETAIL'
      this.deliveryFlag = false
      this.detailFlag = true
      this.updateFlag = false
      this.poFlag = false
      this.downloadFlag = false
      if (params.data.rejectflag === '1') {
        if (this.pCostsave === false) {
          this.disableDetail = true
        }
      } else {
        this.disableDetail = true
      }
    }
  }

  btnClickedHandler(_event: any) {
    if (this.params.colDef.headerName === 'DELIVERY') {
      this.dialog.open(ShipmentHistoryComponent, {
        data: {
          authNumber: this.params.data.authnumber,
        },

      })
    } else if (this.params.colDef.headerName === 'UPDATE') {
      if (this.params.data?.authtype === '3') {
        sessionStorage.setItem('trialOrder', JSON.stringify(this.params.data))
        this.router.navigate(['/auth-list/trial-order'])
      } else {
        if (this.params.data?.authtype === '0') {
          this.router.navigate([
            `auth-list/dispose-package/${this.params.data.plantcode}/${this.params.data.suppliercode}/${this.params.data.partnumber}/${this.params.data.authnumber}`,
          ])
        } 
        else if (this.params.data?.authtype === '1') {
          this.router.navigate([
            `auth-list/maintenance-package/${this.params.data.plantcode}/${this.params.data.suppliercode}/${this.params.data.pkgcode}/${this.params.data.partnumber}/${this.params.data.authnumber}`,
          ])
        } 
        else if (this.params.data?.authtype === '2') {
          this.router.navigate([
            `auth-list/move-package/${this.params.data.plantcode}/${this.params.data.suppliercode}/${this.params.data.authnumber}`,
          ])
        } 
        else if (this.params.data?.authtype === '3') {
          sessionStorage.setItem('trialOrder', JSON.stringify(this.params.data))
          this.router.navigate(['/auth-list/trial-order'])
        } 
        else if (this.params.data?.authtype === '4') {
          const obj = {
            authNumber: this.params.data?.authnumber,
            specialistCode: this.params.data?.specialistcode,
            plantCode: this.params.data?.plantcode,
            flag: '',
            pkgstartdate: this.params.data?.pkgstartdate,
            partNumber: this.params.data?.partnumber,
            mode: 2
          }
          sessionStorage.setItem('createRCAF', JSON.stringify(obj))
          this.router.navigateByUrl('/auth-list/create-rcaf');
        }
      }
    } else if (this.params.colDef.headerName === 'PO #SET') {
      this.params.clicked(this.params.column.colId, this.params.data)
    } else if (this.params.colDef.headerName === 'EXCEL DOWNLOAD') {
      this.downloadExcel(this.params.data)
    } else if (this.params.colDef.headerName === 'REJECT REASON') {
      this.dialog.open(DetailAuthBtnComponent, {
        data: {
          auth: this.params.data.authnumber,
        },
        width: '40%'
      })
    }
  }

  downloadExcel(data: any) {
    this.spinnerService.start()
    this.authListService.authDownload(data.authnumber).subscribe({
      next: (res: any) => {
        const toConvert = res.Data
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`
        FileSaver.saveAs(file, res.Filename)
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : ''
  }

  getRoles() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res
        this.pCostsave = res.role?.p_costsave
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }
}
