<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Supplier Information</label>
        </div>
    </header>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <span *ngFor="let message of errorList">{{message}}</span>
      </div>
    <div class="popup-contents">
        <div class="row m-3 align-items-start">
            
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2" style="top:4px;position:relative">Plant</label>
              
                <input type="text" [(ngModel)]="plantC" [readonly]="true">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2" style="top:4px;position:relative" >Part#</label>
                <input type="text" [(ngModel)]="partC" [readonly]="true">

            </div>   
            <div class="col-4 d-flex">
                <label class="catlabel col-5" style="top:4px;position:relative">Supplier Code</label>
                <input type="text" [(ngModel)]="supplierC" [readonly]="true">
            </div>
        </div>
        <div class="row m-3 align-items-start">
            
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="input2">New Supplier Code</label>
              
                <input type="text" [(ngModel)]="newSupplier" maxlength="5">

            </div>
            </div>
      
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 roboto-bold" (click)="close()"> CANCEL </button>
            <button class="submit black-button me-3 roboto-bold" (click)="updateBtn()">UPDATE</button>
        </div>
    </div>

</div>