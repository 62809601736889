import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class BackupAuthlistService {
  URL = this.constantsService.MASTERCOST
  commonUrl = this.constantsService.INTERNAL
  plantdata: any;
  baGridSate:any ={
    sort : null,
    filter : null,
    currentpage : 0,
    totalpage : 0,
    rowcount : 0,
    pagesize : 0
  }

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  backupAuthlistOnLoad(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'BackupAuthList/OnLoad', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  getAllPlant(plantObj: any): Observable<any> {

    return this.http.post(this.URL + 'common/getPlantCost',
      plantObj, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  backupAuthDownload(dataObj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackupAuthListDownload',dataObj,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }
  
  getDisplayColumns(spObj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/getDisplayColumns',spObj,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  updateColumnInfo(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'BackupAuthList/updateColumnInfo', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  setPo(authNum: any): Observable<any> {
    return this.http.get( `${this.URL}cost/onloadSetPO/0/${authNum}`,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  updatePo(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'cost/updatePO', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadBackupAuthListDetails(params: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackUpAuthDetail',params,
      { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  downloadInvoice(invObj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackUpAuthDetail/GETPDF',invObj,
      { headers: this.constantsService.httpOptions, responseType : 'json' })
      .pipe(catchError(this.handleError))
  }
  
backupAuthApprove(approveObj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackUpAuthDetail/Approve',approveObj,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  backupAuthReject(rejectObj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackUpAuthDetail/Reject',rejectObj,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }
  getBudgeList(obj: any): Observable<any> {
    return this.http.post(this.URL + 'BackupAuthList/BackUpAuthDetail/Budget',obj,
    { headers: this.constantsService.httpOptions })
    .pipe(catchError(this.handleError))
  }
  private ppfArray: any[] = [];

  setPpfArray(data: any[]): void {
    this.ppfArray = data;
  }

  getPpfArray(): any[] {
    return this.ppfArray;
  }
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}


  //filterchanges
  retainedBackupAuthValues:any;
  setBackupAuthFilters(data:any)
  {
    this.retainedBackupAuthValues=data;
  }

  //filterchanges
  getBackupAuthFilters()
  {
    return this.retainedBackupAuthValues;
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }

  //filterchanges
  clearBackupAuthFilters()
  {
    this.retainedBackupAuthValues=''
  }
}