import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service';
import { TargetInputCellRender } from 'src/app/core/gridrender/target-input-cell-render';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-delivery-btn',
  templateUrl: './delivery-btn.component.html',
  styleUrls: ['./delivery-btn.component.scss'],
})
export class DeliveryBtnComponent implements OnInit {
  public rowData: any;

  //ag-grid-specs
  gridApi: any;
  columnDefs1: any[] = [];
  columnDefs2: any[] = [];
  displayRowData: any[] = [];
  displayRowData1: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  public domLayout: any = '';
  rowStyle: any = {};
  sum: any;
  spCode: any;
  deliveryDetails: any;
  authItems: any[] = [];
  dates: any[] = [];
  var = '';
  total = 0;
  rowClassRules: any;
  narestMonday = '';

  constructor(
    public dialogRef: MatDialogRef<DeliveryBtnComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    public datepipe: DatePipe,
    private readonly authListService: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    public router: Router
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      wrapHeaderText: true,
      suppressPaginationPanel: true,
      autoHeight: false,
      suppressHorizontalScroll: true,
      singleClickEdit: true,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  public targetcellRendererParams = {
    cellRendererFramework: TargetInputCellRender,
  };
  ngOnInit() {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    this.rebuild = this.rebuild.bind(this);
    this.loadDeliveryDetails();
    this.columnDefs1 = [
      {
        headerName: '',
        field: 'heading',
        minWidth: 100,
      },
      {
        headerName: 'Container',
        field: 'container',
        minWidth: 90,
      },
      {
        headerName: 'Dunnage1',
        field: 'dunnage1',
        minWidth: 90,
      },
      {
        headerName: 'Dunnage2',
        field: 'dunnage2',
        minWidth: 90,
      },
      {
        headerName: 'Top Cap',
        field: 'topCap',
        minWidth: 80,
      },
      {
        headerName: 'Pallet',
        field: 'pallet',
        minWidth: 70,
      },
      {
        headerName: 'Kanban Holder',
        field: 'kanbanHolder',
        minWidth: 90,
      },
      {
        headerName: 'Label',
        field: 'label',
        minWidth: 70,
      },
      {
        headerName: 'Other',
        field: 'other',
        minWidth: 70,
      },
    ];
  }

  loadDeliveryDetails() {
    this.spinnerService.start();
    const vals = ['authorized', 'shipped', 'received', 'remaining'];
    const columns = [
      'heading',
      'container',
      'dunnage1',
      'dunnage2',
      'topCap',
      'pallet',
      'kanbanHolder',
      'label',
      'other',
    ];

    const params = {
      authNumber: this.dataStore.auth, // PD130213-0068
    };
    this.authListService.loadDeliveryDetails(params).subscribe({
      next: (res: any) => {
        this.deliveryDetails = res;
        this.displayRowData1 = Object.keys(res)
          .filter((f) => vals.includes(f))
          .map((v) => {
            const content = [v.toUpperCase(), ...res[v]];
            return columns.reduce((acc: any, e: any, curIndex) => {
              acc[e] = content[curIndex];
              return acc;
            }, {});
          });
        this.rowCount = this.displayRowData1.length;
        this.generateTable2(res);
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  generateTable2(result: any) {
    const date = new Date(result.deliveryDate);
    let newdate = new Date(date.getTime() - 84 * 24 * 60 * 60 * 1000);
    const day = newdate.getDay();
    for (let i = 0; i < day; i++) {
      newdate = new Date(newdate.getTime() - 24 * 60 * 60 * 1000);
    }
    newdate = new Date(newdate.getTime() + 24 * 60 * 60 * 1000);
    const dates = [];
    for (let i = 0; i < 16; i++) {
      const m = `${newdate.getMonth() + 1}/${newdate.getDate()}`;
      dates.push(m);
      newdate = new Date(newdate.getTime() + 7 * 24 * 60 * 60 * 1000);
    }
    this.dates = dates;
    const target = result.target;
    const paData = result.planAndActual.map((p: any, ind: any) => {
      return { ...p, ...{ shipment_target: target[ind].target } };
    });
    const red = paData.reduce((acc: any, a: any) => {
      const k = Object.keys(a);
      const temp = { ...acc };
      k.forEach((e) => {
        if (temp[e]) {
          temp[e] = [...temp[e], a[e]];
        } else {
          temp[e] = [a[e]];
        }
      });
      return temp;
    }, {});
    const data = dates.map((d) => {
      return Object.keys(red).map((v) => {
        return {
          heading: v,
          ...red[v].reduce((acc: any, j: any) => {
            return { ...acc, [d]: j };
          }, {}),
        };
      });
    });
    const rows = data.reduce((acc: any, d: any) => {
      const temp = { ...acc };
      d.forEach((k: any) => {
        if (temp[k['heading']]) {
          temp[k['heading']] = { ...temp[k['heading']], ...k };
        } else {
          temp[k['heading']] = { ...k };
        }
      });
      return temp;
    }, {});
    const c = [
      'shipment_target',
      'shipment-plan',
      'shipment-actual',
      'receivement_plan',
      'receivement-actual',
    ];
    let final = c.map((k) => {
      if (k.includes('_')) {
        return {
          ...rows[k],
          schedule: k.split('_')[0].toUpperCase(),
          subschedule: k.split('_')[1].toUpperCase(),
        };
      } else {
        const nv = k.replace('-', '_');
        return {
          ...rows[nv],
          // schedule: nv.split('_')[0].toUpperCase(),
          subschedule: nv.split('_')[1].toUpperCase(),
        };
      }
    });
    final = final.map((f, _ind) => {
      const total = this.dates
        .map((d) => f[d])
        .reduce((acc: any, c: any) => {
          return acc + c;
        }, 0);
      return { ...f, total: total };
    });
    this.displayRowData = final;
    this.narestMonday = this.findmonday();
    this.columnDefs2 = [
        {
          headerName: 'Schedule',
          field: 'schedule',
          width: 140,
          pinned: 'left',
          rowSpan: this.rowSpan,
          cellClassRules: {
            'cell-span': "value==='SHIPMENT' || value==='RECEIVEMENT'",
          },
          cellStyle: {
            'background-color': '#f7f9fb',
            border: '1px solid #c6d8e0',
            'font-weight': 500,
          },
        },
        {
          headerName: '',
          field: 'subschedule',
          width: 130,
          pinned: 'left',
          cellStyle: {
            'background-color': '#f7f9fb',
            border: '1px solid #c6d8e0',
            'font-weight': 500,
          },
          // cellStyle: (_params: any) => {
          //   return { border: 'none !important' };
          // },
        },
        ...this.dates.map((d) => {
          return {
            headerName: d,
            field: d,
            width: 90,
            cellStyle: (params: any) => {
              if (params.colDef.field === this.narestMonday) {
                return { backgroundColor: 'yellow' };
              } else {
                return { backgroundColor: 'white' };
              }
            },
          };
        }),
        {
          headerName: 'Total',
          field: 'total',
          width: 100,
        },
    ];
  }

  findmonday() {
    const deliverydate = new Date(this.deliveryDetails.deliveryDate);
    const deliveryMonth = deliverydate.getMonth() + 1;
    const deliveryDay = deliverydate.getDate();
    let count = 0;
    this.dates.forEach((e) => {
      const newDates = new Date(e);
      const dateMonth = newDates.getMonth() + 1;
      const dateDay = newDates.getDate();
      if (deliveryMonth === dateMonth) {
        if (deliveryDay > dateDay || deliveryDay === dateDay) {
          this.var = e;
          count++;
        }
      } else if (deliveryMonth - dateMonth === 1) {
        if (deliveryDay < dateDay || deliveryDay === dateDay) {
          this.var = e;
          count++;
        }
      } else {
        if (count === 0) {
          this.var = '';
        }
      }
    });
    return this.var;
  }

  rowSpan(params: any) {
    const schedule = params.data?.schedule;
    let v = 1;
    if (schedule === 'SHIPMENT') {
      v = 3;
    } else if (schedule === 'RECEIVEMENT') {
      v = 2;
    }
    return v;
  }

  cellStyle(params: any) {
    if (params.value === 'delay') {
      return {
        'background-color': '#FF9966',
        color: '#FFFFFF',
        'border-radius': '10px',
      };
    } else {
      return null;
    }
  }

  rebuild() {
    this.displayRowData = this.displayRowData.map((f, ind) => {
      let total = this.dates
        .map((d) => f[d])
        .reduce((acc: any, c: any) => {
          return acc + c;
        }, 0);
      if (ind === 0) {
        total = total + this.total;
      }
      return { ...f, total: total };
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
}
