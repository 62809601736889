import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadEstBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/download-est-btn-cell-render';
import { DownloadRingiBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/download-ringi-btn-cell-render';
import { DownloadAuthBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/download-auth-btn-cell-render';
import { DetailBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/detail-btn-cell-render';
import { AuthListBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/auth-list-btn-cell-render';
import { ForecastBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/forecast-btn-cell-render';
import { CalcBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/calc-btn-cell-render';
import { EstFixBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/est-fix-btn-cell-render';
import { RingiFixBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/ringi-fix-list-btn-cell-render';
import { AddRingiComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/cost-management/add-ringi/add-ringi.component';
import { ImportRingiComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/cost-management/add-ringi/import-ringi/import-ringi.component';
import { UpdateRingiComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/cost-management/add-ringi/update-ringi/update-ringi.component';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  GridOptions,
} from 'ag-grid-community';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { DeleteBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/delete-btn-cell-render';
import { ExportStateService } from 'src/app/services/export-state.service';
import { DeleteFabComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/delete-fab/delete-fab.component';
import { UpdateRingiBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/update-ringi-btn-cell-render';
import { ConfirmDialogComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/confirm-dialog/confirm-dialog.component';
import { LegendsComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/legends/legends.component';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-ringi-list',
  templateUrl: './ringi-list.component.html',
  styleUrls: ['./ringi-list.component.scss'],
})
export class RingiListComponent implements OnInit {

  headerClass = 'child-header-color';
  headerClassNoBoarder = 'child-header-color no-border';
  ringi: any;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  rowCount = 0;
  defaultColDef: {};
  gridOptions!: GridOptions;
  icons: {};
  paginationPageSize = 10;
  isAccessible = false;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  spCode: any;
  supplier = '';
  plant: any;
  plants: any[] = [];
  plantcode = '';
  plantArray: any[] = [];
  ringinumber = '';
  familycode: any = '';
  modelcode: any = '';
  endDateFrom: any = formatDate(
    new Date().setFullYear(new Date().getFullYear()),
    'yyyy-MM-dd',
    'en-US'
  );
  endDateTo: any = '';
  startDateFrom: any = '';
  startDateTo: any = '';
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  haveAccess = false
  haveAddAccess = false
  modifyAccess = false
  deleteAccess = false
  userInfo: any
  userPlant: any = "";
  userSpCode: any = "";
  rate = 0;
  plantVal: any;
  isAll = false;
  isSearch = false;
  filteredOptions: any[] = [];
  errorList: string[] = [];


  constructor(
    private readonly dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private readonly ringiListService: RingiListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly message: MatSnackBar
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
      headerClass: 'child-header-color no-border',
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
      'row-aqua': 'node.data.ringiver === "1"'
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res?.info
          this.haveAccess = res.role?.p_costview
          this.haveAccess && this.apiCall();
          this.userPlant = res.info.plantcode
          this.userSpCode = res.info.specialistcode
          this.haveAccess && this.getcolumnDefs();
          this.haveAddAccess = res.role?.p_costsave
          this.deleteAccess = res.role?.p_costdelete
          !this.haveAccess && this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
        this.spinnerService.stop()
      },
    })
  }
  getcolumnDefs() {
    this.columnDefs = [
      {
        headerName: '', field: 'ringinumber', minWidth: 80,
        children: [
          {
            headerName: 'Ringi#', field: 'ringinumber', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 130, pinned: 'left',
          },
        ],
      },
      {
        headerName: '', field: 'STATUSNAME', minWidth: 110,
        children: [
          {
            headerName: 'Ringi Type', field: 'STATUSNAME', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, pinned: 'left',
            
          },
        ],
      },
      {
        headerName: '', field: 'familycode', minWidth: 100,
        children: [
          {
            headerName: 'Family Code', field: 'familycode', floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, sortable: true, unSortIcon: true, pinned: 'left',
            valueFormatter: this.nullDashFormatter
          },
        ],
      },
      {
        headerName: '', field: 'modelcode', minWidth: 110,
        children: [
          {
            headerName: 'Model Code', field: 'modelcode', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, pinned: 'left',
            valueFormatter: this.nullDashFormatter
          },
        ],
      },
      {
        headerName: '', field: 'plantname', minWidth: 140,
        children: [
          {
            headerName: 'Plant', field: 'plantname', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, pinned: 'left',
          },
        ],
      },
      {
        headerName: '', field: 'fromdate', minWidth: 150, valueFormatter: this.dateFormatter, comparator: dateComparator,
        children: [
          {
            headerName: 'Start Date', field: 'fromdate', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, comparator: dateComparator,
          },
        ],
      },
      {
        headerName: '', field: 'todate', minWidth: 110, valueFormatter: this.dateFormatter, comparator: dateComparator,
        children: [
          {
            headerName: 'End Date', field: 'todate', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 100, comparator: dateComparator,
          },
        ],
      },
      {
        headerName: '', field: 'productionvolume', minWidth: 180,
        children: [
          {
            headerName: 'Production Volume/Day', field: 'productionvolume', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 180,
            valueFormatter: this.nullDashFormatter
          },
        ],
      },
      {
        headerName: '', field: 'maintenancecost', minWidth: 130,
        children: [
          {
            headerName: 'Ringi Cost$(US)', field: 'maintenancecost', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 130,
            valueFormatter: this.nullDashFormatter, cellRenderer: this.getRingiCost.bind(this),
          },
        ],
      },
      {
        headerName: '', field: 'authcost', minWidth: 130,
        children: [
          {
            headerName: 'Auth Cost$(US)', field: 'authcost', sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true, filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder, minWidth: 130,
            valueFormatter: this.nullDashFormatter
          },
        ],
      },
      {
        headerName: 'Download', minWidth: 65, sortable: false, unSortIcon: false,
        children: [
          {
            headerName: 'Est', field: 'est', headerClass: this.headerClass, sortable: false, unSortIcon: false, minWidth: 70,
            cellRendererFramework: DownloadEstBtnCellRender,
          },
          {
            headerName: 'Ringi', field: 'ringiChild', headerClass: this.headerClass, sortable: false, unSortIcon: false, minWidth: 80,
            cellRendererFramework: DownloadRingiBtnCellRender,
          },
          {
            headerName: 'Auth', field: 'auth', headerClass: this.headerClass, sortable: false, unSortIcon: false, minWidth: 80,
            cellRendererFramework: DownloadAuthBtnCellRender,
          },
        ],
      },
      {
        headerName: 'Details', field: 'details', sortable: true, unSortIcon: true, minWidth: 110,
        children: [
          {
            headerName: '', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
            cellRendererFramework: DetailBtnCellRender,
          },
        ],
      },
      {
        headerName: 'Auth List', field: 'authList', sortable: true, unSortIcon: true, minWidth: 110,
        children: [
          {
            headerName: '', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
            cellRendererFramework: AuthListBtnCellRender,
          },
        ],
      },
      {
        headerName: 'Cost Forecast', field: 'costForecast', sortable: false, unSortIcon: false, minWidth: 140,
        children: [
          {
            field: '', headerClass: this.headerClassNoBoarder, minWidth: 70, sortable: false, unSortIcon: false,
            cellRendererFramework: CalcBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.calcButton(field);
              },
            },
          },
          {
            field: '', headerClass: this.headerClassNoBoarder, minWidth: 100, sortable: false, unSortIcon: false,
            cellRendererFramework: ForecastBtnCellRender,
          },
        ],
      },
      {
        headerName: 'Est Fix', field: 'estFix', sortable: true, unSortIcon: true, minWidth: 110,
        children: [{
          headerName: '', field: 'estFix', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
          cellRendererFramework: EstFixBtnCellRender,
          cellRendererParams: {
            clicked: (field?: any) => {
              this.confirmestFixRingi(field);
            },
          },
        }],
      },
      {
        headerName: 'Ringi Fix', field: 'ringiFix', sortable: true, unSortIcon: true, minWidth: 110,
        children: [{
          headerName: '', field: 'ringiFix', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
          cellRendererFramework: RingiFixBtnCellRender,
          cellRendererParams: {
            clicked: (field?: any) => {
              this.confirmRingiFix(field);
            },
          },
        }],
      },
      {
        headerName: 'UPDATE', field: 'update', sortable: true, unSortIcon: true, minWidth: 110,
        children: [{
          headerName: '', field: 'update', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
          cellRendererFramework: UpdateRingiBtnCellRender,
          cellRendererParams: {
            clicked: (field?: any, data?: any) => {
              this.updateRingi(data, field);
            },
          },
        }],
      },
      {
        headerName: 'DELETE', field: 'DELETE', sortable: true, unSortIcon: true, minWidth: 110,
        children: [{
          headerName: '', field: 'DELETE', headerClass: this.headerClassNoBoarder, sortable: false, unSortIcon: false, minWidth: 80,
          cellRendererFramework: DeleteBtnCellRender,
          cellRendererParams: {
            clicked: (field?: any) => {
              this.delete(field);
            },
          },
        }],
      },
    ];
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'startDateFrom') {
        this.startDateFrom = '';
      }
      if (id === 'endDateTo') {
        this.endDateTo = '';
      }
      if (id === 'endDateFrom') {
        this.endDateFrom = '';
      }
      if (id === 'startDateTo') {
       this.startDateTo = ''
      }
    }
  }

  openLegends() {
    this.dialog.open(LegendsComponent, {
      data: { screenName: 'ringiList' },
      width: '35%',
    });
  }

  getRingiCost(params: any) {
    if (params.data.ringitype === '2' || params.data.ringitype === '3') {
      return (params.data.maintenancecost * this.rate)?.toFixed(2).toString();
    }
    else {
      if (params.data.ringicost === null) {
        return '-';
      }
      else {
        return (params.data.ringicost * this.rate)?.toFixed(2).toString();
      }
    }
  }
  getAuthTypee(params: any) {
    if (params.data.ringitype === '1') {
      return 'New Model';
    } else if (params.data.ringitype === '2') {
      return 'Maintenance';
    } else if (params.data.ringitype === '3') {
      return 'Proto Type';
    } else {
      return '';
    }
  }
  delete(field: any) {
    const dialogRef = this.dialog.open(DeleteFabComponent, { data: { field, mode: 'Ringi' }, height: '400px', width: '550px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        const ringinumber = field.ringinumber;
        this.spinnerService.start();
        this.ringiListService.deleteRingiList(ringinumber)?.subscribe({
          next: (_res: any) => {
            this.loadRingi();
            this.spinnerService.stop();
          },
          error: (_err: any) => {
            this.spinnerService.stop();
          },
        })
      }
    })
  }

  updateRingi(colId?: string, field?: any, _data?: any) {
    field.ringitypeName = this.getAuthTypee({
      data: { ringitype: field?.ringitype },
    });

    const dialogPopup = this.dialog.open(UpdateRingiComponent, {
      data: { mode: 'UPDATE', colId, field, ringiType: field?.ringitype },
    });
    dialogPopup.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'CloseEvent') {
        if (result?.msg === 'LOADRINGI') {
          this.loadRingi();
        }
      }
    });
  }

  estFix(_field: any) {
    alert('Are you sure that you want to Fix Ringi [863L60EXP] ?');
  }

  help() {
    this.router.navigate(['./master-help', 3, "Cost Management", 40])
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    });
  }

  uploadEstimateFile() {
    const dialog = this.dialog.open(ImportRingiComponent, {
      data: { mode: 'IMPORT ESTIMATION' },
    });

    dialog.afterClosed()?.subscribe((result: any) => {
      if (!result) { return };
      if (result.data.type === 'import') {
        this.loadRingi();
        this.openMessageBox('Estimate File uploaded successfully!', 'success');
      }
    });
  }
  uploadRingiFile() {
    const dialog = this.dialog.open(ImportRingiComponent, {
      data: { mode: 'IMPORT RINGI' },
    });

    dialog.afterClosed()?.subscribe((result: any) => {
      if (result?.data?.type === 'import') {
        this.loadRingi();
        this.openMessageBox('Ringi File uploaded successfully!', 'success');
      }
    });
  }
  addRingi() {
    const dialogPopup = this.dialog.open(AddRingiComponent);
    dialogPopup.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'CloseEvent') {
        if (result?.msg === 'LOADRINGI') {
          this.loadRingi();
        }
      }
    });
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.ringiListService.getfilter()) {
      const retrivedValue = this.ringiListService.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  apiCall() {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
    const obj = {
      SpecialistCode: this.spCode,
      Type: 2,
    };
    this.ringiListService.plant(obj).subscribe({
      next: (response: any) => {
        this.resPlant = response.map(
          (item: { plantcode: any }) => item.plantcode
        );
        this.selectedPlant = [
          { plant: 'ALL', plantcode: '(All)' }, ...response,
        ];
        this.plant = '(All)';
        this.filteredOptions = this.selectedPlant
        this.displayFn(this.selectedPlant);
        this.ringiListService.setplant(this.selectedPlant);
        const plantdata = this.ringiListService.getplant();
        if (plantdata) { //check for plantcode data Retaining
          const data = this.ringiListService.getRingListFilters();
          if (data) {
            this.ringinumber = data.RingiNumber
            this.plant = data.PlantCode.length > 1 ? '(All)' : data.PlantCode[0]
            this.modelcode = data.ModelCode
            this.familycode = data.FamilyCode
            this.startDateFrom = data.FromFrom
            this.startDateTo = data.FromTo
            this.endDateFrom = data.ToFrom
            this.endDateTo = data.ToTo
          }
        }
        this.loadRingi();

      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  async loadRingi() {
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase()) ))
    this.plant = plant[0]?.plantcode
    const loadObj = {
      RingiNumber: this.ringinumber,
      PlantCode: this.plant === '(All)' ? this.resPlant : [this.plant],
      ModelCode: this.modelcode,
      RingiType: '',
      FamilyCode: this.familycode,
      FromFrom: this.startDateFrom,
      FromTo: this.startDateTo,
      ToFrom: this.endDateFrom,
      ToTo: this.endDateTo,
      MaintenanceCost: '',
      UserPlantCode: this.userPlant,
      UserSpecialistCode: this.userSpCode,
      Note: '',
      EstVer: '',
      RingiVer: '',
    };
    this.ringiListService.setRingListFilters(loadObj)
    this.spinnerService.start();
    return new Promise((resolve, reject) => {
      this.ringiListService.onloadRingiList(loadObj).subscribe({
        next: (res: any) => {
          const data = res.result;
          data.forEach((element: any) => {
            element.todate = this.dateFormatter({ value: element.todate })
            element.fromdate = this.dateFormatter({ value: element.fromdate })
            element.STATUSNAME = this.getAuthTypee({ data: { ringitype: element.ringitype } });
          });
          this.displayRowData = res.result;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = res.result.length;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.rate = res.Rate;
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
          resolve('')
        },
        error: (_err: any) => {
          this.spinnerService.stop();
          reject('')
        },
      });
    })

  }

  filterOptions() { //Filters the plant code
    this.filteredOptions = this.selectedPlant.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) { //Displays the plant code
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  dateFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null) {
      return '';
    } else {
      return params?.value
        ? formatDate(params.value, 'MM/dd/YYYY', 'en-US')
        : '';
    }
  }
  onSearch() {
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((plantCheck?.length === 0) || (!plantCheck)) {
    this.errorList = ["Please select valid plant name"];
    } else {
      this.errorList = []
      this.spinnerService.start();
      this.loadRingi();
  }
}

close() {
  this.errorList = [];
}

  confirmestFixRingi(field: any) {
    const message = field.estver === "0" ? `Are you sure that you want to Fix Estimate [${field.ringinumber}]?` : `Are you sure that you want to cancel Fix Estimate [${field.ringinumber}]?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message, header: 'EST Fix Confirmation' }, height: '400px', width: '550px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        field.estver === "0" ? this.estFixRingi(field) : this.estFixCancel(field);
      }
    })
  }

  estFixRingi(field: any): void {
    const estFixObj = {
      RingiNumber: field.ringinumber,
      EstVer: field.estver === "0" ? "1" : "0",
    };
    this.spinnerService.start();
    this.ringiListService.estFixRingiList(estFixObj)?.subscribe({
      next: (res: any) => {
        this.loadRingi();
        this.displayRowData = res?.result;
        this.rowCount = this.displayRowData?.length;
        this.totalRecords = res?.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }
  estFixCancel(field: any) {
    const ringinumber = field.ringinumber;
    this.spinnerService.start();
    this.ringiListService.estFixCancel(ringinumber)?.subscribe({
      next: (_res: any) => {
        this.loadRingi();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    })
  }

  confirmRingiFix(field: any) {
    const message = field.ringiver === "0" ? `Are you sure that you want to Fix Ringi [${field.ringinumber}]?` : `Are you sure that you want to cancel Fix Ringi [${field.ringinumber}]?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message, header: 'Ringi Fix Confirmation' }, height: '400px', width: '550px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        field.ringiver === "0" ? this.ringiFix(field) : this.ringiFixCancel(field);
      }
    })
  }

  ringiFix(field: any): void {
    const ringiFixObj = {
      RingiNumber: field.ringinumber,
      RingiVer: field.ringiver === "0" ? "1" : "0",
    };
    this.spinnerService.start();
    this.ringiListService.ringiFix(ringiFixObj)?.subscribe({
      next: (res: any) => {
        this.loadRingi();
        this.displayRowData = res?.result;
        this.rowCount = this.displayRowData?.length;
        this.totalRecords = res?.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }
  ringiFixCancel(field: any) {
    const ringinumber = field.ringinumber;
    this.spinnerService.start();
    this.ringiListService.ringiFixCancel(ringinumber)?.subscribe({
      next: (_res: any) => {
        this.loadRingi();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    })

  }

  calcButton(field: any): void {
    const ringiFixObj = {
      RingiNumber: field.ringinumber,
      EstVer: field.estver,
    };
    this.spinnerService.start();
    this.ringiListService.calcButton(ringiFixObj)?.subscribe({
      next: (res: any) => {
        this.loadRingi();
        this.displayRowData = res?.result;
        this.rowCount = this.displayRowData?.length;
        this.totalRecords = res?.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  nullDashFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null || params.value === "") {
      return '-';
    } else { return params?.value; }
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []

      for (let i = 0; i < Math.ceil(this.totalRecords / 1000); i++) {
        this.cPage = i + 1;
        this.tPage = 1;
        this.rpPage = 1000; //Records per page is set to 1000 for 'ALL'
        await this.loadRingi()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadRingi();
    }
  }

  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.ringiListService.setfilter(allFilterModels);

  }
}