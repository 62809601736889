import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class DiscrepancyListService {

  partFilter: any;
  pagination: any;
  pagedata: any;
  plantdata: any;
  filterdata: any;
  discdata: any;
  partFilterSelect: any;
  filterdataSelect: any;
  plantdataSelect: any;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  URL = this.constantsService.INTERNAL ;

  onLoad(updateObject: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/onloadDiscrepancy', updateObject, 
    { headers: this.constantsService.internalHttpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  plant(obj: any): Observable<any> {
    return this.http.post(this.URL + 'common/getPlant', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  selectOnload(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/onloadSelectTracking ', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  replaceOnload(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/onloadReplacePart ', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  updatePart(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/updatePart', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  onloadregPart(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/onloadRegPart', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  onloadregPartDue(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/regPartDue', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  onloadRegPartContainer(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/onloadRegPartCont', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  addRegPart(updateObject: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/addRegPartCont', updateObject, 
    { headers: this.constantsService.internalHttpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  updateRegPart(updateObject: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/updateRegPartCont', updateObject, 
    { headers: this.constantsService.internalHttpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  addPart(obj: any): Observable<any> {
    return this.http.post(this.URL + 'tracking/addPartInfo', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  download(plantcode:any,discCode:any): Observable<any> {
    return this.http.get(this.URL + `tracking/exportDisc/${plantcode}/${discCode}`, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

  setfilter(filter: any) {
    this.filterdata = filter;
  }
  getfilter() {
    return this.filterdata;
  }

  setpartFilters(partFilter: any) {
    this.partFilter = partFilter;
  }
  getpartFilters() {
    return this.partFilter;
  }
  setplant(plant: any) {
    this.plantdata = plant;
  }
  getplant() {
    return this.plantdata;
  }

  //discrepancy-select
  
  setfilterSelect(filter: any) {
    this.filterdataSelect = filter;
  }

  getfilterSelect() {
    return this.filterdataSelect;
  }

  setpartFiltersSelect(partFilter: any) {
    this.partFilterSelect = partFilter;
  }
  getpartFiltersSelect() {
    return this.partFilterSelect;
  }
  setplantSelect(plant: any) {
    this.plantdataSelect = plant;
  }
  getplantSelect() {
    return this.plantdataSelect;
  }
 

}
