<div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Update PPF Reason</div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
<div class="row row-cols-3 m-3 content-row pb-5">
    <div class="col-md-4 align-items-center px-0">
        <div class="row px-3">
            <div class="col-md-4 titleColumn">
                <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0">
                <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 === 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>
    </div>

        <div class="col">
            <div class="">
                <mat-accordion hideToggle multi class="p-0">
                    <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0">
                        <mat-expansion-panel-header class="header-container">
                            <mat-panel-title class="roboto-bold me-0">UPDATE PART NUMBER</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-3 mx-1 heading-row">
                            <table class="table" aria-describedby="update-partnumber">
                                <tbody class="d-flex">
                                    <th class="col-md-4 titleColumn" id="part">
                                        <div class="contents2 ps-4 py-3">
                                            Current Part
                                        </div>
                                        <div class="contents2 ps-4 py-3">
                                            New Part
                                        </div>
                                    </th>
                                    <div class="col-md-8 contentColumn rowClass2">
                                        <div class="ps-4 py-2 d-flex align-items-center">
                                            {{result?.partnumber}}
                                        </div>
                                        <div class="ps-4 py-2">
                                            <input type="text" class="" [(ngModel)]="newPart" name="newPart" required minlength="10" maxlength="10">
                                        </div>
                                    </div>
                                </tbody>
                            </table>

                        </div>
                        <div class="row justify-content-end px-3">
                            <button class="vpacs-btn-table roboto-medium col-md-3" (click)="update()"> UPDATE
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>


        </div>

        <div class="col">
            <div class="">
                <mat-accordion hideToggle multi>
                    <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0">
                        <mat-expansion-panel-header class="header-container">
                            <mat-panel-title class="roboto-bold me-0">UPDATE PPF</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-3 mx-1 heading-row">
                            <table class="table" aria-describedby="update-ppf">
                                <tbody class="d-flex">
                                    <th class="col-md-4 titleColumn" id="title">
                                        <div *ngFor="let title of updatePpf1" class="contents ps-4 py-3">
                                            {{title}}
                                        </div>
                                    </th>
                                    <div class="col-md-8 contentColumn rowClass">
                                        <div class="ps-4 py-2 d-block">
                                            <mat-select id="plant" class="mat-sel py-2" [(value)]="selectedValue" disableOptionCentering
                                                [(ngModel)]="selectedValue">
                                                <mat-option *ngFor="let reason of reasonWhatDropdown" [value]="reason.code">
                                                    {{reason.checkitem}}
                                                </mat-option>
                                            </mat-select> <br />
                                            <input type="text" class="mt-2">
                                        </div>
                                        <div class="ps-4 py-2 d-block">
                                            <mat-select id="selectedValue1" class="mat-sel py-2"
                                                [(ngModel)]="selectedValue1">
                                                <mat-option *ngFor="let reason of reasonWhyDropdown" [value]="reason.code">
                                                    {{reason.checkitem}}
                                                </mat-option>
                                            </mat-select> <br />
                                            <input type="text" class="mt-2">
                                        </div>
                                    </div>
                                </tbody>
                            </table>

                        </div>
                        <div class="row justify-content-end px-3">
                            <button class="vpacs-btn-table roboto-medium col-md-3" (click)="ok()"> OK
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
</div>
