import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from '../message-popup-toast/message-popup-toast.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorServiceService {

  constructor(public message:MatSnackBar) { }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 4000,
      data: {
        type: type,
        message: message
      }
    })
  }

  public handleError(err: HttpErrorResponse) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      switch (err.status) {
        case 400:
          errorMessage = "Bad Request.";
          break;
        case 401:
          errorMessage = "You need to log in to do this action.";
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = "The requested resource does not exist.";
          break;
        case 412:
          errorMessage = "Precondition Failed.";
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        case 503:
          errorMessage = "The requested service is not available.";
          break;
        case 422:
          errorMessage = "Validation Error!";
          break;
        default:
          errorMessage = "Something went wrong! please try again";
      }
    }
    if (errorMessage) {
      this.openMessageBox(errorMessage,'error')      
    }
  }
}
