import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PackageMaintananceService {
  public httpHeaderDetails:any;
  subject:any

  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService
  ) {  this.httpHeaderDetails = new HttpHeaders({'x-api-key': environment.API_CALL, 'Authorization': sessionStorage.getItem('idToken') || ''})}

  setSearchflag(val: any) {
    this.subject=val
  }

  getSearchflag() {
    return this.subject;
  }
  resetSearchflag(){
    this.subject = '';
  }
  onload(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/GetPackageOnload';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  
  costOnLoad(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/getPackageCostOnLoad';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  
  addUpdateApi(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/AddorUpdatePackageCost';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  
  getUpdateLoad(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/getUpdatePackageData';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  addPackage(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/AddPackage';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  
  updatePackage(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/UpdatePackage';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 
  deletePackge(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/DeletePackage';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  
  registerAPi(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/getEstimateCostList';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 
  registerAdd(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/AddEstimateCostList';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 

  getDrawPhoto(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/GetDrawPhoto';
    return this.http.post(URL,data, { observe: 'response', responseType: 'text', headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }  


  deletePackageCost(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/DeletePackageCost';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }  

  packageDownload(): Observable<any> {
    return this.http.get(this.constantsService.MASTERCOST  + `package/exportPackage`,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }
  getAllPlant(plantObj: any): Observable<any> {
    const URL = this.constantsService.MASTERCOST
    return this.http.post(URL + 'common/getPlant',
      plantObj, { headers: this.constantsService.httpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  backUpStatusDownload(plantCode: any): Observable<any> {
    return this.http.post(this.constantsService.MASTERCOST + `package/exportCostPackage`, plantCode,
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }
  
  uploadPackageCostData(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/uploadPackageCostData';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 
 
  uploadPackageData(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/uploadPackageData';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 

  nextApi(data:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'package/Next';
    return this.http.post(URL,data, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  } 

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error');
  }
}
