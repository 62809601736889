import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  URL = this.constantsService.MASTERCOST ;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  getRoleInfo(): Observable<any> {
    return this.http.get((this.URL + 'role/getRoleInfo'),  
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  deleteRole(deleteObject: any): Observable<any> {
    return this.http.delete((this.URL + 'role/deleteRole'), 
    {body:deleteObject, headers: this.constantsService.httpOptions, responseType: 'json'})
      .pipe(catchError(this.handleError))
  }

  selectAuthorityType(): Observable<any> {
    return this.http.get((this.URL + 'role/SelectAuthorityType'),  
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  selectAllAuthorityType(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/SelectAllAuthority'),  body,  
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  selectRoleAuthority(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/SelectRoleAuthority'), body, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  
  updateRole(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/UpdateRole'), body, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  addRole(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/AddRole'), body, //doubt
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  getTrackingCheckList(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/GetTrackingCheckList'), body, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  getUsablePlantList(body:any): Observable<any> {
    return this.http.post((this.URL + 'role/GetUsablePlantList'), body, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }


  addExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + 'master/addExchangeRate'), param, //latest url not provided
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  updateExchangeRate(param: any): Observable<any> {
    return this.http.post((this.URL + 'master/updateExchangeRate'), param, //latest url not provided
    {headers: this.constantsService.httpOptions})
      .pipe(catchError(this.handleError))
  }

  

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

}
