import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'sup-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" [disabled]="isButtonDisabled"
    style="height:30px;"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class SupCellRender implements ICellRendererAngularComp {

  constructor(
    private readonly router: Router, 
    private readonly route: ActivatedRoute,
    private readonly state: ExportStateService,
    ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  isButtonDisabled = false;
  buttonName: any;
  isDisabed =  false;
  res: any;
  dullFlag = false;
  disableFlag= false;



  agInit(params: any): void {
    this.params = params;
    this.getUserData()

    if (params.colDef.headerName === 'UPDATE'){
      this.buttonName = 'UPDATE';
    }
  }

  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        if (!data.role.p_usersave) {
          if (this.buttonName ===  'UPDATE') {
            this.isButtonDisabled = true;
          }
        }
      }
    })
  }

  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data)
  }
}