<div class="popup-container">
    <div class="header text-middle"> 
Package Cost Maintenance
        </div>
    <div class="mat-typography content">
        <div class="flex1">
                    <div class="item d-flex flex-column">
                        <label class="catlabel lbl  ">Package Code</label>
                        <label class="catlabel col-7 val"><strong>{{pckgCode}}</strong></label>

                    </div>
                    <div class="item d-flex flex-column">
                        <label class="catlabel   ">Plant Type</label>
                        <label class="catlabel col-6 val"><strong>{{plantType}}</strong></label>

                    </div>
                    <div class="item  d-flex  flex-column">
                        <label class="catlabel  ">Est Cost</label>
                        <label class="catlabel col-6 val"><strong>{{estCost}}</strong></label>

                    </div>
                    <div class="item  d-flex  flex-column">
                        <label class=" pkglabl">Package Fabrication<small >*</small></label>
                        <mat-select class="mat-sel pt-1" [(ngModel)]="lp">
                            <mat-option [value]="''" aria-disabled="true">-ALL-</mat-option>
                            <mat-option *ngFor="let lp of lpcodes" [value]="lp">{{lp}}</mat-option>
                          </mat-select>
                    </div>
                    <div class="item  d-flex  ">
                        <button class="black-button search"(click)="search()">SEARCH</button>
                    </div>
                
        </div>

            <div id='err' class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
                <span  >{{errorList}}</span>
              </div>
              <div id='err' class="alert alert-success mx-2" *ngIf="successMessage.length > 0">
                <span  >{{successMessage}}</span>
              </div>

           <div class="flex2">
                 <button class="black-button align-items-center  mx-3 pt-2 pb-2 register" (click)="packageEstimate()" [disabled]="registerButtonFlag">REGISTER PACKAGE ESTIMATE COST</button>  
            </div>
       
            <div class="grid-agg grid-height-package-tmaintenance-cost mx-2 px-2 py-1 my-4 ">
              <ag-grid-angular style="width:97%;height:100%;" class="ag-theme-balham"  (window:resize)="sizeToFit()"
                  [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
                  [rowData]="displayRowData" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
                  [icons]="icons" [overlayLoadingTemplate]="overlayLoadingTemplate" [gridOptions]="gridOptions"
                  [overlayNoRowsTemplate]="overlayNoRowsTemplate" [headerHeight]="60">
              </ag-grid-angular>
          </div>
      
      
          
    </div>

    <button class="close black-button"  (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2" >
        <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
            <button class="submit black-button me-3 roboto-bold" (click)="addPackageCost()" [disabled]="addFlag">ADD</button> 
    </div>  
</div>