import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'target-input-cell-renderer',
  template: `
    <div style="display:flex;flex-direction:row;height:26px;">
      <div *ngIf="showInput">
      <input  type="text" value="{{params.value}}" (change)="change($event)" style="width:20px;height:20px;margin-right:5px;font-size:12px"/>
      </div>  
      <div *ngIf="!showInput">
      {{params.value}}
      </div>  
    </div>
  `,
})

export class TargetInputCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

   params: any;
  public showInput =false;

  agInit(params: any): void {
    this.params = params;    
    if(params?.data.schedule ==="SHIPMENT" && params.data.subschedule==="TARGET"){
      this.showInput=true
    }
  }

  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

  change(_event:Event){
    const input:HTMLInputElement|null=document.querySelector("#input")
    if(input){
     this.params.onchange(input?.value)
    }
  }

}




