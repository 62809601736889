<div class="popup-container">
    <header>
        <button class="close black-button" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">Supplier Information</div>
          <div>
            <button class="help-btn" (click)="help()">Help</button>
          </div>
          </div>
    </header>

    <div class="popup-contents" >
        <div class="row m-3 align-items-start">
           
            <div class="col-4 d-flex">
                <label for="part"  class="catlabel col-5" >NA Supplier Code</label>
                <input type="text" id="part" [value]="result?.suppliercode" [disabled]="true" >
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="type"  >TMMC Code</label>
                <input type="text" id="type" [value]="result?.tmmccode" [disabled]="true">
            </div>
           
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="plant">NUMMI Supplier Code</label>
                <input type="text" id="type" [value]="result?.nummicode" [disabled]="true">
               
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >Name</label>
                <input type="text"style="pointer-events: auto;" id="part" [value]="result?.name" [disabled]="true" >
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >Short Name</label>
                <input type="text" id="part" [value]="result?.shortname" [disabled]="true">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partCost"  >Contact Name</label>
                <input type="text" id="part" [value]="result?.contactcode" [disabled]="true">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >Phone</label>
                <input type="text" id="part" [value]="result?.phone" [disabled]="true">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >FAX</label>
                <input type="text" id="part"  [value]="result?.fax"[disabled]="true">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partCost"  >Email</label>
                <input type="text" id="part" [value]="result?.email" [disabled]="true">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >Cell/Pager</label>
                <input type="text" id="part" [value]="result?.cellpager" [disabled]="true">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >Zip Code</label>
                <input type="text" id="part" [value]="result?.zipcode" [disabled]="true">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="partCost"  >City</label>
                <input type="text" id="part" [value]="result?.city" [disabled]="true" >
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >State</label>
                <input type="text" id="part" [value]="result?.state" [disabled]="true" >
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >Country</label>
                <input type="text" id="part" [value]="result?.country" [disabled]="true">

            </div>
            
        </div>
        </div>
    </div>

