import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-add-sup',
  templateUrl: './add-sup.component.html',
  styleUrls: ['./add-sup.component.scss']
})
export class AddSupComponent implements OnInit {

  modalHeader = '';
  buttonValue = '';
  isEditMode = false;
  SupplierCode = '';
  tmmccode = '';
  nummicode = '';
  name = '';
  shortname = '';
  address = '';
  city = '';
  state = '';
  zipcode = '';
  country = '';
  res = '';
  isNameError = false;
  isSupplierCodeError = false;
  isShortNameError = false;
  errorList: any[] = [];
  deleteFlag = false;
  errorFlag = false;
  errorMessage: any;
  supplierForm:any;
  pageName: any;
  redirect = false;
  formValues: { SupplierCode: any; TMMCCode: any; NUMMICode: any; Name: any; ShortName: any; Address: any; City: any; State: any; ZipCode: any; Country: any; urlSupplierCode: any; } | undefined;
  redirectResponse: any;
  oldResponse: any;
  miniPopup = false;

  constructor(
    public supplierService: SupplierService,
    public dialogRef: MatDialogRef<AddSupComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router,
    public spinner : NgxUiLoaderService
  ) {
    this.supplierForm = new FormGroup({
      na: new FormControl('', [Validators.required, Validators.maxLength(5),Validators.minLength(5)]),
      tmmc: new FormControl('', [Validators.maxLength(5), this.alphaNumericValidator]),
      nummi: new FormControl('', [Validators.maxLength(5)]),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      shortname: new FormControl('', [Validators.required, Validators.maxLength(5), this.upperCaseValidator]),
      address: new FormControl('', [Validators.maxLength(100)]),
      city: new FormControl('', [Validators.maxLength(50)]),
      state: new FormControl('', [Validators.maxLength(50)]),
      zipcode: new FormControl('', [Validators.maxLength(10)]),
      country: new FormControl('', [Validators.maxLength(20)]),
      tpna: new FormControl('', [Validators.maxLength(5), this.alphaNumericValidator])
    });
  }

  alphaNumericValidator(control: AbstractControl) {
    if (control.value && !control.value.match(/^(?=.*[0-9]{4})(?=.*[a-zA-Z]{1})([a-zA-Z0-9]+)$/)) {
      return { alphaError: 'TMMC code should be alphanumeric(4 digits & 1 alphabet)' }
    }
    return null;
  }

  upperCaseValidator(control: AbstractControl) {
    if (control.value && control.value !== control.value.toUpperCase()) {
      return { codeError: 'Short name must have uppercase' }
    }
    return null;
  }
  
  ngOnInit(): void {
    this.pageName = this.dataStore.mode;
    if (this.pageName === "ADD") {
      this.deleteFlag = false;
      this.modalHeader = 'Add Supplier';
      this.buttonValue = 'ADD';
      this.isEditMode = false;
    } else if (this.pageName === "UPDATE") {
      this.supplierForm.controls.na.disable();
      this.deleteFlag = false;
      this.modalHeader = 'Update Supplier';
      this.buttonValue = 'UPDATE';
      this.setFormValuesOnUpdateOnload(this.dataStore.field)
      this.isEditMode = true;
    } else if (this.dataStore.mode === "DELETE"){
      this.deleteFlag = true;
    }
  }

  setFormValuesOnUpdateOnload(data:any){
    this.supplierForm.setValue({
      na : data.suppliercode,
      tmmc : data.tmmccode,
      nummi : data.nummicode,
      name : data.name,
      shortname : data.shortname,
      address : data.address,
      city : data.city,
      state : data.state,
      zipcode : data.zipcode,
      country : data.country,
      tpna : data.vendorcode
    });
  }

  addOperation(){
    this.spinner.start();
    const {na,tmmc,nummi,name,shortname,address,city,state,zipcode,country,tpna} = this.supplierForm.controls;
    const loadObj = {
      SupplierCode: na.value,
      TMMCCode: tmmc.value,
      NUMMICode: nummi.value,
      Name: name.value,
      ShortName: shortname.value,
      Address: address.value,
      City: city.value,
      State: state.value,
      ZipCode: zipcode.value,
      Country: country.value,
      urlSupplierCode: '',
      mode: 'ADD',
      newShortName: shortname.value,
      TPNACode: tpna.value,
      SpecialistCode: this.dataStore?.userInfo
    }

    this.supplierService.addButton(loadObj).subscribe({
      next: (_data) => {
        this.spinner.stop();        
        if(_data.error){
          this.errorFlag = true;
          this.errorMessage = _data.message;
        }else{
          this.dialogRef.close({ data: { result: 'SUCCESS', message : 'Supplier added successfully' } });
        }
      },
      error: _err => {
        this.spinner.stop();
      }
    });
  }

  updateOperation(){
    const {na,tmmc,nummi,name,shortname,address,city,state,zipcode,country,tpna} = this.supplierForm.controls;
    const loadObj = {
      SupplierCode: na.value,
      TMMCCode: tmmc.value,
      NUMMICode: nummi.value,
      Name: name.value,
      ShortName: shortname.value,
      Address: address.value,
      City: city.value,
      State: state.value,
      ZipCode: zipcode.value,
      Country: country.value,
      urlSupplierCode: this.dataStore?.field?.suppliercode,
      mode: 'UPDATE',
      TPNACode: tpna.value,
      SpecialistCode:this.dataStore?.userInfo
    }
    this.miniPopup = true;
    this.formValues = loadObj;
  }

  openUpdateSupplierConfirmation(msg:any){
    if(msg === 'child'){
      this.redirect = false;
      this.miniPopup = false;
    }else{
      this.spinner.start()
      const {na,tmmc,nummi,name,shortname,address,city,state,zipcode,country,tpna} = this.supplierForm.controls;
      const loadObj = {
        SupplierCode: this.nullStr(na.value),
        TMMCCode: this.nullStr(tmmc.value),
        NUMMICode: this.nullStr(nummi.value),
        Name: this.nullStr(name.value),
        ShortName: this.nullStr(shortname.value),
        Address: this.nullStr(address.value),
        City: this.nullStr(city.value),
        State: this.nullStr(state.value),
        ZipCode: this.nullStr(zipcode.value),
        Country: this.nullStr(country.value),
        urlSupplierCode: this.dataStore?.field?.suppliercode,
        mode: 'UPDATE',
        newShortName: (this.dataStore?.field?.shortname === shortname.value) ? '' : shortname.value,
        TPNACode: tpna.value,
        SpecialistCode:this.dataStore?.userInfo
      }
      this.formValues = loadObj;
      this.supplierService.updateSupplier(loadObj).subscribe({
        next: (res: any) => {
          this.spinner.stop();
          if(res.error){
            this.errorFlag = true;
            this.miniPopup = false;
            this.errorMessage = res.message;
          }else{
            this.oldResponse = res;
            if(res.Redirect){
              this.redirect = true;
              this.updateSupplierConfirmAPI();
            }else{
              this.dialogRef.close({ data: { result: 'SUCCESS',message : 'Supplier updated successfully' } });
            }
          }
        },
        error: _err => {
          this.spinner.stop();
        }
      });
    }
  }

  nullStr(value: any) {
    if (value === undefined || value === 'undefined' || value === null) {
      return '';
    }
    else {
      return value;
    }
  }

  updateSupplierConfirmAPI(){
    this.spinner.start();
    const payload = {
      supData_SupplierCode : this.supplierForm.controls.na.value
    }
    this.supplierService.updateExistingSupplierCode(payload).subscribe({
      next : (res:any) =>{
        if(res){
          this.redirectResponse = res[0];
        }
        this.spinner.stop();
      },
      error : (_err:any) =>{
        this.spinner.stop();
      }
    })
  }

  confirmUpdate(type:number){
    //1-current update
    //2-new update
    const {na,tmmc,nummi,state,tpna} = this.supplierForm.controls;
    if(type === 1){
      this.spinner.start();
      const payload = {
        "supData_SupplierCode":na.value,
        "supData_TMMCCode":tmmc.value,
        "supData_NUMMICode":nummi.value,
        "supData_State":state.value,
        "newSupCode":this.oldResponse.message.newSupCode,
        "oldSupCode":this.oldResponse.message.oldSupCode,
        "TPNACode":tpna.value
      }
      this.supplierService.confirmSupplierOld(payload).subscribe({
        next : (_res:any) =>{
          this.spinner.stop();
        },
        error : (_err:any) =>{
          this.spinner.stop();
        }
      })
    }else if(type === 2){
      this.spinner.start();
      const payload = {
        "supData_SupplierCode": this.redirectResponse.suppliercode,
        "supData_TMMCCode": this.redirectResponse.tmmccode,
        "supData_NUMMICode": this.redirectResponse.nummicode,
        "supData_Name": this.redirectResponse.name,
        "supData_ShortName": this.redirectResponse.shortname,
        "supData_Address": this.redirectResponse.address,
        "supData_City": this.redirectResponse.city,
        "supData_State": this.redirectResponse.state,
        "supData_ZipCode": this.redirectResponse.zipcode,
        "supData_Country": this.redirectResponse.country,
        "newSupCode":this.oldResponse.message.newSupCode,
        "oldSupCode": this.oldResponse.message.oldSupCode
    }
      this.supplierService.confirmSupplierNew(payload).subscribe({
        next : (_res:any) =>{
          this.spinner.stop();
        },
        error : (_err:any) =>{
          this.spinner.stop();
        }
      })
    }
  }

  addBtn() {
    this.supplierForm.markAllAsTouched();
    if(this.supplierForm.valid){
      if(this.pageName === 'ADD'){
        this.addOperation();
      }else if(this.pageName === 'UPDATE'){
        this.updateOperation();
      }
    }
  }

  close(msg: string): void {
    if(msg === 'child'){
      this.redirect = false;
      this.miniPopup = false;
    }else{
      this.dialogRef.close({ event: 'CloseEvent', msg });
    }
  }

  updateSupplierOnload() {
    const loadObj = {
      SupplierCode: this.dataStore.field?.suppliercode,
    }
    this.spinner.start();
    this.supplierService.updateSupplierOnload(loadObj).subscribe({
      next: (res: any) => {
        this.res = res.data;
        this.spinner.stop();
      },
      error: _err => {
        this.spinner.stop()
      }
    });
  }

  deleteSupplier(){
    this.spinner.start();
    const loadObj = {
      SupplierCode: this.dataStore.field?.suppliercode,
    };
    this.supplierService.deleteButton(loadObj)?.subscribe({
      next: (res: any) => {
        this.spinner.stop();
        if(!res.error){
          this.dialogRef.close({data : 'SUCCESS',message : 'Supplier deleted successfully'})
        }else{
          this.errorFlag = true;
          this.errorMessage = res.Message;
        }
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
    })
  }

  help() {
    this.dialogRef.close();
    if (this.modalHeader === "Add Supplier"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 113])
    }else if (this.modalHeader === "Update Supplier"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 114])
    }
  }

}
