<div *ngIf="!isExport" class="popup-container">
   
       <div class="d-flex justify-content-between align-items-center header">

    <p class="page-title">Import Package Cost </p>
  <div>
    <button class="black-button" (click)="help()">help</button>
  </div>
  </div>
  <div class="mat-typography content">
    
      <div class="d-flex">
          <div class="col-11">
            <div class="img-cont col d-flex px-3 gap-2">
              <div class="left col-6">
                <div class="img-preview">
                  <div *ngIf="!image[3]" class="d-flex h-100 align-items-center justify-content-center">
                      <mat-icon class="text-secondary">panorama</mat-icon>
                  </div>                                    
                  <img *ngIf="image[3]" [src]="image[3]"  alt="partImage"/>
              </div>
                <label
                  for="file-upload"
                  class="vpacs-btn-table my-1"
                  style="width: 125px"
                >
                  CHOOSE FILE
                  <input
                    type="file"
                    id="file-upload"
                    accept=".gif,.jpg,"
                    style="display: none"

                  />
                </label>
              </div>
            </div>

          </div>
        </div>


     
     

    
    
        
  </div>

  <button class="close black-button" (click)="onNoClick()">
    <mat-icon  (click)="onNoClick()">close</mat-icon>
</button>
  <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2" >
      <button class="cancel me-3 roboto-bold"  (click)="onNoClick()"> CANCEL </button>
          <button class="submit black-button me-3 roboto-bold">IMPORT</button> 
  </div>  
</div>


<div *ngIf="isExport" class="popup-container">
  
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">Export Package</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <div class="mat-typography content">
    <div class="container mr-2" style="padding-bottom: 10px;">
      <div id='err' class="alert alert-danger" *ngIf="errorList.length > 0">
        <span  >{{errorList}}</span>
      </div>
      <div id='err' class="alert alert-success" *ngIf="successMessage.length > 0">
        <span  >{{successMessage}}</span>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label class="">Package Data</label>
        </div>
        <div class="col-5">
          <button class="submit black-button me-3 roboto-bold"(click)="packageCode()">Export</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label class="">Cost Data</label>
        </div>
        <div class="col-5">
          <button class="submit black-button me-3 roboto-bold"(click)="downloadCost()">Export</button>
        </div>
      </div>
    </div>
  

  </div>

  <button class="close black-button" (click)="onNoClick()">
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>