<div class="popup-container">
    <form #myForm="ngForm" (ngSubmit)="saveData()">
    <header>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">{{modalHeader}} </div>
          <div>
            <button class="help-btn" type="button" (click)="help()">Help</button>
          </div>
          </div>
    </header>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <div *ngFor="let message of errorList" class="errormsg">
          <div class="message-container">
            <span class="message-text">{{ message }}</span>
          </div>
        </div>
    </div>

    <div class="popup-contents">
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel ">Plant Code</label>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel ">Plant Name</label>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel ">Plant Id</label>
            </div>
        </div>
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel "><strong>{{plantCode}}</strong></label>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel "><strong>{{plantName}}</strong></label>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel "><strong>{{plantId}}</strong></label>
            </div>
        </div>
        <div class="row m-3 align-items-start">
            <div class="col-6 d-flex">
                <label class="catlabel col-5">Dock <small class="text-danger">*</small></label>
                <input *ngIf="dockCodeFlag" type="text" name="dcode" [class.error-outline]="isDockError"  [(ngModel)]="dcode" maxlength="2" (change)="checkValidations()">
                <input *ngIf="dcodeFlag" type="text" name="dcode" [class.error-outline]="isDockError"  [(ngModel)]="dcode" disabled>
            </div>

        </div> 
        <div class="row m-3 align-items-start text-nowrap">
            <div class="col-6 d-flex">
                <small class="col-6 offset-5 d-flex text-danger" *ngIf="isDockError">
                    Dock Code is required.
                </small>
            </div>
        </div>  
        <div class="row m-3 align-items-start">
            <div class="col-6 d-flex">
                <label class="catlabel col-5">Address</label>
                <input type="text"  name="address" [value]="address"   [(ngModel)]="address">
            </div>
        </div>  

        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button type="button" class="cancel me-3" (click)="close('CANCEL')"> CANCEL </button>
            <button type="submit" class="submit black-button me-3">{{buttonValue}}</button>
        </div>
        <button type="button" class="close black-button" (click)="close('CANCEL')">
            <mat-icon>close</mat-icon>
        </button>
          
    </div>
</form>

</div>