<div class="popup-container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">Set Priority</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="alert alert-danger mx-2" *ngIf="returnMessage.length > 0">
    <span *ngFor="let message of returnMessage">{{message}}<br/></span>
  </div>

  <div class="m-2 p-2">
    <table class="vpacs-shadow set-priority" aria-describedby="set-priority">
      <thead>
        <tr>
          <th style="border-right: 1px solid #bcd1e0"></th>
          <th style="border-bottom: 1px solid #bcd1e0">Priority Type</th>
          <th style="border-bottom: 1px solid #bcd1e0">Select Priority</th>
          <th style="border-bottom: 1px solid #bcd1e0">Other Model</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="col-color" style="border-right: 1px solid #bcd1e0">
            1st Priority
          </td>
          <td class="col-md-3">
            <div>
              <mat-select class="mat-sel" [(ngModel)]="priorityType1" (selectionChange)="setPriority()"
                placeholder="-Selected Priority-" disableOptionCentering>
                <mat-option *ngFor="let plant1 of plants" [value]="plant1.id">{{
                  plant1.Name
                  }}</mat-option>
              </mat-select>
            </div>
          </td>

          <td class="col-md-3">
            <div *ngIf="dropdownVisible">
                <mat-select class="mat-sel" [(ngModel)]="priorityType2" (selectionChange)="setPriority2()" *ngIf="showOption"
                [disabled]="btndisable1" disableOptionCentering>             
                  <mat-option *ngFor="let plant2 of plants2" [value]="plant2.id">{{ plant2.Name }}</mat-option>
                </mat-select>
                <mat-select class="mat-sel" [(ngModel)]="priorityType2" (selectionChange)="setPriority2()" *ngIf="!showOption"
                [disabled]="btndisable1" disableOptionCentering>             
                  <mat-option *ngFor="let plant2 of selectPlant" [value]="plant2.plantcode">{{ plant2.plant }}</mat-option>
                </mat-select>
            </div>
          </td>

          <td class="col-md-3">
            <div class="align-items-center">
              <input type="text" [(ngModel)]="othermodel1" [disabled]="btndisable2" style="width: 100%" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="col-color" style="border-right: 1px solid #bcd1e0">
            2nd Priority
          </td>
          <td class="col-md-3">
            <div>
              <mat-select class="mat-sel" [(ngModel)]="priorityType3" placeholder="-Selected Priority-"
                (selectionChange)="setPriority3()" disableOptionCentering>
                <mat-option *ngFor="let plant3 of plants" [value]="plant3.id">{{
                  plant3.Name
                  }}</mat-option>
              </mat-select>
            </div>
          </td>

          <td class="col-md-3">
            <div *ngIf="dropdownVisible2">
              <mat-select class="mat-sel" [(ngModel)]="priorityType4" (selectionChange)="setPriority4()" *ngIf="showOption2"
                [disabled]="btndisable3" disableOptionCentering>
                <mat-option *ngFor="let plant4 of plants2" [value]="plant4.id">{{ plant4.Name }}</mat-option>
              </mat-select>
              <mat-select class="mat-sel" [(ngModel)]="priorityType4" (selectionChange)="setPriority4()" *ngIf="!showOption2"
                [disabled]="btndisable3" disableOptionCentering>
                <mat-option *ngFor="let plants of selectPlant" [value]="plants.plantcode">{{ plants.plant }}</mat-option>
              </mat-select>

            </div>
          </td>
          <td class="col-md-3">
            <div>
              <input type="text" [(ngModel)]="othermodel2" [disabled]="btndisable4" style="width: 100%" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="col-color" style="border-right: 1px solid #bcd1e0">
            3rd Priority
          </td>
          <td class="col-md-3">
            <div>
              <mat-select class="mat-sel" [(ngModel)]="priorityType5" (selectionChange)="setPriority5()"
                placeholder="-Selected Priority-" disableOptionCentering>
                <mat-option *ngFor="let plant5 of plants" [value]="plant5.id">{{
                  plant5.Name
                  }}</mat-option>
              </mat-select>
            </div>
          </td>

          <td class="col-md-3">
            <div *ngIf="dropdownVisible3">
              <mat-select class="mat-sel" [(ngModel)]="priorityType6" (selectionChange)="setPriority6()" *ngIf="showOption3"
                [disabled]="btndisable5" disableOptionCentering>
                <mat-option *ngFor="let plant6 of plants2" [value]="plant6.id">{{ plant6.Name }}</mat-option>
              </mat-select>
              <mat-select class="mat-sel" [(ngModel)]="priorityType6" (selectionChange)="setPriority6()" *ngIf="!showOption3"
                [disabled]="btndisable5" disableOptionCentering>
                <mat-option *ngFor="let plants of selectPlant" [value]="plants.plantcode">{{ plants.plant }}</mat-option>
              </mat-select>
            </div>
          </td>
          <td class="col-md-3">
            <div>
              <input type="text" [(ngModel)]="othermodel3" [disabled]="btndisable6" style="width: 100%" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="col-color" style="border-right: 1px solid #bcd1e0">
            Priority
          </td>
          <td colspan="5" class="bg-color m-0 ps-0">
            <div class="col-md-5">
              <mat-radio-group [(ngModel)]="radioselection" aria-labelledby="example-radio-group-label" class="example-radio-group">
                <mat-radio-button class="example-radio-button" [value]="'m3part'">
                  M3/Part
                </mat-radio-button>
                <mat-radio-button class="mx-3 example-radio-button" [value]="'pkgcostp'">
                  Cost/Part
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="footer d-flex justify-content-end align-items-center pt-2 pb-2">
    <button class="cancel me-3 roboto-bold" (click)="close()">CANCEL</button>
    <button class="submit black-button me-3 roboto-bold" (click)="submit()">Ok</button>
  </div>
</div>