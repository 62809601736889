<div class="d-flex justify-content-between align-items-center mx-2">
    <p class="page-title px-2">Update Supplier Contact</p>
    <button class="help-btn" (click)="help()">HELP</button>
</div>
<!-- errormessage for access failure -->
<app-notification [codes]="errorMessage" *ngIf="!viewAccess && errorMessage"></app-notification>
<!-- errormessage for API failure -->
<app-notification [codes]="errorMessage" *ngIf="viewAccess && errorMessage"></app-notification>
<!-- errorMessage for Validation/API 200 with error -->
<div class="alert alert-danger mx-2" *ngIf="errorList.length>0">
    <span *ngFor="let x of errorList">{{x}}</span>
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="viewAccess">
    <div class="row mx-2 mb-3 align-items-start">
        <div class="d-grid col-lg-2 col-md-6 col-12">Name
            <span class="text-black"><b>{{fullName}}</b></span>
        </div>
        <div class="d-grid col-lg-2 col-md-6 col-12">Supplier Code<span
                class="text-black"><b>{{suppliercode}}</b></span></div>
        <div class="d-grid col-lg-1 col-md-6 col-12">Admin
            <label class="checkcontainer mb-0 my-1">
                <input type="checkbox" [checked]="admin === '1'" [value]="admin"
                    (click)="handleChkClick($event, 'Admin')">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="d-grid col-lg-1 col-md-6 col-12">Valid
            <label class="checkcontainer mb-0 my-1">
                <input type="checkbox" [checked]="valid === '1'" [value]="valid"
                    (click)="handleChkClick($event, 'Valid')">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="d-grid col-lg-2 col-md-6 col-12">Valid(# of task)
            <label class="checkcontainer mb-0 my-1">
                <input type="checkbox" [checked]="displayRowData[0]?.authvalid === '1'" [value]="authvalid"
                    (click)="handleChkClick($event, 'authValid')">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</div>
<div class="grid-agg tracking-summary grid-height-supplier-update-contact" *ngIf="viewAccess">
    <ag-grid-angular 
        style="width:100%;height:100%;" 
        class="ag-theme-balham" 
        (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" 
        [rowHeight]="40" 
        [animateRows]="true" 
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs" 
        [rowData]="displayRowData" 
        [suppressCellSelection]="true"
        [suppressHorizontalScroll]="false" 
        [pagination]="false"
        [suppressPaginationPanel]="true" 
        [icons]="icons"
        [overlayLoadingTemplate]="overlayLoadingTemplate" 
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [rowClassRules]="rowClassRules"
    >
    </ag-grid-angular>
</div>
<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom" *ngIf="viewAccess">
    <button class="help-btn" (click)="backBtn()">BACK</button>
    <button class="submit black-button me-3" (click)="updateSupplierContact()">Update</button>
</div>

<router-outlet></router-outlet>