<div class="dialog-wrapper col">
  <div class="col-12 shadow-sm fw-bold p-3">Select Package</div>
  <div class="px-3">
    Component Type:
    <span class="fw-bold mx-2">{{ this.data.componentName }}</span>
  </div>
  <div
    class="filter-params d-flex flex-wrap align-items-center px-3"
    [formGroup]="searchPackage" (keyup.enter)="onSearch()"
  >
    <div class="col-lg-2 col-md-6 mt-2">
      <label for="code">Code</label>
      <input type="text" id="code" class="col-6 ms-2" formControlName="code" />
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="type">Type</label>
      <mat-select
        formControlName="type"
        id="type"
        class="mat-sel pt-2 col-6 ms-2"
        disableOptionCentering
      >
        <mat-option *ngFor="let type of typeDropdown" [value]="type.value">{{
          type.name
        }}</mat-option>
      </mat-select>
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="material">Material</label>
      <mat-select
        formControlName="material"
        id="material"
        class="mat-sel pt-2 col-6 ms-2"
        disableOptionCentering
      >
        <mat-option
          *ngFor="let material of materialDropdown"
          [value]="material.value"
          >{{ material.name }}</mat-option
        >
      </mat-select>
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="sn">S/N</label>
      <mat-select
        formControlName="sn"
        id="sn"
        class="mat-sel pt-2 col-6 ms-2"
        disableOptionCentering
      >
        <mat-option value="All">All</mat-option>
        <mat-option value="S">S:Standard</mat-option>
        <mat-option value="N">N:Non Standard</mat-option>
      </mat-select>
    </div>
    <button class="black-button col-lg-1 col-md-6 mt-2" (click)="onSearch()">
      Search
    </button>
    <div class="col-lg-11 col-md-6 mt-2 d-flex align-items-center">
      <input
        type="radio"
        id="outside"
        value="outside"
        formControlName="dimension"
        class="mx-2"
      />
      <label for="outside">Outside Dimension</label>
      <input
        type="radio"
        id="inside"
        value="inside"
        formControlName="dimension"
        class="mx-2"
      />
      <label for="inside">Inside Dimension</label>
    </div>
    <button class="black-button col-lg-1 col-md-6 mt-2" (click)="newPackage()">
      New
    </button>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Length(mm)</span>
      <label for="l-over">Over</label>
      <input
        type="text"
        id="l-over"
        class="col-4 mx-2"
        formControlName="lengthOver"
        style="width: 70px"
      />
      <label for="l-under">Under</label>
      <input
        type="text"
        id="l-under"
        class="col-4 mx-2"
        formControlName="lengthUnder"
        style="width: 70px"
      />
    </div>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Width(mm)</span>
      <label for="w-over">Over</label>
      <input
        type="text"
        id="w-over"
        class="col-4 mx-2"
        formControlName="widthOver"
        style="width: 70px"
      />
      <label for="w-under">Under</label>
      <input
        type="text"
        id="w-under"
        class="col-4 mx-2"
        formControlName="widthUnder"
        style="width: 70px"
      />
    </div>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Height(mm)</span>
      <label for="h-over">Over</label>
      <input
        type="text"
        id="h-over"
        class="col-4 mx-2"
        formControlName="heightOver"
        style="width: 70px"
      />
      <label for="h-under">Under</label>
      <input
        type="text"
        id="h-under"
        class="col-4 mx-2"
        formControlName="heightUnder"
        style="width: 70px"
      />
    </div>
  </div>
  <div class="grid mt-2 px-3">
    <ag-grid-angular
      style="width: 100%; height: 250px"
      class="ag-theme-balham"
      (gridReady)="onGridReady($event)"
      [rowHeight]="rowHeight"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [paginationPageSize]="paginationPageSize"
      [columnDefs]="columnDefs"
      [rowData]="displayRowData"
      [suppressCellSelection]="true"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [pagination]="true"
      [suppressPaginationPanel]="true"
      [headerHeight]="40"
      [domLayout]="domLayout"
      (paginationChanged)="onPaginationChanged()"
    >
    </ag-grid-angular>
  </div>
  <div
    class="d-flex pagination align-items-center justify-content-between"
    *ngIf="rowCount > 0"
  >
    <div>
      <div>
        <label class="page-drpdwn-label mx-2">Records/Page: </label>
        <select style="height: 30px" (change)="onPageSizeChanged($event)">
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="ALL">ALL</option>
        </select>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <div>Viewing Records</div>
      <mat-icon
        (click)="goToFirstPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: getCurrentPage() === true,
          enable_icon: getCurrentPage() !== true
        }"
        >first_page</mat-icon
      >
      <mat-icon
        (click)="goToPreviousPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: getCurrentPage() === true,
          enable_icon: getCurrentPage() !== true
        }"
        >fast_rewind</mat-icon
      >
      <div>
        (
        <span class="value" id="lbCurrentPage"></span>-
        <span class="value" id="lbTotalPages"></span>)
        <span>OF {{ rowCount }}</span>
      </div>
      <mat-icon
        (click)="goToNexpage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: isLastPage() === true,
          enable_icon: isLastPage() !== true
        }"
        >fast_forward</mat-icon
      >
      <mat-icon
        (click)="goToLastPage()"
        class="text-primary"
        [ngClass]="{
          disable_icon: isLastPage() === true,
          enable_icon: isLastPage() !== true
        }"
        >last_page</mat-icon
      >
    </div>
  </div>
  <button (click)="closeDialog()" class="black-button close-btn">
    &times;
  </button>
</div>
