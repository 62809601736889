<div class="popup-container">
    <header>
        <label class="close black-button"  (click)="onNoClick()" >X</label>
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Add Part</label>
        </div>
    </header><br>

    <div class="popup-contents" >
        <h4>PartNumber: {{this.dialog.partnumber}}</h4>
        <table style="width:100% ;  border: 1px solid #BCD1E0;" aria-describedby="">
            <th  class="d-none"></th>
                <tr>
                    <td style="background-color:#e4ecf0;border-bottom:1px solid #bcd1e0; border-right:1px solid #bcd1e0"></td>
                    <td style="background-color:#e4ecf0; border-bottom:1px solid #bcd1e0;border-right:1px solid #bcd1e0">Current Part</td>
                    <td style="background-color:#e4ecf0;border-bottom:1px solid #bcd1e0;">New Part</td>
                </tr>
              <tr>
                  <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Length</td>
                  <td style="border-right:1px solid #bcd1e0">{{currLength}}</td>
                  <td>{{this.dialog.partlength}}</td>
              </tr>
              <tr>
                  <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Width</td>
                  <td style="border-right:1px solid #bcd1e0">{{currWidth}}</td>
                  <td>{{this.dialog.partwidth}}</td>
              </tr>
              <tr>
                  <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Height</td>
                  <td style="border-right:1px solid #bcd1e0">{{currHeight}}</td>
                  <td>{{this.dialog.partheight}}</td>
              </tr>
              <tr>
                  <td style="background-color:#e4ecf0;border-right:1px solid #bcd1e0">Weight</td>
                  <td style="border-right:1px solid #bcd1e0">{{currWeight}}</td>
                  <td>{{this.dialog.partweight}}</td>
              </tr>
              
          </table><br>
        <label>	Changed Due(The above-mentioned part): </label><br>
        <label><input type="radio" [(ngModel)]="radioVal" id="op1" name="over" value="0">Overwrite</label>
        <span style="margin-right: 20px;"></span>
        <label><input type="radio" [(ngModel)]="radioVal" id="op2" name="over" value="1" checked>Not Overwrite</label>
    </div><br>


        <div class="footer d-flex justify-content-end align-items-center fixed-bottom">
            <button class="submit black-button me-3 roboto-bold" (click)="nextBt()">NEXT</button>
            <button class="cancel me-3 roboto-bold"(click)="onNoClick()" > CANCEL </button>
        </div>

</div>
