import { Component  } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-packagecell-btn-cell-renderer',
  template: `
    <div class="d-flex align-items-center gap-1">
      <button
        class="vpacs-btn-table"
        style="height:30px"
        (click)="btnClickedHandler($event)" [disabled]="disable"
      >
        {{ buttonName }}
      </button>
    </div>
  `,
})
export class PackageCellRendererComponent implements ICellRendererAngularComp {
  disable=false
  private params: any;
  buttonName: any;
  show = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly state: ExportStateService
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  agInit(params: any): void {
    this.buttonName = 'SELECT';
    this.params = params;

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          if (res.role.p_pkgmodify && res.role.p_pkgdelete) {
            this.disable = false;
          } else {
            this.disable = true;
          }
        }
      },
    });
  }

  btnClickedHandler( _event: any) {
    this.params.context.componentParent.reverseCall(this.params.data);
  }
}
