import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { ShipmentSelectBtnCellRender } from '../../gridrender/shipment-select-btn-cell-render';
import { ActivatedRoute } from '@angular/router';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';


@Component({
  selector: 'app-select-auth',
  templateUrl: './select-auth.component.html',
  styleUrls: ['./select-auth.component.scss'],
  providers: [RegexFilter]
})
export class SelectAuthComponent implements OnInit  {
  [x: string]: any;
  plants = ['(ALL)', 'TMCA', 'TMMR', 'TDV','TNGA','TMMNK','TMMK', 'TMMWV', 'TMMR', 'TMMC','Export',
  'TMMAL','NUMMI','TMMNA','TMC','TMMBC','TMMTX','TMMI','TABC','TMMMS','VUTEX','TSAM','TMUK','TMS','TASA',
  'TMAP','TMC-EXP','TMMGT','MTMUS-M'];
  
  public slectAuth :any; 
  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any;
  icons: {};
  isAccessible = false;
  auth:any="";
  supplier:any="";
  part:any="";
  plant = "(ALL)";
  modelCode:any="";
  supplierNumber:any="";
  supplierCode: any;
  pageName:any;

  constructor(
    public http : HttpClient,  
    public route: ActivatedRoute,  
    public NewshipmentService: NewshipmentService,
    public regex:RegexFilter,
    public dialogRef:MatDialogRef<SelectAuthComponent>,
      @Inject(MAT_DIALOG_DATA) _data:any,
    ) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,      
      suppressPaginationPanel:true,   
      autoHeight: true, 
      suppressHorizontalScroll: true, 
      sortable:true,    
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
      if(localStorage.getItem('tracking')){
        this.supplierCode = JSON.parse(localStorage.getItem("tracking") || '');
      }
      this.NewshipmentService.addAuthData(this.supplierCode).subscribe((res)=> {
        this.displayRowData = res;
       this.NewshipmentService.setAuthData(res);
        this.rowCount = this.displayRowData.length
      });
      this.pageName = this.route.snapshot.data['breadcrumb'];

      if(this.pageName === 'Detail'){
        this.NewshipmentService.addAuthDataDetail(this.supplierCode).subscribe((res)=> {
          this.displayRowData = res;
          this.NewshipmentService.setAuthData(res);
          this.rowCount = this.displayRowData.length
        });
      }
      
     this.columnDefs = [
       {
        headerName: "Auth Number", field: "AUTHNUMBER",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 120,
       },
       {
        headerName: "Plant", field: "PLANTNMAE",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
        },
       {
        headerName: "Model", field: "MODELCODE",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
        },
        {
          headerName: "Supplier", field: "SUPPLIERNAME",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
        },
       {
        headerName: "Part Number", field: "PARTNUMBER",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
       },
       {
        headerName: "Pkg Start Date", field: "PKGSTARTDATE",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
       },
       {
        headerName: "Dunnage1", field: "DUN1FAB",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
       },
       {
        headerName: "Auth Type", field: "AUTHTYPEDISP",floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100
       },
       {
        headerName: "Select", field: "select",filter: 'agTextColumnFilter',  minWidth: 100, cellRendererFramework: ShipmentSelectBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            if (field?.AUTHNUMBER) {
              const AUTHNUMBER = field?.AUTHNUMBER;
              this.dialogRef.close({data:AUTHNUMBER})
            }
          }
        }
       }
       
     ];
 }
     

 sizeToFit() { this.gridApi?.sizeColumnsToFit() }  
  onSearch() {
    const searchParams: any = {
      PLANTNMAE: this.plant, MODELCODE: this.modelCode
    };
    if (searchParams.PLANTNMAE === '(ALL)') {
      searchParams.PLANTNMAE = '*';
    }
    if(!this.modelCode && !this.part && !this.supplier && !this.auth && !this.plant){
      this.gridApi.setRowData(this.displayRowData)
      this.rowCount=this.displayRowData.length
    } else{
      const filterParams: any = {
        SUPPLIERNAME: this.supplier ? this.supplier : '*',
        MODELCODE: this.modelCode ? this.modelCode : '*',
        PARTNUMBER: this.part ? this.regex.addStarForPartNumber(this.part) : '*',
        AUTHNUMBER: this.auth ? this.auth : '*',
        PLANTNMAE: this.plant ? this.plant : '*'
      } 
      if(filterParams.PLANTNMAE === '(ALL)'){
        filterParams.PLANTNMAE = '*'
      }
   
      const filteredData = this.regex.regexFilter(this.displayRowData, filterParams);
      this.gridApi?.setRowData(filteredData);
      this.rowCount = filteredData.length
    }
    

  }
  
  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
  }  
  }