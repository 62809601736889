import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'sup-del-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button
    class="vpacs-btn-table"
    style="height:30px"
    [disabled]="disableFlag"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class SupDelBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly state: ExportStateService) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  isButtonDisabled = false;
  buttonName: any;
  isDisabed = false;
  res: any;
  dullFlag = false;
  disableFlag = false;

  agInit(params: any): void {
    this.params = params;
    this.dullFlag = this.disableFlag = Number(params.data.used) > 0;
    this.getUserData()

    if (params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
    }
    else if (params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
    }
  }

  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        if (!data.role.p_userdelete) {
          if (this.buttonName === 'DELETE') {
            this.disableFlag = true;
          }
        }  
      }
    })
  }

  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data)
  }
}