<div class="popup-container">
    <form #myForm="ngForm" (ngSubmit)="saveData()">
    <header>
        <div class="d-flex justify-content-between align-items-center m-2 header">
            <p class="page-title">{{modalHeader}} </p>
          <div>
            <button class="help-btn" type="button" (click)="help()">Help</button>
          </div>
          </div>
    </header>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <div *ngFor="let message of errorList" class="errormsg">
          <div class="message-container">
            <span class="message-text">{{message}}</span>
          </div>
        </div>
      </div>
    <div class="popup-contents">
        <div class="row m-3 align-items-start">
            <div class="col-12 d-flex my-1">
                <label class="catlabel col-5">Plant Code</label>
                <label class="catlabel col-5"><strong>{{plantCode}}</strong></label>
            </div>
            <br/>
            <div class="col-12 d-flex my-1" >
                <label class="catlabel col-5" for="input2">Plant Name</label>
                <label class="catlabel col-5" for="input2"><strong>{{plantName}}</strong></label>
            </div>
            <div class="col-12 d-flex my-1" >
                <label class="catlabel col-5" for="input2">Plant ID</label>
                <label class="catlabel col-5" for="input2"><strong>{{plantId}}</strong></label>
            </div>
            <br/>
            <div class="col-12 d-flex my-1" >
                <label class="catlabel col-5" for="input2">Cost Center<small class="text-danger">*</small></label>
                <input *ngIf="familycodeFlag" type="text" name="costCenter" [class.error-outline]="isBudgetError"  [(ngModel)]="costCenter" maxlength="5" (change)="checkValidations()">
                <input *ngIf="familyFlag" type="text" name="costCenter" [class.error-outline]="isBudgetError"  [(ngModel)]="costCenter" disabled>
            </div>
            <div class="col-12 d-flex my-1" >
                <small class="col-7 offset-5 d-flex my-1 text-danger" *ngIf="isBudgetError">
                    Cost Center is required.
                </small>
            </div>
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button type="button" class="cancel me-3 roboto-bold" (click)="close('CANCEL')"> CANCEL </button>
            <button type="submit" class="submit black-button me-3">{{buttonValue}}</button>
        </div>
        <button type="button" class="close black-button" (click)="close('CANCEL')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</form>
</div>