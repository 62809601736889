import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-auth-link',
  templateUrl: './auth-link.component.html',
  styleUrls: ['./auth-link.component.scss']
})
export class AuthLinkComponent implements OnInit {
  supplierCode = [];
  plant: any;
  details: any;
  panelOpenState = true;
  panelOpenState2 = true;
  panelOpenState3 = true;
  panelOpenState4 = true;
  partNumber: any;
  ringi: any;
  ringiList = [];
  reason: any;
  reasonList = [];

  tables = [
    {
      data: [
        { column1: 'Plant', column2: '', },
        { column1: 'Family Code', column2: '', },
        { column1: 'Model Code', column2: '', },
        { column1: 'Part #', column2: '', },
        { column1: 'Supplier', column2: '', },
      ],

      data2: [
        { column1: 'Ringi Number', column2: '', },
        { column1: 'Ringi Type', column2: '', },
        { column1: 'Auth Type', column2: '', },
        { column1: 'Reason', column2: '', },
        { column1: 'Delivery Date', column2: '', },

      ],
      data3: [
        { column1: 'Background Color', column2: '', },
        { column1: 'Font Color', column2: '', },
        { column1: 'NAMC Code', column2: '', },
        { column1: 'Supplier Code', column2: '', },
        { column1: 'Short Name', column2: '', },

      ],
      data4: [
        { column1: 'Container', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Dunnage1', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Dunnage2', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Top Cap', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Pallet', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Stretch Wrap/Strap', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Kanban Card Holder', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Other Package', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Label', column2: '', column3: '', column4: '', column5: '' },
        { column1: 'Other', column2: '', column3: '', column4: '', column5: '' },
      ],
      data5: [
        { column1: 'Total', column2: '' }
      ]

    }
  ]
  authNum: any;
  plantCode: any;
  totalCost: any[] = [];
  rate: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authListService: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    public datepipe: DatePipe
    ) { }

  ngOnInit(): void {
    this.authNum = sessionStorage.getItem('authNum') || this.route.snapshot.queryParamMap.get('auth');
    this.plantCode = JSON.parse(sessionStorage.getItem('plantCode') || '');
    this.loadAuthDetails(this.authNum);
  }

  loadAuthDetails(authNum: any) {
    this.spinnerService.start();
    const params: any = {
      authNum: authNum,
      plantCode: this.plantCode
    }
    this.authListService.loadAuthListDetails(params)
      .subscribe({
        next: (res: any) => {          
          res.ComponentInfo?.forEach((element: any) => {
            element.packagecode = this.changeNullData(element.packagecode).replace('*','-');
          });
          this.details = res;
          this.rate =  this.details.Rate
          const deliveryDate = this.datepipe.transform(this.details.AuthorizationInfo[0].actualdate.split("T")[0], 'MM/dd/YYYY');
          this.tables[0].data[0].column2 = this.details.AuthorizationInfo[0].plant;
          this.tables[0].data[1].column2 = this.details.AuthorizationInfo[0].familycode;
          this.tables[0].data[2].column2 = this.details.AuthorizationInfo[0].modelcode;
          this.tables[0].data[3].column2 = this.details.AuthorizationInfo[0].partnumber;
          this.tables[0].data[4].column2 = this.details.AuthorizationInfo[0].supplier;
          this.tables[0].data2[0].column2 = this.details.AuthorizationInfo[0].ringinumber;
          this.tables[0].data2[1].column2 = this.details.AuthorizationInfo[0].ringitype;
          this.tables[0].data2[2].column2 = this.details.AuthorizationInfo[0].authtype;
          this.tables[0].data2[3].column2 = this.details.AuthorizationInfo[0].reason;          
          this.tables[0].data2[4].column2 = deliveryDate ?? '';
          this.tables[0].data3[0].column2 = this.changeNullData(this.details.LabelInfo[0].labelbgcolor);
          this.tables[0].data3[1].column2 = this.changeNullData(this.details.LabelInfo[0].labelfontcolor);
          this.tables[0].data3[2].column2 = this.details.LabelInfo[0].plantcode;
          this.tables[0].data3[3].column2 = this.details.LabelInfo[0].suppliercode;
          this.tables[0].data3[4].column2 = this.details.LabelInfo[0].shortname;
          let j = 0;

          this.tables[0].data4 && this.tables[0].data4.forEach((e: any, i: any) => {
            if (this.details.ComponentInfo && Number(this.details.ComponentInfo[j]?.rownumber) === i + 1) {
              e.column2 = this.details?.ComponentInfo[j]?.packagecode;
              e.column3 = Number(this.details?.ComponentInfo[j]?.qty).toFixed(0);
              e.column4 = (Number(this.details?.ComponentInfo[j]?.unitcost) * this.rate).toFixed(2);
              e.column5 = '$' + (Number(e.column3) * Number(e.column4)).toFixed(2);
              this.totalCost.push(Number(e.column3) * Number(e.column4));
              j++;
            }
            else {
              e.column2 = '-';
              e.column3 = '-';
              e.column4 = '-';
              e.column5 = '-';
            }
          })
          this.tables[0].data4[8].column3 = this.details.AuthorizationInfo[0].labelqty ? Number(this.details.AuthorizationInfo[0].labelqty).toFixed(0) : '-';
          this.tables[0].data4[8].column4 = this.details.AuthorizationInfo[0].labelcost ? (Number(this.details.AuthorizationInfo[0].labelcost) *  this.rate).toFixed(2) : '-';
          this.tables[0].data4[8].column5 = 
          this.isNan((Number(this.tables[0].data4[8].column3) * Number(this.tables[0].data4[8].column4))) === 0 ? '-' : '$' + this.isNan((Number(this.tables[0].data4[8].column3) * Number(this.tables[0].data4[8].column4))).toFixed(2);
          this.tables[0].data4[9].column5 = this.details.AuthorizationInfo[0].othercost ? String(Number(this.details.AuthorizationInfo[0].othercost) * this.rate.toFixed(2)) : '';
          this.totalCost.push(this.isNan(Number(this.tables[0].data4[8].column3) * Number(this.tables[0].data4[8].column4)));
          this.totalCost.push(this.isNan(Number(this.tables[0].data4[9].column5)));//Total other cost value
          this.totalCost = this.totalCost.reduce((a, b) => a + b, 0).toFixed(2);
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        }
      });
  }

  isNan(data: any){
    if(isNaN(data)){
      return 0
    }
    else{
      return data
    }
  }

  changeNullData(data: any) {
    if(data === null || data.length === 0 || String(data) === '[object Object]') {
      return " - ";
    } else {
      return data;
    }	
  }

  help(){
    this.router.navigate(['./master-help',3,"Ringi List",60])
  
  }

  cancel() { 
    sessionStorage.removeItem('authNum');
    let uri = ''
    if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    } else if (this.router.url.includes('pur-decision-list')) {
      uri = '/pur-decision-list'
    }
   else if (this.router.url.includes('decision-list')) {
    uri = '/decision-list'
  }
    this.router.navigateByUrl(uri);
  }
}

