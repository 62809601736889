import { PpfCellRenderer } from 'src/app/core/gridrender/ppf-cell-render';
import { PPFInputCellRendererComponent } from 'src/app/core/gridrender/ppf-input-cellRenderer';
export const flexStart='flex-start'
export const columnDefs = [
  {
    headerName: 'Component Name',
    field: 'COMPONENT',
    maxWidth: 240,
    minWidth: 170,
    cellStyle: (_params: any) => {
      return {
        display: 'flex',
        'justify-content': flexStart,
        height: '40px',
        padding: '0 10px',
      };
    },
  },
  {
    headerName: 'Description',
    field: 'DESCRIPTION',
    maxWidth: 240,
    minWidth: 120,
  },
  {
    headerName: 'Manufacturer',
    field: 'MANUFACTURER',
    maxWidth: 240,
    minWidth: 170,
    cellRendererFramework: PpfCellRenderer,
  },
  {
    headerName: 'Material',
    field: 'MATERIAL',
    maxWidth: 200,
    minWidth: 130,
  },
  {
    headerName: 'Package Code',
    field: 'PACKAGECODE',
    maxWidth: 250,
    minWidth: 170,
    cellRendererFramework: PpfCellRenderer,
    cellRendererParams: {
      changed: (_value: any) => {
      },
    },
  },
  {
    headerName: 'Lead Time',
    field: 'LEADTIME',
    maxWidth: 150,
    minWidth: 80,
  },
  {
    headerName: 'RET/ EXP',
    field: 'RETEXP',
    maxWidth: 150,
    minWidth: 70,
  },
  {
    headerName: 'QUANTITY/ CONT/ RACK',
    field: 'QUANTITY',
    maxWidth: 190,
    minWidth: 120,
    cellRendererFramework: PPFInputCellRendererComponent,
  },
  {
    headerName: 'Cost Estimate',
    field: 'COST',
    maxWidth: 180,
    minWidth: 80,
    cellRendererFramework: PPFInputCellRendererComponent,
  },
  {
    headerName: 'COMMENTS',
    field: 'COMMENTS',
    maxWidth: 300,
    minWidth: 150,
    cellRendererFramework: PPFInputCellRendererComponent,
    cellStyle: (_params: any) => {
      return {
        'white-space': 'break-spaces',
        'word-break': 'break-word',
        'line-height': '20px',
        display: 'flex',
        'justify-content': flexStart,
        height: '40px',
        padding: '0 10px',
      };
    },
  },
];
export const columnDefs2 = [
  {
    headerName: 'Component',
    field: 'COMPONENT',
    maxWidth: 240,
    minWidth: 200,
    cellStyle: (_params: any) => {
      return {
        display: 'flex',
        'justify-content': flexStart,
        height: '40px',
        padding: '0 10px',
      };
    },
  },
 
  {
    headerName: 'LENGTH(mm)',
    field: 'LENGTH',
    maxWidth: 240,
    minWidth: 60,
    cellRendererFramework: PPFInputCellRendererComponent,
  },
  {
    headerName: 'WIDTH(mm)',
    field: 'WIDTH',
    maxWidth: 200,
    minWidth: 60,
    cellRendererFramework: PPFInputCellRendererComponent,
  },
  {
    headerName: 'HEIGHT(mm)',
    field: 'HEIGHT',
    maxWidth: 200,
    minWidth: 60,
    cellRendererFramework: PPFInputCellRendererComponent,
    cellStyle: (_params: any) => {
      return { 'border-right': '2px solid #E4ECF0' };
    },
  },
  {
    headerName: 'Component',
    field: 'COMPONENT2',
    maxWidth: 360,
    minWidth: 250,
    cellStyle: (_params: any) => {
      return {
        display: 'flex',
        'justify-content': flexStart,
        height: '40px',
        padding: '0 10px',
      };
    },
  },
  {
    headerName: 'Weight(kg)',
    field: 'WEIGHT',
    maxWidth: 200,
    minWidth: 80,
    cellRendererFramework: PPFInputCellRendererComponent,
    cellStyle: (_params: any) => {
      return { 'border-right': '2px solid #E4ECF0' };
    },
  },
  {
    headerName: 'Quantities',
    field: 'QUANTITIES',
    minWidth: 250,
    tooltipValueGetter: (params: any) => params.value,
    cellStyle: (params: any) => {
      if (params.value === 'SUPPLIER INVENTORY') {
        return {
          background: '#E4ECF0',
          display: 'flex',
          'justify-content': 'center',
        };
      }
      return {
        'white-space': 'break-spaces',
        'word-break': 'break-word',
        'line-height': '20px',
        display: 'flex',
        'justify-content': flexStart,
        height: '40px',
        padding: '0 10px',
      };
    },
    colSpan: (params: any) => {
      return params.data.row === 4 ? 2 : 1;
    },
    wrapText: true,
  },
  {
    headerName: '',
    field: 'QUANTITIES2',
    maxWidth: 100,
    minWidth: 60,
    cellRendererFramework: PPFInputCellRendererComponent,
  },
];

export const column1 = [
  'PART SIZE',
  'CONTAINER OUTSIDE DIMENSION',
  'CONTAINER INSIDE DIMENSION',
  'CONTAINER RETURN HEIGHT',
  'PALLET ONLY',
  'PALLET LOAD AS SHIPPED',
];

export const column2 = [
  'PART',
  'DUNNAGE(TARE)',
  'CONTAINER/RACK(TARE)',
  'PALLET(TARE)	',
  'CONTAINER/RACK GROSS(INCLUDE PARTS)',
  'PALLET LOAD GROSS(INCLUDE PARTS)',
];
export const column3 = [
  'QUANTITY PARTS/CONTAINER/RACK',
  'MAXIMUM CONTAINER/LAYER ON PALLET',
  'MAXIMUM LAYERS/PALLET',
  'SUPPLIER INVENTORY',
  'SUPPLIER INTERNAL INVENTORY REQUIREMENT (DAYS)',
  'SUPPLIER INTERNAL INVENTORY ALLOCATION (DAYS)',
];
