import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-update-ppf',
  templateUrl: './update-ppf.component.html',
  styleUrls: ['./update-ppf.component.scss']
})
export class UpdatePpfComponent implements OnInit {
  reasonText = false;
  rejectReason: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public router:Router,
    public dialogRef: MatDialogRef<UpdatePpfComponent>,
    public formbuilder : FormBuilder,
    public ppfService: PpfService,
    private readonly constants: ConstantsService
  ) { }

  public myForm !: FormGroup ;
  public validForm  = true;
  public metaData:any = {};
  public details:any ;
  public whatDropdown:any ;
  public whyDropdown:any ;
  public updateForm:any ;

  ngOnInit(): void { 
    
    this.metaData = JSON.parse(sessionStorage.getItem('PPFqueryParams') || '{}');
    this.ppfService.updatePPFReason(this.metaData).subscribe((res) =>{
        this.details = res.details[0]; 
        if(this.details.ISUPDATEPPF === 2){
          this.reasonText = true;
          this.rejectReason = res?.rejectReason
        }
        this.whatDropdown = res.what;
        this.whyDropdown = res.why;
      },
    )
   
    this.updateForm = this.formbuilder.group(
      {
        reasonwhat: ['',Validators.required],
        reasonwhatcomment: [''],
        reasonwhy: ['',Validators.required],
        reasonwhycomment: [''],
        pkgstartdate: [formatDate(this.metaData?.pkgStartDate, 'yyyy-MM-dd', 'en'),Validators.required]
      }
    )

    this.updateForm.valueChanges.subscribe((_value:any)=>{
      this.validForm = !this.updateForm.valid;
    })
   
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  routeToPPF(){
    this.updateForm.markAllAsTouched()
    if(this.updateForm.valid){
      this.updateForm.value.ppfNumber = this.details?.PPFCODE;
      this.updateForm.value.supplierCode = this.metaData?.supplierCode;
      this.updateForm.value.view = (this.details.ISUPDATEPPF !== 0 && this.details.ISUPDATEPPF !== null) ? 4 : 3;
      this.updateForm.value.ISUPDATEPPF = this.details.ISUPDATEPPF;
      sessionStorage.setItem('ppfPassData', JSON.stringify(this.updateForm.value))
      this.dialogClose();
      const plantCode = this.constants.getTypeFromPlantCode(this.data.PLANTCODE)
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('ppfPlantCode', this.data.PLANTCODE);
        this.router.navigate([`tracking/serviceparts-pdf/${this.data.PPFCODE}/${this.updateForm.value.view}/${this.details.PARTNUMBER}/${1}/${1}`])
      } else if (plantCode === 'ASSY') {
        sessionStorage.setItem('ppfPlantCode', this.data.PLANTCODE);
        this.router.navigate([`tracking/accessoryParts-pdf/${this.data.PPFCODE}/${this.updateForm.value.view}/${this.details.PARTNUMBER}/${1}/${1}`])
      }
       else {
        this.router.navigate(['/tracking/ppf/', this.data.SUPPLIERCODE]);
      }
    }    
  }

}
