import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorInterceptorServiceService } from './error-interceptor-service.service';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable()
export class ErrorInterceptorInterceptor implements HttpInterceptor {

  constructor(private readonly errorService:ErrorInterceptorServiceService, private readonly constants: ConstantsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error:HttpErrorResponse) => {
        request.url.includes(this.constants.EXTERNAL) && this.errorService.handleError(error)
        return throwError(()=> error)
      })
    )
  }
}
