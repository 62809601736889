import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'decision-link-btn-cell-render',
  template: `
  <a (click)="openDialog()" style="text-decoration:underline">{{ text }}</a>
  `,
})

export class DecisionLinkBtnCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog,
    private readonly constants: ConstantsService) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }
  private params: any;
  text: any;
  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'AUTH#') {
      this.text = this.params?.data?.authnumber;
    }
    else if (params.colDef.headerName === 'PART NUMBER') {
      if (typeof params.value === 'undefined' || params.value === null) {
       this.text=""
      }else{
        this.text = `${this.params?.data?.partnumber?.slice(0, 5)}-${this.params?.data?.partnumber?.slice(5)}`;
      }
    }
  }
  openDialog() {
    if (this.params.colDef.headerName === 'AUTH#') {
      const auth=this.params?.data?.authnumber
      if(this.params.data.authtype === '10'){
      this.router.navigateByUrl(
        '/decision-list/backup-auth-details?auth=' + auth,
      )
      }
      else{
      this.router.navigateByUrl(
        '/decision-list/auth-detail?auth=' + auth,
      )
      }
    }
    else if (this.params.colDef.headerName === 'PART NUMBER'){
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data?.plantcode)
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber',this.params?.data?.partnumber);
        this.router.navigate(['/decision-list/serviceparts-pdf', this.params?.data?.ppfcode,this.params?.data?.ppfver, 1, 2])
      }  else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber',this.params?.data?.partnumber);
        this.router.navigate(['/decision-list/accessoryParts-pdf', this.params?.data?.ppfcode,this.params?.data?.ppfver, 1, 2])
      }
      else{
      this.router.navigate(['/decision-list/ppf',this.params?.data?.ppfcode,this.params?.data?.ppfver,1,2]) 
      }      
    }
 }
}




