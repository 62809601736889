<div class="wrapper bg-white">  
    <div class="header shadow-sm"><h2 mat-dialog-title class="p-4 text-capitalize shadow-sm">{{data.dialogType}}</h2></div>
    <div class="mat-typography content">
    <mat-accordion class="example-headers-align" hideToggle multi [formGroup]="endUseForm">
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">PART INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span class="text-black">{{accData?.auth.PARTNUMBER}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Plant<span class="text-black">{{accData?.auth.PLANT}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">PKG Start Date<span class="text-black">{{accData?.auth.PSD}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Model<span class="text-black">{{accData?.meta.model}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">CONTAINER INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData?.auth.CONTCODE}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData?.auth.CONTL}}/{{accData?.auth.CONTW}}/{{accData?.auth.CONTH}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData?.auth.CONTCOST}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData?.auth.CONTWEIGHT}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">QPC<span class="text-black">{{accData?.auth.QPC}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">DUNNAGE INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData?.auth.DUNCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData?.auth.DUNL}}/{{accData?.auth.DUNW}}/{{accData?.auth.DUNH}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData?.auth.DUNWEIGHT}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData?.auth.DUNWEIGHT}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">TOP CAP INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData?.auth.LIDCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData?.auth.LIDL}}/{{accData?.auth.LIDW}}/{{accData?.auth.LIDH}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData?.auth.LIDCOST}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">PALLET INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span class="text-black">{{accData?.auth.PLTCODE}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData?.auth.PLTL}}/{{accData?.auth.PLTW}}/{{accData?.auth.PLTH}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span class="text-black">{{accData?.auth.PLTCOST}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span class="text-black">{{accData?.auth.PLTWEIGHT}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel7 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">ENTRY END DATE</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel7.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-3 d-flex flex-wrap col-12">
                <div class="d-flex align-items-center my-2 col-lg-4 col-md-6 col-12">
                    <label for="from-date" class="col-3">End Date</label>
                    <input type="date" id="from-date" class="col-6" formControlName="entryEndDate"/>
                </div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel8 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">ATTACH BACKUP PACKAGE INVOICE/RECEIPT FROM VENDOR</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel8.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-3 d-flex flex-wrap col-12">
                <div class="d-flex align-items-center my-2 col-lg-4 col-md-6 col-12">
                    <label for="from-date" class="col-3">File</label>
                    <input type="file" id="from-date" class="col-9" accept=".pdf, .xlsx, .doc" size="60" (change)="handleUpload($event)"/>
                </div>
                <span>{{errorMessage}}</span>
            </div>     
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex">
    <div mat-dialog-close class="mx-3 cancel">Cancel</div>
    <button class="black-button mx-3" (click)="onSubmit()" [disabled]='validForm && !this.errorMessage'>Submit</button>
</div>
</div>