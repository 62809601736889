<div class="popup-container">
  <button class="close black-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="d-flex justify-content-between align-items-center m-2 header">
    <div class="page-title">Set Po#</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <div  id='err' class="alert alert-danger mx-2 mt-2" *ngIf="errorList?.length > 0">
    <span  >{{errorList}}</span>
  </div>
  <div id='err' class="alert alert-success mx-2 mt-2" *ngIf="successMessage?.length > 0">
    <span  >{{successMessage}}</span>
  </div>

  <div class="popup-contents m-2 py-2 px-2">
    <div>
      <table class="vpacs-shadow" aria-describedby="setPo">
        <th id="tHead" style="display: none;"></th>
        <tr>
          <td style="background-color:#e4ecf0;width:50%">Auth#</td>
          <td><input [(ngModel)]="auth" type="text" class="col-3" [ngModelOptions]="{ standalone: true }" readonly></td>
        </tr>
        <tr>
          <td style="background-color:#e4ecf0;width:50%">PO# <span class="required-field"></span></td>
          <td><input [(ngModel)]="po" type="text" class="col-3" [ngModelOptions]="{ standalone: true }"></td>
        </tr>

      </table>
    </div>

  </div>
  <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
    <button class="cancel black-button me-3" (click)="close()">Cancel</button>
    <button class=" me-3 black-button" (click)="ok()"> OK</button>

  </div>
</div>