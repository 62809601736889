<div class="d-flex justify-content-between align-items-center">
  <p class="page-title mx-2 px-2">Update Package Fabricator Contact</p>
  <div>
    <button class="help-btn m-2" (click)="onCancel()">BACK</button>
  </div>
</div>

<app-notification [codes]="errorMessage" *ngIf="!viewAccess && errorMessage"></app-notification>
<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="row mx-2 mb-3 align-items-start">

    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Supplier Code<span class="text-black">{{ pkgcode }}</span>
    </div>
    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Short Name<span class="text-black">{{ alphacode }}</span>
    </div>
    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">
      Name<span class="text-black">{{ name }}</span>
    </div>
  </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="grid-agg tracking-summary grid-height-package-fabricator-contact">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham"
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()"
      [rowHeight]="50"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [rowData]="displayRowData"
      [suppressCellSelection]="true"
      [showOpenedGroup]="true"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [suppressHorizontalScroll]="false"
      [icons]="icons"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [domLayout]="domLayout"
      [rowClassRules]="rowClassRules"
      [floatingFiltersHeight]="40"
    >
    </ag-grid-angular>
  </div>
</div>
<router-outlet></router-outlet>
