import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'

@Component({
  selector: 'app-add-plant',
  templateUrl: './add-plant.component.html',
  styleUrls: ['./add-plant.component.scss'],
})
export class AddPlantComponent implements OnInit {
  assMgr = 'Assistant Manager'
  type = [
    { Id: 0, name: 'Specialist' },
    { Id: 1, name: this.assMgr },
    { Id: 2, name: 'Manager' },
  ]
  type1 = [
    { Id: 0, name: 'Specialist' },
    { Id: 1, name: this.assMgr },
    { Id: 2, name: 'Manager' },
  ]
  type2 = [
    { Id: 0, name: 'Specialist' },
    { Id: 1, name: this.assMgr },
    { Id: 2, name: 'Manager' },
  ]

  components = [
    'Select',
    'All',
    'Initial',
    'Receive Pre PPF',
    'Send Pre PPF',
    'Approval Pre PPF',
    'Trial Order',
    'Send Final PPF',
    'Approval Final',
    'Dunnage',
  ]

  component = 'Select'
  codeFlag = false
  dCodeFlag = false
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'
  ty: any
  ty1: any
  ty2: any
  plant: any
  supplier: any
  Suppliers: any[] = []
  Packages: any[] = []
  Monetarys: any[] = []
  isEditMode = false
  plantCode = ''
  plantName = ''
  plantId = ''
  amCost: any = ''
  mgrCost: any = ''
  prePpfApproval = ''
  trialApproval = ''
  finalPpfApproval = ''
  purProtoAppcost = ''
  purProdAppCost = ''
  purMainteAppCost = ''
  sup: any = ''
  montry: any = ''
  pkg: any = ''
  Value: any
  isAdd!: boolean
  isPlantCodeError = false
  isPlantNameError = false
  isPlantIdError = false
  isAmCostError = false
  isMgrCostError = false
  errorList: any[] = []
  isAmMgrCostError = false
  amNumeric = false
  mgNumeric = false
  isProtoAppError = false
  protoNumeric = false
  prodNumeric = false
  isProdAppError = false
  isMainteAppError = false
  mainteNumeric = false

  constructor(
    public plantService: PlantService,
    public message: MatSnackBar,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<AddPlantComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.addPlantOnload()
    if (this.dataStore.mode === 'ADD') {
      this.modalHeader = 'Add Plant'
      this.buttonValue = 'ADD'
      this.codeFlag = true
      this.isAdd = true
      this.isEditMode = false
      const selectedType = this.type.find(
        (type) => type.Id === 0 && type.name === 'Specialist',
      )
      this.ty = selectedType
      const selectedType1 = this.type1.find(
        (type1) => type1.Id === 0 && type1.name === 'Specialist',
      )
      this.ty1 = selectedType1
      const selectedType2 = this.type2.find(
        (type2) => type2.Id === 0 && type2.name === 'Specialist',
      )
      this.ty2 = selectedType2
    } else if (this.dataStore.mode === 'UPDATE') {
      this.modalHeader = 'Update Plant'
      this.buttonValue = 'UPDATE'
      this.dCodeFlag = true
      this.isAdd = false
      this.plantCode = this.dataStore?.field?.plantcode
      this.plantName = this.dataStore.field.name
      this.plantId = this.dataStore.field.plantid
      this.amCost = Number(this.dataStore.field.amcost)
        ? this.dataStore.field.amcost
        : ''
      this.mgrCost = Number(this.dataStore.field.mgrcost)
        ? this.dataStore.field.mgrcost
        : ''
      const selectedType = this.type.find(
        (type) => type.Id === this.dataStore.field.preppfapproval,
      )
      this.ty = selectedType
      const selectedType1 = this.type1.find(
        (type1) => type1.Id === this.dataStore.field.trialapproval,
      )
      this.ty1 = selectedType1
      const selectedType2 = this.type2.find(
        (type2) => type2.Id === this.dataStore.field.finalppfapproval,
      )
      this.ty2 = selectedType2
      this.purProtoAppcost = Number(this.dataStore.field.purprotoappcost)
        ? this.dataStore.field.purprotoappcost
        : ''
      this.purProdAppCost = Number(this.dataStore.field.purprodappcost)
        ? this.dataStore.field.purprodappcost
        : ''
      this.purMainteAppCost = Number(this.dataStore.field.purmainteappcost)
        ? this.dataStore.field.purmainteappcost
        : ''
      this.sup = this.dataStore.field.suppliercodetype
      this.pkg = this.dataStore.field.packagecosttype
      this.montry = this.dataStore.field.monetaryunit
      this.isEditMode = true
    }
  }
  addPlantOnload(): void {
    const loadObj = {
      PlantCode: '',
    }
    setTimeout(() => {this.spinnerService.start()})
    this.plantService.addPlantOnload(loadObj)?.subscribe({
      next: (res: any) => {
        this.Suppliers = res.DropDownResult.SupplierType
        this.Packages = res.DropDownResult.PackageType
        this.Monetarys = res.DropDownResult.MonetaryUnit
        if (this.dataStore.mode === 'ADD') {
          this.sup = this.Suppliers[0]
          this.pkg = this.Packages[0]
          this.montry = this.Monetarys[0]
        }
        if (this.dataStore.mode === 'UPDATE') {
          this.buttonValue = 'UPDATE'
          this.Suppliers = res.DropDownResult?.SupplierType
          this.Packages = res.DropDownResult?.PackageType
          this.Monetarys = res.DropDownResult?.MonetaryUnit
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg })
  }

  checkValidations() {
    this.errorList = []
    this.isPlantCodeError = false
    this.isPlantNameError = false
    this.isPlantIdError = false
    this.isAmMgrCostError = false
    this.isAmCostError = false
    this.isMgrCostError = false
    this.amNumeric = false
    this.mgNumeric = false
    this.protoNumeric = false
    this.isProtoAppError = false
    this.prodNumeric = false
    this.isProdAppError = false
    this.isMainteAppError = false
    this.mainteNumeric = false
    this.isPlantCodeError = !this.plantCode ? true : false
    this.isPlantNameError = !this.plantName ? true : false
    this.isPlantIdError = !this.plantId ? true : false

    if (this.amCost) {
      this.isNumeric('amCost')
    }
    if (this.mgrCost) {
      this.isNumeric('mgrCost')
    } 
    if (this.purProtoAppcost) {
      this.isNumeric('protoAppcost')
    }
    if (this.purProdAppCost) {
      this.isNumeric('prodAppCost')
    }
    if (this.purMainteAppCost) {
      this.isNumeric('mainteApp')
    } 
    if (
      (Number(this.amCost) || Number(this.mgrCost)) &&
      Number(this.amCost) >= Number(this.mgrCost)
    ) {
      this.isAmMgrCostError = true
    }
  }

  isNumeric(name: any) {
    if (name === 'amCost') {
      this.isAmCostError =
        !isNaN(parseFloat(this.amCost)) && isFinite(+this.amCost)
      if (!this.isAmCostError) {
        this.amNumeric = true
      } else {
        this.amNumeric = false
        this.isAmCostError = false
      }
    }
    if (name === 'mgrCost') {
      this.isMgrCostError =
        !isNaN(parseFloat(this.mgrCost)) && isFinite(+this.mgrCost)
      if (!this.isMgrCostError) {
        this.mgNumeric = true
      } else {
        this.mgNumeric = false
        this.isMgrCostError = false
      }
    }
    if (name === 'protoAppcost') {
      this.isProtoAppError =
        !isNaN(parseFloat(this.purProtoAppcost)) &&
        isFinite(+this.purProtoAppcost)
      if (!this.isProtoAppError) {
        this.protoNumeric = true
      } else {
        this.protoNumeric = false
        this.isProtoAppError = false
      }
    }
    if (name === 'prodAppCost') {
      this.isProdAppError =
        !isNaN(parseFloat(this.purProdAppCost)) &&
        isFinite(+this.purProdAppCost)
      if (!this.isProdAppError) {
        this.prodNumeric = true
      } else {
        this.prodNumeric = false
        this.isProdAppError = false
      }
    }
    if (name === 'mainteApp') {
      this.isMainteAppError =
        !isNaN(parseFloat(this.purMainteAppCost)) &&
        isFinite(+this.purMainteAppCost)
      if (!this.isMainteAppError) {
        this.mainteNumeric = true
      } else {
        this.mainteNumeric = false
        this.isMainteAppError = false
      }
    }
  }

  saveData() {
    this.checkValidations()
    if (
      this.isPlantCodeError ||
      this.isPlantNameError ||
      this.isPlantIdError ||
      this.isAmMgrCostError ||
      this.mgNumeric ||
      this.amNumeric ||
      this.prodNumeric ||
      this.protoNumeric ||
      this.mainteNumeric
    ) {
      return
    }
    if (this.buttonValue === 'ADD') {
      const loadObj = {
        PlantCode: this.plantCode,
        PlantName: this.plantName,
        PlantID: this.plantId,
        AMCost: Number(this.amCost),
        MGRCost: Number(this.mgrCost),
        PURPROTOAPPCOST: Number(this.purProtoAppcost),
        PURPRODAPPCOST: Number(this.purProdAppCost),
        PURMAINTEAPPCOST: Number(this.purMainteAppCost),
        PrePPFApproval: this.ty.Id,
        TrialApproval: this.ty1.Id,
        FinalPPFApproval: this.ty2.Id,
        MonetaryUnit: this.montry.Value,
        SupplierCodeType: this.pkg.Value,
        PackageCostType: this.pkg.Value,
      }
      setTimeout(() => {this.spinnerService.start()});
      this.plantService.addPlant(loadObj).subscribe({
        next: (data: any) => {
          if (!data.status) {
            this.errorList.push(data.message)
          } else {
            this.dialogRef.close({ data: { result: 'SUCCESS', data } })
          }
          this.spinnerService.stop()
        },
        error: () => {
          this.spinnerService.stop()
          this.openMessageBox('Error adding the file', 'error')
        },
      })
    } else if (this.buttonValue === 'UPDATE') {
      const loadObj = {
        PlantCode: this.plantCode,
        PlantName: this.plantName,
        PlantID: this.plantId,
        AMCost: this.amCost || 0,
        MGRCost: this.mgrCost || 0,
        PURPROTOAPPCOST: this.purProtoAppcost || 0,
        PURPRODAPPCOST: this.purProdAppCost || 0,
        PURMAINTEAPPCOST: this.purMainteAppCost || 0,
        PrePPFApproval: this.ty?.Id,
        TrialApproval: this.ty1?.Id,
        FinalPPFApproval: this.ty2?.Id,
        MonetaryUnit: this.montry,
        SupplierCodeType: this.sup,
        PackageCostType: this.pkg,
        OldPlantCode: this.plantCode,
      }
      setTimeout(() => {this.spinnerService.start()});
      this.plantService.updatePlant(loadObj).subscribe({
        next: (data) => {
          const result = data?.status ? 'SUCCESS' : '';
          this.dialogRef.close({ data: { result: result, data } })
          this.spinnerService.stop()
        },
        error: () => {
          this.spinnerService.stop()
          this.openMessageBox('Error updating the file', 'error')
        },
      })
    } else {
      this.close('')
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  help() {
    this.dialogRef.close()
    if (this.modalHeader === 'Add Plant') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 110])
    } else if (this.modalHeader === 'Update Plant') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 111])
    }
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    })
  }
}
