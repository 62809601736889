<div class="popup-container">
  <div class="mx-3 header d-flex align-items-center justify-content-between">
    <span>New Part Confirmation</span>
    <div class="d-flex" *ngIf="viewAccess">
      <button class="black-button mx-3" (click)="handleOkClick()">OK</button>
      <button class="black-button mx-3" (click)="navigateToModelList()">Back</button>
    </div>
  </div>
  <app-notification [codes]="errorMessage" *ngIf="viewAccess===false && errorMessage"></app-notification>
  <div class="filter d-flex m-3" *ngIf="viewAccess">
    <div class="d-flex col-4 flex-wrap">
      <span class="col-6">Model Code</span>
      <span class="col-6">{{routeParams.modelcode}}</span>
      <span class="col-6">Total New Part</span>
      <span class="col-6">{{displayRowData.length}}</span>
    </div>
  </div>
  <div class="grid-agg mt-3 grid-height" *ngIf="viewAccess">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham"
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [rowData]="displayRowData"
      [gridOptions]="gridOptions"
      [suppressCellSelection]="true"
      [icons]="icons"
      [headerHeight]="headerHeight"
      [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40"
      [domLayout]="domLayout"
    >
    </ag-grid-angular>
    <app-pagination
      [cPage]="cPage"
      [tPage]="tPage"
      [rpPage]="rpPage"
      [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)"
      *ngIf="totalRecords > 0"
    >
    </app-pagination>
  </div>
</div>
