<div id='err' class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let i of errorList ">{{i}}</div>
</div>
<div id='err' class="alert alert-success mx-2" *ngIf="successMessage.length > 0">
    <span>{{successMessage}}</span>
</div>
<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title mx-3">{{pageType}}</p>
    <div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<form [formGroup]="form">
    <div class="d-flex flex-wrap col mx-2 align-items-start">
        <div class="d-flex align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">Code<small *ngIf="isRequired('code')">*</small></label>
            <div class="d-flex flex-column">
                <input type="text" formControlName="code" />
                <div *ngIf="isValid['code']?.invalid && (isValid['code']?.dirty || isValid['code']?.touched)">
                    <small *ngIf="isValid['code']?.hasError('required')" class="errorValiid">
                        Code Required
                    </small>
                    <div *ngIf="isValid['code'].errors?.['maxlength'] " class="errorValiid">length exceeded 15
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2 ">
            <label class="col-4">Group Code</label>
            <div class="d-flex flex-column">
                <input type="text" formControlName="groupeCode" />
                <div
                    *ngIf="isValid['groupeCode']?.invalid && (isValid['groupeCode']?.dirty || isValid['groupeCode']?.touched)">
                    <div *ngIf="isValid['groupeCode'].errors?.['required']  " class="errorValiid"> Group Code Required
                    </div>
                    <div *ngIf="isValid['groupeCode'].errors?.['maxlength']  " class="errorValiid"> length exceeded 12
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">Type<small *ngIf="isRequired('type')">*</small></label>
            <div class="d-flex flex-column">
                <mat-select class="mat-sel pt-1" formControlName="type" (selectionChange)="selected($event)">
                    <mat-option *ngFor="let type of types" [value]="type.packagetypecode">{{type.packagetypecode}} :
                        {{type.packagetype}}</mat-option>
                </mat-select>
                <div *ngIf="isValid['type']?.invalid && (isValid['type']?.dirty || isValid['type']?.touched)">
                    <div *ngIf="isValid['type'].errors?.['required'] " class="errorValiid">Type required</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">Description</label>
            <div class="d-flex flex-column">
                <input type="text" formControlName="description" />
                <div
                    *ngIf="isValid['description']?.invalid && (isValid['description']?.dirty || isValid['description']?.touched)">
                    <div *ngIf="isValid['description'].errors?.['required'] " class="errorValiid"> description Required
                    </div>
                    <div *ngIf="isValid['description'].errors?.['maxlength']  " class="errorValiid"> length exceeded 20
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4 lbl">Dunnage Attach Method<small *ngIf="isRequired('dunnageAttach')">*</small></label>
            <div class="d-flex flex-column">
                <mat-select id="supplier" class="mat-sel pt-1" formControlName="dunnageAttach">
                    <mat-option [value]=1>Rivets</mat-option>
                    <mat-option [value]=2>Velcro</mat-option>
                    <mat-option [value]=3>Glue</mat-option>
                    <mat-option [value]=4>Bolts</mat-option>
                    <mat-option [value]=5>Other-Totes</mat-option>
                    <mat-option [value]=6>Other-Rack</mat-option>
                </mat-select>
                <div
                    *ngIf="isValid['dunnageAttach']?.invalid && (isValid['dunnageAttach']?.dirty || isValid['dunnageAttach']?.touched)">
                    <div *ngIf="isValid['dunnageAttach'].errors?.['required']  " class="errorValiid"> Dunnage Attach
                        Method Required</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">OD Length <small *ngIf="isRequired('ODLength2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="ODLength1"
                        (change)="changeMM($event, 'ODLength2','ODLength1','html')" />
                    <input type="text" style="width:40px;" formControlName="ODLength2"
                        (change)="changemmToInches($event, 'ODLength1','ODLength2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['ODLength1']?.invalid || isValid['ODLength2']?.invalid)  && (isValid['ODLength1']?.dirty || isValid['ODLength1']?.touched || isValid['ODLength2']?.dirty || isValid['ODLength2']?.touched)">
                    <div *ngIf="(isValid['ODLength1'].errors?.['required'] || isValid['ODLength2'].errors?.['required'] ) "
                        class="errorValiid">OD Length Required</div>
                    <div *ngIf="(isValid['ODLength1'].errors?.['pattern'] || isValid['ODLength2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['ODLength1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">OD width<small *ngIf="isRequired('ODwidth2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="ODwidth1"
                        (change)="changeMM($event, 'ODwidth2','ODwidth1','html')" />
                    <input type="text" style="width:40px;" formControlName="ODwidth2"
                        (change)="changemmToInches($event, 'ODwidth1','ODwidth2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['ODwidth1']?.invalid || isValid['ODwidth2']?.invalid)  && (isValid['ODwidth1']?.dirty || isValid['ODwidth1']?.touched || isValid['ODwidth2']?.dirty || isValid['ODwidth2']?.touched)">
                    <div *ngIf="(isValid['ODwidth1'].errors?.['required'] || isValid['ODwidth2'].errors?.['required'] ) "
                        class="errorValiid"> OD width Required</div>
                    <div *ngIf="(isValid['ODwidth1'].errors?.['pattern'] || isValid['ODwidth2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['ODwidth1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">OD Height<small *ngIf="isRequired('ODHeight2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="ODHeight1"
                        (change)="changeMM($event, 'ODHeight2','ODHeight1','html')" />
                    <input type="text" style="width:40px;" formControlName="ODHeight2"
                        (change)="changemmToInches($event, 'ODHeight1','ODHeight2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['ODHeight1']?.invalid || isValid['ODHeight2']?.invalid)  && (isValid['ODHeight1']?.dirty || isValid['ODHeight1']?.touched || isValid['ODHeight2']?.dirty || isValid['ODHeight2']?.touched)">
                    <div *ngIf="(isValid['ODHeight1'].errors?.['required'] || isValid['ODHeight2'].errors?.['required'] ) "
                        class="errorValiid"> OD Height Required</div>
                    <div *ngIf="(isValid['ODHeight1'].errors?.['pattern'] || isValid['ODHeight2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['ODHeight1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label for="shippingClass" class="col-4">Material<small *ngIf="isRequired('material')">*</small></label>
            <div class="d-flex flex-column">
                <mat-select class="mat-sel pt-1" formControlName="material">
                    <mat-option *ngFor="let material of materials" [value]="material.materialcode">
                        {{material.materialcode}} : {{material.material}}</mat-option>
                </mat-select>
                <div
                    *ngIf="isValid['material']?.invalid && (isValid['material']?.dirty || isValid['material']?.touched)">
                    <div *ngIf="isValid['material'].errors?.['required'] " class="errorValiid"> Material Required</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">S/N<small *ngIf="isRequired('standard')">*</small></label>
            <div class="d-flex flex-column">
                <mat-select id="supplier" class="mat-sel pt-1" formControlName="standard">
                    <mat-option value="S">S:Standard</mat-option>
                    <mat-option value="N">N:Non Standard</mat-option>
                </mat-select>
                <div
                    *ngIf="isValid['standard']?.invalid && (isValid['standard']?.dirty || isValid['standard']?.touched)">
                    <div *ngIf="isValid['standard'].errors?.['required'] " class="errorValiid"> S/N Required</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">ID Length<small *ngIf="isRequired('IDLength2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="IDLength1"
                        (change)="changeMM($event, 'IDLength2','IDLength1','html')" />
                    <input type="text" style="width:40px;" formControlName="IDLength2"
                        (change)="changemmToInches($event, 'IDLength1','IDLength2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['IDLength2']?.invalid || isValid['IDLength1']?.invalid) && (isValid['IDLength1']?.dirty || isValid['IDLength1']?.touched || isValid['IDLength2']?.dirty || isValid['IDLength2']?.touched)">
                    <div *ngIf="(isValid['IDLength1'].errors?.['required'] || isValid['IDLength2'].errors?.['required'] ) "
                        class="errorValiid"> ID Length Required</div>
                    <div *ngIf="(isValid['IDLength1'].errors?.['pattern'] || isValid['IDLength2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['IDLength1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">ID width<small *ngIf="isRequired('IDwidth2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="IDwidth1"
                        (change)="changeMM($event, 'IDwidth2','IDwidth1','html')" />
                    <input type="text" style="width:40px;" formControlName="IDwidth2"
                        (change)="changemmToInches($event, 'IDwidth1','IDwidth2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['IDwidth1']?.invalid || isValid['IDwidth2']?.invalid) && (isValid['IDwidth1']?.dirty || isValid['IDwidth1']?.touched || isValid['IDwidth2']?.dirty || isValid['IDwidth2']?.touched)">
                    <div *ngIf="(isValid['IDwidth1'].errors?.['required'] || isValid['IDwidth2'].errors?.['required'] ) "
                        class="errorValiid">ID width Required</div>
                    <div *ngIf="(isValid['IDwidth1'].errors?.['pattern'] || isValid['IDwidth2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['IDwidth1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">ID Height<small *ngIf="isRequired('IDHeight2')">*</small></label>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="IDHeight1"
                        (change)="changeMM($event, 'IDHeight2','IDHeight1','html')" />
                    <input type="text" style="width:40px;" formControlName="IDHeight2"
                        (change)="changemmToInches($event, 'IDHeight1','IDHeight2')" />
                    <label class="col-2">mm</label>
                </div>
                <div
                    *ngIf="(isValid['IDHeight1']?.invalid || isValid['IDHeight2']?.invalid) && (isValid['IDHeight1']?.dirty || isValid['IDHeight1']?.touched || isValid['IDHeight2']?.dirty || isValid['IDHeight2']?.touched)">
                    <div *ngIf="(isValid['IDHeight1'].errors?.['required'] || isValid['IDHeight2'].errors?.['required'] ) "
                        class="errorValiid"> ID Height Required</div>
                    <div *ngIf="(isValid['IDHeight1'].errors?.['pattern'] || isValid['IDHeight2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['IDHeight1'].errors?.['maxlength']  " class="errorValiid">length exceeded 6
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label for="shippingClass" class="col-4">R/E<small *ngIf="isRequired('RE')">*</small></label>
            <div class="d-flex flex-column">
                <mat-select id="supplier" class="mat-sel pt-1" formControlName="RE">
                    <mat-option *ngFor="let type of returns" [value]="type.code" (click)="selected(type.code)">
                        {{type.code}} : {{type.Name}}</mat-option>
                </mat-select>
                <div *ngIf="isValid['RE']?.invalid && (isValid['RE']?.dirty || isValid['RE']?.touched)">
                    <div *ngIf="isValid['RE'].errors?.['required']  " class="errorValiid"> R/E Required</div>
                    <div *ngIf="isValid['RE'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6 characters
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">Qty Cont/Layer<small *ngIf="isRequired('qtyContLayer')">*</small></label>
            <div class="d-flex flex-column">
                <input type="text" formControlName="qtyContLayer" />
                <div
                    *ngIf="isValid['qtyContLayer']?.invalid && (isValid['qtyContLayer']?.dirty || isValid['qtyContLayer']?.touched)">
                    <div *ngIf="isValid['qtyContLayer'].errors?.['required']   " class="errorValiid">Qty Cont/Layer
                        Required</div>
                    <div *ngIf="isValid['qtyContLayer'].errors?.['pattern']   " class="errorValiid"> please provide a
                        number</div>
                    <div *ngIf="isValid['qtyContLayer'].errors?.['maxlength']  " class="errorValiid">length exceeded 10
                        characters</div>
                </div>
            </div>
        </div>
        <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
            <label class="col-4">Weight<small *ngIf="isRequired('Weight2')">*</small></label>

            <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap-2">
                    <input type="text" style="width:40px;" formControlName="Weight1"
                        (change)="changeWeight($event, 'Weight2')" />
                    <input type="text" style="width:40px;" formControlName="Weight2"
                        (change)="changeWeightTolbs($event, 'Weight1')" />
                    <label class="col-2">kg</label>
                </div>
                <div
                    *ngIf="(isValid['Weight1']?.invalid || isValid['Weight2']?.invalid) && (isValid['Weight1']?.dirty || isValid['Weight1']?.touched || isValid['Weight2']?.dirty || isValid['Weight2']?.touched)">
                    <div *ngIf="(isValid['Weight1'].errors?.['required'] || isValid['Weight2'].errors?.['required'] ) "
                        class="errorValiid"> Weight Required</div>
                    <div *ngIf="(isValid['Weight1'].errors?.['pattern'] || isValid['Weight2'].errors?.['pattern'] ) "
                        class="errorValiid"> please provide a number</div>
                    <div *ngIf="isValid['Weight1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 5
                        characters</div>
                    <div *ngIf="isValid['Weight2'].errors?.['max'] || isValid['Weight2'].errors?.['min'] "
                        class="errorValiid"> 0 < weight < 99999 </div>
                    </div>
                </div>
            </div>
            <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Nesting<small *ngIf="isRequired('nesting2')">*</small></label>
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center gap-2">
                        <input type="text" style="width:40px;" formControlName="nesting1"
                            (change)="changeMM($event, 'nesting2','nesting1','html')" />
                        <input type="text" style="width:40px;" formControlName="nesting2"
                            (change)="changemmToInches($event, 'nesting1','nesting2')" />
                        <label class="col-2">mm</label>
                    </div>
                    <div
                        *ngIf="(isValid['nesting1']?.invalid || isValid['nesting2']?.invalid) && (isValid['nesting1']?.dirty || isValid['nesting1']?.touched || isValid['nesting2']?.dirty || isValid['nesting2']?.touched)">
                        <div *ngIf="(isValid['nesting1'].errors?.['required'] || isValid['nesting2'].errors?.['required'] ) "
                            class="errorValiid"> Nesting Required</div>
                        <div *ngIf="(isValid['nesting1'].errors?.['pattern'] || isValid['nesting2'].errors?.['pattern'] ) "
                            class="errorValiid"> please provide a number</div>
                        <div *ngIf="isValid['nesting1'].errors?.['maxlength']  " class="errorValiid"> length exceeded 6
                            characters</div>
                    </div>
                </div>
            </div>
            <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Return Height<small *ngIf="isRequired('returnHeight2')">*</small></label>
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center gap-2">
                        <input type="text" style="width:40px;" formControlName="returnHeight1"
                            (change)="changeMM($event, 'returnHeight2','returnHeight1','html')" />
                        <input type="text" style="width:40px;" formControlName="returnHeight2"
                            (change)="changemmToInches($event, 'returnHeight1','returnHeight2')" />
                        <label class="col-2">mm</label>
                    </div>
                    <div
                        *ngIf="(isValid['returnHeight1']?.invalid || isValid['returnHeight2']?.invalid) && (isValid['returnHeight1']?.dirty || isValid['returnHeight1']?.touched || isValid['returnHeight2']?.dirty || isValid['returnHeight2']?.touched)">
                        <div *ngIf="(isValid['returnHeight1'].errors?.['required'] || isValid['returnHeight2'].errors?.['required'] ) "
                            class="errorValiid"> Return Height Required</div>
                        <div *ngIf="(isValid['returnHeight1'].errors?.['pattern'] || isValid['returnHeight2'].errors?.['pattern'] ) "
                            class="errorValiid"> please provide a number</div>
                        <div *ngIf="isValid['returnHeight1'].errors?.['maxlength']  " class="errorValiid"> length
                            exceeded 6 characters</div>
                    </div>
                </div>
            </div>
            <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label for="shippingClass" class="col-4">Qty Layer/Pallet<small
                        *ngIf="isRequired('qtyLayerPallet')">*</small></label>
                <div class="d-flex flex-column">
                    <input type="text" id="shippingClass" formControlName="qtyLayerPallet" />
                    <div
                        *ngIf="isValid['qtyLayerPallet']?.invalid && (isValid['qtyLayerPallet']?.dirty || isValid['qtyLayerPallet']?.touched)">
                        <div *ngIf="isValid['qtyLayerPallet'].errors?.['required']  " class="errorValiid">Qty
                            Layer/Pallet Required</div>
                        <div *ngIf="isValid['qtyLayerPallet'].errors?.['pattern']  " class="errorValiid"> please provide
                            a number</div>
                        <div *ngIf="isValid['qtyLayerPallet'].errors?.['maxlength']  " class="errorValiid"> length
                            exceeded 10 characters</div>
                    </div>
                </div>
            </div>
            <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Qty Kholder<small *ngIf="isRequired('qtyKholder')">*</small></label>
                <div class="d-flex flex-column">
                    <input type="text" formControlName="qtyKholder" />
                    <div
                        *ngIf="isValid['qtyKholder']?.invalid && (isValid['qtyKholder']?.dirty || isValid['qtyKholder']?.touched)">
                        <div *ngIf="isValid['qtyKholder'].errors?.['required']   " class="errorValiid"> Qty Kholder
                            Required</div>
                        <div *ngIf="isValid['qtyKholder'].errors?.['pattern']   " class="errorValiid"> please proide a
                            number</div>
                        <div *ngIf="isValid['qtyKholder'].errors?.['maxlength']  " class="errorValiid"> length exceeded
                            10 characters</div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Qty of Label<small *ngIf="isRequired('qtyofLabel')">*</small></label>
                <div class="d-flex flex-column">
                    <input type="text" formControlName="qtyofLabel" />
                    <div
                        *ngIf="isValid['qtyofLabel']?.invalid && (isValid['qtyofLabel']?.dirty || isValid['qtyofLabel']?.touched)">
                        <div *ngIf="isValid['qtyofLabel'].errors?.['required'] " class="errorValiid"> Qty of Label
                            Required</div>
                        <div *ngIf="isValid['qtyofLabel'].errors?.['pattern'] " class="errorValiid"> please provide a
                            number</div>
                        <div *ngIf="isValid['qtyofLabel'].errors?.['maxlength']  " class="errorValiid"> length exceeded
                            10 characters</div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Cost of Label<small *ngIf="isRequired('costofLabel')">*</small></label>
                <div class="d-flex flex-column">
                    <input type="text" formControlName="costofLabel" />
                    <div
                        *ngIf="isValid['costofLabel']?.invalid && (isValid['costofLabel']?.dirty || isValid['costofLabel']?.touched)">
                        <div *ngIf="isValid['costofLabel'].errors?.['required']  " class="errorValiid"> Cost of Label
                            Required</div>
                        <div *ngIf="isValid['costofLabel'].errors?.['pattern']  " class="errorValiid"> please provide a
                            number</div>
                        <div *ngIf="isValid['costofLabel'].errors?.['max']  " class="errorValiid"> value exceeded 99999
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex  align-items-start col-lg-2 col-md-6 col-12 mx-4 my-2">
                <label class="col-4">Lead Time(Days)<small *ngIf="isRequired('leadTime')">*</small></label>
                <div class="d-flex flex-column">
                    <input type="text" formControlName="leadTime" />
                    <div
                        *ngIf="isValid['leadTime']?.invalid && (isValid['leadTime']?.dirty || isValid['leadTime']?.touched)">
                        <div *ngIf="isValid['leadTime'].errors?.['required'] " class="errorValiid"> Lead Time Required
                        </div>
                        <div *ngIf="isValid['leadTime'].errors?.['pattern'] " class="errorValiid"> please provide a
                            number</div>
                        <div *ngIf="isValid['leadTime'].errors?.['max']  " class="errorValiid"> value exceeded 999</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap mx-3">
            <div class="d-flex flex-wrap align-items-start col-12 mx-3">
                <span class="draw">Draw</span>
                <div class="col-12">
                    <label class="vpacs-btn-table my-2" style="width: 120px">
                        CHOOSE FILE  
                        <input type="file" accept=".jpg, .xlsx, .pdf, .doc" style="display: none"
                            (change)="handleUploadDraw($event)" formControlName="file1" />
                    </label>
                    <input type="checkbox" id="check1" value="option1" formControlName="checkoffile1" />
                    <label for="check1">Delete</label>
                    <div *ngIf="showDrawfile?.length>0" class='col-12'> {{showDrawfile}} </div>
                </div>
                <span>{{drawError}}</span>
            </div>
            <div class="d-flex flex-wrap align-items-start col-12 mx-3">
                <span class="draw">View</span>
                <div class="col-12">
                    <label class="vpacs-btn-table my-2" style="width: 120px">
                        CHOOSE FILE  
                        <input type="file" accept=".jpg, .xlsx, .pdf, .doc" style="display: none"
                            (change)="handleUpload($event)" formControlName="file2" />
                    </label>
                    <input type="checkbox" id="check2" value="option1" formControlName="checkoffile2" />
                    <label for="check2">Delete</label>
                    <div *ngIf="showviewfile?.length>0" class='col-12'> {{showviewfile}} </div>
                </div>
                <span>{{photError}}</span>
            </div>
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 " type="button" (click)='OnCancel()'>BACK </button>
            <button type="submit" (click)='Onsubmit()' class="black-button me-3" [disabled]="errorList.length>0">{{buttonName}}</button>
        </div>
</form>
<router-outlet></router-outlet>