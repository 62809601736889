import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PartListSupplierCodeComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/tracking-part-list/part-list-supplier-code/part-list-supplier.component';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'part-list-hyperLink-btn-cell-render',
  template: `
  <a  (click)="openDialog()"  style="cursor:pointer;color:#0000ff;text-decoration:underline">{{ text }}</a>
  `,
})

export class PartListHyperLinkBtnCellRenderer implements ICellRendererAngularComp {
  supType: any;

  constructor(private readonly router: Router,
    public dialog: MatDialog,
    private readonly state: ExportStateService,
    private readonly constants: ConstantsService) { }

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }

  private params: any;
  text: any;

  agInit(params: any): void {
    this.params = params;

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.supType = res.role.supType;
        }
      }
    })

    if (params.colDef.headerName === 'SUPPLIER CODE') {
      if (this.supType === "0") {
        this.text = params.data.suppliercode
      } else if (this.supType === "1") {
        this.text = (([null, undefined, '', 'undefined', 'null'].includes(params.data.tmmccode)) ? params.data.suppliercode : params.data.tmmccode);
      } else if (this.supType === "2") {
        this.text = (([null, undefined, '', 'undefined', 'null'].includes(params.data.nummicode)) ? params.data.suppliercode : params.data.nummicode);
      }
      Object.assign(params.data, {
        supplierCode: this.text
      });
    }

    else if (params.colDef.headerName === 'SUPPLIER SHORT NAME') {
      this.text = this.params.data.supplier;
    }
    else if (params.colDef.headerName === 'PART NUMBER') {
      this.text = `${this.params?.data?.partnumber?.slice(0, 5)}-${this.params?.data?.partnumber?.slice(5)}`;
    }
  }

  openDialog() {
    if (this.params.colDef.headerName === 'SUPPLIER CODE' || this.params.colDef.headerName === 'SUPPLIER SHORT NAME') {
      const updateParams = {
        "supplierCode": this.params.data.suppliercode,
        "plantCode": this.params.data.plant,
      }
      sessionStorage.setItem('supplierPart', JSON.stringify(updateParams))
      this.dialog.open(PartListSupplierCodeComponent,)
    }
    else if (this.params.colDef.headerName === 'PART NUMBER') {
      sessionStorage.setItem('ppfcloseFromTracking', '0')
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data.plantcode)
      if (plantCode === 'SERVICE') {
        this.router.navigate(['/part-list/serviceparts-pdf',this.params?.data?.partnumber, this.params.data.ppfcode, this.params.data.ppfver, 1, 2])
      } else if(plantCode === 'ASSY'){
        this.router.navigate(['/part-list/accessoryParts-pdf', this.params?.data?.partnumber, this.params.data.ppfcode, this.params.data.ppfver, 1, 2])
      }
      else {
        window.open(`/part-list/ppf/${this.params.data.ppfcode}/${this.params.data.ppfver}/1/2`, '_blank');
      }
      sessionStorage.setItem('ppfcloseFromTracking', '1')
    }
  }
}
