import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'selectAll',
  template: `
    <div *ngIf="params.column.colId !== 'telephonenumber' && params.column.colId !== 'SUPPLIERCODE'">
      <input
        type="checkbox"
        (click)="checkedHandler($event)"
        [checked]="this.checked === '1'"
      />
  </div>
  `,
})
export class CheckBox implements ICellRendererAngularComp {
  suppliercode: any;
  constructor(protected globalState:GlobalStateService) {}
  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params.data}`
    );
  }

  public params: any;
  protected checked: any = 1;
  protected telephoneNumber = '';
  private modifiedData:any[] = []

  agInit(params: any): void {
    this.params = params;
    this.checked = this.params.value
  }

  mergeArrayById(existingArray:any[],updatedObj:any){
    const index = existingArray.findIndex((existingObj:any) => existingObj.toyotaguid === updatedObj.toyotaguid);
    if(index !== -1){
      existingArray[index] = updatedObj;
    }else{
      existingArray.push(updatedObj);
    }
    return existingArray;
  }

  checkedHandler(event: any) {
    const {
      column: { colId },
    } = this.params;
    this.modifiedData = this.globalState.getAuthorityListUpdateData();
    if (event.target.checked) {
      this.params.data[colId] = 1;
    } else {
      this.params.data[colId] = 0;
    }
    const x = Object.entries(this.params.data).reduce((a:any, [key, value]) => {
        a[key.toUpperCase()] = value;
        a['TYPE'] = 1
      return a;
    }, {});
    this.globalState.setAuthorityListUpdateData(x)
  }
}