import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-btn-cell-render',
  template: `
    <button  [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'" 
    class="vpacs-btn-table"
    style="width:68px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnClickedHandler($event)">DETAIL</button>
  `,
})

export class DetailBtnCellRender implements ICellRendererAngularComp {


  constructor(
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    public dialog: MatDialog
    ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  disableFlag = false;
  dullFlag = false;

  agInit(params: any): void {
    this.params = params;  
    if (params.data.ringitype === "2" || params.data.ringitype === "3" ) {
      
        this.disableFlag = true;
            this.dullFlag = true;

      }
    
    
  }
  btnClickedHandler(_event: any) {
    this.router.navigateByUrl('/ringi-list/ringi-detail?ringiNumber='+ this.params.data.ringinumber)
  }

}




