<p class="page-title mx-2 px-1">Shipment List</p> 



  <div class="alert alert-danger  mt-3" *ngIf="errorList.length > 0">
    <span *ngFor="let message of errorList">{{message}}</span>
  </div>
  <div class="vpacs-shadow m-2 p-2 " *ngIf="haveAccess&&haveAddAccess">
            <div class="filter mx-2  d-flex" (keyup.enter)="Search()">   
                <div class="d-flex align-items-center col-lg-2 col-md-6 col-8  me-3">
                    <label for="family-code" class="col-4">Shipment#</label>
                    <input type="text" id="family-code" [(ngModel)]="Shipment"  style="width: 100%;">
                </div>
                <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-3">
                    <label for="shipper" class="col-4">Shipper</label>
                    <input type="text" id="shipper" [(ngModel)]="shipper" style="width: 100%;">
                </div> 
                <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-3">
                    <label for="receiver" class="col-4">Receiver</label>
                    <input type="text" id="receiver" [(ngModel)]="receiver" style="width: 100%;">
                </div>    
                <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-3">
                    <label for="pickup" class="col-4">Pickup</label>
                    <input type="date" id="pickup"[(ngModel)]="pickup" (keydown)="onKeyDown($event,'pickup')"  style="width: 100%;" >
                </div>            
                <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-3">
                    <label for="delivery" class="col-4">Delivery</label>
                    <input type="date" id="delivery" [(ngModel)]="delivery" (keydown)="onKeyDown($event,'delivery')" style="width: 100%;">
                </div>
                <div class="d-flex  justify-content-end col-lg-1 col-md-6 col-12 mx-1 ">
                  <button class="black-button" (click)="Search()">Search</button>
                </div>
         
 </div>
 <div class="d-flex flex-wrap event-checkbox my-3 mx-2">  
  <div class="d-flex align-items-center gap-3" (keyup.enter)="Search()">
        <label class="checkcontainer mb-0">Draft
          <input type="checkbox" [checked]="(Draft===1)?1:0" [(ngModel)]="Draft" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Wait Receiver
          <input type="checkbox" [checked]="(WaitReceiver===1)?1:0" [(ngModel)]="WaitReceiver" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer  mb-0">Wait LP
          <input type="checkbox" [checked]="( WaitLP===1)?1:0" [(ngModel)]= "WaitLP" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Confirm Shipment
          <input type="checkbox" [checked]="( WaitShipper===1)?1:0" [(ngModel)]="WaitShipper" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer  mb-0">Actual Shipment
          <input type="checkbox" [checked]="(ActualShipment===1)?1:0" [(ngModel)]="ActualShipment" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Actual Receivement
          <input type="checkbox" [checked]="(ActualReceivement===1)?1:0" [(ngModel)]="ActualReceivement" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Complete
          <input type="checkbox" [checked]="(Complete==='')?0:1" [(ngModel)]="Complete" [ngModelOptions]="{ standalone: true }">
          <span class="checkmark"></span>
        </label>
      </div>
</div>

</div>           
<div class="m-2 p-1" *ngIf="haveAccess&&haveAddAccess">
  <div class="grid-agg mt-3 grid-height-shipment-list mb-5">
    <ag-grid-angular
    style="width: 100%;height: 100%"
    class="ag-theme-balham"
    (gridReady)="onGridReady($event)"
    (window:resize)="sizeToFit()"
    [rowHeight]="30" 
    [animateRows]="true"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [rowData]="displayRowData"
    [showOpenedGroup]="true"
    [rowClassRules]="rowClassRules"
    [suppressCellSelection]="true"
    [domLayout]="domLayout" 
    (filterChanged)="onFilterChanged($event)"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [suppressPaginationPanel]="true"
    [suppressAggFuncInHeader]='true'
  
    [icons]="icons"
    [overlayLoadingTemplate]="overlayLoadingTemplate" 
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [floatingFiltersHeight]="40"
  >
  </ag-grid-angular>
  <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
  (changePaginationValues)="changePaginationValues($event)"> </app-pagination>
</div>
