import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GridOptions} from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface';
import { AddMaterialComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/add-material/add-material.component';
import { MaterialCellComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/material-main';
import { MaterialService } from 'src/app/internal-vpacs/services-internal/master-maintenance/material.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})

export class MaterialComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef:any;
  gridOptions !: GridOptions;
  icons: {};
  rowHeight = 40;
  totalPage = 0;
  isAll=false;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  haveAccess : any;
  haveAddAccess : any;
  errorMessage !: ErrorMessage;
  modifyAccess: any;
  deleteAccess: any;
  constructor(
    private readonly dialog: MatDialog,
    private readonly materialService:MaterialService,
    private readonly state:ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router: Router,
    ) {
      this.icons = {
        sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
      }
      this.defaultColDef = {
        suppressMovable: true,
        wrapText: true,
        suppressPaginationPanel: true,
        autoHeight: true,
        sortable: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        unSortIcon: true,
      }
  }
   ngOnInit(): void { 
    this.state.userInfoState.subscribe({
      next: (res:any) =>{
        if(res){
          this.spinnerService.start();
          this.haveAccess = res.role?.p_pkgview;
          this.haveAddAccess = res.role?.p_pkgsave;
          this.modifyAccess = res.role?.p_pkgmodify;
          this.deleteAccess = res.role?.p_pkgdelete;
          this.haveAccess && this.getColumnDefs();
          this.haveAccess && this.apiCall();
          !this.haveAccess && this.spinnerService.stop();
        }
      },
      error : (_err:any) =>{
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
        this.spinnerService.stop();
      }
    });
  }
  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "CODE", 
        field: "materialcode", 
        minWidth: 60,
      },
      {
        headerName: "NAME", 
        field: "material", 
      },
      {
        headerName: "UPDATE", 
        field: "update", 
        minWidth: 60,
        cellRendererFramework: MaterialCellComponent,
        cellRendererParams: {
          clicked: (colId:string,data:any) => {
            this.openDialog(colId,data)
          },
        },
        floatingFilter: false,
        sortable: false
      },
      {
        headerName: "DELETE", 
        field: "delete",
        minWidth: 60,
        cellRendererFramework: MaterialCellComponent,
        cellRendererParams: {
          clicked: (colId:string,data:any) => {
            this.openDialog(colId,data)
          },
        },
        floatingFilter: false,
        sortable: false
      },
   ] 
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  apiCall() {
    this.spinnerService.start();
    this.haveAccess && this.materialService.loadMaterialData().subscribe({
      next: (res:any)=>{
        const result = res.result.map((item:any) => ({...item, modifyAccess : this.modifyAccess, deleteAccess : this.deleteAccess}))
        this.displayRowData = result;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = result.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      },
      error: (_err:any) =>{
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.errorMessage = {errorCode : `API-${_err.status}`,childErrorCode : _err.status}
        this.spinnerService.stop();
      },
    })
  }

  openDialog(pageName:string,data:any) {
    const dialogRef = this.dialog.open(AddMaterialComponent, {data: {pageName: pageName,row:data}});
    dialogRef.afterClosed().subscribe((result:any)=>{
      if(result){
        if(result.data?.result === 'success'){
          this.haveAccess && this.apiCall()
        }
      }
    })
  }


  //pagination changes
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1;
          this.tPage = 1;
          this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall()

    }
  }

  async onLoadAll() {
    this.apiCall();
    this.totalRecords = this.gridApi.getDisplayedRowCount();
  }

  onFilterChanged(_params:any){
    this.cPage =1;
    this.totalRecords = this.gridApi.getDisplayedRowCount(); 
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }
  help(){
    this.router.navigate(['./master-help',5,"Master Maintenance",106])
  }
}

