<div class="popup-container" *ngIf="pageName !== 'delete' " [formGroup]="materialForm">
    <div class="d-flex justify-content-between align-items-center header">
        <div class="page-title">{{pageName}} Material </div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
    <div class="alert alert-danger m-2" *ngIf="errorList.length > 0">
        {{errorList}}
    </div>
    <div class="p-2">
        <div class="col-12 mt-2">
            <label class="col-2">Code<small>*</small></label>
            <input type="text" formControlName="materialCode" />
        </div>
        <div class="col-12 d-flex" *ngIf="materialCode.invalid && (materialCode.dirty || materialCode.touched)">
            <label class="col-2 invisible"></label>
            <small *ngIf="materialForm.get('materialCode')?.hasError('required')">
                Material code is required
            </small>
            <small *ngIf="materialForm.get('materialCode')?.hasError('maxlength')">
                Material code cannot be greater than 3 characters
            </small>
        </div>
        <div class="col-12 my-2">
            <label class="col-2">Name<small>*</small></label>
            <input type="text" formControlName="material" />
        </div>
        <div class="col-12 d-flex" *ngIf="material.invalid && (material.dirty || material.touched)">
            <label class="col-2 invisible"></label>
            <small *ngIf="materialForm.get('material')?.hasError('required')">
                Material name is required
            </small>
            <small *ngIf="materialForm.get('material')?.hasError('maxlength')">
                Material name cannot be greater than 20 characters
            </small>
        </div>
    </div>
    <div class="foot">
        <div mat-dialog-close class="mx-3 text-primary">CANCEL</div>
        <button class="black-button mx-3" (click)="apiCall()">{{pageName}}</button>
    </div>
    <button class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="popup-container" *ngIf="pageName === 'delete' ">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete Confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete {{this.data?.row?.materialcode}}?</p>
    </div>
    <div class="d-flex justify-content-end col-12 align-items-center p-2">
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
        <button class="black-button mx-3" style="height: 30px;" (click)="deleteMaterial()">Yes</button>
    </div>
</div>