import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'define-select-btn-renderer',
  template: `
    <button 
    style="height : '30px'"
    class = "vpacs-btn-table"
    (click)="btnClickedHandler()">Select</button>
  `,
})

export class DefineSelectBtnRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;    
  }
  
  btnClickedHandler() {
    this.router.navigate(['/tracking/define-backup'])
    sessionStorage.setItem('isselectedValue', 'true');    
    this.params.clicked(this.params.data);
  }
}




