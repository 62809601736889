import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'selectAllRendered',
  template: `
    <div 
     *ngIf = "!this.params.displayName">
      <input 
        type="radio" 
        (click)="checkedHandler($event)"
        [checked]="params.value"
        name="radio-content-{{this.params.rowIndex}}"
      />
    </div>
     
    <div *ngIf = "this.params.displayName" class="d-flex align-items-center gap-1">
    <input 
     type="radio"
      (change) = "checkSelectAll($event)"
      [checked]="checkDefault()"
      name="radio-header"
    />
    <span>ALL</span>
  </div>
  `,
})


export class CheckBoxDetailBossRendered implements ICellRendererAngularComp {
  isdefaultSet = false;
  isSelected = false;
  @Output() clicked: EventEmitter<string> = new EventEmitter<string>();


  refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params.data}`);
	}
  public params: any;
  agInit(params: any): void {
    this.params = params;
    if(this.params.data && this.params.data[this.params.column.colId] === undefined) {
      this.params.value = this.setCheckBoxValues();
     this.params.data[this.params.column.colId] = this.params.value ? 1 : 0;
    }
  }

  setCheckBoxValues() {
    switch(this.params?.data?.Decision?.toString()) {
      case "0": return this.params.column.colId === 'approve';
      case "1": return this.params.column.colId === 'reject';
      case "2": return this.params.column.colId === 'reserve';
      default:  return this.params.column.colId === 'reserve';
    }
  }

  checkedHandler(event: any) {
    const colId = this.params.column.colId;
    switch (colId) {
      case 'approve':
        this.params.data.Decision = '0';
        break;
      case 'reject':
        this.params.data.Decision = '1';
        break;
      case 'reserve':
        this.params.data.Decision = '2';
        break;
      default:
        // Handle other cases if needed
        break;
    }
  
    this.params.radioGroup1.forEach((element: any) => {
      this.params.data[element] = 0;
    });
    if (event.target.checked === true) {
      this.params.node.data[colId] = 1;
    } else {
      this.params.node.data[colId] = 0;
    }
    this.btnClickedHandler(this.params.data);
  }

  checkDefault() {
    const colId = this.params.column.colId;
    let count = 0;
    const totalRows = this.params.api.getDisplayedRowCount();
    const countMatchingRows = (node: any) => {
      if (node.data[colId] === 1) {
        count++;
      }
    };
    this.params.api.forEachNode(countMatchingRows);
    return count === totalRows && totalRows > 0 ? true : false;
  }

  checkSelectAll(event:any){
    this.isSelected = true;
    const items:any = [];
    const column:any = this.params.column.colId
    const radioGroup1 = this.params.column.userProvidedColDef.cellRendererParams.radioGroup1;
    this.params.api.forEachNode((node:any) => { 
       radioGroup1.forEach((element: any) => {
         node.data[element] = 0;
       });
      if(event.target.checked === true){
        node.data[column] = 1;
      }
      else{
        node.data[column] = 0; 
      }
      items.push(node.data);
    });
    this.params.api.setRowData(items);
  }
  btnClickedHandler(_event: any) {
    this.params?.clicked(this.params.data, this.params.rowIndex);
  }
}




