<div class="wrapper bg-white">  
    <div class="page-title px-4 py-2">Message</div>
    <div class="mat-typography content my-1 px-3">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">RECEIVE FROM TOYOTA</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="col-lg-3 col-md-6 col-12 my-2">Package Due Date<br/><span class="text-black">{{receiveFromToyota?.pkgDueDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Auth Date<br/><span class="text-black">{{receiveFromToyota?.authDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Supplier Forecast<br/><span class="text-black">{{receiveFromToyota?.supplierForecast | date: 'MM/dd/yyyy'}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Status<br/><span class="text-black">{{receiveFromToyota?.status}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Part Number<br/><span class="text-black">{{receiveFromToyota?.partNumber}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Package Start Date<br/><span class="text-black">{{receiveFromToyota?.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2"> 
                    <label class="col-6">Authorization Message</label>
                    <textarea rows="3" readonly>{{receiveFromToyota?.authorizationMessage}}</textarea>
                </div>
            </div> 
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">ORDER INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Container Code<span class="text-black">{{orderInformation?.containercode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage1 Code<span class="text-black">{{orderInformation?.Dunnage1Code}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage2 Code<span class="text-black">{{orderInformation?.Dunnage2Code}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Top Cap Code<span class="text-black">{{orderInformation?.TopCapCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Container<span class="text-black">{{orderInformation?.QtyContainer}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage1<span class="text-black">{{orderInformation?.QtyDunnage}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage2<span class="text-black">{{orderInformation?.QtyDunnage2}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Top Code<span class="text-black">{{orderInformation?.QtyTopCap}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Pallet Code<span class="text-black">{{orderInformation?.PalletCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Strech Code <span class="text-black">{{orderInformation?.StretchCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Kanban Holder Code<span class="text-black">{{orderInformation?.KanbanHolderCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Other Pkg Code<span class="text-black">{{orderInformation?.OtherPkgCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Pallet<span class="text-black">{{orderInformation?.QtyPallet}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Strech<span class="text-black">{{orderInformation?.QtyStretch}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Kanban Holder<span class="text-black">{{orderInformation?.QtyKanbanHolder}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Other Pkg<span class="text-black">{{orderInformation?.QtyOtherPkg}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">REASON</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class=" col-lg-3 col-md-6 col-12 my-2">Reason<br/><span class="text-black">{{reason?.reason}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2"> 
                    <label class="col-6">Reason Detail</label>
                    <textarea rows="3">{{reason?.reasonDetail}}</textarea>
            </div> 
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="details-pane my-2 d-flex flex-wrap gap-2" >
                <button class="vpacs-btn-table" (click)="ppf()">PPF</button>
                <button class="vpacs-btn-table" *ngIf="authDownloadFlag" (click)="authDownload()">AUTH DOWNLOAD</button>
                <button *ngIf="dhFlag" class="vpacs-btn-table" >DELIVERY TARGET</button>    
                <button *ngIf="dhFlag" [disabled]="!serialFlag" class="vpacs-btn-table">SERIAL</button>
            </div>
            <div class="d-grid col-lg-2 col-md-6 col-12 my-2" *ngIf="!btnDisabled">Readiness Date
                <input type="date" [(ngModel)]="readinessDate">
            </div>
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex">
    <div class="mx-3 cancel" (click)="onCancel()">Cancel</div>
    <button class="black-button mx-3" [disabled]="btnDisabled" *ngIf="okFlag" (click)="submit()">Ok</button>
    <button class="black-button mx-3" [disabled]="btnDisabled" (click)="cancelOk()" *ngIf="cancelOKFlag">Cancel NG</button>
    <button class="black-button mx-3" [disabled]="btnDisabled" (click)="cancelNg()" *ngIf="cancelNGFlag">Cancel OK</button>
</div>  
</div>