import { Component, OnInit, Inject } from '@angular/core'
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  spCode: any
  header: any
  backupArray: any[] = []
  errorMessage: any
  errorList = ''
  text: any
  err = '099  - 001 :Runtime Error : Contact a system administrator, please. Uncaught Error, DB Error '
  confPsd = 'CONFIRM PSD'

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly authListService: AuthListService,
    private readonly trackListService: TrackingListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly modellistService: ModelListService,
    private readonly state: ExportStateService,
    public message: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.backupArray = res.role?.backupDetails?.map((a: any) => a.specialistcode);
      },
      error: (_err: any) => {
        this.errorList = 'Contact System Administrator'
      },
    })
    if (this.dataStore.action === 'CANCEL') {
      this.header = 'Cancel AuthList';
      this.text = `Are you sure that you want to cancel [${this.dataStore.authNum}]?`;
    } else if (this.dataStore.action === 'DELETE') {
      this.header = 'Delete AuthList'
      this.text = `Are you sure that you want to delete [${this.dataStore.authNum}]?`;
    } else if (this.dataStore.action === 'DELETE MODELLIST') {
      this.header = 'Delete Modellist'
      this.text = `Are you sure that you want to delete`;
    } else if (this.dataStore.action === 'DELETE TRACKING LIST') {
      this.header = 'Delete Tracking list'
      this.text = `Are you sure to delete this part [${this.dataStore?.data.partnumber}]?`;
    } else if (this.dataStore.action === this.confPsd) {
      this.header = this.confPsd
      this.text = this.dataStore.data;
    }
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent', data: 'close' })
  }

  ok() {
    this.spinnerService.start()
    this.errorList = '';
    this.errorMessage = '';
    if (this.dataStore.action === 'DELETE MODELLIST') {
      this.header = 'Delete Modellist'
      this.deleteModellist()
    } else if (this.dataStore.action === 'DELETE TRACKING LIST') {
      const obj: any = {
        PlantCode: this.dataStore?.data.plantcode,
        ModelCode: this.dataStore?.data.modelcode,
        SupplierCode: this.dataStore?.data.suppliercode,
        PartNumber: this.dataStore?.data.partnumber,
        PkgStartDate: this.dataStore?.data.pkgstartdate,
      }
      this.deleteTrackingList(obj)
    } else if (this.dataStore.action === this.confPsd) {
      this.confirmPsd()
    }else {
      const obj: any = {
        SpecialistCode: this.spCode,
        AuthNumber: this.dataStore.authNum,
        BackUp: this.backupArray ? this.backupArray : [''],
      }
      if (this.dataStore.action === 'CANCEL') {
        this.cancelAuthList(obj)
      } else if (this.dataStore.action === 'DELETE') {
        this.deleteAuthList(obj)
      }
    }
  }

  confirmPsd() {
     this.dialogRef.close({ event: 'Confirmed'  })
     this.spinnerService.stop();
  }

  deleteModellist() {
    const deleteObj = {}

    this.modellistService.deleteModellist(deleteObj).subscribe({
      next: (_res: any) => {
        this.dialogRef.close({ event: 'CloseEvent' })
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop();
      },
    })
  }

  cancelAuthList(cancelObj: any) {
    this.authListService.cancelAuthList(cancelObj).subscribe({
      next: (res: any) => {
        if (res.command === 'UPDATE') {
          this.openMessageBox('Cancelled Successfully','success')
          this.dialogRef.close({ event: 'CloseEvent' })
        }
        else if(res.status === false){
          this.errorMessage = {
            errorCode:
              res?.message !== ''
                ? res?.message
                : this.err,
            childErrorCode: '',
          }
        }
      },
      error: (err: any) => {
        this.spinnerService.stop()
        this.errorMessage = {
          errorCode:
            err.error?.error !== ''
              ? err.error?.error
              : this.err,
          childErrorCode: '',
        }
      },
      complete: () => {
        this.spinnerService.stop();
      }
    })
  }

  deleteAuthList(deleteObj: any) {
    this.authListService.deleteAuthList(deleteObj).subscribe({
      next: (res: any) => {
        if (res.status === true) {
          this.openMessageBox('Deleted Successfully','success')
          this.dialogRef.close({ event: 'CloseEvent' })
        } else if (res.status === false) {
          this.errorList = res.message
          setTimeout(() => {
            this.errorList=''          
          }, 4000)
        }
      },
      error: (err: any) => {
        this.spinnerService.stop()
        this.errorMessage = {
          errorCode:
            err.error?.error !== ''
              ? err.error?.error
              : this.err,
          childErrorCode: '',
        }
      },
      complete: () => {
        this.spinnerService.stop();
      },
    })
  }

  deleteTrackingList(deleteObj: any) {
    this.trackListService.deleteTrackingList(deleteObj).subscribe({
      next: (_res: any) => {
        this.openMessageBox('Deleted Successfully','success')
        this.dialogRef.close({ event: 'CloseEvent' })
      },
      error: (err: any) => {
        this.spinnerService.stop()
        this.errorList = err.error?.error?.message;
        setTimeout(() => {
          this.errorList=''          
        }, 4000)
      },
      complete: () => {
        this.spinnerService.stop()
      }
    })
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }
}
