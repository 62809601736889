<div class="popup-container">
    <header>
        <label class="close black-button" (click)="close()">X</label>
        <div class="header d-block">
            <label class="ms-2 mt-2 mb-3">Update Supplier</label>
        </div>
    </header>
    <div class="popup-contents" [formGroup]="updateSupplierForm">
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label for="ringi"  class="catlabel col-5" >NA Supplier Code* </label>
                <input type="text" id="ringi" (keyup.enter)="onSearch()" formControlName="ringi">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="type"  >TMMC Supplier code</label>
                <input type="text" id="type" (keyup.enter)="onSearch()" formControlName="type">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="plant">NUMMI Supplier Code</label>
                <input type="text" id="type" (keyup.enter)="onSearch()" formControlName="type">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >Name*</label>
                <input type="text" id="startDate" (keyup.enter)="onSearch()" formControlName="startDate">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >Short Name*</label>
                <input type="text" id="endDate"  (keyup.enter)="onSearch()" formControlName="endDate">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="ringiCost"  >Address</label>
                <input type="text" id="ringiCost" (keyup.enter)="onSearch()" formControlName="ringiCost">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >City</label>
                <input type="text" id="startDate" (keyup.enter)="onSearch()" formControlName="startDate">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="endDate"  >State</label>
                <input type="text" id="endDate"  (keyup.enter)="onSearch()" formControlName="endDate">

            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="ringiCost"  >Zip Code</label>
                <input type="text" id="ringiCost" (keyup.enter)="onSearch()" formControlName="ringiCost">
            </div>
        </div>
        <div class="row mx-3 mb-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="startDate"  >Country</label>
                <input type="text" id="startDate" (keyup.enter)="onSearch()" formControlName="startDate">
            </div>
            <div class="col-4 d-flex">
               

            </div>
            <div class="col-4 d-flex">
                
            </div>
        </div>
        


        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button class="cancel me-3 roboto-bold" (click)="close()"> CANCEL </button>
            <button class="submit black-button me-3 roboto-bold" (click)="updateSupplierOnload()">UPDATE</button>
        </div>
    </div>
</div>
