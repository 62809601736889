<div class="d-flex justify-content-between align-items-center m-2">
  <p class="page-title">Package Fabricator Maintenance</p> 
  <div>
    <button class="help-btn"(click)="help()">Help</button>
  </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
  <div *ngFor="let message of errorList" class="errormsg">
    <div class="message-container">
      <span class="message-text">{{ message }}</span>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
  </div>
</div>
<div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
  <span>{{ successMessage }}</span>
  <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="alert alert-warning mx-2" *ngIf="viewAccess === false">
  Authority Error - Access denied
</div>
<div *ngIf="viewAccess" class="vpacs-shadow m-2 py-2">
  <div
    class="filter d-flex align-items-center col-12 flex-wrap event-checkbox mb-2"
  >
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-0">
      <label for="code" class="col-4">Code</label>
      <input
        type="text"
        id="code"
        style="width: 100%"
        [(ngModel)]="PackageSupplierCode"
        (keyup.enter)="onSearch()"
      />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-0">
      <label for="alpha-code" class="col-6">Alpha Code</label>
      <input
        type="text"
        id="alpha-code"
        style="width: 100%"
        [(ngModel)]="alphaCode"
        (keyup.enter)="onSearch()"
      />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-0">
      <label for="name" class="col-4">Name</label>
      <input
        type="text"
        id="name"
        style="width: 100%"
        [(ngModel)]="Name"
        (keyup.enter)="onSearch()"
      />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-0">
      <label for="contact" class="col-4">Contact</label>
      <input
        type="text"
        id="contact"
        style="width: 100%"
        [(ngModel)]="digits"
        (keyup.enter)="onSearch()"
      />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 mx-0">
      <div class="d-flex justify-content align-items-center col-lg-4 mx-4">
        <button class="black-button" (click)="onSearch()">Search</button>
      </div>
      <div class="d-flex justify-content align-items-center col-lg-4 mx-0">
        <button class="vpacs-btn-table" [disabled]="!editAccess" (click)="addPackgeFab()">ADD</button>
      </div>
    </div>
  </div>

  <div class="filter d-flex align-items-center col-12 flex-wrap event-checkbox" (keyup.enter)="onSearch()">
    <label for="commodity" class="me-2">Commodity</label>
    <label class="checkcontainer mb-0">
    <input
        type="checkbox"
        name="allNonTrades"
        [(ngModel)]="nontrade"
        (change)="allNonTrades($event)"
      />ALL <span class="checkmark"></span> </label
    >&nbsp;
    <ng-container *ngFor="let plant of plants">
      <label class="mb-0" style="padding-left: 0%;">
        <input
          type="checkbox"
          name="{{ plant.value }}"
          [disabled]="plant.disable"
           [(ngModel)]="plant.selected"
          (change)="changeTradesByCategory($event)"
          class="disabled-checkbox"
        />{{ plant.value }} <span class="checkmark"></span> </label
      >&nbsp;
    </ng-container>
  </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
  <div class="grid-agg mt-3 grid-height-package-fabricator mb-5">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham"
      (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [rowData]="displayRowData"
      [suppressCellSelection]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [suppressPaginationPanel]="true"
      [icons]="icons"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40"
      (filterChanged) = "onFilterChanged($event)"
    >
    </ag-grid-angular>
    <app-pagination
      [cPage]="cPage"
      [tPage]="tPage"
      [rpPage]="rpPage"
      [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)"
    >
    </app-pagination>
  </div>
</div>

<router-outlet></router-outlet>
