import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogDivisionComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';
import { DivisionMasterBtnCellRenderComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/division-master';
import { DivisionMasterService } from 'src/app/internal-vpacs/services-internal/master-maintenance/division-master.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-division-master',
  templateUrl: './division-master.component.html',
  styleUrls: ['./division-master.component.scss'],
})
export class DivisionMasterComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  icons: {};
  defaultColDef: {};
  gridOptions: any;
  rowClassRules: any;
  isAll=false;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  response: any = [];
  rowCount = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  errorList: string[] = [];
  userInfo: any;
  haveAccess = true;
  addflag: any = false;
  deleteflag: any = false;
  updateflag: any = false;
  successMessage: string[] = [];
  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    public route: ActivatedRoute,
    public divService: DivisionMasterService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res;
        this.haveAccess = res.role?.p_userview;
        this.haveAccess && this.setColumnDefs();
        this.haveAccess && this.onLoad();
        !this.haveAccess && this.spinnerService.stop();
        this.haveAccess = res.role?.p_userview;
        if (res && !res.role?.p_usersave) {
          this.addflag = true;
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.errorList.push(_err.message);
        this.spinnerService.stop();
      },
    });
  }
  //maxwidth was occuping more spaces
  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'DIVISION CODE',
        field: 'divcode',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'UPDATE',
        field: 'update',
        minWidth: 150,
        cellRendererFramework: DivisionMasterBtnCellRenderComponent,
        cellStyle: { cursor: 'pointer' },
        cellRendererParams: {
          clicked: (colId: string, data: any) => {
            this.close();
            this.status(colId, data);
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        minWidth: 150,
        cellRendererFramework: DivisionMasterBtnCellRenderComponent,
        cellStyle: { cursor: 'pointer' },
        cellRendererParams: {
          clicked: (colId: string, data: any) => {
            this.close();
            this.status(colId, data);
          },
        },
      },
    ];
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.divService?.getDivGridFilter()) {
      const retrivedValue = this.divService.getDivGridFilter();
      this.gridApi?.setDivGridFilter(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  //pagination changes
 
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onLoad();
    
    }
  }

  async onLoadAll() {
    this.onLoad();
    this.totalRecords = this.gridApi?.getDisplayedRowCount();
    
  }
  
  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.divService.setDivGridFilter(allFilterModels);
  }
  add() {
    this.close();
    const dialogRef = this.dialog.open(DialogDivisionComponent, {
      data: {
        headerName: 'Add',
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data === false) {
        this.errorList = ['Unable to Add '];
      } else if (data === true) {
        this.onLoad();
        this.successMessage = ['Data Updated'];
      } else {
        this.close();
      }
    });
  }
  onLoad() {
    const obj = {
      sort: ['divcode asc'],
    };
    this.divService.onloadDivision(obj)?.subscribe({
      next: (res: any) => {
        if (res) {
          const result = res.result.data.map((item: any) => ({
            ...item,
            updateflag: this.userInfo?.role.p_usersave,
            deleteflag: this.userInfo?.role.p_userdelete,
          }));
          this.response = result;
          this.displayRowData = this.response;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = this.response.length;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
        } else {
          this.spinnerService.stop();
          this.errorList = ['No Data Found'];
        }
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.errorList = ['No Data Found'];
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  status(pageName: any, data: any) {
    if (pageName === 'UPDATE' || pageName === 'DELETE') {
      const successMessage =
        pageName === 'UPDATE' ? 'Data Updated' : 'Data Deleted';
      const errorMessage = `Unable to ${pageName.toLowerCase()}`;
      if (data === true) {
        this.onLoad();
        this.successMessage = [successMessage];
      } else if (data === false) {
        this.errorList = [errorMessage];
      } else {
        this.close();
      }
    }
  }

  close() {
    this.errorList = [];
    this.successMessage = [];
  }
  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 157]);
  }
}
