<div class="popup-container">
    <div class="header text-middle">Import Package </div>
    <button class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="content p-3">
        <div *ngFor="let tableItr of trdata">
            <div class="alignment">
                <div class="headeralign">Package Code : {{tableItr[0]?.pkgCode}}</div>
                <table class="table table-bordered" aria-describedby="reason">
                    <thead>
                        <tr class="titleColumn">
                            <th scope="col"> </th>
                            <th scope="col">Current master data of Package</th>
                            <th scope="col">New data of Package</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="contentColumn" *ngFor="let data of tableItr">
                            <td> {{data?.rowName}}</td>
                            <td> {{data?.currentData}}</td>
                            <td>{{data?.newData}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class='container'>
            <div>Change Package Data</div>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group "
                [(ngModel)]="radioVal">
                <mat-radio-button class="example-radio-button me-3" [value]='0'>Overwrite</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]='1'>Not Overwrite</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="footer-dialog d-flex px-3" style="height: 50px">
        <div mat-dialog-close class="mx-3 cancel">Cancel</div>
        <button class="black-button mx-3" (click)="CallApi()">NEXT</button>
    </div>
</div>