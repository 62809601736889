import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'app-un-authorized',
  templateUrl: './un-authorized.component.html',
})
export class UnAuthorizedComponent implements OnInit{
  maxAttempts = 3;
  storageKey = 'reloadAttemptCounter';

  constructor(private readonly state: ExportStateService, private readonly router:Router,public constants:ConstantsService) {}

  ngOnInit(){
    let attemptCount = parseInt(sessionStorage.getItem(this.storageKey) || '0', 10);
    if (attemptCount < this.maxAttempts) {
      attemptCount++;
      sessionStorage.setItem(this.storageKey, attemptCount.toString());
      if (attemptCount < this.maxAttempts) {
        window.location.reload();
      }
    }

    this.state.userInfoState.subscribe((data:any)=>{
      if(data){
        this.state.roleState.subscribe((data:any) => {
          if (data) {
            const access = this.state.checkAppType(data);
            if(access === 'Internal'){
              this.router.navigate(['/internal']);
            }else{
              //check the user has admin access
              const adminAccess = this.state.checkExternalAccess(data[0]);
              if(adminAccess){
                this.router.navigate(['/admin-access']);
              }else{
                this.router.navigate(['/tracking']);
              }
            }
          }
        })
      }
    })
  }
}
