import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { PartListCheckBoxDetailRendered } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/part-list/partListCheckBoxDetailRendered';
import { PartListBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/part-list-btn-cell-render';
import { PartListHyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/part-list-hyperLink';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.scss'],
})
export class PartListComponent implements OnInit {
  isAll = false;
  errorMessage:any;
  rowCount = 0;
  headerHeight = 50;
  originalPage: any;
  response1: any;
  apiFromSearch = false;
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  paginationPageSize = 10;
  familyCode = '';
  modelCode = '';
  partOver = '';
  partUnder = '';
  pkgCode = '';
  fabricator = '';
  partNumber = '';
  supplier = '';
  partDescription = '';
  packageFromDate :any ;
  pkgToDate :any;
  totalOver = '';
  totalUnder = '';
  specialistCode = '';
  spCode: any;
  selectedPlant: any[] = [];
  filteredOptions: any[] = [];
  haveAccess: any;
  plant: any;
  activeChangesArray:any=[]
  headerClassNoBorder = 'child-header-color no-border'
  radioGroup = [
    'Is Active',
  ];

  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  // errorMsg !: ErrorMessage;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  rowClassRules: any;
  resPlant: any[] = [];
  res: any;
  cPage = 1;
  tPage = 0;
  rpPage:any;
  totalRecords = 0;
  gridColumnApi: any;
  temp: any;
  part1 = '';
  part2 = '';
  unit: any = '';
  supType: any = '';
  userInfo: any;
  filterdata: any;
  showActive=true;

  constructor(
    private readonly PartListService: PartListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly state: ExportStateService,
    public datepipe: DatePipe,
    private readonly constants: ConstantsService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.rpPage=50;
    sessionStorage.removeItem('backUp');
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        const plantdata = this.PartListService.getplant();
         this.spinnerService.start();
        if (res) {
          this.userInfo = res.info;
          this.unit = res.info?.unit;
          this.supType = res.role.supType;
          this.haveAccess = res.role?.p_trackingview;
          this.haveAccess && this.getcolumndef();
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
            this.spCode = res.info.specialistcode;
          }
        }
        if (plantdata) {
          this.filterdata = this.PartListService.getpartFilters();
          if(this.filterdata){
          this.familyCode = this.filterdata?.FamilyCode;
          this.selectedPlant = plantdata;
          this.plant=(this.filterdata?.PlantCodes.length>1)?'(All)':this.filterdata?.PlantCodes[0];
          this.supplier = this.filterdata?.SupCode;
          this.packageFromDate=this.filterdata.PkgStartDateFrom === "null" ?  undefined : this.datepipe.transform(this.filterdata.PkgStartDateFrom, "YYYY-MM-dd");
          this.partOver = this.filterdata?.PartOver;
          this.partUnder = this.filterdata?.PartUnder;
          this.modelCode = this.filterdata?.ModelCode;
          this.partNumber =
            (this.filterdata?.Part1 ?? '') + (this.filterdata?.Part2 ?? '');
          this.partDescription = this.filterdata?.Description;
          this.pkgToDate=this.filterdata.PkgStartDateTo === "null" ?  undefined : this.datepipe.transform(this.filterdata.PkgStartDateTo, "YYYY-MM-dd");
          this.totalOver = this.filterdata?.TWOver;
          this.totalUnder = this.filterdata?.TWUnder;
          this.fabricator = this.filterdata?.FabCode;
          this.specialistCode = this.filterdata?.Specialist;
          this.pkgCode = this.filterdata?.Container;
        }
      }
        this.haveAccess && this.plantLoad();
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
  }

  getcolumndef() {
    this.columnDefs = [
      {
        headerName: 'FAMILY CODE',
        field: 'familycode',
        pinned: 'left',
        minWidth: 90,
      },
      {
        headerName: 'MODEL CODE',
        field: 'modelcode',
        pinned: 'left',
        minWidth: 90,
        maxWidth: 100,
      },
      {
        headerName: 'SUPPLIER CODE',
        field: 'supplierCode',
        minWidth: 120,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        pinned: 'left',
      },
      {
        headerName: 'SUPPLIER SHORT NAME',
        field: 'supplier',
        minWidth: 120,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        pinned: 'left',
      },
      {
        headerName: 'FABRICATOR',
        field: 'fabcode',
        minWidth: 120,
        pinned: 'left',
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'PART NUMBER',
        field: 'partnumber',
        minWidth: 90,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        pinned: 'left',
      },
      {
        headerName: 'PART DESCRIPTION',
        field: 'partdescription',
        minWidth: 300,
        valueFormatter: this.fabricatorFormat,

      },
      {
        headerName: 'PACKAGE START DATE',
        field: 'pkgstartdate',
        minWidth: 120,
        comparator: (valueA:any, valueB:any, _nodeA:any, _nodeB:any, _isInverted:any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: 'TOTAL WEIGHT',
        field: 'totalweight',
        minWidth: 120,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'PARTM3',
        field: 'partm3',
        minWidth: 140,
        valueFormatter: this.fabricatorFormat,

      },
      {
        headerName: 'QPC',
        field: 'qtybox',
        minWidth: 140,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'QPU',
        field: 'qpu',
        minWidth: 90,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'CONTAINER',
        field: 'container',
        minWidth: 100,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'DUNNAGE 1',
        field: 'dunnage1',
        minWidth: 100,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'DUNNAGE 2',
        field: 'dunnage2',
        minWidth: 140,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'HAZMAT',
        field: 'hazmat',
        minWidth: 140,
      },
      {
        headerName: 'SPECIALIST',
        field: 'specialist',
        minWidth: 100,
      },
      {
        headerName: 'PLANT',
        field: 'plant',
        minWidth: 140,
      },
      {
        headerName: 'PART ROUTING',
        field: 'routing',
        minWidth: 130,
        valueFormatter: this.fabricatorFormat,
      },
      {
        headerName: 'ECI#',
        field: 'ecino',
        minWidth: 140,
        valueFormatter: this.fabricatorFormat,
      },
      { 
        headerName: 'Is Active', field: 'isActive', floatingFilterComponentParams: { suppressFilterButton: false },
        floatingFilter: false, sortable: false, unSortIcon: false, minWidth: 80,
        hide: this.showActive,
        cellRendererFramework: PartListCheckBoxDetailRendered,
        cellRendererParams: {
          radioGroup: this.radioGroup,
          showActive: this.showActive,
          clicked: (data: any) => {
            this.updateDecision(data);
          },
        },
      },
      {
        headerName: 'BACK-UP',
        field: 'temp',
        floatingFilterComponentParams: { suppressFilterButton: false },
        floatingFilter: false,
        minWidth: 90,
        cellRendererFramework: PartListBtnCellRender,
        cellStyle: { cursor: 'pointer' },
      },
      {
        headerName: 'UPDATE PDF',
        field: 'ppf',
        floatingFilterComponentParams: { suppressFilterButton: false },
        floatingFilter: false,
        minWidth: 90,
        cellRendererFramework: PartListBtnCellRender,
        cellStyle: { cursor: 'pointer' },
      },
      
    ];
  }

  updateDecision(_data: any) {
    if(this.activeChangesArray.includes(_data)){
      const i=this.activeChangesArray.indexOf(_data);
      this.activeChangesArray.splice(i,1)
    }
    else{
      this.activeChangesArray.push(_data)
    }
  }


  fabricatorFormat(params: any) {
    return params.value ? params.value : '-';
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll && (this.plant || this.supplier ||this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.pkgCode || this.pkgToDate || this.packageFromDate || this.partDescription)) {
      this.displayRowData = [];
      for (let i = 0; i < Math.ceil(this.totalRecords / 40000); i++) {
        this.cPage = i + 1; 
        this.tPage = 1;
        this.rpPage = 40000;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }else  if(this.plant==='(All)' &&!(this.supplier ||this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.pkgCode || this.pkgToDate || this.packageFromDate || this.partDescription))
    {
      this.spinnerService.stop();
     this.errorMessage='For `ALL` plant , Please select another field as well to search'
     return
    } else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onload();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.onload();
      if (this.apiFromSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiFromSearch = false;
      }
      resolve();
    });
  }

  filterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.PartListService.setfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response1?.pagination?.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    if (this.PartListService.getfilter()) {
      const retrivedValue = this.PartListService.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  onload() {
    const plantCode = this.constants.getTypeFromPlantCode(this.plant)
    if(plantCode === 'SERVICE' || plantCode === 'ASSY')
    {
      this.showActive=false;
      this.getcolumndef()
    }
    else{
      this.showActive=true;
      this.getcolumndef()
    }
    this.errorMessage=''
    let updatedPart = this.partNumber;
    if(this.partNumber.includes('-') ){
      updatedPart = (updatedPart + "").replace('-', '');
    }else if(this.partNumber.includes(' ') ){
      updatedPart = (updatedPart + "").replace(' ', '');
    }
    if(this.partNumber.length >0 && this.partNumber.length <=10 && !this.partNumber.includes('*')){
      updatedPart += '*'
    }
    this.part1 = updatedPart.slice(0, 5);
    this.part2 = updatedPart.slice(5, 11);
    const date = 'MM/dd/yyyy';
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase()) ))
    this.plant = plant[0]?.plantcode
    const updateObject = {
      FamilyCode: this.familyCode,
      sort: 'null',
      userUnit: this.unit,
      userSupplierType: this.supType,
      SupCode: this.supplier,
      ModelCode: this.modelCode,
      Part1: this.part1,
      Part2: this.part2,
      FabCode: this.fabricator,
      Description: this.partDescription,
      PkgStartDateFrom: this.packageFromDate
        ? this.datepipe.transform(this.packageFromDate, date)
        : 'null',
      PkgStartDateTo: this.pkgToDate
        ? this.datepipe.transform(this.pkgToDate, date)
        : 'null',
      Container: this.pkgCode,
      Specialist: this.specialistCode,
      TWOver: this.totalOver,
      TWUnder: this.totalUnder,
      PartOver: this.partOver,
      PartUnder: this.partUnder,
      PlantCodes: this.plant === '(All)' ? this.resPlant : [this.plant],
      limit: this.rpPage,
      page: this.cPage,
    };
    this.PartListService.setpartFilters(updateObject);
    this.PartListService.onLoad(updateObject).subscribe({
      next: (res: any) => {
        this.response1 = res;
        this.spinnerService.stop();
        const result = res.data.map((item: any) => ({
          ...item,
          unit: this.unit,
        }));
        this.displayRowData = result;
        this.rowCount = this.response1.length;
        this.totalRecords = res.pagination.TotalRecords;
        if(this.isAll){
          this.rpPage=this.totalRecords
        }
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }  
        this.apiFromSearch = false;
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      
    },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.spinnerService.stop();
      },
    });
  }
  onSearch() {
    this.apiFromSearch = true;
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if (!plantCheck) {
      this.errorMessage = ["Please select valid plant name"];
    }
    else {
      if (this.plant === '(All)' && !(this.supplier || this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.pkgCode || this.pkgToDate || this.packageFromDate || this.partDescription)) {
        this.displayRowData = [];
        this.spinnerService.stop();
        this.errorMessage = 'For `ALL` plant , Please select another field as well to search'
        return
      }
      this.spinnerService.start();
      if (this.apiFromSearch) {
        this.cPage = 1;
        this.rpPage = 50;
        this.totalRecords = 0;
        this.isAll = false;
      }
      this.activeChangesArray = []
      const plantCode = this.constants.getTypeFromPlantCode(this.plant)
      if (plantCode === 'SERVICE' || plantCode === 'ASSY') {
        this.showActive = false;
        this.getcolumndef()
      }
      else {
        this.showActive = true;
        this.getcolumndef()
      }
      this.onload();
    }
  }

  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specialistCode,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistCode = result;
    });
  }

  plantLoad() {
    this.spCode = this.userInfo.specialistcode;
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };

    this.PartListService.plant(obj).subscribe({
      next: (res: any) => {
        const response = res.sort((a: any, b: any) =>
          a.name?.localeCompare(b.name)
        );
        this.resPlant = response.map(
          (item: { plantcode: any }) => item.plantcode
        );
        this.selectedPlant = [
          { plant: 'ALL', plantcode: '(All)' },
          ...response,
        ];
        this.plant = this.plant ?? this.userInfo?.plantcode;
        this.filteredOptions = this.selectedPlant
        this.displayFn(this.selectedPlant);
        this.PartListService.setplant(this.selectedPlant);
        //<----As per new requirement we need to restrict the auto search feature
        // this.onload();
        if(this.filterdata){
          this.onload();
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }


  filterOptions() {
    this.filteredOptions = this.selectedPlant.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }
 
  help() {
    this.router.navigate(['./master-help', 2, 'Tracking', 13]);
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'packageFromDate') {
        this.packageFromDate = '';
      }
      if (id === 'pkgToDate') {
        this.pkgToDate = '';
      }
    } 
  }

}
