import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-discrepancy-temp-container',
  templateUrl: './discrepancy-temp-container.component.html',
  styleUrls: ['./discrepancy-temp-container.component.scss'],
})
export class DiscrepancyTempContainerComponent implements OnInit {
  spCode: any;
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  fplant: any = '';
  ffamily: any;
  fmodel: any;
  fpart: any;
  fsupplier: any;
  pounds: any;
  height: any;
  width: any;
  length: any;
  mmwidth: any = 0;
  mmlength: any = 0;
  mmheight: any = 0;
  kg: any = 0;
  freason: any = '';
  fradioVal: any = '0';
  tableForm: any;
  qtyParts: any;
  cost: any;
  plantcode: any;
  suppliercode: any;
  specialist: any;
  partnumber: any;
  fromdate: any;
  update!: boolean;
  add!: boolean;
  fcost: any;
  fqpc: any;
  fpay: any;
  private file:File|null=null;
  base64String:any;
  tableData: any[]=[];
  rowNum:any=1;
  backup: any;

  constructor(
    private readonly DiscrepancyListService: DiscrepancyListService,
    public formbuilder: FormBuilder,
    private readonly spinnerService: NgxUiLoaderService,
    public router: Router,private readonly state: ExportStateService
  ) {}

  ngOnInit(): void {
    this.plantcode = localStorage.getItem('plantcode');
    this.suppliercode = localStorage.getItem('suppliercode');
    this.specialist = localStorage.getItem('specialist');
    this.partnumber = localStorage.getItem('partnumber');
    this.fromdate = localStorage.getItem('fromdate');
    this.fromdate = this.fromdate?.substring(0, 10);
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.backup=res.role.backupDetails
      },
      error: (_err: any) => { 
      },
    });
    this.plant();
  }

  kgConversion() {
    this.kg = this.pounds * 0.45359237;
    this.kg = parseFloat(this.kg.toFixed(2));
  }
  lengthConv() {
    this.mmlength = (2 * (this.length * 25.4)) / 2;
    this.mmlength = parseFloat(this.mmlength.toFixed(2));
    this.mmlength = Math.round(this.mmlength);
  }
  widthConv() {
    this.mmwidth = this.width * 25.4;
    this.mmwidth = parseFloat(this.mmwidth.toFixed(2));
    this.mmwidth = Math.round(this.mmwidth);
  }
  heightConv() {
    this.mmheight = this.height * 25.4;
    this.mmheight = parseFloat(this.mmheight.toFixed(2));
    this.mmheight = Math.round(this.mmheight);
  }

  async onFileSelected(event: any){
    this.file= event.target.files[0];
    if (this.file) {
      try {
      this.base64String =await this.convertFileToBase64(this.file);
      } catch (error) {
      }
      }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        this.base64String = reader.result as string;
        const base64Data = this.base64String.split(',')[1];
        resolve(base64Data);
      };
  
      reader.onerror = () => {
        reject('Error reading the file');
  };
  
  reader.readAsDataURL(file);
  });
  }


  updateApi(){
      if (
        !this.length ||
        !this.width ||
        !this.height ||
        !this.pounds ||
        !this.qtyParts ||
        !this.cost ||
        !this.fradioVal ||
        !this.freason
      ) {
        alert('Please fill in all the required fields');
      } else {
        this.spinnerService.start();
        const updateObject = {
          UserSpecialistCode: this.spCode,
          PlantCode: this.plantcode,
          SupplierCode: this.suppliercode,
          PartNumber: this.partnumber,
          PkgStartDate: this.fromdate,
          Length: this.length,
          Width: this.width,
          Height: this.height,
          Weight: this.pounds,
          QPC: this.qtyParts,
          Payment: this.fradioVal,
          Cost: this.cost,
          Reason: this.freason,
          FilesList: [
            {
              FileName: this.file?.name,
              FileData: this.base64String
            },
          ],
        };
  
        this.DiscrepancyListService.updateRegPart(updateObject).subscribe({
          next: (_res: any) => {
            this.cancel();
            this.spinnerService.stop();
          },
          error: (_err: any) => {
            this.spinnerService.stop();
          },
        });
      }
  }

  addrow(){
    const newRow={
      rowNum:this.rowNum
    }
    this.tableData.push(newRow);
    this.rowNum++;
  }

  onSubmit() {
    if (
      !this.length ||
      !this.width ||
      !this.height ||
      !this.pounds ||
      !this.qtyParts ||
      !this.cost ||
      !this.fradioVal ||
      !this.freason
    ) {
      alert('Please fill in all the required fields');
    } else {
      this.spinnerService.start();
      const updateObject = {
        UserSpecialistCode: this.spCode,
        PlantCode: this.plantcode,
        SupplierCode: this.suppliercode,
        PartNumber: this.partnumber,
        PkgStartDate: this.fromdate,
        Length: this.length,
        Width: this.width,
        Height: this.height,
        Weight: this.pounds,
        QPC: this.qtyParts,
        Payment: this.fradioVal,
        Cost: this.cost,
        Reason: this.freason,
        FilesList: [
          {
            FileName: this.file?.name,
            FileData: this.base64String
          },
        ],
      };

      this.DiscrepancyListService.addRegPart(updateObject).subscribe({
        next: (_res: any) => {
          this.cancel();
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
      });
    }
  }

  onLoad() {
    this.spinnerService.start();
    const updateObject = {
      PlantCode: this.plantcode,
      FamilyCode: '',
      ModelCode: '',
      SupplierCode: this.suppliercode,
      UserSpecialistCode: this.specialist,
      PartNumber: this.partnumber,
      PkgStartDate: this.fromdate,
      BackUp: this.backup,
    };

    this.DiscrepancyListService.onloadRegPartContainer(updateObject).subscribe({
      next: (res: any) => {
        this.fplant = res.PartInfo?.Plant;
        this.ffamily = res.PartInfo?.Family;
        this.fmodel = res.PartInfo?.Model;
        this.fpart = res.PartInfo?.PartNumber;
        this.fsupplier = res.PartInfo?.Supplier;
        this.freason = res?.ContainerSpecification[0]?.reason;
        this.update = res?.btnUpdate_Visible;
        this.add = res?.btnAdd_Visible;
        this.fcost = res.ContainerSpecification[0].cost;
        this.fqpc = res.ContainerSpecification[0].qpc;
        this.fpay = res.ContainerSpecification[0].payment;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  cancel() {
    this.router.navigate(['/discripency-list']);
  }

  plant() {
    this.spinnerService.start();
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };

    this.DiscrepancyListService.plant(obj).subscribe((response) => {
      this.resPlant = response.map(
        (item: { plantcode: any }) => item.plantcode
      );
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response];
      this.onLoad();
    });
  }
}
