import { Component, OnInit } from '@angular/core';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UpdateConfirmComponent } from '../../part-list/ppf-update/update-confirm/update-confirm.component';
import { ActivatedRoute,Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/confirmation/confirmation.component';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-update-ppf',
  templateUrl: './update-ppf.component.html',
  styleUrls: ['./update-ppf.component.scss'],
  providers:[BreadcrumbComponent]
})
export class UpdatePpfComponent implements OnInit {
  partInfo: any[] = [];
  partInfoContents: any[] = [];
  updatePpf1: any[] = [];
  updatePpfContents1: any[] = [];
  reasonListWhat: any[] = [];
  reasonListWhy: any[] = [];
  reason: any;
  reasonWhy: any;
  ppfUpdate: any;
  result: any;
  reasonWhat: any;
  reasonWhatDropdown: any[] = [];
  selectedValue: any[] = [];
  reasonWhyDropdown: any[] = [];
  selectedValue1: any[] = [];
  newPart: any;
  supplierPsd: any;
  reqSelectedVal: any;
  reqSelectedVal1: any;
  tdRequest = true;
  tdSupDate = true;
  btnDisplayPPF = true;
  btnApprove = true;
  btnReject = true;
  tblRejectReason = true;
  tblCheck = true;
  spCode: any;
  ppfCode: any;
  ppfVer: any;
  rejReason: any;
  reasonWhat1: any;
  reasonWhy1: any;
  reasonWhat2: any;
  reasonWhy2: any;
  btnAuthorize = true;
  tblPPF = true;
  successMessage = '';
  errorMessage = '';
  divReq = false;
  auth: any;
  trackingList='/tracking-list';
  _trackinglist='tracking-list'
  supplierPsdError="Supplier PSD is Invalid Date Format";
  hzParams: any;
  step10status: any;

  constructor(
    private readonly PartListService: PartListService,
    public readonly datepipe: DatePipe,
    public dialog: MatDialog,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrump:BreadcrumbComponent,
    private readonly constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.ppfCode = this.route.snapshot.params['ppfcode'];
    this.ppfVer = this.route.snapshot.params['ppfver'];
    this.auth = this.route.snapshot.params['auth'];
    this.step10status = this.route.snapshot.params['step10status'];
    this.partInfo = ['Plant', 'Part Number', 'Supplier', 'Pkg Start Date'];
    this.updatePpf1 = ['Reason (What)', 'Reason (Why)'];
    this.ppfUpdate = JSON.parse(sessionStorage.getItem('updatePPF') || '{}');
    this.hzParams = JSON.parse(sessionStorage.getItem('hzParams') || '{}');
    const storedSpecialistCode = sessionStorage.getItem('specialistCode');
    this.spCode = storedSpecialistCode ? JSON.parse(storedSpecialistCode) : '';
    sessionStorage.removeItem('updatePPFReasons');
    this.loadUpdatePPF();
  }

  loadUpdatePPF() {
    if(this.auth==='1' && this.step10status !=="4" && this.router.url.includes(this._trackinglist)){
      this.errorMessage="Authorization is already existing to purchase packaging for parts in this Tracking."
    }
    if(this.hzParams?.hazmat === 'Y' && this.hzParams?.hzapprovestatus !== 'A' && this.hzParams?.isupdateppf === '1' && this.hzParams?.step09status === '4'){
      this.errorMessage = 'Hazmat approval is required.';
    }
    this.spinnerService.start();
    this.PartListService.updatePpfOnload(this.ppfUpdate).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.result = res[0][0];
        this.reasonWhat = res[1];
        this.reasonWhy = res[2];
        this.partInfoContents = [
          this.result.plant,
          this.result.partnumber,
          this.result.supplier,
          this.datepipe.transform(this.result.pkgstartdate, 'MM/dd/yyyy'),
        ];
        this.reasonWhatDropdown = this.reasonWhat;
        this.selectedValue = this.reasonWhatDropdown[0].code;
        this.reasonWhyDropdown = this.reasonWhy;
        this.selectedValue1 = this.reasonWhyDropdown[0].code;
        this.reason = res[4][0];
        if (this.reason && this.reason.supplierpsd) {
          this.supplierPsd=this.reason?.supplierpsd;
      
        }
        this.reqSelectedVal = this.reason?.reasonwhatcode;
        this.reqSelectedVal1 = this.reason?.reasonwhycode;
        if( this.reason && this.reason.reasonwhat && this.reason.reasonwhat!=='null' ){
          this.reasonWhat2=this.reason.reasonwhat
        }else{
          this.reasonWhat2=''
        }
        if( this.reason && this.reason.reasonwhy && this.reason.reasonwhy!=='null' ){
          this.reasonWhy2=this.reason.reasonwhy
        }else{
          this.reasonWhy2=''
        }
        if (!this.isFinalApproval(res[3])) {
          this.btnAuthorize = false;
          this.tdRequest = false;
          this.tdSupDate = false;
          this.tblPPF = false;
        } 
        if (this.result.isupdateppf !== '1' || (this.result.isupdateppf === '1' && res[3][0]?.step09status !== '4')) {
          this.tdRequest = false;
          this.tdSupDate = false;
          this.btnDisplayPPF = false;
          this.btnApprove = false;
          this.btnReject = false;
          this.tblRejectReason = false;
          this.tblCheck = false;
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  isFinalApproval(dv:any) {
    for (const item of dv) {
        const status = item.step09status;
        if (status === '4') {
            return true;
        }
    }
    return false;
}


  changediv() {
    this.errorMessage = ''
    this.divReq = !this.newPart ? true : false
  }
  
  update() {
    this.errorMessage = '';
    if (!this.newPart) {
      this.divReq = true;
    } else {
      if (this.newPart?.length !== 10) {
        this.errorMessage =
          '303-403:Range Error. New Part Number must be 10-12 digit';
      } else {
        if (this.newPart !== this.result?.partnumber) {
          const ppfConfirm = {
            plantCode: this.result.plantcode,
            partNumber: this.result.partnumber,
            supplierCode: this.result.suppliercode,
            pkgStartDate: this.result.pkgstartdate,
            newPartNumber: this.newPart,
          };
          sessionStorage.setItem('ppfUpdate', JSON.stringify(ppfConfirm));
          this.dialog.open(UpdateConfirmComponent);
        } else {
          this.errorMessage =
            '399-402:Data Error. [New Part] is equal to [Current Part].';
        }
      }
    }
  }

  reject() {
    const cancelObj = {
      urlppfCode: this.ppfCode,
      urlppfVersion: this.ppfVer,
      supplierPsd: this.supplierPsd ? this.supplierPsd : '',
      txtsupwhat: this.reasonWhat2 ? this.reasonWhat2 : '',
      txtsupwhy: this.reasonWhy2 ? this.reasonWhy2 : '',
      rejectReason: this.rejReason,
      userSpecialistCode: this.spCode,
      selsupwhat: this.reqSelectedVal ? this.reqSelectedVal : 0,
      selsupwhy: this.reqSelectedVal1 ? this.reqSelectedVal1 : 0,
    };
    this.PartListService.reject(cancelObj).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigateByUrl(this.trackingList);
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  approve() {
    const approveObj = {
      urlppfCode: this.ppfCode,
      urlppfVersion: this.ppfVer,
      supplierPsd: this.supplierPsd ? this.supplierPsd : '',
      partNumber: this.result.partnumber,
      userName: '',
      txtsupwhat: this.reasonWhat2 ? this.reasonWhat2 : '',
      txtsupwhy: this.reasonWhy2 ? this.reasonWhy2 : '',
      rejectReason: this.rejReason ?? '',
      userSpecialistCode: this.spCode,
      selsupwhat: this.reqSelectedVal ? this.reqSelectedVal : 0,
      selsupwhy: this.reqSelectedVal1 ? this.reqSelectedVal1 : 0,
    };
    this.PartListService.approve(approveObj).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigateByUrl(this.trackingList);
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  confirmDate() {
    this.errorMessage = ''
    var dt = this.datepipe.transform( this.supplierPsd, 'MM/dd/yyyy') ?? '';
    var _dtdetail = dt.split("/");

    if (!dt.match("\\d{1,2}/\\d{1,2}/\\d{4}")) {
      this.errorMessage = this.supplierPsdError;
    }

    if (_dtdetail.length !== 3) {
      this.errorMessage = this.supplierPsdError;
    }

    if (!this.checkDate(_dtdetail[2], _dtdetail[0], _dtdetail[1])) {
      this.errorMessage = this.supplierPsdError;
    }

    let str = "";
    str = `Please confirm ${dt} is the correct packaging start date`;
    if (this.errorMessage === '') {
      this.confirm(str, _dtdetail)
    }
  }

  confirm(data: any, _dtdetail: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        action: 'CONFIRM PSD',
        data: data
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.event === 'Confirmed') {
        if (_dtdetail[0].length === 1) {
          _dtdetail[0] = "0" + _dtdetail[0];
        }
        if (_dtdetail[1].length === 1) {
          _dtdetail[1] = "0" + _dtdetail[1];
        }
        this.supplierPsd = `${_dtdetail[0]}/${_dtdetail[1]}/${_dtdetail[2]}`;
        this.approve();
      }
    })
  }

  checkDate(year: any, month: any, day: any) {
    var yy = parseInt(year, 10);
    var mm = parseInt(month, 10);
    var dd = parseInt(day, 10);
    if (isNaN(yy) || isNaN(mm) || isNaN(dd)) {
      return false;
    }
    if (yy < 0 || yy > 9999) {
      return false;
    }
    if ((mm < 1) || (mm > 12)) {
      return false;
    }
    if ((mm === 1) || (mm === 3) || (mm === 5)
      || (mm === 7) || (mm === 8) || (mm === 10) || (mm === 12)) {
      if ((dd < 1) || (dd > 31)) {
        return false;
      }
    }
    else {
      if ((dd < 1) || (dd > 30)) {
        return false;
      }
    }

    if (mm === 2) {
      if ((yy % 400 === 0) || ((yy % 4 === 0) && (yy % 100 !== 0))) {
        if (dd > 29) {
          return false;
        }
      }
      else {
        if (dd > 28) {
          return false;
        }
      }
    }
    return true;
  }

  authorize() {
    const updatePPFReasons = {
      what: this.selectedValue,
      whatDesc: this.reasonWhat1,
      why: this.selectedValue1,
      whyDesc: this.reasonWhy1,
      pkgstartdate: this.result.pkgstartdate
    }
    const plantCode = this.constants.getTypeFromPlantCode(this.ppfUpdate.plantCode)
    sessionStorage.setItem('updatePPFReasons', JSON.stringify(updatePPFReasons))
    sessionStorage.setItem('ppfcloseFromTracking','0')  
    if (this.router.url.includes(this._trackinglist)) {
      if(plantCode === 'SERVICE'){
        sessionStorage.setItem('partnumber',this.result?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
        this.router.navigate([
          this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url + `/serviceparts-pdf/${this.ppfUpdate?.PPFCode}/${this.ppfVer
          }/${1}/${1}`,
        ]);                    
      } else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber',this.result?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
        this.router.navigate([
          this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url + `/accessoryParts-pdf/${this.ppfUpdate?.PPFCode}/${this.ppfVer
          }/${1}/${1}`,
        ]); 
      }
      else{
        window.open(this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url +`/ppf/${this.ppfUpdate?.PPFCode}/${this.ppfVer}/${1}/${1}`, '_blank');      }
    } else if (this.router.url.includes('part-list')) {
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber',this.result?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
        this.router.navigate([
          this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url + `/serviceparts-pdf/${this.ppfUpdate?.PPFCode}/${this.ppfVer
          }/${1}/${1}`,
        ]);
      } else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber',this.result?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
        this.router.navigate([
          this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url + `/accessoryParts-pdf/${this.ppfUpdate?.PPFCode}/${this.ppfVer
          }/${1}/${1}`,
        ]); 
      }
      else {
        window.open(this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url +`/ppf/${this.ppfUpdate?.PPFCode}/${this.ppfUpdate?.PPFVer}/${1}/${1}`, '_blank');
      }
    }
    sessionStorage.setItem('ppfcloseFromTracking','1')  
  }
close(){
  this.errorMessage=''
}
 displayPPF() {
    sessionStorage.setItem('ppfcloseFromTracking', '0');
    const plantCode = this.constants.getTypeFromPlantCode(this.ppfUpdate.plantCode)
    const isTrackingList = this.router.url.includes(this._trackinglist);
    const urlPrefix = this.breadcrump.breadcrumbs[this.breadcrump.breadcrumbs.length - 1].url;

    if (isTrackingList || this.router.url.includes('part-list')) {
        if (plantCode === 'SERVICE') {
            sessionStorage.setItem('partnumber', this.result?.partnumber);
            sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
            this.router.navigate([`${urlPrefix}/serviceparts-pdf/${this.ppfUpdate?.PPFCode}/${-1}/${1}/${2}`]);
        } else if(plantCode === 'ASSY'){
          sessionStorage.setItem('partnumber',this.result?.partnumber);
          sessionStorage.setItem('ppfPlantCode', this.ppfUpdate.plantCode);
          this.router.navigate([`${urlPrefix}/accessoryParts-pdf/${this.ppfUpdate?.PPFCode}/${-1}/${1}/${2}`]);
        }
        else {
            window.open(`${urlPrefix}/ppf/${this.ppfUpdate?.PPFCode}/${-1}/${1}/${2}`, '_blank');
        }
    }
    sessionStorage.setItem('ppfcloseFromTracking', '1');
}


  cancel() {
    let uri :any;
    if (this.router.url.includes(this._trackinglist)) {
      uri = this.trackingList;
    } else if (this.router.url.includes('part-list')) {
      uri = '/part-list';
    }

    this.router.navigateByUrl(uri);
  }

  help() {
    this.router.navigate(['./master-help', 2, 'Tracking', 146]);
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'supplierPsd') {
        this.supplierPsd = '';
      }
    }
  }
}