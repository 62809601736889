<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Tracking History</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
<app-notification [codes] = "errorMessage" *ngIf="errorMessage"></app-notification>

<div class="row m-3 content-row">
    <div class="col-md-4 align-items-center">
        <div class="row">
            <div class="col-md-5 titleColumn">
                <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-7 contentColumn p-0">
                <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-5 d-flex">
        <label class="col-3">Comment (not shared with supplier)</label>
        <textarea rows="2" class="comment col-8 ms-3" [(ngModel)]="comment"></textarea>
    </div>
</div>

<div class="vpacs-shadow m-3 p-3">
    <div class="row">
        <div class="d-flex flex-wrap col-11 event-checkbox">
            <div *ngFor="let check of checkList; let i = index" class="col-md-3 align-items-center ">
                <label class="checkcontainer">{{check.name}}
                    <input type="checkbox" [checked]='check.checked===true' (change)="onChange($event, i)">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="col-1">
            <input type="button" value="SEARCH" class="black-button"  (click)="loadTrackingHistory()"/>
        </div>
    </div>
</div>

<div class="alert alert-danger mx-3 d-flex justify-content-center" *ngIf="errorList.length > 0">
    <span *ngFor="let message of errorList">{{message}}</span>
</div>

<div class="accordion-container m-3 pb-5">
    <div class="">
        <div class="col-md-8" *ngFor="let header of sortedArray; let i=index">
            <mat-accordion hideToggle multi>
                <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0"
                    (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="me-0">{{header?.title}}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row m-3 content-row mx-0">
                        <div class="col-md-4 titleColumn">
                            <div *ngFor="let title of header?.content" class="contents ps-2 py-2">
                                {{title.name}}
                            </div>
                        </div>
                        <div class="col-md-8 contentColumn p-0">
                            <div *ngFor="let title of header?.content; let i=index" class="px-4 py-2"
                                [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'" id="link">
                                <span *ngIf="title.name!=='File'">{{title.value}}</span>
                                <a (click)="viewPPF(title.value)" *ngIf="title.name==='File'">PPF</a>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3" (click)="onCancel()"> BACK </button>
    <button class="black-button me-3" (click)="save()" [disabled]="isSaveDisabled">SAVE</button>
</div>