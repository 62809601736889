import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { BenchMarkImgCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/benchmark-img-cell-renderer'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-bench-mark',
  templateUrl: './bench-mark.component.html',
  styleUrls: ['./bench-mark.component.scss'],
})
export class BenchMarkComponent implements OnInit {
  plantValues: any
  columnDefs: any[] = []
  defaultColDef: any
  gridApi: any
  rowClassRules: any
  displayRowData: any[] = []
  plant = ''
  modelCode = ''
  partNumber = ''
  supplier = ''
  benchMarkData: any
  benchMarkParams: any
  public domLayout: any = ''
  benchMarrkGridData: any[] = []
  isSelected = false
  errorList: string[] = []
  filterArr: any[] = []
  haveAccess: any
  haveAddAccess: any
  modifyAccess: any
  deleteAccess: any
  userInfo: any

  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  noMatch = 'NO MATCH FOUND'
  pplantTrackingList: any[] = []
  blnSelectBenchmark = true;
  colName: any

  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly router: Router,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.userAccess()
  }

  userAccess() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spinnerService.start()
        this.userInfo = res.info
        this.haveAccess = res.role?.p_trackingview
        this.haveAddAccess = res.role?.p_trackingsave
        this.modifyAccess = res.role?.p_trackingcorrect
        this.deleteAccess = res.role?.p_trackingdelete
        this.pplantTrackingList = res.role?.p_plantTrackingList;
        this.benchMarkParams = JSON.parse(
          sessionStorage.getItem('benchMark') || '{}',
        )
        if (this.haveAccess && this.containPlantTrackingList(this.benchMarkParams?.plantcode)) {
          this.loadBenchMark()
          this.getcolumnDef()
        } else {
          this.errorList = ['Authority error']
          this.spinnerService.stop()
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
        this.spinnerService.stop()
      },
    })
  }

  containPlantTrackingList(plantCode: any) {
    for (let i = 0; i < this.pplantTrackingList?.length; i++) {
      if (this.pplantTrackingList[i]["plantcode"] === plantCode) {
        return true;
      }
    }
    return false;
  }

  loadBenchMark() {
    this.errorList = []

    this.benchMarkParams = {
      ...this.benchMarkParams,
      sessionBackup: this.benchMarkParams?.userInfo?.role?.backupDetails,
      sessionSpecialistCode: this.userInfo?.p_specialistcode
    }

    this.trackingListService.benchMarkLoad(this.benchMarkParams).subscribe({
      next: (res: any) => {
        if (res?.status === 'SUCCESS' || (res?.status === 'ERROR' && res?.data)) {
          if (res?.status === 'ERROR') {
            this.errorList.push(res?.message)
          }
          this.benchMarkData = res?.data
          this.plant = res?.data.createHtml_Info_response?.tdPlant
          this.modelCode = res?.data.createHtml_Info_response?.tdModelCode
          this.partNumber = res?.data.createHtml_Info_response?.tdPartNumber
          this.supplier = res?.data.createHtml_Info_response?.tdSupplier
          this.benchMarrkGridData = res?.data.createBenchMark?.result

          this.benchMarrkGridData.forEach((row: any) => {
            row['modelName'] =
              `${this.nullStr(row.modelcode)} : ${this.nullStr(row.vehiclename)}`
            row.partLevelMatch = this.nullStr(row.partlevelmatch + ' digit')
            row.plant = this.nullStr(row.plantname)
            row.part = `${this.changePartNumber(this.nullStr(row['partnumber']))} : ${this.nullStr(row['description'])}`

            row.supCode = `${this.nullStr(row['suppliercode'])} : ${this.nullStr(row['suppliername'])}`

            row.imageSrc = 'data:image/jpg;base64,' + row.pkgimage_url
            row.container = `${this.nullStr(row['packagecode'])} : ${this.nullStr(row['dunnage1code'])}`

            row.packagType = `${this.nullStr(row['packagetypecode'])} : ${this.nullStr(row['packagetype'])}`

            row.lwh = `${this.changeUnitFormat(this.changeSize(this.nullStr(row['packagelength']), 0), 0,)} / ${this.changeUnitFormat(this.changeSize(this.nullStr(row['packagewidth']), 0), 0,)} / ${this.changeUnitFormat(this.changeSize(this.nullStr(row['packageheight']), 0), 0,)}`

            row.qpc = this.nullStr(row['qtypartcontrack'])

            row.partLWH = `${this.changeUnitFormat(
              this.changeSize(this.nullStr(row['partlength']), 0),
              0,
            )} / ${this.changeUnitFormat(
              this.changeSize(this.nullStr(row['partwidth']), 0),
              0,
            )} / ${this.changeUnitFormat(
              this.changeSize(this.nullStr(row['partheight']), 0),
              0,
            )}`


            row.weight = this.changeUnitFormat(
              this.changeSize(this.nullStr(row['partweight']), 1),
              1,
            )
            row.m3 = this.nullStr(row['cubicperpart'])
            row.contGross = this.changeUnitFormat(
              this.changeSize(this.nullStr(row['containergross']), 1),
              1,
            )
            row.lnkPPF = 'Display PPF'
            row.efficiencyRank = this.nullStr(row['cubicperpartrank'])
            row.logCost = (this.nullInt(row['logisticscost']) === 9999) ? '-' : ('$' + this.nullStr(row['logisticscost'].toFixed(3)))
            row.pkgCost = (this.nullInt(row['packagecost']) === 9999) ? '-' : ('$' + this.nullStr(row['packagecost'].toFixed(3)))
            row.totalCost = (this.nullInt(row['totalcost']) === 9999) ? '-' : ('$' + this.nullStr(row['totalcost'].toFixed(3)))
            row.totalCostRank = (this.nullInt(row['totalcost']) === 9999) ? '-' : this.nullStr(row['totalcostrank'])
            row.radio = 'radionButton'
            row.radioDisplay = true
          })
          this.formatData()
        }
        else {
          this.errorList.push(res?.message)
          window.scrollTo(0, 0)
        }

        this.spinnerService.stop()
      },
      error: (error: any) => {
        this.spinnerService.stop()
        this.errorList.push(error.message)
        window.scrollTo(0, 0)
      },
    })
  }

  formatData() {
    const plantValues = [
      { leftHeader: 'MODEL NAME', field: 'modelName' },
      { leftHeader: 'PLANT', field: 'plant' },
      { leftHeader: 'PART', field: 'part' },
      { leftHeader: 'PART LEVEL MATCH', field: 'partLevelMatch' },
      { leftHeader: 'SUPPLIER CODE', field: 'supCode' },
      { leftHeader: 'PACKAGE PICTURE', field: 'imageSrc' },
      { leftHeader: 'CONTAINER: DUNNAGE1', field: 'container' },
      { leftHeader: 'PACKAGE TYPE', field: 'packagType' },
      { leftHeader: 'LWH', field: 'lwh' },
      { leftHeader: 'QPC', field: 'qpc' },
      { leftHeader: 'PART LWH', field: 'partLWH' },
      { leftHeader: 'WEIGHT', field: 'weight' },
      { leftHeader: 'EFFICIENCY RANK', field: 'efficiencyRank' },
      { leftHeader: '㎥/PART', field: 'm3' },
      { leftHeader: 'CONTAINER GROSS', field: 'contGross' },
      { leftHeader: 'LOGISTICS COST/VEHICLE', field: 'logCost' },
      { leftHeader: 'PACKAGE COST/VEHICLE', field: 'pkgCost' },
      { leftHeader: 'TOTAL COST', field: 'totalCost' },
      { leftHeader: 'TOTAL COST RANK', field: 'totalCostRank' },
      { leftHeader: 'LINK PPF', field: 'lnkPPF' },
      { leftHeader: 'COPY', field: 'radio' },
    ]
    this.plantValues = plantValues
    this.plantValues.map((item: any, _index: number) => {
      const temp = {
        matchLevel: item.leftHeader,
      }
      this.displayRowData.push(temp)
    })

    for (let i = 0; i < this.benchMarrkGridData.length; i++) {
      for (const prob in this.benchMarrkGridData[i]) {
        for (let j = 0; j < this.plantValues.length; j++) {
          if (prob === this.plantValues[j]['field']) {
            if (this.benchMarrkGridData[i].matchlevel === 1) {
              this.displayRowData[j].bestCurrent = this.benchMarrkGridData[i][
                prob
              ]
            } else if (this.benchMarrkGridData[i].matchlevel === 2) {
              this.displayRowData[j].bestPart = this.benchMarrkGridData[i][prob]
            } else if (this.benchMarrkGridData[i].matchlevel === 3) {
              this.displayRowData[j].bestPartGt5 = this.benchMarrkGridData[i][
                prob
              ]
            } else if (this.benchMarrkGridData[i].matchlevel === 4) {
              this.displayRowData[j].pkgBmc = this.benchMarrkGridData[i][prob]
            } else if (this.benchMarrkGridData[i].matchlevel === 5) {
              this.displayRowData[j].inDev = this.benchMarrkGridData[i][prob]
            }
          }
        }
      }
    }
    if (!this.displayRowData[3]?.bestCurrent) {
      this.displayRowData[3].bestCurrent = this.noMatch;
    }
    if (!this.displayRowData[3]?.bestPart) {
      this.displayRowData[3].bestPart = this.noMatch;
    }
    if (!this.displayRowData[3]?.bestPartGt5) {
      this.displayRowData[3].bestPartGt5 = this.noMatch;
    }
    if (!this.displayRowData[3]?.pkgBmc) {
      this.displayRowData[3].pkgBmc = this.noMatch;
    }
    if (!this.displayRowData[3]?.inDev) {
      this.displayRowData[3].inDev = this.noMatch;
    }
    this.displayRowData = [...this.displayRowData]
  }

  getcolumnDef() {
    this.columnDefs = [
      {
        headerName: 'MATCH LEVEL',
        autoHeight: true,
        field: 'matchLevel',
        minWidth: 170,
        maxWidth: 170,
        pinned: 'left',
      },
      {
        headerName: 'BEST CURRENT',
        autoHeight: true,
        field: 'bestCurrent',
        minWidth: 270,
        maxWidth: 270,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 1)
          },
        },
      },
      {
        headerName: 'BEST PART',
        autoHeight: true,
        field: 'bestPart',
        minWidth: 270,
        maxWidth: 270,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 2)
          },
        },
      },
      {
        headerName: 'BEST PART(>5)',
        autoHeight: true,
        field: 'bestPartGt5',
        minWidth: 270,
        maxWidth: 270,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 3)
          },
        },
      },
      {
        headerName: 'PACKAGE BMC',
        autoHeight: true,
        field: 'pkgBmc',
        minWidth: 270,
        maxWidth: 270,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 4)
          },
        },
      },
      {
        headerName: 'IN DEVELOPMENT',
        autoHeight: true,
        field: 'inDev',
        minWidth: 270,
        maxWidth: 270,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 5)
          },
        },
      },
      {
        headerName: 'BLANK',
        autoHeight: true,
        field: 'blank',
        minWidth: 150,
        maxWidth: 150,
        cellRendererFramework: BenchMarkImgCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, ele: string) => {
            this.openDialog(colId, data, ele, 6)
          },
        },
      },
    ]
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
  }

  approveValidations() {
    let returnVal = true
    if (!this.isSelected) {
      this.errorList = ['304-401:Required Item Error [is not selected.]']
      window.scrollTo(0, 0)
      returnVal = false
    }
    return returnVal
  }

  onSave() {
    if (!this.approveValidations()) {
      return
    }
    const payload = {
      ppfcode: this.benchMarkParams.ppfcode,
      ppfver: this.benchMarkParams.ppfver,
      selectppfcode: this.filterArr[0]?.ppfcode,
      selectppfver: this.filterArr[0]?.ppfver,
      blnSelectBenchmark: this.blnSelectBenchmark,
    }
    this.errorList = []
    this.spinnerService.start()
    this.trackingListService.benchMarkSave(payload).subscribe({
      next: (res: any) => {
        if (res) {
          if (this.benchMarkParams?.plantcode === '50SER') {
            this.router.navigate(['/tracking-list/benchmark/serviceparts-pdf', this.benchMarkParams.ppfcode, Number(this.benchMarkParams.ppfver) + 1, 1, 1])
          } else {
            sessionStorage.setItem('ppfcloseFromTracking', '0')
            if (this.colName === 'blank') {
              window.open(`/tracking-list/benchmark/ppf/${this.benchMarkParams.ppfcode}/${Number(this.benchMarkParams.ppfver)}/1/1`, '_blank');
            } else {
              window.open(`/tracking-list/benchmark/ppf/${this.benchMarkParams.ppfcode}/${Number(this.benchMarkParams.ppfver) + 1}/1/1`, '_blank');
            }
            sessionStorage.setItem('ppfcloseFromTracking', '1')
          }
        }
        this.spinnerService.stop()
      },
      error: (error: any) => {
        this.errorList.push(error.message)
        window.scrollTo(0, 0)
        this.spinnerService.stop()
      },
    })
  }

  close() {
    this.errorList = []
  }

  onCancel() {
    this.router.navigate(['/tracking-list']);
  }

  openDialog(colName: string, data: any, ele: string, colId: number) {
    this.colName = colName;
    if (ele === 'img') {
      const base64ImageData = data[colName]
      const newWindow = window.open(
        'ShowImage',
        '_blank',
        'width=500,height=500,toolbar=no,menubar=no,resizable=yes,location=no,status=yes,scrollbars=yes',
      )

      if (newWindow) {
        newWindow.document.title = 'ShowImage'
        newWindow.document.write(
          `<img src="${base64ImageData}"  alt="Base64 Image">`,
        )
      } else {
        this.errorList = ['Failed to open a new window']
      }
    } else if (ele === 'link') {
      const filterArr = this.benchMarrkGridData.filter(
        (item) => item.matchlevel === colId,
      )
      if (filterArr.length > 0) {
        if (this.benchMarkParams?.plantcode === '50SER') {
          this.router.navigate(['/tracking-list/benchmark/serviceparts-pdf', filterArr[0].ppfcode, filterArr[0].ppfver, 1, 2])
        } else {
          sessionStorage.setItem('ppfcloseFromTracking', '0')
          window.open(`/tracking-list/benchmark/ppf/${filterArr[0].ppfcode}/${filterArr[0].ppfver}/1/2`, '_blank');
          sessionStorage.setItem('ppfcloseFromTracking', '1')
        }
      }
    } else if (ele === 'radio') {
      if (colId === 6) {
        this.isSelected = true;
        this.blnSelectBenchmark = false
      } else {
        this.filterArr = this.benchMarrkGridData.filter(
          (item) => item.matchlevel === colId,
        )
        if (this.filterArr.length > 0) {
          this.isSelected = true
        }
        this.blnSelectBenchmark = true
      }
    }
  }

  nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  changePartNumber(partnumber: string) {
    if (partnumber == null) {
      return ''
    } else if (partnumber.length < 6) {
      return partnumber
    } else {
      return this.insertCharacter(partnumber, 5, '-')
    }
  }

  insertCharacter(str: string, index: number, char: string) {
    return str.slice(0, index) + char + str.slice(index)
  }

  changeSize(obj: any, type: number) {
    if (obj == null) {
      return 0
    } else {
      const size = this.nullDouble(obj)

      if (this.userInfo.unit === '0') {
        return size
      } else {
        if (type === 0) {
          return this.changeInch(size)
        } else if (type === 1) {
          return this.changePound(size)
        }
        return 0
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === '' || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj)
        } else {
          return parseInt(obj)
        }
      } catch {
        return 0
      }
    }
  }

  changeInch(size: any) {
    return Math.round((size / 25.4) * 2) / 2
  }

  changePound(size: any) {
    return (size * 1000) / 453.59237
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size)
      } else {
        return this.formatPoundNull(size)
      }
    } else {
      if (type === 0) {
        return this.formatMmNull(size)
      } else {
        return this.formatkgNull(size)
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return '0'
    }
    const hasDecimal = size % 1 !== 0
    return hasDecimal ? size.toFixed(2) : size.toFixed(0)
  }

  formatPoundNull(size: any) {
    if (size === 0) {
      return ''
    }
    const hasDecimal = size % 1 !== 0
    return hasDecimal ? size.toFixed(2) : size.toFixed(0)
  }

  formatMmNull(mm: any) {
    if (mm === 0) {
      return ''
    }
    const hasDecimal = mm % 1 !== 0
    return hasDecimal ? mm.toFixed(2) : mm.toFixed(0)
  }

  formatkgNull(kg: any) {
    if (kg === 0) {
      return ''
    }
    const hasDecimal = kg % 1 !== 0
    return hasDecimal ? kg.toFixed(2) : kg.toFixed(0)
  }

  nullInt(obj: any) {
    if (obj === null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }
}
