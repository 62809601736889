<div class="d-flex justify-content-between align-items-center m-3">
  <div class="page-title">Move Package</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="mx-3 p-2 header">AUTHORIZATION FORM</div>
<div class="alert alert-danger m-3" *ngIf="errorList?.length>0">
  <ng-container *ngFor="let error of errorList ">
    <div>{{error}}</div>
  </ng-container>
</div>
<div [formGroup]="moveForm">
  <div class="top-arr d-flex flex-wrap m-3">
    <div class="top-arr-left col-md-4 col-12 d-flex">
      <div class="left d-flex flex-column col-5 header">
        <span>Plant</span>
        <span>Supplier</span>
        <span>Actual Qty</span>
        <span>Require Qty</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>{{response?.FromPlantName[0]?.name}}</span>
        <span>{{response?.FromSupplierName[0]?.shortname}}</span>
        <span>{{response?.FromQty[0]}}</span>
        <span>{{response?.FromQty[1]}}</span>
      </div>
    </div>
    <div class="top-arr-center col-2">
      <img
        src="../../../../../assets/images/right-arrow.svg"
        style="width: 50px"
        alt="arrow"
      />
    </div>
    <div class="top-arr-right col-md-4 col-12 d-flex">
      <div class="left d-flex flex-column col-5 header">
        <span>Plant</span>
        <span>Supplier</span>
        <span>Actual Qty</span>
        <span>Require Qty</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>{{response?.ToPlantName[0]?.name}}</span>
        <span>{{response?.ToSupplierName[0]?.shortname}}</span>
        <span>{{response?.ToQty[0]}}</span>
        <span>{{response?.ToQty[1]}}</span>
      </div>
    </div>
  </div>
  <div class="main-content d-flex flex-wrap m-3">
    <div class="main-left col-md-4 col-12 d-flex alignHt">
      <div class="left d-flex flex-column col-5 header">
        <span>Ringi</span>
        <span>Ringi Type</span>
        <span>Auth Type</span>
        <span>Reason</span>
        <span>From Date (mm/dd/yyyy)</span>
        <span>To Date (mm/dd/yyyy)</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>
          <mat-select
            class="mat-sel pt-2"
            disableOptionCentering
            formControlName="ringi"
          >
          <mat-option
              *ngFor="let x of ringiDropDown"
              [value]="x.ringinumber"
              >{{ x.ringinumber }}</mat-option
            >
          </mat-select>
        </span>
        <span>Type 2</span>
        <span>Move</span>
        <span>
          <mat-select
            class="mat-sel pt-2"
            disableOptionCentering
            formControlName="reason"
          >
          <mat-option *ngFor="let x of reasonDropDown" [value]="x.id">{{
              x.reason
            }}</mat-option>
          </mat-select>
        </span>
        <span
          ><input type="date" style="width: 100%" formControlName="fromDate"
        /></span>
        <span
          ><input type="date" style="width: 100%" formControlName="toDate"
        /></span>
      </div>
    </div>
    <div class="right d-flex col-lg-8 col-md-12 col-12 ps-5">
      <div class="main-right" style="overflow-x: auto;">
        <table class="tg w-100" aria-describedby="table1">
          <thead>
            <tr>
              <th class="tg-mg6y"></th>
              <th class="tg-mg6y">Package Code</th>
              <th class="tg-mg6y">Move Qty</th>
              <th class="tg-mg6y">Cost/Piece</th>
              <th class="tg-mg6y">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tg-mg6y">Package</td>
              <td class="tg-z5to">{{package1}}</td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="package2"/>
              </td>
              <td class="tg-z5to" colspan="2">
                <button class="vpacs-btn-table" (click)="calcPackage()">Calc Package</button>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Container</td>
              <td class="tg-eydr">{{container1}}</td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container2"/>
                <div
                  *ngIf="isValid['container2']?.invalid && (isValid['container2']?.dirty || isValid['container2']?.touched || submitted)">
                  <div *ngIf="isValid['container2'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['container2'].errors?.['pattern'] && !isValid['container2'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['container2'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999 range</div>
                </div>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container3"/>
                <div
                  *ngIf="isValid['container3']?.invalid && (isValid['container3']?.dirty || isValid['container3']?.touched || submitted)">
                  <div *ngIf="isValid['container3'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['container3'].errors?.['pattern'] && !isValid['container3'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['container3'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999999 range</div>
                </div>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="container4"/>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Dunnage1</td>
              <td class="tg-z5to">{{dunnage11}}</td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="dunnage12"/>
                <div
                  *ngIf="isValid['dunnage12']?.invalid && (isValid['dunnage12']?.dirty || isValid['dunnage12']?.touched || submitted)">
                  <div *ngIf="isValid['dunnage12'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['dunnage12'].errors?.['pattern'] && !isValid['dunnage12'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['dunnage12'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999 range</div>
                </div>
              </td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="dunnage13"/>
                <div
                  *ngIf="isValid['dunnage13']?.invalid && (isValid['dunnage13']?.dirty || isValid['dunnage13']?.touched || submitted)">
                  <div *ngIf="isValid['dunnage13'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['dunnage13'].errors?.['pattern'] && !isValid['dunnage13'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['dunnage13'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999999 range</div>
                </div>
              </td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="dunnage14"/>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Dunnage 2</td>
              <td class="tg-eydr">{{dunnage21}}</td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="dunnage22" />
                <div
                  *ngIf="isValid['dunnage22']?.invalid && (isValid['dunnage22']?.dirty || isValid['dunnage22']?.touched || submitted)">
                  <div *ngIf="isValid['dunnage22'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['dunnage22'].errors?.['pattern'] && !isValid['dunnage22'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['dunnage22'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999 range</div>
                </div>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="dunnage23" />
                <div
                  *ngIf="isValid['dunnage23']?.invalid && (isValid['dunnage23']?.dirty || isValid['dunnage23']?.touched || submitted)">
                  <div *ngIf="isValid['dunnage23'].errors?.['required']  " class="errorValiid"> required field
                  </div>
                  <div *ngIf="isValid['dunnage23'].errors?.['pattern'] && !isValid['dunnage23'].errors?.['required']  "
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['dunnage23'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999999 range</div>
                </div>
              </td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="dunnage24"/>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Label</td>
              <td class="tg-z5to">-</td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="label2"/>
                <div
                  *ngIf="isValid['label2']?.invalid && (isValid['label2']?.dirty || isValid['label2']?.touched || submitted)">
                  <div *ngIf="isValid['label2'].errors?.['pattern']"
                    class="errorValiid"> please provide a number</div>
                  <div *ngIf="isValid['label2'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999 range</div>
                </div>
              </td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="label3"/>
                <div
                *ngIf="isValid['label3']?.invalid && (isValid['label3']?.dirty || isValid['label3']?.touched || submitted)">
                <div *ngIf="isValid['label3'].errors?.['pattern']"
                  class="errorValiid"> please provide a number</div>
                <div *ngIf="isValid['label3'].errors?.['max']  " class="errorValiid"> value should be in 0 to 99999999 range</div>
              </div>
              </td>
              <td class="tg-z5to">
                <input type="text" (change)="ipChange()" formControlName="label4"/>
              </td>
            </tr>
            <tr>
              <td class="tg-mg6y">Other</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">-</td>
              <td class="tg-eydr">
                <input type="text" (change)="ipChange()" formControlName="other4"/>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="ppf-footer col-12 d-flex align-items-center flex-wrap p-2">
          <span>
            Total
            <input type="text" formControlName="grandTotal" class="mx-3"/>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-block m-3 mt-4 pb-5 content-row me-0 comment">
    <div class="d-flex col-md-4 align-items-baseline px-0 pe-1">
      <label for="details" class="control-label pt-1 col-md-4"
        >Comment For Supplier</label
      >
      <textarea
        name=""
        id="details"
        cols="170"
        rows="3"
        class="form-control detail_area ms-3 pt-0"
        formControlName="commentSupplier"
      ></textarea>
    </div>
    <div class="d-flex col-md-4 align-items-baseline px-0 pe-1 mt-3 mb-5">
      <label for="details" class="control-label col-md-4"
        >Internal Use Only</label
      >
      <textarea
        name=""
        id="details"
        cols="170"
        rows="3"
        class="form-control detail_area ms-3 pt-0"
        formControlName="internal"
      ></textarea>
    </div>
  </div>
</div>
<div
  class="
    create_footer
    d-flex
    justify-content-end
    align-items-center
    fixed-bottom
  "
>
  <div (click)="onBack()" class="mx-3 text-primary link">BACK</div>
  <button class="black-button me-3" (click)="onSubmit()">
    AUTHORIZE
  </button>
</div>
