import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessService {
  private readonly httpHeaderDetails:any;
  constructor(private readonly http: HttpClient,private readonly constantsService: ConstantsService) {
    this.httpHeaderDetails = this.constantsService.httpCustomOptions;
  }

  loadOnhold() : Observable<any>{
    return this.http.get(this.constantsService.EXTERNAL + 'adminaccess', {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}
