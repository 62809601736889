<div class="d-flex justify-content-between align-items-center m-3">
  <p class="page-title">Select PPF</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="haveErrors === true && errorList.length > 0">
  <span *ngFor="let message of errorList">{{ message }}</span>
</div>
<div class="vpacs-shadow m-3 p-2">
  <div class="filter m-2 d-flex" (keyup.enter)="searchBtn()">

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="plant" class="col-4">PPF</label>
      <mat-select id="plant" class="mat-sel" [(ngModel)]="ppfVal" placeholder="Select">
        <mat-option *ngFor="let ppf of PPF" [value]="ppf.value">{{ppf.name}}</mat-option>
      </mat-select>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="plant" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
      (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
      </mat-option>
    </mat-autocomplete>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Family Code</label>
      <input type="text" id="family-code" [(ngModel)]="familyCode" /> 
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Model Code</label>
      <input type="text" id="family-code" [(ngModel)]="modelCode" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="family-code" class="col-4">Supplier</label>
      <input type="text" id="family-code" [(ngModel)]="supplier"/>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5 mt-2">
      <label for="part-number" class="col-4">Part Number</label>
      <input type="text" id="part-number" [(ngModel)]="partNo">
    </div>

    <div class="d-flex align-items-center me-5 mt-2">
      <input type="button" style="height: 30px;" value="SEARCH" class="black-button ms-3" (click)="searchBtn()" />
    </div>


  </div>
</div>

<div class="vpacs-shadow m-3 p-0 mb-0 mb-5 pb-3">
  <div class="grid-agg px-0 tracking-summary">
    <ag-grid-angular style="width: 100%;height: 400px" class="ag-theme-balham" (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
      [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true" [gridOptions]="gridOptions"
      [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
      [headerHeight]="headerHeight" [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" [domLayout]="domLayout">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)">
    </app-pagination>
  </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
  <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
</div>