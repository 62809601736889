import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DiscrepencySelectBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-discrepancy/descripency-select';
import { DiscrepancyListHyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/discrepancy-list-hyperlink';
import { PartListHyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-part-list/part-list-hyperLink';
import { PartListSelectComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/tracking-part-list/part-list-select/part-list-select.component';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { TrackingListService, dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-select-track-part',
  templateUrl: './select-track-part.component.html',
  styleUrls: ['./select-track-part.component.scss']
})
export class SelectTrackPartComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50
  isAccessible = false;
  // plant: any;
  plants: any[] = [];
  totalRecords = 0;
  partNumber = '';
  part1 = '';
  part2 = '';
  sortModel: any[] = [];
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  cPage = 1;
  tPage = 0;
  rpPage = 50;

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal: any = 1;
  spCode: any;
  suppliercode: any;
  familycode: any = '';
  partnumber: any;
  fromdate: any;
  plantcode: any;
  plantss: any;
  rn: any;
  modelcode: any = '';
  partdescription: any = '';
  container: any;
  specialistcode: any = '';
  over: any = '';
  under: any = '';
  toDate: any = '';
  develop = false;
  complete = false;
  gridColumnApi: any;
  haveAccess: any;
  supType: any = '';
  userInfo: any;
  unit: any = '';
  plantDropdown: any;

  constructor(private readonly router: Router, private readonly spinnerService: NgxUiLoaderService, public dialog: MatDialog,
    private readonly state: ExportStateService, private readonly trackingListService: TrackingListService,private readonly discrepancyListService:DiscrepancyListService) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      filterParams: { newRowsAction: 'keep' },
      comparator:dateComparator
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spinnerService.start();
        if (res) {
          this.userInfo = res.info;
          this.unit = res.info?.unit;
          this.supType = res.role.supType;
          this.spCode = res.info.specialistcode;
          this.haveAccess = res.role?.p_trackingview;
          this.haveAccess && this.getColumnDefs();
          this.haveAccess && this.plant();
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
            this.plantDropdown='(All)'
            this.specialistcode=this.spCode
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
    if ( sessionStorage.getItem('parentData')) {
      const data1=sessionStorage.getItem('parentData')
      let data:any
      if(data1!=null) {
      data=JSON.parse(data1)
      }
      this.fromdate =  data?.fromdate;
      this.plantss =  data?.plantss;
      this.rn = data?.rn;
    }
  }
  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "SELECT", field: "history", minWidth: 90, maxWidth: 100,

        cellRendererFramework: DiscrepencySelectBtnCellRender, cellStyle: { cursor: 'pointer' }
      },


      {
        headerName: "FAMILY CODE", field: "familycode", minWidth: 90, maxWidth: 100
      },
      {
        headerName: "MODEL CODE", field: "modelcode", minWidth: 90, maxWidth: 100
      },
      {
        headerName: "SUPPLIER CODE", field: "supplier", minWidth: 120, maxWidth: 150,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "SUPPLIER SHORT NAME", field: "supplier", minWidth: 120, maxWidth: 150,
        cellRendererFramework: PartListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "PART NUMBER", field: "partnumber", minWidth: 90, maxWidth: 100,
        cellRendererFramework: DiscrepancyListHyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }
      },
      {
        headerName: "PART DESCRIPTION", field: "partdescription", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, cellStyle: { whiteSpace: 'normal', lineHeight: 'normal' },
        filter: 'agTextColumnFilter', minWidth: 170, maxWidth: 180
      },
      {
        headerName: "PKG START DATE", field: "pkgstartdate", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, valueFormatter: this.dateFormatter,
        filter: 'agTextColumnFilter', minWidth: 120, maxWidth: 150
      },
      {
        headerName: this.unit === '0' ? "TOTAL WEIGHT(kg)" : "TOTAL WEIGHT(IB)", field: "totalweight", sortable: true, unSortIcon: true, minWidth: 120, maxWidth: 150
      },
      {
        headerName: "PARTM3", field: "partm3", sortable: true, unSortIcon: true, minWidth: 140, maxWidth: 160
      },
      {
        headerName: "QPC", field: "qtybox", minWidth: 140, maxWidth: 160,
      },
      {
        headerName: "QPU", field: "qpu", minWidth: 90, maxWidth: 100,
      },
      {
        headerName: "CONTAINER", field: "container", minWidth: 90, maxWidth: 100,
      },
      {
        headerName: "DUNNAGE 1", field: "dunnage1", minWidth: 90, maxWidth: 100,
      },
      {
        headerName: "DUNNAGE 2", field: "dunnage2", minWidth: 140, maxWidth: 160,
      },
      {
        headerName: "HAZMAT", field: "hazmat", minWidth: 140, maxWidth: 160,
      },
      {
        headerName: "SPECIALIST", field: "specialist", minWidth: 90, maxWidth: 100,
      },
      {
        headerName: "PLANT", field: "plant", minWidth: 140, maxWidth: 160,
      },
      {
        headerName: "PART ROUTING", field: "routing", minWidth: 130, maxWidth: 160,
      },
      {
        headerName: "ECI#", field: "ecino", minWidth: 140, maxWidth: 160,
      },

    ]
  }

  dateFormatter(params: any) {
    return params.value ? params.value.substring(0, 10) : '';
  }

  setText(selector: string, text: any) {
    if (this.rowCount > 0) {
      const element = document.querySelector(selector);
      if (element !== null) {
          (element as HTMLElement).innerHTML = text;
      }    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  backBtn() {
    this.removeData();
    if (this.router.url.includes('sI-preppf-dept-approval')) {
      this.router.navigate(['/tracking-list/sI-preppf-dept-approval'])
    }
    else if (this.router.url.includes('sI-final-dept-approval')) {
      this.router.navigate(['/tracking-list/sI-final-dept-approval'])
    }
    else if (this.router.url.includes('si-pkgtrial-dept-approval')) {
      this.router.navigate(['/tracking-list/si-pkgtrial-dept-approval'])
    }
    else if (this.router.url.includes('send-ppf')) {
      this.router.navigate(['/tracking-list/send-ppf'])
    }
  }

  removeData() {
    sessionStorage.removeItem('parentData')
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 36])
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
    this.onLoad();
  }


  onSearch() {
    this.spinnerService.start();
    this.part1 = this.partnumber.slice(0, 5)
    this.part2 = this.partnumber.slice(5, 11);
    const plant = this.selectedPlant.filter((item: any) => ((item.plant.toLowerCase() === this.plantDropdown?.toLowerCase()) || (item.plantcode.toLowerCase() === this.plantDropdown?.toLowerCase())))
    this.plantDropdown = plant[0]?.plantcode
    const searchPayload = {
      "PlantCode": this.plantDropdown === '(All)' ? this.resPlant : this.plantDropdown,
      "SupplierCode": this.suppliercode,
      "FamilyCode": this.familycode,
      "ModelCode": this.modelcode,
      "PartNumber1": this.part1,
      "PartNumber2": "",
      "Description": this.partdescription,
      "PkgStartDateFrom": this.fromdate === "null" ? '' : this.fromdate,
      "PkgStartDateTo": this.toDate,
      "Container": this.container ? this.container : '',
      "SpecialistCode": this.specialistcode,
      "PartOver": this.over,
      "PartUnder": this.under,
      "SupplierType": 0,
      "Unit": 0,
      "page": this.cPage,
      "limit": this.rpPage
    }
    this.selectOnload(searchPayload)
  }


  onLoad() {
    this.spinnerService.start();
    const updateObject = {
      "PlantCode":  this.resPlant,
      "SupplierCode": this.suppliercode,
      "FamilyCode": "",
      "ModelCode": "",
      "PartNumber1": "",
      "PartNumber2": "",
      "Description": "",
      "PkgStartDateFrom": "",
      "PkgStartDateTo": "",
      "Container": "",
      "SpecialistCode": this.specialistcode,
      "PartOver": "",
      "PartUnder": "",
      "SupplierType": 0,
      "Unit": 0,
      "page": this.cPage,
      "limit": this.rpPage
    }
    this.selectOnload(updateObject)
  }
  private selectOnload(payload: any) {
    this.trackingListService.selectPartBtnOnload(payload).subscribe({
      next: (res: any) => {
        this.part1 = this.partNumber.slice(0, 5)
        this.part2 = this.partNumber.slice(6, 11);
        this.spinnerService.stop();
        this.displayRowData = res.response?.data.result;
        this.rowCount = res.pagination?.TotalRecords;
        this.totalRecords = res.pagination?.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
      },

    })
  }
  selectSpecialist() {
    const dialogRef = this.dialog.open(PartListSelectComponent, {
      data: { spCode: this.specialistcode, all: this.resPlant }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistcode = result
    })
  }

  plant() {
    this.spinnerService.start();
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
    const obj = {
      // "supplierCode": [this.supplier],
      "SpecialistCode": this.spCode,
      "Type": 1
    }

    this.discrepancyListService.plant(obj).subscribe((response:any) => {
      this.resPlant = response.map((item: { plantcode: any; }) => item.plantcode)
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response]
      this.onLoad();

    })
  }


}

