<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Update PPF Reason</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div  id='err' class="alert alert-danger m-3" *ngIf="errorMessage">
    <span  >{{errorMessage}}</span>
    <button type="button" class="btn-close" (click)="close()"></button>
</div>

<div id='err' class="alert alert-success m-3" *ngIf="successMessage">
    <span  >{{successMessage}}</span>
</div>
<div class="row m-3 content-row pb-5">
    <div class="col-md-4 align-items-center px-0">
        <div class="row px-3">
            <div class="col-md-4 titleColumn">
                <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0">
                <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>

        <div class="row mt-4 w-100 mx-0" *ngIf="tdRequest">
            <mat-accordion hideToggle multi class="px-0">
                <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="roboto-bold me-0">REQUEST UPDATE PPF FROM SUPPLIER</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3 mx-1 heading-row">
                        <table class="table" aria-describedby="request-update-ppf">
                            <tbody class="d-flex">
                                <th class="col-md-4 titleColumn" id="title">
                                    <div *ngFor="let title of updatePpf1" class="contents ps-4 py-3">
                                        {{title}}
                                    </div>
                                </th>
                                <div class="col-md-8 contentColumn rowClass">
                                    <div class="ps-4 py-2 d-block">
                                        <mat-select id="plant" class="mat-sel py-2" [(value)]="reqSelectedVal" disabled
                                            disableOptionCentering [(ngModel)]="reqSelectedVal">
                                            <mat-option *ngFor="let reason of reasonWhatDropdown" [value]="reason.code">
                                                {{reason.checkitem}}
                                            </mat-option>
                                        </mat-select> <br />
                                        <textarea style="width:100%" class="mt-2" disabled  [(ngModel)]="reasonWhat2"></textarea>                                    </div>
                                    <div class="ps-4 py-2 d-block">
                                        <mat-select id="selectedValue1" class="mat-sel py-2" disabled
                                            [(ngModel)]="reqSelectedVal1">
                                            <mat-option *ngFor="let reason of reasonWhyDropdown" [value]="reason.code">
                                                {{reason.checkitem}}
                                            </mat-option>
                                        </mat-select> <br />
                                        <textarea style="width:100%" class="mt-2" disabled  [(ngModel)]="reasonWhy2"></textarea>
                                    </div>
                                </div>
                            </tbody>
                        </table>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="col-md-4">
        <div class="row w-100 m-0">
            <mat-accordion hideToggle multi class="p-0">
                <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="roboto-bold me-0">UPDATE PART NUMBER</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3 mx-1 heading-row">
                        <table class="table" aria-describedby="update-partnumber">
                            <tbody class="d-flex">
                                <th class="col-md-4 titleColumn" id="part">
                                    <div class="contents2 ps-4 py-3">
                                        Current Part
                                    </div>
                                    <div class="contents2 ps-4 py-3">
                                        New Part<small>*</small>
                                    </div>
                                </th>
                                <div class="col-md-8 contentColumn rowClass2">
                                    <div class="ps-4 py-2 d-flex align-items-center">
                                        {{result?.partnumber}}
                                    </div>
                                    <div class="ps-4 py-2">
                                        <input type="text" [(ngModel)]="newPart" [ngClass]="{'error-border': divReq}"  maxlength="10" (keyup)="changediv()">
                                        <div *ngIf="divReq">
                                           <small>partnumber is required</small> 
                                        </div>
                                    </div>
                                </div>
                            </tbody>
                        </table>

                    </div>
                    <div class="row justify-content-end px-3">
                        <button class="vpacs-btn-table roboto-medium col-md-3" (click)="update()"> UPDATE
                        </button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="row mt-5 px-3" *ngIf="tdSupDate">
            <div class="col-md-4 titleColumn px-0 d-flex align-items-center">
                <div class="contents ps-4 py-2 d-flex align-items-center">
                    Supplier PSD
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0  d-flex align-items-center">
                <div class="d-flex align-items-center me-2 ps-4 py-2">
                    <input type="date" [(ngModel)]="supplierPsd" id="supplierPsd" *ngIf="!supplierPsd" (keydown)="onKeyDown($event,'supplierPsd')">
                    <input type="text" [(ngModel)]="supplierPsd" *ngIf="supplierPsd">
                </div>
            </div>
        </div>

        <div class="row justify-content-end px-3 mt-3" *ngIf="btnDisplayPPF">
            <button class="vpacs-btn-table roboto-medium col-md-3" (click) = "displayPPF()"> DISPLAY PPF
            </button>
        </div>
    </div>
    <div class="col-md-4">
        <div class="row" *ngIf="tblPPF">
            <mat-accordion hideToggle multi class="pe-0">
                <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="roboto-bold me-0">UPDATE PPF</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3 mx-1 heading-row">
                        <table class="table" aria-describedby="update-ppf">
                            <tbody class="d-flex">
                                <th class="col-md-4 titleColumn" id="title">
                                    <div *ngFor="let title of updatePpf1" class="contents ps-4 py-3">
                                        {{title}}
                                    </div>
                                </th>
                                <div class="col-md-8 contentColumn rowClass" >
                                    <div class="ps-4 py-2 d-block">
                                        <mat-select id="plant" class="mat-sel py-2" [(value)]="selectedValue"
                                            disableOptionCentering [(ngModel)]="selectedValue">
                                            <mat-option *ngFor="let reason of reasonWhatDropdown" [value]="reason.code">
                                                {{reason.checkitem}}
                                            </mat-option>
                                        </mat-select> <br />
                                        <textarea style="width:100%" class="mt-2" [(ngModel)]="reasonWhat1"></textarea>
                                    </div>
                                    <div class="ps-4 py-2 d-block">
                                        <mat-select id="selectedValue1" class="mat-sel py-2"
                                            [(ngModel)]="selectedValue1">
                                            <mat-option *ngFor="let reason of reasonWhyDropdown" [value]="reason.code">
                                                {{reason.checkitem}}
                                            </mat-option>
                                        </mat-select> <br />
                                        <textarea style="width:100%"class="mt-2" [(ngModel)]="reasonWhy1"></textarea>
                                    </div>
                                </div>
                            </tbody>
                        </table>

                    </div>
                    <div class="row justify-content-end px-3" *ngIf="btnAuthorize">
                        <button class="vpacs-btn-table roboto-medium col-md-3" (click)="authorize()"> OK
                        </button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="row mt-3 content-row ms-0 w-100" *ngIf="tblRejectReason">
            <div class="col-md-12 d-flex p-0">
                <label class="col-3">Reject Reason</label>
                <textarea rows="3" class="comment col-9 " [(ngModel)] = "rejReason"></textarea>
            </div>
        </div>
        <div class="row mt-3 content-row ms-0 w-100" *ngIf="tblCheck">
            <div class="col-md-12 justify-content-end d-flex ps-0">
                <label class="checkcontainer mb-0"> Need To Make Auth
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="cancel()"> BACK </button>
    <button class="cancel me-3 roboto-bold" (click)="reject()" *ngIf="btnReject"> REJECT </button>
    <button class="black-button me-3 roboto-medium" (click)="confirmDate()" *ngIf ="btnApprove">APPROVE</button>
</div>