import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { PackageAuthorizationService } from 'src/app/services/pkg-authorization.service';
import { MessagePopupToastComponent } from '../message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'pkg-authorization-submit-btn-cell-render',
	template: 
    `<div class="d-flex align-items-center" *ngIf = "this.params.SUBMITFLAG">
        <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center " 
          style="height:30px; font-size: 21px"
          title="Submit" (click)="goToSubmitBtn()">upload
        </mat-icon>
    <div>`,
})

export class PackageAuthorizationSubmitBtnCellRender implements ICellRendererAngularComp {
    
    constructor(
      public pkgAuthService: PackageAuthorizationService, 
      public router:Router,
      public message:MatSnackBar){}
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	public params: any;

	agInit(params: any): void {
		this.params = params.data;
	}

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  goToSubmitBtn(): void {
      const body:any = {}
      if(localStorage.getItem('toyotaGuid')){
        body['guid'] = localStorage.getItem('toyotaGuid');
      }
      this.pkgAuthService.submitPkgAuthorizationData(this.params.AUTHNUMBER, this.params.AUTHSTATUS,body).subscribe({
        next: (_response:any) =>{
            this.openMessageBox('Submitted Successfully','success')
        },
        error: (_error:any) =>{
            this.openMessageBox('Error occured while submitting','error')
        },
        complete: ()=>{ 
            this.router.navigateByUrl('/', {skipLocationChange : true}).then(()=>{
                this.router.navigate(['/pkg-authorization'])
            })  
        }
        })      
  }
  
  }