import { Component, OnInit } from '@angular/core'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface'
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { Router } from '@angular/router'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-backup-temp-container',
  templateUrl: './backup-temp-container.component.html',
  styleUrls: ['./backup-temp-container.component.scss'],
})
export class BackupTempContainerComponent implements OnInit {
  panelOpenState = true
  panelOpenState2 = true
  panelOpenState3 = true
  panelOpenState4 = true
  gridApi: any
  icons: any
  public domLayout: any = ''
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  defaultColDef: any
  rowClassRules: any
  partInfo: any
  partInfoContents: any
  backupSpecification: any

  errorMessage!: ErrorMessage
  showSuccess = false
  successMessage = ''
  backUpResult: any
  ppfResult: any
  imagePath: any
  convertedImg: any
  backupData: any
  reqChangesArr: any[] = []
  reasonReqArr: any[] = []
  reqChange: any[] = []
  reqChangeText = ''
  reasonText = ''
  approveVisible = true
  rejectVisible = true
  reason = true
  requested = true
  spCode: any
  rate:any;
  res: any
  userInfo: any
  
  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly spinner: NgxUiLoaderService,
    private readonly router: Router,
    public backUp: PartListService,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      autoHeight: true,
      sortable: false,
      unSortIcon: false,
      headerClass: 'child-header-color no-border',
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.spinner.start()
    this.backupData = JSON.parse(sessionStorage.getItem('backUp') || '{}')
      this.state.userInfoState.subscribe({
        next: (res: any) => {
          this.userInfo = res
          if(res){
            this.backupOnLoad()
          }
        },
        error: (_err: any) => {
          this.spinner.stop()
        }
      })

    this.reqChangesArr = [
      { code: 0, description: 'Container Type', checked: false },
      { code: 1, description: 'Qty. per Container', checked: false },
      { code: 2, description: 'Container Size', checked: false },
      { code: 3, description: 'Dunnage', checked: false },
      { code: 4, description: 'Part Orientation', checked: false },
      { code: 5, description: 'other', checked: false },
    ]
    this.reasonReqArr = [
      { code: 0, description: 'Safety', checked: false },
      { code: 1, description: 'Quality', checked: false },
      { code: 2, description: 'Workability', checked: false },
      { code: 3, description: 'Over Weight', checked: false },
      { code: 4, description: 'Poor Efficiency', checked: false },
      { code: 5, description: 'Other', checked: false },
    ]

    this.partInfo = [
      { name: 'Plant', description: 'plantCode' },
      { name: 'Family Code', description: 'familyCode' },
      { name: 'Model Code', description: 'modelCode' },
      { name: 'Part Number', description: 'partNumber' },
      { name: 'Supplier', description: 'supplier' },
      { name: 'Status', description: 'supplier' },

      'Plant',
      'Family Code',
      'Model Code',
      'Part Number',
      'Supplier',
      'Status',
    ]

    this.partInfoContents = [
      'NUMMI',
      '593L',
      '593L',
      '90119A029100',
      'INFST',
      'Need Approval',
    ]

  }

  help() {
    this.router.navigate(['./master-help', 2, 'Tracking', 39])
  }

  backupOnLoad() {
    const payload={
      "plantCode": this.backupData.plantCode,
      "partNumber": this.backupData.partNumber,
      "supplierCode": this.backupData.supplierCode,
      "pkgStartDate": this.backupData.pkgStartDate,
      "userPlantCode": this.backupData.userPlantCode,
      "userUnit": this.backupData.userUnit,
      "modelCode": this.backupData.modelCode,
      "familyCode": this.backupData.familyCode,
    }
    this.trackingListService.backUpOnLoad(payload).subscribe({
      next: (res: any) => {
        this.res=res;
        this.backUpResult = res.BackupSpec[0]
        this.ppfResult = res.PPFSpec[0]
        this.imagePath = res.Image
        this.rate=res?.rate
        this.convertedImg = 'data:image/jpg;base64,' + this.imagePath
        
        if (this.backUpResult.approvalstatus !== '0') {
          this.approveVisible = false
          this.rejectVisible = false
          this.reason = false
          this.requested = false
        }
        let isSpec = false
        this.spCode = res.specialistCode[0].specialistcode
        
        if (this.spCode !== this.userInfo?.info?.specialistcode) {
          for (let i = 0; i < this.userInfo?.role?.backupDetails?.length; i++) {
            if (this.spCode === this.userInfo?.role.backupDetails[i]?.specialistcode) {
              isSpec = true
            }
          }
        } else {
          isSpec = true
        }

        if (!isSpec) {
          this.approveVisible = false
          this.rejectVisible = false
          this.reason = false
          this.requested = false
        }
        this.spinner.stop()
      },
      error: () => {
        this.spinner.stop()
      },
    })
  }

  getReq() {
    return this.reqChangesArr.filter((item) => item.checked).map((e) => e.code)
  }

  getReason() {
    return this.reasonReqArr.filter((item) => item.checked).map((e) => e.code)
  }

  approve(val: string) {
    const data = {
      RequestChecks: this.getReq(),
      SupplierCode: this.backupData?.supplierCode,
      ReasonChecks: this.getReason(),
      PlantCode: this.backupData?.plantCode,
      PartNumber: this.backupData?.partNumber,
      PkgStartDate: this.backupData?.pkgStartDate,
      RequestMessage: this.reqChangeText,
      ReasonMessage: this.reasonText,
      ApprovalStatus: val === 'A' ? 1 : 2,
    }

    this.spinner.start()
    this.showSuccess = false
    this.trackingListService.backUpApproveOrReject(data).subscribe({
      next: (res: any) => {
        this.spinner.stop()
        if(res.status === "SUCCESS"){
          this.successMessage = res.message
          this.showSuccess = true
          this.router.navigateByUrl('/tracking-list')
        }
      },
      error: () => {
        this.spinner.stop()
      },
    })
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
  }

  getResult(result: any): any {
    switch (+result) {
      case -1:
        return 'Working'
      case 0:
        return 'Need Approval'
      case 1:
        return 'Approved Backup'
      case 2:
        return 'Rejected Backup'

      default:
        return ''
    }
  }

  cancel() {
    let uri = ''
    if (this.router.url.includes('part-list')) {
      uri = '/part-list'
    } else if (this.router.url.includes('tracking-list')) {
      uri = '/tracking-list'
    }
    this.router.navigateByUrl(uri)
  }
}
