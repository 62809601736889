import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExchangeRateBtnCellRender } from '../../../internal-Vpacs-gridrender/master-maintenance/exchange-rate-btn-cell-render';
import { AddExchangeComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/exchange-rate/add-exchange/add-exchange.component';
import { ExchangeRateService } from 'src/app/internal-vpacs/services-internal/master-maintenance/exchange-rate.service';
import { ExchangeRate } from 'src/app/internal-vpacs/model/exchangerate';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  displayRowData: ExchangeRate[] = [];
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  icons: {};
  paginationPageSize = 5
  isAccessible = false;
  type: any = "0";
  types: any[] = [{ text: "CANADA", value: "0" }, { text: "MEXICO", value: "1" }]
  isSearchVisible = false;
  userInfo: any;
  isButtonAddActive = false;
  errorList: any[] = [];


  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal: any = 1;
  dateFormat = 'MM/dd/yyyy';
  dialogRef: MatDialogRef<AddExchangeComponent> | any = null;

  constructor(private readonly router: Router, public route: ActivatedRoute, public dialog: MatDialog,
    public exchangerateService: ExchangeRateService, private readonly _state: ExportStateService,
    private readonly _spinner: NgxUiLoaderService,) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      cellStyle: {
        textAlign: 'center'
      }
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {    
    this.columnDefs = [

      {
        headerName: "Country", field: "countryid", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true
      },
      {
        headerName: "From Date", field: "fromdate", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true
      },
      {
        headerName: "To Date", field: "todate", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true
      },
      {
        headerName: "Exchange Rate", field: "exchangerate", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true
      },
      {
        headerName: "UPDATE", field: "update", sortable: false, unSortIcon: true, autoHeight: true,
        cellRendererFramework: ExchangeRateBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.dialogOpen(field, "UPDATE");
          },

        },
      },
      {
        headerName: "DELETE", field: "delete", sortable: true, autoHeight: true, unSortIcon: false,
        cellRendererFramework: ExchangeRateBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.dialogOpen(field, "DELETE");
          },

        },
      },

    ]
    this.assignUserRole();
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  
  getCurrentPage(): boolean {
    this.currentPageNo = this.pageNumber;
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi.sizeColumnsToFit();
    this.apiCall(this.paginationPageSize);
  }
  apiCall(_paginationPageSize: number) {
    this.rowCount = this.displayRowData.length;
    this.gridApi?.redrawRows();
  }

  private assignUserRole() {
    this._state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {          
          this.userInfo = res;
          if (this.userInfo.role.p_userview){
            this.loadExchangeRate({ CountryID: this.type });
          }else{
            this.errorList = ['Authority Error'];
          }
          this.isButtonAddActive = this.userInfo.role.p_usersave 
        }
      },
      error: (_err: any) => {
        this._spinner.stop();
      },
    });
  }

  loadExchangeRate(param: any) {
    const userSave = this.userInfo.role.p_usersave;
    const userDelete = this.userInfo.role.p_userdelete;
    this._spinner.start();
    this.exchangerateService.getExchangeRate(param).subscribe({
      next: data => {
        data.result.forEach((obj, _index) => {          
          if (obj.countryid === "0"){
            obj.countryid = "CANADA"
          }else if (obj.countryid === "1"){
            obj.countryid = "MEXICO"
          }
          if (Number(obj.exchangerate) !== Math.floor(Number(obj.exchangerate))){
            obj.exchangerate = Number(obj.exchangerate).toFixed(2);
          }
          obj.p_usersave = userSave;
          obj.p_userdelete = userDelete;
        });

        this.displayRowData = data.result;
        this.isSearchVisible = data.searchVisible;
        this._spinner.stop();
      },
      error: _err => {
        this._spinner.stop();
      }
    });

  }


  onSearch() {
    if (this.userInfo.role.p_userview){
      this.loadExchangeRate({ CountryID: this.type });
    }
  }

  addPackgeType() {
    if (!this.userInfo.role.p_usersave){ return};

    this.dialogRef = this.dialog.open(AddExchangeComponent, {
       width: '30%',
      data: { mode: 'ADD' },
      disableClose: true,
    })

    this.dialogRef.afterClosed().subscribe((result: any) => {      
      if (result.msg === "SUCCESS"){
        this.onSearch();
      }
    })
  }

  dialogOpen(field: any, mode : string) {
    this.dialogRef = this.dialog.open(AddExchangeComponent, {
      width: '30%',
      data: { mode, field },
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result.msg === "SUCCESS"){
        this.onSearch();
      }
    })
  }

  help(){
    this.router.navigate(['./master-help',5,"Master Maintenance",140])
  }
}
