import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { validate } from "../internal-vpacs/internal-vpacs/tracking/part-list/service-parts/servicepartsCalculations";
export class ConstantsService {	
	public contType = 'application/json';
	public httpOptions:any = new HttpHeaders({ 
		'Content-Type': this.contType,
		'Accept': '*/*',
		'x-api-key': environment.API_CALL,
	});

	public internalHttpOptions:any = new HttpHeaders({ 
		'Content-Type': this.contType,
		'Accept': '*/*',
		'x-api-key': environment.API_CALL,
		// 'x-internal' : 'true'
	});

	public httpCustomOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json, application/x-www-form-urlencoded;charset=ISO-8859-15',
			'Accept': this.contType,
			'x-api-key': environment.API_CALL,
		})
	};

	public EXTERNAL = '';
	public INTERNAL = '';	
	public MASTERCOST = '';
	public TRACKING = '';
	VPACSHOMEURL: string;
	public VPACSSystemAdminStage = "VPACS.SystemAdmin-STAGE";
	public VPACSSystemAdminDev = "VPACS.SystemAdmin-DEV";
	public VPACSSystemAdminProd = "VPACS.SystemAdmin";
	public devTm = "VPACS.TeamMembers-DEV";
	public stageTm = "VPACS.TeamMembers-STAGE";
	public stageSa = "VPACS.SupplierAdmin-STAGE";
	public devSa = "VPACS.SupplierAdmin-DEV";
	public devSp = "VPACS.Supplier-DEV";
	public stageSp = "VPACS.Supplier-STAGE";
	public prodTm = "VPACS.TeamMembers";
	public prodSa = "VPACS.SupplierAdmin";
	public prodSp = "VPACS.Supplier";
	public devUser = "VPACS.Users-DEV";
	public qaUser = "VPACS.Users-STAGE";
	public prodUser = "VPACS.Users";
	public allRoles: string[];
	public tmmcPlant="21TMC"
	adminSystemRoles: string[];
	adminSupRoles: string[];
	allExceptSystemAdminRoles: string[];
	prodOrder: string[];
	stageOrder: string[];
	devOrder: string[];
	supplierRoles: string[];
	//interanal-possible-roles
	public intStage = "VPACS.Internal-STAGE";
	public intDev = "VPACS.Internal-DEV";
	public intProd = "VPACS.Internal";
	allInternal: string[];
	vpacsAll :string[] = [];
	disableBackupPlants = ['50SER','51ACC']
	rolesUser = ['Packaging Specialist','TMMC PKG Specialist','Administrator','NAMC Administrator']
	newPackageDrawAllowedTypes = ['dwg', 'model', 'CATPart', 'CATProduct', 'cgr', 'pdf'];
	newPackagePhotoAllowedTypes = ['jpg', 'gif', 'xlsx', 'doc', 'pdf'];
	newPackageDrawErrorFile = 'File size must be less than 2mb. Extension must be .dwg or .model or .CATPart or .CATProduct or .cgr or .pdf.';
	newPackagePhotoErrorFile = 'File size must be less than 2mb. Extension must be .jpg or .gif or .xlsx or .doc or .pdf.';
	newPackageFileSizeLimit = 2048;
	// package conversion constants
	public beforePkgCodeEqualAfterPkgCode="Before package code is equal to After package code";
	public PkgCodesMustSelect="Before package & After package  must be selected before conversion"
	constructor() {	
		this.EXTERNAL = environment.EXTERNAL;
		this.INTERNAL = environment.INTERNAL;	
		this.MASTERCOST = environment.MASTERCOST;
		this.TRACKING = environment.TRACKING;
		this.VPACSHOMEURL = environment.VPACSHOMEURL;
		//External
		this.allRoles = [
			this.VPACSSystemAdminDev,this.VPACSSystemAdminStage,this.VPACSSystemAdminProd,
			this.devTm,this.stageTm,this.prodTm,
			this.devSa,this.stageSa,this.prodSa,
			this.devSp,this.stageSp,this.prodSp
		];
		this.vpacsAll = [
			this.intDev,this.intStage,this.intProd,
			this.VPACSSystemAdminDev,this.VPACSSystemAdminStage,this.VPACSSystemAdminProd,
			this.devSa,this.stageSa,this.prodSa,
			this.devSp,this.stageSp,this.prodSp,
			this.devTm,this.stageTm,this.prodTm,
		];
		this.adminSystemRoles = [
			this.VPACSSystemAdminDev,this.VPACSSystemAdminStage,this.VPACSSystemAdminProd
		];
		this.adminSupRoles = [
			this.VPACSSystemAdminDev,this.VPACSSystemAdminStage,this.VPACSSystemAdminProd,
			this.devSa,this.stageSa,this.prodSa,
		];
		this.allExceptSystemAdminRoles = [
			this.devTm,this.stageTm,this.prodTm,
			this.devSa,this.stageSa,this.prodSa,
			this.devSp,this.stageSp,this.prodSp
		];
		//Internal
		this.allInternal = [this.intDev,this.intStage,this.intProd];
		this.prodOrder = [this.VPACSSystemAdminProd,this.prodSa,this.prodSp,this.prodUser,this.prodTm];
		this.stageOrder = [this.VPACSSystemAdminStage,this.stageSa,this.stageSp,this.qaUser,this.stageTm];
		this.devOrder = [this.VPACSSystemAdminDev,this.devSa,this.devSp,this.devUser,this.devTm];
		this.supplierRoles = [this.devSp,this.stageSp,this.prodSa]		
	}
	getTypeFromPlantCode(plantCode: string): string {
		switch (plantCode) {
			case '50SER':
				return 'SERVICE';
			case '51ACC':
				return 'ASSY';
			default:
				return 'MFG';
		}
	}

	checkRolesUser(data:any){
		return this.rolesUser.some((item:any)=> data.includes(item));
	}

	partTypeDropdown(pValue: string) {
		switch (pValue) {
			case 'Service Parts PDF':
				return [
					{ name: 'SERVICE PART', value: 'SERVICE PART' },
					{ name: 'CAMPAIGN PART', value: 'CAMPAIGN PART' }
				];
			case 'Accessory Parts PDF':
				return [
					{ name: 'ACCY PPO', value: 'ACCY PPO' },
					{ name: 'ACCY DIO', value: 'ACCY DIO' },
					{ name: 'ACCY PPO & DIO', value: 'ACCY PPO & DIO' },
					{ name: 'CAMPAIGN PART', value: 'CAMPAIGN PART' },
				];
			default:
				return [];
		}
	}
	setGrid2(unitTable: any[], data: any): void {
		// dynamic rows set non-Editable from row0 to row6
		for (let i = 0; i < 6; i++) {
			unitTable[i].col1 = validate(data[i]?.packagingtype);
			unitTable[i].col2 = validate(data[i]?.materialdescription);
			unitTable[i].col3 = validate(data[i]?.weight);
			unitTable[i].col4 = validate(data[i]?.length);
			unitTable[i].col5 = validate(data[i]?.width);
			unitTable[i].col6 = validate(data[i]?.height);
			unitTable[i].col7 = validate(data[i]?.unitcost);
			unitTable[i].col8 = validate(data[i]?.labortime);
			unitTable[i].col9 = validate(data[i]?.qty);
			unitTable[i].col10 = validate(data[i]?.totalcost);
		}
		//row7
		unitTable[6].col2 = validate(data[6]?.unitcost);
		unitTable[6].col3 = validate(data[6]?.labortime);
		unitTable[6].col4 = validate(data[6]?.qty);
		unitTable[6].col5 = validate(data[6]?.totalcost);
		//row8
		unitTable[7].col2 = validate(data[7]?.unitcost);
		unitTable[7].col3 = validate(data[7]?.labortime);
		unitTable[7].col4 = validate(data[7]?.qty);
		unitTable[7].col5 = validate(data[7]?.totalcost);
		//row9
		unitTable[8].col1 = validate(data[8]?.unitcost);
		unitTable[8].col2 = validate(data[8]?.labortime);
		unitTable[8].col3 = validate(data[8]?.qty);
		unitTable[8].col4 = validate(data[8]?.totalcost);
		//row10
		unitTable[9].col1 = validate(data[9]?.unitcost);
		unitTable[9].col2 = validate(data[9]?.labortime);
		unitTable[9].col3 = validate(data[9]?.qty);
		unitTable[9].col4 = validate(data[9]?.totalcost);
		//row11
		unitTable[10].col1 = validate(data[10]?.unitcost);
		unitTable[10].col2 = validate(data[10]?.labortime);
		unitTable[10].col3 = validate(data[10]?.qty);
		unitTable[10].col4 = validate(data[10]?.totalcost);
	}
	setNonEditableGrid3(intermediateTable: any[], data: any): void {
		// dynamic rows set-nonEditable from row0 to row4
		for (let i = 0; i < 4; i++) {
			intermediateTable[i].col1 = validate(data[i]?.packagingtype);
			intermediateTable[i].col2 = validate(data[i]?.materialdescription);
			intermediateTable[i].col3 = validate(data[i]?.weight);
			intermediateTable[i].col4 = validate(data[i]?.length);
			intermediateTable[i].col5 = validate(data[i]?.width);
			intermediateTable[i].col6 = validate(data[i]?.height);
			intermediateTable[i].col7 = validate(data[i]?.unitcost);
			intermediateTable[i].col8 = validate(data[i]?.labortime);
			intermediateTable[i].col9 = validate(data[i]?.qty);
			intermediateTable[i].col10 = validate(data[i]?.totalcost);
		}
		//row5
		intermediateTable[4].col2 = validate(data[4]?.unitcost);
		intermediateTable[4].col3 = validate(data[4]?.labortime);
		intermediateTable[4].col4 = validate(data[4]?.qty);
		intermediateTable[4].col5 = validate(data[4]?.totalcost);
	}
	setNonEditableGrid4(masterTable: any[], data: any): void {
		//dynamic row non-editable fetch from row0 to row4
		for (let i = 0; i < 5; i++) {
			masterTable[i].col1 = validate(data[i]?.packagingtype);
			masterTable[i].col2 = validate(data[i]?.materialdescription);
			masterTable[i].col3 = validate(data[i]?.weight);
			masterTable[i].col4 = validate(data[i]?.length);
			masterTable[i].col5 = validate(data[i]?.width);
			masterTable[i].col6 = validate(data[i]?.height);
			masterTable[i].col7 = validate(data[i]?.unitcost);
			masterTable[i].col8 = validate(data[i]?.labortime);
			masterTable[i].col9 = validate(data[i]?.qty);
			masterTable[i].col10 = validate(data[i]?.totalcost);
		}
		//row6  
		masterTable[5].col2 = validate(data[5]?.unitcost);
		masterTable[5].col3 = validate(data[5]?.labortime);
		masterTable[5].col4 = validate(data[5]?.qty);
		masterTable[5].col5 = validate(data[5]?.totalcost);
		//row7
		masterTable[6].col2 = validate(data[6]?.unitcost);
		masterTable[6].col3 = validate(data[6]?.labortime);
		masterTable[6].col4 = validate(data[6]?.qty);
		masterTable[6].col5 = validate(data[6]?.totalcost);
	}
	setNonEditableGrid5(palletTable: any[], data: any): void {
		//dynamic row non-editable fetch from row0 to row5
		for (let i = 0; i < 6; i++) {
			palletTable[i].col1 = validate(data[i]?.packagingtype);
			palletTable[i].col2 = validate(data[i]?.materialdescription);
			palletTable[i].col3 = validate(data[i]?.weight);
			palletTable[i].col4 = validate(data[i]?.length);
			palletTable[i].col5 = validate(data[i]?.width);
			palletTable[i].col6 = validate(data[i]?.height);
			palletTable[i].col7 = validate(data[i]?.unitcost);
			palletTable[i].col8 = validate(data[i]?.labortime);
			palletTable[i].col9 = validate(data[i]?.qty);
			palletTable[i].col10 = validate(data[i]?.totalcost);
		}
		//row7
		palletTable[6].col2 = validate(data[6]?.unitcost);
		palletTable[6].col3 = validate(data[6]?.labortime);
		palletTable[6].col4 = validate(data[6]?.qty);
		palletTable[6].col5 = validate(data[6]?.totalcost);
		//row8
		palletTable[7].col2 = validate(data[7]?.unitcost);
		palletTable[7].col3 = validate(data[7]?.labortime);
		palletTable[7].col4 = validate(data[7]?.qty);
		palletTable[7].col5 = validate(data[7]?.totalcost);
	}
	plantCodeValue2(plantType: any) {
		let pValue;
		switch (plantType) {
			case 'NA':
				pValue = 0;
				break;
			case 'CAN':
				pValue = 1;
				break;
			case 'NUMMI':
				pValue = 2;
				break;
			default:
				pValue = 0;
		}
		return pValue;
	} 

	nullToDashFormatter(params: any) {
		if (typeof params.value === 'undefined' || params.value === undefined || params.value === null || params.value === "") {
		  return '-';
		} else {
		  return params?.value;
		}
	  }
	
	  cellStyle(_params: any) {
		return {
		  height: '40px',
		  'text-overflow': 'ellipsis',
		  'white-space': 'normal',
		  'word-break': 'keep-all',
		   overflow: 'hidden',
		  'margin-top': 'auto',
		  'margin-bottom': 'auto',
		  'vertical-align': 'middle',
		  position: 'absolute',
		};
	  }

	  cellStyleColumn(_params: any) {
		return {
		  'display': 'inline-block',
		  'text-overflow': 'ellipsis',
		  'white-space': 'nowrap',
		  'overflow': 'hidden',
		  'vertical-align': 'middle',
		  'width' : _params.colDef.maxWidth || _params.colDef.minWidth || _params.colDef.width,
		  'padding-left' : '5px',
		};
	  }

	  findSpecialCharsExternal(obj:any,columns:any[]){
		function hasSpecialSymbols(str:string) {
			return /["$%'\{}*_]/.test(str);
		}
		const fieldsWithSymbols:any = [];	 
		obj.comments.forEach((element:any,index:number) => {
			if (hasSpecialSymbols(element)) {
				fieldsWithSymbols.push(`${columns[index]} has prohibited character.["$%'\{}*_]`);
			}
		});
		return fieldsWithSymbols;
	  }

	  findFieldsWithSpecialSymbols(obj:any) {
		const allowedFields = ['ContainerComment','Dunnage1Comment','Dunnage2Comment','KHolderComment','OtherPkgComment','PalletComment','TopCapComment','WrapComment']
		const fieldsWithSymbols:any = [];	 
		function hasSpecialSymbols(str:string) {
			return /["$%'\{}*_]/.test(str);
		}	 
		function traverse(value:any, path:any) {
			if (typeof value === 'string') {
				if(path && allowedFields.includes(path)){
					if (hasSpecialSymbols(value)) {
						fieldsWithSymbols.push(`${path} has prohibited character.["$%'\{}*_]`);
					}
				}
			} else if (typeof value === 'object' && value !== null) {
				for (const key in value) {
					if (value.hasOwnProperty(key)) {
						if(path === 'comments' || !path.includes('PlantList')){
							const newPath = path ? `${path}.${key}` : key;
							if (hasSpecialSymbols(value)) {
								fieldsWithSymbols.push(`${newPath} has prohibited character.["$%'\{}*_]`);
							}
							traverse(value[key], newPath);
						}
					}
				}
			}
		}
	 	traverse(obj, '');
		return fieldsWithSymbols;
	}

	replacePlaceholdersWithComments(errorMessages:any[], comments:any) {
		return errorMessages.map((message, index) => {
			return message.replace(`comments.${index}`, comments[index]);
		});
	}

	checkFilenameHasMoreThanFifyChars(text:string){
		if(text.length > 50){
			return ['Failed to upload : Filename, no more than 50 characters allowed.'];
		}else{
			return [];
		}
	}
}

