import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthSelectComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/auth-select/auth-select.component';
import { HistoryBtnauthCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/history-btn-auth-cell-render';
import { ShipmentRackCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/rack-btn-cell-render';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { DatePipe } from '@angular/common';
import { ShipmentCompanyComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/shipment-company/shipment-company.component';
import { DeleteAuthBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/delete-auth-btn-cell-render';
import { ShipmentHistoryComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/shipment-history/shipment-history.component';
import { InputBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/input-btn-cell-render';


@Component({
  selector: 'app-shipmentlist-details',
  templateUrl: './shipmentlist-details.component.html',
  styleUrls: ['./shipmentlist-details.component.scss']
})
export class ShipmentListDetailsComponent implements OnInit {

  shipmentUrl='/shipment-list'
  lpcodes = []
  selLP = ''
  timeArray = Array(24);
  public rowData: any;
  rejectFlag = true;
  approveFlag = true;
  rowCount = 0;
  saveFlag = true;
  sendFlag = true;
  rp = false;
  shippingClassdis = true;
  cancelShipFlag = true;
  sendConfirmFlag = true;
  //ag-grid-specs
  gridApi: any;
  cancelRejectFlag = true;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef={};
  icons: { sortUnSort: string; };
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  detailPage: any;
  result: any;
  ShipNumber: any;
  res: any;
  companySelectDisabled = true;
  companydis1 = true;
  addressdis1 = true;

  userInfo: any;
  rdoReceiverSup = false;
  rdoReceiverCont = false;
  rdoReceiverDun1 = false;
  rdoReceiverPlant = false;
  addflag = false;

  Weight = '0.0000';
  numberOfPallet = 0;
  lp = '';
  currency = ('USD');
  chknoshipment: any
  shipmentDate:any = '';
  receivedDate:any = '';
  gridColumnApi: any;
  actualCost = '';
  shipmentDatedis = true
  receivedDatedis = true
  actualCostdis = true
  currencydis = true
  pickupDate :any= '';
  receiver = '';
  pickupTime = '0';
  pickupdatedis = true
  pickuptimedis = true
  deliveryDate:any = ''

  deliveryTime = '0'
  deliveryDatedis = true
  deliveryTimedis = true
  carrier = '';
  trackingNumber = '';
  costEstimate = '';
  currency2 = 'USD'
  carrierdis = true
  trackingNumberdis = true
  costEstimatedis = true
  currencydis2 = true
  company = '';
  company2 = ''
  contactPerson = ''
  contactPhone = '';
  contactEmail = '';
  contactAddress = '';
  date :any= '';
  windowTimeHH = '0';
  windowTimeMM = '0';
  contactPersondis1 = true
  contactPhonedis1 = true
  contactEmaildis1 = true
  savebtn = true;
  sendbtn = true;
  approvebtn = true;
  rejectbtn = true;
  rejectcancelbtn = true;
  shipcancelbtn = true;
  hidRecvPlantCode: any;
  datedis1 = true
  windowTimeHHdis1 = true
  windowTimeMMdis1 = true
  contactPerson2 = ''
  contactPerson21 = ''
  contactPhone2 = '';
  contactEmail2 = '';
  contactAddress2 = '';
  date2:any = '';
  windowTimeHH2 = ('0');
  windowTimeMM2 = '0';
  contactPersondis2 = true
  contactPersondis23 = true
  contactPhonedis2 = true
  contactPhonedis23 = true
  contactEmaildis2 = true
  contactEmaildis23 = true
  contactAddressdis2 = true
  datedis2 = true
  addressdis2 = true
  companydis2 = true
  windowTimeHHdis2 = true
  windowTimeMMdis2 = true
  shippingClass = '';
  hidShipPlantCode: any;

  disabled = false;
  flag: any;
  selected: string = 'supplier' || 'contfab' || 'dun1fab' || 'namc';
  isShipmentCancelled: any;
  lptype: any;
  lpplantcode: any;
  lpdocktype: any;
  lpsupcode: any;
  lpdockcode: any;
  lpfabcode: any;
  shiptype: any;
  shipdockcode: any;
  shipfabcode: any;
  shipsubcode: any;
  recvtype: any;
  recvdockcode: any;
  recvfabcode: any;
  recvsupcode: any;
  cost: any;
  status: any;
  recvemail: any='';
  recvperson: any='';
  recvphone: any='';
  authNumber: any;
  haveAccess: any
  errorList: any[] = [];
  successMessage: string[] = [];
  haveAddAccess: any;
  constructor(
    private readonly spinnerService: NgxUiLoaderService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public route: ActivatedRoute,
    public shipmentListService: ShipmentListService,
    public router: Router,
    protected state: ExportStateService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      flex:1,
      autoHeight: true,
      suppressHorizontalScroll: false,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.detailPage = params?.shipmentNumber
      this.flag = params?.flag
      this.state.userInfoState.subscribe({
        next: (res: any) => {
          if (res) {
            this.userInfo = res?.info;
            this.haveAccess = res.role?.p_trackingview;
            this.haveAddAccess = res.role?.p_trackingsave;
            const { p_specialistcode, p_plantcode, unit } = res.info;
            if (p_specialistcode && p_plantcode && unit) {
              if (this.haveAccess&&this.haveAddAccess) {
                this.spinnerService.start();
                this.apiCall();
                this.setColumnDefs()
              } else {
                this.spinnerService.stop();
                this.errorList = ["You're not authorized to access this page"];
              }
            }
          }
        },
        error: (_err: any) => {
          this.spinnerService.stop();
          this.haveAccess = false;
          this.haveAddAccess=false;
        },
      });
    })

  }

  setColumnDefs() {
    this.columnDefs = [
      { headerName: 'AUTH#', field: 'authnumber',minWidth:90  },
      { headerName: 'MODEL CODE', field: 'modelcode',minWidth:90  },
      { headerName: 'SUPPLIER', field: 'shortname' ,minWidth:90 },
      { headerName: 'PART#', field: 'partnumber', minWidth:190 },
      { headerName: 'PART DESC', field: 'description',minWidth:190 },
      { headerName: 'PACKAGE CODE', field: 'packagecode',minWidth:90  },
      { headerName: 'PACKAGE TYPE', field: 'packagetype',minWidth:90  },
      { headerName: 'DUN1 FAB', field: 'fabcode',minWidth:90  },
      { headerName: 'LENGTH', field: 'outerlength',minWidth:90  },
      { headerName: 'WIDTH', field: 'outerwidth',minWidth:90  },
      { headerName: 'HEIGHT', field: 'outerheight',minWidth:90  },
      {
        headerName: '# OF PARTS',
        field: 'numofparts',
        cellRendererFramework: InputBtnCellRender,
        cellRendererParams: {
          shipmentNumber: this.detailPage,
        },
        minWidth:90 
      },
      { headerName: 'QTY AVAILABLE', field: 'qtyavailable',minWidth:90},
      {
        headerName: 'PLAN QTY',
        field: 'shipqty',
        cellRendererFramework: ShipmentRackCellRenderer,
        cellRendererParams: {
          shipmentNumber: this.detailPage,
        },
        minWidth:90 
      },
      {
        headerName: 'ACTUAL QTY(SHIP)',
        field: 'actualshipqty',
        cellRendererFramework: ShipmentRackCellRenderer,
        cellRendererParams: {
          shipmentNumber: this.detailPage,
        },
        minWidth:90 

      },
      {
        headerName: 'ACTUAL QTY(RECV)',
        field: 'actualrecvqty',
        cellRendererFramework: ShipmentRackCellRenderer,
        cellRendererParams: {
          shipmentNumber: this.detailPage,
        },
        minWidth:90 
      },
      {
        headerName: 'HISTORY',
        field: 'history',
        cellRendererFramework: HistoryBtnauthCellRender,
        minWidth:90 ,
        cellRendererParams: {
          disabled: (field: any) => {
            if (field?.historyDisabled) {
              return true;
            } else {
              return false;
            }
          },
          clicked: (field?: any) => {
            this.dialogOpenHistoryComponent(field?.authnumber);
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        cellRendererFramework: DeleteAuthBtnCellRender,
        minWidth:90 
      },
    ];
  }

  dialogOpen1() {
    this.dialog.open(ShipmentCompanyComponent, {
      width: '100%',

    });
  }
  dialogOpen2() {
    this.dialog.open(ShipmentCompanyComponent);

  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  addShipment() {
    const dialogRef = this.dialog.open(AuthSelectComponent);
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        const data = this.displayRowData;
        data.push(result)
        this.gridApi.setRowData(data)
      }
      else {
        this.rp = false
      }
    });
  }

  sliceString(input: string): string {
    let start = 0;
    while (start < input.length && input[start] === '0') {
      start++;
    }
    return input.substring(start)
  }
  setDetailOnLoadValues(result: any) {
    this.numberOfPallet = result[0].numofpallet;
    this.contactAddress = result[0].shipaddress;
    this.contactPerson = result[0].shipcperson;
    this.contactPhone = result[0].shipphonenumber;
    this.lptype = result[0].lptype
    this.date = result[0]?.shipdate ? this.transformDateFormat(result[0].shipdate):'';
    this.shippingClass = result[0].shipshipclass;
    this.contactEmail = result[0].shipemail;
    this.windowTimeHH = result[0].shipwintimef && this.sliceString(result[0].shipwintimef.slice(0, 2)) || '0';
    this.windowTimeMM = result[0].shipwintimet && this.sliceString(result[0].shipwintimet.slice(0, 2)) || '0';
    if (result[0].recvtype === '1') {
      this.receiver = 'supplier';
      this.rdoReceiverSup = true
    }
    else if (result[0].recvtype === '2') {
      this.receiver = 'contfab'
      this.rdoReceiverCont = true
    }
    else if (result[0].recvtype === '3') {
      this.receiver = 'dun1fab'
      this.rdoReceiverDun1 = true
    }
    else if (result[0].recvtype === '4') {
      this.receiver = 'namc'
      this.rdoReceiverPlant = true
    }

    this.setDetailOnLoadValuesNext(result)
  }
  
  setDetailOnLoadValuesNext(result:any){
    this.lp = result[0].lpfabcode;
    this.recvtype = result[0].recvtype;
    this.recvdockcode = result[0].recvdockcode;
    this.recvfabcode = result[0].recvfabcode;
    this.recvsupcode = result[0].recvsupcode;
    this.shipdockcode = result[0].shipdockcode;
    this.shipfabcode = result[0].shipfabcode;
    this.shipsubcode = result[0].shipsubcode;
    this.hidShipPlantCode = result[0].shipplantcode;
    this.lpplantcode = result[0].lpplantcode;
    this.lpdocktype = result[0].lpdocktype;
    this.lpsupcode = result[0].lpsupcode;
    this.lpdockcode = result[0].lpdockcode;
    this.shiptype = result[0].shiptype;
    this.setDetailOnLoadValuesNexthalf(result)
  }

  setDetailOnLoadValuesNexthalf(result:any){
    this.recvemail = result[0].recvemail2;
    this.recvperson = result[0].recvcperson2;
    this.recvphone = result[0].recvphonenumber2;
    this.hidRecvPlantCode = result[0].recvplantcode;
    this.contactAddress2 = result[0].recvaddres;
    this.contactEmail2 = result[0].recvemail1;
    this.contactPerson2 = result[0].recvcperson1;
    this.contactPhone2 = result[0].recvphonenumber1;
    this.status = result[0].status;
    this.date2 = result[0]?.recvdate ?this.transformDateFormat(result[0]?.recvdate):'';
    this.windowTimeHH2 = result[0].recvwintimef && this.sliceString(result[0].recvwintimef.slice(0, 2)) || '0';
    this.windowTimeMM2 = result[0].recvwintimet && this.sliceString(result[0].recvwintimet.slice(0, 2)) || '0';
    this.currency = result[0]?.plancostunit || 'USD';
    this.pickupDate = result[0]?.pickupdate ?this.transformDateFormat(result[0].pickupdate):'';
    this.deliveryDate = result[0]?.delivdate ?this.transformDateFormat(result[0].delivdate):'';
    this.pickupTime = result[0]?.pickuptime && this.sliceString(result[0]?.pickuptime.slice(0, 2)) || '0';
    this.deliveryTime = result[0]?.delivtime && this.sliceString(result[0]?.delivtime.slice(0, 2)) || '0';
    this.carrier = result[0]?.carriercode;
    this.trackingNumber = result[0]?.trackingno;
    this.cost = result[0].cost;
    this.costEstimate = result[0]?.plancost;
    this.currency2 = result[0]?.actualcostunit || 'USD';
    this.actualCost = result[0]?.actualcost;
    this.shipmentDate=result[0]?.actualshipdate ?this.transformDateFormat(result[0].actualshipdate):'';
    this.receivedDate = result[0]?.actualrecvdate ?this.transformDateFormat(result[0].actualrecvdate):''
  }

  dialogOpenHistoryComponent(authnumber: string) {
    this.dialog.open(ShipmentHistoryComponent, {

      data: {
        authNumber: authnumber
      },
    });
  }
  detailOnLoad(result: any) {

    this.setDetailOnLoadValues(result)
  }
  rejectCancel() {
    this.close();
    const params = {
      shipmentNumber: this.detailPage
    }
    this.spinnerService.start();
    this.shipmentListService.rejectCancel(params).subscribe({
      next: (_res: any) => {
        this.router.navigate([this.shipmentUrl])
        this.spinnerService.stop();
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })
  }
  cancelShipment() {
    this.close();
    const params = {
      shipmentNumber: this.detailPage
    }
    this.spinnerService.start();
    this.shipmentListService.shipmentCancel(params).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigate([this.shipmentUrl])
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })
  }
  reject() {
    this.close();
    const params = {
      shipmentNumber: this.detailPage
    }
    this.spinnerService.start();
    this.shipmentListService.reject(params).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigate([this.shipmentUrl]);
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })
  }
  approve() {
    this.close();
    const params = {
      shipmentNumber: this.detailPage,
      authNumber: this.authNumber,
      plantCode: this.userInfo.p_plantcode,
      specialistCode: this.userInfo.p_specialistcode,
      newAuth: "",
      userUnit: Number(this.userInfo.unit),
      chgText: ""
    }
    this.spinnerService.start();
    this.shipmentListService.approve(params).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigate([this.shipmentUrl]);
      },
      error: (error: any) => {
        this.spinnerService.stop();
        this.errorList.push(error);
      }
    })
  }

  buttonDisabNext(Status:any){

    if (Status[0].status === "0" || Status[0].status === "22") {
      this.rejectFlag = false
      this.cancelShipFlag = false
      this.cancelRejectFlag = false
      this.approveFlag = false
    } else if (Status[0].status === "13") {
      this.saveFlag = false
    } else if (Status[0].status === "21") {
      this.rejectFlag = false
    }
    if (this.flag === "0") {
      this.sendFlag = false
      this.rejectFlag = false
      this.saveFlag = false
      this.cancelShipFlag = false
      this.cancelRejectFlag = false
      this.approveFlag = false
    }
    if (!this.isShipmentCancelled) {
      this.rejectcancelbtn = true;
      this.approvebtn = true;
    }
  }

  buttonDisab(Status: any) {
    this.buttonDisabNext(Status)

    if (this.hidShipPlantCode !== '' || this.hidRecvPlantCode !== '') {
      if (!((this.hidShipPlantCode === this.userInfo.p_plantcode && (Status[0].status === "0" || Status[0].status === "13" || Status[0].status === "21")) ||
        (this.hidRecvPlantCode === this.userInfo.p_plantcode && (Status[0].status === "11" || Status[0].status === "22"))) || Status[0].status === "14") {
        this.sendbtn = true;
        this.rejectbtn = true;
        this.savebtn = true;
      }

      if (this.isShipmentCancelled || (this.hidShipPlantCode !== '' && this.hidShipPlantCode !== this.userInfo.p_plantcode)) {
        this.shipcancelbtn = true;
        if (Status[0].status === "0" || Status[0].status === "13" || Status[0].status === "21") {
          this.sendbtn = true;
          this.rejectbtn = true;
          this.savebtn = true;
        }
      }
      if (this.hidShipPlantCode !== this.userInfo.p_plantcode) {
        this.shipcancelbtn = true;
      }
    } else {
      this.shipcancelbtn = true;
      this.sendbtn = true;
      this.rejectbtn = true;
      this.savebtn = true;
    }
  }
  send() {
    this.close();
    const params = {
      txtCompany2: this.company2,
      hidNewShipNo: "",
      chkNoShipment: this.chknoshipment,
      rdoReceiverSup: this.rdoReceiverSup,
      rdoReceiverCont: this.rdoReceiverCont,
      rdoReceiverDun1: this.rdoReceiverDun1,
      rdoReceiverPlant: this.rdoReceiverPlant,
      shipmentNumber: this.detailPage,
      authNumber: this.authNumber,
      plantCode: this.userInfo.p_plantcode,
      specialistCode: this.userInfo.p_specialistcode,
      newAuth: "",
      userUnit: Number(this.userInfo.unit),
      chgText: "",
      LPType: this.lptype,
      LPPlantCode: this.lpplantcode,
      LPDockCode: this.lpdockcode,
      LPSupCode: this.lpsupcode,
      LPFabCode: this.lp,
      ShipType: this.shiptype,
      ShipPlantCode: this.hidShipPlantCode,
      ShipDockCode: this.shipdockcode,
      ShipSupCode: this.shipsubcode,
      ShipFabCode: this.shipfabcode,
      ShipAddress: this.contactAddress,
      ShipCPerson: this.contactPerson,
      ShipPhoneNumber: this.contactPhone,
      ShipEmail: this.contactEmail,
      ShipDate: this.date,
      ShipWinTimeF: this.windowTimeHH,
      ShipWinTimeT: this.windowTimeMM,
      ShipShipClass: this.shippingClass,
      RecvType: this.recvtype,
      RecvPlantCode: this.hidRecvPlantCode,
      RecvDockCode: this.recvdockcode,
      RecvSupCode: this.recvsupcode,
      RecvFabCode: this.recvfabcode,
      RecvAddres: this.contactAddress2,
      RecvCPerson1: this.contactPerson2,
      RecvPhoneNumber1: this.contactPhone2,
      RecvEmail1: this.contactEmail2,
      RecvCPerson2: this.recvperson,
      RecvPhoneNumber2: this.recvphone,
      RecvEmail2: this.recvemail,
      RecvDate: this.date2,
      RecvWinTimeF: this.windowTimeHH2,
      RecvWinTimeT: this.windowTimeMM2,
      PickupDate: this.pickupDate,
      PickupTime: this.pickupTime,
      DelivDate: this.deliveryDate,
      DelivTime: this.deliveryTime,
      CarrierCode: this.carrier,
      TrackingNo: this.trackingNumber,
      Cost: this.cost,
      ActualShipDate: this.shipmentDate,
      ActualRecvDate: this.receivedDate,
      NumOfPallet: this.numberOfPallet,
      Status: this.status
    }
    this.spinnerService.start();
    this.shipmentListService.send(params).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigate([this.shipmentUrl]);
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })

  }
  save() {
    this.close();
    const params = {
      delAuthNum: "",
      txtCompany2: this.company2,
      hidNewShipNo: "",
      chkNoShipment: this.chknoshipment,
      rdoReceiverSup: this.rdoReceiverSup,
      rdoReceiverCont: this.rdoReceiverCont,
      rdoReceiverDun1: this.rdoReceiverDun1,
      rdoReceiverPlant: this.rdoReceiverPlant,
      shipmentNumber: this.detailPage,
      authNumber: this.authNumber,
      plantCode: this.userInfo.p_plantcode,
      specialistCode: this.userInfo.p_specialistcode,
      newAuth: "",
      userUnit: Number(this.userInfo.unit),
      chgText: "",
      LPType: this.lptype,
      LPPlantCode: this.lpplantcode,
      LPDockCode: this.lpdockcode,
      LPSupCode: this.lpsupcode,
      LPFabCode: this.lp,
      ShipType: this.shiptype,
      ShipPlantCode: this.hidShipPlantCode,
      ShipDockCode: this.shipdockcode,
      ShipSupCode: this.shipsubcode,
      ShipFabCode: this.shipfabcode,
      ShipAddress: this.contactAddress,
      ShipCPerson: this.contactPerson,
      ShipPhoneNumber: this.contactPhone,
      ShipEmail: this.contactEmail,
      ShipDate: this.date,
      ShipWinTimeF: this.windowTimeHH,
      ShipWinTimeT: this.windowTimeMM,
      ShipShipClass: this.shippingClass,
      RecvType: this.recvtype,
      RecvPlantCode: this.hidRecvPlantCode,
      RecvDockCode: this.recvdockcode,
      RecvSupCode: this.recvsupcode,
      RecvFabCode: this.recvfabcode,
      RecvAddres: this.contactAddress2,
      RecvCPerson1: this.contactPerson2,
      RecvPhoneNumber1: this.contactPhone2,
      RecvEmail1: this.contactEmail2,
      RecvCPerson2: this.recvperson,
      RecvPhoneNumber2: this.recvphone,
      RecvEmail2: this.recvemail,
      RecvDate: this.date2,
      RecvWinTimeF: this.windowTimeHH2,
      RecvWinTimeT: this.windowTimeMM2,
      PickupDate: this.pickupDate,
      PickupTime: this.pickupTime,
      DelivDate: this.deliveryDate,
      DelivTime: this.deliveryTime,
      CarrierCode: this.carrier,
      TrackingNo: this.trackingNumber,
      Cost: this.cost,
      ActualShipDate: this.shipmentDate,
      ActualRecvDate: this.receivedDate,
      NumOfPallet: this.numberOfPallet,
      Status: this.status
    }
    this.spinnerService.start();
    this.shipmentListService.save(params).subscribe({
      next: (_res: any) => {
        this.spinnerService.stop();
        this.router.navigate([this.shipmentUrl]);
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })

  }
  apiCall() {
    const params = {
      shipmentNumber: this.detailPage,
      authNumber: "",
      plantCode: this.userInfo.p_plantcode,
      specialistCode: this.userInfo.p_specialistcode,
      newAuth: "",
      userUnit: Number(this.userInfo.unit),
      chgText: ""
    }

    this.shipmentListService.shipmentDetailOnload(params).subscribe({
      next: (res: any) => {
        this.displayRowData = res.shipDetailAuth;
        this.rowData = res;
        this.rowCount = this.displayRowData.length;
        this.Weight = res.hidTotalWeight;
        this.authNumber = res.shipDetailAuth[0].authnumber
        this.addflag = res.ADDDisabled
        this.company2 = res.txtCompany2;
        this.company = res.txtCompany1;
        this.lpcodes = res.selLP;
        this.chknoshipment = res.hidNoShipmentFlag === "0" ? false : true
        this.isShipmentCancelled = res.isShipmentCancelled
        this.detailOnLoad(res.shipDetail);
        this.buttonDisab(res.crctStatus);
        this.spinnerService.stop();
      },
      error: (error: any) => {
        this.errorList.push(error);
        this.spinnerService.stop();
      }
    })
  }

  cancel(){
    this.router.navigateByUrl(this.shipmentUrl);
  }

  close() {
    this.errorList = [];
    this.successMessage = [];
  }

  transformDateFormat(originalDate: string) {    
     // Assuming the original date is in dd/mm/yyyy format    
     const parts = originalDate.split('/');     
     const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;     // Using Angular's DatePipe to format the date    
     return this.datepipe.transform(formattedDate, "yyyy-MM-dd");  
     }
}
