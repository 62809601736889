import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ImportCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/import-radio-btn-cell-render';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-delete-part-confirmation',
  templateUrl: './delete-part-confirmation.component.html',
  styleUrls: ['./delete-part-confirmation.component.scss']
})
export class DeletePartConfirmationComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  isAccessible = false;
  paginationPageSize = 50;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  partInfo: any;
  partInfoContents: any;
  importCode: any;
  value: any;
  value2: any;
  value3: any;
  res: any;
  currSupplierCode: any;
  psd: any;
  partNumber: any;
  line: any;
  rowCount = 0;
  gridColumnApi: any;
  routeParams: any;
  errorArray: any[] = [];
  viewAccess: any;
  errorMessage !: { errorCode: string; childErrorCode: string; };
  isAll = false;


  constructor(
    private readonly router: Router,
    private readonly service: ModelListService,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    public state : ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }

  }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if (this.routeParams) {
        this.spinnerService.start();
        this.checkAccess();
      }
    })
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    if (this.isAll) {
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      // this.apiCall()

    }
    // this.apiCall()
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit("autoWidth");
    this.gridApi?.paginationSetPageSize(this.rpPage);
  }

  radioChange(_colId:any,data:any){
    const rowData:any[] = []
    this.gridApi.forEachNode((node :any) =>{
      rowData.push(node.data);
    })
    const index = rowData.findIndex((obj:any) => obj.hidPartNumber === data.hidPartNumber);    
    if(index !== -1){
      rowData[index] = data;
    }
    this.gridApi.setRowData(rowData)
  }

  okBtn() {
    const rowData :any [] = []
    this.gridApi.forEachNode((node :any) =>{
      rowData.push(node.data);
    });
    const body:any = {};
    rowData.forEach((item:any,index:any)=>{
      body[`hidPartNumber_${index}`] = item.hidPartNumber;
      body[`hidPSD_${index}`] = item.hidPSD;
      body[`rdoName_${index}`] = item.selectedValue === 'ignore' ? 'Ignore' : 'Delete';
      body[`hidSupplierCode_${index}`] = item.hidSupplierCode
      body.ID = this.routeParams.id;
      body.PlantCode = this.routeParams.plantcode;
      body.ModelCode = this.routeParams.modelcode;
    })
    this.spinnerService.start();
    this.service.deleteBtnOnload(body).subscribe({
      next : (res:any)=>{
        if (res.status) {
          const {ID,ModelCode,PlantCode} = res.response
          if (res.redirect === 'NewPartConfirmation') {
            this.router.navigate([`/model-list/new-part/${ID}/${ModelCode}/${PlantCode}`]);
          }else if (res.redirect === 'DeletePartConfirmation') {
            this.router.navigate([`/model-list/delete-part/${ID}/${ModelCode}/${PlantCode}`]);
          }else if (res.redirect === 'ReplacePartConfirmation') {
            this.router.navigate([`/model-list/replace-part/${ID}/${ModelCode}/${PlantCode}`]);
          }else if (res.redirect === 'ImpPartConfirm') {
            this.router.navigate([`/model-list/confirm-import/${ID}/${ModelCode}/${PlantCode}`]);
          }
        }else{
          this.errorArray.push(res.message);
        }
        this.spinnerService.stop();
      },
      error: ()=>{
        this.spinnerService.stop();
      }
    });
  }

  checkAccess(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.viewAccess = res.role?.p_trackingsave;
        if (this.viewAccess) {
          this.getColumnDefs();
          this.apiCall();
        } else {
          this.errorMessage = {errorCode : `API-403`,childErrorCode : '400'};
        }
      },
      error: (_err: any) => {
        this.viewAccess = false;
        this.errorMessage = {errorCode : `API-099`,childErrorCode : '400'};
      },
    });
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "supplier code ", field: 'hidSupplierCode', sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "Supplier name", field: 'hidSupplierName', sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "part#", field: 'hidPartNumber', sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "delete", field: "delete_del", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: ImportCellRendererComponent,
        cellRendererParams: {
          clicked: (colId:string,data:any) => {
            this.radioChange(colId,data)
          },
        }
      },
      {
        headerName: "ignore", field: "ignore_del", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: ImportCellRendererComponent,
        cellRendererParams: {
          clicked: (colId:string,data:any) => {
            this.radioChange(colId,data)
          },
        }
      },
    ]
  }

  apiCall() {
    this.spinnerService.start();
    this.gridApi?.showLoadingOverlay();
    const deleteObject = {
      "ID": this.routeParams.id,
      "PlantCode": this.routeParams.plantcode,
      "ModelCode": this.routeParams.modelcode,
      "page ": 1,
      "limit": 200
    }
    this.service.deleteOnload(deleteObject).subscribe({
      next: (res: any) => {
        if (res.result.status && res.result.response.length > 0) {
          const result = res.result.response.flat(1);
          const output: any[] = [];
          const tempMap: any = {};
          for (const item of result) {
            const fieldIDParts = item.fieldID.split('_');
            const prefix = fieldIDParts[0];
            const index = fieldIDParts[1];

            if (!tempMap[index]) {
              tempMap[index] = {};
            }
            tempMap[index][prefix] = item.fieldValue;
            tempMap[index]['ignore_del'] = 'ignore';
            tempMap[index]['delete_del'] = 'delete';
            tempMap[index]['selectedValue'] = 'ignore';
          }
          for (const key in tempMap) {
            output.push(tempMap[key]);
          }
          this.displayRowData = output;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
        } else {
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
          if (res.result.message.length > 0) {
            this.errorArray = res.message;
          }
        }
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = this.displayRowData.length;
        this.totalRecords = this.displayRowData.length;
        this.tPage = 0
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      }
    })
  }

  navigateToModelList() {
    this.router.navigate(['/model-list'])
  }

}
