<div class="d-flex justify-content-between align-items-center m-3">
  <div class="col-11 page-title mx-3">Part List</div>
  <div class="col">
    <button class="help-btn" (click)="help()">help</button>
  </div>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
  Authority Error - Access denied
</div>
<div id='err' class="alert alert-danger m-3" *ngIf="errorMessage">
  <span  >{{errorMessage}}</span>
</div>

<div class="vpacs-shadow m-3 p-2" *ngIf="haveAccess" (keyup.enter)="onSearch()">
  <div class="filter mx-2 d-flex">
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
      <label for="family-code" class="col-4">Family Code</label>
      <input type="text" id="family-code" [(ngModel)]="familyCode" style="width: 100%;"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
      <label for="plant" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
      (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
      style="width: 80%">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
      </mat-option>
    </mat-autocomplete>
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Supplier</label>
      <input type="text" id="family-code" [(ngModel)]="supplier" style="width: 100%;"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
      <label for="packageFromDate" class="col-4">Package Start From Date</label>
      <input type="date" id="packageFromDate" [(ngModel)]="packageFromDate" style="width: 100%;" (keydown)="onKeyDown($event,'packageFromDate')" />
    </div>

    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  ">
      <label for="part" class="col-4">Part M3</label>
      <div class="floating">
        <label for="over">Over</label>
        <input type="text" id="part-1" [(ngModel)]="partOver" style="width: 100%;"  />
      </div>
      <div class="mx-1 floating">
        <label for="over">Under</label>
        <input type="text" id="part-1" [(ngModel)]="partUnder" style="width: 100%;"  />
      </div>
    </div>

    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Model Code</label>

      <input type="text" id="family-code" [(ngModel)]="modelCode" style="width: 100%;"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Part Number</label>
      <input type="text" id="family-code" [(ngModel)]="partNumber" style="width: 100%;"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Part Description</label>
      <input type="text" id="family-code" style="width: 100%;" [(ngModel)]="partDescription"
         />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="pkgToDate" class="col-4">Package Start To Date</label>
      <input type="date" id="pkgToDate" style="width: 100%;" [(ngModel)]="pkgToDate" (keydown)="onKeyDown($event,'pkgToDate')"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  ">
      <label for="part" class="col-4">Total Weight(kg)</label>
      <div class="floating">
        <label for="over">Over</label>
        <input type="text" id="part-1" [(ngModel)]="totalOver" style="width: 100%;"  />
      </div>
      <div class="mx-1 floating">
        <label for="over">Under</label>
        <input type="text" id="part-1" [(ngModel)]="totalUnder" style="width: 100%;"  />
      </div>
    </div>

    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Package Code</label>
      <input type="text" id="family-code" [(ngModel)]="pkgCode" style="width: 100%;"  />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
      <label for="plant" class="col-4">Fabricator</label>
      <input type="text" id="family-code" [(ngModel)]="fabricator" style="width: 100%;"  />
    </div>

    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 ">
      <label for="plant" class="col-4">Specialist Code</label>
      <input type="text" style="width: 100%;" [(ngModel)]="specialistCode"   [ngModelOptions]="{ standalone: true }" />
      <button mat-icon-button>
        <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
      </button>
    </div>
    <div class="d-flex  justify-content-end col-lg-4 col-md-6 col-12">
      <button class="black-button" (click)="onSearch()">Search</button>
    </div>
  </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
  <div class="grid-agg grid-height-part-list mb-5">
      <ag-grid-angular style="width:100%;height:100%;" 
          class="ag-theme-balham" 
          (gridReady)="onGridReady($event);"
          (window:resize)="sizeToFit()" 
          [rowHeight]="30" 
          [headerHeight]="headerHeight"
          [animateRows]="true" 
          [defaultColDef]="defaultColDef"
          [columnDefs]="columnDefs" 
          [rowData]="displayRowData" 
          [suppressCellSelection]="true"
          [showOpenedGroup]="true"
          (filterChanged) = "filterChanged($event)"
          [suppressAggFuncInHeader]='true'
          [suppressHorizontalScroll]="false" 
          [pagination]="true" 
          [paginationPageSize]="rpPage"
          [suppressPaginationPanel]="true" 
          [icons]="icons" 
          [overlayLoadingTemplate]="overlayLoadingTemplate" 
          [overlayNoRowsTemplate]="overlayNoRowsTemplate"
          [domLayout]="domLayout" 
          [rowClassRules]="rowClassRules"
          [floatingFiltersHeight]="40" 
          >
      </ag-grid-angular>
      <app-pagination 
          [cPage]="cPage" 
          [tPage]="tPage" 
          [rpPage]="rpPage" 
          [totalRecords]="totalRecords"
          (changePaginationValues)="changePaginationValues($event)" 
          *ngIf="totalRecords > 0">
      </app-pagination>
  </div>
</div>
  <router-outlet></router-outlet>