<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Tracking Correction</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div id='err' class="alert alert-danger m-3" *ngIf="errorMessage!== null">
    <span  >{{errorMessage}}</span>
  </div>

<div class="row m-3 content-row pb-5">
    <div class="col-md-4 align-items-center px-0">
        <div class="row px-3">
            <div class="col-md-4 titleColumn">
                <div *ngFor="let title of partInfo" class="contents ps-4 py-2">
                    {{title}}
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0">
                <div *ngFor="let title of partInfoContents; let i=index" class="ps-4 py-2"
                    [ngClass]="i % 2 == 0? 'row-even' : 'row-odd'">
                    {{title}}
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3 px-3"> 
            <button class="black-button me-3 roboto-medium col-md-3" (click)="previous()" [disabled]="btnPrevDisabled"> PREVIOUS </button>
            <button class="black-button me-3 roboto-medium col-md-3" (click)="next()" [disabled]="btnNextDisabled">NEXT</button>
        </div>

        <div class="row mt-4 px-3">
            <div class="col-md-4 titleColumn">
                <div class="contents ps-4 py-2 d-flex align-items-center">
                    Skip Auth
                </div>
            </div>
            <div class="col-md-8 contentColumn p-0  d-flex align-items-center">
                <div class="d-flex align-items-center me-2 ps-4 py-2">
                    <mat-checkbox (change)="onChange($event)"
                                  [checked]="chkAuthCancelChecked" 
                                  [disabled]="chkAuthCancelDisabled"
                    ></mat-checkbox>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <mat-accordion hideToggle multi>
                <mat-expansion-panel disabled [expanded]="true" #panel class="mat-elevation-z0"
                    (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="roboto-bold me-0">IMPORT PPF</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row my-3 ms-3 me-3 heading-row">
                        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

                        <div class="file-upload d-grid gap-2 ps-0">
                            <label>
                                PPF File
                            </label>
                            <div class="file text-center d-grid align-items-center">
                                <div>
                                    <img src="assets/images/file.svg" alt="uploaded file">
                                </div>
                                <label>
                                    {{fileName || "No file uploaded yet."}}
                                </label>
                            </div>

                            <button class="upload-btn vpacs-btn-table mb-5" (click)="fileUpload.click()" [disabled]="filPrePPFDisabled">
                                Choose File
                            </button>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-7 d-flex">
        <div class="row w-75">
            <table class="table" aria-describedby="change-status">
                <thead>
                    <tr class="titleColumn">
                        <th scope="col" class="ps-4">Change Status</th>
                    </tr>
                </thead>
                <tbody class="d-flex">
                    <div class="col-md-6 titleColumn">
                        <div *ngFor="let title of statusTitle" class="contents ps-4 py-3">
                            {{title}}
                        </div>
                    </div>
                    <div class="col-md-6 contentColumn rowClass">
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selinitialStatus">
                                <mat-option *ngFor="let status of initialStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selSendPPFStatus">
                                <mat-option *ngFor="let status of sendPPFStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selReceivePreStatus">
                                <mat-option *ngFor="let status of receivePreStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selApprovePPFStatus">
                                <mat-option *ngFor="let status of approvePPFStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selOrderStatus">
                                <mat-option *ngFor="let status of orderStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selShipStatus">
                                <mat-option *ngFor="let status of shipStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selTrialApprovedStatus">
                                <mat-option *ngFor="let status of trialApprovedStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selReceivesFinalStatus">
                                <mat-option *ngFor="let status of receivesFinalStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selFinalApprovalStatus">
                                <mat-option *ngFor="let status of approvalStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                        <div  class="ps-4 py-2">
                            <mat-select id="plant" class="mat-sel py-2 ms-3" disableOptionCentering disabled
                                [(ngModel)]="selPkgAuthStatus">
                                <mat-option *ngFor="let status of kgAuthStatus" [value]="status.value">{{status.text}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
    <button class="cancel me-3 roboto-bold" (click)="onCancel()"> BACK </button>
    <button class="black-button me-3 roboto-medium" (click)="submitCorrection()" [disabled]="isEmpty">OK</button>
</div>
