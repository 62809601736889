import { Component, OnInit } from '@angular/core';
import { SelectBtnCellRender } from 'src/app/core/gridrender/select-btn-cell-render';
import { Router } from '@angular/router';
import { AdminAccessService } from 'src/app/services/admin-access.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'app-admin-access',
  templateUrl: './admin-access.component.html',
  styleUrls: ['./admin-access.component.scss'],
  providers: [RegexFilter],
})
export class AdminAccessComponent implements OnInit {
  public step: any;
  public rowData: any;
  public shortName: any = '';
  public supplierName: any = '';
  public code: any = '';
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  displayRowData: any[] = [];
  totalPage = 0;
  currentPageForTotalPage = 0;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  // Display 50 records at initial load to fill the grid empty spaces
  paginationPageSize = 50;
  isAccessible = false;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border text-danger" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  completeData: any;
  unAuthorized: any;

  constructor(
    public adminService: AdminAccessService,
    public state: ExportStateService,
    protected router: Router,
    public constants: ConstantsService,
    public regex: RegexFilter,
    public globalState: GlobalStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
    sessionStorage.removeItem('trackingFilter');
    this.checkRoles();
    this.globalState.resetFilters();
    this.globalState.trackingRowData = {};
    let data = this.globalState.getAdminAccessFilters();
    data = this.regex.checkValueEqualsStar(data);
    if (data) {
      this.code = data?.SUPPLIERCODE;
      this.supplierName = data?.NAME;
      this.shortName = data?.SHORTNAME;
    }
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: 'SELECT',
        field: 'select',
        width: 50,
        cellRendererFramework: SelectBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            if (field?.SUPPLIERCODE) {
              localStorage.setItem(
                'tracking',
                JSON.stringify([field?.SUPPLIERCODE])
              );
            }
          },
        },
      },
      {
        headerName: 'SUPPLIER CODE',
        field: 'SUPPLIERCODE',
        width: 50,
      },
      {
        headerName: 'SHORT NAME',
        field: 'SHORTNAME',
        width: 50,
      },
      {
        headerName: 'SUPPLIER NAME',
        field: 'NAME',
        width: 120,
      },
    ];
  }

  checkRoles() {
    this.state.roleState.subscribe((data) => {
      if (data) {
        const userRoles = data;
        if(this.constants.allRoles.includes(userRoles[0])){
          if (!this.constants.adminSystemRoles.includes(userRoles[0])) {
            this.unAuthorized = true;
          } else {
            this.unAuthorized = false;
            this.getColumnDefs();
            this.apiCall();
          }
        }else{
          this.unAuthorized = true;
        }
      }
    });
  }

  apiCall() {
    const stateAdminAccessData = this.globalState.getAdminAccessData();
    if (stateAdminAccessData.length > 1) {
      setTimeout(() => {
        this.completeData = stateAdminAccessData;
        this.displayRowData = stateAdminAccessData;
        this.rowCount = this.displayRowData.length;
        this.onSearch();
        this.gridApi?.hideOverlay(); 
      },500);
    } else {
      this.adminService.loadOnhold().subscribe({
        next: (res: any) => {
          this.globalState.setAdminAccessData(res);
          this.completeData = res;
          this.displayRowData = res;
          this.rowCount = this.displayRowData.length;
          this.onSearch();
        },
        error: (_err) => {
          this.completeData = [];
          this.displayRowData = [];
          this.rowCount = this.displayRowData.length;
          this.gridApi?.hideOverlay();
        },
      });
    }
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  goToNexpage() {
    this.gridApi.paginationGoToNextPage();
  }
  goToPreviousPage() {
    return this.gridApi.paginationGoToPreviousPage() + 1;
  }
  goToFirstPage() {
    return this.gridApi.paginationGoToFirstPage();
  }
  goToLastPage() {
    return this.gridApi.paginationGoToLastPage() + 1;
  }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      return true;
    } else {
      return false;
    }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText(
        '#lbCurrentPage',
        this.gridApi.paginationGetCurrentPage() + 1
      );
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === 'ALL') {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    if (this.globalState.getAdminAccessData().length < 1) {
      this.gridApi?.showLoadingOverlay();
    }
    this.gridApi?.sizeColumnsToFit();
  }

  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }
  
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  onSearch() {
    if (!this.code && !this.shortName && !this.supplierName) {
      this.gridApi?.setRowData(this.completeData);
      this.rowCount = this.completeData.length;
    } else {
      const filterParams: any = {
        SUPPLIERCODE: this.code ? this.code : '*',
        SHORTNAME: this.shortName ? this.shortName : '*',
        NAME: this.supplierName ? this.supplierName : '*',
      };
      this.globalState.setAdminFilters(filterParams);
      const filteredData = this.regex.regexFilter(
        this.completeData,
        filterParams
      );
      this.displayRowData = filteredData;
      this.gridApi?.setRowData(this.displayRowData);
      this.rowCount = filteredData.length;
    }
  }
}
