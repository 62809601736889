<div class="quantity">
  <div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Quantity Control</div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
  </div>
  <div class="alert alert-danger mx-2" *ngIf="errorMessages && getLength()>0">
    <div>{{errorMessages}}</div>
  </div>
  <div class="filter-cont p-2 vpacs-shadow py-3 m-2 d-flex flex-wrap" [formGroup]="filterForm"
    *ngIf="haveAccess === true">
    <div class="inputs">
      <label for="plant">Plant</label>
      <input type="text" matInput formControlName="plant" class="mat-input me-1" [placeholder]="'--Select Plant--'"
      aria-label="text" [matAutocomplete]="auto1">
    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let x of filterOptions | async" [value]="x.code">{{x.name}}
      </mat-option>
    </mat-autocomplete>
    </div>
    <div class="inputs">
      <label for="supplier">Supplier</label>
      <input type="text" id="supplier" formControlName="supplier"  (keyup.enter)="apiCall()"/>
    </div>
    <div class="inputs">
      <label for="partNumber">Part Number</label>
      <input type="text" id="partNumber" formControlName="partNumber" (keyup.enter)="apiCall()" />
    </div>
    <div class="inputs">
      <label for="packageCode">Package Code</label>
      <input type="text" id="packageCode" formControlName="packageCode" (keyup.enter)="apiCall()"/>
    </div>
    <div class="inputs">
      <label for="dunnageCode">Dunnage Code</label>
      <input type="text" id="dunnageCode" formControlName="dunnageCode" (keyup.enter)="apiCall()" />
    </div>
    <div class="inputs">
      <mat-radio-group class="d-flex gap-1 align-items-center smallRadio" formControlName="type">
        <mat-radio-button *ngFor="let x of radioOptions" [value]="x.value" (change)="radioChange($event)" (keyup.enter)="apiCall()">
          {{ x.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="inputs">
      <button class="black-button" (click)="apiCall()">SEARCH</button>
    </div>
  </div>
  <div class="grid-agg px-2 grid-height-quantity-control mb-5" *ngIf="haveAccess === true">
    <ag-grid-angular class="ag-theme-balham " style="width: 100%; height : 100%"
      (gridReady)="onGridReady($event)" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
      [rowData]="displayRowData" [rowHeight]="40" [suppressCellSelection]="true" [paginationPageSize]="rpPage"
      [pagination]="true" [suppressPaginationPanel]="true">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>
  </div>
  <div class="d-flex justify-content-between align-items-center mt-3" *ngIf="haveAccess === true">
    <div class="legend d-flex px-2 mt-2 gap-2">
      Quantity Check :
      <div *ngFor="let x of legend" class="d-flex align-items-center gap-1">
        <div style="width: 15px; height: 15px" [style.background-color]="x.color"></div>
        {{ x.label }}
      </div>
      <span class="ms-5">On Actual : </span>
      <div *ngFor="let x of legend2" class="d-flex align-items-center gap-1">
        <div style="width: 15px; height: 15px" [style.background-color]="x.color"></div>
        {{ x.label }}
      </div>
    </div>
    <div class="d-flex gap-2 pe-2 align-items-center">
      <div class="mx-3 text-primary link" (click)="resetForm()">RESET</div>
      <button class="black-button" (click)="clickOk()" [disabled]="disableOk === true">OK</button>
    </div>
  </div>
  <div class="bottom-inputs px-2 mt-2 d-flex flex-wrap col-12" [formGroup]="cont1Form" *ngIf="haveAccess === true">
    <div class="cont-1 d-flex flex-wrap col-lg-6 col-12">
      <div class="col-4 pb-2">
        <label for="package" class="col-4">Package</label>
        <input type="text" id="package" class="col-7" formControlName="packageCode" />
      </div>
      <div class="col-4 pb-2">
        <label for="part" class="col-4">Part#</label>
        <input type="text" id="part" class="col-7" formControlName="partNumber" />
      </div>
      <div class="col-4 pb-2">
        <label for="supplier" class="col-4">Supplier</label>
        <input type="text" id="supplier" class="col-7" formControlName="supplier" />
      </div>
      <div class="col-4 pb-2">
        <label for="dunnage1" class="col-4">Dunnage1</label>
        <input type="text" id="dunnage1" class="col-7" formControlName="dunnage1Code" />
      </div>
      <div class="col-4 pb-2">
        <label for="plant" class="col-4">Plant</label>
        <input type="text" id="plant" class="col-7" formControlName="plant" />
      </div>
      <div class="col-4 pb-2">
        <label for="week" class="col-4">Week</label>
        <input type="text" id="week" class="col-7" formControlName="week" />
      </div>
      <div class="col-4 pb-2">
        <label for="dunnage2" class="col-4">Dunnage2</label>
        <input type="text" id="dunnage2" class="col-7" formControlName="dunnage2Code" />
      </div>
    </div>
    <div class="arrow col-1 d-flex pt-3 justify-content-center" *ngIf="hideForms">
      <img src="../../../../../assets/images/right-arrow.svg" alt="Arrow" style="width: 45px; height: 45px" />
    </div>
    <div class="cont-2 d-flex flex-wrap col-lg-4 col-md-12" *ngIf="hideForms" [formGroup]="cont2Form">
      <div class="col-6">
        <label for="partnumber" class="col-4">Part#</label>
        <input type="text" id="partnumber" class="col-7" formControlName="partnumber" />
      </div>
      <div class="col-6">
        <label for="supplier" class="col-4">Supplier</label>
        <input type="text" id="supplier" class="col-7" formControlName="supplier" />
      </div>
      <div class="col-6">
        <label for="plant" class="col-4">Plant</label>
        <input type="text" id="plant" class="col-7" formControlName="plant" />
      </div>
      <div class="col-6">
        <label for="week" class="col-4">Week</label>
        <input type="text" id="week" class="col-7" formControlName="week" />
      </div>
      <div class="col-4 pb-2 invisible">
        <label for="dunnage2" class="col-4">Dunnage2</label>
        <input type="text" id="dunnage2" class="col-7" />
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>