import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PpfServiceInternal {
PpfPkgData: any;
table1Data:any;
table2Data:any;
NEtable1Data:any;
NEtable2Data:any;
ppfFlag=false
ppfFlag2=false
previousURL = '';
currentURL = '';
ppfImgData: any;
ppfhazmat: any;
newPackage = {
  row : 10,
  packagecode : ''
}
  constructor(
    private readonly constantsService : ConstantsService,
    private readonly http : HttpClient,private readonly router: Router
    ) {  
      this.currentURL = this.router.url;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
        this.previousURL = this.currentURL;
        this.currentURL = event.url
      })
    }
  getPreviousURL():string{
    return this.previousURL;
  }
    
  downloadPPF( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/DownloadPPF`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  selectPkgFab( body:any){
    const URL = this.constantsService.INTERNAL + `trackingPartList/PPF/manufacturerOnload`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  OnloadPPFData( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/OnloadPPFData`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  //copyppf
  copyPPF( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/copyPPF`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  onLoadImage(payload:any){
    const URL = this.constantsService.INTERNAL + `tracking/getImage`;
    return this.http.post(URL,payload, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  //copyppfimage
  copyPPFImage(payload:any){
    const URL = this.constantsService.INTERNAL + `tracking/getImageCopy`;
    return this.http.post(URL,payload, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  DownloadPPF( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/DownloadPPF`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  CopyPPFData( body:any){
    const URL = this.constantsService.INTERNAL + `trackingPartList/PPF/copy`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  PackageOnload( body:any){
    const URL = this.constantsService.INTERNAL + `trackingPartList/PPF/packageOnload`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  savePPF( body:any){
    const URL = this.constantsService.INTERNAL + `trackingPartList/PPF/save`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  //dummy url here need to modify
  uploadImage( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/savePPFImage`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  getCostHeight( body:any){
    const URL = this.constantsService.INTERNAL + `tracking/getCostHeight`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  externalSPPFOnload(payload:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/ExtPPFOnload/${payload.ppfcode}/${payload.viewMode}/${payload.partNumber}`;
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  servicePdfOnload( obj:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/PPFOnload`;
    return this.http.post(URL,obj, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  getImageOnload(payload:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/getImage`;
    return this.http.post(URL,payload, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  uploadImageOnload(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/savePPFImage`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  ServicePartsPPFDownload(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/PPFDownload`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  ServicePartsPPFSave(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/PPFSave`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  ExtServicePartsPPFSave(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/ExtPPFSave`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  ServicePartsValidatePart(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/validatePart`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  ServicePartsCopyOnload(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/copyOnload`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  internalDelete(body:any){
    const URL = this.constantsService.INTERNAL + `ServiceParts/DeletePart`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }
  setPpfPkgData(val: any) {
    this.PpfPkgData = val
  }

  getPpfPkgData() {
    return this.PpfPkgData;
  }

  resetPpfPkgData(){
    this.PpfPkgData = null;
  }

  ppfTable1Data(){
    return this.table1Data;
  }
  setPpfTable1Data(data:any){
    this.table1Data=data;
  }
  ppfTable2Data(){
    return this.table2Data;
  }
  setPpfTable2Data(data:any){
    this.table2Data=data;
  }
  getPpfImgData(){
    return this.ppfImgData;
  }
  setPpfImgData(data:any){
    this.ppfImgData=data;
  }
  setPpfHazmat(data: any) {
    if (data) {
    this.ppfhazmat = data;
    }
  }
  getPpfHazmat() {
    return this.ppfhazmat;
  }
}
