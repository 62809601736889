<div class="d-flex justify-content-between align-items-center m-2">
  <p class="page-title">Package Maintenance</p>
  <div>
  <button class="black-button" (click)="addPackage()" [disabled]='addFlag'>Add</button>
  <button class="help-btn" (click)="help()">Help</button>
</div>
</div>
<div id='err' class="alert alert-danger mx-2" *ngIf="errorList">
  <span  >{{errorList}}</span>
</div>
<div id='err' class="alert alert-success mx-2" *ngIf="successMessage.length > 0">
  <span  >{{successMessage}}</span>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
  Authority Error - Access denied
</div>
<div class="vpacs-shadow m-2 py-2 px-2 d-flex flex-wrap" *ngIf="haveAccess"  (keyup.enter)="onSearch()">
  <div class="filter mx-1 d-flex col-12 mb-2  flex-lg-row" >
   <div class="d-flex align-items-lg-center align-items-sm-start flex-sm-column flex-lg-row gap-4 col-xl-6 col-lg-5 col-md-6 col-12"> 
      <div class="d-flex align-items-center col-lg-3 col-md-8 col-12 mx-2">
        <label for="family-code" class="col-3 text-center col-md-6  col-lg-4">Code</label>
        <input type="text" id="family-code"   style="width: 90%;"  [(ngModel)]="codePackage"  >
      </div>
      <div class="d-flex align-items-center col-lg-3 col-md-8 col-12 mx-2">
        <label for="plant" class="col-3  col-md-6 text-center">Type</label>
        <mat-select class="mat-sel pt-1" [(ngModel)]="type">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let type of types" [value]="type.packagetypecode">{{ type.packagetypecode}} : {{ type.packagetype}}</mat-option>
        </mat-select>
      </div>
      <div class="d-flex align-items-center col-lg-3 col-md-8 col-12 mx-2">
        <label for="plant" class="col-3 col-md-6 text-center">Material</label>
        <mat-select class="mat-sel pt-1" [(ngModel)]="material">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let material of materials" [value]="material.materialcode"
           >{{material.materialcode}} : {{  material.material}}</mat-option>
        </mat-select>
      </div>
      <div class="d-flex align-items-center col-lg-3 col-md-8 col-12 mx-2">
        <label for="plant" class="col-3 col-md-6 text-center">S/N</label>
        <mat-select id="supplier" class="mat-sel pt-1" [(ngModel)]="standard">
          <mat-option value="">All</mat-option>
          <mat-option value="S">S:Standard</mat-option>
          <mat-option value="N">N:Non Standard</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="d-flex align-items-center align-self-end justify-content-end gap-4 col-lg-7 col-xl-6 col-md-6 col-12">
      <button  class="black-button w-auto" (click)="onSearch()">SEARCH</button>
      <button class="black-button w-auto" (click)="importpackagedata()" [disabled]="importDataFlag" >IMPORT DATA</button>
      <button class="black-button w-auto" (click)="importpackagecost()" [disabled]="importCostFlag" >IMPORT COST</button>
      <button class="black-button w-auto" (click)="exportMethod()">EXPORT </button>
    </div>
  </div>
</div>

<div class="grid-agg mt-3 grid-height-package-maintenance mb-5" *ngIf="haveAccess">
  <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
    [rowData]="displayRowData" [suppressCellSelection]="true"   [gridOptions]="gridOptions"
    [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40"  [rowClassRules]="rowClassRules">
  </ag-grid-angular>
  <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
    (changePaginationValues)="changePaginationValues($event)"*ngIf="totalRecords > 0">
  </app-pagination>
</div>