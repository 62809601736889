import { Component, OnInit } from '@angular/core';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-broadcast-calc',
  templateUrl: './broadcast-calc.component.html',
  styleUrls: ['./broadcast-calc.component.scss']
})
export class BroadcastCalcComponent implements OnInit {
  partInfo: any[] = [];
  partInfo1: any[] = [];
  partInfo2: any[] = [];
  partInfoContents: any[] = [];
  paramData: any;
  calcDetails: any;
  txtBroadcastReqSystem: any;
  txtTrailersInSystem: any;
  txtContainerPerTrainer: any;
  txtBroadcastReqManual: any;
  BroadcastCalcMethod: any;
  rdoBroadcastCalcMethod1Checked = false;
  rdoBroadcastCalcMethod2Checked = false;
  errorMessage = '';

  constructor(
    private readonly trackingListService: TrackingListService,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.partInfo = ['Plant', 'Model Code', 'Part Number', 'Supplier', 'Container Code', 'Dunnage1 Code'];
    this.partInfo1 = ['Trailers in System', 'Container / Trailer'];
    this.partInfo2 = ['System Calc', 'Manual Input'];
    this.route.queryParams.subscribe(
      (_params: any) => {
        this.paramData = JSON.parse(sessionStorage.getItem('trackingParams') || '{}')
        this.loadBroadcastCalc();
      }
    )
  }

  loadBroadcastCalc() {
    this.spinnerService.start();
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      ModelCode: this.paramData?.modelcode,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate
    };
    this.trackingListService.loadBroadcastCalc(loadObj).subscribe({
      next: (res: any) => {
        this.calcDetails = res;
        this.partInfoContents = [this.paramData?.plantname, this.paramData?.modelcode,
        this.paramData?.partnumber, this.paramData?.suppliername,
        this.calcDetails?.createDataContDunn1FromPPF ? this.calcDetails?.createDataContDunn1FromPPF[0]?.containercode : '',
        this.calcDetails?.createDataContDunn1FromPPF ? this.calcDetails?.createDataContDunn1FromPPF[0]?.dunnage1code : ''];

        this.txtTrailersInSystem = this.calcDetails?.createDataBroadcastFromTracking[0]?.trailerinsystem;
        this.txtContainerPerTrainer = this.calcDetails?.createDataBroadcastFromTracking[0]?.containerpertrailer;
        this.txtBroadcastReqSystem = this.calcDetails?.createDataBroadcastFromTracking[0]?.broadcastreqsystem;
        this.txtBroadcastReqManual = this.calcDetails?.createDataBroadcastFromTracking[0]?.broadcastreqmanual;

        if (this.txtBroadcastReqManual === "") {
          this.txtBroadcastReqManual = "0";
        }

        this.BroadcastCalcMethod = this.calcDetails?.createDataBroadcastFromTracking[0]?.broadcastcalcmethod;
        if (this.BroadcastCalcMethod !== "1" && this.BroadcastCalcMethod !== "2") {
          this.BroadcastCalcMethod = "1";
        }

        if (this.BroadcastCalcMethod === "1") {
          this.rdoBroadcastCalcMethod1Checked = true;
        }
        else {
          this.rdoBroadcastCalcMethod2Checked = true;
        }

        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  calc() {
    this.errorMessage = '';
    this.spinnerService.start();
    const calcObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      ModelCode: this.paramData?.modelcode,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate
    };
    this.trackingListService.broadcastCalcButton(calcObj).subscribe({
      next: (res: any) => {
        if (res.result && res?.result?.result?.status < 0) {
          this.txtBroadcastReqSystem = "";
          this.errorMessage = `Runtime Error : Contact a system administrator, please. ${res?.result?.result?.errorMessage}`;
        }
        else {
          this.errorMessage = '';
          this.txtBroadcastReqSystem = res?.result?.createDataBroadcastFromTracking[0]?.broadcastreqsystem;
          this.txtBroadcastReqManual = res?.result?.createDataBroadcastFromTracking[0]?.broadcastreqmanual;
        }
        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  onRadioValChange(event: any){
    this.BroadcastCalcMethod = event.value;
  }

  onCancel() {
    this.router.navigateByUrl('/tracking-list');
  }

  onSubmit() {
    this.errorMessage = '';
    this.spinnerService.start();
    const obj = {
      checkInput: true,
      TrailerInSystem: this.txtTrailersInSystem !== (null || undefined) ? (this.txtTrailersInSystem !== '' ? this.txtTrailersInSystem : '0') : "0",
      ContainerPerTrailer: this.txtContainerPerTrainer !== (null || undefined) ? (this.txtContainerPerTrainer !== '' ? this.txtContainerPerTrainer : '0') : "0",
      BroadcastReqSystem: this.txtBroadcastReqSystem !== (null || undefined) ? (this.txtBroadcastReqSystem !== '' ? this.txtBroadcastReqSystem : '0') : "0",
      BroadcastReqManual: this.txtBroadcastReqManual !== (null || undefined) ? (this.txtBroadcastReqManual !== '' ? this.txtBroadcastReqManual : '0') : "0",
      BroadcastCalcMethod: this.BroadcastCalcMethod !== (null || undefined) ? (this.BroadcastCalcMethod !== '' ? this.BroadcastCalcMethod : '0') : "0",
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate
    }
    this.trackingListService.broadcastOKButton(obj).subscribe({
      next: (res: any) => {
        if (res === true) {
          this.router.navigateByUrl('/tracking-list');
        }
        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  help(){
    this.router.navigate(['./master-help',2,"Tracking ",612])
  }
}
