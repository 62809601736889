import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DoPkgAuthorizationComponent } from '../../pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';
import { Location } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
@Component({
  selector: 'app-tracking-do-ship-trail',
  templateUrl: './tracking-do-ship-trail.component.html',
  styleUrls: ['./tracking-do-ship-trail.component.scss'],
  providers: [DoPkgAuthorizationComponent]
})
export class TrackingDoShipTrailComponent implements OnInit {
  receiveFromToyota: any;
  packageReview:any;
  orderInformation: any;
  replyToToyota:any;
  data: any;
  public shipTrailForm : any;
  res: any;
  btnDisabled = false;
  cancelOKFlag = false;
  cancelNGFlag = false;
  okFlag = false;
  approvalFlag = false;
  rejectFlag = false;
  arriveFlag = false;
  arriveDate = '';
  userName: any;
  view = 2;
  edit = 0;
  constructor(
    public trackingService: TrackingService,
    public router: Router,
    public dialog: MatDialog,
    public dopkgauth: DoPkgAuthorizationComponent,
    public location: Location,
    public state : ExportStateService,
    private readonly constants: ConstantsService,
    protected globalState: GlobalStateService
  ) {
      this.shipTrailForm = new FormGroup({
        arriveDate: new FormControl('',),
        carrierName: new FormControl('',),
        testStatus: new FormControl(''),
        comment: new FormControl('',[Validators.maxLength(250)])
      });
    }

  ngOnInit(): void {
    if(sessionStorage.getItem('queryParamsShipTrial')){
      this.data = JSON.parse(sessionStorage.getItem('queryParamsShipTrial') || '');
    }
    if (this.data?.stepNumber === 3 || this.data?.stepNumber === 8) {
      if (this.data?.statusNumber !== 9 || this.data?.statusNumber !== 10) {
        this.view = 1;
      }
    }
    if (this.data?.WORKING === 6) {
      this.edit = 0;
    } else if (this.data?.statusNumber === 12 || this.data?.statusNumber === 13) {
      this.edit = 1;
    } else if (this.data?.statusNumber === 1 || this.data?.statusNumber === 2 || this.data?.statusNumber === 6) {
      this.edit = 2;
    }
    this.state.userInfoState.subscribe((data:any)=>{
      if(data){
        this.userName = this.getFullName(data.info[0])
      }
    })
    this.data && this.shipTrailOnload();
  }
  getFullName(info: any): any {
    if(info.FULLNAME){
      return info.FULLNAME
    }else{
      return info.FIRSTNAME + info.LASTNAME
    }
  }

  shipTrailOnload() {
    this.trackingService.shipTrialOnload(this.data).subscribe({
      next: (res) => {
        this.res = res;        
        this.receiveFromToyota = res?.receiveFromToyota;
        this.packageReview = res?.packageReview;
        this.orderInformation = res?.orderInformation;
        this.replyToToyota = res?.replyToToyota;
        if(res?.replyToToyota?.arriveDate === '0001/01/01'){
          this.arriveDate = '';
        }else{
          this.arriveDate = res?.replyToToyota?.arriveDate;
        }
        this.setDefaultValues(res?.replyToToyota)
      },
 
      complete: ()=>{
        this.checkButtons();
        this.disableLogic();
        this.checkArriveDate();
      }
    });
  }

  checkArriveDate(){
    if(this.data.cancelFlag && this.data?.cancelFlag !== "2" ){
      if((this.data.whichRequest === this.data?.supOrFab)){
        this.arriveFlag = true;
        this.shipTrailForm.get('arriveDate').addValidators([Validators.required]);
        this.shipTrailForm.get('carrierName').addValidators([Validators.required]);
        this.shipTrailForm.controls.arriveDate.updateValueAndValidity();
        this.shipTrailForm.controls.carrierName.updateValueAndValidity();
      }
    }
  }

  setDefaultValues(res:any){ 
    this.shipTrailForm.patchValue({
      carrierName: res?.carrier,
      arriveDate: res?.arriveDate === '0001/01/01' ? '' : res?.arriveDate?.replace(/\//g, '-'),
      comment: res?.comment,
      testStatus: Number(res?.yesOrno[0]?.STEP6CONFIRM)
    })
  }
  cancelOk() {
    this.trackingService.cancelOk(this.data?.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
    })
  }

  cancelNg() {
    this.trackingService.cancelNg(this.data?.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
    })
  }

  disableLogic(){        
    if(this.data && this.data.stepNumber) {
      if([3,8].includes(this.data.stepNumber)) {
        if(!([6,7,9,10,11].includes(this.res?.getStatus))) {
          this.btnDisabled = true;
        }else{
          this.btnDisabled = false
        }
      } else if([6,11,12].includes(this.data.stepNumber)) {
        if(this.data?.whichRequest !== this.data.supOrFab) {
          this.btnDisabled = true;
        }
        if((this.data.stepNumber === 6) && ([8,9,10,11].includes(this.res?.getStatus))) {
          this.btnDisabled = false;
        }
      } else {
        if([2,6,7,9,10,11].includes(this.res?.getStatus)) {
          this.btnDisabled = false
        } else {
          this.btnDisabled = true;
        }
      }
    }
  }

  checkButtons(){
    if(!([9,10,11].includes(this.res?.getStatus))){
      if(this.data.cancelFlag === 2){
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      }else{
        this.okFlag = true;     
      }  
    }else if([9,10,11].includes(this.res?.getStatus)){
      this.approvalFlag = true;
      this.rejectFlag = true;
    }
  }

  approveReject(type:number){
    const body:any = {
      "hidPlantCode":this.data?.plantCode,
      "hidPartNumber":this.data?.partNumber,
      "hidSupplierCode":this.data?.supplierCode,
      "hidPkgStartDate":this.data?.pkgStartDate,
      "q1_1_yn":this.shipTrailForm.value.testStatus,
      "stepNo":this.data?.stepNumber,
      "flag": type,
      "ppfCode": this.data?.ppfNumber,
    }
    if(type === 2){
      body.rejectPerson = this.userName
    }
    if(this.shipTrailForm.value.testStatus !== null && type === 1){
      this.trackingService.rejectOrApproval(body).subscribe({
        next: (_res) =>{
          this.setUpdatedTrackingGrid(body);
          this.router.navigate(["/tracking"]);
        },
      })
    }else{
      if(type === 1){
        alert('Check Yes or No before approve')
      }else{
        this.trackingService.rejectOrApproval(body).subscribe({
          next: (_res) =>{
            this.setUpdatedTrackingGrid(body);
            this.router.navigate(["/tracking"]);
          },
        })
      }
    }
  }

  setUpdatedTrackingGrid(body:any){
    const {
      hidPartNumber: partNumber,
      ppfCode : ppfCode
    } = body;
    const mergedData = Object.values(this.globalState.trackingRowData).reduce((acc:any,val:any) => acc.concat(val),[]);
    if (mergedData) {
      mergedData.forEach(
        (row: {
          submitFlag: boolean;
          gridDate: string;
          STEP06STATUS: number;
          ISUPDATEPPF: number | null;
          STEP06DATE: string;
          PARTNUMBER: any;
          PPFCODE: any;
        }) => {
          if (row.PARTNUMBER === partNumber && row.PPFCODE === ppfCode) {
            row.ISUPDATEPPF = 1;
            row.STEP06STATUS = 8;
            row.submitFlag = true;
          }
        }
      );
    }
  }

  submit() {
    if(this.shipTrailForm.valid && this.shipTrailForm.value.testStatus!==null) {
      const req = {
        "hidPlantCode": this.data?.plantCode,
        "hidPartNumber": this.data?.partNumber,
        "hidSupplierCode": this.data?.supplierCode,
        "hidPkgStartDate" :this.data?.pkgStartDate,
        "hidModelCode": this.data?.modelCode,
        "arriveDate": this.shipTrailForm.controls.arriveDate.value,
        "carrierName": this.shipTrailForm.controls.carrierName.value,
        "note": this.shipTrailForm.controls.comment.value,
        "ppfCode": this.data?.ppfNumber,
        "from": localStorage.getItem('toyotaGUID') ? localStorage.getItem('toyotaGUID') : '',
        "q1_1_yn" : this.shipTrailForm.controls.testStatus.value
      }
      this.trackingService.updateshipTrial(req).subscribe({
        next: (_res) => {
          this.setUpdatedTrackingGrid(req);
          this.router.navigate(["/tracking"]);
        },
      });
    }else{
      (this.shipTrailForm.controls.testStatus.value !== null && this.arriveFlag)
      ?alert("Enter the required Fields...")
      :alert("Require Item [Confirm Yes or No]")
    } 
  }
  
  ppf(){
      const query:any = {
        'plantCode': this.data?.plantCode, 
        'partNumber': this.data?.partNumber,
        'supplierCode' : this.data?.supplierCode,  
        'pkgStartDate': this.data?.pkgStartDate,  
        'ppfNumber' : this.data?.ppfNumber,
        'view': this.view,
        'edit': this.edit
      };
    const plantCode = this.constants.getTypeFromPlantCode(this.data.plantCode)
    if (plantCode === 'SERVICE') {
      this.router.navigate([`/tracking/serviceparts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    } else if (plantCode === 'ASSY') {
      this.router.navigate([`/tracking/accessoryParts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    }
    else {
      sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
      this.router.navigate(['/tracking/ppf/', this.data?.supplierCode, 'readOnly'])
    }
  }
  authDownload(){
    const queryParams = {
      authNumber: this.data?.authNumber,
      comment1: this.receiveFromToyota ? (this.receiveFromToyota.message ? this.receiveFromToyota?.message : '') : ''
    }
    sessionStorage.setItem('queryParamsDo', JSON.stringify(queryParams));
    this.dopkgauth.authDownload()
  }

  cancel(){
    this.location.back();
  }


}
