import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
 

@Component({
  selector: 'app-pur-comment-editor',
  template: `
  <img title="{{params.decisioncomment}}" style="cursor:pointer" src="../../../assets/images/copyComment.svg" alt="NoData" 
  *ngIf="!isdisabled" (click)="viewComment()" />
<input type="text" [(ngModel)]="params.decisioncomment" [disabled]=isdisabled (keyup)="onEnter()">
  `,
})
export class PurCommentEditor implements ICellEditorAngularComp {
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
    constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog,public formsModule:FormsModule) { }
    refresh(_params: ICellRendererParams): boolean {
      return true;
    }
  
 params: any;
 comment:any;
 isdisabled : any;
  agInit(params: any): void {
    this.params = params.data;
    this.params.decisioncomment=params.value;
    this.isdisabled=(this.params.reserve === 1)
  }

  getValue(): any {
    return this.params.decisioncomment;
  }

  onEnter(): void {  
    this.params?.clicked?.(this.params?.decisioncomment,this.params?.authnumber);
  }

  viewComment(){
    navigator.clipboard.writeText(this.params?.decisioncomment).catch(() => {
    });
  }
}