import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
import { Location } from '@angular/common';
import { TrackingService } from 'src/app/services/tracking.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-tracking-do-send-final-pdf',
  templateUrl: './tracking-do-send-final-pdf.component.html',
  styleUrls: ['./tracking-do-send-final-pdf.component.scss']
})
export class TrackingDoSendFinalPdfComponent implements OnInit {
  data: any;
  sendFinalForm: any;
  response: any;
  okFlag = false;
  view = 2;
  edit = 0;
  cancelOKFlag = false;
  cancelNGFlag = false;
  approvalFlag = false;
  rejectFlag = false;
  btnDisabled = false;
  rejectReasonFlag = false;
  disclaimerFlag = false;
  packageReview: any;
  ppfSubmitFlag = false;
  stepTypeFlag = false;
  hazmatComment: any;
  editablePPF = false;
  roles : any;
  constructor(protected globalState: GlobalStateService,
    private readonly location: Location,
    public trackingService: TrackingService,
    public router: Router,
    private readonly constants: ConstantsService,
    private readonly route: ActivatedRoute, private readonly state : ExportStateService) {
    this.sendFinalForm = new FormGroup({
      comment: new FormControl('',[Validators.maxLength(250)]),
      q1_1_yn: new FormControl('',Validators.required),
    })
  }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('queryParamsFinalPpf') || '');
    this.state.roleState.subscribe({
      next: (res: any) => {
        if (res) {
          this.roles = res;
          // fabricators
        if ((this.data.supOrFab === 1 || this.state.checkExternalAccess(this.roles[0])) && (this.data.WORKING === 2 && this.data.loggedIn.includes(this.data.fabcode))) {
          this.editablePPF = true;
        } else if ((this.data.supOrFab === 1 || this.state.checkExternalAccess(this.roles[0])) && (this.data.WORKING === 8 && this.data.loggedIn.includes(this.data.dun1code))) {
          this.editablePPF = true;
        } else if ((this.data.supOrFab === 1 || this.state.checkExternalAccess(this.roles[0])) && (this.data.WORKING === 6 && this.data.loggedIn.includes(this.data.supplierCode))){
          this.editablePPF = true;
        }
        // suppliers
        if (this.data.supOrFab === 0 && (this.data.WORKING === 2 && this.data.loggedIn.includes(this.data.fabcode.substring(0,4)))) {
          this.editablePPF = true;
        } else if (this.data.supOrFab === 0 && (this.data.WORKING === 8 && this.data.loggedIn.includes(this.data.dun1code.substring(0,4)))) {
          this.editablePPF = true;
        } else if (this.data.supOrFab === 0 && (this.data.WORKING === 6 && this.data.loggedIn.includes(this.data.supplierCode.substring(0,4)))){
          this.editablePPF = true;
        }
        }
      },
    });
    if (this.data?.stepNumber === 3 || this.data?.stepNumber === 8) {
      if (this.data?.statusNumber !== 9 || this.data?.statusNumber !== 10) {
        this.view = 1;
      }
    }
    if (this.data?.WORKING === 6) {
      this.edit = 0;
    } else if (this.data?.statusNumber === 12 || this.data?.statusNumber === 13) {
      this.edit = 1;
    } else if (this.data?.statusNumber === 1 || this.data?.statusNumber === 2 || this.data?.statusNumber === 6) {
      this.edit = 2;
    }

    if (this.data?.STEP03STATUS === 5) {
      this.stepTypeFlag = true;
    }

    this.sendFinalOnload();
  }

  sendFinalOnload() {
    this.trackingService.sendFinalPpfOnload(this.data).subscribe({
      next: (data: any) => {
        this.response = data;
        this.packageReview = data?.packageReview
        this.setDefaultValues(data);
        this.hazmatComment = data.hazmatComment;
      },
      complete: () => {
        this.checkButtons();
        this.disableLogic();
        if (sessionStorage.hasOwnProperty('ppfSaveData')) {
          const ppfPSaveData = JSON.parse(sessionStorage.getItem('ppfSaveData') || '')
          if (ppfPSaveData.PPFCODE === this.data?.ppfNumber) {
            this.btnDisabled = false;
          }
        }
      }
    })
  }

  disableLogic() {
    if (this.data && this.data.stepNumber) {
      if ([3, 8].includes(this.data.stepNumber)) {
        if (!([6, 7, 9, 10, 11].includes(this.response.getStatus))) {
          this.btnDisabled = true;
        } else {
          this.btnDisabled = false
        }
      } else if ([6, 11, 12].includes(this.data.stepNumber)) {
        if (this.data.whichRequest !== this.data.supOrFab) {
          this.btnDisabled = true;
        }
        if ((this.data.stepNumber === 6) && ([8, 9, 10, 11].includes(this.response.getStatus))) {
          this.btnDisabled = false;
        }
      } else {
        if ([2, 6, 7, 9, 10, 11].includes(this.response.getStatus)) {
          this.btnDisabled = false
        } else {
          this.btnDisabled = true;
        }
      }

      if (this.data.supOrFab === 1) {
        this.disclaimerFlag = false;
      }
      else {
        this.disclaimerFlag = true;
      }
    }
  }

  setDefaultValues(data: any) {
    if (data.reject.length > 0) {
      this.rejectReasonFlag = true;
      this.sendFinalForm.patchValue({
        comment: data.reject[0].REJECTREASON
      })
    }else if(data.commentbox){
      this.rejectReasonFlag = false;
      this.sendFinalForm.patchValue({
        comment: data.commentbox
      })
    }
    if (data.yesorno[0]) {
      this.sendFinalForm.patchValue({
        q1_1_yn: data.yesorno[0].STEP8CONFIRM
      })
    }
    if (data.comment.length > 0) {
      this.sendFinalForm.patchValue({
        comment: data.comment[0].NOTE
      })
    }
  }

  checkButtons() {
    if (!([9, 10, 11].includes(this.response.getStatus))) {
      if (this.data.cancelFlag && this.data.cancelFlag === 2) {
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      } else {
        this.okFlag = true;
      }
    } else if ([9, 10, 11].includes(this.response.getStatus)) {
      this.approvalFlag = true;
      this.rejectFlag = true;
    }
  }

  submit() {
    this.sendFinalForm.markAllAsTouched();

    const body: any = {
      "hidPlantCode": this.data?.plantCode,
      'hidPartNumber': this.data?.partNumber,
      'hidSupplierCode': this.data?.supplierCode,
      'hidPkgStartDate': this.response?.pkgStartDate,
      'note': this.sendFinalForm.value.comment,
      'q1_1_yn': this.sendFinalForm.value.q1_1_yn,
      "ppfCode": this.data?.ppfNumber,
      "from": localStorage.getItem('toyotaGUID') ? localStorage.getItem('toyotaGUID') : ''
    }
    if (this.sendFinalForm.valid) {
      this.trackingService.sendFinalPpf(body).subscribe({
        next: (_res) => {
          this.enableSubmit(body)
          this.router.navigate(["/tracking"]);
        },
      })
    }else{
      alert('Require Item. Confirm Yes or No')
    }
  }

  enableSubmit(body:any){
    const {
      ppfCode: ppfCode,
      hidPartNumber: partNumber,
    } = body;
    const mergedData = Object.values(this.globalState.trackingRowData).reduce((acc:any,val:any) => acc.concat(val),[]);

    if (mergedData) {
      mergedData.forEach(
        (row: {
          submitFlag: boolean;
          gridDate: string;
          STEP08STATUS: number;
          ISUPDATEPPF: number | null;
          STEP08DATE: string;
          PARTNUMBER: any;
          PPFCODE: any;
        }) => {
          if (row.PARTNUMBER === partNumber && row.PPFCODE === ppfCode) {
            row.ISUPDATEPPF = 1;
            row.STEP08STATUS = 8;
            row.submitFlag = true;
          }
        }
      );
    }

  }

  approveReject(type: number) {
    const body = {
      "hidPlantCode": this.data?.plantCode,
      "hidPartNumber": this.data?.partNumber,
      "hidSupplierCode": this.data?.supplierCode,
      "hidPkgStartDate": this.data?.pkgStartDate,
      "rejectReason": this.sendFinalForm.value.comment,
      "q1_1_yn": this.sendFinalForm.value.q1_1_yn,
      "rejectPerson": this.response?.msgFrom,
      "stepNo": this.data?.stepNumber,
      "flag": type,
      "ppfCode": this.data?.ppfNumber,
    }
    if (this.sendFinalForm.valid) {
      this.trackingService.rejectOrApproval(body).subscribe({
        next: (_res) => {
          this.enableSubmit(body)
          this.router.navigate(["/tracking"]);
        },
      })
    }
  }

  ppf() {
    const query: any = {
      'plantCode': this.data?.plantCode,
      'partNumber': this.data?.partNumber,
      'supplierCode': this.data?.supplierCode,
      'pkgStartDate': this.data?.pkgStartDate,
      'ppfNumber': this.data?.ppfNumber,
      'ISUPDATEPPF': this.data?.ISUPDATEPPF,
      'view': this.view,
      'edit': this.edit
    };
    const queryRead: any = {
      'plantCode': this.data?.plantCode,
      'partNumber': this.data?.partNumber,
      'supplierCode': this.data?.supplierCode,
      'pkgStartDate': this.data?.pkgStartDate,
      'ppfNumber': this.data?.ppfNumber,
      'ISUPDATEPPF': this.data?.ISUPDATEPPF,
    };
    sessionStorage.removeItem('ppfPassData');
    sessionStorage.removeItem('PPFqueryParams');
    const plantCode = this.constants.getTypeFromPlantCode(this.data?.plantCode)
    if (plantCode === 'SERVICE') {
      sessionStorage.setItem('ppfPlantCode', this.data?.plantCode);
      this.router.navigate([`/tracking/serviceparts-pdf/${this.data?.ppfNumber}/${this.view}/${this.data.partNumber}/${1}/${1}`])
    } else if (plantCode === 'ASSY') {
      sessionStorage.setItem('ppfPlantCode', this.data?.plantCode);
      this.router.navigate([`/tracking/accessoryParts-pdf/${this.data?.ppfNumber}/${this.view}/${this.data.partNumber}/${1}/${1}`])
    }
    else {
      if(this.editablePPF){
        sessionStorage.setItem('ppfPassData', JSON.stringify(query))
        this.router.navigate(['/tracking/ppf/', this.data?.supplierCode]);
      }else{
        sessionStorage.setItem('PPFqueryParams', JSON.stringify(queryRead))
        this.router.navigate(['/tracking/ppf', this.data?.supplierCode, 'readOnly'], { relativeTo: this.route })
      }
    }
  }

  onCancel() {
    this.location.back()
  }

}
