import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'selectAll',
  template: `
    <div 
     *ngIf = "!this.params.displayName">
      <input 
        type="checkbox" 
        (click)="checkedHandler($event)"
        [checked]="params.value"
      />
    </div>
     
    <div *ngIf = "this.params.displayName" class="d-flex align-items-center gap-1">
    <input 
     type="checkbox"
      (click) = "checkSelectAll($event)"
      [checked]="checkDefault()"
    />
    <span>ALL</span>
  </div>
  `,
})


export class PurCheckBox implements ICellRendererAngularComp {


  refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params.data}`);
	}
  public params: any;
  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event:any) {
    const colId = this.params.column.colId;
    if(event.target.checked === true){
      this.params.node.data[`${colId}`] = 1; 
    }else{
      this.params.node.data[`${colId}`] = 0; 
    }
  }

  checkDefault() {
    const colId = this.params.column.colId;
    let count = 0;
    const totalRows = this.params.api.getDisplayedRowCount();
    for (let i = 0; i < totalRows; i++) {
      const node = this.params.api.getDisplayedRowAtIndex(i);
      if (node && node.data[colId] === 1) {
        count++;
      }
    }
    return count === totalRows && totalRows > 0;
  }

  checkSelectAll(event:any){
  
    const items:any = [];
    const column:any = this.params.column.colId
     this.params.api.forEachNode((node:any) => { 
      if(event.target.checked === true){
        node.data[column] = 1;
      }
      else{
        node.data[column] = 0; 
      }
      items.push(node.data);
    });

    this.params.api.setRowData(items);
  }

}




