import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, throwError, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InternalApprovementListService {
  URL = this.constantsService.INTERNAL;
  plantdata: any;
  internalFilter: any;
  filterdata: any;
  approved = false;
  groupList ='';

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  getAllPlant(plantObj: any): Observable<any> {
    return this.http.post(this.URL + 'common/getPlant', plantObj, { 
      headers: this.constantsService.internalHttpOptions })
    .pipe(catchError(this.handleError))
  }

  loadIntAppListDetails(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/onLoadIntAppList', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadDeptApprove(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/onLoadDeptApprove', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  getConvPersonList(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/getConvPersonList', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  
  sendDeptApprove(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/Send', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadSelectPPF(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/onLoadSelectPPF', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadComparingPPF(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/onLoadComparingPPF', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  downloadComparingData(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/downloadComparingData', params,{
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  specialistCode(codeObj: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/SpecialistCodes', 
    codeObj, { headers: this.constantsService.internalHttpOptions, })
    .pipe(catchError(this.handleError))
  }
  setInternalFilters(internalFilter: any) {
    this.internalFilter = internalFilter;
  }
  getInternalFilters() {
    return this.internalFilter;
  }
  setInternalPlant(plant: any) {
    this.plantdata = plant;
  }
  getInternalPlant() {
    return this.plantdata;
  }
 
  setInternalGridFilter(filter: any) {
    this.filterdata = filter;
  }
  getInternalGridFilter() {
    return this.filterdata;
  }
  resetInternalFilters(){
    this.internalFilter = '';
  }
  resetInternalGridfilter(){
    this.filterdata = '';
  }
  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

  setApprove(data:any){
    this.approved=data;
  }
  getApprove(){
    return this.approved;
  }


  setGroupList(data:any){
    this.groupList=data;
  }

  getGroupList(){
    return this.groupList;
  }

  
}
