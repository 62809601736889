import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { UpdateConfirmComponent } from './update-confirm/update-confirm.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-ppf-update',
  templateUrl: './ppf-update.component.html',
  styleUrls: ['./ppf-update.component.scss']
})
export class PpfUpdateComponent implements OnInit {
  partInfo: any[] = [];
  partInfoContents: any[] = [];
  updatePpf1: any[] = [];
  updatePpfContents1: any[] = [];
  reasonListWhat: any[] = [];
  reasonListWhy: any[] = [];
  reason: any;
  reasonWhy: any;
  ppfUpdate: any;
  result: any;
  reasonWhat: any;
  reasonWhatDropdown: any[] = []
  selectedValue: any[] = []
  reasonWhy1: any;
  reasonWhyDropdown: any[] = []
  selectedValue1: any[] = []
  newPart: any
  userData: any;

  constructor(
      private readonly PartListService: PartListService,
      public datepipe: DatePipe, 
      public dialog: MatDialog,    
      public router: Router,
      private readonly spinnerService: NgxUiLoaderService,
      private readonly state : ExportStateService
    ) { }

  ngOnInit(): void {
    this.spinnerService.start();
    this.partInfo = [
      'Plant', 'Part Number', 'Supplier', 'Pkg Start Date'
    ]
    this.updatePpf1 = [
      'Reason (What)', 'Reason (Why)'
    ]
    this.ppfUpdate = JSON.parse(sessionStorage.getItem('updatePPF') || '{}');

    this.state.userInfoState.subscribe((data:any)=>{
      if(data){
        this.userData = data;
        this.apiCall();
      }
    })
  }

  apiCall(){
    this.PartListService.updatePpfOnload(this.ppfUpdate)
      .subscribe((res: any) => {
        this.result = res[0][0]
        this.reasonWhat = res[1]
        this.reasonWhy1 = res[2]
        this.partInfoContents = [
          this.result.plant, this.result.partnumber, this.result.supplier,
          this.datepipe.transform(this.result.pkgstartdate, 'MM/dd/yyyy')
        ]
        this.reasonWhatDropdown = this.reasonWhat
        this.selectedValue = this.reasonWhatDropdown[0].code
        this.reasonWhyDropdown = this.reasonWhy1
        this.selectedValue1 = this.reasonWhyDropdown[0].code
this.spinnerService.stop()
      })
  }
  update() {
    if (this.newPart.length === 10) {
      const ppfConfirm = {
        "plantCode": this.result.plantcode,
        "partNumber": this.result.partnumber,
        "supplierCode": this.result.suppliercode,
        "pkgStartDate": this.result.pkgstartdate,
        "newPartNumber": this.newPart,
      }
      sessionStorage.setItem('ppfUpdate', JSON.stringify(ppfConfirm))

      this.dialog.open(UpdateConfirmComponent);
    }
    else {
      alert("NewPartNumber must contain 10 characters")
    }
  }

  ok(){
    this.router.navigate([`/part-list/ppf/${this.ppfUpdate?.PPFCode}/${this.ppfUpdate?.PPFVer}/${1}/${1}`]);
  }
  help(){
    this.router.navigate(['./master-help',2,"Tracking",146])
  }
}
