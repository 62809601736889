import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PurDecListService } from 'src/app/internal-vpacs/services-internal/authorize/pur-dec-list.service';

@Component({
  selector: 'app-update-fab',
  templateUrl: './update-fab.component.html',
  styleUrls: ['./update-fab.component.scss']
})
export class UpdateFabComponent implements OnInit {
  

  plants: any[] = [];
  plant: any = '';
  authnumber: any ='';
  alphacode: any = '';
  filteredPlants: any[] = [];

  constructor(
    private readonly purDecListService: PurDecListService,
    public readonly dialogRef: MatDialogRef<UpdateFabComponent>,
    private readonly router:Router,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    @Inject(MAT_DIALOG_DATA) public data : {id:number,id1:number},
    private readonly spinnerService: NgxUiLoaderService,
  ) { }
  ngOnInit(): void {
    this.apiCall();
  }
  apiCall() {
    setTimeout(() => {this.spinnerService.start()});
    this.authnumber=this.data.id
    this.purDecListService.getFabricators(this.authnumber).subscribe({
      next : (res:any) =>{
        this.plants = res;
        this.plant = res[7].pkgsupcode;
        this.filteredPlants = [...this.plants];
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete : ()=>{
        this.spinnerService.stop();
      },
    })
  }
  updateFabricator(){
    this.spinnerService.start();
    const body: any ={
      "AuthNumber": this.authnumber,
      "FabCode": this.plant
    }
    this.purDecListService.updateFabricator(body).subscribe({
      next : (_res:any) =>{
        this.dialogRef.close({
          event: 'SaveEvent',
          responseType: 'Success'
        });
      },
      error: (_err: any) => {
        this.dialogRef.close({
          event: 'SaveEvent',
          responseType: 'error'
        });
        this.spinnerService.stop();
      },
      complete : ()=>{
        this.spinnerService.stop();
      },
    })
  }
  deleteFabricator(){
    const body: any ={
      "AuthNumber": this.authnumber,
    }
    this.spinnerService.start();
    this.purDecListService.deleteFabricator(body).subscribe({
      next : (res:any) =>{
        this.dialogRef.close({
          event: 'onDeletePress',
          responseType: res.status
        });
      },
      error: (_err: any) => {
        this.dialogRef.close({
          event: 'onDeletePress',
          responseType: 'error'
        });
        this.spinnerService.stop();
      },
      complete : ()=>{
        this.spinnerService.stop();
      },
    })
  }
  onSearch(e: any){
    const data = e.currentTarget.value;
    this.filteredPlants = [...this.plants.filter(currentplant => currentplant.pkgsupcode.toUpperCase().startsWith(data.toUpperCase()))];
  this.plant = this.filteredPlants[0].pkgsupcode;
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }
  closeDialog(){
    this.dialogRef.close()
  }
  
  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',5,"Master Maintenance",149 ])
    }
}
