import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectionChangedEvent } from 'ag-grid-community';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegexFilter } from '../../regexFilter/regex-filter';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-rack-shipment',
  templateUrl: './add-rack-shipment.component.html',
  styleUrls: ['./add-rack-shipment.component.scss'],
  providers: [RegexFilter],
})
export class AddRackShipmentComponent implements OnInit {

  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  isAccessible = false;
  public domLayout:any ='';
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  selectedRow:any=[]

  parentClick:any;
  pageName: any;
  rackDataShip: any;
  rackDataShip2: any;
  rackDataDetail: any;
  filterData: any;
  filteredData: any;
  public searchRack = new FormGroup({
    serial: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data:any,
    public router: Router,
    private readonly newShipmentService : NewshipmentService,
    private readonly location: Location,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public regex:RegexFilter
    ) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,     
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.parentClick=data.clicked
  }

  ngOnInit()  {

    let curplace:any = '';
    if(sessionStorage.hasOwnProperty("rackData")){
      this.rackData = JSON.parse(sessionStorage.getItem("rackData")|| '');
    }
    if(this.router.url.includes('/shipment/rackShipmentDetail')){
      if(sessionStorage.hasOwnProperty("rackData3")){
        this.rackDataShip = JSON.parse(sessionStorage?.getItem("rackData3")|| '');
      }
      this.rackDataDetail = this.rackDataShip[2][0].SHIPTYPE
      if(this.rackDataDetail === '1'){
        curplace = 2
      }else if(this.rackDataDetail === '2' || this.rackDataDetail === '3'){
        curplace = 3
      }else if(this.rackDataDetail === '4'){
        curplace = 1
      }else{
        curplace = 4
      }
    } 
    else {
      if(sessionStorage.hasOwnProperty("rackData2")){
        this.rackDataShip2 = JSON.parse(sessionStorage?.getItem("rackData2")|| '');
      }
      if(this.rackDataShip2 === 1){
        curplace = 2
      }else if(this.rackDataShip2 === 2 || this.rackDataShip2 === 3){
        curplace = 3
      }else if(this.rackDataShip2 === 4){
        curplace = 1
      }else{
        curplace = 4
      }
    }

    const params={
      packageCode : this.rackData.PACKAGECODE,
      authNumber : this.rackData.AUTHNUMBER,
      curPlace : curplace,
      supplierCode : this.rackData.SUPPLIERCODE,
      plantCode : this.rackData.PLANTCODE,
      dockCode : this.rackData.DOCKCODE,
      fabCode : this.rackData.CONTFAB
     }
     
    //call onLoad API
    if(this.router.url.includes('/shipment/rackShipmentDetail')){
      this.newShipmentService.RAddButtonDataDetail(params).subscribe((res: any)=> {
        this.displayRowData = res;
        this.rowCount = this.displayRowData.length;
      })
    } else {
      this.newShipmentService.RAddButtonData(params).subscribe((res: any)=> {
      this.displayRowData = res;
      this.rowCount = this.displayRowData.length;   
    });
    }

    //set grid columns
    this.columnDefs = [
      {
        headerName: "All", field: "", width: 50, 
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      { headerName: "Serial Number", field: "SERIALNUMBER",  width: 100 },
      { headerName: "Auth Number", field: "CREATEDAUTHNUMBER",  width: 100 }   
    ];
  }

  onAdd(){
    this.parentClick(this.selectedRow)
  }

  onCancel(){
    this.dialog.closeAll()
  }
  
  onSelectionChanged(event: SelectionChangedEvent) {
    const rows = event.api.getSelectedNodes();
    const data=rows.map(r=>{
      return (r.data)
    })
    this.selectedRow=data
  }

  onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
  }

  onSearch(){
    this.filterData = this.displayRowData; 
    const filterParams: any = {
      SERIALNUMBER: this.searchRack.get('serial')?.value ? this.searchRack.get('serial')?.value : '*'
    };
    this.filteredData = this.regex.regexFilter(this.filterData,filterParams)
    this.gridApi.setRowData(this.filteredData);
    this.rowCount = this.filteredData.length; 
  }

}
