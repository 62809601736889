import { Component, Inject, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message-popup-toast',
  templateUrl: './message-popup-toast.component.html',
  styleUrls: ['./message-popup-toast.component.scss']
})
export class MessagePopupToastComponent  {
  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:any) { }  

}
