<div class="popup-container">
    <h2 mat-dialog-title class="p-2 shadow-sm" style="font-size: 20px">{{data.header}}</h2>
    <div mat-dialog-content>
        <p class="p-2">{{data.message}}</p>
    </div>
    
    <div mat-dialog-actions class="d-flex flex-row-reverse align-items-center">
    <button class="black-button mx-2" (click)="dialogData('data')">Yes</button>
    <div mat-dialog-close class="text-primary mx-4" style="cursor: pointer;"  (click)="dialogData('NoData')">No</div>
    </div>
    <br>
  </div>