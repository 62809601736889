import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingListBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/tracking-list-btn-cell-renderer';
import { SelectCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/select-cell-renderer';
import { ExportStateService } from 'src/app/services/export-state.service';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';
import { SupplierInfoComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/supplier-info/supplier-info.component';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-si-dept-approval',
  templateUrl: './si-dept-approval.component.html',
  styleUrls: ['./si-dept-approval.component.scss']
})
export class SiDeptApprovalComponent implements OnInit {
  leaderPlant: any[] = [];
  leaderPlantContents: any[] = [];
  qpc: any[] = [];
  qpcContents: any[] = [];
  work: any[] = [];
  workContents: any[] = [];
  radioVal: any;
  radioValWork: any;
  columnDefs: any[] = [];
  defaultColDef: any;
  isService = false;
  gridApi: any;
  rowClassRules: any;
  displayRowData: any[] = []
  columnDefs1: any[] = [];
  defaultColDef1: any;
  gridApi1: any;
  displayRowData1: any[] = []
  public rowSelection: 'single' | 'multiple' = 'multiple';
  paramData: any;
  userInfo: any;
  roleInfo: any;
  details: any;
  dateSentVal: any;
  crrStep: any;
  stepStatus: any;
  stepStatusArray: any[] = [];
  leaderFlg: any;
  displayFlg: any;
  urlVer: any;
  showWorkTable = false;
  rdoContFabWorkChecked = false;
  rdoSupWorkChecked = false;
  rdoDun1FabWorkChecked = false;
  rdoContFabWorkDisabled = false;
  rdoSupWorkDisabled = false;
  rdoDun1FabWorkDisabled = false;
  trSupplierConfirmDisplay = false;
  hideControlbtnRejectBM = false;
  hideControlbtnRejectSup = false;
  hideControlbtnReject = false;
  hideControlbtnApprove = false;
  hideControlbtnSend = false;
  btnSendDisabled = false;
  tdSharePlant: any;
  hideControltblReqAsFinal = false;
  isConceptCompareDisabled = false;
  taNoteValue: any;
  taIntComment: any;
  taExtComment: any;
  taIntCommentDisable = false;
  taExtCommentDisable = false;
  tdDock1: any;
  tdCrrDept1: any;
  tdDock2: any;
  tdCrrDept2: any;
  tdDock3: any;
  tdCrrDept3: any;
  tdDock4: any;
  tdCrrDept4: any;
  tdDock5: any;
  tdCrrDept5: any;
  dvdock: any;
  dvspec: any;
  aldock: any;
  defaultApprover: any;
  rdoYesDisabled = false;
  rdoNoDisabled = false;
  rdoYesChecked = false;
  rdoNoChecked = false;
  chkSelDock1Checked = false;
  chkSelDock1Disabled = false;
  selDock1Disabled = false;
  btnSelDock1Disabled = false;
  chkSelDock2Checked = false;
  chkSelDock2Disabled = false;
  selDock2Disabled = false;
  btnSelDock2Disabled = false;
  chkSelDock3Checked = false;
  chkSelDock3Disabled = false;
  selDock3Disabled = false;
  btnSelDock3Disabled = false;
  chkSelDock4Checked = false;
  chkSelDock4Disabled = false;
  selDock4Disabled = false;
  btnSelDock4Disabled = false;
  chkSelDock5Checked = false;
  chkSelDock5Disabled = false;
  selDock5Disabled = false;
  btnSelDock5Disabled = false;
  selectdvspec1: any[] = [];
  chkSelDock1: any;
  selDock1: any;
  selectdvspec2: any[] = [];
  chkSelDock2 = false;
  selDock2: any;
  selectdvspec3: any[] = [];
  chkSelDock3 = false;
  selDock3: any;
  selectdvspec4: any[] = [];
  chkSelDock4 = false;
  selDock4: any;
  selectdvspec5: any[] = [];
  chkSelDock5 = false;
  selDock5: any;
  sharePlants: any;
  dvchld: any;
  tdSharePlantOK: any;
  tdSharePlantNG: any;
  btnSharePlantsDisabled = false;
  chkReqAsFinal = false;
  taFinalReason: any;
  chkReqAsFinalDisabled = false;
  taFinalReasonDisabled = false;
  spCode: any;
  errorMessage: any
  err = '099  - 001 :Runtime Error : Contact a System Administrator.'
  ppfVer: any;
  trackingUrl = '/tracking-list'

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly trackingListService: TrackingListService,
    private readonly constants: ConstantsService
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res?.info
        this.roleInfo = res?.role
        if (res) {
          this.paramData = JSON.parse(sessionStorage.getItem('deptApprove') || '{}');
          this.spCode = this.userInfo.specialistcode;
          if (this.paramData) {
            this.siDeptApprDetails()
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    })
    this.leaderPlant = [
      'Leader Plant', 'Plant', 'Dock', 'Model', 'Supplier', 'Part Number', 'Specialist', 'Date sent'
    ]

    this.qpc = [
      'QPC', 'Full Cont. Weight'
    ]

    this.work = [
      'Supplier', 'Cont Fab', 'Dun1 Fab'
    ]

    this.columnDefs = [
      {
        headerName: 'SHARE PLANT', field: 'sharePlant'
      },
      {
        headerName: 'OK', field: 'ok'
      },
      {
        headerName: 'NG', field: 'ng'
      },
      {
        headerName: 'DETAIL', field: 'detail', cellRendererFramework: TrackingListBtnCellRenderer
      }
    ]

    this.displayRowData = [{ sharePlant: '-', ok: '-', ng: '-' }]

    this.columnDefs1 = [
      {
        headerName: "",
        field: '',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        minWidth: 40, maxWidth: 40
      },
      {
        headerName: 'DOCK', field: 'dock'
      },
      {
        headerName: 'STATUS', field: 'status'
      },
      {
        headerName: 'CONV APPROVER', field: 'convApprover', cellRendererFramework: SelectCellRenderer
      },
      {
        headerName: 'DETAIL', field: 'detail', cellRendererFramework: TrackingListBtnCellRenderer
      }
    ]

    this.displayRowData1 = [
      { field: '5A', status: '-' },
      { field: '5A', status: '-' },
      { field: '5A', status: '-' },
    ]
  }


  siDeptApprDetails() {
    this.leaderPlantContents = []
    this.qpcContents = []
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      PPFCode: this.paramData?.ppfcode,
      PPFver: this.paramData?.ppfver,
      step: '2',
    }
    this.trackingListService.sendDepartmentApproval(loadObj).subscribe({
      next: (res: any) => {
        this.details = res
        const plantCode = this.constants.getTypeFromPlantCode(this.paramData.plantcode)
        if (plantCode === 'SERVICE' || plantCode === 'ASSY') {
          this.isService = true;
          this.radioVal = 0;
          this.chkReqAsFinal = true;
          this.taFinalReason = plantCode === 'SERVICE' ? 'Service Parts' : 'Accessory Parts';
        }
        this.dateSent()
        const items = [
          this.paramData?.leaderplant,
          this.paramData?.plantname,
          this.details?.dv_tblInfo?.[0]?.dockcode !== undefined ? this.details.dv_tblInfo[0].dockcode : '',
          this.paramData?.modelcode,
          this.paramData?.suppliername,
          this.paramData?.partnumber,
          this.paramData?.specialist,
          this.dateSentVal
        ]
        this.leaderPlantContents.push(...items)

        const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
          && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
            this.changeSize(this.details?.tdFullCont, 1),
            1,
          ) : ""

        const qpcItems = [
          this.details?.qtyPartCont,
          fullContStore === "" ? "-" : fullContStore
        ]
        this.qpcContents.push(...qpcItems)

        this.taFinalReasonDisabled = (plantCode === 'SERVICE' || plantCode === 'ASSY') ? true : (this.chkReqAsFinal ? false : true)
        this.loadParameter(res)

        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
    })
  }

  loadParameter(data: any) {
    this.crrStep = data?.getCurrentStepApprove
    this.stepStatus = data?.stepStatus
    if (this.stepStatus?.length > 0) {
      this.stepStatusArray = Object.entries(this.stepStatus[0]);
    }

    this.leaderFlg = data?.leaderFlag
    this.displayFlg = data?.displayFlag // Need to check with backend
    this.displayFlg = [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 1, 0]
    this.urlVer = data?.url_ver
    this.ppfVer = data?.url_ver;
    // follower
    if (this.leaderFlg === 1) {
      this.displayFlg[16] = 1;
      this.displayFlg[17] = 0;
      this.displayFlg[18] = 1;
      this.displayFlg[19] = 0;
      this.displayFlg[20] = 0;
      this.displayFlg[21] = 0;
      this.ppfVer = ''
    }

    this.createHtml_dispControl(data)
  }

  createHtml_dispControl(data: any) {
    const displayFlg = this.displayFlg
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData.plantcode)
    // working
    if (displayFlg[2] === 1) {
      this.showWorkTable = true
      this.createHtml_tblWork()
    }

    // concept compare
    if (this.crrStep === 2) {
      this.isConceptCompareDisabled = true
    }

    if (!this.roleInfo?.p_costview) {
      this.isConceptCompareDisabled = true
    }

    if (this.crrStep === 4 || this.crrStep === 7 || this.crrStep === 9) {
      this.taNoteValue = this.details?.t_Note_GetComment ??
        (this.details?.createData_supQuestionnaire?.commentData !== '' ? this.details?.createData_supQuestionnaire?.commentData :
          this.details?.createData_supQuestionnaire?.questionnaire[0]?.note)
    }

    // request as final
    if (displayFlg[11] === 1) {
      this.createHtml_RequestAsFinal(data)
    } else {
      this.hideControltblReqAsFinal = true
    }
    if (plantCode !== 'SERVICE' && plantCode !== 'ASSY') {
      this.createHtml_intappDock(data)
    }
    else {
      this.chkSelDock1Disabled = true;
      this.chkSelDock2Disabled = true;
      this.chkSelDock3Disabled = true;
      this.chkSelDock5Disabled = true;
      this.chkSelDock4Checked = true;

    }
    this.createHtml_commentIntSup(data)
    this.createHtml_sharePlants(data)


    // send button
    if (displayFlg[16] === 1) {
      // display

      if (this.tdSharePlant === '-' && this.leaderFlg !== 1) {
        this.btnSendDisabled = true
      }
    } else {
      this.hideControlbtnSend = true
    }

    // approve button
    if (displayFlg[17] === 1) {
      this.hideControlbtnApprove = false
    } else {
      this.hideControlbtnApprove = true
    }

    // reject button
    if (displayFlg[18] === 1) {
      this.hideControlbtnReject = false
    } else {
      this.hideControlbtnReject = true
    }

    // reject to bm/supplier button
    if (displayFlg[19] === 1 && displayFlg[20] === 1) {
      this.hideControlbtnRejectBM = false
      this.hideControlbtnRejectSup = false
    } else if (displayFlg[19] === 0 && displayFlg[20] === 0) {
      this.hideControlbtnRejectBM = true
      this.hideControlbtnRejectSup = true
    }
  }

  createHtml_commentIntSup(data: any) {
    try {
      // input data

      const objcommentIntSup = data?.createHtml_commentIntSup

      this.taIntComment = this.nullStr(objcommentIntSup['dv_taIntComment'])
      this.taExtComment = this.nullStr(objcommentIntSup['dv_taExtComment'])


      if (
        this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
        this.leaderFlg === 1
      ) {
        this.taIntCommentDisable = true
        this.taExtCommentDisable = true
      }
    } catch (error) {
      return
    }
  }

  createHtml_RequestAsFinal(data: any) {
    try {
      const dv: any = data?.createHtml_RequestAsFinal

      const screenID = 720
      if (screenID !== 720 || this.leaderFlg === 1) {
        if (this.nullInt(dv?.finalFlag) === 1) {
          this.chkReqAsFinal = true;
        }
        if (this.nullStr(dv?.finalReason) !== '') {
          this.taFinalReason = this.nullStr(dv?.finalReason)
        }

        this.chkReqAsFinalDisabled = true;
        this.taFinalReasonDisabled = true;
      }
    } catch (error) {
      return
    }
  }

  onChange(event: any) {
    if (!event.target.checked) {
      this.chkReqAsFinal = false;
      this.taFinalReasonDisabled = true;
    }
    else {
      this.chkReqAsFinal = true;
      this.taFinalReasonDisabled = false;
    }
  }

  createHtml_intappDock(data: any) {
    try {
      // start assignment
      // set value
      this.tdDock1 = '-'
      this.tdCrrDept1 = '-'

      this.tdDock2 = '-'
      this.tdCrrDept2 = '-'

      this.tdDock3 = '-'
      this.tdCrrDept3 = '-'

      this.tdDock4 = '-'
      this.tdCrrDept4 = '-'

      this.tdDock5 = '-'
      this.tdCrrDept5 = '-'

      this.dvdock = data?.dv_dock
      this.dvspec = data?.dv_spec.result
      this.aldock = data?.tdDock
      this.defaultApprover = data?.defaultApprover
      // end assignment
      const strDocks = data?.getIntApproveDocks

      if (strDocks === '') {
        this.radioVal = 0
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true
        this.rdoYesChecked = false
        this.rdoNoChecked = true

        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnSelDock1Disabled = true

        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnSelDock2Disabled = true

        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnSelDock3Disabled = true

        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnSelDock4Disabled = true

        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnSelDock5Disabled = true

        this.taIntCommentDisable = true

        return
        //here to start tomorrow
      } else if (strDocks === null) {
        return
      }

      let dockStatus = 0

      // dock - row 1
      if (this.aldock && this.aldock.length >= 1) {
        // dock name
        this.tdDock1 = this.nullStr(this.aldock[0])

        // dataview

        for (const iterator of this.dvspec) {
          if (this.nullStr(iterator['divcode']) === 'CV') {
            this.selectdvspec1.push({
              specialist: iterator['specialist'],
              specialistcode: iterator['specialistcode'],
            })
          }
        }

        if (!this.checkNull(this.dvspec) && this.dvspec.length >= 1) {
          // status
          if (!this.checkNull(this.dvdock) && this.dvdock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvdock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvdock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvdock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvdock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock1Checked = false
              this.tdCrrDept1 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 0) {
                this.tdCrrDept1 = '-'
              } else if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept1 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept1 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept1 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept1 = 'Complete'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvspec1) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvdock[0]['cvcode'])
                ) {
                  this.selDock1 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvspec1) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock1 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvspec1.length < 1) {
            this.chkSelDock1Checked = false
            this.chkSelDock1Disabled = true
            this.selDock1Disabled = true
            this.btnSelDock1Disabled = true
          }
          else {
            this.chkSelDock1Checked = true
          }
        } else {
          this.chkSelDock1Checked = false
          this.chkSelDock1Disabled = true
          this.selDock1Disabled = true
          this.btnSelDock1Disabled = true
        }
      } else {
        this.chkSelDock1Checked = false
        this.chkSelDock1Disabled = true
        this.selDock1Disabled = true
        this.btnSelDock1Disabled = true
      }

      // dock - row2
      if (this.aldock && this.aldock.length >= 2) {
        // dock name
        this.tdDock2 = this.nullStr(this.aldock[1])

        // dataview

        if (!this.checkNull(this.dvspec) && this.dvspec.length >= 1) {
          // conv approver

          for (const iterator of this.dvspec) {
            if (this.nullStr(iterator['divcode']) === 'CV') {
              this.selectdvspec2.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvdock) && this.dvdock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvdock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvdock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvdock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvdock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock2Checked = false
              this.tdCrrDept2 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept2 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept2 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept2 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept2 = 'Complete'
              } else {
                this.tdCrrDept2 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvspec2) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvdock[0]['cvcode'])
                ) {
                  this.selDock2 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvspec2) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock2 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvspec2.length < 1) {
            this.chkSelDock2Checked = false
            this.chkSelDock2Disabled = true
            this.selDock2Disabled = true
            this.btnSelDock2Disabled = true
          }
          else {
            this.chkSelDock2Checked = true
          }
        } else {
          this.chkSelDock2Checked = false
          this.chkSelDock2Disabled = true
          this.selDock2Disabled = true
          this.btnSelDock2Disabled = true
        }
      } else {
        this.chkSelDock2Checked = false
        this.chkSelDock2Disabled = true
        this.selDock2Disabled = true
        this.btnSelDock2Disabled = true
      }

      // dock - row3
      if (this.aldock && this.aldock.length >= 3) {
        // dock name
        this.tdDock3 = this.nullStr(this.aldock[2])

        // dataview

        if (!this.checkNull(this.dvspec) && this.dvspec.length >= 1) {
          // conv approver

          for (const iterator of this.dvspec) {
            if (this.nullStr(iterator['divcode']) === 'CV') {
              this.selectdvspec3.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvdock) && this.dvdock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvdock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvdock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvdock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvdock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock3Checked = false
              this.tdCrrDept3 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept3 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept3 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept3 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept3 = 'Complete'
              } else {
                this.tdCrrDept3 = '-'
              }
            }

            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvspec3) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvdock[0]['cvcode'])
                ) {
                  this.selDock3 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvspec3) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock3 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvspec3.length < 1) {
            this.chkSelDock3Checked = false
            this.chkSelDock3Disabled = true
            this.selDock3Disabled = true
            this.btnSelDock3Disabled = true
          }
          else {
            this.chkSelDock3Checked = true
          }
        } else {
          this.chkSelDock3Checked = false
          this.chkSelDock3Disabled = true
          this.selDock3Disabled = true
          this.btnSelDock3Disabled = true
        }
      } else {
        this.chkSelDock3Checked = false
        this.chkSelDock3Disabled = true
        this.selDock3Disabled = true
        this.btnSelDock3Disabled = true
      }

      // dock - row4
      if (this.aldock && this.aldock.length >= 4) {
        // dock name
        this.tdDock4 = this.nullStr(this.aldock[3])

        // dataview

        if (!this.checkNull(this.dvspec) && this.dvspec.length >= 1) {
          // conv approver

          for (const iterator of this.dvspec) {
            if (this.nullStr(iterator['divcode']) === 'CV') {
              this.selectdvspec4.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvdock) && this.dvdock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvdock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvdock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvdock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvdock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock4Checked = false
              this.tdCrrDept4 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept4 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept4 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept4 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept4 = 'Complete'
              } else {
                this.tdCrrDept4 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvspec4) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvdock[0]['cvcode'])
                ) {
                  this.selDock4 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvspec4) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock4 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvspec4.length < 1) {
            this.chkSelDock4Checked = false
            this.chkSelDock4Disabled = true
            this.selDock4Disabled = true
            this.btnSelDock4Disabled = true
          }
          else {
            this.chkSelDock4Checked = true
          }
        } else {
          this.chkSelDock4Checked = false
          this.chkSelDock4Disabled = true
          this.selDock4Disabled = true
          this.btnSelDock4Disabled = true
        }
      } else {
        this.chkSelDock4Checked = false
        this.chkSelDock4Disabled = true
        this.selDock4Disabled = true
        this.btnSelDock4Disabled = true
      }

      // dock - row5
      if (this.aldock && this.aldock.length >= 5) {
        // dock name
        this.tdDock5 = this.nullStr(this.aldock[4])

        // dataview

        if (!this.checkNull(this.dvspec) && this.dvspec.length >= 1) {
          // conv approver
          for (const iterator of this.dvspec) {
            if (this.nullStr(iterator['divcode']) === 'CV') {
              this.selectdvspec5.push({
                specialist: iterator['specialist'],
                specialistcode: iterator['specialistcode'],
              })
            }
          }

          // status
          if (!this.checkNull(this.dvdock) && this.dvdock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(this.dvdock[0]['int1status'])
            } else if (this.crrStep === 4) {
              dockStatus = this.nullInt(this.dvdock[0]['int2status'])
            } else if (this.crrStep === 7) {
              dockStatus = this.nullInt(this.dvdock[0]['int3status'])
            } else if (this.crrStep === 9) {
              dockStatus = this.nullInt(this.dvdock[0]['int4status'])
            }

            if (this.nullInt(dockStatus) === 5) {
              this.chkSelDock5Checked = false
              this.tdCrrDept5 = 'Skip'
            } else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept5 = 'Conv'
              } else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept5 = 'Mfg'
              } else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept5 = 'Qc'
              } else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept5 = 'Complete'
              } else {
                this.tdCrrDept5 = '-'
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectdvspec5) {
                if (
                  iterator.specialistcode ===
                  this.nullStr(this.dvdock[0]['cvcode'])
                ) {
                  this.selDock5 = iterator.specialistcode
                  break
                }
              }
            } else {
              if (this.defaultApprover !== '') {
                for (const iterator of this.selectdvspec5) {
                  if (iterator.specialistcode === this.defaultApprover) {
                    this.selDock5 = iterator.specialistcode
                    break
                  }
                }
              }
            }
          }

          if (this.selectdvspec5.length < 1) {
            this.chkSelDock5Checked = false
            this.chkSelDock5Disabled = true
            this.selDock5Disabled = true
            this.btnSelDock5Disabled = true
          }
          else {
            this.chkSelDock5Checked = true
          }
        } else {
          this.chkSelDock5Checked = false
          this.chkSelDock5Disabled = true
          this.selDock5Disabled = true
          this.btnSelDock5Disabled = true
        }
      } else {
        this.chkSelDock5Checked = false
        this.chkSelDock5Disabled = true
        this.selDock5Disabled = true
        this.btnSelDock5Disabled = true
      }

      // set disable(send)
      if (this.stepStatusArray[this.crrStep - 1][1] === '13') {
        this.rdoYesDisabled = true
        this.rdoNoDisabled = true

        this.chkSelDock1Disabled = true
        this.chkSelDock2Disabled = true
        this.chkSelDock3Disabled = true
        this.chkSelDock4Disabled = true
        this.chkSelDock5Disabled = true

        this.selDock1Disabled = true
        this.selDock2Disabled = true
        this.selDock3Disabled = true
        this.selDock4Disabled = true
        this.selDock5Disabled = true

        // internal approvement y/n
        if (
          (this.tdCrrDept1 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept2 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept3 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept4 === '-' || this.tdCrrDept1 === 'Skip') &&
          (this.tdCrrDept5 === '-' || this.tdCrrDept1 === 'Skip')
        ) {
          this.radioVal = 0
          this.rdoNoChecked = true
          this.rdoYesChecked = false
        } else {
          this.radioVal = 1
          this.rdoYesChecked = true
          this.rdoNoChecked = false
        }
      }

      if (this.stepStatusArray[this.crrStep - 1][1] === '1') {
        this.btnSelDock1Disabled = true
        this.btnSelDock2Disabled = true
        this.btnSelDock3Disabled = true
        this.btnSelDock4Disabled = true
        this.btnSelDock5Disabled = true
      }
    } catch (error) {
    }
  }

  createHtml_sharePlants(data: any) {
    try {
      this.sharePlants = data?.createHtml_sharePlants

      const countOK = this.sharePlants?.countOK
      const countNG = this.sharePlants?.countNG
      const cntWorking = this.sharePlants?.cntWorking

      // child plants
      this.dvchld = data?.dv_chld

      if (this.checkNull(this.dvchld)) {
        this.tdSharePlant = '-'
        this.tdSharePlantOK = '-'
        this.tdSharePlantNG = '-'
        this.btnSharePlantsDisabled = true
        return
      }

      if (
        this.leaderFlg === 1 ||
        this.stepStatusArray[this.crrStep - 1][1] === '1'
      ) {
        this.btnSharePlantsDisabled = true
      }

      this.tdSharePlant = this.nullStr(this.dvchld.length)

      // count OK or NG

      if (cntWorking > 0 || this.dvchld.length === countNG + countOK) {
        this.btnSendDisabled = true
      }

      this.tdSharePlantOK = countOK.toString()
      this.tdSharePlantNG = countNG.toString()
    } catch (error) {
      return
    }
  }

  createHtml_tblWork() {
    const working = Number(this.details?.nowWorking)
    const filteredData = this.trackingListService.getWorkTableData()
    if (filteredData === undefined || filteredData === '') {
      switch (working) {
        case 2:
          this.rdoContFabWorkChecked = true
          this.radioValWork = 1;
          break
        case 6:
          this.rdoSupWorkChecked = true
          this.radioValWork = 2;
          break
        case 8:
          this.rdoDun1FabWorkChecked = true
          this.radioValWork = 3;
          break
        default:
          break
      }
    }
    else {
      this.radioValWork = filteredData;
      this.rdoContFabWorkChecked = this.radioValWork === 1 ? true : false
      this.rdoSupWorkChecked = this.radioValWork === 2 ? true : false
      this.rdoDun1FabWorkChecked = this.radioValWork === 3 ? true : false
    }
    if (
      this.stepStatusArray &&
      this.stepStatusArray[this.crrStep - 1] &&
      this.stepStatusArray[this.crrStep - 1][1] !== '1' ||
      this.leaderFlg === 1
    ) {
      this.rdoContFabWorkDisabled = true
      this.rdoSupWorkDisabled = true
      this.rdoDun1FabWorkDisabled = true
    }
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData.plantcode)
    if (plantCode === 'SERVICE' || plantCode === 'ASSY') {
      this.radioValWork = 2
      this.rdoContFabWorkChecked = false
      this.rdoSupWorkChecked = true
      this.rdoDun1FabWorkChecked = false
      this.rdoContFabWorkDisabled = true
      this.rdoSupWorkDisabled = false
      this.rdoDun1FabWorkDisabled = true
    }
    this.trackingListService.setWorkTableData(this.radioValWork)
  }

  checkNull(value: any) {
    try {
      if (Object.prototype.toString.call(value) === '[object Array]') {
        return false
      }
      else if (Object.prototype.toString.call(value) === '[object Object]') {
        return false
      }
      else if (
        value === null ||
        value === undefined ||
        value.trim() === '' ||
        value === 'undefined' ||
        value === 'null'
      ) {
        return true
      }
      else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  nullInt(obj: any) {
    if (obj === null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  nullStr(value: any): any {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  changeSize(obj: any, type: any) {
    if (obj === null) {
      return 0
    } else {
      const size = this.nullDouble(obj)
      if (this.userInfo?.unit === '0') {
        return size
      } else {
        if (type === 0) {
          return this.changeInch(size)
        } else if (type === 1) {
          return this.changePound(size)
        }
        return 0
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj.toString())
        } else {
          return parseInt(obj.toString())
        }
      } catch {
        return 0
      }
    }
  }

  changeInch(size: any) {
    return Math.round((size / 25.4) * 2) / 2
  }

  changePound(size: any) {
    return (size * 1000) / 453.59237
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size)
      } else {
        return this.formatPoundNull(size)
      }
    } else {
      if (type === 0) {
        return this.formatMmNull(size)
      } else {
        return this.formatkgNull(size)
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return '0'
    }
    return size.toFixed(2)
  }

  formatPoundNull(size: any) {
    if (size === 0) {
      return ''
    }
    return size.toFixed(1)
  }

  formatMmNull(mm: any) {
    if (mm === 0) {
      return ''
    }
    return mm.toFixed(2)
  }

  formatkgNull(kg: any) {
    if (kg === 0) {
      return ''
    }
    return kg.toFixed(2)
  }


  dateSent() {
    if (this.details?.dv_date !== null && this.details?.dv_date?.length > 0) {
      this.dateSentVal = '-'
      if (this.nullStr(this.details?.dv_date[0]['datesent']) !== '') {
        this.dateSentVal = formatDate(this.details?.dv_date[0]['datesent'], 'MM/dd/yyyy', 'en-US', 'UTC')
      }
    } else {
      this.dateSentVal = '-'
    }
  }

  internalAppChange(event: any) {
    if (event.value === 0) {
      this.rdoYesChecked = false;
      this.rdoNoChecked = true;
    }
    else if (event.value === 1) {
      this.rdoYesChecked = true;
      this.rdoNoChecked = false;
    }
  }

  onWorkChange(event: any) {
    this.radioValWork = event.value
    this.trackingListService.setWorkTableData(this.radioValWork)
  }

  approve() {
    if (this.paramData.suppliercode === 'SSSSS') {
      this.errorMessage = ['Please update the supplier code']
      window.scrollTo(0, 0);
    }
    else {
      this.spinnerService.start();

      this.errorMessage = [];
      const stepStatus = Object.values(this.stepStatus[0]).map(Number);

      const obj = {
        leaderFlg: this.nullInt(this.leaderFlg),  // (variable) page load API will assign this value 
        crrStep: this.crrStep, // (variable) page load API will assign this value 
        stepStatus: stepStatus, // (variable) page load API will assign this value 
        rdoYes: this.radioVal === 1 ? true : false, // (Internal Approvement) radio button
        rdoNo: this.radioVal === 0 ? true : false,  // (Internal Approvement) radio button
        PlantCode: this.paramData?.plantcode, // query param
        PartNumber: this.paramData?.partnumber, // query param
        SupplierCode: this.paramData?.suppliercode, // query param
        SupplierName: this.paramData?.suppliername,
        PkgStartDate: this.paramData?.pkgstartdate, // query param 
        ppfCode: this.paramData?.ppfcode, // query param
        UserName: this.userInfo?.p_specialistcode, // userInfo  Specialist Name
        SpecialistCode: this.userInfo?.p_specialistcode,
        ModelCode: this.paramData?.modelcode,  // (variable) page load API will assign this value 
        rdoSupWork: this.radioValWork === 2 ? true : false,  // Work radio button
        rdoContFabWork: this.radioValWork === 1 ? true : false, // Work radio button
        rdoDun1FabWork: this.radioValWork === 3 ? true : false, // Work radio button
        chkReqAsFinal: this.chkReqAsFinal, // Request as Final checkbox
        taFinalReason: this.taFinalReason ?? '',  // Reason text Area
        selDock1_SelectedIndex: this.selDock1 ? 1 : 0, // Conv Approver Select List (index)
        selDock2_SelectedIndex: this.selDock2 ? 1 : 0, // Conv Approver Select List (index)
        selDock3_SelectedIndex: this.selDock3 ? 1 : 0, // Conv Approver Select List (index)
        selDock4_SelectedIndex: this.selDock4 ? 1 : 0,  // Conv Approver Select List (index)
        selDock5_SelectedIndex: this.selDock5 ? 1 : 0, // Conv Approver Select List (index)
        taIntComment: this.taIntComment,  // Internal Comment textArea
        taExtComment: this.taExtComment, // Supplier/Fabricator Comment textArea
        chkSelDock1: this.chkSelDock1Checked, // table check box
        chkSelDock2: this.chkSelDock2Checked, // table check box
        chkSelDock3: this.chkSelDock3Checked, // table check box
        chkSelDock4: this.chkSelDock4Checked, // table check box
        chkSelDock5: this.chkSelDock5Checked, // table check box
        tdDock1: this.tdDock1, // Dock column read only
        tdDock2: this.tdDock2, // Dock column read only
        tdDock3: this.tdDock3, // Dock column read only
        tdDock4: this.tdDock4, // Dock column read only
        tdDock5: this.tdDock5, // Dock column read only
        rdoTrialYes: false,  // keep it false | not applicable for SI SE 
        rdoTrialNo: false,
        taTriFinalReason: "",
        taRequestChanges: '', // keep it empty |  not applicable for SI SE 
        taReasonRequested: '', // keep it empty |  not applicable for SI SE 
        chkSelDock1_Disabled: this.chkSelDock1Disabled,
        chkSelDock2_Disabled: this.chkSelDock3Disabled,
        chkSelDock3_Disabled: this.chkSelDock3Disabled,  // table check box 3 | send disabled or not
        chkSelDock5_Disabled: this.chkSelDock5Disabled, // table check box 5 | send disabled or not
        chkSelDock4_Checked: this.chkSelDock4Checked, // table check box 4 | send checked or not
        selDock1: this.selDock1,  // Conv Approver Select List (value)
        selDock2: this.selDock2,  // Conv Approver Select List (value)
        selDock3: this.selDock3, // Conv Approver Select List (value)
        selDock4: this.selDock4, // Conv Approver Select List (value)
        selDock5: this.selDock5, // Conv Approver Select List (value)
        rdoAsFinal: false, // keep it false | not applicable for SI SE 
        cblRequested: [{ "Selected": true, "Value": "" }], // page load will assign  | not applicable for SI SE
        cblReason: [{ "Selected": true, "Value": "" }],
      }
      this.trackingListService.siBtnApprove(obj).subscribe({
        next: (res: any) => {
          if (res.status === 'SUCCESS') {
            this.trackingListService.resetWorkTableData();
            sessionStorage.removeItem('partnumber');
            sessionStorage.removeItem('deptApprove');
            this.router.navigateByUrl(this.trackingUrl);
          } else{
            this.errorMessage = (res?.message)?(res?.message):[this.err];
          }
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.errorMessage = (_err.error?.error?.message)?(_err.error?.error?.message): [this.err]
          window.scrollTo(0, 0);
          this.spinnerService.stop();
        },
      })
    }
  }

  send() {
    this.spinnerService.start();
    this.errorMessage = [];
    const stepStatus = Object.values(this.stepStatus[0]).map(Number);

    const obj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      leaderFlg: this.nullInt(this.leaderFlg),
      crrStep: this.crrStep,
      stepStatus: stepStatus,
      rdoYes: this.rdoYesChecked,
      approval: true
    }
    this.trackingListService.siBtnSend(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.trackingListService.resetWorkTableData();
          this.router.navigateByUrl(this.trackingUrl);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  rejectBm() {
    this.spinnerService.start();
    this.errorMessage = [];

    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep
    }
    this.trackingListService.siBtnRejectBm(obj).subscribe({
      next: (res: any) => {
        if (res?.result?.result) {
          this.trackingListService.resetWorkTableData();
          this.router.navigateByUrl(this.trackingUrl);
        }
        else {
          this.errorMessage = [res?.result?.message];
          window.scrollTo(0, 0);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  rejectSup() {
    this.spinnerService.start();
    this.errorMessage = [];

    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.rdoSupWorkChecked,
      rdoContFabWork: this.rdoContFabWorkChecked,
      rdoDun1FabWork: this.rdoDun1FabWorkChecked,
      taExtComment: this.taExtComment,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      FileList: '',
      taRequestChanges: '',
      taReasonRequested: '',
      rdoAsFinal: false,
      rdoTrialNo: false,
      cblRequested: [{ "Selected": true, "Value": "" }],
      cblReason: [{ "Selected": true, "Value": "" }],
      PPFCode: this.paramData?.ppfcode
    }
    this.trackingListService.siBtnRejectSup(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.trackingListService.resetWorkTableData();
          this.router.navigateByUrl(this.trackingUrl);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }

  reject() {
    this.spinnerService.start();
    this.errorMessage = [];

    const obj = {
      leaderFlg: this.leaderFlg,
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.rdoSupWorkChecked,
      rdoContFabWork: this.rdoContFabWorkChecked,
      rdoDun1FabWork: this.rdoDun1FabWorkChecked,
      taExtComment: this.taExtComment,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      FileList: '',
      taRequestChanges: '',
      taReasonRequested: '',
      cblRequested: [{ "Selected": true, "Value": "" }],
      cblReason: [{ "Selected": true, "Value": "" }],
      PPFCode: this.paramData?.ppfcode
    }
    this.trackingListService.siBtnReject(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.trackingListService.resetWorkTableData();
          this.router.navigateByUrl(this.trackingUrl);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        this.spinnerService.stop();
        window.scrollTo(0, 0);
      },
    })
  }
  openSupplierInfo() {
    const params = {
      plant: this.paramData?.plantname,
      partNumber: this.paramData?.partnumber,
      supplier: this.paramData?.suppliername,
      fromDate: this.paramData?.pkgstartdate,
      plantC: this.paramData?.plantcode,
      model: this.paramData?.modelcode,
      pkgStartDate: this.paramData?.pkgstartdate,
      supplierC: this.paramData?.suppliercode
    }
    const dialogRef = this.dialog.open(SupplierInfoComponent, {
      data: params
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result?.event === 'UpdateEvent') {
        this.spinnerService.start();
        this.paramData.suppliername = result?.data?.supName
        this.paramData.suppliercode = result?.data?.newSupplier
        sessionStorage.setItem('deptApprove', JSON.stringify(this.paramData))
        this.siDeptApprDetails();
        this.errorMessage = [];
      }
    });
  }

  cancel() {
    sessionStorage.removeItem('partnumber');
    sessionStorage.removeItem('deptApprove');
    this.trackingListService.resetWorkTableData();
    this.router.navigate([this.trackingUrl]);
  }

  PPF() {
    sessionStorage.setItem('ppfcloseFromTracking', '0')
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
    if (this.ppfVer === '') {
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber', this.paramData?.partnumber)
        this.router.navigate(['/tracking-list/send-dept-approval/serviceparts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 2])
      } 
      else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber)
        this.router.navigate(['/tracking-list/send-dept-approval/accessoryParts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 2])
      }
      else {
        window.open(`/tracking-list/send-dept-approval/ppf/${this.paramData.ppfcode}/${this.ppfVer}/1/2`, '_blank');
      }
    } else {
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.paramData?.plantcode);
        this.router.navigate(['/tracking-list/send-dept-approval/serviceparts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 1])
      } 
      else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber', this.paramData?.partnumber);
        sessionStorage.setItem('ppfPlantCode', this.paramData?.plantcode);
        this.router.navigate(['/tracking-list/send-dept-approval/accessoryParts-pdf', this.paramData.ppfcode, this.ppfVer, 1, 1])
      }
      else {
        window.open(`/tracking-list/send-dept-approval/ppf/${this.paramData.ppfcode}/${this.ppfVer}/1/1`, '_blank');
      }
    }
    sessionStorage.setItem('ppfcloseFromTracking', '1')
  }

  conceptCompare() {
    const params = {
      ppfcode1: this.paramData?.ppfcode,
      ppfver1: this.details?.url_ver,
      ppfcode2: this.paramData?.ppfcode,
      ppfver2: -1
    }
    sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
    this.router.navigateByUrl('/tracking-list/send-dept-approval/concept-compare');
  }

  goToDeptAppr() {
    const params = {
      leaderPlant: this.paramData?.leadername,
      plant: this.paramData?.plantcode,
      plantName: this.paramData?.plantname,
      dock: this.tdDock1,
      modelCode: this.paramData?.modelcode,
      supplier: this.paramData?.suppliercode,
      supplierName: this.paramData?.suppliername,
      specialist: this.paramData?.specialist,
      part: this.paramData?.partnumber,
      psd: formatDate(this.paramData?.pkgstartdate, 'yyyy-MM-dd', 'en-US', 'UTC'),
      dateSent: this.dateSentVal !== '-' ? this.dateSentVal : 'null',
      mode: 1,
      step: 2,
      currentIntNo: this.details?.getIntApprove?.result[0]?.currentintno ?? 0,
      currentIntVer: this.details?.getIntApprove?.result[0]?.currentintver ?? 0,
    }
    sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
    this.router.navigateByUrl('/tracking-list/send-dept-approval/dept-approve');
  }

  goToIntAppr() {
    this.router.navigateByUrl('/internal-approvement')
  }

}
