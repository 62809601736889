import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { SpinnerComponent } from './spinner.component';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  reqCount = 0;
  constructor(private readonly spinner: SpinnerComponent) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {  
    // Check if the route is "/tracking" and if so, skip the interceptor
    if (request.url.includes('/api/tracking/count?') || 
        request.url.includes('/api/tracking?supplierCode=') || 
        (request.url.includes('/api/authorityList') && request.method === 'POST') || 
        (request.url.includes('/api/pkgAuthorisationCount?supplierCode=')) ||
        (request.url.includes('/api/pkgAuthorisation?supplierCode=')) ||
        (request.url.includes('api/trackingList/OnLoad')) ||
        (request.url.includes('api/cost/OnLoadAuthList'))
        ) {
      return next.handle(request);
    }

    // If it's not "/tracking," show the spinner and proceed with the request
    this.reqCount++;
    this.spinner.showSpinner();
    return next.handle(request).pipe(
      finalize(() => {
        this.reqCount--;
        if(this.reqCount === 0){
          this.spinner.hideSpinner();
        }
      })
    );
  }


}