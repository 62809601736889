<div class="popup-container">
    <div class="header text-middle">
        Download Status
    </div>
    <small class="m-3 p-3 text-danger" *ngIf="!data.fromDate">* From Date is Mandatory</small>
    <div mat-dialog-content class="link-btn">
        <button class="black-button" (click)="downloadProduction()" [disabled]="disableButtons">Production Status</button>
        <button class="black-button" (click)="downloadBackup()" [disabled]="disableButtons">Backup Status</button>              
    </div>
    <button (click)="onNoClick()" class="close black-button">X</button>
</div>



