<div class="popup-container" *ngIf="pageName !== 'delete'">
  <div class="d-flex justify-content-between align-items-center header">
    <span *ngIf="!profile" class="page-title">{{pageName}} Specialist </span>
    <span *ngIf="profile" class="page-title">Maintain My Profile </span>

    <button class="help-btn" (click)="help()">Help</button>
  </div>
  <div class="alert alert-danger m-3" role="alert" *ngIf = "errorArray.length > 0">
    <span *ngFor="let message of errorArray">{{message}}</span>
  </div>
  <div class="popup-contents" [formGroup] = "userForm">
    <div class="d-flex flex-wrap m-3 align-items-start">
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4">Code<small>*</small></label>
        <input type="text" class="col-8" formControlName = "specialistcode" (change)="codeChange($event)"/>
        <div class="col-12  d-flex" *ngIf="userForm.get('specialistcode').invalid && (userForm.get('specialistcode').dirty || userForm.get('specialistcode').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('specialistcode').hasError('required')">
            Specialist code is required
          </small>
          <small class="error col-8" *ngIf="userForm.get('specialistcode').hasError('codeError')">
            {{userForm.controls.specialistcode.errors.codeError}}
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4">First Name<small>*</small></label>
        <input type="text" class="col-8" formControlName = "firstname"/>
        <div class="col-12 d-flex" *ngIf="userForm.get('firstname').invalid && (userForm.get('firstname').dirty || userForm.get('firstname').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('firstname')?.hasError('required')">
              First Name is required
          </small>
          <small class="error col-8" *ngIf="userForm.get('firstname').hasError('firstNameError')">
            {{userForm.controls.firstname.errors.firstNameError}}
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4">Last Name<small>*</small></label>
        <input type="text" class="col-8" formControlName = "lastname"/>
        <div class="col-12 d-flex" *ngIf="userForm.get('lastname').invalid && (userForm.get('lastname').dirty || userForm.get('lastname').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('lastname')?.hasError('required')">
              Last Name is required
          </small>
          <small class="error col-8" *ngIf="userForm.get('lastname').hasError('lastNameError')">
            {{userForm.controls.lastname.errors.lastNameError}}
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4 no-label">Middle Initial</label>
        <input type="text" class="col-8" formControlName = "middleinitial"/>
        <div class="col-12 d-flex" *ngIf="userForm.get('middleinitial').invalid && (userForm.get('middleinitial').dirty || userForm.get('middleinitial').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('middleinitial').hasError('middleInitialError')">
            {{userForm.controls.middleinitial.errors.middleInitialError}}
          </small>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-center m-3">
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="plant" class="col-4">Plant<small>*</small></label>
        <input type="text" id="plant" *ngIf="!profile" matInput formControlName="plantcode" class="mat-input me-1" [placeholder]="'--Select Plant--'"
        aria-label="text" matInput [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnPlant.bind(this)">
        <mat-option *ngFor="let x of filterOptions | async" [value]="x.code">{{x.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-select id="plant" *ngIf="profile" class="mat-sel col-6" placeholder="Select" formControlName="plantcode" [disabled]="profile">
        <mat-option *ngFor="let x of plant" [value]="x.plantcode">{{ x.plant }}</mat-option>
      </mat-select>
        <div class="col-12 d-flex" *ngIf="userForm.get('plantcode').invalid && (userForm.get('plantcode').dirty || userForm.get('plantcode').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="!profile && userForm.get('plantcode')?.hasError('required')">
              Plantcode is required
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="title" class="col-4">Title<small>*</small></label>
        <mat-select id="title" class="mat-sel material-select" placeholder="Select" formControlName = "title" [disabled]="profile">
          <mat-option *ngFor="let x of title" [value]="x.value">{{x.desc}}</mat-option>
        </mat-select>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="supervisor" class="col-4">Supervisor<small>*</small></label>
        <mat-select id="supervisor" class="mat-sel" placeholder="Select" formControlName = "boss" >
          <mat-option *ngFor="let x of bossList" [value]="x.bosscode">{{x.boss}}</mat-option>
        </mat-select>
        <div class="col-12 d-flex" *ngIf="userForm.get('boss').invalid && (userForm.get('boss').dirty || userForm.get('boss').touched)">
          <label class="col-4 invisible"></label>
          <small *ngIf="!profile && userForm.get('boss')?.hasError('required')"  class="error col-8" >
            Supervisor is required
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="division" class="col-4">Divison<small>*</small></label>
        <mat-select id="division" class="mat-sel" placeholder="Select" formControlName = "divcode" [disabled]="profile">
          <mat-option *ngFor="let x of div" [value]="x.divcode">{{x.divcode}}</mat-option>
        </mat-select>
        <div class="col-12 d-flex" *ngIf="userForm.get('divcode').invalid && (userForm.get('divcode').dirty || userForm.get('divcode').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('divcode')?.hasError('required')">
              Division is required
          </small>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-start m-3">
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4">Phone<small>*</small></label>
        <input type="text" class="col-6" formControlName = "phone"/>
        <div class="col-12 d-flex" *ngIf="userForm.get('phone').invalid && (userForm.get('phone').dirty || userForm.get('phone').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('phone')?.hasError('required')">
              Phone is required
          </small>
          <small class="error col-8" *ngIf="userForm.get('phone').hasError('phoneError')">
            {{userForm.controls.phone.errors.phoneError}}
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4 no-content">Fax</label>
        <input type="text" formControlName = "fax"/>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4 no-content-label">Ext</label>
        <input type="text" formControlName = "ext"/>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="name" class="col-4">E-mail<small>*</small></label>
        <input type="text" class="col-8" formControlName = "email"/>
        <small class="error col-8" *ngIf="userForm.get('email').hasError('emailError')">
          {{userForm.controls.email.errors.emailError}}
        </small>
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-center m-3">
      <div class="d-flex col-lg-3 col-md-6 col-12 align-items-center">
        <label id="backup" class="col-4">Back Up</label>
        <div class="col-8 d-flex">
          <div class = "checkcontainer mb-0">
          <input type="checkbox" class="me-3" formControlName="backupCheck" (change)="backupCheckChange($event)" />
          <span class="checkmark"></span>
          </div>
          <input type="text" formControlName="backup" style="max-width: 65%" class="mat-input me-1" [placeholder]="'--Select--'"
          aria-label="text" matInput [matAutocomplete]="auto2">
        <mat-autocomplete  #auto2="matAutocomplete" [displayWith]="displayFnBackupUpdate.bind(this)">
         <mat-option *ngFor = "let x of backUpListUpdate | async" [value]="x.substitutecode">{{x.substitute}}</mat-option>
        </mat-autocomplete>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="unit" class="col-4">Unit<small>*</small></label>
        <mat-select id="unit" class="mat-sel col-6" placeholder="Select" formControlName = "unit">
          <mat-option *ngFor = "let x of unit" [value] = "x.value">{{x.desc}}</mat-option>
        </mat-select>
        <div class="col-12 d-flex" *ngIf="userForm.get('unit').invalid && (userForm.get('unit').dirty || userForm.get('unit').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('unit')?.hasError('required')">
              Unit is required
          </small>
        </div>
      </div>
      <div *ngIf="!profile" class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="role" class="col-4">Role<small>*</small></label>
        <mat-select id="role" class="mat-sel col-6" placeholder="Select" formControlName = "roles" multiple>
          <mat-option *ngFor="let x of role" [value]="x.rolecode || x.code">{{x.name}}</mat-option>
        </mat-select>
        <div class="col-12 d-flex" *ngIf="userForm.get('roles').invalid && (userForm.get('roles').dirty || userForm.get('roles').touched)">
          <label class="col-4 invisible"></label>
          <small class="error col-8" *ngIf="userForm.get('roles')?.hasError('required')">
              Roles is required
          </small>
        </div>
      </div>
      <div class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="dock" class="col-4 no-content">Dock</label>
        <mat-select id="dock" class="mat-sel col-6" placeholder="Select" formControlName = "dockcodes" multiple>
          <mat-option *ngFor = "let x of dockList" [value]="x">{{x}}</mat-option>
        </mat-select>
      </div>
    </div>

    <!-- changes -->
    <div *ngIf="!profile" class="d-flex flex-wrap align-items-center m-3">
      <div class="d-flex col-lg-3 col-md-6 col-12 align-items-center gap-2">
        <label class="me-3">Group User</label>
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            formControlName = "groupMemberCheck"
            (change)="groupMemberCheckChange($event)"
            [disabled]="enableRadio"
          >
            <mat-radio-button class="example-radio-button"  [value]=1>
              Yes
            </mat-radio-button>
            <mat-radio-button class="mx-3 example-radio-button" [value]=0 >
              No
            </mat-radio-button>
          </mat-radio-group>
      </div>
      <div *ngIf="!profile" class="d-flex flex-wrap col-lg-3 col-md-6 col-12 align-items-center">
        <label id="dock" class="col-4 no-content">Groups</label>
        <mat-select id="role" class="mat-sel col-6" placeholder="Select" formControlName = "groupMember" multiple>
          <mat-option *ngFor="let x of group; let i = index" [value]="x" >{{x}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="d-flex flex-wrap align-items-center m-3 ">
      <div class="d-flex col-12 align-items-center" >
        <label class="me-3">Responsible Production</label>
        <div class="checkcontainer mb-0" 
          >Pre Production
          <input  type="checkbox" formControlName = "preprod" />
          <span class="{{ profile ? 'checkmark disable' : 'checkmark' }}"></span>
        </div>
        <div class="checkcontainer mb-0 mx-3"
          >Mass Production
          <input type="checkbox" formControlName = "massprod" />
          <span class="{{ profile ? 'checkmark disable' : 'checkmark' }}"></span>
        </div>
      </div>
    </div>

    <div *ngIf="!profile" class="d-flex flex-wrap align-items-center m-3">
      <div class="d-flex align-items-center gap-2">
        <label class="me-3">Valid</label>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          formControlName = "isValid"
        >
          <mat-radio-button class="example-radio-button"  [value]= 1>
            Yes
          </mat-radio-button>
          <mat-radio-button class="mx-3 example-radio-button" [value]=0>
            No
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
    <button class="cancel me-3" (click)="close()">CANCEL</button>
    <button *ngIf="!profile" class="black-button me-3" (click)="submitForm()">{{pageName}}</button>
    <button *ngIf="profile" class="black-button me-3" (click)="submitForm()">Update</button>
  </div>
  <button class="close black-button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="d-flex justify-content-center">
  <div class="popup-container p-4 w-50" *ngIf="pageName === 'delete'">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
    <div mat-dialog-content>
      <p class="p-2">Are you sure that you want to delete this User?</p>
    </div>
    <div class="d-flex flex-row-reverse align-items-center">
      <div mat-dialog-close class="text-primary mx-3">No</div>
      <button class="black-button mx-3" (click)="deleteUser()">Yes</button>
    </div>
  </div>
</div>
