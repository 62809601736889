import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-summary-router-list-cell-renderer',
  template: `
  <a *ngIf="text !== '0'" (click)="openDialog()" style="text-decoration:underline">{{text}}</a>
  <span *ngIf="text === '0'" style="color: black;">{{text}}</span>
  `,
})

export class SummaryRouterLinkCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog) { }
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;
  warn : any;
  delay : any;
  good : any;
  ofRecords = '# of Records';
  modelcode : any;
  plantCode : any;
  specialistCode : any;
  scrFlg : any;
  page : any;
  stepAll = false;
 
  agInit(params: any): void {
    this.params = params;
    this.specialistCode = this.params.data?.specialistcode || "";
    this.scrFlg = 0;
    this.modelcode = this.params.data.modelcode;
    this.plantCode = this.params.data?.plantcode || "";
    this.warn = 0;
    this.delay = 0;
    this.good = 0;
    this.page = 'TrackingSummary';
    this.stepAll=true
    if (params.colDef.headerName === this.ofRecords) {
      this.text = this.params.data?.count;
    }
    else if (params.colDef.headerName === 'Delay') {
      this.text = this.params.data?.delay;
    }
    else if (params.colDef.headerName === 'Warn') {
      this.text = this.params.data?.warn;
    }
    else if (params.colDef.headerName === 'Good') {
      this.text = this.params.data?.good;
    }
  }
 
  openDialog() {
   
   
    if (this.params.colDef.headerName === this.ofRecords) {
      const count = Number(this.params.data.count);
      if(count > 0){
        this.warn = 1;
        this.delay = 1;
        this.good = 1
      };
    }
    else if (this.params.colDef.headerName === 'Delay') {
      const delay = Number(this.params.data.delay);
      if(delay > 0){
        this.delay = 1;
      };
    }
    else if (this.params.colDef.headerName === 'Warn') {
      const warn = Number(this.params.data.warn);
      if(warn > 0){
        this.warn = 1;
      };
    }
    else if (this.params.colDef.headerName === 'Good') {
      const good = Number(this.params.data.good);
      if( good > 0){
        this.good = 1;
      }
    }
   
    if (this.params.colDef.headerName === this.ofRecords ||
        this.params.colDef.headerName === 'Delay' ||
        this.params.colDef.headerName === 'Warn' ||
        this.params.colDef.headerName === 'Good'){
      this.router.navigate(['/tracking-summary/tracking-list',this.modelcode,this.plantCode,this.specialistCode,this.stepAll,this.delay,this.warn,this.good,this.scrFlg])
    }
  }

}
