import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service'
import { formatDate } from '@angular/common'
import * as FileSaver from 'file-saver-es'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-concept-compare',
  templateUrl: './concept-compare.component.html',
  styleUrls: ['./concept-compare.component.scss'],
})
export class ConceptCompareComponent implements OnInit {
  rowClassRules: any
  titleContents: any
  titleInfoContents: any
  backupSpecification: any
  titleContents2: any
  desc = 'Description'
  manu = 'Manufacturer'
  mat = 'Material'
  pkgCode = 'Package Code'
  leadTime = 'Lead Time'
  re = 'R/E'
  qcr = 'Quantity/Cont/Rack'
  estCost = 'EST Cost (P)'
  comm = 'Comment'
  tables: any[] = [
    {
      data: [
        { column1: 'Step', column2: '', column3: '' },
        { column1: 'Date', column2: '', column3: '' },
        { column1: 'Plant', column2: '', column3: '' },
        { column1: 'Model', column2: '', column3: '' },
        { column1: 'TLMS', column2: '', column3: '' },
        { column1: 'ECI', column2: '', column3: '' },
        { column1: 'Dock', column2: '', column3: '' },
      ],
      button: [{ column1: 'SELECT', column2: 'DOWNLOAD' }],
      plant: [
        { column1: 'Number', column2: '', column3: '' },
        { column1: 'Description', column2: '', column3: '' },
        { column1: 'Replaces Number', column2: '', column3: '' },
      ],
      heading: [{ column1: 'Part' }],

      comment: [{column1:'Comment', column2: 'Name', column3: '' }],
      supplier: [
        { column1: 'Name', column2: '', column3: '' },
        { column1: 'Code', column2: '', column3: '' },
        { column1: 'Contact', column2: '', column3: '' },
        { column1: 'Phone', column2: '', column3: '' },
        { column1: 'FAX', column2: '', column3: '' },
        { column1: 'Mail', column2: '', column3: '' },
        { column1: 'Pickup Location', column2: '', column3: '' },
      ],
      supHeading: [{ column1: 'Supplier' }],

      hazmat: [{ column1: 'Hazmat' , column2: '', column3: '' }],

      container: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      dunnage1: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      dunnage2: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      topCap: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      pallet: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      stretch: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      kanban: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      other: [
        { column1: this.desc, column2: '', column3: '' },
        { column1: this.manu, column2: '', column3: '' },
        { column1: this.mat, column2: '', column3: '' },
        { column1: this.pkgCode, column2: '', column3: '' },
        { column1: this.leadTime, column2: '', column3: '' },
        { column1: this.re, column2: '', column3: '' },
        { column1: this.qcr, column2: '', column3: '' },
        { column1: this.estCost, column2: '', column3: '' },
        { column1: this.comm, column2: '', column3: '' },
      ],
      contHeading: [
        {
          column1: 'Container',
          column2: 'Dunnage1',
          column3: 'Dunnage2',
          column4: 'Top Cap',
          column5: 'Pallet',
          column6: 'Stretch Wrap/Strap',
          column7: 'Kanban Card Holder',
          column8: 'Other',
        },
      ],

      pkgHeading: [{ column1: 'Pkg Material' }],

      pkgDataHeading: [
        {
          column1: 'Pkg Data',
          column2: 'Part Size(mm)',
          column3: 'Container(mm)',
          column4: 'Pallet Only(mm)',
          column5: 'Pallet Load(mm)',
          column6: 'Weight (kg)',
          column7: 'Quantities',
          column8: 'Comment',
        },
      ],

      partsize: [
        { column1: 'Length', column2: '', column3: '' },
        { column1: 'Width', column2: '', column3: '' },
        { column1: 'Height', column2: '', column3: '' },
      ],

      containers: [
        { column1: 'Outside Length', column2: '', column3: '' },
        { column1: 'Outside Width', column2: '', column3: '' },
        { column1: 'Outside Height', column2: '', column3: '' },
        { column1: 'Inside Length', column2: '', column3: '' },
        { column1: 'Inside Width', column2: '', column3: '' },
        { column1: 'Inside Height', column2: '', column3: '' },
        { column1: 'Return Height', column2: '', column3: '' },
      ],

      palletonly: [
        { column1: 'Length', column2: '', column3: '' },
        { column1: 'Width', column2: '', column3: '' },
        { column1: 'Height', column2: '', column3: '' },
      ],

      palletload: [
        { column1: 'Length', column2: '', column3: '' },
        { column1: 'Width', column2: '', column3: '' },
        { column1: 'Height', column2: '', column3: '' },
      ],

      weight: [
        { column1: 'Part', column2: '', column3: '' },
        { column1: 'Dunnage', column2: '', column3: '' },
        { column1: 'Container', column2: '', column3: '' },
        { column1: 'Pallet', column2: '', column3: '' },
        { column1: 'Container Gross', column2: '', column3: '' },
        { column1: 'Pallet Load Gross', column2: '', column3: '' },
      ],

      Quantities: [
        { column1: 'Qty Parts/Container', column2: '', column3: '' },
        { column1: 'Container/Layer on Pallet', column2: '', column3: '' },
        { column1: 'Layer/Pallet', column2: '', column3: '' },
      ],
    },
  ]
  ppfCode1: any
  ppfCode2: any
  ppfVer1: any
  ppfVer2: any
  details: any
  paramData: any
  isDownloaded1 = false
  isDownloaded2 = false
  unit: any
  differnce: any
  showDifference = false
  supplierType: any

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly iaService: InternalApprovementListService,
    private readonly state: ExportStateService
  ) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.spinnerService.start()

    this.paramData = JSON.parse(sessionStorage.getItem('conceptCompareDetails') || '{}')
    this.ppfCode1 = this.paramData.ppfcode1;
    this.ppfVer1 = this.paramData.ppfver1;
    this.ppfCode2 = this.paramData.ppfcode2;
    this.ppfVer2 = this.paramData.ppfver2;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.unit = Number(res.info?.unit);
        this.supplierType = res.role?.supType
        if(res){
          const unitLength = this.unit === 0 ? 'mm':'in'
          const unitWeight = this.unit === 0 ? 'kg':'lb'
          this.tables[0].pkgDataHeading[0].column2 = `Part Size (${unitLength})`
          this.tables[0].pkgDataHeading[0].column3 = `Container (${unitLength})`
          this.tables[0].pkgDataHeading[0].column4 = `Pallet Only (${unitLength})`
          this.tables[0].pkgDataHeading[0].column5 = `Pallet Load (${unitLength})`
          this.tables[0].pkgDataHeading[0].column6 = `Weight (${unitWeight})`
          this.loadComparingPPFDetails();
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    });
    this.titleContents = [
      'Step',
      'Date',
      'Plant',
      'Model',
      'TLMS',
      'ECI',
      'Dock',
    ]
    this.titleContents2 = ['Part']

    this.titleInfoContents = ['preliminary', 'preliminary', 'preliminary']
  }

  loadComparingPPFDetails() {
    const params: any = {
      Unit: this.unit,
      ppfcode1: this.ppfCode1,
      ppfcode2: this.ppfCode2,
      ppfver1: Number(this.ppfVer1),
      ppfver2: Number(this.ppfVer2),
      SupplierType: this.supplierType
    }
    this.iaService.loadComparingPPF(params).subscribe({
      next: (res: any) => {
        this.details = res
        this.loadComparingPPFDetails2();
        this.loadComparingPPFDetails3();
        this.loadComparingPPFDetails4();
        this.differnce = res.DifferenceCount
        if(this.differnce > 0){
          this.showDifference = true;
        }
        else{
          this.showDifference = false;
        }
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  loadComparingPPFDetails2(){
    this.tables[0].data.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 =
          this.details?.Result1[0]?.preorfinal === '1'
            ? 'Preliminary'
            : this.details?.Result1[0]?.preorfinal === '2'
            ? 'Final'
            : ''
        e.column3 =
          this.details?.Result2[0]?.preorfinal === '1'
            ? 'Preliminary'
            : this.details?.Result2[0]?.preorfinal === '2'
            ? 'Final'
            : ''
      }
      if (i === 1) {
        e.column2 = this.dateFormatter(this.details?.Result1[0]?.regdate)
        e.column3 = this.dateFormatter(this.details?.Result2[0]?.regdate)
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.plant
        e.column3 = this.details?.Result2[0]?.plant
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.modelcode
        e.column3 = this.details?.Result2[0]?.modelcode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.tlms_code
        e.column3 = this.details?.Result2[0]?.tlms_code
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.ecinumber
        e.column3 = this.details?.Result2[0]?.ecinumber
      }
      if (i === 6) {
        e.column2 = this.details?.Result1[0]?.dockcode
        e.column3 = this.details?.Result2[0]?.dockcode
      }
    })
    this.tables[0].plant.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.partnumber
        e.column3 = this.details?.Result2[0]?.partnumber
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.description
        e.column3 = this.details?.Result2[0]?.description
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.replacespartnumber
        e.column3 = this.details?.Result2[0]?.replacespartnumber
      }
    })
    this.tables[0].supplier.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.suppliername
        e.column3 = this.details?.Result2[0]?.suppliername
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.suppliercode
        e.column3 = this.details?.Result2[0]?.suppliercode
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.suppliercontact
        e.column3 = this.details?.Result2[0]?.suppliercontact
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.supplierphonenumber
        e.column3 = this.details?.Result2[0]?.supplierphonenumber
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.supplierfaxnumber
        e.column3 = this.details?.Result2[0]?.supplierfaxnumber
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.suppliermail
        e.column3 = this.details?.Result2[0]?.suppliermail
      }
      if (i === 6) {
        e.column2 = this.details?.Result1[0]?.supplierpickup
        e.column3 = this.details?.Result2[0]?.supplierpickup
      }
    })
    this.tables[0].hazmat.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 =
          this.details?.Result1[0]?.hazmat === ('N' || 'NO') ? 'NO' : 'YES'
        e.column3 =
          this.details?.Result2[0]?.hazmat === ('N' || 'NO') ? 'NO' : 'YES'
      }
    })
    //container
    this.tables[0].container.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.condescription
        e.column3 = this.details?.Result2[0]?.condescription
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.conmanufacturer
        e.column3 = this.details?.Result2[0]?.conmanufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.conmaterial
        e.column3 = this.details?.Result2[0]?.conmaterial
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.conpackagecode
        e.column3 = this.details?.Result2[0]?.conpackagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.conleadtime
        e.column3 = this.details?.Result2[0]?.conleadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.conretexp
        e.column3 = this.details?.Result2[0]?.conretexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.conqty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.conqty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.concostestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.concostestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.connote
        e.column3 = this.details?.Result2[0]?.connote
      }
    })
    //dunnage1
    this.tables[0].dunnage1.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.dun1description
        e.column3 = this.details?.Result2[0]?.dun1description
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.dun1manufacturer
        e.column3 = this.details?.Result2[0]?.dun1manufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.dun1material
        e.column3 = this.details?.Result2[0]?.dun1material
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.dun1packagecode
        e.column3 = this.details?.Result2[0]?.dun1packagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.dun1leadtime
        e.column3 = this.details?.Result2[0]?.dun1leadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.dun1retexp
        e.column3 = this.details?.Result2[0]?.dun1retexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.dun1qty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.dun1qty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.dun1costestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.dun1costestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.dun1note
        e.column3 = this.details?.Result2[0]?.dun1note
      }
    })
  }

  loadComparingPPFDetails3(){
     //dunnage2
     this.tables[0].dunnage2.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.dun2description
        e.column3 = this.details?.Result2[0]?.dun2description
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.dun2manufacturer
        e.column3 = this.details?.Result2[0]?.dun2manufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.dun2material
        e.column3 = this.details?.Result2[0]?.dun2material
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.dun2packagecode
        e.column3 = this.details?.Result2[0]?.dun2packagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.dun2leadtime
        e.column3 = this.details?.Result2[0]?.dun2leadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.dun2retexp
        e.column3 = this.details?.Result2[0]?.dun2retexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.dun2qty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.dun2qty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.dun2costestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.dun2costestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.dun2note
        e.column3 = this.details?.Result2[0]?.dun2note
      }
    })

    //topcap
    this.tables[0].topCap.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.topdescription
        e.column3 = this.details?.Result2[0]?.topdescription
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.topmanufacturer
        e.column3 = this.details?.Result2[0]?.topmanufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.topmaterial
        e.column3 = this.details?.Result2[0]?.topmaterial
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.toppackagecode
        e.column3 = this.details?.Result2[0]?.toppackagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.topleadtime
        e.column3 = this.details?.Result2[0]?.topleadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.topretexp
        e.column3 = this.details?.Result2[0]?.topretexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.topqty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.topqty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.topcostestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.topcostestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.topnote
        e.column3 = this.details?.Result2[0]?.topnote
      }
    })

    //pallet
    this.tables[0].pallet.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.paldescription
        e.column3 = this.details?.Result2[0]?.paldescription
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.palmanufacturer
        e.column3 = this.details?.Result2[0]?.palmanufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.palmaterial
        e.column3 = this.details?.Result2[0]?.palmaterial
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.palpackagecode
        e.column3 = this.details?.Result2[0]?.palpackagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.palleadtime
        e.column3 = this.details?.Result2[0]?.palleadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.palretexp
        e.column3 = this.details?.Result2[0]?.palretexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.palqty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.palqty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.palcostestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.palcostestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.palnote
        e.column3 = this.details?.Result2[0]?.palnote
      }
    })

    //stretch
    this.tables[0].stretch.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.strdescription
        e.column3 = this.details?.Result2[0]?.strdescription
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.strmanufacturer
        e.column3 = this.details?.Result2[0]?.strmanufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.strmaterial
        e.column3 = this.details?.Result2[0]?.strmaterial
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.strpackagecode
        e.column3 = this.details?.Result2[0]?.strpackagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.strleadtime
        e.column3 = this.details?.Result2[0]?.strleadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.strretexp
        e.column3 = this.details?.Result2[0]?.strretexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.strqty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.strqty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.strcostestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.strcostestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.strnote
        e.column3 = this.details?.Result2[0]?.strnote
      }
    })

    //kanban
    this.tables[0].kanban.forEach((e: any, i: any) => {
      if (i === 0) {
        e.column2 = this.details?.Result1[0]?.kandescription
        e.column3 = this.details?.Result2[0]?.kandescription
      }
      if (i === 1) {
        e.column2 = this.details?.Result1[0]?.kanmanufacturer
        e.column3 = this.details?.Result2[0]?.kanmanufacturer
      }
      if (i === 2) {
        e.column2 = this.details?.Result1[0]?.kanmaterial
        e.column3 = this.details?.Result2[0]?.kanmaterial
      }
      if (i === 3) {
        e.column2 = this.details?.Result1[0]?.kanpackagecode
        e.column3 = this.details?.Result2[0]?.kanpackagecode
      }
      if (i === 4) {
        e.column2 = this.details?.Result1[0]?.kanleadtime
        e.column3 = this.details?.Result2[0]?.kanleadtime
      }
      if (i === 5) {
        e.column2 = this.details?.Result1[0]?.kanretexp
        e.column3 = this.details?.Result2[0]?.kanretexp
      }
      if (i === 6) {
        e.column2 = this.formatQty(this.details?.Result1[0]?.kanqty)
        e.column3 = this.formatQty(this.details?.Result2[0]?.kanqty)
      }
      if (i === 7) {
        e.column2 = this.formatInchNull(this.details?.Result1[0]?.kancostestimate)
        e.column3 = this.formatInchNull(this.details?.Result2[0]?.kancostestimate)
      }
      if (i === 8) {
        e.column2 = this.details?.Result1[0]?.kannote
        e.column3 = this.details?.Result2[0]?.kannote
      }
    })
  }

  loadComparingPPFDetails4(){
 //other
 this.tables[0].other.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.othdescription
    e.column3 = this.details?.Result2[0]?.othdescription
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.othmanufacturer
    e.column3 = this.details?.Result2[0]?.othmanufacturer
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.othmaterial
    e.column3 = this.details?.Result2[0]?.othmaterial
  }
  if (i === 3) {
    e.column2 = this.details?.Result1[0]?.othpackagecode
    e.column3 = this.details?.Result2[0]?.othpackagecode
  }
  if (i === 4) {
    e.column2 = this.details?.Result1[0]?.othleadtime
    e.column3 = this.details?.Result2[0]?.othleadtime
  }
  if (i === 5) {
    e.column2 = this.details?.Result1[0]?.othretexp
    e.column3 = this.details?.Result2[0]?.othretexp
  }
  if (i === 6) {
    e.column2 = this.formatQty(this.details?.Result1[0]?.othqty)
    e.column3 = this.formatQty(this.details?.Result2[0]?.othqty)
  }
  if (i === 7) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.othcostestimate)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.othcostestimate)
  }
  if (i === 8) {
    e.column2 = this.details?.Result1[0]?.othnote
    e.column3 = this.details?.Result2[0]?.othnote
  }
})
// partsize
this.tables[0].partsize.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.partl
    e.column3 = this.details?.Result2[0]?.partl
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.partw
    e.column3 = this.details?.Result2[0]?.partw
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.parth
    e.column3 = this.details?.Result2[0]?.parth
  }
})
// pkg container
this.tables[0].containers.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.contl
    e.column3 = this.details?.Result2[0]?.contl
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.contw
    e.column3 = this.details?.Result2[0]?.contw
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.conth
    e.column3 = this.details?.Result2[0]?.conth
  }
  if (i === 3) {
    e.column2 = this.details?.Result1[0]?.incontl
    e.column3 = this.details?.Result2[0]?.incontl
  }
  if (i === 4) {
    e.column2 = this.details?.Result1[0]?.incontw
    e.column3 = this.details?.Result2[0]?.incontw
  }
  if (i === 5) {
    e.column2 = this.details?.Result1[0]?.inconth
    e.column3 = this.details?.Result2[0]?.inconth
  }
  if (i === 6) {
    e.column2 = this.details?.Result1[0]?.contreth
    e.column3 = this.details?.Result2[0]?.contreth
  }
})
// palletonly
this.tables[0].palletonly.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.palletonlyl
    e.column3 = this.details?.Result2[0]?.palletonlyl
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.palletonlyw
    e.column3 = this.details?.Result2[0]?.palletonlyw
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.palletonlyh
    e.column3 = this.details?.Result2[0]?.palletonlyh
  }
})
//palletload
this.tables[0].palletload.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.palletloadl
    e.column3 = this.details?.Result2[0]?.palletloadl
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.palletloadw
    e.column3 = this.details?.Result2[0]?.palletloadw
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.palletloadh
    e.column3 = this.details?.Result2[0]?.palletloadh
  }
})
//weight
this.tables[0].weight.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.partweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.partweight)
  }
  if (i === 1) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.dunnageweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.dunnageweight)
  }
  if (i === 2) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.contrackweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.contrackweight)
  }
  if (i === 3) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.palletweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.palletweight)
  }
  if (i === 4) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.contrackgrossweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.contrackgrossweight)
  }
  if (i === 5) {
    e.column2 = this.formatInchNull(this.details?.Result1[0]?.palletloadgrossweight)
    e.column3 = this.formatInchNull(this.details?.Result2[0]?.palletloadgrossweight)
  }
})
// Quantities
this.tables[0].Quantities.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.qtypartcontrack
    e.column3 = this.details?.Result2[0]?.qtypartcontrack
  }
  if (i === 1) {
    e.column2 = this.details?.Result1[0]?.qtymaxcontlayer
    e.column3 = this.details?.Result2[0]?.qtymaxcontlayer
  }
  if (i === 2) {
    e.column2 = this.details?.Result1[0]?.qtymaxlayerpallet
    e.column3 = this.details?.Result2[0]?.qtymaxlayerpallet
  }
})

//comment
this.tables[0].comment.forEach((e: any, i: any) => {
  if (i === 0) {
    e.column2 = this.details?.Result1[0]?.note
    e.column3 = this.details?.Result2[0]?.note
  }
})
  }
  selectBtn(point: any) {
    const params = {
      point: point,
      ppfcode : this.ppfCode1,
      ppfVer1: this.ppfVer1,
      ppfVer2: this.ppfVer2
    }
    sessionStorage.setItem('selectPPF', JSON.stringify(params));
    let uri = ''
    if (this.router.url === '/internal-approvement/dept-approve/concept-compare') {
      uri = '/internal-approvement/dept-approve/concept-compare/select-ppf'
    } else if (this.router.url.includes('auth-list')) {
       if(this.router.url.includes('/trial-order')){
        uri = '/auth-list/trial-order/concept-compare/select-ppf'
      }
    }
    else if (this.router.url.includes('tracking-list')) {
      if(this.router.url.includes('/hazmat-approval')){
        uri = '/tracking-list/hazmat-approval/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/send-dept-approval')){
        uri = '/tracking-list/send-dept-approval/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/sI-preppf-dept-approval')){
        uri = '/tracking-list/sI-preppf-dept-approval/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/si-pkgtrial-dept-approval')){
        uri = '/tracking-list/si-pkgtrial-dept-approval/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/sI-final-dept-approval')){
        uri = '/tracking-list/sI-final-dept-approval/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/trial-order')){
        uri = '/tracking-list/trial-order/concept-compare/select-ppf'
      }
      else if(this.router.url.includes('/send-ppf')){
        uri = '/tracking-list/send-ppf/concept-compare/select-ppf'
      }
      else if (this.router.url.includes('tracking-list/internal-approvement')) {
        uri = '/tracking-list/internal-approvement/dept-approve/concept-compare/select-ppf'
      }
    }
    else if (this.router.url.includes('hazmat-approve-list')) {
      uri = '/hazmat-approve-list/hazmat-approval/concept-compare/select-ppf'
    }

    this.router.navigateByUrl(uri)
  }

  downloadBtn(point: any) {
    this.spinnerService.start()

    const params: any = {
      PPFCode: point === 'first' ? this.ppfCode1 : this.ppfCode2,
      PPFVer: point === 'first' ? this.ppfVer1 : this.ppfVer2,
      Unit: this.unit,
      SupplierType: '',
    }
    this.iaService.downloadComparingData(params).subscribe({
      next: (res: any) => {
        const toConvert = res.Data
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`
        FileSaver.saveAs(file, res.Filename)
        if(point === 'first'){
          this.isDownloaded1 = true
        }
        else{
          this.isDownloaded2 = true
        }
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  public base64ToBlob(b64Data: any, sliceSize = 512) {
    const byteCharacters = Buffer.from(b64Data).toString('base64') //data.file there
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  }

  dateFormatter(params: any) {
    return params ? formatDate(params, 'MM/dd/YYYY', 'en-US') : ''
  }

  cancel() { 
    let uri = ''
    if(this.router.url === '/internal-approvement/dept-approve/concept-compare'){
      uri = '/internal-approvement/dept-approve'
    } 
    else if (this.router.url.includes('auth-list')) {
      if(this.router.url.includes('/trial-order')){
       uri = '/auth-list/trial-order'
     }
   }
    else if (this.router.url.includes('tracking-list')) {
      if(this.router.url.includes('/hazmat-approval')){
        uri = '/tracking-list/hazmat-approval'
      }
      else if(this.router.url.includes('/send-dept-approval')){
        uri = '/tracking-list/send-dept-approval'
      }
      else if(this.router.url.includes('/sI-preppf-dept-approval')){
        uri = '/tracking-list/sI-preppf-dept-approval'
      }
      else if(this.router.url.includes('/si-pkgtrial-dept-approval')){
        uri = '/tracking-list/si-pkgtrial-dept-approval'
      }
      else if(this.router.url.includes('/sI-final-dept-approval')){
        uri = '/tracking-list/sI-final-dept-approval'
      }
      else if(this.router.url.includes('/trial-order')){
        uri = '/tracking-list/trial-order'
      }
      else if(this.router.url.includes('/send-ppf')){
        uri = '/tracking-list/send-ppf'
      }
      else if (this.router.url.includes('tracking-list/internal-approvement')) {
        uri = '/tracking-list/internal-approvement/dept-approve'
      }
    }
    else if (this.router.url.includes('hazmat-approve-list')) {
      uri = '/hazmat-approve-list/hazmat-approval'
    }       
    sessionStorage.removeItem('conceptCompareDetails');
    this.router.navigateByUrl(uri)
  }
  
  help(){
    this.router.navigate(['./master-help',2,"Tracking",30])
  }
  formatInchNull(size: any) {
    const size2 = Number(size);
    if (size === 0) {
      return '0'
    }
    return size2.toFixed(2)
  }
  formatQty(val:any){
    const strval = Number(val).toFixed(2)

    if(strval.includes('.00') && strval !=='0.00'){
      return Math.round(Number(strval))
    }
    return strval
  }
}
