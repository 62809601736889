import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { IPlantType, ISupplierPlantDropdownsData } from 'src/app/internal-vpacs/interface/ISupplierPlant';
import { SupplierPlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier-plant.service';

@Component({
  selector: 'app-update-data',
  templateUrl: './update-data.component.html',
  styleUrls: ['./update-data.component.scss'],
})
export class UpdateDataComponent implements OnInit {
  plantName = '';
  supplierShortName = '';
  supplierCode = '';

  userPlant: any;
  selectedOpe: any = '';
  selectedDev: any = '';
  selectedSupplierContact: any = '';

  plantList: IPlantType[] = [];
  opeList: any[] = [];
  devList: any[] = [];
  supplierContactList: any[] = [];
  supplierType: any;
  errorList: string[] = [];
  plantCode: any;
  plCodeIndex: any;
  filteredOptions: any;

  constructor(
    private readonly dialogRef: MatDialogRef<UpdateDataComponent>,
    private readonly supplierPlantService: SupplierPlantService,
    private readonly spinner: NgxUiLoaderService,
    private readonly message: MatSnackBar, private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    this.dialogRef.disableClose = true;
    this.supplierCode = data.suppliercode;
    this.supplierShortName = data.suppliername;
    this.plCodeIndex = data?.plCodeIndex;
    const supInfo = data?.plancodes[this.plCodeIndex]?.supplierinfo;
    this.selectedOpe = supInfo?.opecode;
    this.selectedDev = supInfo?.devcode;
    this.plantList = data.plantlist;
    this.filteredOptions = this.plantList
    this.supplierType = data.supplierType;
    this.plantName = data?.plancodes[this.plCodeIndex]?.plantname;
    this.plantCode = data?.plancodes[this.plCodeIndex]?.plantcode;
  }

  ngOnInit(): void {
    this.subOnLoad();
  }

  getSpecialistCode() {
    return JSON.parse(sessionStorage.getItem('specialistCode') || '');
  }

  subOnLoad() {
    setTimeout(() => {
      this.spinner.start();
    });

    const body = {
      supplierType: this.supplierType,
      PlantCodes: this.getPlantCodes(this.plantList),
      SupplierCode: this.supplierCode,
      userSpecialistCode: this.getSpecialistCode(),
      specialistcode: '',
    };
    this.supplierPlantService.subOnLoad(body).subscribe({
      error: this.errorHandler,
      next: (res: ISupplierPlantDropdownsData) => {
        this.opeList = res.ope;
        this.devList = res.dev;
        this.supplierContactList = res.supplierContact;

        this.selectedOpe = this.opeList?.find(
          (operator) => operator.specialist === this.selectedOpe
        )?.specialistcode;
        if(this.selectedOpe === null || this.selectedOpe === '' || this.selectedOpe === undefined){
          this.selectedOpe = ''
        }

        this.selectedDev = this.devList?.find(
          (developer) => developer.specialist === this.selectedDev
        )?.specialistcode;
        if(this.selectedDev === null || this.selectedDev === '' || this.selectedDev === undefined){
          this.selectedDev = ''
        }
        this.spinner.stop();
      },
    });
  }

  errorHandler(_err: any) {
    this.spinner.stop();
  }

  getPlantSpecialistDropdown() {
    const plantCheck = this.plantList.find((item: any) => ((item.plant?.toLowerCase() === this.userPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.userPlant?.toLowerCase())))
    const plant = this.plantList.filter((item: any) => ((item.plant?.toLowerCase() === this.userPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.userPlant?.toLowerCase())))
    if(plantCheck){
    this.spinner.start();
    this.userPlant = plant[0]?.plantcode
    const body = {
      plantcode: [this.userPlant],
      specialistcode: '',
    };
    this.supplierPlantService.getPlantSpecialistDropdown(body).subscribe({
      error: this.errorHandler,
      next: (res: ISupplierPlantDropdownsData) => {
        this.opeList = res.ope;
        this.devList = res.dev;
        this.supplierContactList = res.supplierContact;
        this.selectedOpe =
          this.opeList?.find(
            (operator) => operator.specialistcode === this.selectedOpe
          )?.specialistcode || '';
        this.selectedDev =
          this.devList?.find(
            (developer) => developer.specialistcode === this.selectedDev
          )?.specialistcode || '';

        this.spinner.stop();
      },
    });
  }
}

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    });
  }

  updateSupplierPlant() {
    this.errorList = [];
    if (!this.selectedOpe && !this.selectedDev) {
      this.errorList.push('304-401:Required Item Error [Operator] is not selected.');
      this.errorList.push('304-402:Required Item Error [Developer] is not selected.');
    }
    else if (!this.selectedOpe) {
      this.errorList.push('304-401:Required Item Error [Operator] is not selected.');
    }
    else if (!this.selectedDev) {
      this.errorList.push('304-402:Required Item Error [Developer] is not selected.');
    }
    else {
      this.spinner.start();
      const body = {
        suppliercode: this.supplierCode,
        PlantCodes: this.plantCode,
        OpeCode: this.selectedOpe,
        DevCode: this.selectedDev,
        SupplierContact: this.selectedSupplierContact,
      };
      this.supplierPlantService.updateSupplierPlant(body).subscribe({
        next: (_res: any) => {
          this.dialogRef.close({ success: true });
        },
        error: (err: any) => {
          if (err?.error?.error.includes('duplicate')) {
            this.openMessageBox('Supplier Specialist already exists!', 'error');
            this.openMessageBox('Could not update Supplier Specialist!', 'error');
          }
          this.errorHandler(err);
        },
      });
    }

  }

  close() {
    this.errorList = [];
  }

  getPlantNames(plantList: IPlantType[]) {
    return plantList.map((plant) => plant.plant);
  }

  getPlantCodes(plantList: IPlantType[]) {
    return plantList.map((plant) => plant.plantcode);
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: params });
  }

  filterOptions() {
    this.filteredOptions = this.plantList.filter(option => option.plant?.toLowerCase().includes(this.userPlant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plantList.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  closeDialog() {
    this.dialogRef.close();
  }
  help() {
    this.dialogRef.close();
    this.router.navigate(['./master-help', 5, "Master Maintenance", 120])
  }
}
