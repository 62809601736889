<div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Tracking Summary</div>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div class="alert alert-warning mx-2" *ngIf="!haveAccess">
    Authority Error - Access denied
  </div>
  <div class="alert alert-danger mx-2" *ngIf="haveErrors">
    <span *ngFor="let message of errorList">{{ message }}</span>
  </div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
    <div class="filter mx-1 d-flex" (keyup.enter)="onSearch()" >
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="family-code" class="col-4">Family Code</label>
            <input type="text" id="family-code" style="width: 100%;" [(ngModel)]="familycode" (keyup.enter)="onSearch()"/>
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="sop" class="col-4">SOP From Date</label>
            <input type="date" id="sop" style="width: 100%;" [(ngModel)]="sop" (keydown)="onKeyDown($event,'sop')"/>
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="eop" class="col-4">EOP From Date</label>
            <input type="date" id="eop" style="width: 100%;" [(ngModel)]="eop"(keydown)="onKeyDown($event,'eop')"/>
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="modelCode" class="col-4">Model Code</label>
            <input type="text" id="modelCode" style="width: 100%;" [(ngModel)]="modelCode" (keyup.enter)="onSearch()"/>
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="plant" class="col-4">Plant</label>
            <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
            (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
            style="width: 80%">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="sopFromDate" class="col-4">SOP To Date</label>
            <input type="date" id="sopFromDate" style="width: 100%;" [(ngModel)]="sopFromDate"  (keydown)="onKeyDown($event,'sopFromDate')" />
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-2">
            <label for="eopToDate" class="col-4">EOP To Date</label>
            <input type="date" id="eopToDate" style="width: 100%;" [(ngModel)]="eopToDate"(keydown)="onKeyDown($event,'eopToDate')" />
        </div>
        <div class="d-flex  align-items-center col-lg-1 col-md-6 col-12 me-2"></div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-2">
            <button class="black-button" (click)="onSearch()">Search</button>
        </div>
    </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
    <div class="grid-agg grid-height-tracking-summary mb-5">
        <ag-grid-angular style="width:100%;height:100%;" 
            class="ag-theme-balham" 
            (gridReady)="onGridReady($event);"
            (window:resize)="sizeToFit()" 
            [rowHeight]="30" 
            [animateRows]="true" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData" 
            [suppressCellSelection]="true"
            [groupDisplayType]="groupDisplayType"
            [showOpenedGroup]="true"
            [autoGroupColumnDef]="autoGroupColumnDef" 
            [suppressAggFuncInHeader]='true'
            [suppressHorizontalScroll]="false" 
            [pagination]="true" 
            [paginationPageSize]="rpPage"
            [suppressPaginationPanel]="true" 
            [icons]="icons" 
            [overlayLoadingTemplate]="overlayLoadingTemplate" 
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="domLayout" 
            [rowClassRules]="rowClassRules"
            [floatingFiltersHeight]="40" 
            [masterDetail]="true"
            [detailCellRendererParams]="detailCellRendererParams"
            [defaultColDef]="defaultColDef"
            >
        </ag-grid-angular>
        <app-pagination 
            [cPage]="cPage" 
            [tPage]="tPage" 
            [rpPage]="rpPage" 
            [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)" 
            *ngIf="totalRecords > 0">
        </app-pagination>
    </div>
</div>
<router-outlet></router-outlet>

