import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TrackingListService, dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { SelectLeaderRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/select-leader-renderer'
import { DatePipe } from '@angular/common'
import { NgxUiLoaderService } from 'ngx-ui-loader'

@Component({
  selector: 'app-release-follower',
  templateUrl: './release-follower.component.html',
  styleUrls: ['./release-follower.component.scss'],
})
export class ReleaseFollowerComponent implements OnInit {
  rowClassRules: any
  rowHeight = 40
  headerHeight = 40
  gridApi: any
  columnDefs: any[] = []
  displayRowData: any[] = []
  rowCount = 0
  defaultColDef: any
  gridOptions: any
  public rowSelection: 'single' | 'multiple' = 'multiple'
  response: any
  model: any = ''
  plant: any = ''
  supplier: any = ''
  part: any = ''
  pgkdate: any = ''
  loading = false;
  dateFormat = 'MM-dd-YYYY'
  dateformater='MM/dd/YYYY'

  constructor(
    public dialogRef: MatDialogRef<ReleaseFollowerComponent>,
    public trackingListService: TrackingListService,
    private readonly spinner: NgxUiLoaderService,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      comparator:dateComparator

    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.start();
    });
    this.columnDefs = [
      {
        headerName: 'RELEASE',
        field: 'release',
        // headerCheckboxSelection: true,
        // checkboxSelection: true,
        // showDisabledCheckboxes: true,
        minWidth: 90,
        maxWidth: 100,
        cellRendererFramework: SelectLeaderRenderer,
        cellRendererParams: {
          clicked: (_colId?: any, params?: any) => {
            this.releaseFollower(params)
          },
        },
      },
      {
        headerName: 'PLANT',
        field: 'plantname',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        headerName: 'MODEL',
        field: 'modelcode',
        minWidth: 100,
        maxWidth: 100,
      },

      {
        headerName: 'SUPPLIER',
        field: 'suppliername',
        minWidth: 100,
        maxWidth: 100,
      },

      {
        headerName: 'PART#',
        field: 'partnumber',
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'Pkg Start Date',
        field: 'pkgstartdate',
        minWidth: 120,
        maxWidth: 120,
      },
    ]
    this.onLoad()
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' })
  }

  onLoad() {
    const obj = {
      plantcode: this.dataStore.plantC,
      suppliercode: this.dataStore.supplierC,
      partnumber: this.dataStore?.part,
      pkgstartdate: this.datepipe.transform(
        this.dataStore.psd,
          this.dateFormat,
        ),
      modelcode: this.dataStore.model
    }
    this.trackingListService.getReleaseData(obj).subscribe({
      next: (res: any) => {
        this.response = res
        this.model = this.dataStore.model
        this.plant = this.dataStore.plant
        this.supplier = this.dataStore.supplier
        this.part = this.dataStore.part
        this.pgkdate = this.dataStore.psd
        this.displayRowData = this.response?.response
        this.displayRowData.forEach((row: any) => {
          row.pkgstartdate = this.datepipe.transform(
            row.pkgstartdate,
            this.dateformater,
          )
        })
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
    })
  }

  releaseFollower(params: any) {
    this.spinner.start();
    const selectObj = {
      plantcode: params?.plantcode,
      suppliercode: params?.suppliercode,
      partnumber: params?.partnumber,
      pkgstartdate: this.datepipe.transform(
        params?.pkgstartdate,
        this.dateFormat,
      ) ,
      followerCount: this.response?.count,
      onplantcode: this.dataStore.plantC,
      onsuppliercode: this.dataStore?.supplierC,
      onpartnumber: this.dataStore.part,
      onpkgstartdate: this.datepipe.transform(this.dataStore.psd, this.dateFormat),
    }
    this.trackingListService.releaseFollower(selectObj).subscribe({
      next: (res: any) => {
        if (res.status === true) {
          this.dialogRef.close({ event: 'CloseEvent' })
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api
  }
}
