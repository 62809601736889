import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { BreadcrumbComponent } from '../../../../core/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-quantity-detail',
  templateUrl: './quantity-detail.component.html',
  styleUrls: ['./quantity-detail.component.scss'],
  providers : [BreadcrumbComponent]
})
export class QuantityDetailComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  sortModel:any[] = [];
  pageNumber = 1;
  lastRowIndex = 0;
  gridColumnApi: any;
  routeParams: any;
  haveAccess = false;
  response: any;
  
  constructor(
    private readonly route: ActivatedRoute,
    private readonly quantityService: QuantityControlService,
    private readonly state : ExportStateService,
    public loader : NgxUiLoaderService,
    private readonly router : Router,
    public breadcrumb : BreadcrumbComponent
    ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      headerClass: 'child-header-color'
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
  }
   ngOnInit(): void { 
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.loader.start();
          this.haveAccess = res.role?.p_qtyview;
          this.haveAccess && this.getRouteValues();
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false;
      },
    });
  }
  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params;
      if(this.routeParams){
        this.columnDefs = this.setColumnDefs();
        this.apiCall();
      }
    })
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  setColumnDefs() {
    const columnDefs:any[] = [
      {
        headerName: 'PART NUMBER', 
        field: 'partnumber', 
        width:120,
        sortable: true, 
        unSortIcon: true,
        sort: null,
      },
      {
        headerName: 'DOCK CODE', 
        field: 'dockcode', 
        width:120, 
        sortable: true, 
        unSortIcon: true,
        sort: null,
      },
    ];

    for (let i = 2; i <= 15; i++) {
      const subHeader = {
        headerName: `W+${i - 2}` === 'W+0'? 'W' : `W+${i - 2}`,
        children:[
          {headerName: 'Part Vol', field: (i-2) <= 9 ? `partvol0${i - 1}` : `partvol${i - 1}`, width:90,},
          {headerName: 'L/T', field: (i-2) <= 9 ? `leadtime0${i - 1}` : `leadtime${i - 1}`, width:60,},
          {headerName: 'Freq', field: (i-2) <= 9 ? `freq0${i - 1}` : `freq${i - 1}`, width:60,},
          {headerName: 'Sysdays', field: (i-2) <= 9 ? `sysdays0${i - 1}` : `sysdays${i - 1}`, width:90,},
          {headerName: 'QPC', field: (i-2) <= 9 ? `qpc0${i - 1}` : `qpc${i - 1}`, width:60,},
          {headerName: 'Req', field: (i-2) <= 9 ? `reqqty0${i - 1}` : `reqqty${i - 1}`, width:60,}
        ]
      }
      columnDefs.splice(i,0,subHeader)
    }
    return columnDefs
  }


  apiCall() {
    const body = {
      PlantCode : this.routeParams?.plant,
      SupplierCode : this.routeParams?.supplier,
      PackageCode : this.routeParams?.pkg,
      DunnageCode : this.routeParams?.dun,
      Dunnage2Code : this.routeParams?.dun2
    }
    this.quantityService.detailData(body).subscribe({
      next: (res: any) => {        
        if (res && !res.errMsg ) {
          this.totalRecords = res.Data.length > 0 ? res.Data.length : 0;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.displayRowData = res.Data.length > 0 && res.Data;
          this.rowCount = this.displayRowData.length;
          this.gridApi?.setRowData(this.displayRowData);
          this.response = res;
        }else{
          this.displayRowData = [];
        }
        this.loader.stop();
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = 0;
        this.totalRecords = 0;
        this.loader.stop();
        this.gridApi?.hideOverlay(); 
      },
    })
  }

  onBack(){
    const url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    this.router.navigate([url]);
  }
  help(){
    this.router.navigate(['./master-help',4,"Quantity Control",76])
    }
}


