import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class DecListService {

  URL = this.constantsService.INTERNAL;
  filterDecdata: any;
  gridFilterDecdata: any;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }
  onloadGetPlantSpecialist(loadObj: any): Observable<any> {
    const requestURL = this.URL + 'DecisionList/getPlantSpecialist'; 
    return this.http.post(requestURL, loadObj, {
      responseType: 'json',
      headers: this.constantsService.internalHttpOptions
    }).pipe(catchError(this.handleError));
  }
  //popup
  searchDecisionList(loadObj:any): Observable<any> {
    return this.http.post((this.URL + 'DecisionList/searchDecisionList'), loadObj,
    { responseType: 'json', headers: this.constantsService.internalHttpOptions})
    .pipe(catchError(this.handleError))
  }//search button
  getDecisionList(loadObj:any): Observable<any> {
    return this.http.post((this.URL + 'DecisionList/getDecisionList'), loadObj,
    { responseType: 'json', headers: this.constantsService.internalHttpOptions})
    .pipe(catchError(this.handleError))
  }//onload to grid
  updateDecisionList(loadObj:any): Observable<any> {
    return this.http.post((this.URL + 'DecisionList/updateDecisionList'), loadObj,
    { responseType: 'json', headers: this.constantsService.internalHttpOptions})
    .pipe(catchError(this.handleError))
  }//do button
 private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
  setDecFilter(filter: any) {
    this.filterDecdata = filter;
  }
  getDecFilter(){
    return this.filterDecdata;
  }
 
  setDecGridfilter(gridFilter: any) {
    this.gridFilterDecdata = gridFilter;
  }
  getDecGridfilter() {
    return this.gridFilterDecdata;
  }
  
  }
