import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/package-maintenance-btn-cell-render';
import { ImportPackageDataComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/import-package-data/import-package-data.component';
import { ImportPackageCostComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/import-package-cost/import-package-cost.component';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
@Component({
  selector: 'app-package-maintenance',
  templateUrl: './package-maintenance.component.html',
  styleUrls: ['./package-maintenance.component.scss'],
})
export class PackageMaintenanceComponent implements OnInit {
  isAll = false;
  apiFromSearch = false;
  searchfilters: any
  packageTypeFlag: any
  searched = false
  onloadFlag = true
  header = 'child-header-color'
  headerNoBorder = 'child-header-color no-border'
  errorList = ''
  unit: any
  gridApi: any;
  sortModel: any[] = [];
  gridColumnApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  isAccessible = false;
  type = ""
  types: any[] = [];
  material = ""
  materials: any[] = [];
  standard = ''
  codePackage = ''
  addFlag = false
  importDataFlag = false
  importCostFlag = false
  successMessage = ''
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal: any = 1;
  autoGroupColumnDef: any;
  response: any;
  plantCode: any;
  userinfostate: any;
  getPlantType: any
  haveAccess: any;
  // errorMsg !: ErrorMessage;

  constructor(
    private readonly router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly service: PackageMaintananceService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    public constants: ConstantsService,
  ) {
    const nullToDash = this.constants.nullToDashFormatter
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return nullToDash(params)
        } else {
          return null;
        }
      }
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.spinnerService.start();
          this.userinfostate = res;
          this.unit = this.userinfostate.info?.unit
          this.haveAccess = res.role?.p_pkgview;
          this.haveAccess && this.setColumnDefs();
          this.haveAccess && this.loaduserInfo();
          this.haveAccess && this.backCall();
          this.spinnerService.stop();
          !this.haveAccess && this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  backCall() {
    this.searchfilters = this.service.getSearchflag()

    if (this.searchfilters && this.searchfilters.SEARCHED) {
      this.onloadFlag = this.searchfilters.ONLOAD
      this.codePackage = `${this.searchfilters.CODE}`
      this.type = `${this.searchfilters.TYPE}`
      this.material = `${this.searchfilters.MATERIAL}`
      this.standard = this.searchfilters.STANDARD
      this.packageTypeFlag = `${this.searchfilters.PACKAGETYPEFLAG ?? ""}`
      this.searched = this.searchfilters.SEARCHED
    }
    
    if(!this.onloadFlag){
      this.getPackMain(this.type, this.packageTypeFlag, this.material, this.standard, this.codePackage)
    }
    else{
      this.onloadFlag=false
    }
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: "CODE", field: "packagecode", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100, pinned: 'left',
      },
      {
        headerName: "GROUP CODE", field: "groupcode", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 110, pinned: 'left',
      },
      {
        headerName: "TYPE", field: "packagetypecode", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 110, pinned: 'left',
      },
      {
        headerName: "DESCRIPTION", field: "description", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 130, pinned: 'left',
      },
      {
        headerName: this.unit === '0' ? "Outside Dimension(mm)" : "Outside Dimension(inch)", minWidth: 65, sortable: false, unSortIcon: false,
        children:
          [
            {
              headerName: 'Length', field: 'outerlength', headerClass: this.header, minWidth: 100,
              valueGetter: this.outerlengthRender
            },
            {
              headerName: 'Width', field: 'outerwidth', headerClass: this.header, minWidth: 100,
              valueGetter: this.outerwidthRender
            },
            {
              headerName: 'Height', field: 'outerheight', headerClass: this.header, minWidth: 100,
              valueGetter: this.outerheightRender
            },
          ],
      },
      {
        headerName: this.unit === '0' ? "Inside Dimension(mm)" : "Inside Dimension(inch)", minWidth: 65, sortable: false, unSortIcon: false,
        children:
          [
            {
              headerName: 'Length', field: 'innerlength', headerClass: this.header, minWidth: 100,
              valueGetter: this.innerlengthRender
            },
            {
              headerName: 'Width', field: 'innerwidth', headerClass: this.header, minWidth: 80,
              valueGetter: this.innerwidthRender,
            },
            {
              headerName: 'Height', field: 'innerheight', headerClass: this.header, minWidth: 80,
              valueGetter: this.innerheightRender
            },
          ],
      },
      {
        headerName: this.unit === '0' ? "WEIGHT(kg)" : "WEIGHT(IB)", field: "weight", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
        valueGetter: this.weightRender
      },
      {
        headerName: "MATERIAL", field: "material", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: this.unit === '0' ? "NEST(mm)" : "NEST(INCH)", field: "nesting", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
        valueGetter: this.nestingRender
      },
      {
        headerName: this.unit === '0' ? "RETURN HEIGHT(mm)" : "RETURN HEIGHT(INCH)", field: "retheight", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 110,
        valueGetter: this.retheightRender
      },
      {
        headerName: "S/N", field: "standard", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: "R/E", field: "returnable", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: "CONT/LAYER", field: "qtycontlayer", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 110,
      },
      {
        headerName: "LAYER/PALLET", field: "qtylayerpallet", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: "QTY KHOLDER", field: "qtykholder", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: "QTY LABEL", field: "qtylabel", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
        valueGetter: this.qtylabelRender
      },
      {
        headerName: "COST LABEL", field: "costlabel", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
        valueGetter: this.costlabelRender
      },
      {
        headerName: "LEAD TIME(DAYS)", field: "leadtime", headerClass: this.headerNoBorder, sortable: true, unSortIcon: true, minWidth: 100,
        valueGetter: this.leadtimeRender
      },
      {
        headerName: "COST", field: "cost", headerClass: this.headerNoBorder, floatingFilterComponentParams: false, cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false, minWidth: 110,
      },
      {
        headerName: "DRAW", field: "draw", headerClass: this.headerNoBorder, floatingFilterComponentParams: false, cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false, minWidth: 130,
      },
      {
        headerName: "PHOTO", field: "photo", headerClass: this.headerNoBorder, floatingFilterComponentParams: false, cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false, minWidth: 110,
      },
      {
        headerName: "UPDATE", field: "update", headerClass: this.headerNoBorder, floatingFilterComponentParams: false, cellRendererFramework: PackageCellRenderer,
        cellRendererParams: {
          fieldName: "ok",
          clicked: (data: any) => {
            this.updateClicked(data)
          }
        },
        floatingFilter: false,
        filter: false, minWidth: 110,
      },
      {
        headerName: "DELETE", field: "delete", headerClass: this.headerNoBorder, floatingFilterComponentParams: false, cellRendererFramework: PackageCellRenderer,
        floatingFilter: false,
        filter: false, minWidth: 110,
      },
    ]
  }

  outerlengthRender(params: ValueGetterParams) {
    if (params.data.outerlength !== null) {
      if (params.data.outerlength !== 0) {
        return Number(params.data.outerlength).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  outerwidthRender(params: ValueGetterParams) {
    if (params.data.outerwidth !== null) {
      if (params.data.outerwidth !== 0) {
        return Number(params.data.outerwidth).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  outerheightRender(params: ValueGetterParams) {
    if (params.data.outerheight !== null) {
      if (params.data.outerheight !== 0) {
        return Number(params.data.outerheight).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  innerlengthRender(params: ValueGetterParams) {
    if (params.data.innerlength !== null) {
      if (params.data.innerlength !== 0) {
        return Number(params.data.innerlength).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  innerwidthRender(params: ValueGetterParams) {
    if (params.data.innerwidth !== null) {
      if (params.data.innerwidth !== 0) {
        return Number(params.data.innerwidth).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  innerheightRender(params: ValueGetterParams) {
    if (params.data.innerheight !== null) {
      if (params.data.innerheight !== 0) {
        return Number(params.data.innerheight).toFixed(1).toString()
      } else { return "" }
    } else {
      return ""
    }
  }

  weightRender(params: ValueGetterParams) {
    if (params.data.weight !== null) {
      if (params.data.weight === "0.0000000") {
        return "0"
      }
      if (params.data.weight.trim().length !== 0) {
        return Number(params.data.weight).toFixed(1).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  nestingRender(params: ValueGetterParams) {
    if (params.data.nesting !== null) {
      if (params.data.nesting.trim().length !== 0) {
        return Number(params.data.nesting).toFixed(1).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  retheightRender(params: ValueGetterParams) {
    if (params.data.retheight !== null) {
      if (params.data.retheight.trim().length !== 0) {
        return Number(params.data.retheight).toFixed(1).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  qtylabelRender(params: ValueGetterParams) {
    if (params.data.qtylabel !== null) {
      if (params.data.qtylabel === "0.0000000") {
        return "0"
      }
      if (params.data.qtylabel.trim().length !== 0) {
        return Number(params.data.qtylabel).toFixed(0).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  costlabelRender(params: ValueGetterParams) {
    if (params.data.costlabel !== null) {
      if (params.data.costlabel === "0.000") {
        return "0"
      }
      if (params.data.costlabel.trim().length !== 0) {
        return Number(params.data.costlabel).toFixed(2).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  leadtimeRender(params: ValueGetterParams) {
    if (params.data.leadtime !== null) {
      if (params.data.leadtime === "0.000") {
        return "0"
      }
      if (params.data.leadtime.trim().length !== 0) {
        return Number(params.data.leadtime).toFixed(0).toString()
      } else { return "0" }
    } else {
      return "0"
    }
  }

  updateClicked(data: any) {
    const data1 = {
      materialcode: data.materialcode,
      packagecode: data.packagecode,
      packagetypecode: data.packagetypecode,
      standard: data.standard
    }
    sessionStorage.setItem('updatePackageData', JSON.stringify(data1))
    this.router.navigate(['/package-maintenance/update-package']);
  }

  loaduserInfo() {
    this.plantCode = this.userinfostate?.info?.plantcode;
    if (!this.userinfostate?.role?.p_pkgsave) {
      this.addFlag = true;
      this.importDataFlag = true
      this.importCostFlag = true;
    }

    if (!this.userinfostate?.role?.p_pkgmodify) {
      this.importDataFlag = true
      this.importCostFlag = true;
    } else {
      this.importDataFlag = false
      this.importCostFlag = false;
    }
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onDelete(message: string) {
    this.errorList = message
    setTimeout(() => {
      this.errorList = ''
    }, 4000)
  }

  Onsuccess(message: any) {
    this.successMessage = message
    setTimeout(() => {
      this.successMessage = ''
    }, 4000)
  }

  getPackMain(packageTypeCode: any, packageTypeFlag: any, material: any, standard: any, packageCode: any) {
    if (this.apiFromSearch) {
      this.cPage = 1;
    }
    const body = {
      MaterialCode: material ?? "",
      PackageCode: packageCode,
      MatCode: '',
      PackageTypeCode: packageTypeCode,
      Standard: standard ?? '',
      PackageTypeFlag: packageTypeFlag ?? '',
      PkgFab: '',
      page: this.cPage,
      limit: this.rpPage,
      PkgTypeCode: '',
      PkgTypeFlag: '',
      Unit: isNaN(this.unit) ? 0 : Number(this.unit)
    };
    this.service.onload(body).subscribe({
      next: (response: any) => {
        this.response = response;
        this.materials = this.response.GetMaterialCode.result;
        this.types = this.response.GetPackageType.result;
          if (this.isAll) {
            this.displayRowData = [...this.displayRowData, ...response.GetPackage.finalData]
          } else {
            this.displayRowData = response.GetPackage.finalData;
          }
          this.totalRecords = response.GetPackage.OriginalLength
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        if (this.apiFromSearch) {
          this.cPage = 1
        }
        this.apiFromSearch = false;
        this.getPlantType = response.GetPlantType[0].PLANT_NA;
        this.gridApi?.hideOverlay();

        const setFilterObj = {
          CODE: packageCode ?? '',
          TYPE: packageTypeCode ?? '',
          MATERIAL: material ?? '',
          STANDARD: standard ?? '',
          PACKAGETYPEFLAG: packageTypeFlag ?? '',
          ONLOAD: this.onloadFlag,
          SEARCHED: this.searched
        }
        this.service.setSearchflag(setFilterObj)
      // }
    },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  onSearchFrompagination() {
    this.spinnerService.start()
    const obj = this.types.find((val) => {
      return val.packagetypecode === this.type
    })
    const ptflag = (obj?.packagetypeflag) ? Number(obj?.packagetypeflag) : ''
    const materl = this.material
    const std = this.standard ?? ''
    this.searched = true
    this.getPackMain(obj?.packagetypecode ?? '', ptflag, materl, std, this.codePackage.trim());
  }

  onSearch() {
    this.spinnerService.start()
    const obj = this.types.find((val) => {
      return val.packagetypecode === this.type
    })
    const ptflag = (obj?.packagetypeflag) ? Number(obj?.packagetypeflag) : ''
    const materl = this.material
    const std = this.standard ?? ''
    this.searched = true
    if (this.isAll) {
      this.apiFromSearch = true;
      const values = {
        cPage: 1,
        tPage: 1,
        rpPage: 'ALL',
      }
      this.changePaginationValues(values);
    }
    else {
      this.getPackMain(obj?.packagetypecode ?? '', ptflag, materl, std, this.codePackage.trim());

    }
  }

  importpackagedata() {
   const dialogData= this.dialog.open(ImportPackageDataComponent,
      { data: { plantCode: this.plantCode, flag: 'data', component: this } });
    dialogData.afterClosed().subscribe((result: any) => {
      if (result?.success) {
        this.backCall()
      }
    })
  }

  importpackagecost() {
    const dialogData= this.dialog.open(ImportPackageDataComponent,
      { data: { plantCode: this.plantCode, flag: 'cost', component: this } });
      dialogData.afterClosed().subscribe((result: any) => {
        if(result?.success){
          this.backCall()
        }       })
  }

  exportMethod() {
    this.dialog.open(ImportPackageCostComponent, { data: { isExport: true, plantCode: this.plantCode } });
  }

  addPackage() {
    this.router.navigate(['/package-maintenance/add-package']);
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1;
          this.tPage = 1;
          this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearchFrompagination();
    }
  }
  async onLoadAll() {
    this.onSearchFrompagination();
    if (this.apiFromSearch) {
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.apiFromSearch = false;
    }
    return ''
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  help() {
    this.router.navigate(['./master-help', 5, "Master Maintenance", 100])
  }

}