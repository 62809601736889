import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { HyperLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/auth-list/hyper-link-btn-cell-render'
import { ExcelDlComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/excel-dl/excel-dl.component'
import { CostAuthListBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/auth-list/cost-auth-list-btn-cell-render'
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { FormBuilder } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component'
import { SetPoBtnComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/set-po-btn/set-po-btn.component'
import { ConfirmationComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/confirmation/confirmation.component'
import { CancelAuthComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/auth-list/cancel-auth/cancel-auth.component'
import { DeleteAuthComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/auth-list/delete-auth/delete-auth.component'
import { MatSelect } from '@angular/material/select'
import { MatOption } from '@angular/material/core'
import { ExportStateService } from 'src/app/services/export-state.service'
import { LegendsComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/legends/legends.component'
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface'


@Component({
  selector: 'app-auth-list',
  templateUrl: './auth-list.component.html',
  styleUrls: ['./auth-list.component.scss'],
})
export class AuthListComponent implements OnInit {
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 1
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  rowHeight = 50
  headerHeight = 50
  isAccessible = false
  plants: any[] = []
  plantVal: any
  @ViewChild('select') select!: MatSelect
  autoGroupColumnDef: any
  rate: any
  allSelected = false
  isAll = false;
  apiFromSearch = false;
  response: any;
  originalPage: any
  TotalRecords2: any;
  filteredOptions: any[] = [];



  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  pageNumber = 0
  lastRowIndex = 0
  cPage = 1
  tPage = 0
  rpPage = 50
  totalRecords = 0
  spCode: any
  resPlant: any[] = []
  authNum: any
  ringiNum: any
  approve: any
  pkgCode: any
  plantCode: any
  modelCode: any
  fabCode: any
  partNum1: any
  partNum2: any
  supplierCode: any
  authFrom: any
  authTo: any
  authOver: any
  authUnder: any
  actualFrom: any
  actualTo: any
  actualOver: any
  actualUnder: any
  positive = 1
  negative = 0
  waitCancel = 0
  reasonCode: any;
  forecast: any = '';
  readiness: any = '';
  specCode: any
  forecastList = [
    {
      name: 'ALL',
      value: '',
    },
    {
      name: 'Not Open',
      value: 1,
    },
    {
      name: 'Working',
      value: 2,
    },
    {
      name: 'Complete',
      value: 4,
    },
  ]
  reasonList: any[] = []
  columns = [
    'Auth#', 'PO#', 'Ringi#', 'Type', 'Specialist', 'Package Code', 'Package Type', 'Plant', 'Family',
    'Model', 'Supplier', 'Fabricator', 'Part Number', 'Reason', 'Auth', 'Actual', 'PC Auth Date',
    'Approve', 'PUR Approve', 'Approve Status', 'Supplier Forecast', 'Supplier Readiness', 'Fabricator Forecast',
    'Fabricator Readiness'
  ]
  headerClass = 'child-header-color'
  checkVal: any[] = []
  checkList: any[] = []
  checkAll: any
  rowClassRules: any
  columnInfo: any
  gridColumnApi: any
  sortModel: any[] = []
  searchFlag = false
  haveAccess = false
  haveAddAccess = false
  modifyAccess = false
  deleteAccess = false
  plant: any
  userInfo: any
  purchase = true
  move = true
  disposed = true
  maintenance = true
  prototype = true
  authChanged = false
  dateFormat = 'MM/dd/YYYY'
  errorList: any[] = [];
  displayDateFormat = 'YYYY-MM-dd';

  constructor(
    public readonly route: Router,
    private readonly dialog: MatDialog,
    public readonly router: ActivatedRoute,
    private readonly authListService: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    public readonly formBuilder: FormBuilder,
    private readonly state: ExportStateService,
    public datepipe: DatePipe,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      headerClass: 'child-header-color no-border',
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'data.flag === `1` && node.rowIndex % 2 === 0',
      'row-odd': 'data.flag === `1` && node.rowIndex % 2 !== 0',
      'row-pink': 'data.flag === `0`',
      'row-yellow': 'data.flag === `2`',
      'row-reject': 'data.rejectflag === `1`'
    }
  }
  errorMsg !: ErrorMessage;

  ngOnInit(): void {
    this.spinnerService.start()
    this.ringiNum = this.router.snapshot.queryParamMap.get('ringiNumber');
    if(this.authListService.authGridSate.pagesize){
      this.rpPage=this.authListService.authGridSate.pagesize           
    }
    if(this.authListService.authGridSate.currentpage){
      this.cPage=this.authListService.authGridSate.currentpage           
    }
    if(this.authListService.authGridSate.totalpage){
      this.tPage=this.authListService.authGridSate.totalpage           
    }

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res?.info
          this.haveAccess = res.role?.p_costview
          this.haveAddAccess = res.role?.p_costsave
          this.deleteAccess = res.role?.p_costdelete
          this.plantCode = this.userInfo.plantcode || JSON.parse(sessionStorage.getItem('plantCode') || '{}');
          if (this.haveAccess) {
            this.spCode = this.userInfo.specialistcode;
            this.specCode = this.spCode
            this.onloadApiCall();
          }
          else {
            this.errorList = ["You're not authorized to access this page"];
            this.spinnerService.stop();
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
        this.spinnerService.stop()
      },
    })
    this.reasonCode = 'ALL'
  }

  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'AUTH#', field: 'authnumber', minWidth: 90, maxWidth: 150, cellRendererFramework: HyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' }, hide: this.shouldHide(0),
      },
      {
        headerName: 'PO#', field: 'ponumber', minWidth: 90, hide: this.shouldHide(1), maxWidth: 150, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'RINGI#', field: 'ringinumber', minWidth: 90, hide: this.shouldHide(2), maxWidth: 150,
      },
      {
        headerName: 'TYPE', field: 'authtypeName', minWidth: 90, hide: this.shouldHide(3), maxWidth: 100,
      },
      {
        headerName: 'SPECIALIST', field: 'specialist', minWidth: 90, hide: this.shouldHide(4), maxWidth: 100,
      },
      {
        headerName: 'PACKAGE CODE', field: 'pkgcode', minWidth: 90, hide: this.shouldHide(5), maxWidth: 100,
      },
      {
        headerName: 'PACKAGE TYPE', field: 'pkgtype', minWidth: 90, hide: this.shouldHide(6), maxWidth: 100,
      },
      {
        headerName: 'PLANT', field: 'plant', minWidth: 70, hide: this.shouldHide(7), maxWidth: 100,
      },
      {
        headerName: 'FAMILY CODE', field: 'Family', minWidth: 90, hide: this.shouldHide(8), maxWidth: 100, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'MODEL CODE', field: 'model', minWidth: 90, hide: this.shouldHide(9), maxWidth: 100, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'SUPPLIER', field: 'supplier', minWidth: 90, hide: this.shouldHide(10), maxWidth: 100,
      },
      {
        headerName: 'FABRICATOR', field: 'fabcode', minWidth: 100, hide: this.shouldHide(11), maxWidth: 100,
      },
      {
        headerName: 'PART NUMBER', field: 'partnumber', minWidth: 90, maxWidth: 100, hide: this.shouldHide(12), cellRendererFramework: HyperLinkBtnCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },
      },
      {
        headerName: 'REASON', field: 'reason', minWidth: 130, hide: this.shouldHide(13), maxWidth: 140,
      },
      {
        headerName: 'AUTH',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(14),
        children: this.getAuthChildren()
      },
      {
        headerName: 'ACTUAL',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(15),
        children: this.getActualChildren()
      },
      {
        headerName: 'PC APPROVE DATE', hide: this.shouldHide(16), field: 'pcissuedate', minWidth: 90, maxWidth: 100,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,

      },
      {
        headerName: 'APPROVE',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(17),
        children: this.getApproveChildren()
      },
      {
        headerName: 'PUR Approve',
        hide: this.shouldHide(18),
        minWidth: 90,
        maxWidth: 100,
        children: this.getPurAprroveChildren()
      },
      {
        headerName: 'APPROVE STATUS', field: 'apprstatus', minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(19),
        valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'SUPPLIER FORECAST',
        field: 'suplierforecastdate',
        minWidth: 100,
        maxWidth: 120,
        hide: this.shouldHide(20),
        valueFormatter: this.nullToDashFormatter,
        comparator: dateComparator,
      },
      {
        headerName: 'SUPPLIER READINESS',
        field: 'supplierreadiness',
        minWidth: 100,
        maxWidth: 120,
        hide: this.shouldHide(21),
        valueFormatter: this.nullToDashFormatter,
        comparator: dateComparator,
      },
      {
        headerName: 'FABRICATOR FORECAST',
        field: 'fabricatorforecast',
        minWidth: 100,
        maxWidth: 120,
        hide: this.shouldHide(22), //based on cfm status
        valueFormatter: this.nullToDashFormatter,
        comparator: dateComparator,
      },
      {
        headerName: 'FABRICATOR READINESS',
        field: 'fabricatorreadiness',
        minWidth: 100,
        maxWidth: 120,
        hide: this.shouldHide(23), //based on ready status
        valueFormatter: this.nullToDashFormatter,
        comparator: dateComparator,
      },
      {
        headerName: 'CANCEL',
        sortable: false,
        unsortable: false,
        minWidth: 90,
        maxWidth: 100,
        filter: false,
        cellRendererFramework: CancelAuthComponent,
        cellRendererParams: {
          clicked: (_colId: string, data: any) => {
            this.confirmAuthList(data.authnumber, 'CANCEL')
          },
        },
      },
      {
        headerName: 'UPDATE', sortable: false, unsortable: false, filter: false, minWidth: 90, maxWidth: 100, cellRendererFramework: CostAuthListBtnCellRender,
      },
      {
        headerName: 'PO #SET',
        sortable: false,
        unsortable: false,
        filter: false,
        minWidth: 90,
        maxWidth: 100,
        cellRendererFramework: CostAuthListBtnCellRender,
        cellRendererParams: {
          clicked: (_colId: string, data: any) => {
            this.openDialog(data)
          },
        },
      },
      {
        headerName: 'DELETE',
        sortable: false,
        unsortable: false,
        filter: false,
        minWidth: 90,
        maxWidth: 100,
        cellRendererFramework: DeleteAuthComponent,
        cellRendererParams: {
          clicked: (_colId: string, data: any) => {
            this.confirmAuthList(data.authnumber, 'DELETE')
          },
        },
      },
      {
        headerName: 'EXCEL DOWNLOAD', sortable: false, filter: false, unsortable: false, minWidth: 90, maxWidth: 100, cellRendererFramework: CostAuthListBtnCellRender,
      },
      {
        headerName: 'REJECT REASON', sortable: false, filter: false, unsortable: false, minWidth: 90, maxWidth: 100, cellRendererFramework: CostAuthListBtnCellRender,
      },
      {
        headerName: 'DELIVERY', sortable: false, filter: false, unsortable: false, minWidth: 90, maxWidth: 100, cellRendererFramework: CostAuthListBtnCellRender,
      },
    ]
  }

  getAuthChildren() {
    return [
      {
        headerName: 'Date', field: 'authdate', hide: this.shouldHide(14), minWidth: 90, maxWidth: 100, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator
      },
      {
        headerName: 'Unit$(US)', field: 'authunit', hide: this.shouldHide(14), minWidth: 90, maxWidth: 100, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'Qty', field: 'authqty', minWidth: 90, maxWidth: 100, hide: this.shouldHide(14), headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'Total$(US)', field: 'authtotal', hide: this.shouldHide(14), minWidth: 90, maxWidth: 100, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      }]
  }

  getActualChildren() {
    return [
      {
        headerName: 'Date', field: 'actualdate', hide: this.shouldHide(15), minWidth: 90, maxWidth: 100, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator
      },
      {
        headerName: 'Unit$(US)', field: 'actualunit', hide: this.shouldHide(15), minWidth: 90, maxWidth: 100, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'Qty', field: 'actualqty', minWidth: 90, maxWidth: 100, hide: this.shouldHide(15), headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'Total$(US)', field: 'actualtotal', hide: this.shouldHide(15), minWidth: 90, maxWidth: 100, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
    ]
  }

  getApproveChildren() {
    return [
      {
        headerName: 'AM', field: 'amname', minWidth: 70, maxWidth: 90, hide: this.shouldHide(17), headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'App date', field: 'pcamappdate', hide: this.shouldHide(17), minWidth: 70, maxWidth: 90, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,
      },
      {
        headerName: 'MGR', field: 'mgrname', minWidth: 70, maxWidth: 90, hide: this.shouldHide(17), headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'App Date', field: 'pcmgrappdate', minWidth: 70, maxWidth: 90, hide: this.shouldHide(17), headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,
      },
    ]
  }

  getPurAprroveChildren() {
    return [
      {
        headerName: 'Spec', field: 'purspec', hide: this.shouldHide(18), minWidth: 70, maxWidth: 90, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'App Date', field: 'purappdate', hide: this.shouldHide(18), minWidth: 70, maxWidth: 90, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,
      },
      {
        headerName: 'AM', field: 'puram', minWidth: 70, maxWidth: 90, hide: this.shouldHide(18), headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'App Date', field: 'puramappdate', hide: this.shouldHide(18), minWidth: 70, maxWidth: 90, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,
      },
      {
        headerName: 'MGR', field: 'purmgr', hide: this.shouldHide(18), minWidth: 70, maxWidth: 90, headerClass: this.headerClass, valueFormatter: this.nullToDashFormatter,
      },
      {
        headerName: 'App Date', field: 'purmgrappdate', hide: this.shouldHide(18), minWidth: 70, maxWidth: 90, headerClass: this.headerClass,
        valueFormatter: this.nullToDashFormatter, comparator: dateComparator,
      },
    ]
  }

  shouldHide(index: any) {
    if (this.checkVal[index] !== 1) {
      return true
    } else {
      return false
    }
  }

  confirmAuthList(authNum: any, action: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        action: action,
        authNum: authNum,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result.data) {
          this.onloadApiCall();
      }
    })
  }

  openDialog(data: any) {
    const dialogRef = this.dialog.open(SetPoBtnComponent, {
      data: {
        auth: data.authnumber,
        ponum: data.ponumber,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.data !== 'close') {
        this.onloadApiCall();
      }
    })
  }

  loadPlants() {
    this.spinnerService.start()
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 2,
    }
    this.authListService.getAllPlant(plantObj)?.subscribe({
      next: (res: any) => {
        if (res) {
          this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res]
          this.resPlant = res.map((item: { plantcode: any }) => item.plantcode)
          this.plantVal = '(All)'
          this.filteredOptions = this.plants
          this.displayFn(this.plants);
          this.authListService.setplant(this.plants);
          const plantdata = this.authListService.getplant();
          if (plantdata) {
          const data = this.authListService.getAuthLisFilters()
          if (data) {
            this.spinnerService.start()
            this.spCode = data.SpecialistCode;
            if (this.ringiNum === null) {
              this.ringiNum = data.RingiNumber === '' ? null : data.RingiNumber
            }
            this.specCode = data.SpecialistCode;
            this.positive = data.Positive === "1" ? 1 : 0;
            this.negative = data.Negative === "1" ? 1 : 0;
            this.waitCancel = data.WaitCancel === "1" ? 1 : 0;
            this.authNum = data.AuthNumber
            this.approve = data.Approve
            this.pkgCode = data.PkgCode
            this.modelCode = data.ModelCode
            this.fabCode = data.FabCode
            this.partNum1 = data.PartNumber1
            this.partNum2 = data.PartNumber2
            this.supplierCode = data.SupplierCode
            this.authFrom = data.AuthFrom ? this.datepipe.transform(data.AuthFrom, this.displayDateFormat) : undefined;
            this.authTo = data.AuthTo ? this.datepipe.transform(data.AuthTo, this.displayDateFormat) : undefined;
            this.authOver = data.AuthOver
            this.authUnder = data.AuthUnder
            this.actualFrom = data.ActualFrom ? this.datepipe.transform(data.actualFrom, this.displayDateFormat) : undefined;
            this.actualTo = data.ActualTo ? this.datepipe.transform(data.ActualTo, this.displayDateFormat) : undefined;
            this.actualOver = data.ActualOver
            this.actualUnder = data.ActualUnder
            this.reasonCode = data.ReasonCode === '' ? "ALL" : data.ReasonCode
            this.forecast = data.Forecast
            this.readiness = data.Readiness
            this.plantVal = data.PlantCode.length > 1 ? '(All)' : data.PlantCode[0]
            this.maintenance = data.Maintenance === "1" ? true : false;
            this.disposed = data.Dispose === "1" ? true : false;
            this.move = data.Move === "1" ? true : false;
            this.prototype = data.Prototype === "1" ? true : false;
            this.purchase = data.Purchase === "1" ? true : false;
          }
        }
          this.getParams();
          this.loadAuthList();
        }
      },
      error: () => {
        this.spinnerService.stop()
      }
    })
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plantVal?.toLowerCase()))
  }


  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  filterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response?.pagination?.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
    this.saveGridState();
  }

  getParams() {
    this.spinnerService.start();
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plantVal?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plantVal?.toLowerCase()) ))
    this.plantVal = plant[0]?.plantcode
    const params: any = {
      SpecialistCode: this.specCode ?? '',
      UserSpecialistCode: this.userInfo?.specialistcode,
      RingiNumber: this.ringiNum ? this.ringiNum : '',
      Positive: this.positive.toString(),
      Negative: this.negative.toString(),
      WaitCancel: this.waitCancel.toString(),
      AuthNumber: this.authNum ?? '',
      Approve: this.approve ?? '',
      PkgCode: this.pkgCode ?? '',
      ModelCode: this.modelCode ?? '',
      FabCode: this.fabCode ?? '',
      PartNumber1: this.partNum1 ?? '',
      PartNumber2: this.partNum2 ?? '',
      SupplierCode: this.supplierCode ?? '',
      AuthFrom: this.authFrom ?? '',
      AuthTo: this.authTo ?? '',
      AuthOver: this.authOver ?? '',
      AuthUnder: this.authUnder ?? '',
      ActualFrom: this.actualFrom ?? '',
      ActualTo: this.actualTo ?? '',
      ActualOver: this.actualOver ?? '',
      ActualUnder: this.actualUnder ?? '',
      ReasonCode: this.reasonCode === "ALL" ? '' : this.reasonCode,
      Forecast: this.forecast === 'ALL' ? '' : this.forecast,
      Readiness: this.readiness === "ALL" ? '' : this.readiness,
      SupplierType: 0,
      LoadParam: true,
      ScreenID: '45',
      UserPlantCode: this.userInfo?.plantcode,
      PlantCode: this.plantVal
        ? this.plantVal === '(All)'
          ? this.resPlant
          : [this.plantVal]
        : this.resPlant,
      page: this.cPage,
      limit: this.rpPage,
      Maintenance: this.maintenance ? "1" : "0",
      Dispose: this.disposed ? "1" : "0",
      Move: this.move ? "1" : "0",
      Prototype: this.prototype ? "1" : "0",
      Purchase: this.purchase ? "1" : "0",
    }
    return params;
  }

  onPosChange(event: any) {
    if (event.target.checked) {
      this.positive = 1;
    }
    else {
      this.positive = 0;
    }
  }

  onNegChange(event: any) {
    if (event.target.checked) {
      this.negative = 1;
    }
    else {
      this.negative = 0;
    }
  }

  onWaitChange(event: any) {
    if (event.target.checked) {
      this.waitCancel = 1;
    }
    else {
      this.waitCancel = 0;
    }
  }

  nullToDashFormatter(params: any) {
    const colId = params?.colDef ? params.colDef.field : null
    if (typeof params.value === 'undefined' || params.value === null || params.value === '') {
      return '-';
    } else {
      if (colId === 'authtotal' || colId === 'authunit' || colId === 'actualtotal' || colId === 'actualunit') {
        return !isNaN(params?.value) ? parseFloat(params?.value).toFixed(2) : params?.value;
      }
      else if (colId === 'authqty' || colId === 'actualqty') {
        return !isNaN(params?.value) ? parseFloat(params?.value).toFixed(0) : params?.value;
      } else {
        return params?.value;
      }
    }
  }
  assignData(result: any) {
    result.forEach((element: any) => {
      let toSupplier = true;
      if (element.request_to !== null && (element.request_to !== element.suppliercode)) {
        toSupplier = false
      }

      Object.assign(element, {
        authtypeName: this.getAuthType(element.authtype),
      })
      if (element.rejectflag === '1') {
        Object.assign(element, {
          apprstatus: 'Rejected',
        })
      } else {
        Object.assign(element, {
          apprstatus: element.status,
        })
      }
      // column - Supplier Forecast
      const cfmStatus = this.getSupplierStatus(element.cfmstatus)
      if (toSupplier) {
        if (element.flag !== 0 || element.flag !== '0') {
          if (cfmStatus === 'complete') {
            Object.assign(element, {
              suplierforecastdate: (element.forecastdate !== null) ? this.datepipe.transform(
                element.forecastdate.split('T')[0],
                this.dateFormat,
              ) : '',
            })
          } else {
            Object.assign(element, {
              suplierforecastdate: cfmStatus,
            })
          }
        }
      } else {
        Object.assign(element, { suplierforecastdate: '' })
      }
      // column - Supplier Readiness
      const readyStatus = this.getSupplierStatus(element.readystatus)
      if (toSupplier) {
        if (readyStatus === 'complete') {
          Object.assign(element, {
            supplierreadiness: (element.fromreadydate !== null) ? this.datepipe.transform(
              element.fromreadydate.split('T')[0],
              this.dateFormat,
            ) : '',
          })
        } else {
          Object.assign(element, {
            supplierreadiness: readyStatus,
          })
        }
      } else {
        Object.assign(element, { supplierreadiness: '' })
      }
      // column - Fabricator Forecast
      const fabcfmStatus = this.getSupplierStatus(element.cfmstatus)
      if (!toSupplier) {
        if (element.flag !== 0 || element.flag !== '0') {
          if (fabcfmStatus === 'complete') {
            Object.assign(element, {
              fabricatorforecast: (element.forecastdate !== null) ? this.datepipe.transform(
                element.forecastdate.split('T')[0],
                this.dateFormat,
              ) : '',
            })
          } else {
            Object.assign(element, {
              fabricatorforecast: cfmStatus,
            })
          }
        }
      } else {
        Object.assign(element, { fabricatorforecast: '' })
      }
      // column - Fabricator Readiness
      const fabreadyStatus = this.getSupplierStatus(element.readystatus)
      if (!toSupplier) {
        if (fabreadyStatus === 'complete') {
          Object.assign(element, {
            fabricatorreadiness: (element.fromreadydate !== null) ? this.datepipe.transform(
              element.fromreadydate.split('T')[0],
              this.dateFormat,
            ) : '',
          })
        } else {
          Object.assign(element, {
            fabricatorreadiness: fabreadyStatus,
          })
        }
      } else {
        Object.assign(element, { fabricatorreadiness: '' })
      }
    })
  }

  loadAuthList() {
    this.spinnerService.start()
    this.errorList = []
    const params = this.getParams();
    this.authListService.setAuthLisFilters(params);
    this.gridApi?.showLoadingOverlay();
    this.authListService.loadAuthList(params)?.subscribe({
      next: (res: any) => {
        if (res) {
            this.response = res;
            //Taking show/hide response from onload api
            this.checkVal = this.response.result?.DisplayColumns;
            if (!this.checkVal?.includes(0)) {
              this.allSelected = true
            } else {
              this.allSelected = false
            }
            this.checkBox()
            this.getColDefs()
            //show/hide ends here

            this.reasonList = [{ id: 'ALL', type: '', reason: 'ALL' }, ...(res.result?.ReasonList || [])];
            this.rate = res.result?.Rate
            const result = res.result?.AuthListResult?.result
            result?.length > 0 && this.assignData(result)
            this.displayRowData = result.map((rate: any) => ({
              ...rate,
              authdate: (rate.authdate != null) ? this.datepipe.transform(rate.authdate.split('T')[0], this.dateFormat) : rate.authdate,
              actualdate: (rate.actualdate != null) ? this.datepipe.transform(rate.actualdate.split('T')[0], this.dateFormat) : rate.actualdate,
              pcissuedate: (rate.pcissuedate != null) ? this.datepipe.transform(rate.pcissuedate.split('T')[0], this.dateFormat) : rate.pcissuedate,
              pcamappdate: (rate.pcamappdate != null) ? this.datepipe.transform(rate.pcamappdate.split('T')[0], this.dateFormat) : rate.pcamappdate,
              pcmgrappdate: (rate.pcmgrappdate != null) ? this.datepipe.transform(rate.pcmgrappdate.split('T')[0], this.dateFormat) : rate.pcmgrappdate,
              purappdate: (rate.purappdate != null) ? this.datepipe.transform(rate.purappdate.split('T')[0], this.dateFormat) : rate.purappdate,
              puramappdate: (rate.puramappdate != null) ? this.datepipe.transform(rate.puramappdate.split('T')[0], this.dateFormat) : rate.puramappdate,
              purmgrappdate: (rate.purmgrappdate != null) ? this.datepipe.transform(rate.purmgrappdate.split('T')[0], this.dateFormat) : rate.purmgrappdate,

              authunit: !isNaN(rate.authunit) ? Number(rate.authunit) * this.rate : rate.authunit,
              authtotal: !isNaN(rate.authtotal) ? Number(rate.authtotal) * this.rate : rate.authtotal,
              actualunit: (rate.actualunit) ? Number(rate.actualunit) * this.rate : rate.actualunit,
              actualtotal: (rate.actualtotal) ? Number(rate.actualtotal) * this.rate : rate.actualtotal
            }));
            this.rowCount = res.pagination?.rows
            this.totalRecords = res.pagination?.TotalRecords
            this.tPage = Math.ceil(this.totalRecords / this.rpPage)
            this.TotalRecords2 = res.pagination?.TotalRecords
            if (this.isAll) {
              const values = { cPage: 1, tPage: 1, rpPage: 'ALL', }
              this.changePaginationValues(values);
            } else {
              this.spinnerService.stop()
            }
        }
      },
      error: () => {
        this.gridApi?.hideOverlay()
        this.displayRowData = []
        this.rowCount = 0
        this.spinnerService.stop()
      },

    })
  }

  search() {
    this.displayRowData = []
    this.totalRecords = 0;
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plantVal?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plantVal?.toLowerCase())))
    if ((plantCheck?.length === 0) || (!plantCheck)) {
      this.errorList = ["Please select valid plant name"];
    } else if (this.plantVal === '(All)' && !(this.authNum || this.partNum1 || this.ringiNum || this.modelCode || this.specCode || this.supplierCode || this.pkgCode)) {
      this.spinnerService.stop();
      this.errorList = ['For `ALL` plant , Please select another field as well to search'];
      return
    } else {
      this.searchFlag = true
      this.cPage = 1;
      this.rpPage = 50;
      this.isAll = false;
      this.loadAuthList();
    }
  }

  getAuthType(authType: any) {
    let authTypeName: any
    if (authType === '0') {
      authTypeName = 'Dispose'
      return authTypeName
    } else if (authType === '1') {
      authTypeName = 'Maintenance'
      return authTypeName
    } else if (authType === '2') {
      authTypeName = 'Move'
      return authTypeName
    } else if (authType === '3') {
      authTypeName = 'Proto Type'
      return authTypeName
    } else if (authType === '4') {
      authTypeName = 'Purchase'
      return authTypeName
    }
    return authTypeName
  }

  getSupplierStatus(data: any) {
    let dataVal: any
    if (data === '1') {
      dataVal = 'not open'
      return dataVal
    } else if (data === '2' || data === '6' || data === '7') {
      dataVal = 'working'
      return dataVal
    } else if (data === '4') {
      dataVal = 'complete'
      return dataVal
    }
    return dataVal
  }

  changeAuthType(event: any, type: any) {
    this.authChanged = true;
    if (type === 'purchase') {
      this.purchase = event.target.checked ? true : false
    }
    else if (type === 'move') {
      this.move = event.target.checked ? true : false
    }
    else if (type === 'disposed') {
      this.disposed = event.target.checked ? true : false
    }
    else if (type === 'maintenance') {
      this.maintenance = event.target.checked ? true : false
    }
    else if (type === 'prototype') {
      this.prototype = event.target.checked ? true : false
    }
  }


  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'authFrom') {
        this.authFrom = '';
      }
      if (id === 'authTo') {
       this.authTo = '';
      }
      if (id === 'actualFrom') {
       this.actualFrom = '';
      }
      if (id === 'actualTo') {
        this.actualTo = '';
      }
    } 
  }

  cellStyleColumn() {
    return { display: 'flex', 'justify-content': 'flex-start', height: '40px' }
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll && (this.authNum || this.plantVal || this.partNum1 || this.ringiNum || this.modelCode || this.specCode || this.supplierCode || this.pkgCode)) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 4000); i++) {
        this.cPage = i + 1
        this.tPage = 1
        this.rpPage = 4000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else if (this.plantVal === '(All)' && !(this.authNum || this.partNum1 || this.ringiNum || this.modelCode || this.specCode || this.supplierCode || this.pkgCode)) {
      this.spinnerService.stop();
      this.errorList = ['For `ALL` plant , Please select another field as well to search'];
      return
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadAuthList();

    }
    this.saveGridState();
  }

  async onLoadAll() {
    const loadObj = this.getParams();
    const gridData: any[] = []
    const authData = await this.authListService.getDataSynchronous(loadObj);
    gridData.push(...authData.result?.AuthListResult?.result)
    this.displayRowData.push(...gridData)

    this.displayRowData?.length > 0 && this.assignData(this.displayRowData)
    this.displayRowData = this.displayRowData.map((rate: any) => ({
      ...rate,
      authdate: (rate.authdate != null) ? this.datepipe.transform(rate.authdate.split('T')[0], this.dateFormat) : rate.authdate,
      actualdate: (rate.actualdate != null) ? this.datepipe.transform(rate.actualdate.split('T')[0], this.dateFormat) : rate.actualdate,
      pcissuedate: (rate.pcissuedate != null) ? this.datepipe.transform(rate.pcissuedate.split('T')[0], this.dateFormat) : rate.pcissuedate,
      pcamappdate: (rate.pcamappdate != null) ? this.datepipe.transform(rate.pcamappdate.split('T')[0], this.dateFormat) : rate.pcamappdate,
      pcmgrappdate: (rate.pcmgrappdate != null) ? this.datepipe.transform(rate.pcmgrappdate.split('T')[0], this.dateFormat) : rate.pcmgrappdate,
      purappdate: (rate.purappdate != null) ? this.datepipe.transform(rate.purappdate.split('T')[0], this.dateFormat) : rate.purappdate,
      puramappdate: (rate.puramappdate != null) ? this.datepipe.transform(rate.puramappdate.split('T')[0], this.dateFormat) : rate.puramappdate,
      purmgrappdate: (rate.purmgrappdate != null) ? this.datepipe.transform(rate.purmgrappdate.split('T')[0], this.dateFormat) : rate.purmgrappdate,

      authunit: !isNaN(rate.authunit) ? Number(rate.authunit) * this.rate : rate.authunit,
      authtotal: !isNaN(rate.authtotal) ? Number(rate.authtotal) * this.rate : rate.authtotal,
      actualunit: (rate.actualunit) ? Number(rate.actualunit) * this.rate : rate.actualunit,
      actualtotal: (rate.actualtotal) ? Number(rate.actualtotal) * this.rate : rate.actualtotal
    }));    

    this.gridApi.setRowData(this.displayRowData)
    if (this.apiFromSearch) {
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.apiFromSearch = false;
    }    
    if(this.cPage === Math.ceil(this.totalRecords / 4000)){
      this.spinnerService.stop();
    }
  }

  sortChanged(_event:any){
    this.saveGridState();
  }

  saveGridState(){
    this.authListService.authGridSate.sort = this.gridColumnApi?.getColumnState();
    this.authListService.authGridSate.filter = this.gridApi?.getFilterModel();
    this.authListService.authGridSate.currentpage = this.gridApi?.paginationGetCurrentPage();
    this.authListService.authGridSate.totalPage = this.gridApi?.paginationGetTotalPages();
    this.authListService.authGridSate.rowcount = this.gridApi?.paginationGetRowCount();
    this.authListService.authGridSate.pagesize = this.gridApi?.paginationGetPageSize();
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.gridApi.addEventListener('firstDataRendered', () => {
        if (this.authListService.authGridSate.filter) {
          const retrievedValue = this.authListService.authGridSate.filter;
          this.gridApi?.setFilterModel(retrievedValue);
          this.gridApi?.onFilterChanged();
        }
        if (this.authListService.authGridSate.sort) {
          const retrievedValue = this.authListService.authGridSate.sort;
          this.gridColumnApi?.applyColumnState({
            state: retrievedValue,
            applyOrder: true
          });
        }
        if(this.authListService.authGridSate.pagesize){
          this.rpPage=this.authListService.authGridSate.pagesize           
        }
        this.gridApi?.paginationSetPageSize(this.authListService.authGridSate.pagesize);
      })
    });
    this.gridApi.sizeColumnsToFit()

  }

  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specCode,
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specCode = result
    })
  }

  help() {
    this.route.navigate(['./master-help', 3, "Cost Management", 45])
  }

  selectApprover() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.approve,
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.approve = result
    })
  }

  getDisplayColumns() {
    this.spinnerService.start()
    this.authListService.getDisplayColumns(this.spCode).subscribe({
      next: (res: any) => {
        if (res) {
          this.checkVal = res
          if (!this.checkVal.includes(0)) {
            this.allSelected = true
          } else {
            this.allSelected = false
          }
          this.checkBox()
          this.getColDefs()
          if (!this.searchFlag) {
            this.loadPlants()
          } else {
            this.search()
          }
        }
      },
      error: () => {
        this.spinnerService.stop()
      },
    })
  }

  onloadApiCall(){
    if (!this.searchFlag) {
      this.loadPlants()
    } else {
      this.search()
    }
  }

  updateColumnInfo() {
    this.spinnerService.start();
    this.columnInfo = 0
    for (let i = 0; i < this.columns.length; i++) {
      if (this.checkList.includes(this.columns[i])) {
        this.columnInfo += 1 << i
      }
    }
    const updObj: any = {
      SpecialistCode: this.spCode,
      ColumnInfo: this.columnInfo,
    }
    this.authListService.updateColumnInfo(updObj).subscribe({
      next: (res: any) => {
        if (res) {
          this.onloadApiCall();
        }
      },
      error: () => {
        this.spinnerService.stop();
      },
    })
  }

  checkBox() {
    if (this.allSelected) {
      this.select?.options?.forEach((item: MatOption) => item.select())
    } else {
      this.select?.options.forEach((e: MatOption, key: any) => {
        if (this.checkVal[key] === 1) {
          e.select()
        } else {
          e.deselect()
        }
      })
    }
  }

  toggleAllSelection() {
    if (this.checkAll) {
      this.select?.options.forEach((item: MatOption) => item.select())
    } else {
      this.select?.options.forEach((item: MatOption) =>
        item.deselect(),
      )
    }
  }

  optionClick() {
    let newStatus = true
    this.select?.options.forEach((item: MatOption) => {
      if (!item.selected) {
        this.checkAll = false
        newStatus = false
      }
    })
    this.allSelected = newStatus
  }

  excelDl() {
    this.dialog.open(ExcelDlComponent)
  }

  dateFormatter(params: any) {
    return params.value
      ? this.datepipe.transform(params.value.split('T')[0], this.dateFormat)
      : '-'
  }

  openLegends() {
    this.dialog.open(LegendsComponent, {
      data: {
        data: 'auth-list'
      },
      width: '35%',
    })
  }
}
