<div class="d-flex justify-content-between align-items-center m-3">
  <p class="page-title">Comparing PPF</p>
  <p *ngIf="showDifference" class="text-center text-danger fw-bold fs-5">{{differnce}} difference</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<ng-container *ngFor="let table of tables" class="pb-5 mb-5">
  <table  class="vpacs-shadow mx-3 py-2 px-2 pb-5 mb-5 tableStyle" aria-describedby="concept-compare">
    <tr class="d-none"><th id="">concept-compare</th></tr>
  <tr>
      <td colspan="3" class="tableStyle white-background width-td"></td>
      <td  class="align-text white-background">
        <button *ngFor="let button of table.button" class="me-2 vpacs-btn-table-dept py-2 px-3" (click)="selectBtn('first')">{{button['column1']}}</button>
        <button  *ngFor="let button of table.button" class="vpacs-btn-table-dept py-2 px-3" (click)="downloadBtn('first')" [disabled]="isDownloaded1">{{button['column2']}}</button>
      </td>
      <td  class="align-text white-background">
        <button *ngFor="let button of table.button" class="me-2 vpacs-btn-table-dept py-2 px-3" (click)="selectBtn('second')">{{button['column1']}}</button>
        <button  *ngFor="let button of table.button" class="vpacs-btn-table-dept py-2 px-3" (click)="downloadBtn('second')" [disabled]="isDownloaded2">{{button['column2']}}</button>
      </td>
    </tr>
    <tr class="align-text" *ngFor="let data of table.data; index as i">
      <td class="table-align align-text"  colspan="3">{{data['column1']}}</td>
      <td class="contentColumn align-text "  [ngClass]="{'data-background':(i===0 || i === 1 || i===2 || i===3), 'diff-background':((i===4 || i===5  || i===6) && data['column2'] !== data['column3'])}">{{data['column2']}}</td>
      <td class="contentColumn align-text "  [ngClass]="{'data-background':(i===0 || i === 1 || i===2 || i===3), 'diff-background':((i===4 || i===5 || i===6)  && data['column2'] !== data['column3'])}">{{data['column3']}}</td>
    </tr>
    <tr class="align-text"  *ngFor="let heading of table.heading">
      <td class="table-align font-col" rowspan="4">{{heading['column1']}}</td>
    </tr>
    <tr  *ngFor="let plant of table.plant">
      <td  class="table-align align-text " colspan="2">{{plant['column1']}}</td>
      <td class="contentColumn align-text" [ngClass]="plant['column2'] !== plant['column3'] ? 'diff-background':'' ">{{plant['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="plant['column2'] !== plant['column3'] ? 'diff-background':'' ">{{plant['column3']}}</td>
    </tr>
    <tr class="align-start"  *ngFor="let sup of table.supHeading">
      <td class="table-align font-col" rowspan="8">{{sup['column1']}}</td>
    </tr>
    <tr class="align-text" *ngFor="let supplier of table.supplier; index as i">
      <td class="table-align" colspan="2">{{supplier['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="(i!==1) && (supplier['column2'] !== supplier['column3']) ? 'diff-background':'' ">{{supplier['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="(i!==1) && (supplier['column2'] !== supplier['column3']) ? 'diff-background':'' ">{{supplier['column3']}}</td>
    </tr>
    <tr class="align-text" *ngFor="let hazmat of table.hazmat">
      <td class="table-align font-col" colspan="3">{{hazmat['column1']}}</td>
      <td  class="contentColumn align-text"  [ngClass]="hazmat['column2'] !== hazmat['column3'] ? 'diff-background':'' ">{{hazmat['column2']}}
      </td>
      <td  class="contentColumn align-text"  [ngClass]="hazmat['column2'] !== hazmat['column3'] ? 'diff-background':'' " >
        {{hazmat['column3']}}
      </td>
    </tr>
    <tr class="align-start"  *ngFor="let pkg of table.pkgHeading">
      <td class="table-align font-col" rowspan="81">{{pkg['column1']}}</td>
    </tr>
    <tr class="align-start"  *ngFor="let cont of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{cont['column1']}}</td>
    </tr>
    <tr *ngFor="let container of table.container">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text" [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text" [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr class="align-start"  *ngFor="let dun of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{dun['column2']}}</td>
    </tr>
    <tr *ngFor="let container of table.dunnage1">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let dun2 of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{dun2['column3']}}</td>
    </tr>
    <tr *ngFor="let container of table.dunnage2">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let top of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{top['column4']}}</td>
    </tr>
    <tr *ngFor="let container of table.topCap">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let pallet of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{pallet['column5']}}</td>
    </tr>
    <tr *ngFor="let container of table.pallet">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text" [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text" [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let strap of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{strap['column6']}}</td>
    </tr>
    <tr *ngFor="let container of table.stretch">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text" [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let kanban of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{kanban['column7']}}</td>
    </tr>
    <tr *ngFor="let container of table.kanban">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let other of table.contHeading">
      <td class="table-align font-col" rowspan="10">{{other['column8']}}</td>
    </tr>
    <tr *ngFor="let container of table.other;">
      <td class="table-align ">{{container['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="container['column2'] !== container['column3'] ? 'diff-background':'' ">{{container['column3']}}</td>
    </tr>
    <tr  *ngFor="let pkgData of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="28">{{pkgData['column1']}}</td>
    </tr>
    <tr  *ngFor="let part of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="4">{{part['column2']}}</td>
    </tr>
    <tr *ngFor="let partsize of table.partsize">
      <td class="table-align">{{partsize['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="partsize['column2'] !== partsize['column3'] ? 'diff-background':'' ">{{partsize['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="partsize['column2'] !== partsize['column3'] ? 'diff-background':'' ">{{partsize['column3']}}</td>
    </tr>
    <tr  *ngFor="let contIn of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="8">{{contIn['column3']}}</td>
    </tr>
    <tr *ngFor="let containers of table.containers">
      <td class="table-align ">{{containers['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="containers['column2'] !== containers['column3'] ? 'diff-background':'' ">{{containers['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="containers['column2'] !== containers['column3'] ? 'diff-background':'' ">{{containers['column3']}}</td>
    </tr>
    <tr  *ngFor="let palletIn of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="4">{{palletIn['column4']}}</td>
    </tr>
    <tr *ngFor="let palletonly of table.palletonly">
      <td class="table-align ">{{palletonly['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="palletonly['column2'] !== palletonly['column3'] ? 'diff-background':'' ">{{palletonly['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="palletonly['column2'] !== palletonly['column3'] ? 'diff-background':'' ">{{palletonly['column3']}}</td>
    </tr>
    <tr  *ngFor="let palIn of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="4">{{palIn['column5']}}</td>
    </tr>
    <tr *ngFor="let palletload of table.palletload">
      <td class="table-align ">{{palletload['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="palletload['column2'] !== palletload['column3'] ? 'diff-background':'' ">{{palletload['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="palletload['column2'] !== palletload['column3'] ? 'diff-background':'' ">{{palletload['column3']}}</td>
    </tr>
    <tr  *ngFor="let weightlb of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="7">{{weightlb['column6']}}</td>
    </tr>
    <tr *ngFor="let weight of table.weight">
      <td class="table-align ">{{weight['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="weight['column2'] !== weight['column3'] ? 'diff-background':'' ">{{weight['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="weight['column2'] !== weight['column3'] ? 'diff-background':'' ">{{weight['column3']}}</td>
    </tr>
    <tr class="align-text" *ngFor="let quant of table.pkgDataHeading">
      <td class="table-align font-col" rowspan="4">{{quant['column7']}}</td>
    </tr>
    <tr class="align-text" *ngFor="let Quantities of table.Quantities">
      <td colspan="2" class="table-align ">{{Quantities['column1']}}</td>
      <td class="contentColumn align-text"  [ngClass]="Quantities['column2'] !== Quantities['column3'] ? 'diff-background':'' ">{{Quantities['column2']}}</td>
      <td class="contentColumn align-text"  [ngClass]="Quantities['column2'] !== Quantities['column3'] ? 'diff-background':'' ">{{Quantities['column3']}}</td>
    </tr>
    <tr  *ngFor="let comment of table.comment" class="pb-5 mb-5 align-text">
      <td class="table-align font-col" colspan="3">{{comment['column1']}}</td>
      <td class="contentColumn align-text"   [ngClass]="comment['column2'] !== comment['column3'] ? 'diff-background':'' ">
        {{comment['column2']}}</td>
      <td class="contentColumn align-text"   [ngClass]="comment['column2'] !== comment['column3'] ? 'diff-background':'' ">
        {{comment['column3']}}</td>
    </tr>
  </table>
</ng-container>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
  <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
</div>