<p class="page-title mx-2 px-2">Tracking</p>
<div class="p-2">
<div class="filter mx-1 d-flex" [formGroup]="searchParameters" (keyup.enter)="onSearch(apiFilters ? 'api' : 'ui')">   
    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12">
        <label for="from-date" class="col-lg-3 col-md-2">From Date</label>
        <input type="date" id="from-date"  style="width: 100%;" (change)="apiFiltersChange($event, 'from')" (keyup)="dateOnChange($event, 'from')" formControlName="fromDate"/>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="step" class="col-2">Step</label>
        <mat-select formControlName="step" class="mat-sel pt-2" disableOptionCentering >
            <mat-option *ngFor="let s of step" [value]="s.value">
               {{s.value}}
            </mat-option>
        </mat-select>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="part-no" class="col-2">Part#</label>
        <input type="text" id="part-no" style="width: 100%;" (change)="apiFiltersChange($event, 'partnumber')"  formControlName="partnum"/>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="supplier" class="col-lg-3 col-md-2">Supplier</label>
        <mat-select formControlName="supplier" id="supplier" (valueChange)="valueChange($event)" class="mat-sel pt-2" disableOptionCentering>
            <mat-option value="ALL" *ngIf="supplierNumber?.length > 1">------ALL------</mat-option>
            <mat-option *ngFor="let s of supplierNumber; index as i" [value]="i">{{s}}</mat-option>
        </mat-select>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="to-date" class="col-lg-3 col-md-2">To Date</label>
        <input type="date" id="to-date" style="width: 100%;" [min]="searchParameters.controls['fromDate'].value" (change)="apiFiltersChange($event, 'to')" (keyup)="dateOnChange($event, 'to')" formControlName="toDate"/>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="from" class="col-2">From</label>
        <input type="text" id="from" style="width: 100%;" formControlName="from"/>
    </div>
    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
        <label for="model" class="col-2">Model</label>
        <input type="text" id="model" style="width: 100%;" (change)="apiFiltersChange($event, 'model')" formControlName="model"/>
    </div>
    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12">
        <div class="col-lg-3 col-md-2">
            <input type="radio" name="check" id="onlydo" (change)="apiFiltersChange($event, 'check')" value="Do" formControlName="check">
            <label for="onlyDo" class="ms-1">OnlyDo</label><br>
            <input type="radio" name="check" id="all" (change)="apiFiltersChange($event, 'check')" value="All" formControlName="check">
            <label for="all" class="ms-1">All</label><br>
        </div>
        <div class="control-btns d-flex gap-1">
            <button [disabled]="!searchParameters.valid" class="black-button" (click)="onSearch(apiFilters ? 'api' : 'ui')">Search</button>
            <button class="black-button" (click)= "openDialog()" title="Download Status">DL Status</button>
        </div>
    </div>
</div>
<div class="m-1 pt-2">
    <div class="legend my-2 mx-3">
        <app-tracking-legend></app-tracking-legend>
    </div>
    <div class="grid-agg skeleton-loader gap-3" *ngIf="loading" style="width:100%;height:45vh;">
        <div class="spinner-border text-secondary"></div>
        Loading... Please wait
    </div>
    <div class="grid-agg grid-height-tracking">
        <ag-grid-angular style="width:100%;height:100%;"
            class="{{loading ? 'opacity-0 ag-theme-balham h-100' : 'ag-theme-balham'}}"
            (gridReady)="onGridReady($event);" (window:resize)="sizeToFit()" [rowHeight]="40" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
            [rowClassRules]="rowClassRules" [suppressCellSelection]="true" [suppressHorizontalScroll]="false"
            [pagination]="true" [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true"
            [icons]="icons" [floatingFiltersHeight]="40" [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate" [domLayout]="domLayout"
            (paginationChanged)="onPaginationChanged()" (filterChanged)="filterChanged($event)" (sortChanged)="sortChanged($event)">
        </ag-grid-angular>
        <div class="d-flex pagination align-items-center justify-content-between">
            <div>
                <div>
                    <span class="page-drpdwn-label mx-2">Records/Page: </span>
                    <select style="height: 30px" (change)="onPageSizeChanged($event)" id="pagination">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20" selected>20</option>
                        <option value="50">50</option>
                        <option value="{{rowCount}}">ALL</option>
                    </select>
                </div>
            </div>
            <span *ngIf="asyncLoading">Loading {{totalRowCount}}/{{allTrackingData}}</span>
            <div class="d-flex align-items-center gap-3">
                <div *ngIf="!asyncLoading">Viewing Records</div>
                <mat-icon (click)="goToFirstPage()" class="text-primary"
                    [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}">first_page</mat-icon>
                <mat-icon (click)="goToPreviousPage()" class="text-primary"
                    [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}">fast_rewind</mat-icon>
                <div>
                    (
                    <span class="value" id="lbCurrentPage"></span>-
                    <span class="value" id="lbTotalPages"></span>)
                    <span>OF {{rowCount}}</span>
                </div>
                <mat-icon (click)="goToNexpage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}">fast_forward</mat-icon>
                <mat-icon (click)="goToLastPage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}">last_page</mat-icon>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>