import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlantService {

  URL = this.constantsService.MASTERCOST ;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  plantOnload(): Observable<any> {
    return this.http.get((this.URL + 'master/plantOnload'),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  addPlantOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addPlantOnload'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  addPlant(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addPlant'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  updatePlant(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/updatePlant'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  deletePlant(PlantCode: string): Observable<any> {
    return this.http.delete((this.URL + `master/deletePlant/${PlantCode}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  calconload(PlantCode: string): Observable<any> {
    return this.http.get((this.URL + `master/calconload/${PlantCode}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  calcInsert(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/calcInsert'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  costOnload(plantCode: string): Observable<any> {
    return this.http.get((this.URL + `master/costOnload/${plantCode}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  addcostOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addcostOnload'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  
  addcostCenter(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addcostCenter'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  

  updateCostCenter(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/updateCostCenter'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  deleteCostCenter(plantCode: string,costCenter:string): Observable<any> {
    return this.http.delete((this.URL + `master/deleteCostCenter/${plantCode}/${costCenter}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }
  
  budgetOnload(plantCode: string,costCenter:string): Observable<any> {
    return this.http.get((this.URL + `master/budgetOnload/${plantCode}/${costCenter}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  addBudgetOnload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addBudgetOnload'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  addBudget(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/addBudget'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  updateBudget(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/updateBudget'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  deleteBudget(plantCode: string, Budget: string, CostCenter:string): Observable<any> {
    return this.http.delete((this.URL + `master/deleteBudget/${plantCode}/${Budget}/${CostCenter}`),
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  dockonload(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/plant/dockonload'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  dockdelete(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/plant/dockdelete'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  dockAdd(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/plant/dockinsert'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  dockUpdate(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/plant/dockupdate'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  dockRegist(loadObj: any): Observable<any> {
    return this.http.post((this.URL + 'master/plant/dockregist'), loadObj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

}
