import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, IsRowSelectable } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-discrepancy-select-part',
  templateUrl: './discrepancy-select-part.component.html',
  styleUrls: ['./discrepancy-select-part.component.scss'],
})
export class DiscrepancySelectPartComponent implements OnInit {
  @ViewChild('agGrid', { static: true })
  agGrid!: AgGridAngular;
  gridApi: any;
  columnDefs: any[] = [];
  totalPage = 0;
  displayRowData: any[] = [];
  isLastPageFound!: boolean;
  defaultColDef: {};
  public rowSelection: 'single' | 'multiple' = 'multiple';
  radioVal = 1;
  gridOptions: GridOptions = {
    rowSelection: 'multiple',

  };
  public isRowSelectable: IsRowSelectable = () => {
    return this.radioVal === 0 ? true : false
  };
  rowHeight = 60;
  headerHeight = 60;
  isAccessible = false;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  partNumber = '';
  part1 = '';
  part2 = '';
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  rowCount = 0;
  radioValAll = true;
  errorList: string[] = [];

  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  spCode: any;
  plantcode: any;
  strtDate: any;
  disablebox: any;
  selectedRows: any;
  isCheckedAllModel = false;
  modelListArr: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DiscrepancySelectPartComponent>,

    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public datepipe: DatePipe, public router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly DiscrepancyListService: DiscrepancyListService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      comparator: dateComparator
    };
  }

  ngOnInit(): void {
    this.plant();
    if (this.dialog.params && this.dialog.params.pkgstartdate) {
      this.strtDate = this.dialog.params?.pkgstartdate.substring(0, 10);
    }

    this.columnDefs = [
      {
        minWidth: 20,
        maxWidth: 100,
        checkboxSelection: true,
        rowMultiSelectWithClick: true
      },

      {
        headerName: 'MODEL',
        field: 'modelcode',
        minWidth: 60,
        maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PLANT',
        field: 'plant',
        minWidth: 60,
        maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PART NUMBER',
        field: 'partnumber',
        minWidth: 60,
        maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SUPPLIER',
        field: 'supplier',
        minWidth: 60,
        maxWidth: 142,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PACKAGE START DATE',
        field: 'pkgstartdate',
        minWidth: 70,
        maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        valueFormatter: this.dateFormatter,
      },
    ];

  }

  slt(_event: any) {
    if (this.radioVal === 1) {
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(
        (rowNode: any) => rowNode.setRowSelectable(false)
      )
    }
    else if (this.radioVal === 0) {
      this.gridApi.forEachNode(
        (rowNode: any) => rowNode.setRowSelectable(this.isRowSelectable(rowNode))
      )
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  dateFormatter(params: any) {
    return params.value ? params.value.substring(0, 10) : '';
  }

  onLoad() {
    setTimeout(() => {
      this.spinnerService.start();
    });

    const updateObject = {
      PlantCode: this.dialog.params.plantcode,
      SupplierCode: this.dialog.params.suppliercode,
      PartNumber: this.dialog.params.partnumber,
      PkgStartDate: this.datepipe.transform(this.strtDate, 'MM/dd/YYYY'),
    };

    this.DiscrepancyListService.replaceOnload(updateObject).subscribe({
      next: (res: any) => {

        this.part1 = this.partNumber.slice(0, 5);
        this.part2 = this.partNumber.slice(6, 11);
        this.spinnerService.stop();
        this.displayRowData = res.Result;
        this.rowCount = res.pagination?.TotalRecords;
        this.totalRecords = res.pagination?.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
      },
    });
  }

  plant() {
    setTimeout(() => {
      this.spinnerService.start();
    });
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };

    this.DiscrepancyListService.plant(obj).subscribe((response) => {
      this.resPlant = response.map(
        (item: { plantcode: any }) => item.plantcode
      );
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response];
      this.onLoad();
    });
  }

  updateBtn() {

    this.spinnerService.start();
    const updateObject = {
      PlantCode: this.dialog.params.plantcode,
      SupplierCode: this.dialog.params.suppliercode,
      NewPartNumber: this.dialog.params.partnumber,
      PartNumber: this.dialog.pNumber,
      NewSupplierCode: this.dialog.sCode,
      PkgStartDate: this.datepipe.transform(
        this.strtDate,
        'YYYY/MM/dd'
      ),
      ModelList: this.modelListArr,
      AllModel: this.isCheckedAllModel,
    };

    this.DiscrepancyListService.updatePart(updateObject).subscribe({
      next: (_res: any) => {
        this.onNoClick();
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
      },
    });
  }

  update() {
    this.errorList = [];
    this.modelListArr = [];
    if (this.radioVal === 1) {
      this.isCheckedAllModel = true;
      this.displayRowData.forEach((e: any, _i: any) => {
        if (!this.modelListArr?.includes(e.modelcode)) {
          this.modelListArr.push(e.modelcode)
        }
      });
      this.updateBtn();
    }
    else {
      this.isCheckedAllModel = false;
      if (this.displayRowData?.length === this.selectedRows?.length) {
        this.isCheckedAllModel = true;
      }
      
      if (this.selectedRows?.length > 0) {
        this.selectedRows.forEach((e: any, _i: any) => {
          if (!this.modelListArr?.includes(e.modelcode)) {
            this.modelListArr.push(e.modelcode)
          }
        });
        this.updateBtn();
      }
      else {
        this.errorList = ['304-001:Required Item Error [CheckBox], Select rows to update.']
      }
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onNoClick() {
    this.dialogRef.close();
  }

  onSelectionChanged(_event: any) {
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 166])
  }

}
