import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { BackupAuthRouterLinkCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/backup-auth-list/backup-auth-router-link-cell-renderer';
import { BackupAuthListBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/backup-auth-list/backup-auth-list-btn-cell-renderer';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';
import { DatePipe, formatDate } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SetPoComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/cost-management/backup-auth-list/set-po/set-po.component';
import { backupAuthListConstant } from '../../../notification/backup-auth-list.constant'
import { LegendsComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/legends/legends.component';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-backup-auth-list',
  templateUrl: './backup-auth-list.component.html',
  styleUrls: ['./backup-auth-list.component.scss']
})
export class BackupAuthListComponent implements OnInit {
  displayDateFormat='YYYY-MM-dd'; //filterchanges
  isAll = false;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 10
  isAccessible = false;
  pageNumber = 0;
  lastRowIndex = 0;
  plants: any[] = [];
  statusList = backupAuthListConstant.statusList
  reasonList: any[] = [];
  plant: any;
  plantDropdown: any;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  plantCodeValue: any;
  selectPlant: any[] = [];
  authNum = '';
  ringiNum = '';
  approve = '';
  pkgCode = '';
  modelCode = '';
  fabCode = '';
  supplierCode = '';
  partNum1 = '';
  partNum2 = '';
  actualOver = '';
  actualUnder = '';
  reason = '';
  specCode: any;
  fromDate: any;
  toDate: any;
  positive = 1;
  negative = 0;
  waitCancel = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  resPlant: any[] = [];
  userPlantCode: any;
  flagValue: any;
  allSelected = false;
  @ViewChild('select') select!: MatSelect;
  plantVal: any;
  isStatus: any;
  autoGroupColumnDef: any
  filteredOptions: any[] = [];
  columns = backupAuthListConstant.columns
  checkVal: any[] = [];
  checkList: any[] = [];
  checkAll: any;
  rowClassRules: any;
  columnInfo: any;
  gridColumnApi: any;
  sortModel: any[] = [];
  haveAccess = false;
  haveAddAccess = false;
  modifyAccess = false
  deleteAccess = false;
  statusDropdown = 'ALL';
  data: any;
  ppfArray: any = []
  response: any;
  cellRendererParams: any = {};
  allRecord: any;
  userInfo: any;
  errorList: string[] = [];
  successMessage: string[] = [];
  routeParams: any;
  originalPage: any;
  spCode: any;


  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    public readonly route: ActivatedRoute,
    private readonly service: BackupAuthlistService,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    public datepipe: DatePipe) { // filterchanges

    this.defaultColDef = {
      
      suppressMovable: true,
      filterParams: { newRowsAction: 'keep' },
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      headerClass: 'child-header-color no-border',
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = backupAuthListConstant.rowClassRules
  }

  ngOnInit(): void {
    this.route.params.subscribe((data:any)=>{
      this.routeParams = data;
    })
    if (Object.keys(this.routeParams).length !== 0) {
      const sp = this.routeParams.sp;
      const appType = this.routeParams.app_type
      if (sp !== null && appType !== null) {
        this.specCode = sp;
        this.statusDropdown = this.statusList[Number(appType)].name;
      }
    }
    if(this.service.baGridSate.pagesize){
      this.rpPage=this.service.baGridSate.pagesize           
    }
    if(this.service.baGridSate.currentpage){
      this.cPage=this.service.baGridSate.currentpage           
    }
    if(this.service.baGridSate.totalpage){
      this.tPage=this.service.baGridSate.totalpage           
    }
    this.getUserInfo();
    this.reason='ALL' //filterchanges
  }
  getUserInfo() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res.info
          this.haveAccess = res.role?.p_costview;
          this.haveAddAccess = res.role?.p_costsave;
          this.deleteAccess = res.role?.p_costdelete;
          this.userPlantCode = this.userInfo.plantcode
          if (this.haveAccess) {
            this.spCode = this.userInfo.specialistcode;
            this.specCode= this.spCode
            this.spinnerService.start();
            this.getDisplayColumns();
          } else {
            this.spinnerService.stop()
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.deleteAccess = false;
        this.spinnerService.stop();
      }
    });
  }

  getDisplayColumns() {
    this.spinnerService.start();
    const spObj = {
      userSpecialistCode: this.spCode
    }
    this.service.getDisplayColumns(spObj)
      .subscribe({
        next: (res: any) => {
          this.checkVal = res;
          if (!this.checkVal.includes(0)) {
            this.allSelected = true;
          }
          else {
            this.allSelected = false;
          }
          this.checkBox();
          this.getColDefs();
          this.loadPlants();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        }
      });
  }

  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'AUTH#',
        field: 'authnumber',
        minWidth: 100,
        maxWidth: 120,
        cellRendererFramework: BackupAuthRouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        hide: this.shouldHide(0),
      },
      {
        headerName: 'PO#',
        field: 'ponumber',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(1),
      },
      {
        headerName: 'RINGI#',
        field: 'ringinumber',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(2),
      },
      {
        headerName: 'SPECIALIST',
        field: 'specialist',
        minWidth: 100,
        maxWidth: 100,
        hide: this.shouldHide(3),
      },
      {
        headerName: 'PACKAGE CODE',
        field: 'pkgcode',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(4),
      },
      {
        headerName: 'PACKAGE TYPE',
        field: 'pkgtype',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(5),
      },
      {
        headerName: 'PLANT',
        field: 'plant',
        minWidth: 100,
        maxWidth: 120,
        hide: this.shouldHide(6),
      },
      {
        headerName: 'FAMILY',
        field: 'family',
        minWidth: 110,
        maxWidth: 130,
        hide: this.shouldHide(7),
      },
      {
        headerName: 'MODEL',
        field: 'model',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(8),
      },
      {
        headerName: 'SUPPLIER',
        field: 'supplier',
        minWidth: 120,
        maxWidth: 150,
        hide: this.shouldHide(9),
      },
      {
        headerName: 'FABRICATOR',
        field: 'fabcode',
        minWidth: 100,
        maxWidth: 100,
        hide: this.shouldHide(10),
      },
      {
        headerName: 'PART NUMBER',
        field: 'partnumber',
        minWidth: 110,
        maxWidth: 130,
        hide: this.shouldHide(11),
        cellRendererFramework: BackupAuthRouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        cellRendererParams: this.cellRendererParams,
      },
      {
        headerName: 'REASON',
        field: 'reason',
        minWidth: 160,
        maxWidth: 180,
        hide: this.shouldHide(12),
      },
      {
        headerName: 'AUTH',
        field: 'auth',
        minWidth: 140,
        hide: this.shouldHide(13),
        children: [
          {
            headerName: 'Date',
            field: 'authdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(13),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
          {
            headerName: 'Unit$(US)',
            field: 'authunit',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(13), valueFormatter: this.nullToDashFormatter
          },
          {
            headerName: 'Qty',
            field: 'authqty',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(13),valueFormatter: this.nullToDashFormatter
          },
          {
            headerName: 'Total$(US)',
            field: 'authtotal',
            minWidth: 100,
            maxWidth: 110,
            hide: this.shouldHide(13),valueFormatter: this.nullToDashFormatter
          },
        ],
      },
      {
        headerName: 'PC APPROVE DATE',
        field: 'pcissuedate',
        minWidth: 120,
        maxWidth: 160,
        hide: this.shouldHide(14),
        valueFormatter: this.dateFormatter,
        comparator: dateComparator,
      },
      {
        headerName: 'APPROVE',
        field: 'approve',
        sortable: false,
        unSortIcon: false,
        minWidth: 90,
        hide: this.shouldHide(15),
        children: [
          {
            headerName: 'AM',
            field: 'amname',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(15),
          },
          {
            headerName: 'App Date',
            field: 'pcamappdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(15),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
          {
            headerName: 'MGR',
            field: 'mgrname',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(15),
          },
          {
            headerName: 'App Date',
            field: 'pcmgrappdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(15),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
        ],
      },
      {
        headerName: 'PUR APPROVE',
        field: 'purApprove',
        minWidth: 90,
        maxWidth: 100,
        hide: this.shouldHide(16),
        children: [
          {
            headerName: 'Spec',
            field: 'purspec',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
          },
          {
            headerName: 'App Date',
            field: 'purappdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
          {
            headerName: 'AM',
            field: 'puram',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
          },
          {
            headerName: 'App Date',
            field: 'puramappdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
          {
            headerName: 'MGR',
            field: 'purmgr',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
          },
          {
            headerName: 'App Date',
            field: 'purmgrappdate',
            minWidth: 90,
            maxWidth: 100,
            hide: this.shouldHide(16),
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
          },
        ],
      },
      {
        headerName: 'APPROVE STATUS',
        field: 'statusName',
        minWidth: 120,
        maxWidth: 130,
        hide: this.shouldHide(17),
      },
      {
        headerName: 'CANCEL',
        field: 'cancel',
        minWidth: 110,
        maxWidth: 120,
      },
      {
        headerName: 'PO #SET',
        field: 'poSet',
        minWidth: 110,
        maxWidth: 120,
        cellRendererFramework: BackupAuthListBtnCellRenderer,
        cellRendererParams: {
          clicked: (data: any) => {
            this.openDialog(data);
          }
        }
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        minWidth: 110,
        maxWidth: 120,
        cellRendererFramework: BackupAuthListBtnCellRenderer,
        cellStyle: { cursor: 'pointer' },
      },
      {
        headerName: 'EXCEL DOWNLOAD',
        field: 'download',
        minWidth: 110,
        maxWidth: 120,
        cellRendererFramework: BackupAuthListBtnCellRenderer,
        cellStyle: { cursor: 'pointer' },
      },
    ];
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'authfromDate') {
        this.fromDate = '';
      }
      if (id === 'authtoDate') {
        this.toDate = '';
      }
    }
  }

  loadPlants() {
    this.spinnerService.start();
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 2,
    };
    this.service.getAllPlant(plantObj).subscribe({
      next: (res: any) => {
        this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res]
        this.resPlant = res.map((item: { plantcode: any }) => item.plantcode);
        this.plantVal = '(All)';
        this.filteredOptions = this.plants;
        this.displayFn(this.plants);
        this.service.setplant(this.plants)
        const plantdata = this.service.getplant();
        if(plantdata){
        const data  = this.service.getBackupAuthFilters(); //filterchanges
        if(data){
            this.authNum=data.AuthNumber
            this.ringiNum=data.RingiNumber===' ' ? null: data.RingiNumber
            this.specCode=data.SpecialistCode 
            this.approve=data.Approve
            this.pkgCode=data.PackageCode
            this.plantVal=data.PlantCodes.length > 1 ? '(All)' : data.PlantCodes[0];
            this.modelCode=data.ModelCode
            this.fabCode=data.FabCode
            this.supplierCode=data.SupplierCode
            this.partNum1=data.PartNumber1
            this.partNum2=data.PartNumber2
            this.fromDate=data.AuthDateFrom ? this.datepipe.transform(data.AuthDateFrom, this.displayDateFormat) : undefined;
            this.toDate=data.AuthDateTo ? this.datepipe.transform(data.AuthDateTo, this.displayDateFormat) : undefined;
            this.actualOver=data.AuthCostOver 
            this.actualUnder=data.AuthCostUnder
            this.positive=data.Positive === '1' ? 1 : 0
            this.negative=data.Negative==='1'?  1 : 0;
            this.waitCancel=data.WaitCancel==='1' ? 1 : 0
            this.reason=data.Reason==='' ? 'ALL' : data.Reason
            this.statusDropdown=data.status
        }
      }
        this.loadPayload();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    });
  }

  loadPayload() { //filterchanges
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plantVal?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plantVal?.toLowerCase()) ))
    this.plantVal = plant[0]?.plantcode
    const params = {
      sort: '',
      AuthNumber: this.authNum,
      RingiNumber: this.ringiNum,
      SpecialistCode: this.specCode ? this.specCode : '',
      Approve: this.approve ? this.approve : '',
      PackageCode: this.pkgCode,
      userPlantCode: this.userPlantCode,
      PlantCodes: this.plantVal
        ? this.plantVal === '(All)'
          ? this.resPlant
          : [this.plantVal]
        : this.resPlant,
      ModelCode: this.modelCode,
      FabCode: this.fabCode,
      userSpecialistCode: this.userInfo?.specialistcode,
      userSupplierType: '',
      SupplierCode: this.supplierCode,
      PartNumber1: this.partNum1,
      PartNumber2: this.partNum2,
      AuthDateFrom: (this.fromDate) ? this.fromDate : "0001-01-01",
      AuthDateTo: (this.toDate) ? this.toDate : "0001-01-01",
      AuthCostOver: this.actualOver ? this.actualOver : '',
      AuthCostUnder: this.actualUnder ? this.actualUnder : '',
      Positive: this.positive ? "1" : "0",
      Negative: this.negative ? "1" : "0",
      WaitCancel: this.waitCancel ? "1" : "0",
      Reason: this.reason === 'ALL' ? '' : this.reason, 
      page: this.cPage,
      limit: this.rpPage,
      status: this.statusDropdown
    };
    this.backupAuthlistOnLoad(params);
  }

  getPlants(res: any) {
    this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res]
    this.resPlant = res.map((item: { plantcode: any }) => item.plantcode);
    this.plantVal = '(All)'
  }

  filterOptions() { //Filters the plant code
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plantVal?.toLowerCase()))
  }

  displayFn(selectId: any) { //Displays the plant code
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  backupAuthlistOnLoad(params: any) {
    this.spinnerService.start();
    this.service.setBackupAuthFilters(params);  //filterchanges
    this.gridApi?.showLoadingOverlay();
    this.service.backupAuthlistOnLoad(params).subscribe({
      next: (res: any) => {
        this.response = res
        this.reasonList = [{ id: 'ALL', reason: 'ALL', type: "" }, ...res.ReasonList];
        this.ppfArray = res.PpfResult
        this.service.setPpfArray(this.ppfArray);
        this.dataTrasform(res);
        this.displayRowData = res.data;
        this.rowCount = this.response.data.length;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        if (this.cPage > this.tPage) {
          this.cPage = 1;
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinnerService.stop();
      }
    });
  }

  dataTrasform(res: any) {
        res.data.forEach((row: any) => {
          row.ponumber = (row.ponumber) ? row.ponumber : '-';
          row.pkgtype = (row.pkgtype) ? row.pkgtype : '-';
          row.fabcode = (row.fabcode) ? row.fabcode : '-';
          row.authdate = (row.authdate) ? row.authdate : '-';
          row.authunit = (row.authunit) ? row.authunit : '-';
          row.authqty = (row.authqty) ? row.authqty : '-';
          row.authtotal = (row.authtotal) ? row.authtotal : '-';
          row.am = (row.am) ? row.am : '-';
          row.amAppdate = (row.amAppdate) ? row.amAppdate : '-';
          row.mgr = (row.mgr) ? row.mgr : '-';
          row.mgrAppdate = (row.mgrAppdate) ? row.mgrAppdate : '-';
          row.purspec = (row.purspec) ? row.purspec : '-';
          row.purappdate = (row.purappdate) ? row.purappdate : '-';
          row.puram = (row.puram) ? row.puram : '-';
          row.puramappdate = (row.puramappdate) ? row.puramappdate : '-';
          row.purmgr = (row.purmgr) ? row.purmgr : '-';
          row.purmgrappdate = (row.purmgrappdate) ? row.purmgrappdate : '-';
          row.pcissuedate = (row.pcissuedate) ? row.pcissuedate : '-';
        })     
  }

  searchBackupAuthList() { 
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plantVal?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plantVal?.toLowerCase())))
    if ((plantCheck?.length === 0) || (!plantCheck)) {
      this.errorList = ["Please select valid plant name"];
    }else {
      this.errorList = [];
      this.loadPayload();
    }
  }
  

  updateColumnInfo() {
    this.spinnerService.start();
    this.columnInfo = 0;
    for (let i = 0; i < this.columns.length; i++) {
      if (this.checkList.includes(this.columns[i])) {
        this.columnInfo += 1 << i;
      }
    }
    const updObj: any = {
      userSpecialistCode: this.spCode,
      columnInfo: this.columnInfo
    }
    this.service.updateColumnInfo(updObj)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.getDisplayColumns();
          }

        },
        error: (_err: any) => {
          this.spinnerService.stop();
        }
      });
  }

  shouldHide(index: any) {
    if (this.checkVal[index] !== 1) {
      return true;
    }
    else {
      return false;
    }
  }

  checkBox() {
    if (this.allSelected) {
      this.select?.options.forEach((item: MatOption) => item.select());
    } else {
      this.select?.options.forEach((e: MatOption, key: any) => {
        if (this.checkVal[key] === 1) {
          e.select();
        }
        else {
          e.deselect();
        }
      })
    }
  }

  toggleAllSelection() {
    if (this.checkAll) {
      this.select?.options.forEach((item: MatOption) => item.select());
    } else {
      this.select?.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select?.options.forEach((item: MatOption) => {
      if (!item.selected) {
        this.checkAll = false;
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specCode
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specCode = result;
    })
  }
  selectApprover() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.approve
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.approve = result
    })
  }

  openDialog(data: any) {
    const dialogRef = this.dialog.open(SetPoComponent, {
      data: {
        auth: data?.authnumber
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.event !== 'Close') {
        this.getDisplayColumns();
      }
    })
  }

  dateFormatter(params: any) {
    return (params?.value !== '-' && params.value !==null) ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '-';
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  help() {
    this.router.navigate(['./master-help', 3, "Cost Management", 61])

  }

  sortChanged(_event:any){
    this.saveGridState();
  }

  saveGridState(){
    this.service.baGridSate.sort = this.gridColumnApi?.getColumnState();
    this.service.baGridSate.filter = this.gridApi?.getFilterModel();
    this.service.baGridSate.currentpage = this.gridApi?.paginationGetCurrentPage();
    this.service.baGridSate.totalPage = this.gridApi?.paginationGetTotalPages();
    this.service.baGridSate.rowcount = this.gridApi?.paginationGetRowCount();
    this.service.baGridSate.pagesize = this.gridApi?.paginationGetPageSize();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.gridApi.addEventListener('firstDataRendered', () => {
        if (this.service.baGridSate.filter) {
          const retrievedValue = this.service.baGridSate.filter;
          this.gridApi?.setFilterModel(retrievedValue);
          this.gridApi?.onFilterChanged();
        }
        if (this.service.baGridSate.sort) {
          const retrievedValue = this.service.baGridSate.sort;
          this.gridColumnApi?.applyColumnState({
            state: retrievedValue,
            applyOrder: true
          });
        }
      })
    });
    this.gridApi.sizeColumnsToFit();
  }

  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.response.pagination.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
    this.saveGridState();
  }

  async changePaginationValues(values: any) {

    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
      this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;

      this.spinnerService.start();
      if (this.isAll) {
        this.displayRowData = [];
        for (let i = 0; i < Math.ceil(this.totalRecords / 1000); i++) {
          this.cPage = i + 1;
           this.tPage = 1;
           this.rpPage = 1000;//Records per page is set to 1000 for 'ALL'
          await this.onLoadAll();
    }
    this.rowCount = this.totalRecords;
    this.tPage = 1;
    this.cPage = 1;
    this.rpPage = this.totalRecords;
    this.gridApi?.paginationSetPageSize(this.totalRecords);
  } else {
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
    this.searchBackupAuthList()
    this.loadPayload();
  }
  this.saveGridState();
}
onLoadAll() {
  this.loadPayload();
 
  }

  close() {
    this.errorList = [];
    this.successMessage = [];
  }

  
 nullToDashFormatter(params: any) {
    const colId  = params?.colDef ? params.colDef.field : null
    if (typeof params.value === 'undefined' || params.value === null || params.value === '') {
      return '-';
    } else {
      if(colId === 'authtotal' || colId === 'authunit' ) {
        return !isNaN(params?.value) ? parseFloat(params?.value).toFixed(2) : params?.value;
      }
      else if (colId === 'authqty' ) {
        return !isNaN(params?.value) ? parseFloat(params?.value).toFixed(0) : params?.value;
      } else {
        return params?.value;
      }
        
    }
  }

  openLegends() {
    this.dialog.open(LegendsComponent,{
      data: {
        data:'auth-list'
      },
      width:'35%', 
  })
  }

}