import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-material-add',
  template: `
    <button 
        class="vpacs-btn-table text-uppercase"
        style="height:30px"
        (click)="btnClickedHandler()"
        [disabled]="this.updateFlag || this.deleteFlag"
    >{{params.column.colId}}</button>
  `,
})

export class MaterialCellComponent implements ICellRendererAngularComp {

    public params: any;
    updateFlag = false;
    deleteFlag = false;
    
  refresh(params: ICellRendererParams): boolean {
	throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }
 
  agInit(params: any): void {
    this.params = params;  
    if(params.column.colId === 'update'){
      this.updateFlag = !params.data.modifyAccess ? true : false;
    }else if(params.column.colId === 'delete'){
      const used = this.params.data.used ? parseInt(this.params.data.used) : 0;
      this.deleteFlag = (used > 0 || !params.data.deleteAccess) ? true : false;
    }
  }

  btnClickedHandler() {
    this.params.clicked(this.params.column.colId, this.params.data);
  }
}




