<div class="popup-container" [formGroup]="fileDocForm">
  <header>
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="header d-block roboto-medium">
      <label class="ms-2 mt-2 mb-3">Import Estimate Cost</label>
    </div>
  </header>
    <div class="popup-contents" >
      <div>
        <div class="d-flex flex-wrap align-items-center p-2 px-4 col-12">
          <div class="text-secondary subtitle">
            Caution : Select excel-file for estimate cost
          </div>
            <span class="col-12 my-1">File<small>*</small></span>
            <label for="documentation" class="vpacs-btn-table col-4" (change)="handleUpload($event)">
                CHOOSE FILE
                <input type="file" id="documentation" style="display: none;" accept=".pdf, .xlsx, .doc" size="60" (change)="handleUpload($event)"/>
            </label>
            <span class="col-8">{{fileDocForm.controls['fileName'].value}}</span>
            <small>{{errorMessage1}}</small>
            
        </div>            
    </div>

      <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
        <div mat-dialog-close class="mx-3 cancel">Cancel</div>
        <button class="black-button mx-3" (click)="nextBtn()" >IMPORT</button>       
    </div>
    </div>
