import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DiscrepancySelectPartComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-select-part/discrepancy-select-part.component';

@Component({
  selector: 'discrepency-select-btn-cell-render',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table"
    style="height:30px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class DiscrepencySelectBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute,public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  suppliercode:any;
  partnumber:any;

  agInit(params: any): void {
    this.suppliercode = localStorage.getItem('suppliercode');
    this.partnumber = localStorage.getItem('partnumber');
    this.params = params;
    if (params.colDef.headerName === 'SELECT'){
      this.buttonName = 'SELECT';
    }
  }

  btnClickedHandler(_event: any) {
    if (this.buttonName === 'SELECT') {
      const data1=sessionStorage.getItem('parentData')
      let isTracking=''
      let url=''
      if(data1!=null) {
        const data=JSON.parse(data1)
       isTracking = data.isTracking
      url = data.url
      }
      if(isTracking){
        sessionStorage.setItem('siPartNum', this.params.data.partnumber)
        this.router.navigate([url])
      }
      else{
        this.dialog.open(DiscrepancySelectPartComponent,{
          data:{
            params:this.params.data,
            sCode:this.suppliercode,
            pNumber:this.partnumber
          }
        })
      }
    }
   

  }
}




