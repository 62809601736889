<div class="wrapper mx-3">
    <div class="header my-1">BackUp Define</div>
    <div class="mat-typography content">
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >RECEIVE FROM TOYOTA</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Part Information
                        <span class="text-black">{{defineData?.partNumber}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Plant
                        <span class="text-black" >{{defineData?.plantName}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Pkg Start Date
                        <span class="text-black">{{defineData?.pkgStartDate | date: 'MM/dd/yyyy'}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Model
                        <span class="text-black">{{defineData?.modelCode}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Status
                        <span class="text-black" >{{status}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">
                        <span></span>
                        <button class="vpacs-btn-table" style="height: '30px'" (click)="copyOther()">COPY FROM OTHER PART</button>    
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >CONTAINER INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-3 col-6 my-1">Package Code
                        <span><input type="text" [(ngModel)]="code" class="define" maxlength="15"></span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-4 col-6 my-1">L/W/H(mm)<span>
                            <input type="text" style="width:60px" [(ngModel)]="length" class="define">&nbsp;
                            <input type="text" style="width:60px" [(ngModel)]="width" class="define">&nbsp;
                            <input type="text" style="width: 60px;" [(ngModel)]="height"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-3 col-6 my-1">Estimated Cost/Pkg piece<span>
                            <input type="text"  [(ngModel)]="cost"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-3 col-6 my-1">Container Weight(kg)<span>
                            <input type="text"  [(ngModel)]="containerkg" class="define"></span>
                    </div>
                    <div class="d-grid col-lg-1 col-md-3 col-4 my-1">QPC
                        <span><input type="text" [(ngModel)]="qpc" class="define"></span>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >DUNNAGE INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Package Code
                        <span><input type="text" [(ngModel)]="dunCode" class="define" maxlength="15"></span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-4 col-6 my-1">L/W/H(mm)<span>
                            <input type="text" style="width:60px" [(ngModel)]="dunLength" class="define">&nbsp;
                            <input type="text" style="width:60px" [(ngModel)]="dunWidth" class="define">&nbsp;
                            <input type="text" style="width:60px;" [(ngModel)]="dunHeight"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Estimated Cost/Pkg piece<span>
                            <input type="text"  [(ngModel)]="dunCost"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Dunnage Weight(kg)<span>
                            <input type="text"  [(ngModel)]="dunContainerKg"
                                class="define"></span>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >TOP CAP INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Package Code
                        <span><input type="text" [(ngModel)]="topCode" class="define" maxlength="15"></span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-4 col-6 my-1">L/W/H(mm)<span>
                            <input type="text" style="width:60px" [(ngModel)]="topLength" class="define">&nbsp;
                            <input type="text" style="width:60px" [(ngModel)]="topWidth" class="define">&nbsp;
                            <input type="text" style="width:60px;" [(ngModel)]="topHeight"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Estimated Cost/Pkg piece<span>
                            <input type="text" [(ngModel)]="topCost"
                                class="define"></span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Top Cap Weight(kg)<span>
                            <input type="text" [(ngModel)]="topContainerKg"
                                class="define"></span>
                    </div>
                    <div  class="d-grid col-lg-2 col-md-4 col-6 my-1">
                        <span class="invisible">tytyt</span>
                        <button class="copy" style="height: '30px';" (click)="topCap()">STD BACK-UP TOP CAP</button>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >PALLET INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Package Code
                        <span>
                            <input type="text" [(ngModel)]="palletCode" class="define" maxlength="15">
                        </span>
                    </div>

                    <div class="d-grid col-lg-3 col-md-4 col-6 my-1">L/W/H(mm)<span>
                            <input type="text" style="width:60px" [(ngModel)]="palletLength" class="define">&nbsp;
                            <input type="text" style="width:60px" [(ngModel)]="palletWidth" class="define">&nbsp;
                            <input type="text" style="width:60px;" [(ngModel)]="palletHeight"
                                class="define"></span></div>

                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Estimated Cost/Pkg piece
                        <span>
                            <input type="text" [(ngModel)]="palletCost" class="define">
                        </span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-1">Pallet Weight(kg)<span>
                            <input type="text" [(ngModel)]="palletContainerKg"
                                class="define"></span>
                    </div>
                    <div  class="d-grid col-lg-2 col-md-4 col-6 my-1">
                        <span class="invisible">STD WOOD</span>
                        <button class="copy" style="height: '30px';" (click)="pallet()">STD WOOD PALLET</button>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel7 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >ATTACH PICTURE OF PACKAGE</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel7.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>

                <div class="d-flex">
                    <div class="col-11">
                        <div class="img-cont col d-flex px-3 gap-2">
                            <div class="left col-6">
                                <div *ngIf="imageSrc">
                                    <img style="width: 125px; height: 125px; margin-top:18px"  [src]="imageSrc"alt="partImage" >
                                </div>                                   
                                <label for="file-upload" class="vpacs-btn-table my-1" style="width: 100px;">
                                    CHOOSE FILE
                                    <input type="file" id="file-upload" accept=".gif,.jpg," style="display: none;"
                                        (change)="fileChange($event)" alt="partImage"/>
                                </label>
                            </div>
                        </div>
                        <div class="text-secondary px-4 subtitle">Image size must be under 200KB. Extension must be .gif
                            or .jpg</div>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0" *ngIf="packageReviewStatus">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title >PACKAGE REVIEW </mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Result
                        <span class="text-black">{{status}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested 
                        <span>{{defineRes?.packageReview?.requested}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested Changes
                        <span>{{defineRes?.packageReview?.requestChanges}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason
                        <span>{{defineRes?.packageReview?.reason}}</span>
                    </div>
                    <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason Requested
                        <span>{{defineRes?.packageReview?.reasonRequested}}</span>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="footer-dialog d-flex fixed-bottom">
        <div class="mx-3 cancel" (click)="goBack()">Cancel</div>
        <button class="vpacs-btn-table caps mx-3" style="height: 40px;" *ngIf="defineRes?.approvalstatus !== '0'" (click)="saveClick('save')">Save</button>
        <button class="black-button mx-3" *ngIf="defineRes?.approvalstatus !== '0'" (click)="saveClick('submit')">Submit</button>
    </div>
</div>
