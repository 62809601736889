import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { DoPkgAuthorizationComponent } from 'src/app/pages/pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';

@Component({
  selector: 'pkg-authorization-do-btn-cell-render',
  template:
    `
    <div class="d-flex align-items-center" *ngIf="this.type === 'do' && this.disableAuthDownload">
        <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center " 
          style="height:30px; font-size: 21px"
          title="Do" (click)="goToDoBtn()">check_circle
        </mat-icon>
    </div>
    <div class="d-flex align-items-center" *ngIf="this.type === 'auth' && !this.disableAuthDownload">
      <mat-icon class="vpacs-btn-table d-flex justify-content-center align-items-center " 
        style="height:30px; font-size: 21px"
        title="Download" (click)="authDownload()">download
      </mat-icon>
    </div>
    `,
  providers: [DoPkgAuthorizationComponent]
})

export class PackageAuthorizationDoBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute,public dopkgauth: DoPkgAuthorizationComponent) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  public params: any;
  type = ''
  disableAuthDownload = false;
  agInit(params: any): void {
    this.params = params;
    if(this.params.data.SUPORFAB === this.params.data.WHICH_REQUEST){
      this.disableAuthDownload = true;
    }
    if(params.column.colId === 'do'){
      this.type = 'do'
    }else{
      this.type = 'auth';
    }
  }

  authDownload(){
    const queryParams = {
      authNumber: this.params.data.AUTHNUMBER,
    }
    sessionStorage.setItem('queryParamsDo', JSON.stringify(queryParams));
    this.dopkgauth.authDownload()
  }

  goToDoBtn(): void {    
    const query: any = {
      authNumber: this.params.data.AUTHNUMBER,
      status: this.params.data.AUTHSTATUSNAME,
      message: this.params.data.SPECIALISTCODE,
      plantCode: this.params.data.PLANTCODE,
      supplierCode: this.params.data.SUPPLIERCODE,
      pkgStartDate: this.params.data.PKGSTARTDATE,
      modelCode: this.params.data.MODELCODE,
      supOrFab: this.params.data.SUPORFAB,
      whichRequest: this.params.data.WHICH_REQUEST,
      validFlag: this.params.data.VALIDFLAG,
      authStatus:this.params.data.AUTHSTATUS,
      authType:this.params.data.AUTHTYPE,
      confirmStatus:this.params.data.CFMSTATUS
    };
    sessionStorage.setItem('queryParamsDo', JSON.stringify(query))
    this.router.navigate(['pkg-authorization/do-pkg-authorization']);
  }

  setWhichRequest(){
    let value = 0;
    const {SUPORFAB,SUPPLIERCODE,REQUEST_TO,FABCODE} = this.params.data;
    if (SUPORFAB === 0) {
        if(SUPPLIERCODE !== REQUEST_TO){
          value = 1;
        }
    }else if(FABCODE === REQUEST_TO){
        value = 1;
    }
    return value;
  }

}
