<div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Select Part</div>
    <div>
      <button class="help-btn" (click)="backBtn()">BACK</button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
  </div>
  <div class="vpacs-shadow m-3 p-2" *ngIf="haveAccess">
    <div class="filter m-2 d-flex" (keyup.enter)="onSearch()" >
      
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Family Code</label>
        <input type="text" id="family-code" [(ngModel)]="familycode" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
        <label for="plant" class="col-4">Plant</label>
          <mat-select id="plant" class="mat-sel pt-1" [(ngModel)]="plantDropdown">
              <mat-option *ngFor="let plant of selectedPlant" [value]="plant.plantcode" >{{plant.plant}}</mat-option>
          </mat-select>
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Supplier</label>
        <input type="text" id="family-code" [(ngModel)]="suppliercode" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
        <label for="model-code" class="col-4">Package Start From Date</label>
        <input type="date" id="model-code" style="width: 100%;" [(ngModel)]="fromdate"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  ">
        <label for="part" class="col-4">Part M3</label>
        <div class="floating">
          <label for="over">Over</label>
          <input type="text" id="part-1" [(ngModel)]="over" style="width: 100%;"  />
        </div>
        <div class="mx-1 floating">
          <label for="over">Under</label>
          <input type="text" id="part-1" [(ngModel)]="under" style="width: 100%;"  />
        </div>
      </div>
      
  
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Model Code</label>
        <input type="text" id="family-code" style="width: 100%;" [(ngModel)]="modelcode"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Part Number</label>
        <input type="text" id="family-code" [(ngModel)]="partnumber" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Part Description</label>
        <input type="text" id="family-code" [(ngModel)]="partdescription" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="model-code" class="col-4">Package Start To Date</label>
        <input type="date" id="model-code" [(ngModel)]="toDate" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 ">
        <label for="part" class="col-4">Total Weight(kg)</label>
        <div class="floating">
          <label for="over">Over</label>
          <input type="text" id="part-1" style="width: 100%;"  />
        </div>
        <div class="mx-1 floating">
          <label for="over">Under</label>
          <input type="text" id="part-1" style="width: 100%;"  />
        </div>
      </div>
  
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12 me-5">
        <label for="family-code" class="col-4">Package Code</label>
        <input type="text" id="family-code" style="width: 100%;"  />
      </div>
      <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
        <label for="plant" class="col-4">Specialist</label>
        <input type="text" style="width: 100%;" [(ngModel)]="specialistcode" [ngModelOptions]="{ standalone: true }" />
        <button mat-icon-button>
          <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
        </button>
      </div>
      <div class="d-flex  justify-content-end col-lg-4 col-md-6 col-12">
        <button class="black-button" (click)="onSearch()">Search</button>
      </div>
    </div>
  </div>
  <div class=" m-2 py-2 px-2">
    <div class="grid-agg mt-3">
      <ag-grid-angular style="width: 100%;height: 400px" class="ag-theme-balham" (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40" [domLayout]="domLayout">
      </ag-grid-angular>
      <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)">
    </app-pagination>
  
    </div>
  
    <router-outlet></router-outlet>
  