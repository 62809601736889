import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PackageAuthorizationService } from 'src/app/services/pkg-authorization.service';
import { PackageAuthorizationDoBtnCellRender } from 'src/app/core/gridrender/pkg-authorization-do-btn-cell-render';
import { PackageAuthorizationSubmitBtnCellRender } from 'src/app/core/gridrender/pkg-authorization-submit-btn-cell-render';
import { formatDate } from '@angular/common';
import { DocumentDownload } from 'src/app/core/gridrender/document-download';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
 selector: 'app-pkg-authorization',
 templateUrl: './pkg-authorization.component.html',
 styleUrls: ['./pkg-authorization.component.scss'],
 providers: [RegexFilter]
})

export class PkgAuthorizationComponent implements OnInit {

  public pkgstep:any;
  public rowData :any;
  public searchParameters:any = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    partnum: new FormControl(''),
    model: new FormControl(''),
    type: new FormControl(''),
    supplier: new FormControl(),
    from: new FormControl(''),
    check: new FormControl('Do'),
  });
  public authStatusName:any[] = ['Confirm', 'Readiness', 'Finish'];
  public types:any[] = ['New Purchase', 'Add Purchase', 'Move','Dispose', 'Maintenance', 'proto Type'];

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  currentPageNo  = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [] ;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  rowHeight = 50;
  paginationPageSize = 20
  isAccessible = false;
  dateArray:any[] = [];

  public rowClassRules:any ;
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  supplierNumber: any;
  doRadioButtons = [
    { id: 'onlydo', value: 'Do', text: 'OnlyDo' },
    { id: 'all', value: 'All', text: 'All' },
  ];
  loading = false;

  constructor(
      public PackageAuthorizationService: PackageAuthorizationService, 
      public regex:RegexFilter,
      public globalState:GlobalStateService,
      public state : ExportStateService,
      public constants : ConstantsService
    ) {
    this.defaultColDef = {
      suppressMovable: false,       
      suppressPaginationPanel:true,   
      autoHeight: true,   
      filterParams: { newRowsAction: 'keep' },
      flex : 1 //occupy entire width in page
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-pink': 'data.VALIDFLAG === 2',
      'row-bold': this.setBold,
    };

  }
  
  ngOnInit(): void {
    this.state.roleState.subscribe({
      next : (res:any)=>{
        if(res){
          this.PackageAuthorizationService.loadSteps().subscribe ((res:any)=> {
            this.pkgstep = res;
          } )
          if(!(this.constants.adminSystemRoles.some((ele:any) => res.includes(ele)))){
            this.getSupplierCodes();
          }else{
            this.performInitialOperation()
          }
        }
      }
    })
  }

  getSupplierCodes(){
    this.state.userInfoState.subscribe({
      next : (res:any) =>{
        if(res){
          const data = this.state.setSupplierCode(res.supplierCode);
          if(data.length > 0){
            this.performInitialOperation(data)
          }
        }
      }
    })
  }

  performInitialOperation(inputData?:any[]){
    if(localStorage.hasOwnProperty("tracking")){
      this.supplierNumber = JSON.parse(localStorage.getItem("tracking") ?? '');
    }else{
      this.supplierNumber = inputData;
    }
    this.getColumnDefs();
    let data = this.globalState.getPkgAuthFilters()    
    data = this.regex.checkValueEqualsStar(data);
    if (data) {
      this.apiCall(this.supplierNumber);
    } else {
      if(this.supplierNumber.length > 1){
        this.defaultSearchFilter('ALL');
        this.apiCall(this.supplierNumber);
      }else{
        this.defaultSearchFilter(0);
        this.apiCall([this.supplierNumber[0]]);
      }
    }
    if (this.supplierNumber && this.supplierNumber?.length <= 1) {
      this.searchParameters.controls.supplier.disable();
    }
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "Date", field: "AUTHDATEFILTER", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
        comparator: (valueA:any, valueB:any, _nodeA:any, _nodeB:any, _isInverted:any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: "Type", field: "AUTHTYPEDESC", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 110, maxWidth:120,
      },
      {
        headerName: "Status", field: "AUTHSTATUSNAME", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
      },
      {
        headerName: "From", field: "SPECIALISTCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100, maxWidth:110,
      },
      {
        headerName: "Plant", field: "PLANTNAME", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
      },
      {
        headerName: "AuthNumber", field: "AUTHNUMBER", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 120, maxWidth:150,
      },
      {
        headerName: "Supplier", field: "SUPPLIERCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
      },
      {
        headerName: "Container Fabricator", field: "FABCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,
      },
      {
        headerName: "Dunnage1 Fabricator", field: "DUN1FABCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,
      },
      {
        headerName: "Family", field: "FAMILYCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
      },
      {
        headerName: "Model", field: "MODELCODE", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90, maxWidth:100,
      },
      {
        headerName: "Part Number", field: "PARTNUMBER", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 110, maxWidth:120,cellRendererFramework: DocumentDownload
      },
      {
        headerName: "Part Name", field: "PARTNAME", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter',tooltipValueGetter: (params:any)=> params.value, sortable: true, unSortIcon: true, minWidth: 300,cellStyle: this.cellStyleColumn
      },
      {
        headerName: "Do", field: "do", minWidth: 60, maxWidth:100,cellRendererFramework: PackageAuthorizationDoBtnCellRender
      },
      {
        headerName: "Auth", field: "auth", minWidth: 60, maxWidth:100,cellRendererFramework: PackageAuthorizationDoBtnCellRender
      },
      {
        headerName: "Submit", field: "submit", minWidth: 60, maxWidth:100,cellRendererFramework: PackageAuthorizationSubmitBtnCellRender
      },
    ];
  }

  valueChange(_event:any){
    this.onSearch();
  }

  async apiCall(number: any) {
    this.displayRowData = [];
    this.rowData = [];
    this.rowCount = 0;
    this.loading = true;
    try {
      for (const element of number) {
        const response:any = await this.PackageAuthorizationService.testGetCount(element).toPromise();
        const lambdaSize = 10000;
        let rowRangeMin = 0;
        let count = response.count;        
        while (count > 0) {
          const res = await this.PackageAuthorizationService.loadPkgAuthorizationData(element, lambdaSize, rowRangeMin).toPromise();
          const data = res.data.filter((item:any) => item);
          data.length > 0 && data.forEach((element:any) => {
            if(element !== null){
              element.SUPORFAB = res.supOrFab;
              element.WHICH_REQUEST = this.getWhichRequest(element);
              Object.assign(element, {
                SUPORFAB : res.supOrFab,
                AUTHDATEFILTER: this.authDate(element.AUTHDATE),
                AUTHTYPEDESC:this.typeGetter(element.AUTHTYPE),
                AUTHSTATUSNAME: this.getAuthStatusName(element.CFMSTATUS, element.READYSTATUS,'authStatusName'),
                JOBSTATUS: this.getJobStatus(element.CFMSTATUS, element.READYSTATUS),
                AUTHSTATUS: element.CFMSTATUS !== 4 ? 1 : 2, 
                DOFLAG: this.doFlag(element),
                SUBMITFLAG: this.submitFlag(element)
              })
            }
          });
          this.rowData = [...this.rowData,...data];
          rowRangeMin += lambdaSize;
          count -= lambdaSize;
        }
      }
      let data2 = this.globalState.getPkgAuthFilters();
      data2 = this.regex.checkValueEqualsStar(data2);
      if (data2) {
        this.defaultSearchFilter2(data2);
      }
      this.rowData = this.rowData.filter((a:any) => a);
      this.displayRowData = this.rowData.length > 0 ? this.rowData.filter((item:any) => item.DOFLAG) : [];
      this.rowCount = this.displayRowData.length;
      if(this.rowCount === 0){
        this.gridApi?.hideOverlay();
        this.gridApi?.showNoRowsOverlay();
      }
      this.onSearch();
    } catch (_error) {
      this.displayRowData = [];
      this.rowData = [];
      this.rowCount = 0;
      this.gridApi?.hideOverlay();
      this.loading = false;
    }finally{
      this.gridApi?.hideOverlay();
      this.loading = false;
    }
  }

  authDate(params:any){
    return params ? this.dateConverter({value : params}) : ''
  }

  dateConverter(params:any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }

  dateFormatter(params:any) {
    if(params.value && params.value !== '-'){
      return params.value
    }else{
      return ''
    }
  }

  typeGetter(authType?:any){
    return this.types[parseInt(authType)]
  }


  getAuthStatusName(cfmStatus:any, readyStatus:any, type:string){
    if(cfmStatus !== 4){
      return (type === 'authStatus')? 1 : this.authStatusName[0]
    }else{
      if(readyStatus === 4){
        return this.authStatusName[2]
      }

      return (type === 'authStatus')? 2 : this.authStatusName[1]
    }
  }

  getJobStatus(cfmStatus:any, readyStatus:any){
    return (cfmStatus !== 4) ? cfmStatus : readyStatus;
  }

  getWhichRequest(element:any){
    let value:any = 0;
    if (element.SUPORFAB === 0) {
      if(element.SUPPLIERCODE === element.REQUEST_CC || element.SUPPLIERCODE === element.REQUEST_TO){
        if(element.SUPPLIERCODE !== element.REQUEST_TO){
          value = 1;
        }
      }
    }else if(element.FABCODE === element.REQUEST_CC || element.FABCODE === element.REQUEST_TO || element.DUN1FABCODE === element.REQUEST_TO){
      if(element.FABCODE === element.REQUEST_TO  || element.DUN1FABCODE === element.REQUEST_TO){
        value = 1;
      }
    }
    return value;
  }

  setBold(params:any){
    const data = params.data;
    let bold = false;
    if(data?.JOBSTATUS === 1){
      if(data.SUPORFAB === data.WHICH_REQUEST){
        bold = true
      }
    }
    return bold;
  }

  doFlag(data:any){    
    return data.READYSTATUS !== 4
  }

  submitFlag(data:any){
    let outputbutton:boolean;
    let submitFlag = false;
    if(data.SUPORFAB === data.WHICH_REQUEST) {
         outputbutton = true;
    } else {
         outputbutton = false;
    }

    if(outputbutton) {
      if((data.CFMSTATUS === 7 || data?.READYSTATUS === 7)  && data.VALIDFLAG === 1) {
        submitFlag = true;
      }
    }
    return submitFlag;
  }

  
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage()}
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1}
  goToFirstPage() { this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      this.currentPageBoolean = true;
    }
    else { this.currentPageBoolean = false; }
    return this.currentPageBoolean;
  }
  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }
  onPaginationChanged() {
  if (this.gridApi) {
    this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
    this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
    this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
  }
}
  onPageSizeChanged(event: any) {
    if (event.target.value === "ALL"){
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    }else{
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params:any) {
    this.gridApi = params.api;
    this.gridApi?.sizeColumnsToFit();
    if (this.globalState.getPkgPage()) {
      const retrivedValue = this.globalState.getPkgPage()
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }
  filterChanged(_event: any) {
    const filter = this.gridApi?.getFilterModel();
    this.globalState.pkgPage(filter);
    this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
    this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());   
    this.rowCount = this.gridApi.getDisplayedRowCount(); 
  }
  
  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  cellStyleColumn(_params: any) {
		return {
		  'display': 'inline-block',
		  'text-overflow': 'ellipsis',
		  'white-space': 'nowrap',
		  'overflow': 'hidden',
		  'vertical-align': 'middle',
		  'width' : _params.colDef.maxWidth || _params.colDef.minWidth || _params.colDef.width,
		  'padding-left' : '5px',
		};
	}

  defaultSearchFilter(supplier:any) {
    this.searchParameters = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      partnum: new FormControl(''),
      model: new FormControl(''),
      type: new FormControl('All'),
      supplier: new FormControl(supplier),
      from: new FormControl(''),
      check: new FormControl('Do'),
    });    
    this.globalState.setPkgAuthFilters({
      ...this.searchParameters.value,
    }); 
  }

  defaultSearchFilter2(data:any) {
    this.searchParameters = new FormGroup({
      fromDate: new FormControl(data?.fromDate),
      toDate: new FormControl(data?.toDate),
      partnum: new FormControl(data?.partnum),
      model: new FormControl(data?.model),
      type: new FormControl(data?.type || 'All'),
      supplier: new FormControl(data?.supplier),
      from: new FormControl(data?.from),
      check: new FormControl(data?.check),
    });
    if(this.rowData.length > 0){
      this.onSearch();
    }
  }

  dateFilter(filteredData:any, searchParameters:any){
    if(searchParameters.value.fromDate  && searchParameters.value.toDate === ''){ 
        filteredData =  filteredData.filter((item:any) => {
          if(item.AUTHDATE){
            return new Date(item.AUTHDATE.replace(/\//g, '-')) >=new Date(searchParameters.value.fromDate) 
          }else{ return null }
        })
    }

    if(searchParameters.value.fromDate === ''  && searchParameters.value.toDate ){ 
        filteredData =  filteredData.filter((item:any) => {
          if(item.AUTHDATE){
            return new Date(item.AUTHDATE.replace(/\//g, '-')) <=new Date(searchParameters.value.toDate) 
          }else{ return null; }
        })
    }

    if(searchParameters.value.fromDate && searchParameters.value.toDate ){ 
        filteredData = filteredData.filter((item:any) => {
          if(item.AUTHDATE){
            return (new Date(item.AUTHDATE.replace(/\//g, '-')) >=new Date(searchParameters.value.fromDate) &&
            (new Date(item.AUTHDATE.replace(/\//g, '-')) <=new Date(searchParameters.value.toDate)))
          }else{ return null; }
        })
    }
    return filteredData;
  }

  dateOnChange(event: KeyboardEvent, type:any){
    if((event.target as HTMLInputElement).validity.valid){
      if(type === 'from'){
        this.searchParameters.controls.fromDate.setErrors(null)
      }else{
        this.searchParameters.controls.toDate.setErrors(null)
      }
    }else{
      if(type === 'from'){
        this.searchParameters.controls.fromDate.setErrors({'incorrect': true})
      }else{
        this.searchParameters.controls.toDate.setErrors({'incorrect': true})
      }
    }
  }

  async onSearch(){ 
    if(this.searchParameters.controls.fromDate.value){
      this.searchParameters.controls.fromDate.setErrors(null)
    }
    if(this.searchParameters.controls.toDate.value){
      this.searchParameters.controls.toDate.setErrors(null)
    }
    
    let filteredData:any[] = this.rowData;
    if(this.searchParameters.value.supplier === 'ALL'){
      filteredData = this.rowData;
    }else{
      if(this.supplierNumber.length >1){
        filteredData = filteredData.filter((x:any)=>{
          return x.SUPPLIERCODE.includes(this.supplierNumber[this.searchParameters.value.supplier])
        })
      }
    }
    for(const item of this.rowData){
       this.dateArray.push(item.AUTHDATE); 
    }
    const {model,partnum,type,from,check} = this.searchParameters.value;
    const filterParams: any = {
      MODELCODE: model || '*', 
      PARTNUMBER : partnum ? this.regex.addStarForPartNumber(partnum) : '*',
      AUTHTYPEDESC : type ? type : '*',
      SPECIALISTCODE: from || '*'
    };    

    filteredData = this.dateFilter(filteredData, this.searchParameters)
              
    if(filterParams.AUTHTYPEDESC === 'All'){
      filterParams.AUTHTYPEDESC = '*'
    }
    if(check === 'Do'){
      filteredData = filteredData.filter((item:any) => item.DOFLAG)      
    }


  filteredData.forEach((o:any) => {
    Object.keys(o).forEach((k) => {
        if (o[k] === null){o[k] = '-';}
    });
  });
  this.globalState.setPkgAuthFilters(this.searchParameters.value)        
  filteredData = this.regex.regexFilter(filteredData,filterParams)
  this.displayRowData = filteredData
      this.gridApi.setRowData(this.displayRowData);
      this.rowCount = filteredData.length; 
  }
 
}