import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  public spinnerVisible = new BehaviorSubject<boolean>(false);
  spinnerVisible$ = this.spinnerVisible.asObservable()


  show() {
    setTimeout(() => {
      this.spinnerVisible.next(true)
    });
  }

  hide() {
    setTimeout(() => {
      this.spinnerVisible.next(false)
    });   
  }


}