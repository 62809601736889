import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError,Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class DlstatusService {

  public httpHeaderDetails:any;
  constructor(private readonly httpClient: HttpClient, public constantsService: ConstantsService) {
    this.httpHeaderDetails = new HttpHeaders({'x-api-key': environment.API_CALL, 'Authorization': sessionStorage.getItem('idToken') || ''});
  }

  productionStatusDownload(supplierCode:any): Observable<any> {
    return this.httpClient
        .post(this.constantsService.EXTERNAL  + `trackingStatus/productionStatus`, supplierCode, {observe: 'response', responseType: 'blob', headers: this.httpHeaderDetails})
        .pipe(catchError(this.handleError))
  }
  
  backUpStatusDownload(supplierCode:any): Observable<any> {
    return this.httpClient
        .post(this.constantsService.EXTERNAL + `trackingStatus/backUpStatus`, supplierCode, { observe: 'response', responseType: 'blob',headers: this.httpHeaderDetails})
        .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
		return (error.message || "Server Error");
	}
}
