import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackUpDefineService } from 'src/app/services/back-up-define.service';

@Component({
  selector: 'app-back-up-define-tracking',
  templateUrl: './back-up-define-tracking.component.html',
  styleUrls: ['./back-up-define-tracking.component.scss']
})
export class BackUpDefineTrackingComponent implements OnInit {

  imageUpload = true;
  defineData: any;
  supplierCode: any;
  approvalStatus: any;
  code: any;
  length: any;
  height: any;
  width: any;
  cost: any;
  containerkg: any;
  qpc: any;
  packageReviewStatus = false;
  submit = false;
  packageReview: any;
  selectData: any;
  dunCode: any;
  dunLength: any;
  dunWidth: any;
  dunHeight: any;
  dunCost: any;
  dunContainerKg: any;
  topCode: any;
  topLength: any;
  topWidth: any;
  topHeight: any;
  topCost: any;
  palletCode: any;
  palletLength: any;
  palletWidth: any;
  palletHeight: any;
  palletContainerKg: any;
  topContainerKg: any;
  palletCost: any;
  plantCode: any
  partNumber: any;
  pkgStartDate: any;
  templateFile: any;
  imageSrc: any;
  selectedImage: any;
  errorMessage: string[] = [];
  selectedValue: any
  private readonly shouldcallSelectAPI = true
  selectApiCalled = false
  defineRes: any;
  defineStatus: any;
  status: any;
  image: any;
  costPrice="Cost/Price[Container] must be a Number!"
  constructor(private readonly router: Router, public definebackUpService: BackUpDefineService) {
  }

  ngOnInit(): void {
    this.defineData = JSON.parse(sessionStorage.getItem('queryParams') || '');
    {
      if (sessionStorage.getItem('isselectedValue') === 'true') {
        this.selectApiCalled = true
        sessionStorage.removeItem('isselectedValue');
      }
      if (this.selectApiCalled) {
        this.select();
      }
      else {
        this.define();
      }
    }
  }
  select() {
    this.selectData = JSON.parse(sessionStorage.getItem('queryParamsSelect') || '')

    this.definebackUpService.selectOnLoad(this.selectData).subscribe((res: any) => {
      this.status = this.appStatus(res[1].status)
      this.imageSrc = res[1].image === 'No image' ? null : res[1].image;

      this.packageReviewStatus = (this.status === "Approved" || this.status === "Rejected") ? true : false
      this.submit = this.status === "Working" ? true : false
      const prefetcheddata = JSON.parse(sessionStorage.getItem('copybackupdata') || '')
      this.code = res[0]?.containerInformation?.packageCode === null ? '' : res[0]?.containerInformation?.packageCode
      this.length = (res[0]?.containerInformation?.LHW.split('/')[0] === null || res[0]?.containerInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[0]
      this.width = (res[0]?.containerInformation?.LHW.split('/')[2] === null || res[0]?.containerInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[2]
      this.height = (res[0]?.containerInformation?.LHW.split('/')[1] === null || res[0]?.containerInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[1]
      this.cost = prefetcheddata.con_cost || '';
      this.containerkg = (res[0]?.containerInformation?.weight === null || res[0]?.containerInformation?.weight === 0) ? '' : res[0]?.containerInformation?.weight
      this.qpc = prefetcheddata.con_qpc || '';

      this.dunCode = res[0]?.dunnageInformation?.packageCode === null ? '' : res[0]?.containerInformation?.weight
      this.dunLength = (res[0]?.dunnageInformation?.LHW.split('/')[0] === null || res[0]?.dunnageInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[0]
      this.dunWidth = (res[0]?.dunnageInformation?.LHW.split('/')[2] === null || res[0]?.dunnageInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[2]
      this.dunHeight = (res[0]?.dunnageInformation?.LHW.split('/')[1] === null || res[0]?.dunnageInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[1]
      this.dunCost = prefetcheddata.dun_cost || '';
      this.dunContainerKg = (res[0]?.dunnageInformation?.weight === null || res[0]?.dunnageInformation?.weight === 0) ? '' : res[0]?.dunnageInformation?.weight

      this.topCode = res[0]?.topCapInformation?.packageCode === null ? '' : res[0]?.topCapInformation?.packageCode
      this.topLength = (res[0]?.topCapInformation?.LHW.split('/')[0] === null || res[0]?.topCapInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[0]
      this.topWidth = (res[0]?.topCapInformation?.LHW.split('/')[2] === null || res[0]?.topCapInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[2]
      this.topHeight = (res[0]?.topCapInformation?.LHW.split('/')[1] === null || res[0]?.topCapInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[1]
      this.topCost = prefetcheddata.topCost || '';
      this.topContainerKg = (res[0]?.topCapInformation?.weight === null || res[0]?.topCapInformation?.weight === 0) ? '' : res[0]?.topCapInformation?.weight

      this.palletCode = res[0]?.palletInformation?.packageCode === null ? '' : res[0]?.palletInformation?.packageCode
      this.palletLength = (res[0]?.palletInformation?.LHW.split('/')[0] === null || res[0]?.palletInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[0]
      this.palletWidth = (res[0]?.palletInformation?.LHW.split('/')[2] === null || res[0]?.palletInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[2]
      this.palletHeight = (res[0]?.palletInformation?.LHW.split('/')[1] === null || res[0]?.palletInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[1]
      this.palletCost = prefetcheddata.plt_cost || '';
      this.palletContainerKg = (res[0]?.palletInformation?.weight === null || res[0]?.palletInformation?.weight === 0) ? '' : res[0]?.palletInformation?.weight
    });
  }
  define() {
    this.definebackUpService.defineOnLoad(this.defineData).subscribe((res: any) => {
      this.defineRes = res[0]
      this.status = this.appStatus(res[0].approvalstatus)
      this.imageSrc = res[0]?.attachPictureOfPackage?.image
      this.packageReviewStatus = (this.status === "Approved" ||this.status=== "Rejected") ? true : false
      this.submit = this.status === "Working" ? true : false

      this.packageReview = this.defineRes?.packageReview
      this.code = res[0]?.containerInformation?.packageCode === null ? '' : res[0]?.containerInformation?.packageCode
      this.length = (res[0]?.containerInformation?.LHW.split('/')[0] === null || res[0]?.containerInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[0]
      this.width = (res[0]?.containerInformation?.LHW.split('/')[2] === null || res[0]?.containerInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[2]
      this.height = (res[0]?.containerInformation?.LHW.split('/')[1] === null || res[0]?.containerInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.containerInformation?.LHW.split('/')[1]
      this.cost = (res[0]?.containerInformation?.estimated === null || res[0]?.containerInformation?.estimated === 0) ? "" : res[0]?.containerInformation?.estimated
      this.containerkg = (res[0]?.containerInformation?.weight === null || res[0]?.containerInformation?.weight === 0) ? "" : res[0]?.containerInformation?.weight
      this.qpc = (res[0]?.containerInformation?.qpc === null || res[0]?.containerInformation?.qpc === 0) ? "" : res[0]?.containerInformation?.qpc

      this.dunCode = res[0]?.dunnageInformation?.packageCode === null ? "" : res[0]?.dunnageInformation?.packageCode
      this.dunLength = (res[0]?.dunnageInformation?.LHW.split('/')[0] === null || res[0]?.dunnageInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[0]
      this.dunWidth = (res[0]?.dunnageInformation?.LHW.split('/')[2] === null || res[0]?.dunnageInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[2]
      this.dunHeight = (res[0]?.dunnageInformation?.LHW.split('/')[1] === null || res[0]?.dunnageInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.dunnageInformation?.LHW.split('/')[1]
      this.dunCost = (res[0]?.dunnageInformation?.estimated === null || res[0]?.dunnageInformation?.estimated === 0) ? '' : res[0]?.dunnageInformation?.estimated
      this.dunContainerKg = (res[0]?.dunnageInformation?.weight === null || res[0]?.dunnageInformation?.weight === 0) ? '' : res[0]?.dunnageInformation?.weight

      this.topCode = res[0]?.topCapInformation?.packageCode === 'null' ? '' : res[0]?.topCapInformation?.packageCode
      this.topLength = (res[0]?.topCapInformation?.LHW.split('/')[0] === null || res[0]?.topCapInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[0]
      this.topWidth = (res[0]?.topCapInformation?.LHW.split('/')[2] === null || res[0]?.topCapInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[2]
      this.topHeight = (res[0]?.topCapInformation?.LHW.split('/')[1] === null || res[0]?.topCapInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.topCapInformation?.LHW.split('/')[1]
      this.topCost = (res[0]?.topCapInformation?.estimated === null || res[0]?.topCapInformation?.estimated === 0) ? '' : res[0]?.topCapInformation?.estimated
      this.topContainerKg = (res[0]?.topCapInformation?.weight === null || res[0]?.topCapInformation?.weight === 0) ? '' : res[0]?.topCapInformation?.weight

      this.palletCode = res[0]?.palletInformation?.packageCode === null ? '' : res[0]?.palletInformation?.packageCode
      this.palletLength = (res[0]?.palletInformation?.LHW.split('/')[0] === null || res[0]?.palletInformation?.LHW.split('/')[0] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[0]
      this.palletWidth = (res[0]?.palletInformation?.LHW.split('/')[2] === null || res[0]?.palletInformation?.LHW.split('/')[2] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[2]
      this.palletHeight = (res[0]?.palletInformation?.LHW.split('/')[1] === null || res[0]?.palletInformation?.LHW.split('/')[1] === 0) ? '' : res[0]?.palletInformation?.LHW.split('/')[1]
      this.palletCost = (res[0]?.palletInformation?.estimated === null || res[0]?.palletInformation?.estimated === 0) ? '' : res[0]?.palletInformation?.estimated
      this.palletContainerKg = (res[0]?.palletInformation?.weight === null || res[0]?.palletInformation?.weight === 0) ? '' : res[0]?.palletInformation?.weight
      const prefetchedData = {
        con_cost: this.cost,
        dun_cost: this.dunCost,
        topCost: this.topCost,
        plt_cost: this.palletCost,
        con_qpc: this.qpc
      }
      sessionStorage.setItem('copybackupdata', JSON.stringify(prefetchedData))
    });
  }
  appStatus(data: any) {
    switch (data) {
        case '-1':
        case null:
            this.approvalStatus = 'Working';
            break;
        case '1':
            this.approvalStatus = 'Approved';
            break;
        case '2':
            this.approvalStatus = 'Rejected';
            break;
        case '0':
            this.approvalStatus = 'Not Approved';
            break;
        default:
            this.approvalStatus = data;
    }
    return this.approvalStatus;
}

  fileChange(event: any) {
    this.templateFile = event.target.files[0];
    if (this.templateFile.size <= 200 * 1024) {
      const reader = new FileReader()
      reader.readAsDataURL(this.templateFile)
      reader.onload = () => {
        this.imageSrc = reader.result;
      }
    } else {
      alert("File size should be less than 200KB")
    }
  }

  topCap() {
    this.topCode = "STD_LID EXP";
    this.topLength = 1219
    this.topWidth = 1143
    this.topHeight = 13
    this.topContainerKg = 0.2267
  }
  pallet() {
    this.palletCode = "STD_PLT EXP"
    this.palletLength = 1219
    this.palletWidth = 1143
    this.palletHeight = 127
    this.palletContainerKg = 11.3398
  }
  copyOther() {
    this.defineData = JSON.parse(sessionStorage.getItem('queryParams') || '');
    this.router.navigate(['/tracking/define-backup/select']);
  }
  saveClick(type: string) {
    this.errorMessage = []
    if (this.code === '') {
      this.errorMessage.push("Please fill 'Container Code'!");
    } 
    if (this.cost === '') {
      this.errorMessage.push("Please fill 'Container Estimated Cost/Pkg Piece'!");
    } else if (isNaN(this.cost)) {
      this.errorMessage.push(this.costPrice);
    }
    if (this.qpc === '') {
      this.errorMessage.push("Please fill 'QPC'!");
    } else if (isNaN(this.qpc)) {
      this.errorMessage.push("QPC must be a Number!");
    }
    if (this.length === '' || this.width === '' || this.height === '') {
      this.errorMessage.push("Please fill 'Container Dims'!");
    }
    if (isNaN(this.length)) {
      this.errorMessage.push("Length[Container] must be a Number!");
    } 
    if (isNaN(this.width)) {
      this.errorMessage.push("Width[Container] must be a Number!");
    } 
    if (isNaN(this.height)) {
      this.errorMessage.push("Height[Container] must be a Number!");
    }

    if (this.containerkg === '') {
      this.errorMessage.push("Please fill 'Container Weight'!");
    } else if (isNaN(this.containerkg)) {
      this.errorMessage.push("Weight[Container] must be a Number!");
    }

    if (this.dunCode) {
      if (this.dunCost === '') {
        this.errorMessage.push("Please fill 'Dunnage Estimated Cost/Pkg Piece'!");
      } else if (isNaN(this.dunCost)) {
        this.errorMessage.push(this.costPrice);
      } 
      if (this.dunLength === '' || this.dunWidth === '' || this.dunHeight === '') {
        this.errorMessage.push("Please fill 'Dunnage Dims'!");
      }
      if (isNaN(this.dunLength)) {
        this.errorMessage.push("Length[Dunnage] must be a Number!");
      } 
      if (isNaN(this.dunWidth)) {
        this.errorMessage.push("Width[Dumnnage] must be a Number!");
      } 
      if (isNaN(this.dunHeight)) {
        this.errorMessage.push("Height[Dumnnage] must be a Number!");
      }
      if (this.dunContainerKg === '') {
        this.errorMessage.push("Please fill 'Dunnage Weight'!");
      } else if (isNaN(this.dunContainerKg)) {
        this.errorMessage.push("Weight[Dumnnage] must be a Number!");
      }
    }
    else {
      if (this.dunCost || this.dunLength !== '' || this.dunLength !== '' || this.dunHeight !== '' || this.dunContainerKg) {
        this.errorMessage.push("Please fill 'Dunnage Code'.(Or remove Dunnage Information) !");
      }
       if (isNaN(this.dunLength)) {
        this.errorMessage.push("Length[Dumnnage] must be a Number!");
      } 
      if (isNaN(this.dunWidth)) {
        this.errorMessage.push("Width[Dumnnage] must be a Number!");
      } 
      if (isNaN(this.dunHeight)) {
        this.errorMessage.push("Height[Dumnnage] must be a Number!");
      }
      if (isNaN(this.dunContainerKg)) {
        this.errorMessage.push("Weight[Dumnnage] must be a Number!");
      }
      if (isNaN(this.dunCost)) {
        this.errorMessage.push(this.costPrice);
      }
    }
    if (this.topCode) {
      if (this.topCost === '') {
        this.errorMessage.push("Please fill 'Top Cap Estimated Cost/Pkg Piece'!");
      } else if (isNaN(this.topCost)) {
        this.errorMessage.push("Cost/Price[Top Cap] must be a Number!");
      } 
      if (this.topLength === '' || this.topWidth === '' || this.topHeight === '') {
        this.errorMessage.push("Please fill 'Top Cap Dims'!");
      } 
      if (isNaN(this.topLength)) {
        this.errorMessage.push("Length[Top Cap] must be a Number!");
      } 
      if (isNaN(this.topWidth)) {
        this.errorMessage.push("Width[Top Cap] must be a Number!");
      } 
      if (isNaN(this.topHeight)) {
        this.errorMessage.push("Height[Top Cap] must be a Number!");
      }

      if (this.topContainerKg === '') {
        this.errorMessage.push("Please fill 'Top Cap Weight'!");
      } else if (isNaN(this.topContainerKg)) {
        this.errorMessage.push("Weight[Top Cap] must be a Number!");
      }
    }
    else {
      if (this.topCost || this.topLength !== '' || this.topWidth !== '' || this.topHeight !== '' || this.topContainerKg) {
        this.errorMessage.push("Please fill 'Top Cap Code'.(Or remove Top Cap Information) !");
      }
      if (isNaN(this.topLength)) {
        this.errorMessage.push("Length[Top Cap] must be a Number!");
      } 
      if (isNaN(this.topWidth)) {
        this.errorMessage.push("Width[Top Cap] must be a Number!");
      } 
      if (isNaN(this.topHeight)) {
        this.errorMessage.push("Height[Top Cap] must be a Number!");
      }
      if (isNaN(this.topContainerKg)) {
        this.errorMessage.push("Weight[Top Cap] must be a Number!");
      } 
      if (isNaN(this.topCost)) {
        this.errorMessage.push("Cost/Price[Top Cap] must be a Number!");
      }

    }
    if (this.palletCode) {
      if (this.palletCost === '') {
        this.errorMessage.push("Please fill 'Pallet Estimated Cost/Pkg Piece'!");
      } else if (isNaN(this.palletCost)) {
        this.errorMessage.push("Cost/Price[Pallet] must be a Number!");
      } 
      if (this.palletLength === '' || this.palletWidth === '' || this.palletHeight === '') {
        this.errorMessage.push("Please fill 'Pallet Dims'!");
      }
      if (isNaN(this.palletLength)) {
        this.errorMessage.push("Length[Pallet] must be a Number!");
      } 
      if (isNaN(this.palletWidth)) {
        this.errorMessage.push("Width[Pallet] must be a Number!");
      } 
      if (isNaN(this.palletHeight)) {
        this.errorMessage.push("Height[Pallet] must be a Number!");
      }
      if (this.palletContainerKg === '') {
        this.errorMessage.push("Please fill 'Pallet Weight'!");
      } else if (isNaN(this.palletContainerKg)) {
        this.errorMessage.push("Weight[Pallet] must be a Number!");
      }
    }
    else {
      if (this.palletCost || this.palletContainerKg || this.palletHeight !== '' || this.palletWidth !== '' || this.palletLength !== '') {
        this.errorMessage.push("Please fill 'Pallet Code'.(Or remove Pallet Information) !");
      }
      if (isNaN(this.palletLength)) {
        this.errorMessage.push("Length[Pallet] must be a Number!");
      } 
      if (isNaN(this.palletWidth)) {
        this.errorMessage.push("Width[Pallet] must be a Number!");
      } 
      if (isNaN(this.palletHeight)) {
        this.errorMessage.push("Height[Pallet] must be a Number!");
      }
      if (isNaN(this.palletCost)) {
        this.errorMessage.push("Cost/Price[Pallet] must be a Number!");
      }
      if (isNaN(this.palletContainerKg)) {
        this.errorMessage.push("Weight[Pallet] must be a Number!");
      }
    }
    if ((!this.imageSrc || this.imageSrc === 'No image') && type === 'submit') {
      this.errorMessage.push("please 'Attach Picture Of Package'!");
    } else {
      if (this.templateFile instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
        reader.readAsDataURL(this.templateFile)

        reader.onloadend = () => {
          const updateObject = {
            hidPlantCode: this.defineData.plantCode,
            hidPartNumber: this.defineData.partNumber,
            hidSupplierCode: this.defineData.supplierCode,
            hidPkgStartDate: this.defineData.pkgStartDate,
            fileName: this.templateFile.name,
            base64Data: this.imageSrc,
          }
          this.definebackUpService.imageUpload(updateObject).subscribe((_res: any) => {
          })
        }
      }
    }
    if (this.errorMessage.length > 0) {
      alert(this.errorMessage.join('\n'));
    } else {
      let appStatus;
      if (type === 'save') {
        appStatus = -1;
      } else if (type === 'submit') {
        appStatus = 0;
      }
      const updateObject = {
        hidPlantCode: this.defineData.plantCode,
        hidPartNumber: this.defineData.partNumber,
        hidSupplierCode: this.defineData.supplierCode,
        hidPkgStartDate: this.defineData.pkgStartDate,
        qpc: this.qpc,
        contCode: this.code,
        contL: this.length,
        contH: this.height,
        contW: this.width,
        contWeight: this.containerkg,
        contPrice: this.cost,
        dunCode: this.dunCode,
        dunL: this.dunLength,
        dunH: this.dunHeight,
        dunW: this.dunWidth,
        dunWeight: this.dunContainerKg,
        dunPrice: this.dunCost,
        lidCode: this.topCode,
        lidL: this.topLength,
        lidW: this.topWidth,
        lidH: this.topHeight,
        lidWeight: this.topContainerKg,
        lidPrice: this.topCost,
        pltCode: this.palletCode,
        pltL: this.palletLength,
        pltH: this.palletHeight,
        pltW: this.palletWidth,
        pltWeight: this.palletContainerKg,
        pltPrice: this.palletCost,
        // approvalStatus: this.defineRes?.status || this.defineStatus?.status === "Approved" ? "1" : "0"
        approvalStatus: appStatus
      }
      this.definebackUpService.submitObject(updateObject).subscribe((_res: any) => {
        this.router.navigate(['/tracking']);
      })

    }

  }
  goBack() {
    this.router.navigate(['/tracking']);
  }
}
