<div class="popup-container">
  <div class="d-flex header justify-content-between align-items-center px-3">
    <p class="page-title mt-3">Update Supplier Specialist</p>
    <div>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
  <div class="popup-contents m-2 py-2 px-2">
    <div class="alert alert-danger my-2" *ngIf="errorList.length > 0">
      <div *ngFor="let message of errorList" class="errormsg">
        <span class="message-text">{{ message }} </span>
      </div>
    </div>
    <div>
      <table aria-describedby="add-package">
        <tr class="d-none">
          <th>update-supplier</th>
        </tr>
        <tr>
          <td class="cell1">Plant</td>
          <td class="cell3" colspan="2">{{ plantName }}</td>
        </tr>
        <tr>
          <td class="cell1">Supplier</td>
          <td class="cell3" colspan="2">{{ supplierShortName }}</td>
        </tr>
        <tr>
          <td class="cell1">User Plant</td>
          <td class="cell3" colspan="2">
            <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="userPlant"
              (input)="filterOptions()" (ngModelChange)="getPlantSpecialistDropdown()" placeholder="--Select Plant--"
              [matAutocomplete]="auto" style="width: 80%">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
              <mat-option >--Select Plant--</mat-option>
              <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
              </mat-option>
            </mat-autocomplete>

          </td>
        </tr>
        <tr>
          <td class="cell1" rowspan="2">Plant Specialist</td>
          <td class="cell2">Ope</td>
          <td class="cell3" colspan="2">
            <mat-select class="mat-sel pt-2" [(ngModel)]="selectedOpe">
              <mat-option value="">Select Operator</mat-option>
              <mat-option *ngFor="let ope of opeList" [value]="ope.specialistcode">{{ ope.specialist }}</mat-option>
            </mat-select>
          </td>
        </tr>
        <tr>
          <td class="cell2">Dev</td>
          <td class="cell3" colspan="2">
            <mat-select class="mat-sel pt-2" [(ngModel)]="selectedDev">
              <mat-option value="">Select Developer</mat-option>
              <mat-option *ngFor="let dev of devList" [value]="dev.specialistcode">{{ dev.specialist }}</mat-option>
            </mat-select>
          </td>
        </tr>
        <tr>
          <td class="cell1">Supplier Contact</td>
          <td class="cell3" colspan="2">
            <mat-select class="mat-sel pt-2" [(ngModel)]="selectedSupplierContact">
              <mat-option value="">Select Contact</mat-option>
              <mat-option *ngFor="let contact of supplierContactList" [value]="contact">{{ contact }}</mat-option>
            </mat-select>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="
      footer
      d-flex
      justify-content-end
      align-items-center
      fixed-bottom
      mt-2
      pt-2
      pb-2
    ">
    <button class="cancel me-3 roboto-bold" (click)="closeDialog()">
      CANCEL
    </button>
    <button class="submit black-button me-3 roboto-bold" (click)="updateSupplierPlant()">
      UPDATE
    </button>
  </div>
</div>