<div class="popup-container">
  <header>
    <button class="close black-button" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    <div class="header d-block roboto-medium">
        <label class="ms-2 mt-2 mb-3">Select Specialist </label>
    </div>
  </header>
  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <span *ngFor="let message of errorList">{{message}}</span>
  </div>
  <div class="container">
    <div class="row pt-4">
      <div class="col d-flex ps-0">
        <label for="part" class="col-1 mx-3">Plant</label>
        <input type="text" id="supplier" class="pt-2" aria-label="text" matInput [(ngModel)]="plantCode" (ngModelChange)="callSpecialist(1)"
        placeholder="--Select Plant--" [matAutocomplete]="auto" (input)="filterOptions()">
     <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
       <mat-option *ngFor="let x of plantFilteredOptions" [value]="x?.plantcode">{{ x?.plant }}</mat-option>
     </mat-autocomplete>
      </div>

      <div class="col d-flex ps-0">
        <mat-label class="col-3 align-self-center">Specialist</mat-label>
        <input type="text"
              placeholder="Pick one"
              aria-label="Number"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto1">
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.fullName">
            {{option.fullName}}
          </mat-option>
        </mat-autocomplete>
      </div>
      
      
    </div>

    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-1 pt-2 pb-2">
      <button class="cancel me-3 roboto-bold" (click)="close()"> CANCEL </button>
      <button class="submit black-button me-3 roboto-bold" (click)="update()">OK</button>
    </div>
  </div>

</div>

