import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  @Input() parentdata: any;
  @Input() displaytrQuestionnaire = false;
  @Input() trSupplierConfirmDisplay = false;
  @Input() displaytrQuestionnaireComment = false;
  @Input() tblRequestReasonDisplay = false;
  @Input() tblTrialDisplay = false;
  @Input() screenId: any
  @Input() txtParentPart: any

  rdoYes11Checked = false
  rdoNo11Checked = false
  rdoYes12Checked = false
  rdoNo12Checked = false
  rdoYes25Checked = false
  rdoNo25Checked = false
  rdoYes26Checked = false
  rdoNo26Checked = false
  rdoYes27Checked = false
  rdoNo27Checked = false
  txtNote11Value: any
  txtNote12Value: any
  txtNote24Value: any
  txtPart26Value: any
  txtModel26Value: any
  sel22Items: any[] = []
  sel22SelectedIndex: any
  sel23Items: any[] = []
  sel23SelectedIndex: any
  sel24Items: any[] = []
  sel24SelectedIndex: any
  rdoYesTestChecked = false
  rdoNoTestChecked = false
  cblRequested = false
  taRequestChanges: any
  taRequestChangesDisabled = false
  cblReason = false
  taReasonRequested: any
  taReasonRequestedDisabled = false
  trial = ['Trial', 'Sibling Part', 'Reason']
  rdoTrialYesChecked = false
  rdoTrialYesDisabled = false
  rdoTrialNoChecked = false
  rdoTrialNoDisabled = false
  rdoAsFinalChecked = false
  rdoAsFinalDisabled = false
  txtParentPartDisabled = true
  btnParentPartDisabled = true
  taTriFinalReason: any
  taTriFinalReasonDisabled = true

  reqChangesChkList = [
    { code: 1, description: 'Container Type', checked: false },
    { code: 2, description: 'Qty. per Container', checked: false },
    { code: 3, description: 'Container Size', checked: false },
    { code: 4, description: 'Dunnage', checked: false },
    { code: 5, description: 'Part Orientation', checked: false },
    { code: 6, description: 'other', checked: false },
  ]

  reasonReqArr = [
    { code: 1, description: 'Safety', checked: false },
    { code: 2, description: 'Quality', checked: false },
    { code: 3, description: 'Workability', checked: false },
    { code: 4, description: 'Over Weight', checked: false },
    { code: 5, description: 'Poor Efficiency', checked: false },
    { code: 6, description: 'Non Standard', checked: false },
    { code: 7, description: 'Other', checked: false },
  ]
  stepStatus: any;
  stepStatusArray: any[] = []
  crrStep: any;
  q13date: any;
  q14date: any;
  q21date: any;
  taNoteValue: any;

  constructor(
    private readonly router: Router, public trackingListService: TrackingListService
  ) { }

  ngOnInit(): void {
    this.crrStep = this.parentdata?.getCurrentStepApprove
    const data = sessionStorage.getItem('quesRad')
    sessionStorage.removeItem('quesRad')
    if (data !== null) {
      const retData = JSON.parse(data)
      if (retData) {
        this.rdoTrialYesChecked = retData.yes;
        this.rdoTrialNoChecked = retData.no;
        this.rdoAsFinalChecked = retData.final
      }
    }

    if (this.crrStep === 4 || this.crrStep === 7 || this.crrStep === 9) {
      this.taNoteValue = this.parentdata?.t_Note_GetComment ??
        (this.parentdata?.createData_supQuestionnaire?.commentData !== '' ? this.parentdata?.createData_supQuestionnaire?.commentData :
          this.parentdata?.createData_supQuestionnaire?.questionnaire[0]?.note)
    }
    this.createHtml_tblSupplierQuestionnaire(this.parentdata);
    if (this.tblTrialDisplay) {
      this.createHtml_tblTrial()
    }
    if (this.tblRequestReasonDisplay) {
      this.createHtml_RequestedComment(this.parentdata)
    }
  }


  createHtml_tblSupplierQuestionnaire(data: any) {
    const questionnaire = data?.createQuestionnaire
      ? data?.createQuestionnaire[0]
      : (data?.createData_supQuestionnaire
        ? data?.createData_supQuestionnaire?.questionnaire[0] : '')

    if (this.nullStr(questionnaire) !== '') {
      this.createQuestionnaire(questionnaire)
      this.createQuestionnaire2(questionnaire)
      this.createQuestionnaire3(questionnaire)
      this.txtNote11Value = questionnaire?.q1_1_note
      this.txtNote12Value = questionnaire?.q1_2_note
      this.txtNote24Value = questionnaire?.q2_4_note
      this.txtPart26Value = questionnaire?.q2_6_part
      this.txtModel26Value = questionnaire?.q2_6_model
      this.createQuestionnaire4(questionnaire)

      const date = 'MM/dd/yyyy';
      if (questionnaire?.q1_3_date !== null && questionnaire?.q1_3_date !== undefined) {
        this.q13date = formatDate(questionnaire?.q1_3_date, date, 'en-US', 'UTC')
      }
      if (questionnaire?.q1_4_date !== null && questionnaire?.q1_4_date !== undefined) {
        this.q14date = formatDate(questionnaire?.q1_4_date, date, 'en-US', 'UTC')
      }
      if (questionnaire?.q2_1_date !== null && questionnaire?.q2_1_date !== undefined) {
        this.q21date = formatDate(questionnaire?.q2_1_date, date, 'en-US', 'UTC')
      }

      if (data?.getCurrentStepApprove === 7) {
        if (this.nullStr(questionnaire['step6confirm']) === '1') {
          this.rdoYesTestChecked = true
        } else if (this.nullStr(questionnaire['step6confirm']) === '0') {
          this.rdoNoTestChecked = true
        }
      } else if (data?.getCurrentStepApprove === 9) {
        if (this.nullStr(questionnaire['step8confirm']) === '1') {
          this.rdoYesTestChecked = true
        } else if (this.nullStr(questionnaire['step8confirm']) === '0') {
          this.rdoNoTestChecked = true
        }
      }
    }
  }

  createQuestionnaire(questionnaire: any) {
    if (questionnaire?.q1_1_yn === 'Y') {
      this.rdoYes11Checked = true
    } else if (questionnaire?.q1_1_yn === 'N') {
      this.rdoNo11Checked = true
    }

    if (questionnaire?.q1_2_yn === '1') {
      this.rdoYes12Checked = true
    } else if (questionnaire?.q1_2_yn === '0') {
      this.rdoNo12Checked = true
    }

    if (questionnaire?.q2_5_yn === 'Y') {
      this.rdoYes25Checked = true
    } else if (questionnaire?.q2_5_yn === 'N') {
      this.rdoNo25Checked = true
    }

    if (questionnaire?.q2_6_yn === 'Y') {
      this.rdoYes26Checked = true
    } else if (questionnaire?.q2_6_yn === 'N') {
      this.rdoNo26Checked = true
    }

    if (questionnaire?.q2_7_yn === '1') {
      this.rdoYes27Checked = true
    } else if (questionnaire?.q2_7_yn === '0') {
      this.rdoNo27Checked = true
    }
  }

  createQuestionnaire2(questionnaire: any) {
    if (questionnaire?.q2_2_select === '1') {
      const options = {
        index: '1',
        value: 'Drawing / Data',
      }
      this.sel22Items.push(options)
      this.sel22SelectedIndex = '1'
    } else if (questionnaire?.q2_2_select === '2') {
      const options = {
        index: '1',
        value: 'Sample Part',
      }
      this.sel22Items.push(options)
      this.sel22SelectedIndex = '1'
    } else if (questionnaire?.q2_2_select === '3') {
      const options = {
        index: '1',
        value: 'Other',
      }
      this.sel22Items.push(options)
      this.sel22SelectedIndex = '1'
    } else {
      this.sel22SelectedIndex = '0'
    }
  }

  createQuestionnaire3(questionnaire: any) {
    if (questionnaire?.q2_3_select === '1') {
      const options = {
        index: '1',
        value: 'Accept - Exact Pkg',
      }
      this.sel23Items.push(options)
      this.sel23SelectedIndex = '1'
    } else if (questionnaire?.q2_3_select === '2') {
      const options = {
        index: '1',
        value: 'Accept - Modify Pkg',
      }
      this.sel23Items.push(options)
      this.sel23SelectedIndex = '1'
    } else if (questionnaire?.q2_3_select === '3') {
      const options = {
        index: '1',
        value: 'New Proposal',
      }
      this.sel23Items.push(options)
      this.sel23SelectedIndex = '1'
    } else {
      this.sel23SelectedIndex = '0'
    }
  }

  createQuestionnaire4(questionnaire: any) {
    if (questionnaire?.q2_4_select === '1') {
      const options = {
        index: '1',
        value: 'Part Shape',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else if (questionnaire?.q2_4_select === '2') {
      const options = {
        index: '1',
        value: 'Part Size',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else if (questionnaire?.q2_4_select === '3') {
      const options = {
        index: '1',
        value: 'Part Weight',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else if (questionnaire?.q2_4_select === '4') {
      const options = {
        index: '1',
        value: 'Material Change',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else if (questionnaire?.q2_4_select === '5') {
      const options = {
        index: '1',
        value: 'New Part Introduction',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else if (questionnaire?.q2_4_select === '6') {
      const options = {
        index: '1',
        value: 'Other',
      }
      this.sel24Items.push(options)
      this.sel24SelectedIndex = '1'
    } else {
      this.sel24SelectedIndex = '0'
    }
  }





  createHtml_RequestedComment(data: any) {
    const dv: any = data?.createHtml_RequestedComment
    if (dv === null || dv === '') {
      return
    }
    const alchk = dv?.al_chk;
    this.crrStep = data?.getCurrentStepApprove
    this.stepStatus = data?.stepStatus
    this.stepStatusArray = Object.entries(this.stepStatus[0])

    for (let i = 0; i < alchk?.length; i++) {
      if (this.nullInt(alchk[i]) === 1) {
        this.reqChangesChkList[0].checked = true
      } else if (this.nullInt(alchk[i]) === 2) {
        this.reqChangesChkList[1].checked = true
      } else if (this.nullInt(alchk[i]) === 3) {
        this.reqChangesChkList[2].checked = true
      } else if (this.nullInt(alchk[i]) === 4) {
        this.reqChangesChkList[3].checked = true
      } else if (this.nullInt(alchk[i]) === 5) {
        this.reqChangesChkList[4].checked = true
      } else if (this.nullInt(alchk[i]) === 6) {
        this.reqChangesChkList[5].checked = true
      } else if (this.nullInt(alchk[i]) === 7) {
        this.reasonReqArr[0].checked = true
      } else if (this.nullInt(alchk[i]) === 8) {
        this.reasonReqArr[1].checked = true
      } else if (this.nullInt(alchk[i]) === 9) {
        this.reasonReqArr[2].checked = true
      } else if (this.nullInt(alchk[i]) === 10) {
        this.reasonReqArr[3].checked = true
      } else if (this.nullInt(alchk[i]) === 11) {
        this.reasonReqArr[4].checked = true
      } else if (this.nullInt(alchk[i]) === 12) {
        this.reasonReqArr[5].checked = true
      } else if (this.nullInt(alchk[i]) === 13) {
        this.reasonReqArr[6].checked = true
      }
    }

    this.taRequestChanges = this.nullStr(dv?.commentRequested)
    this.taReasonRequested = this.nullStr(dv?.commentReason)

    if (this.stepStatusArray[this.crrStep - 1][1] !== '1' || data?.leaderFlag === 1) {
      this.cblRequested = true
      this.cblReason = true

      this.taRequestChangesDisabled = true
      this.taReasonRequestedDisabled = true
    }
  }

  nullInt(obj: any) {
    if (obj === null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  private nullStr(value: any): string {

    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }

  onRadioChange(event: any) {
    if (event.value === 0) {
      this.rdoTrialNoChecked = true
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 1) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = true
      this.rdoAsFinalChecked = false
      this.createHtml_tblTrial()
    } else if (event.value === 2) {
      this.rdoTrialNoChecked = false
      this.rdoTrialYesChecked = false
      this.rdoAsFinalChecked = true
      this.createHtml_tblTrial()
    }
    const data = {
      yes: this.rdoTrialYesChecked,
      no: this.rdoTrialNoChecked,
      final: this.rdoAsFinalChecked
    }
    sessionStorage.setItem('quesRad', JSON.stringify(data))
  }

  goToSelectPart() {
    this.data();
    this.router.navigate(['/tracking-list/sI-final-dept-approval/select']);
  }

  data() {
    const parentdata = {
      suppliercode: this.parentdata.SupplierCode,
      partnumber: this.parentdata.dv_tblInfo[0]?.partnumber,
      fromdate: this.parentdata.PkgStartDate,
      plantcode: this.parentdata.PlantCode,
      plantss: this.parentdata?.dv_tblInfo[0].plantname,
      rn: '',
      isTracking: 'true',
      url: '/tracking-list/send-ppf'
    }
    sessionStorage.setItem('parentData', JSON.stringify(parentdata));
  }

  onChange(event: any, value: any) {
    this.reqChangesChkList[value].checked = event.target.checked
  }

  onReasonChange(event: any, value: any) {
    this.reasonReqArr[value].checked = event.target.checked
  }

  createHtml_tblTrial() {
    if (this.rdoTrialNoChecked === true) {
      this.txtParentPartDisabled = false
      this.btnParentPartDisabled = false
      this.taTriFinalReasonDisabled = true
    } else if (this.rdoTrialYesChecked === true) {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = true
    } else if (this.rdoAsFinalChecked) {
      this.txtParentPartDisabled = true
      this.btnParentPartDisabled = true
      this.taTriFinalReasonDisabled = false
    } 
    if (this.screenId === 19) {
      const dv = this.parentdata?.createHtml_tblTrial;
      if (dv == null) {
        return;
      }

      if (this.nullInt(dv[0]?.trialflag) === 1) {
        this.rdoTrialYesChecked = true;
      }
      else if (this.nullInt(dv[0]?.trialflag) === 2) {
        this.rdoAsFinalChecked = true;
        this.taTriFinalReason = this.nullStr(dv[0]?.trialfinalreason);
      }
      else if (this.nullInt(dv[0]?.trialflag) === 0) {
        this.rdoTrialNoChecked = true;
        this.txtParentPart = this.nullStr(dv[0]?.parentpartno);
      }
      this.crrStep = this.parentdata?.getCurrentStepApprove
      this.stepStatus = this.parentdata?.stepStatus
      this.stepStatusArray = Object.entries(this.stepStatus[0])
      if (this.stepStatusArray[this.crrStep - 1][1] === '1') {
        this.rdoTrialYesChecked = true;
        this.rdoTrialNoChecked = false;
      }
      if (this.stepStatusArray[this.crrStep - 1][1] === '13' || this.parentdata.leaderFlag === 1) {
        this.rdoTrialYesDisabled = true;
        this.rdoTrialNoDisabled = true;
        this.rdoAsFinalDisabled = true;
        this.txtParentPartDisabled = true;
        this.taTriFinalReasonDisabled = true;
        this.btnParentPartDisabled = true;
      }
    }


  }



}
