<div class="popup-container" *ngIf="!deleteFlag" cdkDrag [cdkDragBoundary]="dragBoundary">
  <div class="header text-middle justify-content-between align-items-center" cdkDragHandle>
    <div class="page-title">
      <label>{{ data.mode }} Model</label>
    </div>
    <div>
      <button class="help-btn" (click)="help()">help</button>
    </div>
  </div>
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
      </div>
    </div>
  </div>

  <div class="popup-contents" [formGroup]="modelForm">
    <div class="row m-3 align-items-start">
      <div class="d-flex flex-wrap col-4 modelLabel">
        <label for="plant" class="col-5">Plant</label>
        <input *ngIf="familycodeFlag" type="text" id="plant" formControlName="plant" [placeholder]="'--Select Plant--'"
          aria-label="text" matInput [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
          <mat-option *ngFor="let plant of filteredOptions | async" [value]="plant.plantcode">{{plant.plant}}
          </mat-option>
        </mat-autocomplete>
        <input *ngIf="familyFlag" type="text" formControlName="plant" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('plant')?.invalid &&
            (modelForm.get('plant')?.dirty || modelForm.get('plant')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('plant')?.hasError('required')">
            Plant is required
          </small>
        </div>
      </div>

      <div *ngIf="familycodeFlag" class="d-flex flex-wrap col-4 modelLabel">
        <label id="name" class="col-5 align-self-center">Family Code</label>
        <input type="text" formControlName="familycode" class="mat-input me-1" [placeholder]="'--New--'"
          aria-label="Number" matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option value="">-- New --</mat-option>
          <mat-option *ngFor="let fCode of familycodes | async" [value]="fCode">{{ fCode }}
          </mat-option>
        </mat-autocomplete>
        <input type="text" formControlName="familycodetext" class="fc" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('familycodetext')?.invalid &&
            (modelForm.get('familycodetext')?.dirty ||
              modelForm.get('familycodetext')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('familycodetext')?.hasError('required')">
            Family Code is required
          </small>
        </div>
      </div>

      <div *ngIf="familyFlag" class="d-flex flex-wrap col-4 modelLabel">
        <label id="familyUpdate" class="col-5">Family Code</label>
        <input type="text" formControlName="familycode" readonly />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('familycode')?.invalid &&
            (modelForm.get('familycode')?.dirty ||
              modelForm.get('familycode')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('familycode')?.hasError('required')">
            Family Code is required
          </small>
        </div>
      </div>
      <div class="d-flex col-4">
        <label id="prevfamily" class="col-5">Previous Family</label>
        <input type="text" formControlName="prevfamily" />
      </div>
    </div>

    <div class="row m-3 align-items-start">
      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="modelname" class="col-5">Model Name</label>
        <input type="text" formControlName="modelname" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('modelname')?.invalid &&
            (modelForm.get('modelname')?.dirty ||
              modelForm.get('modelname')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('modelname')?.hasError('required')">
            Model Name is required
          </small>
        </div>
      </div>

      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="modelcode" class="col-5">Model Code</label>
        <input *ngIf="familycodeFlag" type="text" formControlName="modelcode" />
        <input *ngIf="familyFlag" type="text" formControlName="modelcode" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('modelcode')?.invalid &&
            (modelForm.get('modelcode')?.dirty ||
              modelForm.get('modelcode')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('modelcode')?.hasError('required')">
            Model Code is required
          </small>
        </div>
      </div>
      <div class="d-flex col-4">
        <label id="modelyear" class="col-5 no-content">Model Year</label>
        <input type="text" formControlName="modelyear" maxlength="4" pattern="\d{4}" />
      </div>
    </div>

    <div class="row m-3 align-items-start">
      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="preppfdue" class="col-5">Pre PPF Due</label>
        <input type="date" id="preppfdue" formControlName="preppfdue" (change)="ValidatePkg()"
          (keydown)="onKeyDown($event,'preppfdue')" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('preppfdue')?.invalid &&
            (modelForm.get('preppfdue')?.dirty ||
              modelForm.get('preppfdue')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('preppfdue')?.hasError('required')">
            Pre PPF Due is required
          </small>
          <small class="error col-8" *ngIf="modelForm.get('preppfdue')?.hasError('Error')">
            {{modelForm.controls.preppfdue.errors.Error}}
          </small>
        </div>
      </div>

      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="pkgtrialdue" class="col-5">Pkg Trial Due</label>
        <input type="date" id="pkgtrialdue" formControlName="pkgtrialdue" (change)="ValidatePkg()"
          (keydown)="onKeyDown($event,'pkgtrialdue')" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('pkgtrialdue')?.invalid &&
            (modelForm.get('pkgtrialdue')?.dirty ||
              modelForm.get('pkgtrialdue')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('pkgtrialdue')?.hasError('required')">
            Pkg Trial Due is required
          </small>
          <small class="error col-8" *ngIf="modelForm.get('pkgtrialdue')?.hasError('Error')">
            {{modelForm.controls.pkgtrialdue.errors.Error}}
          </small>
        </div>
      </div>

      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="pkgauthdue" class="col-5">Pkg Authorized Due</label>
        <input type="date" id="pkgauthdue" formControlName="pkgauthdue" (change)="ValidatePkg()"
          (keydown)="onKeyDown($event,'pkgauthdue')" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('pkgauthdue')?.invalid &&
            (modelForm.get('pkgauthdue')?.dirty ||
              modelForm.get('pkgauthdue')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('pkgauthdue')?.hasError('required')">
            Pkg Authorized Due is required
          </small>
          <small class="error col-8" *ngIf="modelForm.get('pkgauthdue')?.hasError('Error')">
            {{modelForm.controls.pkgauthdue.errors.Error}}
          </small>
        </div>
      </div>
    </div>

    <div class="row m-3 align-items-start">
      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="readinessdue" class="col-5">Readiness Due</label>
        <input type="date" id="readinessdue" formControlName="readinessdue" (change)="ValidatePkg()"
          (keydown)="onKeyDown($event,'readinessdue')" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('readinessdue')?.invalid &&
            (modelForm.get('readinessdue')?.dirty ||
              modelForm.get('readinessdue')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('readinessdue')?.hasError('required')">
            Readiness Due is required
          </small>
          <small class="error col-8" *ngIf="modelForm.get('readinessdue').hasError('Error')">
            {{modelForm.controls.readinessdue.errors.Error}}
          </small>
        </div>
      </div>

      <div class="d-flex flex-wrap col-4 modelLabel">
        <label id="sop" class="col-5">SOP</label>
        <input type="date" formControlName="sop" (change)="ValidatePkg()" id="sop"
          (keydown)="onKeyDown($event,'sop')" />
        <div class="col-12 d-flex" *ngIf="
            modelForm.get('sop')?.invalid &&
            (modelForm.get('sop')?.dirty || modelForm.get('sop')?.touched)
          ">
          <label class="col-5 invisible"></label>
          <small class="error col-8" *ngIf="modelForm.get('sop')?.hasError('required')">
            SOP is required
          </small>
          <small class="error col-8" *ngIf="modelForm.get('sop').hasError('Error')">
            {{modelForm.controls.sop.errors.Error}}
          </small>
        </div>
      </div>

      <div class="d-flex flex-wrap col-4">
        <label id="eop" class="col-5">EOP</label>
        <input type="date" id="eop" formControlName="eop" (change)="ValidatePkg()"
          (keydown)="onKeyDown($event,'eop')" />
        <div class="col-12 d-flex" *ngIf="
        modelForm.get('eop')?.invalid">
          <label class="col-5 invisible"></label>
        </div>
      </div>
    </div>

    <div class="row m-3 align-items-start">
      <div class="d-flex col-4">
        <label id="prevmodel" class="col-5">Previous Model</label>
        <input type="text" formControlName="prevmodel" />
      </div>
      <div class="d-flex col-4">
        <label id="production" class="col-5">Production Volume/Day</label>
        <input type="text" formControlName="production" />
      </div>
    </div>
  </div>

  <div class="
      footer
      d-flex
      justify-content-end
      align-items-center
      fixed-bottom
      mt-2
      pt-2
      pb-2
    ">
    <button class="cancel me-3 roboto-bold" (click)="close()">CANCEL</button>
    <button class="submit black-button me-3 roboto-bold" (click)="submitForm()">
      {{ data.mode }}
    </button>
  </div>
</div>

<div class="popup-container2" *ngIf="deleteFlag" style="width: 500px">
  <h2 mat-dialog-title class="header text-middle justify-content-between align-items-center">
    Delete confirmation
  </h2>
  <div class="alert alert-warning mx-2" *ngIf="errorFlag">
    {{ errorMessage }}
  </div>
  <div mat-dialog-content>
    <p class="p-2">
      Are you sure that you want to delete [{{ this.data.result.plantcode }} -
      {{ this.data.result.modelcode }}] ?
    </p>
  </div>
  <div mat-dialog-actions style="justify-content:flex-end">
    <div mat-dialog-close class="text-primary mx-3">No</div>
    <button class="black-button mx-3" (click)="deleteBtn()">Yes</button>
  </div>
</div>