<div class="d-flex justify-content-between align-items-center m-3">
  <div class="page-title">ADJUST PACKAGE QUANTITY</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="mx-3 p-2 header">ADJUST PACKAGE QUANTITY</div>
<div class="alert alert-danger m-3" *ngIf="errorList.length>0">
  <ng-container *ngFor="let error of errorList ">
    <div>{{error}}</div>
  </ng-container>
</div>
<div class="d">
  <div class="top-arr d-flex flex-wrap m-3">
    <div class="top-arr-left col-md-4 col-12 d-flex align">
      <div class="left d-flex flex-column col-5 header">
        <span>Plant</span>
        <span>Part #</span>
        <span>Supplier</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>{{response?.PlantName[0]?.name}}</span>
        <span>{{response?.PartNumber}}</span>
        <span>{{response?.SupplierName[0]?.shortname}}</span>
      </div>
    </div>
    <div class="right d-flex flex-column col-7 alnLeft">
      <div class="main-right">
        <table class="tg w-100" aria-describedby="table1">
          <thead>
            <tr>
              <th class="tg-mg6y"></th>
              <th class="tg-mg6y">Package Code</th>
              <th class="tg-mg6y">Current Qty</th>
              <th class="tg-mg6y">New Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tg-mg6y">Package</td>
              <td class="tg-mg6y">{{response?.PackageCode}}</td>
              <td class="tg-mg6y"><input type="text" [(ngModel)]="currentQty" [disabled]="true"/></td>
              <td class="tg-mg6y"><input type="text" [(ngModel)]="toQty"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="main-content d-flex m-3">
    <div class="main-left col-md-4 col-12 d-flex">
      <div class="left d-flex flex-column col-5 header">
        <span>Reason</span>
      </div>
      <div class="right d-flex flex-column col-7">
        <span>
          <mat-select class="mat-sel pt-2" disableOptionCentering [(ngModel)]="selectedReason">
            <mat-option value="select">Select</mat-option>
            <mat-option *ngFor="let x of reasonList" [value]="x.id">{{
              x.reason
              }}</mat-option>
          </mat-select>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="
    create_footer
    d-flex
    justify-content-end
    align-items-center
    fixed-bottom
  ">
  <div (click)="onBack()" class="mx-3 text-primary link">Back</div>
  <button class="black-button me-3 roboto-medium" (click)="onSubmit()">
    OK
  </button>
</div>