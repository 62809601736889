import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class NewshipmentService {

  private authData: any[] = [];

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  loadDetailShipment(shipmentNumber:any): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `shipmentlist/Detail?ShipmentNumber=${shipmentNumber}`,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  loadDNewShipmentData(body: any): Observable<any> {
    return this.http.post(this.constantsService.EXTERNAL + 'NewShipment/onLoad', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  addAuthData(SUPPLIERCODE: any): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `NewShipment/addAuth/onLoad?SupplierCode=${SUPPLIERCODE}`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  addAuthDataDetail(SUPPLIERCODE: any): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `Shipmentlist/Detail/addAuth/onLoad?SupplierCode=${SUPPLIERCODE}`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  setAuthData(authData: any[]) {
    this.authData = authData;
  }

  getAuthDataByNumber(authNumber: string) {
    return this.authData?.filter(authData => authData.AUTHNUMBER === authNumber);
  }

  addButtonData(authNumber: string): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `newShipment/add?authNumber=${authNumber}&shipmentNumber=`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  addDetailButtonData(authNumber: string): Observable<any>{
    return this.http.get(this.constantsService.EXTERNAL + `Shipmentlist/Detail/add?authNumber=${authNumber}&shipmentNumber=`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }


  RButtonData(authNumber: string, shipmentNumber: string): Observable<any> {
    const params = new HttpParams().set('authNumber', authNumber).set("shipmentNumber", shipmentNumber);
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/Rbutton/onload', { params: params, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RButtonDataDetail(authNumber: string, shipmentNumber: string): Observable<any> {
    const params = new HttpParams().set('authNumber', authNumber).set("shipmentNumber", shipmentNumber);
    return this.http.get(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rbutton/onLoad', { params: params, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RAddButtonData(params: any): Observable<any> {
    let p = new HttpParams()
    Object.keys(params).forEach(k => {
      p = p.set(k, params[k])
    })
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/Rbutton/addOnload', { params: p, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RAddButtonDataDetail(params: any): Observable<any> {
    let p = new HttpParams()
    Object.keys(params).forEach(k => {
      p = p.set(k, params[k])
    })
    return this.http.get(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rbutton/addOnload', { params: p, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RAddButtonAddData(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'NewShipment/Rbutton/add/add', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RAddButtonAddDataDetail(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rbutton/add/add', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RDeleteButton(body: any): Observable<any> {
    return this.http.delete(this.constantsService.EXTERNAL + 'NewShipment/Rbutton/delete', { body: body, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  RDeleteButtonDetail(body: any): Observable<any> {
    return this.http.delete(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rbutton/delete', { body: body, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }


  ReceiveOnload(authNumber: string, shipmentNumber: string): Observable<any> {
    const params = new HttpParams().set('authNumber', authNumber).set("shipmentNumber", shipmentNumber);
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/Rack/ReceiveOnload', { params: params, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }
  
  ReceiveOnloadDetail(authNumber: string, shipmentNumber: string): Observable<any> {
    const params = new HttpParams().set('authNumber', authNumber).set("shipmentNumber", shipmentNumber);
    return this.http.get(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rack/ReceiveOnload', { params: params, headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }


  ShipmentSave(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'newShipment/Save', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  ShipmentListSave(body: any): Observable<any>{
    return this.http.put(this.constantsService.EXTERNAL + 'shipmentlist/Detail/Save', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  ShipmentSend(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'newShipment/Send', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  ShipmentListSend(body: any): Observable<any>{
    return this.http.put(this.constantsService.EXTERNAL + 'shipmentlist/Detail/Send', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  ReceiveSave(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'NewShipment/Rack/ReceiveUpdate', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  ReceiveSaveDetail(body: any): Observable<any> {
    return this.http.put(this.constantsService.EXTERNAL + 'Shipmentlist/Detail/Rack/ReceiveUpdate', body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  cancelShipmentBtn(shipmentNumber: any): Observable<any> {
    const body = {ShipmentNumber: shipmentNumber}
    return this.http.put(this.constantsService.EXTERNAL + `shipment/Detail/cancelshipment`,body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  
  ShipmentReject(shipmentNumber: any): Observable<any> {
    const body = {ShipmentNumber: shipmentNumber}
    return this.http.put(this.constantsService.EXTERNAL + `shipmentlist/Detail/Reject`,body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  fabricatorData(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/SelectCompany/createFabricatorData', { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  fabricatorDataDetail(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `ShipmentList/SelectCompany/createFabricatorData`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  supplierData(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/SelectCompany/createSupplierData', { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  supplierDataDetail(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `ShipmentList/SelectCompany/createSupplierData`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  namcData(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + 'NewShipment/SelectCompany/createPlantData', { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  namcDataDetail(): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `ShipmentList/SelectCompany/createPlantData`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  HistoryData(authNumber: string): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `newShipment/add/Delivery?authNumber=${authNumber}`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  HistoryDataDetail(authNumber: string): Observable<any> {
    return this.http.get(this.constantsService.EXTERNAL + `shipmentlist/Detail/Delivery?authNumber=${authNumber}`, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }
  private handleError(error: HttpErrorResponse) {
    return throwError(() => (error.message || "Server Error"));
  }
}
