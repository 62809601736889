<div class="wrapper bg-white">
    <br><p class="page-title p-1 px-3">Select Company</p>
    <div class="mat-typography content p-2" (keyup.enter)="Search()">
        <mat-radio-group name="Select Receiver"  >
        <div class="d-flex row mx-2">
            <div class="d-flex  align-items-center col-lg-1 col-md-3 col-12 mx-3">
                <mat-radio-button value="fabricator" (change)="changeSelected($event)" [checked]="selected ==='fabricator'">Fabricator</mat-radio-button>
            </div>
            <div class="d-flex  align-items-center col-lg-4 col-md-3 col-12 mx-3">
                <label for="FabricatorCode" class="pe-3 col-sm-5">Fabricator Code</label>
                <input type="text" id="FabricatorCode" class=""  [(ngModel)]="fabCode"  />
            </div>
        </div>
        <div class="d-flex  row mx-2 my-3">
            <div class="d-flex  align-items-center col-lg-1 col-md-3 col-12 mx-3">
                <mat-radio-button value="supplier" (change)="changeSelected($event)">Supplier</mat-radio-button>
            </div>
            <div class="d-flex  align-items-center col-lg-4 col-md-3 col-12 mx-3 my-1">
                <label for="SupplierCode" class="pe-3 col-sm-5">Supplier Code </label>
                <input type="text"  id="SupplierCode" [(ngModel)]="supplierCode" />
            </div>
            <div class="d-flex align-items-center col-lg-4 col-md-3 col-12 mx-3">
                <label for="supplierName" class="pe-3 col-sm-5">Supplier Name</label>
                <input type="text" class="" id="supplierName"  [(ngModel)]="supplierName" />
            </div>
            <div class="d-flex align-items-center col-lg-1 col-md-3 col-12 mx-3">
                <button class="black-button" (click)="Search()">Search</button>
            </div>
        </div>
        <div class="d-flex  row mx-2 my-3">
            <div class="d-flex  align-items-center col-lg-1 col-md-3 col-12 mx-3">
               <mat-radio-button value="namc" (change)="changeSelected($event)">NAMC</mat-radio-button>
            </div>
            <div class="d-flex align-items-center col-lg-4 col-md-3 col-12 mx-3">
                <label for="plant" class="pe-3 col-sm-5" >Plant</label>
                <mat-select id="plant" [(ngModel)]="namcPlant" class="mat-sel pt-2" disableOptionCentering>
                    <mat-option *ngFor="let plant of plants" [value]= "plant" >{{plant}}</mat-option> 
                </mat-select>
            </div> 
        </div>
    </mat-radio-group>
        <div class="filter d-flex">
            <div class="grid-agg" *ngIf="selected ==='fabricator'">
                <ag-grid-angular style="width:100%;height:200px;" 
                class="ag-theme-balham" 
                (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()" 
                [animateRows]="true" 
                [defaultColDef]="defaultColDef" 
                [columnDefs]="columnDefs"                
                [rowData]="displayRowData" 
                [suppressCellSelection]= "true"
                [suppressPaginationPanel]="true" 
                [icons]="icons"
                [headerHeight]="40"
                [floatingFiltersHeight]="40">
                </ag-grid-angular>
            </div>
            <div class="grid-agg" *ngIf="selected === 'supplier'">
                <ag-grid-angular style="width:100%;height:200px;" 
                class="ag-theme-balham" 
                (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()" 
                [animateRows]="true" 
                [defaultColDef]="defaultColDef" 
                [columnDefs]="columnDefs"                
                [rowData]="displayRowData" 
                [suppressCellSelection]="true"
                [suppressPaginationPanel]="true" 
                [icons]="icons"
                [headerHeight]="40"
                [floatingFiltersHeight]="40">
                </ag-grid-angular>
            </div>
            <div class="grid-agg" *ngIf="selected === 'namc'">
                <ag-grid-angular style="width:100%;height:200px;" 
                class="ag-theme-balham" 
                (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()" 
                [animateRows]="true" 
                [defaultColDef]="defaultColDef" 
                [columnDefs]="columnDefs"                
                [rowData]="displayRowData" 
                [suppressCellSelection]="true"
                [suppressPaginationPanel]="true" 
                [icons]="icons"
                [headerHeight]="40"
                [floatingFiltersHeight]="40">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="footer-dialog d-flex">
        <div mat-dialog-close class="mx-3 cancel">Close</div>
    </div>
</div>
    

