import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
@Component({
  selector: 'delete-btn-cell-renderer',
  template: `
    <button 
    class="vpacs-btn-table"
    style="width:75px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnDelete()">DELETE</button>
  `,
})

export class DeleteBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute,public shipmentListService: ShipmentListService, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;    
  }
  btnDelete() {
    const par={
    "packageCode":this.params.data.packagecode,
    "serialNumber": this.params.data.serialnumber
    }
    this.shipmentListService.delete(par).subscribe({
      next:(_res)=>{
        alert(_res.result)
      }
     
    })
  
  }

}




