import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceService {
  protected URL = ''
  onLoadFlag=true;
  constructor(private readonly constantsService: ConstantsService, private readonly http: HttpClient) {
    this.URL = this.constantsService.MASTERCOST + 'userMaintainance/'
  }
  //main-page
  loadAllPlants(): Observable<any> {
    const URL = this.URL + 'getPlantCode';
    return this.http.get(URL,{ headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }
  loadUserData(body:any): Observable<any> {
    const URL = this.URL + 'getUserDetails';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  deleteUser(body: any): Observable<any> {
    const URL = this.URL + 'deleteUser';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  //add-user
  addOnLoad(): Observable<any> {
    const URL = this.URL + 'getAddOnLoad';
    return this.http.get(URL,{ headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  plantChange(body:any): Observable<any> {
    const URL = this.URL + 'plantCodeOnClick';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  titleChange(body:any): Observable<any> {
    const URL = this.URL + 'titleOnClick';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  addUser(body:any): Observable<any> {
    const URL = this.URL + 'addUser';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  updateUser(body:any): Observable<any> {
    const URL = this.URL + 'updateUser';
    return this.http.post(URL,body, { headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  //update
  updateOnLoad(body:any): Observable<any> {
    const URL = this.URL + 'getUpdateOnLoad';
    return this.http.post(URL,body,{ headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }

  //changes
  loadGroupDropDown(body:any){
    const URL=this.URL + 'getGroupList';
    return this.http.post(URL,body,{ headers: this.constantsService.internalHttpOptions }).pipe(catchError(this.handleError))
  }

  setOnLoadFlag(data:any){
    this.onLoadFlag=data;
  }

  getOnLoadFlag(){
    return this.onLoadFlag;
  }

  resetOnLoadFlag() {
    this.onLoadFlag = true; 
  }
}
