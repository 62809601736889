import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExportStateService } from './services/export-state.service';
import { UsersService } from './services/users.service';
import { InactivityService } from './services/idle.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vpacs';
  isAuthorized = false;
  AuthToken = false;
  clientHeight: number;
  userId: any;
  constructor(
    public userService: UsersService,
    public router: Router,
    public state:ExportStateService,
    public idleService : InactivityService
    ) {
    this.clientHeight = window.innerHeight;
  }

  ngOnInit() {
    setTimeout(() => {
      this.state.getRoles()
    }, 2000);
    if (localStorage.getItem('toyotaGUID')) {
      this.AuthToken = true;
    }
  }

  // ngOnDestroy(): void {
  //   this.idleService.stopWatching();
  // }
}
