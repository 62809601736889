import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ShipmentActionBtnCellRender } from 'src/app/core/gridrender/shipment-action-btn-cell-render';
import { ShipmentService } from 'src/app/services/shipment.service';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss'],
  providers: [RegexFilter],
})
export class ShipmentComponent implements OnInit {
  public rowData: any;
  public shipmentstep: any;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50;
  isAccessible = false;
  public shipStatusName: any[] = [
    'Draft',
    'Wait Receiver',
    'Wait LP',
    'Confirm Shipment',
    'Actual Shipment',
    'Actual Receivement',
    'Complete',
    'Select All',
  ];
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  supplierNumber: any;
  searchParameters: any = new FormGroup({
    shipment: new FormControl(''),
    shipper: new FormControl(''),
    receiver: new FormControl(''),
    status: new FormControl(''),
    pickup: new FormControl(''),
    delivery: new FormControl(''),
    supplier: new FormControl(),
  });;
  supplierCode: any;

  constructor(
    private readonly router: Router,
    public shipmentService: ShipmentService,
    public regex: RegexFilter,
    public globalState: GlobalStateService,
    public state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      flex : 1 //occupy entire width in page
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }
  ngOnInit(): void {
    sessionStorage.removeItem('authNumber');
    this.state.roleState.subscribe({
      next: (res: any) => {
        if (res.length > 0) {
          this.getSupplierCodes();
        }
      },
    });
  }
  getSupplierCodes() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        const data = res && this.state.setSupplierCode(res.supplierCode);
        if (res) {
          this.performInitialOperations(data);
          this.getColumnDefs();
        }
      },
    });
  }

  performInitialOperations(inputData?: any[]) {
    if (localStorage.getItem('tracking')) {
      this.supplierNumber = JSON.parse(localStorage.getItem('tracking') || '');
    } else {
      this.supplierNumber = inputData;
    }
    if (this.supplierNumber.length > 0) {
      const data = this.globalState.getShipmentFilters();
      if (data) {
        this.defaultSearchFilter2(data);
        const supplier = data.supplier ? data?.supplier : 0;
        this.apiCall(supplier);
      } else {
        this.defaultSearchFilter(0);
        this.apiCall(0);
      }
    }
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'SHIPMENT NUMBER',
        field: 'SHIPMENTNUMBER',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'SHIPPER',
        field: 'SHIPPERNAME',
        sortable: true,
        unSortIcon: true,
        minWidth: 70,
      },
      {
        headerName: 'RECEIVER',
        field: 'RECVNAME',
        sortable: true,
        unSortIcon: true,
        minWidth: 70,
      },
      {
        headerName: 'LP',
        field: 'LPCODE',
        sortable: true,
        unSortIcon: true,
        minWidth: 70,
      },
      {
        headerName: 'PICKUP',
        field: 'PICKUPDATE',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'DELIVERY',
        field: 'DELIVDATE',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'STATUS',
        field: 'STATUSNAME',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'ACTION',
        field: 'action',
        minWidth: 100,
        cellRendererFramework: ShipmentActionBtnCellRender,
      },
    ];
  }

  getShipmentStatusName(STATUS: number) {
    let statusName: string;

    switch (STATUS) {
      case 0:
        statusName = this.shipStatusName[0];
        break;
      case 11:
        statusName = this.shipStatusName[1];
        break;
      case 12:
        statusName = this.shipStatusName[2];
        break;
      case 13:
        statusName = this.shipStatusName[3];
        break;
      case 21:
        statusName = this.shipStatusName[4];
        break;
      case 22:
        statusName = this.shipStatusName[5];
        break;
      default:
        statusName = this.shipStatusName[6];
        break;
    }

    return statusName;
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  goToNexpage() {
    this.gridApi?.paginationGoToNextPage();
  }
  goToPreviousPage() {
    return this.gridApi?.paginationGoToPreviousPage() + 1;
  }
  goToFirstPage() {
    this.gridApi?.paginationGoToFirstPage();
  }
  goToLastPage() {
    return this.gridApi?.paginationGoToLastPage() + 1;
  }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      return true;
    } else {
      return false;
    }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText(
        '#lbCurrentPage',
        this.gridApi.paginationGetCurrentPage() + 1
      );
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === 'ALL') {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.getCurrentPage();
    this.gridApi.sizeColumnsToFit();
  }

  setText(selector: string, text: any) {
    if(this.rowCount > 0){
      const element = document.querySelector(selector);
      if (element !== null) {
          element.innerHTML = text;
      }
    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  defaultSearchFilter(supplier: any) {
    this.searchParameters = new FormGroup({
      shipment: new FormControl(''),
      shipper: new FormControl(''),
      receiver: new FormControl(''),
      status: new FormControl(''),
      pickup: new FormControl(''),
      delivery: new FormControl(''),
      supplier: new FormControl(supplier),
    });
    if (this.supplierNumber?.length <= 1) {
      this.searchParameters.controls.supplier.disable();
    }
  }

  defaultSearchFilter2(data: any) {
    this.searchParameters = new FormGroup({
      shipment: new FormControl(data?.shipment),
      shipper: new FormControl(data?.shipper),
      receiver: new FormControl(data?.receiver),
      status: new FormControl(data?.status),
      pickup: new FormControl(data?.pickup),
      delivery: new FormControl(data?.delivery),
      supplier: new FormControl(data.supplier ? data?.supplier : 0),
    });
    if (this.supplierNumber?.length <= 1) {
      this.searchParameters.controls.supplier.disable();
    }
  }

  dateFilter(filteredData: any, searchParameters: any) {
    if (searchParameters.value.pickup && !searchParameters.value.delivery) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item.gridDate.replace(/\//g, '-')) >=
            new Date(searchParameters.value.pickup)
          );
        } else {
          return null;
        }
      });
    }

    if (!searchParameters.value.pickup && searchParameters.value.delivery) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item.gridDate.replace(/\//g, '-')) <=
            new Date(searchParameters.value.delivery)
          );
        } else {
          return null;
        }
      });
    }

    if (searchParameters.value.pickup && searchParameters.value.delivery) {
      filteredData = filteredData.filter((item: any) => {
        if (item.gridDate) {
          return (
            new Date(item?.gridDate.replace(/\//g, '-')) >=
              new Date(searchParameters.value.pickup) &&
            new Date(item?.gridDate.replace(/\//g, '-')) <=
              new Date(searchParameters.value.delivery)
          );
        } else {
          return null;
        }
      });
    }
    return filteredData;
  }

  dateOnChange(event: KeyboardEvent, type: any) {
    if ((event.target as HTMLInputElement).validity.valid) {
      if (type === 'pickup') {
        this.searchParameters.controls.pickup.setErrors(null);
      } else {
        this.searchParameters.controls.delivery.setErrors(null);
      }
    } else {
      if (type === 'pickup') {
        this.searchParameters.controls.pickup.setErrors({ incorrect: true });
      } else {
        this.searchParameters.controls.delivery.setErrors({ incorrect: true });
      }
    }
  }

  onSearch() {
    if (this.searchParameters.controls.pickup.value) {
      this.searchParameters.controls.pickup.setErrors(null);
    }
    if (this.searchParameters.controls.delivery.value) {
      this.searchParameters.controls.delivery.setErrors(null);
    }

    let filteredData = this.rowData;
    const filterParams: any = {
      SHIPMENTNUMBER: this.searchParameters.value.shipment
        ? this.regex.addStarForPartNumber(this.searchParameters.value.shipment)
        : '*',
      SHIPPERCODE: this.searchParameters.value.shipper
        ? this.regex.addStarForPartNumber(this.searchParameters.value.shipper)
        : '*',
      RECVNAME: this.searchParameters.value.receiver
        ? this.searchParameters.value.receiver
        : '*',
      STATUSNAME: this.searchParameters.value.status
        ? this.searchParameters.value.status
        : '*',
    };
    if (filterParams.STATUSNAME === 'Select All') {
      filterParams.STATUSNAME = '*';
    }

    this.globalState.setShipmentFilters(this.searchParameters.value);
    filteredData = this.regex.regexFilter(this.rowData, filterParams);
    filteredData = this.dateFilter(filteredData, this.searchParameters);
    this.displayRowData = filteredData;
    this.gridApi.setRowData(this.displayRowData);
    this.rowCount = filteredData.length;
  }

  callNewShipment() {
    this.router.navigate(['/shipment/newShipment'], {
      state: { supplierCode: this.supplierCode },
    });
  }

  valueChange(event: any) {
    this.defaultSearchFilter(event);
    this.apiCall(event);
    this.supplierCode = this.supplierNumber[event];
  }

  apiCall(number: any) {
    this.gridApi?.showLoadingOverlay();
    this.shipmentService
      ?.loadShipSteps()
      .subscribe((res: any) => (this.shipmentstep = res));
    this.supplierNumber &&
      this.supplierNumber.length > 0 &&
      this.shipmentService
        ?.loadShipmentData(this.supplierNumber[number])
        .subscribe({
          next: (res: any) => {
            res &&
              res.forEach((element: any) => {
                Object.assign(element, {
                  SUPPLIERCODE: this.supplierNumber[number],
                  STATUSNAME: this.getShipmentStatusName(element.STATUS),
                });
              });

            this.displayRowData = res.filter(
              (x: any) => x.STATUSNAME !== 'Complete'
            );
            this.rowData = res;
            this.rowCount = this.displayRowData.length;
            if (this.rowCount === 0) {
              this.gridApi?.hideOverlay();
              this.gridApi?.showNoRowsOverlay();
            }
            this.onSearch();
          },
          error: (_err: any) => {
            this.gridApi?.showNoRowsOverlay();
            this.displayRowData = [];
            this.rowCount = 0;
          },
        });
  }
}
