import { formatDate } from '@angular/common'
import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { DockBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/dock-btn-cell-render'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { DockMaintenanceBtnCellRender } from '../../../../internal-Vpacs-gridrender/master-maintenance/dock-maintenance-btn-cell-render'
import { AddDockComponent } from '../../../add-dock/add-dock.component'
import { DeleteFabComponent } from '../../../delete-fab/delete-fab.component'
import { InfoDialogComponent } from '../../../info-dialog/info-dialog.component'
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'

@Component({
  selector: 'app-dock-maintenance',
  templateUrl: './dock-maintenance.component.html',
  styleUrls: ['./dock-maintenance.component.scss'],
})
export class DockMaintenanceComponent implements OnInit {
  public addPlantForm: FormGroup = new FormGroup({})

  components = [
    'Select',
    'All',
    'Initial',
    'Receive Pre PPF',
    'Send Pre PPF',
    'Approval Pre PPF',
    'Trial Order',
    'Send Final PPF',
    'Approval Final',
    'Dunnage',
  ]

  component = 'Select'
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'

  plant: any
  supplier: any
  PlantCode: any
  PlantId: any
  plantCode: any
  leaderPlant: any
  leaderPlant1: any
  leaderPlant2: any
  leaderPlant3: any
  leaderPlant4: any
  leaderPlant5: any
  leaderPlant6: any
  leaderPlant7: any
  dockCode: any
  Buffpre: any
  buffProd: any
  buffOther: any
  buffLog: any
  plantName: any
  ringi: any
  haveAccess: any
  haveAddAccess: any
  modifyAccess: any
  deleteAccess: any
  saveAccess: any
  errorList: string[] = [];
  successMessage: string[] | string = [];
  //ag-grid-specs
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  paginationPageSize = 10
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  params: any
  regist: any
  finalRegist: any = []
  headerClass = 'child-header-color'
  headerClassNoBorder = 'child-header-color no-border'

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : ''
  }

  constructor(
    private readonly state: ExportStateService,
    public plantService: PlantService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<DockMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private readonly router: Router,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
      comparator:dateComparator

    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.',
    }
  }

  ngOnInit(): void {
    this.plantCode = this.data?.field?.plantcode
    this.plantName = this.data?.field?.name
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview
        this.haveAddAccess = res.role?.p_usersave
        this.deleteAccess = res.role?.p_userdelete
        this.haveAccess = true
        if (this.haveAccess) {
          this.dockonload()
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
      },
    })

    this.columnDefs = [
      {
        headerName: '',field: '', minWidth: 80,headerClass: this.headerClass,
        children: [
          {
            headerName: 'Dock Code',field: 'dockcode', headerClass: this.headerClassNoBorder,minWidth: 80,
          },
        ],
      },
      {
        headerName: 'Leaders',field: '',minWidth: 880,headerClass: this.headerClass,
        children: [
          {
            headerName: 'Pre Production',field: '',minWidth: 440,headerClass: this.headerClass,
            children: [
              {
                headerName: 'Conv',field: 'preconvsp',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'Qc',field: 'preqcsp',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'mfg',field: 'premfgsp',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'pks',field: 'prepks',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
            ],
          },
          {
            headerName: 'Mass Production',field: '',minWidth: 440,headerClass: this.headerClass,
            children: [
              {
                headerName: 'Conv',field: 'massconvsp',
                headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'Qc',field: 'massqcsp',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'mfg',field: 'massmfgsp',headerClass: this.headerClassNoBorder,minWidth: 110,cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'pks',
                field: 'masspks',
                headerClass: this.headerClassNoBorder,
                minWidth: 110,
                cellRendererFramework: DockMaintenanceBtnCellRender,
              },
            ],
          },
        ],
      },
      {
        headerName: 'Buffers(Days)',
        field: '',
        minWidth: 420,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'Pre Production',
            field: '',
            minWidth: 120,
            headerClass: this.headerClass,
            children: [
              {
                headerName: 'Internal Safety',
                field: 'buffpre',
                headerClass: this.headerClassNoBorder,
                minWidth: 120,
                cellRendererFramework: DockMaintenanceBtnCellRender,
              },
            ],
          },
          {
            headerName: '',
            field: '',
            minWidth: 330,
            headerClass: this.headerClass,
            children: [
              {
                headerName: 'Prod',
                field: 'buffprod',
                headerClass: this.headerClassNoBorder,
                minWidth: 110,
                cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'logistics',
                field: 'bufflog',
                headerClass: this.headerClassNoBorder,
                minWidth: 110,
                cellRendererFramework: DockMaintenanceBtnCellRender,
              },
              {
                headerName: 'other',
                field: 'buffother',
                headerClass: this.headerClassNoBorder,
                minWidth: 110,
                cellRendererFramework: DockMaintenanceBtnCellRender,
              },
            ],
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClassNoBorder,
        children: [
          {
            headerName: 'UPDATE',
            field: 'update',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: DockBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.updateBtn(field)
              },
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClassNoBorder,
        children: [
          {
            headerName: 'DELETE',
            field: 'delete',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: DockBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.dockDelete(field)
              },
            },
          },
        ],
      },
    ]
  }

  dockonload(){
    const loadObj = {
      plantCode: this.plantCode,
    }
    setTimeout(() => {this.spinnerService.start()})
    this.plantService.dockonload(loadObj).subscribe({
      next: (res: any) => {
        this.spinnerService.stop()
        this.PlantCode = res.plantCode
        this.PlantId = res.plantId
        this.displayRowData = res.dockDetails
        this.rowCount = this.displayRowData.length
        this.gridApi?.hideOverlay()
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  dockDelete(field: any) {
    const dialogRef = this.dialog.open(DeleteFabComponent, {
      data: { field, mode: 'dock' },
      height: '400px',
      width: '600px',
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        const loadObj = {
          plantCode: this.plantCode,
          dockCode: this.dockCode,
        }
        this.spinnerService.start()
        this.plantService.dockdelete(loadObj).subscribe({
          next: () => {},
          error: () => {
            this.spinnerService.stop()
          },
          complete: () => {
            this.spinnerService.stop()
          },
        })
      } 
    })
  }

  dockInformationDialog() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      height: '400px',
      width: '600px',
    })

    dialogRef.afterClosed().subscribe((_result: any) => {
      this.dockRegist();
    })
  }

  dockRegist() {
    const regisList = {
      regist: [
        {
          PreConv: null,
          Premfg: null,
          PreQc: null,
          Prepks: null,
          MassConv: null,
          Massmfg: null,
          MassQc: null,
          Masspks: null,
          BuffPre: 0,
          BuffProd: 0,
          BuffLog: 0,
          BuffOther: 0,
          DockCode: '25TMG',
          PlantCode: 's2',
        },
      ],
    }
    for (const row of this.displayRowData) {
      regisList.regist.push({
        PreConv: row.preconvsp,
        Premfg: row.premfgsp,
        PreQc: row.preqcsp,
        Prepks: row.prepks,
        MassConv: row.massconvsp,
        Massmfg: row.massmfgsp,
        MassQc: row.massqcsp,
        Masspks: row.masspks,
        BuffPre: row.buffpre,
        BuffProd: row.buffprod,
        BuffLog: row.bufflog,
        BuffOther: row.buffother,
        DockCode: row.dockcode,
        PlantCode: this.plantCode,
      })
    }
    this.spinnerService.start()
    this.plantService.dockRegist(regisList).subscribe({
      next: () => {},
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    this.apiCall()
  }

  apiCall() {
    this.rowCount = this.displayRowData.length
    this.gridApi?.redrawRows()
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }
  updateBtn(field: any) {
    const dialogRef = this.dialog.open(AddDockComponent, {
      width: '50%',
      data: {
        mode: 'UPDATE',
        field,
        plantCode: this.PlantCode,
        PlantName: this.plantName,
        plantId: this.PlantId,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (
          result.data?.result === 'SUCCESS' &&
          result.data?.mode === 'Update'
        ) {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }

  dialogOpen() {
    const dialogRef = this.dialog.open(AddDockComponent, {
      width: '50%',
      data: {
        mode: 'ADD',
        plantCode: this.PlantCode,
        PlantName: this.plantName,
        plantId: this.PlantId,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS' && result.data?.mode === 'Add') {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }
  afterDialogClose(data: any) {
    if (data?.result === 'Success' || data?.result === 'SUCCESS') {
      this.successMessage = data?.data.rslt;
      this.dockonload();
    } else if (data?.result === 'Failure' || data?.result === 'FAILURE') {
      this.errorList = data?.data.message;
    } else {
      this.errorList = [];
      this.successMessage = [];
    }
    setTimeout(() => {
      this.errorList = [];
      this.successMessage = [];
    }, 5000);
  }
  Close() {
    this.errorList = [];
    this.successMessage = [];
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' })
  }
  closeDialog() {
    this.dialogRef.close()
  }
  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 131])
  }
}
