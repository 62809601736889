import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'ppf-input-cellRenderer',
  template: `
    <span *ngIf="this.spanFlag">{{params.value ? params.value : '-'}}</span>
    <div *ngIf="this.readOnly && !this.spanFlag" 
      class="{{dangerFlag ? 'col-12 bg-danger' : 'col-12'}}" 
      style="height:30px; line-height: 30px; padding: 0 15px;"
    >
      {{ params.value ? params.value : '' }}
    </div>    
    <div *ngIf="!this.readOnly && !this.spanFlag">
    <input 
        type="text"
        value="{{ params.value}}" 
        class="{{dangerFlag ? 'col-12 bg-danger' : 'col-12'}}" 
        style="height:30px;"
        (change)="this.onChange($event)"
        [disabled]="disableHeight"
        [pattern]="pattern"
        (input)="this.validate($event)"
    />
    </div>
  `,
})
export class PPFInputCellRendererComponent implements ICellRendererAngularComp {
  public params:any;
  spanFlag = false;
  disableHeight = false;
  pattern:any = "[0-9]+(\.[0-9]{1,3})?";
  dangerFlag = false;
  protected readOnly = false
  constructor(private readonly router:ActivatedRoute,public globalState:GlobalStateService){
    if(this.router.snapshot.params['readOnly'] === 'readOnly'){
      this.readOnly = true;
    }
  }
  agInit(params: ICellRendererParams): void {  
    this.params = params;    
    if(['LENGTH','WIDTH'].includes(this.params.column.colId) && [1,2,3,4,5].includes(this.params.rowIndex)){
        this.spanFlag = true;
    }
    if(['HEIGHT'].includes(this.params.column.colId)){
      if([1,2,3,4].includes(this.params.rowIndex)){
        this.spanFlag = true;
      }else if(this.params.rowIndex === 5){
        this.disableHeight = true;
      }
    }
    if(['QUANTITY'].includes(this.params.column.colId)){
      if([0,3,4].includes(this.params.rowIndex)){
        this.disableHeight = true;
      }
    }
    if(this.params.column.colId === 'WEIGHT' && this.params.rowIndex ===4){
      if(params.value >= 15){
        this.dangerFlag = true;
      }
    } 
    if((this.params.column.colId === 'WEIGHT' && this.params.rowIndex !== 0)){
      this.disableHeight = true;
    } 
    if(['COMMENTS'].includes(this.params.column.colId)){
      this.pattern = ".*";
    }
    if(['COST'].includes(this.params.column.colId)){
      if(this.params.data.MANUFACTURER || this.params.data.PKGCODE){
        this.disableHeight = false;
      }else{
        this.disableHeight = true;
        this.params.value = '';
      }
    }
    
    if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 1)){
      this.readOnly = true;
      if(['LENGTH','WIDTH','HEIGHT','WEIGHT'].includes(this.params.column.colId)){
        if([0,4].includes(this.params.rowIndex)){
        this.readOnly = false;
        }
      }
      else if(['QUANTITIES2'].includes(this.params.column.colId)){
        if([4].includes(this.params.rowIndex)){
        this.readOnly = false;
        }
      }
    }
    else if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 2)){
      this.readOnly = false;
      if(['LENGTH','WIDTH','HEIGHT','WEIGHT'].includes(this.params.column.colId)){
        if([0,4].includes(this.params.rowIndex)){
        this.readOnly = true;
        }
      }
      else if(['QUANTITIES2'].includes(this.params.column.colId)){
        if([4].includes(this.params.rowIndex)){
        this.readOnly = true;
        }
      }
    }
    else if((params.data?.VIEWMODE === 2 && params.data?.EDITMODE === 0)){
      this.readOnly = true;
    }
    else if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 0)){
      this.readOnly = false;
    }
  }

  onChange(event:any){
    const colId:any = this.params.column?.getColId(); 
    this.params.data[colId] = event.target.value;
    if((colId === 'WEIGHT' && this.params.rowIndex === 0) || (colId === 'QUANTITY' && [1,2].includes(this.params.rowIndex) )
      || (colId === 'QUANTITIES2' && [0,1,2].includes(this.params.rowIndex) )){
      this.params.calculate();           
    } 
  }

  validate(event:any){
    //retaining grid2 data
    const rowData:any=[]
    this.params.api?.forEachNode((node:any) => rowData.push(node.data));
    if(rowData.length===6){
    this.globalState.setGrid2Data(rowData)
    }
    //end

    if(!(['COMMENTS'].includes(this.params.column.colId))){
      const input = event.target as HTMLInputElement;
      input.value = input.value.replace(/[^0-9.]/g,'')
    }
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}