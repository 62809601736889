import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PurConfabLinkCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/pur-confab-link-cell-renderer';
import { PurRouterLinkCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/pur-router-link-cell-renderer';
import { PurDecListService, PurDecdataComparator, dollarComparator } from 'src/app/internal-vpacs/services-internal/authorize/pur-dec-list.service';
import { formatDate } from '@angular/common';
import { CheckBoxDetailRendered } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/checkBoxDetail';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { PurCheckBox } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/PurCheckBox';
import { PurCommentEditor } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/purCommentEditor';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConfirmDialogComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/confirm-dialog/confirm-dialog.component';
import { InfoDialogPurComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/info-dialog-pur/info-dialog-pur.component';
import { LegendsComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/legends/legends.component';
import { CheckBoxDetailBossRendered } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/checkBoxDetailBoss';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-pur-decision-list',
  templateUrl: './pur-decision-list.component.html',
  styleUrls: ['./pur-decision-list.component.scss'],
})
export class PURDecisionListComponent implements OnInit {
  headerClassNoBorder = 'child-header-color no-border'
  plants: any = [];
  plant: any;
  name: any;
  selectedPlant: any[] = [];
  authnumber: any = '';
  supplier: any = '';
  shortname: any = '';
  partnumber: any = '';
  partnumber1: any = '';
  specialist: any = '';
  specialistCode: any;
  model: any;
  modelCode: any;
  params: any;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  pageNumber = 0;
  lastRowIndex = 0;
  paginationPageSize = 10;
  isAccessible = false;
  rowClassRules: any;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
<div>
  <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
  <p class="fw-bold fs-5">No data available</p>
  <span>Please select the above filters to get the results.</span>
</div>
`;

  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  radioGroup = [
    'approve',
    'toSupplier',
    'toDunnage1',
    'toContainer',
    'reject',
    'reserve',
    'needCC',
  ];
  radioGroup1 = [
    'approve',
    'reject',
    'reserve',
  ];
  public shipStatusName: any[] = [
    'Draft',
    'Wait Receiver',
    'Wait LP',
    'Confirm Shipment',
    'Actual Shipment',
    'Actual Receivement',
    'Complete',
    'Select All',
  ];
  comment: any;
  newComment: any;
  userInfo: any;
  haveAccess: any;
  errorList: string[] = [];
  isAll = false;
  currentTitle = '1';
  spCode: any;
  resPlant: any[] = [];
  userTitle = '';
  errorMessage:any;
  filteredOptions: any[] = [];
  getRowClass: any;


  constructor(
    private readonly purDecListService: PurDecListService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      unSortIcon: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    };

    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };

    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
    
    this.getRowClass = (params: any) => {
      if (this.currentTitle !== '3' && (params.data?.request_to !== null || params.data?.request_to !== "") &&
          params.data?.request_to === params.data?.shortname) {
          return 'row-yellow';
      }
      else if (this.currentTitle !== '3' && (params.data?.request_to !== null || params.data?.request_to !== "") &&
        params.data?.request_to === params.data?.fabcode) {
        return 'row-green-pur';
      }
      else if (this.currentTitle !== '3' && (params.data?.request_to !== null || params.data?.request_to !== "") &&
        params.data?.request_to === params.data?.dun1fabcode) {
        return 'row-blue';
      }
      return '';
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userTitle = res.info?.title;
          this.userInfo = res;
          this.haveAccess = (res.role?.title !== '0');
          this.haveAccess && this.apiCall();
          if (this.haveAccess) {
            const plantData = this.purDecListService.getplant();
            if (plantData) {
              const data = this.purDecListService.getPurDecListFilters();
              if (data) {
                this.shortname = data.SupplierCode
                this.plant = data.PlantCode === '' ? '' : data.PlantCode;
                this.selectedPlant = plantData;
                this.specialistCode = data.SpecialistCode
                this.partnumber = data.PartNumber1;
                this.partnumber1 = data.PartNumber2;
              }
            }
            if (res.info?.title === '3') {
              this.currentTitle = '3'
              this.getcolumndef();
            }
            else {
              this.getcolumndefTitleBoss();
            }
          }
          !this.haveAccess && this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
  }
  getcolumndef() {
    this.columnDefs = [
      {
        headerName: '', field: 'modelcode', minWidth: 75,
        children: [{
          headerName: 'MODEL', field: 'modelname', headerClass: this.headerClassNoBorder, minWidth: 80,
          valueFormatter: this.dataFormatter,
        }],
      },
      {
        headerName: '', field: 'plantname', minWidth: 75,
        children: [{ headerName: 'Plant', field: 'plantname', headerClass: this.headerClassNoBorder, minWidth: 80 }],
      },
      {
        headerName: '', field: 'shortname', minWidth: 95,
        children: [{ headerName: 'Supplier', field: 'shortname', headerClass: this.headerClassNoBorder, minWidth: 95 }],
      },
      {
        headerName: '', field: 'alphacode', minWidth: 95,
        children: [
          {
            headerName: 'Cont Fab', field: 'alphacode', headerClass: this.headerClassNoBorder, minWidth: 95,
            cellRendererFramework: PurConfabLinkCellRendererComponent,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
            cellRendererParams: {
              clicked: (_data: any) => {
                this.onSearch();
              },
            },
          },
        ],
      },
      {
        headerName: '', field: 'dun1fabcode', minWidth: 95,
        children: [{
          headerName: 'Dun1 Fab', field: 'dun1fabcode', headerClass: this.headerClassNoBorder, minWidth: 95,
          valueFormatter: this.dataFormatter,
        }],
      },
      {
        headerName: '', field: 'partnumber', minWidth: 110,
        children: [{
          headerName: 'Part Number', field: 'partnumber', headerClass: this.headerClassNoBorder, minWidth: 110,
          cellRendererFramework: PurRouterLinkCellRenderer,
          cellStyle: { cursor: 'pointer', color: '#2688DA' },
        }],
      },
      {
        headerName: '', field: 'specialist', minWidth: 100,
        children: [{ headerName: 'Specialist', field: 'specialist', headerClass: this.headerClassNoBorder, minWidth: 100 }],
      },
      {
        headerName: '', field: 'dummydate', minWidth: 90,
        children: [{ headerName: 'Date', field: 'dummydate', headerClass: this.headerClassNoBorder, minWidth: 90, comparator: dateComparator, }],
      },
      {
        headerName: '', field: 'authnumber', minWidth: 110,
        children: [
          {
            headerName: 'Auth#', field: 'authnumber', headerClass: this.headerClassNoBorder, minWidth: 110,
            cellRendererFramework: PurRouterLinkCellRenderer,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
          },
        ],
      },
      {
        headerName: '', field: 'authtype', minWidth: 100,
        children: [{
          headerName: 'Auth Type', field: 'authtype', headerClass: this.headerClassNoBorder, minWidth: 100,
        }],
      },
      {
        headerName: '', field: 'authunit', minWidth: 80,
        children: [{ headerName: 'Unit$', field: 'authunit', headerClass: this.headerClassNoBorder, minWidth: 80, comparator: dollarComparator }],
      },
      {
        headerName: '', field: 'authqty', minWidth: 80,
        children: [{ headerName: 'Qty', field: 'authqty', headerClass: this.headerClassNoBorder, minWidth: 80, comparator: PurDecdataComparator }],
      },
      {
        headerName: '', field: 'authtotal', minWidth: 100,
        children: [{ headerName: 'Total$', field: 'authtotal', headerClass: this.headerClassNoBorder, minWidth: 100, comparator: dollarComparator }],
      },
      {
        headerName: 'To Supplier', field: 'toSupplier', sortable: false, unSortIcon: false, minWidth: 75,
        children: [
          {
            headerName: 'ALL', field: 'toSupplier', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailRendered,
            headerComponentFramework: CheckBoxDetailRendered,
            cellRendererParams: {
              radioGroup: this.radioGroup,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'To Container', field: 'toContainer', sortable: false, unSortIcon: false, minWidth: 75,
        children: [
          {
            headerName: 'ALL', field: 'toContainer', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 110,
            cellRendererFramework: CheckBoxDetailRendered,
            headerComponentFramework: CheckBoxDetailRendered,
            cellRendererParams: {
              radioGroup: this.radioGroup,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'To Dunnage1', field: 'toDunnage1', sortable: false, unSortIcon: false, minWidth: 75,
        children: [
          {
            headerName: 'ALL', field: 'toDunnage1', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailRendered,
            headerComponentFramework: CheckBoxDetailRendered,
            cellRendererParams: {
              radioGroup: this.radioGroup,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'Reject', field: 'reject', sortable: false, unSortIcon: false, minWidth: 80,
        children: [
          {
            headerName: 'ALL', field: 'reject', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailRendered,
            headerComponentFramework: CheckBoxDetailRendered,
            cellRendererParams: {
              radioGroup: this.radioGroup,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'Reserve', field: 'reserve', sortable: false, unSortIcon: false, minWidth: 90,
        children: [
          {
            headerName: 'ALL', field: 'reserve', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailRendered,
            headerComponentFramework: CheckBoxDetailRendered,
            cellRendererParams: {
              radioGroup: this.radioGroup,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'Need CC	', field: 'needCC	', sortable: false, unSortIcon: false, minWidth: 90,
        children: [
          {
            headerName: 'ALL', field: 'needCC', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: PurCheckBox,
            headerComponentFramework: PurCheckBox,
            cellRendererParams: {
              radioGroup: this.radioGroup,
            },
          },
        ],
      },
      {
        headerName: '', field: 'decisioncomment', sortable: false, unSortIcon: false, minWidth: 110,
        children: [
          {
            headerName: 'COMMENT', field: 'decisioncomment', sortable: false, unSortIcon: false,
            headerClass: this.headerClassNoBorder, minWidth: 185,
            suppressKeyboardEvent: (_params: any) => true,
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellRendererFramework: PurCommentEditor,
            cellRendererParams: {
              clicked: (comment: any, id: any) => {
                this.comment = comment;
                this.updateComment(this.comment, id);
              },
            },
          },
        ],
      },
    ];
  }

  getcolumndefTitleBoss() {
    this.columnDefs = [
      {
        headerName: '', field: 'modelcode', minWidth: 75,
        children: [
          {
            headerName: 'MODEL', field: 'modelname', headerClass: this.headerClassNoBorder, minWidth: 80,
            valueFormatter: this.dataFormatter,
          },
        ],
      },
      {
        headerName: '', field: 'plantname', minWidth: 75,
        children: [
          {
            headerName: 'Plant', field: 'plantname', headerClass: this.headerClassNoBorder, minWidth: 80,
          },
        ],
      },
      {
        headerName: '', field: 'shortname', minWidth: 95,
        children: [
          {
            headerName: 'Supplier', field: 'shortname', headerClass: this.headerClassNoBorder, minWidth: 95,
          },
        ],
      },
      {
        headerName: '', field: 'alphacode', minWidth: 95,
        children: [
          {
            headerName: 'Cont Fab', field: 'alphacode', headerClass: this.headerClassNoBorder, minWidth: 95,
            cellRendererFramework: PurConfabLinkCellRendererComponent,
            cellStyle: { cursor: 'pointer', color: this.userInfo.info.title === "4" ? '' : '#2688DA' },
            cellRendererParams: {
              clicked: (_data: any) => {
                this.onSearch();
              },
              userInfo: this.userInfo
            },
          },
        ],
      },
      {
        headerName: '', field: 'dun1fabcode', minWidth: 95,
        children: [
          {
            headerName: 'Dun1 Fab', field: 'dun1fabcode', headerClass: this.headerClassNoBorder, minWidth: 95,
            valueFormatter: this.dataFormatter,
          },
        ],
      },
      {
        headerName: '', field: 'partnumber', minWidth: 110,
        children: [
          {
            headerName: 'Part Number', field: 'partnumber', headerClass: this.headerClassNoBorder, minWidth: 110,
            cellRendererFramework: PurRouterLinkCellRenderer,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
          },
        ],
      },
      {
        headerName: '', field: 'specialist', minWidth: 100,
        children: [
          {
            headerName: 'Specialist', field: 'specialist', headerClass: this.headerClassNoBorder, minWidth: 100,
          },
        ],
      },
      {
        headerName: '', field: 'dummydate', minWidth: 90,
        children: [
          {
            headerName: 'Date', field: 'dummydate', headerClass: this.headerClassNoBorder, minWidth: 90, comparator: dateComparator
          },
        ],
      },
      {
        headerName: '', field: 'authnumber', minWidth: 110,
        children: [
          {
            headerName: 'Auth#', field: 'authnumber', headerClass: this.headerClassNoBorder, minWidth: 110,
            cellRendererFramework: PurRouterLinkCellRenderer,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
          },
        ],
      },
      {
        headerName: '', field: 'authtype', minWidth: 100,
        children: [
          {
            headerName: 'Auth Type', field: 'authtype', headerClass: this.headerClassNoBorder, minWidth: 100,

          },
        ],
      },
      {
        headerName: '', field: 'authunit', minWidth: 80,
        children: [
          {
            headerName: 'Unit$', field: 'authunit', headerClass: this.headerClassNoBorder, minWidth: 80, comparator: dollarComparator
          },
        ],
      },
      {
        headerName: '', field: 'authqty', minWidth: 80,
        children: [
          {
            headerName: 'Qty', field: 'authqty', headerClass: this.headerClassNoBorder, minWidth: 80, comparator: PurDecdataComparator
          },
        ],
      },
      {
        headerName: '',
        field: 'authtotal',
        minWidth: 100,
        children: [
          {
            headerName: 'Total$',
            field: 'authtotal',
            headerClass: this.headerClassNoBorder,
            minWidth: 100,
            comparator: dollarComparator
          },
        ],
      },
      {
        headerName: "", field: "request_to", sortable: true, unSortIcon: true, minWidth: 100,
        children: [{
          headerName: "Auth To", field: "request_to", sortable: true, unSortIcon: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
          filter: 'agTextColumnFilter', headerClass: this.headerClassNoBorder, minWidth: 100,
        },]
      },
      {
        headerName: 'Approve', field: 'approve', sortable: false, unSortIcon: false, minWidth: 75,
        children: [
          {
            headerName: 'ALL', field: 'approve', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailBossRendered,
            headerComponentFramework: CheckBoxDetailBossRendered,
            cellRendererParams: {
              radioGroup1: this.radioGroup1,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'Reject', field: 'reject', sortable: false, unSortIcon: false, minWidth: 80,
        children: [
          {
            headerName: 'ALL', field: 'reject', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailBossRendered,
            headerComponentFramework: CheckBoxDetailBossRendered,
            cellRendererParams: {
              radioGroup1: this.radioGroup1,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: 'Reserve', field: 'reserve', sortable: false, unSortIcon: false, minWidth: 90,
        children: [
          {
            headerName: 'ALL', field: 'reserve', floatingFilterComponentParams: { suppressFilterButton: false },
            floatingFilter: false, filter: false, headerClass: this.headerClassNoBorder, minWidth: 100,
            cellRendererFramework: CheckBoxDetailBossRendered,
            headerComponentFramework: CheckBoxDetailBossRendered,
            cellRendererParams: {
              radioGroup1: this.radioGroup1,
              clicked: (data: any) => {
                this.updateDecision(data);
              },
            },
          },
        ],
      },
      {
        headerName: '', field: 'decisioncomment', sortable: false, unSortIcon: false, minWidth: 110,
        children: [
          {
            headerName: 'COMMENT', field: 'decisioncomment', sortable: false, unSortIcon: false,
            headerClass: this.headerClassNoBorder, minWidth: 185,
            suppressKeyboardEvent: (_params: any) => true,
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellRendererFramework: PurCommentEditor,
            cellRendererParams: {
              clicked: (comment: any, id: any) => {
                this.comment = comment;
                this.updateComment(this.comment, id);
              },
            },
          },
        ],
      },
    ];
  }

  apiCall() {
    this.spinnerService.start();
    this.spCode = this.userInfo.info.specialistcode || JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
    const obj = {
      "SpecialistCode": this.spCode,
      "Type": 1
    }

    this.purDecListService.plant(obj).subscribe({
      next: (response: any) => {
        this.resPlant = response.map((item: { plantcode: any }) => item.plantcode);
        this.selectedPlant = [{ plant: 'ALL', plantcode: '' }, ...response,];
        this.plant = this.plant ?? this.userInfo.info?.plantcode;
        this.filteredOptions = this.selectedPlant;
        this.displayFn(this.selectedPlant);
        this.loadPurDecision();
        this.purDecListService.setplant(this.selectedPlant);
      },
      complete: () => {
        this.spinnerService.stop();
      },

    })
  }

  filterOptions() {
    this.filteredOptions = this.selectedPlant.filter(option => option.plant.toLowerCase().includes(this.plant.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  updateComment(data: any, id: any) {
    const authnumber = id;
    this.newComment = data;
    const rowToUpdate = this.displayRowData.find(
      (row) => row.authnumber === authnumber
    );
    if (rowToUpdate) {
      rowToUpdate.comment = this.newComment;
      rowToUpdate.setDataValue('comment', this.newComment);
    }
  }
  updateDecision(_data: any) {
    this.gridApi.setRowData(this.displayRowData);
  }
  dateFormatter(params: any) {
    return params.value
      ? formatDate(params.value, 'MM/dd/YYYY', 'en-US', 'UTC')
      : '';
  }
  dataFormatter(params: any) {
    return params.value ? params.value : '-';
  }
  dollarFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null) {
      return '';
    } else {
      return '$' + params?.value;
    }
  }

  do() {
    alert('Are you sure?');
  }
  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specialistCode,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistCode = result;
    });
  }

  loadPurDecision(): void {
    this.spinnerService.start();
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode
    const loadObj = {
      SupplierCode: this.shortname,
      PlantCode: this.plant === '' ? '' : this.plant,
      SpecialistCode: this.specialistCode || '',
      PartNumber1: this.partnumber,
      PartNumber2: this.partnumber1,
      Title: this.userInfo.info?.title,
      UserSpecialistCode: this.userInfo.info.specialistcode,
      UserPlantCode: this.userInfo.info.plantcode,
      page: this.cPage,
      limit: this.rpPage,
    };
    this.purDecListService.setPurDecListFilters(loadObj);
    this.purDecListService.onloadPurDecisionList(loadObj).subscribe({
      next: (res: any) => {
        res.result.Result.forEach((element: any) => {
          element.AuthType = element.authtype;
          if (element.authtype === '0' || element.authtype === '1') {
            element.authtype = 'Purchase'

          } else if (element.authtype === '2') {
            element.authtype = 'Move';
          } else if (element.authtype === '4') {
            element.authtype = 'Maintenance';
          } else if (element.authtype === '5') {
            element.authtype = 'Prototypes';
          } else if (element.authtype === '3') {
            element.authtype = 'Dispose';
          } else if (element.authtype === '10') {
            element.authtype = 'Backup';
          } else {
            element.authtype = '';
          }
          element.request_To = element.request_to;
          if (element.request_to !== '' && element.request_to !== null) {
            if (element.suppliercode === element.request_to) {
              element.request_to = element.shortname;
            }
            else if ((element.fabcode === element.request_to) || (element.dun1fabcode === element.request_to)) {
              element.request_to = element.request_To;
            }
            else {
              element.request_to = '-';
            }
          } else {
            element.request_to = '-';
          }
          element.AuthTotal = element.authtotal
          element.authtotal = this.dollarFormatter({ value: element.authtotal })
          element.authUnit = element.authunit
          if (element.authunit !== null && element.authunit !== undefined) {
            element.authunit = element.authunit.trim();
          }
          element.authunit = this.dollarFormatter({ value: element.authunit })
          element.dummydate = this.dateFormatter({ value: element.dummydate })
        });
        for (const item of res.result.Result) {
          item.decision = '3';
        }
        this.displayRowData = res.result.Result;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
        this.errorMessage = null;
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  onSearch() {
    const plantCheck = this.selectedPlant.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((!plantCheck)) {
      this.errorMessage = "Please select valid plant name";
    }
    else {
      this.errorMessage = null;
      this.loadPurDecision();
    }
  }

  deleteFabricator() {
    const data = this.displayRowData.filter(item => item.fabcode && item.toDunnage1 === 1);

    const body: any = {
      "AuthNumber": data[0].authnumber,
    }
    this.spinnerService.start();
    this.purDecListService.deleteFabricator(body).subscribe({
      next: (_res: any) => {
        this.doPurDecision();
      },
      error: (_err: any) => {

        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    })
  }

  confirmPurDecision() {
    const message = `Are you sure?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message, header: 'PUR Decision Confirmation' }, height: '400px', width: '550px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        const displayError = this.displayRowData.filter(item => !item.dun1fabcode && item.toDunnage1 === 1);
        if (displayError.length > 0) {
          this.dialog.open(InfoDialogPurComponent, {
            data: {
              message: '399-221:Data Error. The dunnage1fabricator does not exist. You cannot select [To Dunnage1Fabricator].',
              header: 'Error Message', btnText: 'CLOSE'
            }, height: '400px', width: '550px'
          })
        } else if (this.userTitle === "3") {
          this.doPurDecision();
        } else if (this.userTitle === "4" || this.userTitle === "5") {
          this.doPurBossDecision();
        }
      }
    })
  }

  doPurDecision() {
    for (const item of this.displayRowData) {
      if (item?.toSupplier === 1) {
        item.decision = '0';
      }
      else if (item?.toContainer === 1) {
        item.decision = '1';
      }
      else if (item?.reject === 1) {
        item.decision = '2';
      }
      else if (item?.reserve === 1) {
        item.decision = '3';
      }
      else if (item?.toDunnage1 === 1) {
        item.decision = '6';
      }
    }
    const body: any = {
      UserSpecialistCode: this.userInfo.info.specialistcode,
      Data: this.displayRowData.map((item) => ({
        ...item,
        cc: item?.needCC,
        reason: item.decisioncomment,
        authtype: item.AuthType,
        authunit: item.authunit.replace('$', ''),
        authtotal: item.authtotal.replace('$', ''),
        request_to: item.request_To,
      })),
      UserName: '',
    };

    this.spinnerService.start();
    this.purDecListService.doPurDecision(body).subscribe({
      next: (res: any) => {
        if (res.status === true) {
          this.onSearch();
        }else{
          this.errorList = [res.message[0]];
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }
  doPurBossDecision() {
    this.errorMessage = null;
    const body: any = {
      sessionSpecialistCode: this.userInfo.info.specialistcode,
      data: this.displayRowData.map((item) => ({
        ...item,
        cc: -1,
        reason: item.decisioncomment,
        DECISION: 0,
        Decision: item.approve ? 0 : (item.reject ? 1 : 2),
        authtype: Number(item.AuthType),
        authunit: item.authunit.replace('$', ''),
        authtotal: item.authtotal.replace('$', ''),
        request_to: item.request_To,
      })),
      uinfo_getUserName: '',
    };

    this.spinnerService.start();
    this.purDecListService.doPurBossDecision(body).subscribe({
      next: (res: any) => {
        if (res.SuccessMessage === true) {
          this.onSearch();
        }
        else {
          if (res?.ErrorMessage !== '') {
            window.scrollTo(0, 0);
            this.errorMessage = res?.ErrorMessage;
            this.onSearch();
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  };

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.purDecListService.getPurDecGridFilters()) {
      const retrivedValue = this.purDecListService.getPurDecGridFilters();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  filterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    this.gridApi?.paginationSetPageSize(this.totalRecords);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.purDecListService.setPurDecGridFilters(allFilterModels);
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []

      for (let i = 0; i < Math.ceil(this.totalRecords / 100); i++) {
        this.cPage = i + 1;
        this.tPage = 1;
        this.rpPage = 100;
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearch();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.onSearch();
      resolve();
    });
  }

  help() {
    this.router.navigate(['./master-help', 3, "Cost Management", 56])
  }
  openLegends() {
    this.dialog.open(LegendsComponent, {
      data: {
        data: 'purDecision-List'
      },
      height: '400px', width: '550px'
    })
  }
  close() {
    this.errorList = [];
  }
}