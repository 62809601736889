<div class="popup-container">
    <header>
        <div class="d-flex justify-content-between align-items-center header">
            <p class="page-title mx-0 px-0">Budget Maintenance</p>   
            <div>
                <button class="submit black-button mx-3 " style="height: 30px;" (click)="dialogOpen()" [disabled]="!haveAddAccess">ADD</button>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>
    <div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
        <span>{{ successMessage }}</span>
        <button type="button" class="btn-close" (click)="Close()"></button>
    </div>
    <div class="popup-contents">
        <div class="row">
            <div class="col-12">
                <div class="row m-2 align-items-start">
                    <div class="col-3 d-flex">
                        <label class="catlabel">Plant Code</label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel">Plant Name</label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel">Plant ID</label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel">Cost Center</label>
                    </div>
                </div>                
            </div>         
            <div class="col-12">
                <div class="row m-2 align-items-start">
                    <div class="col-3 d-flex">
                        <label class="catlabel"><strong>{{plantResult?.plantcode}}</strong></label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel"><strong>{{plantResult?.name}}</strong></label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel"><strong>{{plantResult?.plantid}}</strong></label>
                    </div>
                    <div class="col-3 d-flex">
                        <label class="catlabel"><strong>{{costCenter}}</strong></label>
                    </div>
                </div>                
            </div>            
        </div>
        <div class="vpacs-shadow m-2 py-2 px-2">
            <div class="grid-agg tracking-summary">
                <ag-grid-angular style="width:100%;height:32vh;" 
                    class="ag-theme-balham" 
                    (gridReady)="onGridReady($event);"
                    (window:resize)="sizeToFit()" 
                    [rowHeight]="50" 
                    [animateRows]="true" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs" 
                    [rowData]="displayRowData" 
                    [suppressCellSelection]="true"
                    [suppressHorizontalScroll]="false" 
                    [pagination]="false" 
                    [suppressPaginationPanel]="true" 
                    [icons]="icons" 
                    [floatingFiltersHeight]="40"
                    [overlayLoadingTemplate]="overlayLoadingTemplate" 
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [domLayout]="domLayout" 
                    >
                </ag-grid-angular>        
            </div>
            <button type="button" class="close black-button" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>    
        </div>
    </div>
</div>
