import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'input-cell-renderer',
  template: `
    <input type="text" [disabled]= "partFlag"  (change)="changed($event)" style="width:100%;height:30px;"/>
  `,
})

export class InputCellRender implements ICellRendererAngularComp {

  pageName: any;
  partFlag = false;
  detailPage: any;
  
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(_params: ICellRendererParams): boolean {
      return true;
  	}
 
  private params: any;
  agInit(params: any): void {
    this.params = params;
    if (sessionStorage.getItem('detailShipmentNumber')) {
      this.detailPage = JSON.parse(
        sessionStorage.getItem('detailShipmentNumber') || ''
      );
    this.pageName = this.route.snapshot.data['breadcrumb'] 
    if(this.pageName === 'Detail')   {
     
      if (this.detailPage.status === 0 && (this.detailPage.currentUser ===  this.detailPage.supplierCode)) {
         this.partFlag = false;
       } else{
        this.partFlag = true;
       }
  }
}
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

  changed(event: any) {
    const value = Number(event.target.value);
    this.params.changed(this.params.data, value);
  }

}




