import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDueDateComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/update-due-date/update-due-date.component';
import { SelectLeaderComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-leader/select-leader.component';
import { ReleaseFollowerComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/release-follower/release-follower.component';
import { ConstantsService } from 'src/app/services/constant.service';
@Component({
  selector: 'tracking-list-cell-renderer',
  template: `
    <div *ngIf="ancherTag" >
      <a (click)="openDialog()" style="cursor:pointer;color:#0000ff;text-decoration:underline;"> {{ text }} </a>
    </div>
    <div *ngIf="!ancherTag" >
      <span style="color:#000;cursor:default;">{{ text }}</span>
    </div>`,
})

export class RouterLinkCellRenderer implements ICellRendererAngularComp {

    constructor(private readonly router: Router, private readonly route: ActivatedRoute, public readonly dialog: MatDialog,private readonly constants: ConstantsService) { }

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }

  private params: any;
  text: any;
  saveAccess = false;
  leaderFlag = false;
  leadName = false;
  ancherTag = false;
  private dialogRef: any = null;  

  agInit(params: any): void {
    this.params = params;
    const field = this.params.colDef.field;
    this.text = this.params.data[field];
    this.saveAccess = this.params.data.saveAccess;     
 
    if (this.params.colDef.field === 'suppliername') {
      this.ancherTag = true;
    }else if (this.params.colDef.field === 'fabricator' || this.params.colDef.field === 'dfabricator') {
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data.plantcode)
      this.ancherTag = (plantCode === 'SERVICE' || plantCode === 'ASSY') ? false : true;
    }else if (this.params.colDef.field === 'partnumber' && this.params.data["partnumber"] !== null) {
      this.ancherTag = true;
    }
    else if ( this.params.colDef.field === "step03due" && this.saveAccess && this.nullStr(this.params.data["follower"]) === ""){
      this.ancherTag = true;    
    }else if ( this.params.colDef.field === "step06due" && this.saveAccess && this.nullStr(this.params.data["follower"]) === ""){
      this.ancherTag = true;    
    }else if ( this.params.colDef.field === "step09due" && this.saveAccess && this.nullStr(this.params.data["follower"]) === ""){
      this.ancherTag = true;    
    }else if ( this.params.colDef.field === "step12due" && this.saveAccess){
      this.ancherTag = true;    
    }else if ( this.params.colDef.field === "pkgstartdate" && (this.saveAccess || this.nullStr(this.params.data["follower"]) === "")){
      this.ancherTag = true;    
    }else {
      this.ancherTag = this.saveAccess;
    }
  }

  openDialog() {
    if (this.params.colDef.headerName === 'LEAD') {
      if (this.params.value === '-') { 
        this.dialog.open(SelectLeaderComponent, {
          data: {
            id: this.params.column.instanceId,
            model: this.params.data.modelcode,
            plant: this.params.data.plantname,
            part: this.params.data.partnumber,
            supplier: this.params.data.suppliername,
            psd: this.params.data?.pkgstartdate,
            plantC: this.params.data.plantcode,
            supplierC: this.params.data.suppliercode,
            lead: this.params.data.leadname
          }
        })
      }
      else if (this.params.value === 'Leader') { 
        this.dialog.open(ReleaseFollowerComponent, {
          data: {
            id: this.params.column.instanceId,
            model: this.params.data.modelcode,
            plant: this.params.data.plantname,
            part: this.params.data.partnumber,
            supplier: this.params.data.suppliername,
            psd: this.params.data?.pkgstartdate,
            plantC: this.params.data.plantcode,
            supplierC: this.params.data.suppliercode,
            lead: this.params.data.leadname
          }
        })
      }
      else {
        this.params.clicked(this.params.column, this.params.data);
      }
    }
    else if (this.params.colDef.headerName === 'SUPPLIER') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }
    else if (this.params.colDef.headerName === 'C.FAB'){
      this.params.clicked(this.params.column.colId, this.params.data,this.params.data.fabricator);
    }
     else if( this.params.colDef.headerName === 'D.FAB') {
      this.params.clicked(this.params.column.colId, this.params.data,this.params.data.dfabricator);
    } else if (this.params.colDef.headerName === 'PRE PPF DUE' ||
      this.params.colDef.headerName === 'PACKAGE TRIAL DUE' ||
      this.params.colDef.headerName === 'PACKAGE AUTHORIZED DUE' ||
      this.params.colDef.headerName === 'READINESS DUE' ||
      this.params.colDef.headerName === 'PACKAGE START DATE'
    ) {

      const { suppliercode, partnumber, modelcode, pkgstartdate, plantcode, familycode } = this.params.data;

      this.dialogRef = this.dialog.open(UpdateDueDateComponent, {
        data: {
          id: this.params.column.instanceId,
          SupplierCode: suppliercode,
          PartNumber: partnumber,
          ModelCode: modelcode,
          PkgStartDate: pkgstartdate,
          PlantCode: plantcode,
          FamilyCode: familycode,
          SaveAccess: this.saveAccess
        },
        disableClose: true
      });

      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result.msg === "SUCCESS"){
          this.params.clicked();
        }          
      });

    }
    else if (this.params.colDef.headerName === 'SPECIALIST') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }
    else if (this.params.colDef.headerName === 'PART NUMBER') { //serviceparts ppf
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data.plantcode);  
      if(plantCode === 'SERVICE'){
        this.router.navigate(['/tracking-list/serviceparts-pdf',this.params.data.partnumber,this.params.data.ppfcode,this.params.data.ppfver,1,2])       
      }else if(plantCode === 'ASSY'){
        this.router.navigate(['/tracking-list/accessoryParts-pdf',this.params.data.partnumber,this.params.data.ppfcode,this.params.data.ppfver,1,2])
      }
      else{
        sessionStorage.setItem('ppfcloseFromTracking','0') 
        window.open( `/tracking-list/ppf/${this.params.data.ppfcode}/${this.params.data.ppfver}/1/2`, '_blank');      
      }       
      sessionStorage.setItem('ppfcloseFromTracking','1')  
    }
  }

  private nullStr(value: any): string {

    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }
}