import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadcrumbResolver } from './core/breadcrumb/breadcrumb.resolver';
import { AdminAccessComponent } from './pages/admin-access/admin-access.component';
import { AuthorityListComponent } from './pages/authority-list/authority-list.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { PkgAuthorizationComponent } from './pages/pkg-authorization/pkg-authorization.component';
import { NewShipmentComponent } from './pages/shipment/newshipment/newshipment.component';
import { ShipmentComponent } from './pages/shipment/shipment.component';
import { NotifyUpdateTrackingComponent } from './pages/tracking/notify-update-tracking/notify-update-tracking.component';
import { NewPackageComponent } from './pages/tracking/ppf/new-package/new-package.component';
import { PpfComponent } from './pages/tracking/ppf/ppf.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { UseBackupTrackingComponent } from './pages/tracking/use-backup-tracking/use-backup-tracking.component';
import { UseBackupComponent } from './pages/tracking/use-backup/use-backup.component';
import { RackShipmentComponent } from './core/dialogs/rack-shipment/rack-shipment.component';
import { RackReceivementComponent } from './core/dialogs/rack-receivement/rack-receivement.component';
import { DoPkgAuthorizationComponent } from './pages/pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';
import { BackUpDefineTrackingComponent } from './pages/tracking/back-up-define-tracking/back-up-define-tracking.component';
import { SelectBackUpDefineComponent } from './pages/tracking/back-up-define-tracking/select-back-up-define/select-back-up-define.component';
import { TrackingDoReadinessComponent } from './pages/tracking/tracking-do-readiness/tracking-do-readiness.component';
import { TrackingDoSendPrePdfComponent } from './pages/tracking/tracking-do-send-pre-pdf/tracking-do-send-pre-pdf.component';
import { TrackingDoShipTrailComponent } from './pages/tracking/tracking-do-ship-trail/tracking-do-ship-trail.component';
import { TrackingDoConfirmComponent } from './pages/tracking/tracking-do-confirm/tracking-do-confirm.component';
import { UnAuthorizedComponent } from './pages/un-authorized/un-authorized.component';
import { TrackingDoForecastComponent } from './pages/tracking/tracking-do-forecast/tracking-do-forecast.component';
import { TrackingDoSendFinalPdfComponent } from './pages/tracking/tracking-do-send-final-pdf/tracking-do-send-final-pdf.component';
import { InitialLoadComponent } from './pages/initial-load/initial-load.component';
import { UseBackupMasterComponent } from './pages/tracking/use-backup-master/use-backup-master.component';
import { InternalModule } from './internal-vpacs/internal.module';
import { MsalGuard } from './msal';
import { ServicePartsComponent } from './internal-vpacs/internal-vpacs/tracking/part-list/service-parts/service-parts.component';
const PPF_READONLY_URL = 'ppf/:supplier/:readOnly'
const SPDF_BREADCRUMB = 'Service Parts PDF'
const ACCESSORY_BREADCRUMB = 'Accessory Parts PDF'
const routes: Routes = [
  { path: '', component: InitialLoadComponent,canActivate:[MsalGuard]},
  {
    path: 'internal',
    loadChildren: () =>
      import('./internal-vpacs/internal.module').then(() => InternalModule),
      canActivate:[MsalGuard]
  },
  {
    path: 'admin-access',
    component: AdminAccessComponent,
    data: { breadcrumb: 'Admin-Access' },
    canActivate:[MsalGuard]
  },
  {
    path: 'tracking',
    component: TrackingComponent,
    data: { breadcrumb: 'Tracking' },
    canActivate:[MsalGuard]
  },
  {
    path: 'tracking',
    data: { breadcrumb: 'Tracking' },
    children: [
      // { path: '', component: TrackingComponent},
      {
        path: 'define-backup',
        component: BackUpDefineTrackingComponent,
        data: { breadcrumb: 'Backup-Define' },
        canActivate:[MsalGuard]
      },  
      {
        path: 'define-backup',
        data: { breadcrumb: 'Backup-Define' },
        children: [
          {
            path: 'select',
            component: SelectBackUpDefineComponent,
            data: { breadcrumb: 'Select-Backup-Define' },
            canActivate:[MsalGuard]
          },
        ],
      },
      // { path: 'define-backup', component: BackUpDefineTrackingComponent, data: { breadcrumb: 'Backup-Define' }},
      // { path: 'select', component: SelectBackUpDefineComponent, data: { breadcrumb: 'Copy from Other part' }},
      {
        path: 'ppf/:supplier',
        component: PpfComponent,
        data: { breadcrumb: 'PPF' },
        canActivate:[MsalGuard]
      },
      {
        path: 'ppf/:supplier',
        data: { breadcrumb: 'PPF' },
        children: [
          {
            path: 'new-package',
            component: NewPackageComponent,
            data: { breadcrumb: 'New Package' },
            canActivate:[MsalGuard]
          },
        ],
        
      },
      {
        path: PPF_READONLY_URL,
        component: PpfComponent,
        data: { breadcrumb: 'View PPF' },
        canActivate:[MsalGuard]
      },
      {
        path:'serviceparts-pdf/:ppfcode/:viewMode/:partNumber/:mode/:external',
        component: ServicePartsComponent,
        data: {breadcrumb: SPDF_BREADCRUMB},
        canActivate:[MsalGuard]
      },
      {
        path:'accessoryParts-pdf/:ppfcode/:viewMode/:partNumber/:mode/:external',
        component: ServicePartsComponent,
        data: {breadcrumb: ACCESSORY_BREADCRUMB},
        canActivate:[MsalGuard]
      },
      {
        path: 'use-backup/:supplier',
        component: UseBackupMasterComponent,
        data: { breadcrumb: 'Use-Backup' },
        children: [
          { path: '', component: UseBackupComponent, data: { breadcrumb: '' },       
            canActivate:[MsalGuard]
          },
          {
            path: ':pn',
            component: NotifyUpdateTrackingComponent,
            resolve: { apiData: BreadcrumbResolver },
            canActivate:[MsalGuard]
          },
          {
            path: 'tracking/update',
            component: UseBackupTrackingComponent,
            data: { breadcrumb: 'Use-Backup-Tracking' },
            canActivate:[MsalGuard]
          },
        ],
      },
      {
        path: 'do-readiness',
        component: TrackingDoReadinessComponent,
        data: { breadcrumb: 'Readiness Do' },
        
      },
      {
        path: 'do-sendPrePpf',
        component: TrackingDoSendPrePdfComponent,
        data: { breadcrumb: 'Send Pre PPF Do' },
        canActivate:[MsalGuard]
      },
      {
        path: 'do-sendPrePpf',
        data: { breadcrumb: 'Send Pre PPF Do' },
        children : [
          {
            path: PPF_READONLY_URL,
            component: PpfComponent,
            data: { breadcrumb: 'View PPF' },
            canActivate:[MsalGuard]
          },
          {
            path:'serviceparts-pdf/:ppfcode/:viewMode/:partNumber/:mode/:external',
            component: ServicePartsComponent,
            data: {breadcrumb: SPDF_BREADCRUMB},
            canActivate:[MsalGuard]
          },
          {
            path:'accessoryParts-pdf/:ppfcode/:viewMode/:partNumber/:mode/:external',
            component: ServicePartsComponent,
            data: {breadcrumb: ACCESSORY_BREADCRUMB},
            canActivate:[MsalGuard]
          },
        ]
      },
      {
        path: 'do-shipTrail',
        component: TrackingDoShipTrailComponent,
        data: { breadcrumb: 'Ship Trail Do' },
        canActivate:[MsalGuard]
      },
      {
        path: 'do-confirm',
        component: TrackingDoConfirmComponent,
        data: { breadcrumb: 'Confirm Do' },
        canActivate:[MsalGuard]
      },
      {
        path: 'do-forecast',
        component: TrackingDoForecastComponent,
        data: { breadcrumb: 'Forecast Do' },
        canActivate:[MsalGuard]
      },
      {
        path: 'do-finalPpf',
        component: TrackingDoSendFinalPdfComponent,
        data: { breadcrumb: 'Send Final PPF' },
        canActivate:[MsalGuard]
      },
    ],
  },
  {
    path: 'pkg-authorization',
    component: PkgAuthorizationComponent,
    data: { breadcrumb: 'Package Authorization' },
    canActivate:[MsalGuard]
  },
  {
    path: 'pkg-authorization',
    data: { breadcrumb: 'Package-Authorization' },
    children: [
      // { path: '', component: PkgAuthorizationComponent},
      {
        path: 'do-pkg-authorization',
        component: DoPkgAuthorizationComponent,
        data: { breadcrumb: 'Package-Authorization-Do' },
        canActivate:[MsalGuard]
      },
      {
        path: PPF_READONLY_URL,
        component: PpfComponent,
        data: { breadcrumb: 'View PPF' },
        canActivate:[MsalGuard]
      },
      {
        path:'serviceparts-pdf/:ppfcode/:ppfver/:history/:mode/:external',
        component: ServicePartsComponent,
        data: {breadcrumb: SPDF_BREADCRUMB},
        canActivate:[MsalGuard]
      },
      {
        path:'accessoryParts-pdf/:ppfcode/:ppfver/:history/:mode/:external',
        component: ServicePartsComponent,
        data: {breadcrumb: ACCESSORY_BREADCRUMB},
        canActivate:[MsalGuard]
      },
    ],
  },
  {
    path: 'shipment',
    component: ShipmentComponent,
    data: { breadcrumb: 'Shipment' },
    canActivate:[MsalGuard]
  },
  {
    path: 'shipment',
    data: { breadcrumb: 'Shipment' },
    children: [
      // { path:'', component: ShipmentComponent},
      {
        path: 'newShipment',
        component: NewShipmentComponent,
        data: { breadcrumb: 'New Shipment' },
        canActivate:[MsalGuard]
      },
      {
        path: 'detail',
        component: NewShipmentComponent,
        data: { breadcrumb: 'Detail' },
        canActivate:[MsalGuard]
      },
      {
        path: 'rackShipment',
        component: RackShipmentComponent,
        data: { breadcrumb: 'Rack Shipment' },
        canActivate:[MsalGuard]
      },
      {
        path: 'rackShipmentDetail',
        component: RackShipmentComponent,
        data: { breadcrumb: 'Rack Shipment Detail' },
        canActivate:[MsalGuard]
      },
      {
        path: 'rackReceivement',
        component: RackReceivementComponent,
        data: { breadcrumb: 'Rack Receivement' },
        canActivate:[MsalGuard]
      },
      {
        path: 'rackReceivementDetail',
        component: RackReceivementComponent,
        data: { breadcrumb: 'Rack Receivement Detail' },
        canActivate:[MsalGuard]
      },
    ],
  },
  {
    path: 'authority-list',
    component: AuthorityListComponent,
    data: { breadcrumb: 'Authority-List' },
    canActivate:[MsalGuard]
  },
  {
    path: 'documentation',
    component: DocumentationComponent,
    data: { breadcrumb: 'Documentation' },
    canActivate:[MsalGuard]
  },
  {
    path: 'unauthorized',
    component: UnAuthorizedComponent,
    data: { breadcrumb: 'UnAuthorized' },
    canActivate:[MsalGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
