import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MaterialService } from '../../services-internal/master-maintenance/material.service';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss']
})
export class AddMaterialComponent implements OnInit {
  pageName = '';
  materialForm: FormGroup = new FormGroup({
    materialCode: new FormControl('', [Validators.required, Validators.maxLength(3)]),
    material: new FormControl('', [Validators.required, Validators.maxLength(20)]),
  });
  errorList:any[] = [];
  constructor(
    private readonly materialService: MaterialService,
    private readonly dialogRef: MatDialogRef<AddMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly spinnerService: NgxUiLoaderService, private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.pageName = this.data?.pageName;
    if (this.pageName === 'update') {
      this.materialForm.setValue({ materialCode: this.data?.row?.materialcode, material: this.data?.row?.material });
      this.materialForm.updateValueAndValidity();
    }
  }

  get materialCode() {
    return this.materialForm.get('materialCode') as FormControl;
  }

  get material() {
    return this.materialForm.get('material') as FormControl;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  deleteMaterial() {
    this.spinnerService.start();
    const body = {
      "materialcode": this.data?.row?.materialcode
    }
    this.materialService.deleteMaterial(body).subscribe({
      next: (_res: any) => {
        this.dialogRef.close({ data: { result: 'success' } })
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    })
  }

  apiCall() {
    this.materialForm.markAllAsTouched();
    if (this.materialForm.valid) {
      this.spinnerService.start();
      if (this.pageName === 'Add') {
        const body = {
          "materialCode": this.materialForm.controls['materialCode'].value,
          "material": this.materialForm.controls['material'].value,
          "oldmaterialcode": null
        }
        this.materialService.addMaterial(body).subscribe({
          next: (_res: any) => {
            this.spinnerService.stop();
            if (_res.status === 'Failure') {
              this.errorList.push(_res.message)
            } else {
              this.dialogRef.close({ data: { result: 'success' } })
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
          }
        })
      } else if (this.pageName === 'update') {
        const body = {
          "MaterialCode": this.materialForm.controls['materialCode'].value,
          "Material": this.materialForm.controls['material'].value,
          "OldMaterialCode": this.data?.row?.materialcode
        }
        this.materialService.updateMaterial(body).subscribe({
          next: (_res: any) => {
            this.spinnerService.stop();
            if (_res.status === 'FAILURE') {
              this.errorList.push(_res.message)
            } else {
              this.dialogRef.close({ data: { result: 'success' } })
            }
          },
          error: (_err: any) => {
            this.spinnerService.stop();
          }
        })
      }
    }
  }
  help() {
    this.dialogRef.close();
    if (this.pageName === "update"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 108])
    }else if (this.pageName === "Add"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 107])
    }
  }
}
