export interface UserRoles {
        monetaryUnit: any;
        u_roles: any;
        backupDetails: any;
        supType: any;
        p_trackingview: boolean;
        p_trackingsave: boolean;
        p_trackingdelete: boolean;
        p_trackingcorrect: boolean;
        p_costview: boolean;
        p_costsave: boolean;
        p_costdelete: boolean;
        p_qtyview: boolean;
        p_qtyauth: boolean;
        p_userview: boolean;
        p_usersave: boolean;
        p_userdelete: boolean;
        p_pkgview: boolean;
        p_pkgsave: boolean;
        p_pkgmodify: boolean;
        p_pkgdelete: boolean;
        p_plantList: string[];
        p_plantTrackingList: string[];
        m_role: string
};

export function createEmptyUserRoles(): UserRoles {
        return {
                p_trackingview: false,
                p_trackingsave: false,
                p_trackingdelete: false,
                p_trackingcorrect: false,
                p_costview: false,
                p_costsave: false,
                p_costdelete: false,
                p_qtyview: false,
                p_qtyauth: false,
                p_userview: false,
                p_usersave: false,
                p_userdelete: false,
                p_pkgview: false,
                p_pkgsave: false,
                p_pkgmodify: false,
                p_pkgdelete: false,
                p_plantList: [],
                p_plantTrackingList: [],
                m_role: '',
                supType: '0',
                backupDetails : [],
                u_roles : [],
                monetaryUnit : ''
        }
}

export interface UserDetails {
        p_specialistcode: string;
        p_plantcode: string;
        p_firstname: string;
        p_lastname: string;
        p_middle: string;
        p_phone: number;
        p_fax: string;
        p_e_mail: string;
        p_bosscode: string;
        p_auth: string;
        p_title: string;
        p_suptype: number;
        p_backup: string[];
        p_ri: UserRoles;
        p_unit: number;
        monetary_unit: string;
        rate: number;
}

export function createEmptyUserDetails(): UserDetails {
        return {
                p_specialistcode: '',
                p_plantcode: '',
                p_firstname: '',
                p_lastname: '',
                p_middle: '',
                p_phone: 0,
                p_fax: '',
                p_e_mail: '',
                p_bosscode: '',
                p_auth: '',
                p_title: '',
                p_suptype: 0,
                p_backup: [''],
                p_ri: createEmptyUserRoles(),
                p_unit: 0,
                monetary_unit: '',
                rate: 0
        }
}


//API response
export interface Root {
        specialist: Specialist
        specialistRole: SpecialistRole
}



export interface Specialist {
        specialistcode: string
        p_specialistcode ?: string
        plantcode: string
        p_plantcode ?: string
        firstname: string
        lastname: string
        middleinitial: any
        password: string
        authlevel: any
        phone: string
        fax: any
        email: string
        pager: any
        bosscode: string
        title: string
        ext: any
        substitute: any
        subflag: string
        unit: string
        validflag: string
        ins_date: string
        up_date: string
        divcode: string
        preprod: string
        massprod: string
        guid: string
}



export interface SpecialistRole {
        role: Role
        roleAuthority: RoleAuthority[]
        authoriy: Authoriy[]
        authorityType: AuthorityType[]
        roleplant: Roleplant
        roleplanttracking: Roleplanttracking
}



export interface Role {
        rolecode: string
        name: string
        ins_date: string
        up_date: string
}



export interface RoleAuthority {
        rolecode: string
        authoritycode: string
        ins_date: string
        up_date: string
}



export interface Authoriy {
        authoritycode: string
        type: string
        name: string
        ins_date: any
        up_date: any
}



export interface AuthorityType {
        authoritytypecode: string
        name: string
        ins_date: any
        up_date: any
}



export interface Roleplant {
        rolecode: string
        plantcode: string
        ins_date: string
        up_date: string
}



export interface Roleplanttracking {
        rolecode: number
        plantcode: string
        ins_date: string
        up_date: string
}
      