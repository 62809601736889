import { Component, Input, OnInit } from '@angular/core';
import { ErrorMessage } from '../interface/ErrorInterface';
import { getFinalErrorMessage } from './ErrorConstants';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() codes !:ErrorMessage;
  protected result: any;
  show = true;

  ngOnInit(): void {
    this.result = getFinalErrorMessage(
      this.codes?.errorCode,
      this.codes?.childErrorCode,
      this.codes?.error,
      this.codes?.fieldErrorCode,
      this.codes?.field,
      this.codes?.index
    );
  }
  
}
