import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {MatDialog} from '@angular/material/dialog';
const pdfMakeX = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from "pdfmake/build/vfs_fonts"; 
pdfMakeX.vfs = pdfFonts.pdfMake.vfs;

export interface DialogData {
    dialogType: 'download' | 'update' | 'tracking' | 'end use/request payment' | 'notify of start use';
  }

@Component({
	selector: 'tracking-backup-btn-cell-render',
	template: `
        <div *ngIf="costs" class="d-flex">
            <span>$</span>
        </div>
    `,

})

export class UseBackupTrackingGrid implements ICellRendererAngularComp {

    constructor(public dialog: MatDialog){}
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	private params: any;
    public costs = false;
    public total = false;

	agInit(params: any): void {
		this.params = params;
        if(this.params.column.colId === 'costs'){ this.costs = true; }
        if(this.params.column.colId === 'total'){ this.total = true; }      
	}

  }