import { Component } from '@angular/core';
import { formatDate } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-cancel-auth',
  templateUrl: './cancel-auth.component.html',
  styleUrls: ['./cancel-auth.component.scss']
})
export class CancelAuthComponent {
  params: any;
  buttonName: any;
  userInfo: any;
  bDisabled = false;
  hideButton = false;
  text: any;
  constructor(
    private readonly state: ExportStateService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if(res){
          this.userInfo = res
        }
      },
      error: (_err: any) => {
      }
    })
    if (params.colDef.headerName === 'CANCEL') {
      if(params.data.readystatus === '4'){
        this.text = '';
        this.hideButton = true;
      }
      else{
        if ((params.data.flag === '1') && (params.data.dummyflag === '1')) {
          this.buttonName = 'CANCEL';
          if(params.data.specialistcode !== this.userInfo?.info?.specialistcode) {
            this.bDisabled = true;
            for(let j=0; j < this.userInfo?.role?.backupDetails.length; j++) {
              if(params.data.specialistcode === this.userInfo?.role?.backupDetails[j]) {
                this.bDisabled = false;
              }
            }
          }
        }
        else if ((params.data.flag === '0') && (params.data.reqcancelstatus === '1')) {
          // display supplier cancel date when negative flag 
          this.text = this.dateFormatter(params.data?.supcanceldate);
          this.hideButton = true;
        }
        else if (((params.data.flag === '2') && (params.data.reqcancelstatus === '1')) || 
        ((params.data.flag === '0') && (params.data.reqcancelstatus === '0'))) {
          // display toyota cancel date when wait cancel flag
          this.text = this.dateFormatter(params.data?.toyotacanceldate);
          this.hideButton = true;
        }
        else {
          this.hideButton = true;
        }
      }
    }
  }

  btnClickedHandler(_event: any) {
    if (this.params.colDef.headerName === 'CANCEL') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }
  }

  dateFormatter(params: any) {
    return params ? formatDate(params, 'MM/dd/YYYY', 'en-US', 'UTC') : '';
  }

}
