<p class="page-title mx-3">Hazmat Approval</p>
<div id="err" class="alert alert-success m-3" *ngIf="successMessage !== ''">
  <span>{{ successMessage }}</span>
</div>

<div id="err" class="alert alert-danger m-3" *ngIf="errorMessage !== null">
  <span>{{ errorMessage }}</span>
  <button type="button" class="btn-close" (click)="close()" data-bs-dismiss="alert" aria-label="close"></button>
</div>
<div class="row m-3 content-row pb-5">
  <div class="col-md-4 align-items-center ps-0 pe-4">
    <table class="table" aria-describedby="leader-plant">
      <th></th>
      <tbody class="d-flex">
        <div class="col-md-5 titleColumn">
          <div *ngFor="let title of leaderPlant" class="contents ps-4 py-2">
            {{ title }}
          </div>
        </div>
        <div class="col-md-7 contentColumn p-0">
          <div *ngFor="let title of leaderPlantContents; let i = index" class="ps-4 py-2"
            [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'">
            {{ title }}
          </div>
        </div>
      </tbody>
    </table>
    <div class="d-flex align-items-center">
      <button class="black-button me-3 roboto-medium" (click)="PPF()">
        PPF
      </button>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row">
      <div class="col-md-6 titleColumn p-0">
        <div *ngFor="let title of qpc" class="contents ps-4 py-2">
          {{ title }}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div *ngFor="let title of qpcContents; let i = index" class="ps-4 py-2"
          [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showWorkTable">
      <table class="table" aria-describedby="work">
        <thead>
          <tr class="titleColumn">
            <th scope="col" class="ps-4">Work</th>
          </tr>
        </thead>
        <tbody class="d-flex">
          <div class="col-md-6 titleColumn">
            <div *ngFor="let title of work" class="contents ps-4 py-2">
              {{ title }}
            </div>
          </div>
          <div class="col-md-6 contentColumn p-0">
            <mat-radio-group aria-labelledby="example-radio-group-label"
              class="example-radio-group direction-vertical d-grid p-0" name="work" [(ngModel)]="radioValWork">
              <mat-radio-button class="
                  example-radio-button
                  ps-4
                  py-2
                  align-items-center
                  d-flex
                  row-even
                " [value]="2" [checked]="rdoSupWorkChecked" [disabled]="rdoSupWorkDisabled"></mat-radio-button>
              <mat-radio-button class="
                  example-radio-button
                  ps-4
                  py-2
                  align-items-center
                  d-flex
                  row-odd
                " [value]="1" [checked]="rdoContFabWorkChecked" [disabled]="rdoContFabWorkDisabled">
              </mat-radio-button>
              <mat-radio-button class="
                  example-radio-button
                  ps-4
                  py-2
                  align-items-center
                  d-flex
                  row-even
                " [value]="3" [checked]="rdoDun1FabWorkChecked" [disabled]="rdoDun1FabWorkDisabled">
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-5">
    <div class="row align-items-baseline ps-4">
      <label for="hazmatComment" class="control-label col-md-12 ps-0">
        Hazmat Comment
      </label>
      <textarea name="" id="hazmatComment" cols="170" rows="3" class="form-control detail_area" name="hazmatComment"
        [(ngModel)]="hazmatComment"></textarea>
    </div>
    <div class="row align-items-baseline ps-4 mt-3">
      <label for="taIntComment" class="control-label col-md-12 ps-0">
        Internal Comment
      </label>
      <textarea readonly name="" id="taIntComment" cols="170" rows="3" class="form-control detail_area" name="taIntComment"
        [(ngModel)]="taIntComment"></textarea>
    </div>
    <div class="row align-items-baseline ps-4 mt-3">
      <label for="taExtComment" class="control-label col-md-12 ps-0">
        Supplier/ Fabricator Comment
      </label>
      <textarea readonly name="" id="taExtComment" cols="170" rows="3" class="form-control detail_area" name="taExtComment"
        [(ngModel)]="taExtComment"></textarea>
    </div>
  </div>
</div>

<div class="
    create_footer
    d-flex
    justify-content-end
    align-items-center
    fixed-bottom
  ">
  <button class="black-button me-3 roboto-medium" (click)="approve()">
    APPROVE
  </button>
  <button class="black-button me-3 roboto-medium" (click)="reject()">
    REJECT
  </button>
  <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
</div>