import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SupplierPlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier-plant.service';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
})
export class ImportDataComponent {
  fileName = '';
  base64File: string | ArrayBuffer | null = '';
  supplierType: any;
  errorList: any [] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<ImportDataComponent>,
    private readonly supplierPlantService: SupplierPlantService,
    private readonly spinner: NgxUiLoaderService,
    private readonly message: MatSnackBar,private readonly router:Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    this.dialogRef.disableClose = true;
    this.supplierType = data.supplierType;
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if (!file){ return }

    this.spinner.start();

    const fileName: string = file.name;
    this.fileName = fileName;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.errorList = [];
      const base64:any = reader.result
      this.base64File = base64?.split(';base64,')[1];
      this.spinner.stop();
    };
  }

  nextBtn() {
    if(this.base64File){
      if(this.fileName.split('.').pop() === 'xlsx'){
        this.spinner.start();
  
        const body = {
          filename: 'SupplierSpecialist',
          supplierType: this.supplierType,
          file: {
            data: this.base64File
          },
        };
  
        this.supplierPlantService.uploadSupplierPlant(body).subscribe({
          next: (res: any) => {
            if (res.status) {
              this.dialogRef.close({ success: true });
            }
            else{
              res.msg.forEach((e: any, i: any) => {
                if(i < 3){
                  this.errorList.push(e);
                }
              });
              if(Object.entries(res.msg).length > 3){
                this.errorList.push('more...');
              }
            }
            this.spinner.stop();
          },
          error: (_err: any) => {
            this.errorList = [`File upload failed.`];
            this.spinner.stop();
          }
        });
      }
      else{
        this.errorList = [`442-202:Uploading File`];
        this.fileName = '';
      }
    }
    else{
      this.errorList = [`Please select a file to upload.`];
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  help(){
    this.dialogRef.close();
    this.router.navigate(['./master-help',5,"Master Maintenance",119])
  }
}