import { formatDate } from '@angular/common'
import { Component, OnInit, Inject } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { CostCenterBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/cost-center-btn-cell-renderer'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { DeleteFabComponent } from '../../../delete-fab/delete-fab.component'
import { AddCostComponent } from '../add-cost/add-cost.component'
import { BudgetMaintenanceComponent } from '../budget-maintenance/budget-maintenance.component'

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.scss'],
})
export class CostCenterComponent implements OnInit {
  component = 'Select'
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'
  plantCode: any
  plantResult: any
  costCenter: any
  plant: any
  supplier: any
  ringi: any
  haveAccess: any
  haveAddAccess: any
  modifyAccess: any
  deleteAccess: any
  saveAccess: any
  errorList: string[] = [];
  successMessage: string[] | string = [];
  //ag-grid-specs
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  paginationPageSize = 10
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : ''
  }

  constructor(
    private readonly state: ExportStateService,
    public plantService: PlantService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<CostCenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private readonly router: Router,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.plantCode = this.data?.plantcode
    this.costCenter = this.data?.field?.costcenter
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview
        this.haveAddAccess = res.role?.p_usersave
        this.deleteAccess = res.role?.p_userdelete
        this.haveAccess = true
        if (this.haveAccess) {
          this.costOnload()
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
      },
    })

    this.columnDefs = [
      {
        headerName: 'Cost Center',
        field: 'costcenter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
      },
      {
        headerName: 'BUDGET',
        field: 'budget',
        sortable: false,
        unSortIcon: true,
        autoHeight: true,
        cellRendererFramework: CostCenterBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.dialogOpenBudget(field?.plantcode, field?.costcenter)
          },
        },
      },
      {
        headerName: 'UPDATE',
        field: 'update',
        sortable: false,
        unSortIcon: true,
        autoHeight: true,
        cellRendererFramework: CostCenterBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.updateBtn(field)
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        sortable: true,
        autoHeight: true,
        unSortIcon: false,
        cellRendererFramework: CostCenterBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteCostCenter(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
    ]
  }

  dialogOpenBudget(plantcode: string, costcenter: string) {
    const dialogRef = this.dialog.open(BudgetMaintenanceComponent, {
      width: '60%',
      data: { plantcode: plantcode, costcenter: costcenter },
    })

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.mode === 'X') {
        return
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }

  costOnload() {
    setTimeout(() => {this.spinnerService.start()});
    this.plantService.costOnload(this.plantCode).subscribe({
      next: (res: any) => {
        if (res && res.PlantResult) {
          this.plantResult = res?.PlantResult[0]
        }
        this.displayRowData = res.CostResult
        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      },
    })
  }

  deleteCostCenter(field: any) {
    const dialogRef = this.dialog.open(DeleteFabComponent, {
      data: { field, mode: 'cost' },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        this.spinnerService.start()
        this.plantService
          .deleteCostCenter(this.plantCode, field?.costcenter)
          .subscribe({
            next: (res: any) => {
              const result = res.status ? 'Success' : '';
              const _res = {...res, result, data: {message: res.message}}
            this.afterDialogClose(_res)
              this.displayRowData = res.BudgetResult
            },
            error: (_err: any) => {
              this.spinnerService.stop()
            },
          })
      } 
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    this.apiCall(this.paginationPageSize)
  }

  apiCall(_paginationPageSize: number) {
    this.rowCount = this.displayRowData.length
    this.gridApi?.redrawRows()
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  updateBtn(field: any) {
    const dialogRef = this.dialog.open(AddCostComponent, {
      width: '50%',
      data: {
        mode: 'UPDATE',
        field,
        plantCode: this.plantResult?.plantcode,
        PlantName: this.plantResult?.name,
        plantId: this.plantResult?.plantid,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (
          result.data?.result === 'SUCCESS' &&
          result.data?.mode === 'Update'
        ) {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }

  dialogOpen() {
    const dialogRef = this.dialog.open(AddCostComponent, {
      width: '50%',
      data: {
        mode: 'ADD',
        plantCode: this.plantResult?.plantcode,
        PlantName: this.plantResult?.name,
        plantId: this.plantResult?.plantid,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS' && result.data?.mode === 'Add') {
          this.afterDialogClose(result?.data)
          return
        } else if (result?.mode === 'X') {
          return
        }
      }
      dialogRef.close()
      this.dialogRef.close()
    })
  }
  afterDialogClose(data: any) {
    if (data?.result === 'Success' || data?.result === 'SUCCESS') {
      this.costOnload();
      this.successMessage = data.mode === 'Update' ? data?.data.message[0] : data?.data.message;
    } else if (data?.result === 'Failure' || data?.result === 'FAILURE') {
      this.errorList = data?.data.message;
    } else {
      this.errorList = [];
      this.successMessage = [];
    }
    setTimeout(() => {
      this.errorList = [];
      this.successMessage = [];
    }, 5000);
  }
  Close() {
    this.errorList = [];
    this.successMessage = [];
  }
  closeDialog() {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' })
  }
  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 170])
  }
}
