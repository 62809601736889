<p class="page-title mx-3">Hazmat Approval List</p>

<div id='err' class="alert alert-danger m-3" *ngIf="errorMsg">
  <span>{{errorMsg}}</span>
</div>

<div class="vpacs-shadow m-3 p-2" [formGroup]="searchForm">
  <div class="filter m-2 justify-content-between gap-2 d-flex" (keyup.enter)="search()">
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="family-code" class="col-4">Leader Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="leaderPlant"
        [ngModelOptions]="{ standalone: true }" (input)="leaderFilterOptions()" placeholder="--Select Plant--"
        [matAutocomplete]="auto1" style="width: 80%">
      <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="leaderDisplayFn.bind(this)">
        <mat-option *ngFor="let plants of selectedPlant" [value]="plants.plantcode">{{ plants.plant }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="family-code" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
        [ngModelOptions]="{ standalone: true }" #inputField="ngModel" (input)="filterOptions()"
        placeholder="--Select Plant--" [matAutocomplete]="auto2" style="width: 80%">
      <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="model-code" class="col-4">Model Code</label>
      <input type="text" id="model-code" formControlName="modelCode" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="supplier" class="col-4">Supplier</label>
      <input type="text" id="supplier" formControlName="supplier" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="part-number" class="col-4">Part Number</label>
      <input type="text" id="part-number" formControlName="partNumber" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="specialist" class="col-4">Specialist</label>
      <input type="text" [(ngModel)]="specialistCode" [ngModelOptions]="{ standalone: true }" />
      <button mat-icon-button>
        <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist('specialist')">more_vert</mat-icon>
      </button>
    </div>

    <div class="d-flex flex-wrap event-checkbox col-lg-2 col-md-6 col-12">
      <div class="d-flex align-items-center gap-2">
        <label class="checkcontainer mb-0">Pre
          <input type="checkbox" [checked]="preVal === 1 ? true : false" [(ngModel)]="preVal"
            [ngModelOptions]="{ standalone: true }" (change)="onPreChange($event)" />
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Trial
          <input type="checkbox" [checked]="trialVal === 1 ? true : false" [(ngModel)]="trialVal"
            [ngModelOptions]="{ standalone: true }" (change)="onTrialChange($event)" />
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Final
          <input type="checkbox" [checked]="finalVal === 1 ? true : false" [(ngModel)]="finalVal"
            [ngModelOptions]="{ standalone: true }" (change)="onFinalChange($event)" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between col-lg-2 col-md-6 col-12">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group d-flex gap-2"
        [(ngModel)]="radioVal" [ngModelOptions]="{ standalone: true }">
        <mat-radio-button class="example-radio-button" [value]="1">
          All
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" [value]="0">
          Do
        </mat-radio-button>
      </mat-radio-group>
      <button style="height: 30px;" class="black-button" (click)="search()">Search</button>
    </div>
    <div class="col-lg-2 col-md-6 col-12"></div>
    <div class="col-lg-2 col-md-6 col-12"></div>
  </div>
</div>

<div class="vpacs-shadow m-3 p-0 mb-0">
  <div class="grid-agg px-0 tracking-summary grid-height-hazmat-approvement-list-connect mb-5">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
      [rowData]="displayRowData" [gridOptions]="gridOptions" [suppressCellSelection]="true" [pagination]="true"
      [suppressPaginationPanel]="true" [autoGroupColumnDef]="autoGroupColumnDef" [icons]="icons" [headerHeight]="40"
      [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40" [domLayout]="domLayout" [rowClassRules]="rowClassRules"
      (filterChanged)="onFilterChanged($event)">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>
  </div>
</div>