<div class="d-flex justify-content-between align-items-center mx-3">
    <p class="page-title">Home</p> 
    <div>
        <button class="help-btn"(click)="openDialog('profile')">Profile</button>
        <button class="help-btn"(click)="help()">Help</button>
  </div>
  </div>
  <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
    </div>
  </div>
<app-loader [lines]=4 [table]=4 *ngIf="loading"></app-loader>
<div class="row m-3" *ngIf="!loading">
    <div class="col-6 ps-0 pe-3">
        <p>TRACKING</p>
        <div class="vpacs-shadow">
            <div class="grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items" (gridReady)="onGridReady($event)"
                    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true"
                    [headerHeight]="40" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [rowClassRules]="rowClassRules">
                </ag-grid-angular>
            </div>
        </div>

        <div class="vpacs-shadow mt-3">
            <div class="grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items2" (gridReady)="onGridReady2($event)"
                    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs2" [rowData]="displayRowData2" [suppressCellSelection]="true"
                    [headerHeight]="40" [rowClassRules]="rowClassRules">
                </ag-grid-angular>
            </div>
        </div>

        <div class="vpacs-shadow mt-3">
            <div class="grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items3" (gridReady)="onGridReady3($event)"
                    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs3" [rowData]="displayRowData3" [suppressCellSelection]="true"
                    [headerHeight]="40" [rowClassRules]="rowClassRules">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="col-6 ps-3 pe-0 backup">
        <p>BACKUP</p>
        <table class="table vpacs-shadow" aria-describedby="reason">
            <thead>
                <tr class="titleColumn">
                    <th scope="col">APPROVED ITEMS</th>
                    <th scope="col" class="text-center">NUMBER OF TASK</th>
                </tr>
            </thead>
            <tbody>
                <tr class="contentColumn">
                    <td class="row-odd">Backup Define</td>
                    <td class="text-center text-decoration-underline row-odd" *ngIf="displayRowData4?.d!=='0'"
                        (click)="navigate('define')">{{displayRowData4?.d}}</td>
                    <td class="text-center row-odd" *ngIf="displayRowData4?.d==='0'">{{displayRowData4?.d}}</td>
                </tr>
                <tr class="contentColumn">
                    <td>Backup Use</td>
                    <td class="text-center text-decoration-underline" *ngIf="displayRowData4?.u!=='0'"
                        (click)="navigate('use')">{{displayRowData4?.u}}</td>
                    <td class="text-center" *ngIf="displayRowData4?.u==='0'">{{displayRowData4?.u}}</td>
                </tr>
                <tr class="contentColumn">
                    <td class="row-odd">Backup Reimbursement</td>
                    <td class="text-center text-decoration-underline row-odd" *ngIf="displayRowData4?.r!=='0'"
                        (click)="navigate('reimburse')">{{displayRowData4?.r}}</td>
                    <td class="text-center row-odd" *ngIf="displayRowData4?.r==='0'">{{displayRowData4?.r}}</td>
                </tr>
            </tbody>
        </table>

        <div class="vpacs-shadow mt-3">
            <div class="grid-agg px-0">
                <ag-grid-angular class="ag-theme-balham grid-items5" (gridReady)="onGridReady5($event)"
                    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs5" [rowData]="displayRowData5" [suppressCellSelection]="true"
                    [headerHeight]="40" [rowClassRules]="rowClassRules">
                </ag-grid-angular>
            </div>
        </div>
        <div class="vpacs-shadow grid-items6 mt-3">
            <table class="table mb-0" aria-describedby="reason">
                <thead>
                    <tr class="titleColumn">
                        <th scope="col">TASK LIST</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="contentColumn" *ngIf="isdecisionCount">
                        <td class="text-decoration-underline">
                            <a [routerLink]="'/decision-list'">
                                {{decisionDetail}} : [{{decisionCount}} Decision]
                            </a>
                        </td>
                    </tr>
                    <tr class="contentColumn" *ngIf="isdiscCount">
                        <td class="text-decoration-underline">
                            <a (click)="discNavigate()">
                                {{discrepencyDetail}} : [{{discCount}} Discrepancy]
                            </a>
                        </td>
                    </tr>
                    <tr class="contentColumn" *ngIf="ismflmsCount">
                        <td class="text-decoration-underline">
                            <a [routerLink]="['/log-details','1']">
                                {{mflmsDetail}} : [{{mflmsCount}} Discrepancy]
                            </a>
                        </td>
                    </tr>
                    <tr class="contentColumn" *ngIf="ispreCount">
                        <td class="text-decoration-underline">
                            <a [routerLink]="['/log-details','2']">
                                {{preDetail}} : [{{preCount}} Discrepancy]
                            </a>
                        </td>
                    </tr>
                    <tr class="contentColumn" *ngFor="let tasklist of displayRowData6; let i=index">
                        <td class="text-decoration-underline">
                            <a (click)="updateUser(tasklist)">
                                {{tasklist.ins_date | date:'MM/dd/YYYY' }} : {{detail[i]}} : [{{tasklist.detail}}]
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>