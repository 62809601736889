import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-details-btn',
  templateUrl: './details-btn.component.html',
  styleUrls: ['./details-btn.component.scss']
})
export class DetailsBtnComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;

  //display message
  protected displayMessage = ''
  headNameSup = "supplier code";
  headNamePsd = "pkg start date";

  constructor(
    private readonly service: ModelListService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetailsBtnComponent>,
    public loader: NgxUiLoaderService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: false,
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      comparator:dateComparator
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
  }
  ngOnInit(): void {
    if (this.data.mode === 'New Tracking Details') {
      this.getNewgrid();
      setTimeout(() => {
        this.loader.start();
        this.newApiCall();
      });
    }
    if (this.data.mode === 'Part Replacement Details') {
      this.getPartReplacementGrid();
      setTimeout(() => {
        this.loader.start();
        this.partReplacementCall();
      });
    }
    if (this.data.mode === 'Supplier Replacement Details') {
      this.getSupplierReplacementGrid();
      setTimeout(() => {
        this.loader.start();
        this.supplierReplacementCall();
      });
    }
    if (this.data.mode === 'Update Tracking Details') {
      this.getUpdateGrid();
      setTimeout(() => {
        this.loader.start();
        this.updateCall();
      });
    }
    if (this.data.mode === 'Delete Tracking Details') {
      this.getDeleteGrid();
      setTimeout(() => {
        this.loader.start();
        this.deleteCall();
      });
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  closeDialog() {
    this.dialogRef.close()
  }

  okBtn() {
    this.dialogRef.close()
  }

  getNewgrid() {
    this.columnDefs = [
      {
        headerName: "part#",
        field: "partNumber",
        minWidth: 120,
      },
      {
        headerName: "proto part#",
        field: "protoPartNumber",
        minWidth: 90,
      },
      {
        headerName: "description",
        field: "description",
        minWidth: 90,
      },
      {
        headerName: this.headNameSup,
        field: "supplierCode",
        minWidth: 90,
      },
      {
        headerName: this.headNamePsd,
        field: "pkgDate",
        minWidth: 90,
        valueFormatter : (params:any) => this.formatDateTo(params)
      },
    ]
  }

  getPartReplacementGrid() {
    this.columnDefs = [
      {
        headerName: "current",
        field: "prevfamilyCode",
        minWidth: 120,
        children: [
          {
            headerName: "part#",
            field: "currentPart",
            minWidth: 90,
          },
          {
            headerName: this.headNameSup,
            field: "currentSupCode",
            minWidth: 90,
          },
          {
            headerName: this.headNamePsd,
            field: "currentPkgDate",
            minWidth: 90,
            valueFormatter : (params:any) => this.formatDateTo(params)
          },
        ]
      },
      {
        headerName: "new",
        field: "prevfamilyCode",
        minWidth: 120,
        children: [
          {
            headerName: "part#",
            field: "newPart",
            minWidth: 90,
          },
          {
            headerName: this.headNameSup,
            field: "newSupCode",
            minWidth: 90,
          },
          {
            headerName: this.headNamePsd,
            field: "newPkgDate",
            minWidth: 90,
            valueFormatter : (params:any) => this.formatDateTo(params)
          },
        ]
      },
      {
        headerName: "replaced by part list",
        field: "prevfamilyCode",
        minWidth: 120,
        children: [
          {
            headerName: "",
            field: "replacedpartlist",
            unSortIcon: false
          }
        ]
      },
    ]
  }

  getSupplierReplacementGrid() {
    this.columnDefs = [
      {
        headerName: "part#",
        field: "currentPart",
        minWidth: 120,
      },
      {
        headerName: "Current Supplier Code",
        field: "currentSupCode",
        minWidth: 90,
      },
      {
        headerName: "New Supplier Code",
        field: "newSupCode",
        minWidth: 90,
      },
      {
        headerName: this.headNamePsd,
        field: "currentPkgDate",
        minWidth: 90,
        valueFormatter : (params:any) => this.formatDateTo(params)
      },
    ]
  }

  getUpdateGrid() {
    this.columnDefs = [
      {
        headerName: "part#",
        field: "partNumber",
        minWidth: 120,
      },
      {
        headerName: this.headNameSup,
        field: "supplierCode",
        minWidth: 90,
      },
      {
        headerName: "Current Pkg Start Date",
        field: "currentPkgDate",
        minWidth: 90,
        valueFormatter : (params:any) => this.formatDateTo(params)
      },
      {
        headerName: "New Pkg Start Date code",
        field: "newPkgDate",
        minWidth: 90,
        valueFormatter : (params:any) => this.formatDateTo(params)
      },
    ]
  }

  getDeleteGrid() {
    this.columnDefs = [
      {
        headerName: "part#",
        field: "partNumber",
        minWidth: 120,
      },
      {
        headerName: this.headNameSup,
        field: "supplierCode",
        minWidth: 90,
      },
      {
        headerName: this.headNamePsd,
        field: "pkgDate",
        minWidth: 90,
        valueFormatter : (params:any) => this.formatDateTo(params)
      },
    ]
  }
  formatDateTo(params: any) {
    if (params.value === 'undefined' || params.value === null) {
      return '';
    } else {
      return params?.value ? new Date(params.value).toLocaleDateString('en-US',{timeZone : 'UTC'}): '';
    }
  }

  newApiCall() {
    const { id, plantcode, modelcode } = this.data.params
    const body = {
      "ID": id,
      "PlantCode": plantcode,
      "ModelCode": modelcode,
      "mode": this.data.params.row - 1
    }
    this.service.ImportnewDetails(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.displayMessage = res.detailsmsg || '';
          this.displayRowData = res.response.flat();
          //dummy data
          // this.displayRowData = [{protopartnumber : '111', suppliercode : '111', formattedDate1 : '222', formattedDate2 : '222'}]
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  partReplacementCall() {
    const { id, plantcode, modelcode } = this.data.params
    const body = {
      "ID": id,
      "PlantCode": plantcode,
      "ModelCode": modelcode
    }
    this.service.replaceDetails(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.displayMessage = res.detailsmsg || '';
          this.displayRowData = res.response[0];
          //dummy data
          // this.displayRowData = [{newSupCode : '111', newPart : '111', newPkgDate : '222',currentSupCode : '111', currentPart : '111', currentPkgDate : '222', replacedpartlist : '222'}]
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  supplierReplacementCall() {
    const { id, plantcode, modelcode } = this.data.params
    const body = {
      "ID": id,
      "PlantCode": plantcode,
      "ModelCode": modelcode
    }
    this.service.supplierReplaceDetails(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.displayMessage = res.detailsmsg || '';
          this.displayRowData = res.response.flat();
          //dummy data
          // this.displayRowData = [{ newSupCode: '111', newPart: '111', newPkgDate: '222', currentSupCode: '111', currentPart: '111', currentPkgDate: '222', replacedpartlist: '222' }]
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  updateCall() {
    const { id, plantcode, modelcode } = this.data.params
    const body = {
      "ID": id,
      "PlantCode": plantcode,
      "ModelCode": modelcode,
      "mode": this.data.params.row === 7 ? 0 : 1
    }
    this.service.ImportupdateDetails(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.displayMessage = res.detailsmsg || '';
          this.displayRowData = res.response.flat();
          //dummy data
          // this.displayRowData = [{protopartnumber : '111', suppliercode : '111', formattedDate1 : '222', formattedDate2 : '222'}]
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

  deleteCall() {
    const { id, plantcode, modelcode } = this.data.params
    const body = {
      "ID": id,
      "PlantCode": plantcode,
      "ModelCode": modelcode
    }
    this.service.deleteDetailsOnload(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.displayMessage = res.detailsmsg || '';
          this.displayRowData = res.response;
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
      }
    })
  }

}
