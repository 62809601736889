import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-auth-list-btn-cell-render',
  template: `
    <button 
    class="vpacs-btn-table"
    style="width:75px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnClickedHandler($event)">AUTH LIST</button>
  `,
})

export class AuthListBtnCellRender implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;    
  }
  btnClickedHandler(_event: any) {
    this.router.navigateByUrl('/ringi-list/auth-list?ringiNumber='+ this.params.data.ringinumber)
  }
}




