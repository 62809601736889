import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  public stepURL = '../../../assets/json/ship-steps.json';
  public trackigDataURL = '../../../assets/json/tracking-data.json'

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  loadShipSteps(): Observable<any>{
    return this.http.get(this.stepURL).pipe(catchError(this.handleError))
 }

  loadShipmentData(supplierNumber:string) : Observable<any>{
    const URL = this.constantsService.EXTERNAL + `shipmentlist?SupplierCode=${supplierNumber}`
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  downloadprintPdf(ShipmentNumber:string){
    return this.http.get(this.constantsService.EXTERNAL + `shipmentlist/Print?ShipmentNumber=${ShipmentNumber}`, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }

  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error.message);
	}
}
