import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportStateService } from 'src/app/services/export-state.service';


@Component({
  selector: 'app-ringi-detail',
  templateUrl: './ringi-detail.component.html',
  styleUrls: ['./ringi-detail.component.scss']
})
export class RingiDetailComponent implements OnInit {
  
  public ringiDetailForm:any ;
  headerClass = 'child-header-color';
  headerClassNoBoarder = 'child-header-color no-border';
  gridApi: any;
  columnDefs : any[] = [];
  currentPageNo  = 0;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [] ;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  pageNumber = 0;
  lastRowIndex = 0;
  paginationPageSize = 50
  isAccessible = false;
  rowClassRules: any;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  ringinumber:any;
  params: any;
  ringiNumber : any;
  result : any ='';
  estunitcost : any;
  estUnder : any;
  ringiOver : any;
  ringiUnder : any;
  authOver : any;
  authUnder : any;
  actOver : any;
  actUnder : any;
  partnumber : any;
  haveAccess = false
  haveAddAccess = false
  modifyAccess = false
  deleteAccess = false
  userInfo: any
  userPlant : any = "";
  estTotal = "";
  ringiTotal = "";
  authTotal = "";
  actTotal = "";
  plantVal: any;
  isAll = false;
  isSearch = false;

  constructor(private readonly dialog: MatDialog,
    private readonly formbuilder:FormBuilder, 
    private readonly ringiListService:RingiListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly route: ActivatedRoute,
    private readonly state: ExportStateService,
    private readonly router: Router,

    ){ 
    
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable:true,
      autoHeight: true,
    };
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
   }

  ngOnInit(): void {
    this.ringiNumber = this.route.snapshot.queryParamMap.get('ringiNumber')
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res?.info
          this.haveAccess = res.role?.p_costview
          this.userPlant = res.info?.plantcode
          this.haveAccess && this.apiCall();
          !this.haveAccess && this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.spinnerService.stop()
      },
    })

    this.columnDefs = [
      {
        headerName: "", field: "ringinumber", minWidth: 120, headerClass: this.headerClass,
        children:[{headerName: "Ringi#", field: "ringinumber", sortable: true, unSortIcon: true,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder,minWidth:120},]
      },
      {
        headerName: "", field: "partnumber	", minWidth: 110, headerClass: this.headerClass,
        children:[{headerName: "Part Number", field: "partnumber", sortable: true, unSortIcon: true,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', headerClass: this.headerClassNoBoarder,minWidth:100},]
      },
      {
        headerName: "Est Cost $(US)",  minWidth: 65, sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'Est',field: 'estunitcost',sortable: false, unSortIcon: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter',headerClass: this.headerClass, minWidth:70, 
          },
          {
            headerName: 'Qty',field: 'estqty',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80, 
          },
          {
            headerName: 'Total',field: 'esttotal',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80,
          },
        ],
      },
      {
        headerName: "Ringi Cost $(US)",  minWidth: 65, sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'Ringi',field: 'ringiunitcost',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass, minWidth:70, 
          },
          {
            headerName: 'Qty',field: 'ringiqty',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80, 
          },
          {
            headerName: 'Total',field: 'ringitotal',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80,
          },
        ],
      },
      {
        headerName: "Auth Cost $(US)",  minWidth: 65, sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'Auth',field: 'authunitcost',sortable: false, unSortIcon: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass, minWidth:70, 
          },
          {
            headerName: 'Qty',field: 'authqty',sortable: false, unSortIcon: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80, 
          },
          {
            headerName: 'Total',field: 'authtotal',sortable: false, unSortIcon: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80,
          },
        ],
      },
      {
        headerName: "Actual Cost $(US)",  minWidth: 65, sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'Act',field: 'actunitcost',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass, minWidth:70, 
          },
          {
            headerName: 'Qty',field: 'actqty',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80, 
          },
          {
            headerName: 'Total',field: 'acttotal',sortable: false, unSortIcon: false,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
            filter: 'agTextColumnFilter', headerClass: this.headerClass,minWidth:80,
          },
        ],
      }
    ]; 

  }
 
  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.params = params;
    this.gridApi.sizeColumnsToFit();
  }

  apiCall() {
      const loadObj={
        "RingiNumber" : this.ringiNumber ,
        "PartNumber1" : this.partnumber,
        "PartNumber2" : "",
        "EstOver" : this.estunitcost,
        "EstUnder" : this.estUnder,
        "RingiOver" : this.ringiOver,
        "RingiUnder" : this.ringiUnder,
        "AuthOver" : this.authOver,
        "AuthUnder" : this.authUnder,
        "ActOver" : this.actOver ,
        "ActUnder" : this.actUnder,
        "UserPlantCode": this.userPlant,
        "limit": this.rpPage, 
        "page": this.cPage,  
      };
      this.spinnerService.start();
      this.ringiListService.onloadRingiDetails(loadObj)
      .subscribe( {next: (res:any) => {
        const rate = res?.data.Rate;
        this.authTotal = (res?.data.AuthTotal * rate)?.toFixed(2).toString();
        this.ringiTotal = (res?.data.RingiTotal * rate)?.toFixed(2).toString();
        this.estTotal = (res?.data.EstTotal * rate)?.toFixed(2).toString();
        this.actTotal = (res?.data.ActualTotal * rate)?.toFixed(2).toString();
        this.displayRowData = res?.data.Result.result;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = res?.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.gridApi?.hideOverlay();
        this.spinnerService.stop();
      } ,
      error: (_err: any) => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      }
    })
  }

  help(){
    this.router.navigate(['./master-help',3,"Cost Management",42])
  }

  onSearch(){
    this.apiCall()
  }

  backBtn(){
    this.router.navigate(['/ringi-list'])
  }
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if(this.isAll){
      this.displayRowData = []

      for (let i=0; i<Math.ceil(this.totalRecords/100); i++){
        this.cPage = i+1;
        this.tPage = 1;
        this.rpPage = 100
        await this.apiCall()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else{
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall();
    }
  }

  onFilterChanged(_params:any){
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount(); 
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    this.apiCall();
  }   
}
