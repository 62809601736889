import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddRoleMasterComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/role-maintenance/add-role-master/add-role-master.component';
import { UpdateRoleMaterBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/update-role-master-btn-cell-render';
import { RoleService } from 'src/app/internal-vpacs/services-internal/master-maintenance/role.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-master-maintenance-role',
  templateUrl: './master-maintenance-role.component.html',
  styleUrls: ['./master-maintenance-role.component.scss'],
})
export class MasterMaintenanceRoleComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  icons: {};
  defaultColDef: {};
  rowClassRules: any;

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rowIndex: any;
  rolecode: any;
  errorList: string[] = [];
  successMessage: string[] = [];
  haveAccess: any;
  addflag: any = false;

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    public route: ActivatedRoute,
    private readonly roleService: RoleService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: false,
      autoHeight: true,
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.spinnerService.start();
          this.haveAccess = res.role?.p_userview;
          this.haveAccess && this.getcolumnDefs();
          this.haveAccess && this.apiCall(1);
          !this.haveAccess && this.spinnerService.stop();
        }
        if (res && !res.role?.p_usersave) {
          this.addflag = true;
        } else {
          this.addflag = false;
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.spinnerService.stop();
      },
    });
  }
  getcolumnDefs() {
    this.columnDefs = [
      {
        headerName: 'ROLE NAME',
        field: 'rolename',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 60,
      },
      {
        headerName: 'UPDATE',
        field: 'backup',
        minWidth: 60,
        cellRendererFramework: UpdateRoleMaterBtnCellRender,
        cellStyle: { cursor: 'pointer' },
        cellRendererParams: {
          dialogClose: (data: any) => {
            this.afterDialogClose(data);
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        minWidth: 60,
        cellRendererFramework: UpdateRoleMaterBtnCellRender,
        cellRendererParams: {
          dialogClose: (data: any) => {
            this.afterDialogClose(data);
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
    ];
  }
  afterDialogClose(data: any) {
    if (data?.status === 'Success' || data?.status === 'SUCCESS') {
      this.successMessage = data?.message;
      this.apiCall(1);
    } else if (data?.status === 'Failure' || data?.status === 'FAILURE') {
      this.errorList = data?.message;
    } else {
      this.errorList = [];
      this.successMessage = [];
    }
    setTimeout(() => {
      this.errorList = [];
      this.successMessage = [];
    }, 5000);
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  add() {
    this.close();
    const dialogRef = this.dialog.open(AddRoleMasterComponent, {
      width: '1062px',
      data: { headerName: 'Add' },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      this.afterDialogClose(data);
    });
  }

  apiCall(x?: any) {
    if (x === 1) {
      this.spinnerService.start();
      this.roleService.getRoleInfo().subscribe({
        next: (res: any) => {
          this.displayRowData = res.result;
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        },
      });
    }
  }

  close() {
    this.errorList = [];
    this.successMessage = [];
  }
  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 134]);
  }
}
