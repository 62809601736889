import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { IPlantType } from 'src/app/internal-vpacs/interface/ISupplierPlant'
import { SupplierPlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier-plant.service'

export interface ICalculationERow {
  e1: any
  e2: any
  e3: any
  e4: any
  e5: any
  e6: any
  e7: any
  e8: any
}

export interface ICalculationFRow {
  f1: any
  f2: any
  f3: any
  f4: any
  f5: any
  f6: any
  f7: any
  f8: any
}

export interface ITotalRow {
  t1: any
  t2: any
  t3: any
  t4: any
  t5: any
  t6: any
  t7: any
  t8: any
}

export interface IOtherRow {
  o1: any
  o2: any
  o3: any
  o4: any
  o5: any
  o6: any
  o7: any
  o8: any
}

export interface ICalculationData {
  eRow: ICalculationERow
  fRow: ICalculationFRow
}
@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
})
export class CalculationComponent implements OnInit {
  plantCode = ''
  plantName = ''
  supplierShortName = ''
  supplierCode = ''
  successMessage = ''
  errorList = ''
  private readonly calculationData: ICalculationData = {
    eRow: { e1: 0, e2: 0, e3: 0, e4: 0, e5: 0, e6: 0, e7: 0, e8: 0 },
    fRow: { f1: 0, f2: 0, f3: 0, f4: 0, f5: 0, f6: 0, f7: 0, f8: 0 },
  }

  plantCalculationData = { ...this.calculationData }
  supplierCalculationData = { ...this.calculationData }

  totalRow: ITotalRow = {
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
    t5: 0,
    t6: 0,
    t7: 0,
    t8: 0,
  }

  otherRow: IOtherRow = {
    o1: 0,
    o2: 0,
    o3: 0,
    o4: 0,
    o5: 0,
    o6: 0,
    o7: 0,
    o8: 0,
  }
  result2: any
  result: any
  haveAddAccess = false

  constructor(
    private readonly dialogRef: MatDialogRef<CalculationComponent>,
    private readonly supplierPlantService: SupplierPlantService,
    private readonly spinner: NgxUiLoaderService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.dialogRef.disableClose = true
    this.plantCode = data.plancodes[0].plantcode
    this.supplierCode = data.suppliercode
    this.supplierShortName = data.suppliername
    this.plantName = data.plantlist.find(
      (plant: IPlantType) => plant.plantcode === this.plantCode,
    )?.plant
    this.haveAddAccess = data.haveAccess
  }

  onCompare(_left: any, _right: any) {
    return 0
  }

  ngOnInit(): void {
    this.getCalculation()
  }

  getCalculation() {
    setTimeout(() => {
      this.spinner.start()
    })

    const body = {
      plantcode: this.plantCode,
      suppliercode: this.supplierCode,
    }
    this.supplierPlantService.getCalculation(body).subscribe({
      next: (res: any) => {
        this.result = res?.result1?.result[0]
        this.result2 = res?.result2?.result[0]

        this.totalCalc(this.result, this.result2, 0)
      },
    })
  }

  totalCalc(result: any, result2: any, flag: any) {
    if (result) {
      const plantERow = {
        e1: Number(result.plante1).toFixed(2),
        e2: Number(result.plante2).toFixed(2),
        e3: Number(result.plante3).toFixed(2),
        e4: Number(result.plante4).toFixed(2),
        e5: Number(result.plante5).toFixed(2),
        e6: Number(result.plante6).toFixed(2),
        e7: Number(result.plante7).toFixed(2),
        e8: Number(result.plante8).toFixed(2),
      }

      const plantFRow = {
        f1: Number(result.plantf1).toFixed(2),
        f2: Number(result.plantf2).toFixed(2),
        f3: Number(result.plantf3).toFixed(2),
        f4: Number(result.plantf4).toFixed(2),
        f5: Number(result.plantf5).toFixed(2),
        f6: Number(result.plantf6).toFixed(2),
        f7: Number(result.plantf7).toFixed(2),
        f8: Number(result.plantf8).toFixed(2),
      }

      this.plantCalculationData = { eRow: plantERow, fRow: plantFRow }
      const supplierERow = {
        e1: Number(result.supe1).toFixed(2),
        e2: Number(result.supe2).toFixed(2),
        e3: Number(result.supe3).toFixed(2),
        e4: Number(result.supe4).toFixed(2),
        e5: Number(result.supe5).toFixed(2),
        e6: Number(result.supe6).toFixed(2),
        e7: Number(result.supe7).toFixed(2),
        e8: Number(result.supe8).toFixed(2),
      }

      const supplierFRow = {
        f1: Number(result.supf1).toFixed(2),
        f2: Number(result.supf2).toFixed(2),
        f3: Number(result.supf3).toFixed(2),
        f4: Number(result.supf4).toFixed(2),
        f5: Number(result.supf5).toFixed(2),
        f6: Number(result.supf6).toFixed(2),
        f7: Number(result.supf7).toFixed(2),
        f8: Number(result.supf8).toFixed(2),
      }

      this.totalRow = {
        t1: (Number(plantERow.e1) + Number(plantFRow.f1) + Number(supplierERow.e1) + Number(supplierFRow.f1)).toFixed(2),
        t2: (Number(plantERow.e2) + Number(plantFRow.f2) + Number(supplierERow.e2) + Number(supplierFRow.f2)).toFixed(2),
        t3: (Number(plantERow.e3) + Number(plantFRow.f3) + Number(supplierERow.e3) + Number(supplierFRow.f3)).toFixed(2),
        t4: (Number(plantERow.e4) + Number(plantFRow.f4) + Number(supplierERow.e4) + Number(supplierFRow.f4)).toFixed(2),
        t5: (Number(plantERow.e5) + Number(plantFRow.f5) + Number(supplierERow.e5) + Number(supplierFRow.f5)).toFixed(2),
        t6: (Number(plantERow.e6) + Number(plantFRow.f6) + Number(supplierERow.e6) + Number(supplierFRow.f6)).toFixed(2),
        t7: (Number(plantERow.e7) + Number(plantFRow.f7) + Number(supplierERow.e7) + Number(supplierFRow.f7)).toFixed(2),
        t8: (Number(plantERow.e8) + Number(plantFRow.f8) + Number(supplierERow.e8) + Number(supplierFRow.f8)).toFixed(2),
      }

      this.supplierCalculationData = {
        eRow: supplierERow,
        fRow: supplierFRow,
      }
    }

    if (result2) {
      if (flag === 0) {
        this.otherRow = {
          o1: Number(result2.other1).toFixed(2),
          o2: Number(result2.other2).toFixed(2),
          o3: Number(result2.other3).toFixed(2),
          o4: Number(result2.other4).toFixed(2),
          o5: Number(result2.other5).toFixed(2),
          o6: Number(result2.other6).toFixed(2),
          o7: Number(result2.other7).toFixed(2),
          o8: Number(result2.other8).toFixed(2),
        }
      } else if (flag === 1) {
        this.otherRow = {
          o1: !isNaN(Number(this.otherRow.o1)) ? Number(this.otherRow.o1).toFixed(2) : 0,
          o2: !isNaN(Number(this.otherRow.o2)) ? Number(this.otherRow.o2).toFixed(2) : 0,
          o3: !isNaN(Number(this.otherRow.o3)) ? Number(this.otherRow.o3).toFixed(2) : 0,
          o4: !isNaN(Number(this.otherRow.o4)) ? Number(this.otherRow.o4).toFixed(2) : 0,
          o5: !isNaN(Number(this.otherRow.o5)) ? Number(this.otherRow.o5).toFixed(2) : 0,
          o6: !isNaN(Number(this.otherRow.o6)) ? Number(this.otherRow.o6).toFixed(2) : 0,
          o7: !isNaN(Number(this.otherRow.o7)) ? Number(this.otherRow.o7).toFixed(2) : 0,
          o8: !isNaN(Number(this.otherRow.o8)) ? Number(this.otherRow.o8).toFixed(2) : 0
        }
      }

      this.totalRow = {
        t1: (Number(this.totalRow.t1) + Number(this.otherRow.o1)).toFixed(2),
        t2: (Number(this.totalRow.t2) + Number(this.otherRow.o2)).toFixed(2),
        t3: (Number(this.totalRow.t3) + Number(this.otherRow.o3)).toFixed(2),
        t4: (Number(this.totalRow.t4) + Number(this.otherRow.o4)).toFixed(2),
        t5: (Number(this.totalRow.t5) + Number(this.otherRow.o5)).toFixed(2),
        t6: (Number(this.totalRow.t6) + Number(this.otherRow.o6)).toFixed(2),
        t7: (Number(this.totalRow.t7) + Number(this.otherRow.o7)).toFixed(2),
        t8: (Number(this.totalRow.t8) + Number(this.otherRow.o8)).toFixed(2),
      }
    } else {
      if (flag === 1) {
        this.otherRow = {
          o1: !isNaN(Number(this.otherRow.o1)) ? Number(this.otherRow.o1).toFixed(2) : 0,
          o2: !isNaN(Number(this.otherRow.o2)) ? Number(this.otherRow.o2).toFixed(2) : 0,
          o3: !isNaN(Number(this.otherRow.o3)) ? Number(this.otherRow.o3).toFixed(2) : 0,
          o4: !isNaN(Number(this.otherRow.o4)) ? Number(this.otherRow.o4).toFixed(2) : 0,
          o5: !isNaN(Number(this.otherRow.o5)) ? Number(this.otherRow.o5).toFixed(2) : 0,
          o6: !isNaN(Number(this.otherRow.o6)) ? Number(this.otherRow.o6).toFixed(2) : 0,
          o7: !isNaN(Number(this.otherRow.o7)) ? Number(this.otherRow.o7).toFixed(2) : 0,
          o8: !isNaN(Number(this.otherRow.o8)) ? Number(this.otherRow.o8).toFixed(2) : 0
        }
        this.totalRow = {
          t1: (Number(this.totalRow.t1) + Number(this.otherRow.o1)).toFixed(2),
          t2: (Number(this.totalRow.t2) + Number(this.otherRow.o2)).toFixed(2),
          t3: (Number(this.totalRow.t3) + Number(this.otherRow.o3)).toFixed(2),
          t4: (Number(this.totalRow.t4) + Number(this.otherRow.o4)).toFixed(2),
          t5: (Number(this.totalRow.t5) + Number(this.otherRow.o5)).toFixed(2),
          t6: (Number(this.totalRow.t6) + Number(this.otherRow.o6)).toFixed(2),
          t7: (Number(this.totalRow.t7) + Number(this.otherRow.o7)).toFixed(2),
          t8: (Number(this.totalRow.t8) + Number(this.otherRow.o8)).toFixed(2),
        }
      }
    }

    this.spinner.stop()
  }

  updateCalculation() {
    this.spinner.start()

    const others: number[] = Object.values(this.otherRow)

    const body = {
      PlantCode: this.plantCode,
      SupplierCode: this.supplierCode,
      Others: others,
    }

    this.supplierPlantService.updateCalculation(body).subscribe({
      next: (res: any) => {
        if (res?.status === true) {
          this.dialogRef.close({ success: true })
        } else if (res?.status === false) {
          this.errorList = res?.msg
        }
        this.spinner?.stop()
      },
      error: (_err: any) => {
        this.spinner.stop()
        this.errorList = _err.error?.error?.name
      },
      complete: () => {
        this.spinner.stop()
      },
    })
  }

  errorHandler(_err: any) {
    this.spinner.stop()
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: params })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 137])
  }
}
