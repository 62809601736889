import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import * as fileSaver from 'file-saver-es';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service';

@Component({
  selector: 'app-download-ringi-btn-cell-render',
  template: `
    <button [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'"
    class="vpacs-btn-table"
    style="width:60px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnClickedHandler($event)">RINGI</button>
  `,
})

export class DownloadRingiBtnCellRender implements ICellRendererAngularComp {
  dialogRef: any;
  res: any;

  constructor(
    private readonly router: Router, 
    private readonly route: ActivatedRoute,
    public message: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly ringiListService:RingiListService,
    private readonly spinnerService: NgxUiLoaderService,
    ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  disableFlag = false;
  dullFlag = false;
  agInit(params: any): void {
    this.params = params;   
    if (params.data.ringitype === "2" || params.data.ringitype === "3" ) {
      this.disableFlag = true;
      this.dullFlag = true;
    } 
  }
  btnClickedHandler(_event: any) {
    const downloadRingi = {
      "RingiNumber": this.params.data.ringinumber,
      "PlantCode": this.params.data.plantcode,    
      "PlantName":this.params.data.plantname,   
      "ModelCode":this.params.data.modelcode
    }
    this.spinnerService.start();
    this.ringiListService.downloadRingi(downloadRingi).subscribe({
      next: (res: any) => {
        const RingiNumber = this.params.data.ringinumber;
        const toConvert = res.data
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
        setTimeout(() => {
          fileSaver.saveAs(file,  RingiNumber +'_r' );
        });
      },
      error: () => {
        this.openMessageBox('Error downloading the file', 'error')
      },
      complete: () => {
        this.spinnerService.stop();
      }
    })

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }
}




