<div class="popup-container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">{{headerMessage}} </div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <div class="mat-typography content">
    <div id='err' class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
      <span  >{{errorList}}</span>
    </div>
      <div class="d-flex">
          <div class="col-11">
            <div class="img-cont col d-flex px-3 gap-2">
              <div class="left col-6 align-items-center">
                
                <label
                  for="file-upload"
                  class="vpacs-btn-table my-1"
                  style="width: 125px"
                >
                  CHOOSE FILE
                  <input
                    type="file"
                    id="file-upload"
                    accept=".xlsx"
                    style="display: none"
                    (change)="handleUpload($event)"
                 
                  />
                </label>
                <div class="row">
                  <div class="col-12">
                    <small *ngIf="errorMessage">{{ errorMessage }}</small>
                  </div>
                  <div class="col-12" *ngIf="!errorMessage">
                    <span>{{ fileName }}</span>
                    </div>
                    <div class="col-12">
                    <span>{{ filemessage }}</span>
                  </div>
                </div>
                
              </div>
            </div>

          </div>
        </div>
        
  </div>

  <button class="close black-button" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>
  <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2" >
      <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
          <button class="submit black-button me-3 roboto-bold"  (click)="nextBtn()"  >IMPORT</button> 
  </div>  
</div>