<div class="dialog-wrapper">
<div class="page-title p-2">Select Part</div>
<div class="content py-2">
    <div class="d-flex flex-wrap align-items-center gap-2 px-2" [formGroup]="searchPart" (keyup.enter)="onSearch()">
        <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
            <label for="plant">Plant</label>
            <mat-select formControlName="plant" class="mat-sel pt-2 " style="width: 120px;" disableOptionCentering>
                <mat-option *ngFor="let x of plantNames" [value]="x.value">{{x.value}}</mat-option>
            </mat-select>
        </div>
        <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
            <label for="model" >Model</label>
            <input type= "text" id="model" formControlName="model" style="width: 120px;"/>
        </div>
        <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" >Part</label>
            <input type= "text" id="part" formControlName="part" style="width: 120px;"/>
        </div>
        <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
            <label for="type">Type</label>
            <mat-select formControlName="type" class="mat-sel pt-2 " disableOptionCentering>
                <mat-option [value]="'on_develop'">On Developing</mat-option>
                <mat-option [value]="'product'">Product</mat-option>
            </mat-select>
        </div>
        <div class="d-grid align-items-center col-lg-2 col-md-6 col-12">
            <div>&nbsp;</div>
            <button class="black-button" (click)="onSearch()" style="width: 120px;">Search</button>
        </div>
    </div>
    <div class="grid mt-2 px-2">
        <div>
            <ag-grid-angular
            style="width: 100%;height: 300px"
                class="ag-theme-balham"
                (gridReady)="onGridReady($event);" 
                (window:resize)="sizeToFit()" 
                [rowHeight]="rowHeight"
                [animateRows]="true"
                [defaultColDef]="defaultColDef" 
                [paginationPageSize]="paginationPageSize"
                [columnDefs]="columnDefs" 
                [rowData]="displayRowData"
                [suppressCellSelection]="true"
                [pagination]="true" 
                [suppressPaginationPanel] = "true"
                [headerHeight]= '50'
                (paginationChanged)="onPaginationChanged()"
                [overlayLoadingTemplate]="overlayLoadingTemplate"
            >       
            </ag-grid-angular>
        </div>
        <div class="px-2 d-flex pagination align-items-center justify-content-between" *ngIf = "rowCount > 0">
            <div >
                <div>
                    <label class="page-drpdwn-label mx-2">Records/Page: </label>
                    <select style="height: 30px;" (change)="onPageSizeChanged($event)">
                        <option value="5">5</option>
                        <option value="10" selected>10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value={{rowCount}}>ALL</option>
                    </select>
                </div>
            </div>
            <div class="d-flex align-items-center gap-3">
                <div>Viewing Records</div>
                <mat-icon (click)="goToFirstPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage() === true,'enable_icon':getCurrentPage()!== true}"
                    >first_page</mat-icon>
                <mat-icon (click)="goToPreviousPage()" class="text-primary"
                    [ngClass]="{'disable_icon':getCurrentPage() === true,'enable_icon':getCurrentPage() !== true}"
                    >fast_rewind</mat-icon>
                <div>
                    (
                    <span class="value" id="lbCurrentPage"></span>-
                    <span class="value" id="lbTotalPages"></span>)
                    <span>OF {{rowCount}}</span>
                </div>
                <mat-icon (click)="goToNexpage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage() === true,'enable_icon':isLastPage() !== true}"                
                    >fast_forward</mat-icon>
                <mat-icon (click)="goToLastPage()" class="text-primary"
                    [ngClass]="{'disable_icon':isLastPage() === true,'enable_icon':isLastPage() !== true}"
                    >last_page</mat-icon>
            </div>
        </div>
    </div>
    <button (click)="closeDialog()" class="black-button close-btn">&times;</button>
</div>
</div>