import { Component, OnInit } from '@angular/core'
import { RcafCostComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-cost/rcaf-cost.component'
import { RcafUnitComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-unit/rcaf-unit.component'
import { RcafQuantityComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-quantity/rcaf-quantity.component'
import { GridOptions } from 'ag-grid-community'
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { DatePipe } from '@angular/common'
import { MatDialog } from '@angular/material/dialog'
import { SelectPackageCostComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/select-package-cost/select-package-cost.component'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConstantsService } from 'src/app/services/constant.service'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-trial-order',
  templateUrl: './trial-order.component.html',
  styleUrls: ['./trial-order.component.scss'],
})
export class TrialOrderComponent implements OnInit {
  panelOpenState = true
  crafDetails: any
  shortName: any
  partNumber: any
  supplier: any
  namc: any
  bgColor: any[] = []
  fontColor: any[] = []
  fontColorList: any[] = []
  bgColorList: any[] = []
  labelInfo: any[] = []

  gridApi: any
  defaultColDef: {}
  public displayRowData: any
  totalCost: any;
  public columnDefs: any[] = []
  sideHeaders: any[] = []
  rowClassRules: any
  public domLayout: any = ''
  public gridOptions: GridOptions = {}
  public rowHeight = 40

  trialDueDate: any = ''
  trialPlant: any
  trialPlantList: any[] = []
  dockList: any[] = []
  selectedDock: any
  attnList: any[] = []
  selectedATTN: any
  ringNumberList: any[] = []
  selectedRingiNm: any
  htmlData: any
  plantCode: any
  familyCode: any
  modelCode: any
  trialOrderDetails: any
  supplierCode: any
  pkgstartdate: any
  paramData: any
  txtPackageQty: any
  tblCalcResp: any
  txtLabelTotalCost: any
  txtOtherCost: any
  calcUnitCost: any
  tdCalcContainer: any
  tdCalcDunnage1: any
  tdCalcDunnage2: any
  tdCalcTopCap: any
  tdCalcPallet: any
  tdCalcStretchWrapStrap: any
  tdCalcKanbanCardHolder: any
  tdCalcOther: any
  txtLabelQty: any
  txtLabelUnitCost: any
  txtContainerQty: any
  txtContainerUnitCost: any
  txtContainerTotalCost: any
  txtDunnage1Qty: any
  txtDunnage1UnitCost: any
  txtDunnage1TotalCost: any
  txtDunnage2Qty: any
  txtDunnage2UnitCost: any
  txtDunnage2TotalCost: any
  txtTopCapUnitCost: any
  txtPalletUnitCost: any
  txtStretchWrapStrapUnitCost: any
  txtKanbanCardHolderUnitCost: any
  txtOtherUnitCost: any
  txtTopCapTotalCost: any
  txtPalletTotalCost: any
  txtStretchWrapStrapTotalCost: any
  txtKanbanCardHolderTotalCost: any
  txtOtherTotalCost: any
  txtStretchWrapStrapQty: any
  txtPalletQty: any
  txtTopCapQty: any
  txtKanbanCardHolderQty: any
  txtOtherQty: any
  taExtComment: any
  mode: any
  details: any
  errorMsg: any
  qtyOfPart: any
  params: any
  spCode: any

  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly spinner: NgxUiLoaderService,
    private readonly dialog: MatDialog,
    private readonly message: MatSnackBar,
    public datepipe: DatePipe,
    private readonly constants: ConstantsService,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    }

    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.spinner.start()
    this.labelInfo = [
      'Background Color',
      'Font Color',
      'NAMC Code',
      'Supplier Code',
      'Short Name',
    ]
    this.sideHeaders = [
      'CONTAINER',
      'DUNNAGE 1',
      'DUNNAGE 2',
      'TOP CAP',
      'PALLET',
      'STRETCH WRAP/ STRAP',
      'KANBAN CARD HOLDER',
      'OTHER PACKAGE',
      'LABEL',
      'OTHER',
    ]
    this.bgColorList = [
      { id: 1, label: 'Blue (Process Blue)' },
      { id: 2, label: 'Red (Red 187)' },
      { id: 3, label: 'Purple (Pantone Purple)' },
      { id: 4, label: 'White (White)' },
      { id: 5, label: 'Yellow (Pantone Yellow)' },
      { id: 6, label: 'Pink (Pantone 223C)' },
      { id: 7, label: 'Lt Grey (Pantone 441C)' },
      { id: 8, label: 'Orange (Orange 144)' },
      { id: 9, label: 'Black (Black)' },
    ]

    this.fontColorList = [
      { id: 1, label: 'White' },
      { id: 2, label: 'Black' },
    ]

    this.columnDefs = [
      {
        headerName: '',
        autoHeight: true,
        field: '',
        minWidth: 170,
        maxWidth: 170,
        pinned: 'left',
        cellRenderer: (params: any) => {
          const index = this.sideHeaders[params.node.rowIndex]
          return `<span>${index}</span>`
        },
      },
      {
        headerName: 'PACKAGE CODE',
        autoHeight: true,
        field: 'packagecode',
        cellRenderer: (params: any) => {
          if (params.data.rowHeader !== '') {
            return `<span>${params.data.packagecode}</span>`
          } else {
            return ''
          }
        },
      },
      {
        headerName: 'QUANTITY',
        autoHeight: true,
        field: 'qty',
        cellRendererFramework: RcafQuantityComponent,
      },
      {
        headerName: 'UNIT$(US)',
        autoHeight: true,
        field: 'unitcost',
        cellRendererFramework: RcafUnitComponent,
        cellRendererParams: {
          clicked: (_colId: string, _data: any) => {
            this.calcPackage()
          },
        },
      },
      {
        headerName: 'COST',
        autoHeight: true,
        field: 'cost',
        cellRendererFramework: RcafCostComponent,
      },
    ]

    this.displayRowData = []
    this.paramData = JSON.parse(sessionStorage.getItem('trialOrder') || '{}')
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spCode = res?.info?.specialistcode;
        res && this.loadTrialOrder()
      },
      error: (_err: any) => {
        this.spinner.stop()
      },
    })
  }

  loadTrialOrder() {
    this.errorMsg = []
    const date = 'yyyy-MM-dd';
    const payload = {
      url_plant: this.paramData.plantcode,
      url_family: this.paramData?.familycode ?? (this.paramData?.Family ?? ''),
      url_model: this.paramData?.modelcode ?? (this.paramData?.model ?? ''),
      url_part: this.paramData.partnumber,
      url_sup: this.paramData.suppliercode,
      fromdate: this.paramData.pkgstartdate ?? '01/01/0001',
      sessionplantcode: JSON.parse(sessionStorage.getItem('plantCode') || ''),
      mode: (this.paramData.authnumber === null || this.paramData.authnumber === '') ? 'ADD' : 'UPDATE',
      sessionspecialistcode: JSON.parse(
        sessionStorage.getItem('specialistCode') || '',
      ),
      selTrialPlant_SelectedIndex_plantcodes: [],
      authnumber: (this.paramData.authnumber === null || this.paramData.authnumber === '') ? '' : this.paramData.authnumber
    }
    this.trackingListService.trialOrderLoad(payload).subscribe({
      next: (res: any) => {
        if (res.status === 'FAILURE') {
          this.errorMsg = res?.message;
          window.scrollTo(0, 0);
        }
        this.trialOrderDetails = res
        this.trialDueDate = this.datepipe.transform(res.data.createHtml_selRingiNumber_Response?.txtTrialDue_Value, date)
        this.htmlData =
          res.data.createHtml_tblInfo_Response?.createHtml_tblInfo_displayData
        this.qtyOfPart = this.htmlData?.txtTrialQty_value
        this.details = this.trialOrderDetails?.data?.createHtml_tblCalc_Response
        this.calcUnitCost = this.details?.createHtmlPackage_createHtml_tblCalc
        this.tblCalcResp = this.calcUnitCost?.createHtmlPackage_DispalyObjects
        
        this.txtLabelQty = this.errorMsg?.length > 0 ? '' : (this.details?.txtLabelQty_Value ?? '')
        this.txtLabelUnitCost = this.errorMsg?.length > 0 ? '' : (this.details?.createHtmlPackage_createHtml_tblCalc.txtLabelUnitCost_Value ?? '')
        this.txtOtherCost = this.errorMsg?.length > 0 ? '' : (this.details?.txtOtherCost_Value ?? '')
        this.taExtComment = this.details?.taExtComment_Value ?? ''

        this.mode = this.details?.mode
        if (this.mode === 'ADD') {
          //TRIAL PLANT
          this.txtPackageQty = this.trialOrderDetails?.data?.createHtml_tblCalc_Response?.txtPackageQty_Value

          this.trialPlantList =
            res.data.createHtml_selRingiNumber_Response.createHtml_selTrialPlant.selTrialPlant_Items_dropDown
          const index =
            res.data.createHtml_selRingiNumber_Response
              .createHtml_selTrialPlant.selTrialPlant_SelectedIndex
          if (index > -1) {
            const selectedObj = this.trialPlantList[index]
            this.trialPlant = selectedObj?.plantCode
          }

          //DOCK
          this.dockList =
            res.data.createHtml_selRingiNumber_Response.createHtml_selTrialDock.selDock_Items
          const dockIndex =
            res.data.createHtml_selRingiNumber_Response
              .createHtml_selTrialDock
              .selDock_Items_Items_dropDown_SelectedIndex
          if (dockIndex > -1) {
            const selectedObj = this.dockList[dockIndex]
            this.selectedDock = selectedObj?.dockCode
          }

          //ATTN - SPECIALIST
          this.attnList =
            res.data.createHtml_selRingiNumber_Response.createHtml_selTrialATTN.selTrialATTN_Items_dropDown
          const attnIndex =
            res.data.createHtml_selRingiNumber_Response
              .createHtml_selTrialATTN
              .selTrialATTN_Items_dropDown_SelectedIndex
          if (attnIndex > -1) {
            const selectedObj = this.attnList[attnIndex]
            this.selectedATTN = selectedObj?.SpecialistCode
          }

          //RINGI
          this.ringNumberList = res.data.createHtml_selRingiNumber_Response.createHtml_selRingiNumber.selRingi_Items_dropDown
          const ringiNumberIndex =
            res.data.createHtml_selRingiNumber_Response
              .createHtml_selRingiNumber.selRingi_SelectedIndex
          if (ringiNumberIndex > -1) {
            const selectedObj = this.ringNumberList[ringiNumberIndex]
            this.selectedRingiNm = selectedObj?.RingiNumber
          }

          //Binding calc

          this.tdCalcContainer = this.tblCalcResp?.tdCalcContainer_InnerHtml_encodeBr
          this.tdCalcDunnage1 = this.tblCalcResp?.tdCalcDunnage1_InnerHtml_encodeBr
          this.tdCalcDunnage2 = this.tblCalcResp?.tdCalcDunnage2_InnerHtml_encodeBr
          this.tdCalcTopCap = this.tblCalcResp?.tdCalcTopCap_InnerHtml_encodeBr
          this.tdCalcPallet = this.tblCalcResp?.tdCalcPallet_InnerHtml_encodeBr
          this.tdCalcStretchWrapStrap = this.tblCalcResp?.tdCalcStretchWrapStrap_InnerHtml_encodeBr
          this.tdCalcKanbanCardHolder = this.tblCalcResp?.tdCalcKanbanCardHolder_InnerHtml_encodeBr
          this.tdCalcOther = this.tblCalcResp?.tdCalcOther_InnerHtml_encodeBr
          if (this.errorMsg?.length === 0) {
            this.txtContainerUnitCost = this.calcUnitCost?.contcostObj?.txtContainerUnitCost
            this.txtDunnage1UnitCost = this.calcUnitCost?.dun1costObj?.txtDunnage1UnitCost
            this.txtDunnage2UnitCost = this.calcUnitCost?.dun2costObj?.txtDunnage2UnitCost
            this.txtTopCapUnitCost = this.calcUnitCost?.topcostObj?.txtTopCapUnitCost
            this.txtPalletUnitCost = this.calcUnitCost?.palcostObj?.txtPalletUnitCost
            this.txtStretchWrapStrapUnitCost = this.calcUnitCost?.strcostObj?.txtStretchWrapStrapUnitCost
            this.txtKanbanCardHolderUnitCost = this.calcUnitCost?.khcostObj?.txtKanbanCardHolderUnitCost
            this.txtOtherUnitCost = this.calcUnitCost?.othcostObj?.txtOtherUnitCost
            this.calcTotalCost()
          }
        } else {
          // MODE === UPDATE

          //TRIAL
          this.txtPackageQty = this.trialOrderDetails?.data?.createHtml_selRingiNumber_Response?.txtPackageQty_Value

          this.trialPlantList =
            res.data.createHtml_selRingiNumber_Response?.m_plant_createData?.selTrialPlant_Items_dropDown
          const index =
            res.data.createHtml_selRingiNumber_Response?.m_plant_createData
              ?.selTrialPlant_SelectedIndex
          if (index > -1) {
            const selectedObj = this.trialPlantList[index]
            this.trialPlant = selectedObj?.plantCode
          }

          //DOCK
          this.dockList =
            res.data.createHtml_selRingiNumber_Response?.m_dock_createData?.selDock_Items
          const dockIndex =
            res.data.createHtml_selRingiNumber_Response?.m_dock_createData
              ?.selDock_Items_Items_dropDown_SelectedIndex
          if (dockIndex > -1) {
            const selectedObj = this.dockList[dockIndex]
            this.selectedDock = selectedObj?.dockCode
          }

          //ATTN - SPECIALIST
          this.attnList =
            res.data.createHtml_selRingiNumber_Response?.m_specialist_createData?.selTrialATTN_Items_dropDown
          const attnIndex =
            res.data.createHtml_selRingiNumber_Response
              ?.m_specialist_createData
              ?.selTrialATTN_Items_dropDown_SelectedIndex
          if (attnIndex > -1) {
            const selectedObj = this.attnList[attnIndex]
            this.selectedATTN = selectedObj?.SpecialistCode
          }

          //RINGI
          this.ringNumberList =
            res.data.createHtml_selRingiNumber_Response?.createHtml_selRingiNumber?.selRingi_Items_dropDown
          const ringiNumberIndex =
            res.data.createHtml_selRingiNumber_Response
              ?.createHtml_selRingiNumber?.selRingi_SelectedIndex
          if (ringiNumberIndex > -1) {
            const selectedObj = this.ringNumberList[ringiNumberIndex]
            this.selectedRingiNm = selectedObj?.RingiNumber
          }

          const dv = this.trialOrderDetails?.data?.createHtml_tblCalc_Response
            ?.dv1Forloop?.result
          if (dv !== undefined || dv?.length > 0) {
            for (let i = 0; i < dv.length; i++) {
              const row = this.nullInt(dv[i]['rownumber'])
              if (
                this.nullStr(dv[i]['packagecode']) !== '-' &&
                this.nullStr(dv[i]['packagecode']) !== '*'
              ) {
                if (row === 1) {
                  this.tdCalcContainer = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 2) {
                  this.tdCalcDunnage1 = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 3) {
                  this.tdCalcDunnage2 = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 4) {
                  this.tdCalcTopCap = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 5) {
                  this.tdCalcPallet = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 6) {
                  this.tdCalcStretchWrapStrap = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 7) {
                  this.tdCalcKanbanCardHolder = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                } else if (row === 8) {
                  this.tdCalcOther = this.changeNullData(
                    this.nullStr(dv[i]['packagecode']),
                  )
                }
              }

              if (row === 1) {
                this.txtContainerQty = this.nullInt(dv[i]['qty']).toString()
              } else if (row === 2) {
                this.txtDunnage1Qty = this.nullInt(dv[i]['qty']).toString()
              } else if (row === 3) {
                this.txtDunnage2Qty = this.nullInt(dv[i]['qty']).toString()
              } else if (row === 4) {
                this.txtTopCapQty = this.nullInt(dv[i]['qty']).toString()
              } else if (row === 5) {
                this.txtPalletQty = this.nullInt(dv[i]['qty']).toString()
              } else if (row === 6) {
                this.txtStretchWrapStrapQty = this.nullInt(
                  dv[i]['qty'],
                ).toString()
              } else if (row === 7) {
                this.txtKanbanCardHolderQty = this.nullInt(
                  dv[i]['qty'],
                ).toString()
              } else if (row === 8) {
                this.txtOtherQty = this.nullInt(dv[i]['qty']).toString()
              }

              const rate = this.details?.getExchangeRate
                ? Number(this.details?.getExchangeRate)
                : 0

              if (row === 1) {
                this.txtContainerUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 2) {
                this.txtDunnage1UnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 3) {
                this.txtDunnage2UnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 4) {
                this.txtTopCapUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 5) {
                this.txtPalletUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 6) {
                this.txtStretchWrapStrapUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 7) {
                this.txtKanbanCardHolderUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              } else if (row === 8) {
                this.txtOtherUnitCost = this.formatCost(
                  rate * this.nullDouble(dv[i]['unitcost']),
                )
              }

              if (row === 1) {
                this.txtPackageQty = this.nullInt(dv[i]['qty']).toString()
              }
            }
          }
          this.calcTotalCost()
        }

        this.spinner.stop()
      },
      error: (err: any) => {
        this.spinner.stop()
        this.errorMsg = err.error?.error?.message
        window.scrollTo(0, 0);
      },
    })
  }

  formatCost(cost: any): any {
    return cost.toFixed(2).toString()
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === '' || obj === undefined) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj)
        } else {
          return parseInt(obj)
        }
      } catch {
        return 0
      }
    }
  }

  changeNullData(data: string) {
    if (data === null || data.length === 0) {
      return ' - '
    } else {
      return data
    }
  }

  onDisplayPPF() {
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
    if (plantCode === 'SERVICE') {
      sessionStorage.setItem('partnumber', this.paramData.partnumber);
      this.router.navigate(['/tracking-list/trial-order/serviceparts-pdf', this.paramData?.ppfcode, this.paramData?.ppfver, 1, 2])
    } else if (plantCode === 'ASSY') {
      sessionStorage.setItem('partnumber', this.paramData.partnumber);
      this.router.navigate(['/tracking-list/trial-order/accessoryParts-pdf', this.paramData?.ppfcode, this.paramData?.ppfver, 1, 2])
    }
    else {
      sessionStorage.setItem('ppfcloseFromTracking', '0')
      window.open(`/tracking-list/trial-order/ppf/${this.paramData?.ppfcode}/${this.paramData?.ppfver}/1/2`, '_blank');
      sessionStorage.setItem('ppfcloseFromTracking', '1')
    }
  }

  onSubmit() {
    this.spinner.start()

    this.errorMsg = []
    if (Math.sign(Number(this.totalCost)) === 0) {
      this.errorMsg.push('This authorization has no cost.  Zero cost authorizations are not allowed.');
      this.spinner.stop()
    }
    else{
      const trialObj = {
        PkgStartDate: this.datepipe.transform(this.paramData.pkgstartdate, 'MM/dd/YYYY', 'UTC'),
        PlantCode: this.paramData.plantcode,
        SupplierCode: this.paramData.suppliercode,
        ModelCode: this.paramData.modelcode,
        PartNumber: this.paramData.partnumber,
        RingiNumber: this.selectedRingiNm === '--Select--' ? '' : this.selectedRingiNm,
        TrialDue: this.trialDueDate,
        Mode: (this.paramData.authnumber === null || this.paramData.authnumber === '') ? 'ADD' : 'UPDATE',
        TrialQty: Number(this.qtyOfPart),
        SpecialistCode: this.spCode,
        PackageQty: Number(this.txtPackageQty),
        TotalCost: this.totalCost ?? '',
        ContainerCode: this.tdCalcContainer ?? '',
        Dunnage1Code: this.tdCalcDunnage1 ?? '',
        Dunnage2Code: this.tdCalcDunnage2 ?? '',
        TopCapCode: this.tdCalcTopCap ?? '',
        PalletCode: this.tdCalcPallet ?? '',
        StretchWrapStrapCode: this.tdCalcStretchWrapStrap ?? '',
        KanbanCardHolderCode: this.tdCalcKanbanCardHolder ?? '',
        OtherCode: this.tdCalcOther ?? '',
        ContainerQty: this.txtContainerQty ?? '',
        Dunnage1Qty: this.txtDunnage1Qty ?? '',
        Dunnage2Qty: this.txtDunnage2Qty ?? '',
        TopCapQty: this.txtTopCapQty ?? '',
        PalletQty: this.txtPalletQty ?? '',
        StretchWrapStrapQty: this.txtStretchWrapStrapQty ?? '',
        KanbanCardHolderQty: this.txtKanbanCardHolderQty ?? '',
        LabelQty: this.txtLabelQty ?? '',
        OtherQty: this.txtOtherQty ?? '',
        ContainerUnitCost: this.txtContainerUnitCost ?? '',
        Dunnage1UnitCost: this.txtDunnage1UnitCost ?? '',
        Dunnage2UnitCost: this.txtDunnage2UnitCost ?? '',
        TopCapUnitCost: this.txtTopCapUnitCost ?? '',
        PalletUnitCost: this.txtPalletUnitCost ?? '',
        StretchWrapStrapUnitCost: this.txtStretchWrapStrapUnitCost ?? '',
        KanbanCardHolderUnitCost: this.txtKanbanCardHolderUnitCost ?? '',
        OtherUnitCost: this.txtOtherUnitCost ?? '',
        LabelUnitCost: this.txtLabelUnitCost ?? '',
        OtherCost: this.txtOtherCost ?? '',
        ExtComment: this.taExtComment ?? '',
        selTrialDock: this.selectedDock ?? '',
        selTrialPlant: this.trialPlant ?? '',
        selTrialATTN: this.selectedATTN ?? '',
        url_auth: (this.paramData.authnumber === null || this.paramData.authnumber === '') ? '' : this.paramData.authnumber
      }
      this.trackingListService.trialOrderAuthorize(trialObj).subscribe({
        next: (res: any) => {
          if (res.status === 'SUCCESS') {
            this.goToPrev();
            this.openMessageBox(res.message, 'success')
          } else if (res.status === 'FAILURE') {
            this.errorMsg = res.message
            window.scrollTo(0, 0);
          }
          this.spinner.stop()
        },
        error: (err: any) => {
          this.spinner.stop()
          this.errorMsg = err.error?.error?.message
          window.scrollTo(0, 0);
        },
      })
    }
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    })
  }

  onComparePPF() {
    const params = {
      ppfcode1: this.paramData?.ppfcode,
      ppfver1: this.trialOrderDetails?.data?.loadParamResult?.url_ver,
      ppfcode2: this.paramData?.ppfcode,
      ppfver2: -1,
    }
    sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
    if(this.router.url.includes('/auth-list/trial-order')){
      this.router.navigateByUrl('/auth-list/trial-order/concept-compare')
    }else {
      this.router.navigateByUrl('/tracking-list/trial-order/concept-compare')
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDomLayout('autoHeight')
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  nullInt(obj: any) {
    if (obj == null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  calcPackage() {
    this.totalCost = '';
    const pkgQty = Number(this.txtPackageQty)
    const checkVal = this.tblCalcResp
    const qppVal = this.calcUnitCost

    if (checkVal.hidcontainercode_Value) {
      this.txtContainerQty = pkgQty
      this.calcPackageCost(
        this.txtContainerQty,
        this.txtContainerUnitCost,
        this.txtContainerTotalCost,
      )
    }
    if (checkVal.hidDunnage1Code_Value) {
      this.txtDunnage1Qty = this.errorMsg?.length > 0 ? 0 : Math.ceil(
        pkgQty * Number(qppVal?.hidDunnage1QPP_Value),
      )
      this.calcPackageCost(
        this.txtDunnage1Qty,
        this.txtDunnage1UnitCost,
        this.txtDunnage1TotalCost,
      )
    }
    if (checkVal.hidDunnage2Code_Value) {
      this.txtDunnage2Qty = this.errorMsg?.length > 0 ? 0 : Math.ceil(
        pkgQty * Number(qppVal?.hidDunnage2QPP_Value),
      )
      this.calcPackageCost(
        this.txtDunnage2Qty,
        this.txtDunnage2UnitCost,
        this.txtDunnage2TotalCost,
      )
    }
    if (checkVal.hidTopCapCode_Value) {
      this.txtTopCapQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(pkgQty * Number(qppVal?.hidTopCapQPP_Value))
      this.calcPackageCost(
        this.txtTopCapQty,
        this.txtTopCapUnitCost,
        this.txtTopCapTotalCost,
      )
    }
    if (checkVal.hidPalletCode_Value) {
      this.txtPalletQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(pkgQty * Number(qppVal?.hidPalletQPP_Value))
      this.calcPackageCost(
        this.txtPalletQty,
        this.txtPalletUnitCost,
        this.txtPalletTotalCost,
      )
    }
    if (checkVal.hidStretchWrapStrapCode_Value) {
      this.txtStretchWrapStrapQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(
        pkgQty * Number(qppVal?.hidWrapQPP_Value),
      )
      this.calcPackageCost(
        this.txtStretchWrapStrapQty,
        this.txtStretchWrapStrapUnitCost,
        this.txtStretchWrapStrapTotalCost,
      )
    }
    if (checkVal.hidKanbanCardHolderCode_Value) {
      this.txtKanbanCardHolderQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(
        pkgQty * Number(qppVal?.hidKHolderQPP_Value),
      )
      this.calcPackageCost(
        this.txtKanbanCardHolderQty,
        this.txtKanbanCardHolderUnitCost,
        this.txtKanbanCardHolderTotalCost,
      )
    }
    if (checkVal.hidOtherCode_Value) {
      this.txtOtherQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(
        pkgQty * Number(qppVal?.hidOtherPkgQPP_Value),
      )
      this.calcPackageCost(
        this.txtOtherQty,
        this.txtOtherUnitCost,
        this.txtOtherTotalCost,
      )
    }

    this.txtLabelQty = this.errorMsg?.length > 0 ? 0 : Math.ceil(pkgQty * Number(qppVal?.hidLabelQPP_Value))
    this.calcPackageCost(
      this.txtLabelQty,
      this.txtLabelUnitCost,
      this.txtLabelTotalCost,
    )
  }

  calcPackageCost(_pkgQty: any, _unitcost: any, _total: any) {
    this.calcTotalCost()
  }

  calcTotalCost() {
    this.txtContainerTotalCost =
      Number((this.nullDouble(this.txtContainerUnitCost) *
      this.nullDouble(this.txtContainerQty)).toFixed(2))
    this.txtDunnage1TotalCost =
      Number((this.nullDouble(this.txtDunnage1UnitCost) *
      this.nullDouble(this.txtDunnage1Qty)).toFixed(2))
    this.txtDunnage2TotalCost =
      Number((this.nullDouble(this.txtDunnage2UnitCost) *
      this.nullDouble(this.txtDunnage2Qty)).toFixed(2))
    this.txtTopCapTotalCost =
      Number((this.nullDouble(this.txtTopCapUnitCost) *
      this.nullDouble(this.txtTopCapQty)).toFixed(2))
    this.txtPalletTotalCost =
      Number((this.nullDouble(this.txtPalletUnitCost) *
      this.nullDouble(this.txtPalletQty)).toFixed(2))
    this.txtStretchWrapStrapTotalCost =
      Number((this.nullDouble(this.txtStretchWrapStrapUnitCost) *
      this.nullDouble(this.txtStretchWrapStrapQty)).toFixed(2))
    this.txtKanbanCardHolderTotalCost =
      Number((this.nullDouble(this.txtKanbanCardHolderUnitCost) *
      this.nullDouble(this.txtKanbanCardHolderQty)).toFixed(2))
    this.txtOtherTotalCost =
      Number((this.nullDouble(this.txtOtherUnitCost) * this.nullDouble(this.txtOtherQty)).toFixed(2))
    this.txtLabelTotalCost =
      Number((this.nullDouble(this.txtLabelUnitCost) * this.nullDouble(this.txtLabelQty)).toFixed(2))
    this.totalCost = (
      this.txtContainerTotalCost +
      this.txtDunnage1TotalCost +
      this.txtDunnage2TotalCost +
      this.txtTopCapTotalCost +
      this.txtPalletTotalCost +
      this.txtStretchWrapStrapTotalCost +
      this.txtKanbanCardHolderTotalCost +
      this.txtOtherTotalCost +
      this.txtLabelTotalCost +
      Number(this.txtOtherCost)
    ).toFixed(2)
  }

  numValidatorForm(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const enteredKey = event.key;
    if (target.value.includes('.') && enteredKey === '.') {
      event.preventDefault();
      return;
    }
    const keyArray = ['.', 'Backspace', 'ArrowLeft', 'Tab', 'ArrowRight', 'Delete']
    if (isNaN(Number(enteredKey)) && !keyArray.includes(enteredKey)) {
      event.preventDefault();
      const numericValue = parseFloat(target.value);
      target.value = isNaN(numericValue) ? '' : String(numericValue);
    }
  }

  selectPackageCost(pkgCode: any, index: any) {
    const dialogRef = this.dialog.open(SelectPackageCostComponent, {
      data: {
        packageCode: pkgCode,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === undefined) {
        return;
      }
      else if (index === 1) {
        this.txtContainerUnitCost = result.data
      } else if (index === 2) {
        this.txtDunnage1UnitCost = result.data
      } else if (index === 3) {
        this.txtDunnage2UnitCost = result.data
      } else if (index === 4) {
        this.txtTopCapUnitCost = result.data
      } else if (index === 5) {
        this.txtPalletUnitCost = result.data
      } else if (index === 6) {
        this.txtStretchWrapStrapUnitCost = result.data
      } else if (index === 7) {
        this.txtKanbanCardHolderUnitCost = result.data
      } else if (index === 8) {
        this.txtOtherUnitCost = result.data
      }
      this.calcPackage()
    })
  }

  goToPrev() {
    sessionStorage.removeItem('trialOrder')
    let uri = '';
    if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    } else if (this.router.url.includes('tracking-list')) {
      uri = '/tracking-list'
    }
    this.router.navigateByUrl(uri)
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'trialDueDate') {
        this.trialDueDate = '';
      }
    }
  }
}
