import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delay-grid',
  templateUrl: './delay-grid.component.html',
  styleUrls: ['./delay-grid.component.scss']
})
export class DelayGridComponent {
  params: any;
  step: any;
  delay: any;
  warn: any;
  good: any;
  scrflg: any;
  url: any;
  specialistCode: any;
  plantCode: any;
  isDelay = false;
  isWarn = false;
  constructor(private readonly router: Router) { }


  agInit(params: any): void {
    this.params = params;
    this.specialistCode = JSON?.parse(sessionStorage.getItem('specialistCode') || '{}');
    this.plantCode = JSON?.parse(sessionStorage.getItem('plantCode') || '{}');
    if (params?.data.rowHeader !== '') {
      if (params.rowIndex === 0) {
        this.step = 2;
      }
      else if (params.rowIndex === 1) {
        this.step = 4;
      }
      else if (params.rowIndex === 2) {
        this.step = 7;
      }
      else if (params.rowIndex === 3) {
        this.step = 9;
      }
      else if (params.rowIndex === 4) {
        this.step = 5;
      }
      else if (params.rowIndex === 5) {
        this.step = 10;
      }
      if (Number(params?.data.d) > 0) {
        this.isDelay = true;
        this.delay = 1;
        this.warn = 0;
        this.good = 0;
        this.scrflg = 1;
      }
    }
  }
  
  navigate(){
    this.router?.navigate(['/tracking-list',this.plantCode,this.specialistCode,this.delay,this.warn,this.good,this.step,this.scrflg])
  }

}
