import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
 
  selector: 'r-btn-cell-renderer',
  template: `
  <div *ngIf="this.planQty" class="d-flex gap-1">
  <input (change)="changed($event)" [disabled]="this.planQtyInput"  value="{{rcount}}"  style="width:50px;height:30px"  type="text"  [(ngModel)]= "value" id="input" />
  <button class="vpacs-btn-table"
    *ngIf="this.buttonVisible"
    style="height:30px" 
    [disabled]="this.planQtyBtn"
    (click)="btnClickedHandler($event)">R
  </button>
  </div>
  <div *ngIf="this.actualShipQty" class="d-flex gap-1">
    <input (change)="changed($event)" [disabled]="this.actualShipQtyInput" value="{{rcount}}"   style="width:50px;height:30px"  type="text"  [(ngModel)]= "value" id="input" />
    <button class="vpacs-btn-table"
      *ngIf="this.buttonVisible"
      style="height:30px" 
      [disabled]="this.actualShipQty"
      (click)="btnClickedHandler($event)">R
    </button>
  </div>
  <div *ngIf="this.actualReceiveQty" class="d-flex gap-1">
    <input (change)="changed($event)" [disabled]="this.actualReceiveQtyInput" value="{{rcount}}"  style="width:50px;height:30px"  type="text"  [(ngModel)]= "value"  id="input" />
    <button class="vpacs-btn-table"
      style="height:30px" 
      *ngIf="this.buttonVisible"
      [disabled]="this.actualReceiveQty"
      (click)="btnClickedHandler($event)">R
    </button>
  </div>
  `,
})
export class RBtnCellRender implements ICellRendererAngularComp {

  rcount: any;
  pageName: any;
  qtyFlag = false;
  qtyBtnFlag = false;
  detailPage: any;
  columnDefs: any;
  buttonFlag:any = '';
  value: any;
  planQtyInput = false;
  actualShipQtyInput = false;
  actualReceiveQtyInput = false;
  edit2 = false;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute,) {
    if(sessionStorage.getItem('buttonFlag')){
      this.buttonFlag = JSON.parse(sessionStorage.getItem('buttonFlag') || '')
    }
  }
  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
  params: any;
  disabled = false;
  planQty = false;
  planQtyBtn = false;
  actualShipQty = false;
  actualShipQtyBtn = false;
  actualReceiveQty = false;
  buttonVisible = false;
  actualReceiveQtyBtn = false;
  o1: any = true
  o2: any = true
  agInit(params: any): void {
    this.params = params;
    if (sessionStorage.getItem('detailShipmentNumber')) 
    {
      this.detailPage = JSON.parse(
        sessionStorage.getItem('detailShipmentNumber') || ''
      );
    this.pageName = this.route.snapshot.data['breadcrumb']
      }
   
    if(this.params.column.colId === 'SHIPQTY'){
      this.planQty = true;
    }
    if(this.params.column.colId === 'actualShipqty'){
      this.actualShipQty = true;
    }
    if(this.params.column.colId === 'actualReceiveqty'){
      this.actualReceiveQty = true
    } 

    if(this.params.data?.PACKAGETYPECODE === 'R' || this.params.data?.PACKAGETYPECODE === 'B'){
      this.buttonVisible = true;
      if(this.buttonFlag.cancelFlag !== '1'){ 
        this.planQtyInput = true;
        this.actualShipQtyInput = true;
        this.actualReceiveQtyInput = true;

        if(this.buttonFlag.status !== ''){
          this.planQtyBtn = true;
        }
        if(this.buttonFlag.status !== '21'){
          this.actualShipQtyBtn = true;
        }
        if(this.buttonFlag.status !== '22'){
          this.actualReceiveQtyBtn = true;
        }        
      }     
    }else{
      if(this.buttonFlag.cancelFlag !== '1'){       
        if(this.buttonFlag.status !== ''){
          this.planQtyInput = true;
        }
        if(this.buttonFlag.status !== '21'){
          this.actualShipQtyInput = true;
        }
        if(this.buttonFlag.status !== '22'){
          this.actualReceiveQtyInput = true;
        }        
      }   
    }
    
    this.rcount = this.params.count;
    this.value = params.value;
    this.disabled = this.params.disabled(params.data);
   
    
    if(this.pageName !== 'Detail' ||  (this.detailPage.status === 0) && (this.detailPage.currentUser === this.detailPage.supplierCode)){
    if (this.disabled) {
      this.planQtyBtn = true;
      this.planQtyInput = true;
      this.actualShipQtyBtn = true;
      this.actualShipQtyInput = false;
      this.actualReceiveQtyBtn = true;
      this.actualReceiveQtyInput = true;
    }else{
      this.planQtyBtn = false;
      this.planQtyInput = true;
      this.actualShipQtyBtn = true;
      this.actualShipQtyInput = true;
      this.actualReceiveQtyBtn = true;
      this.actualReceiveQtyInput = true;
    }
  }
    if (this.params.count) {
      if (this.rcount !== -1) {
        const input: HTMLInputElement | null = document.querySelector("#input")
        if (input) {
          input.value = this.rcount
        }
      }
    }
    
}
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

  changed(event: any) {
    const value = Number(event.target.value);
    this.params.changed(this.params.data, value);
  }

}
