<div class="d-flex align-items-center gap-1">
 <button *ngIf="!hideBUCL" class="vpacs-btn-table" [class.blue]="!colorChange" [class.orange]="colorChange" [disabled]="isUsedBackup" 
 (click)="navigate('BU')" data-bs-toggle="tooltip" data-bs-placement="top" title="Backup">BU</button>

    <button class="vpacs-btn-table blue" (click)="navigate('H')" data-bs-toggle="tooltip" data-bs-placement="top" title="History">H</button>

    <button class="vpacs-btn-table blue" (click)="navigate('TC')" data-bs-toggle="tooltip" data-bs-placement="top" title="Tracking Correction" [disabled]="tcdisabled">TC</button>

    <button class="vpacs-btn-table blue" (click)="navigate('AU')" data-bs-toggle="tooltip" data-bs-placement="top" title="Authorize" [disabled]="authdisabled">AU</button>
   
    <button class="vpacs-btn-table" [class.orange]="isOrange" [class.green]="!isOrange" data-bs-toggle="tooltip" data-bs-placement="top" title="Update Ppf / Part# Cng" [disabled]="ppfdisabled" (click)="navigate('PPF')">PPF</button>

    <button class="vpacs-btn-table red" [disabled]="deletedisabled" (click)="navigate('D')" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">D</button>

    <button *ngIf="!hideBUCL" class="vpacs-btn-table blue" [disabled]="calcdisabled" (click)="navigate('CL')" data-bs-toggle="tooltip" data-bs-placement="top" title="Broadcast Calc" [disabled]="disableCalc">CL</button>
</div>