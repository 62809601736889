import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private readonly state: ExportStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(pNam: string) {
    this.dialogRef.close({ data: pNam })
  }
}
