import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SelectSpecialistComponent } from '../../tracking/select-specialist/select-specialist.component';
import * as FileSaver from 'file-saver-es';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-excel-dl',
  templateUrl: './excel-dl.component.html',
  styleUrls: ['./excel-dl.component.scss']
})
export class ExcelDlComponent implements OnInit {
  spCode: any;
  plants: any[] = [];
  resPlant: any[] = [];
  ringi: any;
  modelCode: any;
  forecast: any;
  partNum1: any;
  partNum2: any;
  specCode: any;
  supplierCode: any;
  authFrom: any;
  authTo: any;
  positive = 1;
  negative = 0;
  waitCancel = 0;
  retPkg = 1;
  expPkg = 1;
  plantVal: any;
  successMessage = ''
  errorList: any[] = []
  defaultErrMsg = "Something went wrong, Please contact System Administrator";

  constructor(
    public dialogRef: MatDialogRef<ExcelDlComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly authListService: AuthListService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) { }


  ngOnInit(): void {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    this.loadPlants();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  loadPlants() {
    setTimeout(() => {this.spinnerService.start();})
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 1
    }
    this.authListService.getAllPlant(plantObj)
      .subscribe({
        next: (res: any) => {
          this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
          this.resPlant = res.map((item: { plantcode: any; }) => item.plantcode);
          this.specCode = this.spCode;
          this.plantVal = '(All)'
        },
        error: () => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  downloadBtn() {
    this.successMessage = '';
    this.errorList = [];
    if (this.authFrom && this.authTo) {
      this.spinnerService.start();

      const params: any = {
        PlantCode: this.plantVal ? (this.plantVal === '(All)' ? this.resPlant : [this.plantVal]) : this.resPlant, 
        RingiNumber: this.ringi ? this.ringi : "",
        SupplierCode: this.supplierCode ? this.supplierCode : "",
        ModelCode: this.modelCode ? this.modelCode : "",
        SpecialistCode: this.specCode ?? "",
        PartNumber1: this.partNum1 ? this.partNum1 : "",
        PartNumber2: this.partNum2 ? this.partNum2 : "",
        AuthFrom: this.authFrom ? formatDate(this.authFrom, 'MM/dd/YYYY', 'en-US') : '01/01/0001',
        AuthTo: this.authTo ? formatDate(this.authTo, 'MM/dd/YYYY', 'en-US') : '',
        Positive: this.positive ? "1" : "0",
        Negative: this.negative ? "1" : "0",
        WaitCancel: this.waitCancel ? "1" : "0",
        Ret: this.retPkg ? "1" : "0",
        Exp: this.expPkg ? "1" : "0"
      }
      this.authListService.authListDownload(params)
        .subscribe({
          next: (res: any) => {
            if (res?.Data) {
              const toConvert = res.Data;
              const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${toConvert}`;
              FileSaver.saveAs(file, res.Filename);
              this.successMessage = 'Downloaded successfully'
              this.spinnerService.stop();
              this.dialogRef.close();
            }
            else {
              res?.message !== '' ? this.errorList.push(res?.message) : this.errorList.push(this.defaultErrMsg);
            }
          },
          error: () => {
            this.errorList.push(this.defaultErrMsg);
            this.spinnerService.stop();
          },
          complete: () => {
            this.spinnerService.stop();
          }
        });
    }
    else {
      if (!this.authFrom && this.authTo) {
        this.errorList.push('304-401:Required Item Error [Auth From Date]')
      }
      else if (this.authFrom && !this.authTo) {
        this.errorList.push('304-402:Required Item Error [Auth To Date]')
      }
      else if (!this.authFrom && !this.authTo) {
        this.errorList.push('304-401:Required Item Error [Auth From Date], 304-402:Required Item Error [Auth To Date]')
      }
    }
  }

  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specCode
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specCode = result;
    })
  }
  help() {
    this.dialogRef.close();
    this.router.navigate(['./master-help', 3, "Cost Management", 52])
  }
}
