import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';

@Component({
  selector: 'app-set-po',
  templateUrl: './set-po.component.html',
  styleUrls: ['./set-po.component.scss']
})
export class SetPoComponent implements OnInit {

  auth: any;
  po: any;
  successMessage: any;
  errorList: any;

  constructor(public dialogRef: MatDialogRef<SetPoComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any, private readonly router: Router,
    private readonly service: BackupAuthlistService,
    private readonly spinnerService: NgxUiLoaderService
  ) { }
  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.start();
    });
    this.auth = this.dataStore.auth;
    this.loadData(this.auth);
  }


  loadData(authNum: any) {
    this.service.setPo(authNum)
      .subscribe({
        next: (res: any) => {
          this.po = res?.ponumber;
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  closeDialog() {
    this.dialogRef.close()
  }

  onSearch() {

  }

  close(): void {
    this.dialogRef.close({ event: 'Close' });
  }

  help() {
    this.router.navigate(['./master-help', 3, "BackupAuth List", 51])

  }

  ok() {
    if (this.po != null) {
      this.spinnerService.start()
      const params = {
        Upflag: 1,
        PONumber: this.po,
        AuthNumber: this.auth,
      }
      
      this.service.updatePo(params).subscribe({
        next: (res: any) => {
          if (res.status === 'SUCCESS') {
            this.successMessage = 'Updated successfully'
            setTimeout(() => {
              this.successMessage = ''
              this.dialogRef.close({ event: 'CloseEvent', data: params })
            }, 3000)
          }
          else {
            this.errorList = res.message
            setTimeout(() => {
              this.errorList = ''
            }, 4000)
          }
          this.spinnerService.stop()
        },
        error: (err: any) => {
          this.spinnerService.stop()
          this.errorList = err.error?.error ?? 'Contact a system administrator'
          setTimeout(() => {
            this.errorList = ''
          }, 4000)
        },
      })
    }
    else {

      this.errorList = '304-401:Required Item Error [PO#]'
      setTimeout(() => {
        this.errorList = ''
      }, 4000)
    }
  }
}

