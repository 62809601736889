import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe, formatDate } from '@angular/common'
import { backupAuthListConstant } from '../../../../notification/backup-auth-list.constant'
import * as FileSaver from 'file-saver-es';


@Component({
  selector: 'app-backup-auth-details',
  templateUrl: './backup-auth-details.component.html',
  styleUrls: ['./backup-auth-details.component.scss'],
})
export class BackupAuthDetailsComponent implements OnInit {
  dateFormat = 'MM/dd/YYYY'
  costBudgetFlag = true
  invoiceFlag = true
  commntFlag = true
  panelOpenState = true;
  panelOpenState2 = true;
  panelOpenState3 = true;
  gridApi: any;
  icons: any;
  public domLayout: any = '';
  defaultColDef: {};
  public rowHeight = 40;
  public columnDefs1: any[] = [];
  public displayRowData: any;
  public displayRowData1: any;
  public gridOptions: GridOptions = {};
  sideHeaders: any[] = [];
  rowClassRules: any;
  undefinedOrNullArr = [undefined, 'undefined', null,'null'];
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  defaultColDef1: any;
  partInfo: any;
  partInfoContents: any;
  backupSpecification: any;
  backupSpecification1: any;
  response: any[] = [];
  authNum: any;
  reason: any;
  responsibility: any;
  costCenter: any;
  budget: any;
  pkgstartdate: any;
  costcenter: any;
  userInfo: any;
  startdate: any;
  ResApproval: any;
  comment: any = '';
  rejectreason:any='';
  rejectReasonFlag = true
  UserDate: any;
  spCode: any;
  plantCode: any;
  costCenterVal = '';
  constCenterArr: any[] = [];
  budgetVal = ''
  budgetArr: any[] = [];
  palntCodeB = '';
  
  constructor(
    private readonly service: BackupAuthlistService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    protected state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    public readonly datepipe: DatePipe,
  ) {
    this.defaultColDef = {
      autoHeight: true,
      sortable: false,
      unSortIcon: false,
      headerClass: 'child-header-color no-border',
    };
    this.defaultColDef1 = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  errorList: string[] = [];

  dates: any = [];
  container: any;
  dunnage: any;
  pallet: any;
  topcap: any;
  containerData: any = {};
  public dunnageData: any;
  public palletData: any;
  public topUpData: any;
  public accData: any = {};
  public rowData: any;
  public columnDefs: any = [];
  public grandTotal: any;
  public dateArray: any = [];
  public cellStyle = {
    'box-shadow': 'inset 0px 1px 6px #00000029',
    border: '1px solid #D7DCE1',
    color: '#676A6E',
    height: '30px',
    width: '50px',
    'margin-left': '5px',
  };
  dateDifference: any;
  approveFlag = true;
  rejectFlag = true;
  gridFlag = true;
  family= 'Family Code';
  model='Model Code';
  selectString="-- Select --"
  select=this.selectString;
  backupAuthList='backup-auth-list';
  selCostCenter = true;
  selBudjet = true;

  ngOnInit(): void {
    this.authNum = this.route.snapshot.queryParamMap.get('auth');
    this.plantCode = JSON.parse(sessionStorage.getItem('plantCode') || '{}');
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res.info) {
          this.userInfo = res?.info;
          this.spCode = this.userInfo.specialistcode;
          this.backupSpecification = (this.userInfo.unit === '1')? backupAuthListConstant.backupSpecificationI: backupAuthListConstant.backupSpecificationM;
          this.backupSpecification1 = (this.userInfo.unit === '1')? backupAuthListConstant.backupSpecificationI1: backupAuthListConstant.backupSpecificationM1;
          const { p_specialistcode, p_plantcode, unit } = res.info;
          if (p_specialistcode && p_plantcode && unit) {
            this.loadBackupAuthListDetails();
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });

    this.partInfo = [
      'Auth#',
      'Plant',
      this.family,
      this.model,
      'Part #',
      'Supplier',
      // 'Status',
    ];
    this.partInfoContents = [];
  }

  setColDefs() {

    this.columnDefs = [
      { headerName: '', field: 'packagetype', width: 80, pinned: 'left' },
      {
        headerName: 'Cost',
        field: 'costs',
        width: 80,
        pinned: 'left',
        cellStyle: (params: any) => {
          return params.data.packagetype !== '' ? this.cellStyle : null;
        },
      },
      {
        field:
          this.dateArray && this.dateArray.length > 0 ? this.dateArray[0] : '',
        children: [
          {
            headerName: 'MON',
            field: 'Mon',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'tue',
            field: 'Tue',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'wed',
            field: 'Wed',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'thu',
            field: 'Thu',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'fri',
            field: 'Fri',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
        ],
      },
      {
        field:
          this.dateArray && this.dateArray.length > 0 ? this.dateArray[1] : '',
        children: [
          {
            headerName: 'MON',
            field: 'Mon1',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'tue',
            field: 'Tue1',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'wed',
            field: 'Wed1',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'thu',
            field: 'Thu1',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'fri',
            field: 'Fri1',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
        ],
      },
      {
        field:
          this.dateArray && this.dateArray.length > 0 ? this.dateArray[2] : '',
        children: [
          {
            headerName: 'Mon',
            field: 'Mon2',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Tue',
            field: 'Tue2',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Wed',
            field: 'Wed2',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Thu',
            field: 'Thu2',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Fri',
            field: 'Fri2',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
        ],
      },
      {
        field:
          this.dateArray && this.dateArray.length > 0 ? this.dateArray[3] : '',
        children: [
          {
            headerName: 'Mon',
            field: 'Mon3',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Tue',
            field: 'Tue3',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Wed',
            field: 'Wed3',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Thu',
            field: 'Thu3',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
          {
            headerName: 'Fri',
            field: 'Fri3',
            width: 60,
            cellStyle: this.manualCellStyle,
            valueParser: this.integerValueParser,
          },
        ],
      },
      {
        headerName: 'Total Cost',
        field: 'total',
        width: 80,
        pinned: 'right',
        editable: false,
      },
    ];
  }

  integerValueParser(params: any) {
    const parsedValue = parseInt(params.newValue);
    if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
      return params.oldValue;
    }
    return parsedValue;
  }
  manualCellStyle(params: any) {
    const disableStyle={
      'box-shadow': 'inset 0px 1px 6px #00000029',
      border: '1px solid #D7DCE1',
      color: '#676A6E',
      height: '30px',
      width: '50px',
      'margin-left': '5px',
      'background-color':'#ADADAD'
    }
    const style = {
      'box-shadow': 'inset 0px 1px 6px #00000029',
      border: '1px solid #D7DCE1',
      color: '#676A6E',
      height: '30px',
      width: '50px',
      'margin-left': '5px',
    };
    if (params.rowIndex === 4) {
      return null;
    } else {
      if (params.data.empty === 0) {
        return ['Mon', 'Tue', 'Wed'].includes(params.column.colId)
          ? null
          : style;
      } else {
        return [''].includes(params.value) ? disableStyle : style;
      }
    }
  }

  setTrackQTYJSON() {
    const newArray: any = [];
    const data: any = [];
    for (let index = 0; index < this.dateArray.length; index++) {
      const temp = Object.fromEntries(
        Object.entries(this.rowData[index]).filter(
          ([key]) => !['packagetype', 'costs'].includes(key)
        )
      );
      data.push(temp);
    }
    const Container = Object.keys(data[0]).map((k) => data[0][k]);
    const pallet = Object.keys(data[1]).map((k) => data[1][k]);
    const lid = Object.keys(data[2]).map((k) => data[2][k]);
    const dunnage = Object.keys(data[3]).map((k) => data[3][k]);

    for (let i = 0; i < this.dateArray.length; i++) {
      newArray.push({
        date: this.dateArray[i],
        container: parseInt(Container[i]),
        pallet: parseInt(pallet[i]),
        lid: parseInt(lid[i]),
        dunnage: parseInt(dunnage[i]),
      });
    }
    return newArray;
  }

  setCPPJSON() {
    const cpp: any = {};
    cpp['container'] = parseInt(this.rowData[0].costs);
    cpp['pallet'] = parseInt(this.rowData[1].costs);
    cpp['topcap'] = parseInt(this.rowData[2].costs);
    cpp['dunnage'] = parseInt(this.rowData[3].costs);
    return cpp;
  }

  loadBackupAuthListDetails() {
    this.spinnerService.start();
    const params = {
      authNumber:this.authNum,
      userPlantCode: this.plantCode,
      userUnit: this.userInfo.unit
    };
    this.service.loadBackupAuthListDetails(params).subscribe({
      next: (res: any) => {
        this.palntCodeB = res.BackupPPFfromAuth[0].plantcode;
        if(res.BackupPPFfromAuth[0].resapproval === "3"){
          const temp = [];
          const obj = res.DataBackupAuthDetail[0];
          const pcs  = this.nullStr(res.BackupPPFfromAuth[0]["pcstatus"]); 
          if(pcs !== "") {
            const costObj = {"plantcode": "","costcenter": this.select,"used": ""}
            if (res.hasOwnProperty('costcenter')) {
              this.constCenterArr = [costObj, ...res.costcenter];
            } else {
              this.constCenterArr = [costObj];
            }
            this.costCenterVal = this.constCenterArr[0].costcenter;
            const newObject = {"plantcode": "","costcenter": "","budget": this.select}
            this.budgetArr =  [newObject];
            this.budgetVal = this.budgetArr[0].budget
            this.comment = res.DataBackupAuthDetail[0].comment1
          } else if(pcs === "") {
            this.costBudgetFlag = false;
            this.invoiceFlag = false;
            this.commntFlag   = false;
          }

          for (const key of this.partInfo) {
            if (key === 'Auth#') {
              temp.push(this.authNum ||"-");
            } else if (key === 'Plant') {
              temp.push(obj['plantname'] || '-');
            } else if (key === this.family) {
              temp.push(obj['familycode'] || '-');
            } else if (key === this.model) {
              temp.push(obj['modelcode'] || '-');
            } else if (key === 'Part #') {
              temp.push(obj['partnumber'] || '-');
            } else if (key === 'Supplier') {
              temp.push(obj['suppliername'] || "-");
            }
          }
          this.partInfoContents = [...temp];
          this.backupSpecification.forEach((items: any) => {
            if (items['title'] === 'Container') {
              items['subHeads'][0]['value'][0].val = res.DataBackupAuthDetail[0].contcode == null?'-': res.DataBackupAuthDetail[0].contcode
              items['subHeads'][1]['value'][0].val = res.DataBackupAuthDetail[0].contl == null?'-': res.DataBackupAuthDetail[0].contl
              items['subHeads'][1]['value'][1].val = res.DataBackupAuthDetail[0].contw == null?'-': res.DataBackupAuthDetail[0].contw
              items['subHeads'][1]['value'][2].val = res.DataBackupAuthDetail[0].conth == null?'-': res.DataBackupAuthDetail[0].conth
              items['subHeads'][1]['value'][4].val = res.DataBackupAuthDetail[0].contweight == null?'-': res.DataBackupAuthDetail[0].contweight
              items['subHeads'][2]['value'][0].val = res.DataBackupAuthDetail[0].contprice == null?'-': res.DataBackupAuthDetail[0].contprice;
              items['subHeads'][2]['value'][4].val = res.DataBackupAuthDetail[0].qpc == null?'-': res.DataBackupAuthDetail[0].qpc;
            }
            if (items['title'] === 'Dunnage') {
              items['subHeads'][0]['value'][0].val = res.DataBackupAuthDetail[0].duncode == null?'-': res.DataBackupAuthDetail[0].duncode
              items['subHeads'][1]['value'][0].val = res.DataBackupAuthDetail[0].dunl == null?'-': res.DataBackupAuthDetail[0].dunl;
              items['subHeads'][1]['value'][1].val = res.DataBackupAuthDetail[0].dunw == null?'-': res.DataBackupAuthDetail[0].dunw;
              items['subHeads'][1]['value'][2].val = res.DataBackupAuthDetail[0].dunh == null?'-': res.DataBackupAuthDetail[0].dunh;
              items['subHeads'][1]['value'][4].val = res.DataBackupAuthDetail[0].dunweight == null?'-': res.DataBackupAuthDetail[0].dunweight
              items['subHeads'][2]['value'][0].val = res.DataBackupAuthDetail[0].dunprice == null?'-': res.DataBackupAuthDetail[0].dunprice;
            }
            if (items['title'] === 'Top cap') {
              items['subHeads'][0]['value'][0].val = res.DataBackupAuthDetail[0].lidcode == null?'-': res.DataBackupAuthDetail[0].lidcode
              items['subHeads'][1]['value'][0].val = res.DataBackupAuthDetail[0].lidl == null?'-': res.DataBackupAuthDetail[0].lidl;
              items['subHeads'][1]['value'][1].val = res.DataBackupAuthDetail[0].lidw == null?'-': res.DataBackupAuthDetail[0].lidw;
              items['subHeads'][1]['value'][2].val = res.DataBackupAuthDetail[0].lidh == null?'-': res.DataBackupAuthDetail[0].lidh;
              items['subHeads'][1]['value'][4].val = res.DataBackupAuthDetail[0].lidweight == null?'-': parseFloat(res.DataBackupAuthDetail[0].lidweight)
              items['subHeads'][2]['value'][0].val = res.DataBackupAuthDetail[0].lidprice == null?'-': res.DataBackupAuthDetail[0].lidprice;
            }
            if (items['title'] === 'Pallet') {
              items['subHeads'][0]['value'][0].val = res.DataBackupAuthDetail[0].pltcode == null?'-': res.DataBackupAuthDetail[0].pltcode
              items['subHeads'][1]['value'][0].val = res.DataBackupAuthDetail[0].pltl == null?'-': res.DataBackupAuthDetail[0].pltl;
              items['subHeads'][1]['value'][1].val = res.DataBackupAuthDetail[0].pltw == null?'-': res.DataBackupAuthDetail[0].pltw;
              items['subHeads'][1]['value'][2].val = res.DataBackupAuthDetail[0].plth == null?'-': res.DataBackupAuthDetail[0].plth;
              items['subHeads'][1]['value'][4].val = res.DataBackupAuthDetail[0].pltweight == null?'-':  res.DataBackupAuthDetail[0].pltweight 
              items['subHeads'][2]['value'][0].val = res.DataBackupAuthDetail[0].pltprice == null?'-': res.DataBackupAuthDetail[0].pltprice;
            }
          });
          if(res.DataBackupAuthDetail[0].responsibility==='0'){
            this.responsibility ="Supplier"
          }else{
            this.responsibility="Toyota"
          }
          this.UserDate = (res?.DataBackupAuthDetail[0]?.startdate) ? this.datepipe.transform(
            res?.DataBackupAuthDetail[0]?.startdate.split('T')[0],
            this.dateFormat,
          ) : '';
          this.reason = (res.DataBackupAuthDetail[0].reason) ? res.DataBackupAuthDetail[0].reason : '';;
          this.dateArray = res.TableData?.Dates;
          const objectTable: any = [];
          //latest changes
          const length = this.dateArray.length;
          this.dateDifference = this.calculateDiff(this.dateArray[0], this.dateArray[length - 1]);
          this.dates = this.calculateDatesBetween(this.dateDifference, this.dateArray[0]);
          this.container = this.tableFilter(this.dates, res.BackupAuthQtyData, 'container');
          this.dunnage = this.tableFilter(this.dates, res.BackupAuthQtyData, 'dunnage');
          this.pallet = this.tableFilter(this.dates, res.BackupAuthQtyData, 'pallet');
          this.topcap = this.tableFilter(this.dates, res.BackupAuthQtyData, 'topcap');
          
          this.containerData = {
            packagetype: 'Container',
            costs: res.TableData?.ContainerCost,
            Mon: this.nullStr1(this.container[0]),
            Tue: this.nullStr1(this.container[1]),
            Wed: this.nullStr1(this.container[2]),
            Thu: this.nullStr1(this.container[3]),
            Fri: this.nullStr1(this.container[4]),
            Mon1: this.nullStr1(this.container[5]),
            Tue1: this.nullStr1(this.container[6]),
            Wed1: this.nullStr1(this.container[7]),
            Thu1: this.nullStr1(this.container[8]),
            Fri1: this.nullStr1(this.container[9]),
            Mon2: this.nullStr1(this.container[10]),
            Tue2: this.nullStr1(this.container[11]),
            Wed2: this.nullStr1(this.container[12]),
            Thu2: this.nullStr1(this.container[13]),
            Fri2: this.nullStr1(this.container[14]),
            Mon3: this.nullStr1(this.container[15]),
            Tue3: this.nullStr1(this.container[16]),
            Wed3: this.nullStr1(this.container[17]),
            Thu3: this.nullStr1(this.container[18]),
            Fri3: this.nullStr1(this.container[19]),
            total: res.TableData?.ContTotal,
          };
          this.dunnageData = {
            packagetype: 'Dunnage',
            costs: res.TableData?.DunCost,
            Mon: this.nullStr1(this.dunnage[0]),
            Tue: this.nullStr1(this.dunnage[1]),
            Wed: this.nullStr1(this.dunnage[2]),
            Thu: this.nullStr1(this.dunnage[3]),
            Fri: this.nullStr1(this.dunnage[4]),
            Mon1: this.nullStr1(this.dunnage[5]),
            Tue1: this.nullStr1(this.dunnage[6]),
            Wed1: this.nullStr1(this.dunnage[7]),
            Thu1: this.nullStr1(this.dunnage[8]),
            Fri1: this.nullStr1(this.dunnage[9]),
            Mon2: this.nullStr1(this.dunnage[10]),
            Tue2: this.nullStr1(this.dunnage[11]),
            Wed2: this.nullStr1(this.dunnage[12]),
            Thu2: this.nullStr1(this.dunnage[13]),
            Fri2: this.nullStr1(this.dunnage[14]),
            Mon3: this.nullStr1(this.dunnage[15]),
            Tue3: this.nullStr1(this.dunnage[16]),
            Wed3: this.nullStr1(this.dunnage[17]),
            Thu3: this.nullStr1(this.dunnage[18]),
            Fri3: this.nullStr1(this.dunnage[19]),
            total: res.TableData?.DunTotal,
          };
  
          this.palletData = {
            packagetype: 'Pallet',
            costs: res.TableData?.PltCost,
            Mon: this.nullStr1(this.pallet[0]),
            Tue: this.nullStr1(this.pallet[1]),
            Wed: this.nullStr1(this.pallet[2]),
            Thu: this.nullStr1(this.pallet[3]),
            Fri: this.nullStr1(this.pallet[4]),
            Mon1: this.nullStr1(this.pallet[5]),
            Tue1: this.nullStr1(this.pallet[6]),
            Wed1: this.nullStr1(this.pallet[7]),
            Thu1: this.nullStr1(this.pallet[8]),
            Fri1: this.nullStr1(this.pallet[9]),
            Mon2: this.nullStr1(this.pallet[10]),
            Tue2: this.nullStr1(this.pallet[11]),
            Wed2: this.nullStr1(this.pallet[12]),
            Thu2: this.nullStr1(this.pallet[13]),
            Fri2: this.nullStr1(this.pallet[14]),
            Mon3: this.nullStr1(this.pallet[15]),
            Tue3: this.nullStr1(this.pallet[16]),
            Wed3: this.nullStr1(this.pallet[17]),
            Thu3: this.nullStr1(this.pallet[18]),
            Fri3: this.nullStr1(this.pallet[19]),
            total: res.TableData?.PltTotal,
          };
          this.topUpData = {
            packagetype: 'Top Cap',
            costs: res.TableData?.TopCapCost,
            Mon: this.nullStr1(this.topcap[0]),
            Tue: this.nullStr1(this.topcap[1]),
            Wed: this.nullStr1(this.topcap[2]),
            Thu: this.nullStr1(this.topcap[3]),
            Fri: this.nullStr1(this.topcap[4]),
            Mon1: this.nullStr1(this.topcap[5]),
            Tue1: this.nullStr1(this.topcap[6]),
            Wed1: this.nullStr1(this.topcap[7]),
            Thu1: this.nullStr1(this.topcap[8]),
            Fri1: this.nullStr1(this.topcap[9]),
            Mon2: this.nullStr1(this.topcap[10]),
            Tue2: this.nullStr1(this.topcap[11]),
            Wed2: this.nullStr1(this.topcap[12]),
            Thu2: this.nullStr1(this.topcap[13]),
            Fri2: this.nullStr1(this.topcap[14]),
            Mon3: this.nullStr1(this.topcap[15]),
            Tue3: this.nullStr1(this.topcap[16]),
            Wed3: this.nullStr1(this.topcap[17]),
            Thu3: this.nullStr1(this.topcap[18]),
            Fri3: this.nullStr1(this.topcap[19]),
            total: res.TableData?.TopCapTotal,
          };
  
          objectTable[0] = { ...this.containerData };
          objectTable[1] = { ...this.palletData };
          objectTable[2] = { ...this.topUpData };
          objectTable[3] = { ...this.dunnageData };

          //disable logic
          const compareDate = new Date(res.BackupAuthQtyData[0].authdate);
          const result: boolean[] = [];
          for (let i = 0; i < 20; i++) {
            const currentDate = new Date(this.dates[i]);
            result.push(compareDate > currentDate)
          }
          result.unshift(false, false);
          result.push(false)
          //end of disable logic

          this.grandTotal = res.TableData?.Total;
          this.rowData = objectTable;
          this.setColDefs();
          if(res.DataBackupAuthDetail[0].pcspec !== this.userInfo.p_specialistcode || res.DataBackupAuthDetail[0].pcstatus !== '0'){
            this.rejectReasonFlag = false
            this.approveFlag = false;
            this.rejectFlag = false;
            this.selCostCenter = false;
            this.selBudjet = false;
            this.budget = (res.DataBackupAuthDetail[0].budget) ? res.DataBackupAuthDetail[0].budget : '-';
            this.costcenter = (res.DataBackupAuthDetail[0].costcenter) ? res.DataBackupAuthDetail[0].costcenter : '-';
          }

        }else{
          this.gridFlag = false;
          this.costBudgetFlag = false;
          this.invoiceFlag = false;
          const temp = [];
          const obj = res.BackupPPFfromAuth[0];
          for (const key of this.partInfo) {
            if (key === 'Auth#') {
              temp.push(this.authNum);
            } else if (key === 'Plant') {
              temp.push(obj['plantname']);
            } else if (key === this.family) {
              temp.push(obj['familycode']);
            } else if (key === this.model) {
              temp.push(obj['modelcode']);
            } else if (key === 'Part #') {
              temp.push(obj['partnumber']);
            } else if (key === 'Supplier') {
              temp.push(obj['suppliername']);
            }
          }
          this.partInfoContents = [...temp]; 
          this.backupSpecification.forEach((items: any) => {
            if (items['title'] === 'Container') {
              items['subHeads'][0]['value'][0].val = res.BackupPPFfromAuth[0].contcode == null ?'-': res.BackupPPFfromAuth[0].contcode
              items['subHeads'][1]['value'][0].val = res.BackupPPFfromAuth[0].contl == null?'-': res.BackupPPFfromAuth[0].contl;
              items['subHeads'][1]['value'][1].val = res.BackupPPFfromAuth[0].contw == null?'-': res.BackupPPFfromAuth[0].contw;
              items['subHeads'][1]['value'][2].val = res.BackupPPFfromAuth[0].conth == null?'-': res.BackupPPFfromAuth[0].conth;
              items['subHeads'][1]['value'][4].val = res.BackupPPFfromAuth[0].contweight == null?'-': res.BackupPPFfromAuth[0].contweight
              items['subHeads'][2]['value'][1].val = res.BackupPPFfromAuth[0].contprice == null?'-': res.BackupPPFfromAuth[0].contprice;
              items['subHeads'][2]['value'][4].val = res.BackupPPFfromAuth[0].qpc == null?'-': res.BackupPPFfromAuth[0].qpc;
            }
            if (items['title'] === 'Dunnage') {
              items['subHeads'][0]['value'][0].val = res.BackupPPFfromAuth[0].duncode == null?'-': res.BackupPPFfromAuth[0].duncode
              items['subHeads'][1]['value'][0].val = res.BackupPPFfromAuth[0].dunl == null?'-': res.BackupPPFfromAuth[0].dunl;
              items['subHeads'][1]['value'][1].val = res.BackupPPFfromAuth[0].dunw == null?'-': res.BackupPPFfromAuth[0].dunw;
              items['subHeads'][1]['value'][2].val = res.BackupPPFfromAuth[0].dunh == null?'-': res.BackupPPFfromAuth[0].dunh;
              items['subHeads'][1]['value'][4].val = res.BackupPPFfromAuth[0].dunweight == null?'-': res.BackupPPFfromAuth[0].dunweight
              items['subHeads'][2]['value'][0].val = res.BackupPPFfromAuth[0].dunprice == null?'-': res.BackupPPFfromAuth[0].dunprice;
            }
            if (items['title'] === 'Top cap') {
              items['subHeads'][0]['value'][0].val = res.BackupPPFfromAuth[0].lidcode == null?'-': res.BackupPPFfromAuth[0].lidcode
              items['subHeads'][1]['value'][0].val = res.BackupPPFfromAuth[0].lidl == null?'-': res.BackupPPFfromAuth[0].lidl;
              items['subHeads'][1]['value'][1].val = res.BackupPPFfromAuth[0].lidw == null?'-': res.BackupPPFfromAuth[0].lidw;
              items['subHeads'][1]['value'][2].val = res.BackupPPFfromAuth[0].lidh == null?'-': res.BackupPPFfromAuth[0].lidh;
              items['subHeads'][1]['value'][4].val = res.BackupPPFfromAuth[0].lidweight == null?'-': res.BackupPPFfromAuth[0].lidweight
              items['subHeads'][2]['value'][0].val = res.BackupPPFfromAuth[0].lidprice == null?'-': res.BackupPPFfromAuth[0].lidprice;
            }
            if (items['title'] === 'Pallet') {
              items['subHeads'][0]['value'][0].val = res.BackupPPFfromAuth[0].pltcode == null?'-': res.BackupPPFfromAuth[0].pltcode
              items['subHeads'][1]['value'][0].val = res.BackupPPFfromAuth[0].pltl == null?'-': res.BackupPPFfromAuth[0].pltl;
              items['subHeads'][1]['value'][1].val = res.BackupPPFfromAuth[0].pltw == null?'-':  res.BackupPPFfromAuth[0].pltw;
              items['subHeads'][1]['value'][2].val = res.BackupPPFfromAuth[0].plth == null?'-':  res.BackupPPFfromAuth[0].plth;
              items['subHeads'][1]['value'][4].val = res.BackupPPFfromAuth[0].pltweight == null?'-':  res.BackupPPFfromAuth[0].pltweight;
              items['subHeads'][2]['value'][0].val = res.BackupPPFfromAuth[0].pltprice == null?'-': res.BackupPPFfromAuth[0].pltprice;
            }
          })
          
          if(res.BackupPPFfromAuth[0].responsibility==='0'){
            this.responsibility ="Supplier"
          }else{
            this.responsibility="Toyota"
          }
          this.UserDate = (res?.BackupPPFfromAuth[0]?.startdate) ?  this.datepipe.transform( res?.BackupPPFfromAuth[0]?.startdate.split('T')[0], this.dateFormat) : '';
          const reason = res.BackupPPFfromAuth[0].reason;
          const reasonDetail = (res.BackupPPFfromAuth[0].reasondetail) ? res.BackupPPFfromAuth[0].reasondetail : ''
          this.reason = `${reason} ${reasonDetail}`;
          
            
            if(res.BackupPPFfromAuth[0]?.pcspec !== this.userInfo.p_specialistcode || res.BackupPPFfromAuth[0]?.resapproval !== '0'){
              this.rejectReasonFlag = false;
              this.approveFlag = false;
              this.rejectFlag = false;
              
            }
            this.commntFlag = false;
        }
        this.backupSpecification1.forEach((items: any) => {
          if (items['title'] === 'Container') {
            items['subHeads'][0]['value'][0].val = 
              res.PPFSpecForBackup[0].containercode == null?'-': res.PPFSpecForBackup[0].containercode
            items['subHeads'][1]['value'][0].val = res.PPFSpecForBackup[0].contl == null?'-': res.PPFSpecForBackup[0].contl;
            items['subHeads'][1]['value'][1].val = res.PPFSpecForBackup[0].contw == null?'-': res.PPFSpecForBackup[0].contw;
            items['subHeads'][1]['value'][2].val = res.PPFSpecForBackup[0].conth == null?'-': res.PPFSpecForBackup[0].conth;
            items['subHeads'][1]['value'][4].val = res.PPFSpecForBackup[0].contweight == null?'-': res.PPFSpecForBackup[0].contweight
            items['subHeads'][2]['value'][0].val = res.PPFSpecForBackup[0].concpp == null?'-': res.PPFSpecForBackup[0].concpp;
            items['subHeads'][2]['value'][4].val = res.PPFSpecForBackup[0].qpc == null?'-': res.PPFSpecForBackup[0].qpc;
          }
          if (items['title'] === 'Dunnage') {
            items['subHeads'][0]['value'][0].val = res.PPFSpecForBackup[0].dunnage1code == null?'-': res.PPFSpecForBackup[0].dunnage1code
            items['subHeads'][1]['value'][0].val = res.PPFSpecForBackup[0].dunl == null?'-': res.PPFSpecForBackup[0].dunl;
            items['subHeads'][1]['value'][1].val = res.PPFSpecForBackup[0].dunw == null?'-': res.PPFSpecForBackup[0].dunw;
            items['subHeads'][1]['value'][2].val = res.PPFSpecForBackup[0].dunh == null?'-': res.PPFSpecForBackup[0].dunh;
            items['subHeads'][1]['value'][4].val = res.PPFSpecForBackup[0].dunweight == null?'-': res.PPFSpecForBackup[0].dunweight
            items['subHeads'][2]['value'][0].val = res.PPFSpecForBackup[0].duncpp == null?'-': res.PPFSpecForBackup[0].duncpp;
          }
          if (items['title'] === 'Top cap') {
            items['subHeads'][0]['value'][0].val = res.PPFSpecForBackup[0].topcapcode == null?'-': res.PPFSpecForBackup[0].topcapcode 
            items['subHeads'][1]['value'][0].val = res.PPFSpecForBackup[0].lidl == null?'-': res.PPFSpecForBackup[0].lidl;
            items['subHeads'][1]['value'][1].val = res.PPFSpecForBackup[0].lidw == null?'-': res.PPFSpecForBackup[0].lidw;
            items['subHeads'][1]['value'][2].val = res.PPFSpecForBackup[0].lidh == null?'-': res.PPFSpecForBackup[0].lidh;
            items['subHeads'][1]['value'][4].val = res.PPFSpecForBackup[0].lidweight == null?'-': res.PPFSpecForBackup[0].lidweight
            items['subHeads'][2]['value'][0].val =  res.PPFSpecForBackup[0].lidcpp == null?'-': res.PPFSpecForBackup[0].lidcpp;
          }
          if (items['title'] === 'Pallet') {
            items['subHeads'][0]['value'][0].val = res.PPFSpecForBackup[0].palletcode == null?'-': res.PPFSpecForBackup[0].palletcode
            items['subHeads'][1]['value'][0].val = res.PPFSpecForBackup[0].pltl == null?'-': res.PPFSpecForBackup[0].pltl;
            items['subHeads'][1]['value'][1].val = res.PPFSpecForBackup[0].pltw == null?'-': res.PPFSpecForBackup[0].pltw;
            items['subHeads'][1]['value'][2].val = res.PPFSpecForBackup[0].plth == null?'-': res.PPFSpecForBackup[0].plth;
            items['subHeads'][1]['value'][4].val = res.PPFSpecForBackup[0].pltweight == null?'-': res.PPFSpecForBackup[0].pltweight;
            items['subHeads'][2]['value'][0].val = res.PPFSpecForBackup[0].pltcpp == null?'-': res.PPFSpecForBackup[0].pltcpp;
          }
        });
        this.spinnerService.stop();
      },
      error:(_err:any)=>{
        this.spinnerService.stop();
      }
    });
  }

  tableFilter(dates: any, authDetails: any, rowType: string) {
    const Container: any = [],
      pallet: any = [],
      topcap: any = [],
      Dunnage: any = [];
    for (const element of dates) {
      const result = authDetails?.filter((x: any) => element?.includes(formatDate(x.authdate, 'yyyy/MM/dd', 'en-US', 'UTC')));

      if (result?.length === 0) {
        // Ignore undefined
        Container.push('');
        Dunnage.push('');
        pallet.push('');
        topcap.push('');
      } else {
        Container.push(result[0]?.boxqty === '0' ? '-' : result[0]?.boxqty);
        Dunnage.push(result[0]?.dunqty === '0' ? '-' : result[0]?.dunqty);
        pallet.push(result[0]?.pltqty === '0' ? '-' : result[0]?.pltqty);
        topcap.push(result[0]?.lidqty === '0' ? '-' : result[0]?.lidqty);
      }
    }

    if (rowType === 'container') {
      return Container;
    } else if (rowType === 'dunnage') {
      return Dunnage;
    } else if (rowType === 'pallet') {
      return pallet;
    } else {
      return topcap;
    }
  }
  calculateDiff(date1: any, date2: any) {
    const dateA: any = new Date(date1);
    const dateB: any = new Date(date2);
    const diffDays: any = Math.floor((dateB - dateA) / (1000 * 60 * 60 * 24));
    return diffDays + 7;
  }

  calculateDatesBetween(diff: any, date: any) {
    const DateArray: any = [];
    for (let i = 0; i < diff; i++) {
      const myDate = new Date(new Date(date).getTime() + i * 24 * 60 * 60 * 1000);
      if (myDate.getDay() <= 5 && myDate.getDay() > 0) {
        DateArray?.push(myDate.toLocaleDateString('fr-CA').replace(/-/g, '/'));
      }
    }
    return DateArray;
  }

  approveValidation() {
    let value = true;
    this.errorList = [];
    if(this.selCostCenter && this.costCenterVal === this.selectString) {
      value = false;
      this.errorList = ['Required Item Error [Cost Center] is not selected.'];
      window.scrollTo(0, 0);
    } else if(this.selBudjet && this.budgetVal === this.selectString) {
      value = false;
      this.errorList = ['Required Item Error [Budget] is not selected.'];
      window.scrollTo(0, 0);
    }

    return value;

  }

  approve() {
    if(!this.approveValidation()) {
      return
    }

    const approveObj = {
      authNumber: this.authNum,
      userSpecialistCode: this.spCode,
      totalWeight: this.grandTotal,
      budget: this.budgetVal,
      costcenter: this.costCenterVal,
      comment: this.nullStr(this.comment)
    };
    this.spinnerService.start();
    this.service.backupAuthApprove(approveObj).subscribe({
      next: (res: any) => {
        if (res.command === 'UPDATE') {
          this.approveFlag = true;
          this.router.navigate([this.backupAuthList]);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  reject() {
    const rejectObj = {
      authNumber: this.authNum,
      userSpecialistCode:this.spCode,
      Reason: this.rejectreason,
      budget: this.budgetVal !== this.selectString ? this.budgetVal : "",
      cost: this.costCenterVal !== this.selectString ? this.costCenterVal : "",
      comment: this.nullStr(this.comment)
    };
    
    if (this.rejectreason) {
      this.spinnerService.start();
      this.service.backupAuthReject(rejectObj).subscribe({
        next: (_res: any) => {
          this.router.navigate([this.backupAuthList]);
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
      });
    } else {
      this.errorList = ['Please enter comment for rejection'];
      window.scrollTo(0, 0);
    }
  }

  downloadInvoice() {
    this.spinnerService.start();
    const invObj = {
      authNumber:this.authNum
    };
    this.service.downloadInvoice(invObj).subscribe({
      next: (res: any) => {
      const byteCharacters = atob(res.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, res.name);
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  base64ToUint8Array(base64: any) {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const uint8Array = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return uint8Array;
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
 
  onBack() { 
    let uri = ''
    if (this.router.url.includes(this.backupAuthList)) {
      uri = '/backup-auth-list'
    } else if (this.router.url.includes('pur-decision-list')) {
      uri = '/pur-decision-list'
    }
   else if (this.router.url.includes('decision-list')) {
    uri = '/decision-list'
  }
    this.router.navigateByUrl(uri);
  }
  help(){
    this.router.navigate(['./master-help',3,"Cost Management",62])
  
  }
getRowHeight(): number {
  return 31;
}

close() {
  this.errorList = [];
} 

onSelectChange(event: any) {
  if(event.target.value) {
    const payload = {
        plantCode: this.palntCodeB,
        costCenter: event.target.value
    }
    this.budgetArr = [];
    const newObject = {"plantcode": "", "costcenter": "", "budget": this.select}
    this.service.getBudgeList(payload).subscribe({
      next: (res: any) => {
        const arrayOfObjects = res;
        const newArray = [newObject, ...arrayOfObjects]
        this.budgetArr = newArray;
        this.budgetVal = this.budgetArr[0].budget
      },
      error: (err: any) => {
        const newArray = [newObject, ...this.budgetArr]
        this.budgetArr = newArray;
        this.budgetVal = this.budgetArr[0].budget
        this.errorList = [err.error]
        window.scrollTo(0, 0);
      },
    });
  }
}

private nullStr(value: any): string {
  if (this.undefinedOrNullArr.includes(value)) {    
    return "";
  }
  else {
    return value.toString();
  }
}

nullStr1(value: any): string {
  if (this.undefinedOrNullArr.includes(value)) {    
    return "-";
  }
  else {
    return value.toString();
  }
}


}
