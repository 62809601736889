<div class="wrapper bg-white" [formGroup]="shipTrailForm">  
    <div class="page-title px-3 py-2">Message</div>
    <div class="mat-typography content mx-2">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title >RECEIVE FROM TOYOTA</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Trial Due Date<span >{{receiveFromToyota?.trialDueDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Auth Date<span >{{receiveFromToyota?.trialDueDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Status<span >{{receiveFromToyota?.authdate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Status<span >{{receiveFromToyota?.status}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Part Number<br/><span >{{receiveFromToyota?.partNumber}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Package Start Date<br/><span >{{receiveFromToyota?.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
                <div class="col-lg-3 col-md-6 col-12 my-2">Message From<br/><span >{{receiveFromToyota?.messageFrom}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2"> 
                    <span class="col-3">Message</span>
                    <textarea readOnly rows="3" class="col-12">{{receiveFromToyota?.message}}</textarea>
                </div>
            </div> 
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0"  >
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title >PACKAGE REVIEW </mat-panel-title>
                <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Result<span >{{packageReview?.result}}</span></div>
                <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested <span >{{packageReview?.requested}}</span></div>
                <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Requested Changes<span >{{packageReview?.requestChanges}}</span></div>
                <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason<span >{{packageReview?.reason}}</span></div>
                <div class="d-grid col-lg-2 col-md-4 col-6 my-2">Reason Requested<span >{{packageReview?.reasonChanges}}</span></div>  
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title >ORDER INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Quantity of Part<span >{{orderInformation?.otyOfPart}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">ATTN Plant<span >{{orderInformation?.attnPlant}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dock Code<span >{{orderInformation?.dockCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Top Cap Code<span >{{orderInformation?.dockCode}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title >REPLY TO TOYOTA</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="row col-12">
                    <div class="details-pane my-2 d-flex flex-wrap col-12 gap-2">
                        <input type="button" value="PPF" (click)="ppf()" class="vpacs-btn-table"/>
                        <input type="button" value="AUTH DOWNLOAD" (click)="authDownload()" class="vpacs-btn-table"/>
                    </div>
                </div>
                <div class="row col-12">
                    <div class="col-lg-3 col-md-6 col-12 my-2" *ngIf="arriveFlag">Name of Carrier<br/>
                        <input type="text" style="width: '100%';"  formControlName="carrierName">
                    </div>    
                    <div class="col-lg-3 col-md-6 col-12 my-2" *ngIf="arriveFlag">Arrive Date at Toyota<br/>
                        <input type="date" style="width: '100%';" formControlName="arriveDate">
                    </div>    
                    <div class="col-lg-3 col-md-6 col-12 my-2" *ngIf="!arriveFlag">Name of Carrier<br/>
                        {{replyToToyota?.carrier}}
                    </div>    
                    <div class="col-lg-3 col-md-6 col-12 my-2" *ngIf="!arriveFlag">Arrive Date at Toyota<br/>
                        {{arriveDate}}
                    </div>  
                    <div class="col-lg-6 col-md-6 col-12 my-2"> 
                        <label for="comment">Comment</label><br/>
                        <textarea id="comment" rows="4" class="w-100" formControlName="comment"></textarea><br/>
                        <small *ngIf="shipTrailForm.get('comment').hasError('maxlength')">Maximum 250 Characters allowed</small>
                    </div>
                </div>
                <div class="d-grid col-lg-12 col-md-6 col-12 my-2">
                    <span class="col-12">I have received and tested the packaging per guidelines in chapter three of slim.</span>
                    <span class="col-6 gap-4">
                        <input type="radio" id="yes" [value]=1 formControlName="testStatus">&nbsp;
                        <label for="yes">Yes</label>
                        <input type="radio" id="no" [value]=0 class="ms-2" formControlName="testStatus">&nbsp;
                        <label for="no" >No</label>
                    </span>
                </div>
        </div>         
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex mx-2 fixed-bottom">
    <div class="mx-3 cancel" (click)="cancel()">Cancel</div>
    <button class="black-button mx-3" [disabled]="btnDisabled || shipTrailForm.invalid" *ngIf="okFlag" (click)="submit()">Submit</button>
    <button class="black-button mx-3" [disabled]="btnDisabled || shipTrailForm.invalid" (click)="cancelOk()" *ngIf="cancelOKFlag">Cancel NG</button>
    <button class="black-button mx-3" [disabled]="btnDisabled || shipTrailForm.invalid" (click)="cancelNg()" *ngIf="cancelNGFlag">Cancel OK</button>
    <button class="black-button mx-3" [disabled]="btnDisabled || shipTrailForm.invalid" (click)="approveReject(1)" *ngIf="approvalFlag">Approve</button>
    <button class="black-button mx-3" [disabled]="btnDisabled || shipTrailForm.invalid" (click)="approveReject(2)" *ngIf="rejectFlag">Reject</button>

</div>
</div>