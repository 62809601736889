<div class="dialog-wrapper col p-2">
    <div class="d-flex justify-content-between align-items-center header">
        <div class="page-title">Select Package Fabricator</div>
      <div>
        <button class="help-btn" (click)="help()">Help</button>
      </div>
      </div>
    <div class="filter-params d-flex flex-wrap w-100 px-2" [formGroup]="selectForm" (keyup.enter)="search()">
        <div class="col-lg-2 col-md-6 mt-2">
            <label for="code" class="col-12">Code</label><br/>
            <input type= "text" class="col-8" id="code" formControlName="code"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="alphacode" class="col-12">Alpha code</label><br/>
            <input type= "text" class="col-8" id="alphacode" formControlName="alpha"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="name" class="col-12">Name</label><br/>
            <input type= "text" class="col-8" id="name" formControlName="name"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="commodity" class="col-12">Commodity</label><br/>
            <mat-select  #select multiple class="mat-sel pt-2 col-8" formControlName="commodity">
                <div class="select-all mx-2 p-3">
                    <mat-checkbox [(ngModel)]="allSelected"
                                  [ngModelOptions]="{standalone: true}"
                                  (change)="toggleAllSelection()"
                    >Select All</mat-checkbox>
                </div>
                <mat-option (click)="optionClick()" *ngFor="let x of dropdownValues" [value]="x.viewValue">
                    {{x.viewValue}}
                </mat-option>
            </mat-select>
        </div>
        <button class="black-button col-lg-1 col-md-6 mt-2" (click)="search()">Search</button>
    </div>
    <div class="grid-agg grid-h mt-2">
        <ag-grid-angular
            style="width: 100%;height: 300px"
            class="ag-theme-balham"
            (gridReady)="onGridReady($event)"
            (window:resize)="sizeToFit()"
            [animateRows]="true"
            [rowHeight]="40"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            [rowData]="displayRowData"
            [suppressCellSelection]="true"
            [paginationPageSize]="rpPage"
            [pagination]="true"
            [suppressPaginationPanel] = "true"
            [icons]="icons"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [floatingFiltersHeight]="40"
            (filterChanged) = "onFilterChanged($event)"
            [suppressHorizontalScroll] = "true"
        >
        </ag-grid-angular>
        <app-pagination
            [cPage]="cPage"
            [tPage]="tPage"
            [rpPage]="rpPage"
            [totalRecords] = "totalRecords" 
            (changePaginationValues)="changePaginationValues($event)"
            *ngIf="totalRecords > 0"
        >
        </app-pagination>
    </div> 
    <button class="black-button close-btn" (click)="closeDialog()">&times;</button>
</div>
