<p class="page-title mx-3">Log Details</p>

<div class="popup-contents">
    <div class="row m-3 content-row">
        <div class="col-md-12 align-items-center">
            <div class="row"  *ngIf="mode === 1">
                <div class="col-md-3 contentColumn p-0">
                    <div *ngFor="let details of displayRowData; let i=index" class="ps-4 py-2"
                        [ngClass]="i % 2 === 0? 'row-even' : 'row-odd'">
                        Plant : {{details.plantcode}}, Supplier : {{details.suppliercode}}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="mode === 2">
                <div class="col-md-3 contentColumn p-0">
                    <div *ngFor="let details of displayRowData; let i=index" class="ps-4 py-2"
                        [ngClass]="i % 2 === 0? 'row-even' : 'row-odd'">
                        Part#: {{details.partnumber}}, Plant : {{details.plantcode}}, Supplier : {{details.suppliercode}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>