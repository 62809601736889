import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PartListService {
  partFilter: any;
  pagination: any;
  pagedata: any;
  plantdata: any;
  filterdata: any;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }
  URL = this.constantsService.INTERNAL ;

  onLoad(updateObject: any): Observable<any> {
   
    return this.http.post(this.URL + 'trackingPartList/onload', updateObject, 
    { headers: this.constantsService.internalHttpOptions,responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  supplierLoad(updateParams: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/supplierInfo', updateParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  backUpOnLoad(backupParams: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/backupOnload', backupParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  updatePpfOnload(PPFParams: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/updatePPFOnload', PPFParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  updatePpfConfirm(ppfConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/updatePartConfirm', ppfConfirm, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  updatePpf(ppfUpdateConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/updatePartNumber',
    ppfUpdateConfirm, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }
  plant(obj: any): Observable<any> {
    return this.http.post(this.URL + 'common/getPlant', 
    obj, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
    .pipe(catchError(this.handleError))
  }
  specialistCode(codeObj: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/SpecialistCodes', 
    codeObj, { headers: this.constantsService.internalHttpOptions, })
    .pipe(catchError(this.handleError))
  }

  approve(ppfUpdateConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/updatePPF/Approve',
    ppfUpdateConfirm, { headers: this.constantsService.internalHttpOptions})
      .pipe(catchError(this.handleError))
  }

  reject(ppfUpdateConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingPartList/updatePPF/Reject',
    ppfUpdateConfirm, { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }
  newPpfCall(ppfCode: any) {
    const queryParams = new HttpParams()
      .set('ppfCode', ppfCode)
    return this.http.get(this.URL+ 'newppf', { params: queryParams,headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))


  }
  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");

  }
 
  setpartFilters(partFilter: any) {
    this.partFilter = partFilter;
  }
  getpartFilters() {
    return this.partFilter;
  }
  setplant(plant: any) {
    this.plantdata = plant;
  }
  getplant() {
    return this.plantdata;
  }
 
  setfilter(filter: any) {
    this.filterdata = filter;
  }
  getfilter() {
    return this.filterdata;
  }
  resetpartFilters(){
    this.partFilter = '';
  }
  resetfilter(){
    this.filterdata = '';
  }
}
