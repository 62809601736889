import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthorityService } from 'src/app/services/authoritylist.service';
import { AuthorityListBtnCellrender } from '../../gridrender/authoritylist-btn-cell-render';
import { Router } from '@angular/router';
import { CheckBoxDetail } from '../../gridrender/checkBoxDetail';

@Component({
  selector: 'app-authority-detail',
  templateUrl: './authority-detail.component.html',
  styleUrls: ['./authority-detail.component.scss']
})
export class AuthorityDetailComponent implements OnInit {

  gridApi: any;
  gridOptions:any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  rowHeight = 40;
  event:any
  isAccessible = false;
  metaData:any;
  _SelectAll='Select All'
  _childHeaderColor='child-header-color'
  public rowSelection: 'single' | 'multiple' = 'multiple';
  

  constructor( 
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<AuthorityListBtnCellrender>,
    public authorityListService:AuthorityService,
    public router:Router
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,      
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
      sortable:true, 
    };
  }

  ngOnInit(): void {
    this.authorityListService.loadAuthorityDetail(this.data.guid, this.data.supplierCode).subscribe(
      (res: any) => {
        this.displayRowData = res;
      }
    );

    this.columnDefs = [
      { 
        headerName: 'Plant',field: 'PLANTNAME',sortable: true,unSortIcon: true,minWidth:120, children:
        [{ headerName: this._SelectAll, field: 'PLANTNAME',headerClass: this._childHeaderColor,minWidth:120}],
      },
      {
       headerName: 'Read',field:'AUTHREAD',sortable: true,unSortIcon: true,minWidth:120, children: 
       [ 
         { headerName: this._SelectAll,field: 'AUTHREAD',editable: true,minWidth:120,  headerClass: this._childHeaderColor,
          cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail}],
      },
      {
        headerName: 'Write',field:'AUTHWRITE',sortable: true,unSortIcon: true,minWidth:120, children: 
        [{ headerName: this._SelectAll,field:'AUTHWRITE',minWidth:120,  headerClass: this._childHeaderColor,
            cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail}],
      },
      {
        headerName: 'Submit',field: 'AUTHSEND',sortable: true,unSortIcon: true,minWidth:120, children: 
        [{ headerName: this._SelectAll,field: 'AUTHSEND',minWidth:120, headerClass: this._childHeaderColor, 
            cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail},],
      },
      {
        headerName: 'PreProd',field: 'PREPROD',children: 
        [
          {
            headerName: 'Leader', headerClass: this._childHeaderColor, children: 
            [{ headerName: this._SelectAll,field: 'PREPROD',minWidth:120,  headerClass: this._childHeaderColor,
                cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail},],
          },
          {
            headerName: 'Email',field: 'NEEDEMAILPRE', headerClass: this._childHeaderColor,children: 
            [
              { headerName: this._SelectAll,field: 'NEEDEMAILPRE',minWidth:120, headerClass: this._childHeaderColor,
                cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail},
            ],
          },
        ],
      },
      {
        headerName: 'MassProd',field: 'MASSPROD',children: 
        [
          {
            headerName: 'Leader', headerClass: this._childHeaderColor,children: 
            [{ headerName: this._SelectAll,field: 'MASSPROD', minWidth:120, headerClass: this._childHeaderColor,
                cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail},],
          },
          {
            headerName: 'Email',field: 'NEEDEMAILMASS', headerClass: this._childHeaderColor,children: 
            [{ headerName: this._SelectAll,field: 'NEEDEMAILMASS',minWidth:120,  headerClass: this._childHeaderColor,
                cellRendererFramework: CheckBoxDetail, headerComponentFramework: CheckBoxDetail},],
          },
        ],
      },
    ];
  }

  
  onCancel(): void {
    this.dialogRef.close();
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  async update() {
    const items:any = [];
    await this.gridApi.forEachNode(function(node:any) { 
        items.push(node.data);
    });
    
    items.forEach((element:any) => {
      Object.assign(element, {TOYOTAGUID: this.data.guid, SUPPLIERCODE5: this.data.supplierCode})
    });

    this.authorityListService.authorityDetailUpdate(items.filter(Boolean)).subscribe({
      next : (_res:any) => {
        this.dialogRef.close();
      },
    }) 
  }

}

