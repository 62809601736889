<div class="wrapper bg-white">  
    <div class="p-2 py-3 page-title">Authority Detail</div>
    <div class="mat-typography content px-2">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">AUTHORIZATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Name<span class="text-black"> {{data.name}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Supplier Code<span class="text-black">{{data.supplierCode}}</span></div>                
            </div>     
        </mat-expansion-panel>
    </mat-accordion> 

    <div class="grid-agg p-2">
        <ag-grid-angular style="width:100%;height:55vh;"
             class="ag-theme-balham"
            (gridReady)="onGridReady($event);" 
            (window:resize)="sizeToFit()" 
            [rowHeight]="rowHeight"
            [animateRows]="true"
            [defaultColDef]="defaultColDef" 
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData"
            [suppressCellSelection]="true"
            [suppressPaginationPanel] = "true"           
            [headerHeight] = "40"
            >       
        </ag-grid-angular>
    </div>
</div>
<div class="footer-dialog d-flex">
    <div mat-dialog-close class="mx-3 cancel">Cancel</div>
    <button class="black-button mx-3"  (click)="update()">Update</button>
</div>




  