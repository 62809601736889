import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { ShipmentSelectBtnCellRender } from '../../gridrender/shipment-select-btn-cell-render';
import { ActivatedRoute } from '@angular/router';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';


@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  providers: [RegexFilter]
})
export class SelectCompanyComponent implements OnInit {
  plants = ['(ALL)','MTMUS-M DNU','MTMUS-T', 'MTM-M', 'TDB', 'TMCA', 'TMMR', 'TDV', 'TNGA', 'TMMNK', 'TMMK', 'TMMWV', 'TMMR', 'TMMC', 'Export',
    'TMMAL', 'NUMMI', 'TMMNA', 'TMC', 'TMMBC', 'TMMTX', 'TMMI', 'TABC', 'TMMMS', 'VUTEX', 'TSAM', 'TMUK', 'TMS', 'TASA',
    'TMAP', 'TMC-EXP', 'TMMGT', 'MTMUS-M','TMEE','TBMNC'];
  public rowData: any;
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  selected = 'fabricator';
  supplierCode = '';
  supplierName = '';
  clicked: any;
  selectDef: any;
  fabCode: any = '';
  namcPlant: any;
  pageName: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public route: ActivatedRoute,
    private readonly dialogRef: MatDialogRef<SelectCompanyComponent>,
    public newShipmentService: NewshipmentService,
    public regex:RegexFilter
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
      sortable: true,
    };
    this.selectDef = (name: string): any => {
      return {
        headerName: 'Action',
        field: 'select',
        filter: 'agTextColumnFilter',
        sortable: false,
        unSortIcon: false,
        minWidth: 100,
        cellRendererFramework: ShipmentSelectBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.clicked({ ...field, selectedSuplier: name });
            this.dialogRef.close();
          },
        },
      };
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.clicked = data?.clicked;
  }

  ngOnInit(): void {
    this.pageName = this.route.snapshot.data['breadcrumb'];
    this.columnDefs = [
      {
        headerName: 'Fabricator',
        field: 'PKGSUPCODE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Address',
        field: 'ADDRESS',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 170,
      },
      {
        headerName: 'Person',
        field: 'NAME',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Phone',
        field: 'PHONE',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 110,
      },
      {
        headerName: 'Email',
        field: 'EMAIL',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 170,
      },
      this.selectDef('fabricator'),
    ];
    this.newShipmentService.fabricatorData().subscribe((result) => {
      this.displayRowData = result;
      this.rowCount = this.displayRowData.length;
    });
    if (this.pageName === 'Detail') {
      this.newShipmentService.fabricatorDataDetail().subscribe((result) => {
        this.displayRowData = result;
        this.rowCount = this.displayRowData.length;
      });
    }
  }
  changeSelected(event: MatRadioChange) {
    this.selected = event.value;
    if (this.selected === 'fabricator') {
      this.columnDefs = [
        {
          headerName: 'Fabricator',
          field: 'PKGSUPCODE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Address',
          field: 'ADDRESS',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        {
          headerName: 'Person',
          field: 'NAME',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Phone',
          field: 'PHONE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Email',
          field: 'EMAIL',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('fabricator'),
      ];
    }
    if (this.selected === 'namc') {
      this.columnDefs = [
        {
          headerName: 'Plant Code',
          field: 'PLANTCODE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Plant Name',
          field: 'PLANTNAME',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Dock',
          field: 'DOCKCODE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Address',
          field: 'ADDRESS',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('namc'),
      ];
    }
    if (this.selected === 'supplier') {
      this.columnDefs = [
        {
          headerName: 'Supplier Code',
          field: 'SUPPLIERCODE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 100,
        },
        {
          headerName: 'Supplier Name',
          field: 'SHORTNAME',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Address',
          field: 'ADDRESS',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 190,
        },
        {
          headerName: 'Person',
          field: 'FULLNAME',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 130,
        },
        {
          headerName: 'Phone',
          field: 'PHONE',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 110,
        },
        {
          headerName: 'Email',
          field: 'EMAIL',
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          minWidth: 170,
        },
        this.selectDef('supplier'),
      ];
    }
    this.Search(true);
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  Search(isRadioChanged = false) {
    if(this.pageName === 'Detail') {
      this.newShipmentService?.fabricatorDataDetail().subscribe((result) => {
        this.displayRowData = result.filter((r: any) => {
          return r.PKGSUPCODE.toLowerCase().startsWith(
            this.fabCode.toLowerCase()
          );
        });
        if(!this.fabCode){
          this.gridApi.setRowData(this.displayRowData)
          this.rowCount=this.displayRowData.length
        }else {
            const filterParams:any = {
              PKGSUPCODE: this.fabCode ? this.fabCode : '*',   
          }
          const filteredData = this.regex.regexFilter(this.displayRowData,filterParams);
            this.gridApi?.setRowData(filteredData);
            this.rowCount = filteredData.length
        }
        this.rowCount = this.displayRowData.length;
      });

      if (this.selected === 'supplier') {
        this.newShipmentService?.supplierDataDetail().subscribe((result) => {
          this.displayRowData = result.filter((r: any) => {
            return (
              r.SUPPLIERCODE.toLowerCase().startsWith(
                this.supplierCode.toLowerCase()
              ) &&
              r.SHORTNAME.toLowerCase().startsWith(
                this.supplierName.toLowerCase()
              )
            );
          });
          if(!this.supplierCode && !this.supplierName){
            this.gridApi.setRowData(this.displayRowData)
            this.rowCount=this.displayRowData.length
          }else {
            const filterParams: any = {
              SUPPLIERCODE: this.supplierCode ? this.supplierCode : '*',
              SHORTNAME: this.supplierName ? this.supplierName : '*',   
            }
            const filteredData = this.regex.regexFilter(this.displayRowData, filterParams);
            this.gridApi?.setRowData(filteredData);
            this.rowCount = filteredData.length
          }
          this.rowCount = this.displayRowData.length;
        });
      }
      

      if (this.selected === 'namc') {
        this.newShipmentService?.namcDataDetail().subscribe((result) => {
          if (this.namcPlant === '(ALL)' || this.namcPlant === undefined) {
            this.displayRowData = result;
          } else {
            this.displayRowData = result.filter((r: any) => {
              return r.PLANTNAME.toLowerCase() === this.namcPlant.toLowerCase();
            });
            this.rowCount = this.displayRowData.length;
          }
        });
      }
    
    } else {

        isRadioChanged && this.newShipmentService?.supplierData().subscribe((result) => {
        this.displayRowData = result.filter((r: any) => {
        return (
          r.SUPPLIERCODE.toLowerCase().startsWith(
            this.supplierCode.toLowerCase()
          ) &&
          r.SHORTNAME.toLowerCase().startsWith(
            this.supplierName.toLowerCase()
          )
        );
      });
      this.rowCount = this.displayRowData.length;
    });

    if (this.selected === 'fabricator') {
      if(!this.fabCode){
        this.gridApi.setRowData(this.displayRowData)
        this.rowCount=this.displayRowData.length
      }else {
          const filterParams:any = {
            PKGSUPCODE: this.fabCode ? this.fabCode : '*',   
        }
        const filteredData = this.regex.regexFilter(this.displayRowData,filterParams);
          this.gridApi?.setRowData(filteredData);
          this.rowCount = filteredData.length
      }
      
    }

    
    if (this.selected === 'supplier') {
      if(!this.supplierCode && !this.supplierName){
        this.gridApi.setRowData(this.displayRowData)
        this.rowCount=this.displayRowData.length
      }else {
        const filterParams: any = {
          SUPPLIERCODE: this.supplierCode ? this.supplierCode : '*',
          SHORTNAME: this.supplierName ? this.supplierName : '*',   
        }
        const filteredData = this.regex.regexFilter(this.displayRowData, filterParams);
        this.gridApi?.setRowData(filteredData);
        this.rowCount = filteredData.length
      }
    }

    if (this.selected === 'namc') {
      this.newShipmentService?.namcData().subscribe((result) => {
        if (this.namcPlant === '(ALL)' || this.namcPlant === undefined) {
          this.displayRowData = result;
        } else {
          this.displayRowData = result.filter((r: any) => {
            return r.PLANTNAME.toLowerCase() === this.namcPlant.toLowerCase();
          });
          this.rowCount = this.displayRowData.length;
        }
      });
    }
  }

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
}


