import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'dec-header-render',
    template: `
    <div class="d-flex align-items-center gap-1">
    <input type="radio" [checked]="checkFlag" (change)="handler($event)" />
    <span>ALL</span>
</div>

  `,
})
export class DecHeaderRender {
    gridDatas: any = [];
    checkFlag = false;
    constructor(public spinnerService: NgxUiLoaderService
        ) { }
    public params: any;
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.spinnerService.start();
        setTimeout(() => {
            const rowsData = this.params.api.getModel().rowsToDisplay;
            this.gridDatas = rowsData.map((rowNode: any) => rowNode.data);
            this.checkAll();
        }, 2000);
    }
    checkAll() {
        if (this.params.column.colId ==='approve') {
            this.checkFlag = this.gridDatas[0]?.headerApprove
        } else if (this.params.column.colId === 'reject') {
            this.checkFlag = this.gridDatas[0]?.headerReject
        } else if (this.params.column.colId === 'reserve') {
            this.checkFlag = this.gridDatas[0]?.headerReserve
        }
        this.spinnerService.stop();

    }

    handler(_event: any) {
        if (this.params.column.colId === 'approve') {
            this.gridDatas.forEach((x: any) => {
              x.headerApprove = true;
              x.headerReserve = false;
              x.headerReject = false;
              x.selectedValue = 'approve';
            });
          } else if (this.params.column.colId === 'reject') {
            this.gridDatas.forEach((x: any) => {
              x.headerApprove = false;
              x.headerReserve = false;
              x.headerReject = true;
              x.selectedValue = 'reject';
            });
          } else if (this.params.column.colId === 'reserve') {
            this.gridDatas.forEach((x: any) => {
              x.headerApprove = false;
              x.headerReserve = true;
              x.headerReject = false;
              x.selectedValue = 'reserve';
            });
          }
        this.params.api.refreshHeader();
        this.params.api.redrawRows();
    }
}