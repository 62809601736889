<div class="popup-container">
    <header>
      <button class="close black-button" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      <div class="header d-block roboto-medium">
          <label class="mt-2 mb-3">{{header}}</label>
      </div>
  </header>
  <div  id='err' class="alert alert-danger m-2" *ngIf="errorList !== ''">
    <span  >{{errorList}}</span>
  </div>
  <app-notification [codes] = "errorMessage" *ngIf="errorMessage"></app-notification>
  
    <div class="container">
      <div class="row pt-4">
        <span>{{text}}</span>
  
      <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-1 pt-2 pb-2">
        <button class="cancel me-3 roboto-bold" (click)="close()"> CANCEL </button>
        <button class="submit black-button me-3 roboto-bold" (click)="ok()">OK</button>
      </div>
    </div>
  
  </div>
  
  