<div class="dialog-wrapper col p-2">
    <div class="col-12 py-3 px-2 page-title">Select Fabricator</div>
    <div>Component Type:
        <span class="fw-bold mx-2">{{this.data.componentName}}</span>
    </div>
    <div class="filter-params d-flex flex-wrap align-items-center" [formGroup]="searchFabricator" (keyup.enter)="onSearch()">
        <div class="col-lg-2 col-md-6 mt-2">
            <label for="code" class="col-4">Code</label>
            <input type= "text" id="code" class="col-6" formControlName="code"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="alphacode" class="col-4">Alpha code</label>
            <input type= "text" id="alphacode" class="col-6" formControlName="alphaCode"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="name" class="col-4">Name</label>
            <input type= "text" id="name" class="col-6" formControlName="name"/>
        </div>
        <div class="col-lg-3 col-md-6 mt-2">
            <label for="commodity" class="col-4">Commodity</label>
            <mat-select formControlName="commodity" id="commodity" class="mat-sel pt-2 col-6" disableOptionCentering>
                <mat-option *ngFor="let x of commodityDropdown" [value]="x.PACKAGETYPECODE">{{x.PACKAGETYPE}}</mat-option>
            </mat-select>
        </div>
        <button class="black-button col-lg-1 col-md-6 mt-2" (click)="onSearch()">Search</button>
    </div>
    <div class="grid mt-2">
        <ag-grid-angular
        style="width: 100%; height: 250px"
            class="ag-theme-balham"
            (gridReady)="onGridReady($event);" 
            (window:resize)="sizeToFit()" 
            [rowHeight]="rowHeight"
            [animateRows]="true"
            [defaultColDef]="defaultColDef" 
            [paginationPageSize]="paginationPageSize"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData"
             
            [pagination]="true" 
            [suppressPaginationPanel] = "true"
            [headerHeight]="40"
            [domLayout]="domLayout"
            (paginationChanged)="onPaginationChanged()"
        >       
        </ag-grid-angular>
    </div>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf = "rowCount > 0">
        <div >
            <div>
                <label class="page-drpdwn-label mx-2">Records/Page: </label>
                <select style="height: 30px;" (change)="onPageSizeChanged($event)">
                    <option value="5" selected>5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="ALL">ALL</option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div>Viewing Records</div>
            <mat-icon (click)="goToFirstPage()" class="text-primary"
            [ngClass]="{'disable_icon':getCurrentPage() === true,'enable_icon':getCurrentPage()!== true}"
                >first_page</mat-icon>
            <mat-icon (click)="goToPreviousPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage() === true,'enable_icon':getCurrentPage()!== true}"
                >fast_rewind</mat-icon>
            <div>
                (
                <span class="value" id="lbCurrentPage"></span>-
                <span class="value" id="lbTotalPages"></span>)
                <span>OF {{rowCount}}</span>
            </div>
            <mat-icon (click)="goToNexpage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage() === true,'enable_icon':isLastPage()!== true}"                
                >fast_forward</mat-icon>
            <mat-icon (click)="goToLastPage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage() === true,'enable_icon':isLastPage()!== true}"
                >last_page</mat-icon>
        </div>
    </div>
    <button (click)="closeDialog()" class="black-button close-btn ">&times;</button>
</div>
