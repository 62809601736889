import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root',
})
export class PackageConversionService {
  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService
  ) {}

  fetchData(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'master/packageconverion/onload';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  doConversion(body:any):Observable<any>{
    const URL = this.constantsService.MASTERCOST+'master/packageconverion/conversion';
    return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  getDropDownData():Observable<any>{
    const URL = this.constantsService.INTERNAL+'common/getPackageType';
    return this.http.get(URL, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }
  

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error');
  }
}
