<div class="popup-container">
  <div class="d-flex justify-content-between align-items-center header">
    <div class="page-title">Import Part List</div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="alert alert-danger m-3 d-flex flex-wrap" *ngIf="errorArray.length > 0">
    <span *ngFor = "let i of errorArray" class="col-12">{{i}}</span>
  </div>
  <div class="filter d-flex mt-3">
    <div class="d-flex align-items-center col-12 px-3">
      <label for="model-code" class="me-3">Model Code</label>
      <input type="text" id="model-code" [(ngModel)]="modelCodeValue" readonly/>
    </div>
    <div class="col-12">
      <small>{{ error }}</small>
      <div class="img-cont col d-flex px-3 gap-2">
        <div class="left col-6">
          <div class="text-center align-items-center">
            <label id="selectedFileName">{{ fileName }}</label>
          </div>
          <label for="fileInput" class="vpacs-btn-table my-1" style="width: 150px">CHOOSE FILE
            <input type="file" id="fileInput" #fileInput accept=".xlsx" style="display: none" (click)="clearFile()" (change)="fileChange($event)"/>
          </label>
        </div>
      </div>
      <div class="text-secondary px-4 subtitle col-12">
        Please change the name of the PartList file to PartList_{{this.data.modelcode}}.xlsx
      </div>
    </div>
  </div>
</div>
<div class="footer-dialog d-flex px-3" style="height: 50px">
  <div mat-dialog-close class="mx-3 cancel">Cancel</div>
  <button class="black-button mx-3" [disabled]="disableNext" (click)="nextBtn()">
    NEXT
  </button>
</div>