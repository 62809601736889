<div class="popup-container">
    <header>
        <label class="close black-button" (click)="onNoClick()">X</label>

        <div class="d-flex justify-content-between align-items-center m-2 mx-0 header">
            <div class="page-title">Replace Part Confirm</div>
            <div>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
       
    </header>

    <div class="container">
        <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
            <div *ngFor="let message of errorList" class="errormsg">
                <div class="message-container">
                    <span class="message-text">{{ message }}</span>
                </div>
            </div>
        </div>
        <div class="row row-cols-6 m-2 align-items-start">
            <div class="div col d-flex ">Plant</div>
            <div class="div col d-flex ">Part Number</div>
            <div class="div col d-flex ">Supplier</div>
            <div class="div col d-flex ">Package Start Date</div>
            <div class="radCont div col d-flex ">
                <input type="radio" [(ngModel)]="radioVal" id="op1" [value]=1 (change)="slt($event)"><label for="op1">All Model</label></div>
                <div class="radCont div col d-flex ">
                <input type="radio" [(ngModel)]="radioVal"  [value]=0 (change)="slt($event)"><label>Select Model</label>
            </div>
            <div class="w-100"></div>
            <div class="text-black col d-flex">{{this.dialog.params.plant}}</div>
            <div class="text-black col d-flex">{{this.dialog.params.partnumber}}</div>
            <div class="text-black col d-flex">{{this.dialog.params.supplier}}</div>
            <div class="text-black col d-flex">{{this.strtDate}}</div>

        </div>
    </div>

    
    
    <div class="m-3 p-0 mb-0">
      
        <label class="gridcaption">Current</label>
        <div class=" vpacs-shadow grid-agg px-0">
            <ag-grid-angular class="ag-theme-balham grid-items" style="width: 100%;height:200px"
                (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef"
                [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true"
                (gridReady)="onGridReady($event)"[rowSelection]="'multiple'"
                [suppressPaginationPanel]="true" [headerHeight]="40" [overlayLoadingTemplate]="overlayLoadingTemplate"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" (selectionChanged)="onSelectionChanged($event)" [isRowSelectable]="isRowSelectable">
            </ag-grid-angular>
        </div>
   
    </div>

    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-3 pt-2 pb-3">
        <button class="cancel me-3 roboto-bold" (click)="onNoClick()"> CANCEL </button>
        <button class="submit black-button me-3 roboto-bold" (click)="update()">UPDATE</button>
    </div>
    
</div>