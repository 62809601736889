<div class="topnav">
    <div class="logo d-flex align-items-center px-3">
        <img src="../../../assets/images/Toyota_logo.png" width="35px" height="30px" alt="logo" /> 
        <div class="text px-2">
            <span>VPACS</span>
            <span>VISUAL PACKAGING CONTROL SYSTEM</span>
        </div>
    </div>
    <div class="carousel-navbar col-7 d-flex align-items-center" *ngIf="unauthorized === false">
        <mat-icon class="nav-button" (click)="scrollLeft()" *ngIf="scrollOffset !== 0" >arrow_back_ios</mat-icon>
        <div class="carousel-wrapper" #carouselWrapper>
            <ul class="menu-items" [style.transform]="'translateX(-' + scrollOffset + 'px)'" style="transition: all 0.2s ease;">
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button>
                        <mat-icon aria-hidden="false" routerLink="/internal">home</mat-icon>
                    </div>        
                </li> 
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu" (click)="clearTrackingFilter()">
                        Tracking
                        <mat-icon>expand_more</mat-icon>
                        <mat-menu #belowMenu="matMenu" yPosition="below">
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/tracking-list">Tracking List</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/part-list" (click)="clearPartListFilter()">Part list</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/model-list" (click)="clearModelFilter()">model list</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/tracking-summary">tracking summary</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/internal-approvement" (click)="clearInternalFilter()">Internal approval list</div>
                            <div class="{{check.hzapprovelist ? 'links' : 'links disabled'}}" routerLink="/hazmat-approve-list" (click)="clearHazmatFilter()">Hazmat approval list</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/shipment-list">shipment list</div>
                            <div class="{{check.tracking ? 'links' : 'links disabled'}}" routerLink="/discripency-list" (click)="clearDiscFilter()">discrepancy list</div>
                        </mat-menu>
                    </div>
                </li>    
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu2" (click)="clearRetainedFilters()">cost management<mat-icon>expand_more</mat-icon>
                        <mat-menu #belowMenu2="matMenu" yPosition="below">
                            <div class="{{check.cost ? 'links' : 'links disabled'}}" routerLink="/auth-list">auth List</div>
                            <div class="{{check.cost ? 'links' : 'links disabled'}}" routerLink="/backup-auth-list">backup auth list</div>
                            <div class="{{check.cost ? 'links' : 'links disabled'}}" routerLink="/ringi-list">ringi list</div>
                        </mat-menu>
                    </div>
                </li>
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu3">Quantity Control<mat-icon>expand_more</mat-icon>
                        <mat-menu #belowMenu3="matMenu" yPosition="below">
                            <div class="{{check.qty ? 'links' : 'links disabled'}}" routerLink="/quantityConrtol">Quantity Control</div>
                        </mat-menu>
                    </div>
                </li>
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu4">Authorize<mat-icon>expand_more</mat-icon>
                        <mat-menu #belowMenu4="matMenu" yPosition="below">
                            <div class="{{check.decision ? 'links' : 'links disabled'}}" routerLink="/decision-list">Decision List</div>
                            <div class="{{check.pur ? 'links' : 'links disabled'}}" routerLink="/pur-decision-list" (click)="clearPurDecisionFilter()">Pur Decision list</div>
                        </mat-menu>
                    </div>
                </li>
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" (click)="clearMasterMainFilter()" mat-button [matMenuTriggerFor]="belowMenu5">Master Maintenance<mat-icon>expand_more</mat-icon>
                        <mat-menu #belowMenu5="matMenu" yPosition="below">
                            <div class="{{check.pkg ? 'links' : 'links disabled'}}" routerLink="/package-maintenance" (click)="clearPackageFilter()">Package</div>
                            <div class="{{check.pkg ? 'links' : 'links disabled'}}" routerLink="/master-package">Package Conversion</div>
                            <div class="{{check.pkg ? 'links' : 'links disabled'}}" routerLink="/package-type">Package Type</div>
                            <div class="{{check.pkg ? 'links' : 'links disabled'}}" routerLink="/materialMaintenance">Material</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/plant-Maintenance">Plant</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/division-master">Division</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/supplier-maintenance">supplier</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/user-maintenance">user</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/master-role">Role</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/supplier-plant">Supplier Plant</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/package-fabricator">Package Fabricator</div>
                            <div class="{{check.user ? 'links' : 'links disabled'}}" routerLink="/exchange-rate">Exchange Rate</div>
                        </mat-menu>
                    </div>
                </li>
                <li routerLinkActive="active">
                    <div class="d-flex align-items-center" mat-button routerLink="/int-documentation">Documentation</div>
                </li>      
            </ul>
        </div>
        <mat-icon class="nav-button" (click)="scrollRight()" *ngIf="scrollOffset !== maxScrollOffset && maxScrollOffset > 99">arrow_forward_ios</mat-icon>
    </div>
    <div class="user" *ngIf="userInfo">
        <div class="avatar">{{userInfo.firstname.charAt(0)}}{{userInfo.lastname.charAt(0)}}</div>
        <div class="name_role ">
                <span class="d-flex align-items-center" mat-button [matMenuTriggerFor]="belowMenu8">{{userInfo.firstname}}
                <mat-icon class="mx-2 drop-ico" >expand_more</mat-icon>
                <mat-menu #belowMenu8="matMenu" xPosition="before">
                    <div mat-menu-item *ngFor="let x of rolesFromLocal.slice(1)" (click)="changeRole(x)">{{x}}</div>
                    <button mat-menu-item (click)="logOut()"><mat-icon>logout</mat-icon>LogOut</button>
                </mat-menu>
            </span>
            <span>{{userRole}}</span>
        </div>
    </div>
</div>
