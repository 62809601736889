<p class="page-title p-1 px-3">Select Back-Up Define</p>
<div class="filter d-flex" (keyup.enter)="onSearch()">
    <div class="d-flex  pe-5 align-items-center">
        <label for="step">Plant</label>
        <mat-select [(ngModel)]="searchValue" class="mat-sel pt-2" disableOptionCentering>
            <mat-option *ngFor="let s of search" [value]="s.value">{{s.value}}</mat-option>
        </mat-select>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="">Model</label>
        <input type="text" [(ngModel)]="model" />
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="">Part#</label>
        <input type="text" id="" [(ngModel)]="part" />
    </div>
    <div class="d-flex pe-5 align-items-center">
        <input type="button" value="Search" class="black-button" style="flex:0.2" (click)="onSearch()" />
    </div>
</div>
<div class="legend my-3 mx-4">

</div>
<div class="grid-agg grid-select">
    <ag-grid-angular style="width:100%; height: 100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
        (window:resize)="sizeToFit()" [rowHeight]="rowHeight" [animateRows]="true" [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true"
        [pagination]="true" [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40" [floatingFiltersHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [domLayout]="domLayout" (paginationChanged)="onPaginationChanged()">
    </ag-grid-angular>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf="rowCount >0">
        <div >
            <div>
                <label class="page-drpdwn-label mx-2">Records/Page: </label>
                <select style="height: 30px;" (change)="onPageSizeChanged($event)" id="pagination">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50" selected>50</option>
                    <option value="{{rowCount}}">ALL</option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div>Viewing Records</div>
            <mat-icon (click)="goToFirstPage()" class="text-primary"
            [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >first_page</mat-icon>
            <mat-icon (click)="goToPreviousPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >fast_rewind</mat-icon>
            <div>
                (
                <span class="value" id="lbCurrentPage"></span>-
                <span class="value" id="lbTotalPages"></span>)
                <span>OF {{rowCount}}</span>
            </div>
            <mat-icon (click)="goToNexpage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"                
                >fast_forward</mat-icon>
            <mat-icon (click)="goToLastPage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"
                >last_page</mat-icon>
        </div>
    </div>
    <!-- {{currentPageForTotalPage}}-{{totalPage}} of({{rowCount}} -->
    <router-outlet></router-outlet>