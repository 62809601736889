<p class="page-title p-1 px-3">Documentation</p>
    
<div class="grid-agg grid-height-doc-ext">
    <ag-grid-angular style="width:100%; height: 100%"
         class="ag-theme-balham"
        (gridReady)="onGridReady($event);" 
        (window:resize)="sizeToFit()" 
        [animateRows]="true"
        [defaultColDef]="defaultColDef" 
        [columnDefs]="columnDefs" 
        [rowData]="displayRowData"
        [suppressCellSelection]= "true"
        [pagination]="true" 
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel] = "true"
        [icons]="icons"
        [headerHeight]="40"
        [floatingFiltersHeight]="40"
        [accentedSort] = "true"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (paginationChanged)="onPaginationChanged()"
        (filterChanged)="onFilterChanged($event)"
        >       
    </ag-grid-angular>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf="rowCount >0">
        <div >
            <div>
                <label for="pagination" class="page-drpdwn-label mx-2">Records/Page: </label>
                <select id="pagination" style="height: 30px;" (change)="onPageSizeChanged($event)">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20" selected>20</option>
                    <option value="50">50</option>
                    <option value="{{rowCount}}">ALL</option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div>Viewing Records</div>
            <mat-icon (click)="goToFirstPage()" class="text-primary"
            [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >first_page</mat-icon>
            <mat-icon (click)="goToPreviousPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >fast_rewind</mat-icon>
            <div>
                (
                <span class="value" id="lbCurrentPage"></span>-
                <span class="value" id="lbTotalPages"></span>)
                <span>OF {{rowCount}}</span>
            </div>
            <mat-icon (click)="goToNexpage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"                
                >fast_forward</mat-icon>
            <mat-icon (click)="goToLastPage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"
                >last_page</mat-icon>
        </div>        
</div>

