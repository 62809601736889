import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="text"]'
})
export class DisableSpecialCharactersDirective {

  constructor(private readonly el:ElementRef) { }
  
  @HostListener('keydown',['$event'])
  onKeyDown(event:KeyboardEvent){
    const forbiddenCharacters = ['&','?',"'","--"];
    if(forbiddenCharacters.includes(event.key)){
      event.preventDefault();
    }
  }

}
