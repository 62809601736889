
<div class="d-flex justify-content-between align-items-center m-2">
  <p class="page-title">Exchange Rate</p> 
  <div>
  <button class="black-button" [disabled]="!isButtonAddActive" (click)="addPackgeType()">Add</button>
  <button class="help-btn"(click)="help()">Help</button>
</div>
</div>
<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
  <span *ngFor="let message of errorList">{{message}}</span>
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="isSearchVisible">
    <div class="filter mx-1 d-flex">
        
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
            <label for="plant" class="col-4">Type</label>
            <mat-select id="supplier" class="mat-sel pt-1"[(ngModel)]="type">
                <mat-option *ngFor="let type of types" [value]="type.value">{{type.text}}</mat-option>
            </mat-select>
        </div>
      
      <div class="d-flex  justify-content-end col-lg-1 col-md-6 col-12 mx-1 ">
          <button class="black-button" (click)="onSearch()">Search</button>
      </div>
            
    </div>
  </div>

  <div class="vpacs-shadow m-2 py-2 px-2">
    <div class="grid-agg mt-3 grid-height-exchange-rate">
      <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="false"
        [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40" [domLayout]="domLayout">
      </ag-grid-angular>              
  </div>