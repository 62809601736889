const position = "center-center"

const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
	"bgsColor": "red",
	"bgsOpacity": 0.5,
	"bgsPosition": "bottom-right",
	"bgsSize": 60,
	"bgsType": "ball-spin-clockwise",
	"blur": 5,
	"delay": 0,
	"fastFadeOut": true,
	"fgsColor": "red",
	"fgsPosition": position,
	"fgsSize": 60,
	"fgsType": "ball-spin-clockwise",
	"gap": 24,
	"logoPosition": position,
	"logoSize": 120,
	"logoUrl": "",
	"masterLoaderId": "master",
	"overlayBorderRadius": "0",
	"overlayColor": "rgba(40, 40, 40, 0.8)",
	"pbColor": "red",
	"pbDirection": "ltr",
	"pbThickness": 3,
	"hasProgressBar": true,
	"text": "",
	"textColor": "#FFFFFF",
	"textPosition": position,
	"maxTime": -1,
	"minTime": 300
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalRoutingModule } from './internal-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '../app.component';
import { AddPkgTypeComponent } from './internal-vpacs-dialogs/master-maintenance/package-type/add-pkg-type/add-pkg-type.component';
import { ImportModelListComponent } from './internal-vpacs-dialogs/import-model-list/import-model-list.component';
import { ModelListAddComponent } from './internal-vpacs-dialogs/tracking/model-list-add/model-list-add.component';
import { HomeComponent } from './internal-vpacs/home/home.component';
import { PackageTypeComponent } from './internal-vpacs/master-maintenance/package-type/package-type.component';
import { ShipmentListComponent } from './internal-vpacs/tracking/shipment-list/shipment-list.component';
import { ShipmentListDetailsComponent } from './internal-vpacs/tracking/shipment-list/shipmentlist-details/shipmentlist-details.component';
import { DeliveryBtnComponent } from './internal-vpacs-dialogs/auth-list/delivery-btn/delivery-btn.component';
import { SetPoBtnComponent } from './internal-vpacs-dialogs/auth-list/set-po-btn/set-po-btn.component';
import { PlantMaintenanceComponent } from './internal-vpacs/master-maintenance/plant-maintenance/plant-maintenance.component';
import { AddPlantComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/add-plant/add-plant.component';
import { DockMaintenanceComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/dock-maintenance/dock-maintenance.component';
import { DockMaintenanceBtnCellRender } from './internal-Vpacs-gridrender/master-maintenance/dock-maintenance-btn-cell-render';
import { AddPkgFabricatorComponent } from './internal-vpacs-dialogs/add-pkg-fabricator/add-pkg-fabricator.component';
import { MasterMaintenanceRoleComponent } from './internal-vpacs/master-maintenance/master-maintenance-role/master-maintenance-role.component';
import { AddRoleMasterComponent } from './internal-vpacs-dialogs/master-maintenance/role-maintenance/add-role-master/add-role-master.component';
import { DiscrepancyListAddPartComponent } from './internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-list-add-part/discrepancy-list-add-part.component';
import { DiscrepancySelectPartComponent } from './internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-select-part/discrepancy-select-part.component';
import { PartListSelectComponent } from './internal-vpacs-dialogs/tracking/tracking-part-list/part-list-select/part-list-select.component';
import { PartListSupplierCodeComponent } from './internal-vpacs-dialogs/tracking/tracking-part-list/part-list-supplier-code/part-list-supplier.component';
import { PartListComponent } from './internal-vpacs/tracking/part-list/part-list.component';
import { PartListBackupComponent } from './internal-vpacs/tracking/part-list/part-list-backup/part-list-backup.component';
import { PartListPpfComponent } from './internal-vpacs/tracking/part-list/part-list-ppf/part-list-ppf.component';
import { DiscrepancyListComponent } from './internal-vpacs/tracking/discrepancy-list/discrepancy-list.component';
import { DescripencySelectComponent } from './internal-vpacs/tracking/discrepancy-list/descripency-select/descripency-select.component';
import { AddDockComponent } from './internal-vpacs-dialogs/add-dock/add-dock.component';
import { PlantSysdaysComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/plant-sysdays/plant-sysdays.component';
import { AuthSelectComponent } from './internal-vpacs-dialogs/tracking/auth-select/auth-select.component';
import { ShipmentRackComponent } from './internal-vpacs-dialogs/tracking/shipment-rack/shipment-rack.component';
import { ReceivementRackComponent } from './internal-vpacs-dialogs/tracking/receivement-rack/receivement-rack.component';
import { TrackingListComponent } from './internal-vpacs/tracking/tracking-list/tracking-list.component';
import { TrackingHistoryComponent } from './internal-vpacs/tracking/tracking-list/tracking-history/tracking-history.component';
import { TrackingCorrectionComponent } from './internal-vpacs/tracking/tracking-list/tracking-correction/tracking-correction.component';
import { CreateRcafComponent } from './internal-vpacs/tracking/tracking-list/create-rcaf/create-rcaf.component';
import { AddRingiComponent } from './internal-vpacs-dialogs/cost-management/add-ringi/add-ringi.component';
import { DecisionListComponent } from './internal-vpacs/authorize/decision-list/decision-list.component';
import { PURDecisionListComponent } from './internal-vpacs/authorize/pur-decision-list/pur-decision-list.component';
import { AuthListComponent } from './internal-vpacs/cost-management/auth-list/auth-list.component';
import { RingiDetailComponent } from './internal-vpacs/cost-management/ringi-list/ringi-detail/ringi-detail.component';
import { RingiListComponent } from './internal-vpacs/cost-management/ringi-list/ringi-list.component';
import { ShipmentCompanyComponent } from './internal-vpacs-dialogs/tracking/shipment-company/shipment-company.component';
import { ImportEstimateCostComponent } from './internal-vpacs-dialogs/cost-management/add-ringi/import-estimate-cost/import-estimate-cost.component';
import { ImportRingiComponent } from './internal-vpacs-dialogs/cost-management/add-ringi/import-ringi/import-ringi.component';
import { UpdateRingiComponent } from './internal-vpacs-dialogs/cost-management/add-ringi/update-ringi/update-ringi.component';
import { TrackingSummaryComponent } from './internal-vpacs/tracking/tracking-summary/tracking-summary.component';
import { BackupAuthListComponent } from './internal-vpacs/cost-management/backup-auth-list/backup-auth-list.component';
import { SupplierInfoComponent } from './internal-vpacs-dialogs/tracking/supplier-info/supplier-info.component';
import { UpdateFabricatorComponent } from './internal-vpacs-dialogs/tracking/update-fabricator/update-fabricator.component';
import { SelectSpecialistComponent } from './internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { ShipmentHistoryComponent } from './internal-vpacs-dialogs/tracking/shipment-history/shipment-history.component';
import { BackupAuthDetailsComponent } from './internal-vpacs/cost-management/backup-auth-list/backup-auth-details/backup-auth-details.component';
import { UpdateDueDateComponent } from './internal-vpacs-dialogs/tracking/update-due-date/update-due-date.component';
import { PkgFabMaintenanceComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/pkg-fab-maintenance.component';
import { AddSupComponent } from './internal-vpacs-dialogs/master-maintenance/Supplie-Maintenance/add-sup/add-sup.component';
import { UpdateSupComponent } from './internal-vpacs-dialogs/master-maintenance/Supplie-Maintenance/update-sup/update-sup.component';
import { SupplierMaintenanceComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/supplier-maintenance.component';
import { ExcelDlComponent } from './internal-vpacs-dialogs/auth-list/excel-dl/excel-dl.component';
import{PackageMaintenanceComponent} from './internal-vpacs/master-maintenance/package-maintenance/package-maintenance.component';
import { ImportPackageCostComponent } from './internal-vpacs-dialogs/master-maintenance/import-package-cost/import-package-cost.component';
import { ImportPackageDataComponent } from './internal-vpacs-dialogs/master-maintenance/import-package-data/import-package-data.component';
import { PackageMaintenanceCostComponent } from './internal-vpacs-dialogs/master-maintenance/package-maintenance-cost/package-maintenance-cost.component';
import { UpdatePackageComponent } from './internal-vpacs/master-maintenance/package-maintenance/update-package/update-package.component';
import { SetPoComponent } from './internal-vpacs-dialogs/cost-management/backup-auth-list/set-po/set-po.component';
import { SelectRackComponent } from'./internal-vpacs-dialogs/tracking/select-rack/select-rack.component';
import { ImportDataComponent } from './internal-vpacs-dialogs/master-maintenance/supplier-plant/import-data/import-data.component';
import { UpdateDataComponent } from './internal-vpacs-dialogs/master-maintenance/supplier-plant/update-data/update-data.component';
import { SupplierPlantComponent } from './internal-vpacs/master-maintenance/supplier-plant/supplier-plant.component';
import { CalculationComponent } from './internal-vpacs-dialogs/master-maintenance/calculation/calculation.component';
import { AddPackageCostComponent } from './internal-vpacs-dialogs/master-maintenance/add-package-cost/add-package-cost.component';
import { RegisterPackageEstimateComponent } from './internal-vpacs-dialogs/master-maintenance/register-package-estimate/register-package-estimate.component';
import { CostCenterComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/cost-center/cost-center.component';
import { AddCostComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/add-cost/add-cost.component';
import { BudgetMaintenanceComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/budget-maintenance/budget-maintenance.component';
import { AddBudgetComponent } from './internal-vpacs-dialogs/master-maintenance/plant-maintenance/add-budget/add-budget.component';
import { AuthLinkComponent } from './internal-vpacs/cost-management/auth-list/auth-link/auth-link.component';
import { SupContactComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/sup-contact/sup-contact.component';
import { UpdateContactComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/update-contact/update-contact.component';
import { DocumentationComponent } from './internal-vpacs/standard-reports/documentation/documentation.component';
import { BenchMarkComponent } from './internal-vpacs/tracking/tracking-list/bench-mark/bench-mark.component';
import { MaterialComponent } from './internal-vpacs/master-maintenance/material/material.component';
import { QuantityComponent } from './internal-vpacs/quantity-control/quantity/quantity.component';
import { FabContComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/fab-cont/fab-cont.component';
import { PkgContComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/pkg-cont/pkg-cont.component';
import { DeleteButtonComponent } from './internal-Vpacs-gridrender/standard-report/delete-btn';
import { SiDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-dept-approval/si-dept-approval.component';
import { PackageConversionComponent } from './internal-vpacs/master-maintenance/package-conversion/package-conversion.component';
import {PaginationComponent} from './pagination/pagination.component';
import { AddDocumentComponent } from './internal-vpacs-dialogs/add-document/add-document.component'
import { InternalApprovementListComponent } from './internal-vpacs/tracking/internal-approvement-list/internal-approvement-list.component';
import { CompareModelListComponent } from './internal-vpacs/tracking/model-list/compare-model-list/compare-model-list.component';
import { ModelListComponent } from './internal-vpacs/tracking/model-list/model-list.component';
import { ModellistConnectComponent } from './internal-vpacs/tracking/model-list/modellist-connect/modellist-connect.component';
import { DetailAuthBtnComponent } from './internal-vpacs-dialogs/auth-list/detail-auth/detail-auth.component';
import { AddMaterialComponent } from './internal-vpacs-dialogs/add-material/add-material.component';
import { SendPpfComponent } from './internal-vpacs/tracking/tracking-list/send-ppf/send-ppf.component';
import { SelectLeaderComponent } from './internal-vpacs-dialogs/tracking/select-leader/select-leader.component';
import { LegendsComponent } from './internal-vpacs-dialogs/tracking/legends/legends.component';
import { QuantityButtomComponent } from './internal-Vpacs-gridrender/quantity/quantity-control';
import { QuantityDetailComponent } from './internal-vpacs/quantity-control/quantity-detail/quantity-detail.component';
import { QuantityLogComponent } from './internal-vpacs/quantity-control/quantity-log/quantity-log.component';
import { ReleaseFollowerComponent } from './internal-vpacs-dialogs/tracking/release-follower/release-follower.component';
import { BackupTempContainerComponent } from './internal-vpacs/tracking/tracking-list/backup-temp-container/backup-temp-container.component';
import { ExchangeRateComponent } from './internal-vpacs/master-maintenance/exchange-rate/exchange-rate.component';
import { AddExchangeComponent } from './internal-vpacs-dialogs/master-maintenance/exchange-rate/add-exchange/add-exchange.component';
import { DeptApproveComponent } from './internal-vpacs/tracking/internal-approvement-list/dept-approve/dept-approve.component';
import { ConceptCompareComponent } from './internal-vpacs/tracking/internal-approvement-list/concept-compare/concept-compare.component';
import { DivisionMasterComponent } from './internal-vpacs/master-maintenance/division-master/division-master.component';
import { SelectPpfComponent } from './internal-vpacs/tracking/internal-approvement-list/select-ppf/select-ppf.component';
import { UserMaintenanceComponent } from './internal-vpacs/master-maintenance/user-maintenance/user-maintenance.component';
import { UpdateUserComponent } from './internal-vpacs-dialogs/master-maintenance/update-user/update-user.component';
import { PpfBtnComponent } from './internal-Vpacs-gridrender/tracking/tracking-list/ppf-btn/ppf-btn.component';
import { UpdatePpfComponent } from './internal-vpacs/tracking/tracking-list/update-ppf/update-ppf.component';
import { ActionBtnComponent } from './internal-Vpacs-gridrender/tracking/tracking-list/action-btn/action-btn.component';
import { DialogDivisionComponent } from './internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';
import { ConnectallModelListComponent } from './internal-vpacs-dialogs/tracking/connectall-model-list/connectall-model-list.component';
import { SqDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/sq-dept-approval/sq-dept-approval.component';
import { RcafCostComponent } from './internal-Vpacs-gridrender/tracking/tracking-list/rcaf-cost/rcaf-cost.component';
import { RcafQuantityComponent } from './internal-Vpacs-gridrender/tracking/tracking-list/rcaf-quantity/rcaf-quantity.component';
import { RcafUnitComponent } from './internal-Vpacs-gridrender/tracking/tracking-list/rcaf-unit/rcaf-unit.component';
import { NecessaryApprovedItemsComponent } from './internal-vpacs-dialogs/home/necessary-approved-items/necessary-approved-items.component';
import { LogDetailsComponent } from './internal-vpacs/home/log-details/log-details.component';
import { PpfUpdateComponent } from './internal-vpacs/tracking/part-list/ppf-update/ppf-update.component';
import { UpdateConfirmComponent } from './internal-vpacs/tracking/part-list/ppf-update/update-confirm/update-confirm.component';
import { WarnGridComponent } from './internal-Vpacs-gridrender/home/tracking-grid/warn-grid/warn-grid.component';
import { GoodGridComponent } from './internal-Vpacs-gridrender/home/tracking-grid/good-grid/good-grid.component';
import { DelayGridComponent } from './internal-Vpacs-gridrender/home/tracking-grid/delay-grid/delay-grid.component';
import { LogRendererComponent } from './internal-Vpacs-gridrender/home/log-renderer/log-renderer.component';
import { UpdatePpfRendererComponent } from './internal-Vpacs-gridrender/home/update-ppf-renderer/update-ppf-renderer.component';
import { ConfirmImportComponent } from './internal-vpacs/tracking/model-list/confirm-import/confirm-import.component';
import { ImportResultComponent } from './internal-vpacs/tracking/model-list/import-result/import-result.component';
import { NewPartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/new-part-confirmation/new-part-confirmation.component';
import { ReplacePartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/replace-part-confirmation/replace-part-confirmation.component';
import { DeletePartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/delete-part-confirmation/delete-part-confirmation.component';
import { DetailsBtnComponent } from './internal-vpacs-dialogs/import-model-list/details-btn/details-btn.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NotificationComponent } from './notification/notification.component';
import { MovePackageComponent } from './internal-vpacs/quantity-control/move-package/move-package.component';
import { DisposePackageComponent } from './internal-vpacs/quantity-control/dispose-package/dispose-package.component';
import { MaintenancePackageComponent } from './internal-vpacs/quantity-control/maintenance-package/maintenance-package.component';
import { AdjustQuantityComponent } from './internal-vpacs/quantity-control/adjust-quantity/adjust-quantity.component';
import { UpdateFabComponent } from './internal-vpacs-dialogs/authorize/update-fab/update-fab.component';
import { ConfirmationComponent } from './internal-vpacs-dialogs/auth-list/confirmation/confirmation.component';
import { CancelAuthComponent } from './internal-Vpacs-gridrender/cost-management/auth-list/cancel-auth/cancel-auth.component';
import { DeleteAuthComponent } from './internal-Vpacs-gridrender/cost-management/auth-list/delete-auth/delete-auth.component';
import { CheckBoxDetailRendered } from './internal-Vpacs-gridrender/authorize/checkBoxDetail';
import { PurCheckBox } from './internal-Vpacs-gridrender/authorize/PurCheckBox';
import { InternCellRenderer } from './internal-Vpacs-gridrender/tracking/internal-approvement/link-cell-render';
import { SummaryRouterLinkCellRenderer } from './internal-Vpacs-gridrender/tracking/tracking-summary/summary-router-link-cell-renderer';
import { ImportCellRendererComponent } from './internal-Vpacs-gridrender/tracking/model-list/import-radio-btn-cell-render';
import { CostAuthListBtnCellRender } from './internal-Vpacs-gridrender/cost-management/auth-list/cost-auth-list-btn-cell-render';
import { DiscrepencyBtnCellRender } from './internal-Vpacs-gridrender/tracking/tracking-discrepancy/descripency';
import { DiscrepancyAddComponent } from './internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-add/discrepancy-add.component';
import { DiscrepancyListRegpartsizeComponent } from './internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-list-regpartsize/discrepancy-list-regpartsize.component';
import { BackupAuthListBtnCellRenderer } from './internal-Vpacs-gridrender/cost-management/backup-auth-list/backup-auth-list-btn-cell-renderer';
import { RouterLinkCellRenderer } from './internal-Vpacs-gridrender/tracking/tracking-list/router-link-cell-renderer';
import { UpdateSupplierInfoComponent } from './internal-vpacs-dialogs/tracking/supplier-info/update-supplier-info/update-supplier-info.component';
import { BroadcastCalcComponent } from './internal-vpacs/tracking/tracking-list/broadcast-calc/broadcast-calc.component';
import { DiscrepancyTempContainerComponent } from './internal-vpacs/tracking/discrepancy-list/discrepancy-temp-container/discrepancy-temp-container.component';
import { LoaderComponent } from './loader/loader.component';
import { BenchMarkImgCellRenderer } from './internal-Vpacs-gridrender/tracking/tracking-list/benchmark-img-cell-renderer';
import { TrialOrderComponent } from './internal-vpacs/tracking/tracking-list/trial-order/trial-order.component';
import { SiPreppfDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-preppf-dept-approval/si-preppf-dept-approval.component';
import { SqBtnCellRenderer } from './internal-Vpacs-gridrender/tracking/tracking-list/sq-btn-cell-renderer';
import { SiFinalppfDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-finalppf-dept-approval/si-finalppf-dept-approval.component';
import { SelectPartComponent } from './internal-vpacs-dialogs/ppf/select-part/select-part.component';
import { PPFSelectRender } from './internal-Vpacs-gridrender/ppf/ppf-select-render';
import { SelectPackageComponent } from './internal-vpacs-dialogs/ppf/select-package/select-package/select-package.component';
import { SelectPkgFabComponent } from './internal-vpacs-dialogs/select-pkg-fab/select-pkg-fab.component';
import { QuestionnaireComponent } from './internal-vpacs/tracking/tracking-list/questionnaire/questionnaire.component';

import { MasterHelpComponent } from './internal-vpacs/master-maintenance/master-help/master-help.component';
import { PartListBtnCellRender } from './internal-Vpacs-gridrender/tracking/tracking-part-list/part-list-btn-cell-render';
import { PurCommentEditor } from './internal-Vpacs-gridrender/authorize/purCommentEditor';
import { SelectPackageCostComponent } from './internal-vpacs-dialogs/select-package-cost/select-package-cost.component';
import { ManualComponent } from './internal-vpacs/master-maintenance/master-help/manual/manual.component';
import { ErrorListComponent } from './internal-vpacs/master-maintenance/master-help/error-list/error-list.component';
import { ForecastBtnCellRender } from './internal-Vpacs-gridrender/cost-management/ringi-list/forecast-btn-cell-render';
import { CalcBtnCellRender } from './internal-Vpacs-gridrender/cost-management/ringi-list/calc-btn-cell-render';
import { HazmatApprovalComponent } from './internal-vpacs/tracking/tracking-list/hazmat-approval/hazmat-approval.component';
import { SiPkgTrialDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-pkg-trial-dept-approval/si-pkg-trial-dept-approval.component';
import { HazmatApprovementListComponent } from './internal-vpacs/tracking/hazmat-approvement-list/hazmat-approvement-list.component';
import { HazmatCellRenderer } from './internal-Vpacs-gridrender/tracking/hazmat-approvement/hz-cell-renderer';
import { ModelCellRendererComponent } from './internal-Vpacs-gridrender/tracking/model-list/model-list-btn-cell-render';
import { PurConfabLinkCellRendererComponent } from './internal-Vpacs-gridrender/authorize/pur-confab-link-cell-renderer';
import { CheckBoxDetailBossRendered } from './internal-Vpacs-gridrender/authorize/checkBoxDetailBoss';
import { SelectTrackPartComponent } from './internal-vpacs/tracking/tracking-list/select-track-part/select-track-part.component';
import { ServicePartsComponent } from './internal-vpacs/tracking/part-list/service-parts/service-parts.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PartListCheckBoxDetailRendered } from './internal-Vpacs-gridrender/tracking/part-list/partListCheckBoxDetailRendered';
import { TrackingListCheckBoxRendered } from './internal-Vpacs-gridrender/tracking/tracking-list/trackingListCheckBoxRender';
import { DisableCharacters } from './internal-vpacs/master-maintenance/disable-Characters.directive';

@NgModule({
  declarations: [
    ModelListComponent,
    PartListComponent,
    ModelListAddComponent,
    TrackingSummaryComponent,
    TrackingListComponent,
    HomeComponent,
    PartListCheckBoxDetailRendered,
    TrackingListCheckBoxRendered,
    DiscrepencyBtnCellRender,
    PartListBtnCellRender,
    ForecastBtnCellRender,
    CalcBtnCellRender,
    RingiListComponent,
    RingiDetailComponent,
    ShipmentListComponent,
    ShipmentListDetailsComponent,
    ModellistConnectComponent,
    ModelCellRendererComponent,
    CompareModelListComponent,
    ImportModelListComponent,
    InternalApprovementListComponent,
    PURDecisionListComponent,
    DecisionListComponent,
    PartListBackupComponent,
    PartListPpfComponent,
    SupplierInfoComponent,
    UpdateFabricatorComponent,
    PartListSelectComponent,
    DisableCharacters,
    DiscrepancyListComponent,
    DescripencySelectComponent,
    DiscrepancyListAddPartComponent,
    ImportEstimateCostComponent,
    ImportRingiComponent,
    UpdateDueDateComponent,
    TrackingHistoryComponent,
    TrackingCorrectionComponent,
    CreateRcafComponent,
    PackageTypeComponent,
    AddPkgTypeComponent,
    UpdateRingiComponent,
    AddRingiComponent,
    ConnectallModelListComponent,
    PartListSupplierCodeComponent,
    ShipmentHistoryComponent,
    AuthListComponent,
    SelectSpecialistComponent,
    BackupAuthListComponent,
    DeliveryBtnComponent,
    SetPoBtnComponent,
    PlantMaintenanceComponent,
    AddPlantComponent,
    DockMaintenanceComponent,
    DockMaintenanceBtnCellRender,
    PkgFabMaintenanceComponent,
    PackageMaintenanceComponent,
    PackageMaintenanceCostComponent,
    UpdatePackageComponent,
    ImportPackageDataComponent,
    ImportPackageCostComponent,
    AddPackageCostComponent,
    RegisterPackageEstimateComponent,
    AddPkgFabricatorComponent,
    SupplierPlantComponent,
    CalculationComponent,
    DiscrepancySelectPartComponent,
    MasterMaintenanceRoleComponent,
    AddRoleMasterComponent,
    AddDockComponent,
    PlantSysdaysComponent,    
    BackupAuthDetailsComponent,  
    SupplierMaintenanceComponent,
    SupContactComponent,
    UpdateContactComponent,
    AddSupComponent,
    UpdateSupComponent,
    ExcelDlComponent,
    SetPoComponent,
    CostCenterComponent,
    AddCostComponent,
    BudgetMaintenanceComponent,
    AddBudgetComponent,
    AuthLinkComponent,
    DocumentationComponent,
    BenchMarkComponent,
    MaterialComponent,
    QuantityComponent,
    PkgContComponent,
    FabContComponent,
    DeleteButtonComponent,
    AuthSelectComponent,
    SiDeptApprovalComponent,
    PackageConversionComponent,
    PaginationComponent,
    ImportDataComponent,
    UpdateDataComponent,
    AddDocumentComponent,
    SendPpfComponent,
    SelectLeaderComponent,
    ShipmentCompanyComponent,
    ShipmentRackComponent,
    SelectRackComponent,
    ReceivementRackComponent,
    DetailAuthBtnComponent,
    AddMaterialComponent,
    LegendsComponent,
    QuantityButtomComponent,
    QuantityDetailComponent,
    QuantityLogComponent,
    ReleaseFollowerComponent,
    BackupTempContainerComponent,
    ExchangeRateComponent,
    AddExchangeComponent,
    DeptApproveComponent,
    ConceptCompareComponent,
    DivisionMasterComponent,
    SelectPpfComponent,
    UserMaintenanceComponent,
    UpdateUserComponent,
    AddExchangeComponent,DivisionMasterComponent, PpfBtnComponent, UpdatePpfComponent, ActionBtnComponent,
    RcafQuantityComponent, 
    RcafUnitComponent, 
    RcafCostComponent, 
    SqDeptApprovalComponent,
    DialogDivisionComponent,
    NecessaryApprovedItemsComponent,
    LogDetailsComponent,
    PpfUpdateComponent,
    UpdateConfirmComponent, 
    WarnGridComponent, 
    GoodGridComponent, 
    SummaryRouterLinkCellRenderer,
    DelayGridComponent, LogRendererComponent, UpdatePpfRendererComponent,
    ConfirmImportComponent, ImportResultComponent, NewPartConfirmationComponent, ReplacePartConfirmationComponent, DeletePartConfirmationComponent, DetailsBtnComponent,
    NotificationComponent,
    MovePackageComponent,
    DisposePackageComponent,
    MaintenancePackageComponent,
    AdjustQuantityComponent,
    UpdateFabComponent,
    ConfirmationComponent,
    CancelAuthComponent,
    DeleteAuthComponent,
    CheckBoxDetailRendered,
    CheckBoxDetailBossRendered,
    PurCheckBox,
    InternCellRenderer,
    HazmatCellRenderer,
    ImportCellRendererComponent,
    CostAuthListBtnCellRender,
    DiscrepancyAddComponent,
    DiscrepancyListRegpartsizeComponent,
    BackupAuthListBtnCellRenderer,
    RouterLinkCellRenderer,
    UpdateSupplierInfoComponent,
    BroadcastCalcComponent,
    DiscrepancyTempContainerComponent,
    LoaderComponent,
    BenchMarkImgCellRenderer,
    TrialOrderComponent,
    SiPreppfDeptApprovalComponent,
    SqBtnCellRenderer,
    SiFinalppfDeptApprovalComponent,
    SelectPartComponent,
    PPFSelectRender,
    SelectPackageComponent,
    SelectPkgFabComponent,
    QuestionnaireComponent,
    PurCommentEditor,
    SelectPackageCostComponent,
    MasterHelpComponent,
    PurCommentEditor,
    ManualComponent,
    ErrorListComponent,
    HazmatApprovalComponent,
    SiPkgTrialDeptApprovalComponent,
    HazmatApprovementListComponent,
    PurConfabLinkCellRendererComponent,
    SelectTrackPartComponent,
    ServicePartsComponent
  ],
  imports: [
    CommonModule,
    InternalRoutingModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatRadioModule,
    MatToolbarModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    DragDropModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class InternalModule { }
