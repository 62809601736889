<div class="d-flex justify-content-between align-items-center mx-3">
  <p class="page-title">Model List</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="haveErrors === true && errorList.length > 0">
  <span *ngFor="let message of errorList">{{ message }}</span>
</div>

<div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
  <span>{{ successMessage }}</span>
  <button type="button" class="btn-close" (click)="Close()"></button>
</div>
<div class="mx-2 p-2" *ngIf="haveAccess">
  <div class="filter mx-1 d-flex" (keyup.enter)="onSearch()">
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="family-code" class="col-4">Family Code</label>
      <input type="text" id="family-code" [(ngModel)]="familyCode" style="width: 100%" />
    </div>
    <div class="d-flex align-items-center me-5"></div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="plant" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
        (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
        style="width: 80%">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{ plants.plant }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="d-flex align-items-center me-5"></div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="sopFrom" class="col-4">SOP From Date</label>
      <input type="date" id="sopFrom" [(ngModel)]="sopFrom" style="width: 100%" (keydown)="onKeyDown($event,'sopFrom')" />
    </div>
    <div class="d-flex align-items-center me-5"></div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="sopTo" class="col-4">SOP To Date</label>
      <input type="date" id="sopTo" [(ngModel)]="sopTo" [min]="sopFrom" style="width: 100%"
      (keydown)="onKeyDown($event,'sopTo')" />
    </div>
    <div class="d-flex align-items-center me-5"></div>

    <div class="d-flex align-items-center col-lg-1 col-md-6 col-12">
      <input type="button" value="search" (click)="onSearch()" class="black-button" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="model-code" class="col-4">Model Code</label>
      <input type="text" id="model-code" [(ngModel)]="modelcode" style="width: 100%" />
    </div>
    <div class="d-flex align-items-center me-5"></div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="model-name" class="col-4">Model Name</label>
      <input type="text" id="model-name" [(ngModel)]="modelName" style="width: 100%" />
    </div>
    <div class="d-flex align-items-center me-5"></div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="eopFrom" class="col-4">EOP From Date</label>
      <input type="date" id="eopFrom" [(ngModel)]="eopFrom" style="width: 100%" (keydown)="onKeyDown($event,'eopFrom')" />
    </div>
    <div class="d-flex align-items-center me-5"></div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-4">
      <label for="eopTo" class="col-4">EOP To Date</label>
      <input type="date" id="eopTo" [(ngModel)]="eopTo" [min]="eopFrom" style="width: 100%" (keydown)="onKeyDown($event,'eopTo')"/>
    </div>
    <div class="d-flex align-items-center me-5"></div>

    <div class="d-flex align-items-center col-lg-1 col-md-6 col-12">
      <input type="button" value="ADD MODEL LIST" class="black-button" (click)="addModellist()"
        [disabled]="accessFlag" />
    </div>
  </div>
</div>
<div class="grid-agg mt-3 grid-height grid-height-model-list mb-5" *ngIf="haveAccess">
  <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
    (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
    [rowData]="displayRowData" [gridOptions]="gridOptions" [suppressCellSelection]="true"
    [autoGroupColumnDef]="autoGroupColumnDef" [pagination]="true" [paginationPageSize]="paginationPageSize"
    [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40"
    [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [floatingFiltersHeight]="40" [domLayout]="domLayout" [groupDefaultExpanded]="groupDefaultExpanded"
    (filterChanged)="onFilterChanged($event)">
  </ag-grid-angular>
  <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
    (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
  </app-pagination>
</div>
<router-outlet></router-outlet>