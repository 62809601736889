<div class="wrapper bg-white">
    <p class="page-title p-2 px-3">Select Auth</p>
    <div class="mat-typography content">
        <mat-accordion>
            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
                <div class="d-flex flex-wrap my-2" (keyup.enter)="onSearch()">

                    <div class="d-flex align-items-center  col-lg-3 col-md-6 col-12 my-2">
                        <label for="auth" class="col-4 mx-2">Auth#</label>
                        <input type="text" id="auth" class="col-3 " [(ngModel)]="auth" />
                    </div>
                    <div class="d-flex align-items-center  col-lg-3 col-md-6 col-12 my-2">
                        <label for="modelCode" class="col-4 mx-2">Model Code</label>
                        <input type="text" id="modelCode" class="col-3 " [(ngModel)]="modelCode" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="part" class="col-4 mx-2">Part#</label>
                        <input type="text" id="part" class="col-6 " [(ngModel)]="part" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <button class="black-button col-4 mx-2" (click)="onSearch()">Search</button>
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Plant" class="col-4 mx-2">Plant</label>
                        <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plant"
                            (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
                            style="width: 80%">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="displayFn.bind(this)">
                            <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{
                                plants.plant }}
                            </mat-option>
                        </mat-autocomplete>

                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="supplier" class="col-4 mx-2">Supplier</label>
                        <input type="text" id="supplier" class="col-5 " [(ngModel)]="supplier" />
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
        <div class="filter d-flex">
            <div class="grid-agg">
                <ag-grid-angular style="width:100%;height:320px;" class="ag-theme-balham grid-items"
                    (gridReady)="onGridReady($event);" (window:resize)="sizeToFit()" [animateRows]="true"
                    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData"
                    [suppressCellSelection]="true" [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="50">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="footer-dialog d-flex">
        <div mat-dialog-close class="mx-3 cancel">Close</div>
    </div>
</div>