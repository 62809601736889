import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalGuard, MsalService } from 'src/app/msal';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router, public dialogRef: MatDialogRef<LogoutComponent>, public msal: MsalGuard, private readonly authService: MsalService,public serv : MsalService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.dialogRef.close(true);
  }

  getSilentToken() {
		const account = this.authService.getAllAccounts()[0];
    const graphurl = 'https://graph.microsoft.com/v1.0/me'
    const scopes: any = this.msal.getScopesForEndpoint(graphurl);
    this.serv.acquireTokenSilent({
      account: account,
      scopes: scopes,
      forceRefresh: true
    }).subscribe({
      next : (res:any) => {
        sessionStorage.setItem('idToken', res?.idToken);
        sessionStorage.setItem('exp', res.idTokenClaims['exp']);
        this.dialogRef.close(false);
      },
      error(err) {
        console.log(err) //NOSONAR
      },
    });
  }
}
