import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UseBackupServiceService } from 'src/app/services/use-backup-service.service';
import { DialogData } from '../../gridrender/use-backup-table-render';

@Component({
  selector: 'app-use-backup-dialog',
  templateUrl: './use-backup-dialog.component.html',
  styleUrls: ['./use-backup-dialog.component.scss']
})
export class UseBackupDialogComponent implements OnInit {
  public accData:any ;
  public endUseForm :any;
  public metaData: any;
  public validForm = true;
  errorMessage = '';
  validationMessage = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, 
              public dialogRef: MatDialogRef<UseBackupDialogComponent>,
              public useBackupService: UseBackupServiceService
              ) { }

  ngOnInit(): void {
    this.metaData = JSON.parse(sessionStorage.getItem('authData') || '{}')
    this.accData = this.data.navData;
    this.endUseForm = new FormGroup({
      entryEndDate: new FormControl('', Validators.required),
      invoice: new FormControl('', Validators.required),
      invoiceName: new FormControl(''),
    })

    this.endUseForm.valueChanges.subscribe((_value:any)=>{
      this.validForm = !this.endUseForm.valid;
    })
  }

  onCancel(): void {
    this.dialogRef.close('success');
  }

  handleUpload(event:any) {
    const file = event.target.files[0];
    const allowedFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf'];
    if(allowedFormats.includes(file.type)){
      this.errorMessage = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          const base64Image:any = reader.result;
          const fileSizeInBytes = base64Image.length * 0.75;
          const fileSizeInKb = fileSizeInBytes/1024;
          if(fileSizeInKb < 2000){
            this.errorMessage = ''
            this.endUseForm.controls.invoice.setValue(reader.result)
            this.endUseForm.controls.invoiceName.setValue(file.name)
          }else{
            this.errorMessage = 'File size should be less than 2mb'
          } 
      };
    }else{
      this.errorMessage = 'File format error [.pdf/.xlsx/.doc]';
    } 
  }

  dateDiffInDays(a:any, b:any) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  validateDates(){
    const sd = new Date(this.metaData.sd);
    const nd = new Date(this.endUseForm.get('entryEndDate').value);
    if(sd < nd){
      this.validationMessage = '';
      const result = this.dateDiffInDays(sd,nd);
      if(result < 28){
        this.validationMessage = '';
        return true;
      }else{
        this.validationMessage = 'Date Range Error. Date Range must be less than 4 Weeks[28 days]';
        return false;
      }
    }else{
      this.validationMessage = 'Start Date must be before End Date';
      return false;
    }
  }

  onSubmit(){ 
    if(!this.errorMessage && this.validateDates()){
      const queryData:any = {};
      queryData['endDate'] = this.endUseForm.value.entryEndDate.split('-').join('/');
      queryData['invoice'] = this.endUseForm.value.invoice.split(',')[1];
      queryData['responsibility'] = this.accData.auth.RESPONSIBILITY;
      queryData['plantCode'] = this.accData.auth.PLANTCODE;
      queryData['invoiceName'] = this.endUseForm.value.invoiceName;
      this.useBackupService.endUseSubmit(queryData, this.accData.auth.AUTHNUMBER).subscribe({
        next: _data =>{
          this.onCancel();  
        },
      }) 
    }else{
      alert(this.validationMessage)
    }
  }
}
