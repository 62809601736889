import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { DialogDivisionComponent } from '../../internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';

@Component({
  selector: 'app-division-master-btn-cell-render',
  template: `
    <div class="d-flex align-items-center gap-1">
      <button
        class="vpacs-btn-table"
        style="height:30px;"
        (click)="btnClickedHandler($event)"
        [disabled]="this.updateflag || this.deleteflag"
      >
        {{ buttonName }}
      </button>
    </div>
  `,
})
export class DivisionMasterBtnCellRenderComponent implements ICellRendererAngularComp {
  updateflag: any = false;
  deleteflag: any = false;
  constructor(
    public dialog: MatDialog,
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
      this.updateflag = !params.data.updateflag ? true : false;
    } else if (params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
      const used = this.params.data.used ? parseInt(this.params.data.used) : 0;
      this.deleteflag = used > 0 || !params.data.deleteflag ? true : false;
    }
  }

  btnClickedHandler(_event: any) {
    if (this.buttonName === 'UPDATE' && !this.updateflag) {
      const dialogRef = this.dialog.open(DialogDivisionComponent, {
        data: {
          headerName: 'Update',
          olddiv: this.params.data.divcode,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.params?.clicked(this.params.colDef.headerName, result);
      });
    } else if (this.buttonName === 'DELETE' && !this.deleteflag) {
      const dialogRef = this.dialog.open(DialogDivisionComponent, {
        data: {
          headerName: 'Delete',
          olddiv: this.params.data.divcode,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.params.clicked(this.params.colDef.headerName, result);
      });
    }
  }
}
