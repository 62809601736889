import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'select-btn-cell-renderer',
  template: `
    <button 
    class="vpacs-btn-table" style= "height: '30px'"
    (click)="btnClickedHandler()">SELECT</button>
  `,
})

export class PPFSelectBtnRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;    
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data);
  }
}




