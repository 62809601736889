import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteButtonComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/standard-report/delete-btn';
import { DocumentationService } from 'src/app/internal-vpacs/services-internal/standard-reports/documentation.service';
import { AddDocumentComponent } from '../../../internal-vpacs-dialogs/add-document/add-document.component';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface';
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  gridApi: any;
  columnDefs: any[] = [];
  totalPage = 0;
  displayRowData: any[] = [];
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  sortModel: any[] = [];
  pageNumber = 1;
  lastRowIndex = 0;
  gridColumnApi: any;
  deleteDetail: any;
  errorMessage !: ErrorMessage
  apiResponse !: { status: string; message: string; };

  constructor(
    private readonly dialog: MatDialog,
    private readonly documentationService: DocumentationService,
    public message: MatSnackBar,
    public router: Router,
    private readonly spinner: NgxUiLoaderService,
    private readonly state:ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      floatingFilterComponentParams: { suppressFilterButton: true }, 
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      unSortIcon: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
  }
  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res:any) =>{
        if(res){
          this.spinner.start();
          this.apiCall();
          this.getColumndefs();
        }
      },
      error : (_err:any) =>{
        this.errorMessage = {errorCode : `API-${_err.status}`,childErrorCode : _err.status};
        this.spinner.stop();
      }
    });
  }

  getColumndefs(){
    this.columnDefs = [
      {
        headerName: "FILE NAME", 
        field: "filename", 
        cellRendererFramework: DeleteButtonComponent,
        cellRendererParams: {
          clicked: (id: any, filename: any) => {
            this.fileDownload(id, filename)
          },
        },
        minWidth : 300,
        cellStyle: (_params: any) => { return { 'white-space': 'normal' } }
      },
      {
        headerName: "LAST UPDATE",
        field: "lastupdate",
        minWidth: 100,
        sort: null,
        comparator: (valueA:any, valueB:any, _nodeA:any, _nodeB:any, _isInverted:any) => {
          if (new Date(valueA).getTime() === new Date(valueB).getTime()) {
            return 0;
          } else {
            return (new Date(valueA) > new Date(valueB)) ? 1 : -1;
          }
        },
      },
      {
        headerName: "UPDATE PERSON",
        field: "ins_user",
        sort: null,
        minWidth : 100
      },
      {
        headerName: "DESCRIPTION",
        field: "description",
        cellStyle: (_params: any) => { return { 'white-space': 'normal'} },
        sort: null,
        minWidth : 300,
      },
      {
        headerName: "DELETE",
        field: "delete",
        sortable: false,
        minWidth: 80,
        filter : false,
        cellRendererFramework: DeleteButtonComponent,
        cellRendererParams: {
          clicked: (data: any) => {
            this.deleteDetail = data;
            this.addDocument('delete');
          },
        },
      },
    ]
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  apiCall() {
    this.documentationService.loadDocData().subscribe({
      next: (res: any) => {
        const data = res;
        data.forEach((element:any) => {
          element.lastupdate = this.dateFormatter({value : element.lastupdate})
        });
        this.displayRowData = res;
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.errorMessage = {errorCode : `API-EMPTY`,childErrorCode : '400'};
        this.spinner.stop();
      },
    })
  }

  deleteFile(data: any) {
    this.spinner.start();
    const params = {
      "id": data
    }
    this.documentationService.deleteFile(params).subscribe({
      next: (_res: any) => {
        if(_res.status){
          this.spinner.stop();
          this.apiCall();
          this.apiResponse = {status : 'success', message : 'File deleted successfully'};
        }else{
          this.apiResponse = {status : 'failure', message : 'Unable to delete. Please try after sometime'};
        }
        this.clearNotification();
      },
      error: () => {
        this.errorMessage = {errorCode : `API-EMPTY`,childErrorCode : '400'};
        this.spinner.stop();
      },
    })
  }

  clearNotification(){
    setTimeout(() => {
      this.apiResponse = { status: '', message: ''};
    }, 5000);
  }

  dateFormatter(params: any) {
    if (typeof params.value === 'undefined' || params.value === null) {
      return '';
    } else {
      return params?.value
        ? formatDate(params.value, 'MM/dd/YYYY', 'en-US', 'UTC')
        : '';
    }
  }

  fileDownload(id: any, filename: any) {
    this.spinner.start();
    this.documentationService.downloadFile(id, filename).subscribe({
      next: (res: any) => {
        this.spinner.stop();
        window.open(res.body);
        this.apiResponse  = {status : 'success', message : 'File downloaded successfully'};
        this.clearNotification();
      },
      error: () => {
        this.errorMessage = {errorCode : `API-EMPTY`,childErrorCode : '400'};
        this.spinner.stop();
      },
    })
  }

  addDocument(pageName: string) {
    const dialogRef = this.dialog.open(AddDocumentComponent, { data: { pageName: pageName } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.type === 'delete') {
          this.deleteFile(this.deleteDetail)
        } else if (result?.data?.type === 'add') {
          this.apiCall();
          this.apiResponse = {status : 'success', message : 'Document added successfully'};
          this.clearNotification();
        }
      }
    })
  }
  help() {
    this.router.navigate(['./master-help', 6, "Standard Report", 300])
  }
}
