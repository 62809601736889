import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'user-btn-cell-renderer',
  template: `
    <button 
      class="vpacs-btn-table text-uppercase"
      style="height:30px;"
      [disabled]="disableFlag"
      (click)="btnClickedHandler($event)"
    >{{buttonName}}
    </button>
  `,
})

export class UserBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog,) { }
  refresh(params: ICellRendererParams): any {
    if(params && !params.data){
      throw new Error(`Error while refreshing renderer component. INFO-${params}`);
    }
}

  private params: any;
  buttonName: any;
  disableFlag = false;
  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'UPDATE') {
      if(this.params.updateaccess === false){
        this.disableFlag = true;
      }
      this.buttonName = 'UPDATE';
    }else if (params.colDef.headerName === 'Delete') {
      if((this.params.deleteaccess === false) || (Number(this.params.data.used) > 0)){
        this.disableFlag = true;
      }
      this.buttonName = 'DELETE';
    }
  }
  
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.column.colId, this.params.data);
  }
}