import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-initial-load',
  templateUrl: './initial-load.component.html',
})
export class InitialLoadComponent implements OnInit {
  public appType = ''

  constructor(
    public state: ExportStateService,
    public router: Router,
    public constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.state.roleState.subscribe((data: any) => {
      if (data) {
        this.appType = this.state.checkAppType(data);
        if (this.appType === 'Internal') {
          this.router.navigate(['/internal']);
        } else if (this.appType === 'External') {
          //check the user has admin access
          const adminAccess = this.state.checkExternalAccess(data[0]);
          if (adminAccess) {
            this.router.navigate(['/admin-access']);
          } else {
            setTimeout(() => {
              this.router.navigate(['/tracking']);
            }, 1000);
          }
        }
      }
    })
  }
}