import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service';

@Component({
  selector: 'link-cell-renderer',
  template: `
  <button (click)="navigate()" id="hyperLink" class="cmd_tracking_ia vpacs-btn-table" style="font-weight:500; width: 30px; height: 30px" *ngIf="!abtnCheck">{{ text }}</button>
  <a (click)="navigate()" id="hyperLink" style="font-weight:500" *ngIf="!skipFlag && abtnCheck">{{ text }}</a>
  <span *ngIf="skipFlag" style="color:#000; cursor:auto">{{text}}</span>
  `,
})

export class InternCellRenderer implements ICellRendererAngularComp {
  specialistCode: any;
  skipFlag = false;
  abtnCheck = false;
  mode: any;

  constructor(private readonly router: Router,public readonly iaService:InternalApprovementListService, public dialog: MatDialog, private readonly datepipe: DatePipe) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;
  step: any;
  getGroupUserList:any

  agInit(params: any): void {
    this.specialistCode = JSON.parse(sessionStorage.getItem('specialistCode') || '')
    this.getGroupUserList=this.iaService.getGroupList();
    this.params = params;
    if (this.params.colDef.headerName === 'bm') {
      this.step = 1;
      this.createCellValue(params, params.rowIndex, this.step);
    }
    if (this.params.colDef.headerName === 'pre ppf') {
      this.step = 2;
      this.createCellValue(params, params.rowIndex, this.step);
    }
    if (this.params.colDef.headerName === 'pkg trial') {
      this.step = 3;
      this.createCellValue(params, params.rowIndex, this.step);
    }
    if (this.params.colDef.headerName === 'final ppf') {
      this.step = 4;
      this.createCellValue(params, params.rowIndex, this.step);
    }
  }
  createCellValue(rowData: any, _rowIndex: any, status: any) {
    let intStatus = 0;
    let intStatusApprovedDate = '';
    const cvcode = rowData.data.cvcode;
    const currentApproverCode=rowData.data.currentapprovercode;
    const mfcode = rowData.data.mfcode;
    const qccode = rowData.data.qccode;
    const currentdept = rowData.data.currentdept;
    if (status === 1) {
      intStatus = rowData.data.int1status;
      intStatusApprovedDate = rowData.data.int1approveddate;
    }
    else if (status === 2) {
      intStatus = rowData.data.int2status;
      intStatusApprovedDate = rowData.data.int2approveddate;
    }
    else if (status === 3) {
      intStatus = rowData.data.int3status;
      intStatusApprovedDate = rowData.data.int3approveddate;
    }
    else if (status === 4) {
      intStatus = rowData.data.int4status;
      intStatusApprovedDate = rowData.data.int4approveddate;
    }
    if (intStatus === null || Number(intStatus) === 0) {
      this.text = '-';
      this.skipFlag = true;
      this.abtnCheck = true;
    }
    else if (Number(intStatus) === 1) {
      if(this.getGroupUserList.length === 0 && cvcode === this.specialistCode && Number(currentdept) === 2)
      {
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      }
      else if (this.getGroupUserList.includes(currentApproverCode) && Number(currentdept) === 2) {
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      } else {
        this.text = "Wait Conv";
        this.skipFlag = false;
        this.abtnCheck = true;
        this.mode = 1;
      }
    }
    else if (Number(intStatus) === 2) {
      if(this.getGroupUserList.length === 0 && mfcode  === this.specialistCode && Number(currentdept) === 3){
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      }
      else if (this.getGroupUserList.includes(currentApproverCode) && Number(currentdept) === 3) {
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      } else {
        this.text = "Wait Mfg";
        this.skipFlag = false;
        this.abtnCheck = true;
        this.mode = 1;
      }
    }
    else if (Number(intStatus) === 3) {
      if(this.getGroupUserList.length === 0 && qccode === this.specialistCode && Number(currentdept) === 4){
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      }
      else if (this.getGroupUserList.includes(currentApproverCode) && Number(currentdept) === 4) {
        this.text = "A";
        this.abtnCheck = false;
        this.mode = 0;
      } else {
        this.text = "Wait Qc";
        this.skipFlag = false;
        this.abtnCheck = true;
        this.mode = 1;
      }
    }
    else if (Number(intStatus) === 4) {
      this.text = this.datepipe.transform(intStatusApprovedDate,'MM/dd/yyyy','en-US');
      this.skipFlag = false;
      this.abtnCheck = true;
      this.mode = 1;
    }
    else if (Number(intStatus) === 5) {
      this.text = "Skip";
      this.skipFlag = true;
      this.abtnCheck = true;
    }
    else {
      this.text = "-";
      this.skipFlag = true;
      this.abtnCheck = true;
    }
  }

  navigate() {
    if (this.params.colDef.headerName === 'bm' ||
      this.params.colDef.headerName === 'pre ppf' ||
      this.params.colDef.headerName === 'pkg trial' ||
      this.params.colDef.headerName === 'final ppf'
    ) {

      const params = {
        leaderPlant : this.params.data?.leadername,
        plant: this.params.data?.plantcode,
        plantName: this.params.data?.plantname,
        dock: this.params.data?.dockcode,
        modelCode: this.params.data?.modelcode,
        supplier: this.params.data?.suppliercode,
        supplierName: this.params.data?.suppliername,
        specialist: this.params.data?.specialist,
        part: this.params.data?.partnumber,
        psd: this.params.data?.pkgstartdate,
        dateSent: this.params.data?.datesent,
        mode : this.mode,
        step: this.step,
        currentIntNo: this.params.data?.currentintno,
        currentIntVer: this.params.data?.currentintver
      }
      sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
      if(this.router.url === '/tracking-list/internal-approvement'){
        this.router.navigateByUrl('/tracking-list/internal-approvement/dept-approve');
      }
      else{
        this.router.navigateByUrl('/internal-approvement/dept-approve');
      }
    }
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }
}




