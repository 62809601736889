import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'app-plant-maintenance-btn-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table" 
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  </div>
  `,
})

export class PlantMaintenanceBtnCellRender implements ICellRendererAngularComp {

  constructor(
    private readonly state: ExportStateService,
    ) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;
  
  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'CALC'){
      this.buttonName = 'CALC';
    }
    else if (params.colDef.headerName === 'COST CENTER'){
      this.buttonName = 'COST CENTER'; 
    }
    else if (params.colDef.headerName === 'DOCK'){
      this.buttonName = 'DOCK'; 
    }
    else{
      this.buttonName = params.colDef.headerName; 
    }
  }

  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data)
  }
}




