import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PackageTypeBtnCellRender } from '../../../internal-Vpacs-gridrender/master-maintenance/package-type-btn-cell-render';
import { AddPkgTypeComponent } from '../../../internal-vpacs-dialogs/master-maintenance/package-type/add-pkg-type/add-pkg-type.component';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PackageTypeService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-type.service';
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface';

@Component({
  selector: 'app-package-type',
  templateUrl: './package-type.component.html',
  styleUrls: ['./package-type.component.scss'],
})
export class PackageTypeComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  paginationPageSize = 5;
  isAccessible = false;
  plant: any;
  plants: any[] = [];
  haveAccess: any;
  haveAddAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  errorMessage!: ErrorMessage;
  rowCount = 0;
  rowHeight = 40;
  totalPage = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  headerHeight = 40;
    
  isAll=false;
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rowClassRules: any;
  components: any[] = [];

  constructor(
    private readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly dialog: MatDialog,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly packageTypeService: PackageTypeService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      unSortIcon: true,
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.components = [
      { code: 1, desc: 'Container' },
      { code: 2, desc: 'Dunnage' },
      { code: 4, desc: 'Top Cap' },
      { code: 5, desc: 'Pallet' },
      { code: 6, desc: 'Stretch Wrap/Strap' },
      { code: 7, desc: 'Kanban Holder' },
      { code: 8, desc: 'Other' },
    ];
    this.userAccess();
  }

  userAccess() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.spinnerService.start();
        this.haveAccess = res.role?.p_pkgview;
        this.haveAddAccess = res.role?.p_pkgsave;
        this.modifyAccess = res.role?.p_pkgmodify;
        this.deleteAccess = res.role?.p_pkgdelete;
        if (this.haveAccess) {
          this.apiCall();
          this.getColumnDefs();
        } else {
          this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
        this.spinnerService.stop();
      },
    });
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'CODE',
        field: 'packagetypecode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
      },
      {
        headerName: 'NAME',
        field: 'packagetype',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
      },
      {
        headerName: 'COMPONENT',
        field: 'packagetypeDesc',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
      },
      {
        headerName: 'UPDATE',
        field: 'update',
        sortable: false,
        unSortIcon: true,
        autoHeight: true,
        cellRendererFramework: PackageTypeBtnCellRender,
        cellRendererParams: {
          clicked: (colId: string, data: any) => {
            this.openDialog(colId, data);
          },
        },
        cellStyle: { cursor: 'pointer' },
        floatingFilter: false,
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        sortable: true,
        minWidth: 60,
        autoHeight: true,
        unSortIcon: false,
        cellRendererParams: {
          clicked: (colId: string, data: any) => {
            this.openDialog(colId, data);
          },
        },

        cellRendererFramework: PackageTypeBtnCellRender,
        cellStyle: { cursor: 'pointer' },
        floatingFilter: false,
      },
    ];
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  apiCall() {
    this.spinnerService.start();
    this.haveAccess &&
      this.packageTypeService.loadPackageTypeData().subscribe({
        next: (res: any) => {
          const result = res.result.map((item: any) => ({
            ...item,
            modifyAccess: this.modifyAccess,
            deleteAccess: this.deleteAccess,
          }));
          this.displayRowData = result;
          if (result.length) {
            result.forEach((element: any) => {
              Object.assign(element, {
                packagetypeDesc: this.getCompType(element.packagetypeflag),
              });
            });
          }
          this.rowCount = this.displayRowData.length;
          this.totalRecords = result.length;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.gridApi?.hideOverlay();
          this.displayRowData = [];
          this.rowCount = 0;
          this.errorMessage = {
            errorCode: `API-${_err.status}`,
            childErrorCode: _err.status,
          };
          this.spinnerService.stop();
        },
      });
  }

  openDialog(pageName: string, data: any) {
    const dialogRef = this.dialog.open(AddPkgTypeComponent, {
      width: '30%',
      data: { mode: pageName, field: data, tableData: this.displayRowData },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'success') {
          this.haveAccess && this.apiCall();
        }
      }
    });
  }

  //pagination changes

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall()
    }
  }

  async onLoadAll() {
    this.apiCall();
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    
  }

  onFilterChanged() {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }

  getCompType(comType: any) {
    let componentType: any = '';
    if (comType === '1') {
      componentType = 'Container';
    } else if (comType === '2') {
      componentType = 'Dunnage';
    } else if (comType === '4') {
      componentType = 'Top Cap';
    } else if (comType === '5') {
      componentType = 'Pallet';
    } else if (comType === '6') {
      componentType = 'Stretch Wrap/Strap';
    } else if (comType === '7') {
      componentType = 'Kanban Holder';
    } else if (comType === '8') {
      componentType = 'Other';
    }
    return componentType;
  }
  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 103]);
  }
}