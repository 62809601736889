<div class="d-flex justify-content-between align-items-center m-3">
  <p class="page-title">Role Maintenance</p>
  <div>
    <button class="black-button mx-3" style="height: 30px" (click)="add()" [disabled]="addflag">
      ADD
    </button>
    <button class="help-btn" (click)="help()">help</button>
  </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
  <div *ngFor="let message of errorList" class="errormsg">
    <div class="message-container">
      <span class="message-text">{{ message }}</span>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
  </div>
</div>
<div class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
  <span>{{ successMessage }}</span>
  <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
  Authority Error - Access denied
</div>
<div class="" *ngIf="haveAccess">
    <div class="grid-agg grid-height-master-maintenace-role mb-5">
      <ag-grid-angular  style="width: 100%; height: 100%"
        class="ag-theme-balham"
        (window:resize)="sizeToFit()"
        (gridReady)="onGridReady($event)"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [rowData]="displayRowData"
        [suppressCellSelection]="true"
        [suppressHorizontalScroll]="true" 
        [icons]="icons"
        [headerHeight]="40"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFiltersHeight]="40"
        [rowSelection]="rowSelection"
        [rowClassRules]="rowClassRules"
      >
      </ag-grid-angular>
    </div>
</div>
