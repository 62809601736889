import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tracking-legend',
  templateUrl: './tracking-legend.component.html',
  styleUrls: ['./tracking-legend.component.scss']
})
export class TrackingLegendComponent implements OnInit {
  public pageName = ''
  constructor(public router:Router) {}

  ngOnInit(): void {
    this.pageName = this.router.url.replace('/','')
  }

}
