import { Inject, Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-discrepancy-add',
  templateUrl: './discrepancy-add.component.html',
  styleUrls: ['./discrepancy-add.component.scss']
})
export class DiscrepancyAddComponent implements OnInit {
  defaultColDef: {};
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  public domLayout: any = '';
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  fradioVal: any = '1';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  spCode: any;
  redo:any;
  plantcode:any;
  dbauth: any;
  dbppf: any;
  dbtrail: any;
  dbreadiness: any;
  gridApi: any;
  supType: any;

  constructor(public dialogRef: MatDialogRef<DiscrepancyAddComponent>,public router: Router,
    private readonly spinnerService: NgxUiLoaderService,private readonly DiscrepancyListService:DiscrepancyListService,
    @Inject(MAT_DIALOG_DATA) public fdialog: any,
    private readonly state: ExportStateService) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
    };
   }

  ngOnInit(): void {
  
    this.redo=localStorage.getItem('redo');
    this.plantcode=localStorage.getItem('plantcode')
    this.columnDefs = [
      
      {
        headerName: 'Part Number',
        field: 'partnumber',
        minWidth: 60,
        maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Specialist',
        field: '',
        minWidth: 60,
        maxWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Pkg Trail Due',
        field: 'pkgtrail',
        minWidth: 60,
        maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Trail Complete Target',
        field: 'preppf',
        minWidth: 60,
        maxWidth: 142,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Pkg Authorized',
        field: 'pkgauthorised',
        minWidth: 70,
        maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SUPPLIER READINESS',
        field: 'readinessdue',
        minWidth: 70,
        maxWidth: 150,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
    ];
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.DiscrepancyListService.getplantSelect();
        this.spinnerService.start();
        if (res) {
          this.supType = res.role.supType;
          this.plant();
        }
      }
    })
  }

  onLoad() {
    this.spinnerService.start();
    const updateObject = {
      "PlantCode": this.plantcode,
      "PartNumber": this.fdialog.partnumber,
      "ModelCode": this.fdialog.model,
      "Description": this.fdialog.partdesc,
      "Routing": this.fdialog.partrouting,
      "PrePPFDue": this.fdialog.preppf,
      "PkgTrialDue": this.fdialog.pkgtrail,
      "PkgAuthDue": this.fdialog.pkgauthorised,
      "ReadyDue": this.fdialog.readinessdue,
      "SupplierCode": this.fdialog.suppliercode,
      "SpecialistCode": this.fdialog.specialistcode,
      "PkgStartDate": this.fdialog.pkgstart,
      "QPU": this.fdialog.qpu,
      "Redo": this.redo,
      "Mode": 0,
      "Radue": 1,
      "Length": this.fdialog.currLength,
      "Width": this.fdialog.currWidth,
      "Height": this.fdialog.currHeight,
      "Weight": this.fdialog.currWeight,
      "SupplierType": Number(this.supType)
    }

    this.DiscrepancyListService.onloadregPartDue(updateObject).subscribe({
      next: () => {
        this.displayRowData=[this.fdialog];
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },

    })

  }
  help(){
    this.router.navigate(['./master-help',2,"Discrepancy List",27])
  
  }

  plant() {
    this.spinnerService.start();
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '')
    const obj = {
      // "supplierCode": [this.supplier],
      "SpecialistCode": this.spCode,
      "Type": 1
    }

    this.DiscrepancyListService.plant(obj).subscribe(response => {
      this.resPlant = response.map((item: { plantcode: any; }) => item.plantcode)
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response]      
      this.onLoad();

    })
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  finish(){
      this.spinnerService.start();
      const updateObject = {
        "PlantCode": this.plantcode,
        "PartNumber": this.fdialog.partnumber,
        "ModelCode": this.fdialog.model,
        "Description": this.fdialog.partdesc,
        "Routing": this.fdialog.partrouting,
        "PrePPFDue": this.fdialog.preppf,
        "PkgTrialDue": this.fdialog.pkgtrail,
        "PkgAuthDue": this.fdialog.pkgauthorised,
        "ReadyDue": this.fdialog.readinessdue,
        "SupplierCode": this.fdialog.suppliercode,
        "SpecialistCode": this.fdialog.specialistcode,
        "PkgStartDate": this.fdialog.pkgstart,
        "QPU": this.fdialog.qpu,
        "Redo": this.redo,
        "Mode": 0,
        "Radue": this.fradioVal,
        "Length": this.fdialog.currLength,
        "Width": this.fdialog.currWidth,
        "Height": this.fdialog.currHeight,
        "Weight": this.fdialog.currWeight,
        "SupplierType": Number(this.supType)
      }
  
      this.DiscrepancyListService.addPart(updateObject).subscribe({
        next: () => {
          // this.displayRowData=[this.fdialog]
          this.spinnerService.stop();
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
  
      })
  
    this.dialogRef.close();
  }

  onNoClick(){
    this.dialogRef.close();
  }

  

}
