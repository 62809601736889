<div class="wrapper mx-3">
    <div class="page-title py-1 ">Use BackUp {{pageName}}</div>
    <div class="mat-typography content">
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">PART INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-1 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span
                            class="text-black">{{metaData.partNumber}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Plant<span
                            class="text-black">{{accData?.PLANT}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">PKG Start Date<span
                            class="text-black">{{metaData.pkgStartDate | date: 'MM/dd/yyyy'}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Model<span
                            class="text-black">{{metaData.modelCode}}</span></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">CONTAINER INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Package Code<span
                            class="text-black">{{accData.CONTCODE}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.CONTL|
                            number: '1.0-4'}}/{{accData.CONTW| number: '1.0-4'}}/{{accData.CONTH| number:
                            '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span
                            class="text-black">{{accData.CONTPRICE| number: '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Weight(kg)<span
                            class="text-black">{{accData.CONTWEIGHT| number: '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-2 col-md-6 col-12 my-2">QPC<span class="text-black">{{accData.QPC}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">DUNNAGE INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span
                            class="text-black">{{accData.DUNCODE}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.DUNL|
                            number: '1.0-4'}}/{{accData.DUNW| number: '1.0-4'}}/{{accData.DUNH| number: '1.0-4'}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span
                            class="text-black">{{accData.DUNPRICE| number: '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span
                            class="text-black">{{accData.DUNWEIGHT| number: '1.0-4'}}</span></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">TOP CAP INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span
                            class="text-black">{{accData.LIDCODE}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.LIDL|
                            number: '1.0-4'}}/{{accData.LIDW| number: '1.0-4'}}/{{accData.LIDH| number: '1.0-4'}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span
                            class="text-black">{{accData.LIDPRICE| number: '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span
                            class="text-black">{{accData.LIDWEIGHT| number: '1.0-4'}}</span></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel5 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">PALLET INFORMATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Code<span
                            class="text-black">{{accData.PLTCODE}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">L/W/H(mm)<span class="text-black">{{accData.PLTL|
                            number: '1.0-4'}}/{{accData.PLTW| number: '1.0-4'}}/{{accData.PLTH| number: '1.0-4'}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Estimated Cost/Pkg piece<span
                            class="text-black">{{accData.PLTPRICE| number: '1.0-4'}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Weight(kg)<span
                            class="text-black">{{accData.PLTWEIGHT| number: '1.0-4'}}</span></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel6 class="mat-elevation-z0" *ngIf="pageName !== 'tracking' ">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">ENTER START DATE</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel6.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-3 d-flex flex-wrap col-12" [formGroup]="submit">
                    <div class="d-flex align-items-center my-2 col-lg-4 col-md-6 col-12">
                        <label for="step" class="col-3">Reason Used
                            <small>*</small>
                        </label>
                        <select id="step" class="col-6" formControlName="reason">
                            <option value="" selected disabled hidden>Select</option>
                            <option *ngFor="let reason of dropDownReason" [value]="reason.ID">{{reason.reason}}</option>
                        </select>
                    </div>
                    <div class="d-flex align-items-center my-2 col-lg-4 col-md-6 col-12 gap-4">
                        <label class="col-3">Responsibility
                            <small>*</small>
                        </label>
                        <input type="radio" id="onlydo" value='supplier' formControlName="responsibility">
                        <label for="onlyDo">Supplier</label>
                        <input type="radio" id="all" value='toyota' formControlName="responsibility">
                        <label for="all">Toyota</label>
                    </div>
                    <div class="d-flex align-items-center my-2 col-lg-4 col-md-6 col-12">
                        <label for="from-date" class="col-3">Start Date
                            <small>*</small>
                        </label>
                        <input type="date" id="from-date" class="col-6" formControlName="startDate" />
                    </div>
                    <div class="d-flex align-items-center justify-content-center my-2 col-lg-4 col-md-6 col-12">
                        <small class="error col-6" *ngIf="submit.get('reason').invalid && (submit.get('reason').dirty || submit.get('reason').touched)
                        && submit.get('reason').hasError('required')">
                            Select an option from dropdown
                        </small>
                    </div>
                    <div class="d-flex align-items-center justify-content-center my-2 col-lg-4 col-md-6 col-12">
                        <small class="error col-6" *ngIf="submit.get('responsibility').invalid && (submit.get('responsibility').dirty || submit.get('responsibility').touched)
                        && submit.get('responsibility').hasError('required')">
                            Radio button needs to be selected
                        </small>
                    </div>
                    <div class="d-flex align-items-center justify-content-center my-2 col-lg-4 col-md-6 col-12"
                        *ngIf="submit.get('startDate').invalid && (submit.get('startDate').dirty || submit.get('startDate').touched)">
                        <small class="error col-6" *ngIf="submit.get('startDate').hasError('required')">
                            Please provide a Date
                        </small>
                    </div>
                    <div class="d-flex align-items-center my-2 col-lg-12 col-md-6 col-12"
                        *ngIf="pageName === 'update' || 'notify of start use'">
                        <label for="reason-detail" class="col-1">Reason Detail
                            <small>*</small>
                        </label>
                        <textarea rows="4" id="reason-detail" class="col-4" formControlName="reasonDetail"
                            maxlength="250"></textarea>
                    </div>
                    <div class="d-flex align-items-center justify-content-center my-2 col-lg-12 col-md-6 col-12"
                        *ngIf="submit.get('reasonDetail').invalid && (submit.get('reasonDetail').dirty || submit.get('reasonDetail').touched)">
                        <small class="error col-8" *ngIf="submit.get('reasonDetail').hasError('required')">
                            Reason Detail is required
                        </small>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" #panel7 class="mat-elevation-z0" *ngIf="pageName !== 'tracking' ">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold align-items-center">RETURNABLE PACKAGING SHORTAGE INVESTIGATION AND
                        NOTIFICATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel7.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <p class="fw-bold my-3">Supplier Info</p>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Submission Date
                        <span class="text-black">{{supplierSubmission !== '-' ? (supplierSubmission| date: 'MM/dd/yyyy'): ('-')}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Supplier Code
                        <span class="text-black">{{supplierCode}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Supplier Name
                        <span class="text-black">{{supplierName}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Contact Name
                        <span class="text-black">{{suppliercontactName}}</span>
                    </div>
                </div>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Contact Ph#
                        <span class="text-black">{{suppliercontactNo}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Contact Email
                        <span class="text-black">{{suppliercontactEmail}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">What NAMC is this for?
                        <span class="text-black">{{supplierPlantCode}}</span>
                    </div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2"><span class="text-black"></span></div>
                </div>
                <hr>
                <p class="fw-bold">Returnable Packaging Data</p>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">V-PACS Returnable Packaging Code<span
                            class="text-black">{{returnablePkgCode}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">V-PACS Dunnage Code<span
                            class="text-black">{{returnableDunnage}}</span></div>
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">10 Digit PN<span
                            class="text-black">{{returnablePartNumber}}</span></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="footer-dialog d-flex">
        <div class="mx-3 cancel" (click)="navigatePrevious()">Cancel</div>
        <button class="black-button mx-3" (click)="onSubmit()">Submit</button>
    </div>
</div>