<div [formGroup] = "confirmImportform">
<div class="popup-container">
  <div class="mx-3 header d-flex justify-content-between">
    <span *ngIf="!readOnly">Confirm Importing Data</span>
    <span *ngIf="readOnly">Imported Result</span>
    <button (click)="help()" class="vpacs-btn-table">HELP</button>
  </div>
  <div class="alert alert-danger mx-3" role="alert" *ngIf = "errorArray.length > 0">
    <span *ngFor="let message of errorArray">{{message}}</span>
  </div>
  <app-notification [codes]="errorMessage" *ngIf="viewAccess===false && errorMessage"></app-notification>
  <div class="filter d-flex m-3" *ngIf="viewAccess">
    <div class="d-flex col-4 flex-wrap">
      <span class="col-6">Plant</span>
      <span class="col-6">{{routeParams.plantcode}}</span>
      <span class="col-6">Model Code</span>
      <span class="col-6">{{routeParams.modelcode}}</span>
      <span class="col-6">SOP</span>
      <span class="col-6">{{this.impModel.sop | date : 'dd/MM/yyy'}}</span>
    </div>
  </div>
</div>
<div class="col-12" *ngIf="!readOnly && viewAccess">
  <table class="tg mx-3 col-11" aria-describedby="confirm-import">
    <thead>
      <tr>
        <th class="tg-mg6y" colspan="3">Total Records</th>
        <th class="tg-z5to">{{onLoadData?.tdTotal}}</th>
        <th class="tg-z5to" colspan="2"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-mg6y" rowspan="4">New</td>
        <td class="tg-v2d4" rowspan="2">Isn't exist in V-PACS</td>
        <td class="tg-mg6y">Part Add</td>
        <td class="tg-gau3">{{onLoadData?.tdAdd}}</td>
        <td class="tg-gau3"></td>
        <td class="tg-gau3">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('new',1)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y">Part Ignore</td>
        <td class="tg-z5to">{{onLoadData?.tdIgnore}}</td>
        <td class="tg-z5to"></td>
        <td class="tg-z5to">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('new',2)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" rowspan="2">Exist in other model</td>
        <td class="tg-mg6y">Same Pkg Start Date</td>
        <td class="tg-gau3">{{onLoadData?.tdAddsamepsd}}</td>
        <td class="tg-gau3">
          <mat-radio-group formControlName = "newSamePkg">
            <mat-radio-button class="mx-2" value="A">Add</mat-radio-button>
            <mat-radio-button class="mx-2" value="I">Ignore</mat-radio-button>
          </mat-radio-group>
        </td>
        <td class="tg-gau3">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('new',3)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y">Diff Pkg Start Date</td>
        <td class="tg-z5to">{{onLoadData?.tdAddDiffPsd}}</td>
        <td class="tg-z5to">
          <mat-radio-group formControlName = "newDiffPkg">
            <mat-radio-button class="mx-2" value="C">Carry over</mat-radio-button>
            <mat-radio-button class="mx-2" value="A">Add as new record</mat-radio-button>
            <mat-radio-button class="mx-2" value="I">Ignore</mat-radio-button>
          </mat-radio-group>
        </td>
        <td class="tg-z5to">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('new',4)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-n2z5" rowspan="4">Update</td>
        <td class="tg-mg6y" colspan="2">Part Replacement</td>
        <td class="tg-gau3">{{onLoadData?.tdPartRep}}</td>
        <td class="tg-phrp"></td>
        <td class="tg-gau3">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('part-replacement',5)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Supplier Replacement</td>
        <td class="tg-z5to">{{onLoadData?.tdSupplierRep}}</td>
        <td class="tg-z5to"></td>
        <td class="tg-z5to">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('supplier-replacement',6)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Same Pkg Start Date</td>
        <td class="tg-gau3">{{onLoadData?.tdUpdatesamepsd}}</td>
        <td class="tg-gau3"></td>
        <td class="tg-gau3">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('update',7)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Diff Pkg Start Date</td>
        <td class="tg-z5to">{{onLoadData?.tdUpdateDiffPsd}}</td>
        <td class="tg-z5to" >
          <mat-radio-group formControlName = "updateDiffPkg">
            <mat-radio-button class="mx-2" value = "U">Update</mat-radio-button>
            <mat-radio-button class="mx-2" value = "A">Add as new record</mat-radio-button>
            <mat-radio-button class="mx-2" value = "I">Ignore</mat-radio-button>
          </mat-radio-group>
        </td>
        <td class="tg-z5to">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('update',8)">Details</button>
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="3">
          Delete part (Not exist in part list)
        </td>
        <td class="tg-gau3">{{onLoadData?.tddelete}}</td>
        <td class="tg-gau3"></td>
        <td class="tg-gau3">
          <button class="vpacs-btn-table" style="height: '30px'" (click)="detailsBtn('delete',8)">Details</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="popup-container d-flex" *ngIf="!readOnly && viewAccess">
  <div class="filter d-flex flex-wrap gap-2 m-3">
    <h6 class="d-flex col-4 col-md-4 col-12 flex-wrap">Part Dimension<br/>(Null data is NOT overwrited if you select overwrite)</h6>
    <h6 class="d-flex col-4 col-md-4 col-12 flex-wrap">Others<br/>(Null data is overwrited if you select overwrite)</h6>
    <div class="d-flex col-4 col-md-4 col-12 flex-wrap part-info">
      <span class="col-6">Dimension</span>
      <span class="col-6 d-flex justify-content-between" >
        <mat-radio-group formControlName = "dimension">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Weight</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "weight">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Description</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "desc">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Email</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "eci">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
    </div>
    <div class="d-flex col-4 col-md-4 col-12 flex-wrap">
      <span class="col-6">Fabricator</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "fab">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Specialist</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "specialist">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">QPU</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "qpu">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Routing info</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "routing">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Due</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "due">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
      <span class="col-6">Leader Plant</span>
      <span class="col-6 d-flex justify-content-between">
        <mat-radio-group formControlName = "leader">
          <mat-radio-button class="mx-2" value = "1">Overwrite</mat-radio-button>
          <mat-radio-button class="mx-2" value = "0">Ignore</mat-radio-button>
        </mat-radio-group>
      </span>
    </div>
  </div>
</div>
<div class="col-12" *ngIf="readOnly && viewAccess">
  <table class="tg mx-3 col-11" aria-describedby="confirm-import">
    <thead>
      <tr>
        <th class="tg-mg6y" colspan="3">Total Records</th>
        <th class="tg-z5to">{{onLoadData?.tdTotal}}</th>
        <th class="tg-z5to" colspan="2"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-mg6y" rowspan="4">New</td>
        <td class="tg-v2d4" rowspan="2">isn't exist in V-PACS</td>
        <td class="tg-mg6y">Part Add</td>
        <td class="tg-gau3">{{outArray[0]}}</td>
        <td class="tg-gau3"></td>
      </tr>
      <tr>
        <td class="tg-mg6y">Part Ignore</td>
        <td class="tg-z5to">{{outArray[8]}}</td>
        <td class="tg-z5to"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" rowspan="2">Exist in Other Model</td>
        <td class="tg-mg6y">Same Pkg Start Date</td>
        <td class="tg-gau3">{{outArray[1]}}</td>
        <td class="tg-gau3">
          {{newSamePkg}}
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y">Diff Pkg Start Date</td>
        <td class="tg-z5to">{{outArray[2]}}</td>
        <td class="tg-z5to">
          {{newDiffPkg}}
        </td>
      </tr>
      <tr>
        <td class="tg-n2z5" rowspan="4">Update</td>
        <td class="tg-mg6y" colspan="2">Same Pkg Start Date Part Replacement</td>
        <td class="tg-gau3">{{outArray[6]}}</td>
        <td class="tg-phrp"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Same Pkg Start Date Supplier Replacement</td>
        <td class="tg-z5to">{{outArray[7]}}</td>
        <td class="tg-z5to"></td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Same Pkg Start Date</td>
        <td class="tg-gau3">{{outArray[3]}}</td>
        <!-- This is hardcoded as per the old app -->
        <td class="tg-gau3">Update</td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="2">Diff Pkg Start Date</td>
        <td class="tg-z5to">{{outArray[4]}}</td>
        <td class="tg-z5to" >
          {{updateDiffPkg}}
        </td>
      </tr>
      <tr>
        <td class="tg-mg6y" colspan="3">
          Delete part (Not exist in part list)
        </td>
        <td class="tg-gau3">{{outArray[5]}}</td>
        <td class="tg-gau3"></td>
      </tr>
    </tbody>
  </table>
</div>
<div class="footer-dialog d-flex mt-3 px-3" *ngIf="viewAccess" style="height: 50px">
  <button class="black-button mx-3" (click)="confirmImport()" *ngIf="!readOnly">IMPORT</button>
  <div class="mx-3 cancel" (click)="routeToModelList()">Back</div>
</div>
</div>
