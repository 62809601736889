import { Component, OnInit } from '@angular/core';
import 'ag-grid-enterprise';
import { FormGroup, FormControl } from '@angular/forms';
import { RowGroupingDisplayType } from 'ag-grid-community';
import { AuthorityListBtnCellrender } from 'src/app/core/gridrender/authoritylist-btn-cell-render';
import { AuthorityService } from 'src/app/services/authoritylist.service';
import { AgGridModule } from 'ag-grid-angular';
import { CheckBox } from 'src/app/core/gridrender/checkBox';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { Router } from '@angular/router';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'app-authority-list',
  templateUrl: './authority-list.component.html',
  styleUrls: ['./authority-list.component.scss'],
})
export class AuthorityListComponent implements OnInit {
  public authority: any;
  public rowData: any[] = [];
  loading = false;
  public gridColumnApi: any;
  userData:any;
  public searchParameters = new FormGroup({
    Name: new FormControl(''),
    Phone: new FormControl(''),
    Suppliercode: new FormControl(''),
  });

  gridApi: any;
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  setcolumnDefs: any;
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  headerHeight = 50;
  paginationPageSize = 20;
  isAccessible = false;
  defaultColDef: any;
  autoGroupColumnDef: any;
  columnDefs: any;
  currentRowCount: any;
  noOfGroups = 0;
  currentStartRowCount = 0;
  asyncLoading = true;
  public modules = AgGridModule;
  public supplierNumber: any;
  public overlayLoadingTemplate = `<div class="spinner-border text-danger" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  unauthorizedFlag: any;
  supplierCodeData: any;
  message = '';
  detailCellRendererParams: any;

  constructor(
    private readonly authorityService: AuthorityService,
    public state: ExportStateService,
    public constants: ConstantsService,
    public router: Router,
    public globalState: GlobalStateService
  ) {
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('authUpdate')){
      sessionStorage.removeItem('authUpdate')
    }
    this.checkIsadminBadmin();
    this.gridOptions = {
      masterDetail : true
    }

    this.defaultColDef = {
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      filterParams: { newRowsAction: 'keep' },
    };
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: 'UserName',field: 'GIVENNAME'},
          { headerName: 'Email',field: 'MAIL'},
          { headerName: 'UserId',field: 'USERID'},
          { headerName: 'SupplierCdde',field: 'SUPPLIERCODE'},
          {
            headerName: 'PLANT LEVEL ACCESS',
            filter: false,
            cellRendererFramework: AuthorityListBtnCellrender,
          },
        ],
        defaultColDef: {
          flex: 1,
        }
      },
      getDetailRowData: (params:any) => {
        this.getChildRows(params);
      },
    }

    this.columnDefs = [
      {
        headerName: 'NAME',
        field: 'fullname',
        sortable: true,
        unSortIcon: true,
        cellRenderer : 'agGroupCellRenderer'
      },
      {
        headerName: 'PHONE',
        field: 'telephonenumber',
        resizable: true,
      },
      {
        headerName: 'VALID',
        field: 'valid',
        editable: false,
        filter: false,
        cellRendererFramework: CheckBox,
      },
      {
        headerName: 'ADMIN',
        field: 'isadmin',
        editable: false,
        filter: false,
        cellRendererFramework: CheckBox,
      },
      {
        headerName: 'SUPPLIERCODE',
        field: 'SUPPLIERCODE',
        sortable: true,
        unSortIcon: true,
        filter: false,
      },
    ];
  }

  getChildRows(params:any){
    const body = params.data;
    body['supplierCode'] = body.SUPPLIERCODE;
    this.authorityService.getChildRows(body).subscribe({
      next : (res:any) =>{
        params.successCallback(res);
      },
      error : (_err:any) =>{
        
      }
    });
  }


  filterChanged(_event: any) {
    const filter = this.gridApi?.getFilterModel();
    this.globalState.authListPage(filter);
    this.noOfGroups = this.gridApi.getDisplayedRowCount()
  }
 
  cellStyleColumn(_params: any) {
    return { display: 'flex', 'justify-content': 'flex-start', height: '40px' };
  }

  update() {
    const newPayload = this.globalState.getAuthorityListUpdateData();
    if(newPayload.length > 0){
      this.authorityService.authorityUpdate(newPayload).subscribe({
        next : (_res:any) =>{
          this.message = 'Successfully Updated'
        },
        error : (_err:any) =>{
          this.message = 'Failed to update'
        },
        complete : () =>{
          setTimeout(() => {
            this.message = ''
          }, 5000);
        }
      });
    }else{
      alert("Modify data to update");
    }
  }

  cancel() {
    this.gridApi?.setRowData(this.globalState.getAuthorityData())
  }

  checkForAdminRole(userRoles:any){  
    if(this.constants.adminSupRoles.some((ele: any) => userRoles.includes(ele))){
      return true;
    }else{
      return false;
    }
  }

  checkIsadminBadmin(){
    this.state.userInfoState.subscribe(async(data:any)=>{
      if(data){
        this.userData = data;
        this.checkRoles();
      }
    })
  }

  checkRoles() {
    this.state.roleState.subscribe(async(data: any) => {
      if (data) {
        const userRoles: any[] = data;
        if (this.checkForAdminRole(userRoles) || this.userData.info[0].ISADMIN === "1" || this.userData.b_admin)  {
          this.getSupplierCodes(data);
          this.unauthorizedFlag = false;
        } else {
          this.unauthorizedFlag = true;
        }
      }
    });
  }

  getSupplierCodes(data:any[]) {
    if (this.constants.adminSystemRoles.some((ele: any) => data.includes(ele))) {      
      this.apiCall();
    }else{      
      this.state.userInfoState.subscribe({
        next: (res: any) => {
          if (res) {
          const data = this.state.setSupplierCode(res.supplierCode);
            if (data.length > 0) {
              this.supplierCodeData = data;
              this.apiCall();
            }
          }
        },
      });
    }
  }

  apiCall() {  
    if (localStorage.hasOwnProperty('tracking')) {
      this.supplierNumber = JSON.parse(localStorage.getItem('tracking') || '');
    } else {
      this.supplierNumber = this.supplierCodeData;
    }
  
    if (this.supplierNumber && this.supplierNumber.length > 0) {
      this.displayRowData = [];
      this.rowData = [];
      this.rowCount = 0;
          this.authorityService.loadAuthority({supplierCode : this.supplierNumber}).subscribe({
            next : (res:any) =>{
              if (Array.isArray(res)) {
                this.globalState.setAuthorityData(res);      
                res = res.sort(
                  (
                    a: { valid: number; fullname: string },
                    b: { valid: number; fullname: any }
                  ) => {
                    // check undefined or null in fullname field
                    const nameA = a.fullname || '';
                    const nameB = b.fullname || '';
                    if (a.valid === 0 && b.valid !== 0) {
                      return 1;
                    } else if (a.valid !== 0 && b.valid === 0) {
                      return -1;
                    }
                    if (a.valid !== 0 && b.valid !== 0) {
                      return nameA.localeCompare(nameB);
                    }
                    return 0;
                  }
                );
                this.displayRowData = this.displayRowData.concat(res);
                this.rowData = this.rowData.concat(res);
                this.noOfGroups = this.displayRowData.length;
              }
            },
            error : (_err:any) =>{
              this.displayRowData = [];
              this.rowData = [];
              this.rowCount = 0;
              this.loading = false;
              this.asyncLoading = false;
            },
          })
    }
  }
  
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  goToNexpage() {
    this.gridApi.paginationGoToNextPage();
  }
  goToPreviousPage() {
    return this.gridApi.paginationGoToPreviousPage() + 1;
  }
  goToFirstPage() {
    this.gridApi.paginationGoToFirstPage();
  }
  goToLastPage() {
    return this.gridApi.paginationGoToLastPage() + 1;
  }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) {
      this.currentPageBoolean = true;
    } else {
      this.currentPageBoolean = false;
    }
    return this.currentPageBoolean;
  }
  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === 'ALL') {
      this.paginationPageSize = Number(this.displayRowData.length);
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.supplierNumber &&
      this.supplierNumber.length > 0 &&
      this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    if (this.globalState.getAuthListPage()) {
      const retrivedValue = this.globalState.getAuthListPage();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.currentStartRowCount = Number(
        this.gridApi?.paginationGetCurrentPage() + 1
      );
      this.currentRowCount = this.gridApi?.paginationGetTotalPages();
    }
  }

}