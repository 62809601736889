<div class="d-flex justify-content-between align-items-center ">
    <p class="page-title mx-3">Benchmark</p>
</div>
<div class="alert alert-danger mx-3" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <span class="message-text">{{ message }} </span>
      <button class="btn-close" (click)="close()"></button>
    </div>
  </div>
<div class="top-container">
    <div class="row mt-3 ms-3 me-3 heading-row">
        <div class="col-1 ps-0">
            <label> Plant </label>
        </div>
        <div class="col-1 ">
            <label> Family </label>
        </div>
        <div class="col-3 ">
            <label> Model Code </label>
        </div>
        <div class="col-3 ">
            <label> Part Number </label>
        </div>
        <div class="col-2">
            <label> Supplier </label>
        </div>
        <div class="col-1 ">
            <label> QPU </label>
        </div>
        <div class="col-1 pe-0">
            <label> Cost/㎥ </label>
        </div>
    </div>
    <div class="row mt-2 ms-3 me-3 content-row">
        <div class="col-1 ps-0">
            <span>{{plant}} </span>
        </div>
        <div class="col-1 ">
            <span>{{benchMarkParams?.familycode}}</span>
        </div>
        <div class="col-3 ">
            <span>{{modelCode}}</span>
        </div>
        <div class="col-3">
            <span>{{partNumber}}</span>
        </div>
        <div class="col-2 ">
            <span> {{supplier}} </span>
        </div>
        <div class="col-1 pe-0">
            <span>{{benchMarkData?.createHtml_Info_response?.qpu}}</span>
        </div>
        <div class="col-1">
            <span>${{benchMarkData?.createHtml_Info_response?.CostPerCubic}}</span>
        </div>
    </div>
</div>

<div class="vpacs-shadow m-3 p-0 mb-0 pb-5 mb-5">
    <div class="grid-agg px-0">
        <ag-grid-angular class="ag-theme-balham grid-items benchmark-grid" style="width: 100%; height:870px"
            (gridReady)="onGridReady($event)" (window:resize)="sizeToFit()" [animateRows]="true"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="displayRowData" [headerHeight]="40"
             [rowClassRules]="rowClassRules" [suppressCellSelection]="true">
        </ag-grid-angular>
    </div>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom" >
    <button class="cancel me-3 roboto-bold" (click)="onCancel()"> BACK </button>
    <button class="black-button me-3 roboto-medium" (click)="onSave()" >OK</button>
</div>

