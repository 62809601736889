import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { InternCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/internal-approvement/link-cell-render';
import { InternalApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/internal-approvement-list/internal-approvement-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-internal-approvement-list',
  templateUrl: './internal-approvement-list.component.html',
  styleUrls: ['./internal-approvement-list.component.scss']
})
export class InternalApprovementListComponent implements OnInit {
  radioVal = 1;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50
  isAccessible = false;
  plants: any[] = [];
  plant: any;
  autoGroupColumnDef: any;
  groupLIst:any;
  filteredOptions: any[] = [];

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  isAll = false;
  rowClassRules: any;
  bmVal = 1;
  preVal = 1;
  trialVal = 1;
  finalVal = 1;
  latestVal = 1;
  allVal = 0;
  public searchForm !: FormGroup;
  spCode: any;
  supplier: any;
  gridColumnApi: any;
  sortModel: any[] = [];
  specialistCode: any;
  resPlant: any;
  selectedPlant: any[] = [];
  haveAccess: any;
  haveAddAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  leaderPlant: any;
  approverCode: any;
  errorList: any[] = [];
  paramData: any;
  isNavigation = false;
  plantData: any;
  originalPage: any;
  loadResponse: any;
  apiSearch = false;
  filterData: any;
  specialCode : any;
  ial = false;
  unknown = false;  
  userInfo: any;


  constructor(
    public readonly route: Router,
    private readonly dialog: MatDialog,
    public readonly router: ActivatedRoute,
    private readonly iaService: InternalApprovementListService,
    private readonly spinnerService: NgxUiLoaderService,
    public formBuilder: FormBuilder,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      leaderPlant: ['', Validators.required],
      plant: ['', Validators.required],
      dock: ['', Validators.required],
      modelCode: ['', Validators.required],
      supplier: ['', Validators.required],
      partNumber: ['', Validators.required],
      specialist: [''],
      currentApprover: [''],
    });
    this.spinnerService.start();
    this.plantData=this.iaService.getInternalPlant();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_trackingview;
        this.haveAddAccess = res.role?.p_trackingsave;
        this.modifyAccess = res.role?.p_trackingcorrect;
        this.deleteAccess = res.role?.p_trackingdelete;
        this.userInfo = res;
        this.paramData = JSON.parse(
          sessionStorage.getItem('intApprove') || '{}',
        )
        this.spCode = res?.info?.specialistcode;

        if(res && this.haveAccess){
          if(this.plantData){
            this.filterData=this.iaService.getInternalFilters();
            if(this.filterData){
              this.ial=true;
              const allPagination = Number(sessionStorage.getItem('paginationIAL'));
              const allPaginationCurr = Number(sessionStorage.getItem('paginationIALCurr'));
              if(allPagination){
               this.rpPage = allPagination;
              }
              if(allPaginationCurr){
                this.cPage = allPaginationCurr;
               }
            this.leaderPlant = (this.filterData?.LeaderPlant?.length>1)? '*':this.filterData?.LeaderPlant[0];
            this.plant=(this.filterData?.PlantCode?.length>1)? '(All)':this.filterData?.PlantCode[0];
            this.plants = this.plantData;
            this.searchForm.get('dock')?.setValue(this.filterData.DockCode);
            this.searchForm.get('modelCode')?.setValue(this.filterData.ModelCode);
            this.searchForm.get('supplier')?.setValue(this.filterData.SupplierCode);
            this.searchForm.get('partNumber')?.setValue(this.filterData.PartNumber1);
            this.approverCode = this.filterData.CurrentApproverCode;
            this.specialistCode = this.filterData.SpCode; 
            this.bmVal = this.filterData.Bm;
            this.preVal =  this.filterData.Pre;
            this.trialVal = this.filterData.Trial;
            this.finalVal = this.filterData.Final;
            this.radioVal = Number(this.filterData.Do);
            }
          }
          this.specialCode=res.info?.specialistcode;
          this.loadPlants();
        }
        else if(res && !this.haveAccess){
          this.errorList = ["You're not authorized to access this page"];
          this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
        this.spinnerService.stop();
      }
    });

    
    this.displayRowData = [this.columnDefs];
    this.columnDefs = [
      {
        headerName: "leader plant", field: "leadername", sortable: true, unSortIcon: true, minWidth: 120, filter: 'agTextColumnFilter',
      },
      {
        headerName: "plant", field: "plantname", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "dock", field: "dockcode", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "model", field: "modelcode", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "part number", field: "partnumber", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "description", field: "partdescription", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "supplier", field: "suppliername", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "specialist", field: "specialist", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "current approver", field: "currentapprovername", sortable: true, unSortIcon: true, minWidth: 150,
      },
      {
        headerName: "date sent", field: "datesent", sortable: true, unSortIcon: true, minWidth: 100, valueFormatter: this.dateFormatter,comparator: dateComparator,
      },
      {
        headerName: "bm", field: "bm", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: InternCellRenderer, 
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
      },
      {
        headerName: "pre ppf", field: "preppf", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: InternCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },
      {
        headerName: "pkg trial", field: "pkgtrial", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: InternCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },
      {
        headerName: "final ppf", field: "finalppf", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: InternCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },

    ]
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }

  loadIntAppListDetails(params: any) {
    this.spinnerService.start();
    this.iaService.loadIntAppListDetails(params)
      .subscribe({
        next: (res: any) => {
          this.loadResponse = res;
          this.groupLIst=res.Output.GetGroupUserList; //changes
          this.displayRowData = res.Output.GetIntAppList.finalData
          this.iaService.setGroupList(this.groupLIst) //changes
          this.displayRowData.forEach(val=>{
            if(val.leadername === null || val.leadername === ''){
              val.leadername ='-';
            }
          })
          this.rowCount = res.Pagination.rows;
          this.totalRecords = res.Pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          if (this.cPage > this.tPage) {
            this.cPage = 1;
          }
         
          this.apiSearch = false;
          this.gridApi?.hideOverlay();
          this.spinnerService.stop();
        },
        error: (err: any) => {
          this.gridApi?.hideOverlay();
          this.displayRowData = [];
          this.rowCount = 0;
          this.spinnerService.stop();
          this.errorList = [`API-${err.status}`];
        }
      });
  }

  loadPlants() {
    this.spinnerService.start();

    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 1
    }
    this.iaService.getAllPlant(plantObj)
      .subscribe({
        next: (res: any) => {
          this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
          this.resPlant = res.map((item: { plantcode: any; }) => item.plantcode);
          this.selectedPlant = this.plants;
          this.plant = this.plant ?? '(All)'
          this.filteredOptions = this.plants
          this.displayFn(this.plants);
          this.leaderDisplayFn(this.plants)
          if (this.route.url === '/tracking-list/internal-approvement'){
            this.isNavigation = true;
          }
          this.unknown=this.iaService.getApprove();
          if(!this.unknown && !this.ial){
            this.approverCode = this.isNavigation ? '': (this.specialCode ?? this.spCode)
          }
          this.specialistCode = this.isNavigation ? this.specialCode: (this.filterData ? this.specialistCode : '')
          if(this.plantData || this.isNavigation){
            this.search();
          }
          this.iaService.setInternalPlant(this.plants)
         
        },
        error: (err: any) => {
          this.spinnerService.stop();
          this.errorList = [`API-${err.status}`];
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  leaderFilterOptions() {
    this.selectedPlant = this.plants.filter(option => option.plant?.toLowerCase().includes(this.leaderPlant?.toLowerCase()))
  }

  leaderDisplayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  cellStyleColumn(_params: any) {
    return ({ 'display': 'flex', 'justify-content': 'flex-start', 'height': '40px' })
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if(this.isAll){
      this.displayRowData = []
      sessionStorage.setItem('paginationIAL',this.rpPage.toString());
      for (let i=0; i<Math.ceil(this.totalRecords/100000); i++){
        this.cPage = i+1;
        this.tPage = 1;
        this.rpPage = 100000;
        await this.search()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }else  if ((this.plant === '(All)' || this.leaderPlant === '(All)') &&  (!this.searchForm.controls['supplier']?.value &&
    !this.searchForm.controls['dock']?.value &&
    !this.searchForm.controls['modelCode']?.value&&
    !this.specialistCode && !this.approverCode &&
    !this.searchForm.controls['partNumber']?.value)) {
    this.spinnerService.stop();
    this.errorList.push('For `All` plant, please select another field as well to search');
    return;
  }
    else{
      sessionStorage.setItem('paginationIAL',this.rpPage.toString());
      sessionStorage.setItem('paginationIALCurr',this.cPage.toString());
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.search();
    }
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi?.applyColumnState(this.sortModel)
    if (this.iaService.getInternalGridFilter()) {
      const retrivedValue = this.iaService.getInternalGridFilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }
  onFilterChanged(_params:any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    const allFilterModels = this.gridApi?.getFilterModel();
    this.iaService.setInternalGridFilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.loadResponse.Pagination.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
    else{
    setTimeout(() => {
      this.totalRecords = this.gridApi?.getDisplayedRowCount();
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }, 1000);
  }
  }

  selectSpecialist(field: any) {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: field === 'specialist' ? this.specialistCode : this.approverCode
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(field === 'specialist'){
        this.specialistCode = result;
      }
      else if(field === 'approver'){
        this.approverCode = result;
      }  
    })
  }
  onBmChange(event:any){
    if(event.target.checked){
      this.bmVal = 1;
    }
    else{
      this.bmVal = 0;
    }
  }
  onPreChange(event:any){
    if(event.target.checked){
      this.preVal = 1;
    }
    else{
      this.preVal = 0;
    }
  }
  onTrialChange(event:any){
    if(event.target.checked){
      this.trialVal = 1;
    }
    else{
      this.trialVal = 0;
    }
  }
  onFinalChange(event:any){
    if(event.target.checked){
      this.finalVal = 1;
    }
    else{
      this.finalVal = 0;
    }
  }
  search() {
    this.apiSearch = true;
    this.errorList = [];
    const plantCheck = this.plants.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    const plantCheckLeader = this.plants.find((item: any) => ((item.plant?.toLowerCase() === this.leaderPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.leaderPlant?.toLowerCase())))

    if ((![undefined,'undefined',null,'null'].includes(this.plant)) && (!plantCheck)) {
      this.errorList.push("Please select valid plant name");
      return;
    }
    else if((![undefined,'undefined',null,'null',''].includes(this.leaderPlant)) && (!plantCheckLeader) ){
      this.errorList.push("Please select valid leaderPlant name");
      return;
    }
    
    else {
    if ((this.plant === '(All)' || this.leaderPlant === '(All)') && (!this.searchForm.controls['supplier']?.value &&
      !this.searchForm.controls['dock']?.value &&
      !this.searchForm.controls['modelCode']?.value&&
      !this.specialistCode && !this.approverCode &&
      !this.searchForm.controls['partNumber']?.value)) {
      this.displayRowData = []
      this.totalRecords = 0;
      this.spinnerService.stop();
      this.errorList.push('For `All` plant, please select another field as well to search');
      return;
    } else {

    this.spinnerService.start();
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode;
    const leaderPlant = this.selectedPlant.filter((item: any) => ((item.plant?.toLowerCase() === this.leaderPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.leaderPlant?.toLowerCase())))
    this.leaderPlant = leaderPlant[0]?.plantcode;
    const params: any = {
      LeaderPlant: this.leaderPlant === '(All)' ? this.resPlant : (this.leaderPlant ? [this.leaderPlant] : []),
      PlantCode: this.plant === '(All)' ? this.resPlant : (this.plant ? [this.plant] : []),
      DockCode: this.searchForm.controls['dock']?.value ? this.searchForm.controls['dock']?.value : '',
      ModelCode: this.searchForm.controls['modelCode']?.value ? this.searchForm.controls['modelCode']?.value : '',
      SupplierCode: this.searchForm.controls['supplier']?.value ? this.searchForm.controls['supplier']?.value : '',
      PartNumber1: this.searchForm.controls['partNumber']?.value ? this.searchForm.controls['partNumber']?.value : '',
      PartNumber2: '',  
      CurrentApproverCode: this.approverCode ? this.approverCode : '',
      SpCode: this.specialistCode ? this.specialistCode : '',
      SupplierType: "",
      Bm: this.bmVal,
      Pre: this.preVal,
      Trial: this.trialVal,
      Final: this.finalVal,
      Do: this.radioVal.toString(),
      Type: 1,
      page: this.cPage,
      limit: this.rpPage,
      specialistCode: this.spCode ? this.spCode : '' 
    }
    this.iaService.setInternalFilters(params);
    this.loadIntAppListDetails(params);
  }
}
  }

}