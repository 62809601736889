import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'fab-btn-cell-render',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    [disabled]="isDisabed"
    class="vpacs-btn-table"
    style="height:30px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
  styles: [
    '.btn-disable { opacity: 0.5}',
    '.btn-enable { opacity: 1}'
  ]
})
export class FabBtnCellRender implements ICellRendererAngularComp {
  userInfo: any

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`)
  }
  private params: any;
  isButtonDisabled = false;
  buttonName: any;
  isDisabed =  false;
  res: any;
  updateFlag = false;
  deleteFlag  = false;
  disableBtn = 'btn-enable'
  btnEnable = 'btn-enable'
  btnDisable = 'btn-disable'
  agInit(params: any): void {
      this.params = params;
      if (params.colDef.headerName === 'UPDATE'){
        this.buttonName = 'UPDATE'; 
        this.updateFlag = !params.data.modifyAccess ? true : false;
        this.disableBtn = this.updateFlag ? this.btnDisable : this.btnEnable 
      } else if (params.colDef.headerName === 'DELETE' ){
        this.buttonName = 'DELETE'; 
        const used = this.params.data.used ? parseInt(this.params.data.used) : 0;
        this.deleteFlag = (used > 0 || !params.data.deleteAccess) ? true : false;
        this.disableBtn = this.deleteFlag ? this.btnDisable : this.btnEnable 
      }
      this.isDisabed= this.buttonName === 'UPDATE'? this.updateFlag:this.deleteFlag; 

  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data)
  }
}
