<div class="row">
    <div class="col-md-3">
      <p class="page-title mx-3">Send Dept.Approval(Pkg Trial)</p>
    </div>
  </div>
  
  <div class="alert alert-danger m-3" *ngIf="errorMessage?.length>0">
    <ng-container *ngFor="let error of errorMessage ">
      <div>{{error}}</div>
    </ng-container>
  </div>
  
  <div class="main-cont">
    <div class="row m-3 mt-0 content-row">
      <div class="col-md-4 align-items-center px-0">
        <table class="table" aria-describedby="leader-plant">
          <thead class="d-none">
            <tr class="titleColumn">
              <th scope="col" class="ps-4"></th>
            </tr>
          </thead>
          <tbody class="d-flex">
            <div class="col-md-6 titleColumn">
              <div *ngFor="let title of leaderPlant" class="contents ps-4 py-2">
                {{ title }}
              </div>
            </div>
            <div class="col-md-6 contentColumn p-0">
              <div
                *ngFor="let title of leaderPlantContents; let i = index"
                class="ps-4 py-2"
                [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'"
              >
                {{ title }}
              </div>
            </div>
          </tbody>
        </table>
      </div>
      <div class="col-md-3 align-items-center ps-4">
        <div class="d-flex align-items-baseline ps-0 mb-3">
          <div class="col-md-6 titleColumn p-0">
            <div *ngFor="let title of qpc" class="contents ps-4 py-2">
              {{ title }}
            </div>
          </div>
          <div class="col-md-6 contentColumn p-0">
            <div
              *ngFor="let title of qpcContents; let i = index"
              class="ps-4 py-2"
              [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'"
            >
              {{ title }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-baseline ps-0" *ngIf="showWorkTable">
          <table class="table" aria-describedby="work">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="ps-4">WORK <small class="fw-normal">*</small></th>
              </tr>
            </thead>
            <tbody class="d-flex">
              <div class="col-md-6 titleColumn">
                <div *ngFor="let title of work" class="contents ps-4 py-2">
                  {{ title }}
                </div>
              </div>
              <div class="col-md-6 contentColumn p-0">
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group direction-vertical d-grid p-0"
                  name="work" [(ngModel)]="radioValWork"
                >
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
                    [value]="2"
                    [checked]="rdoSupWorkChecked"
                    [disabled]="rdoSupWorkDisabled"
                  ></mat-radio-button>
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex row-odd"
                    [value]="1"
                    [checked]="rdoContFabWorkChecked"
                    [disabled]="rdoContFabWorkDisabled"
                  ></mat-radio-button>
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
                    [value]="3"
                    [checked]="rdoDun1FabWorkChecked"
                    [disabled]="rdoDun1FabWorkDisabled"
                  ></mat-radio-button>
                </mat-radio-group>
              </div>
            </tbody>
          </table>
        </div>
        <div class="d-flex align-items-center mt-2">
          <button
            type="buton"
            class="black-button me-3 roboto-medium"
            (click)="PPF()"
          >
            PPF
          </button>
          <button
            type="buton"
            class="black-button me-3 roboto-medium"
            (click)="conceptCompare()"
            [disabled]="isConceptCompareDisabled"
          >
            CONCEPT COMPARE
          </button>
        </div>
      </div>
      <div class="col-md-5 ps-5 mt-0">
        <div class="row ps-0" *ngIf="hazmat">
          <h5>Hazmat Approval</h5>
          <div class="col-md-6 titleColumn">
            <div class="contents ps-3 py-2">
              Hazmat Approval Status
            </div>
          </div>
          <div class="col-md-6 contentColumn p-0 d-flex">
            <div class="d-flex align-items-center py-2">
                <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group align-self-stretch"
                name="hazmatVal"
                [(ngModel)]="hazmatVal"
              >
                <mat-radio-button
                  class="example-radio-button ms-2"
                  [value]="1"
                  [disabled]="hazmatApproveDisabled"
                  [checked]="hazmatApproveChecked"
                >
                  Approved
                </mat-radio-button>
                <mat-radio-button
                  class="example-radio-button ms-4"
                  [value]="0"
                  [disabled]="hazmatRejectDisabled"
                  [checked]="hazmatRejectChecked"
                >
                  Rejected
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="row align-items-baseline ps-0 mt-3" *ngIf="hazmat">
          <label for="hazmatComment" class="control-label col-md-12 ps-0">
              Hazmat Comment
            </label>
            <textarea
              name=""
              id="hazmatComment"
              cols="170"
              rows="3"
              class="form-control detail_area"
              name="hazmatComment"
              [disabled]="hazmatCommentDisabled"
              [(ngModel)]="hazmatComment"
            ></textarea>
            
        </div>
        </div>
    </div>
    
    <div class="row m-3" *ngIf="tblProposalDisplay">
      <div class="col-md-4 px-0 d-flex">
        <div class="col-md-6 titleColumn d-flex align-items-center">
          <div class="contents ps-4 py-2">
            Proposal with Changes
          </div>
        </div>
        <div class="col-md-6 contentColumn p-0 d-flex align-items-center me-2 ps-4 py-2">
            <div id="tdProposalChanges" class="d-flex align-items-center me-3">{{tdProposalChanges}}</div>
            <button
                class="vpacs-btn-table ms-3"
                style="height: 30px; font-size: 11px;" (click) = "goToHistory()"
            >
                HISTORY
            </button>
        </div>
      </div>
    </div>


    <div class="row m-3 mt-0 content-row" *ngIf="displayQuestionnaire">
      <div class="col-md-7 align-self-center ps-0">
        <h5>Supplier Questionnaire</h5>
      </div>
    
      <div class="col-md-7 d-flex align-self-center titleColumn">
        <h6 class="mb-0 py-2">Reply to Toyota</h6>
      </div>
      <div class="col-md-7 align-items-center questionnaire-container ps-0 pe-0">
        <table class="table" aria-describedby="part-data" *ngIf="displaytrQuestionnaire">
          <thead>
            <tr class="titleColumn">
              <th scope="col" class="ps-3">PART DATA EVALUATION</th>
              <th scope="col" class="ps-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="contentColumn">
              <td class="ps-3 w-75">
                1. Is part number and description correct?
                <br />
                If not, please enter the correct part number or description.
              </td>
              <td>
                <table aria-describedby="q1">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <td class="d-flex">
                        <input
                          value="2"
                          name="rdoYN_1_1"
                          type="radio"
                          class="me-3"
                          id="rdoYes_1_1"
                          [checked]="rdoYes11Checked"
                          disabled
                        />
                        Yes
                        <input
                          value="2"
                          name="rdoYN_1_1"
                          type="radio"
                          id="rdoNo_1_1"
                          [checked]="rdoNo11Checked"
                          class="ms-5 me-3"
                          disabled
                        />
                        No
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          name="txtNote_1_1"
                          type="text"
                          id="txtNote_1_1"
                          disabled
                          [(ngModel)]="txtNote11Value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">
                2. Is this the correct supplier code?
                <br />
                If not, please identify the correct code if possible.
              </td>
              <td>
                <table aria-describedby="q2">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <td class="d-flex">
                        <input
                          value="2"
                          name="rdoYN_1_2"
                          type="radio"
                          class="me-3"
                          id="rdoYes_1_2"
                          [checked]="rdoYes12Checked"
                          disabled
                        />
                        Yes
                        <input
                          value="2"
                          name="rdoYN_1_2"
                          type="radio"
                          id="rdoNo_1_2"
                          class="ms-5 me-3"
                          disabled
                          [checked]="rdoNo12Checked"
                        />
                        No
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          name="txtNote_1_2"
                          type="text"
                          id="txtNote_1_2"
                          disabled
                          [(ngModel)]="txtNote12Value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">3. Part data availability date.</td>
              <td>
                <table aria-describedby="q3">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <input type="text" disabled *ngIf="q13Date" [(ngModel)]="q13Date"/>
                    <input type="date" disabled *ngIf="!q13Date"/>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">4. Sample part availability date.</td>
              <td>
                <table aria-describedby="q4">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <input type="text" disabled *ngIf="q14Date" [(ngModel)]="q14Date"/>
                      <input type="date" disabled *ngIf="!q14Date"/>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table mt-3" aria-describedby="pkg-evaluation" *ngIf="displaytrQuestionnaire">
          <thead>
            <tr class="titleColumn">
              <th scope="col" class="ps-3">PACKAGE EVALUATION</th>
              <th scope="col" class="ps-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="contentColumn">
              <td class="ps-3 w-75">1. Expected concept evaluation date.</td>
              <td>
                <table aria-describedby="q11">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <input type="text" disabled *ngIf="q21Date" [(ngModel)]="q21Date"/>
                      <input type="date" disabled *ngIf="!q21Date"/>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">2. Evaluation method.</td>
              <td>
                <mat-select
                  class="mat-sel"
                  name="sel_2_2"
                  id="sel_2_2"
                  size="1"
                  style="width: 100%;"
                  disabled
                  [(ngModel)]="sel22SelectedIndex"
                >
                  <mat-option *ngFor="let opt of sel22Items" [value]="opt.index">
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">3. Concept evaluation.</td>
              <td>
                <mat-select
                  class="mat-sel"
                  name="sel_2_3"
                  id="sel_2_3"
                  size="1"
                  style="width: 100%;"
                  disabled
                  [(ngModel)]="sel23SelectedIndex"
                >
                  <mat-option *ngFor="let opt of sel23Items" [value]="opt.index">
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">4. Reason for modification or new proposal?</td>
              <td>
                <mat-select
                  class="mat-sel"
                  name="sel_2_4"
                  id="sel_2_4"
                  size="1"
                  style="width: 100%;"
                  disabled
                  [(ngModel)]="sel24SelectedIndex"
                >
                  <mat-option *ngFor="let opt of sel24Items" [value]="opt.index">
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
                <br />
                <input
                  name="txtNote_2_4"
                  type="text"
                  id="txtNote_2_4"
                  class="mt-2"
                  disabled
                  [(ngModel)]="txtNote24Value"
                />
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">
                5. If modification or new concept is needed, can another exact
                Toyota
                <br />
                packaging design be used?
              </td>
              <td>
                <table aria-describedby="q55">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          value="2"
                          name="rdoYN_2_5"
                          type="radio"
                          class="me-3"
                          id="rdoYes_2_5"
                          [checked]="rdoYes25Checked"
                          disabled
                        />
                        Yes
                      </td>
                      <td>
                        <input
                          value="2"
                          name="rdoYN_2_5"
                          type="radio"
                          id="rdoNo_2_5"
                          [checked]="rdoNo25Checked"
                          disabled
                          class="ms-5 me-3"
                        />
                        No
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">
                <tr>6. Was the above part copied into the PPF?</tr>
                <br />
                <tr class="d-flex ps-3">Part Number</tr>
                <br />
                <tr class="d-flex ps-3">Model Code</tr>
              </td>
              <td>
                <table aria-describedby="q66">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <td class="d-flex">
                        <input
                          value="2"
                          name="rdoYN_2_6"
                          type="radio"
                          class="me-3"
                          id="rdoYes_2_6"
                          [checked]="rdoYes26Checked"
                          disabled
                        />
                        Yes
                        <input
                          value="2"
                          name="rdoYN_2_6"
                          type="radio"
                          id="rdoNo_2_6"
                          [checked]="rdoNo26Checked"
                          class="ms-5 me-3"
                          disabled
                        />
                        No
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          name="txtPart_2_6"
                          type="text"
                          id="txtPart_2_6"
                          class="mt-2"
                          disabled
                          [(ngModel)]="txtPart26Value"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          name="txtModel_2_6"
                          type="text"
                          id="txtModel_2_6"
                          class="mt-2"
                          disabled
                          [(ngModel)]="txtModel26Value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="contentColumn">
              <td class="ps-3">
                7. Based on the revised proposal, is a newdunnage design needed?
              </td>
              <td>
                <table aria-describedby="q77">
                  <th id="empty"></th>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          value="2"
                          name="rdoYN_2_7"
                          type="radio"
                          class="me-3"
                          id="rdoYes_2_7"
                          [checked]="rdoYes27Checked"
                          disabled
                        />
                        Yes
                      </td>
                      <td>
                        <input
                          value="2"
                          name="rdoYN_2_7"
                          type="radio"
                          id="rdoNo_2_7"
                          [checked]="rdoNo27Checked"
                          class="ms-5 me-3"
                          disabled
                        />
                        No
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            
          </tbody>
        </table>
        <table class="table mt-3" aria-describedby="pkg-evaluation"  *ngIf="trSupplierConfirmDisplay">
            <thead class="d-none">
              <tr class="titleColumn">
                <th scope="col" class="ps-3">SUPPLIER CONFIRM</th>
                <th scope="col" class="ps-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="contentColumn"
                id="trSupplierConfirm"
              >
                <td class="ps-3 fw-500">
                    I have reviewed and tested the packaging per guidlines in chapter three of SLIM.
                </td>
                <td>
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group d-flex p-0"
                    name="rdoYN_test"
                  >
                    <mat-radio-button
                      class="example-radio-button ps-4 py-2 align-items-center d-flex"
                      [value]="1"
                      [checked]="rdoYesTestChecked"
                      [disabled]="true"
                      (change)="onRadioChange($event)"
                    >
                      Yes
                    </mat-radio-button>
                    <mat-radio-button
                      class="example-radio-button ps-4 py-2 align-items-center d-flex"
                      [value]="0"
                      [checked]="rdoNoTestChecked"
                      [disabled]="true"
                      (change)="onRadioChange($event)"
                    >
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
              </tr>
            </tbody>
          </table>
        <table class="table mt-3" aria-describedby="pkg-evaluation"  *ngIf="displaytrQuestionnaireComment">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="ps-3">COMMENT</th>
                <th scope="col" class="ps-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="contentColumn"
                id="trQuestionnaireComment"
               
              >

                <td>
                  <textarea rows="3" [(ngModel)]="taNoteValue" disabled></textarea>
                </td>
              </tr>
            </tbody>
        </table>
    
        <div class="row mt-3 ps-3 content-row d-flex align-items-baseline">
          <div class="col-md-6 ps-0">
            <label for="taIntComment" class="control-label col-md-12">
              Internal Comment
            </label>
            <textarea
              name=""
              id="taIntComment"
              cols="170"
              rows="3"
              class="form-control detail_area"
              name="taIntComment"
              [(ngModel)]="taIntComment"
              [disabled]="rdoNoChecked"
            ></textarea>
          </div>
       
          <div class="col-md-6">
            <label for="taExtComment" class="control-label col-md-12 ps-0">
              Supplier/ Fabricator Comment
            </label>
            <textarea
              name=""
              id="taExtComment"
              cols="170"
              rows="3"
              class="form-control detail_area"
              name="taExtComment"
              [(ngModel)]="taExtComment"
            ></textarea>
          </div>
        </div>
  
        
  
        <table class="table mt-3 pb-5" aria-describedby="label">
          <thead>
            <tr class="titleColumn">
              <th scope="col" class="text-center">SHARE PLANTS</th>
              <th scope="col" class="text-center">OK</th>
              <th scope="col" class="text-center">NG</th>
              <th scope="col" class="text-center">&nbsp;</th>
            </tr>
          </thead>
          <tbody class="contentColumn">
            <tr class="row-even transparent">
              <td class="text-center">
                <label class="py-2">{{ tdSharePlant }}</label>
              </td>
              <td class="text-center">
                <label class="py-2">{{ tdSharePlantOK }}</label>
              </td>
              <td class="text-center">
                <label class="py-2">{{ tdSharePlantNG }}</label>
              </td>
              <td class="flex-center d-flex justify-content-center">
                <button
                  class="vpacs-btn-table"
                  style="height: 30px; font-size: 11px;"
                  [disabled]="btnSharePlantsDisabled"
                  (click)="goToIntAppr()"
                >
                  DETAIL
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-5 ps-5 mt-3">
        <div class="row mb-3">
          <div class="col-md-5 titleColumn">
            <div class="contents ps-4 py-2">
              Internal Approval<small class="ps-2">*</small>
            </div>
          </div>
          <div class="col-md-7 contentColumn p-0 d-flex">
            <div class="d-flex align-items-center me-2 ps-4 py-2">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group align-self-stretch"
                name="radioVal"
                [(ngModel)]="radioVal"
              >
                <mat-radio-button
                  class="example-radio-button ms-5 me-5"
                  [value]="1"
                  [disabled]="rdoYesDisabled"
                  [checked]="rdoYesChecked"
                  (change)="internalAppChange($event)"
                >
                  Yes
                </mat-radio-button>
                <mat-radio-button
                  class="example-radio-button ms-5"
                  [value]="0"
                  [disabled]="rdoNoDisabled"
                  [checked]="rdoNoChecked"
                  (change)="internalAppChange($event)"
                >
                  No
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        
        <div class="row">
          <table class="table" aria-describedby="label">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="text-center">&nbsp;&nbsp;</th>
                <th scope="col" class="text-center">DOCK</th>
                <th scope="col" class="text-center">STATUS</th>
                <th scope="col" class="text-center">
                  CONV
                  <br />
                  APPROVER
                </th>
                <th scope="col" class="text-center">DETAIL</th>
              </tr>
            </thead>
            <tbody class="contentColumn">
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock1"
                      [checked]="chkSelDock1Checked"
                      [disabled]="chkSelDock1Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock1 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept1 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock1"
                    [(ngModel)]="selDock1"
                    [disabled]="selDock1Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectDvSpec1"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock1Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-odd transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock2"
                      [checked]="chkSelDock2Checked"
                      [disabled]="chkSelDock2Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock2 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept2 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock2"
                    [(ngModel)]="selDock2"
                    [disabled]="selDock2Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectDvSpec2"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock2Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock3"
                      [checked]="chkSelDock3Checked"
                      [disabled]="chkSelDock3Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock3 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept3 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock3"
                    [(ngModel)]="selDock3"
                    [disabled]="selDock3Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectDvSpec3"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock3Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-odd transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock4"
                      [checked]="chkSelDock4Checked"
                      [disabled]="chkSelDock4Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock4 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept4 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock4"
                    [(ngModel)]="selDock4"
                    [disabled]="selDock4Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectDvSpec4"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock4Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock5"
                      [checked]="chkSelDock5Checked"
                      [disabled]="chkSelDock5Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock5 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept5 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock5"
                    [(ngModel)]="selDock5"
                    [disabled]="selDock5Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectDvSpec5"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock5Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div
          class="row d-flex align-items-baseline ps-0"
          *ngIf="tblRequestReasonDisplay"
        >
          <table class="table" aria-describedby="reqChange">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="ps-4">REQUESTED CHANGES</th>
              </tr>
            </thead>
            <tbody class="d-flex">
              <div class="col-md-4 titleColumn">
                <div
                  *ngFor="let req of reqChangesChkList; let i = index"
                  class="contents ps-2 py-2"
                >
                  <label class="checkcontainer">
                    {{ req.description }}
                    <input
                      type="checkbox"
                      [checked]="req.checked === true"
                      (change)="onChange($event, i)"
                      [disabled]="cblRequested"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div
                class="col-md-8 contentColumn p-0 d-flex justify-content-center align-items-center"
              >
                <textarea
                  id="taRequestChanges"
                  cols="170"
                  rows="3"
                  class="form-control detail_area w-90 h-90"
                  name="taRequestChanges"
                  [(ngModel)]="taRequestChanges"
                  [disabled] = "taRequestChangesDisabled"
                ></textarea>
              </div>
            </tbody>
          </table>
        </div>
    
        <div
          class="row d-flex align-items-baseline ps-0"
          *ngIf="tblRequestReasonDisplay"
        >
          <table class="table" aria-describedby="reqChange">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="ps-4">REASON REQUESTED</th>
              </tr>
            </thead>
            <tbody class="d-flex">
              <div class="col-md-4 titleColumn">
                <div
                  *ngFor="let req of reasonReqArr; let i = index"
                  class="contents ps-2 py-2"
                >
                  <label class="checkcontainer">
                    {{ req.description }}
                    <input
                      type="checkbox"
                      [checked]="req.checked === true"
                      (change)="onReasonChange($event, i)"
                      [disabled]="cblReason"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div
                class="col-md-68 contentColumn p-0 d-flex justify-content-center align-items-center"
              >
                <textarea
                  id="taReasonRequested"
                  cols="170"
                  rows="3"
                  class="form-control detail_area w-90 h-90"
                  name="taReasonRequested"
                  [(ngModel)]="taReasonRequested"
                  [disabled] = "taReasonRequestedDisabled"
                ></textarea>
              </div>
            </tbody>
          </table>
        </div>
    
        <div class="row" *ngIf="tblTrialDisplay">
          <table class="table" aria-describedby="trial">
            <thead class="d-none">
              <tr class="titleColumn">
                <th scope="col" class="ps-4">Trial</th>
              </tr>
            </thead>
            <tbody class="d-flex trial">
              <div class="col-md-4 titleColumn">
                <div
                  *ngFor="let title of trial"
                  class="contents ps-2 py-2 d-flex align-items-center"
                >
                  {{ title }}
                </div>
              </div>
              <div class="col-md-8 contentColumn p-0">
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group d-flex p-0"
                  name="trial"
                >
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
                    [value]="1"
                    [checked]="rdoTrialYesChecked"
                    [disabled]="rdoTrialYesDisabled"
                    (change)="onRadioChange($event)"
                  >
                    Yes
                  </mat-radio-button>
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex"
                    [value]="0"
                    [checked]="rdoTrialNoChecked"
                    [disabled]="rdoTrialNoDisabled"
                    (change)="onRadioChange($event)"
                  >
                    No
                  </mat-radio-button>
                  <mat-radio-button
                    class="example-radio-button ps-4 py-2 align-items-center d-flex"
                    [value]="2"
                    [checked]="rdoAsFinalChecked"
                    [disabled]="rdoAsFinalDisabled"
                    (change)="onRadioChange($event)"
                  >
                    Approve as Final
                  </mat-radio-button>
                </mat-radio-group>
                <div class="row-odd d-flex">
                  <input
                    type="text"
                    class="ps-4"
                    id="txtParentPart"
                    maxlength='10'
                    [(ngModel)]="txtParentPart"
                    [disabled]="txtParentPartDisabled"
                  />
                  <button
                    class="vpacs-btn-table ms-3"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnParentPartDisabled"
                    (click)="goToSelectPart()"
                  >
                    SELECT
                  </button>
                </div>
                <div class="row-even d-flex">
                  <textarea
                    id="taTriFinalReason"
                    cols="170"
                    rows="3"
                    class="form-control detail_area w-90 h-90"
                    name="taTriFinalReason"
                    [(ngModel)]="taTriFinalReason"
                    [disabled]="taTriFinalReasonDisabled"
                  ></textarea>
                </div>
              </div>
            </tbody>
          </table>
        </div>
    
        
      </div>
    </div>
    
    <div
      class="create_footer d-flex justify-content-end align-items-center fixed-bottom-r"
    >
      <button
        class="black-button me-3 roboto-medium"
        *ngIf="!hideControlBtnSend"
        [disabled]="btnSendDisabled"
        (click)="send()"
      >
        SEND
      </button>
      <button
        class="black-button me-3 roboto-medium"
        *ngIf="!hideControlbtnApprove"
        (click)="approve()"
      >
        APPROVE
      </button>
      <button
        class="black-button me-3 roboto-medium"
        *ngIf="!hideControlBtnReject"
        (click)="reject()"
      >
        REJECT
      </button>
      <button
        class="black-button me-3 roboto-medium"
        *ngIf="!hideControlBtnRejectBM"
        (click)="rejectBm()"
      >
        REJECT TO BENCHMARK
      </button>
      <button
        class="black-button me-3 roboto-medium"
        *ngIf="!hideControlBtnRejectSup"
        (click)="rejectSup()"
      >
        REJECT TO SUPPLIER
      </button>
      <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
    </div>
  </div>
  
  