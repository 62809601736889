<p class="page-title mx-2 px-1">Package Authorizations</p>
<div class="vpacs-shadow m-2 py-2 px-2">
<div class="filter d-flex" [formGroup]="searchParameters" (keyup.enter)="onSearch()">   
    <div class="d-flex  pe-5 align-items-center">
        <label for="from-date">From Date</label>
        <input type="date" id="from-date" (keyup)="dateOnChange($event, 'from')" formControlName="fromDate"/>
    </div>
    <div class="d-flex pe-5 align-items-center">
        <label for="step">Type</label>
        <mat-select formControlName="type" class="mat-sel pt-2" disableOptionCentering>
            <mat-option *ngFor="let s of pkgstep" [value]="s.value">{{s.value}}</mat-option>
        </mat-select>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="part-no">Part#</label>
        <input type="text" id="part-no"  formControlName="partnum"/>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="supplier">Supplier</label>
        <mat-select formControlName="supplier" id="supplier" (valueChange)="valueChange($event)" class="mat-sel pt-2" disableOptionCentering>
            <mat-option value="ALL" *ngIf="supplierNumber?.length > 1">------ALL------</mat-option>
            <mat-option *ngFor="let s of supplierNumber; index as i" [value]="i">{{s}}</mat-option>
        </mat-select>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="to-date">To Date</label>
        <input type="date" id="to-date" (keyup)="dateOnChange($event, 'to')" formControlName="toDate"/>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="from">From</label>
        <input type="text" id="from"  formControlName="from"/>
    </div>
    <div class="d-flex  pe-5  align-items-center">
        <label for="model">Model</label>
        <input type="text" id="model"  formControlName="model"/>
    </div>
    <div class="d-flex align-items-center">
        <div class="radio align-items-center">
            <span *ngFor="let radio of doRadioButtons">
                <input type="radio" name="check" id={{radio.id}} value={{radio.value}} formControlName="check">
                <label for={{radio.id}>{{radio.text}}</label><br>
            </span>
        </div>
        <button class="black-button" [disabled]="!searchParameters.valid" (click)="onSearch()">Search</button>
    </div>
</div>
</div>
<div class="m-1 pt-2">
<div class="legend my-1 mx-3">
    <app-tracking-legend></app-tracking-legend>
</div>
<div class="grid-agg skeleton-loader gap-3" *ngIf="loading" style="width:100%;height:370px;">
    <div class="spinner-border text-secondary"></div>
    Loading... Please wait
</div>
<div class="grid-agg grid-height-pkg">
    <ag-grid-angular style="width:100%;height:100%;"
        class="{{loading ? 'opacity-0 ag-theme-balham h-25' : 'ag-theme-balham'}}"
        (gridReady)="onGridReady($event);" 
        (window:resize)="sizeToFit()" 
        [rowHeight]="rowHeight"
        [animateRows]="true"
        [defaultColDef]="defaultColDef" 
        [columnDefs]="columnDefs" 
        [rowData]="displayRowData"
        [rowClassRules]="rowClassRules"
        [suppressCellSelection] = "true"
        [pagination]="true" 
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel] = "true"
        [icons]="icons"
        [headerHeight]="40"
        [floatingFiltersHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (paginationChanged)="onPaginationChanged()"
        (filterChanged) = "filterChanged($event)"
        >       
    </ag-grid-angular>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf="rowCount > 0 && !loading">
        <div >
            <div>
                <label for="pagination" class="page-drpdwn-label mx-2">Records/Page: </label>
                <select id="pagination" style="height: 30px;" (change)="onPageSizeChanged($event)">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20" selected>20</option>
                    <option value="50">50</option>
                    <option value="{{rowCount}}">ALL</option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div>Viewing Records</div>
            <mat-icon (click)="goToFirstPage()" class="text-primary"
            [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >first_page</mat-icon>
            <mat-icon (click)="goToPreviousPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >fast_rewind</mat-icon>
            <div>
                (
                <span class="value" id="lbCurrentPage"></span>-
                <span class="value" id="lbTotalPages"></span>)
                <span>OF {{rowCount}}</span>
            </div>
            <mat-icon (click)="goToNexpage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"                
                >fast_forward</mat-icon>
            <mat-icon (click)="goToLastPage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"
                >last_page</mat-icon>
        </div>
    </div>
</div>
</div>
<router-outlet></router-outlet>   
