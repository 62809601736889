<div class="d-flex justify-content-between align-items-center m-3">
  <p class="page-title">Send Dept.Approval(BM)</p>
</div>

<div class="alert alert-danger m-3" *ngIf="errorMessage?.length>0">
  <ng-container *ngFor="let error of errorMessage ">
    <div>{{error}}</div>
  </ng-container>
</div>

<div class="row m-3 content-row pb-5">
  <div class="col-md-4 align-items-center ps-0 pe-4">
    <table class="table" aria-describedby="leader-plant">
      <th class = "d-none"></th>
      <tbody class="d-flex">
        <div class="col-md-5 titleColumn">
          <div *ngFor="let title of leaderPlant" class="contents ps-4 py-2">
            {{ title }}
          </div>
        </div>
        <div class="col-md-7 contentColumn p-0">
          <div
            *ngFor="let title of leaderPlantContents; let i = index"
            class="ps-4 py-2"
            [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'"
          >
            <div *ngIf="!isService"> {{ title }} </div>
            <div *ngIf="i!==4 && isService"> {{ title }} </div>
            <a *ngIf="i===4 && isService" (click)="openSupplierInfo()">{{ title }}</a>
          </div>
        </div>
      </tbody>
    </table>
    <div class="d-flex align-items-center">
      <button class="black-button me-3 roboto-medium" (click)="PPF()">
        PPF
      </button>
      <button
        class="black-button me-3 roboto-medium" *ngIf="!isService" [disabled]="isConceptCompareDisabled"
        (click)="conceptCompare()"
      >
        CONCEPT COMPARE
      </button>
    </div>
    <div class="d-block align-items-baseline ps-0 mt-3" *ngIf="!isService">
        <label for="taIntComment" class="control-label col-md-12">
            Internal Comment
          </label>
          <textarea
            name=""
            id="taIntComment"
            cols="170"
            rows="3"
            class="form-control detail_area"
            name="taIntComment"
            [(ngModel)]="taIntComment"
            [disabled]="rdoNoChecked"
          ></textarea>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row">
      <div class="col-md-6 titleColumn p-0">
        <div *ngFor="let title of qpc" class="contents ps-4 py-2">
          {{ title }}
        </div>
      </div>
      <div class="col-md-6 contentColumn p-0">
        <div
          *ngFor="let title of qpcContents; let i = index"
          class="ps-4 py-2"
          [ngClass]="i % 2 === 0 ? 'row-even' : 'row-odd'"
        >
          {{ title }}
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showWorkTable">
      <table class="table" aria-describedby="work">
        <thead>
          <tr class="titleColumn">
            <th scope="col" class="ps-4">WORK <small class="fw-normal">*</small></th>
          </tr>
        </thead>
        <tbody class="d-flex">
          <div class="col-md-6 titleColumn">
            <div *ngFor="let title of work" class="contents ps-4 py-2">
              {{ title }}
            </div>
          </div>
          <div class="col-md-6 contentColumn p-0">
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group direction-vertical d-grid p-0"
              name="work" [(ngModel)]="radioValWork"
            >
              <mat-radio-button
                class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
                [value]="2"
                [checked]="rdoSupWorkChecked"
                [disabled]="rdoSupWorkDisabled" (change)="onWorkChange($event)"
              ></mat-radio-button>
              <mat-radio-button
                class="example-radio-button ps-4 py-2 align-items-center d-flex row-odd"
                [value]="1"
                [checked]="rdoContFabWorkChecked"
                [disabled]="rdoContFabWorkDisabled" (change)="onWorkChange($event)"
              ></mat-radio-button>
              <mat-radio-button
                class="example-radio-button ps-4 py-2 align-items-center d-flex row-even"
                [value]="3"
                [checked]="rdoDun1FabWorkChecked"
                [disabled]="rdoDun1FabWorkDisabled" (change)="onWorkChange($event)"
              ></mat-radio-button>
            </mat-radio-group>
          </div>
        </tbody>
      </table>
    </div>
    <div class="row mt-2" *ngIf="!hideControltblReqAsFinal">
      <div class="d-flex align-items-center mt-2 ps-0" >
        <label class="checkcontainer mb-0">
          Request As Final
          <input [ngClass]="{disableClass : isService} " type="checkbox" [checked]="chkReqAsFinal === true" [disabled]="isService"
          (change)="onChange($event)" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="row d-block align-items-baseline ps-0 mt-3">
      <label for="reason" class="control-label col-md-12 ps-0">Reason <small *ngIf="chkReqAsFinal">*</small></label>
      <textarea
        name=""
        id="reason"
        cols="170"
        rows="3"
        class="form-control detail_area"
        [(ngModel)]="taFinalReason"
        [disabled]="taFinalReasonDisabled"
      ></textarea>
    </div>
    <div class="row align-items-baseline ps-0 mt-3">
        <label for="taExtComment" class="control-label col-md-12 ps-0">
            Supplier/ Fabricator Comment
          </label>
          <textarea
            name=""
            id="taExtComment"
            cols="170"
            rows="3"
            class="form-control detail_area"
            name="taExtComment"
            [(ngModel)]="taExtComment"
          ></textarea>
    </div>
  </div>
  <div class="col-md-5">
    <div class="row ps-4" *ngIf="!isService">
      <div class="col-md-5 titleColumn">
        <div class="contents ps-3 py-2">
          Internal Approval<small class="ps-2">*</small>
        </div>
      </div>
      <div class="col-md-7 contentColumn p-0 d-flex">
        <div class="d-flex align-items-center me-2 ps-4 py-2">
            <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group align-self-stretch"
            name="radioVal"
            [(ngModel)]="radioVal"
          >
            <mat-radio-button
              class="example-radio-button ms-5 me-5"
              [value]="1"
              [disabled]="rdoYesDisabled"
              [checked]="rdoYesChecked"
              (change)="internalAppChange($event)"
            >
              Yes
            </mat-radio-button>
            <mat-radio-button
              class="example-radio-button ms-5"
              [value]="0"
              [disabled]="rdoNoDisabled"
              [checked]="rdoNoChecked"
              (change)="internalAppChange($event)"
            >
              No
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="row mt-3 ps-4" *ngIf="!isService">
        <table class="table" aria-describedby="label">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="text-center">&nbsp;&nbsp;</th>
                <th scope="col" class="text-center">DOCK</th>
                <th scope="col" class="text-center">STATUS</th>
                <th scope="col" class="text-center">
                  CONV
                  <br />
                  APPROVER
                </th>
                <th scope="col" class="text-center">DETAIL</th>
              </tr>
            </thead>
            <tbody class="contentColumn">
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock1"
                      [checked]="chkSelDock1Checked"
                      [disabled]="chkSelDock1Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock1 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept1 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock1"
                    [(ngModel)]="selDock1"
                    [disabled]="selDock1Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectdvspec1"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock1Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-odd transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock2"
                      [checked]="chkSelDock2Checked"
                      [disabled]="chkSelDock2Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock2 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept2 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock2"
                    [(ngModel)]="selDock2"
                    [disabled]="selDock2Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectdvspec2"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock2Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock3"
                      [checked]="chkSelDock3Checked"
                      [disabled]="chkSelDock3Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock3 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept3 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock3"
                    [(ngModel)]="selDock3"
                    [disabled]="selDock3Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectdvspec3"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock3Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-odd transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock4"
                      [checked]="chkSelDock4Checked"
                      [disabled]="chkSelDock4Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock4 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept4 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock4"
                    [(ngModel)]="selDock4"
                    [disabled]="selDock4Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectdvspec4"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock4Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="checkcontainer mb-0">
                    <input
                      type="checkbox"
                      name="chkSelDock5"
                      [checked]="chkSelDock5Checked"
                      [disabled]="chkSelDock5Disabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdDock5 }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdCrrDept5 }}</label>
                </td>
                <td class="text-center">
                  <mat-select
                    id=""
                    class="mat-sel pt-2 ms-3"
                    name="selDock5"
                    [(ngModel)]="selDock5"
                    [disabled]="selDock5Disabled" placeholder="--Select--"
                    disableOptionCentering
                  >
                    <mat-option
                      *ngFor="let item of selectdvspec5"
                      [value]="item.specialistcode"
                    >
                      {{ item.specialist }}
                    </mat-option>
                  </mat-select>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSelDock5Disabled"
                    (click)="goToDeptAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
    <div class="row mt-3 ps-4" *ngIf="!isService">
        <table class="table" aria-describedby="label">
            <thead>
              <tr class="titleColumn">
                <th scope="col" class="text-center">SHARE PLANTS</th>
                <th scope="col" class="text-center">OK</th>
                <th scope="col" class="text-center">NG</th>
                <th scope="col" class="text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody class="contentColumn">
              <tr class="row-even transparent">
                <td class="text-center">
                  <label class="py-2">{{ tdSharePlant }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdSharePlantOK }}</label>
                </td>
                <td class="text-center">
                  <label class="py-2">{{ tdSharePlantNG }}</label>
                </td>
                <td class="flex-center d-flex justify-content-center">
                  <button
                    class="vpacs-btn-table"
                    style="height: 30px; font-size: 11px;"
                    [disabled]="btnSharePlantsDisabled"
                    (click)="goToIntAppr()"
                  >
                    DETAIL
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>

<div
  class="create_footer d-flex justify-content-end align-items-center fixed-bottom"
>
  <button
    class="black-button me-3 roboto-medium"
    *ngIf="!hideControlbtnSend"
    [disabled]="btnSendDisabled"
    (click)="send()"
  >
    SEND
  </button>
  <button
    class="black-button me-3 roboto-medium"
    *ngIf="!hideControlbtnApprove"
    (click)="approve()"
  >
    APPROVE
  </button>
  <button
    class="black-button me-3 roboto-medium"
    *ngIf="!hideControlbtnReject"
    (click)="reject()"
  >
    REJECT
  </button>
  <button
    class="black-button me-3 roboto-medium"
    *ngIf="!hideControlbtnRejectBM"
    (click)="rejectBm()"
  >
    REJECT TO BENCHMARK
  </button>
  <button
    class="black-button me-3 roboto-medium"
    *ngIf="!hideControlbtnRejectSup"
    (click)="rejectSup()"
  >
    REJECT TO SUPPLIER
  </button>
  <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
</div>
