import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'history-btn-cell-renderer',
  template: `
  <button [disabled]="disabled" mat-icon-button>  
  <mat-icon class="vpacs-btn-table fs-5 d-flex justify-content-center align-items-center " 
        style="height:30px" title="history"
        (click)="btnClickedHandler($event)">history</mat-icon>
        </button>
  `,
})

export class HistoryBtnCellRender implements ICellRendererAngularComp {

  disabled = false;
    refresh(params: ICellRendererParams): boolean {
      this.agInit(params);
      return true;
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;    
    this.disabled=this.params.disabled(params.data);
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




