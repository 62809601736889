import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root',
})
export class RingiListService {
  URL = this.constantsService.MASTERCOST;
  filterdata: any;
  plantdata: any;

  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService
  ) {}

  plant(obj: any): Observable<any> {
    return this.http
      .post(this.URL + 'common/getPlantCost', obj, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  onloadRingiList(loadObj: any): Observable<any> {
    return this.http
      .post(this.URL + 'costManagement/ringiList', loadObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  estFixRingiList(estFixObj: any): Observable<any> {
    return this.http
      .patch(this.URL + 'costManagement/ringiList/estFix', estFixObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  ringiFix(ringiFixObj: any): Observable<any> {
    return this.http
      .patch(this.URL + 'costManagement/ringiList/ringiFix', ringiFixObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  calcButton(ringiFixObj: any): Observable<any> {
    return this.http
      .patch(this.URL + 'costManagement/ringiList/calc', ringiFixObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  deleteRingiList(ringinumber : any): Observable<any> {
    return this.http
      .delete(this.URL + `costManagement/ringiList/${ringinumber}`, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }
  estFixCancel(ringinumber : any): Observable<any> {
    return this.http
      .delete(this.URL + `costManagement/ringiList/estFixCancel/${ringinumber}`, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }
  ringiFixCancel(ringinumber : any): Observable<any> {
    return this.http
      .delete(this.URL + `costManagement/ringiList/ringiFixCancel/${ringinumber}`, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  onloadRingiDetails(loadObj: any): Observable<any> {
    return this.http
      .post(this.URL + 'costManagement/ringiList/details', loadObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  addButtonRingi(loadObj: any): Observable<any> {
    return this.http
      .post(this.URL + 'costManagement/ringiList/regRingi', loadObj, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }
 
  getRingi(ringiNumber: any): Observable<any> {
    return this.http.get((this.URL.concat('costmanagement/ringilist/getringi/', ringiNumber)),
      { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  downloadEstimate(estimateObject: any): Observable<any> {
    return this.http
      .post(
        this.URL + 'costManagement/ringiList/downloadEstimate',
        estimateObject,
        {
          responseType: 'json',
          headers: this.constantsService.internalHttpOptions,
        }
      )
      .pipe(catchError(this.handleError));
  }

  downloadRingi(downloadRingi: any): Observable<any> {
    return this.http
      .post(
        this.URL + 'costManagement/ringiList/downloadRingi',
        downloadRingi,
        {
          responseType: 'json',
          headers: this.constantsService.internalHttpOptions,
        }
      )
      .pipe(catchError(this.handleError));
  }

  downloadAuth(authObject: any): Observable<any> {
    return this.http
      .post(this.URL + 'costManagement/ringiList/downloadAuth', authObject, {
        responseType: 'json',
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  downloadForecast(forecastObj: any): Observable<any> {
    return this.http
      .post(
        this.URL + 'costManagement/ringiList/downloadForecast',
        forecastObj,
        {
          responseType: 'json',
          headers: this.constantsService.internalHttpOptions,
        }
      )
      .pipe(catchError(this.handleError));
  }

  fileUpload(body: any): Observable<any> {
    return this.http
      .post(`${this.URL}costManagement/ringiList/import${body.type}`, body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error');
  }
  ringListFilters:any;
 
 
  setRingListFilters(data:any)
  {
    this.ringListFilters=data;
  }

  getRingListFilters()
  {
    return this.ringListFilters;
  }

  setplant(plant: any) {
    this.plantdata = plant;
  }
  
  getplant() {
    return this.plantdata;
  }
 
  clearRingiListFilters()
  {
    this.ringListFilters='';
  }

  setfilter(filter:any){
    this.filterdata = filter;
  }

  getfilter() {
    return this.filterdata;
  }


}