import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NumberFilterModel } from 'ag-grid-community';
import { catchError, forkJoin, Observable, of, switchMap, throwError } from 'rxjs';
import { ConstantsService } from '../constant.service';

@Injectable({
  providedIn: 'root'
})
export class PpfService {

  protected plantURL = '../../../assets/json/search.json'
  protected dummyPart = '../../../assets/json/selectpartDummy.json'

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) {}

  updatePPFReason(queryData:any) : Observable<any>{
    const queryParams = new HttpParams()
      .set('hidPartNumber', queryData.partNumber)
      .set('hidPkgStartDate', queryData.pkgStartDate)
      .set('hidPlantCode',queryData.plantCode)
      .set('hidSupplierCode', queryData.supplierCode)  
    return this.http.get(this.constantsService.EXTERNAL + 'ppf/ppfOnload', {params: queryParams, headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }

  copyButtonOnload(supplierCode:string){
    return this.http.get(this.constantsService.EXTERNAL + `ppf/copy?supplierCode=${supplierCode}`,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )
  }

  copyButtonLoadDropdown(){
    return this.http.get(this.plantURL).pipe(catchError(this.handleError))
  }

  testGetCount(rowNumber:any,incrementor:number,rowRangeMin:number){
    const URL1 = this.constantsService.EXTERNAL + `ppf/packageCodeCount?rowNumber=${rowNumber}`
    return this.http.get(URL1,{headers: this.constantsService.httpOptions}).pipe(
      switchMap((data:any) =>{      
        const secondData:any = [];
        let count = data.count; 
        if(count>10000){
          incrementor=3500
        }    
        while(count >0){
          const result = this.selectPackageOnLoad(rowNumber,incrementor,rowRangeMin);
          secondData.push(result);
          rowRangeMin += incrementor
          count -= incrementor;
        }
        if(count === 0){          
          return of([])
        }else{    
          return forkJoin(secondData)
        }
      })
    )
  }

  copySelectButton(queryParams:any){
    return this.http.get(this.constantsService.EXTERNAL + `ppf/copy/select`, {params: queryParams,headers: this.constantsService.httpOptions},).pipe(catchError(this.handleError))
  }

  selectPackageOnLoad(rowNumber:NumberFilterModel,incrementor:any,rowMin:any){
    return this.http.get(this.constantsService.EXTERNAL + `ppf/packageCode?rowNumber=${rowNumber}&incrementor=${incrementor}&rowMin=${rowMin}`,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  getCost(mfg:any,pkg:any){
    return this.http.get(this.constantsService.EXTERNAL + `ppf/cost?pkgcode=${pkg}&manufact=${mfg}`,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  ppfOnLoad(ppfCode:string,viewMode:number){
    const URL = this.constantsService.EXTERNAL + `ppf?ppfCode=${ppfCode}&viewMode=${viewMode}`
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  ppfImageOnLoad(payload:any){
    const URL = this.constantsService.EXTERNAL + `ppf/ppfOnloadImage?ppfCode=${payload.ppfcode}&viewMode=${payload.viewmode}&imageNo=${payload.imageNumber}`;
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  selectFabricatorOnLoad(row:number,pkgCode:string){
    return this.http.get(this.constantsService.EXTERNAL + `ppf/manufacture?rowNumber=${row}&pkgCode=${pkgCode}`,{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  newPackageOnLoad(rowNumber:string){
    const URL = this.constantsService.EXTERNAL + `ppf/newpackage?rowNumber=${rowNumber}`;
    return this.http.get(URL, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  addNewPackage(rowNumber:string, body:any){
    const URL = this.constantsService.EXTERNAL + `ppf/newpackage?rowNumber=${rowNumber}`;
    return this.http.post(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  sendPPF(body:any, ppfNumber:string, ISUPDATEPPF:number){
    const URL = this.constantsService.EXTERNAL + `ppf?ppfCode=${ppfNumber}&viewMode=${ISUPDATEPPF}`
    return this.http.put(URL,body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }  
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> (error.message || "Server Error"))
	}
}
