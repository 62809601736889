import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { formatDate } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QuestionnaireComponent } from '../questionnaire/questionnaire.component';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-send-ppf',
  templateUrl: './send-ppf.component.html',
  styleUrls: ['./send-ppf.component.scss']
})
export class SendPpfComponent implements OnInit {
  
  
  @ViewChild(QuestionnaireComponent) appQuestionnaire!: QuestionnaireComponent;

  work: any[] = [];
  workContents: any[] = [];
  radioVal: any;
  radioValWork = 1;
  defaultColDef: any;
  gridApi: any;
  rowClassRules: any;
  defaultColDef1: any;
  gridApi1: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public sendPPFform: FormGroup = new FormGroup({});
  paramData: any;
  tdLeaderPlant: any;
  tdPlant: any;
  tdDock: any;
  tdModel: any;
  tdSupplier: any;
  tdPartNumber: any;
  tdSpecialist: any;
  tdDateSent: any;
  tdQPC: any;
  tdFullCont: any;
  btnConceptCompareDisabled = false;
  roleInfo: any = {};
  displayFlg: number[] = [];
  tdDock1 = "-";
  tdCrrDept1 = "-";
  tdDock2 = "-";
  tdCrrDept2 = "-";
  tdDock3 = "-";
  tdCrrDept3 = "-";
  tdDock4 = "-";
  tdCrrDept4 = "-";
  tdDock5 = "-";
  tdCrrDept5 = "-";
  btnSelDock1Disabled = false;
  btnSelDock2Disabled = false;
  btnSelDock3Disabled = false;
  btnSelDock4Disabled = false;
  btnSelDock5Disabled = false;
  alDock: any[] = [];
  selectDvSpec1: any[] = [];
  selectDvSpec2: any[] = [];
  selectDvSpec3: any[] = [];
  selectDvSpec4: any[] = [];
  selectDvSpec5: any[] = [];
  crrStep = 0;
  stepStatus: any[] = [];
  tdSharePlant = "-";
  tdSharePlantOK = "-";
  tdSharePlantNG = "-";
  btnSharePlantsDisabled = false;
  leaderFlg = 0;
  sharePlants: any = {};
  btnSendDisabled = false;
  hideControlBtnSend = false;
  hideControlBtnApprove = false;
  hideControlBtnReject = false;
  hideControlBtnRejectBM = false;
  hideControlBtnRejectSup = false;
  hideControlTblWork = false;
  hideControlTblReqAsFinal = false;
  userInfo: any;
  working: any;
  urlVer = "";
  tdProposalChanges: any
  tblProposalDisplay = false
  childData : any
  displayQuestionnaire=false 
  displaytrQuestionnaire=false
  trSupplierConfirmDisplay=false
  displaytrQuestionnaireComment=false
  tblRequestReasonDisplay = false
  tblTrialDisplay = false
  txtParentPart: any
  errorMessage : any
  TRACKING_PATH = '/tracking-list'
  details: any;
  step: any;
  title: any;
  spCode: any;
  err = '099  - 001 :Runtime Error : Contact a System Administrator.'
  screenID: any;
  ppfVer: any;
  isService = false
  hazmat = false
  hazmatComment: any
  hazmatVal: any
  hazmatApproveDisabled = false
  hazmatApproveChecked = false
  hazmatRejectDisabled = false
  hazmatRejectChecked = false
  hazmatCommentDisabled = false

  constructor(private readonly _router: Router, private readonly _formBuilder: FormBuilder, private readonly _route: ActivatedRoute,
    private readonly _trackingListService: TrackingListService, private readonly _state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,private readonly router:Router,private readonly constants: ConstantsService) {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.work = [
      'Supplier', 'Cont Fab', 'Dun1 Fab'
    ];

    this.intializeForm();
    this.assignUserRole();    
  }

  cancel() { 
    this._router.navigate([this.TRACKING_PATH]);
  }

  cblRequestedFun() {
    this.appQuestionnaire?.reqChangesChkList.forEach((ele:any) => {
      ele.Selected = ele.checked;
      ele.Value = ele.description;
    });
    return this.appQuestionnaire?.reqChangesChkList;
  }

  cblReasonFun() {
    this.appQuestionnaire?.reasonReqArr.forEach((ele: any) => {
      ele.Selected = ele.checked;
      ele.Value = ele.description;
    })
    return this.appQuestionnaire?.reasonReqArr;
  }

  selDock1_SelectedIndexFun(array:any) {
    let index = 0;
    index = array.findIndex((element: any) => element.specialistcode === this.sendPPFform.get('selDock1')?.value);
    return index;
  }

  approve() {
    this.errorMessage = [];
    this.spinnerService.start();
    const fileList : any[] = [];
    this.getFiles.controls.forEach((element: any) => {
      fileList.push(element.value)
    });

    const authorizePayload = {
      "txtParentPart":this.appQuestionnaire?.txtParentPart,
      "leaderFlg": this.leaderFlg,
      "crrStep": this.crrStep,
      "stepStatus": this.stepStatus,
      "rdoYes": (this.sendPPFform.get('radioVal')?.value) === 1 ? true: false,//(Internal Approvement) radio button
      "rdoNo": (this.sendPPFform.get('radioVal')?.value) === 0 ? true: false, // (Internal Approvement) radio button
      "PlantCode": this.paramData?.plantcode,
      "PartNumber": this.paramData?.partnumber,
      "SupplierCode": this.paramData?.suppliercode,
      "PkgStartDate": this.paramData.pkgstartdate,
      "ppfCode": this.paramData?.ppfcode,
      "UserName": this.userInfo?.p_specialistcode,
      "ModelCode": this.tdModel,
      "rdoSupWork": this.sendPPFform.get('work')?.value === 1 ? true: false,
      "rdoContFabWork": this.sendPPFform.get('work')?.value === 2 ? true: false,
      "rdoDun1FabWork": this.sendPPFform.get('work')?.value === 3 ? true: false,
      "chkReqAsFinal": this.sendPPFform.get('chkReqAsFinal')?.value,
      "taFinalReason": this.sendPPFform.get('taFinalReason')?.value,
      "selDock1_SelectedIndex" : this.sendPPFform.get('selDock1')?.value ? 1 : 0, // Conv Approver Select List (index)
      "selDock2_SelectedIndex" : this.sendPPFform.get('selDock2')?.value ? 1 : 0, // Conv Approver Select List (index)
      "selDock3_SelectedIndex" : this.sendPPFform.get('selDock3')?.value ? 1 : 0, // Conv Approver Select List (index)
      "selDock4_SelectedIndex" : this.sendPPFform.get('selDock4')?.value ? 1 : 0,  // Conv Approver Select List (index)
      "selDock5_SelectedIndex" : this.sendPPFform.get('selDock5')?.value ? 1 : 0, // Conv Approver Select List (index)
      "taIntComment": this.sendPPFform.get('taIntComment')?.value,
      "taExtComment": this.sendPPFform.get('taExtComment')?.value,
      "chkSelDock1": this.sendPPFform.get('chkSelDock1')?.value,
      "chkSelDock2": this.sendPPFform.get('chkSelDock2')?.value,
      "chkSelDock3": this.sendPPFform.get('chkSelDock3')?.value,
      "chkSelDock4": this.sendPPFform.get('chkSelDock4')?.value,
      "chkSelDock5": this.sendPPFform.get('chkSelDock5')?.value,
      "tdDock1": this.tdDock1,
      "tdDock2": this.tdDock2,
      "tdDock3": this.tdDock3,
      "tdDock4": this.tdDock4,
      "tdDock5": this.tdDock5,
      "rdoTrialYes": this.appQuestionnaire?.rdoTrialYesChecked ?? false, // keep it false | not applicable for SI SE 
      "rdoTrialNo": this.appQuestionnaire?.rdoTrialNoChecked ?? false,
      "taTriFinalReason": this.appQuestionnaire?.rdoAsFinalChecked ? this.appQuestionnaire?.taTriFinalReason : "",
      "taRequestChanges": this.appQuestionnaire?.taRequestChanges ?? '', // keep it empty |  not applicable for SI SE
      "taReasonRequested": this.appQuestionnaire?.taReasonRequested ?? '', // keep it empty |  not applicable for SI SE
      "chkSelDock3_Disabled": this.appQuestionnaire?.taReasonRequestedDisabled ?? '', 
      "chkSelDock4_Checked": this.sendPPFform.get('chkSelDock4')?.value,
      "selDock1": this.sendPPFform.get('selDock1')?.value,
      "selDock2": this.sendPPFform.get('selDock2')?.value,
      "selDock3": this.sendPPFform.get('selDock3')?.value,
      "selDock4": this.sendPPFform.get('selDock4')?.value,
      "selDock5": this.sendPPFform.get('selDock5')?.value,
      "chkSelDock5_Disabled": this.sendPPFform.get('chkSelDock5')?.value,
      "rdoAsFinal": this.appQuestionnaire?.rdoAsFinalChecked ?? false,
      "cblRequested": this.cblRequestedFun(),
      "cblReason": this.cblReasonFun(),
      "FileList": fileList
    }
    this._trackingListService.departmentApprovalSI(authorizePayload).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res.status === 'SUCCESS') {
          this._router.navigate([this.TRACKING_PATH]);
        }
        else{
          this.errorMessage = (res?.message)?(res?.message):[this.err];
          window.scrollTo(0, 0);
          if(this.errorMessage[0] === "Approved Successfully. Unable to send Email to supplier. Please Contact system Administrator."){
            setTimeout(() => {
              this._router.navigate([this.TRACKING_PATH]);
            }, 5000);
          }
        }
      }, error: (error: any) => {
        this.spinnerService.stop();
        this.errorMessage = (error.error?.error?.message) ?(error.error?.error?.message): [this.err]
        window.scrollTo(0, 0);
        if(this.errorMessage[0] === "Approved Successfully. Unable to send Email to supplier. Please Contact system Administrator."){
          setTimeout(() => {
            this._router.navigate([this.TRACKING_PATH]);
          }, 5000);
        }
      }
    })
  }

  send(){
    this.spinnerService.start();
    this.errorMessage = [];
    const stepStatus = Object.values(this.stepStatus[0]).map(Number);

    const obj = {
      PlantCode : this.paramData?.plantcode,
      PartNumber : this.paramData?.partnumber,
      SupplierCode : this.paramData?.suppliercode,
      PkgStartDate : this.paramData?.pkgstartdate,
      leaderFlg : this.nullInt(this.leaderFlg),
      crrStep : this.crrStep,
      stepStatus : stepStatus,
      rdoYes : this.sendPPFform.controls['radioVal'].value,
      approval : true
    }
    this._trackingListService.siBtnSend(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this._router.navigateByUrl(this.TRACKING_PATH);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        window.scrollTo(0, 0);
      },
    })
  }

  rejectBm(){
    this.errorMessage = [];
    this.spinnerService.start();
    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep
    }
    this._trackingListService.siBtnRejectBm(obj).subscribe({
      next: (res: any) => {
        if (res?.result?.result) {
          this.router.navigateByUrl(this.TRACKING_PATH);
        }
        else{
          this.errorMessage = [res?.result?.message];
          window.scrollTo(0, 0);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        window.scrollTo(0, 0);
      },
    })
  }

  rejectSup(){
    this.errorMessage = [];
    this.spinnerService.start();
    const fileList : any[] = [];
    this.getFiles.controls.forEach((element: any) => {
      fileList.push(element.value)
    });
    const obj = {
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.sendPPFform.get('work')?.value === 1 ? true: false,
      rdoContFabWork: this.sendPPFform.get('work')?.value === 2 ? true: false,
      rdoDun1FabWork: this.sendPPFform.get('work')?.value === 3 ? true: false,
      taExtComment:this.sendPPFform.get('taExtComment')?.value,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      FileList: fileList,
      taRequestChanges: this.appQuestionnaire?.taRequestChanges ?? '',
      taReasonRequested: this.appQuestionnaire?.taReasonRequested ?? '',
      rdoAsFinal: this.appQuestionnaire?.rdoAsFinalChecked ?? false,
      rdoTrialNo: this.appQuestionnaire?.rdoTrialNoChecked ?? false,
      cblRequested : this.cblRequestedFun(), // page load will assign  | not applicable for SI SE
      cblReason :  this.cblReasonFun(),
      PPFCode: this.paramData?.ppfcode
    }
    this._trackingListService.siBtnRejectSup(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl(this.TRACKING_PATH);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        window.scrollTo(0, 0);
      },
    })
  }
  

  reject(){
    this.errorMessage = [];
    this.spinnerService.start();
    const fileList : any[] = [];
    this.getFiles.controls.forEach((element: any) => {
      fileList.push(element.value)
    });
    const obj = {
      leaderFlg: this.leaderFlg,
      PkgStartDate: this.paramData?.pkgstartdate,
      PartNumber: this.paramData?.partnumber,
      PlantCode: this.paramData?.plantcode,
      SupplierCode: this.paramData?.suppliercode,
      ModelCode: this.paramData?.modelcode,
      crrStep: this.crrStep,
      rdoSupWork: this.sendPPFform.get('work')?.value === 1 ? true: false,
      rdoContFabWork: this.sendPPFform.get('work')?.value === 2 ? true: false,
      rdoDun1FabWork: this.sendPPFform.get('work')?.value === 3 ? true: false,
      taExtComment:this.sendPPFform.get('taExtComment')?.value,
      SpecialistCode: this.spCode,
      AttachmentFile: '',
      taRequestChanges: this.appQuestionnaire?.taRequestChanges ?? '',
      taReasonRequested: this.appQuestionnaire?.taReasonRequested ?? '',
      cblRequested : this.cblRequestedFun(), // page load will assign  | not applicable for SI SE
      cblReason :  this.cblReasonFun(),
      FileList: fileList,
      PPFCode: this.paramData?.ppfcode
    }
    this._trackingListService.siBtnReject(obj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl(this.TRACKING_PATH);
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.errorMessage = _err.error?.error?.message ?? [this.err]
        window.scrollTo(0, 0);
      },
    })
  }

  PPF() {
    sessionStorage.setItem('ppfcloseFromTracking','0')  
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
    // making PPF as editable as per kaizen request

    if (plantCode === 'SERVICE') {
      sessionStorage.setItem('partnumber',this.paramData?.partnumber)
      this.router.navigate(['/tracking-list/send-ppf/serviceparts-pdf', this.paramData.ppfcode, this.urlVer, 1, 1])
    }else if(plantCode === 'ASSY'){
      sessionStorage.setItem('partnumber', this.paramData?.partnumber)
      this.router.navigate(['/tracking-list/send-ppf/accessoryParts-pdf', this.paramData.ppfcode, this.urlVer, 1, 1])
    }
    else{
      window.open(`/tracking-list/send-ppf/ppf/${this.paramData.ppfcode}/${this.urlVer}/1/1`, '_blank');
    }

    sessionStorage.setItem('ppfcloseFromTracking','1')  
  }

  conceptCompare() { 
    const params = {
      ppfcode1 : this.paramData?.ppfcode,
      ppfver1: this.urlVer,
      ppfcode2: this.paramData?.ppfcode,
      ppfver2: -1
    }
    sessionStorage.setItem('conceptCompareDetails', JSON.stringify(params));
    this.router.navigateByUrl('/tracking-list/send-ppf/concept-compare');
  }

  goToDeptAppr() {
    const params = {
      leaderPlant : this.paramData?.leadername,
      plant: this.paramData?.plantcode,
      plantName: this.paramData?.plantname,
      dock: this.tdDock1,
      modelCode: this.paramData?.modelcode,
      supplier: this.paramData?.suppliercode,
      supplierName: this.paramData?.suppliername,
      specialist: this.paramData?.specialist,
      part: this.paramData?.partnumber,
      psd: formatDate(this.paramData?.pkgstartdate, 'yyyy-MM-dd', 'en-US' , 'UTC'),
      dateSent: this.tdDateSent !== '-' ? this.tdDateSent : 'null',
      mode : 1,
      step: 2,
      currentIntNo: this.details?.getIntApprove?.result[0]?.currentintno ?? 0,
      currentIntVer: this.details?.getIntApprove?.result[0]?.currentintver ?? 0,
    }
    sessionStorage.setItem('deptApproveDetails', JSON.stringify(params));
    this.router.navigateByUrl('/tracking-list/send-ppf/dept-approve');
  }

  goToHistory(){
    if(this.roleInfo?.p_trackingsave){
      sessionStorage.setItem('trackingParams', JSON.stringify(this.paramData))
      this.router.navigate(['/tracking-list/tracking-history']);
    }
    else{
      this.errorMessage = ['Authority Error']
      window.scrollTo(0, 0);
    }
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady1(params: any) {
    this.gridApi1 = params.api
    this.gridApi1.sizeColumnsToFit();
  }

  onFileSelected(event: any, index: number) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.getFiles.controls[index].setValue({
          FileName: file.name,
          FileData: ((reader.result) as string)?.split(',')[1]
        });
      };
      reader.onerror = () => { alert('Error while uploading') }

    }
  }

  private intializeForm() {

    this.sendPPFform = this._formBuilder.group({
      taIntComment: [''],
      chkReqAsFinal: [false],
      taFinalReason: [''],
      taExtComment: [''],
      work: [],
      radioVal: [],
      files: this._formBuilder.array([this.getFilesControl()]),
      chkSelDock1: [false],
      chkSelDock2: [false],
      chkSelDock3: [false],
      chkSelDock4: [false],
      chkSelDock5: [false],
      selDock1: [''],
      selDock2: [''],
      selDock3: [''],
      selDock4: [''],
      selDock5: ['']
    });

  }

  private getFilesControl(): FormGroup {
    return this._formBuilder.group({ FileName: [''], FileData: [''] })
  }

  public get getFiles() {
    return (this.sendPPFform.get('files') as FormArray);
  }

  public getfileName(index: number): string {
    let fileName = this.getFiles.controls[index].get('FileName')?.value;

    if (fileName) {
      if (fileName.length > 14) {
        fileName = `${fileName.slice(0, 8)}...${fileName.slice(-6)}`;
        return fileName;
      } else {
        return fileName;
      }
    } else {
      return "No file uploaded yet.";
    }
  }

  public addFiles() {
    this.getFiles.push(this.getFilesControl());
  }

  public deleteFile(index: number) {
    if (this.getFiles.controls.length > 1) {
      this.getFiles.controls.splice(index, 1);
    }
    else if (this.getFiles.controls.length === 1) {
      this.getFiles.controls[index].setValue({
        FileName: '',
        FileData: ''
      });
    }
  }

  loadPageLoadData() {

    const reqParam = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      PPFCode: this.paramData?.ppfcode,
      PPFver: this.paramData?.ppfver,
      step: ""
    }

    this._trackingListService.getSEOnload(reqParam).subscribe({
      next: (data: any) => {
        if (! this.roleInfo.p_trackingsave){
          return;
        } 
        this.details = data;
        const plantCode = this.constants.getTypeFromPlantCode(this.paramData.plantcode)
        if(plantCode === 'SERVICE' || plantCode === 'ASSY')
        {
          this.isService=true;
          this.radioVal=0;
        }
        this.loadParameter(data);   
        this.createHtml_tblInfo(data);
        this.createHtml_dispControl(data);  
        this.spinnerService.stop();      
      },
      error: () => {
        this.spinnerService.stop();
      }
    });

  }

  private loadParameter(data: any) {
    const newData = {
      ...data,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      PlantCode: this.paramData?.plantcode,

    };
    
    this.childData = newData;
    this.crrStep = data?.getCurrentStepApprove
    const stepStatusObj = data?.stepStatus[0];

    for (const key in stepStatusObj) {
      if (stepStatusObj.hasOwnProperty(key)) {
        this.stepStatus.push(+(stepStatusObj[key]));
      }
    }
    this.leaderFlg = data?.leaderFlag 
    this.displayFlg = data?.displayFlag;
    this.urlVer = data?.url_ver;
    this.ppfVer = '';
    if(this.step === '2'){
      this.title = 'Send PPF to Supplier';
      this.displayFlg = [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 0];
      this.screenID = 18;
    }
    else if(this.step === '4'){
      this.title = 'Packaging Proposal';
      this.displayFlg = [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0];
      this.screenID = 19;
    }
    else if(this.step === '7'){
      this.title = 'Packaging Trial';
      this.displayFlg = [1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1];
      this.screenID = 20;
    }
    else if(this.step === '9'){
      this.title = 'Final Packaging Proposal';
      this.displayFlg = [1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1];
      this.screenID = 21;
    }

    // follower
    if (this.leaderFlg === 1)
    {
        this.displayFlg[16] = 1;
        this.displayFlg[17] = 0;
        this.displayFlg[18] = 1;
        this.displayFlg[19] = 0;
        this.displayFlg[20] = 0;
        this.displayFlg[21] = 0;
        this.ppfVer = ''
    }
  }


  private createHtml_tblInfo(data: any): boolean {
    try {
      const dvTblInfo = data?.dv_tblInfo;

      if (this.checkNull(dvTblInfo)) {
        return false;
      }
      if (dvTblInfo.length < 1) {
        return false;
      }

      this.tdLeaderPlant = this.nullStr(dvTblInfo[0]["leaderplant"]);
      this.tdPlant = this.nullStr(dvTblInfo[0]["plantname"]);
      this.tdDock = this.nullStr(dvTblInfo[0]["dockcode"]);
      this.tdModel = this.nullStr(dvTblInfo[0]["modelcode"]);
      this.tdSupplier = this.nullStr(dvTblInfo[0]["suppliername"]);
      this.tdPartNumber = this.nullStr(dvTblInfo[0]["partnumber"]);
      this.tdSpecialist = this.nullStr(dvTblInfo[0]["specialist"]);
      this.tdQPC = (data?.dv_tblInfo[0]["qpc"] === "") ? "-" : data?.dv_tblInfo[0]["qpc"];

      const fullContStore = (this.details?.tdFullCont !== null && this.details?.tdFullCont !== ''
        && this.details?.tdFullCont !== '-') ? this.changeUnitFormat(
          this.changeSize(this.details?.tdFullCont, 1),
          1,
        ) : ""
      this.tdFullCont = fullContStore === "" ? "-" : fullContStore;

      this.working = this.nullInt(dvTblInfo[0]["working"]);

      if (!this.checkNull(data?.dv_date) && data?.dv_date.length > 0) {
        this.tdDateSent = "-";
        if (this.nullStr(data?.dv_date[0]["datesent"]) !== "") {
          this.tdDateSent = formatDate(data?.dv_date[0]["datesent"], 'MM/dd/yyy', 'en-US', 'UTC')
        }
      }
      else {
        this.tdDateSent = "-";
      }

      return true;

    }
    catch (error) {
      return false;
    }
  }


  private createHtml_dispControl(data: any) {
    // working
    if (this.displayFlg[2] === 1) {
      // display
      this.createHtml_tblWork(data);
    }
    else {
      this.hideControlTblWork = true;
    }

    // concept compare
    if (this.crrStep === 2) {
      this.btnConceptCompareDisabled = true;
    }

    if (!this.roleInfo?.p_costview) {
      this.btnConceptCompareDisabled = true;
    }

    // proposal with changes
    if (this.displayFlg[5] === 1) {
      this.tblProposalDisplay = true
      this.createHtml_ProposalWithChanges(data)
    } else {
      this.tblProposalDisplay = false
    }

    // supplier questionnaire
    if (this.displayFlg[6] === 1 && this.displayFlg[7] === 1) {
      this.displayQuestionnaire = true
      this.displaytrQuestionnaireComment = true
      this.displaytrQuestionnaire = true
     
    }
    else if (this.displayFlg[6] === 0 && this.displayFlg[7] === 1) {

      this.displaytrQuestionnaire = false
      this.displayQuestionnaire = true
      this.displaytrQuestionnaireComment = true

    }
    else if (this.displayFlg[6] === 1 && this.displayFlg[7] === 0) {

      this.displayQuestionnaire = true
      this.displaytrQuestionnaire = true
      this.displaytrQuestionnaireComment = false
    }
    else if (this.displayFlg[6] === 0 && this.displayFlg[7] === 0) {

      this.displayQuestionnaire = false
    }

    // requested changes/reason requested
    if (this.displayFlg[8] === 1 && this.displayFlg[9] === 1) {
      this.tblRequestReasonDisplay = true
    }
    else if (this.displayFlg[8] === 0 && this.displayFlg[9] === 0) {
      this.tblRequestReasonDisplay = false
    }

    // trial
    if (this.displayFlg[10] === 1) {
      this.tblTrialDisplay = true;
    }
    else {
      this.tblTrialDisplay = false;
    }

    // request as final
    if (this.displayFlg[11] === 1) {
      // display
      this.createHtml_RequestAsFinal(data);
    }
    else {
      this.hideControlTblReqAsFinal = true;;
    }


    this.createHtml_intappDock(data);
    this.createHtml_sharePlants(data);
    this.createHtml_commentIntSup(data);

    // send button
    if (this.displayFlg[16] === 1) {
      // display

      if (this.tdSharePlant === "-" && this.leaderFlg !== 1) {
        this.btnSendDisabled = true;
      }
    }
    else {
      this.hideControlBtnSend = true;
    }

    // approve button
    if (this.displayFlg[17] === 1) {
      // display
    }
    else {
      this.hideControlBtnApprove = true;
    }

    // reject button
    if (this.displayFlg[18] === 1) {
      // display
    }
    else {
      this.hideControlBtnReject = true;
    }

    // reject to bm/supplier button
    if (this.displayFlg[19] === 1 && this.displayFlg[20] === 1) {
      // display
    }
    else if (this.displayFlg[19] === 0 && this.displayFlg[20] === 0) {
      this.hideControlBtnRejectBM = true;
      this.hideControlBtnRejectSup = true;
    }

    // questionnaire of testing
    if (this.displayFlg[22] === 1) {
      this.displayQuestionnaire = true
      this.trSupplierConfirmDisplay = true;
    }
    else {
      this.trSupplierConfirmDisplay = false;
    }

    //Hazmat Approval
    const hazmatCheck = data.dv_tblInfo[0].hazmat;
    const hazmatStatus = data.dv_tblInfo[0].hzapprovestatus;
    if (hazmatCheck === 'N' || hazmatCheck === null || hazmatCheck === '') {
      this.hazmat = false;
    }
    else if(this.paramData?.plantcode === '50SER'|| this.paramData?.plantcode === '51ACC'){
      this.hazmat = true;
      this.hazmatApproveDisabled = true;
      this.hazmatRejectDisabled = true;
      this.hazmatCommentDisabled = true;
      if (hazmatStatus === 'A' || hazmatStatus === 'Approved' || hazmatStatus === 'APPROVED') {
        this.hazmatVal = 1;
        this.hazmatApproveChecked = true;
        this.hazmatComment = data.dv_tblInfo[0].hzapprovercomment;
      }
      else {
        this.hazmatVal = 0;
        this.hazmatRejectChecked = true;
        this.hazmatComment = data.dv_tblInfo[0].hzapprovercomment;
      }
    }
    else{
      this.hazmat = false;
    }
  }

  private createHtml_tblWork(data: any) {

    const working = data?.nowWorking ? Number(data?.nowWorking) : 0;

    switch (working) {
      case 2:
        this.sendPPFform.patchValue({ work: 2 });
        break;
      case 6:
        this.sendPPFform.patchValue({ work: 1 });
        break;
      case 8:
        this.sendPPFform.patchValue({ work: 3 });
        break;
      default:
        break;
    }

    if (this.stepStatus[this.crrStep - 1] !== 1 || this.leaderFlg === 1) {
      this.sendPPFform.get('work')?.disable();
    } else {
      this.sendPPFform.get('work')?.enable();
    }
      
  }


  private createHtml_intappDock(data: any) {
    try {
      // start assignment
      // set value
      this.tdDock1 = "-";
      this.tdCrrDept1 = "-";

      this.tdDock2 = "-";
      this.tdCrrDept2 = "-";

      this.tdDock3 = "-";
      this.tdCrrDept3 = "-";

      this.tdDock4 = "-";
      this.tdCrrDept4 = "-";

      this.tdDock5 = "-";
      this.tdCrrDept5 = "-";

      const dvDock = data?.dv_dock;
      const dvSpec = data?.dv_spec.result;
      this.alDock = data?.tdDock
      const defaultApprover = data?.defaultApprover;
      // end assignment

      const strDocks = data?.getIntApproveDocks;
      if (strDocks === "") {

        this.sendPPFform.patchValue({ radioVal: 0 });
        this.sendPPFform.get('radioVal')?.disable();

        this.sendPPFform.get('chkSelDock1')?.disable();
        this.sendPPFform.get('chkSelDock2')?.disable();
        this.sendPPFform.get('chkSelDock3')?.disable();
        this.sendPPFform.get('chkSelDock4')?.disable();
        this.sendPPFform.get('chkSelDock5')?.disable();

        this.sendPPFform.get('selDock1')?.disable();
        this.sendPPFform.get('selDock2')?.disable();
        this.sendPPFform.get('selDock3')?.disable();
        this.sendPPFform.get('selDock4')?.disable();
        this.sendPPFform.get('selDock5')?.disable();

        this.btnSelDock1Disabled = true;
        this.btnSelDock2Disabled = true;
        this.btnSelDock3Disabled = true;
        this.btnSelDock4Disabled = true;
        this.btnSelDock5Disabled = true;
        this.sendPPFform.get('taIntComment')?.disable();

        return;
      }
      else if (strDocks === null) {
        return;
      }
      let dockStatus = 0;
      if (this.alDock && this.alDock.length >= 1) {
        this.tdDock1 = this.nullStr(this.alDock[0]);
        for (const iterator of dvSpec) {
          if (this.nullStr(iterator["divcode"]) === "CV") {
            this.selectDvSpec1.push({ specialist: iterator["specialist"], specialistcode: iterator["specialistcode"] })
          }  
        }

        if (!this.checkNull(dvSpec) && dvSpec.length >= 1) {
          if (!this.checkNull(dvDock) && dvDock.length >= 1) {
            if (this.crrStep === 2) {
              dockStatus = this.nullInt(dvDock[0]["int1status"]);
            }
            else if (this.crrStep === 4) {
              dockStatus = this.nullInt(dvDock[0]["int2status"]);
            }
            else if (this.crrStep === 7) {
              dockStatus = this.nullInt(dvDock[0]["int3status"]);
            }
            else if (this.crrStep === 9) {
              dockStatus = this.nullInt(dvDock[0]["int4status"]);
            }

            if (this.nullInt(dockStatus) === 5) {
              this.sendPPFform.patchValue({ chkSelDock1: 2 });
              this.tdCrrDept1 = "Skip";
            }
            else {
              if (this.nullInt(dockStatus) === 0) {
                this.tdCrrDept1 = "-";
              }
              else if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept1 = "Conv";
              }
              else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept1 = "Mfg";
              }
              else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept1 = "Qc";
              }
              else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept1 = "Complete";
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec1) {
                if (iterator.specialistcode === this.nullStr(dvDock[0]["cvcode"])) {
                  this.sendPPFform.patchValue({ selDock1: iterator.specialistcode });
                  break;
                }
              }
            }
            else {
              if (defaultApprover !== "") {
                for (const iterator of this.selectDvSpec1) {
                  if (iterator.specialistcode === defaultApprover) {
                    this.sendPPFform.patchValue({ selDock1: iterator.specialistcode });
                    break;
                  }
                }
              }
            }
          }

          if (this.selectDvSpec1.length < 1) {
            this.sendPPFform.patchValue({ chkSelDock1: false });
            this.sendPPFform.get('chkSelDock1')?.disable();
            this.sendPPFform.get('selDock1')?.disable();
            this.btnSelDock1Disabled = true;
          }
          else{
            this.sendPPFform.patchValue({ chkSelDock1: true });
          }
        }
        else {
          this.sendPPFform.patchValue({ chkSelDock1: false });
          this.sendPPFform.get('chkSelDock1')?.disable();
          this.sendPPFform.get('selDock1')?.disable();
          this.btnSelDock1Disabled = true;
        }
      }
      else {
        this.sendPPFform.patchValue({ chkSelDock1: false });
        this.sendPPFform.get('chkSelDock1')?.disable();
        this.sendPPFform.get('selDock1')?.disable();
        this.btnSelDock1Disabled = true;
      }

      // dock - row2
      if (this.alDock && this.alDock.length >= 2) {
        // dock name
        this.tdDock2 = this.nullStr(this.alDock[1]);
        if (!this.checkNull(dvSpec) && dvSpec.length >= 1) {
          for (const iterator of dvSpec) {
            if (this.nullStr(iterator["divcode"]) === "CV") {
              this.selectDvSpec2.push({ specialist: iterator["specialist"], specialistcode: iterator["specialistcode"] })
            }
          }

          // status
          if (!this.checkNull(dvDock) && dvDock.length >= 1) {

            if (this.crrStep === 2) {
              dockStatus = this.nullInt(dvDock[0]["int1status"]);
            }
            else if (this.crrStep === 4) {
              dockStatus = this.nullInt(dvDock[0]["int2status"]);
            }
            else if (this.crrStep === 7) {
              dockStatus = this.nullInt(dvDock[0]["int3status"]);
            }
            else if (this.crrStep === 9) {
              dockStatus = this.nullInt(dvDock[0]["int4status"]);
            }

            if (this.nullInt(dockStatus) === 5) {
              this.sendPPFform.patchValue({ chkSelDock2: false });
              this.tdCrrDept2 = "Skip";
            }
            else {

              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept2 = "Conv";
              }
              else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept2 = "Mfg";
              }
              else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept2 = "Qc";
              }
              else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept2 = "Complete";
              }
              else {
                this.tdCrrDept2 = "-";
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec2) {
                if (iterator.specialistcode === this.nullStr(dvDock[0]["cvcode"])) {
                  this.sendPPFform.patchValue({ selDock2: iterator.specialistcode });
                  break;
                }
              }
            }
            else {
              if (defaultApprover !== "") {
                for (const iterator of this.selectDvSpec2) {
                  if (iterator.specialistcode === defaultApprover) {
                    this.sendPPFform.patchValue({ selDock2: iterator.specialistcode });
                    break;
                  }
                }
              }
            }
          }

          if (this.selectDvSpec2.length < 1) {
            this.sendPPFform.patchValue({ chkSelDock2: false });
            this.sendPPFform.get('chkSelDock2')?.disable();
            this.sendPPFform.get('selDock2')?.disable();
            this.btnSelDock2Disabled = true;
          }
          else{
            this.sendPPFform.patchValue({ chkSelDock2: true });
          }
        }
        else {
          this.sendPPFform.patchValue({ chkSelDock2: false });
          this.sendPPFform.get('chkSelDock2')?.disable();
          this.sendPPFform.get('selDock2')?.disable();
          this.btnSelDock2Disabled = true;
        }
      }
      else {
        this.sendPPFform.patchValue({ chkSelDock2: false });
        this.sendPPFform.get('chkSelDock2')?.disable();
        this.sendPPFform.get('selDock2')?.disable();
        this.btnSelDock2Disabled = true;
      }

      // dock - row3
      if (this.alDock && this.alDock.length >= 3) {
        // dock name
        this.tdDock3 = this.nullStr(this.alDock[2]);

        // dataview

        if (!this.checkNull(dvSpec) && dvSpec.length >= 1) {
          // conv approver

          for (const iterator of dvSpec) {
            if (this.nullStr(iterator["divcode"]) === "CV") {
              this.selectDvSpec3.push({ specialist: iterator["specialist"], specialistcode: iterator["specialistcode"] })
            } 
          }

          // status
          if (!this.checkNull(dvSpec) && dvDock.length >= 1) {

            if (this.crrStep === 2) {
              dockStatus = this.nullInt(dvDock[0]["int1status"]);
            }
            else if (this.crrStep === 4) {
              dockStatus = this.nullInt(dvDock[0]["int2status"]);
            }
            else if (this.crrStep === 7) {
              dockStatus = this.nullInt(dvDock[0]["int3status"]);
            }
            else if (this.crrStep === 9) {
              dockStatus = this.nullInt(dvDock[0]["int4status"]);
            }

            if (this.nullInt(dockStatus) === 5) {
              this.sendPPFform.patchValue({ chkSelDock3: false });
              this.tdCrrDept3 = "Skip";
            }
            else {

              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept3 = "Conv";
              }
              else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept3 = "Mfg";
              }
              else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept3 = "Qc";
              }
              else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept3 = "Complete";
              }
              else {
                this.tdCrrDept3 = "-";
              }
            }

            if (this.nullInt(dockStatus) !== 0) {

              for (const iterator of this.selectDvSpec3) {
                if (iterator.specialistcode === this.nullStr(dvDock[0]["cvcode"])) {
                  this.sendPPFform.patchValue({ selDock3: iterator.specialistcode });
                  break;
                }
              }

            }
            else {
              if (defaultApprover !== "") {
                for (const iterator of this.selectDvSpec3) {
                  if (iterator.specialistcode === defaultApprover) {
                    this.sendPPFform.patchValue({ selDock3: iterator.specialistcode });
                    break;
                  }
                }
              }
            }
          }

          if (this.selectDvSpec3.length < 1) {
            this.sendPPFform.patchValue({ chkSelDock3: false });
            this.sendPPFform.get('chkSelDock3')?.disable();
            this.sendPPFform.get('selDock3')?.disable();
            this.btnSelDock3Disabled = true;
          }
          else{
            this.sendPPFform.patchValue({ chkSelDock3: true });
          }
        }
        else {
          this.sendPPFform.patchValue({ chkSelDock3: false });
          this.sendPPFform.get('chkSelDock3')?.disable();
          this.sendPPFform.get('selDock3')?.disable();
          this.btnSelDock3Disabled = true;
        }
      }
      else {
        this.sendPPFform.patchValue({ chkSelDock3: false });
        this.sendPPFform.get('chkSelDock3')?.disable();
        this.sendPPFform.get('selDock3')?.disable();
        this.btnSelDock3Disabled = true;
      }


      // dock - row4
      if (this.alDock && this.alDock.length >= 4) {
        // dock name
        this.tdDock4 = this.nullStr(this.alDock[3]);

        // dataview

        if (!this.checkNull(dvSpec) && dvSpec.length >= 1) {
          // conv approver

          for (const iterator of dvSpec) {
            if (this.nullStr(iterator["divcode"]) === "CV") {
              this.selectDvSpec4.push({ specialist: iterator["specialist"], specialistcode: iterator["specialistcode"] })
            }
              
          }

          // status
          if (!this.checkNull(dvDock) && dvDock.length >= 1) {

            if (this.crrStep === 2) {
              dockStatus = this.nullInt(dvDock[0]["int1status"]);
            }
            else if (this.crrStep === 4) {
              dockStatus = this.nullInt(dvDock[0]["int2status"]);
            }
            else if (this.crrStep === 7) {
              dockStatus = this.nullInt(dvDock[0]["int3status"]);
            }
            else if (this.crrStep === 9) {
              dockStatus = this.nullInt(dvDock[0]["int4status"]);
            }

            if (this.nullInt(dockStatus) === 5) {
              this.sendPPFform.patchValue({ chkSelDock4: false });
              this.tdCrrDept4 = "Skip";
            }
            else {

              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept4 = "Conv";
              }
              else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept4 = "Mfg";
              }
              else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept4 = "Qc";
              }
              else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept4 = "Complete";
              }
              else {
                this.tdCrrDept4 = "-";
              }
            }
            if (this.nullInt(dockStatus) !== 0) {

              for (const iterator of this.selectDvSpec4) {
                if (iterator.specialistcode === this.nullStr(dvDock[0]["cvcode"])) {
                  this.sendPPFform.patchValue({ selDock4: iterator.specialistcode });
                  break;
                }
              }
            }
            else {

              if (defaultApprover !== "") {
                for (const iterator of this.selectDvSpec4) {
                  if (iterator.specialistcode === defaultApprover) {
                    this.sendPPFform.patchValue({ selDock4: iterator.specialistcode });
                    break;
                  }
                }
              }
            }

          }

          if (this.selectDvSpec4.length < 1) {
            this.sendPPFform.patchValue({ chkSelDock4: false });
            this.sendPPFform.get('chkSelDock4')?.disable();
            this.sendPPFform.get('selDock4')?.disable();
            this.btnSelDock4Disabled = true;
          }
          else{
            this.sendPPFform.patchValue({ chkSelDock4: true });
          }
        }
        else {
          this.sendPPFform.patchValue({ chkSelDock4: false });
          this.sendPPFform.get('chkSelDock4')?.disable();
          this.sendPPFform.get('selDock4')?.disable();
          this.btnSelDock4Disabled = true;
        }
      }
      else {
        this.sendPPFform.patchValue({ chkSelDock4: false });
        this.sendPPFform.get('chkSelDock4')?.disable();
        this.sendPPFform.get('selDock4')?.disable();
        this.btnSelDock4Disabled = true;
      }

      // dock - row5
      if (this.alDock && this.alDock.length >= 5) {
        // dock name
        this.tdDock5 = this.nullStr(this.alDock[4]);

        // dataview

        if (!this.checkNull(dvSpec) && dvSpec.length >= 1) {
          // conv approver
          for (const iterator of dvSpec) {
            if (this.nullStr(iterator["divcode"]) === "CV") {
              this.selectDvSpec5.push({ specialist: iterator["specialist"], specialistcode: iterator["specialistcode"] })
            }  
          }

          // status
          if (!this.checkNull(dvDock) && dvDock.length >= 1) {

            if (this.crrStep === 2) {
              dockStatus = this.nullInt(dvDock[0]["int1status"]);
            }
            else if (this.crrStep === 4) {
              dockStatus = this.nullInt(dvDock[0]["int2status"]);
            }
            else if (this.crrStep === 7) {
              dockStatus = this.nullInt(dvDock[0]["int3status"]);
            }
            else if (this.crrStep === 9) {
              dockStatus = this.nullInt(dvDock[0]["int4status"]);
            }

            if (this.nullInt(dockStatus) === 5) {
              this.sendPPFform.patchValue({ chkSelDock5: false });
              this.tdCrrDept5 = "Skip";
            }
            else {
              if (this.nullInt(dockStatus) === 1) {
                this.tdCrrDept5 = "Conv";
              }
              else if (this.nullInt(dockStatus) === 2) {
                this.tdCrrDept5 = "Mfg";
              }
              else if (this.nullInt(dockStatus) === 3) {
                this.tdCrrDept5 = "Qc";
              }
              else if (this.nullInt(dockStatus) === 4) {
                this.tdCrrDept5 = "Complete";
              }
              else {
                this.tdCrrDept5 = "-";
              }
            }
            if (this.nullInt(dockStatus) !== 0) {
              for (const iterator of this.selectDvSpec5) {
                if (iterator.specialistcode === this.nullStr(dvDock[0]["cvcode"])) {
                  this.sendPPFform.patchValue({ selDock5: iterator.specialistcode });
                  break;
                }
              }
            }
            else {
              if (defaultApprover !== "") {
                for (const iterator of this.selectDvSpec5) {
                  if (iterator.specialistcode === defaultApprover) {
                    this.sendPPFform.patchValue({ selDock5: iterator.specialistcode });
                    break;
                  }
                }
              }

            }
          }

          if (this.selectDvSpec5.length < 1) {
            this.sendPPFform.patchValue({ chkSelDock5: false });
            this.sendPPFform.get('chkSelDock5')?.disable();
            this.sendPPFform.get('selDock5')?.disable();
            this.btnSelDock5Disabled = true;
          }
          else{
            this.sendPPFform.patchValue({ chkSelDock5: true });
          }
        }
        else {
          this.sendPPFform.patchValue({ chkSelDock5: false });
          this.sendPPFform.get('chkSelDock5')?.disable();
          this.sendPPFform.get('selDock5')?.disable();
          this.btnSelDock5Disabled = true;
        }
      }
      else {
        this.sendPPFform.patchValue({ chkSelDock5: false });
        this.sendPPFform.get('chkSelDock5')?.disable();
        this.sendPPFform.get('selDock5')?.disable();
        this.btnSelDock5Disabled = true;
      }

      // set disable(send)
      if (this.stepStatus[this.crrStep - 1] === 13) {

        this.sendPPFform.get('radioVal')?.disable();

        this.sendPPFform.get('chkSelDock1')?.disable();
        this.sendPPFform.get('chkSelDock2')?.disable();
        this.sendPPFform.get('chkSelDock3')?.disable();
        this.sendPPFform.get('chkSelDock4')?.disable();
        this.sendPPFform.get('chkSelDock5')?.disable();

        this.sendPPFform.get('selDock1')?.disable();
        this.sendPPFform.get('selDock2')?.disable();
        this.sendPPFform.get('selDock3')?.disable();
        this.sendPPFform.get('selDock4')?.disable();
        this.sendPPFform.get('selDock5')?.disable();

        
        // internal approvement y/n
        if ((this.tdCrrDept1 === "-" || this.tdCrrDept1 === "Skip") &&
          (this.tdCrrDept2 === "-" || this.tdCrrDept1 === "Skip") &&
          (this.tdCrrDept3 === "-" || this.tdCrrDept1 === "Skip") &&
          (this.tdCrrDept4 === "-" || this.tdCrrDept1 === "Skip") &&
          (this.tdCrrDept5 === "-" || this.tdCrrDept1 === "Skip")) {            
          this.sendPPFform.patchValue({ radioVal: 0 });
        }
        else {
          this.sendPPFform.patchValue({ radioVal: 1 });
        }
      }

      if (this.stepStatus[this.crrStep - 1] === 1) {
        this.btnSelDock1Disabled = true;
        this.btnSelDock2Disabled = true;
        this.btnSelDock3Disabled = true;
        this.btnSelDock4Disabled = true;
        this.btnSelDock5Disabled = true;
      }
    }
    catch (error) {
    }
  }

  private createHtml_sharePlants(data: any) {
    try {
      this.sharePlants = data?.createHtml_sharePlants;

      const countOK = this.sharePlants?.countOK;
      const countNG = this.sharePlants?.countNG;
      const cntWorking = this.sharePlants?.cntWorking;

      // child plants
      const dvChld = data?.dv_chld

      if (this.checkNull(dvChld)) {
        this.tdSharePlant = "-";
        this.tdSharePlantOK = "-";
        this.tdSharePlantNG = "-";
        this.btnSharePlantsDisabled = true;
        return;
      }

      if (this.leaderFlg === 1 || this.stepStatus[this.crrStep - 1] === 1) {
        this.btnSharePlantsDisabled = true;
      }

      this.tdSharePlant = this.nullStr(dvChld.length);

      // count OK or NG 


      if (cntWorking > 0 || (dvChld.length === countNG + countOK)) {
        this.btnSendDisabled = true;
      }

      this.tdSharePlantOK = countOK.toString();
      this.tdSharePlantNG = countNG.toString();
    }
    catch (error) {
      return;
    }
  }

  private createHtml_commentIntSup(data: any) {
    try {
      // input data

      const objCommentIntSup = data?.createHtml_commentIntSup;

      const taIntComment = this.nullStr(objCommentIntSup["dv_taIntComment"]);
      const taExtComment = this.nullStr(objCommentIntSup["dv_taExtComment"]);

      this.sendPPFform.patchValue({ taIntComment: taIntComment });
      this.sendPPFform.patchValue({ taExtComment: taExtComment });

      if (this.stepStatus[this.crrStep - 1] !== 1 || this.leaderFlg === 1) {
        this.sendPPFform.get('taIntComment')?.disable();
        this.sendPPFform.get('taExtComment')?.disable();
      }

    }
    catch (error) {
      return;
    }
  }

  private createHtml_RequestAsFinal(data: any) {
    try {
      const dv: any = data?.createHtml_RequestAsFinal;
      
      if (this.screenID !== 720 || this.leaderFlg === 1) {
        if (this.nullInt(dv?.finalFlag) === 1) {
          this.sendPPFform.patchValue({ chkReqAsFinal: true })
        }
        if (this.nullStr(dv?.finalReason) !==  "") {
         
          this.sendPPFform.patchValue({ taFinalReason: this.nullStr(dv?.finalReason) })
        }

        this.sendPPFform.get('chkReqAsFinal')?.disable();
        this.sendPPFform.get('taFinalReason')?.disable();
      }

    }
    catch (error) {
      return;
    }
  }

  private assignUserRole() {
    this._state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {          
          this.userInfo = res?.info;
          this.roleInfo = res?.role;
          this.paramData = JSON.parse(sessionStorage.getItem('deptApprove') || '{}');
          this.spCode = this.userInfo.specialistcode;
          this.step = sessionStorage.getItem('step') || '';
          this.loadPageLoadData();
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }


  private checkNull(value: any): boolean {
    try {

      if (Object.prototype.toString.call(value) === '[object Array]') {
        return false;
      } else if (Object.prototype.toString.call(value) === '[object Object]') {
        return false;
      } else if (value === null || value === undefined || value.trim() === "" || value === 'undefined' || value === 'null') {
        return true;
      } else {
        return false;
      }
        
    } catch (error) {
      return false;
    }
  }

  private nullStr(value: any): string {

    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }

  private nullInt(obj: any) {
    if (obj === null) {
      return 0;
    }
    else {
      return parseInt(obj)
    }
  }

  changeUnitFormat(size: any, type: any) {
    if (this.userInfo?.unit === '1') {
      if (type === 0) {
        return this.formatInchNull(size);
      }
      else {
        return this.formatPoundNull(size);
      }
    }
    else {
      if (type === 0) {
        return this.formatMmNull(size);
      }
      else {
        return this.formatkgNull(size);
      }
    }
  }

  formatInchNull(size: any) {
    if (size === 0) {
      return "0";
    }
    return size.toFixed(2);
  }

  formatPoundNull(size: any) {
    if (size === 0) {
      return "";
    }
    return size.toFixed(1);
  }

  formatMmNull(mm: any) {
    if (mm === 0) {
      return "";
    }
    return mm.toFixed(2);
  }

  formatkgNull(kg: any) {
    if (kg === 0) {
      return "";
    }
    return kg.toFixed(2);
  }

  changeSize(obj: any, type: any) {
    if (obj === null) {
      return 0;
    }
    else {
      const size = this.nullDouble(obj); 
      if (this.userInfo?.unit === '0') {
        return size;
      }
      else {
        if (type === 0) {
          return this.changeInch(size);
        }
        else if (type === 1) {
          return this.changePound(size);
        }
        return 0;
      }
    }
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === undefined) {
      return 0;
    }
    else {
      try {
        if (typeof obj === 'number') {
          return obj;
        }
        else if (typeof obj === 'string') {
          return parseFloat(obj.toString());
        }
        else {
          return parseInt(obj.toString());
        }
      }
      catch {
        return 0;
      }
    }

  }

  changeInch(size: any) {
    return Math.round(size / 25.4 * 2) / 2;
  }

  changePound(size: any) {
    return size * 1000 / 453.59237;
  }


  createHtml_ProposalWithChanges(data : any) {
    const dv: any[] = data?.createData?.result

    if (this.checkNull(dv)) {
      return
    }
    if (dv.length < 1) {
      this.tdProposalChanges = 'No'
      return
    }
    
    const result = dv ? this.nullInt(dv[0]['result']) : 0

    if (result === 1) {
      this.tdProposalChanges = 'Yes'
    } else {
      this.tdProposalChanges = 'No'
    }
  }
  
  help(){
    this.router.navigate(['./master-help',2,"Tracking",18])
  }
}
