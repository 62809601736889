import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'dec-cell-render',
  template: `
    <div *ngIf="!this.params.displayName">
      <input
      type="radio" 
      [checked] = "params.value === params.data.selectedValue"
      (change)="handler($event)"
      />
    </div>
  `,
})
export class DecCellRender implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params.data}`
    );
  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  handler(_event: any) {
    this.params.data.selectedValue = this.params.value
    const rowsData = this.params.api.getModel().rowsToDisplay;
    const gridDatas = rowsData.map((rowNode: any) => rowNode.data);
    const approveArray: any = [];
    const rejectArray: any = [];
    const reserveArray: any = [];
    for (const row of gridDatas) {
      if (this.params.column.colId === 'approve') {
        if (row.approve === row.selectedValue) {
          approveArray.push(true);
        } else {
          approveArray.push(false);
        }
      } else if (this.params.column.colId === 'reject') {
        if (row.reject === row.selectedValue) {
          rejectArray.push(true);
        } else {
          rejectArray.push(false);
        }
      } else if (this.params.column.colId === 'reserve') {
        if (row.reserve === row.selectedValue) {
          reserveArray.push(true);
        } else {
          reserveArray.push(false);
        }
      }
    }
    if (approveArray.length > 0 && approveArray.every((x: any) => x === true)) {
      for (const gridData of gridDatas) {
        gridData.headerApprove = true;
    }
    
    } else if (rejectArray.length > 0 && rejectArray.every((x: any) => x === true)) {
      for (const gridData of gridDatas) {
        gridData.headerReject = true;
      }
    } else if (reserveArray.length > 0 && reserveArray.every((x: any) => x === true)) {
      for (const gridData of gridDatas) {
        gridData.headerReserve = true;
      }
    }else{
      for (const data of gridDatas) {
        data.headerReject = false;
        data.headerReserve = false;
        data.headerApprove = false;

      }
    }
    this.params.api.refreshHeader();
    this.params.api.redrawRows();
  }
}
