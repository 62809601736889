import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { Router } from '@angular/router';
import { TargetInputCellRender } from 'src/app/core/gridrender/target-input-cell-render';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.scss']
})
export class ShipmentHistoryComponent implements OnInit {
  public rowData: any;

  //ag-grid-specs
  gridApi: any;
  columnDefs1: any[] = [];
  columnDefs2: any[] = [];
  displayRowData: any[] = [];
  displayRowData1: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  public domLayout: any = '';
  rowStyle: any = {};
  sum: any;
  spCode: any;
  deliveryDetails: any;
  authItems: any[] = [];
  dates: any[] = [];
  datesVal : any[] = []
  var = '';
  total = 0;
  rowClassRules: any;
  narestMonday = '';
  authNumber: any;
  haveAccess:any;
  propertyExist = false;
  planAndActualTotal: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ShipmentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    public datepipe: DatePipe,
    public shipmentListService: ShipmentListService,
    private readonly spinnerService: NgxUiLoaderService,
    public router: Router,
    protected state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      wrapHeaderText: true,
      suppressPaginationPanel: true,
      autoHeight: false,
      suppressHorizontalScroll: true,
      singleClickEdit: true,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  public targetcellRendererParams = {
    cellRendererFramework: TargetInputCellRender,
  };
  ngOnInit() {
   this.authNumber=this.dataStore.authNumber
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_pkgview;
          setTimeout(() => {
            this.spinnerService.start();
            this.rebuild = this.haveAccess && this.rebuild.bind(this);
            this.haveAccess && this.loadDeliveryDetails();
            this.haveAccess && this.setcolumndefs()
            !this.haveAccess && this.spinnerService.stop();
          },0)
        }
      },
    })
  }

  setcolumndefs(){
    this.columnDefs1 = [
      {
        headerName: '',
        field: 'heading',
        minWidth: 100,
      },
      {
        headerName: 'Container',
        field: 'container',
        minWidth: 90,
      },
      {
        headerName: 'Dunnage1',
        field: 'dunnage1',
        minWidth: 90,
      },
      {
        headerName: 'Dunnage2',
        field: 'dunnage2',
        minWidth: 90,
      },
      {
        headerName: 'Top Cap',
        field: 'topCap',
        minWidth: 80,
      },
      {
        headerName: 'Pallet',
        field: 'pallet',
        minWidth: 70,
      },
      {
        headerName: 'Kanban Holder',
        field: 'kanbanHolder',
        minWidth: 90,
      },
      {
        headerName: 'Label',
        field: 'label',
        minWidth: 70,
      },
      {
        headerName: 'Other',
        field: 'other',
        minWidth: 70,
      },
    ];
  }

  loadDeliveryDetails() {
    this.spinnerService.start();
    const vals = ['authorized', 'shipped', 'received', 'remaining'];
    const columns = [
      'heading',
      'container',
      'dunnage1',
      'dunnage2',
      'topCap',
      'pallet',
      'kanbanHolder',
      'label',
      'other',
    ];

    const params = {
      authNumber: this.authNumber
    };
    this.shipmentListService.DeliveryHistory(params).subscribe({
      next: (res: any) => {
        this.deliveryDetails = res;

        this.displayRowData1 = Object.keys(res)
          .filter((f) => vals.includes(f))
          .map((v) => {
            const content = [v.toUpperCase(), ...res[v]];
            return columns.reduce((acc: any, e: any, curIndex) => {
              acc[e] = content[curIndex];
              return acc;
            }, {});
          });

        this.rowCount = this.displayRowData1.length;

        const propertyToCheck = "date";
        this.propertyExist = res.planAndActual.every((obj: any) => obj.hasOwnProperty(propertyToCheck))
        if(this.propertyExist) {
          const planAndActualLength = res.planAndActual.length;
            for(let i = planAndActualLength; i < 16; i++) {
              res.planAndActual.push(
                {
                  "date": "",
                  "shipment_plan": "0",
                  "receivement_plan": "0",
                  "shipment_actual": 0,
                  "receivement_actual": 0
                }
              )
            }
        }

        this.generateTable2(res);
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      },
    });
  }

  generateTable2(result: any) {
    const date = new Date(result.deliveryDate);
    let newdate = new Date(date.getTime() - 84 * 24 * 60 * 60 * 1000);
    const day = newdate.getDay();
    for (let i = 0; i < day; i++) {
      newdate = new Date(newdate.getTime() - 24 * 60 * 60 * 1000);
    }
    newdate = new Date(newdate.getTime() + 24 * 60 * 60 * 1000);
    const dates = [];
    for (let i = 0; i < 16; i++) {
      const dumy= `0${(newdate.getMonth() + 1)}`
       const m = `${newdate.getMonth() + 1 < 10 ? dumy :  (newdate.getMonth() + 1)}/${newdate.getDate() < 10 ? '0'+newdate.getDate(): newdate.getDate()}`;
      dates.push(m);
      newdate = new Date(newdate.getTime() + 7 * 24 * 60 * 60 * 1000);
    }
    this.dates = dates;
    if(this.propertyExist) {
      this.datesVal = [];
      this.datesVal = result.planAndActual.map((obj: any) => obj.date)
      const mDates: any[] = [];
      this.datesVal.forEach((val: any) => {
        if(val !== "") {
         const mondayDate =  this.findMonday(val);
         mDates.push(mondayDate);
        } 
      })
      const planAndActual = result.planAndActual.map((obj: any, index: number) => ({...obj, mondayDate:mDates[index]}))
      this.planAndActualTotal = [];
      planAndActual.forEach((item: any) => {
        const valueToFind = item.mondayDate
        const objectsWithSameValue = planAndActual.filter((obj: any) => obj['mondayDate'] === valueToFind)
        if(objectsWithSameValue.length > 1) {
          const receivementPlan = objectsWithSameValue.map((value: any) => value.receivement_plan).reduce((accumulator: any, currentValue: any) => {
            return +accumulator + +currentValue;
          }, 0);
          const shipmentPlan = objectsWithSameValue.map((value: any) => value.shipment_plan).reduce((accumulator: any, currentValue: any) => {
            return +accumulator + +currentValue;
          }, 0)
          const receivementActual = objectsWithSameValue.map((value: any) => value.receivement_actual).reduce((accumulator: any, currentValue: any) => {
            return +accumulator + +currentValue;
          }, 0)
          const shipmentActual = objectsWithSameValue.map((value: any) => value.shipment_actual).reduce((accumulator: any, currentValue: any) => {
            return +accumulator + +currentValue;
          }, 0)
          const valueExist = this.planAndActualTotal.find((findItem: any) => findItem.mondayDate === objectsWithSameValue[0].mondayDate)
          if(!valueExist) {
            this.planAndActualTotal.push({
              date: objectsWithSameValue[0].date,
              mondayDate: objectsWithSameValue[0].mondayDate,
              receivement_plan: receivementPlan,
              shipment_plan: shipmentPlan,
              receivement_actual: receivementActual,
              shipment_actual: shipmentActual
            })
          }
        } else {
          this.planAndActualTotal.push({
            date: objectsWithSameValue[0].date,
            mondayDate: objectsWithSameValue[0].mondayDate,
            receivement_plan: +objectsWithSameValue[0].receivement_plan,
            shipment_plan: +objectsWithSameValue[0].shipment_plan,
            receivement_actual: +objectsWithSameValue[0].receivement_actual,
            shipment_actual: +objectsWithSameValue[0].shipment_actual
          })
        }
      })
    }

    const target = result.target;
    let paData = [];
    paData = result.planAndActual.map((p: any, ind: any) => {
      return { ...p, ...{ shipment_target: target[ind].target } };
    });
    const red = paData.reduce((acc: any, a: any) => {     
      const k = Object.keys(a);     
      const temp = { ...acc };    
      k.forEach((e) => {
        if (temp[e]) {
          temp[e] = [...temp[e], a[e]];
        } else {
          temp[e] = [a[e]];
        }
      });
      return temp;
    }, {});
    const data = dates.map((d) => {
      return Object.keys(red).map((v) => {
        return {
          heading: v,
          ...red[v].reduce((acc: any, j: any) => {
            return { ...acc, [d]: j };
          }, {}),
        };
      });
    });
    const rows = data.reduce((acc: any, d: any) => {
      const temp = { ...acc };
      d.forEach((k: any) => {
        if (temp[k['heading']]) {
          temp[k['heading']] = { ...temp[k['heading']], ...k };
        } else {
          temp[k['heading']] = { ...k };
        }
      });
      return temp;
    }, {});
    const planAndActualTotalCopy = this.planAndActualTotal;
    rows.date && Object.keys(rows.date).forEach((item: any, index: number) => {
     const matchDate = this.planAndActualTotal.find((val: any) => val.mondayDate === item)
     if(matchDate) {
      rows.receivement_actual[item] = matchDate.receivement_actual;
      rows.receivement_plan[item] = matchDate.receivement_plan;
      rows.shipment_plan[item] = matchDate.shipment_plan;
      rows.shipment_actual[item] = matchDate.shipment_actual;
      const indexVal = this.planAndActualTotal.findIndex((val1: any) => val1.mondayDate === matchDate.mondayDate)
      if(indexVal !== -1) {
        planAndActualTotalCopy.splice(indexVal, 1);
      }
     } else {
      rows.receivement_actual[item] = 0
      rows.receivement_plan[item] = 0
      rows.shipment_plan[item] = 0
      rows.shipment_actual[item] = 0
     }
     if(index > 0) {
      rows.shipment_target[item] =  target[index-1].target;
      }

    })
   
    const c = [
      'shipment_target',
      'shipment-plan',
      'shipment-actual',
      'receivement_plan',
      'receivement-actual',
    ];
    let final = c.map((k) => {
      if (k.includes('_')) {
        return {
          ...rows[k],
          schedule: k.split('_')[0].toUpperCase(),
          subschedule: k.split('_')[1].toUpperCase(),
        };
      } else {
        const nv = k.replace('-', '_');
        return {
          ...rows[nv],
          // schedule: nv.split('_')[0].toUpperCase(),
          subschedule: nv.split('_')[1]?.toUpperCase(),
        };
      }
    });
    final = final.map((f, _ind) => {
      const total = this.dates
        .map((d) => f[d])
        .reduce((acc: any, c: any) => {
          return (+acc) + (+c);
        }, 0);
      return { ...f, total: total };
    });
    const receivementPlan = planAndActualTotalCopy.map((value: any) => value.receivement_plan).reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue;
    }, 0);
    const receivementActual = planAndActualTotalCopy.map((value: any) => value.receivement_actual).reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue;
    }, 0);
    const shipmentActual = planAndActualTotalCopy.map((value: any) => value.shipment_actual).reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue;
    }, 0);
    const shipmentPlan = planAndActualTotalCopy.map((value: any) => value.shipment_plan).reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue;
    }, 0);
   final.forEach((total: any, index: number) => {
     if(index === 1) {
      total.total = (total.total + shipmentPlan);
     } else if (index === 2) {
      total.total = (total.total + shipmentActual)
    } else if (index === 3){
      total.total = (total.total + receivementPlan)
    } else if(index === 4) {
      total.total = (total.total + receivementActual)
    }
   });
    this.displayRowData = final;
    this.narestMonday = this.findmonday();
      this.columnDefs2 = [
        {
          headerName: 'Schedule',
          field: 'schedule',
          width: 140,
          pinned: 'left',
          rowSpan: this.rowSpan,
          cellClassRules: {
            'cell-span': "value==='SHIPMENT' || value==='RECEIVEMENT'",
          },
          cellStyle: {
            'background-color': '#f7f9fb',
            border: '1px solid #c6d8e0',
            'font-weight': 500,
          },
        },
        {
          headerName: '',
          field: 'subschedule',
          width: 130,
          pinned: 'left',
          cellStyle: {
            'background-color': '#f7f9fb',
            border: '1px solid #c6d8e0',
            'font-weight': 500,
          },
          // cellStyle: (_params: any) => {
          //   return { border: 'none !important' };
          // },
        },
        ...this.dates.map((d) => {
          return {
            headerName: d,
            field: d,
            width: 90,
            cellStyle: (params: any) => {
              if (params.rowIndex === 2 && params.value < params.node.parent.childrenAfterSort[1].data[params.colDef.field] || params.rowIndex === 4 && params.value < params.node.parent.childrenAfterSort[3].data[params.colDef.field]) {
                return { backgroundColor: 'red' };
              } else if (params.colDef.field === this.narestMonday) {
                return { backgroundColor: 'yellow' };
              } else {
                return { backgroundColor: 'white' };
              }
            },
          };
        }),
        {
          headerName: 'Total',
          field: 'total',
          width: 100,
        },
      ];
  }

  findMonday(inputDate: any) {
    const date = new Date(inputDate);
    const currentDayOfWeek = date.getDay();
    const daysUntilMonday = (currentDayOfWeek + 6) % 7;
    const mondayDate = new Date(date);
    mondayDate.setDate(date.getDate() - daysUntilMonday);
    return mondayDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit'})
  }

  findmonday() {
    const deliverydate = new Date(this.deliveryDetails.deliveryDate);
    const deliveryMonth = deliverydate.getMonth() + 1;
    const deliveryDay = deliverydate.getDate();
    let count = 0;
    this.dates.forEach((e) => {
      const newDates = new Date(e);
      const dateMonth = newDates.getMonth() + 1;
      const dateDay = newDates.getDate();
      if (deliveryMonth === dateMonth) {
        if (deliveryDay > dateDay || deliveryDay === dateDay) {
          this.var = e;
          count++;
        }
      } else if (deliveryMonth - dateMonth === 1) {
        if (deliveryDay < dateDay || deliveryDay === dateDay) {
          this.var = e;
          count++;
        }
      } else {
        if (count === 0) {
          this.var = '';
        }
      }
    });
    return this.var;
  }

  rowSpan(params: any) {
    const schedule = params.data?.schedule;
    let v = 1;
    if (schedule === 'SHIPMENT') {
      v = 3;
    } else if (schedule === 'RECEIVEMENT') {
      v = 2;
    }
    return v;
  }

  cellStyle(params: any) {
    if (params.value === 'delay') {
      return {
        'background-color': '#FF9966',
        color: '#FFFFFF',
        'border-radius': '10px',
      };
    } else {
      return null;
    }
  }

  rebuild() {
    this.displayRowData = this.displayRowData.map((f, ind) => {
      let total = this.dates
        .map((d) => f[d])
        .reduce((acc: any, c: any) => {
          return acc + c;
        }, 0);
      if (ind === 0) {
        total = total + this.total;
      }
      return { ...f, total: total };
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
}
