<div class="d-flex justify-content-between align-items-center m-3">
  <p class="page-title">Auth Detail</p>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="accordion-container m-3">
  <mat-accordion hideToggle multi>
    <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0" (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="header-container">
        <mat-panel-title class="roboto-bold me-0">AUTHORIZATION FORM</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let table of tables">
        <div class="row my-3 content-row">
          <div class="col-md-4">
            <table class="vpacs-shadow" style="width:100%; border: 1px solid #BCD1E0;" aria-labelledby="">
              <th class="d-none" id="">auth detail</th>
              <tr *ngFor='let data of table.data'>
                <td style="background-color:#e4ecf0" class="col-md-4">{{data['column1']}}</td>
                <td class="col-md-8 ps-3">{{data['column2']}}</td>
              </tr>
            </table>
            <br>
            <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
              <th class="d-none" id="">auth detail</th>
              <tr *ngFor='let data2 of table.data2'>
                <td style="background-color:#e4ecf0" class="col-md-4">{{data2['column1']}}</td>
                <td class="col-md-8 ps-3">{{data2['column2']}}</td>
              </tr>
            </table>
            <br>
            <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
              <th class="d-none" id="">auth detail</th>
              <tr>
                <td colspan="5"
                  style="width:2% ; background-color:#e4ecf0; text-align: left;border: 1px solid #BCD1E0; font-weight: 500">Label
                  Information</td>
              </tr>
              <tr *ngFor='let data3 of table.data3'>
                <td style=" background-color:#e4ecf0" class="col-md-4">{{data3['column1']}}</td>
                <td class="col-md-8">{{data3['column2']}}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-8 ps-4">
            <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
              <th class="d-none" id="">auth detail</th>
              <tr class="tHead">
                <th style="background-color:#e4ecf0" id=""></th>
                <th style="background-color:#e4ecf0" id="">Package Code</th>
                <th style="background-color:#e4ecf0" id="">Qty</th>
                <th style="background-color:#e4ecf0" id="">Unit $(US)</th>
                <th style="background-color:#e4ecf0" id="">Cost</th>
              </tr>
              <tr>
              </tr>
              <tr *ngFor='let data4 of table.data4'>
                <td style=" background-color:#e4ecf0">{{data4['column1']}}</td>
                <td>{{data4['column2']}}</td>
                <td>{{data4['column3']}}</td>
                <td>{{data4['column4']}}</td>
                <td>{{data4['column5']}}</td>
              </tr>
              <tr *ngFor='let data5 of table.data5'>
                <td colspan="4" style="background-color:#F4F0DF; text-align: right;  border: 1px solid #BCD1E0;">
                  {{data5['column1']}}
                </td>
                <td colspan="22" style="background-color:#F4F0DF;  border: 1px solid #BCD1E0;">${{totalCost}}</td>
              </tr>
            </table><br>
            <div class="d-flex col-md-12 ps-0 gap-5">
              <div class="d-flex col-md-5">
                <label class="col-3 me-3">Comment for Supplier</label>
                <textarea rows="7" cols="70" readonly>{{details?.AuthorizationInfo[0].comment1}}</textarea>
              </div>
              <div class="d-flex col-md-5 ms-1">
                <label class="col-3 me-3">Internal use only</label>
                <textarea rows="7" cols="70" readonly>{{details?.AuthorizationInfo[0].comment2}}</textarea>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion><br><br>
</div>

<div class="create_footer d-flex justify-content-end align-items-center fixed-bottom">
  <button class="cancel me-3 roboto-bold" (click)="cancel()">BACK</button>
</div>

