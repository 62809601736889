<div class="legend-wrapper d-flex justify-content-between flex-wrap align-items-center">
    <div class="legend-details d-flex gap-3">
        <div class="d-flex gap-2 align-items-center" *ngIf = "pageName !== 'pkg-authorization'">
            <span class="no1"></span>(Delay n < 0)
        </div>
        <div class="d-flex gap-2 align-items-center" *ngIf = "pageName !== 'pkg-authorization'">
            <span class="no2"></span>Warn (0<=N<=5)
        </div>
        <div class="d-flex gap-2 align-items-center" *ngIf = "pageName !== 'pkg-authorization'">
            <span class="no3"></span>Good (5< N)
        </div>
        <div class="d-flex gap-2 align-items-center">
            <span class="no4"></span>Cancel Auth
        </div>
        <div class="d-flex gap-2 align-items-center" *ngIf = "pageName !== 'pkg-authorization'">
            <span class="no5"></span>Reject Backup, Update PPF
        </div>
    </div>
    <div class="legend-right-icons d-flex gap-2 align-items-center">
            <mat-icon class="mat-ico-legend" *ngIf = "pageName !== 'pkg-authorization'">my_location</mat-icon>
            <span *ngIf = "pageName !== 'pkg-authorization'">Define</span>
            <mat-icon class="mat-ico-legend" *ngIf = "pageName !== 'pkg-authorization'">pan_tool_alt</mat-icon>
            <span *ngIf = "pageName !== 'pkg-authorization'">Use</span>
            <mat-icon class="mat-ico-legend" >check_circle</mat-icon>
            <span>Do</span>
            <mat-icon class="mat-ico-legend" *ngIf = "pageName !== 'tracking'">download</mat-icon>
            <span *ngIf = "pageName !== 'tracking'">Auth Download</span>
            <mat-icon class="mat-ico-legend" >upload</mat-icon>
            <span>Submit</span>
            <mat-icon class="mat-ico-legend" *ngIf = "pageName !== 'pkg-authorization'">description</mat-icon>
            <span *ngIf = "pageName !== 'pkg-authorization'">PPF</span>
    </div>
</div>
