import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
interface ShipmentUpdateRequest {
  url_partnumber: string;
  url_supplier: string;
  url_plant: string;
  url_fromdate: string;
  newSupplier: string;
  supplierType: string; 
}
@Injectable({
  providedIn: 'root',
})

export class TrackingListService {
  trackingFilter: any;
  tFilters:any;
  trackingSort:any;
  plantdata: any;
  filterdata: any;
  work: any;
  paginationValues:any;
  pkg: any;
  flag: any;
  trackingGridSate:any={
    sort : null,
    filter : null,
    currentpage : 0,
    totalpage : 0,
    rowcount : 0,
    pagesize : 0
  }
  
  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService
  ) { }
  URL = this.constantsService.TRACKING;
  URL1=this.constantsService.INTERNAL;

  loadTrackingListData(payload: any): Observable<any> {
    const URL = this.URL + 'trackingList/OnLoad';
    return this.http
      .post(URL, payload, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  getDataSynchronous(payload: any):Promise<any>{
    return this.loadTrackingListData(payload).toPromise()
  }

  backUpOnLoad(backupParams: any): Observable<any> {
    return this.http.post(this.URL1 + 'trackingPartList/backupOnload', backupParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))

    
  }

  trialOrderLoad(backupParams: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/StepR', backupParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  backUpApproveOrReject(payload: any): Observable<any> {
    const URL = this.URL + 'trackingList/approveRejectBackupPpf';
    return this.http
      .post(URL, payload, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  releaseLeader(params: any): Observable<any> {
    return this.http.put<any>(this.URL + 'trackingList/leadFollowerRelease', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  benchMarkLoad(backupParams: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/benchMarkOnLoad', backupParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  benchMarkSave(backupParams: any): Observable<any> {
    return this.http.put(this.URL + 'trackingList/BMOkClick', backupParams, { headers: this.constantsService.internalHttpOptions, responseType: 'json' })
      .pipe(catchError(this.handleError))
  }

  shipmentOnLoad(obj: any): Observable<any> {
      return this.http.post<any>(this.URL + 'trackingList/supplierOnLoad', obj, 
      {headers: this.constantsService.internalHttpOptions})
        .pipe(
          catchError(this.handleError)
        )
  }

  supplierUpdateOnLoad(obj: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/supplierUpdateOnLoad', obj, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(
        catchError(this.handleError)
      )
  }

  shipmentUpdate(body: any): Observable<any> {
        const URL = this.constantsService.TRACKING + 'trackingList/supplierUpdate';
        return this.http.put(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  leadOnLoad(body: any): Observable<any> {
      const URL = this.constantsService.TRACKING + 'trackingList/leadFollower';
      return this.http.post(URL,body, {headers: this.constantsService.internalHttpOptions}).pipe(catchError(this.handleError))
  }

  getFabricatorList(payload: any): Observable<any> {
    const URL =
      this.URL + 'trackingList/getFabricatorList';
    return this.http
      .post(URL, payload, {
        headers: this.constantsService.internalHttpOptions,
      })
      .pipe(catchError(this.handleError));
  }

  updateFabricator(payload: any): Observable<any> {
    const URL =
      this.URL + 'trackingList/updateFabricator';
    return this.http
      .put(URL, payload, { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError));
  }
  
  deleteFabricator(payload: any): Observable<any> {
      return this.http.post<any>(this.URL + 'trackingList/deleteFabricator', payload, {
        headers: this.constantsService.internalHttpOptions,
      }).pipe(
        catchError(this.handleError)
      )
  }
  //tracking history
  loadTrackingHistory(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/trackingHistory', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  selectPartBtnOnload(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'tracking/selectBtnOnload', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  btnSave(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnSave_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  updateSpecialist(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/updateButton', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  deleteTrackingList(params: any): Observable<any> {
    return this.http.delete<any>(this.URL + 'trackingList/deletePart', {
      body: params,
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  updatePartConfirm(ppfConfirm: any): Observable<any> {
    return this.http.put(this.URL + 'trackingList/updatePartConfirm', ppfConfirm, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  getCorrection(ppfConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/getCorrection', ppfConfirm, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  submitCorrection(ppfConfirm: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/submitCorrection', ppfConfirm, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  loadBroadcastCalc(params: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/broadcastCalc', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  broadcastCalcButton(params: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/btnCalc_ServerClick', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  getUpdateDue(param: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/getUpdateDue', param, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUpdateDue(param: any): Observable<any> {
    return this.http.put<any>(this.URL + 'trackingList/updateUpdateDue', param, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(
        catchError(this.handleError)
      )
  }
  
  broadcastOKButton(params: any): Observable<any> {
    return this.http.put(this.URL + 'trackingList/calcOk', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  leadFollowerSelect(params: any): Observable<any> {
    return this.http.put(this.URL + 'trackingList/leadfollowerSelect', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  //SI, SE, SQ, HZ BUTTONS

  sendDepartmentApproval(params: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/SendDepartmentApproval', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  departmentApprovalSI(params: any): Observable<any> {
    return this.http.post(this.URL + 'trackingList/SIbtnApprove_ServerClick', params, 
    { headers: this.constantsService.internalHttpOptions })
      .pipe(catchError(this.handleError))
  }

  getSEOnload(param: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/SendDepartmentApproval', param, 
    {headers: this.constantsService.internalHttpOptions})
      .pipe(
        catchError(this.handleError)
      )
  }

  siBtnApprove(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/SIbtnApprove_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  hazmatApprove(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/hazmatApprove', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  siBtnSend(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnSend_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  hazmatSend(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/hazmatSend', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  siBtnReject(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnReject_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  hazmatReject(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/hazmatReject', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  siBtnRejectBm(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnRejectBM_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
   
  hazmatRejectBm(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/hazmatRejectBM', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  siBtnRejectSup(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnRejectSup_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }
  
  hazmatRejectSup(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/hazmatRejectSup', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  trialOrderAuthorize(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/btnOrder_ServerClick', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  getPackageCost(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/getPackageCost', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  getReleaseData(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/getReleaseData', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  releaseFollower(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'trackingList/releasefollower', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error');
  }

  resetFilters(){
    this.trackingFilter = '';
  }
  settrackingFilters(trackingFilter: any) {
    this.trackingFilter = trackingFilter;
  }
  gettrackingFilters() {
    return this.trackingFilter;
  }
  setTrackingplant(plant: any) {
    this.plantdata = plant;
  }
  getTrackingplant() {
    return this.plantdata;
  }

  resetTrackingGridfilter(){
    this.filterdata = '';
  }
 
  setTrackingGridfilter(filter: any) {
    this.filterdata = filter;
  }
  getTrackingGridfilter() {
    return this.filterdata;
  }

  setPaginationValues(data:any){
    this.paginationValues=data
  }

  getPaginationValues(){
    return this.paginationValues;
  }

  clearPaginationValues(){
    this.paginationValues=undefined;
  }

  setNavFlag(flag: any) {
    this.flag = flag;
  }
  
  getNavFlag() {
    return this.flag;
  }

  setWorkTableData(val: any) {
    this.work = val
  }

  getWorkTableData() {
    return this.work;
  }

  resetWorkTableData(){
    this.work = '';
  }

  setPkgStartDate(date: any) {
    this.pkg = date
  }

  getPkgStartDate() {
    return this.pkg;
  }

  resetPkgStartDate(){
    this.pkg = '';
  }
}
export const dateComparator = (valueA :any, valueB:any) => {
  const dateA = new Date(valueA);
  const dateB = new Date(valueB);
  if (dateA.getTime() === dateB.getTime()) {
    return 0;
  } else {
    return dateA.getTime() > dateB.getTime() ? 1 : -1;
  }
};

export const customComparator = (a:any,b:any) => {
  if(a<b){
    return -1;
  }else if(a > b){
    return 1;
  }else{
    return 0;
  }
}

export const dataComparator = (valueA:any, valueB:any) => {
  if (!valueA) { return -1};     
  if (!valueB) { return 1};     
  const numberA = parseInt(valueA.slice(1));    
  const numberB = parseInt(valueB.slice(1));     
  if (numberA === numberB) { return 0 };     
  return (numberA > numberB) ? 1 : -1;
};
