import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'input-btn-cell-render',
  template: `
  <input  style="width:50px;height:30px" [disabled] = "true" [value]="params.value ?? '' "  type="text"  id="input" />
  `,
})

export class InputBtnCellRender implements ICellRendererAngularComp {

  pageName: any;
  partFlag = false;
  detailPage: any;
  params: any;
  
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(_params: ICellRendererParams): boolean {
      return true;
	}
  agInit(params: any): void {
    this.params = params;
  }
}




