import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'

@Component({
  selector: 'app-add-budget',
  templateUrl: './add-budget.component.html',
  styleUrls: ['./add-budget.component.scss'],
})
export class AddBudgetComponent implements OnInit {
  component = 'Select'
  modalHeader = ''
  buttonValue = ''
  defaultVal = 'Inital'
  isEditMode = false
  plantResult = ''
  budgetCodeFlag = false
  budgetFlag = false
  plant: any
  supplier: any
  costCenter = ''
  oldCostCenter = ''
  plantCode = ''
  plantName = ''
  plantId = ''
  budget = ''
  oldBudget = ''
  isBudgetError = false
  errorList: any[] = []
  constructor(
    public plantService: PlantService,
    public dialogRef: MatDialogRef<AddBudgetComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.addBudgetOnload()

    if (this.dataStore.mode === 'ADD') {
      this.modalHeader = 'Add Budget'
      this.buttonValue = 'ADD'
      this.budgetCodeFlag = true
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore?.plantId
      this.costCenter = this.dataStore.costCenter
      this.isEditMode = false
    } else if (this.dataStore.mode === 'UPDATE') {
      this.modalHeader = 'Update Budget'
      this.buttonValue = 'UPDATE'
      this.budgetFlag = true
      this.oldBudget = this.dataStore.field.budget
      this.costCenter = this.dataStore.field.costcenter
      this.plantCode = this.dataStore.plantCode
      this.plantName = this.dataStore.PlantName
      this.plantId = this.dataStore.plantId
      this.budget = this.dataStore.field.budget
      this.isEditMode = true
    }
  }
  checkValidations() {
    this.errorList = []
    this.isBudgetError = false

    if (!this.budget) {
      this.isBudgetError = true
    }
  }
  saveData() {
    this.checkValidations()
    if (this.isBudgetError) {
      return
    }
    if (this.buttonValue === 'ADD') {
      const loadObj = {
        PlantCode: this.plantCode,
        CostCenter: this.costCenter,
        PlantName: this.plantName,
        PlantId: this.plantId,
        Budget: this.budget,
      }
      this.plantService.addBudget(loadObj).subscribe({
        next: (data: any) => {
          if (!data.status) {
            this.errorList.push(data.message)
          } else {
            this.dialogRef.close({ data: { result: 'SUCCESS', mode: 'Add', data } })
          }
        },
      })
    } else if (this.buttonValue === 'UPDATE') {
      const loadObj = {
        CostCenter: this.costCenter,
        PlantCode: this.plantCode,
        Budget: this.budget,
        OldBudget: this.oldBudget,
      }
      this.plantService.updateBudget(loadObj).subscribe({
        next: (data) => {
          const result = data?.status ? 'SUCCESS' : '';
          this.dialogRef.close({ data: { result: result, mode: 'Update', data } })
        },
      })
    } else {
      this.close('')
    }
  }

  addBudgetOnload(): void {
    const loadObj = {
      PlantCode: this.dataStore.plantCode,
      Budget: this.dataStore.field?.budget || '',
    }
    this.plantService.addBudgetOnload(loadObj).subscribe({
      next: (res: any) => {
        this.plantResult = res.PlantResult[0]
      },
    })
  }

  closee() {
    this.errorList = []
  }

  close(msg: string): void {
    this.dialogRef.close({ event: 'CloseEvent', msg, mode: 'X' })
  }

  closeDialog() {
    this.dialogRef.close()
  }

  help() {
    this.dialogRef.close()
    if (this.modalHeader === 'Add Budget') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 174])
    } else if (this.modalHeader === 'Update Budget') {
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 175])
    }
  }
}
