import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { getFinalErrorMessage } from 'src/app/internal-vpacs/notification/ErrorConstants';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';

@Component({
  selector: 'app-connectall-model-list',
  templateUrl: './connectall-model-list.component.html',
  styleUrls: ['./connectall-model-list.component.scss'],
})
export class ConnectallModelListComponent implements OnInit {
  isAccessible = false;
  public domLayout: any = '';
  public cellStyle = {
    'box-shadow': 'inset 0px 1px 6px #00000029',
    border: '1px solid #D7DCE1',
    color: '#676A6E',
  };
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  defaultColDef: any;
  displayRowData: any;
  rowCount: any;
  rowData: any;
  plants = [
    { Name: '-Selected Priority-', id: 0 },
    { Name: 'Part Number', id: 1 },
    { Name: 'Model', id: 2 },
    { Name: 'Plant', id: 3 }
  ];
  plants2 = [
    { Name: 'Previous Model', id: 1 },
    { Name: 'Other Model', id: 0 },
  ];
  plant: any = 'ALL';
  plant1: any = '';
  plant2: any;
  plant3: any;
  plant4: any;
  plant5: any;
  plant6: any;
  othermodel1: any;
  othermodel2: any;
  othermodel3: any;
  priorityType1: any = 0;
  priorityType2: any = 0;
  priorityType3: any = 0;
  priorityType4: any = 0;
  priorityType5: any = 0;
  priorityType6: any = 0;
  dropdownVisible = false;
  dropdownVisible2 = false;
  dropdownVisible3 = false;
  btndisable1 = true;
  btndisable2 = false;
  btndisable3 = true;
  btndisable4 = false;
  btndisable5 = true;
  btndisable6 = false;
  spCode: any;
  selectPlant: any[] = [];
  resPlant: any;
  showOption = false;
  showOption2 = false;
  showOption3 = false;
  defaultOption = 'ALL';
  connectParams: any;
  selectedIndices: number[] = [];
  plantNames!: string[];
  plantsId!: number[];
  radioselection: string | null = 'm3part';
  ModelValue: any
  PartNumberValue: any;
  SupplierCode: any;
  TMMCCode: any;
  NUMMICode: any;
  errorList: any[] = [];
  errorMessage: any = [];
  returnMessage: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ConnectallModelListComponent>,
    private readonly service: ModelListService,
    public router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public form: FormsModule,
    public matselect: MatSelectModule
  ) { }

  ngOnInit(): void {
    this.plantNames = this.plants.map(plant => plant.Name);
    this.plantsId = this.plants.map(plant => plant.id);
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    this.data.data.forEach((element: any) => {
      this.ModelValue = element.modelcode
      this.PartNumberValue = element.partnumber
      this.SupplierCode = element.suppliercode
      this.TMMCCode = element.tmmccode
      this.NUMMICode = element.nummicode
    });
    this.connectParams = JSON.parse(
      sessionStorage.getItem('connectParams') || '{}'
    );
    this.apiCall();
    this.getPlant();
  }

  apiCall() {
    setTimeout(() => { this.spinnerService.start() })
    const connectAllObject = {
      url_model: this.data.ModelCode,
      url_plant: this.data.plantCode,
    };
    this.service.connectAllOnload(connectAllObject).subscribe((res: any) => {
      this.spinnerService.stop()
      this.displayRowData = res;
      this.rowCount = this.displayRowData.length;
    });
  }

  getPlant() {
    setTimeout(() => { this.spinnerService.start() })
    const plantobj = {
      "SpecialistCode": this.spCode,
      "Type": 1
    }
    this.service.getAllPlant(plantobj).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.resPlant = res.map((item: { plantcode: any; }) => item.plantcode);
        this.selectPlant = res;
      },
      error: (_er: any) => {
        this.spinnerService.stop();
      },
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
  }

  setPriority() {
    this.btndisable2 = false;
    if (this.priorityType1 === this.plantsId[1] || this.priorityType1 === this.plantsId[0]) {
      this.dropdownVisible = false;
    } else if (this.priorityType1 === this.plantsId[2]) {
      this.dropdownVisible = true;
      this.btndisable1 = false;
      this.showOption = true;
      this.priorityType2 = this.plants2[0].id;
    } else if (this.priorityType1 === this.plantsId[3]) {
      this.showOption = false;
      this.dropdownVisible = true;
      this.btndisable1 = false;
      this.priorityType2 = this.selectPlant[6].plantcode;
    }
  }

  setPriority2() {
    if (this.priorityType2 === this.plants2[0].id) {
      this.btndisable2 = true;
    } else if (this.priorityType2 === this.plants2[1].id) {
      this.btndisable2 = false;
    }
    else {
      this.btndisable2 = true;
    }
  }

  setPriority3() {
    this.btndisable4 = false;
    if (this.priorityType3 === this.plantsId[1] || this.priorityType3 === this.plantsId[0]) {
      this.dropdownVisible2 = false;
    } else if (this.priorityType3 === this.plantsId[2]) {
      this.dropdownVisible2 = true;
      this.btndisable3 = false;
      this.priorityType4 = this.plants2[0].id;
      this.showOption2 = true;
    } else if (this.priorityType3 === this.plantsId[3]) {
      this.dropdownVisible2 = true;
      this.btndisable3 = false;
      this.priorityType4 = this.selectPlant[6].plantcode;
      this.showOption2 = false;
    }
  }

  setPriority4() {
    if (this.priorityType4 === this.plants2[0].id) {
      this.btndisable4 = true;
    } else if (this.priorityType4 === this.plants2[1].id) {
      this.btndisable4 = false;
    }
    else {
      this.btndisable4 = true;
    }
  }

  setPriority5() {
    this.btndisable6 = false;
    if (this.priorityType5 === this.plantsId[1] || this.priorityType5 === this.plantsId[0]) {
      this.dropdownVisible3 = false;
    } else if (this.priorityType5 === this.plantsId[2]) {
      this.dropdownVisible3 = true;
      this.btndisable5 = false;
      this.priorityType6 = this.plants2[0].id;
      this.showOption3 = true;
    } else if (this.priorityType5 === this.plantsId[3]) {
      this.dropdownVisible3 = true;
      this.btndisable5 = false;
      this.priorityType6 = this.selectPlant[6].plantcode;
      this.showOption3 = false;
    }
  }

  setPriority6() {
    if (this.priorityType6 === this.plants2[0].id) {
      this.btndisable6 = true;
    } else if (this.priorityType6 === this.plants2[1].id) {
      this.btndisable6 = false;
    }
    else {
      this.btndisable6 = true;
    }
  }

  help() {
    this.dialogRef.close();
    this.router.navigate(['./master-help', 2, "Tracking", 34])
  }

  getBody() {
   return {
      "first": this.priorityType1,
      "otherPart1": this.priorityType1 === this.plantsId[1] ? this.othermodel1 : '',
      "previousModel1": this.priorityType1 === this.plantsId[2] ? this.priorityType2 : '',
      "previousModelValue1": "",
      "otherModel1": this.priorityType1 === this.plantsId[2] ? this.othermodel1 : '',
      "plantDropValue1": this.priorityType1 === this.plantsId[3] ? this.priorityType2 : '',
      "otherPlant1": this.priorityType1 === this.plantsId[3] ? this.othermodel1 : '',

      "second": this.priorityType3,
      "otherPart2": this.priorityType3 === this.plantsId[1] ? this.othermodel2 : '',
      "previousModel2": this.priorityType3 === this.plantsId[2] ? this.priorityType4 : '',
      "previousModelValue2": "",
      "otherModel2": this.priorityType3 === this.plantsId[2] ? this.othermodel2 : '',
      "plantDropValue2": this.priorityType3 === this.plantsId[3] ? this.priorityType4 : '',
      "otherPlant2": this.priorityType3 === this.plantsId[3] ? this.othermodel2 : '',

      "third": this.priorityType5,
      "otherPart3": this.priorityType5 === this.plantsId[1] ? this.othermodel3 : '',
      "previousModel3": this.priorityType5 === this.plantsId[1] ? this.priorityType6 : '',
      "otherModel3": this.priorityType5 === this.plantsId[2] ? this.othermodel3 : '',
      "previousModelValue3": "",
      "plantDropValue3": this.priorityType5 === this.plantsId[2] ? this.priorityType6 : '',
      "otherPlant3": this.priorityType5 === this.plantsId[3] ? this.othermodel3 : '',

      "url_plant": this.connectParams.plantCode,
      "url_sup": this.SupplierCode,
      "url_part1": this.PartNumberValue,
      "url_part2": "",
      "url_model": this.connectParams.modelCode,
      "TMMCCode": this.TMMCCode,
      "NUMMICode": this.NUMMICode,
      "radioButton": this.radioselection,
      "SessionPlantcode": [
        ""
      ]
    }

  }

  submit() {
    if (this.validation() === true) {
      this.spinnerService.start();
      const body = this.getBody();
      this.service.connectAll(body).subscribe({
        next: (res: any) => {
          if (res.status === true) {
            this.displayRowData = res;
            this.rowCount = this.displayRowData.length;
            this.dialogRef.close({
              event: 'SaveEvent',
              responseType: res.rowsAffected,
            });
            this.spinnerService.stop();
          }
          else {
            if(res.message.length > 0){
            this.returnMessage.push(res.message)
            }
            this.spinnerService.stop();
            this.displayRowData = [];
          };
        },
        error: (_error) => {
          this.spinnerService.stop();
          
          setTimeout(() => {
            this.returnMessage = []
          }, 5000);
        },
      })
    }
  }

  validation() {
    let count = 0;

    if (this.priorityType1 === this.plantsId[0]) {
      if (this.priorityType3 !== this.plantsId[0]) {
        this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'401'}`, undefined, fieldErrorCode: `${'EY0001'}`, field: `${''}`, index: 1 });
        count++;
      }

      if (this.priorityType5 !== this.plantsId[0]) {
        this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'402'}`, undefined, fieldErrorCode: `${'EY0001'}`, field: `${''}`, index: 1 });
        count++;
      }

    } else if (this.priorityType1 === this.plantsId[2]) {
      if (this.priorityType2 === this.plants2[1].id) {
        if (this.othermodel1 === undefined || this.othermodel1 === null) {
          this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'402'}`, undefined, fieldErrorCode: `${'EY0002'}`, field: `${''}`, index: 1 });
          count++;
        }
      }
    }
    if (this.priorityType3 === this.plantsId[0]) {
      if (this.priorityType5 !== this.plantsId[0]) {
        this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'403'}`, undefined, fieldErrorCode: `${'EY0003'}`, field: `${''}`, index: 1 });
        count++;
      }

    } else if (this.priorityType3 === this.plantsId[2]) {
      if (this.priorityType4 === this.plants2[1].id) {
        if (this.othermodel2 === undefined || this.othermodel2 === null) {
          this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'404'}`, undefined, fieldErrorCode: `${'EY0004'}`, field: `${''}`, index: 1 });
          count++;
        }
      }
    }

    if (this.priorityType5 === this.plantsId[2]) {
      if (this.priorityType6 === this.plants2[1].id) {
        if (this.othermodel3 === undefined || this.othermodel3 === null) {
          this.errorMessage.push({ errorCode: `${'999'}`, childErrorCode: `${'405'}`, undefined, fieldErrorCode: `${'EY0005'}`, field: `${''}`, index: 1 });
          count++;
        }
      }
    }

    this.errorMessage.forEach((element: any) => {
      this.returnMessage.push(getFinalErrorMessage(element.errorCode, element.childErrorCode, undefined, element.fieldErrorCode, element.field, element.index))
    });

    if (count === 0) {
      return true
    }
    else {
      setTimeout(() => {
        this.errorMessage = []
        this.returnMessage = []
      }, 5000);
      return false
    }

  }

}
