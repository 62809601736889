import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'hyper-link-btn-cell-render',
  template: `
  <a (click)="navigate()" style="text-decoration:none; font-weight:500">{{ text }}</a>
  `,
})

export class HyperLinkBtnCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, public dialog: MatDialog,private readonly constants: ConstantsService) { }

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }

  private params: any;
  text: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'AUTH#') {
      this.text = this.params.data.authnumber;
    }
    else if (params.colDef.headerName === 'PART NUMBER') {
      this.text = this.params.data.partnumber;
    }
  }

  navigate() {
    if (this.params.colDef.headerName === 'AUTH#') {
      sessionStorage.setItem('authNum', this.params.data.authnumber)
      this.router.navigateByUrl('/auth-list/auth-link');
    }
    else if (this.params.colDef.headerName === 'PART NUMBER'){
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data?.plantcode)
      if (plantCode === 'SERVICE') { 
        this.router.navigate(['/auth-list/serviceparts-pdf',this.params.data.partnumber,this.params.data.ppfcode,this.params.data.ppfver,1,2])
      } 
      else if(plantCode === 'ASSY'){
        this.router.navigate(['/auth-list/accessoryParts-pdf',this.params.data.partnumber,this.params.data.ppfcode,this.params.data.ppfver,1,2])
      }
      else{
        this.router.navigate(['/auth-list/ppf',this.params.data.ppfcode,this.params.data.ppfver,1,2]);
      }
    }
  }
}




