<div class="popup-container">
    <header>
        <button type="button" class="close black-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>  
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title mx-0 px-0">Dock Maintenance</div>   
            <div>
                <button type="submit" class="submit black-button mx-2" style="height: 30px;"  [disabled]="!haveAddAccess" (click)="dialogOpen()" >ADD</button>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>
    <div class="alert alert-success m-2 mt-2" *ngIf="successMessage.length > 0">
        <span>{{ successMessage }}</span>
        <button type="button" class="btn-close" (click)="Close()"></button>
    </div>
    <div class="popup-contents">
        <div class="row">
            <div class="col-10">
                <div class="row m-3 align-items-start">
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5">Plant Code</label>
                    </div>
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5">Plant Name</label>
                    </div>
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5">Plant Id</label>
                    </div>
                </div>
                <div class="row m-3 align-items-start">
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5"><strong>{{PlantCode}}</strong></label>
                    </div>
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5"><strong>{{plantName}}</strong></label>
                    </div>
                    <div class="col-4 d-flex">
                        <label class="catlabel col-5"><strong>{{PlantId}}</strong></label>
                    </div>
                </div>
            </div>
            <div class="col-2 d-flex align-items-center">
                <button class="vpacs-btn-table"
                    style="height:30px; " [disabled]="!haveAddAccess" (click)="dockInformationDialog()" >REGIST</button>
            </div>
        </div>
        <div class="m-2 py-2 px-2">
            <div class="grid-agg tracking-summary">
                <ag-grid-angular style="width:100%;height:55vh;" 
                    class="ag-theme-balham" 
                    (gridReady)="onGridReady($event);"
                    (window:resize)="sizeToFit()" 
                    [rowHeight]="50" 
                    [animateRows]="true" 
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="columnDefs" 
                    [rowData]="displayRowData" 
                    [suppressCellSelection]="true"
                    [suppressHorizontalScroll]="false" 
                    [pagination]="false" 
                    [suppressPaginationPanel]="true" 
                    [icons]="icons" 
                    [floatingFiltersHeight]="40"
                    [overlayLoadingTemplate]="overlayLoadingTemplate" 
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [domLayout]="domLayout" 
                    >
                </ag-grid-angular>        
            </div>
        </div>
    </div>
</div>