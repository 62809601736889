<div *ngIf="haveAccess">
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <button type="button" class="btn-close" (click)="close()"></button>
        <div *ngFor="let message of errorList" class="errormsg">
          <div class="message-container">
            <span class="message-text">{{ message }}</span>
          </div>
        </div>
      </div>
    <div class="ppf" id="print">
        <div class="top-control-buttons gap-2 pe-3">
            <button class="vpacs-btn-table" *ngIf="mode === 1" (click)="savePPF()">SAVE</button>
            <button class="btn btn-link" (click)="downloadPPF()">DOWNLOAD</button>
            <button class="btn btn-link" (click)="navigatePrevious()">CLOSE</button>
        </div>
        <div class="ppf-content m-3" #PPF id="PPF">  
            <div [formGroup]="form0">
            <div style="display:flex;">
                <div class="reqHead col-2" style="width:19.3%;">
                    <h2 class="req">REQUEST TYPE</h2>
                    <ng-container *ngIf="mode===1">
                        <mat-select id="requestType" class="mat-sel" placeholder="Select" formControlName="requestType" (selectionChange)="onRequestChange()">
                            <mat-option *ngFor="let x of requestDropdown" [value]="x.requestValue">{{x.name}}</mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="mode===2">
                        <div>
                            <span class="costEval">{{form0.controls['requestType'].value}}</span>
                        </div>
                    </ng-container>
                    <h2 class="reqPart">PART TYPE</h2>
                    <ng-container *ngIf="mode===1">
                        <mat-select id="partType" class="mat-sel" placeholder="Select" formControlName="partType" (selectionChange)="onRequestChange()">
                            <mat-option *ngFor="let part of partType; let i=index" [value]="part.value">
                                {{ part.name }}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="mode===2">
                        <span class="costEval">{{form0.controls['partType'].value}}</span>
                    </ng-container>
                </div>

                <div style="width:60%; background-color:#ffffff; padding:7px;margin-left:2px; text-align: center;"
                    class="col-8">
                    <div style="display:flex; flex-direction: column;">
                        <div
                            style="background-color:#ffffff;height: 50%; padding:10px;box-shadow: 0px 3px 8px #00000029;">
                            <h3 style="margin:0; padding:0;">ACCESSORY PPO/DIO AND SERVICE PARTS PACKAGING DATA FORM
                            </h3>
                            <p class="costEval">PACKAGING DESIGN/COST EVALUATION</p>
                        </div>
                        <div class="emailFrom">                           
                            <p class="pHead">EMAIL FORM TO: <span
                                    style="text-decoration: underline;">PSCP-ServicePackagingDesign@toyota.com</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="reqHead col-2" style="width: 19.3%;">
                    <h2 class="req">STATUS</h2>
                    <ng-container *ngIf="mode===1">
                        <mat-select id="status" class="mat-sel" placeholder="Select" formControlName="status">                           
                            <mat-option *ngFor="let x of statusDropdown" [value]="x.statusValue">{{x.name}}</mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="mode===2">
                        <span class="costEval">{{finalView}}</span>
                    </ng-container>
                </div>
            </div>            
            <div class="container-list d-flex flex-wrap mb-2">
                <div class="card col-lg-3 col-md-6 col-12 m-1 flex-wrap" >
                    <div class="card4-header px-3 py-2">
                        <span>VENDOR INFORMATION</span>
                    </div> 
                    <table style="width: 100%;" aria-describedby="vendor-information">
                        <tr class="d-none"><th>vendor-information</th></tr>
                        <div class="row  mt-2 mx-1 heading-row m-1 py-2 px-2 table-content">
                            <table aria-describedby="vendor-information" class="table">
                                <tr class="d-none"><th>vendor-information</th></tr>
                                <tbody class="d-flex">
                                    <div class="col-md-7 rowClass2">
                                        <div class="contents2 ps-1 py-1">
                                            Date
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            Vendor Name
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            TMS FOB City/State/Prov
                                        </div>
                                        <div class="contents2 ps-1 py-1 modelLabel">
                                            TMS Vendor Code
                                        </div>
                                        <div class="contents2 ps-1 py-1 modelLabel">
                                            Project Code
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            Packaging Contact
                                        </div>
                                        <div class="contents2 ps-1 py-1 h-85">
                                            E-mail Address
                                        </div>
                                        <div class="contents2 ps-1 py-1 h-85">
                                            Phone Number
                                        </div>
                                    </div>
                                    <div class="col-md-5  rowClass2">                                        
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venDate'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venName'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center " >
                                            <span>{{form0.controls['venFOB'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center " >
                                            <span>{{form0.controls['venCode'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venProject'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venSubName'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venSubEmail'].value}}</span>
                                        </div>
                                        <div class="ps-2 py-1 d-flex align-items-center ">
                                            <span>{{form0.controls['venSubPhone'].value}}</span>
                                        </div>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </table>
                </div>
                <div class="card col-lg-4 col-md-6 col-12 m-1 flex-wrap" >
                    <div class="card4-header px-3 py-2">
                        <span>KEY INPUTS</span>
                    </div>                   
                    <table style="width: 100%;" aria-describedby="key-inputs">
                        <tr class="d-none"><th>key-inputs</th></tr>
                        <div class="row  mt-2 mx-1 heading-row m-1 py-2 px-2 table-content">
                            <table aria-describedby="key-inputs" class="table">
                                <tr class="d-none"><th>key-inputs</th></tr>
                                <tbody class="d-flex">
                                    <div class="col-md-9 rowClass2">
                                        <div class="contents2 ps-1 py-1">
                                            Hourly Labor Rate (Direct TM Labor Rate ONLY)
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            Weight of Unit Pack Part & Packaging (g)
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            Pieces Per Unit Package
                                        </div>
                                        <div class="contents2 ps-1 py-1 modelLabel">
                                            Units Per Each Intermediate Pack
                                        </div>
                                        <div class="contents2 ps-1 py-1 modelLabel">
                                            Total Intermediate PACKS per Masterpack or Pallet
                                        </div>
                                        <div class="contents2 ps-1 py-1">
                                            Total Units per Masterpack or Pallet
                                        </div>
                                        <div class="contents2 ps-1 py-1 h-85">
                                            Total Unit Package Fill % (Goal=90%)
                                        </div>
                                        <div class="contents2 ps-1 py-1 h-85">
                                            TOTAL Case Weight (KG) ("<"362KG)
                                        </div>
                                    </div>
                                    <div class="col-md-3  rowClass2">
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyLaborRate" (keydown)="numValidatorForm($event,false,true)" (input)="keyLaborRateChange();KeyLaborRate()"formControlName="keyLaborRate"> 
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{form0.controls['keyLaborRate'].value}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyWeight"(input)="getTotalCaseWeight()" formControlName="keyWeight"> 
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyWeight}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyUnitPackage" (keydown)="numValidatorForm($event,false,true)" (input)="keyUnitPackageChange()"formControlName="keyUnitPackage">
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{form0.controls['keyUnitPackage'].value}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "  *ngIf="mode === 1">
                                            <input type="text" id="keyInterPack" formControlName="keyInterPack">
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyInterPack}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyMasterpack" (keydown)="numValidatorForm($event,false,true)" (input)="keyMasterPackFun()" formControlName="keyMasterpack">
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyMasterpack}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyPallet" (keydown)="numValidatorForm($event,false,true)" (input)="keyPalletfun();" formControlName="keyPallet">
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyPallet}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyPackageFill" formControlName="keyPackageFill" >
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyPackageFill}}</span>
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center"  *ngIf="mode === 1">
                                            <input type="text" id="keyCaseWeight" formControlName="keyCaseWeight" >
                                        </div>
                                        <div class="ps-4 py-1 d-flex align-items-center "   *ngIf="mode === 2">
                                            <span>{{keyCaseWeight}}</span>
                                        </div>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </table>
                </div>
                <div class="card col-lg col-md-6 col-12 m-1 flex-wrap" >
                    <div class="card5-header  px-2 py-2">
                        <div class="col-md-8  d-flex">
                            TOTAL UNIT PACKAGING COST(PER PIECE)
                        </div>
                        <div class="col-md-4 ps-4  d-flex">
                            <span>{{totalUnitPackagingCost}}</span>
                        </div>
                    </div>                    
                    <div class="row  mt-2 mx-1 heading-row m-1 py-2 px-2 table-content">
                        <table aria-describedby="Unit Packaging" class="table">
                            <tr class="d-none"><th>Unit Packaging</th></tr>
                            <tbody class="d-flex">
                                <div class="col-md-8 rowClass2">
                                    <div class="contents2 ps-1 py-1">
                                        Haz-Mat or Dangerous Goods(DG)?
                                    </div>
                                    <div *ngIf="!hazmatPKG" class="contents2 ps-1 py-2">
                                        Haz-Mat PKG Approval - 
                                        Completed by Haz-Mat Compliance TM
                                    </div>
                                    <div class="contents2 ps-1 py-2"></div>
                                    <div class="contents2 ps-1 py-2">
                                        Final PKG Approval - 
                                        Completed by PKG Engineer
                                    </div>
                                    <div class="contents2 ps-1 py-2"></div>
                                    <div class="contents2 ps-1 py-1 modelLabel">
                                        Units per Masterpack Estimate
                                    </div>
                                    <div class="contents2 ps-1 py-1 modelLabel">
                                        Total Material Cost Per UNIT
                                    </div>
                                    <div class="contents2 ps-1 py-1">
                                        Total Labor Cost Per UNIT
                                    </div>
                                    
                                </div>
                                <div class="col-md-4  rowClass2">
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                        <mat-select id="plant" class="mat-sel pt-1"  placeholder="Select" formControlName="totalDangerousGoods" (selectionChange)="hazmatDangHide(); onRequestChange()">                                           
                                            <mat-option *ngFor="let x of hazmatdropdown" [value]="x.hazmatValue">{{x.name}}</mat-option>
                                        </mat-select> 
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span>{{form0.controls['totalDangerousGoods'].value}}</span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 1 && !hazmatPKG">
                                        <input type="text"  id="totalComplianceTM" formControlName="totalComplianceTM" >
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2 && !hazmatPKG">
                                        <span>{{totalComplianceTM}}</span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span></span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                        <input type="text" id="totalPKGEngineer" formControlName="totalPKGEngineer" >
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span>{{totalPKGEngineer}}</span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span></span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                        <input type="text" id="totalMasterpackEst" formControlName="totalMasterpackEst" >
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span>{{totalMasterpackEst}}</span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                        <input type="text" id="totalMaterial" formControlName="totalMaterial" >
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span>{{totalMaterial}}</span>
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center" *ngIf="mode === 1">
                                        <input type="text" id="totalLabour" formControlName="totalLabour" >
                                    </div>
                                    <div class="ps-4 py-1 d-flex align-items-center " *ngIf="mode === 2">
                                        <span>{{totalLabour}}</span>
                                    </div>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                    
            <mat-accordion class="example-headers-align mt-2" hideToggle multi>
                <mat-expansion-panel [expanded]="true" #panel1 class="mat-elevation-z0 mt-2 mb-2">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="fw-bold">TMS PART</mat-panel-title>
                        <button *ngIf="mode===1" class="help-btn me-4" (click)="addPart($event)">Add Part</button>
                        <mat-icon class="toggleIcon">{{panel1.expanded ? 'remove':'add'}}</mat-icon>
                    </mat-expansion-panel-header>
                    <!-- grid1  -->                    
                    <div class="d-flex mt-2 table-container mb-2">
                        <div class="w-100" [formGroup]="form1">
                            <table class="tg w-100 table6" aria-describedby="table11">
                                <thead>
                                    <tr class="tg-mg6y">
                                        <th *ngFor="let header of headers1" [style.width.px]="header.width">{{ header.label }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of rows1; let i = index">
                                        <td *ngFor="let col of columns1 let j = index"
                                           >
                                            <ng-container *ngIf="(mode===1) && (j !== 10) && (( j !== 3) || ((i!==1) && (i!==0)))">
                                                <input type="text" class="centerText inputTms"
                                                    [style.width]="(j===2) ? '150px' : '90px'"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="onInputChange1()" (keydown)="numValidatorForm($event,false,false,0,data.length,4,8)" (input)="value1Change()"/>
                                            </ng-container>
                                            <ng-container *ngIf="(mode===1) && (j === 10)">
                                                <button *ngIf="deleteArray[i]" class="help-btn me-4" (click) = onPartDelete(i)>DELETE</button>
                                            </ng-container>
                                            <ng-container *ngIf="((j === 3 && (i===1 || i===0))) && (mode===1)">
                                                <mat-select placeholder="Select" class="mat-sel centerText"
                                                    name="homePositinDrop" id="homePositinDrop"  [formControlName]="'row' + i + 'col' + j">
                                                    <mat-option *ngFor="let home of homePositinDrop; let i = index;"
                                                        [value]="home.statusValue">{{ home.name }}</mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="mode===2" >
                                                <div class="centerText inputTms" [style.width]="(j===0 || j===2) ? '240px' : ''" >
                                                    {{ data[i]['col' + j ] }}
                                                </div>           
                                            </ng-container>                                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                   
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0 mt-2 mb-2">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="fw-bold">UNIT PACKAGING</mat-panel-title>
                        <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
                    </mat-expansion-panel-header>
                <!-- grid2 -->
                <div class="d-flex mt-2 table-container">
                    <div class="w-100" [formGroup]="form2">
                        <table class="tg w-100 table6" aria-describedby="table12">
                            <thead>
                                <tr class="tg-mg6y">
                                    <th *ngFor="let header of headers2" [attr.rowspan]="header.rowspan"
                                        [attr.colspan]="header.colspan" [style.width.px]="header.width">{{ header.label }}</th>
                                </tr>
                                <tr class="tg-mg6y">
                                    <td *ngFor="let sub of subHeader" [style.width.px]="sub.width">{{sub.label}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows2; let i = index">                                    
                                    <ng-container *ngFor="let col of getitertor2(i); let j = index">
                                        <td *ngIf="j!=0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'"
                                            [attr.colspan]="(j===1 && (i==6 || i===7))?6:1 ">
                                            <ng-container *ngIf="((j !== 0 && (( j !== 1) || (i!==0 && i!==6 && i!=7)))) && (mode===1)">
                                                <input type="text" class="centerText inputTms"
                                                    [style.width]="((j===2 || j===1) &&( i===0 || i===1 || i===2 || i===3 || i===4 || i===5)) ? '200px' : ''"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="onInputChange2()" (keydown)="numValidatorForm($event,true,false,0,9,3,10)" (input)=" masterHeavyCaseLabel(); palletHeavyCaseLabel(); onInputChange1()"/>
                                            </ng-container>                                           
                                            <ng-container *ngIf="((j === 1 && (i===0))) && (mode===1)">
                                                <mat-select  placeholder="Select" class="mat-sel"
                                                    name="unitPackageType" id="*unitinterPackageType"  [formControlName]="'row' + i + 'col' + j" (selectionChange)="onInputChange2()">
                                                    <mat-option *ngFor="let pkg of unitDropdown(i); let i = index;"
                                                        [value]="pkg.value">{{ pkg.name }}</mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="(j==1 && (i===6 || i===7)) && (mode===1)">
                                                {{ unitTable[i]['col' + j ] }}
                                            </ng-container>
                                            <ng-container *ngIf="mode===2" >
                                                <div [style.float]="j === 0 ? 'left' : ''">
                                                    {{ unitTable[i]['col' + j ] }}
                                                </div>                                               
                                            </ng-container>    
                                        </td>
                                        <td *ngIf="j===0" [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'"
                                            [attr.colspan]="(j===0 && (i==8 || i===9 || i===10))?7:1 ">                                           
                                            <ng-container *ngIf="(j === 0)">
                                                <div [style.float]="j === 0 ? 'left' : ''">
                                                    {{ unitTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="grid1 d-flex mb-2">
                    <table style="width:100%" class="table5" aria-describedby="table5">
                        <tr>
                            <th *ngIf="!arrayFlag" colspan="3" style="width:50%;" class="unitPkgfloor">Floor Mat Only</th>
                            <th colspan="4" style="width: 50%;" class="unitSubTotal">Sub Totals</th>
                        </tr>
                        <tr>
                            <td *ngIf="!arrayFlag" class="unitPkgfloor">Driver side Mat Placed on Top Warning Label Fully Visible? </td>
                            <td *ngIf="!arrayFlag" class="unitPkgfloor">Floor Mat Safety Check Insert Placed Inside Unit Packaging?</td>
                            <td *ngIf="!arrayFlag" class="unitPkgfloor">Retention Clip Hardware Bag Attached To Driver Side Mat Close To Grommet?</td>
                            <td class="unitSubTotal">Labor Cost($)</td>
                            <td class="unitSubTotal">Labor Time(sec)</td>
                            <td class="unitSubTotal">Material Cost($)</td>
                            <td class="unitSubTotal">ONE Unit Package Cost($)</td>
            
                        </tr>
                        <tr>
                            <td *ngIf="!arrayFlag">
                                <ng-container *ngIf = "mode===1">
                                    <mat-select class="mat-sel" name="tickMark1" id="tickMark1"
                                        placeholder="Select" formControlName="unitfloormat1">
                                        <mat-option value="tick">&#10004;</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2 && unitfloormat1 === 'tick'">
                                    <span>&#10004;</span>
                                </ng-container>
                            </td>
                            <td *ngIf="!arrayFlag">
                                <ng-container *ngIf = "mode===1">
                                    <mat-select class="mat-sel pt-1" name="tickMark2" id="tickMark2"
                                        placeholder="Select" formControlName="unitfloormat2">
                                        <mat-option value="tick">&#10004;</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2 && unitfloormat2 === 'tick'">
                                    <span>&#10004;</span>
                                </ng-container>
                            </td>
                            <td *ngIf="!arrayFlag">
                                <ng-container *ngIf = "mode===1">
                                    <mat-select class="mat-sel pt-1" name="tickMark3" id="tickMark3" placeholder="Select"  formControlName="unitfloormat3">
                                        <mat-option value="tick">&#10004;</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2 && unitfloormat3 === 'tick'">
                                    <span>&#10004;</span>
                                </ng-container>
                            </td>
                            <td>${{unitlaborcost}}</td>
                            <td>{{unitlabortime}}</td>
                            <td>${{unitmaterialcost}}</td>
                            <td>${{unitpkgcost}}</td>
                        </tr>
                    </table>
                </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0 mt-2 mb-2">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="fw-bold">INTERMEDIATE PACKS</mat-panel-title>
                        <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
                    </mat-expansion-panel-header>
                <!-- grid3 -->
                <div class="d-flex mt-2  table-container">
                    <div class="w-100" [formGroup]="form3">
                        <table class="tg w-100" aria-describedby="table13">
                            <thead>
                                <tr class="tg-mg6y">
                                    <th *ngFor="let header of headers3" [attr.rowspan]="header.rowspan"
                                        [attr.colspan]="header.colspan" [style.width.px]="header.width">{{ header.label }}</th>
                                </tr>
                                <tr class="tg-mg6y">
                                    <td *ngFor="let sub of subHeader" [style.width.px]="sub.width">{{sub.label}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows3; let i = index">                                    
                                    <ng-container *ngFor="let col of getitertor3(i); let j = index">
                                        <td [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'"
                                            [attr.colspan]="(j===1 && i==4)?6:1 ">
                                            <ng-container *ngIf="(j !== 0 && (( j !== 1) || (i!==0 && i!==4))) && (mode === 1)">
                                                <input type="text" class="centerText inputTms"
                                                    [style.width]="((j===2 || j===1) &&( i===0 || i===1 || i===2 || i===3)) ? '200px' : ''"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="onInputChange3()" (keydown)="numValidatorForm($event,false,false,0,3,3,9)" (input)=" masterHeavyCaseLabel(); palletHeavyCaseLabel()"/>
                                            </ng-container>
                                            <ng-container *ngIf="(mode === 2)">
                                                <div [style.float]="j === 0 ? 'left' : ''">
                                                    {{ intermediateTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="j === 0 && (mode === 1)">
                                                <div style="float: left;">
                                                    {{ intermediateTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="((j === 1 && (i===0))) && (mode === 1)">
                                                <mat-select class="mat-sel" name="interPackageType"
                                                    id="*interPackageType" placeholder="Select" [formControlName]="'row' + i + 'col' + j" (selectionChange)="onInputChange3()">
                                                    <mat-option *ngFor="let pkg of interDropdown(i); let i = index;"
                                                        [value]="pkg.value" (selectionChange)="onInputChange3()">{{ pkg.name }}</mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="(j==1 && (i===4)) && (mode === 1)">
                                                {{ intermediateTable[i]['col' + j ] }}
                                            </ng-container>                                            
                                        </td>   

                                    </ng-container>                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="grid1 d-flex mb-2">
                    <table style="width:100%" class="table5" aria-describedby="unitSubTotal">
                        <tr>
                            <th colspan="4" style="width: 50%;" class="unitSubTotal">Sub Totals</th>
                        </tr>
                        <tr>
                            <td class="unitSubTotal">Labor Cost($)</td>
                            <td class="unitSubTotal">Labor Time(sec)</td>
                            <td class="unitSubTotal">Material Cost($)</td>
                            <td class="unitSubTotal">ONE Intermediate Package Cost($)</td>
                        </tr>
                        <tr>
                            <td>${{interlaborcost}}</td>
                            <td>{{interlabortime}}</td>
                            <td>${{intermaterialcost}}</td>
                            <td>${{interpkgcost}}</td>
                        </tr>
                    </table>
                </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="masterhide" [expanded]="true" #panel4 class="mat-elevation-z0 mt-2 mb-2">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="fw-bold">MASTERPACKS</mat-panel-title>
                        <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
                    </mat-expansion-panel-header>
                <!-- grid4 -->
                <div class="d-flex mt-2 table-container">
                    <div class="w-100" [formGroup]="form4">
                        <table class="tg w-100" aria-describedby="table3">
                            <thead>
                                <tr class="tg-mg6y">
                                    <th *ngFor="let header of headers4" [attr.rowspan]="header.rowspan"
                                        [attr.colspan]="header.colspan" [style.width.px]="header.width">{{ header.label }}</th>
                                </tr>
                                <tr class="tg-mg6y">
                                    <td *ngFor="let sub of subHeader" [style.width.px]="sub.width">{{sub.label}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows4; let i = index">
                                    <ng-container *ngFor="let col of getitertor4(i); let j = index">
                                        <td [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'"
                                            [attr.colspan]="(j===1 && (i==5 || i===6))?6:1 ">
                                            <ng-container *ngIf="(j !== 0 && (( j !== 1) || (i!==0 && i!==1 && i!==5 && i!==6))) && (mode === 1)">
                                                <input type="text" class="centerText inputTms"
                                                    [style.width]="((j===2 || j===1) &&( i===0 || i===1 || i===2 || i===3 || i===4 )) ? '200px' : ''"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="onInputChange4()" (keydown)="numValidatorForm($event,false,false,1,4,3,9)" />
                                            </ng-container>
                                            <ng-container *ngIf="(j === 0) && (mode === 1)">
                                                <div style="float: left;">
                                                    {{ masterTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="mode === 2">
                                                <div [style.float]="j === 0 ? 'left' : ''">
                                                    {{ masterTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="(j === 1 && (i===0 || i===1)) && mode === 1">
                                                <mat-select class="mat-sel" name="masterPackageType"
                                                    id="masterPackageType" placeholder="Select" [formControlName]="'row' + i + 'col' + j" (selectionChange)="onInputChange4();clearMasterDropdown1();">                                                    
                                                    <mat-option *ngFor="let pkg of masterDropdown(i); let i = index;"
                                                        [value]="pkg.value">{{ pkg.name }}</mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="(j==1 && (i===5 || i===6)) && mode === 1">
                                                {{ masterTable[i]['col' + j ] }}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="grid1 d-flex  table-container mb-2">
                    <table style="width:100%" class="table5" aria-describedby="pallet">
                        <tr>
                            <th rowspan="3" class="pConfirmHead">Confirm Certified Heat Treated Pallet</th>
                            <th rowspan="3" class="pConfirmHead">
                                <ng-container *ngIf="mode===1">
                                    <mat-select class="mat-sel pt-1" name="palletDropdown" id="palletDropdown"
                                        placeholder="Select" formControlName="masterheatpallet" (selectionChange)="masterValidation()">
                                        <mat-option value="YES">YES</mat-option>
                                        <mat-option value="N/A">N/A</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2">
                                    <span>{{form0.controls['masterheatpallet'].value}}</span>
                                </ng-container>
                            </th>
                            <th rowspan="3" class="pConfirmHead">Confirm 4-Way Flush Block Pallet</th>
                            <th rowspan="3" class="pConfirmHead">
                                <ng-container *ngIf="mode===1">
                                    <mat-select class="mat-sel pt-1" name="masterpkg" id="masterpkg"
                                        placeholder="Select" formControlName="masterflushpallet" (selectionChange)="masterValidation()">
                                        <mat-option value="YES">YES</mat-option>
                                        <mat-option value="N/A">N/A</mat-option>
                                        <mat-option value="NO">NO</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2">
                                    <span>{{form0.controls['masterflushpallet'].value}}</span>
                                </ng-container>
                            </th>
                            <th colspan="4" class="pSubtotal" style="width: 50%;">Sub Totals</th>
                        </tr>
                        <tr>
                            <td class="pSubtotal">Labour Cost($)</td>
                            <td class="pSubtotal">Labour Time(sec)</td>
                            <td class="pSubtotal">Material Cost($)</td>
                            <td class="pSubtotal">ONE Masterpack Cost($)</td>
                        </tr>
                        <tr>
                            <td>${{masterlaborcost}}</td>
                            <td>{{masterlabortime}}</td>
                            <td>${{mastermaterialcost}}</td>
                            <td>${{masterpkgcost}}</td>
                        </tr>
                    </table>
                </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="pallethide" [expanded]="true" #panel5 class="mat-elevation-z0 mt-2 mb-2">
                    <mat-expansion-panel-header class="header-container">
                        <mat-panel-title class="fw-bold">PALLETS</mat-panel-title>
                        <mat-icon class="toggleIcon">{{panel5.expanded ? 'remove':'add'}}</mat-icon>
                    </mat-expansion-panel-header>
                <!-- grid5 -->
                <div class="table-container d-flex mt-2">
                    <div class="w-100" [formGroup]="form5">
                        <table class="tg w-100" aria-describedby="table31">
                            <thead>
                                <tr class="tg-mg6y">
                                    <th *ngFor="let header of headers5" [attr.rowspan]="header.rowspan"
                                        [attr.colspan]="header.colspan" [style.width.px]="header.width">{{ header.label }}</th>
                                </tr>
                                <tr class="tg-mg6y">
                                    <td *ngFor="let sub of subHeader" [style.width.px]="sub.width">{{sub.label}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows5; let i = index">                                    
                                    <ng-container *ngFor="let col of getitertor5(i); let j = index">
                                        <td [ngClass]="[0].includes(col) ? 'tg-mg6y' : 'tg-z5to'"
                                            [attr.colspan]="(j===1 && (i==6 || i===7))?6:1 ">
                                            <ng-container *ngIf="(j !== 0 && (( j !== 1) || (i!==0 && i!==6 && i!==7))) && mode === 1">
                                                <input type="text" class="centerText inputTms"
                                                    [style.width]="((j===2 || j===1) &&( i===0 || i===1 || i===2 || i===3 || i===4 || i===5 ))   ? '200px' : ''"
                                                    [formControlName]="'row' + i + 'col' + j"
                                                    (change)="onInputChange5()" (keydown)="numValidatorForm($event,false,false,0,5,3,9)" (input)="masterHeavyCaseLabel()"/>
                                            </ng-container>
                                            <ng-container *ngIf="(mode === 2)">
                                                <div [style.float]="j === 0 ? 'left':''">
                                                    {{ palletTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="(j === 0) && (mode === 1)">
                                                <div style="float: left;">
                                                    {{ palletTable[i]['col' + j ] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="(j === 1 && (i===0)) && mode === 1">
                                                <mat-select class="mat-sel" name="palletPackageType"
                                                    id="palletPackageType" placeholder="Select" [formControlName]="'row' + i + 'col' + j" (selectionChange)="onInputChange5()">                                                    
                                                    <mat-option *ngFor="let pkg of palletDropdown(i); let i = index;"
                                                        [value]="pkg.value">{{ pkg.name }}</mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="j==1 && (i===6 || i===7) && mode === 1">
                                                {{ palletTable[i]['col' + j ] }}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="grid1 d-flex  table-container">
                    <table style="width:100%" class="table5" aria-describedby="pallet2">
                        <tr>
                            <th rowspan="3" class="pConfirmHead">Confirm Certified Heat Treated Pallet</th>
                            <th rowspan="3" class="pConfirmHead">
                                <ng-container *ngIf="mode===1">
                                    <mat-select class="mat-sel pt-1" name="ConfirmHeat" id="confirmHeat"
                                        placeholder="Select" formControlName="palletheatpallet" (selectionChange)="palletValidation()">
                                        <mat-option value="YES">Yes</mat-option>
                                        <mat-option value="N/A">N/A</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2">
                                    <span>{{form0.controls['palletheatpallet'].value}}</span>
                                </ng-container>
                            </th>
                            <th rowspan="3" class="pConfirmHead">Confirm 4-Way Flush Block Pallet</th>
                            <th rowspan="3" class="pConfirmHead">
                                <ng-container *ngIf="mode===1">
                                    <mat-select class="mat-sel pt-1" name="confirmWay" id="confirmWay"
                                        placeholder="Select" formControlName="palletflushpallet" (selectionChange)="palletValidation()">
                                        <mat-option value="YES">Yes</mat-option>
                                        <mat-option value="N/A">N/A</mat-option>
                                        <mat-option value="NO">NO</mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-container *ngIf = "mode===2">
                                    <span>{{form0.controls['palletflushpallet'].value}}</span>
                                </ng-container>
                            </th>
                            <th colspan="4" class="pSubtotal" style="width: 50%;">Sub Totals</th>
                        </tr>
                        <tr>
                            <td class="pSubtotal">Labour Cost($)</td>
                            <td class="pSubtotal">Labour Time(sec)</td>
                            <td class="pSubtotal">Material Cost($)</td>
                            <td class="pSubtotal">ONE Pallet Cost($)</td>
                        </tr>
                        <tr>
                            <td>${{palletlaborcost}}</td>
                            <td>{{palletlabortime}}</td>
                            <td>${{palletmaterialcost}}</td>
                            <td>${{palletpkgcost}}</td>
                        </tr>
                    </table>
                </div>
                </mat-expansion-panel>
            </mat-accordion>
            </div>
            <!-- images -->
            <div class="Package mt-1" style="width: 100%;">
                <h4 style="height: 25px;" class="mt-2">PACKAGE ILLUSTRATIONS(Photos, drawing or illustrations with dimensions)</h4>
            </div>
            <!-- dynamic images start-->
            <div class="ppf-content mt-1" id="PPF" #PPF="" >
                <div class="container-list d-flex flex-wrap">
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">BARE PART(Need seperate photo for each part)</h5>
                                <div class="img-cont col-12 d-flex px-3 gap-2">
                                    <div class=" col">
                                        <div class="img-preview col-12" style="height: 220px;">
                                            <div *ngIf="!image[0]"
                                                class="d-flex h-100 align-items-center justify-content-center" >
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[0])">
                                                <img *ngIf="image[0]" [src]="image[0]" alt="Bare Part" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload1" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,1)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload1" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(1)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[0]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[0]">{{formatError[0]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">Packaging Dunnage Around Part</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class=" col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[1]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[1])">
                                                <img *ngIf="image[1]" [src]="image[1]" alt="Packaging Dunnage Around Part" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload2" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,2)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload2" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(2)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[1]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[1]">{{formatError[1]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">Intermediate Packaging</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[2]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[2])">
                                                <img *ngIf="image[2]" [src]="image[2]" alt="Intermediate Packaging" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload3" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,3)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload3" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(3)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[2]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle"  *ngIf="mode === 1 && formatError[2]">{{formatError[2]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">Pallet or Case Load</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[3]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[3])">
                                                <img *ngIf="image[3]" [src]="image[3]" alt="Pallet or Case Load" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload4" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,4)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload4" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(4)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[3]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[3]">{{formatError[3]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">Unit and Intermediate Label</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[4]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[4])">
                                                <img *ngIf="image[4]" [src]="image[4]" alt="Unit and Intermediate Label" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload5" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,5)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload5" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(5)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle"  *ngIf="mode === 1 && !formatError[4]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[4]">{{formatError[4]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2"> Other Photos/Details</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[5]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon class="text-secondary">panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[5])">
                                                <img *ngIf="image[5]" [src]="image[5]" alt="Other" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload6" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,6)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload6" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(6)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle"  *ngIf="mode === 1 && !formatError[5]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle"  *ngIf="mode === 1 && formatError[5]">{{formatError[5]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card1 col-lg-3 col-md-6 col-6 m-2" style="height: 330px;width: 360px;">
                        <div class="d-flex img-upload">
                            <div class="col-12">
                                <h5 class="py-2">Use Below For additional part Photos if Needed</h5>
                                <div class="img-cont col d-flex px-3 gap-2">
                                    <div class="left col">
                                        <div class="img-preview" style="height: 220px;">
                                            <div *ngIf="!image[6]"
                                                class="d-flex h-100 align-items-center justify-content-center">
                                                <mat-icon >panorama</mat-icon>
                                            </div>
                                            <a target="_blank" (click)="openWindow(zoomImage[6])">
                                                <img *ngIf="image[6]" [src]="image[6]" alt="Additional Part" />
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center my-2 justify-content-between">
                                            <label *ngIf="mode === 1" for="file-upload7" class="vpacs-btn-table col-10"
                                                (change)="handleUpload($event,7)">
                                                CHOOSE FILE
                                                <input type="file" id="file-upload7" style="display: none;">
                                            </label>
                                            <img src="../../../../assets/images/noun_edit_1570019.svg" class="col-2"
                                                alt="D" *ngIf="mode === 1" class="vpacs-btn-table" (click)="handleDelete(7)" />
                                        </div>
                                    </div>
        
                                </div>
                                <div class="text-secondary px-4 subtitle" *ngIf="mode === 1 && !formatError[6]">Image size must
                                    be under 200KB. Extension must be .gif or .jpg</div>
                                <div class="text-danger px-4 subtitle" *ngIf="mode === 1 && formatError[6]">{{formatError[6]}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>
