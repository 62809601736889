import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ShipmentSelectBtnCellRender } from 'src/app/core/gridrender/shipment-select-btn-cell-render';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BackupAuthlistService } from 'src/app/internal-vpacs/services-internal/cost-management/backup-authlist/backup-authlist.service';
import { ExportStateService } from 'src/app/services/export-state.service';


@Component({
  selector: 'app-auth-select',
  templateUrl: './auth-select.component.html',
  styleUrls: ['./auth-select.component.scss'],
  
})
export class AuthSelectComponent implements OnInit  {
  [x: string]: any;
  plants:any ;
  filteredOptions:any;
  public slectAuth :any; 
  public rowData :any;
  rpPage=50
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any;
  icons: {};
  isAccessible = false;
  auth:any="";
  supplier:any="";
  part:any=""
  plant:any;
  modelCode:any=""
  supplierNumber:any="";
  supplierCode: any;
  pageName:any;
  specCode:any
  haveAccess:any;
  userInfo:any


  constructor(
    public http : HttpClient,  
    public route: ActivatedRoute,  
    public shipmentListService: ShipmentListService,
    public dialogRef:MatDialogRef<AuthSelectComponent>,
      @Inject(MAT_DIALOG_DATA) _data:any,
      private readonly spinnerService: NgxUiLoaderService,
    private readonly service: BackupAuthlistService,
    protected state: ExportStateService
    ) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,      
      suppressPaginationPanel:true,   
      autoHeight: true, 
      suppressHorizontalScroll: true, 
      sortable:true,    
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }
  
  ngOnInit(): void {
    const spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '');
    this.specCode = spCode
    this.setColumndefs()
     this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_pkgview;
          this.userInfo = res.info?.plantcode
          setTimeout(() => {
            this.spinnerService.start();
            this.haveAccess && this.loadPlants();
            !this.haveAccess && this.spinnerService.stop();
          }, 0);
         
        }
      },
    });
 }

 setColumndefs(){
  this.columnDefs = [
    {
     headerName: "Auth Number", field: "authnumber",minWidth: 140,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,
    },
    {
     headerName: "Plant", field: "plant",minWidth: 130,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
     },
    {
     headerName: "Model", field: "model",minWidth: 130,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
     },
     {
       headerName: "Supplier", field: "supplier",minWidth: 120,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
     },
    {
     headerName: "Part Number", field: "partnumber",minWidth: 120,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
    },
    {
     headerName: "Pkg Start Date", field: "pkgstartdate",minWidth: 140,maxWidth: 180,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
    },
    {
     headerName: "Dunnage1", field: "fabcode",minWidth: 120,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
    },
    {
     headerName: "Auth Type", field: "authtype",minWidth: 130,maxWidth: 150,floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, 
    },
    {
     headerName: "Select", field: "select",minWidth: 120,maxWidth: 150,filter: 'agTextColumnFilter',cellRendererFramework: ShipmentSelectBtnCellRender,
     cellRendererParams: {
       clicked: (field?: any) => {        
           this.dialogRef.close(field)      
         }
       }
    }     
  ];
 }

 loadPlants() {
  this.spinnerService.start();
  const plantObj: any = {
    SpecialistCode: this.specCode,
    Type: 2,
  };
  this.shipmentListService.getAllPlant(plantObj).subscribe({
    next: (res: any) => {
      this.plants = [ ...res];
      this.plant = this.plant ?? this.userInfo;
      this.filteredOptions = this.plants
      this.displayFn(this.plants)
      this.onSearch()
    },
    error: (_err: any) => {
      this.spinnerService.stop();
    }
  });
}

 filterOptions() {
    this.filteredOptions = this.plants.filter((option:any) => option.plant.toLowerCase().includes(this.plant.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants?.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

 sizeToFit() { this.gridApi?.sizeColumnsToFit() }  
  onSearch() {
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant.toLowerCase())))
    this.plant = plant[0]?.plantcode
    const params={
      "AuthNumber":  this.auth ?? "",
      "PlantCode": this.plant ? this.plant : "",
      "Model": this.modelCode ?? "",
      "SupplierCode": this.supplier ?? "",
      "Part1": this.part?.trim().slice(0,5) ?? "",
      "Part2": this.part?.trim().slice(5,10) ?? "",
      "sort": "",
      "limit": this.rpPage,
      "page": 1         
   }
        this.spinnerService.start()
        this.shipmentListService.addOnload(params).subscribe(
          { 
          next:(res:any)=> {     
        this.displayRowData = res;
        this.shipmentListService.setAuthData(res);
        this.rowCount = this.displayRowData.length
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      }
    });
  }
  
  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
  }  
}
