<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="header">
            <label class="mx-2">Legends</label>
        </div>
    </header>
    <div class="d-flex flex-wrap cont p-3" *ngIf="data==='tracking-list'">
        <div class="d-flex markers col-lg-2 col-md-4 col-6 m-2 align-items-center" *ngFor="let x of legends">
            <span class='{{x.css}}'>{{x.label}}</span>
            <div class="ps-2">{{x.desc}}</div>
        </div>
    </div>
    <div class="d-flex flex-wrap cont p-3" *ngIf="data==='auth-list'">
        <div class="d-flex markers col-5  align-items-center" *ngFor="let x of authLegends">
            <span class='{{x.css}}'>{{x.label}}</span>
            <div class="ps-2">{{x.desc}}</div>
        </div>
    </div>
    <div class="d-flex flex-wrap cont p-3" *ngIf="screenName==='ringiList'">
        <div class="d-flex markers col-5  align-items-center" *ngFor="let x of ringiLegend">
            <span class='{{x.css}}'>{{x.label}}</span>
            <div class="ps-2">{{x.desc}}</div>
        </div>
    </div>
    <div class="d-flex flex-wrap cont p-3" *ngIf="authorizeName==='purDecision-List'">
        <div class="d-flex markers col-3 m-3 align-items-center" *ngFor="let x of purDecisionList">
            <span class='{{x.css}}'>{{x.label}}</span>
            <div class="ps-2">{{x.desc}}</div>
        </div>
    </div>
</div>