import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver-es';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { DlstatusService } from 'src/app/services/dlstatus.service';

@Component({
  selector: 'app-dlstatus',
  templateUrl: './dlstatus.component.html',
  styleUrls: ['./dlstatus.component.scss']
})
export class DlstatusComponent implements OnInit {

  public supplierCode!:any ;
  disableButtons = false;

  constructor(public dialogRef: MatDialogRef<DlstatusComponent>, 
              public downloadDlStatus: DlstatusService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public message: MatSnackBar
              ) { }

  ngOnInit(): void {
    if(!this.data.fromDate && this.data.supplierCode.supplierCode.length > 0){
      this.disableButtons = true;
    }
    this.supplierCode = this.data.supplierCode;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }
  //production status download
  downloadProduction() {   
    if(this.supplierCode){
      let data:Blob;
      const body = this.getBody();
      this.downloadDlStatus.productionStatusDownload(body).subscribe({
        next: (response) =>{
          if(response.body){
            const filename = 'Production_status'
            data = new Blob([response.body], { type: 'text/csv; charset=utf-8' });    
            FileSaver.saveAs(data, filename); 
            this.openMessageBox('File downloaded Successfully', 'success')
          }
        },
        error: ()=>{
          this.openMessageBox('Error downloading the file', 'error')
        },
        complete : ()=>{
          this.onNoClick();
        }
      })
    }else{
      this.openMessageBox('Cannot download status without suppliercode', 'error')
    }
  }

  dateConverter(input:any){
    if(![null,undefined,'','null','undefined'].includes(input)){
      return new Date(input).toISOString().slice(0,10).replace(/-/g, '/');
    }
    return null
  }

  getBody(){
    const body = {
      "supplierCode": this.data.supplierCode.supplierCode,
      "fromdate": this.dateConverter(this.data.fromDate),
      "todate": this.dateConverter(this.data.todate),
    }
    return body
  }

  //backup status download
  downloadBackup() {   
    if(this.supplierCode){
      let data:Blob;
      const body = this.getBody();
      this.downloadDlStatus.backUpStatusDownload(body).subscribe({
        next: (response) =>{
          if(response.body){
            const filename = 'BackUp_status'          
            data = new Blob([response.body], { type: 'text/csv; charset=utf-8' });    
            FileSaver.saveAs(data, filename); 
            this.openMessageBox('File downloaded Successfully', 'success')
          }
        },
        error: ()=>{
          this.openMessageBox('Error downloading the file', 'error')
        },
        complete : ()=>{
          this.onNoClick();
        }
      })
    }else{
      this.openMessageBox('Cannot download status without suppliercode', 'error')
    }
  }

}

  