import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-replace-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button
      class="vpacs-btn-table"
      style="height:30px"
      (click) ="this.btnClickedHandler($event)"
    >REPLACE</button>
  </div>
  `,
})

export class ReplaceCellRendererComponent implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(_$event:any){ 
    this.params.clicked(this.params.data);    
  }

}