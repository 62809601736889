import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-ppf-renderer',
  templateUrl: './update-ppf-renderer.component.html',
  styleUrls: ['./update-ppf-renderer.component.scss']
})
export class UpdatePpfRendererComponent {
  params: any;
  plantCode: any;
  specialistCode: any;
  isCnt = false;
  constructor(private readonly router: Router) { }

 
  agInit(params: any): void {
    this.params = params;
    this.specialistCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}');
    this.plantCode = JSON.parse(sessionStorage.getItem('plantCode') || '{}');
    if (params.data.rowHeader !== '') {
      if (Number(params.data.cnt) > 0) {
        this.isCnt = true;
      }
      else {
        this.isCnt = false;
      }
    }
  }

  navigate(){
    this.router.navigate(['/tracking-list',this.plantCode,this.specialistCode,1,1,1,30,1])
  }

}
