import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from 'src/app/services/constant.service';
import { catchError, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  URL = this.constantsService.INTERNAL;

  protected URL2 = ''

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) {
    this.URL2 = this.constantsService.MASTERCOST + 'userMaintainance/'
  }

  loadDataLog(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getDataLog', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadDataTask(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getDataTask', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadNecessaryAppItems(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getNecessaryApprovedItems', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadUpdatePPF(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getUpdatePPF', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadBackUpStatus(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getBackUpStatus', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadTrackingStatus(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getTrackingStatus', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  getDeptApprovedItems(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getDeptApprovedItems', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  downloadApproved(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/DownloadApproved', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  markAsUnRead(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/MarkAsUnRead', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  logDetails(params: any): Observable<any> {
    return this.http.post<any>(this.URL + 'myPage/getLogDetail', params, {
      headers: this.constantsService.internalHttpOptions,
    }).pipe(
      catchError(this.handleError)
    )
  }

  loadAllPlants(obj: any): Observable<any> {
    const URL = this.URL + 'common/getPlant';
    return this.http.post(URL, obj, { 
      headers: this.constantsService.internalHttpOptions, 
      responseType: 'json' 
    })
    .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || "Server Error");
  }
}
