import { Component } from '@angular/core'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ConstantsService } from 'src/app/services/constant.service'
@Component({
  selector: 'app-pur-router-list-cell-renderer',
  template: `
    <a (click)="openDialog()" style="text-decoration:underline">{{ text }}</a>
  `,
})
export class PurRouterLinkCellRenderer implements ICellRendererAngularComp {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly constants: ConstantsService
  ) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`)
  }

  private params: any
  text: any

  agInit(params: any): void {
    this.params = params
    if (params.colDef.headerName === 'Part Number') {
      if (typeof params.value === 'undefined' || params.value === null) {
        this.text=""
       }else{
       this.text = `${this.params?.data?.partnumber?.slice(0, 5)}-${this.params?.data?.partnumber?.slice(5)}`;
       }
    } else if (params.colDef.headerName === 'Auth#') {
      this.text = this.params.data.authnumber
    }
  }

  openDialog() {
    if (this.params.colDef.headerName === 'Part Number') {
      const plantCode = this.constants.getTypeFromPlantCode(this.params.data?.plantcode)
      if (plantCode === 'SERVICE') { 
        sessionStorage.setItem('partnumber',this.params?.data?.partnumber);
        this.router.navigate(['/pur-decision-list/serviceparts-pdf',this.params.data.ppfcode,2])
      } else if(plantCode === 'ASSY'){
        sessionStorage.setItem('partnumber',this.params?.data?.partnumber);
        this.router.navigate(['/pur-decision-list/accessoryParts-pdf',this.params.data.ppfcode,2])
      }
      else{
      this.router.navigate(['/pur-decision-list/ppf',this.params.data.ppfcode,2])
      }
    } else if (this.params.colDef.headerName === 'Auth#') {
      const auth = this.params.data.authnumber
      if (this.params.data.authtype === '10') {
        window.open(
          '/pur-decision-list/backup-auth-details?auth=' + auth,
        )
      } else {
        window.open(
          '/pur-decision-list/auth-link?auth=' + auth,
        )
      }
    }
  }
}
