import { Component } from '@angular/core'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'
@Component({
  selector: 'pkg-contact-btn-cell-renderer',
  template: `
    <button 
    class="vpacs-btn-table"
    style="height:30px"
    (click)="btnClickedHandler()"
    >CONTACT</button>    
  `,
})
export class PkgContactBtnCellRender implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`)
  }
  private params: any;
  agInit(params: any): void {
    this.params = params;    
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data)
  }
}
