<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">PUR Decision List</p>
    <div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
    <div *ngFor="let message of errorList" class="errormsg">
      <div class="message-container">
        <span class="message-text">{{ message }}</span>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
    </div>
  </div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
    Authority Error - Access denied
</div>
<div class="alert alert-danger m-3" *ngIf="errorMessage?.length > 0">
    <span>{{errorMessage}}</span>
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
    <div class="filter mx-1 d-flex">
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-4" (keyup.enter)="onSearch()">
            <label for="plants" class="col-4">Plant</label>
            <input type="text" id="plants" aria-label="text" matInput [(ngModel)]="plant" (input)="filterOptions()"
                placeholder="--Select Plant--" style="width: 100%;" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let x of filteredOptions" [value]="x.plantcode">{{ x.plant }}
                </mat-option>
            </mat-autocomplete>
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-4">
            <label for="shortname" class="col-4">Supplier</label>
            <input type="text" id="shortname" [(ngModel)]="shortname" style="width: 100%;" (keyup.enter)="onSearch()" />
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-4">
            <label for="partNumber" class="col-4">Part Number</label>
            <input type="text" id="partNumber" [(ngModel)]="partnumber" style="width: 100%;"
                (keyup.enter)="onSearch()" />
        </div>
        <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  me-5">
            <label for="specialist" class="col-4">Specialist</label>
            <input type="text" (keyup.enter)="onSearch()" style="width: 100%;" [(ngModel)]="specialistCode"
                [ngModelOptions]="{ standalone: true }" />
            <button mat-icon-button>
                <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
            </button>
        </div>
        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12">
            <button class="black-button me-4" style="height: 30px;" (click)="onSearch()">Search</button>
            <button class="submit black-button me-4 roboto-bold" style="height: 30px;"
                (click)="confirmPurDecision()">DO</button>
            <button class="vpacs-btn-table " style="width:30px;height:30px" (click)="openLegends()">?</button>
        </div>
    </div>
</div>


<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
    <div class="grid-agg tracking-summary grid-height-pur-decision-list mb-5">
        <ag-grid-angular style="width:100%;height:100%;" 
            class="ag-theme-balham" 
            (gridReady)="onGridReady($event);"
            (window:resize)="sizeToFit()" 
            [rowHeight]="50" 
            [animateRows]="true" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData" 
            [suppressCellSelection]="true"
            [suppressHorizontalScroll]="false" 
            [pagination]="true" 
            [paginationPageSize]="rpPage"
            [suppressPaginationPanel]="true" 
            [icons]="icons" 
            [headerHeight]="20"
            [floatingFiltersHeight]="50"
            [rowClassRules]="rowClassRules"
            [overlayLoadingTemplate]="overlayLoadingTemplate" 
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="domLayout"
            (filterChanged)="filterChanged($event)"
            [getRowClass]="getRowClass"
            [enableBrowserTooltips]="true"
            >

        </ag-grid-angular>
        <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
        </app-pagination>

    </div>

</div>



<router-outlet></router-outlet>