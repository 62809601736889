import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PartListSelectComponent } from '../../tracking-part-list/part-list-select/part-list-select.component';
import { DiscrepancyListService } from 'src/app/internal-vpacs/services-internal/tracking/discrepancy-list.service';
import { DiscrepancyAddComponent } from '../discrepancy-add/discrepancy-add.component';
import { DiscrepancyListRegpartsizeComponent } from '../discrepancy-list-regpartsize/discrepancy-list-regpartsize.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-discrepancy-list-add-part',
  templateUrl: './discrepancy-list-add-part.component.html',
  styleUrls: ['./discrepancy-list-add-part.component.scss']
})
export class DiscrepancyListAddPartComponent implements OnInit {
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  part1 = '';
  part2 = '';
  resPlant: any[] = [];
  selectedPlant: any[] = [];
  spCode: any;
  partnumber: any;
  fromdate: any;
  suppliercode: any;
  specialistcode: any;
  diffCount = 0;
  model = '';
  partlength = '';
  partwidth = '';
  partheight = '';
  partweight = '';
  partdesc = '';
  partrouting = '';
  preppf = '';
  pkgtrail = '';
  pkgauthorised = '';
  readinessdue = '';
  pkgstart = '';
  plantcode = '';
  qpu = '';
  currLength = '';
  currWidth = '';
  currHeight = '';
  currWeight = '';
  redo: any = 1;
  errorList: any;
  showRequiredMessage = false


  constructor(public dialogRef: MatDialogRef<DiscrepancyListAddPartComponent>,

    @Inject(MAT_DIALOG_DATA) public dialog: any, public selectdialog: MatDialog,
    private readonly spinnerService: NgxUiLoaderService, private readonly DiscrepancyListService: DiscrepancyListService,
    private readonly router: Router

  ) { }

  ngOnInit(): void {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '');
    this.specialistcode = this.spCode;
    this.partnumber = this.dialog.addData.partnumber;
    this.fromdate = this.dialog.addData.fromdate;
    this.suppliercode = this.dialog.addData.suppliercode;
    this.plantcode = this.dialog.addData.plantcode;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  selectSpecialist() {
    const dialogRef = this.selectdialog.open(PartListSelectComponent, {
      data: { spCode: this.specialistcode, all: this.resPlant }

    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistcode = result

    })
  }

  onLoad() {
    if (!this.model.trim() && !this.qpu.trim()) {
      this.showRequiredMessage = true
      return;
    } else {
      this.showRequiredMessage = false
    }
    {
      this.spinnerService.start();
      const updateObject = {
        "PartNumber": this.partnumber,
        "Length": this.partlength,
        "Width": this.partwidth,
        "Height": this.partheight,
        "Weight": this.partweight,
        "SupplierCode": this.suppliercode,
        "SpecialistCode": this.specialistcode,
        "ModelCode": this.model,
        "PlantCode": this.plantcode
      }

      this.DiscrepancyListService.onloadregPart(updateObject).subscribe({
        next: (res: any) => {
          this.spinnerService.stop();
          if (res.status === false) {

            this.errorList = '305-400:Not Found in Master [Model Code]'
            setTimeout(() => {
              this.errorList = ''
            }, 4000)
          }
          this.currLength = res?.CurrentPart && res?.CurrentPart[0]?.length;
          this.currWidth = res?.CurrentPart && res?.CurrentPart[0]?.width;
          this.currHeight = res?.CurrentPart && res?.CurrentPart[0]?.height;
          this.currWeight = res?.CurrentPart && res?.CurrentPart[0]?.weight;
          if ( res.Compare && (res.Compare[0] === 1 || res.Compare[1] === 1 || res.Compare[2] === 1 || res.Compare[3] === 1)) {
            this.diffCount += 1;
          }
          if (this.diffCount === 0) {
            this.nextBt();
          } else {
            this.addBtn();
          }
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },

      })
    }

  }

  addBtn() {
    localStorage.setItem('plantcode', this.plantcode);
    this.selectdialog.open(DiscrepancyListRegpartsizeComponent,
      {
        width: '500px',
        data: {
          model: this.model,
          partnumber: this.partnumber,
          partlength: this.partlength,
          partwidth: this.partwidth,
          partheight: this.partheight,
          partweight: this.partweight,
          suppliercode: this.suppliercode,
          partdesc: this.partdesc,
          partrouting: this.partrouting,
          preppf: this.preppf,
          pkgtrail: this.pkgtrail,
          pkgauthorised: this.pkgauthorised,
          readinessdue: this.readinessdue,
          pkgstart: this.pkgstart,
          qpu: this.qpu,
          specialistcode: this.specialistcode,
          currLength: this.partlength,
          currWidth: this.partwidth,
          currHeight: this.partheight,
          currWeight: this.partweight
        }
      })
    this.dialogRef.close();
  }

  nextBt() {
    localStorage.setItem('redo', this.redo);
    localStorage.setItem('plantcode', this.plantcode);
    this.selectdialog.open(DiscrepancyAddComponent, {
      width: '800px', data: {
        model: this.model,
        partnumber: this.partnumber,
        partlength: this.partlength,
        partwidth: this.partwidth,
        partheight: this.partheight,
        partweight: this.partweight,
        suppliercode: this.suppliercode,
        partdesc: this.partdesc,
        partrouting: this.partrouting,
        preppf: this.preppf,
        pkgtrail: this.pkgtrail,
        pkgauthorised: this.pkgauthorised,
        readinessdue: this.readinessdue,
        pkgstart: this.pkgstart,
        qpu: this.qpu,
        specialistcode: this.specialistcode,
        currLength: '',
        currWidth: '',
        currHeight: '',
        currWeight: ''
      }
    })
    this.dialogRef.close();
  }
  help() {
    this.dialogRef.close();
    this.router.navigate(['./master-help', 2, "Tracking", 27])
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'preppf') {
        this.preppf = '';
      }
      if (id === 'pkgtrail') {
        this.pkgtrail = ''
      }
      if (id === 'pkgauthorised') {
        this.pkgauthorised = ''
      }
      if (id === 'readinessdue') {
        this.readinessdue = ''
      }
      if (id === 'pkgstart') {
        this.pkgstart = ''
      }
    }
  }
}
