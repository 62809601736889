import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
import { RadioBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/shipment-list/radio-btn-cell-render';

@Component({
  selector: 'app-receivement-rack',
  templateUrl: './receivement-rack.component.html',
  styleUrls: ['./receivement-rack.component.scss']
})
export class ReceivementRackComponent implements OnInit {

  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {}
  gridOptions :any
  icons: {};
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  shipNumber="";
  fieldData: any = {};
  selected: any[]=[];
  pageName: any;
  shipPage: any;
  rackPage: any;
  dunnage= '';
  packageCode= '';

  constructor(public dialog:MatDialog,public route: ActivatedRoute,
    protected location: Location,public router: Router,public shipmentListService: ShipmentListService,) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
    
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit()   {
    this.route.params.subscribe((params: any) => {
      this.rackPage = params?.authnumber
      this.shipPage=params?.shipmentNumber
          this.apiCall()
    });
    
    this.columnDefs = [
      {
        headerName: "Serial Number", field: "serialnumber", width: 100
      },
      {
        headerName: "Receive", field: "receive",
        children:[
          {headerName: "OK", field: "ok",width:20,cellRendererFramework:RadioBtnCellRender,cellRendererParams:{
            fieldName:"ok",
            clicked:(field:any)=>{
             this.radioHandler(field)
            }
          }},
          {headerName: "NG", field: "ng",width:20,cellRendererFramework:RadioBtnCellRender,cellRendererParams:{
            fieldName:"ng",
            clicked:(field:any)=>{
              this.radioHandler(field)
            }
          }},
        ]
      },
      {
        headerName: "Reason", field: "reason",  width: 100
      }   
    ];   
  }
  radioHandler(field:any) {
    this.selected=[...this.selected,{...field}]
  }

sizeToFit() { this.gridApi?.sizeColumnsToFit() } 
apiCall(){
  const parms={
    "authNumber": "KY150513-0004",
    "shipmentNumber": "SKY150924-0001"
  }
  this.shipmentListService.rackRecv(parms).subscribe({
    next: (res: any) => {
      this.displayRowData=res.list
      this.dunnage=res.dunnage1
      this.packageCode=res.packageCode
    }})
}

  onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
  }
save(){
  const params={
     "update":this.selected
  }
  this.shipmentListService.okBtn(params).subscribe({ next: (_res: any) => {
}})
}
  cancel(_event:Event){
    this.location.back() 
   }
}
