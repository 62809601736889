import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from 'src/app/msal';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { createEmptyUserRoles, Specialist, UserRoles } from '../interface/LoginInterface';
import { AuthListService } from '../services-internal/cost-management/auth-list/auth-list.service';
import { BackupAuthlistService } from '../services-internal/cost-management/backup-authlist/backup-authlist.service';
import { InternalApprovementListService } from '../services-internal/tracking/internal-approvement-list/internal-approvement-list.service';
import { ModelListService } from '../services-internal/tracking/model-list.service';
import { TrackingListService } from '../services-internal/tracking/tracking-list/tracking-list.service';
import { HazmatApprovementListService } from '../services-internal/tracking/hazmat-approvement-list/hazmat-approvement-list.service';
import { TrackingSummaryService } from '../services-internal/tracking/tracking-summary.service';
import { RingiListService } from '../services-internal/cost-management/ringi-list.service';
import { PackageMaintananceService } from '../services-internal/master-maintenance/package-maintenance-service';
import { PartListService } from '../services-internal/tracking/part-list.service';
import { DiscrepancyListService } from '../services-internal/tracking/discrepancy-list.service';
import { PurDecListService } from '../services-internal/authorize/pur-dec-list.service';
import { UserMaintenanceService } from '../services-internal/master-maintenance/user-maintenance.service';
import { SupplierService } from '../services-internal/master-maintenance/supplier.service';

@Component({
  selector: 'app-internal-toolbar',
  templateUrl: './internal-toolbar.component.html',
  styleUrls: ['./internal-toolbar.component.scss']
})
export class InternalToolbarComponent implements AfterViewInit,OnDestroy {
  menuItems = [
    {label: 'Home', menuList:'Home' , link:'internal'},
    {label: 'Tracking', menuList:'tracking' , link:'tracking'},
    {label: 'Cost Management', menuList:'costManagement' , link:'tracking' },
    {label: 'Quantity Control', menuList:'quantityControl' , link:'tracking' },
    {label: 'Authorize', menuList:'authorize' , link:'tracking' },
    {label: 'Master Maintenance', menuList:'masterMaintenance' , link:'tracking' },
    {label: 'Standard Report', menuList:'standardReport' , link:'tracking' },
  ];
  scrollOffset = 0;
  maxScrollOffset = 0;
  itemWidth = 130;
  @ViewChild('carouselWrapper') carouselWrapper !: ElementRef;
  isOverflowing =  true ;
  userInfo !: Specialist
  userRole :UserRoles = createEmptyUserRoles();
  rolesFromLocal: any;
  @Input() item:any;
  @Output() changePageType:EventEmitter<any> = new EventEmitter();
  toyotaGUID: any;
  check = {
    tracking : false,
    hzapprovelist : false,
    cost : false,
    qty : false,
    decision : false,
    pur : false,
    pkg : false,
    user : false,
    doc : false
  }
  unauthorized:any;
  userInfoSubscriber:any;
  constructor( 
    private readonly state: ExportStateService, 
    private readonly constants:ConstantsService,
    public router:Router, 
    public authService:MsalService,
    private readonly iaService: InternalApprovementListService,
    private readonly hzService: HazmatApprovementListService,
    private readonly trackingListService: TrackingListService,
    private readonly modelListService: ModelListService,
    public backupAuthservice: BackupAuthlistService,
    public service:AuthListService,
    public trackingSummaryService: TrackingSummaryService,
    public ringiListService : RingiListService,
    private readonly packageService: PackageMaintananceService,
    private readonly partListService: PartListService,
    private readonly discrepancyService: DiscrepancyListService,
    private readonly purDesService: PurDecListService,
    private readonly userService: UserMaintenanceService,
    private readonly supplierService: SupplierService,
    private readonly pkgMainService: PackageMaintananceService,



    ){
      if(localStorage.getItem('toyotaGUID')){
        this.toyotaGUID = localStorage.getItem('toyotaGUID');
      }
  }
  ngOnDestroy(): void {
    this.userInfoSubscriber?.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateMaximumOffset();
      this.checkOverFlow();
      this.getRoles();
    });
  }

  getRoles(){
    this.state.roleState.subscribe((data:any) => {
        if (data) {
          this.userRole = data[0];
          this.rolesFromLocal = data;
          const userRoles = data;  
          const internalroles = [this.constants.intDev,this.constants.intProd,this.constants.intStage];
          if(internalroles.some((ele:any) => userRoles.includes(ele))){
            this.getUserData();
          }
        }
    })
  }

  getUserData(){
    this.userInfoSubscriber  = this.state.userInfoState.subscribe((data:any)=>{
      if(data && data.info && data.role){
        this.unauthorized = false;
        this.userInfo = data.info;
        this.check.tracking = data.role?.p_trackingview;
        this.check.hzapprovelist = data.role?.u_roles.includes("TMNA Hazmat Admin");
        this.check.cost = data.role?.p_costview;
        this.check.pkg = data.role?.p_pkgview;
        this.check.user = data.role?.p_userview;
        this.check.qty = data.role?.p_qtyview;
        this.check.decision = ["1","2"].includes(data.info?.title);
        this.check.pur = ["3","4","5"].includes(data.info?.title);
        this.check.doc = true;
      }else{
        this.unauthorized = true;
      }
    })
  }

  changeRole(type: any) {
    const index = this.rolesFromLocal.findIndex((x: any) => x === type);
    [this.rolesFromLocal[0], this.rolesFromLocal[index]] = [this.rolesFromLocal[index], this.rolesFromLocal[0]];
    localStorage.removeItem('roles');
    sessionStorage.removeItem('preferredRoles');
    localStorage.setItem('roles', JSON.stringify(this.rolesFromLocal));
    sessionStorage.setItem('preferredRoles', JSON.stringify(this.rolesFromLocal));
    if(this.constants.allRoles.includes(this.rolesFromLocal[0])){
      this.state.loginAsExternalUser(this.rolesFromLocal,this.toyotaGUID,true).then(()=>{
        const role = this.state.checkExternalAccess(this.rolesFromLocal[0]);
        this.changePageType.emit('external');
        if(role){
          this.router.navigate(['/admin-access']);
        }else{
          this.router.navigate(['/tracking']);
        }
      })
    }else{
      this.state.loginAsInternalUser(this.toyotaGUID,true).then(()=>{
        this.changePageType.emit('internal');
        this.router.navigate(['/internal']);
      })
    }
  }

  @HostListener('window:resize')
  onWindowResize(){
    this.calculateMaximumOffset();
  }

  calculateMaximumOffset(){
    const wrapperWidth =  this.carouselWrapper?.nativeElement.offsetWidth;
    this.maxScrollOffset = Math.max(0,(this.menuItems.length * this.itemWidth) - wrapperWidth);
  }
  checkButtonState(){
    this.calculateMaximumOffset()
    if(this.scrollOffset <= 0){
      this.scrollOffset = 0
    }
    if(this.scrollOffset >= this.maxScrollOffset){
      this.scrollOffset = this.maxScrollOffset
    }
    this.checkOverFlow();
  }
  checkOverFlow(){
    const wrapperWidth =  this.carouselWrapper?.nativeElement.offsetWidth;
    const menuItemsWidth = this.menuItems.length * this.itemWidth;
    this.isOverflowing = menuItemsWidth > wrapperWidth;
  }
  scrollLeft(){
    this.scrollOffset -= this.itemWidth;
    this.checkButtonState()
  }

  scrollRight(){
    this.scrollOffset += this.itemWidth;
    this.checkButtonState();
  }

  async logOut(){
    setTimeout(() => {
      sessionStorage.removeItem('idToken');
      sessionStorage.removeItem('exp');
      localStorage.removeItem('tracking');
      localStorage.removeItem('roles');
      localStorage.removeItem('toyotaGUID');
      this.router.navigate(['/']); 
      this.authService.logout();
    })
  }
  clearInternalFilter(){
    this.iaService.resetInternalFilters();
    this.iaService.resetInternalGridfilter();
  }
  clearHazmatFilter(){
    this.hzService.resethazmatFilters();
    this.hzService.resethazmatGridfilter();
  }
  clearTrackingFilter(){
    this.trackingListService.resetFilters();
    this.trackingListService.resetTrackingGridfilter();
    this.trackingListService.resetWorkTableData();
    this.trackingListService.trackingGridSate = {
      sort : null,
      filter : null,
      currentpage : 0,
      totalpage : 0,
      rowcount : 0,
      pagesize : 0
    }
    this.trackingSummaryService.clearTrackingSummaryFilters();
  }

  clearModelFilter(){
    const data = null;
    this.modelListService.setfilter(data);
    this.modelListService.setmodelFilters(data);
  }
  clearRetainedFilters()
  {
    this.service.clearAuthFilters();
    this.backupAuthservice.clearBackupAuthFilters();
    this.ringiListService.clearRingiListFilters();
    this.service.authGridSate = {
      sort : null,
      filter : null,
      currentpage : 0,
      totalpage : 0,
      rowcount : 0,
      pagesize : 0
    }
    this.backupAuthservice.baGridSate = {
      sort : null,
      filter : null,
      currentpage : 0,
      totalpage : 0,
      rowcount : 0,
      pagesize : 0
    }
  }
  clearPackageFilter(){
    this.packageService.resetSearchflag();
  }
  clearPartListFilter(){
    this.partListService.resetfilter();
    this.partListService.resetpartFilters();
  }
  clearDiscFilter(){
    const data = null;
    this.discrepancyService.setfilter(data);
    this.discrepancyService.setpartFilters(data);
  } 

  clearPurDecisionFilter(){
    this.purDesService.clearPutDecListFilters();
  }

  //Master Maintenance

  clearMasterMainFilter(){
    this.userService.resetOnLoadFlag()
    this.supplierService.resetOnLoadFlag()
    this.pkgMainService.resetSearchflag()
  }


}
