import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { PackageMaintenanceCostComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/package-maintenance-cost/package-maintenance-cost.component';
import { PackageMaintananceService } from '../../services-internal/master-maintenance/package-maintenance-service';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportStateService } from 'src/app/services/export-state.service';
import { PackageMaintainDeletePopComponentComponent } from '../../internal-vpacs/master-maintenance/package-maintain-delete-pop-component/package-maintain-delete-pop-component.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-package-cell-rendere',
  template: `
    <div class="d-flex align-items-center gap-1">
      <button
        class="vpacs-btn-table"
        style="height:30px;width:80px"
        (click)="btnClickedHandler($event)"
        [disabled]="disable"
      >
        {{ buttonName }}
      </button>
    </div>
  `,
})
export class PackageCellRenderer implements ICellRendererAngularComp {
  modifyAccess: any;
  deleteAccess: any;
  roles:any
  noFile = 'file not found';

  constructor(
    public message: MatSnackBar,
    private readonly service: PackageMaintananceService,
    public dialog: MatDialog,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
  ) { }

  disable = false

  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params}`
    );
  }

  private params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    this.updateValue()
    if (this.params.colDef.headerName === 'COST') {
      this.buttonName = 'COST';
    } else if (this.params.colDef.headerName === 'DRAW') {
      this.buttonName = 'DOWNLOAD';
      if (this.params.data.picdraw === null || this.params.data.picdraw === "") {
        this.disable = true
      }
    } else if (this.params.colDef.headerName === 'PHOTO') {
      this.buttonName = 'VIEW';
      if (this.params.data.picphoto === null || this.params.data.picphoto === "") {
        this.disable = true
      }
    } else if (this.params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
      this.updateValue();
    } else if (this.params.colDef.headerName === 'DELETE') {
      this.buttonName = 'DELETE';
      this.updateValue();
    }
  }

  updateValue() {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.roles=res
        this.modifyAccess = res.role?.p_pkgmodify;
        this.deleteAccess = res.role?.p_pkgdelete;
        if (this.params.colDef.headerName === 'UPDATE') {
          if (!this.modifyAccess) {
            this.disable = true
          } else {
            this.disable = false
          }
        }
        if (this.params.colDef.headerName === 'DELETE') {
          if (!this.deleteAccess) {
            this.disable = true
          } else {
            this.disable = false
          }
        }
      },
      error: (_err: any) => {
        this.deleteAccess = false;
        this.modifyAccess = false;
      }
    });
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  btnClickedHandler(_$event: any) {
    if (this.params.colDef.headerName === 'COST') {
      this.openDialog()
    } else if (this.params.colDef.headerName === 'DRAW') {
      this.clickDrawfun()
    } else if (this.params.colDef.headerName === 'PHOTO') {
      this.clickPhotoFun()
    } else if (this.params.colDef.headerName === 'UPDATE') {
      this.params.clicked(this.params.data);
    } else if (this.params.colDef.headerName === 'DELETE') {
      this.clickDeleteFun()
    }
  }

  openDialog() {
    this.dialog.open(PackageMaintenanceCostComponent, {
      width: '50%',
      data: {
        PackageCode: this.params.data.packagecode,
        PackageSupplierCode: '',
        roles: this.roles
      }
    });
  }

  clickDrawfun(){
    this.spinnerService.start();
    const data = {
      packageCode: this.params.data.packagecode,
      file: this.params.data.picdraw,
      type: 0,
    };

    this.service.getDrawPhoto(data).subscribe({
      next: (res: any) => {
        this.spinnerService.stop()
        if (res.body.includes('https')) {
         Promise.resolve(window.open(res.body)).then((_res)=>{
          this.params.context.componentParent.Onsuccess('file downloaded successfully');
         }).catch((_res)=>{
          this.params.context.componentParent.onDelete(this.noFile);
         })
        } else {
          this.params.context.componentParent.onDelete(this.noFile);
        }
      },
      error: () => {
        this.spinnerService.stop()
        this.params.context.componentParent.onDelete(this.noFile);
      },
      complete: () => {
        this.spinnerService.stop()
      }
    })
  }

  clickPhotoFun() {
    this.spinnerService.start()
    const data = {
      packageCode: this.params.data.packagecode,
      file: this.params.data.picphoto,
      type: 1,
    };

    this.service.getDrawPhoto(data).subscribe({
      next: (res: any) => {
        this.spinnerService.stop()
        if (res.body.includes('https')) {
          Promise.resolve(window.open(res.body))
          .then(() => {
            this.params.context.componentParent.Onsuccess('file downloaded successfully');
          })
          .catch(() => {
            this.params.context.componentParent.onDelete(this.noFile);
          });
        } else {
          this.params.context.componentParent.onDelete(this.noFile);
        }
      },
      error: () => {
        this.spinnerService.stop()
        this.params.context.componentParent.onDelete(this.noFile);
      },
      complete: () => {
        this.spinnerService.stop()
      }
    })
}

  clickDeleteFun() {
    const data = {
      message: `Are you sure that you want to delete ${this.params.data.packagecode}`,
      buttonName: 'DELETE',
      headerName: 'Delete confirmation'
    };
    const dialogRef = this.dialog.open(PackageMaintainDeletePopComponentComponent, { data, height: '400px', width: '470px' });
    const payloadData = {
      PackageCode: this.params.data.packagecode,
    };
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result === 'success') {
          this.service.deletePackge(payloadData).subscribe({
            next: (response: any) => {
              this.spinnerService.stop();
              if (response.status === 'FAILURE') {
                this.params.context.componentParent.onDelete(response.message);
              } else {
                const successMessage = `${this.params.data.packagecode} deleted successfully`
                this.params.context.componentParent.Onsuccess(successMessage);
                this.params.context.componentParent.onSearch();
              }
            },
            error: (error: any) => {
              const error1 = error.error;
              this.params.context.componentParent.onDelete(error1.error);
              this.spinnerService.stop()
            },
          });
        } else {
          this.spinnerService.stop();
        }
      }
    })
  }

}
