import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalGuard, MsalService } from 'src/app/msal';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit,OnDestroy {
  dropToggle = false;
  adminActiveRoute = false;
  userRole: any;
  userInfo: any;
  adminFlag = false;
  pageType = '';
  rolesFromLocal: any;
  toyotaGUID: any;
  unAuthorized:any;
  supplierFlag = false;
  userInfoSubscriber: any;

  constructor(
    public router: Router,
    public state: ExportStateService,
    public userService: UsersService,
    public authService: MsalService,
    private readonly constants: ConstantsService,
    public msal : MsalGuard
  ) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (this.router.url === '/admin-access') {
            this.adminActiveRoute = true;
          } else {
            this.adminActiveRoute = false;
          }
        }
      }
    );
    if (localStorage.getItem('toyotaGUID')) {
      this.toyotaGUID = localStorage.getItem('toyotaGUID');
    }
  }
  ngOnDestroy(): void {
    this.userInfoSubscriber?.unsubscribe();
  }

  ngOnInit() {
    setTimeout(() => {
      this.getRoles();
    }, 1000);
  }

  changePageType(values: any) {
    this.pageType = values;
  }

  private startTimer(){
    if(sessionStorage.getItem('exp')){
      const exp:any = sessionStorage.getItem('exp');
      const currentTime:any = Date.now() / 1000;
      if(Number(exp) < currentTime) {
        this.msal.setUserInfo().subscribe({
          next : (_res:any) =>{
          }
        })
      }
    }
  }
  private resetTimerOnUserActivity() {
    document.addEventListener('click', this.resetTimer.bind(this));
  }
  private resetTimer() {
    this.startTimer();
  }

  getRoles() {
    this.state.roleState.subscribe((data:any) => {
      if (data) {
        this.unAuthorized = false;
        if(this.constants.vpacsAll.some((ele:any) => data.includes(ele))){
          const locRole = (JSON.parse(localStorage.getItem('roles') ?? ''));
          if(this.constants.allInternal.includes(locRole[0])){
            this.pageType = 'internal';
          }else{
            this.pageType = 'external';
            this.userInfoSubscriber  = this.state.userInfoState.subscribe((data:any)=>{
              if(data && this.pageType === 'external'){
                this.userInfo = data.info[0];
                if(this.userInfo?.ISADMIN===1 || data?.b_admin === true){
                  this.supplierFlag = false
                }
              }
            })
          }
          this.getUserData();
          this.userRole = data[0];
          this.rolesFromLocal = data;
          if((this.constants.adminSystemRoles.includes(this.rolesFromLocal[0]))){
            this.adminFlag = true;
          }else{
            this.adminFlag = false
          }
          if(this.constants.adminSupRoles.includes(this.rolesFromLocal[0])){
            this.supplierFlag = true;
          }else{
            this.supplierFlag = false;
          }
        }else{
          this.unAuthorized = true;
        }
      }
    })
  }

  getUserData() {
    this.state.userInfoState.subscribe((data: any) => {
      if (data && data.info) {
        this.unAuthorized = false;
        setTimeout(() => {
          this.userInfo = data.info[0];
          this.toyotaGUID = JSON.stringify(data.info[0]?.TOYOTAGUID); 
        });
        if((this.constants.adminSystemRoles.includes(this.rolesFromLocal[0]))){
          this.adminFlag = true;
        }else{
          this.adminFlag = false
        }
        if(this.constants.adminSupRoles.includes(this.rolesFromLocal[0]) || data.info[0]?.ISADMIN === "1" || data.b_admin){
          this.supplierFlag = true;
        }else{
          this.supplierFlag = false;
        }
      }else{
        this.unAuthorized = true;
      }
    })
  }

  changeRole(type: any) {
    const index = this.rolesFromLocal.findIndex((x: any) => x === type);
    [this.rolesFromLocal[0], this.rolesFromLocal[index]] = [this.rolesFromLocal[index], this.rolesFromLocal[0]];
    localStorage.removeItem('roles');
    sessionStorage.removeItem('preferredRoles');
    localStorage.setItem('roles', JSON.stringify(this.rolesFromLocal));
    sessionStorage.setItem('preferredRoles', JSON.stringify(this.rolesFromLocal));
    if(this.constants.allRoles.includes(this.rolesFromLocal[0])){
      this.state.loginAsExternalUser(this.rolesFromLocal,this.toyotaGUID,true).then(()=>{
        const role = this.state.checkExternalAccess(this.rolesFromLocal[0]);
        this.pageType = 'external';
          if(role){
            this.router.navigate(['/admin-access']);
          }else{
            this.router.navigate(['/tracking']);
          }
        })
        if((this.constants.adminSystemRoles.includes(this.rolesFromLocal[0]))){
          this.adminFlag = true;
        }
        if(this.constants.adminSupRoles.includes(this.rolesFromLocal[0])){
          this.supplierFlag = true;
        }
    }else{
      this.state.loginAsInternalUser(this.toyotaGUID,true).then(()=>{
        this.pageType = 'internal';
        this.router.navigate(['/internal']);
      })
    }
  }

  async logOut() {
    setTimeout(() => {
      sessionStorage.removeItem('idToken');
      sessionStorage.removeItem('exp');
      localStorage.removeItem('tracking');
      localStorage.removeItem('roles');
      localStorage.removeItem('toyotaGUID');
      this.router.navigate(['/']); 
      this.authService.logout();
    });
  }
}
