<div class="wrapper bg-white" [formGroup]="newShipmentForm">
    <div class="d-flex align-items-center mt-2 mb-2" [formGroup]="searchParameters">
        <p class="page-title p-1 px-3 pt-2 mb-1" *ngIf="pageName ==='Detail'">Shipment Detail</p>
        <p class="page-title p-1 px-3 pt-2 mb-1" *ngIf="pageName !=='Detail'">New Shipment</p>
        <label for="supplier" class="ms-5" *ngIf="pageName !== 'Detail'">Supplier</label>
        <mat-select formControlName="supplier" id="supplier" (valueChange)="valueChange($event)"
            class="ms-5 mat-sel" disableOptionCentering *ngIf="pageName !== 'Detail'">
            <mat-option *ngFor="let s of supplierCodeList; index as i" [value]="i">{{s}}</mat-option>
        </mat-select>
    </div>
    <div class="mat-typography content">
        <div class="vpacs-shadow mx-2 px-2 py-1">
        <div class="d-flex justify-content-between  align-items-center pb-3 px-3 align-items-center">
            <span class="fs-6">Shipment:- {{ShipNumber}}</span>
            <button class="vpacs-btn-table mx-4"  *ngIf="pageName !== 'Detail' || addFlag" (click)="addShipment()" >ADD</button>
        </div>
        <div class="grid-agg">
            <ag-grid-angular style="width:100%;height:200px;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
                [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
                [rowData]="displayRowData" [suppressCellSelection]="true" [suppressPaginationPanel]="true"
                [icons]="icons" [overlayLoadingTemplate]="overlayLoadingTemplate"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate" [headerHeight]="60">
            </ag-grid-angular>
        </div>
        <div class="d-flex justify-content-end gap-3 p-3 ">
            <div class="d-flex align-items-center">
                <label for="TotalNoPallet" class="px-2 col-6">Total Number Of Pallet</label>
                <input type="text" id="TotalNoPallet" style="height: 30px;" formControlName="numberOfPallet" (change)="calcTotalWeight()" />
            </div>
            <div class="d-flex align-items-center">
                <label for="Weight" class="px-2">Weight</label>
                <input type="text" id="Weight" style="height: 30px;" formControlName="weight" />
            </div>
        </div>
        </div>
        <div class="d-flex flex-wrap ps-3 py-2 gap-4 col-8">
            <div class="d-flex gap-2 align-items-center">
                <span>LP</span>
                <select id="text" formControlName="lp">
                    <option value="" disabled hidden selected>Select</option>
                    <option *ngFor="let lp of lpcodes" [value]="lp">{{lp}}</option>
                </select>
            </div>
            <div class="d-flex gap-2 align-items-center col-3">
                <input type="checkbox" id="check1" value="option1" formControlName="noShipment" class="col-2" (change)="checkBoxOnChange($event)">
                <span class="col-6">No Shipment</span>
            </div>
        </div>
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel6 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3 my-1">
                    <mat-panel-title class="fw-bold">REQUEST</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel6.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div>
                    <div class="d-flex flex-wrap col mx-3" [formGroup]="requestShipmentReady">
                        <div class="fw-bold col-12">Shipment Ready</div>
                        <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="company" class="col-4">Company</label>
                            <input type="text" id="company"  formControlName="company" />
                            <button [disabled]="companySelectDisabled" mat-icon-button><mat-icon class="col-1 toggleIcon ms-1"  *ngIf="pageName !== 'Detail' || moreVertFlag" (click)="dialogOpen1()">more_vert</mat-icon></button>
                        </div>
                        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="contact Person" class="col-4">Contact Person</label>
                            <input type="text" id="contactPerson" formControlName="contactPerson" />
                        </div>
                        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="contactPhone" class="col-4">Contact Phone</label>
                            <input type="text" id="contactPhone" formControlName="contactPhone" />
                        </div>
                        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="contactEmail" class="col-4">Contact Email</label>
                            <input type="text" id="contactEmail" formControlName="contactEmail" />
                        </div>
                        <div class="d-flex   align-items-center  col-lg-3 col-md-6 col-12 my-2 add-height">
                            <label for="address" class="col-4">Address</label>
                            <textarea rows="3" class="col-6" maxlength="250" id="address"
                                formControlName="contactAddress"></textarea>
                        </div>
                        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="date" class="col-4">Date</label>
                            <input type="date" id="date" formControlName="date" class="col-7" />
                        </div>
                        <div class="d-flex   align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="windowTime" class="col-4">Window Time</label>
                            <div class="d-flex align-items-center gap-4">
                                <select formControlName="windowTimeHH">
                                    <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                                </select>
                                <select formControlName="windowTimeMM">
                                    <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                            <label for="shippingClass" class="col-4">Shipping Class</label>
                            <input type="text" id="shippingClass" formControlName="shippingClass" />
                        </div>
                    </div>
                    <div class="d-flex flex-wrap col mx-3" [formGroup]="requestLastDelivery">
                        <div class="fw-bold col-12">Last Delivery Date</div>
                        <div class=" d-flex flex-wrap my-2">
                            <span class="fw-bold">Select Receiver</span>&nbsp;
                            <input type="radio" value="supplier" formControlName="receiver" (change)="receiverUpdated($event)" class="ms-3 me-1"/>
                            <label>Supplier</label>
                            <input type="radio" value="contfab" formControlName="receiver" (change)="receiverUpdated($event)" class="ms-3 me-1"/>
                            <label>Cont Fab</label>
                            <input type="radio" value="dun1fab" formControlName="receiver" (change)="receiverUpdated($event)" class="ms-3 me-1"/>
                            <label>Dun1 Fab</label>
                            <input type="radio" value="namc" formControlName="receiver" (change)="receiverUpdated($event)" class="ms-3 me-1"/>
                            <label>NAMC</label>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="company" class="col-4">Company</label>
                                <input type="text" id="company" formControlName="company" />
                                <mat-icon class="col-1 toggleIcon ms-1" *ngIf="pageName !== 'Detail' || moreVertFlag"(click)="dialogOpen2()">more_vert</mat-icon>
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="contactPerson" class="col-4">Contact Person</label>
                                <input type="text" id="contactPerson" formControlName="contactPerson" />
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="contactPhone" class="col-4">Contact Phone</label>
                                <input type="text" id="contactPhone" formControlName="contactPhone" />
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="contactEmail" class="col-4">Contact Email</label>
                                <input type="text" id="contactEmail" formControlName="contactEmail" />
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2 add-height">
                                <label for="address" class="col-4">Address</label>
                                <textarea rows="3" class="col-6" maxlength="250" id="address"
                                    formControlName="contactAddress"></textarea>
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="date" class="col-4">Date</label>
                                <input type="date" id="date" formControlName="date" />
                            </div>
                            <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                                <label for="windowTime" class="col-4">Window Time</label>
                                <div class="d-flex align-items-center col-3 gap-4 ">
                                    <select formControlName="windowTimeHH">
                                        <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                                    </select>
                                    <select formControlName="windowTimeMM">
                                        <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel7 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3">
                    <mat-panel-title class="fw-bold">PLAN</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel7.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header><br>
                <div class="d-flex flex-wrap col mx-3" [formGroup]="planShipment">
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Pickup Date" class="col-4">Pickup Date</label>
                        <input type="date" id="date" formControlName="pickupDate" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Pickup Time" class="col-4">Pickup Time</label>
                        <div class="d-flex align-items-center col-3 gap-4 ">
                            <select formControlName="pickupTime" style="height: 30px;" class="mat-sel">
                                <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="DeliveryDate" class="col-4">Delivery Date</label>
                        <input type="date" id="DeliveryDate" formControlName="deliveryDate">
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Delivery Time" class="col-4">Delivery Time</label>
                        <div class="d-flex align-items-center col-3 gap-4 ">
                           
                            <select formControlName="deliveryTime" style="height: 30px;" class="mat-sel">
                                <option *ngFor="let a of timeArray; let index=index" [value] = "index + 0">{{ index + 0 }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="carrier" class="col-4">Carrier</label>
                        <input type="text" id="carrier" formControlName="carrier" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Tracking No." class="col-4">Tracking No.</label>
                        <input type="text" id="Tracking No." formControlName="trackingNumber" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Cost Estimate" class="col-4">Cost Estimate</label>
                        <input type="text" id="Cost Estimate" formControlName="costEstimate" />
                    </div>
                    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <select formControlName="currency" style="height: 30px;" class="mat-sel">
                            <option value="USD">USD</option>
                            <option value="CAD">CAD</option>
                        </select>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel8 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container mx-3">
                    <mat-panel-title class="fw-bold">ACTUAL</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel8.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header><br>
                <div class=" d-flex flex-wrap col mx-3" [formGroup]="actualShipment">
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="shipmentDate" class="col-4">Shipment Date</label>
                        <input type="date" id="shipmentDate" formControlName="shipmentDate" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Received Date" class="col-4">Received Date</label>
                        <input type="date" id="Received Date" formControlName="receivedDate" />
                    </div>
                    <div class="d-flex align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <label for="Actual Cost" class="col-4">Actual Cost</label>
                        <input type="text" id="Actual Cost" formControlName="actualCost" />
                    </div>
                    <div class="d-flex  align-items-center col-lg-3 col-md-6 col-12 my-2">
                        <select formControlName="currency" style="height: 30px;" class="mat-sel">
                            <option value="USD">USD</option>
                            <option value="CAD">CAD</option>
                        </select>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="footer-dialog d-flex px-3 fixed-bottom" style="height: 50px;">
        <div class="mx-3 cancel" (click)="cancel()">Cancel</div>
        <button class="vpacs-btn-table mx-3" *ngIf="pageName !== 'Detail' || saveFlag" (click)="save()">SAVE</button>
        <button class="black-button mx-3" *ngIf="pageName !== 'Detail' || sendFlag" (click)="send()">SEND</button>
        <button class="black-button mx-3" *ngIf="rejectFlag" (click)="reject()">REJECT</button>
        <button class="black-button mx-3" *ngIf="cancelShipFlag" (click)="cancelShipment()">Cancel Shipment</button>
        <button class="black-button mx-3" *ngIf="sendConfirmFlag"(click)="send()">SEND TO CONFIRM</button>
    </div>
</div>