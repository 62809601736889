<div class="doc">
  <div class="d-flex justify-content-between align-items-center m-3">
    <div class="page-title">Documentation List</div>
    <div>
      <button class="black-button mx-3" (click)="addDocument('add')" style="height : 30px">ADD</button>
      <button class="help-btn" (click)="help()">help</button>
    </div>
  </div>
  <app-notification [codes]="errorMessage" *ngIf="errorMessage"></app-notification>
  <div class="{{(apiResponse && apiResponse.status === 'failure') ? 'alert alert-danger mx-2' : 'alert alert-success mx-2'}}" *ngIf="apiResponse && apiResponse.status">
    {{apiResponse.message}}
  </div>
  <div>
    <div class="grid-agg grid-height-documentation">
      <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
        (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="displayRowData" [suppressCellSelection]="true" [icons]="icons"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate" [floatingFiltersHeight]="40" [accentedSort] = "true">
      </ag-grid-angular>
    </div>
  </div>
</div>