
<div class="wrapper bg-white">
    <br><p class="page-title p-1 px-3">Delivery History</p>
   
    <div class="mat-typography content">                                                                          
        <mat-accordion class="example-headers-align" hideToggle multi>
            <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container">
                    <mat-panel-title class="fw-bold">AUTHORIZATION</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header>
                <div class="details-pane my-2 d-flex flex-wrap">
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Auth Number<span class="text-black">{{deliveryDo?.authNumber}}</span></div>                    
                    <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Target Delivery Date<span class="text-black">{{deliveryDo?.deliveryDate | date: 'MM/dd/yyyy'}}</span></div>
                    
                </div> 
                <div class="filter d-flex">
                    <div class="grid-agg">
                        <ag-grid-angular style="width:100%;" 
                        class="ag-theme-balham" 
                        (gridReady)="onGridReady($event);"
                        [animateRows]="true" 
                        [defaultColDef]="defaultColDef" 
                        [columnDefs]="columnDefs1"                
                        [rowData]="displayRowData"
                        [suppressCellSelection]="true"
                        [suppressPaginationPanel]="true" 
                        [suppressRowTransform]="true" [domLayout]="domLayout"
                        [icons]="icons"
                        [headerHeight]="40">
                        </ag-grid-angular>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="true" #panel9 class="mat-elevation-z0">
                <mat-expansion-panel-header class="header-container ">
                    <mat-panel-title class="fw-bold">DELIVERY</mat-panel-title>
                    <mat-icon class="toggleIcon">{{panel9.expanded ? 'remove':'add'}}</mat-icon>
                </mat-expansion-panel-header><br>
                <div class="filter d-flex">
                    <div class="grid-agg">
                        <ag-grid-angular style="width:100%" 
                            class="ag-theme-balham" 
                            [gridOptions]="gridOptions"
                            (gridReady)="onGridReady($event);"
                            (cellValueChanged)="onCellValueChanged($event)"
                            [animateRows]="true" 
                            [defaultColDef]="defaultColDef" 
                            [columnDefs]="columnDefs2"                
                            [rowData]="displayRowData1"
                            [suppressPaginationPanel]="true" [suppressCellSelection]="true"
                            [suppressRowTransform]="true" [domLayout]="domLayout"
                            [icons]="icons"
                            [headerHeight]="40"
                            >
                        </ag-grid-angular>
                    </div><br>
                </div>
                
            </mat-expansion-panel>
            
        </mat-accordion>
    </div><br>
    <div class="footer-dialog d-flex">
        <div class="btn btn-link mx-3" (click)="closeDialog()">CANCEL</div>
        <button class="black-button mx-3" (click)="saveClick()">OK</button>
    </div>
    
</div>
    

