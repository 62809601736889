import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionChangedEvent } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentListService } from 'src/app/internal-vpacs/services-internal/tracking/shipment-list.service';
@Component({
  selector: 'select-rack',
  templateUrl: './select-rack.component.html',
  styleUrls: ['./select-rack.component.scss']
})
export class SelectRackComponent implements OnInit {

  public rowData :any;

  //ag-grid-specs
  gridApi: any;
  columnDefs : any[] = [];
  displayRowData: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  parentClick:any;
  icons: {};
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  selectedRow:any=[]

  pageName: any;
    packageCode: any;
  rackPage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data:any,
    public router: Router,
    public shipmentListService: ShipmentListService,
    private readonly location: Location,
    public route: ActivatedRoute
    ) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,     
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.packageCode=data.packagecode
    this.parentClick=data.clicked
  }

  ngOnInit()   {
    const params={
      "packageCode": this.packageCode,
    }
    this.shipmentListService.addDataOnload(params).subscribe ({ next: (res: any) => {
         this.displayRowData=res
    }
      })
    this.columnDefs = [
      {
        headerName: "All", field: "", width: 50, 
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      { headerName: "Serial Number", field:"serialnumber",  width: 100 },
      { headerName: "Auth Number", field: "createdauthnumber",  width: 100 }   
    ];
      
  }

  onAdd(){
    this.parentClick(this.selectedRow)
  }

  onCancel(){
    this.location.back()
  }
  
  onSelectionChanged(event: SelectionChangedEvent) {
    const rows = event.api.getSelectedNodes();
    const data=rows.map(r=>{
     
      return (r.data)
    })
    this.selectedRow=data
  }


  onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
  }
  


}
