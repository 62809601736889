import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UpdateBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/update-btn-cell-render';
import { SupplierService } from 'src/app/internal-vpacs/services-internal/master-maintenance/supplier.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-sup-contact',
  templateUrl: './sup-contact.component.html',
  styleUrls: ['./sup-contact.component.scss']
})
export class SupContactComponent implements OnInit {

  suppliercode: any = '';
  createDataSupInformation: any = '';
  createtblSupplier: any = '';
  res: any = '';
  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean: any = false;
  totalPage: any = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: any;
  isLastPageFound!: any;
  rowCount: any = 0;
  defaultColDef: {};
  gridOptions: any
  icons: {};
  pageNumber: any = 0;
  lastRowIndex: any = 0;
  rowClassRules: any;
  autoGroupColumnDef: any;
  paginationPageSize: any = 50
  isAccessible = false;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
<div>
<img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
<p class="fw-bold fs-5">No data available</p>
<span>Please select the above filters to get the results.</span>
</div>
`;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  editAccess: any;
  viewAccess: any;


  constructor(
    public supplierService: SupplierService,
    private readonly spinnerService: NgxUiLoaderService,
    public route: ActivatedRoute,
    public router: Router,
    public state: ExportStateService, public constants : ConstantsService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
          };

    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.editAccess = res?.role?.p_usersave;
          this.viewAccess = res?.role?.p_userview;
          if (this.viewAccess) {
            this.spinnerService.start();
            this.route.params.subscribe((params: any) => {
              if (params.suppliercode) {
                this.suppliercode = params.suppliercode;
                this.getColumnDefs();
                this.supplierContactOnload();
              } else {
                this.spinnerService.stop();
              }
            });
          }
        }
      },
      error: (_err: any) => {
        this.editAccess = false;
        this.viewAccess = false;
      }
    })
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: "Name", field: "FullName", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 170
      },
      {
        headerName: "Title", field: "title", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "PreProd", field: "preProd", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90,
        cellStyle: (params: any) => this.cellStyle(params),
        tooltipValueGetter: (params: any) => (params.value !== '-' ? params.value : '')
      },
      {
        headerName: "MassProd", field: "massProd", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 95,
        cellStyle: (params: any) => this.cellStyle(params),
        tooltipValueGetter: (params: any) => (params.value !== '-' ? params.value : '')
      },
      {
        headerName: "Phone", field: "phone", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 110,
      },
      {
        headerName: "E-mail", field: "email", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 220,
      },
      {
        headerName: "Cell/Pager", field: "pager", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 100,
      },
      {
        headerName: "Admin", field: "admin1", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90,
        cellRenderer: this.adminLogic
      },
      {
        headerName: "Valid", field: "valid1", floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true, filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, minWidth: 90,
        cellRenderer: this.validLogic
      },
      {
        headerName: "UPDATE", field: "update", sortable: false, unSortIcon: false, minWidth: 90,
        cellRendererFramework: UpdateBtnCellRender,
        cellRendererParams: {
          editFlag : this.editAccess,
          clicked: (data?: any, field?: any) => {
            this.UpdatePkg(data, field);
          },
        },
      },
    ]
  }

  cellStyle(params: any) {
    if (params.value === '-') {
      return {'display': 'inline-flex'};
    }
    else{
      return {
        'display': 'inline-block',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        'overflow': 'hidden',
        'vertical-align': 'middle',
        'width' : params.colDef.maxWidth || params.colDef.minWidth || params.colDef.width,
        'padding-left' : '5px',
      };
    }
  }

  adminLogic(params: any) {
    if (params.data.admin1 === '0') {
      return "OFF";
    } else if (params.data.admin1 === "1") {
      return "ON";
    } else {
      return "";
    }
  }

  validLogic(params: any) {
    if (params.data.valid1 === '0') {
      return "OFF";
    } else if (params.data.valid1 === "1") {
      return "ON";
    } else {
      return "";
    }
  }

  UpdatePkg(data?: any, _field?: any,) {
    this.router.navigateByUrl(`/supplier-maintenance/supplier-contact-maintenance/${this.suppliercode}/update-supplier-contact/${data.guid}`);
  }

  supplierContactOnload(): void {
    const loadObj = {
      "supplierCode": this.suppliercode,
      "sort": ""
    };
    this.spinnerService.start();
    this.supplierService.supplierContactOnload(loadObj)
      .subscribe({
        next: (res: any) => {
          this.createtblSupplier = res.createtblSupplier[0];
          res.createDataSupInformation.forEach((element: any, index: any) => {
            element.massProd = res.ProdData?.mass[index]?.prod;
            element.preProd = res.ProdData?.pre[index]?.prod;
            element.title = res?.createDataSupInformation[index]?.title ?? '-';
            element.pager = res?.createDataSupInformation[index]?.pager ?? '-';
            element.phone = res?.createDataSupInformation[index]?.phone ?? '-';
            element.email = res?.createDataSupInformation[index]?.email ?? '-';
          });
          this.displayRowData = res.createDataSupInformation;
          this.rowCount = this.displayRowData.length;
          this.totalRecords = res.createDataSupInformation.length;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
        complete: () => {
          this.spinnerService.stop();
        }
      })
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage)
  }

  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }

  help() {
    this.router.navigate(['./master-help', 5, "Master Maintenance", 127])
  }

  backBtn() {
    this.router.navigate(['/supplier-maintenance'])
  }
}