import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { UpdateBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/cost-management/ringi-list/update-btn-cell-render'
import { PkgFabMainService } from 'src/app/internal-vpacs/services-internal/master-maintenance/pkgfabmain.service'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-pkg-cont',
  templateUrl: './pkg-cont.component.html',
  styleUrls: ['./pkg-cont.component.scss'],
})
export class PkgContComponent implements OnInit {
  plant: any
  cPage = 1
  tPage = 0
  rpPage = 50
  totalRecords = 0
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  autoGroupColumnDef: any
  paginationPageSize = 50
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
<div>
<img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
<p class="fw-bold fs-5">No data available</p>
<span>Please select the above filters to get the results.</span>
</div>
`
  pkgcode: any
  name: any
  alphacode: any
  supcode: any
  guid: any
  editAccess: any
  viewAccess: any
  errorMessage: any
  constructor(
    public pkgFabMainService: PkgFabMainService,
    private readonly spinnerService: NgxUiLoaderService,
    public route: ActivatedRoute,
    public router: Router,
    public state: ExportStateService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.editAccess = res?.role?.p_usersave
          this.viewAccess = res?.role?.p_userview
          if (this.viewAccess) {
            this.spinnerService.start()
            this.route.params.subscribe((params: any) => {
              if (params.supcode) {
                this.pkgcode = params['pkgsupcode']
                this.name = params['name']
                this.alphacode = params['alphacode']
                this.supcode = params['supcode']
                this.onloadPkgFabContact()
                this.getColumnDefs()
              } else {
                this.spinnerService.stop()
                this.errorMessage = {
                  errorCode: `API-403`,
                  childErrorCode: '400',
                }
              }
            })
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop()
        this.editAccess = false
        this.viewAccess = false
      },
    })
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'fullname',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Title',
        field: 'title',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
      },
      {
        headerName: 'Phone',
        field: 'phone',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 120,
      },
      {
        headerName: 'E-mail',
        field: 'email',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 200,
      },
      {
        headerName: 'Cell/Pager',
        field: 'pager',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 100,
      },
      {
        headerName: 'Admin',
        field: 'admin',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        cellRenderer: this.adminLogic,
      },
      {
        headerName: 'Valid',
        field: 'valid',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
        cellRenderer: this.validLogic,
      },
      {
        headerName: 'UPDATE',
        field: 'update',
        sortable: false,
        unSortIcon: false,
        minWidth: 90,
        cellRendererFramework: UpdateBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.UpdatePkg(field)
          },
        },
      },
    ]
  }
  adminLogic(params: any) {
    if (params.data.admin === '0') {
      return 'OFF'
    } else if (params.data.admin === '1') {
      return 'ON'
    } else {
      return ''
    }
  }

  validLogic(params: any) {
    if (params.data.valid === '0') {
      return 'OFF'
    } else if (params.data.valid === '1') {
      return 'ON'
    } else {
      return ''
    }
  }
  
  onCancel() {
    this.router.navigateByUrl('/package-fabricator')
  }

  UpdatePkg(field: any) {
    const guid = field.guid
    const supplierCode = this.supcode
    const name = field.fullname
    this.router.navigate([
      `/package-fabricator/package-fabricator-contact/${this.pkgcode}/${this.alphacode}/${this.name}/${this.supcode}/update-fabricator-contact`,
      guid,
      supplierCode,
      name,
    ])
  }
  onloadPkgFabContact(): void {
    this.spinnerService.start()
    this.pkgFabMainService.onloadPkgFabContact(this.supcode).subscribe({
      next: (res: any) => {
        const result = res.result?.map((item: any) => ({
          ...item,
          editflag: this.editAccess,
        }));
        this.displayRowData = result
        this.rowCount = this.displayRowData.length

        this.gridApi?.hideOverlay()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }
  
  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
  }
}
