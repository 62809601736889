import { formatDate } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { PlantMaintenanceBtnCellRender } from '../../../internal-Vpacs-gridrender/master-maintenance/plant-maintenance-btn-cell-render'
import { AddPlantComponent } from '../../../internal-vpacs-dialogs/master-maintenance/plant-maintenance/add-plant/add-plant.component'
import { DockMaintenanceComponent } from '../../../internal-vpacs-dialogs/master-maintenance/plant-maintenance/dock-maintenance/dock-maintenance.component'
import { ImportEstimateCostComponent } from '../../../internal-vpacs-dialogs/cost-management/add-ringi/import-estimate-cost/import-estimate-cost.component'
import { ImportRingiComponent } from '../../../internal-vpacs-dialogs/cost-management/add-ringi/import-ringi/import-ringi.component'
import { PlantSysdaysComponent } from '../../../internal-vpacs-dialogs/master-maintenance/plant-maintenance/plant-sysdays/plant-sysdays.component'
import { CostCenterComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/plant-maintenance/cost-center/cost-center.component'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { PlantService } from 'src/app/internal-vpacs/services-internal/master-maintenance/plant.service'
import { PlantBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/plant-btn-cell-render'
import { DeleteFabComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/delete-fab/delete-fab.component'
import { ExportStateService } from 'src/app/services/export-state.service'
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service'

@Component({
  selector: 'app-plant-maintenance',
  templateUrl: './plant-maintenance.component.html',
  styleUrls: ['./plant-maintenance.component.scss'],
})
export class PlantMaintenanceComponent implements OnInit {
  plant: any
  ringi: any
  params: any
  cPage = 1
  tPage = 0
  rpPage = 50
  totalRecords = 0
  haveAccess: any
  haveAddAccess: any
  modifyAccess: any
  deleteAccess: any
  saveAccess: any
  errorList: string[] = [];
  successMessage: string[] = [];
  //ag-grid-specs
  gridApi: any
  columnDefs: any[] = []
  currentPageNo = 0
  currentPageBoolean = false
  totalPage = 0
  displayRowData: any[] = []
  currentPageForTotalPage!: number
  isLastPageFound!: boolean
  pageNumber = 0
  lastRowIndex = 0
  rowClassRules: any
  rowCount = 0
  defaultColDef: {}
  gridOptions: any
  icons: {}
  isAll = false;
  paginationPageSize = 50
  isAccessible = false
  public domLayout: any = ''
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  headerClass = 'child-header-color'
  headerClassNoBorder = 'child-header-color no-border'
  gridColumnApi: any

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : ''
  }

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public plantService: PlantService,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      wrapText: true,
      sortable: true,
      autoHeight: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.spinnerService.start()
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_userview
        this.haveAddAccess = res.role?.p_usersave
        this.deleteAccess = res.role?.p_userdelete
        if (this.haveAccess) {
          this.plantOnload()
        }
      },
      error: (_err: any) => {
        this.haveAccess = false
        this.haveAddAccess = false
        this.modifyAccess = false
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      }
    })

    this.columnDefs = [
      {
        headerName: '',field: '',minWidth: 80,headerClass: this.headerClass,
        children: [
          {
            headerName: 'Code',field: 'plantcode',sortable: true,unSortIcon: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true,filter: 'agTextColumnFilter',
            headerClass: this.headerClassNoBorder,minWidth: 90,
          },
        ],
      },
      {
        headerName: '',field: '',minWidth: 150,headerClass: this.headerClass,
        children: [
          {
            headerName: 'Name',field: 'name',sortable: true,unSortIcon: true,
            floatingFilterComponentParams: { suppressFilterButton: true },floatingFilter: true,filter: 'agTextColumnFilter',headerClass: this.headerClassNoBorder,minWidth: 150,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'Id',field: 'plantid',floatingFilterComponentParams: { suppressFilterButton: true },floatingFilter: true,
            filter: 'agTextColumnFilter',
            headerClass: this.headerClassNoBorder,
            minWidth: 80,
            sortable: true,
            unSortIcon: true,
          },
        ],
      },

      {
        headerName: 'PPF Approval',
        field: '',
        minWidth: 240,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'Pre PPF',
            field: 'preppfapproval',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 80,
            cellRenderer: this.prePpfApproval,
          },
          {
            headerName: 'Trial',
            field: 'trialapproval',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 80,
            cellRenderer: this.trialApproval,
          },
          {
            headerName: 'Final PPF',
            field: 'finalppfapproval',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 80,
            cellRenderer: this.finalPpfApproval,
          },
        ],
      },

      {
        headerName: 'PUR Approval Cost',
        field: '',
        minWidth: 300,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'Proto',
            field: 'purprotoappcost',
            type: 'numberColumn',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 100,
          },
          {
            headerName: 'Prod',
            field: 'purprodappcost',
            type: 'numberColumn',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 100,
          },
          {
            headerName: 'Mainte',
            field: 'purmainteappcost',
            type: 'numberColumn',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 100,
          },
        ],
      },
      {
        headerName: 'Approval Cost <=',
        field: '',
        minWidth: 200,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'AM',
            field: 'amcost',
            type: 'numberColumn',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 100,
          },
          {
            headerName: 'MGR',
            field: 'mgrcost',
            type: 'numberColumn',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClass,
            minWidth: 100,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        children: [
          {
            headerName: 'Supplier Code Type',
            field: 'suppliercodetype',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClassNoBorder,
            minWidth: 100,
            cellRenderer: this.supplierCodeType,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        children: [
          {
            headerName: 'Package Cost Type',
            field: 'packagecosttype',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClassNoBorder,
            minWidth: 100,
            cellRenderer: this.packageCostType,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        children: [
          {
            headerName: 'Monetary Unit',
            field: 'monetaryunit',
            sortable: false,
            unSortIcon: false,
            headerClass: this.headerClassNoBorder,
            minWidth: 100,
            cellRenderer: this.monetaryUnit,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'DOCK',
            field: 'dock',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: PlantMaintenanceBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.dialog.open(DockMaintenanceComponent, {
                  width: '100%',
                  data: { field },
                })
              },
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'CALC',
            field: 'calc',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: PlantMaintenanceBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.dialogOpenPlantSysdays(field?.plantcode)
              },
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'COST CENTER',
            field: 'costcenter',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: PlantMaintenanceBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.dialogOpenCostCenter(field?.plantcode)
              },
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'UPDATE',
            field: 'update',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            comparator: dateComparator,
            cellRendererFramework: PlantBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.dialogOpen(field)
              },
            },
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 100,
        headerClass: this.headerClass,
        children: [
          {
            headerName: 'DELETE',
            field: 'delete',
            headerClass: this.headerClassNoBorder,
            sortable: false,
            unSortIcon: false,
            minWidth: 100,
            valueFormatter: this.dateFormatter,
            cellRendererFramework: PlantBtnCellRender,
            cellRendererParams: {
              clicked: (field?: any) => {
                this.deleteButton(field)
              },
            },
          },
        ],
      },
    ]
  }

  prePpfApproval(params: any) {
    if (params.data.preppfapproval === 0) {
      return 'SP'
    } else if (params.data.preppfapproval === 1) {
      return 'AM'
    } else if (params.data.preppfapproval === 2) {
      return 'MGR'
    } else {
      return '-'
    }
  }

  trialApproval(params: any) {
    if (params.data.trialapproval === 0) {
      return 'SP'
    } else if (params.data.trialapproval === 1) {
      return 'AM'
    } else if (params.data.trialapproval === 2) {
      return 'MGR'
    } else {
      return '-'
    }
  }

  finalPpfApproval(params: any) {
    if (params.data.finalppfapproval === 0) {
      return 'SP'
    } else if (params.data.finalppfapproval === 1) {
      return 'AM'
    } else if (params.data.finalppfapproval === 2) {
      return 'MGR'
    } else {
      return '-'
    }
  }

  monetaryUnit(params: any) {
    if (params.data.monetaryunit === 0) {
      return '$(US)'
    } else if (params.data.monetaryunit === 1) {
      return '$(CAN)'
    } else if (params.data.monetaryunit === 2) {
      return ' (P) '
    } else {
      return ''
    }
  }

  packageCostType(params: any) {
    if (params.data.packagecosttype === 0) {
      return 'NA'
    } else if (params.data.packagecosttype === 1) {
      return 'CAN'
    } else if (params.data.packagecosttype === 2) {
      return 'NUMMI'
    } else {
      return ''
    }
  }

  supplierCodeType(params: any) {
    if (params.data.suppliercodetype === 0) {
      return 'NA'
    } else if (params.data.suppliercodetype === 1) {
      return 'CAN'
    } else if (params.data.suppliercodetype === 2) {
      return 'NUMMI'
    } else {
      return ''
    }
  }

  dialogOpenPlantSysdays(plantcode: string) {
    const dialog = this.dialog.open(PlantSysdaysComponent, {
      width: '75%',
      data: { plantcode: plantcode },
    })
    dialog.afterClosed().subscribe((result: any) => {
      const res = { result: result?.msg, data: result?.res };
      this.afterDialogClose(res);
    });
  }

  dialogOpenCostCenter(plantcode: string) {
    this.dialog.open(CostCenterComponent, {
      width: '75%',
      data: { plantcode: plantcode },
    })
  }

  plantOnload(): void {
    this.spinnerService.start()
    this.plantService.plantOnload().subscribe({
      next: (res: any) => {
        this.displayRowData = res
        this.rowCount = this.displayRowData.length
        this.totalRecords = res.length
        this.tPage = Math.ceil(this.totalRecords / this.rpPage)
        this.gridApi?.hideOverlay()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  deleteButton(field: any): void {
    const dialogRef = this.dialog.open(DeleteFabComponent, {
      data: { field, mode: 'Plant' },
      height: '400px',
      width: '550px',
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        const PlantCode = field?.plantcode
        this.spinnerService.start()
        this.plantService.deletePlant(PlantCode).subscribe({
          next: (_res: any) => {
            const result = _res.status ? 'Success' : '';
            const res = { ..._res, result, data: { message: [_res.message] } }
            this.plantOnload();
            this.afterDialogClose(res)
            this.spinnerService.stop()
          },
          error: (_err: any) => {
            this.spinnerService.stop()
          },
        })
      }
    })
  }

  uploadFile() {
    this.dialog.open(ImportEstimateCostComponent)
  }

  uploadFile1() {
    this.dialog.open(ImportRingiComponent)
  }

  dialogOpen(field: any) {
    const dialogRef = this.dialog.open(AddPlantComponent, {
      width: '800px',
      data: { mode: 'UPDATE', field },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS') {
          this.afterDialogClose(result.data);
        }
      }
    })
  }

  addPlant() {
    const dialogRef = this.dialog.open(AddPlantComponent, {
      width: '800px',
      data: { mode: 'ADD' },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data?.result === 'SUCCESS') {
          this.afterDialogClose(result.data)
        }
      }
    })
  }
  afterDialogClose(data: any) {
    if (data?.result === 'Success' || data?.result === 'SUCCESS') {
      this.successMessage = data?.data.message[0];
      this.plantOnload();
    } else if (data?.result === 'Failure' || data?.result === 'FAILURE') {
      this.errorList = data?.data.message[0];
    } else {
      this.errorList = [];
      this.successMessage = [];
    }
    setTimeout(() => {
      this.errorList = [];
      this.successMessage = [];
    }, 5000);
  }
  close() {
    this.errorList = [];
    this.successMessage = [];
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
  }

  //pagination changes

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1
        this.tPage = 1
        this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.plantOnload();
    }
  }

  async onLoadAll() {
    this.plantOnload()
    this.totalRecords = this.gridApi.getDisplayedRowCount();
  }

  onFilterChanged(_params: any) {
    this.cPage = 1
    this.totalRecords = this.gridApi.getDisplayedRowCount()
    this.tPage = Math.ceil(this.totalRecords / this.rpPage)
  }

  help() {
    this.router.navigate(['./master-help', 5, 'Master Maintenance', 109])
  }
}
