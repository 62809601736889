import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-update-fabricator',
  templateUrl: './update-fabricator.component.html',
  styleUrls: ['./update-fabricator.component.scss']
})
export class UpdateFabricatorComponent implements OnInit {
  fabricators: any[] = [];
  fabricator: any;
  errorArray: any[] = [];
  searchText: any;
  packageAuthSatus = false;
  constructor(public dialogRef: MatDialogRef<UpdateFabricatorComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly trackingListService: TrackingListService,
    private readonly spinner: NgxUiLoaderService,
  ) { }
  ngOnInit(): void {
    setTimeout(() => {  
      this.spinner.start();
    })
    this.getFabricatorList();
  }

  getFabricatorList() {
    const data = {
      PkgSupCode : "",
      url_plant: this.dataStore.data.plantcode,
      url_supplier: this.dataStore.data.suppliercode,
      url_partnumber: this.dataStore.data.partnumber,
      url_model: this.dataStore.data.modelcode,
      url_pkgstartdate: this.dataStore.data.pkgstartdate
    }
  
  this.errorArray = [];
  this.trackingListService.getFabricatorList(data).subscribe({
      next:(res)=>{
        this.spinner.stop();
        this.fabricators =  res.result;
        const pkgsupcode = this.fabricators.find((item) => item.pkgsupcode === '4GI');
        this.fabricator = pkgsupcode ? pkgsupcode.pkgsupcode : this.fabricator;
        this.packageAuthSatus = res.packageauthStatus;
        if(this.packageAuthSatus) {
          this.errorArray = ['Fabricator code can not be changed at this stage.'];
        }
        
      },
     error: (error) => {
      this.spinner.stop();
        this.errorArray[0] = error.message;
      }
  })
}

  onSearch(_event:any){
    this.searchText = this.searchText.toUpperCase();
    if(this.searchText.length>0){
    const searchResult = this.fabricators.filter(item => item.pkgsupcode.includes(this.searchText));
    this.fabricators = searchResult;
    this.fabricator = this.fabricators?.length>0?this.fabricators[0].pkgsupcode:'';
    }else{
      this.getFabricatorList();
    }
  }

  updateBtn() { 
    this.spinner.start();
    const fabricatorObj = this.fabricators.find((item) => item.pkgsupcode === this.fabricator);
    const data = {
      AuthNumber : "",
      Fabricator : fabricatorObj?.pkgsupcode,
      Screen_Mode : "TRACKING",
      PlantCode : this.dataStore.data.plantcode,
      PartNumber : this.dataStore.data.partnumber,
      SupplierCode : this.dataStore.data.suppliercode,
      PkgStartDate : this.dataStore.data.pkgstartdate,
      UrlType : this.dataStore.pageName === "fabricator" ? "0" : "1"
    }
    this.errorArray = [];
    this.trackingListService.updateFabricator(data).subscribe({
     next:(res)=>{
       this.spinner.stop();
        if(res.status === 'SUCCESS') {
          this.dialogRef.close({ event: 'Fabricator CloseEvent' });
        }
     },
     error: (error) => {
      this.spinner.stop();
        this.errorArray = [error.error.error.message];
     }
    })
  }


  close(): void {
    this.dialogRef.close();
  }

  delete(){
    this.spinner.start()
    const data = {
      AuthNumber : "",
      Fabricator : this.dataStore.fab,
      Screen_Mode : "TRACKING",
      PlantCode : this.dataStore.data.plantcode,
      PartNumber : this.dataStore.data.partnumber,
      SupplierCode : this.dataStore.data?.suppliercode,
      PkgStartDate : this.dataStore.data.pkgstartdate,
      UrlType : this.dataStore.pageName !== "fabricator" ? "0" : "1"
    }
    this.errorArray = [];
    this.trackingListService.deleteFabricator(data).subscribe({
      next:(res)=>{
        this.spinner.stop();
        if(res.status === 'SUCCESS') {
          this.dialogRef.close({ event: 'Fabricator CloseEvent' });
        }
      },
      error: (error:any) => {
        this.spinner.stop();
       this.errorArray[0] = error.error.error.message;
      }
    })
  }

}
