import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SortChangedEvent, Column } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModelCellRendererComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/model-list/model-list-btn-cell-render';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-compare-model-list',
  templateUrl: './compare-model-list.component.html',
  styleUrls: ['./compare-model-list.component.scss'],
})
export class CompareModelListComponent implements OnInit {
  @Input() data: any;
  selectPlant: any;
  plantCodeValue: any = '';
  spCode: any;
  supplier: any;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 25;
  paginationPageSize = 50;
  isAccessible = false;
  partnumber = '';
  partNumber = '';
  supplierCode = '';
  loading = true;
  sortModel: any[] = [];
  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  lastRowIndex = 0;
  pageNumber = 0;
  plant:any ;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  partNumberConnect: any;
  supplierCodeConnect: any = '';
  modelCode: any = '';
  plantCode: any;
  connectionLevel: any;
  transfer = true;
  gridColumnApi: any;
  resPlant: any;
  haveAccess: any;
  levels = [
    {
      Id: 'S1',
      Name: 'Part10 + Plant',
    },
    {
      Id: 'S2',
      Name: 'Part10',
    },
    {
      Id: 'S3',
      Name: 'Part5 + Plant',
    },
    {
      Id: 'S4',
      Name: 'Part5',
    },
    {
      Id: 'S5',
      Name: 'No Connect',
    },
  ];
  level = 'S1';
  compareParams: any;
  apiSearch = false;
  isAll = false;
  filteredOptions: any;
  haveErrors = false;
  errorList: string[] = [];

  constructor(
    public route: Router,
    private readonly service: ModelListService,
    public dialog: MatDialog,
    public router: ActivatedRoute,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      autoHeight: true,
      sortable: true,
      unSortIcon: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      autoSizeAllColumns: true,
      domLayout: 'autoHeight',
      resizable: true,
      valueFormatter: this.nullToDashFormatter,
    };

    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  ngOnInit(): void {
    this.compareParams = JSON.parse(
      sessionStorage.getItem('compareParams') || '{}'
    );
    this.modelCode = this.compareParams.modelCode;
    this.plantCode = this.compareParams.plantCode;
    this.supplierCodeConnect = this.compareParams.supplierCode;
    this.partNumberConnect = this.compareParams.partNumber;
    this.connectionLevel = this.compareParams.connectionLevel;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.haveAccess = res.role?.p_trackingview;
        this.spCode = res.info?.specialistcode;
        this.haveAccess && this.getPlant();
      },
      error: (_err: any) => {
        this.haveAccess = false;
      },
    });
  }

  getPlant() {
    this.spinnerService.start();
    this.loading = true;
    const plantobj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };
    this.service.getAllPlant(plantobj).subscribe({
      next: (res: any) => {
        this.resPlant = res.map((item: { plantcode: any }) => item.plantcode);
        this.selectPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
        this.plant = this.plant ?? '(All)';
        this.filteredOptions = this.selectPlant
        this.displayFn(this.selectPlant);
        this.getColumnDefs();
        this.apiCall();
        this.loading = false;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.selectPlant = [];
        this.loading = false;
        this.spinnerService.stop();
      },
    });
  }

  filterOptions() {
    this.filteredOptions = this.selectPlant.filter((option:any) => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  getColumnDefs() {
    const headerColor = 'child-header-color no-border';
    const color = 'child-header-color';
    this.columnDefs = [
      {
        headerName: 'SELECT', field: 'AUTHREAD', filter: false, sortable: false, unSortIcon: false, minWidth: 100, headerClass: headerColor,
        cellRendererFramework: ModelCellRendererComponent,
      },
      {
        headerName: 'PART #', field: 'partnumber', minWidth: 130, headerClass: headerColor
      },
      {
        headerName: 'PART DESCRIPTION(PART NAME)', field: 'partname', minWidth: 250, headerClass: headerColor,
      },
      {
        headerName: 'SUPPLIER CODE', field: 'suppliercode', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'PLANT', field: 'plant', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'MODEL', field: 'model', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'QPC', field: 'qpc', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'TOTAL/PART', field: 'm3part', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'M3/PART', field: 'm3part', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'HR/BOX#', field: 'hrbox', minWidth: 120, headerClass: headerColor,
      },
      {
        headerName: 'Package QTY',
        minWidth: 160,
        children: [
          {
            headerName: 'R', field: 'pkgqtyr', minWidth: 80, headerClass: color,
          },
          {
            headerName: 'A', field: 'pkgqtya', minWidth: 80, headerClass: color,
          },
        ],
      },
      {
        headerName: 'container',
        minWidth: 200,
        children: [
          {
            headerName: 'Code', field: 'pkgcode', minWidth: 120, headerClass: color,
          },
          {
            headerName: 'Cost$(US)', field: 'contcost', minWidth: 100, headerClass: color
          },
          {
            headerName: 'Cost/Part', field: 'pkgcostp', minWidth: 100, headerClass: color
          },
          {
            headerName: 'L', field: 'pkgl', minWidth: 80, headerClass: color,
          },
          {
            headerName: 'W', field: 'pkgw', minWidth: 80, headerClass: color,
          },
          {
            headerName: 'H', field: 'pkgh', minWidth: 80, headerClass: color,
          },
          {
            headerName: 'Maintenance Cost/Box', minWidth: 250, headerClass: color,
            children: [
              {
                headerName: '2001', field: 'pkgmcost1', minWidth: 80, headerClass: color,
              },
              {
                headerName: '2002', field: 'pkgmcost2', minWidth: 80, headerClass: color,
              },
              {
                headerName: '2003', field: 'pkgmcost3', minWidth: 80, headerClass: color,
              },
            ],
          },
        ],
      },
      {
        headerName: 'Dunnage 1', minWidth: 250, headerClass: color,
        children: [
          {
            headerName: 'Code', field: 'dun1code', minWidth: 120, headerClass: color,
          },
          {
            headerName: 'Cost$(US)', field: 'dun1cost', minWidth: 100, headerClass: color
          },
          {
            headerName: 'Cost/Part', field: 'dun1costp', minWidth: 100, headerClass: color
          },
        ],
      },
      {
        headerName: 'Dunnage 2', minWidth: 250, headerClass: color,
        children: [
          {
            headerName: 'Code', field: 'dun2code', minWidth: 120, headerClass: color,
          },
          {
            headerName: 'Cost$(US)', field: 'dun2cost', minWidth: 100, headerClass: color
          },
          {
            headerName: 'Cost/Part', field: 'dun2costp', minWidth: 100, headerClass: color
          },
        ],
      },
      {
        headerName: 'Pallet', minWidth: 250, headerClass: color,
        children: [
          {
            headerName: 'Code', field: 'palcode', minWidth: 120, headerClass: color,
          },
          {
            headerName: 'Cost$(US)', field: 'palcost', minWidth: 100, headerClass: color
          },
        ],
      },
      {
        headerName: 'Top', minWidth: 250, headerClass: color,
        children: [
          {
            headerName: 'Code', field: 'topcode', minWidth: 120, headerClass: color,
          },
          {
            headerName: 'Cost$(US)', field: 'topcost', minWidth: 100, headerClass: color
          },
        ],
      },
    ];
  }

  apiCall() {
    this.spinnerService.start();
    this.loading = true;
    let partValue = this.partNumber;
    if (partValue.length < 10) {
      partValue = partValue + '*';
    }
    const compareObject = {
      url_part: this.partNumberConnect,
      url_supplier: this.supplierCodeConnect,
      url_plant: this.plantCode,
      url_model: this.modelCode,
      sort: '',
      limit: this.rpPage,
      page: this.cPage,
      Part1: partValue.slice(0, 5),
      Part2: partValue.slice(5, 12),
      SupplierCode: this.supplierCode,
      TMMCCode: '',
      NUMMICode: '',
      connectionLevel: this.level,
      PlantCodes: this.plant === '(All)' ? this.resPlant : [this.plant],
    };
    this.service.compareOnload(compareObject).subscribe({
      next: (res: any) => {
        if (res.result.length > 0) {
          res.result.forEach((element: any) => {
            const pkgcostpValue = element.pkgcostp !== null ? element.pkgcostp.replace(/\s/g, '') : [];
              element.contcost = this.dollarFormatter({ value: element.contcost });
              element.pkgcostp = this.dollarFormatter({ value: pkgcostpValue });
              element.dun1cost = this.dollarFormatter({ value: element.dun1cost });
              element.dun1costp = this.dollarFormatter({ value: element.dun1costp });
              element.dun2cost = this.dollarFormatter({ value: element.dun2cost });
              element.dun2costp = this.dollarFormatter({ value: element.dun2costp });
              element.palcost = this.dollarFormatter({ value: element.palcost });
              element.topcost = this.dollarFormatter({ value: element.topcost })
          });
        }
        this.displayRowData = res.result === -1 ? [] : res.result;
        this.rowCount = res.pagination.TotalRecords;
        this.totalRecords = res.pagination.TotalRecords;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.loading = false;
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.gridApi?.hideOverlay();
        this.displayRowData = [];
        this.loading = false;
        this.spinnerService.stop();
      },
    });
  }

  onBack() {
    this.route.navigate(['/model-list/connect'])
  }

  dollarFormatter(params: any) {
    const data = params.value;
    const num1 = Number(data != null ? data.toString().split('.')[0] : '')
    const num = Number(data != null ? data.toString().split('.')[1] : '')
    if (typeof data === 'undefined' || data === null) {
      return '-';
    } else if ((data !== null) && (num > 0)) {
      return '$' + data;
    }
    else {
      return '$' + num1;
    }
  }

  nullToDashFormatter(params: any) {
    const data = params.value;
    const num1 = Number(data != null ? data.toString().split('.')[0] : '')
    const num = Number(data != null ? data.toString().split('.')[1] : '')
    if (typeof data === 'undefined' || data === null) {
      return '-';
    }
    else if ((data !== null) && (num === 0)) {
      return num1;
    }
    else {
      return data;
    }
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = [];
      for (let i = 0; i < Math.ceil(this.totalRecords / 100); i++) {
        (this.cPage = i + 1); (this.tPage = 1); (this.rpPage = 100);
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    } else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.apiCall();
    }
  }


  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.apiCall();
      if (this.apiSearch) {
        this.totalRecords = this.gridApi.getDisplayedRowCount();
        this.apiSearch = false;
      }
      resolve();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit('autoWidth');
    this.gridApi?.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );
    this.gridColumnApi?.applyColumnState(this.sortModel);
  }

  onSortChanged(event: SortChangedEvent) {
    this.gridApi?.showLoadingOverlay();
    const sortedColumns: Column[] | undefined = event.columnApi
      ?.getAllColumns()
      ?.filter((col) => col.getSort());
    if (sortedColumns) {
      for (const column of sortedColumns) {
        const colId = column.getColDef()?.field;
        const direction = column.getSort()?.toUpperCase();
        const existingSort = this.sortModel.find(
          (sort) => sort.colId === colId
        );
        if (existingSort) {
          if (existingSort.sort !== direction) {
            existingSort.sort = direction;
          }
        } else {
          this.sortModel.push({ colId, sort: direction });
        }
      }
      this.gridColumnApi?.applyColumnState(this.sortModel);
      this.sortModel.forEach((sort) => {
        const coldef = this.columnDefs.find((col) => col.field === sort.colId);
        const index = this.columnDefs.findIndex(
          (col: any) => col.field === sort.colId
        );
        if (coldef) {
          coldef.sort = sort.sort.toLowerCase();
        }
        this.columnDefs[index] = coldef;
      });
      this.gridApi?.setColumnDefs(this.columnDefs);
      this.sizeToFit();
    }
  }

  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }

  onSearch() {
    const plantCheck = this.selectPlant.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((!plantCheck)) {
      this.haveErrors = true
      this.errorList = ["Please select valid plant name"];
    }
    else {
    this.errorList = []
    this.apiCall();
  }
  }  

  help() {
    this.route.navigate(['./master-help', 2, "Tracking", 35])
  }
}