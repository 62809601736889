import { Component, OnInit } from '@angular/core';
import { BackUpDefineService } from 'src/app/services/back-up-define.service';
import { DefineSelectBtnRender } from 'src/app/core/gridrender/define-select-btn-render';
import { RegexFilter } from 'src/app/core/regexFilter/regex-filter';

@Component({
  selector: 'app-select-back-up-define',
  templateUrl: './select-back-up-define.component.html',
  styleUrls: ['./select-back-up-define.component.scss'],
  providers: [RegexFilter]
})
export class SelectBackUpDefineComponent implements OnInit {

  public search: any;
  public model: any = '';
  public part: any = '';
  public rowData: any[] = [];

  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 0;
  currentPageBoolean = false;
  displayRowData: any[] = [];
  totalPage = 0;
  currentPageForTotalPage = 0;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any
  icons: {};
  rowHeight = 40;
  paginationPageSize: any = 50
  isAccessible = false;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  supplierCode: any;
  defineData: any;
  siteItems: any;
  sortValue: any;
  filteredData: any[] = [];
  params: any;
  searchValue: any;
  originalData: any;

  constructor(private readonly SelectService: BackUpDefineService, public regex: RegexFilter) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,

      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };

  }

  ngOnInit(): void {
    this.SelectService.loadSearch().subscribe((res: any) => {
      this.searchValue = res[0].value

      this.search = res;
    });

    this.defineData = JSON.parse(sessionStorage.getItem('queryParams') || '');
    this.SelectService.loadSelect(this.defineData).subscribe((res: any) => {
      this.displayRowData = res
      this.filteredData = res
      this.gridApi?.setRowData(this.displayRowData)
      this.rowCount = this.displayRowData.length;
    })

    this.columnDefs = [
      {
        headerName: "SELECT", field: "select_define", autoHeight: true, cellRendererFramework: DefineSelectBtnRender,
        cellRendererParams: {
          clicked: (params: any) => {
            this.defineData = JSON.parse(sessionStorage.getItem('queryParams') || '');

            const query = {
              'plantCode': params.plantCode,
              'partNumber': params.partNumber,
              'supplierCode': params.supplierCode,
              'pkgStartDate': params.pkgStartDate,
              'modelCode': params.model,
              'plantCodeDefine': this.defineData.plantCode,
              'partNumberDefine': this.defineData.partNumber,
              'pkgStartDateDefine': this.defineData.pkgStartDate,
            };

            sessionStorage.setItem('queryParamsSelect', JSON.stringify(query));
          }
        }
      },
      {
        headerName: "MODEL", field: "model", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "PLANT", field: "plantName", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "PART#", field: "partNumber", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "PART DESC ", field: "partDesc", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "PKG START DATE", field: "pkgStartDate", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "CONTAINER ", field: "container", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "DUNNAGE ", field: "dunnage", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "CONTAINER DIMS (L/W/H)", field: "containerLWH", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "CONTAINER WEIGHT", field: "containerWeight", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
      {
        headerName: "QPC", field: "QPC", floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: true,
        filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, autoHeight: true,
      },
    ];
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  goToNexpage() { this.gridApi.paginationGoToNextPage() }
  goToPreviousPage() { return this.gridApi.paginationGoToPreviousPage() + 1 }
  goToFirstPage() { return this.gridApi.paginationGoToFirstPage() }
  goToLastPage() { return this.gridApi.paginationGoToLastPage() + 1 }

  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();
    if (this.currentPageNo === 0) { return true; }
    else { return false; }
  }

  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage = Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    }
    else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
      this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: any) {
    if (event.target.value === "ALL") {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(this.displayRowData.length));
    } else {
      this.paginationPageSize = event.target.value;
      this.gridApi?.paginationSetPageSize(Number(event.target.value));
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.getCurrentPage();
  }

  setText(selector: string, text: any) {
    if (this.rowCount > 0) {
      const element = document.querySelector(selector);
      if (element !== null) {
        (element as HTMLElement).innerHTML = text;
      }
    }
  }
  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  onSearch() {
    let filteredData = this.displayRowData;
    const searchParams: any = {
      plantName: this.searchValue, model: this.model,partNumber: this.part
    };
    if (searchParams.plantName === 'All') {
      searchParams.plantName = '';
    }
    const filterParams: any = {
      plantName: this.searchValue ? this.searchValue : '*',model: this.model ? this.model : '*',
      partNumber: this.part ? this.regex.addStarForPartNumber(this.part) : '*',
    }
    if (filterParams.plantName === 'All') {
      filterParams.plantName = '*';
    }
    filteredData = this.regex.regexFilter(filteredData, filterParams)
    this.gridApi.setRowData(filteredData);
    this.rowCount = filteredData.length;
  }
}
