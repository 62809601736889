<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="d-flex justify-content-between align-items-center m-2 header">
    <div class="page-title">Download Authorization </div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
  </div>
  <div  id='err' class="alert alert-danger mx-2 mt-2" *ngIf="errorList.length > 0">
    <span  >{{errorList}}</span>
  </div>
  <div id='err' class="alert alert-success mx-2 mt-2" *ngIf="successMessage.length > 0">
    <span  >{{successMessage}}</span>
  </div>

  <div class="popup-contents m-3">
    <div class="filter mx-1 d-flex m-3">
      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="ringi" class="col-4">Ringi#</label>
        <input type="text" id="ringi" [(ngModel)]="ringi" />
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="plant" class="col-4">Plant</label>
        <mat-select id="plant" class="mat-sel" [(ngModel)]="plantVal" placeholder="Select">
          <mat-option *ngFor="let plant of plants" [value]="plant.plantcode">{{plant.plant}}
          </mat-option>
        </mat-select>
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="model-code" class="col-4">Model</label>
        <input type="text" id="model-code" [(ngModel)]="modelCode" />
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="forecast" class="col-4">Forecast</label>
        <input type="text" id="forecast" [(ngModel)]="forecast" />
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="model-code" class="col-4">Part#</label>
        <input type="text" id="part-number" class="w-90" [(ngModel)]="partNum1">
        <input type="text" class="ms-1 w-90" id="part-number" [(ngModel)]="partNum2">
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="specialist" class="col-4">Specialist</label>
        <input type="text" [(ngModel)]="specCode" [ngModelOptions]="{ standalone: true }" />
        <button mat-icon-button>
          <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
        </button>
      </div>
      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="supplier" class="col-4">Supplier</label>
        <input type="text" id="supplier"  [(ngModel)]="supplierCode" />
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="sop-from-date" class="col-4">Auth From Date <span class="required-field"></span></label>
        <input type="date" id="sop-from-date" style="width: 100%" [(ngModel)]="authFrom" />
      </div>
      <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="sop-to-date" class="col-4">Auth To Date <span class="required-field"></span></label>
        <input type="date" id="sop-to-date" style="width: 100%" [(ngModel)]="authTo" />
      </div>
      <div class="d-flex align-items-center col-md-10 gap-3">
        <label class="checkcontainer mb-0">Positive
          <input type="checkbox" checked [(ngModel)]="positive">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Negative
          <input type="checkbox" checked [(ngModel)]="negative">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Wait Cancel
          <input type="checkbox" checked [(ngModel)]="waitCancel">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Returnable Package
          <input type="checkbox" checked [(ngModel)]="retPkg">
          <span class="checkmark"></span>
        </label>
        <label class="checkcontainer mb-0">Expendable Package
          <input type="checkbox" checked [(ngModel)]="expPkg">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="footer-dialog d-flex px-3" style="height: 50px;">
    <div mat-dialog-close class="cancel" (click)="closeDialog()">Cancel</div>
    <button class="black-button me-3" (click)="downloadBtn()">OK</button>
  </div>
</div>