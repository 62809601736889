<div class="dialog-wrapper col">
  <div class="header px-3">
    <span class="page-title">Select Package</span>
    <button class="black-button" (click)="help()">help</button>
  </div>
  <app-notification [codes]="errorMessage" *ngIf="errorMessage"></app-notification>
  <div id='err' class="alert alert-danger m-3" *ngIf="errMsg !== ''">
    <span>{{errMsg}}</span>
  </div>
  <div class="p-3">
    Package Type:
    <span class="fw-bold mx-2">{{ packageType }}</span>
  </div>
  <div class="filter-params d-flex flex-wrap align-items-center p-3 pt-0" [formGroup]="searchPackage" (keyup.enter)="onSearch()">
    <div class="col-lg-2 col-md-6 mt-2">
      <label for="code">Code</label>
      <input type="text" id="code" class="col-6 ms-2" formControlName="code" />
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="type">Type</label>
      <mat-select formControlName="type" id="type" class="mat-sel pt-2 col-6 ms-2" disableOptionCentering>
        <mat-option *ngFor="let type of typeDropdown" [value]="type.packagetypecode">{{
          type.packagetype
          }}</mat-option>
      </mat-select>
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="material">Material</label>
      <mat-select formControlName="material" id="material" class="mat-sel pt-2 col-6 ms-2" disableOptionCentering>
        <mat-option *ngFor="let material of materialDropdown" [value]="material.value">{{ material.name }}</mat-option>
      </mat-select>
    </div>
    <div class="col-lg-3 col-md-6 mt-2">
      <label for="sn">S/N</label>
      <mat-select formControlName="sn" id="sn" class="mat-sel pt-2 col-6 ms-2" disableOptionCentering>
        <mat-option value="">All</mat-option>
        <mat-option value="S">S:Standard</mat-option>
        <mat-option value="N">N:Non Standard</mat-option>
      </mat-select>
    </div>
    <button class="black-button col-lg-1 col-md-6 mt-2" (click)="onSearch()">
      Search
    </button>
    <div class="col-lg-11 col-md-6 mt-2  ">
      <input type="radio" id="outside" value="outside" formControlName="dimension" class="mx-2" />
      <label for="outside" class="me-3">Outside Dimension</label>
      <input type="radio" id="inside" value="inside" formControlName="dimension" class="mx-2" />
      <label for="inside" class="me-3">Inside Dimension</label>
    </div>
    <button class="black-button col-lg-1 col-md-6 mt-2" (click)="newPackage()">
      New
    </button>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Length(mm)</span>
      <label for="l-over">Over</label>
      <input type="text" id="l-over" class="col-4 mx-2" formControlName="lengthOver" style="width: 70px" />
      <label for="l-under">Under</label>
      <input type="text" id="l-under" class="col-4 mx-2" formControlName="lengthUnder" style="width: 70px" />
    </div>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Width(mm)</span>
      <label for="w-over">Over</label>
      <input type="text" id="w-over" class="col-4 mx-2" formControlName="widthOver" style="width: 70px" />
      <label for="w-under">Under</label>
      <input type="text" id="w-under" class="col-4 mx-2" formControlName="widthUnder" style="width: 70px" />
    </div>
    <div class="col-lg-4 col-md-6 col-12 mt-2 floating">
      <span>Height(mm)</span>
      <label for="h-over">Over</label>
      <input type="text" id="h-over" class="col-4 mx-2" formControlName="heightOver" style="width: 70px" />
      <label for="h-under">Under</label>
      <input type="text" id="h-under" class="col-4 mx-2" formControlName="heightUnder" style="width: 70px" />
    </div>
  </div>
  <div class="grid-agg p-3">
    <ag-grid-angular style="width:100%;height:55vh;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
      (window:resize)="sizeToFit()" [rowHeight]="30" [animateRows]="true" [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true" [showOpenedGroup]="true"
      [gridOptions]="gridOptions" [suppressAggFuncInHeader]='true' [suppressHorizontalScroll]="false"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
      [headerHeight]="37" [overlayLoadingTemplate]="overlayLoadingTemplate"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate" [domLayout]="domLayout" [rowClassRules]="rowClassRules"
      [floatingFiltersHeight]="40">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>
  </div>
  <button (click)="closeDialog()" class="black-button close-btn">
    &times;
  </button>
</div>