import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'selectAll',
  template: `
    <div *ngIf="!params.showActive">
      <input 
        type="checkbox" 
        (click)="checkedHandler($event)"
        disabled
        [checked]="this.isChecked === 1"
      />
    </div>
  `,
})
export class PartListCheckBoxDetailRendered implements ICellRendererAngularComp {
    activeInActiveChanges:any=[];
  
    refresh(_params: ICellRendererParams): boolean {
      return true;
    }

    public params: any;
    public showActive:any;
    public isChecked:any;

    agInit(params: any): void {
      this.params = params;
      this.showActive=params.showActive
      if(this.params.column.colId ==='isActive' && this.params.data.isactive==='Y'){
        this.isChecked = 1;
      }
      else{
        this.isChecked = 0;
      }
    }

    checkedHandler(event:any) {
      const colId = this.params.column.colId;
      if (event.target.checked === true) {
        this.params.node.data[colId] = 1;
      } else {
        this.params.node.data[colId] = 0;
      }
    this.btnClickedHandler(this.params.data)
    }

    btnClickedHandler(_event: any) {
      this.params?.clicked(this.params.data);
    }
  
  }

