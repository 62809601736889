import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/update-user/update-user.component';

@Component({
  selector: 'internal-list-btn-cell-renderer',
  template: `
    

    <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table"
    style="width:85px; height:30px; color:#676a6e; font-size: 11px"
    (click)="btnClickedHandler($event)">{{buttonName}}</button>
  `,
})

export class InternalListBtnCellRender implements ICellRendererAngularComp {
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog,) { }
  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === '') {
      this.buttonName = 'SELECT';
    }
    else if (params.colDef.headerName === 'UPDATE') {
      this.buttonName = 'UPDATE';
    }
    else if (params.colDef.headerName === 'Delete') {
      this.buttonName = 'DELETE';
    }
  }
  btnClickedHandler(_event: any) {
    if (this.params.colDef.headerName === '') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }

    else if (this.params.colDef.headerName === 'UPDATE') {
      this.dialog.open(UpdateUserComponent, {
        data: { mode: 'Update' }
      })
    }
  }
}