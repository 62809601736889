import { Component, OnInit } from '@angular/core';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';
import * as ExcelJs from 'exceljs';

@Component({
  selector: 'app-tracking-correction',
  templateUrl: './tracking-correction.component.html',
  styleUrls: ['./tracking-correction.component.scss'],
})
export class TrackingCorrectionComponent implements OnInit {
  panelOpenState = true
  panelOpenState2 = true
  panelOpenState3 = true
  fileName = ''
  partInfo: any[] = []
  partInfoContents: any[] = []
  statusTitle: any[] = []
  rowClassRules: any
  paramData: any
  btnNextDisabled = false
  btnPrevDisabled = false
  stepStatus: any
  chkAuthCancelDisabled = false
  filPrePPFDisabled = false
  chkAuthCancelChecked = false
  initialStatus: any[] = []
  sendPPFStatus: any[] = []
  receivePreStatus: any[] = []
  approvePPFStatus: any[] = []
  orderStatus: any[] = []
  shipStatus: any[] = []
  trialApprovedStatus: any[] = []
  receivesFinalStatus: any[] = []
  approvalStatus: any[] = []
  kgAuthStatus: any[] = []
  selinitialStatus: any
  selSendPPFStatus: any
  selReceivePreStatus: any
  selApprovePPFStatus: any
  selOrderStatus: any
  selShipStatus: any
  selTrialApprovedStatus: any
  selReceivesFinalStatus: any
  selFinalApprovalStatus: any
  selPkgAuthStatus: any
  spCode: any
  stepDates: any
  stepDateArray: any[] = []
  excelBase64: any
  currentStep = 0
  imageArray: any[] = [];
  errorMessage: any = null;
  DATE_FORMAT = 'MM/dd/YYYY';
  err = '099  - 001 :Runtime Error : Contact a System Administrator.'
  isEmpty = false;

  constructor(
    private readonly trackingListService: TrackingListService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {

    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '')
    this.route.queryParams.subscribe((_params: any) => {
      this.paramData = JSON.parse(sessionStorage.getItem('trackingParams') || '{}')
      this.getCorrection()
    })
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }

    this.partInfo = ['Plant', 'Model Code', 'Part Number', 'Supplier']

    this.statusTitle = [
      'Initial Part List',
      'Send PPF to Supplier',
      'Toyota Receives Pre PPF',
      'Toyota Approves Pre Ppf',
      'Trial Order',
      'Ship To Toyota',
      'Package Trial Approved',
      'Toyota Receives Final Ppf',
      'Final PPF Approval',
      'Package Authorized',
    ]
  }

  async onFileSelected(event: any) {
    this.imageArray = [];
    this.errorMessage = null;

    const file: File = event.target.files[0]
    if (file) {
      this.fileName = file.name
      const extension = this.fileName
        .substring(this.fileName.lastIndexOf('.'))
        .toUpperCase()
      if (extension === '.XLSX') {
        this.extractImageFromExcel(file)
      } else {
        this.errorMessage = 'Please select a valid excel file.'
      }
      if (this.fileName.length > 14) {
        this.fileName = `${this.fileName.slice(0, 8)} ... ${this.fileName.slice(-6)}`
      }

      const formData = new FormData()
      formData.append('thumbnail', file)
      const filereader = new FileReader()
      filereader.readAsDataURL(file)
      filereader.onload = () => {
        const base64 = filereader?.result
        this.excelBase64 = ((filereader.result) as string)?.split(',')[1]
        return base64
      }
    }
  }

  async extractImageFromExcel(file: any) {
    const workbook: any = new ExcelJs.Workbook();
    try {
      const arrayBuffer = await this.readFileAsArrayBuffer(file);
      await workbook.xlsx.load(arrayBuffer);
      workbook.worksheets.forEach((worksheet: any) => {
        worksheet.getImages().forEach((image: any) => {
          const img: any = workbook.model.media.find(
            (m: any) => m.index === image.imageId,
          )

          if ([1, 4, 7, 10].includes(image.range.tl.nativeCol)) {
            const blob = new Blob([img?.buffer], { type: `${img.type}/${img.extension}` })
            this.convertToBase64(blob, image.range.tl.nativeCol);
          }
        });
      });
    } catch (error) {
      this.errorMessage = `Error reading Excel file: ${error}`;
    }
  }

  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        resolve(arrayBuffer);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  convertToBase64(blob: Blob, position: number) {
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (_e: any) => {
        if (reader.result) {
          const base64: any = reader.result;
          if (position === 1) {
            this.imageArray[0] = base64.split(',')[1];
          } else if (position === 4) {
            this.imageArray[1] = base64.split(',')[1];
          } else if (position === 7) {
            this.imageArray[2] = base64.split(',')[1];
          } else if (position === 10) {
            this.imageArray[3] = base64.split(',')[1];
          }
        }
      }
    }
  }

  getCorrection() {
    this.spinnerService.start()
    this.errorMessage = null;
    const correctionObj = {
      PlantCode: this.paramData?.plantcode,
      ModelCode: this.paramData?.modelcode,
      SupplierCode: this.paramData?.suppliercode,
      PartNumber: this.paramData?.partnumber,
      PkgStartDate: this.paramData?.pkgstartdate,
    }
    this.trackingListService.getCorrection(correctionObj).subscribe({
      next: (res: any) => {
        if (res[0]?.status === 0) {
          this.errorMessage = res[0]?.result;
          this.isEmpty = true;
          window.scrollTo(0, 0);
        }
        else {
          this.isEmpty = false;
          this.partInfoContents = [
            this.paramData?.plantname,
            this.paramData?.modelcode,
            this.paramData?.partnumber,
            this.paramData?.suppliername,
          ]
          this.initialStatus = res.initialStatus
          this.sendPPFStatus = res.sendPPFStatus
          this.receivePreStatus = res.receivePreStatus
          this.approvePPFStatus = res.approvePPFStatus
          this.orderStatus = res.orderStatus
          this.shipStatus = res.shipStatus
          this.trialApprovedStatus = res.trialApprovedStatus
          this.receivesFinalStatus = res.receivesFinalStatus
          this.approvalStatus = res.approvalStatus
          this.kgAuthStatus = res.kgAuthStatus
          this.stepStatus = res.htmlSelectStatus[0]
          this.stepDates = res.stepDateData[0]
          /* FOR DIABLING NEXT AND PREV BUTTONS */

          // If the Tracking status is Step03, Next button will be available.
          // stepStatus[2] means Step03 and the following is number descriptions.
          // 1:[not open], 2:[Working], 3:[Non], 4:[Complete], 5:[Skip]
          // 6:[Save PPF], 7:[Can submit], 8:[not open], 9:[Confiming], 10:[Approve], 11:[Reject]

          if (
            this.checkStatusForSupplier(Number(this.stepStatus.step03status)) &&
            this.stepStatus.step04status === '0'
          ) {
            this.btnNextDisabled = false
          } else if (
            this.checkStatusForSupplier(Number(this.stepStatus.step06status)) &&
            this.stepStatus.step07status === '0'
          ) {
            this.btnNextDisabled = false
          } else if (
            this.checkStatusForSupplier(Number(this.stepStatus.step08status)) &&
            this.stepStatus.step09status === '0'
          ) {
            this.btnNextDisabled = false
          } else {
            this.btnNextDisabled = true
          }

          if (
            this.stepStatus.step02status !== '4' &&
            this.stepStatus.step02status !== '12' &&
            this.stepStatus.step02status !== '13'
          ) {
            this.btnPrevDisabled = true
          }

          if (this.stepStatus.step10status === '1') {
            this.btnPrevDisabled = false
            this.btnNextDisabled = true
          }

          if (this.stepStatus.step10status === '5') {
            this.btnPrevDisabled = true
            this.btnNextDisabled = true
          }

          // Step6 : not open, working, confirm -> Prev Next are disabled
          if (
            this.stepStatus.step06status === '1' ||
            this.stepStatus.step06status === '2' ||
            this.stepStatus.step06status === '7' ||
            this.stepStatus.step06status === '8' ||
            this.stepStatus.step06status === '9'
          ) {
            this.btnPrevDisabled = true
            this.btnNextDisabled = true
          }
          if (this.paramData.plantcode === '21TMC' &&  this.stepStatus.step10status !== '0')      
           {
            this.btnPrevDisabled = false
          }
          this.createHtmlSelectStatus();
        }
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  createHtmlSelectStatus() {
    // select tracking status
    if (
      this.stepStatus.step10status === '1' ||
      this.stepStatus.step10status === '5'
    ) {
      this.chkAuthCancelDisabled = false
      this.filPrePPFDisabled = true
    } else {
      this.chkAuthCancelDisabled = true
    }

    if (this.stepStatus.step10status === '5') {
      // when step10 status is SKIP(5)
      this.chkAuthCancelChecked = true
    } else {
      this.chkAuthCancelChecked = false
    }

    const TRACKING_CORRECTION_STEP_SIZE = 10 // size of tracking step

    const stepStatusArray = Object.entries(this.stepStatus)

    for (let i = 0; i < TRACKING_CORRECTION_STEP_SIZE; i++) {
      if (
        stepStatusArray[i][1] !== '0' &&
        stepStatusArray[i][1] !== '4' &&
        stepStatusArray[i][1] !== '5'
      ) {
        this.currentStep = i + 1 // current step
      }

      if (
        stepStatusArray[i][1] === '6' ||
        stepStatusArray[i][1] === '7' ||
        stepStatusArray[i][1] === '8' ||
        stepStatusArray[i][1] === '9' ||
        stepStatusArray[i][1] === '10' ||
        stepStatusArray[i][1] === '11'
      ) {
        stepStatusArray[i][1] = '2'
      }

      let flag = false;
      if (i === 0) {
        this.initialStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selinitialStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 1) {
        this.sendPPFStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selSendPPFStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 2) {
        this.receivePreStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selReceivePreStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 3) {
        this.approvePPFStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selApprovePPFStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 4) {
        this.orderStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selOrderStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 5) {
        this.shipStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selShipStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 6) {
        this.trialApprovedStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selTrialApprovedStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 7) {
        this.receivesFinalStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selReceivesFinalStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 8) {
        this.approvalStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selFinalApprovalStatus = flag ? Number(stepStatusArray[i][1]) : 0
      } else if (i === 9) {
        this.kgAuthStatus.forEach((e: any) => {
          if(e.value === Number(stepStatusArray[i][1])){
            flag = true;
          }
        });
        this.selPkgAuthStatus = flag ? Number(stepStatusArray[i][1]) : 0
      }
    }
  }

  checkStatusForSupplier(status: any) {
    if (
      status === 1 ||
      status === 2 ||
      status === 6 ||
      status === 7 ||
      status === 8 ||
      status === 9 ||
      status === 10 ||
      status === 11
    ) {
      return true
    }
    return false
  }

  onChange(event: any){
    this.chkAuthCancelChecked = event.checked ? true : false;
  }

  submitCorrection() {
    this.errorMessage = null;
    this.spinnerService.start()
    this.stepDateArray = Object.entries(this.stepDates)
    const correctionObj = {
      FromDate: this.paramData.pkgstartdate,
      PlantCode: this.paramData.plantcode,
      ModelCode: this.paramData.modelcode,
      PartNumber: this.paramData.partnumber,
      SupplierCode: this.paramData.suppliercode,
      chkAuthCancel: this.chkAuthCancelChecked,
      chkAuthCancel_Disabled: this.chkAuthCancelDisabled,
      PkgAuthStatus: this.selPkgAuthStatus.toString(),
      SpecialistCode: this.spCode,
      htmlselect: [
        { id: "InitialStatus", Value: this.selinitialStatus.toString() },
        { id: "SendPPFStatus", Value: this.selSendPPFStatus.toString() },
        { id: "ReceivePreStatus", Value: this.selReceivePreStatus.toString() },
        { id: "ApprovePPFStatus", Value: this.selApprovePPFStatus.toString() },
        { id: "OrderStatus", Value: this.selOrderStatus.toString() },
        { id: "ShipStatus", Value: this.selShipStatus.toString() },
        { id: "TrialApprovedStatus", Value: this.selTrialApprovedStatus.toString() },
        { id: "ReceivesFinalStatus", Value: this.selReceivesFinalStatus.toString() },
        { id: "FinalApprovalStatus", Value: this.selFinalApprovalStatus.toString() }
      ],
      htmlhiddate: [
        {
          id: 'hidInitial',
          Value: formatDate(this.stepDateArray[0][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidSend',
          Value: formatDate(this.stepDateArray[1][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidRecPre',
          Value: formatDate(this.stepDateArray[2][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidAppPre',
          Value: formatDate(this.stepDateArray[3][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidOrder',
          Value: formatDate(this.stepDateArray[4][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidShip',
          Value: formatDate(this.stepDateArray[5][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidPkg',
          Value: formatDate(this.stepDateArray[6][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidRecFinal',
          Value: formatDate(this.stepDateArray[7][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
        {
          id: 'hidFinal',
          Value: formatDate(this.stepDateArray[8][1], this.DATE_FORMAT, 'en-US', 'UTC'),
        },
      ],
      CurrentStep: this.currentStep,
      excelFile: this.excelBase64 ? this.excelBase64 : '',
      imageFile1: this.imageArray[0] ? this.imageArray[0] : '',
      imageFile2: this.imageArray[1] ? this.imageArray[0] : '',
      imageFile3: this.imageArray[2] ? this.imageArray[0] : '',
      imageFile4: this.imageArray[3] ? this.imageArray[0] : '',
    }
    this.trackingListService.submitCorrection(correctionObj).subscribe({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          this.router.navigateByUrl('/tracking-list')
        }
        this.spinnerService.stop()
      },
      error: (err: any) => {
        this.spinnerService.stop()
        this.errorMessage = err.error?.error?.message ?? this.err
        window.scrollTo(0, 0);
      },
      complete: () => {
        this.spinnerService.stop()
      },
    })
  }

  onCancel() {
    this.router.navigateByUrl('/tracking-list')
  }

  previous() {
    const stepStatus = this.getStepStatus();
    if (stepStatus[2] === 5 && this.checkStatusForSupplier(stepStatus[7])) {
      this.selReceivesFinalStatus = 0
      this.selTrialApprovedStatus = 0
      this.selShipStatus = 0
      this.selOrderStatus = 0
      this.selApprovePPFStatus = 0
      this.selReceivePreStatus = 0
      this.selSendPPFStatus = 1
      this.btnNextDisabled = true
    } else if (stepStatus[4] === 5 && this.checkStatusForSupplier(stepStatus[7])) {
      this.selReceivesFinalStatus = 0
      this.selTrialApprovedStatus = 0
      this.selShipStatus = 0
      this.selOrderStatus = 0
      this.selApprovePPFStatus = 1
      this.btnNextDisabled = true
    } else if (this.checkStatusForSupplier(stepStatus[2])) {
      this.selReceivePreStatus = 0
      this.selSendPPFStatus = 1
      this.btnNextDisabled = true
    } else if (stepStatus[3] === 1 || stepStatus[3] === 2 || stepStatus[3] === 3) {
      this.selApprovePPFStatus = 0
      this.selReceivePreStatus = 1
      this.btnNextDisabled = false
    } else if (stepStatus[4] === 1 || stepStatus[4] === 2 || stepStatus[4] === 3 || stepStatus[4] === 6 || stepStatus[4] === 7 || stepStatus[4] === 8) {
      this.selOrderStatus = 0
      this.selApprovePPFStatus = 1
      this.btnNextDisabled = true
    } else if (stepStatus[5] === 1 || stepStatus[5] === 2 || stepStatus[5] === 6 || stepStatus[5] === 7) {
      this.selShipStatus = 0
      this.selOrderStatus = 1
      this.btnNextDisabled = true
    } else if (stepStatus[6] === 1 || stepStatus[6] === 2 || stepStatus[6] === 3) {
      this.selTrialApprovedStatus = 0
      this.selShipStatus = 1
      this.btnNextDisabled = false
    } else if (this.checkStatusForSupplier(stepStatus[7])) {
      this.selReceivesFinalStatus = 0
      this.selTrialApprovedStatus = 0
      this.selShipStatus = 0
      this.selOrderStatus = 1
      this.btnNextDisabled = true
    } else if (stepStatus[8] === 1 || stepStatus[8] === 2 || stepStatus[8] === 3) {
      this.selReceivesFinalStatus = 1
      this.selFinalApprovalStatus = 0
      this.btnNextDisabled = false
    } else if (stepStatus[1] !== 4) {
      if (stepStatus[1] === 12 || stepStatus[1] === 13) {
        this.selSendPPFStatus = 1
        this.btnNextDisabled = true
        this.btnPrevDisabled = true
      } else {
        this.btnNextDisabled = true
        this.btnPrevDisabled = true
      }
    } else if (stepStatus[9] === 1) {
      this.selFinalApprovalStatus = 1
      this.selPkgAuthStatus = 0
    } else if(this.paramData.plantcode==='21TMC' && stepStatus[9] !==0 && ( stepStatus[9] !==1 || stepStatus[9] !==5)){
      this.submitCorrection()
    }
  }

  next() {
    const stepStatus = this.getStepStatus();
    if (this.checkStatusForSupplier(stepStatus[2]) && stepStatus[3] === 0) {
      this.selReceivePreStatus = 4
      this.selApprovePPFStatus = 1
      this.btnNextDisabled = true
    } else if (this.checkStatusForSupplier(stepStatus[5]) && stepStatus[6] === 0) {
      this.selShipStatus = 4
      this.selTrialApprovedStatus = 1
      this.btnNextDisabled = true
    } else if (this.checkStatusForSupplier(stepStatus[7]) && stepStatus[8] === 0) {
      this.selReceivesFinalStatus = 4
      this.selFinalApprovalStatus = 1
      this.btnNextDisabled = true
    }
  }

  getStepStatus() {
    const data: any[] = [];
    data[0] = this.selinitialStatus;
    data[1] = this.selSendPPFStatus;
    data[2] = this.selReceivePreStatus;
    data[3] = this.selApprovePPFStatus;
    data[4] = this.selOrderStatus;
    data[5] = this.selShipStatus;
    data[6] = this.selTrialApprovedStatus;
    data[7] = this.selReceivesFinalStatus;
    data[8] = this.selFinalApprovalStatus;
    data[9] = this.selPkgAuthStatus;

    return data;
  }

  nullInt(obj: any) {
    if (obj === null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 31])
  }
}
