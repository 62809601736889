import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-list-backup',
  templateUrl: './part-list-backup.component.html',
  styleUrls: ['./part-list-backup.component.scss']
})
export class PartListBackupComponent implements OnInit {
  result: any;
  backupPart: any;
  ppfResult: any;
  imagePath:any;
  convertedImg: any;
  errorList: string[] = [];
  @Output() backupLoad = new EventEmitter();
  
  constructor(public backUp: PartListService,private readonly router: Router,private readonly spinnerService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.spinnerService.start();
    this.backupPart=JSON.parse(sessionStorage.getItem('backUp')|| '{}')
     this.backUp.backUpOnLoad(this.backupPart).subscribe( {
      next: (res: any) => {
       this.backupLoad.emit(res);
      this.result=res.BackupSpec[0]
      this.ppfResult=res.PPFSpec[0]
      this.imagePath=res.Image
      this.convertedImg='data:image/jpg;base64,'+this.imagePath
      },
      error: (err: any) => {
        this.errorList=[err.message]
        this.spinnerService.stop();
      },
 });

  }
  getResult(result:any): any {
    
    switch (+result) {
      case -1:
        return 'Working';
      case 0:
        return 'Need Approval';
      case 1:
        return 'Approved Backup';
      case 2:
        return 'Rejected Backup';
      
      default:
        return '';
    }
  }

  help(){
    this.router.navigate(['./master-help',2,"Tracking",39])
  
  }

close(){
  this.errorList = [];

}


}
