import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { Router } from '@angular/router';
import { DeliveryTargetComponent } from '../../pkg-authorization/do-pkg-authorization/delivery-target/delivery-target.component';
import { MatDialog } from '@angular/material/dialog';
import { DoPkgAuthorizationComponent } from '../../pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';
import { Location } from '@angular/common';
import { ConstantsService } from 'src/app/services/constant.service';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'app-tracking-do-forecast',
  templateUrl: './tracking-do-forecast.component.html',
  styleUrls: ['./tracking-do-forecast.component.scss'],
  providers: [DoPkgAuthorizationComponent]
})
export class TrackingDoForecastComponent implements OnInit {
  receiveFromToyota: any;
  orderInformation: any;
  reason: any;
  foreCastDate: any;
  packageReview: any;
  data: any;
  authNumber: any;
  dhFlag = false;
  serialFlag = false;
  getStatus: any;
  btnDisabled = false;
  cancelNGFlag = false;
  authDownloadFlag = false;
  which = 0;
  forecastEnable = false;
;
  cancelOKFlag = false;
  okFlag = false;
  approvalFlag = false;
  rejectFlag = false;
  constructor(
    private readonly trackingService: TrackingService,
    public router: Router,
    public dialog:MatDialog, 
    public dopkgauth: DoPkgAuthorizationComponent,
    public location:Location,
    private readonly constants: ConstantsService,
    protected globalState: GlobalStateService
  ) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('queryParamsForecast') || '');
    if(this.data){   
      if([6,11,12].includes(this.data?.stepNumber)){
        this.authDownloadFlag = true;
      } 
    }
    this.forecastOnload();
  }
  goBack() {
    this.location.back()
  }

  cancelOk() {
    this.trackingService.cancelOk(this.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
    })
  }

  cancelNg() {
    this.trackingService.cancelNg(this.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
    })
  }

  disableLogic(){    
    if(this.data && this.data.stepNumber) {
      if([3,8].includes(this.data.stepNumber)) {
        if(!([6,7,9,10,11].includes(this.getStatus))) {
          this.btnDisabled = true;
        }else{
          this.btnDisabled = false
        }
      } else if([6,11,12].includes(this.data.stepNumber)) {
        if(this.data.whichRequest !== this.data.supOrFab) {
          this.btnDisabled = true;
        }
        if((this.data.stepNumber === 6) && ([8,9,10,11].includes(this.getStatus))) {
          this.btnDisabled = false;
        }
      } else {
        if([2,6,7,9,10,11].includes(this.getStatus)) {
          this.btnDisabled = false
        } else {
          this.btnDisabled = true;
        }
      }
    }
  }

  checkButtons(){
    if(!([9,10,11].includes(this.getStatus))){
      if(this.data.cancelFlag && this.data.cancelFlag === "2"){
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      }else{
        this.okFlag = true;     
      }  
    }else if([9,10,11].includes(this.getStatus)){
      this.approvalFlag = true;
      this.rejectFlag = true;
    }
  }

  forecastOnload() {
    this.trackingService.forcastOnload(this.data).subscribe({
      next: (res) => { 
        if (this.data?.supOrFab === 0) {                   
            if (this.data?.supplierCode === res[0]?.orderInformation?.requestTo) {              
                this.which = 0;
            } else {              
                this.which = 1;
            }
        } else if (res[0]?.orderInformation?.fabcode === res[0]?.orderInformation?.requestTo) {
            this.which = 1;
        }
        if(this.data.whichRequest !== this.data.supOrFab) {
          this.forecastEnable = true;
        }
        //serial &         
        if((this.data?.stepNumber === 11) && (this.data?.supOrFab === this.which)){
          this.dhFlag = true;
        }
        //
        if(res[0].containerPackageType.length){
          if(res[0]?.containerPackageType[0].PACKAGETYPECODE === 'R' || res[0]?.containerPackageType[0].PACKAGETYPECODE === 'B'){
            this.serialFlag = true;
          }
        }

        this.getStatus = res[0]?.getStatus;
        this.authNumber = res[0]?.authNumber;        
        this.receiveFromToyota = res[0]?.receiveFromToyota;
        const obj = res[0]?.orderInformation;
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
           obj[key] = obj[key] === '*' ? '-' : obj[key]
          }
        }
        this.orderInformation = obj;
        this.reason = res[0]?.reason;
        this.packageReview = res[0]?.packageReview;
        this.foreCastDate = res[0]?.foreCastDate?.date;
      },
      complete: ()=>{
        this.checkButtons();
        this.disableLogic();
      }
    });
  }

  setUpdatedTrackingGrid(body:any){
    const {
      hidPartNumber: partNumber,
    } = body;
    const ppfCode = this.data?.ppfNumber;
    const mergedData = Object.values(this.globalState.trackingRowData).reduce((acc:any,val:any) => acc.concat(val),[]);
    if (mergedData) {
      mergedData.forEach(
        (row: {
          submitFlag: boolean;
          gridDate: string;
          STEP11STATUS: number;
          ISUPDATEPPF: number | null;
          STEP11DATE: string;
          PARTNUMBER: any;
          PPFCODE: any;
        }) => {
          if (row.PARTNUMBER === partNumber && row.PPFCODE === ppfCode) {
            row.ISUPDATEPPF = 1;
            row.STEP11STATUS = 7;
            row.submitFlag = true;
          }
        }
      );
    }
  }

  submit() {
    if(!this.foreCastDate) {
      alert("Enter the forecast date")
    }else{
      const req = {
        "hidPlantCode": this.data.plantCode,
        "hidPartNumber": this.data.partNumber,
        "hidSupplierCode": this.data.supplierCode,
        "hidPkgStartDate" :this.data.pkgStartDate,
        "hidModelCode": this.data.modelCode,
        "forecast": this.foreCastDate
      }
      this.trackingService.updateForcast(req).subscribe({
        next: (_res) => {
          this.setUpdatedTrackingGrid(req);
          this.router.navigate(["/tracking"]);       
        },
      });
    }
  }

  onCancel() {
    this.location.back();
  }

  authDownload(){
    const queryParams = {
      authNumber: this.authNumber,
      comment1: this.receiveFromToyota ? (this.receiveFromToyota.message ? this.receiveFromToyota?.message : '') : ''
    }
    sessionStorage.setItem('queryParamsDo', JSON.stringify(queryParams));
    this.dopkgauth.authDownload()
  }

  deliveryTarget(){
    const queryParams = {
      authNumber: this.authNumber,
    }
    sessionStorage.setItem('queryParamsDelv', JSON.stringify(queryParams));
    this.dialog.open(DeliveryTargetComponent, { width: '110%', height: '90%' })
  }
  ppf(){
    const query:any = {
      'plantCode': this.data?.plantCode, 
      'partNumber': this.data?.partNumber,
      'supplierCode' : this.data?.supplierCode,  
      'pkgStartDate': this.data?.pkgStartDate,  
      'ppfNumber' : this.data?.ppfNumber
    };
    const plantCode = this.constants.getTypeFromPlantCode(this.data.plantCode)
    if (plantCode === 'SERVICE') {
      this.router.navigate([`/tracking/serviceparts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    } else if (plantCode === 'ASSY') {
      this.router.navigate([`/tracking/accessoryParts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    }
    else {
      sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
      this.router.navigate(['/tracking/ppf/', this.data?.supplierCode, 'readOnly'])
    }
}


  serial(){
    const queryParams = {authNumber: this.authNumber}
    sessionStorage.setItem('queryParamsDo',JSON.stringify(queryParams))    
    this.dopkgauth.serial();
  }
  
}
