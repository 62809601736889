import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { RowGroupingDisplayType } from 'ag-grid-community';
import { SummaryRouterLinkCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-summary/summary-router-link-cell-renderer';
import { TrackingSummaryService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-summary.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tracking-summary',
  templateUrl: './tracking-summary.component.html',
  styleUrls: ['./tracking-summary.component.scss']
})
export class TrackingSummaryComponent implements OnInit {

  headerClass = 'child-header-color';
  headerClassNoBoarder = 'child-header-color no-border';
  headerClassDelay = 'child-header-color  headerDelayColor';
  headerClassWarn = 'child-header-color  headerWarnColor';
  headerClassGood = 'child-header-color  headerGoodColor';
  cellStyle = { cursor: 'pointer', color: '#2688DA' }
  headerNameCode = "Family Code"
  sop: any= formatDate(new Date().setFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd', 'en-US');
  plantcode= '';
  modelCode:any="";
  familycode:any="";
  filteredOptions: any[] = [];
  eop: any="";
  eopToDate:any="";
  plant :any;
  plants: any[] = [];
  plantArray: any[] = [];
  sopFromDate: any="";
  gridApi: any;
  params: any;
  columnDefs : any[] = [];
  currentPageNo  = 0;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [] ;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  autoGroupColumnDef: any;
  paginationPageSize = 50
  isAccessible = false;
  public domLayout:any =''
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  isAll = false;
  sortModel:any[] = [];
  gridColumnApi: any;
  spCode: any;
  supplier = '';
  resPlant: any[]=[];
  haveAccess : any;
  haveAddAccess : any;
  modifyAccess: any;
  deleteAccess: any;
  selectedPlant: any[] = [];
  haveErrors = false;
  errorList: any[]= [];
  userInfo: any
  public groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  dateFormatter(params:any) {
    const dt = (params.value?.includes('T')) ?  (params.value?.split('T')[0]) : params.value;
    return params.value ? formatDate(dt, 'MM/dd/YYYY', 'en-US') : '';
  }

  constructor(
      private readonly dialog: MatDialog, 
      private readonly trackingSummaryService: TrackingSummaryService,
      private readonly router: Router,
      private readonly spinnerService: NgxUiLoaderService,
      private readonly state:ExportStateService,

      ) {
    this.defaultColDef = {
      suppressMovable: true,
      suppressPaginationPanel: true,
      suppressHorizontalScroll: true,
      suppressAggFuncInHeader: true,
      wrapText: true,
      sortable:false,
      autoHeight: true,
      groupDisplayType: true,
    };
    this.autoGroupColumnDef = {
      headerName: this.headerNameCode,
      field: 'familycode',
      sortable: false, unSortIcon: false,
      minWidth: 80,
      floatingFilterComponentParams: { suppressFilterButton: true },
      enableGroupFilter: true,
      cellStyle: this.cellStyleColumn,
      cellRenderer: 'agGroupCellRenderer',
      headerClass: this.headerClassNoBoarder,
      cellRendererParams:{
        suppressCount : false,
      },
    
      getRowId: (params:any) => {
        return params.data.familycode;
      },
     };
   
    this.icons = {
      sortUnSort: '<img src="../../../../assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
   }

   ngOnInit(): void {
    this.rpPage = 50;
    this.state.userInfoState.subscribe({
      next: (res:any) =>{
        if(res){
          this.userInfo = res?.info
          this.haveAccess = res.role?.p_trackingview;
          this.spCode = this.userInfo.specialistcode;

          this.haveAccess && this.apiCall();
          !this.haveAccess && this.spinnerService.stop();
          if (this.haveAccess) {
           const plantdata = this.trackingSummaryService.getplant();
            if(plantdata){
            const data=this.trackingSummaryService.getTrackingSummaryFilters();
            if(data)
            {
              this.plant=(data.hidPlant.length > 1) ? '': data.hidPlant[0];
              this.selectedPlant = plantdata;
              this.familycode=data.hidFamily;
              this.modelCode=data.hidModel;
              this.sop=data.hidSOPFrom;
              this.sopFromDate=data.hidSOPTo;
              this.eop=data.hidEOPFrom;
              this.eopToDate=data.hidEOPTo
            }
            else{
              this.spCode=res.info.specialistcode
            }
          }
         };
        }
      },
      error : (_err:any) =>{
        this.haveAccess = false;
        this.spinnerService.stop();
      }
    });
    
    this.columnDefs = [
      {
        headerName: this.headerNameCode,  field: "familycode",headerClass: this.headerClassNoBoarder, minWidth: 80, sortable: false, unSortIcon: false, 
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: "", field: "modelcode",  minWidth: 110, headerClass: this.headerClass, suppressColumnsToolPanel: true,
        children:[{headerName: "Model Code" ,field: "modelcode", headerClass: this.headerClassNoBoarder,minWidth:100},]
      },
      {
        headerName: "", field: "specialistCode",  minWidth: 140, headerClass: this.headerClass, suppressColumnsToolPanel: true,
        children:[{headerName: "Specialist" ,field: "specialistCode", headerClass: this.headerClassNoBoarder,minWidth:100},]
      },
      {
        headerName: "", field: "count", minWidth: 150, headerClass: this.headerClass,
        children:[{headerName: "# of Records" ,field: "count",headerClass: this.headerClassNoBoarder, minWidth:100,
        cellRendererFramework:SummaryRouterLinkCellRenderer,
        cellStyle: this.cellStyle},],
      },
      {
        headerName: "Project Management", minWidth:'180',sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'Delay',field: 'delay', headerClass: this.headerClassDelay, minWidth:90,
            cellRendererFramework:SummaryRouterLinkCellRenderer,
            cellStyle: this.cellStyle
          },
          {
            headerName: 'Warn',field: 'warn', headerClass: this.headerClassWarn, minWidth:90,
            cellRendererFramework:SummaryRouterLinkCellRenderer,
            cellStyle: this.cellStyle
          },
          {
            headerName: 'Good',field: 'good', headerClass: this.headerClassGood, minWidth:90,
            cellRendererFramework:SummaryRouterLinkCellRenderer,
            cellStyle: this.cellStyle
          },
        ],
      },
      {
        headerName: "", field: "plantname",minWidth: 110, headerClass: this.headerClass,
        children:[{headerName: "Plant" ,field: "plantname",  headerClass: this.headerClassNoBoarder,minWidth:80},]
      },
      {
        headerName: "Date",  minWidth: 65, sortable: false, unSortIcon: false,
        children: 
        [
          {
            headerName: 'SOP',field: 'sop', headerClass: this.headerClass, minWidth:70, valueFormatter: this.dateFormatter,
          },
          {
            headerName: 'Imported',field: 'imported', headerClass: this.headerClass,minWidth:80, valueFormatter: this.dateFormatter,
          },
          {
            headerName: 'Updated',field: 'updated', headerClass: this.headerClass,minWidth:80, valueFormatter: this.dateFormatter,
          },
        ],
      } 
    ]; 
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'sop') {
        this.sop = '';
      }
      if (id === 'eop') {
        this.eop = '';
      }
      if (id === 'sopFromDate') {
        this.sopFromDate = '';
      }
      if (id === 'eopToDate') {
       this.eopToDate = ''
      }
    }
  }
  
  cellStyleColumn(_params: any) {
    return ({ 'display': 'flex', 'justify-content': 'flex-start', 'height': '40px','width':'30px' })
  }
  apiCall() {
    this.spinnerService.start();
    const obj = {
      "SpecialistCode": this.spCode,
      "Type": 1
    }

    this.trackingSummaryService.plant(obj).subscribe({
      next : (response:any) =>{
        this.resPlant = response.map((item: { plantcode: any }) => item.plantcode);
        this.selectedPlant = [{ plant: 'ALL', plantcode: '' }, ...response, ];
        this.plant = this.plant ?? this.userInfo?.plantcode;
        this.filteredOptions = this.selectedPlant
        this.displayFn(this.selectedPlant);
        this.loadTrackingSummary();
        this.trackingSummaryService.setplant(this.selectedPlant);
      },
      complete : ()=>{
        this.spinnerService.stop();
      },
      
    })
  }

  filterOptions() {
    this.filteredOptions = this.selectedPlant.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectedPlant.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : [];
  }

  public detailCellRendererParams: any = {
    detailGridOptions: {
      headerHeight : 0,
      columnDefs: [
        { headerName: this.headerNameCode,field: 'familycode',minWidth: 80 },
        { headerName: "Model Code",field: 'modelcode',minWidth: 80 },
        { headerName: "Specialist",field: 'specialistcode', minWidth: 100 },
        { headerName: "# of Records",field: 'count',minWidth: 80,cellRendererFramework:SummaryRouterLinkCellRenderer,
         cellStyle: this.cellStyle },
        { headerName: "Delay",field: 'delay',minWidth: 80,cellRendererFramework:SummaryRouterLinkCellRenderer,
          cellStyle: this.cellStyle},
        { headerName: "Warn",field: 'warn',minWidth: 80,cellRendererFramework:SummaryRouterLinkCellRenderer,
          cellStyle: this.cellStyle},
        { headerName: "Good",field: 'good',minWidth: 80, cellRendererFramework:SummaryRouterLinkCellRenderer,
          cellStyle: this.cellStyle},
        { headerName: "Plant",field: 'plantname',minWidth: 80},
        { headerName: "SOP",field: 'sop',minWidth: 80,valueFormatter: this.dateFormatter,},
        { headerName: "Imported",field: 'imported',minWidth: 80,valueFormatter: this.dateFormatter,},
        { headerName: "Updated",field: 'updated',minWidth: 80,valueFormatter: this.dateFormatter,},
      ],
      defaultColDef: {
        flex: 1,
      },
    },
  
    getDetailRowData: (params:any) => {
      this.childTrackingSummary(params);
    },
  }

  onFirstDataRendered(params:any) {
    setTimeout(function () {
      params.api.forEachNode(function (node:any) {
        node.setExpanded(true);
      });
    }, 0);
  }
  
  loadTrackingSummary():void { 
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode
    const loadObj={
      "hidPlant" : this.plant === '' ? this.resPlant : [this.plant],
      "hidFamily": this.familycode,
      "hidModel": this.modelCode,
      "hidSOPFrom": this.sop,
      "hidSOPTo" : this.sopFromDate,
      "hidEOPFrom" : this.eop,
      "hidEOPTo": this.eopToDate,
      "incrementor": this.rpPage,
      "rowMin": this.cPage,
    };
    this.trackingSummaryService.setTrackingSummaryFilters(loadObj);
    this.spinnerService.start();
    this.trackingSummaryService.loadDocData(loadObj)
    .subscribe( {next: (data:any) => {
      this.haveErrors = false
      this.displayRowData = data?.data;
      this.rowCount = this.displayRowData.length;
      this.totalRecords = data?.paginationCount[0].count;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
      this.gridApi?.hideOverlay();
      this.spinnerService.stop();
    },
    error: (err: any) => {
      this.haveErrors = true
      this.errorList=[err.error.error]
      this.spinnerService.stop();
    },
    
  })
  }
  childTrackingSummary( params: any):void { 
    const childObj={
      plantcode: params.data.plantcode,
      modelcode: params.data.modelcode
    };
    this.spinnerService.start();
    this.trackingSummaryService.childData(childObj)
    .subscribe( {next: (res:any) => {
    res.forEach((element: any) => {
      element.familycode = params.data.familycode;
      element.modelcode = params.data.modelcode;
      element.plantname = params.data.plantname;
      element.plantcode = params.data.plantcode
      element.sop = params.data.sop;
    });
      params.successCallback(res);
    } ,
    error: (_err: any) => {
      this.spinnerService.stop();
    },
    complete: () => {
      this.spinnerService.stop();
    }
  })
  }

  onSearch(){
    const plantCheck = this.selectedPlant.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((![undefined,'undefined',null,'null'].includes(this.plant)) && (!plantCheck)) {
      this.haveErrors = true
      this.errorList = ["Please select valid plant name"];
    }
    else {
      this.haveErrors = false;
      this.errorList = [];
      this.loadTrackingSummary();
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if(this.isAll){
      this.displayRowData = []

      for (let i=0; i<Math.ceil(this.totalRecords/100); i++){
        this.cPage = i+1;
        this.tPage = 1;
        this.rpPage = 100;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else{
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadTrackingSummary();
    }
  }

  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.loadTrackingSummary();
      resolve();
    });
  }
  
  onFilterChanged(_params:any){
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount(); 
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    this.loadTrackingSummary();
  }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  help(){
    this.router.navigate(['./master-help',2,"Tracking",16])
  
  }

}