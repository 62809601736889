import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './internal-vpacs/home/home.component'
import { PackageTypeComponent } from './internal-vpacs/master-maintenance/package-type/package-type.component'
import { PlantMaintenanceComponent } from './internal-vpacs/master-maintenance/plant-maintenance/plant-maintenance.component'
import { ShipmentListComponent } from './internal-vpacs/tracking/shipment-list/shipment-list.component'
import { ShipmentListDetailsComponent } from './internal-vpacs/tracking/shipment-list/shipmentlist-details/shipmentlist-details.component'
import { MasterMaintenanceRoleComponent } from './internal-vpacs/master-maintenance/master-maintenance-role/master-maintenance-role.component'
import { DiscrepancyListAddPartComponent } from './internal-vpacs-dialogs/tracking/discrepancy-list/discrepancy-list-add-part/discrepancy-list-add-part.component'
import { PartListComponent } from './internal-vpacs/tracking/part-list/part-list.component'
import { PackageMaintenanceComponent } from './internal-vpacs/master-maintenance/package-maintenance/package-maintenance.component'
import { UpdatePackageComponent } from './internal-vpacs/master-maintenance/package-maintenance/update-package/update-package.component'
import { PartListPpfComponent } from './internal-vpacs/tracking/part-list/part-list-ppf/part-list-ppf.component'
import { SupplierPlantComponent } from './internal-vpacs/master-maintenance/supplier-plant/supplier-plant.component'
import { DescripencySelectComponent } from './internal-vpacs/tracking/discrepancy-list/descripency-select/descripency-select.component'
import { DiscrepancyListComponent } from './internal-vpacs/tracking/discrepancy-list/discrepancy-list.component'
import { TrackingListComponent } from './internal-vpacs/tracking/tracking-list/tracking-list.component'
import { TrackingHistoryComponent } from './internal-vpacs/tracking/tracking-list/tracking-history/tracking-history.component'
import { TrackingCorrectionComponent } from './internal-vpacs/tracking/tracking-list/tracking-correction/tracking-correction.component'
import { CreateRcafComponent } from './internal-vpacs/tracking/tracking-list/create-rcaf/create-rcaf.component'
import { DecisionListComponent } from './internal-vpacs/authorize/decision-list/decision-list.component'
import { PURDecisionListComponent } from './internal-vpacs/authorize/pur-decision-list/pur-decision-list.component'
import { AuthListComponent } from './internal-vpacs/cost-management/auth-list/auth-list.component'
import { RingiDetailComponent } from './internal-vpacs/cost-management/ringi-list/ringi-detail/ringi-detail.component'
import { RingiListComponent } from './internal-vpacs/cost-management/ringi-list/ringi-list.component'
import { ShipmentRackComponent } from './internal-vpacs-dialogs/tracking/shipment-rack/shipment-rack.component'
import { ReceivementRackComponent } from './internal-vpacs-dialogs/tracking/receivement-rack/receivement-rack.component'
import { TrackingSummaryComponent } from './internal-vpacs/tracking/tracking-summary/tracking-summary.component'
import { BackupAuthListComponent } from './internal-vpacs/cost-management/backup-auth-list/backup-auth-list.component'
import { BackupAuthDetailsComponent } from './internal-vpacs/cost-management/backup-auth-list/backup-auth-details/backup-auth-details.component'
import { PkgFabMaintenanceComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/pkg-fab-maintenance.component'
import { SupplierMaintenanceComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/supplier-maintenance.component'
import { AuthLinkComponent } from './internal-vpacs/cost-management/auth-list/auth-link/auth-link.component'
import { UpdateContactComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/update-contact/update-contact.component'
import { SupContactComponent } from './internal-vpacs/master-maintenance/supplier-maintenance/sup-contact/sup-contact.component'
import { DocumentationComponent } from './internal-vpacs/standard-reports/documentation/documentation.component'
import { BenchMarkComponent } from './internal-vpacs/tracking/tracking-list/bench-mark/bench-mark.component'
import { QuantityComponent } from './internal-vpacs/quantity-control/quantity/quantity.component'
import { MaterialComponent } from './internal-vpacs/master-maintenance/material/material.component'
import { FabContComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/fab-cont/fab-cont.component'
import { PkgContComponent } from './internal-vpacs/master-maintenance/pkg-fab-maintenance/pkg-cont/pkg-cont.component'
import { SiDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-dept-approval/si-dept-approval.component'
import { PackageConversionComponent } from './internal-vpacs/master-maintenance/package-conversion/package-conversion.component'
import { SendPpfComponent } from './internal-vpacs/tracking/tracking-list/send-ppf/send-ppf.component'
import { InternalApprovementListComponent } from './internal-vpacs/tracking/internal-approvement-list/internal-approvement-list.component'
import { CompareModelListComponent } from './internal-vpacs/tracking/model-list/compare-model-list/compare-model-list.component'
import { ModelListComponent } from './internal-vpacs/tracking/model-list/model-list.component'
import { ModellistConnectComponent } from './internal-vpacs/tracking/model-list/modellist-connect/modellist-connect.component'
import { QuantityDetailComponent } from './internal-vpacs/quantity-control/quantity-detail/quantity-detail.component'
import { QuantityLogComponent } from './internal-vpacs/quantity-control/quantity-log/quantity-log.component'
import { BackupTempContainerComponent } from './internal-vpacs/tracking/tracking-list/backup-temp-container/backup-temp-container.component'
import { ExchangeRateComponent } from './internal-vpacs/master-maintenance/exchange-rate/exchange-rate.component'
import { DeptApproveComponent } from './internal-vpacs/tracking/internal-approvement-list/dept-approve/dept-approve.component'
import { ConceptCompareComponent } from './internal-vpacs/tracking/internal-approvement-list/concept-compare/concept-compare.component'
import { DivisionMasterComponent } from './internal-vpacs/master-maintenance/division-master/division-master.component'
import { SelectPpfComponent } from './internal-vpacs/tracking/internal-approvement-list/select-ppf/select-ppf.component'
import { UserMaintenanceComponent } from './internal-vpacs/master-maintenance/user-maintenance/user-maintenance.component'
import { UpdateUserComponent } from './internal-vpacs-dialogs/master-maintenance/update-user/update-user.component'
import { UpdatePpfComponent } from './internal-vpacs/tracking/tracking-list/update-ppf/update-ppf.component'
import { SqDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/sq-dept-approval/sq-dept-approval.component'
import { LogDetailsComponent } from './internal-vpacs/home/log-details/log-details.component'
import { MsalGuard } from '../msal'
import { ConfirmImportComponent } from './internal-vpacs/tracking/model-list/confirm-import/confirm-import.component'
import { ImportResultComponent } from './internal-vpacs/tracking/model-list/import-result/import-result.component'
import { MovePackageComponent } from './internal-vpacs/quantity-control/move-package/move-package.component'
import { DisposePackageComponent } from './internal-vpacs/quantity-control/dispose-package/dispose-package.component'
import { MaintenancePackageComponent } from './internal-vpacs/quantity-control/maintenance-package/maintenance-package.component'
import { AdjustQuantityComponent } from './internal-vpacs/quantity-control/adjust-quantity/adjust-quantity.component'
import { NewPartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/new-part-confirmation/new-part-confirmation.component'
import { DeletePartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/delete-part-confirmation/delete-part-confirmation.component'
import { ReplacePartConfirmationComponent } from './internal-vpacs-dialogs/import-model-list/replace-part-confirmation/replace-part-confirmation.component'
import { BroadcastCalcComponent } from './internal-vpacs/tracking/tracking-list/broadcast-calc/broadcast-calc.component'
import { DiscrepancyTempContainerComponent } from './internal-vpacs/tracking/discrepancy-list/discrepancy-temp-container/discrepancy-temp-container.component'
import { TrialOrderComponent } from './internal-vpacs/tracking/tracking-list/trial-order/trial-order.component'
import { SiPreppfDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-preppf-dept-approval/si-preppf-dept-approval.component'
import { SiFinalppfDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-finalppf-dept-approval/si-finalppf-dept-approval.component'
import { MasterHelpComponent } from './internal-vpacs/master-maintenance/master-help/master-help.component'
import { ErrorListComponent } from './internal-vpacs/master-maintenance/master-help/error-list/error-list.component'
import { ManualComponent } from './internal-vpacs/master-maintenance/master-help/manual/manual.component'
import { HazmatApprovalComponent } from './internal-vpacs/tracking/tracking-list/hazmat-approval/hazmat-approval.component'
import { SiPkgTrialDeptApprovalComponent } from './internal-vpacs/tracking/tracking-list/si-pkg-trial-dept-approval/si-pkg-trial-dept-approval.component'
import { HazmatApprovementListComponent } from './internal-vpacs/tracking/hazmat-approvement-list/hazmat-approvement-list.component'
import { SelectTrackPartComponent } from './internal-vpacs/tracking/tracking-list/select-track-part/select-track-part.component'
import { ServicePartsComponent } from './internal-vpacs/tracking/part-list/service-parts/service-parts.component'

  const DISCREPANCY_LIST = 'DISCREPANCY LIST'
  const decisionList = 'decision-list'
  const internalApprovement = 'internal-approvement'
  const ServicePartsPDF = 'Service Parts PDF'
  const PPF = 'PPF'
  const TrackingSummary = 'Tracking Summary'
  const TRACKING_LIST = 'tracking-list'
  const TrackingListBreadcrumb = 'Tracking List'
  const AUTH_DETAIL =  'auth-detail'
  const TRIAL_ORDER_BREADCRUMB = 'Trial Order'
  const TRIAL_ORDER = 'trial-order'
  const CONCEPT_COMPARE_BREADCRUMB = 'CONCEPT COMPARE' 
  const CONCEPT_COMPARE_URL = 'concept-compare'
  const CONCEPT_COMPARE_URL2 = 'concept-compare/:ppfcode1/:ppfver1/:ppfcode2/:ppfver2'
  const SELECT_PPF_BREADCRUMB = 'SELECT PPF'
  const SELECT_PPF_URL = 'select-ppf'
  const SELECT_PPF_URL2 = 'select-ppf/:point/:ppfcode/:ppfVer1/:ppfVer2'
  const DEPT_APPROVE_URL = 'dept-approve'
  const DEPARTMENT_APPROVE_BREADCRUMB = 'DEPARTMENT APPROVE'
  const SEND_DEPT_APPROVAL_PPF  = 'SEND DEPT.APPROVAL(PRE PPF)'
  const SELECT_PART_BREADCRUMB = 'SELECT PART'
  const SEND_HAZMAT_APPROVAL_BREADCRUMB = 'SEND HAZMAT APPROVAL'
  const INTERNAL_APPROVEMENT_LIST_BREADCRUMB = 'INTERNAL APPROVAL LIST'
  const BACKUP_AUTH_LIST = 'BACKUP AUTH LIST'
  const HAZMAT_APPROVAL_URL = 'hazmat-approval'
  const CONNECT_BREADCRUMB = 'CONNECT'
  const CONFIRM_IMPORT_BREADCRUMB = 'CONFIRM IMPORT'
  const UPDATE_PPF_REASON_BREADCRUMB = 'UPDATE PPF REASON'
  const UPDATE_PPF_REASON_URL = 'update-ppf-reason/:ppfcode/:ppfver/:auth'
  const SEND_PPF_BREADCRUMB = 'SEND PPF'
  const PPF_URL = 'ppf/:ppfcode/:ppfver/:history/:mode'
  const SERVICEPARTS_PPF_URL = 'serviceparts-pdf/:partnumber/:ppfcode/:ppfver/:history/:mode'
  const SERVICEPARTS_PPF_URL2 = 'serviceparts-pdf/:ppfcode/:ppfver/:history/:mode'
  const ACCYPARTS_PPF_URL = 'accessoryParts-pdf/:partnumber/:ppfcode/:ppfver/:history/:mode'
  const ACCYPARTS_PPF_URL2 = 'accessoryParts-pdf/:ppfcode/:ppfver/:history/:mode'
  const PARTLIST_PPF_URL = 'ppf/:ppfcode/:mode'
  const BACKUP_AUTH_DETAIL_URL = 'backup-auth-details'
  const ADD_PKG_BREADCRUMB = 'ADD PACKAGE'
  const CREATE_RCAF_URL = 'create-rcaf'
  const CREATE_RCAF_BREADCRUMB = 'CREATE RCAF'
  const ACCY_PARTS = 'Accessory Parts PDF'
  const ADD_PKG_URL = 'add-package/:ind'
  const DEPT_APPROVE_PARAMS_URL = 'dept-approve/:leaderPlant/:plant/:plantName/:dock/:modelCode/:supplier/:supplierName/:specialist/:part/:psd/:dateSent/:mode/:step/:currentIntNo/:currentIntVer'
const routes: Routes = [
  {
    path: 'internal', component: HomeComponent,
    canActivate: [MsalGuard]
  },

  {
    path: 'internal',
    children: [{
      path: 'discripency-list/:plant/:urlparameter',
      component: DiscrepancyListComponent,
      data: { breadcrumb: DISCREPANCY_LIST },
      canActivate: [MsalGuard]
    },
    {
      path: 'decision-list/:plant/:urlparameter',
      component: DecisionListComponent,
      data: { breadcrumb: decisionList },
      canActivate: [MsalGuard]
    },
  ]
  },

  {
    path: 'log-details/:code',
    component: LogDetailsComponent,
    data: { breadcrumb: 'Log Details' },
    canActivate: [MsalGuard]
  },
  {
    path: 'model-list',
    component: ModelListComponent,
    data: { breadcrumb: 'Model-list' },
    canActivate: [MsalGuard]
  },
  {
    path: 'model-list',
    data: { breadcrumb: 'Model List' },
    children: [
      {
        path: 'connect/:modelCode/:plantCode',
        component: ModellistConnectComponent,
        data: { breadcrumb: CONNECT_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'connect',
        component: ModellistConnectComponent,
        data: { breadcrumb: CONNECT_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'new-part/:id/:modelcode/:plantcode',
        component: NewPartConfirmationComponent,
        data: { breadcrumb: 'NEW PART CONFIRMATION' },
        canActivate: [MsalGuard]
      },
      {
        path: 'delete-part/:id/:modelcode/:plantcode',
        component: DeletePartConfirmationComponent,
        data: { breadcrumb: 'DELETE PART CONFIRMATION' },
        canActivate: [MsalGuard]
      },
      {
        path: 'replace-part/:id/:modelcode/:plantcode',
        component: ReplacePartConfirmationComponent,
        data: { breadcrumb: 'REPLACE PART CONFIRMATION' },
        canActivate: [MsalGuard]
      },
      {
        path: 'connect',
        data: { breadcrumb: CONNECT_BREADCRUMB },
        children: [
          {
            path: 'compare/:modelCode/:plantCode/:partNumber/:supplierCode',
            component: CompareModelListComponent,
            data: { breadcrumb: 'COMPARE' },
            canActivate: [MsalGuard]
          },
          {
            path: 'compare',
            component: CompareModelListComponent,
            data: { breadcrumb: 'COMPARE' },
            canActivate: [MsalGuard]
          },
        ],
      },
      {
        path: 'confirm-import/:id/:modelcode/:plantcode',
        component: ConfirmImportComponent,
        data: { breadcrumb: CONFIRM_IMPORT_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'confirm-import',
        data: { breadcrumb: CONFIRM_IMPORT_BREADCRUMB },
        children: [
          {
            path: 'import-result',
            component: ImportResultComponent,
            data: { breadcrumb: 'IMPORT RESULT' },
            canActivate: [MsalGuard]
          },
        ],
      },
    ],
  },
  {
    path: internalApprovement,
    component: InternalApprovementListComponent,
    data: { breadcrumb: INTERNAL_APPROVEMENT_LIST_BREADCRUMB },
    canActivate: [MsalGuard]
  },
  {
    path: 'part-list',
    component: PartListComponent,
    data: { breadcrumb: 'PART LIST' },
    canActivate: [MsalGuard]
  },
  {
    path: 'part-list',
    data: { breadcrumb: 'Part List' },
    children: [
      {
        path: 'backup',
        component: BackupTempContainerComponent,
        data: { breadcrumb: 'BACKUP' },
        canActivate: [MsalGuard]
      },
      {
        path: PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      },
      {
        path:SERVICEPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF},
        canActivate: [MsalGuard]
      },
      {
        path:ACCYPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS},
        canActivate: [MsalGuard]
      },
      {
        path: PPF_URL,
        data: { breadcrumb: PPF },
        children: [
          {
            path: ADD_PKG_URL,
            component: UpdatePackageComponent,
            data: { breadcrumb: ADD_PKG_BREADCRUMB },
            canActivate: [MsalGuard]
          },
        ],
      },
      {
        path: UPDATE_PPF_REASON_URL,
        component: UpdatePpfComponent,
        data: { breadcrumb: UPDATE_PPF_REASON_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: UPDATE_PPF_REASON_URL,        
        data: { breadcrumb: UPDATE_PPF_REASON_BREADCRUMB },
        children: [
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path:SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ServicePartsPDF},
            canActivate: [MsalGuard]
          },
          {
            path:ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ACCY_PARTS},
            canActivate: [MsalGuard]
          },
        ]
      },
    ],
  },
  {
    path: 'discripency-list',
    component: DiscrepancyListComponent,
    data: { breadcrumb: DISCREPANCY_LIST },
    canActivate: [MsalGuard]
  },
  {
    path: 'discripency-list',
    data: { breadcrumb: DISCREPANCY_LIST },
    children: [
      {
        path: 'select',
        component: DescripencySelectComponent,
        data: { breadcrumb: 'SELECT-PART' },
        canActivate: [MsalGuard]
      },
      {
        path: 'temporary-container',
        component: DiscrepancyTempContainerComponent,
        data: { breadcrumb: 'TEMPORARY-CONTAINER' },
        canActivate: [MsalGuard]
      },
      {
        path: 'new',
        component: DiscrepancyListAddPartComponent,
        data: { breadcrumb: 'NEW' },
        canActivate: [MsalGuard]
      },
      {
        path: PARTLIST_PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      }
    ],
  },
  {
    path: 'tracking-summary',
    component: TrackingSummaryComponent,
    data: { breadcrumb: TrackingSummary },
    canActivate: [MsalGuard]
  },
  {
    path: 'tracking-summary',
    data: { breadcrumb: TrackingSummary },
    children: [
      {
        path: TRACKING_LIST,
        component: TrackingListComponent,
        data: { breadcrumb: TrackingListBreadcrumb },
        canActivate: [MsalGuard]
      },

      {
        path: 'tracking-list/:model/:plant/:specialist/:selectAll/:delay/:warn/:good/:scr_flg',
        component: TrackingListComponent,
        data: { breadcrumb: TrackingListBreadcrumb },
        canActivate: [MsalGuard]
      },
    ],
  },
  {
    path: 'ringi-list',
    component: RingiListComponent,
    data: { breadcrumb: 'Ringi List' },
    canActivate: [MsalGuard]
  },
  {
    path: 'ringi-list',
    data: { breadcrumb: 'Ringi List' },
    children: [
      {
        path: 'ringi-detail',
        component: RingiDetailComponent,
        data: { breadcrumb: 'Ringi Detail' },
        canActivate: [MsalGuard]
      },
      {
        path: 'auth-list',
        component: AuthListComponent,
        data: { breadcrumb: 'AUTH LIST' },
        canActivate: [MsalGuard]
      },
    ],
  },
  {
    path: 'shipment-list',
    component: ShipmentListComponent,
    data: { breadcrumb: 'Shipment List' },
    canActivate: [MsalGuard]
  },
  {
    path: 'shipment-list',
    data: { breadcrumb: 'Shipment List' },
    children: [
      {
        path: 'shipmentlist-details/:shipmentNumber/:flag',
        component: ShipmentListDetailsComponent,
        data: { breadcrumb: 'Details' },
        canActivate: [MsalGuard]
      },
      {
        path: 'rackShipment/:authnumber/:shipmentNumber',
        component: ShipmentRackComponent,
        data: { breadcrumb: 'Rack Shipment' },
        canActivate: [MsalGuard]
      },
      {
        path: 'rackReceivement/:authnumber/:shipmentNumber',
        component: ReceivementRackComponent,
        data: { breadcrumb: 'Rack Receivement' },
        canActivate: [MsalGuard]
      },
    ],
  },
  {
    path: decisionList,
    component: DecisionListComponent,
    data: { breadcrumb: decisionList },
    canActivate: [MsalGuard]
  },
  {
    path: decisionList,
    data: { breadcrumb: decisionList },
    children: [
      {
        path: AUTH_DETAIL,
        component: AuthLinkComponent,
        data: { breadcrumb: AUTH_DETAIL },
        canActivate: [MsalGuard]
      },
      {
        path: BACKUP_AUTH_DETAIL_URL,
        component: BackupAuthDetailsComponent,
        data: { breadcrumb: 'backup-auth-detail' },
        canActivate: [MsalGuard]
      },
      {
        path: PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      },
      {
        path:SERVICEPARTS_PPF_URL2,
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF},
        canActivate: [MsalGuard]
      },
      {
        path:ACCYPARTS_PPF_URL2,
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS},
        canActivate: [MsalGuard]
      },
    ]
  },
  {
    path: 'pur-decision-list',
    component: PURDecisionListComponent,
    data: { breadcrumb: 'PUR-decision-list' },
    canActivate: [MsalGuard]
  },
  {
    path: 'pur-decision-list',
    data: { breadcrumb: 'PUR-decision-list' },
    children: [
      {
        path: 'auth-link',
        component: AuthLinkComponent,
        data: { breadcrumb: AUTH_DETAIL },
        canActivate: [MsalGuard]
      },
      {
        path: BACKUP_AUTH_DETAIL_URL,
        component: BackupAuthDetailsComponent,
        data: { breadcrumb: 'backup-auth-detail' },
        canActivate: [MsalGuard]
      },
      {
        path: PARTLIST_PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      },
      {
        path:'serviceparts-pdf/:ppfcode/:mode',
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF},
        canActivate: [MsalGuard]
      },
      {
        path:'accessoryParts-pdf/:ppfcode/:mode',
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS},
        canActivate: [MsalGuard]
      },
    ]
  },
  {
    path: 'tracking-list/:plant/:specialist/:delay/:warn/:good/:side/:scr_flg',
    component: TrackingListComponent,
    data: { breadcrumb: TrackingListBreadcrumb },
    canActivate: [MsalGuard]
  },
  {
    path: TRACKING_LIST,
    component: TrackingListComponent,
    data: { breadcrumb: TrackingListBreadcrumb },
    canActivate: [MsalGuard]
  },
  {
    path: TRACKING_LIST,
    data: { breadcrumb: TrackingListBreadcrumb },
    children: [
      {
        path:SERVICEPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF},
        canActivate: [MsalGuard]
      },
      {
        path:ACCYPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS},
        canActivate: [MsalGuard]
      },
      {
        path: 'tracking-history',
        component: TrackingHistoryComponent,
        data: { breadcrumb: 'Tracking History' },
        canActivate: [MsalGuard]
      },
      {
        path: 'tracking-history',
        data: { breadcrumb: 'Tracking History' },
        children: [
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path:SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ServicePartsPDF},
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
        ]
      },
      {
        path: PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      },
      {
        path: 'tracking-correction',
        component: TrackingCorrectionComponent,
        data: { breadcrumb: 'Tracking Correction' },
        canActivate: [MsalGuard]
      },
      {
        path: CREATE_RCAF_URL,
        component: CreateRcafComponent,
        data: { breadcrumb: CREATE_RCAF_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: TRIAL_ORDER ,
        component: TrialOrderComponent,
        data: { breadcrumb: TRIAL_ORDER_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: TRIAL_ORDER ,
        data: { breadcrumb: TRIAL_ORDER_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path:SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path:ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ACCY_PARTS},
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'benchmark',
        component: BenchMarkComponent,
        data: { breadcrumb: 'Benchmark' },
        canActivate: [MsalGuard]
      },
      {
        path: 'benchmark',
        data: { breadcrumb: 'Benchmark' },
        children: [
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]            
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
        ]
      },
      {
        path: 'send-dept-approval',
        component: SiDeptApprovalComponent,
        data: { breadcrumb: 'SEND DEPT.APPROVAL(BM)' },
        canActivate: [MsalGuard]
      },
      {
        path: 'send-dept-approval',
        data: { breadcrumb: 'SEND DEPT.APPROVAL(BM)' },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          }
        ]
      },
      {
        path: 'sq-dept-approval',
        component: SqDeptApprovalComponent,
        data: { breadcrumb: SEND_DEPT_APPROVAL_PPF },
        canActivate: [MsalGuard]
      },
      {
        path: 'sI-preppf-dept-approval',
        component: SiPreppfDeptApprovalComponent,
        data: { breadcrumb: SEND_DEPT_APPROVAL_PPF },
        canActivate: [MsalGuard]
      },
      {
        path: 'sI-preppf-dept-approval',
        data: { breadcrumb: SEND_DEPT_APPROVAL_PPF },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
          {
            path: 'select',
            component: SelectTrackPartComponent,
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            canActivate: [MsalGuard] 
          },
          {
            path: 'select',
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            children: [
              {
                path: PARTLIST_PPF_URL,
                component: PartListPpfComponent,
                data: { breadcrumb: PPF },
                canActivate: [MsalGuard]    
              },
            ]
          }
        ]
      },
      {
        path: 'sI-final-dept-approval',
        component: SiFinalppfDeptApprovalComponent,
        data: { breadcrumb: 'SEND DEPT.APPROVAL(FINAL PPF)' },
        canActivate: [MsalGuard]
      },
      {
        path: 'sI-final-dept-approval',
        data: { breadcrumb: 'SEND DEPT.APPROVAL(FINAL PPF)' },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
          {
            path: 'select',
            component: SelectTrackPartComponent,
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: 'select',
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            children: [
              {
                path: PARTLIST_PPF_URL,
                component: PartListPpfComponent,
                data: { breadcrumb: PPF },
                canActivate: [MsalGuard]    
              },
            ]
          }
        ]
      },
      {
        path: 'si-pkgtrial-dept-approval',
        component: SiPkgTrialDeptApprovalComponent,
        data: { breadcrumb: 'SEND DEPT.APPROVAL(PKG TRIAL)' },
        canActivate: [MsalGuard]
      },
      {
        path: 'si-pkgtrial-dept-approval',
        data: { breadcrumb: 'SEND DEPT.APPROVAL(PKG TRIAL)' },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: 'select',
            component: SelectTrackPartComponent,
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: 'select',
            data: { breadcrumb: SELECT_PART_BREADCRUMB },
            children: [
              {
                path: PARTLIST_PPF_URL,
                component: PartListPpfComponent,
                data: { breadcrumb: PPF },
                canActivate: [MsalGuard]    
              },
            ]
          }
        ]
      },
      {
        path: HAZMAT_APPROVAL_URL,
        component: HazmatApprovalComponent,
        data: { breadcrumb: SEND_HAZMAT_APPROVAL_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: HAZMAT_APPROVAL_URL,
        data: { breadcrumb: SEND_HAZMAT_APPROVAL_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          }
        ]
      },
      {
        path: DEPT_APPROVE_PARAMS_URL,
        component: DeptApproveComponent,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: DEPT_APPROVE_URL,
        component: DeptApproveComponent,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: DEPT_APPROVE_URL,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL2,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'send-ppf',
        component: SendPpfComponent,
        data: { breadcrumb: SEND_PPF_BREADCRUMB },
        canActivate: [MsalGuard]
      },

      {
        path: internalApprovement,
        component: InternalApprovementListComponent,
        data: { breadcrumb: INTERNAL_APPROVEMENT_LIST_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: internalApprovement,
        data: { breadcrumb: INTERNAL_APPROVEMENT_LIST_BREADCRUMB },
        children: [
          {
            path:
              DEPT_APPROVE_PARAMS_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                ],
              },
              {
                path: PPF_URL,
                component: PartListPpfComponent,
                data: { breadcrumb: PPF },
                canActivate: [MsalGuard]
              },
              {
                path:SERVICEPARTS_PPF_URL2,
                component: ServicePartsComponent,
                data: {breadcrumb: ServicePartsPDF },
                canActivate: [MsalGuard]
              },
              {
                path:ACCYPARTS_PPF_URL2,
                component: ServicePartsComponent,
                data: {breadcrumb: ACCY_PARTS },
                canActivate: [MsalGuard]
              },
              {
                path: 'ppf',
                component: PartListPpfComponent,
                data: { breadcrumb: PPF },
                canActivate: [MsalGuard]
              },
            ],
          },
        ],
      },
      {
        path: 'backup-temp-container',
        component: BackupTempContainerComponent,
        data: { breadcrumb: 'TEMPORARY CONTAINER' },
        canActivate: [MsalGuard]
      },
      {
        path: UPDATE_PPF_REASON_URL,
        component: UpdatePpfComponent,
        data: { breadcrumb: UPDATE_PPF_REASON_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: UPDATE_PPF_REASON_URL,
        data: { breadcrumb: UPDATE_PPF_REASON_BREADCRUMB },
        children: [
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: PPF_URL,
            data: { breadcrumb: PPF },
            children: [
              {
                path: ADD_PKG_URL,
                component: UpdatePackageComponent,
                data: { breadcrumb: ADD_PKG_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          }
        ]
      },
      {
        path: 'update-ppf-reason',
        component: UpdatePpfComponent,
        data: { breadcrumb: UPDATE_PPF_REASON_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'broadcast-calc',
        component: BroadcastCalcComponent,
        data: { breadcrumb: 'BROADCAST CALC' },
        canActivate: [MsalGuard]
      },
      {
        path: 'send-ppf',
        component: SendPpfComponent,
        data: { breadcrumb: SEND_PPF_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'send-ppf',
        data: { breadcrumb: SEND_PPF_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: DEPT_APPROVE_URL,
            component: DeptApproveComponent,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: DEPT_APPROVE_URL,
            data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
            children: [
              {
                path: CONCEPT_COMPARE_URL2,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                component: ConceptCompareComponent,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: CONCEPT_COMPARE_URL,
                data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
                children: [
                  {
                    path: SELECT_PPF_URL2,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  },
                  {
                    path: SELECT_PPF_URL,
                    component: SelectPpfComponent,
                    data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                    canActivate: [MsalGuard]
                  }
                ]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path: SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path: ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: { breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
        ]
      }
    ],
  },

  {
    path: 'user-maintenance',
    component: UserMaintenanceComponent,
    data: { breadcrumb: 'USER MAINTENANCE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'user-maintenance',
    data: { breadcrumb: 'USER MAINTENANCE' },
    children: [
      {
        path: 'update-user',
        component: UpdateUserComponent,
        data: { breadcrumb: 'Add' },
        canActivate: [MsalGuard]
      },
    ],
  },

  //internal-approvement-list
  {
    path: internalApprovement,
    component: InternalApprovementListComponent,
    data: { breadcrumb: INTERNAL_APPROVEMENT_LIST_BREADCRUMB },
    canActivate: [MsalGuard]
  },
  {
    path: internalApprovement,
    data: { breadcrumb: INTERNAL_APPROVEMENT_LIST_BREADCRUMB },
    children: [
      {
        path:
          DEPT_APPROVE_PARAMS_URL,
        component: DeptApproveComponent,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: DEPT_APPROVE_URL,
        component: DeptApproveComponent,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: DEPT_APPROVE_URL,
        data: { breadcrumb: DEPARTMENT_APPROVE_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL2,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
            ],
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path:SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path:ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
          {
            path: 'ppf',
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
        ],
      },
    ],
  },

  //hazmat-approvement-list
  {
    path: 'hazmat-approve-list',
    component: HazmatApprovementListComponent,
    data: { breadcrumb: 'HAZMAT APPROVAL LIST' },
    canActivate: [MsalGuard]
  },
  {
    path: 'hazmat-approve-list',
    data: { breadcrumb: 'HAZMAT APPROVAL LIST' },
    children: [
      {
        path: HAZMAT_APPROVAL_URL,
        component: HazmatApprovalComponent,
        data: { breadcrumb: SEND_HAZMAT_APPROVAL_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: HAZMAT_APPROVAL_URL,
        data: { breadcrumb: SEND_HAZMAT_APPROVAL_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          },
          {
            path: PPF_URL,
            component: PartListPpfComponent,
            data: { breadcrumb: PPF },
            canActivate: [MsalGuard]
          },
          {
            path:SERVICEPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ServicePartsPDF },
            canActivate: [MsalGuard]
          },
          {
            path:ACCYPARTS_PPF_URL2,
            component: ServicePartsComponent,
            data: {breadcrumb: ACCY_PARTS },
            canActivate: [MsalGuard]
          },
        ]
      },
    ],
  },

  //quantity-control
  {
    path: 'quantityConrtol',
    data: { breadcrumb: 'QUANTITY CONTROL' },
    children: [
      {
        path: '',
        component: QuantityComponent,
        data: { breadcrumb: '' },
        canActivate: [MsalGuard]
      },
      {
        path: 'detail/:plant/:supplier/:pkg/:dun/:dun2',
        component: QuantityDetailComponent,
        data: { breadcrumb: 'DETAIL' },
        canActivate: [MsalGuard]
      },
      {
        path: 'log/:plant/:supplier/:pkg/:dun/:dun2',
        component: QuantityLogComponent,
        data: { breadcrumb: 'LOG' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'move-package/:fplant/:fsupplier/:container/:dunnage1/:dunnage2/:fyear/:fweek/:tplant/:tsupplier/:tyear/:tweek/:qtytype/:auth',
        component: MovePackageComponent,
        data: { breadcrumb: 'MOVE-PACKAGE' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'dispose-package/:plant/:supplier/:container/:dunnage1/:dunnage2/:year/:week/:part/:qtytype/:auth',
        component: DisposePackageComponent,
        data: { breadcrumb: 'DISPOSE-PACKAGE' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'maintenance-package/:plant/:supplier/:pkg/:container/:dunnage1/:dunnage2/:year/:week/:part/:qtytype/:auth',
        component: MaintenancePackageComponent,
        data: { breadcrumb: 'MAINTENANCE-PACKAGE' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'adjust-quantity/:plant/:supplier/:pkg/:dunnage1/:dunnage2/:year/:week/:part/:qtytype/:auth',
        component: AdjustQuantityComponent,
        data: { breadcrumb: 'ADJUST-QUANTITY' },
        canActivate: [MsalGuard]
      },
      {
        path: CREATE_RCAF_URL,
        component: CreateRcafComponent,
        data: { breadcrumb: CREATE_RCAF_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: 'create-rcaf/:specialistCode/:plantCode/:partNumber/:suppliercode/:screen',
        component: CreateRcafComponent,
        data: { breadcrumb: CREATE_RCAF_BREADCRUMB },
        canActivate: [MsalGuard]
      }
    ]
  },
  {
    path: 'package-type',
    component: PackageTypeComponent,
    data: { breadcrumb: 'PACKAGE TYPE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'exchange-rate',
    component: ExchangeRateComponent,
    data: { breadcrumb: 'EXCHANGE RATE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'auth-list',
    component: AuthListComponent,
    data: { breadcrumb: 'AUTH LIST' },
    canActivate: [MsalGuard]
  },
  {
    path: 'auth-list',
    data: { breadcrumb: 'AUTH LIST' },
    children: [
      {
        path:
          'create-rcaf/:authNumber/:specialistCode/:plantCode/:flag/:pkgstartdate/:partNumber',
        component: CreateRcafComponent,
        data: { breadcrumb: CREATE_RCAF_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: CREATE_RCAF_URL,
        component: CreateRcafComponent,
        data: { breadcrumb: CREATE_RCAF_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]

      },
      {
        path:SERVICEPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF },
        canActivate: [MsalGuard]
      },
      {
        path:ACCYPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS },
        canActivate: [MsalGuard]
      },
      {
        path: 'auth-link/:auth',
        component: AuthLinkComponent,
        data: { breadcrumb: 'AUTH UPDATE' },
        canActivate: [MsalGuard]
      },
      {
        path: 'auth-link',
        component: AuthLinkComponent,
        data: { breadcrumb: 'AUTH UPDATE' },
        canActivate: [MsalGuard]
      },
      {
        path: TRIAL_ORDER,
        component: TrialOrderComponent,
        data: { breadcrumb: TRIAL_ORDER_BREADCRUMB },
        canActivate: [MsalGuard]
      },
      {
        path: TRIAL_ORDER,
        data: { breadcrumb: TRIAL_ORDER_BREADCRUMB },
        children: [
          {
            path: CONCEPT_COMPARE_URL,
            component: ConceptCompareComponent,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            canActivate: [MsalGuard]
          },
          {
            path: CONCEPT_COMPARE_URL,
            data: { breadcrumb: CONCEPT_COMPARE_BREADCRUMB },
            children: [
              {
                path: SELECT_PPF_URL2,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              },
              {
                path: SELECT_PPF_URL,
                component: SelectPpfComponent,
                data: { breadcrumb: SELECT_PPF_BREADCRUMB },
                canActivate: [MsalGuard]
              }
            ]
          }
        ]
      },
      {
        path:
          'dispose-package/:plant/:supplier/:part/:auth',
        component: DisposePackageComponent,
        data: { breadcrumb: 'DISPOSE PACKAGE' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'maintenance-package/:plant/:supplier/:pkg/:part/:auth',
        component: MaintenancePackageComponent,
        data: { breadcrumb: 'MAINTENANCE PACKAGE' },
        canActivate: [MsalGuard]
      },
      {
        path:
          'move-package/:fplant/:fsupplier/:auth',
        component: MovePackageComponent,
        data: { breadcrumb: 'MOVE PACKAGE' },
        canActivate: [MsalGuard]

      },
    ],
  },
  {
    path: 'backup-auth-list/:sp/:app_type',
    component: BackupAuthListComponent,
    data: { breadcrumb: BACKUP_AUTH_LIST },
  },
  {
    path: 'backup-auth-list',
    component: BackupAuthListComponent,
    data: { breadcrumb: BACKUP_AUTH_LIST },
    canActivate: [MsalGuard]
  },
  {
    path: 'backup-auth-list',
    data: { breadcrumb: BACKUP_AUTH_LIST },
    children: [
      {
        path: BACKUP_AUTH_DETAIL_URL,
        component: BackupAuthDetailsComponent,
        data: { breadcrumb: 'BACKUP AUTH DETAILS' },
        canActivate: [MsalGuard]
      },
      {
        path: PPF_URL,
        component: PartListPpfComponent,
        data: { breadcrumb: PPF },
        canActivate: [MsalGuard]
      },
      {
        path:SERVICEPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ServicePartsPDF },
        canActivate: [MsalGuard]
      },
      {
        path:ACCYPARTS_PPF_URL,
        component: ServicePartsComponent,
        data: {breadcrumb: ACCY_PARTS },
        canActivate: [MsalGuard]
      },
    ]
  },
  {
    path: 'package-maintenance',
    component: PackageMaintenanceComponent,
    data: { breadcrumb: 'Package Maintenance' },
    canActivate: [MsalGuard]
  },
  {
    path: 'package-maintenance',
    data: { breadcrumb: 'Package Maintenance' },
    children: [
      {
        path: 'update-package',
        component: UpdatePackageComponent,
        data: { breadcrumb: 'Update Package' },
        canActivate: [MsalGuard]
      },
      {
        path: 'add-package',
        component: UpdatePackageComponent,
        data: { breadcrumb: ADD_PKG_BREADCRUMB },
        canActivate: [MsalGuard]
      },
    ],
  },
  {
    path: 'master-package',
    component: PackageConversionComponent,
    data: { breadcrumb: 'PACKAGE CONVERSION' },
    canActivate: [MsalGuard]
  },
  {
    path: 'plant-Maintenance',
    component: PlantMaintenanceComponent,
    data: { breadcrumb: 'PLANT MAINTENANCE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'materialMaintenance',
    component: MaterialComponent,
    data: { breadcrumb: 'material MAINTENANCE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'master-role',
    component: MasterMaintenanceRoleComponent,
    data: { breadcrumb: 'ROLE MAINTENANCE' },
    canActivate: [MsalGuard]
  },
  {
    path: 'division-master',
    component: DivisionMasterComponent,
    data: { breadcrumb: 'DIVISION MASTER' },
    canActivate: [MsalGuard]
  },
  {
    path: 'master-help/:funId/:heading/:screenid',
    component: MasterHelpComponent,
    data: { breadcrumb: 'Help' },
    canActivate: [MsalGuard]
  },

  {
    path: 'manual',
    component: ManualComponent,
    data: { breadcrumb: 'Manual' },
    canActivate: [MsalGuard]
  },
  {
    path: 'error',
    component: ErrorListComponent,
    data: { breadcrumb: 'Error-List' },
    canActivate: [MsalGuard]
  },
  {
    path: 'package-fabricator',
    component: PkgFabMaintenanceComponent,
    data: { breadcrumb: 'Package Fabricator' },
    canActivate: [MsalGuard]
  },
  {
    path: 'package-fabricator',
    data: { breadcrumb: 'Package Fabricator' },
    children: [
      {
        path:
          'package-fabricator-contact/:pkgsupcode/:alphacode/:name/:supcode',
        component: PkgContComponent,
        data: { breadcrumb: 'Package Fabricator Contact' },
        canActivate: [MsalGuard]
      },
      {
        path: 'package-fabricator-contact/:pkgsupcode/:alphacode/:name/:supcode',
        data: { breadcrumb: 'Package Fabricator Contact' },
        children: [
          {
            path: 'update-fabricator-contact/:guid/:supplierCode/:name',
            component: FabContComponent,
            data: { breadcrumb: 'Update Fabricator Contact' },
            canActivate: [MsalGuard]
          }
        ],
      },
    ],
  },
  {
    path: 'supplier-plant',
    component: SupplierPlantComponent,
    data: { breadcrumb: 'Supplier Plant Maintenance' },
    canActivate: [MsalGuard]
  },
  {
    path: 'supplier-maintenance',
    component: SupplierMaintenanceComponent,
    data: { breadcrumb: 'Supplier Maintenance' },
    canActivate: [MsalGuard]
  },
  {
    path: 'supplier-maintenance',
    data: { breadcrumb: 'Supplier Maintenance' },
    children: [
      {
        path: 'supplier-contact-maintenance/:suppliercode',
        component: SupContactComponent,
        data: { breadcrumb: 'Supplier Contact Maintenance' },
        canActivate: [MsalGuard]
      },
      {
        path: 'supplier-contact-maintenance/:suppliercode',
        data: { breadcrumb: 'Supplier Contact Maintenance' },
        children: [
          {
            path: 'update-supplier-contact/:id',
            component: UpdateContactComponent,
            data: { breadcrumb: 'Update Supplier Contact' },
            canActivate: [MsalGuard]
          }
        ],
      },
    ],
  },
  //standard-reports
  {
    path: 'int-documentation',
    data: { breadcrumb: 'Documentation' },
    component: DocumentationComponent,
    canActivate: [MsalGuard]
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InternalRoutingModule { }
