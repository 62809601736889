import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelListService } from 'src/app/internal-vpacs/services-internal/tracking/model-list.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, Observable, of, startWith } from 'rxjs';

@Component({
  selector: 'app-model-list-add',
  templateUrl: './model-list-add.component.html',
  styleUrls: ['./model-list-add.component.scss'],
})
export class ModelListAddComponent implements OnInit {
  familyCode: any;
  familycodeFlag = false;
  familyFlag = false;
  plants: any[] = [];
  plant = '';
  readinessdue = '';
  maintenancecost = '';
  fromdate = '';
  note = '';
  prevfamily = '';
  modelname = '';
  modelcode = '';
  modelyear = '';
  preppfdue = '';
  pkgtrialdue = '';
  pkgauthdue = '';
  sop = '';
  eop: any = '';
  prevmodel = '';
  production = '';
  errorMessage: any;
  displayRowData: any;
  rowCount: any;
  res: any;
  spCode: any;
  supplier: any;
  plantcode = '';
  familycode: any = '';
  htmlData: any;
  plantCodeValue: any;
  selectPlant: any;
  addPlant: any;
  familyUpdate: any = '';
  myForm: any;
  modelName: any;
  formName: any;
  gridApi: any;
  value: any;
  FamilyCode = '';
  familytextFlag = false;
  fcTextFlag = false;
  validFlag = true;
  familycodetext = '';
  deleteFlag = false;
  errorFlag = false;
  errorList: any[] = [];
  pkgTrialError: any;
  fCode: any;
  public modelForm: any;
  familyCodeValue: any[] = [];
  selectedPlantValue: any[] = [];
  myControl = new FormControl()
  familycodes!: Observable<any[]>
  dragBoundary: any;
  filteredOptions: Observable<any[]> | undefined;

  constructor(
    public message: MatSnackBar,
    public datepipe: DatePipe,
    public service: ModelListService,
    public dialogRef: MatDialogRef<ModelListAddComponent>,
    public route: ActivatedRoute,
    public fb: FormBuilder,
    private readonly spinnerService: NgxUiLoaderService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {
    this.dragBoundary = document.documentElement;
  }

  ngOnInit(): void {
    if (this.data.mode === 'Add') {
      this.familycodeFlag = true;
      this.validators();
      this.ValidatePkg();
      this.getPlant();
      this.onSelect();
    } else if (this.data.mode === 'Update') {
      this.familyFlag = true;
      this.updateOnload();
      this.validators();
    } else if (this.data.mode === 'Delete') {
      this.deleteFlag = true;
    }
    setTimeout(() => {
      const draggableElement = this.el.nativeElement.querySelector('.popup-container') as HTMLElement;
      if (draggableElement) {
        const screenWidth = '75%';
        const screenHeight = '30%';
        this.renderer?.setStyle(draggableElement, 'width', `${screenWidth}px`);
        this.renderer.setStyle(draggableElement, 'height', `${screenHeight}px`);
      }
    })
  }

  validators() {
    if (this.data.mode === 'Add') {
      this.modelForm = this.fb.group({
        plant: ['', Validators.required],
        familycode: [''],
        familycodetext: ['', Validators.required],
        prevfamily: [''],
        modelname: ['', Validators.required],
        modelcode: ['', Validators.required],
        modelyear: [''],
        preppfdue: ['', Validators.required],
        pkgtrialdue: ['', Validators.required],
        pkgauthdue: ['', Validators.required],
        readinessdue: ['', Validators.required],
        sop: ['', Validators.required],
        eop: [''],
        prevmodel: [''],
        production: [''],
      });
    } else if (this.data.mode === 'Update') {
      this.modelForm = this.fb.group({
        plant: ['', Validators.required],
        familycode: ['', Validators.required],
        prevfamily: [''],
        modelname: ['', Validators.required],
        modelcode: ['', Validators.required],
        modelyear: [''],
        preppfdue: ['', Validators.required],
        pkgtrialdue: ['', Validators.required],
        pkgauthdue: ['', Validators.required],
        readinessdue: ['', Validators.required],
        sop: ['', Validators.required],
        eop: [''],
        prevmodel: [''],
        production: [''],
      });
    }
  }

  onSelect() {
    this.modelForm.get('familycode')?.valueChanges.subscribe(() => {
      const isDataSelected = this.modelForm.get('familycode')?.value === '';
      if (isDataSelected) {
        this.modelForm.get('familycodetext')?.enable();
      } else if (this.modelForm.get('familycode')?.value !== '' || null) {
        this.modelForm.get('familycodetext')?.disable();
        this.modelForm.get('familycodetext')?.setValue('');
      }
    });
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'preppfdue') {
        this.modelForm.get('preppfdue')?.setValue('');
      }
      if (id === 'pkgtrialdue') {
        this.modelForm.get('pkgtrialdue')?.setValue('');
      }
      if (id === 'pkgauthdue') {
        this.modelForm.get('pkgauthdue')?.setValue('');
      }
      if (id === 'readinessdue') {
        this.modelForm.get('readinessdue')?.setValue('');
      }
      if (id === 'sop') {
        this.modelForm.get('sop')?.setValue('');
      }
      if (id === 'eop') {
        this.modelForm.get('eop')?.setValue('');
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

  getPlant() {
    this.modelForm.patchValue({
      eop: new Date('12/31/9999').toISOString().split('T')[0],
    });
    this.modelForm.updateValueAndValidity();
    setTimeout(() => { this.spinnerService.start() })
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '');
    const plantobj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };
    this.service.getAllPlant(plantobj).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.plantCodeValue = res.map(
          (item: { plantcode: any }) => item.plantcode
        );
        this.selectedPlantValue = res;
        this.selectPlant = this.selectedPlantValue.slice();
        this.filteredOptions = this.modelForm.get('plant')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterPlant(value)),
        );
        this.displayFn(this.selectPlant)
      },
      complete: () => { },
    });
    this.service.familyCode().subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.familyCodeValue = res;
        this.familycodes = this.modelForm.get('familycode')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterFamilycode(value)),
        )
      },
      complete: () => { },
    });
  }

  displayFn(selectId: any) {
    const selectedOption = this.selectPlant?.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '--Select Plant--';
  }

  private _filterPlant(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.selectPlant.filter((option: any) =>
      option.plant.toLowerCase().includes(filterValue),
    )
  }

  private _filterFamilycode(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.familyCodeValue.filter((option) =>
      option.toLowerCase().includes(filterValue),
    )
  }

  submitForm() {
    this.modelForm.markAllAsTouched();
    if (this.modelForm.valid) {
      this.modeBtn();
    }
  }

  updateOnload() {

    setTimeout(() => { this.spinnerService.start() })
    const updateDate = 'yyyy-MM-dd';
    const addObject = {
      plantCode: this.data.result.plantcode,
      modelCode: this.data.result.modelcode,
    };
    this.service.updateOnloadModellist(addObject).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        this.displayRowData = res.result[0];
        this.modelForm.controls.plant.disable();
        this.modelForm.controls.familycode.disable();
        this.modelForm.controls.modelcode.disable();
        this.modelForm.get('plant')?.setValue(this.displayRowData.plantname);
        this.modelForm
          .get('familycode')
          ?.setValue(this.displayRowData.familycode);
        this.modelForm
          .get('modelcode')
          ?.setValue(this.displayRowData.modelcode);
        this.modelForm
          .get('modelyear')
          ?.setValue(this.displayRowData.modelyear);
        this.modelForm
          .get('modelname')
          ?.setValue(this.displayRowData.modelname);
        this.modelForm
          .get('sop')
          ?.setValue(
            this.datepipe.transform(this.displayRowData.sop, updateDate)
          );
        this.modelForm
          .get('eop')
          ?.setValue(
            this.datepipe.transform(this.displayRowData.eop, updateDate)
          );
        Number(
          this.modelForm
            .get('production')
            ?.setValue(this.displayRowData.productionvolume)
        );
        this.modelForm
          .get('prevmodel')
          ?.setValue(this.displayRowData.previousmodel);
        this.modelForm
          .get('prevfamily')
          ?.setValue(this.displayRowData.previousfamily);
        this.modelForm
          .get('preppfdue')
          ?.setValue(
            this.datepipe.transform(this.displayRowData.preppfdue, updateDate)
          );
        this.modelForm
          .get('pkgtrialdue')
          ?.setValue(
            this.datepipe.transform(this.displayRowData.pretrialdue, updateDate)
          );
        this.modelForm
          .get('pkgauthdue')
          ?.setValue(
            this.datepipe.transform(this.displayRowData.pkgauthdue, updateDate)
          );
        this.modelForm
          .get('readinessdue')
          ?.setValue(
            this.datepipe.transform(
              this.displayRowData.readinessdue,
              updateDate
            )
          );
      },
    });
  }

  getBody() {
    const addDate = 'MM/dd/YYYY';
    const {
      plant,
      familycode,
      familycodetext,
      prevfamily,
      modelname,
      modelcode,
      modelyear,
      preppfdue,
      pkgtrialdue,
      pkgauthdue,
      readinessdue,
      sop,
      eop,
      prevmodel,
      production,
    } = this.modelForm.controls;

    if (this.data.mode === 'Add') {

      const body: any = {
        PlantCode: plant.value,
        FamilyCode: familycodetext.value
          ? familycodetext.value
          : familycode.value,
        PreviousFamily: prevfamily.value,
        ModelName: modelname.value,
        ModelCode: modelcode.value,
        ModelYear: modelyear.value,
        PreviousModel: prevmodel.value,
        PrePPFDue: String(this.datepipe.transform(preppfdue.value, addDate)),
        PreTrialDue: String(
          this.datepipe.transform(pkgtrialdue.value, addDate)
        ),
        PkgAuthDue: String(this.datepipe.transform(pkgauthdue.value, addDate)),
        ReadinessDue: String(
          this.datepipe.transform(readinessdue.value, addDate)
        ),
        SOP: String(this.datepipe.transform(sop.value, addDate)),
        EOP: eop.value
          ? String(this.datepipe.transform(eop.value, addDate))
          : this.modelForm.patchValue({
            eop: new Date('12/31/9999').toISOString().split('T')[0],
          }),

        ProductionVolume: Number(production.value)
          ? Number(production.value)
          : null,
        Fromdate: String(
          formatDate(
            new Date().setFullYear(new Date().getFullYear()),
            addDate,
            'en-US'
          )
        ),
        MaintenanceCost: '',
        Note: '',
      };
      return body;
    } else if (this.data.mode === 'Update') {
      const body: any = {
        PlantCode: this.data.result.plantcode,
        FamilyCode: this.data.result.familycode,
        PreviousFamily: prevfamily.value
          ? prevfamily.value
          : this.data.result.prevfamily,
        ModelName: modelname.value
          ? modelname.value
          : this.data.result.modelname,
        ModelCode: this.data.result.modelcode,
        ModelYear: modelyear.value
          ? modelyear.value
          : (modelyear.value === '' ? this.modelForm.get('modelyear')?.setValue('') : ''),
        PreviousModel: prevmodel.value
          ? prevmodel.value
          : this.data.result.prevmodel,
        PrePPFDue: String(
          (preppfdue.value, addDate)
            ? this.datepipe.transform(preppfdue.value, addDate)
            : this.data.result.preppfdue
        ),
        PreTrialDue: String(
          (pkgtrialdue.value, addDate)
            ? this.datepipe.transform(pkgtrialdue.value, addDate)
            : this.data.result.pkgtrialdue
        ),
        PkgAuthDue: String(
          (pkgauthdue.value, addDate)
            ? this.datepipe.transform(pkgauthdue.value, addDate)
            : this.data.result.pkgauthdue
        ),
        ReadinessDue: String(
          (readinessdue.value, addDate)
            ? this.datepipe.transform(readinessdue.value, addDate)
            : this.data.result.readinessdue
        ),
        SOP: String(
          (sop.value, addDate)
            ? this.datepipe.transform(sop.value, addDate)
            : this.data.result.sop
        ),
        EOP: (
          (eop.value, addDate)
            ? this.datepipe.transform(eop.value, addDate)
            : this.data.result.eop
        ),
        ProductionVolume: Number(
          production.value
            ? production.value
            : this.data.result.productionvolumn
        ),
        Fromdate: this.data.result.fromdate,
        MaintenanceCost: this.data.result.maintenancecost,
        Note: this.data.result.note,
      };
      return body;
    } else {
      return null;
    }
  }

  help() {
    this.dialogRef.close();
    if (this.data.mode === 'Add') {
      this.router.navigate(['./master-help', 2, 'Tracking', 12]);
    } else if (this.data.mode === 'Update') {
      this.router.navigate(['./master-help', 2, 'Tracking', 15]);
    }
  }

  ValidatePkg() {
    const date1 = this.modelForm.get('preppfdue')?.value;
    const date2 = this.modelForm.get('pkgtrialdue')?.value;
    const date3 = new Date(date1)
    const date4 = new Date(date2)
    const date5 = this.modelForm.get('pkgauthdue')?.value;
    const date6 = new Date(date2)
    const date7 = new Date(date5)
    const date8 = this.modelForm.get('readinessdue')?.value;
    const date9 = new Date(date5)
    const date10 = new Date(date8)
    const date11 = this.modelForm.get('sop')?.value;
    const date12 = new Date(date8)
    const date13 = new Date(date11)
    const date14 = this.modelForm.get('eop')?.value;
    const date15 = new Date(date11)
    const date16 = new Date(date14)

    if (date1 && date2) {
      if (date3.getTime() > date4.getTime()) {
        this.modelForm.controls['preppfdue'].setErrors({ 'Error': '[Pre Ppf Due] must be Before [Pkg Trial Due]' })
      } else if (!this.modelForm.controls['preppfdue'].value) {
        this.modelForm.controls['preppfdue'].setErrors({ required: true });
      }
      else {
        this.modelForm.controls['preppfdue'].setErrors(null);
      }
    }

    if (date4 && date5) {
      if (date6.getTime() > date7.getTime()) {
        this.modelForm.controls['pkgtrialdue'].setErrors({ 'Error': '[Pkg Trial Due] must be Before [Pkg Auth Due]' })
      }
      else if (!this.modelForm.controls['pkgtrialdue'].value) {
        this.modelForm.controls['pkgtrialdue'].setErrors({ required: true });
      } else {
        this.modelForm.controls['pkgtrialdue'].setErrors(null);
      }
    }

    if (date7 && date8) {
      if (date9.getTime() > date10.getTime()) {
        this.modelForm.controls['pkgauthdue'].setErrors({ 'Error': '[Pkg Auth Due] must be Before [Readiness Due]' })
      } else if (!this.modelForm.controls['pkgauthdue'].value) {
        this.modelForm.controls['pkgauthdue'].setErrors({ required: true });
      }
      else {
        this.modelForm.controls['pkgauthdue'].setErrors(null);
      }
    }

    if (date10 && date11) {
      if (date12.getTime() > date13.getTime()) {
        this.modelForm.controls['readinessdue'].setErrors({ 'Error': '[Readiness Due] must be Before [SOP]' })
      } else if (!this.modelForm.controls['readinessdue'].value) {
        this.modelForm.controls['readinessdue'].setErrors({ required: true });
      }
      else {
        this.modelForm.controls['readinessdue'].setErrors(null);
      }
    }

    if (date13 && date14) {
      if (date15.getTime() > date16.getTime()) {
        this.modelForm.controls['sop'].setErrors({ 'Error': '[SOP] must be Before [EOP]' })
      } else if (!this.modelForm.controls['sop'].value) {
        this.modelForm.controls['sop'].setErrors({ required: true });
      } else {
        this.modelForm.controls['sop'].setErrors(null);
      }
    }

    return of(null);
  }

  modeBtn() {
    if (this.data.mode === 'Add') {
      this.spinnerService.start();
      const body = this.getBody();
      this.service.addModellist(body).subscribe({
        next: (res: any) => {
          this.displayRowData = res;
          this.spinnerService.stop();
          if (res.status === true) {
            this.errorList.push(res.message);
            setTimeout(() => {
              this.errorList = [];
            }, 5000);
          } else {
            this.dialogRef.close({
              event: 'SaveEvent',
              responseType: res.rowsAffected,
            });
            this.spinnerService.stop();
          }
        },
        error: (_error) => {
          this.spinnerService.stop();
        },
      });
    } else if (this.data.mode === 'Update') {
      this.spinnerService.start();
      const body = this.getBody();
      this.service.updateBtnModellist(body).subscribe({
        next: (res: any) => {
          this.spinnerService.stop();
          this.rowCount = this.displayRowData.lengths;
          this.dialogRef.close({
            event: 'SaveEvent',
            responseType: res.rowsAffected,
          });
        },
        error: (_error) => {
          this.spinnerService.stop();
        },
      });
    }
  }

  deleteBtn() {
    this.spinnerService.start();
    const deleteObject = {
      plantCode: this.data.result.plantcode,
      modelCode: this.data.result.modelcode,
    };
    this.service.deleteModellist(deleteObject).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res?.status === true) {
          this.dialogRef.close({ data: 'SUCCESS' });
        } else {
          this.errorFlag = true;
          this.errorMessage = res.Message;
        }
      },
      error: (_error) => {
        this.spinnerService.stop();
      },
    });
  }
}
