import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-update-supplier-info',
  templateUrl: './update-supplier-info.component.html',
  styleUrls: ['./update-supplier-info.component.scss'],
})
export class UpdateSupplierInfoComponent implements OnInit {
  plantC: any = '';
  supplierC: any = '';
  partC: any = '';
  errorList: string[] = [];
  userInfo: any;
  newSupplier: any = '';

  constructor(
    public dialogRef: MatDialogRef<UpdateSupplierInfoComponent>,
    public trackingListService: TrackingListService,
    public readonly dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    public state1: ExportStateService
  ) {}

  ngOnInit(): void {
    this.errorList = [];
    this.state1.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res;
      },
    });
    this.plantC = this.dataStore.plant;
    this.partC = this.dataStore.part;
    this.supplierC = this.dataStore.supplier;
    this.spinnerService.stop();
  }

  updateBtn() {
    this.spinnerService.start();
    this.errorList = [];
    const body = {
      url_plant: this.dataStore.plantC,
      url_supplier: this.dataStore.supplierC,
      url_partnumber: this.dataStore.part,
      url_fromdate: this.dataStore.url_fromdate,
      newSupplier: this.newSupplier,
      supplierType: this.userInfo.role.supType,
      specialistCode: this.userInfo.info.specialistcode
    };
    this.trackingListService.shipmentUpdate(body).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (res.msg === 'Updated successfully') {
          this.dialogRef.close({
            event: 'SuccessEvent',
            data: { newSupplier: this.newSupplier, name: res?.name},
          });
        }
        if (!res.msg) {
          this.errorList = ['Not able to Update Data'];
        }
      },
      error: (err: any) => {
        this.spinnerService.stop();
        this.errorList =[err.error.error];
      },
      
    });
  }
  close(): void {
    this.dialogRef.close({ event: 'CloseEvent', data: this.newSupplier });
  }
}
