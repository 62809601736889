import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-calc-btn-cell-render',
  template: `
    <button *ngIf="isVisible"
    [disabled]="disableFlag" [style.opacity]="dullFlag ? '0.5' : '1'"
    class="vpacs-btn-table"
    style="width:60px; height:30px; color:#676a6e; font-size: 13px"
    (click)="btnClickedHandler($event)" >CALC</button>
  `,
})

export class CalcBtnCellRender implements ICellRendererAngularComp {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  private params: any;
  disableFlag = false;
  dullFlag = false;
  isVisible = true;
  agInit(params: any): void {
    this.params = params;  
    if (params.data.ringitype === "1") {
      if (params.data.costforecastflg === "0" || params.data.costforecastflg === "4") {
        this.disableFlag = false;
        this.dullFlag = false;
      } else {
        this.disableFlag = true;
        this.dullFlag = true;
      }
    } else {
      this.isVisible = false;
    }  
  }
  btnClickedHandler(_event: any) {
    this.params.clicked(this.params.data);
  }

}




