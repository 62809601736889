<div class="popup-container">
    <header>
        <button class="close black-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">Add Ringi</div>
            <div>
                <button class="help-btn" (click)="help()">Help</button>
            </div>
        </div>
    </header>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <div *ngFor="let message of errorList" class="errormsg">
            <div class="message-container">
                <span class="message-text">{{message}}</span>
            </div>
        </div>
    </div>
    <div class="row vpacs-shadow mt-2 mx-1 heading-row m-1 py-2 px-2 table-content">
        <table aria-describedby="update-ringi" class="table">
            <tr class="d-none">
                <th>add-ringi</th>
            </tr>
            <tbody class="d-flex">
                <div class="col-md-4 titleColumn">
                    <div class="contents2 ps-3 py-3">
                        Ringi # <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="isRingiNumberError"></small>
                    <div class="contents2 ps-3 py-3">
                        Type <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="isTypeError"></small>
                    <div class="contents2 ps-3 py-3">Plant

                        <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="isPlantError"></small>
                    <div class="contents2 ps-3 py-3 modelLabel">
                        Start Date <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="isStartDateError"></small>
                    <div class="contents2 ps-3 py-3 modelLabel">
                        End Date <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="isEndDateError"></small>
                    <div class="contents2 ps-3 py-3">
                        Ringi Cost $ <small class="text-danger">*</small>
                    </div>
                    <small *ngIf="amNumeric"></small>
                    <div class="contents2 ps-3 py-3 h-85">
                        Comment
                    </div>
                </div>
                <div class="col-md-8 contentColumn rowClass2">
                    <div class="ps-4 py-0 d-flex align-items-center">
                        <input type="text" [(ngModel)]="ringinumber" [class.error-outline]="isRingiNumberError"
                            (change)="checkValidations()" maxlength="21">
                    </div>
                    <small class="offset-1 text-danger text-nowrap" *ngIf="isRingiNumberError">
                        RingiNumber required.
                    </small>
                    <div class="ps-4 py-0 d-flex align-items-center">
                        <mat-select id="type" class="mat-sel pt-2" disableOptionCentering [(ngModel)]="ty"
                            [class.error-outline]="isTypeError" (change)="checkValidations()" placeholder="Select">
                            <mat-option *ngFor="let ty of type" [value]="ty.Id">{{ty.name}}</mat-option>
                        </mat-select>
                    </div>
                    <small class=" offset-1 text-danger text-nowrap" *ngIf="isTypeError">
                        RingiType is not selected.
                    </small>
                    <div class="ps-4 py-2 d-flex align-items-center">
                        <input type="text" id="plant" aria-label="text" matInput [(ngModel)]="plant"
                            #inputField="ngModel" (input)="filterOptions()" placeholder="--Select Plant--"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="displayFn.bind(this)">
                            <mat-option *ngFor="let plants of filteredOptions"
                                [value]="plants.plantcode">{{plants.plant}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <small class="offset-1  text-danger text-nowrap" *ngIf="isPlantError">
                        PlantCode is not selected.
                    </small>
                    <div class="ps-4 py-2 d-flex align-items-center ">
                        <input type="date" [(ngModel)]="startDate" id="startDate"
                            [class.error-outline]="isStartDateError || startEndDatetError"
                            (change)="checkValidations()" (keydown)="onKeyDown($event,'startDate')">
                    </div>
                    <small class=" offset-1 text-danger text-nowrap" *ngIf="isStartDateError">
                        FromDate required.
                    </small>
                    <small class="offset-1 text-danger text-nowrap" *ngIf="startEndDatetError">
                        [From Date] must be before [To Date].
                    </small>
                    <div class="ps-4 py-1 d-flex align-items-center">
                        <input type="date" id="endDate" [(ngModel)]="endDate" [class.error-outline]="isEndDateError"
                            (change)="checkValidations()" (keydown)="onKeyDown($event,'endDate')">
                    </div>
                    <small class="offset-1 text-danger text-nowrap" *ngIf="isEndDateError">
                        ToDate required.
                    </small>
                    <div class="ps-4 py-2 d-flex align-items-center">
                        <input type="text" [(ngModel)]="maintenanceCost" [class.error-outline]="isMainCostError"
                            maxlength="12" (change)="checkValidations()">
                    </div>
                    <small class="offset-1 text-danger text-nowrap" *ngIf="amNumeric">
                        [Ringi Cost] must be numeric.
                    </small>
                    <div class="ps-4 py-2 align-items-center h-85">
                        <textarea class="col-6" [(ngModel)]="comment"></textarea>
                    </div><br>
                </div>
            </tbody>
        </table>
    </div>
    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
        <button class="cancel me-3" (click)="close('CANCEL')"> CANCEL </button>
        <button class="submit black-button me-3" (click)="addButton()">ADD</button>
    </div>
</div>