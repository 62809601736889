import { Component } from '@angular/core'
import { ICellRendererAngularComp } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'

@Component({
  selector: 'checkBoxFab',
  template: `
    <div class="checkcontainer disable my-2">
      <label class="">
        <input
          type="checkbox"
          (click)="checkedHandler($event)"
          [checked]="valid === 1 ? 1 : 0"
          [disabled]="checkbtn"
        />
        <span class="{{ checkbtn ? 'checkmark disable' : 'checkmark' }}"></span>
      </label>
    </div>
  `,
  styles: [
    `
      input[type='checkbox']:not(:disabled) {
        cursor: pointer;
      }
      input[type='checkbox']:disabled {
        cursor: no-drop;
      }
    `,
  ],
})
export class CheckBoxFab implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): boolean {
    throw new Error(
      `Error while refreshing renderer component. INFO-${params.data}`,
    )
  }
  public params: any
  allFlag = false
  valid: any
  checkbtn = true
  agInit(params: any): void {
    this.params = params
    if (this.params.data.authvalid === '1') {
      if (
        this.params.colDef.headerName === 'Read' ||
        this.params.colDef.headerName === 'Write' ||
        this.params.colDef.headerName === 'Submit'
      ) {
        this.checkbtn = false
      }
    }
    if (this.params.colDef.headerName === 'Read') {
      this.allFlag = true
      this.valid =
        Number(this.params.data.read) || Number(this.params.data.Read)
    }
    if (this.params.colDef.headerName === 'Write') {
      this.allFlag = true
      this.valid =
        Number(this.params.data.write) || Number(this.params.data.Write)
    }
    if (this.params.colDef.headerName === 'Submit') {
      this.allFlag = true
      this.valid = Number(this.params.data.send)
    }
    if (this.params.colDef.field === 'needemailpre') {
      this.valid = Number(this.params.data.needemailpre)
    }
    if (this.params.colDef.field === 'needemailmass') {
      this.valid = Number(this.params.data.needemailmass)
    }
    if (this.params.colDef.field === 'massprod') {
      this.valid = Number(this.params.data.massprod)
    }
    if (this.params.colDef.field === 'preprod') {
      this.valid = Number(this.params.data.preprod)
    }
  }

  checkedHandler(event: any) {
    const colId = this.params.column.colId
    if (event.target.checked === true) {
      this.params.node.data[`${colId}`] = 1
    } else {
      this.params.node.data[`${colId}`] = 0
    }
  }
}
