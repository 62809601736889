import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HelpServiceService } from 'src/app/internal-vpacs/services-internal/help/help-service.service';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
menu:any
  constructor(public helpService:HelpServiceService, private readonly route: Router,   public router:ActivatedRoute,    public spinnerService: NgxUiLoaderService
    )
    { }

  ngOnInit(): void {
    this.spinnerService.start();
    this.api()
  }
api(){
  const body={}
  this.helpService.mainMenu().subscribe({
    next: (res: any) => {
      this.spinnerService.stop();
        this.menu=res
    },
    error: (_err: any) => {
      this.spinnerService.stop();
    },
          });
          
    }

}

