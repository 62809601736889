import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey("CompanyName=SHI International Corp_on_behalf_of_Toyota Motors North America,LicensedGroup=PR211836,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012870,ExpiryDate=19_January_2022_[v2]_MTY0MjU1MDQwMDAwMA==f9d7009c99e40fc7c5686b00f9bd586c");
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
