import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'tracking-list-cell-renderer',
  template: `
  <div class="d-flex align-items-center gap-1">
    <button 
    class="vpacs-btn-table"
    style="height:30px; font-size: 11px"
    >DETAIL</button>
  </div>

  `,
})

export class TrackingListBtnCellRenderer implements ICellRendererAngularComp {

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  buttonName: any;

  agInit(params: any): void {
    this.params = params;
    if (params.colDef.headerName === 'BACKUP') { this.buttonName = 'BU'; }
    else if (params.colDef.headerName === 'HISTORY') { this.buttonName = 'H'; }
    else if (params.colDef.headerName === 'TRACKING CORRECTION') { this.buttonName = 'TC'; }
    else if (params.colDef.headerName === 'AUTH') { this.buttonName = 'AU'; }
    else if (params.colDef.headerName === 'UPDATE PPF/ PART# CNG') { this.buttonName = 'PPF'; }
    else if (params.colDef.headerName === 'DELETE') { this.buttonName = 'D'; }
    else if (params.colDef.headerName === 'BROADCAST CALC') { this.buttonName = 'CL'; }
    else if (params.colDef.headerName === 'DETAIL') { this.buttonName = 'DETAIL'; }
  }

  btnClickedHandler(_event: any) {
    if (this.params.colDef.headerName === 'BACKUP') { this.router.navigate(['/tracking-list/backup-temp-container']); }
    if (this.params.colDef.headerName === 'HISTORY') { this.router.navigate(['/tracking-list/tracking-history']); }
    if (this.params.colDef.headerName === 'TRACKING CORRECTION') { this.router.navigate(['/tracking-list/tracking-correction']); }
    if (this.params.colDef.headerName === 'AUTH') { this.router.navigate(['/tracking-list/create-rcaf']); }
    if (this.params.colDef.headerName === 'DETAIL') { this.router.navigate(['/tracking-list/send-dept-approval']); }
  }

  navigate(action: any) {
    if (action === 'BU') {
      this.router.navigate(['/tracking-list/backup-temp-container']);
    }
    if (action === 'H') {
      this.router.navigate(['/tracking-list/tracking-history']);
    }
    if (action === 'TC') {
      this.router.navigate(['/tracking-list/tracking-correction']);
    }
    if (action === 'AU') {
      this.router.navigate(['/tracking-list/create-rcaf']);
    }
    if (action === 'PPF') {
      this.router.navigate(['/tracking-list/update-ppf-reason']);
    }
  }
}




