import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from './constant.service';
import { catchError, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoPkgAuthorizationService {

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) { }

  dopkgOnload(queryData: any) {   
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
      .set('authStatus', queryData.authStatus)
    const URL = this.constantsService.EXTERNAL + 'pkgauthdopage/onHold';
    let first:any;
    return this.http.get(URL,{params: queryParams,headers: this.constantsService.httpOptions}).pipe(
      switchMap((data:any) =>{  
         first = data[0]    
         return this.deliveryTargetOnload(queryData)   
      }),switchMap((second:any) => {
        return of([first,second])
      })
    )
  }

  authDownload(queryData: any) {
    const queryParams = new HttpParams()
      .set('authNumber', queryData?.authNumber)
    return this.http.get(this.constantsService.EXTERNAL + 'downloadpage/onLoad', { params: queryParams,headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  serialPdf(queryData: any) {
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
    return this.http.get(this.constantsService.EXTERNAL + 'serial/onLoad', { params: queryParams,headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  submitObject(updateObject: any): Observable<any> {
    return this.http.put((this.constantsService.EXTERNAL + 'pkg/dopage/submit'), updateObject, { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  deliveryTargetOnload(queryData: any) {
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
    return this.http.get(this.constantsService.EXTERNAL + 'do/deliveryTarget', { params: queryParams,headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  cancelOk(queryData: any) {
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
    return this.http.get(this.constantsService.EXTERNAL + 'pkg/dopage/cancelok', { params: queryParams,headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  cancelNg(queryData: any) {
    const queryParams = new HttpParams()
      .set('authNumber', queryData.authNumber)
    return this.http.get(this.constantsService.EXTERNAL + 'pkg/dopage/cancelng', { params: queryParams,headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }
  
  saveClick(updateObject: any): Observable<any> {
    return this.http.post((this.constantsService.EXTERNAL + 'do/deliveryTarget'), updateObject, { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return (error.message || "Server Error");
  }
}