import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { UseBackupServiceService } from 'src/app/services/use-backup-service.service';

@Component({
  selector: 'app-use-backup-tracking',
  templateUrl: './use-backup-tracking.component.html',
  styleUrls: ['./use-backup-tracking.component.scss']
})
export class UseBackupTrackingComponent implements OnInit {
  public accData: any = {};
  public pageName = 'Date Start of Use';
  public metaData: any;
  public tableData: any;
  public gridApi: any;
  public rowData: any;
  public rowHeight = 40;
  dateDifference: any;
  dates: any = [];
  container: any;
  dunnage: any;
  pallet: any;
  topcap: any;
  containerData: any = {};
  public dunnageData: any;
  public palletData: any;
  public topUpData: any;
  public cellStyle = {
    'box-shadow': 'inset 0px 1px 6px #00000029',
    'border': '1px solid #D7DCE1',
    'color': '#676A6E',
    'height': '30px',
    'width': '50px',
    'margin-left': '5px'
  }
  public columnDefs: any = []
  public grandTotal: any = [];
  public defaultColDef: ColDef = {
    sortable: false,
    resizable: false,
    filter: false,
    suppressMovable: true,
    wrapText: true,
    singleClickEdit: true
  };
  authData: any;
  public dateArray: any = [];
  gridColumnApi: any;
  showSaveButton = false;
  rejectReasonFlag = false;
  contTable: any[] = [];
  form: any;
  columns: number[] = [...Array(23).keys()];
  rows: number[] = [...Array(4).keys()];
  topUpTable: any[] = [];
  palletTable: any[] = [];
  dunnageTable: any[] = [];
  htmlGrandTotal:any = 0;

  constructor(
    public route: ActivatedRoute,
    public _location: Location,
    public useBackupService: UseBackupServiceService,
    public message: MatSnackBar,
    public fb: FormBuilder) {
    this.form = this.fb.group({});
    for (let i = 0; i < this.rows.length; i++) {
      for (let j = 0; j < this.columns.length; j++) {
        this.form.addControl(`row${i}col${j}`, this.fb.control(''));
      }
    }
  }

  ngOnInit(): void {
    this.metaData = JSON.parse(sessionStorage.getItem('queryParams') ?? '{}');
    this.authData = JSON.parse(sessionStorage.getItem('authData') ?? '{}');
    this.authData && this.useBackupService.trackingOnLoad(this.authData)
      .subscribe({
        next: (data: any) => {
          this.tableData = data;
          this.dateArray = data?.dates;
          let waitAuthApproved: any = 0;
          if (this.tableData.auth.REJECTFLAG === "1") {
            this.rejectReasonFlag = true;
          }
          const rejectflag = [0,null,'null',undefined,"0"].includes(this.tableData?.auth?.REJECTFLAG)  ? 0 : Number(this.tableData?.auth?.REJECTFLAG);
          if ([0].includes(rejectflag) && this.tableData?.auth?.RESAPPROVAL === "3") {
            waitAuthApproved = 1
          } else {
            waitAuthApproved = this.tableData?.auth?.STATUS;
          }
          if (waitAuthApproved === 0 || this.tableData?.auth?.PCSTATUS === '' || this.tableData?.auth?.PCSTATUS === null) {
            this.showSaveButton = true
          }
          if (waitAuthApproved === 1 || (rejectflag !== 0)) {
            this.accData = this.tableData?.auth;
          } else {
            this.accData = this.tableData?.ppf;
            this.accData['CONTCOST'] = this.tableData?.ppf['CONTPRICE'];
            this.accData['PLTCOST'] = this.tableData?.ppf['PLTPRICE'];
            this.accData['LIDCOST'] = this.tableData?.ppf['LIDPRICE'];
            this.accData['DUNCOST'] = this.tableData?.ppf['DUNPRICE'];
          }
        },
       
        complete: () => {
          const length = this.dateArray.length;
          this.dateDifference = this.calculateDiff(this.dateArray[0], this.dateArray[length - 1]);
          this.dates = this.calculateDatesBetween(this.dateDifference, this.dateArray[0]);
          this.container = this.tableFilter(this.dates, this.tableData.authdetails, 'container');
          this.dunnage = this.tableFilter(this.dates, this.tableData.authdetails, 'dunnage');
          this.pallet = this.tableFilter(this.dates, this.tableData.authdetails, 'pallet');
          this.topcap = this.tableFilter(this.dates, this.tableData.authdetails, 'topcap');

          const objectTable: any = [];
          this.rowData = objectTable;
          this.contTable = [
            'Container', this.accData.CONTCOST ? Number(this.accData?.CONTCOST).toFixed(2) : '', this.container[0], this.container[1], this.container[2],
            this.container[3], this.container[4], this.container[5], this.container[6], this.container[7],
            this.container[8], this.container[9], this.container[10], this.container[11], this.container[12],
            this.container[13], this.container[14], this.container[15], this.container[16], this.container[17],
            this.container[18], this.container[19], (this.getTotal(this.container, 0) * this.accData.CONTCOST).toFixed(2)
          ];
          this.dunnageTable = [
            'Dunnage', this.accData.DUNCOST ? Number(this.accData?.DUNCOST).toFixed(2) : 0, this.dunnage[0], this.dunnage[1], this.dunnage[2],
            this.dunnage[3], this.dunnage[4], this.dunnage[5], this.dunnage[6], this.dunnage[7],
            this.dunnage[8], this.dunnage[9], this.dunnage[10], this.dunnage[11], this.dunnage[12],
            this.dunnage[13], this.dunnage[14], this.dunnage[15], this.dunnage[16], this.dunnage[17],
            this.dunnage[18], this.dunnage[19], (this.getTotal(this.dunnage, 1) * this.accData.DUNCOST).toFixed(2)
          ];
          this.palletTable = [
            'Pallet', this.accData.PLTCOST ? Number(this.accData?.PLTCOST).toFixed(2) : 0, this.pallet[0], this.pallet[1], this.pallet[2],
            this.pallet[3], this.pallet[4], this.pallet[5], this.pallet[6], this.pallet[7],
            this.pallet[8], this.pallet[9], this.pallet[10], this.pallet[11], this.pallet[12],
            this.pallet[13], this.pallet[14], this.pallet[15], this.pallet[16], this.pallet[17],
            this.pallet[18], this.pallet[19], (this.getTotal(this.pallet, 1) * this.accData.PLTCOST).toFixed(2)
          ];

          this.topUpTable = [
            'Top Cap', this.accData.LIDCOST ? Number(this.accData?.LIDCOST).toFixed(2) : 0, this.topcap[0], this.topcap[1], this.topcap[2],
            this.topcap[3], this.topcap[4], this.topcap[5], this.topcap[6], this.topcap[7],
            this.topcap[8], this.topcap[9], this.topcap[10], this.topcap[11], this.topcap[12],
            this.topcap[13], this.topcap[14], this.topcap[15], this.topcap[16], this.topcap[17],
            this.topcap[18], this.topcap[19], (this.getTotal(this.topcap, 1) * this.accData.LIDCOST).toFixed(2)
          ];
          //disable logic
          const compareDate = new Date(this.authData.SD);
          const result: boolean[] = [];
          for (let i = 0; i < 20; i++) {
            const currentDate = new Date(this.dates[i]);
            result.push(compareDate > currentDate)
          }
          result.unshift(false, false);
          result.push(false)
          //end of disable logic
          for (let i = 0; i < this.rows.length; i++) {
            for (let j = 0; j < this.columns.length; j++) {
              const value = `row${i}col${j}`;
              if (result[j]) {
                this.form.controls[`${value}`].disable();
                this.form.updateValueAndValidity();
              }
              let arrayChoosen = []
              switch (i) {
                case 0:
                  arrayChoosen = this.contTable;
                  break;
                case 3:
                  arrayChoosen = this.dunnageTable;
                  break;
                case 1:
                  arrayChoosen = this.palletTable;
                  break;
                case 2:
                  arrayChoosen = this.topUpTable;
                  break;
                default:
                  break;
              }
              this.form.patchValue({
                [`${value}`]: arrayChoosen[j]
              })
            }
          }
          this.calculateGrandTotal();
          //---------------------------------EO HTML TABLE---------------//
        }
      });
  }

  onInputChange(i: number, j: any, event: any) {
    const inputval=event.target.value
    if(j<=1){
      event.target.value=inputval.match(/^\d*\.?\d{0,2}/)[0]    
    } else if (j>1){
      event.target.value=parseInt(inputval).toString()
    }
    const obj = this.form.value;
    const substring = `row${i}`
    const result = Object.fromEntries(
      Object.entries(obj).filter(([key, _]) => key.includes(substring))
    );
    result[`row${i}col${j}`]=Number(event.target.value)
    const val = `row${i}col${j}`
    this.form.patchValue({
      [`${val}`] : Number(event.target.value)
    })
    const array = []
    for (let index = 2; index < 22; index++) {
      array.push(result[`row${i}col${index}`])
    }
    const res = (this.getTotal(array,j) * Number(this.form.controls[`row${i}col1`].value)).toFixed(2);  
    const value = `row${i}col22`
    this.form.patchValue({
      [`${value}`]: res
    })
    this.calculateGrandTotal();
  }

  calculateGrandTotal() {
    const htmlGrandTotal = Number(this.form.get('row0col22').value) + Number(this.form.get('row1col22').value) + Number(this.form.get('row2col22').value) + Number(this.form.get('row3col22').value)
    this.htmlGrandTotal = Number(htmlGrandTotal).toFixed(3)
  }


  checkEmpty(array: any, _type: string) {
    if (array.some((value: any) => value === undefined || value === '')) {
      throw new Error("Array elements must not be undefined or empty strings.");
    }
    const compareDate = new Date(this.authData.SD);
    const resultObject: { [key: string]: boolean } = {};
    for (let i = 0; i < array.length; i++) {
      const currentDate = new Date(this.dates[i]);
      resultObject[`col${i + 1}`] = compareDate >= currentDate;
    }
    return resultObject;
  }

  getTotal(obj: any, _rowNo: any) {
    const sum: any = obj.filter(Number.isFinite).reduce((a: any, b: any) => parseFloat(a) + parseFloat(b), 0);
    return sum;
  }

  tableFilter(dates: any, authDetails: any, rowType: string) {
    const container: any = [], pallet: any = [], topcap: any = [], dunnage: any = [];
    for (const element of dates) {
      const result = authDetails?.filter((x: any) => element?.includes(x.AD));

      if (result.length === 0) {
        // Ignore undefined  
        container.push(null);
        dunnage.push(null);
        pallet.push(null);
        topcap.push(null);
      } else {
        container.push(result[0]?.CONTQTY);
        dunnage.push(result[0]?.DUNQTY);
        pallet.push(result[0]?.PALQTY);
        topcap.push(result[0]?.LIDQTY);
      }
    }

    if (rowType === 'container') {
      return container;
    } else if (rowType === 'dunnage') {
      return dunnage;
    } else if (rowType === 'pallet') {
      return pallet;
    } else {
      return topcap;
    }
  }
  calculateDiff(date1: any, date2: any) {
    const dateA: any = new Date(date1);
    const dateB: any = new Date(date2);
    const diffDays: any = Math.floor((dateB - dateA) / (1000 * 60 * 60 * 24));
    return diffDays + 7;
  }

  calculateDatesBetween(diff: any, date: any) {
    const DateArray: any = [];
    for (let i = 0; i < diff; i++) {
      const myDate = new Date(new Date(date).getTime() + (i * 24 * 60 * 60 * 1000));
      if (myDate.getDay() <= 5 && myDate.getDay() > 0) {
        DateArray?.push(myDate.toLocaleDateString('fr-CA').replace(/-/g, '/'))
      }
    }
    return DateArray;
  }

  navigatePrevious() {
    this._location.back();
  }

  setTrackQTYJSON() {
    const newArray: any = [];
    for(let i=0 ;i<this.dates.length; i++){
      newArray.push({
        'date' : this.dates[i],
        'container': parseFloat(this.form.controls[`row0col${i + 2}`].value),
        'pallet': parseFloat(this.form.controls[`row1col${i + 2}`].value),
        'lid': parseFloat(this.form.controls[`row2col${i + 2}`].value),
        'dunnage': parseFloat(this.form.controls[`row3col${i + 2}`].value)
      });
    }
    return newArray
  }

  setCPPJSON() {
    const cpp: any = {};
    cpp['container'] = parseFloat(this.form.controls[`row0col1`].value);
    cpp['pallet'] = parseFloat(this.form.controls[`row1col1`].value);
    cpp['lid'] = parseFloat(this.form.controls[`row2col1`].value);
    cpp['dunnage'] = parseFloat(this.form.controls[`row3col1`].value);
    return cpp;
  }

  setPPFJSON() {
    const ppf: any = {};
    ppf['contPkg'] = this.accData.CONTCODE;
    ppf['contL'] = this.accData.CONTL;
    ppf['contW'] = this.accData.CONTW;
    ppf['contH'] = this.accData.CONTH;
    ppf['contWeight'] = this.accData.CONTWEIGHT;
    ppf['dunPkg'] = this.accData.DUNCODE;
    ppf['dunL'] = this.accData.DUNL;
    ppf['dunW'] = this.accData.DUNW;
    ppf['dunH'] = this.accData.DUNH;
    ppf['dunWeight'] = this.accData.DUNWEIGHT;
    ppf['palPkg'] = this.accData.PLTCODE;
    ppf['palL'] = this.accData.PLTL;
    ppf['palW'] = this.accData.PLTW;
    ppf['palH'] = this.accData.PLTH;
    ppf['palWeight'] = this.accData.PLTWEIGHT;
    ppf['lidPkg'] = this.accData.LIDCODE;
    ppf['lidL'] = this.accData.LIDL;
    ppf['lidW'] = this.accData.LIDW;
    ppf['lidH'] = this.accData.LIDH;
    ppf['lidWeight'] = this.accData.LIDWEIGHT;
    ppf['qpc'] = this.accData.QPC;
    return ppf
  }
  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  checkValidTrackQty(qtyArray: any[], startDate: string) {
    const validTrackQty: any[] = [];
    const start = new Date(startDate);
    qtyArray.forEach(item => {
      const [year, month, day] = item.date.split('/');
      const formattedDate = `${month}/${day}/${year}`;
      const itemDate = new Date(formattedDate);
      if (itemDate >= start) {
        ['container', 'pallet', 'dunnage', 'lid'].forEach(key => {
          item[key] = isNaN(item[key]) ? 0 : item[key];
        });
        validTrackQty.push(item);
      }
    }); 
    return validTrackQty;
  }

  onSubmit() {
    this.gridApi?.clearFocusedCell();
    let body = {}
    const trackQTY = this.checkValidTrackQty(this.setTrackQTYJSON(),this.authData.SD);    
    const cpp = this.setCPPJSON();
    const ppf = this.setPPFJSON();  
    body = {'ppf': ppf, 'cpp': cpp, 'trackQty': trackQTY};
    this.useBackupService.trackingSubmit(body,this.authData.authNumber).subscribe({
        next: (_response:any) =>{
          this.openMessageBox('Updated Successfully','success');
          this.navigatePrevious();
        },
        error: (_error) => {
            this.openMessageBox('Error while Updating','error')
        },
    });
  }

}
