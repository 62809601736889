<div class="d-flex justify-content-between align-items-center mx-2">
    <p class="page-title px-2">Supplier Contact Maintenance</p>
    <div>
        <button class="help-btn" (click)="backBtn()">BACK</button>
        <button class="help-btn" (click)="help()">help</button>
    </div>
</div>
<div class="vpacs-shadow m-2 py-2 px-2">
    <div class="row mx-2 mb-3 align-items-start">
        <span>Supplier Code</span>
        <div class="d-grid col-lg-1 col-md-6 col-12 my-2">NA<span
                class="text-black"><b>{{createtblSupplier?.suppliercode}}</b></span></div>
        <div class="d-grid col-lg-1 col-md-6 col-12 my-2">TMMC<span
                class="text-black"><b>{{createtblSupplier?.tmmccode}}</b></span></div>
        <div class="d-grid col-lg-1 col-md-6 col-12 my-2">NUMMI<span
                class="text-black"><b>{{createtblSupplier?.nummicode}}</b></span></div>
        <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Short Name<span
                class="text-black"><b>{{createtblSupplier?.shortname}}</b></span></div>
        <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Name<span
                class="text-black"><b>{{createtblSupplier?.name}}</b></span></div>
    </div>
</div>
<div class="grid-agg tracking-summary grid-height-supplier-contact">
    <ag-grid-angular style="width:100%;height:100%;" class="ag-theme-balham" (gridReady)="onGridReady($event);"
        (window:resize)="sizeToFit()" [rowHeight]="50" [animateRows]="true" [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs" [rowData]="displayRowData" [suppressCellSelection]="true" [showOpenedGroup]="true"
        [autoGroupColumnDef]="autoGroupColumnDef" [suppressHorizontalScroll]="false" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true" [icons]="icons"
        [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [domLayout]="domLayout" [rowClassRules]="rowClassRules" [floatingFiltersHeight]="40">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
        (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>
</div>
<router-outlet></router-outlet>