import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';
@Injectable({
  providedIn: 'root'
})
export class HelpServiceService {
  URL = this.constantsService.MASTERCOST;

  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }
  subMenu(body:any): Observable<any> {
    return this.http.post((this.URL + 'help/subMenu'), body,
    { responseType: 'json', headers: this.constantsService.internalHttpOptions})
    .pipe(catchError(this.handleError))
  }
  mainMenu(): Observable<any> {
       return this.http.get(this.URL + 'help/mainMenu',{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError) )

  }
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}
