import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'delete-btn-cell-renderer',
  template: `
  <button  mat-icon-button  [disabled]="deleteFlag"
  >
  <mat-icon  class="mx-2 vpacs-btn-table fs-5 d-flex justify-content-center align-items-center " 
  style="height:30px" title="delete" 
   (click)="btnClickedHandler($event)">delete</mat-icon>
  </button>
  `,
})

export class DeleteBtnCellRender implements ICellRendererAngularComp {
  pageName: any;
  detailPage: any;
  deleteFlag = false;
  deleteBtn: any;
  disabled = false;
  
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, ){}
    refresh(_params: ICellRendererParams): boolean {
		return true;
	}

  private params: any;
  agInit(params: any): void {
    this.params = params;     
    this.pageName = this.route.snapshot.data['breadcrumb']
    if (sessionStorage.getItem('detailShipmentNumber')) {
      this.detailPage = JSON.parse(
        sessionStorage.getItem('detailShipmentNumber') || ''
      ); 
    if(this.pageName === 'Detail'){
     
    if (this.detailPage.status === 0 && (this.detailPage.currentUser ===  this.detailPage.supplierCode)) {
         this.deleteFlag = false;
         
     } else{
         this.deleteFlag = true;   
     }  
    }
   }
  }
  btnClickedHandler(_event: any) {
    if(!this.deleteFlag){
      this.params.clicked(this.params.data);
    }
}

}


