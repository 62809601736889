<div class="row m-0">
  <div class="col-10 page-title mx-3">Auth List</div>
  <div class="col">
    <button class="black-button" (click)="excelDl()">EXCEL DL</button>
    <button class="help-btn" (click)="help()">help</button>
  </div>
</div>

<div class="alert alert-danger mx-3 mt-3" *ngIf="errorList.length > 0">
  <span *ngFor="let message of errorList">{{message}}</span>
</div>

<div class="vpacs-shadow m-3 p-2">
  <div class="filter m-2 d-flex justify-content-between" (keyup.enter)="search()">
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Auth#</label>
      <input type="text" id="family-code" [(ngModel)]="authNum"  />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="plant" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-2" aria-label="text" matInput [(ngModel)]="plantVal"
      (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
      style="width: 80%">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let plants of filteredOptions" [value]="plants.plantcode">{{plants.plant}}
      </mat-option>
    </mat-autocomplete>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="family-code" class="col-4">Approver</label>
      <input type="text" [(ngModel)]="approve" [ngModelOptions]="{ standalone: true }"  />
      <button mat-icon-button>
        <mat-icon class="d-flex toggleIcon" (click)="selectApprover()">more_vert</mat-icon>
      </button>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label class="col-4">Forecast</label>
      <mat-select class="mat-sel" [(ngModel)]="forecast" placeholder="" >
        <mat-option *ngFor="let fCast of forecastList" [value]="fCast.value">{{fCast.name}}
        </mat-option>
      </mat-select>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label for="part-number" class="col-4">Part Number</label>
      <input type="text" id="part-number" [(ngModel)]="partNum1" >
      <input type="text" *ngIf="false" class="ms-1" id="part-number" [(ngModel)]="partNum2">
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="ringi" class="col-4">Ringi#</label>
      <input type="text" id="ringi" [(ngModel)]="ringiNum"  />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="model" class="col-4">Model</label>
      <input type="text" id="model" [(ngModel)]="modelCode"  />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="fab" class="col-4">Fabricator</label>
      <input type="text" id="fab" [(ngModel)]="fabCode" />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label class="col-4">Readiness</label>
      <mat-select class="mat-sel" [(ngModel)]="readiness" placeholder="">
        <mat-option *ngFor="let fCast of forecastList" [value]="fCast.value">{{fCast.name}}
        </mat-option>
      </mat-select>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label class="col-4">Actual Cost$(US)</label>
      <div class="floating">
        <label for="over">Over</label>
        <input type="text" id="over" style="width: 100%" [(ngModel)]="actualOver" />
      </div>
      <div class="ms-1 floating">
        <label for="under">Under</label>
        <input type="text" id="under" style="width: 100%" [(ngModel)]="actualUnder" />
      </div>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="pkg" class="col-4">Package Code</label>
      <input type="text" id="pkg" [(ngModel)]="pkgCode"  />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="supplier" class="col-4">Supplier</label>
      <input type="text" id="supplier" [(ngModel)]="supplierCode"  />
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="specialist" class="col-4">Specialist</label>
      <input type="text" id="specialist" [(ngModel)]="specCode" [ngModelOptions]="{ standalone: true }"  />
      <button mat-icon-button>
        <mat-icon class="d-flex toggleIcon" (click)="selectSpecialist()">more_vert</mat-icon>
      </button>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="plant" class="col-4">Reason</label>
      <mat-select id="plant" class="mat-sel" [(ngModel)]="reasonCode" placeholder="Select">
        <mat-option *ngFor="let reason of reasonList" [value]="reason?.id">{{reason?.reason}}
        </mat-option>
      </mat-select>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
      <label class="col-4">Auth Cost$(US)</label>
      <div class="floating">
        <label for="overc">Over</label>
        <input type="text" id="overc" style="width: 100%" [(ngModel)]="authOver" />
      </div>
      <div class="ms-1 floating">
        <label for="underc">Under</label>
        <input type="text" id="underc" style="width: 100%" [(ngModel)]="authUnder" />
      </div>
    </div>

    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="authFrom" class="col-4">Auth From Date</label>
      <input type="date" id="authFrom" style="width: 100%" [(ngModel)]="authFrom"(keydown)="onKeyDown($event,'authFrom')"  />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="authTo" class="col-4">Auth To Date </label>
      <input type="date" id="authTo" style="width: 100%" [(ngModel)]="authTo" (keydown)="onKeyDown($event,'authTo')" />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="actualFrom" class="col-4">Actual From Date</label>
      <input type="date" id="actualFrom" style="width: 100%" [(ngModel)]="actualFrom" (keydown)="onKeyDown($event,'actualFrom')" />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 me-5">
      <label for="actualTo" class="col-4">Actual To Date</label>
      <input type="date" id="actualTo" style="width: 100%" [(ngModel)]="actualTo" (keydown)="onKeyDown($event,'actualTo')" />
    </div>
    <div class="d-flex align-items-center col-lg-2 col-md-6 col-12 justify-content-end">
      <div class="d-flex justify-content-end align-items-center">
        <button class="black-button" style="height: 30px;" (click)="search()">SEARCH</button>
      </div>
    </div>
    <div class="d-flex align-items-center col-md-5 gap-2 mt-2"  >
      <label class="checkcontainer mb-0">Positive
        <input type="checkbox" [checked]="(positive===1)?true:false" [(ngModel)]="positive" (change)="onPosChange($event)">
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Negative
        <input type="checkbox" [checked]="(negative===1)?true:false" [(ngModel)]="negative" (change)="onNegChange($event)">
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Wait Cancel
        <input type="checkbox" [checked]="(waitCancel===1)?true:false" [(ngModel)]="waitCancel" (change)="onWaitChange($event)">
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="d-flex align-items-center gap-2 mt-2"  >
      <label class="me-4 fw-bold">Auth Type</label>
      <label class="checkcontainer mb-0">Purchase
        <input type="checkbox" [checked]="purchase" (change)="changeAuthType($event, 'purchase')" >
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Move
        <input type="checkbox" [checked]="move" (change)="changeAuthType($event, 'move')" >
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Dispose
        <input type="checkbox" [checked]="disposed" (change)="changeAuthType($event, 'disposed')" >
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Maintenance
        <input type="checkbox" [checked]="maintenance" (change)="changeAuthType($event, 'maintenance')" >
        <span class="checkmark"></span>
      </label>
      <label class="checkcontainer mb-0">Prototype
        <input type="checkbox" [checked]="prototype" (change)="changeAuthType($event, 'prototype')" >
        <span class="checkmark"></span>
      </label>
    </div>
  </div>

  <div class="justify-content-between d-flex">
    <div class="d-flex mt-4 ms-2 col-6" >
      <label class="drpdwn_period_label fw-bold pt-1">Show/Hide Columns </label>
      <mat-select multiple class="mat-sel ms-3" id="selColumn" [(ngModel)]="checkList" #select>
      <div class="select-all">
        <mat-checkbox [(ngModel)]="checkAll" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()" [checked]="allSelected===true">
          Select All</mat-checkbox>
      </div>
      <mat-option (click)="optionClick()" *ngFor="let col of columns; let i=index" [value]="col">
        {{col}}</mat-option>
      </mat-select>
      <button class="black-button ms-3" style="height: 30px;" id="btn" (click)="updateColumnInfo()">OK</button> 
    </div>
    <div class="m-4">
      <button class="vpacs-btn-table " style="width:30px;height:30px" (click)="openLegends()">?</button>
    </div>
  </div>
</div>

<div class="vpacs-shadow m-3 p-0 mb-5">
  <div class="grid-agg px-0 tracking-summary grid-height-auth-list">
    <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
      [rowData]="displayRowData" [gridOptions]="gridOptions" [suppressCellSelection]="true" [pagination]="true"
      [suppressPaginationPanel]="true"
      [autoGroupColumnDef]="autoGroupColumnDef" [icons]="icons" [headerHeight]="40"
      [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40" [domLayout]="domLayout"  (filterChanged)="filterChanged($event)" (sortChanged)="sortChanged($event)" [rowClassRules]="rowClassRules">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)">
    </app-pagination>
  </div>
</div>