import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportStateService } from 'src/app/services/export-state.service';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
@Component({
  selector: 'app-part-list-select',
  templateUrl: './part-list-select.component.html',
  styleUrls: ['./part-list-select.component.scss']
})
export class PartListSelectComponent implements OnInit {
  //plant: any;
  plants: any[] = [];
  specialistCode: any;
  supplier: any;
  resPlant: any;
  selectedPlant: any[]=[]
  plantDropdown: any
  spCode: any;
  specialistCodeValue:  any[]=[]
  codeValue: any;
  plantCode:any;

  constructor(public dialogRef: MatDialogRef<PartListSelectComponent>, 
              
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    private readonly PartListService: PartListService,private readonly state: ExportStateService,
              ) { }

  ngOnInit(): void {
    this.codeValue=this.dialog?.specialist
    this.plantCode="(All)"
    this.plant();
  } 

  plant() {
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
    const obj = {
      "supplierCode":[this.supplier],
      "SpecialistCode": this.spCode,
      "Type": 1
    }
    this.PartListService.plant(obj).subscribe(response => {
      this.resPlant=response.map((item: { plantcode: any; }) => item.plantcode)
      this.selectedPlant = [{ plant: 'ALL', plantcode: '(All)' }, ...response]
      this.code();
    })
  }
  code(){
    const codeObj = {
      "PlantCodes":this.plantCode === "(All)"?this.resPlant:[this.plantCode]
    }
    this.PartListService.specialistCode(codeObj).subscribe(response => {
      this.specialistCodeValue=response
    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ok(){
    this.dialogRef.close(this.codeValue);
  }

}

  
