import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-package-maintain-delete-pop-component',
  templateUrl: './package-maintain-delete-pop-component.component.html',
  styleUrls: ['./package-maintain-delete-pop-component.component.scss']
})
export class PackageMaintainDeletePopComponentComponent implements OnInit {
  dltRes: any;

  constructor(    private readonly spinnerService: NgxUiLoaderService,
public dialogRef: MatDialogRef<PackageMaintainDeletePopComponentComponent>,@Inject(MAT_DIALOG_DATA) public dataStore: any) { }

  message:any
  buttonName:any
  headerName:any
  ngOnInit(): void {
    this.message=this.dataStore.message
    this.buttonName=this.dataStore.buttonName
    this.headerName=this.dataStore.headerName
    
    
   
  }

  
  close(): void {
    this.dialogRef.close( 'cancel');
  }
  closeDialog(){
    this.dialogRef.close('cancel');
  }

  deleteEvent() {
     this.spinnerService.start()
        this.dialogRef?.close( 'success');
      
  }



}
