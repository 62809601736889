import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackingListService } from '../../services-internal/tracking/tracking-list/tracking-list.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-select-package-cost',
  templateUrl: './select-package-cost.component.html',
  styleUrls: ['./select-package-cost.component.scss']
})
export class SelectPackageCostComponent implements OnInit {
  plantCode: any;
  packageCode: any;
  pkgDetails: any;

  constructor(public dialogRef: MatDialogRef<SelectPackageCostComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly trkListSvc: TrackingListService,
    private readonly router: Router,
    private readonly spinner: NgxUiLoaderService
  ) { }
  ngOnInit(): void {
    this.spinner.start();
    this.plantCode = JSON.parse(sessionStorage.getItem('plantCode') || '')
    this.packageCode = this.dataStore.packageCode;
    this.loadData();
  }

  loadData() {
    const params = {
      plantCode: this.plantCode,
      packageCode: this.packageCode
    }
    this.trkListSvc.getPackageCost(params)
      .subscribe({
        next: (res: any) => {
          this.pkgDetails = res;
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      });
  }

  select(cost: any){
    this.dialogRef.close({ event: 'CloseEvent', data: cost });
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }
  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',5,"Master Maintenance",148 ])
    }
}
