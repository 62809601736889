
<div class="d-flex justify-content-between align-items-center m-3">
    <p class="page-title">Ringi Detail</p>
    <div>
        <button class="help-btn" (click)="backBtn()">BACK</button>
      <button class="help-btn" (click)="help()">Help</button>
    </div>
  </div>
<div class="vpacs-shadow m-2 py-2 px-2">
    <div class="filter m-2 d-flex">
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="ringinumber" class="col-3">Ringi#</label>
            <input type="text"class="ms-3"  id="ringinumber" (keyup.enter)="onSearch()" [value]="ringinumber" [(ngModel)]="ringiNumber" >
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" class="col-3">Est Total</label>
            <div class="floatings ms-3">
                <label for="over">Over</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="estunitcost"  />
            </div>
            <div class="ms-1 floatings">
                <label for="under">Under</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="estUnder" />
            </div>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" class="col-3">Actual Total</label>
            <div class="floatings ms-3">
                <label for="over">Over</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="actOver" />
            </div>
            <div class="ms-1 floatings">
                <label for="under">Under</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="actUnder" />
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end col-lg-2 col-md-6 col-12">
            <button class="black-button" (click)="onSearch()">Search</button>
        </div>
        <div class="d-flex align-items-center  col-lg-2 col-md-6 col-12">
            <label for="partnumber" class="col-3">Part Number</label>
            <input type="text" class="ms-3" id="partnumber" (keyup.enter)="onSearch()" [(ngModel)]="partnumber" >
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" class="col-3">Ringi Total</label>
            <div class="floatings ms-3">
                <label for="over">Over</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="ringiOver" />
            </div>
            <div class="ms-1 floatings">
                <label for="under">Under</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="ringiUnder" />
            </div>
        </div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12"></div>
        <div class="d-flex align-items-center col-lg-2 col-md-6 col-12">
            <label for="part" class="col-3">Auth Total</label>
            <div class="floatings ms-3">
                <label for="over">Over</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()"  [(ngModel)]="authOver" />
            </div>
            <div class="ms-1 floatings">
                <label for="under">Under</label>
                <input type="text" id="part-1" (keyup.enter)="onSearch()" [(ngModel)]="authUnder" />
            </div>
        </div>
    </div>
</div>

<div class="vpacs-shadow m-2 py-2 px-2">
    <div class="grid-agg tracking-summary grid-height-ringi-details">
        <ag-grid-angular style="width:100%;height:100%;" 
            class="ag-theme-balham" 
            (gridReady)="onGridReady($event);"
            (window:resize)="sizeToFit()" 
            [rowHeight]="50" 
            [animateRows]="true" 
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" 
            [rowData]="displayRowData" 
            [suppressCellSelection]="true"
            [suppressHorizontalScroll]="false" 
            [pagination]="true" 
            [paginationPageSize]="rpPage"
            [suppressPaginationPanel]="true" 
            [rowClassRules]="rowClassRules"
            [icons]="icons" 
            [floatingFiltersHeight]="40"
            [overlayLoadingTemplate]="overlayLoadingTemplate" 
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="domLayout" 
            >
        </ag-grid-angular><br>
        <div class=" d-flex">
            <table aria-describedby="ringi-detail">
                <th  class="d-none"></th>
                <tr>
                    <td style="background-color:#e4ecf0; width:70%">Total Cost$(US)</td>
                    <td style="background-color:#e4ecf0; width:60%">Est</td>
                    <td style=" width:20%">${{estTotal}}</td>
                    <td style="background-color:#e4ecf0; width:70%">Ringi</td>
                    <td style=" width:20%">${{ringiTotal}}</td>
                    <td style="background-color:#e4ecf0; width:70%">Auth</td>
                    <td style=" width:20%">${{authTotal}}</td>
                    <td style="background-color:#e4ecf0; width:60%">Actual</td>
                    <td style=" width:20%">${{actTotal}}</td>
                </tr>
            </table>
        </div>
        <app-pagination 
            [cPage]="cPage" 
            [tPage]="tPage" 
            [rpPage]="rpPage" 
            [totalRecords]="totalRecords"
            (changePaginationValues)="changePaginationValues($event)"
            *ngIf="totalRecords > 0">
        </app-pagination> 
    </div>
</div>

  



