import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddPackageCostComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/add-package-cost/add-package-cost.component';
import { RegisterPackageEstimateComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/register-package-estimate/register-package-estimate.component';
import { SupBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/master-maintenance/sup-btn-cell-render';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
@Component({
  selector: 'app-package-maintenance-cost',
  templateUrl: './package-maintenance-cost.component.html',
  styleUrls: ['./package-maintenance-cost.component.scss']
})
export class PackageMaintenanceCostComponent implements OnInit {
  lpcodes = ['Select'];
  lp = ''
  imageSrc: any;
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  defaultColDef={};
  icons: { sortUnSort: string; };
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
    <div>
      <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
      <p class="fw-bold fs-5">No data available</p>
      <span>Please select the above filters to get the results.</span>
    </div>
    `;
  plantCode: any;
  pckgCode: any;
  suppCode: any;
  response: any;
  pkgFab: any;
  gridOptions: any
  plantCodeValue: any;
  spCode: any;
  plantType: any;
  estCost: any;
  pValue: any;
  registerButtonFlag = false
  successMessage = ''
  haveAccess: any;
  addFlag = false;

  constructor(public dialogRef: MatDialogRef<PackageMaintenanceCostComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly service: PackageMaintananceService, private readonly spinnerService: NgxUiLoaderService, private readonly state: ExportStateService,
    private readonly constants: ConstantsService
  ) {
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: false,
      flex:1,
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }

  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.start()
      this.loadPage()
    });
    if (!this.data.roles?.role?.p_pkgsave) {
      this.registerButtonFlag = true
    }
    this.pckgCode = this.data.PackageCode;
    this.suppCode = this.data.PackageSupplierCode;
    
  }

  loadPage(){
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.plantCodeValue = res.info?.plantcode;
        if (res) {
            this.spinnerService.start();
          this.haveAccess = res.role?.p_pkgview;
          this.haveAccess && this.setColumnDefs();
          this.haveAccess && this.costOnLoadApi();
          !this.haveAccess && this.spinnerService.stop();
          if (!res.role?.p_pkgsave) {
            this.registerButtonFlag = true
            this.addFlag = true;
          }
          if (!res.role?.p_pkgmodify) {
            this.registerButtonFlag = true
          } else {
            this.registerButtonFlag = false
          }
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
          this.spinnerService.stop();
      },
    });
  }
  setColumnDefs() {
    this.columnDefs = [
      { headerName: 'PACKAGE FABRICATOR', field: 'pkgsupcode', width: 190 },
      { headerName: 'FROM DATE', field: 'fromdate', width: 150, valueFormatter: this.dateFormatter, },
      { headerName: 'TO DATE', field: 'todate', width: 150, valueFormatter: this.dateFormatter, },
      { headerName: 'COST', field: 'cost', width: 150 },
      { headerName: 'MONETARY UNIT', field: 'monetaryunit', width: 160, cellRenderer: this.monetaryunit, },
      {
        headerName: "UPDATE", field: "update", headerClass: 'child-header-color no-border', floatingFilterComponentParams: false,
        floatingFilter: false,
        filter: false, minWidth: 110,
        cellRendererFramework: SupBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.updateBtn(field);
          },
        },
      },
      {
        headerName: "DELETE", field: "delete", headerClass: 'child-header-color no-border', floatingFilterComponentParams: false,
        floatingFilter: false,
        filter: false, minWidth: 110,
        cellRendererFramework: SupBtnCellRender,
        cellRendererParams: {
          clicked: (_field?: any) => {
          },
        },
      },
    ];
  }

  Onsuccess(message: any) {
    this.successMessage = message
    setTimeout(() => {
      this.successMessage = ''
    }, 4000)
  }

  errorList = ''
  onDelete(message: string) {
    this.errorList = message
    setTimeout(() => {
      this.errorList = ''
    }, 4000)
  }

  monetaryunit(params: any) {
    if (params.data.monetaryunit === '0') {
      return '$(US)';
    } else if (params.data.monetaryunit === '1') {
      return '$(CAN)';
    } else if (params.data.monetaryunit === '2') {
      return '(P)';
    } else {
      return '';
    }
  }

  updateBtn(field: any) {
    const dialogRef = this.dialog.open(AddPackageCostComponent,
      {
        data: {
          mode: 'Update', field, pckgCode: this.pckgCode, selPkgSup: this.lpcodes,
          type: this.plantType, regFlag: 1, codePlant: this.pValue, parent: this
        }
      });
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'updateEvent') {
        this.costOnLoadApi();
      }
    });
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  packageEstimate() {
    const dialogRef = this.dialog.open(RegisterPackageEstimateComponent, { data: { pckgCode: this.pckgCode, pValue: Number(this.pValue), parent: this } })
    dialogRef.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'addReg') {
        this.costOnLoadApi();
      }
    });
  }

  addPackageCost() {
    const dialogReff = this.dialog.open(AddPackageCostComponent, {
      data: {
        pckgCode: this.pckgCode, selPkgSup: this.lpcodes,
        type: this.plantType, mode: 'Add', regFlag: 0, codePlant: this.pValue, parent: this
      }
    })
    dialogReff.afterClosed()?.subscribe((result: any) => {
      if (result?.event === 'addEvent' || result?.event === 'updateEvent') {
        this.costOnLoadApi();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  costOnLoadApi() {
    const loadObj = {
      "PackageCode": this.pckgCode,
      "PackageSupplierCode": this.lp,
      "PlantCode": this.plantCodeValue //plant code should be dynamic having doubt here 26MAZ
    };

    this.service.costOnLoad(loadObj)
      ?.subscribe({
        next: (res: any) => {
          this.response = res;
          this.lpcodes = (this.response.GetPackageFabrication?.result || [])
            .map((item: any) => item.pkgsupcode)
            .filter(Boolean);
          this.lpcodes.sort((a, b) => a.localeCompare(b));
            
          this.displayRowData = this.response.GetPackageCost;
          this.plantType = this.response.CostType;
          this.pValue = this.constants.plantCodeValue2(this.plantType);
          this.estCost = this.response.GetEstCost[0]?.cost;
        },
        error: (_err: any) => {
        },
      })
      this.spinnerService.stop()
  }  
  search() {
      this.spinnerService.start()
    this.costOnLoadApi();
  } 
}
