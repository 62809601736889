import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModelListService } from '../../services-internal/tracking/model-list.service';
@Component({
  selector: 'app-import-model-list',
  templateUrl: './import-model-list.component.html',
  styleUrls: ['./import-model-list.component.scss']
})
export class ImportModelListComponent implements OnInit {
  @ViewChild('fileInput') fileInput:any;
  imageSrc: any;
  templateFile: any;
  filename: any;
  displayRowData: any;
  rowCount: any;
  fileName = '';
  base64File: any = ''
  modelCode: any = '';
  modelCodeValue: any = '';
  Idvalue: any;
  spCode: any;
  disableNext = true;
  error = '';
  errorArray: any[] = [];

  constructor(
    public dialog: MatDialog,
    private readonly service: ModelListService,
    @Inject(MAT_DIALOG_DATA) public data: { modelcode: any, plantcode: any },
    public dialogRef: MatDialogRef<ImportModelListComponent>,
    public router: Router,
    public loader:NgxUiLoaderService
  ) { }


  ngOnInit(): void {
    this.modelCodeValue = this.data.modelcode;
    this.spCode = JSON.parse(sessionStorage.getItem('specialistCode') || '{}')
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearFile(){
    this.fileInput.nativeElement.value = '';
    this.errorArray= [];
  }

  fileChange(event: any) {
    if (event.target.files[0]) {
      this.errorArray = [];
      this.error = '';
      const file = event.target.files[0];
      if ([`PartList_${this.modelCodeValue}.xlsx`].includes(file.name)) {
        this.error = '';
        this.fileName = file.name;
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.base64File = reader.result;
          this.disableNext = false;
        }
      } else {
        this.disableNext = true;
        this.errorArray.push(`${file.name} does not match the expected filename PartList_${this.modelCodeValue}.xlsx`);
      }
    }
  }

  nextBtn() {
    const body = {
      "filename": this.fileName,
      "supplierType": "0",
      "model": this.data.modelcode,
      "plant": this.data.plantcode,
      "specialistCode": this.spCode,
      "file": { "data": this.base64File.split(',')[1] },
    }
    this.loader.start();
    this.service.importNext(body).subscribe({
      next: (res: any) => {
        if (res.status) {
          sessionStorage.setItem('impModel', JSON.stringify(res.response))
          const {ID,ModelCode,PlantCode} = res.response
          if (res.redirect === 'NewPartConfirmation') {
            this.router.navigate([`/model-list/new-part/${ID}/${ModelCode}/${PlantCode}`]);
            this.dialogRef.close();
          }else if (res.redirect === 'DeletePartConfirmation') {
            this.router.navigate([`/model-list/delete-part/${ID}/${ModelCode}/${PlantCode}`]);
            this.dialogRef.close();
          }else if (res.redirect === 'ReplacePartConfirmation') {
            this.router.navigate([`/model-list/replace-part/${ID}/${ModelCode}/${PlantCode}`]);
            this.dialogRef.close();
          }else if (res.redirect === 'ImpPartConfirm') {
            this.router.navigate([`/model-list/confirm-import/${ID}/${ModelCode}/${PlantCode}`]);
            this.dialogRef.close();
          }
        }else if(!res.status && res.message.length > 0){
          this.errorArray = res.message;
        }else if(!res.status && res.message.length < 0){
          this.errorArray = ['Unable to process the request. Please contact system Administrator']
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
        this.errorArray = ['Unable to process the request. Please contact system Administrator'];
      }
    });
  }

  closeDialog() {
    this.dialogRef.close()
  }
  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',2,"Tracking",14])
  
  }
}
