<div class="wrapper bg-white">
    <br><p class="page-title p-1 px-3">Rack Receivement</p>  
    <div class="mat-typography content">
        <div class="d-flex flex-wrap fullWidth px-3 py-3">
            <div class="d-flex align-items-center  col-lg-4 col-md-6 col-12">
                <label  class="col-4">Package Code</label>
                <input type="text" [(ngModel)]="packageCode"  id="packagecode" />
            </div>
            <div class="d-flex align-items-center  col-lg-4 col-md-6 col-12 my-2">
                <label class="col-4" >Dunnage</label>
                <input type="text" [(ngModel)]="dunnage" id="dunnage"/>
            </div>
        </div>
        <div class="filter d-flex">
            <div class="grid-agg">
                <ag-grid-angular style="width:100%;height:100px;" 
                class="ag-theme-balham" 
                (gridReady)="onGridReady($event);"
                (window:resize)="sizeToFit()"
                [animateRows]="true" 
                [defaultColDef]="defaultColDef" 
                [columnDefs]="columnDefs"                
                [rowData]="displayRowData" 
                [suppressPaginationPanel]="true" 
                [icons]="icons"
                [headerHeight]="40">
                </ag-grid-angular>
            </div>
        </div>
    </div><br>
    <div class="footer-dialog d-flex">
        <div mat-dialog-close class="mx-3 cancel" (click)="cancel($event)">Cancel</div>
        <button  class=" mx-3 save"(click)="save()">OK</button>
    </div>
</div>
    

