import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-update-package',
  templateUrl: './update-package.component.html',
  styleUrls: ['./update-package.component.scss'],
  providers : [BreadcrumbComponent]

})
export class UpdatePackageComponent implements OnInit {
  public image: any[] = [];
  inputiteminfo = []
  protected pageType = '';
  errorList:string[]=[]
  checkoffile1 = false
  checkoffile2 = false
  addPayload: any
  updatePayload: any
  successMessage = ''
  returns = [
  {
    code: 'RET', Name: 'Returnable'
  },
  {
    code: 'EXP', Name: 'Expendable'
  },
  ]
  type: any;
  types: any[] = [];
  materials: any[] = [];
  buttonName: any;
  validationflag = false
  response: any;
  flag = 0
  form!: FormGroup;
  oldpkgCode: any;
  fileName: any;
  fileNameDraw: any;
  showviewfile:any
  showDrawfile:any
  base64File: any;
  errorMessage: any;
  base64FileUpload: any;
  fileNameView: any;
  submitted = false
  specialistcode: any
  navigateUrl = '/package-maintenance'
  updateFlag:any= false;
  addFlag:any= false;
  addPkg = 'Add Package';
  updPkgUrl = 'update-package';
  updPkg = 'Update Package';
  drawError: any;
  photError: any;

  constructor(
    private readonly service: PackageMaintananceService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public spinnerService: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly location:Location,
    public readonly breadcrumb : BreadcrumbComponent,
    private readonly constants : ConstantsService,
    public ppfService: PpfServiceInternal,
  ) {

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.specialistcode = res.info?.specialistcode
      },
      error: (_err: any) => { }
    });

    const body =
    {
      MaterialCode: [],
      PackageCode: "",
      MatCode: "",
      PackageTypeCode: "R",
      Standard: "S",
      PackageTypeFlag: 2,
      PkgFab: "",
      page: 1,
      limit: 2000,
      PkgTypeCode: "",
      PkgTypeFlag: ""
    }
    this.service.onload(body)?.subscribe((response: any) => {
      this.response = response;
      this.materials = this.response.GetMaterialCode.result;
      this.types = this.response.GetPackageType.result;
    });

    if (route?.snapshot.routeConfig?.path === 'add-package' || route?.snapshot.routeConfig?.path?.includes('add-package')) {
      this.pageType = this.addPkg;
      this.buttonName = 'Add';
    } else if (route?.snapshot.routeConfig?.path === this.updPkgUrl || route?.snapshot.routeConfig?.path?.includes(this.updPkgUrl)) {
      this.pageType = this.updPkg;
      this.buttonName = 'Update';
    }
  }

  ngOnInit(): void {
    this.spinnerService.start();
    this.drawError = this.constants.newPackageDrawErrorFile;
    this.photError = this.constants.newPackagePhotoErrorFile
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.maxLength(15)]],
      groupeCode: ['', [Validators.maxLength(12)]],
      type: ['', Validators.required],
      description: ['', [Validators.maxLength(20)]],
      dunnageAttach: [''],
      ODLength1: [''],
      ODLength2: [''],
      ODwidth1: [''],
      ODwidth2: [''],
      ODHeight1: [''],
      ODHeight2: [''],
      material: [''],
      standard: [''],
      IDLength1: [''],
      IDLength2: [''],
      IDwidth1: [''],
      IDwidth2: [''],
      IDHeight1: [''],
      IDHeight2: [''],
      RE: [''],
      qtyContLayer: [''],
      Weight1: [''],
      Weight2: [''],
      nesting1: [''],
      nesting2: [''],
      returnHeight1: [''],
      returnHeight2: [''],
      qtyLayerPallet: [''],
      qtyKholder: ['', this.validate],
      qtyofLabel: [''],
      costofLabel: ['', this.validate],
      leadTime: [''],
      file1: [''],
      checkoffile1: [false],
      file2: [''],
      checkoffile2: [false],
    })

    if (this.buttonName === 'Add') {
      this.createHtml_eachtype('')
    this.spinnerService.stop();
    }

    setTimeout(() => {
      this.apicallupdate()
    }, 3000);

  }
  
  apicallupdate() {
    if (this.route?.snapshot.routeConfig?.path === this.updPkgUrl) {
      const params = JSON.parse(sessionStorage.getItem('updatePackageData') || '{}')

      const matCode = params['materialcode'];
      const payload = {
        PackageCode: params['packagecode'],
        PackageTypeCode: params['packagetypecode'] ?? "",
        MaterialCode: matCode ?? "",
        Standard: params['standard'] ?? "",
      };
      this.service.getUpdateLoad(payload)
        .subscribe({
          next: (res: any) => {
            this.setFieldsForUpdate(res)
          },
        })
    }
  }

  setFieldsForUpdate(res:any){
    this.spinnerService.stop()
    this.oldpkgCode = res[0]?.packagecode
    let materialCode = res[0].materialcode;
    this.flag = 1
    this.materials.forEach((value: any) => {
      if (value.material === res[0]?.material) {
        materialCode = value.materialcode;
      }
    });
    this.form.patchValue({
      code: res[0]?.packagecode,
      groupeCode: res[0]?.groupcode,
      description: res[0]?.description,
      IDHeight2: this.formatfield(res[0]?.innerheight),
      costofLabel: this.formatUpto2Decimal(res[0]?.costlabel),
      dunnageAttach: (isNaN(res[0]?.dunnageattachmethod) || res[0]?.dunnageattachmethod === null) ? null : Number(res[0]?.dunnageattachmethod),
      IDLength2: this.formatfield(res[0]?.innerlength),
      IDwidth2: this.formatfield(res[0]?.innerwidth),
      leadTime: this.formatUpto2Decimal(res[0]?.leadtime),
      material: materialCode,
      nesting2: this.formatfield(res[0]?.nesting),
      ODHeight2: this.formatfield(res[0]?.outerheight),
      ODLength2: this.formatfield(res[0]?.outerlength),
      qtyContLayer: this.formatfield(res[0]?.qtycontlayer),
      qtyKholder: this.formatfield(res[0]?.qtykholder),
      qtyofLabel: this.formatUpto2Decimal(res[0]?.qtylabel),
      qtyLayerPallet: this.formatfield(res[0]?.qtylayerpallet),
      returnHeight2: this.formatfield(res[0]?.retheight),
      RE: res[0]?.returnable,
      standard: res[0]?.standard,
      Weight2: this.formatUpto2Decimal(res[0]?.weight),
      ODwidth2: this.formatfield(res[0]?.outerwidth),
    });
    this.form.patchValue({
      IDHeight1: this.changemmToInchesOnload(res[0]?.innerheight),
      IDLength1: this.changemmToInchesOnload(res[0]?.innerlength),
      IDwidth1: this.changemmToInchesOnload(res[0]?.innerwidth),
      nesting1: this.changemmToInchesOnload(res[0]?.nesting),
      ODLength1: this.changemmToInchesOnload(res[0]?.outerlength),
      ODHeight1: this.changemmToInchesOnload(res[0]?.outerheight),
      ODwidth1: this.changemmToInchesOnload(res[0]?.outerwidth),
      returnHeight1: this.changemmToInchesOnload(res[0]?.retheight),
      Weight1: this.changeWeightTolbsOnload(res[0]?.weight),
    });
    this.form.patchValue({
      type: res[0]?.packagetypecode,
    })
    this.selected(res[0]?.packagetypecode)
    this.showDrawfile = (res[0]?.picdraw === 'Delete' ? '': res[0]?.picdraw )
    this.showviewfile = (res[0]?.picphoto  === 'Delete' ? '': res[0]?.picphoto)
  }
  changemmToInchesOnload(event: any) {
    if (isNaN((Number(event))) || event === null || event === "0.000") {
      return ''
    } else {
      return Number(((Number(event) * 100 / 25.4) / 100).toFixed(1));
    }
  }

  formatUpto2Decimal(event: any) {
    if (isNaN((Number(event))) || event === null || event === "0.000") {
      return ''
    } else {
      return Number(event).toFixed(2)
    }
  }

  formatfield(event: any) {
    if (isNaN((Number(event))) || event === null || event === "0.000") {
      return ''
    } else {
      return Math.round(event)
    }
  }

  changeWeightTolbsOnload(event: any) {
    if (isNaN((event)) || event === null || event === "0.000") {
      return ''
    } else {
      return Number(((event * 100 / 0.455) / 100).toFixed(1));
    }
  }

  get isValid() {
    return this.form.controls
  }

  generateAddPayload() {
    const halfPayload  = {
      oldpkgcode: "",  pkgcode: this.form.controls['code'].value?.trim(),
      groupcode: this.form.controls['groupeCode'].value ?? "",
      pkgtypcode: this.form.controls['type'].value?.trim(),
      description: this.form.controls['description'].value ?? "",
      dunnattachmethod:  this.form.controls['dunnageAttach'].value === ('' || null) ? 0 :  Number(this.form.controls['dunnageAttach'].value) ,
      outerlength: this.form.controls['ODLength2'].value === ('' || null) ? null :  Number(this.form.controls['ODLength2'].value),
      outerwidth: this.form.controls['ODwidth2'].value === ('' || null) ? null :  Number(this.form.controls['ODwidth2'].value),
      outerheight: this.form.controls['ODHeight2'].value === ('' || null) ? null :  Number(this.form.controls['ODHeight2'].value),
      innerlength: this.form.controls['IDLength2'].value === ('' || null) ? null : Number(this.form.controls['IDLength2'].value),
      innerwidth: this.form.controls['IDwidth2'].value === ('' || null) ? null : Number(this.form.controls['IDwidth2'].value),
    };
    this.generateAddPayload2(halfPayload)
  }

  generateAddPayload2(halfPayload:any){
    const mediatepayload={
      ...halfPayload, 
      innerheight: this.form.controls['IDHeight2'].value === ('' || null) ? null : Number(this.form.controls['IDHeight2'].value),
      weight: this.form.controls['Weight2'].value === ('' || null) ? null : Number(this.form.controls['Weight2'].value),
      materialcode: this.form.controls['material'].value?.trim(),
      nesting: this.form.controls['nesting2'].value === ('' || null) ? null : Number(this.form.controls['nesting2'].value),
      retheight: this.form.controls['returnHeight2'].value === ('' || null) ? null : Number(this.form.controls['returnHeight2'].value),
       standard: this.form.controls['standard'].value?.trim(),
      returnable: this.form.controls['RE'].value?.trim(),
      qtycontlayer:  this.form.controls['qtyContLayer'].value === ('' || null) ? null :  Number(this.form.controls['qtyContLayer'].value) ,
      qtylayerpallet:  this.form.controls['qtyLayerPallet'].value === ('' || null) ? null :  Number(this.form.controls['qtyLayerPallet'].value),
    }
  this.generateAddPayload3(mediatepayload)
  }
  
  generateAddPayload3(mediatepayload:any){
    const base64 = this.base64File as string;
    const base64FileUpload = this.base64FileUpload as string;
    this.addPayload={
      ...mediatepayload,
      qtykholder: this.form.controls['qtyKholder'].value === ('' || null) ? null :  Number(this.form.controls['qtyKholder'].value),
      qtylabel: this.form.controls['qtyofLabel'].value === ('' || null) ? null :  Number(this.form.controls['qtyofLabel'].value),
      costlabel: this.form.controls['costofLabel'].value === ('' || null) ? null :  Number(this.form.controls['costofLabel'].value),
      leadtime: this.form.controls['leadTime'].value === ('' || null) ? null :  Number(this.form.controls['leadTime'].value),
      draw: base64 ?? "",
      phot: base64FileUpload ?? "",
      chkdraw: this.form.controls['checkoffile1'].value ? 'checked' : "",
      chkphoto: this.form.controls['checkoffile2'].value ? 'checked' : "",
      drawFileName: this.fileNameDraw ?? '',
      photFileName: this.fileName ?? ''
    }
  }

  generateUpdatePayload() {
    const halfPayload = {
      pkgcode: this.form.controls['code'].value?.trim(),
      oldpkgcode: this.oldpkgCode,
      groupcode: this.form.controls['groupeCode'].value ?? "",
      pkgtypcode: this.form.controls['type'].value?.trim(),
      description: this.form.controls['description'].value ?? "",
      dunnattachmethod:   this.form.controls['dunnageAttach'].value === ('' || null) ? 0 :  Number(this.form.controls['dunnageAttach'].value),
      outerlength: this.form.controls['ODLength2'].value === ('' || null) ? null :  Number(this.form.controls['ODLength2'].value),
      outerwidth: this.form.controls['ODwidth2'].value === ('' || null) ? null :  Number(this.form.controls['ODwidth2'].value),
      outerheight: this.form.controls['ODHeight2'].value === ('' || null) ? null :  Number(this.form.controls['ODHeight2'].value),
      innerlength: this.form.controls['IDLength2'].value === ('' || null) ? null :  Number(this.form.controls['IDLength2'].value),
    };
    this.generateUpdatePayload2(halfPayload)
  }

  generateUpdatePayload2(halfPayload:any){
    const mediatepayload={
        ...halfPayload,
        innerwidth: this.form.controls['IDwidth2'].value === ('' || null) ? null :  Number(this.form.controls['IDwidth2'].value),
        innerheight: this.form.controls['IDHeight2'].value === ('' || null) ? null :  Number(this.form.controls['IDHeight2'].value),
        weight: this.form.controls['Weight2'].value === ('' || null) ? null : Number(this.form.controls['Weight2'].value),
        materialcode: this.form.controls['material'].value?.trim(),
        nesting: this.form.controls['nesting2'].value === ('' || null) ? null :  Number(this.form.controls['nesting2'].value),
        retheight: this.form.controls['returnHeight2'].value === ('' || null) ? null :  Number(this.form.controls['returnHeight2'].value),
        standard: this.form.controls['standard'].value?.trim(),
        returnable: this.form.controls['RE'].value?.trim(),
        qtycontlayer:  this.form.controls['qtyContLayer'].value === ('' || null) ? null :  Number(this.form.controls['qtyContLayer'].value),
        qtylayerpallet:  this.form.controls['qtyLayerPallet'].value === ('' || null) ? null :  Number(this.form.controls['qtyLayerPallet'].value),
    }
    this.generateUpdatePayload3(mediatepayload)
  }

  generateUpdatePayload3(mediatepayload:any){
    const base64 = this.base64File as string;
    const base64FileUpload = this.base64FileUpload as string;
    this.updatePayload={
        ...mediatepayload,
        qtykholder:this.form.controls['qtyKholder'].value === ('' || null) ? null :  Number(this.form.controls['qtyKholder'].value),
        qtylabel: this.form.controls['qtyofLabel'].value === ('' || null) ? null :  Number(this.form.controls['qtyofLabel'].value),
        costlabel: this.form.controls['costofLabel'].value === ('' || null) ? null :  Number(this.form.controls['costofLabel'].value),
        leadtime: this.form.controls['leadTime'].value ===('' || null) ? null :  Number(this.form.controls['leadTime'].value),
        SpecialistCode: this.specialistcode,
        draw: base64 ?? "",
        phot: base64FileUpload ?? "",
        chkdraw: this.form.controls['checkoffile1'].value ? 'checked' : "",
        chkphoto: this.form.controls['checkoffile2'].value ? 'checked' : "",
        drawFileName: this.fileNameDraw ?? '',
        photFileName: this.fileName ?? ''
    }
  }

  Onsubmit() {
    this.validationflag = true
    this.submitted = true
    this.form.markAllAsTouched()
    if (this.buttonName === 'Add') {
      this.generateAddPayload()
      if (this.form.valid) {
        this.handleNextOfadd()
      }
    } else if (this.buttonName === 'Update') {
      this.generateUpdatePayload()
      if (this.form.valid) {
      this.handleNextOfUpdate()
      }
    }
  }

  handleNextOfadd(){
    this.spinnerService.start()
    this.service?.addPackage(this.addPayload).subscribe({
      next: (_res) => {
        if (!_res.input.bool ) {
          const msg = _res.input.ErrorMessages
          this.onDelete(msg)
        } else {
          this.ppfService.newPackage.packagecode = this.form.controls['code'].value?.trim();
          const msg = ' Package added Successfully'
          this.Onsuccess(msg)
          this.OnCancel();
        }
        this.spinnerService.stop()
      },
      error: (_error: any) => {
        this.spinnerService.stop()
        this.onDelete([_error.error.error])
      }
    });
  }

  handleNextOfUpdate(){
    this.spinnerService.start()
    this.service.updatePackage(this.updatePayload)?.subscribe({
      next: (_res) => {
        if (!_res.input.bool ) {
          const msg = _res.input.ErrorMessages
          this.onDelete(msg)
        } else {
          const msg = ' Package updated Successfully'
          this.Onsuccess(msg)
          this.router.navigate([this.navigateUrl]);
        }
        this.spinnerService.stop()
      },
      error: (_error: any) => {
        this.spinnerService.stop()
        this.onDelete([_error.error.error])
      }
    });
  }

  handleUploadDraw(event: any) {
    const file = event.target.files[0];
    if (!file) { return };
    const fileName: string = file.name;
    this.fileNameDraw = fileName;
    if(this.fileNameDraw){
      this.showDrawfile=this.fileNameDraw
    }else{
      this.showDrawfile=''
    }
    const contentType2 = file.name.split('.')[1]; //Get file type(Extension)
    const contentType = contentType2.toLowerCase(); //Get file type(Extension)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image: any = reader.result
      //get file size
      const fileSizeInBytes = base64Image.length * 0.75; //Get file size in bytes
      const fileSizeInKb = fileSizeInBytes / 1024; //Get file size in kilobytes
      if (this.constants.newPackageDrawAllowedTypes.includes(contentType) && fileSizeInKb < this.constants.newPackageFileSizeLimit){
        this.errorList = [];
        this.base64File = reader.result;
        this.base64File = this.base64File.split('base64')[1].substring(1);
      }else{
        this.base64File = '';
        this.base64File = ''
        this.errorList = [this.constants.newPackageDrawErrorFile];
      }
    };
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if (!file) { return };
    const fileName: string = file.name;
    this.fileName = fileName;
    if(this.fileName){
      this.showviewfile=this.fileName
    }else{
      this.showviewfile=''
    }
    const contentType2 = file.name.split('.')[1]; //Get file type(Extension)
    const contentType = contentType2.toLowerCase(); //Get file type(Extension)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image: any = reader.result
      const fileSizeInBytes = base64Image.length * 0.75; //Get file size in bytes
      const fileSizeInKb = fileSizeInBytes / 1024; //Get file size in kilobytes
      if (this.constants.newPackagePhotoAllowedTypes.includes(contentType) && fileSizeInKb < this.constants.newPackageFileSizeLimit){
        this.errorList = [];
        this.base64FileUpload = reader.result;
        this.base64FileUpload = this.base64FileUpload.split('base64')[1].substring(1)
      }else{
        this.base64FileUpload = '';
        this.base64FileUpload = ''
        this.errorList =[ this.constants.newPackageDrawErrorFile];
      }

    };
  }

  changemmToInches(event: any, formControlName: string,formControlName2:any) {
    if (isNaN((event.target.value))) {
      this.form.controls[`${formControlName}`].setValue(0)
    } else {
      const inchdata = Number(((event.target.value * 100 / 25.4) / 100).toFixed(2));
      const number =Math.round(inchdata*2)/2
      this.form.controls[`${formControlName}`].setValue(
        number
      );
      this.changeMM(event,formControlName2,formControlName,'mmtoInch')
    }
  }


  
  changeMM(event: any, formControlName: string, formControlName1:any,method:string) {
    let number:any;
    if(method === 'mmtoInch'){
      const inchdata = Number(((event.target.value * 100 / 25.4) / 100).toFixed(2));
      number =Math.round(inchdata*2)/2
    }
    else{
       number =Math.round(event.target.value*2)/2
    }    
    if (isNaN(number)) {
      this.form.controls[`${formControlName}`].setValue(0)
    } else {
      this.form.controls[`${formControlName1}`].setValue(
       number
      );
      const inchdata = Number(((number * 25.4 * 100) / 100).toFixed(0));
      this.form.controls[`${formControlName}`].setValue(
        Math.round(inchdata)
      );
    }
  }

  changeWeight(event: any, formControlName: string) {
    const currfieldName=event.target.attributes.formcontrolname.nodeValue
    const number =Math.round(event.target.value*2)/2

    if (isNaN((number))) {
      this.form.controls[`${formControlName}`].setValue(0)
    } else {
      this.form.controls[`${currfieldName}`].setValue(
        number
       );
      const inchdata = Number(((100 * number * 0.455) / 100).toFixed(2));
      this.form.controls[`${formControlName}`].setValue(inchdata);
    }
  }

  changeWeightTolbs(event: any, formControlName: string) {
    if (isNaN((event.target.value))) {
      this.form.controls[`${formControlName}`].setValue(0)
    } else {
      const inchdata = Number(((100 * event.target.value / 0.455) / 100).toFixed(1));
      this.form.controls[`${formControlName}`].setValue(inchdata);
    }
  }

  isRequired(fieldName: string) {
    const validator = this.form.get(fieldName);
    const req = validator?.hasValidator(Validators.required);
    if (req) {
      return true;
    } else {
      return false;
    }
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === null) {
      return null;
    }
    if (isNaN(control.value)) {
      return { 'pattern': true };
    }
    return null
  }


  selected(event: any) {
    if (this.flag === 1) {
      this.createHtml_eachtype(event)
    } else {
      this.createHtml_eachtype(event.value);
    }
    this.flag = 0
  }

  setvalidation1(field: any) {

    if (field === 'code') {
      return Validators.maxLength(15)
    }

    if (field === 'groupeCode') {
      return Validators.maxLength(12)
    }

    if (field === 'description') {
      return Validators.maxLength(20)
    }

    if (field === 'Weight1') {
      return Validators.maxLength(5)
    }

    if (field === 'Weight2') {
      return (Validators.min(0), Validators.max(99999))
    }

    if (field === 'costofLabel') {
      return Validators.max(99999)
    }

    if (field === 'leadTime') {
      return Validators.max(999)
    }

    if (field === 'ODLength1' || field === 'ODwidth1' || field === 'ODHeight1' || field === 'IDLength1' || field === 'IDwidth1' || field === 'IDHeight1' || field === 'nesting1' || field === 'returnHeight1') {
      return Validators.maxLength(6)
    }

    if (field === 'qtyLayerPallet' || field === 'qtyKholder' || field === 'qtyofLabel' || field === 'qtyContLayer') {
      return Validators.maxLength(10)
    }

    return Validators.nullValidator
  }

  OnCancel() {
    let fromSelectPackage:any
    this.route.url.subscribe(urlSegments => {
      if (urlSegments.length > 0) {
        fromSelectPackage = urlSegments[urlSegments.length - 1].path;
      }
    });
    if (fromSelectPackage === 'true') {
      const url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
      this.router.navigate([url]);
    } else {
      this.router.navigate([this.navigateUrl]);
    }
  }

  help() {
    if (this.pageType === this.addPkg) {
      this.router.navigate(['./master-help', 5, "Master Maintenance", 101])
    } else if (this.pageType === this.updPkg) {
      this.router.navigate(['./master-help', 5, "Master Maintenance", 102])
    }
  }

  onDelete(message: any) {
    this.errorList=message
      setTimeout(() => {
        this.errorList=[]
      }, 8000);
  }

  Onsuccess(message: any) {
    this.successMessage = message
    setTimeout(() => {
      this.successMessage = ''
    }, 7000)
  }

  createHtml_eachtype(pkgtype: any) {

    if (pkgtype === "") {
      this.createHtml_text('0', 'ODLength1');
      this.createHtml_text('0', 'ODwidth1');
      this.createHtml_text('0', 'ODHeight1');
      this.createHtml_text('0', 'ODLength2');
      this.createHtml_text('0', 'ODwidth2');
      this.createHtml_text('0', 'ODHeight2');
      this.createHtml_text('0', 'IDLength1');
      this.createHtml_text('0', 'IDwidth1');
      this.createHtml_text('0', 'IDHeight1');
      this.createHtml_text('0', 'IDLength2');
      this.createHtml_text('0', 'IDwidth2');
      this.createHtml_text('0', 'IDHeight2');
      this.createHtml_text('0', 'Weight1');
      this.createHtml_text('0', 'Weight2');
      this.createHtml_select('0', 'material');
      this.createHtml_text('0', 'nesting1');
      this.createHtml_text('0', 'nesting2');
      this.createHtml_text('0', 'returnHeight1');
      this.createHtml_text('0', 'returnHeight2');
      this.createHtml_select('0', 'standard');
      this.createHtml_select('0', 'RE');
      this.createHtml_text('0', 'qtyContLayer');
      this.createHtml_text('0', 'qtyLayerPallet');
      this.createHtml_text('0', 'qtyKholder');
      this.createHtml_text('0', 'qtyofLabel');
      this.createHtml_text('0', 'costofLabel');
      this.createHtml_text('0', 'leadTime');
      this.createHtml_select('0', 'dunnageAttach');
    } else {
      this.inputiteminfo = this.createInputItems(pkgtype);
      this.createHtml_text(this.inputiteminfo[0], 'ODLength1');
      this.createHtml_text(this.inputiteminfo[0], 'ODwidth1');
      this.createHtml_text(this.inputiteminfo[0], 'ODHeight1');
      this.createHtml_text(this.inputiteminfo[0], 'ODLength2');
      this.createHtml_text(this.inputiteminfo[0], 'ODwidth2');
      this.createHtml_text(this.inputiteminfo[0], 'ODHeight2');
      this.createHtml_text(this.inputiteminfo[1], 'IDLength1');
      this.createHtml_text(this.inputiteminfo[1], 'IDwidth1');
      this.createHtml_text(this.inputiteminfo[1], 'IDHeight1');
      this.createHtml_text(this.inputiteminfo[1], 'IDLength2');
      this.createHtml_text(this.inputiteminfo[1], 'IDwidth2');
      this.createHtml_text(this.inputiteminfo[1], 'IDHeight2');
      this.createHtml_text(this.inputiteminfo[2], 'Weight1');
      this.createHtml_text(this.inputiteminfo[2], 'Weight2');
      this.createHtml_select(this.inputiteminfo[3], 'material');
      this.createHtml_text(this.inputiteminfo[4], 'nesting1');
      this.createHtml_text(this.inputiteminfo[4], 'nesting2');
      this.createHtml_text(this.inputiteminfo[5], 'returnHeight1');
      this.createHtml_text(this.inputiteminfo[5], 'returnHeight2');
      this.createHtml_select(this.inputiteminfo[6], 'standard');
      this.createHtml_select(this.inputiteminfo[7], 'RE');
      this.createHtml_text(this.inputiteminfo[8], 'qtyContLayer');
      this.createHtml_text(this.inputiteminfo[9], 'qtyLayerPallet');
      this.createHtml_text(this.inputiteminfo[10], 'qtyKholder');
      this.createHtml_text(this.inputiteminfo[11], 'qtyofLabel');
      this.createHtml_text(this.inputiteminfo[12], 'costofLabel');
      this.createHtml_text(this.inputiteminfo[13], 'leadTime');
      this.createHtml_select(this.inputiteminfo[14], 'dunnageAttach');
    }
  }

  createHtml_text(info: any, field: any) {
    if (info === '0') {
      const code1 = this.form.get(field);
      code1?.setValidators([Validators.nullValidator]);
      const obj = { [field]: null }
      this.form.patchValue(obj)
      this.form.get(field)?.updateValueAndValidity();
      code1?.disable();
    } else if (info === '1') {
      this.form.controls[field]?.enable();
      this.form.get(field)?.clearValidators();
      this.form.get(field)?.setValidators([this.validate, this.setvalidation1(field)]);
      this.form.get(field)?.updateValueAndValidity()
    } else if (info === '2') {
      this.form.controls[field]?.enable();
      this.form.get(field)?.setValidators([this.validate, Validators.required, this.setvalidation1(field)]);
      this.form.get(field)?.updateValueAndValidity()
    }
  }

  createHtml_select(info: any, field: any) {
    if (info === '0') {
      const code1 = this.form.get(field);
      code1?.setValidators([Validators.nullValidator]);
      const obj = { [field]: null }
      this.form.patchValue(obj)
      this.form.get(field)?.updateValueAndValidity();
      code1?.disable();
    } else if (info === '1') {
      this.form.controls[field]?.enable();
      this.form.get(field)?.clearValidators();
      this.form.get(field)?.updateValueAndValidity()
    } else if (info === '2') {
      this.form.controls[field]?.enable();
      this.form.get(field)?.setValidators([Validators.required]);
      this.form.get(field)?.updateValueAndValidity();
    }
  }

  createInputItems(pkgtype: any): any {
    const arr = [] as any
    this.types.forEach((element: any) => {
      if (element.packagetypecode === pkgtype) {
        for (let i = 0; i < element.inputiteminfo.length; i++) {
          arr.push(element.inputiteminfo.charAt(i))
        }
      }
    })
    return arr
  }

}