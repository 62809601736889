import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ConstantsService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  private readonly httpHeaderDetails: any;

  constructor(private readonly http: HttpClient, public constantsService: ConstantsService) {
    this.httpHeaderDetails = this.constantsService.httpCustomOptions
  }

  loadAuthority(supplierCode: any): Observable<any> {
    return this.http.post(this.constantsService.EXTERNAL + `authorityList`, supplierCode, { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  getChildRows(body: any): Observable<any> {
    return this.http.post(this.constantsService.EXTERNAL + `authorityList/sub`, body, { headers: this.constantsService.httpOptions })
      .pipe(catchError(this.handleError))
  }

  authorityUpdate(body: any): Observable<any> {
    return this.http.put<any>(this.constantsService.EXTERNAL + `authorityList`, body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  loadAuthorityDetail(toyotaGuid: string, supplierCode5: string): Observable<any> {
    const url = this.constantsService.EXTERNAL + `onDbutton?toyotaGuid=${toyotaGuid}&supplierCode5=${supplierCode5}`
    return this.http.get(url, this.httpHeaderDetails).pipe(catchError(this.handleError))
  }

  authorityDetailUpdate(body: any): Observable<any> {
    const url = this.constantsService.EXTERNAL + `onDbutton`
    return this.http.put<any>(url, body, { headers: this.constantsService.httpOptions }).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    return (error.message || "Server Error");
  }
}