import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'hz-cell-renderer',
  template: `
  <button (click)="navigate()" id="hyperLink" class="cmd_tracking_ia vpacs-btn-table" style="font-weight:500; width: 30px; height: 30px" *ngIf="!aFlag" [disabled]="!roleCheck">{{ text }}</button>
  <span *ngIf="aFlag" style="color:#000; cursor:auto">{{text}}</span>
  `,
})

export class HazmatCellRenderer implements ICellRendererAngularComp {
  specialistCode: any;
  aFlag = false;
  currStep: any;
  roleChecking: any;
  roleCheck = false;

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, public dialog: MatDialog, private readonly DatePipe: DatePipe) { }

  refresh(params: ICellRendererParams): boolean {
    throw new Error(`Error while refreshing renderer component. INFO-${params}`);
  }

  private params: any;
  text: any;
  step: any;
  step04Status = "0";
  step07Status = "0";
  step09Status = "0";

  agInit(params: any): void {
    this.specialistCode = JSON.parse(sessionStorage.getItem('specialistCode') || '')
    this.roleChecking = sessionStorage.getItem('roleChecking') || ''
    if (params.data?.specialistcode===this.specialistCode && this.roleChecking === 'tmna hazmat admin' ) {
      this.roleCheck = true;
    }
    else {
      this.roleCheck = false;
    }
    this.params = params;
    if (this.params.colDef.headerName === 'pre ppf') {
      this.step = 2;
      this.currStep = 4;
      this.createCellValue(params, params.rowIndex, this.step);
    }
    if (this.params.colDef.headerName === 'pkg trial') {
      this.step = 3;
      this.currStep = 7;
      this.createCellValue(params, params.rowIndex, this.step);
    }
    if (this.params.colDef.headerName === 'final ppf') {
      this.step = 4;
      this.currStep = 9;
      this.createCellValue(params, params.rowIndex, this.step);
    }
  }
  createCellValue(rowData: any, _rowIndex: any, status: any) {
    let hazmat04Status = "";
    let hazmat07Status = "";
    let hazmat09Status = "";

    let hazmat04ApproveDate = "";
    let hazmat07ApproveDate = "";
    let hazmat09ApproveDate = "";
    this.step04Status = rowData.data.step04status;
    this.step07Status = rowData.data.step07status;
    this.step09Status = rowData.data.step09status;
    if (status === 2) {
      hazmat04Status = rowData.data.hzapprovestatus;
      hazmat04ApproveDate = this.dateFormatter(rowData.data.hzapprovedate);
      this.step04Hazmat(hazmat04Status, hazmat04ApproveDate);
    }
    if (status === 3) {
      hazmat07Status = rowData.data.hzapprovestatus;
      hazmat07ApproveDate = this.dateFormatter(rowData.data.hzapprovedate);
      this.step07Hazmat(hazmat07Status, hazmat07ApproveDate);
    }
    if (status === 4) {
      hazmat09Status = rowData.data.hzapprovestatus;
      hazmat09ApproveDate = this.dateFormatter(rowData.data.hzapprovedate);
      this.step09Hazmat(hazmat09Status, hazmat09ApproveDate)
    }

  }
  step04Hazmat(step04HazmatStatus: any, step04HazmatApproveDate: any) {
    if (this.step04Status === "1" && step04HazmatStatus !== 'A') {
      this.text = "A";
      this.aFlag = false;
    }
    else if (this.step04Status === "1" && step04HazmatStatus === 'A' ) {
      this.text = step04HazmatApproveDate;
      this.aFlag = true;
    }
    else {
      this.text = "skip";
      this.aFlag = true;
    }
  }
  step07Hazmat(step07HazmatStatus: any, step07HazmatApproveDate: any) {
    if (this.step04Status === "1" && (this.step07Status !== "0" || this.step07Status === "0") && (this.step09Status === "0" || this.step09Status !== "0")) {
      this.text = "-";
      this.aFlag = true;
    }
    if (this.step07Status === "1" && step07HazmatStatus !== 'A') {
      this.text = "A";
      this.aFlag = false;
    }
    else if (this.step07Status === "1" && step07HazmatStatus === 'A' ) {
      this.text = step07HazmatApproveDate;
      this.aFlag = true;
    }
    else if ((this.step04Status === "0" || this.step04Status !== "0") && (this.step07Status === "0" || this.step07Status !== "0") && this.step09Status === "1") {
      this.text = "skip";
      this.aFlag = true;
    }
    else if ((this.step04Status !== "0" && this.step04Status !== "1") && (this.step07Status !== "0" && this.step07Status !== "1") && (this.step09Status !== "0" && this.step09Status !== "1")) {
      this.text = "skip";
      this.aFlag = true;
    }
    else if ((this.step04Status !== "0" && this.step04Status !== "1") && (this.step07Status === "0" && this.step09Status === "0")) {
      this.text = "skip";
      this.aFlag = true;
    }
  }

  step09Hazmat(step09HazmatStatus: any, step09HazmatApproveDate: any) {
    if ((this.step04Status === "0" || this.step04Status !== "0") && this.step07Status === "1" && (this.step09Status === "0" || this.step09Status !== "0")) {
      this.text = "-";
      this.aFlag = true;
    }
    if (this.step04Status === "1" && (this.step07Status === "0" || this.step07Status !== "0") && (this.step09Status === "0" || this.step09Status !== "0")) {
      this.text = "-";
      this.aFlag = true;
    }
    if (this.step09Status === "1" && step09HazmatStatus !== 'A' ) {
      this.text = "A";
      this.aFlag = false;
    }
    else if (this.step09Status === "1" && step09HazmatStatus === 'A' ) {
      this.text = step09HazmatApproveDate;
      this.aFlag = true;
    }
    else if ((this.step04Status !== "0" && this.step04Status !== "1") && (this.step07Status !== "0" && this.step07Status !== "1") && (this.step09Status !== "0" && this.step09Status !== "1")) {
      this.text = "skip";
      this.aFlag = true;
    }
    else if ((this.step04Status !== "0" && this.step04Status !== "1") && (this.step07Status === "0" && this.step09Status === "0")) {
      this.text = "skip";
      this.aFlag = true;
    }
  }

  navigate() {
    if (this.params.colDef.headerName === 'bm' ||
      this.params.colDef.headerName === 'pre ppf' ||
      this.params.colDef.headerName === 'pkg trial' ||
      this.params.colDef.headerName === 'final ppf'
    ) {

      const params = {
        leaderPlant: this.params.data?.leadername,
        plant: this.params.data?.plantcode,
        plantName: this.params.data?.plantname,
        dock: this.params.data?.dockcode,
        modelCode: this.params.data?.modelcode,
        supplier: this.params.data?.suppliercode,
        supplierName: this.params.data?.suppliername,
        specialist: this.params.data?.specialist,
        part: this.params.data?.partnumber,
        psd: this.params.data?.pkgstartdate,
        dateSent: this.params.data?.datesent,
        mode: 1,
        step: this.step,
        currentIntNo: this.params.data?.currentintno,
        currentIntVer: this.params.data?.currentintver,
        ppfcode: this.params.data?.ppfcode,
        ppfver: this.params.data?.ppfver

      }
      sessionStorage.setItem('currentHazmatStep', JSON.stringify(this.currStep));
      sessionStorage.setItem('hazmatApprovementList', JSON.stringify(params));
      this.router.navigateByUrl('/hazmat-approve-list/hazmat-approval');
    }
  }

  dateFormatter(params: any) {
    const date = this.DatePipe.transform(params, 'MM/dd/yyyy', 'UTC')
    return date ? date : '';
  }
}




