import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { Router } from '@angular/router';
import { DeliveryTargetComponent } from '../../pkg-authorization/do-pkg-authorization/delivery-target/delivery-target.component';
import { MatDialog } from '@angular/material/dialog';
import { DoPkgAuthorizationComponent } from '../../pkg-authorization/do-pkg-authorization/do-pkg-authorization.component';
import { Location } from '@angular/common';
import { ConstantsService } from 'src/app/services/constant.service';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'app-tracking-do-readiness',
  templateUrl: './tracking-do-readiness.component.html',
  styleUrls: ['./tracking-do-readiness.component.scss'],
  providers: [DoPkgAuthorizationComponent]
})
export class TrackingDoReadinessComponent implements OnInit {
  receiveFromToyota: any;
  orderInformation: any;
  reason: any;
  readinessDate: any;
  data: any;
  authNumber: any;
  dhFlag = false;
  btnDisabled = false;
  which: any = 0;
  cancelOKFlag = false;
  cancelNGFlag = false;
  okFlag = false;
  approvalFlag = false;
  rejectFlag = false;
  getStatus:any = ''
  serialFlag = false;
  authDownloadFlag = false;
  constructor(
    private readonly trackingService: TrackingService,
    public router: Router,
    public dialog: MatDialog,
    public dopkgauth: DoPkgAuthorizationComponent,
    public location:Location,
    private readonly constants: ConstantsService,
    protected globalState: GlobalStateService
  ) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('queryParamsReadiness') || '');
    if(this.data){   
      if([6,11,12].includes(this.data?.stepNumber)){
        this.authDownloadFlag = true;
      } 
    }
    this.readinessOnload();
  }

  cancelOk() {
    this.trackingService.cancelOk(this.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
      error: (_err)=>{
      }
    })
  }

  cancelNg() {
    this.trackingService.cancelNg(this.authNumber).subscribe({
      next: (_res)=>{
        this.location.back()
      },
      error: (_err)=>{
      }
    })
  }

    disableLogic(){      
    if(this.data && this.data.stepNumber) {
      if([3,8].includes(this.data.stepNumber)) {
        if(!([6,7,9,10,11].includes(this.getStatus))) {
          this.btnDisabled = true;
        }else{
          this.btnDisabled = false
        }
      } else if([6,11,12].includes(this.data.stepNumber)) {
        if(this.data.whichRequest !== parseInt(this.data.supOrFab)) {
          this.btnDisabled = true;
        }
        if((this.data.stepNumber === 6) && ([8,9,10,11].includes(this.getStatus))) {
          this.btnDisabled = false;
        }
      } else {
        if([2,6,7,9,10,11].includes(this.getStatus)) {
          this.btnDisabled = false
        } else {
          this.btnDisabled = true;
        }
      }
    }
  }

  checkButtons(){    
    if(!([9,10,11].includes(this.getStatus))){
      if(this.data.cancelFlag && this.data.cancelFlag === "2"){
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      }else{
        this.okFlag = true;     
      }  
    }else if([9,10,11].includes(this.getStatus)){
      this.approvalFlag = true;
      this.rejectFlag = true;
    }
  }

  readinessOnload() {
    this.trackingService.readinessOnload(this.data).subscribe({
      next: (res) => {
        if(res){
          if (parseInt(this.data?.supOrFab) === 0) {          
            if (this.data?.supplierCode === res[0]?.orderInformation?.requestTo) {              
                this.which = 0
            } else {              
                this.which = 1
            }
          } else if (res[0]?.orderInformation?.fabcode === res[0]?.orderInformation?.requestTo) {
              this.which = 1
          }
          //serial &         
          if((this.data?.stepNumber === 11) && (parseInt(this.data?.supOrFab) === this.which)){
            this.dhFlag = true;
          }
          //
          if(res[0].containerPackageType.length){
            if(res[0]?.containerPackageType[0].PACKAGETYPECODE === 'R' || res[0]?.containerPackageType[0].PACKAGETYPECODE === 'B'){
              this.serialFlag = true;
            }
          }
          this.getStatus = res[0]?.getStatus;
          this.authNumber = res[0]?.authNumber;
          this.receiveFromToyota = res[0]?.receiveFromToyota;
          const obj = res[0]?.orderInformation;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
             obj[key] = obj[key] === '*' ? '-' : obj[key]
            }
          }
          this.orderInformation = obj;
          this.reason = res[0]?.reason;
          this.readinessDate = res[0]?.readinessDate?.date;
        }
      },
      error: (_err)=>{
      },
      complete: ()=>{
        this.disableLogic();
        this.checkButtons();
      }
    });
  }


  setUpdatedTrackingGrid(body:any){
    const {
      hidPartNumber: partNumber,
    } = body;
    const ppfCode = this.data?.ppfNumber;
    const mergedData = Object.values(this.globalState.trackingRowData).reduce((acc:any,val:any) => acc.concat(val),[]);

    if (mergedData) {
      mergedData.forEach(
        (row: {
          submitFlag: boolean;
          gridDate: string;
          STEP12STATUS: number;
          ISUPDATEPPF: number | null;
          STEP12DATE: string;
          PARTNUMBER: any;
          PPFCODE: any;
        }) => {
          if (row.PARTNUMBER === partNumber && row.PPFCODE === ppfCode) {
            row.ISUPDATEPPF = 1;
            row.STEP12STATUS = 7;
            row.submitFlag = true;
          }
        }
      );
    }
  }

  submit() {
    if(!this.readinessDate) {
      alert('Forecast Date is the required filed') 
    }
    const req = {
      "hidPlantCode": this.data.plantCode,
      "hidPartNumber": this.data.partNumber,
      "hidSupplierCode": this.data.supplierCode,
      "hidPkgStartDate" :this.data.pkgStartDate,
      "hidModelCode": this.data.modelCode,
      "readinessDate": this.readinessDate
    }
    this.trackingService.readinessUpdate(req).subscribe({
      next: (_res) => {
        this.setUpdatedTrackingGrid(req)
        this.router.navigate(["/tracking"]);
      },
    });
  }

  onCancel() {
    this.router.navigate(["/tracking"]);
  }

  deliveryTarget(){
    const queryParams = {
      authNumber: this.authNumber
    }
    sessionStorage.setItem('queryParamsDelv', JSON.stringify(queryParams));
    this.dialog.open(DeliveryTargetComponent, { width: '110%', height: '90%' })
  }
  authDownload(){
    const queryParams = {
      authNumber: this.authNumber,
      comment1: this.receiveFromToyota ? (this.receiveFromToyota.authorizationMessage ? this.receiveFromToyota?.authorizationMessage : '') : ''
    }
    sessionStorage.setItem('queryParamsDo', JSON.stringify(queryParams));
    this.dopkgauth.authDownload()
  }

  ppf(){
    const query:any = {
      'plantCode': this.data?.plantCode, 
      'partNumber': this.data?.partNumber,
      'supplierCode' : this.data?.supplierCode,  
      'pkgStartDate': this.data?.pkgStartDate,  
      'ppfNumber' : this.data?.ppfNumber
    };
    const plantCode = this.constants.getTypeFromPlantCode(this.data?.plantCode)
    if (plantCode === 'SERVICE') {
      this.router.navigate([`/tracking/serviceparts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    } else if (plantCode === 'ASSY') {
      this.router.navigate([`/tracking/accessoryParts-pdf/${this.data?.ppfNumber}/${1}/${1}/${2}/${1}`])
    }
    else {
      sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
      this.router.navigate(['/tracking/ppf/', this.data?.supplierCode, 'readOnly'])
    }
}

}
