<div class="popup-container" *ngIf="dataStore.mode !== 'DELETE'">
    <form #myForm="ngForm" (ngSubmit)="saveData()">
        <header>
            <div class="d-flex justify-content-between align-items-center header">
                <div class="page-title">{{modalHeader}} </div>
              <div>
                <button class="help-btn" (click)="help()">Help</button>
              </div>
              </div>
        </header>
        <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
            <span *ngFor="let message of errorList">{{message}}</span>
        </div>
        <div class="popup-contents">
            <div class="row m-3 align-items-start">
                <div class="col-12 d-flex my-1">
                    <label class="catlabel col-5">Country <small class="text-danger">*</small></label>
                    <mat-select id="supplier" class="mat-sel pt-2" [class.error-outline]="isCountryError" name="countryid" disableOptionCentering
                        [(ngModel)]="country" [disabled]="isEditMode">
                        <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>                                   
                    </mat-select>                                          
                </div>
                <br />
                <div class="col-7 offset-5 d-flex my-1 text-danger" *ngIf="isCountryError">
                    Country is required
                </div>
                <br />
                <div class="col-12 d-flex my-1">
                    <label class="catlabel col-5">From Date <small class="text-danger">*</small></label>
                    <input type="date" [class.error-outline]="isFromDateError" id="fromDate" [(ngModel)]="fromDate"  (keydown)="onKeyDown($event,'fromDate')"  name="fromDate" [disabled]="isEditMode">                    
                </div>               
                <br />
                <div class="col-7 offset-5 d-flex my-1 text-danger" *ngIf="isFromDateError">
                    From Date is required
                </div>
                <br />
                <div class="col-12 d-flex my-1">
                    <label class="catlabel col-5">Exchange Rate <small class="text-danger">*</small></label>
                    <input type="text" [(ngModel)]="exchangeRate"  [class.error-outline]="isERError" name="exchangeRate" maxlength="10">                    
                </div>
                <br />
                <div class="col-7 offset-5 d-flex my-1 text-danger" *ngIf="isERError">
                    Exchange Rate is required
                </div>
            </div>

            <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
                <button type="button" class="cancel me-3 roboto-bold" (click)="close('CANCEL')"> CANCEL </button>
                <button type="submit" class="submit black-button me-3 roboto-bold">{{buttonValue}}</button>
            </div>
            <button type="button" class="close black-button" (click)="close('CANCEL')">
                <mat-icon>close</mat-icon>
            </button>    
        </div>
    </form>
</div>

<div class="popup-container delete-container" *ngIf="dataStore.mode === 'DELETE'">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete?</p>
    </div>
    <div mat-dialog-actions style="display:flex; justify-content:flex-end">
    <div mat-dialog-close class="text-primary mx-3 ">No</div>
    <button class="black-button mx-3" (click)="deleteData()">Yes</button>
    </div>
</div>






