import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';
import { ImportDataPopupComponentComponent } from '../import-data-popup-component/import-data-popup-component.component';

@Component({
  selector: 'app-import-package-data',
  templateUrl: './import-package-data.component.html',
  styleUrls: ['./import-package-data.component.scss'],
})
export class ImportPackageDataComponent {
  public image: any[] = [];
  fileName = '';
  errorMessage = '';
  base64File: string | ArrayBuffer | null = '';
  headerMessage = ''
  errorList = ''
  filemessage = ''
  
  constructor(
    public dialogRef: MatDialogRef<ImportPackageDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly spinner: NgxUiLoaderService,
    private readonly service: PackageMaintananceService
  ) {
    if (this.data.flag === 'data') {
      this.headerMessage = "Import Package"
      this.filemessage = 'can select only.xlsx file'
    } else {
      this.headerMessage = "Import Package Cost"
      this.filemessage = 'can select only.xlsx file'
    }
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if (!file) { return };
    this.spinner.start();
    const fileName: string = file.name;
    this.fileName = fileName;
    const reader = new FileReader();
    reader.readAsDataURL(file);
  
    reader.onload = () => {
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
  
      if (fileExtension && ['xlsx'].includes(fileExtension)) {
        this.errorMessage = '';
        this.base64File = reader.result;
      } else {
        this.errorMessage = `${fileName} is not of type xlsx`;
      }
  
      this.spinner.stop();
    };
  }
  
  

  errorHandler(_err: any) {
    this.data.component.onDelete('error while uploading file');

    this.spinner.stop();
  }

  errorArray = [];
  nextBtn() {
    this.spinner.start();
    const base64 = this.base64File as string;
    if (base64.length === 0) {
      this.spinner.stop()
    }else{
    const costbody = {
      PlantCode: this.data.plantCode,
      filename: this.fileName,
      file: {
        data: base64.split('base64')[1]?.substring(1),
      },
    };
    if (this.data.flag === 'data') {
      const body = {
        filename: 'Package',
        file: {
          data: base64.split('base64')[1]?.substring(1),
        },
      };

      if (base64.length !== 0) {
        this.callUploadPackageData(body)
      } else {
        this.errorList = 'File Type Error '
        setTimeout(() => {
          this.errorList = ''
        }, 4000)
        this.spinner.stop();
      }
    } else {
      if (base64.length !== 0) {
        this.callUploadPackageCostData(costbody)

      } else {

        this.errorList = 'File Type Error '
        setTimeout(() => {
          this.errorList = ''
        }, 4000)
        this.spinner.stop();

      }
    }
  }
  }

  callUploadPackageData(body: any) {
    this.service.uploadPackageData(body)?.subscribe({
      error: (error) => {
        this.data.component.onDelete('error while uploading file');
        this.errorHandler(error);
        return this.dialogRef.close({ success: false });
      },
      next: (res) => {
        const matdata = { ...body, ...res, component: this.data.component }
        if (res.status === 2) {
        const dialogData= this.dialog.open(ImportDataPopupComponentComponent, { data: matdata });
          dialogData.afterClosed().subscribe((result: any) => {
            if (result) {
              this.dialogRef.close({ success: true });
            }
          })
        }
        if(res.status === 1 && res.s3Upload ){
          this.data.component.Onsuccess('File imported Successfully');
          this.dialogRef.close({ success: true });

        }else if(res.status !== 2){
          this.data.component.onDelete('Unable to import file');
        }
        this.errorArray = res.errorMessage;
        this.spinner.stop();
      },
    });
  }

  callUploadPackageCostData(costbody: any) {
    this.service.uploadPackageCostData(costbody).subscribe({
      error: (error) => {
        this.errorHandler(error);
        return this.dialogRef.close({ success: false });
      },
      next: (res) => {
        if (res?.status === 1 || res?.status === 2) {
          this.data.component.Onsuccess('file uploaded successfully');
        } else {
          this.data.component.onDelete(res.errorMessage);
        }

        if (res.status) {
            this.dialogRef.close({ success: true });
        }
        this.spinner.stop();
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  help() {
    this.dialogRef.close();
    if (this.headerMessage === "Import Package"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 130])
    }else if (this.headerMessage === "Import Package Cost"){
      this.router.navigate(['./master-help', 5, "Master Maintenance", 139])
    }

  }
}
