<div class="popup-container">
    <h2 mat-dialog-title class="p-2 shadow-sm" style="font-size: 20px">REGIST Confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Select Required</p>
    </div>
    
    <div mat-dialog-actions class="d-flex flex-row-reverse ">
        <button mat-dialog-close class="black-button mx-2" style="cursor: pointer;"  (click)="closeDialog()">OK</button>
    </div>
    <br>
  </div>