<div class="message" [ngClass]="{'success': data.type === 'success', 'error': data.type === 'error'}">
    <span matSnackBarLabel>
        {{data.message}}
    </span>
    <span matSnackBarActions>
        <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
            <mat-icon >close</mat-icon>
        </button>
    </span>
</div>

