<div class="popup-container">
    <form #myForm="ngForm" (ngSubmit)="saveData()">
    <header>
        <div class="d-flex justify-content-between align-items-center header">
            <div class="page-title">{{modalHeader}} </div>
          <div>
            <button class="help-btn" type="button" (click)="help()">Help</button>
          </div>
          </div>
    </header>
    <div class="alert alert-danger mx-2" *ngIf="errorList.length > 0">
        <div *ngFor="let message of errorList" class="errormsg">
          <div class="message-container">
            <span class="message-text">{{message}}</span>
          </div>
        </div>
    </div>
    <div class="popup-contents" >
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label for="plantCode" class="catlabel col-5">Code<small class="text-danger">*</small></label>
                <input *ngIf="codeFlag" type="text" name="plantCode" [class.error-outline]="isPlantCodeError"  [(ngModel)]="plantCode" maxlength="8"
                (change)="checkValidations()">
                <input *ngIf="dCodeFlag" type="text" name="plantCode" [class.error-outline]="isPlantCodeError"  [(ngModel)]="plantCode" disabled>
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="plantName">Name<small class="text-danger">*</small></label>
                <input type="text" id="plantName" name="plantName" [class.error-outline]="isPlantNameError" maxlength="20"  [(ngModel)]="plantName" (change)="checkValidations()">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="plantId">Id<small class="text-danger">*</small></label>
                <input type="text" id="plantId" name="plantId" [class.error-outline]="isPlantIdError"  [(ngModel)]="plantId" maxlength="2" (change)="checkValidations()">
            </div> 
        </div>
        <div class="row m-3 align-items-start text-nowrap">
         <div class="col-4 d-flex">
            <small class="col-4 offset-5 d-flex text-danger" *ngIf="isPlantCodeError">
             Code is required.
            </small>
            </div>
            <div class="col-4 d-flex">
            <small class="col-4 offset-5 d-flex  text-danger" *ngIf="isPlantNameError">
             Name is required.
            </small>
        </div>
        <div class="col-4 d-flex">
            <small class="col-4 offset-5 d-flex text-danger" *ngIf="isPlantIdError">
             Id is required.
            </small>  
        </div>
        </div>  
        <div class="row m-3 align-items-start">
            <b>PPF Approval</b>
        </div>
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5" for="ty">Pre PPF</label>
                <mat-select id="ty" class="mat-sel pt-2" disableOptionCentering  [(ngModel)]="ty.Id"  [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let t of type" name="ty" [value]="t.Id">{{t.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5" for="ty1">Trial</label>
                <mat-select id="supplier" class="mat-sel pt-2" disableOptionCentering  [(ngModel)]="ty1.Id" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let t1 of type1" [value]="t1.Id">{{t1.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5" for="ty2">Final PPF</label>
                <mat-select id="supplier" class="mat-sel pt-2" disableOptionCentering  [(ngModel)]="ty2.Id" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let t2 of type2" [value]="t2.Id">{{t2.name}}</mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row m-3 align-items-start">
            <b>Pur Approval Cost<=</b>
        </div>   
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="purProtoAppcost">Proto</label>
                <input type="text" id="purProtoAppcost" name="purProtoAppcost"  [(ngModel)]="purProtoAppcost" [class.error-outline]="isProtoAppError" (change)="checkValidations()">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="purProdAppCost">Prod</label>
                <input type="text" id="purProdAppCost" name="purProdAppCost"  [(ngModel)]="purProdAppCost" [class.error-outline]="isProdAppError" (change)="checkValidations()">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="purMainteAppCost">Mainte</label>
                <input type="text" id="purMainteAppCost" name="purMainteAppCost"  [(ngModel)]="purMainteAppCost" [class.error-outline]="isMainteAppError" (change)="checkValidations()">
            </div>    
        </div>
        <div class="row m-3 align-items-start text-nowrap">
            <div class="col-4 d-flex">
               <small class="col-4 offset-5 d-flex text-danger" *ngIf="protoNumeric">
                [Proto] must be numeric.
               </small>
               </div>
               <div class="col-4 d-flex">
               <small class="col-4 offset-5 d-flex  text-danger" *ngIf="prodNumeric" >
                [Prod] must be numeric.
               </small>
           </div>
           <div class="col-4 d-flex">
               <small class="col-4 offset-5 d-flex text-danger" *ngIf="mainteNumeric">
                [Mainte] must be numeric.
               </small>  
           </div>
           </div>
        <div class="row m-3 align-items-start">
            <b>Required Approval Cost<=</b>
        </div> 
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="amCost">AM</label>
                <input type="text" id="amCost" name="amCost"  [(ngModel)]="amCost" [class.error-outline]="isAmCostError || isAmMgrCostError" maxlength="10" (change)="checkValidations()">
            </div>
            <div class="col-4 d-flex">
                <label class="catlabel col-5" for="mgrCost">MGR</label>
                <input type="text" id="mgrCost" name="mgrCost"  [(ngModel)]="mgrCost" [class.error-outline]="isMgrCostError" maxlength="10" (change)="checkValidations()">
            </div>  
            <div class="col-4 d-flex"></div>            
        </div>
        <div class="row m-3 align-items-start text-nowrap">
            <div class="col-4 d-flex">
               <small class="col-4 offset-5 d-flex text-danger" *ngIf="amNumeric">
                [AM Cost] must be numeric.
               </small>
               <small class="col-4 offset-5 d-flex text-danger" *ngIf="isAmMgrCostError">
                [AM Required Approval Cost] must be small [MGR Required Approval Cost].
               </small>
               </div>
               <div class="col-4 d-flex">
                    <small class="col-4 offset-5 d-flex  text-danger" *ngIf="mgNumeric">
                        [MGR Cost] must be numeric.
                    </small>
                </div>
                <div class="col-4 d-flex"></div>
            </div> 
        <div class="row m-3 align-items-start">
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5" for="sup">Supplier Code Type</label>
                <mat-select id="supplier" class="mat-sel pt-2" disableOptionCentering [(ngModel)]="sup" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let supp of Suppliers" [value]="isAdd ? supp : supp.Value">{{supp.DisplayName}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5" for="pkg">Package Code Type</label>
                <mat-select id="supplier" class="mat-sel pt-2" disableOptionCentering [(ngModel)]="pkg" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let pkgs of Packages" [value]="isAdd ? pkgs : pkgs.Value">{{pkgs.DisplayName}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4 d-flex my-1">
                <label class="catlabel col-5"     stylt="width: 90px" for="montry">Monetary Unit</label>
                <mat-select id="supplier" class="mat-sel pt-2" disableOptionCentering [(ngModel)]="montry" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let mntry of Monetarys" [value]="isAdd ? mntry : mntry.Value">{{mntry.DisplayName}}</mat-option>
                </mat-select>
            </div>
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button type="button" class="cancel me-3" (click)="close('CANCEL')"> CANCEL </button>
            <button type="submit" class="submit black-button me-3" >{{buttonValue}}</button>
        </div>
        <button type="button" class="close black-button" (click)="close('CANCEL')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</form>
</div>