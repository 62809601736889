import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { TargetInputCellRender } from '../../gridrender/target-input-cell-render';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-history-shipment',
  templateUrl: './history-shipment.component.html',
  styleUrls: ['./history-shipment.component.scss']
})
export class HistoryShipmentComponent implements OnInit {

  public rowData :any;
  //ag-grid-specs
  gridApi: any;
  columnDefs1 : any[] = [];
  columnDefs2 : any[] = [];
  displayRowData: any[] = [] ;
  displayRowData1: any[] = [] ;
  rowCount = 0;
  defaultColDef: {};
  gridOptions :any
  icons: {};
  isAccessible = false;
  public domLayout:any =''
  targetDate:any=""
  public overlayLoadingTemplate =
  `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate=
  `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  authNumber:any;
  dates: any[]=[];
  total: any=0;
  pageName: any;
  detailPage: any;
  constructor(@Inject(MAT_DIALOG_DATA) data:any, public route: ActivatedRoute,public dialog:MatDialog, public router: Router,private readonly newShipmentService : NewshipmentService) { 
    this.defaultColDef = {
      suppressMovable: true, 
      wrapText: true,
      suppressPaginationPanel:true,   
      autoHeight: true,  
      suppressHorizontalScroll: true, 
      sortable:true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.authNumber=data.AUTHNUMBER
  }

  public targetcellRendererParams={cellRendererFramework:TargetInputCellRender,
}

  ngOnInit()   {
    this.rebuild=this.rebuild.bind(this)
    
    //ag-grid-specs
    const vals=["authorized","shipped","received","remaining"]
    const columns=["heading","container","dunnage1","dunnage2","topCap","pallet","kanbanHolder","label","other"]
    if(this.pageName !== 'Detail'){

    
    this.newShipmentService.HistoryData(this.authNumber).subscribe(result=>{
      this.targetDate=`${result.deliveryDate.slice(5,7)}/${result.deliveryDate.slice(8,10)}/${result.deliveryDate.slice(0,4)}`
      this.displayRowData1=Object.keys(result).filter(f=>vals.includes(f)).map(v=>{
        const content=[v.toUpperCase(),...result[v]]
        return columns.reduce((acc:any,e:any,curIndex)=>{
          acc[e]=content[curIndex]
          return acc
        },{})
      })
      this.rowCount=this.displayRowData1.length
      this.generateTable2(result)
    })};
    this.pageName = this.route.snapshot.data['breadcrumb'];
    if(this.pageName === 'Detail'){
    this.newShipmentService.HistoryDataDetail(this.authNumber).subscribe(result=>{
      this.targetDate=`${result.deliveryDate.slice(5,7)}/${result.deliveryDate.slice(8,10)}/${result.deliveryDate.slice(0,4)}`
      this.displayRowData1=Object.keys(result).filter(f=>vals.includes(f)).map(v=>{
        const content=[v.toUpperCase(),...result[v]]
        return columns.reduce((acc:any,e:any,curIndex)=>{
          acc[e]=content[curIndex]
          return acc
        },{})
      })
      this.rowCount=this.displayRowData1.length
      this.generateTable2(result)
    })
  };


    this.columnDefs1 = [
      {
        headerName: "", field: "heading",minWidth: 70,
      },
      {
        headerName: "Container", field: "container",minWidth: 90
      },
      {
        headerName: "Dunnage1", field: "dunnage1",minWidth: 90, 
      },
      {
        headerName: "Dunnage2", field: "dunnage2",minWidth: 90,
      },
      {
        headerName: "Top Cap", field: "topCap", minWidth: 80
      },
      {
        headerName: "Pallet", field: "pallet", minWidth: 70, 
      },
      {
        headerName: "Kanban Holder", field: "kanbanHolder",minWidth: 90,
      },
      {
        headerName: "Label", field: "label",minWidth: 70, 
      },
      {
        headerName: "Other", field: "other",minWidth: 70,
      }    
    ];
    
   
  }
  generateTable2(result: any) {
    const date=new Date()
    date.setFullYear(result.deliveryDate.slice(0,4))
    date.setMonth(result.deliveryDate.slice(5,7))
    date.setDate(result.deliveryDate.slice(8,10))
    let newdate=new Date(date.getTime()-(84*24*60*60*1000))
    const day=newdate.getDay()
    for(let i=0;i<day;i++){
      newdate=new Date(newdate.getTime()-(24*60*60*1000))
    }
    newdate=new Date(newdate.getTime()+(24*60*60*1000))
    const dates=[]
    for(let i=0;i<16;i++){
      const m=`${newdate.getMonth()+1}/${newdate.getDate()}`
      dates.push(m)
      newdate=new Date(newdate.getTime()+(7*24*60*60*1000))
    }
    this.dates=dates
    const target=result.target 
    const paData=result.planAndActual.map((p:any,ind:any)=>{
      return {...p,...{"shipment_target":target[ind].target}}
    })
    const red=paData.reduce((acc:any,a:any)=>{
      const k=Object.keys(a)
      const temp={...acc}
      k.forEach(e=>{
        if(temp[e]){
          temp[e]=[...temp[e],a[e]]
        }
        else{
          temp[e]=[a[e]]
        }
      })
      return temp
    },{})
    const data=dates.map(d=>{
      return Object.keys(red).map(v=>{
          return {"heading":v,...red[v].reduce((acc:any,j:any)=>{
            return {...acc,[d]:j}
          },{})}
      })
    })
    const rows=data.reduce((acc:any,d:any)=>{
      const temp={...acc}
        d.forEach((k:any)=>{
          if(temp[k["heading"]]){
            temp[k["heading"]]={...temp[k["heading"]],...k}
          }
          else{
            temp[k["heading"]]={...k}
          }
        })
        return temp
    },{})
    const c=["shipment_target","shipment-actual","shipment-plan","receivement_actual","receivement-plan"]
    let final=c.map(k=>{
      if(k.includes("_")){
      return {...rows[k],"schedule":k.split("_")[0].toUpperCase(),"subschedule":k.split("_")[1].toUpperCase()}
      }
      else{
        const nv=k.replace("-","_")
        return {...rows[nv],"subschedule":nv.split("_")[1].toUpperCase()}
      }
    })
    final=final.map((f,_ind)=>{
      const total=this.dates.map(d=>f[d]).reduce((acc:any,c:any)=>{
        return acc+c
      },0)
      return {...f,"total":total}
    })
    this.displayRowData=final
    
    this.columnDefs2 = [
      {
        headerName: "Schedule", field: "schedule",width: 170,
        rowSpan:this.rowSpan, 
        cellClassRules: {
            'cell-span': "value==='SHIPMENT' || value==='RECEIVEMENT'",
          },
      },
      {
        headerName: "", field: "subschedule",width: 130,
        cellStyle:(_params:any)=>{return {"border":"none !important"}}
      },
     ...this.dates.map(d=>{
       return  {
        headerName: d, field: d,width: 70,...this.targetcellRendererParams,
      }  
     }),
     {
      headerName: "Total", field: "total",width: 100,
    },
    ];  
  }

  cellStyle(params:any) {
    if (params.value === "delay") {
      return { 'background-color': '#FF9966', 'color': '#FFFFFF', 'border-radius':'10px' };
    }
    else{
      return null;
    }
    }

    rowSpan(params:any) {
      const schedule = params.data?.schedule;
      let v=1;
      if (schedule === 'SHIPMENT') {
        v=3
      } else if (schedule === 'RECEIVED') {
        v=2
      }
      //console.log(v)
      return v;
    }
  
    onGridReady(params:any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    
    }
    rebuild(){
      this.displayRowData=this.displayRowData.map((f,ind)=>{
        let total=this.dates.map(d=>f[d]).reduce((acc:any,c:any)=>{
          return acc+c
        },0)
        if(ind===0){
          total=total+this.total
        }
        return {...f,"total":total}
      })
    }


}

export class ShowCellRenderer implements ICellRendererComp {
  ui: any;

  init(params: ICellRendererParams) {
    const cellBlank = !params.value;
    if (cellBlank) {
      return;
    }

    this.ui = document.createElement('div');
    this.ui.innerHTML =
      `<div class="show-name">${params.value} </div>`
  }

  getGui() {
    return this.ui;
  }

  refresh() {
    return false;
  }
}