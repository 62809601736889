import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RoleService } from 'src/app/internal-vpacs/services-internal/master-maintenance/role.service';

@Component({
  selector: 'app-add-role-master',
  templateUrl: './add-role-master.component.html',
  styleUrls: ['./add-role-master.component.scss'],
})
export class AddRoleMasterComponent implements OnInit {
  columnDefs: any[] = [];
  columnDefs2: any[] = [];
  defaultColDef: {};
  isNonTrade = false;
  isPlantList = false;
  checkAllNonTrades = false;
  checkAllisPlantList = false;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  plantList = [];
  restrictedChars = `["$%'\{}*_]`;
  specialistList: any[] = [];
  authorityRole: any[] = [];
  specialist = '';
  authority = '';
  plant = '';
  roleAuth: any;
  nontrade: any[] = [];
  nontradee: any[] = [];
  name = '';
  rolename: any = ''; 
  oldrolename: any;
  rolecode: any;
  Role: any[] = [];
  save: any;
  append: any;
  appendRole: any;
  addRole: any;
  copyRole: any[] = [];
  isRoleNameUpdateError = false;
  isRoleNameAddError = false;
  isRoleNameSpecialError = false;
  isTrackingError = false;
  isPlantError = false;
  errorList: any = [];
  heading: any;

  constructor(
    private readonly roleService: RoleService,
    private readonly spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<AddRoleMasterComponent>,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerName: string;
      rolecode: any;
      rolename: any;
      oldrolename: any;
      checkflag: any;
    }
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    };
  }
  ngOnInit(): void {
    this.heading=this.data.headerName
    this.apiCall();
    if (this.data.headerName === 'Update') {
      this.RoleAuthority();
    }
    this.columnDefs = [
      {
        headerName: 'SELECT ALL',
        field: 'select',
        width: 100,
        headerCheckboxSelection: true,
      },

      {
        headerName: 'FOR TRACKING',
        field: 'track',
        width: 100,
      },
      {
        headerName: '',
        field: 'track',
        width: 100,
      },
      {
        headerName: '',
        field: 'track',
        width: 100,
      },
      {
        headerName: '',
        field: 'track',
        width: 100,
       },
    ];
    
    this.columnDefs2 = [
      {
        headerName: 'SELECT ALL',
        field: 'select',
        width: 100,
        headerCheckboxSelection: true,
      },

      {
        headerName: 'FOR COST ESTIMATE',
        field: 'track',
        width: 100,
       },
      {
        headerName: '',
        field: 'track',
        width: 100,
        },
      {
        headerName: '',
        field: 'track',
        width: 100,
        },
      {
        headerName: '',
        field: 'track',
        width: 100,
        },
    ];
  }

  code(type: any) {
    this.selectAllAuthority(type);
  }
  addRoles() {
    this.appendRole = this.specialistList.filter(
      (x) => x.authoritytypecode === this.specialist
    );
    this.addRole = this.authorityRole.filter(
      (x) => x.authoritycode === this.save
    );
    this.append =`${this.appendRole[0]?.authoritytypename}.${this.addRole[0]?.authorityname}`

    const params = {
      authoritycode: this.addRole[0]?.authoritycode,
      authorityname: this.addRole[0]?.authorityname,
      authoritytype: this.appendRole[0]?.authoritytypename,
    };
    this.copyRole = this.Role.filter(
      (x) =>
        x.authorityname === this.addRole[0].authorityname &&
        x.authoritytype === this.appendRole[0].authoritytypename
    );
    if (this.copyRole.length === 0) {
      this.Role.push(params);
    }
  }

  deleteRoles() {
    this.Role.forEach((e: any, key: any) => {
      if (e.authoritycode === this.roleAuth) {
        this.Role.splice(key, 1);
      }
    });
  }
  roleChange(event: any){
    const roleName = event.target.value;
    if(event.target.value !== "" ||event.target.value !== undefined) {
      this.isRoleNameSpecialError = roleName && this.restrictedChars.split('').some((specialChar: string) =>  roleName.includes(specialChar));
    }
    else{
      this.isRoleNameSpecialError = false;
    }
    return roleName;
  }
 update() {
  const selectedTracking = this.getSelectedCodes(this.nontrade);
  const selectedPlant = this.getSelectedCodes(this.nontradee);
  const authorityCodes = this.Role.map(a => Number(a.authoritycode));

  this.errorList = [];
  this.isRoleNameAddError = false;
  this.isRoleNameUpdateError = false;
  this.isTrackingError = false;
  this.isPlantError = false;

  const roleNameErrorCondition = (this.data.headerName === 'Add') ? !this.rolename : !this.data.rolename;
  const trackingErrorCondition = selectedTracking.length === 0;
  const plantErrorCondition = selectedPlant.length === 0;

  if (roleNameErrorCondition) {
    this.isRoleNameAddError = this.data.headerName === 'Add';
    this.isRoleNameUpdateError = !this.isRoleNameAddError;
  }

  if (trackingErrorCondition) {
    this.isTrackingError = true;
  }

  if (plantErrorCondition) {
    this.isPlantError = true;
  }

  

  if (this.isRoleNameAddError || this.isRoleNameUpdateError || this.isTrackingError || this.isPlantError
    || this.isRoleNameSpecialError) {
    return;
  }
   this.spinnerService.start();
  const updateData = {
    rolename: this.data.headerName === 'Add' ? this.rolename : this.data.rolename,
    oldrolename: this.data.headerName === 'Add' ? '' : this.data.oldrolename,
    rolecode: this.data.headerName === 'Add' ? undefined : this.data.rolecode,
    authority: authorityCodes,
    tracking: selectedTracking,
    plant: selectedPlant,
  };

  const updateObservable = this.data.headerName === 'Add' ?
    this.roleService.addRole(updateData) :
    this.roleService.updateRole(updateData);

  updateObservable.subscribe({
    next: (res: any) => {
      this.spinnerService.stop();
      this.handleError(res);
      !this.errorList.length && this.dialogRef.close({ status: res.status, message: res.message });},
    error: (_err: any) => {
      this.spinnerService.stop();
    },
  });
}

handleError(data: any) {
 if (data?.status === 'Failure' || data?.status === 'FAILURE') {
    this.errorList.push(data?.message);
  } else {
    this.errorList = [];
  }
  setTimeout(() => {
    this.errorList = [];
  }, 5000);
}

private getSelectedCodes(items: any[]): any[] {
  return items.filter(a => a.selected).map(a => a.plantcode);
}

  close() {
    this.dialogRef.close();
  }

  closeErrorMsg() {
    this.errorList = [];
  }

  apiCall() {
    setTimeout(() => {this.spinnerService.start()});
    this.roleService.selectAuthorityType().subscribe({
      next: (res: any) => {
        this.specialistList = res.result;
        this.getTrackingCheckList();
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  RoleAuthority() {
    setTimeout(() => {this.spinnerService.start()});
    if (this.data.headerName === 'Update') {
      const body = {
        roleCode: this.data.rolecode,
      };
      this.roleService.selectRoleAuthority(body).subscribe({
        next: (res: any) => {
          this.Role = res.result;
        },
        error: (_err: any) => {
          this.spinnerService.stop();
        },
      });
    }
  }
  selectAllAuthority(type: any) {
    const body = {
      type: type,
    };
    this.roleService.selectAllAuthorityType(body).subscribe({
      next: (res: any) => {
        this.authorityRole = res.result;
      },
      error: (_err: any) => {
        this.spinnerService.stop();
      },
    });
  }

  getTrackingCheckList() {
    let body = {
      roleCode: this.data.rolecode || 18,
      checkflag: '0',
    };
    this.roleService.getTrackingCheckList(body).subscribe({
      next: (res: any) => {
        if (this.data.headerName === 'Update') {
          body = {
            roleCode: this.data.rolecode || 18,
            checkflag: '1',
          };
          this.roleService.getTrackingCheckList(body).subscribe({
            next: (selectedRes: any) => {
              res.result.forEach((element: any) => {
                element.selected = !!selectedRes.result.find(
                  (a: any) => a.plantcode === element.plantcode
                );
              });
              this.nontrade = res.result;
            },
            error: (_err: any) => {
              this.spinnerService.stop();
            },
          });
        } else {
          this.nontrade = res.result;
        }
        this.getUsablePlantList();
      },
    });
  }

  getUsablePlantList() {
    let body = {
      roleCode: this.data.rolecode || 18,
      checkflag: '0',
    };
    this.roleService.getUsablePlantList(body).subscribe({
      next: (res: any) => {
        this.spinnerService.stop();
        if (this.data.headerName === 'Update') {
          body = {
            roleCode: this.data.rolecode || 18,
            checkflag: '1',
          };
          this.roleService.getUsablePlantList(body).subscribe({
            next: (selectedRes: any) => {
              this.spinnerService.stop();
              res.result.forEach((element: any) => {
                element.selected = !!selectedRes.result.find(
                  (a: any) => a.plantcode === element.plantcode
                );
              });
              this.nontradee = res.result;
            },
            error: (_err: any) => {
              this.spinnerService.stop();
            },
          });
        } else {
          this.nontradee = res.result;
        }
      },
    });
  }

  changeTradesByCategory(event: any, ntrd: any) {
    if (event.target.name === 'nontrades') {
      this.isNonTrade = true;
    }
    if (this.isNonTrade && this.checkAllNonTrades) {
      event.target.checked = true;
    }
    ntrd.selected = event.target.checked;
  }

  allNonTrades(event: any) {
    const checked = event.target.checked;
    this.nontrade.forEach((item) => (item.selected = checked));
  }

  changeGetUsablePlantList(event: any, ntrd: any) {
    if (event.target.name === 'plantLists') {
      this.isPlantList = true;
    }
    if (this.isPlantList && this.checkAllisPlantList) {
      event.target.checked = true;
    }
    ntrd.selected = event.target.checked;
  }

  allPlantLists(event: any) {
    const checked = event.target.checked;
    this.nontradee.forEach((item) => (item.selected = checked));
  }

  delete() {
    const deleteObject = {
      roleCode: this.data.rolecode,
    };
    this.spinnerService.start();
    this.roleService.deleteRole(deleteObject).subscribe({
      next: (res) => {
        this.spinnerService.stop();
        this.dialogRef.close({ status: res.status, message: res.message });
      },
      error: (_err: any) => {
        this.errorList = [];
        this.spinnerService.stop();
      },
    });
  }
  
  help() {
    this.dialogRef.close();
    if (this.data.headerName === 'Update'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 136]);}
    else if (this.data.headerName === 'Add'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 135]);}
  }
}
