<div class="wrapper mx-3">  
    <div class="page-title my-2">MESSAGE</div>
    <div class="mat-typography content">
    <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel [expanded]="true" #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">RECEIVE FROM TOYOTA</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Package Due Date<span class="text-black">{{receiveFromToyota?.pkgDueDate}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Auth Date<span class="text-black">{{receiveFromToyota?.authDate}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Type<span class="text-black">{{receiveFromToyota?.status}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Status<span class="text-black">{{receiveFromToyota?.status}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Part Number<span class="text-black">{{receiveFromToyota?.msgFrom}}</span></div>
                <div class="d-grid col-lg-5 col-md-6 col-12 my-2"> 
                    <label class="col-6">Authorization Message</label>
                    <textarea disabled="true"rows="3" class="col-12">{{receiveFromToyota?.message}}</textarea>
                </div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel2 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">ORDER INFORMATION</mat-panel-title>
            <mat-icon class="toggleIcon">{{panel2.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Container Code<span class="text-black">{{orderInformation?.containercode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage1 Code<span class="text-black">{{orderInformation?.Dunnage1Code}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Dunnage2 Code<span class="text-black">{{orderInformation?.Dunnage2Code}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Top Cap Code<span class="text-black">{{orderInformation?.TopCapCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Container<span class="text-black">{{orderInformation?.QtyContainer}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage1<span class="text-black">{{orderInformation?.QtyDunnage}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Dunnnage2<span class="text-black">{{orderInformation?.QtyDunnage2}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Top Code<span class="text-black">{{orderInformation?.QtyTopCap}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Pallet Code<span class="text-black">{{orderInformation?.PalletCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Strech Code <span class="text-black">{{orderInformation?.StretchCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Kanban Holder Code<span class="text-black">{{orderInformation?.KanbanHolderCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Other Pkg Code<span class="text-black">{{orderInformation?.OtherPkgCode}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Container<span class="text-black">{{orderInformation?.QtyContainer}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Strech<span class="text-black">{{orderInformation?.QtyStretch}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Kanban Holder<span class="text-black">{{orderInformation?.QtyKanbanHolder}}</span></div>
                <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Qty Other Pkg<span class="text-black">{{orderInformation?.QtyOtherPkg}}</span></div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel3 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">REASON</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel3.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>  
            <div class="details-pane my-2 d-flex flex-wrap">
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">Reason<span class="text-black">{{reason?.reason}}</span></div>
                <div class="d-grid col-lg-3 col-md-6 col-12 my-2">
                    <label class="col-6">Reason Detail</label>
                    <textarea >{{reason?.reasonDetail}}</textarea>
                </div>
            </div>     
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" #panel4 class="mat-elevation-z0">
            <mat-expansion-panel-header class="header-container">
                <mat-panel-title class="fw-bold">REPLY TO TOYOTA</mat-panel-title>
                <mat-icon class="toggleIcon">{{panel4.expanded ? 'remove':'add'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="details-pane my-2 d-flex flex-wrap">
                <span>
                    <button class="vpacs-btn-table" style="margin-top: 12px;" (click)="authDownload()">AUTH
                        DOWNLOAD</button>
                </span>&nbsp;&nbsp;
                <span>
                    <button class="vpacs-btn-table" style="margin-top: 12px;" (click)="deliveryTarget()">DELIVERY
                        TARGET</button>
                </span>&nbsp;&nbsp;
               
            </div>
            <div class="d-grid col-lg-2 col-md-6 col-12 my-2">Forecast Ready Date
                <span>
                    <input type="date" [(ngModel)]="foreCastDate">
                </span>
            </div>
        </mat-expansion-panel>
    </mat-accordion>         
</div>
<div class="footer-dialog d-flex">
    <div class="mx-3 cancel" (click)="onCancel()">Cancel</div>
    <button class="black-button mx-3" (click)="submit()">Submit</button>
</div>
</div>