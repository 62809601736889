<div class="popup-container">
    <header>
        <button class="close black-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        
        <div class="header d-block roboto-medium">
            <label class="ms-2 mt-2 mb-3">Update Fabricator</label>
        </div>
    </header>

    <div class="popup-contents">
        <div class="alert alert-danger m-3 d-flex flex-wrap" *ngIf="errorArray.length > 0">
            <span *ngFor = "let i of errorArray" class="col-12">{{i}}</span>
        </div>
        <div class="row m-3 my-4 align-items-start">
            <div class="col-6 d-flex">
                <label class="catlabel col-3" for="input2">Search</label>
                <input type="text" [(ngModel)]="searchText" (keyup)="onSearch($event)">
            </div>
            
            <div class="col-6 d-flex">
                <label class="catlabel col-3" for="input2">Fabricator</label>
                <mat-select [disabled]="packageAuthSatus" id="fabricator" class="mat-sel pt-2 ms-3" disableOptionCentering [(ngModel)]="fabricator">
                    <mat-option *ngFor="let fabricator of fabricators" [value]="fabricator.pkgsupcode">{{fabricator.pkgsupcode}}</mat-option>
                </mat-select>
            </div>
            
        </div>
        <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
            <button [disabled]="packageAuthSatus" [ngClass]="{'pointer-cursor': packageAuthSatus}" class="cancel me-3 roboto-bold" (click)="delete()"> DELETE </button>
            <button [disabled]="packageAuthSatus" [ngClass]="{'pointer-cursor': packageAuthSatus}" class="submit black-button me-3 roboto-bold" (click)="updateBtn()">UPDATE</button>
        </div>
    </div>

</div>