import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PackageMaintananceService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-maintenance-service';
@Component({
  selector: 'app-import-data-popup-component',
  templateUrl: './import-data-popup-component.component.html',
  styleUrls: ['./import-data-popup-component.component.scss']
})
export class ImportDataPopupComponentComponent {
  errorArray: any[] = [];
  error = '';
  modelCodeValue: any = '';
  radioVal = 0
  Compare: any

  fieldArr = [
    "OuterLength",
    "OuterWidth",
    "OuterHeight",
    "InnerLength",
    "InnerWidth",
    "InnerHeight",
    "Weight",
    "Nesting",
    "RetHeight",
  ]
  trdata = [] as any
  trkaeys = [[{}], [{}]]

  constructor(
    private readonly service: PackageMaintananceService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImportDataPopupComponentComponent>,
    public loader: NgxUiLoaderService
  ) {
    this.Compare = data.Compare
    this.setTable()
  }
  setTable() {
    let i = 0
    this.data.Compare.forEach((element: any) => {
      const row = []
      if (element[1]) {
        const st = { rowName: this.fieldArr[0], currentData: this.data.Currentpkg[i][`${this.fieldArr[0].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[0]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[2]) {
        const st = { rowName: this.fieldArr[1], currentData: this.data.Currentpkg[i][`${this.fieldArr[1].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[1]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[3]) {
        const st = { rowName: this.fieldArr[2], currentData: this.data.Currentpkg[i][`${this.fieldArr[2].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[2]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[4]) {
        const st = { rowName: this.fieldArr[3], currentData: this.data.Currentpkg[i][`${this.fieldArr[3].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[3]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[5]) {
        const st = { rowName: this.fieldArr[4], currentData: this.data.Currentpkg[i][`${this.fieldArr[4].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[4]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[6]) {
        const st = { rowName: this.fieldArr[5], currentData: this.data.Currentpkg[i][`${this.fieldArr[5].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[5]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[7]) {
        const st = { rowName: this.fieldArr[6], currentData: this.data.Currentpkg[i][`${this.fieldArr[6].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[6]}`], pkgCode: element[0] }
        row.push(st);
      }

      if (element[8]) {
        const st = { rowName: this.fieldArr[7], currentData: this.data.Currentpkg[i][`${this.fieldArr[7].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[7]}`], pkgCode: element[0] }
        row.push(st)
      }

      if (element[9]) {
        const st = { rowName: this.fieldArr[8], currentData: this.data.Currentpkg[i][`${this.fieldArr[8].toLowerCase()}`], newData: this.data.NewData[i + 1][`${this.fieldArr[8]}`], pkgCode: element[0] }
        row.push(st)
      }
      i++
      this.trdata.push(row)
    });
    let j = 0
    this.trdata.forEach((element: any) => {
      this.trkaeys[j] = Object.keys(element)
      j++
    })
  }

  CallApi() {
    const payload = {
      filename: "Package",
      radRedo: this.radioVal,
      Currentpkg: this.data.Currentpkg,
      file: {
        data: this.data.file.data
      }
    }
    this.loader.start();
    this.service.nextApi(payload).subscribe({
      next: (res: any) => {
        if (res.imppackage.Status) {
          this.dialogRef.close('true')
        }
        this.loader.stop();
      },
      error: (_err: any) => {
        this.loader.stop();
        const erremsg = 'Unable to import file data'
        this.data.component.onDelete(erremsg)
      },
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
