import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewshipmentService } from 'src/app/services/newshipment.service';
import { DeleteBtnCellRender } from '../../gridrender/delete-btn-cell-render';
import { AddRackShipmentComponent } from '../add-rack-shipment/add-rack-shipment.component';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-rack-shipment',
  templateUrl: './rack-shipment.component.html',
  styleUrls: ['./rack-shipment.component.scss']
})
export class RackShipmentComponent implements OnInit {

  public rowData: any;

  //ag-grid-specs
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any
  icons: {};
  isAccessible = false;
  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  rackData: any;
  fieldData: any = {}
  shipNumber = "";
  rackShipmentUrl = '/shipment/rackShipmentDetail';
  constructor(
    public dialog: MatDialog,
    private readonly newShipmentService: NewshipmentService,
    public router: Router,
    protected readonly location: Location,
    public route: ActivatedRoute,

  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
    this.click = this.click.bind(this)
  }

  ngOnInit() {
    if (sessionStorage.hasOwnProperty("rackData")) {
      this.rackData = JSON.parse(sessionStorage.getItem("rackData") || '');
      this.shipNumber = sessionStorage.getItem("shipNumber") || ""
    }
    if (this.router.url.includes(this.rackShipmentUrl)) {
      this.newShipmentService.RButtonDataDetail(this.rackData.AUTHNUMBER, this.shipNumber).subscribe((result: any) => {
        this.fieldData=  Object.keys(result?.data1).length === 0 ? [] : result?.data1
        this.displayRowData=result?.data2 === -1 ? [] : result.data2
        this.rowCount = this.displayRowData.length
      })
    } else {
      this.newShipmentService.RButtonData(this.rackData.AUTHNUMBER, this.shipNumber).subscribe((result: any) => {
        this.fieldData=  Object.keys(result?.data1).length === 0 ? [] : result?.data1
        this.displayRowData=result?.data2 === -1 ? [] : result.data2
        this.rowCount = this.displayRowData?.length
      });
    }
    //ag-grid-specs
    this.columnDefs = [
      { headerName: "Serial Number", field: "serialnumber" },
      { headerName: "Auth Number", field: "tempauthnumber" },
      {
        headerName: "Delete", field: "delete", cellRendererFramework: DeleteBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.delete(field)
          }
        }
      }
    ];
  }

  delete(field: any) {
    const body = {
      "packageCode": field.packagecode,
      "serialNumber": field.serialnumber
    }
    if (this.router.url.includes(this.rackShipmentUrl)) {
      this.newShipmentService.RDeleteButtonDetail(body).subscribe(result => {
        if (result) {
          this.displayRowData = this.displayRowData.filter(r => r.serialnumber !== field.serialnumber)
          this.rowCount = this.displayRowData.length
        }
      });
    } else {
      this.newShipmentService.RDeleteButton(body).subscribe(result => {
        if (result) {
          this.displayRowData = this.displayRowData.filter(r => r.serialnumber !== field.serialnumber)
          this.rowCount = this.displayRowData.length
        }
      });
    }
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  cancel() {
    this.location.back()
  }


  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
  }

  click(data: any) {
    const body = data.map((d: any) => {
      return {
        "shipmentNumber": this.shipNumber,
        "authNumber": this.rackData.AUTHNUMBER,
        "packageCode": this.fieldData.packagecode,
        "serialNumber": d.SERIALNUMBER + ""
      }
    })

    if (this.router.url.includes(this.rackShipmentUrl)) {
      this.newShipmentService.RAddButtonAddDataDetail(body).subscribe(_result => {
        this.displayRowData = body
        this.rowCount = this.displayRowData.length;
        this.dialog.closeAll()
      })
    } else {
      this.newShipmentService.RAddButtonAddData(body).subscribe(_result => {
        this.displayRowData = body
        this.rowCount = this.displayRowData.length
        this.dialog.closeAll()
      });
    }
  }

  dialogOpen() {

    const dialogRef = this.dialog.open(AddRackShipmentComponent, { data: { clicked: this.click } })
    dialogRef.afterClosed()?.subscribe((_result: any) => {
      this.ngOnInit();
    })
  }

  save() {
    sessionStorage.setItem("rackcount", this.displayRowData.length.toString());
    if (this.router.url.includes(this.rackShipmentUrl)) {
      this.router.navigate(['/shipment/detail'])
    } else {
    this.router.navigate(['/shipment/newShipment'])
    }
  }

}
