<div class="popup-container">
    <div class="d-flex justify-content-between align-items-center m-2">
      <p class="page-title"> Select Package Cost</p> 
      <div>
      <button class="help-btn"(click)="help()">Help</button>
    </div>
    </div>
    <button class="close black-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  
    <div class="popup-contents m-2 py-2 px-2">
      <div>
        <table class="table" aria-describedby="plant">
          <tbody class="d-flex">
              <th class="col-md-5 titleColumn" id="plant">
                  <div class="ps-4">
                      Package Code
                  </div>
              </th>
              <div class="col-md-7 contentColumn rowClass2 d-flex flex-wrap align-content-center ">
                  <div class="ps-4 d-flex align-items-center">
                      {{packageCode}}
                  </div>
              </div>
          </tbody>
      </table>
      <table class="vpacs-shadow" style="width:100% ;  border: 1px solid #BCD1E0;" aria-labelledby="">
        <th class="d-none" id="">auth detail</th>
        <tr class="tHead">
          <th id="" class = "text-center"></th>
          <th id="" class = "text-center">Package Fabricator</th>
          <th id="" class = "text-center">Cost</th>
        </tr>
        
        <tr *ngFor="let detail of pkgDetails">
            <td class = "ps-4">
              <button 
                class="vpacs-btn-table"
                (click)="select(detail?.cost)">SELECT
              </button>
            </td>
            <td class = "text-center">{{detail?.pkgsupcode}}</td>
            <td class = "text-center">{{detail?.cost}}</td>
        </tr>
      </table>
      </div>
  
    </div>
    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
      <button class="cancel black-button me-3 roboto-bold" (click)="close()">Cancel</button>
    </div>
  </div>