import { Component, OnInit } from '@angular/core'
import { formatDate } from '@angular/common'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { QuantityControlService } from 'src/app/internal-vpacs/services-internal/quantity-control/quantity-control.service'
import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-maintenance-package',
  templateUrl: './maintenance-package.component.html',
  styleUrls: ['./maintenance-package.component.scss'],
})
export class MaintenancePackageComponent implements OnInit {
  errorText="Something went wrong, Please contact System Admininstrator"
  sample = "$%'\{}*_"
  packgeArray:any
  qtyArray:any
  costArray:any
  submitted = false
  routeParams: any
  haveAccess = false
  package1: any
  container1: any
  dunnage11: any
  dunnage21: any
  top1: any
  pallet1: any
  stretch1: any
  kanban1: any
  otherpkg1: any

  maintenanceForm = new FormGroup({
    partNumber: new FormControl(''),
    ringi: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required]),
    actualDate: new FormControl('', [Validators.required]),
    commentSupplier: new FormControl(''),
    internal: new FormControl(''),
    package2: new FormControl(),
    container2: new FormControl(),
    container3: new FormControl(),
    container4: new FormControl({ value: 0, disabled: true }),
    dunnage12: new FormControl(),
    dunnage13: new FormControl(),
    dunnage14: new FormControl({ value: 0, disabled: true }),
    dunnage22: new FormControl(),
    dunnage23: new FormControl(),
    dunnage24: new FormControl({ value: 0, disabled: true }),
    top2: new FormControl(),
    top3: new FormControl(),
    top4: new FormControl({ value: 0, disabled: true }),
    pallet2: new FormControl(),
    pallet3: new FormControl(),
    pallet4: new FormControl({ value: 0, disabled: true }),
    stretch2: new FormControl(),
    stretch3: new FormControl(),
    stretch4: new FormControl({ value: 0, disabled: true }),
    kanban2: new FormControl(),
    kanban3: new FormControl(),
    kanban4: new FormControl({ value: 0, disabled: true }),
    otherpkg2: new FormControl(),
    otherpkg3: new FormControl(),
    otherpkg4: new FormControl({ value: 0, disabled: true }),
    other4: new FormControl( 0),
    grandTotal: new FormControl({ value: 0, disabled: true }),
  })
  response: any
  userInfo: any
  reasonList: any[] = []
  ringiList: any[] = []
  partNumberDropDown: any[] = []
  res: any
  errorList: any

  constructor(
    private readonly state: ExportStateService,
    private readonly loader: NgxUiLoaderService,
    public service: QuantityControlService,
    private readonly route: ActivatedRoute,
    public router : Router
  ) {}

  ngOnInit(): void {
    this.loader.start()
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.haveAccess = res.role?.p_qtyview
          this.userInfo = res?.info
          this.haveAccess && this.getRouteValues()
        }
      },
      error: (_err: any) => {
        this.loader.stop();
        this.haveAccess = false
      },
    })
  }

  getRouteValues() {
    this.route.params.subscribe((params: any) => {
      this.routeParams = params
      if (this.routeParams) {
        this.apiCall()
      }
    })
  }

  ipChange() {
    this.checkInput()
    this.changeQty()
  }

  calcPackage() {
    const pkgQty = this.maintenanceForm.get('package2')?.value
    this.maintenanceForm.patchValue({
      container2: pkgQty,
      dunnage12: pkgQty,
      dunnage22: pkgQty,
      top2: pkgQty,
      pallet2: pkgQty,
      kanban2: pkgQty,
      stretch2: pkgQty,
      otherpkg2: pkgQty,
    })
    this.changeQty()
  }

  changeQty() {
    this.populateMessage()
    this.maintenanceForm.patchValue({
      container4: (Number(this.maintenanceForm.get('container2')?.value) || 0) * (Number(this.maintenanceForm.get('container3')?.value) || 0),
      dunnage14: (Number(this.maintenanceForm.get('dunnage12')?.value) || 0) * (Number(this.maintenanceForm.get('dunnage13')?.value) || 0),
      dunnage24: (Number(this.maintenanceForm.get('dunnage22')?.value) || 0) * (Number(this.maintenanceForm.get('dunnage23')?.value) || 0),
      top4: (Number(this.maintenanceForm.get('top2')?.value) || 0) * (Number(this.maintenanceForm.get('top3')?.value) || 0),
      pallet4: (Number(this.maintenanceForm.get('pallet2')?.value) || 0) * (Number(this.maintenanceForm.get('pallet3')?.value) || 0),
      stretch4: (Number(this.maintenanceForm.get('stretch2')?.value) || 0) * (Number(this.maintenanceForm.get('stretch3')?.value) || 0),
      kanban4: (Number(this.maintenanceForm.get('kanban2')?.value) || 0) * (Number(this.maintenanceForm.get('kanban3')?.value) || 0),
      otherpkg4: (Number(this.maintenanceForm.get('otherpkg2')?.value) || 0) * (Number(this.maintenanceForm.get('otherpkg3')?.value) || 0),
    })
    this.calcGrandTotal()
  }

  populateMessage(){
    if (this.ringiList.length === 0) {
      this.showMessage(['Not Found in Master You must make Maintenance Ringi.'])
    }
  }

  calcGrandTotal() {
    this.maintenanceForm.patchValue({
      grandTotal:
        Number(this.maintenanceForm.get('container4')?.value) +
        Number(this.maintenanceForm.get('dunnage14')?.value) +
        Number(this.maintenanceForm.get('dunnage24')?.value) +
        Number(this.maintenanceForm.get('top4')?.value) +
        Number(this.maintenanceForm.get('pallet4')?.value) +
        Number(this.maintenanceForm.get('stretch4')?.value) +
        Number(this.maintenanceForm.get('kanban4')?.value) +
        Number(this.maintenanceForm.get('otherpkg4')?.value),
    })
  }

  apiCall() {
    const body = {
      PlantCode: this.routeParams?.plant,
      SupplierCode: this.routeParams?.supplier,
      PackageCode: this.routeParams?.pkg,
      Part: this.routeParams?.part,
      Year: this.routeParams?.year,
      Week: this.routeParams?.week,
      Container: this.routeParams?.container,
      dunnage1: this.routeParams?.dunnage1,
      dunnage2: this.routeParams?.dunnage2,
      qty_type: this.routeParams?.qtytype,
      Auth: this.routeParams.auth === '-' ? ' ' : this.routeParams.auth
    }
    this.loader.start()
    this.service.maintenancePackageOnload(body).subscribe({
      next: (res: any) => {
        if (res && !res.errMsg) {
          this.reasonList = res.ReasonData ? res.ReasonData : []
          this.ringiList = res.RingiDropdown ? res.RingiDropdown : []
          this.partNumberDropDown = res.PartDropdownData
            ? res.PartDropdownData
            : [{partnumber : this.routeParams?.part}]
          this.response = res?.TableData
          this.setFormValues(this.response?.AuthData)
          this.changeQty()
        }else if(res.errMsg){
          this.errorList = res.errMsg;
        }
        this.loader.stop()
      },
      error: (_err: any) => {
        this.errorList = this.errorText;
        this.loader.stop()
      },
    })
  }

  setFormValues(data: any) {
    this.package1 = data[0]?.packagecode
    this.container1 = data[1]?.packagecode
    this.dunnage11 = data[2]?.packagecode
    this.dunnage21 = data[3]?.packagecode
    this.top1 = data[4]?.packagecode
    this.pallet1 = data[5]?.packagecode
    this.stretch1 = data[6]?.packagecode
    this.kanban1 = data[7]?.packagecode
    this.otherpkg1 = data[8]?.packagecode
    this.setProperValue(data)
    this.checkInput()
  }

  setProperValue(data:any){
this.maintenanceForm.patchValue({
      partNumber: this.response?.selectedPartNumber ?? this.routeParams?.part,
      ringi: this.ringiList ? this.ringiList[0]?.ringinumber : [],
      reason: this.reasonList ? this.reasonList[0]?.id : [],
      actualDate: this.response?.ActualDate ? formatDate(this.response?.ActualDate, 'yyyy-MM-dd', 'en') : '',
      package2: data[0] && parseFloat(data[0].qty),
      container2: data[1] && parseFloat(data[1].qty),
      dunnage12: data[2] && parseFloat(data[2].qty),
      dunnage22: data[3] && parseFloat(data[3].qty),
      top2: data[4] && parseFloat(data[4].qty),
      pallet2: data[5] && parseFloat(data[5].qty),
      stretch2: data[6] && parseFloat(data[6].qty),
    })
    this.setProperValue2(data)
  }

  setProperValue2(data: any) {
    this.maintenanceForm.patchValue({
      kanban2: data[7] && parseFloat(data[7].qty),
      otherpkg2: data[8] && parseFloat(data[8].qty),
      container3: data[1] && parseFloat(data[1].unitcost),
      dunnage13: data[2] && parseFloat(data[2].unitcost),
      dunnage23: data[3] && parseFloat(data[3].unitcost),
      top3: data[4] && parseFloat(data[4].unitcost),
      pallet3: data[5] && parseFloat(data[5].unitcost),
      stretch3: data[6] && parseFloat(data[6].unitcost),
      kanban3: data[7] && parseFloat(data[7].unitcost),
      otherpkg3: data[8] && parseFloat(data[8].unitcost),
    })
    this.maintenanceForm.updateValueAndValidity()
  }

  showMessage(message: string[]) {
    this.errorList = message
    setTimeout(() => {
      this.errorList = []
    }, 8000);
  }

  get isValid() {
    return this.maintenanceForm.controls
  }

  
  payloadStage1() {
    return {
      PlantCode: this.routeParams?.plant ?? '',
      SupplierCode: this.routeParams.supplier ?? '',
      PackageCode: this.routeParams.pkg ?? '',
      Container: this.routeParams.container ?? '',
      Part: this.routeParams.part ?? '',
      Year: this.routeParams.year ?? '',
      Week: this.routeParams.week ?? '',
      Dunnage1Code: this.routeParams.dunnage1 ?? '',
      Dunnage2Code: this.routeParams.dunnage2 ?? '',
      qty_type: 2,
      Mode: '1',
    }
  }

  payloadStage2(){
    return {
      UserSpecialistCode: this.userInfo.specialistcode  ?? '',
      UserPlantCode: this.userInfo.plantcode  ?? '',
      hidPlantCode: this.response?.PlantName[0]?.name  ?? '',
      taComment1: this.maintenanceForm.get('commentSupplier')?.value  ?? '',
      taComment2: this.maintenanceForm.get('internal')?.value  ?? '',
      txtCost: isNaN(Number(this.maintenanceForm.get('grandTotal')?.value)) ? 0 : Number(this.maintenanceForm.get('grandTotal')?.value),
      hidRingi: this.maintenanceForm.get('ringi')?.value  ?? '',
      hidSupplierCode: this.routeParams.supplier  ?? '',
      hidContainerCode: this.response.AuthData > 0 ? this.response?.AuthData[1]?.packagecode : '',
      hidDunnage1Code: this.response.AuthData > 0 ? this.response?.AuthData[2]?.packagecode : '',
      hidDunnage2Code: this.response.AuthData > 0 ? this.response?.AuthData[3]?.packagecode : '',
      hidTopCapCode: this.response.AuthData > 0 ? this.response?.AuthData[4]?.packagecode : '',
      hidPalletCode: this.response.AuthData > 0 ? this.response?.AuthData[5]?.packagecode : '',
    }
  }
  payloadStage3(){
    return {
      hidStretchWrapStrapCode: this.response.AuthData > 0 ? this.response?.AuthData[6]?.packagecode : '',
      hidKanbanCardHolderCode: this.response.AuthData > 0 ? this.response?.AuthData[7]?.packagecode : '',
      hidPartNumber: this.response.createData ? this.response?.createData[0]?.partnumber : '',
      hidOtherCode: this.response.AuthData > 0 ? this.response?.AuthData[8]?.packagecode : '',
      txtQty: this.maintenanceForm.get('container2')?.value  ?? '',
      txtDun1Qty: this.maintenanceForm.get('dunnage12')?.value  ?? '' ,
      txtDun2Qty: this.maintenanceForm.get('dunnage22')?.value  ?? '',
      txtPalQty: this.maintenanceForm.get('pallet2')?.value  ?? '',
      txtStrQty: this.maintenanceForm.get('stretch2')?.value  ?? '',
      txtTopQty: this.maintenanceForm.get('top2')?.value  ?? '',
      txtKanQty: this.maintenanceForm.get('kanban2')?.value  ?? '',
      txtContCPP: this.maintenanceForm.get('container3')?.value ?? '',
      txtDun1CPP: this.maintenanceForm.get('dunnage13')?.value ?? '',
    }
  }

  generateAuthorizePayload(){
    const stage1=this.payloadStage1()
    const stage2=this.payloadStage2()
    const stage3=this.payloadStage3()
    return {
      ...stage1,
      ...stage2,
      ...stage3,
      txtDun2CPP: this.maintenanceForm.get('dunnage23')?.value ?? '',
      txtTopCPP: this.maintenanceForm.get('top3')?.value ?? '',
      txtPalCPP: this.maintenanceForm.get('pallet3')?.value ?? '',
      txtStrCPP: this.maintenanceForm.get('stretch3')?.value ?? '',
      txtKanCPP: this.maintenanceForm.get('kanban3')?.value ?? '',
      txtOthCPP: this.maintenanceForm.get('otherpkg3')?.value ?? '',
      txtOtherTotal: isNaN(Number(this.maintenanceForm.get('otherpkg4')?.value)) ? 0 : Number(this.maintenanceForm.get('otherpkg4')?.value),
      txtActualDate: this.maintenanceForm.get('actualDate')?.value ?? '',
      txtOthQty: this.maintenanceForm.get('other2')?.value  ?? '',
      selReason: this.maintenanceForm.get('reason')?.value ?? '',
      txtReason: this.maintenanceForm.get('reason')?.value ?? '',
    }
  }


  onSubmit() {
    this.submitted = true
    this.checkInput()
    const body=this.generateAuthorizePayload()
      let flag=true
      for (const index of this.sample) {
        if (this.maintenanceForm.controls['commentSupplier']?.value?.includes(index)) {
          flag =false
          break;
        }
      }
      for (const index of this.sample ) {
        if (this.maintenanceForm.controls['internal']?.value?.includes(index)) {
          flag =false
          break;
        }
      }

    if(this.maintenanceForm.valid && flag){
      this.actualAuthorizeCall(body)
    } else {
      this.popupError()
    }
  }

  actualAuthorizeCall(body:any){
    this.loader.start()
    this.service.maintenanceAuthorize(body).subscribe({
      next: (_res: any) => {
        if(_res){
          if(_res.status === 0){
            this.showMessage([_res.message])
          }else{
            this.onBack();
          }
        }else{
          this.errorList = this.errorText;
        }
        this.loader.stop()
      },
      error: (_err: any) => {
        this.errorList = this.errorText;
        this.loader.stop()
      },
    })
  }

  popupError(){
    const arr = []
      for (const index of this.sample ) {
        if (this.maintenanceForm.controls['commentSupplier']?.value?.includes(index)) {
          arr.push(`Data Format Error [Comment for Supplier] has prohibited character.["$%'\{}*_]`)
          break;
        }
      }
      for (const index of this.sample) {
        if (this.maintenanceForm.controls['internal']?.value?.includes(index)) {
          arr.push(`Data Format Error [Internal use only] has prohibited character.["$%'\{}*_]`)
          break;
        }
      }
      if (this.maintenanceForm.controls['ringi']?.invalid) {
        arr.push('Required Item Error [Ringi #] is not selected')
      }
      if (this.maintenanceForm.controls['reason']?.invalid) {
        arr.push('Required Item Error [Reason] is not selected.')
      }
      if (this.maintenanceForm.controls['actualDate']?.invalid) {
        arr.push('Required Item Error [actualDate] is not selected.')
      }
      this.packgeArray.map((data: any) => {
        if (this.maintenanceForm.get(data.setA)?.invalid) {
          arr.push('Required Item Error [Qty]')
        }
        if (this.maintenanceForm.get(data.setB)?.invalid) {
          arr.push('Required Item Error [Unit Cost]')
        }
      })
      this.showMessage(arr)
  }


  checkInput() {
    this.packgeArray = [
      { setA: 'container2', setB: 'container3', value: this.container1 },
      { setA: 'dunnage12', setB: 'dunnage13', value: this.dunnage11 },
      { setA: 'dunnage22', setB: 'dunnage23', value: this.dunnage21 },
      { setA: 'top2', setB: 'top3', value: this.top1 },
      { setA: 'pallet2', setB: 'pallet3', value: this.pallet1 },
      { setA: 'stretch2', setB: 'stretch3', value: this.stretch1 },
      { setA: 'kanban2', setB: 'kanban3', value: this.kanban1 },
      { setA: 'otherpkg2', setB: 'otherpkg3', value: this.otherpkg1, }
    ]

    this.qtyArray = [
      this.maintenanceForm.controls['container2'].value,
      this.maintenanceForm.controls['dunnage12'].value,
      this.maintenanceForm.controls['dunnage22'].value,
      this.maintenanceForm.controls['top2'].value,
      this.maintenanceForm.controls['pallet2'].value,
      this.maintenanceForm.controls['stretch2'].value,
      this.maintenanceForm.controls['kanban2'].value,
      this.maintenanceForm.controls['otherpkg2'].value,
    ]

    this.costArray = [
      this.maintenanceForm.controls['container3'].value,
      this.maintenanceForm.controls['dunnage13'].value,
      this.maintenanceForm.controls['dunnage23'].value,
      this.maintenanceForm.controls['top3'].value,
      this.maintenanceForm.controls['pallet3'].value,
      this.maintenanceForm.controls['stretch3'].value,
      this.maintenanceForm.controls['kanban3'].value,
      this.maintenanceForm.controls['otherpkg3'].value,
    ]

    this.packgeArray.map((data: any) => {
      if (data.value && data.value.length > 0) {
        this.checkRequired(data.setA, data.setB)
      }
    })
  }


  checkRequired(qty: any, unit: any) {
    this.maintenanceForm.get(qty)?.setValidators([Validators.required, Validators.max(99999), this.validate]);
    this.maintenanceForm.get(qty)?.updateValueAndValidity();

    this.maintenanceForm.get(unit)?.setValidators([Validators.required, Validators.max(999999), this.validate]);
    this.maintenanceForm.get(unit)?.updateValueAndValidity();
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === null) {
      return null;
    }
    if (isNaN(control.value)) {
      return { 'pattern': true };
    }
    return null
  }

  onBack(){
    let uri = ''
    if (this.router.url.includes('quantityConrtol')) {
      uri = '/quantityConrtol'
    } 
    else if (this.router.url.includes('tracking-list')) {
      uri = '/tracking-list'
    }
    else if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    }
    this.router.navigateByUrl(uri);
  }
  help(){
    this.router.navigate(['./master-help',4,"Quantity Control",75])
    }
}
