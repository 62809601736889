export function convertAndCheck(length: any, width: any, height: any) {
    const cubicMeters = convertCubicMillimeterToCubicMeter(length, width, height);
    return cubicMeters === 0 ? "" : cubicMeters?.toFixed(5); // If volume is 0, return empty string, otherwise return volume with 6 decimal places
}
export function convertCubicMillimeterToCubicMeter(ln: any, wd: any, ht: any) {
    const cubicMillimeters = ln * wd * ht; // Calculate volume in cubic millimeters
    return cubicMillimeters / 1000000000; // Convert cubic millimeters to cubic meters and Return volume in cubic meters
}
export function toNumber(s:any) {
    if(isNaN(s)) {
        return '';
    } else {
        return s ;
    }
}
export function calculateValue(qty: any, unitCost: any, laborRate: any, laborTime: any) {
    if (qty === "" || isNaN(qty)) {
        return ""; // If Q54 is empty or not a number, return empty string
    } else {
        const result = ((qty * unitCost) + ((laborRate / 3600) * (laborTime * qty)));
        return isNaN(result) ? "" : result?.toFixed(4); // If result is NaN, return empty string, otherwise return the result
    }
}
export function validate(s:any){
    if(s!==null && s!==undefined && !Number.isNaN(s) && s!=="null" &&  s!=="undefined"){
        return s;
    }else{
        return '';
    }
}
export function getCI(a1: any, b1: any, c1: any, unit: any, inter: any, master: any, pallet: any) {
    const a = Number(a1);
    if (validate(a) !== '') {
        let s1 = 0;
        let s2 = 0;
        let res = 0;
        const b = Number(b1);
        const c = Number(c1);
        if (validate(b) && b !== 0) {
            if (validate(unit) !== '') {
                s1 = parseFloat(((a * Number(unit)) / b).toFixed(4));
            }
        }
        if (validate(inter) !== '') {
            s2 = parseFloat((c * Number(inter)).toFixed(4));
        }
        if (validate(master) !== '' && validate(pallet) !== '') {
            res = (s1 + s2 + Number(master) + Number(pallet));
        }
        return res.toFixed(4);
    } else {
        return '0';
    }
}

export function sumProduct(array1: any, array2: any) {
    let sum = 0;
    for (let i = 0; i < array1.length; i++) {
        sum += array1[i] * array2[i];
    }
    return sum;
}