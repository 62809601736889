import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackingListService, dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { SelectLeaderRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/select-leader-renderer';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-select-leader',
  templateUrl: './select-leader.component.html',
  styleUrls: ['./select-leader.component.scss']
})
export class SelectLeaderComponent implements OnInit {
  rowClassRules: any;
  rowHeight = 40;
  headerHeight = 40;
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: any;
  gridOptions: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  response: any;
  model: any = ""
  plant: any = "";
  supplier: any = "";
  part: any = "";
  pgkdate: any = "";
  currentLeader: any = ""
  currLeaderVisible = false;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<SelectLeaderComponent>, 
    public trackingListService: TrackingListService,
    private readonly spinner: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly router: Router
    ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      comparator:dateComparator
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.start();
    });
    this.columnDefs = [
      {
        headerName: "SELECT",
        field: 'select',
        // headerCheckboxSelection: true,
        // checkboxSelection: true,
        // showDisabledCheckboxes: true, 
        minWidth: 90, maxWidth: 100,
        cellRendererFramework: SelectLeaderRenderer,
        cellRendererParams: {
          clicked: (_colId?: any, params?: any) => {
            this.selectLeader(params);
          }
        }
      },
      {
        headerName: "PLANT", field: "plant", minWidth: 100, maxWidth: 100
      },
      {
        headerName: "MODEL", field: "modelcode", minWidth: 100, maxWidth: 100
      },

      {
        headerName: "SUPPLIER", field: "sname", minWidth: 100, maxWidth: 100
      },

      {
        headerName: "PART#", field: "partnumber", minWidth: 140, maxWidth: 140,
      },
      {
        headerName: "Pkg Start Date", field: "to_char", minWidth: 100, maxWidth: 100,
      }
    ]
    this.onLead();
  }

  close(): void {
    this.dialogRef.close({ event: 'close' });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  selectLeader(params: any) {
    this.spinner.start();
    const selectObj = {
      url_model: this.dataStore?.model, //,  PARENT MODEL CODE
      url_plant: this.dataStore.plantC, //PARENT PLANT CODE
      url_part: params?.partnumber,
      url_supplier: this.dataStore?.supplierC,
      url_psd: this.dataStore.psd,  //PARENT PSD
      url_lead: params?.plantcode, //CORRESPONDING PLANT CODE
      lsd: params?.to_char
    };
    this.trackingListService.leadFollowerSelect(selectObj).subscribe({
      next: (res: any) => {
        if(res === true){
          this.dialogRef.close({ event: 'CloseEvent' });
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    });
  }
  
  onLead() {
    const obj = {
      url_model: this.dataStore.model,
      url_plant: this.dataStore.plantC,
      url_supplier: this.dataStore.supplierC,
      url_part: this.dataStore?.part,
      url_psd: this.dataStore.psd
    }
    
    this.trackingListService.leadOnLoad(obj).subscribe({
      next: (res: any) => {
        this.response = res.createSelecteLeader
        this.model = this.dataStore.model;
        this.plant = this.dataStore.plant;
        this.supplier = this.dataStore.supplier;
        this.part = this.dataStore.part;
        this.pgkdate = this.dataStore.psd;
        this.currentLeader = res.getLeaderPlantName
        this.displayRowData = this.response;
        if(this.currentLeader === null || this.currentLeader === ' ') {
          this.currLeaderVisible = false;
        }
        else{
          this.currLeaderVisible = true;
        }
        this.spinner.stop();

      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    });
  }

  release() {
    this.spinner.start();
    const payload = {
      url_plant: this.dataStore.plantC,
      url_supplier: this.dataStore.supplierC,
      url_part:this.dataStore.part,
      url_psd: this.dataStore.psd
    }
    this.trackingListService.releaseLeader(payload).subscribe({
      next: (res: any) => {
        if(res === true){
          this.dialogRef.close({ event: 'CloseEvent' });
        }
        this.spinner.stop();
      }, error: (_error: any) => {
        this.spinner.stop();
      }
    })
  }
}
