<div class="d-flex justify-content-between align-items-center m-3">
  <div class="page-title">Discrepancy List</div>
  <div>
    <button class="help-btn" (click)="help()">Help</button>
  </div>
</div>
<div class="alert alert-danger mx-2" *ngIf="message?.length > 0">
  <span class="message-text">{{ message }} </span>
</div>
<div class="alert alert-warning mx-2" *ngIf="haveAccess === false">
  Authority Error - Access denied
</div>
<div class="vpacs-shadow m-2 py-2 px-2" *ngIf="haveAccess">
  <div class="filter mx-1 d-flex" (keyup.enter)="onSearch()">
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
      <label for="plant" class="col-4">Plant</label>
      <input type="text" id="plant" class="pt-1" aria-label="text" matInput [(ngModel)]="plantDropdown"
        (input)="filterOptions()" placeholder="--Select Plant--" [matAutocomplete]="auto"
        style="width: 80%">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let plant of filteredOptions" [value]="plant.plantcode">{{plant.plant}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
      <label for="supplierCode" class="col-4">Supplier Code</label>
      <input type="text" id="supplierCode" maxlength="8" [(ngModel)]="supplierCode" style="width: 100%;" />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
      <label for="family-code" class="col-4">Part Number</label>
      <input type="text" id="family-code" [(ngModel)]="partNumber" maxlength="10" style="width: 100%;" />
    </div>
    <div class="d-flex  align-items-center col-lg-2 col-md-6 col-12  mx-2">
      <label for="family-code" class="col-4">Discrepancy Type</label>
      <mat-select id="supplier" class="mat-sel pt-1" [(ngModel)]="discDropdown" (valueChange)="changefunc($event)">
        <mat-option *ngFor="let dl of discrepancyList" [value]="dl.code">{{dl.name}}</mat-option>
      </mat-select>
    </div>
    <div class="d-flex align-items-center col-lg-1 col-md-6 col-12 mx-2">
      <button class="black-button" (click)="onSearch()">Search</button>
    </div>
    <div class="d-flex align-items-center col-lg-1 col-md-6 col-12">
      <input type="button" class="black-button" value="Export Discrepancy" (click)="download()" />
    </div>
  </div>
</div>

<div class="vpacs-shadow m-2 p-2" *ngIf="haveAccess">
  <div class="grid-agg grid-height-discrepancy-list mb-5">
    <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham" (gridReady)="onGridReady($event)"
      (window:resize)="sizeToFit()" [animateRows]="true" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
      [rowData]="displayRowData" [suppressCellSelection]="true" [pagination]="true" [context]="context"
      [paginationPageSize]="rpPage" [suppressPaginationPanel]="true" [icons]="icons" [headerHeight]="40"
      [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [floatingFiltersHeight]="40" [domLayout]="domLayout" (filterChanged)="filterChanged($event)">
    </ag-grid-angular>
    <app-pagination [cPage]="cPage" [tPage]="tPage" [rpPage]="rpPage" [totalRecords]="totalRecords"
      (changePaginationValues)="changePaginationValues($event)" *ngIf="totalRecords > 0">
    </app-pagination>
  </div>
</div>


<router-outlet></router-outlet>