import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PackageTypeService } from 'src/app/internal-vpacs/services-internal/master-maintenance/package-type.service';

@Component({
  selector: 'app-add-pkg-type',
  templateUrl: './add-pkg-type.component.html',
  styleUrls: ['./add-pkg-type.component.scss']
})
export class AddPkgTypeComponent implements OnInit {

  errorMessage = '';
  public addPkgForm : FormGroup = new FormGroup({});

  components =[
    {'code': '', 'desc': 'Select'},
    {'code': '1', 'desc': 'Container'},
    {'code': '2', 'desc': 'Dunnage'},
    {'code': '4', 'desc': 'Top Cap'},
    {'code': '5', 'desc': 'Pallet'},
    {'code':'6', 'desc': 'Stretch Wrap/Strap'},
    {'code':'7', 'desc': 'Kanban Holder'},
    {'code': '8', 'desc': 'Other'}
]
  
  component= 'Select'
  modalHeader = '';
  buttonValue = '';
  defaultVal = 'Inital';

  plant: any;
  supplier: any;
  pageName = '';
  submitted = false;
  oldPackageTypeCode = '';
  tableData: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddPkgTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any, 
    private readonly spinnerService: NgxUiLoaderService,
    private readonly packageTypeService: PackageTypeService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.pageName = this.dataStore.mode;
    this.tableData = this.dataStore.tableData;
    let pcode = '';
    let pname = '';
    let pcomponent = '';
    if (this.dataStore.mode === "ADD"){
      this.modalHeader = 'Add Package Type';
      this.buttonValue = 'ADD'; 
    }
    else if (this.dataStore.mode === "update"){
      this.modalHeader = 'Update Package Type';
      this.buttonValue = 'UPDATE';
      pcode = this.dataStore.field.packagetypecode;
      this.oldPackageTypeCode = this.dataStore.field.packagetypecode;
      pname = this.dataStore.field.packagetype;
      pcomponent = this.dataStore.field.packagetypeflag; 
    }
    this.addPkgForm =  new FormGroup({
      code : new FormControl(pcode, [Validators.required, Validators.maxLength(1)]),
      name : new FormControl(pname, [Validators.required, Validators.maxLength(20)]),
      component : new FormControl(pcomponent, [Validators.required])
    });

  }
 
  get f() {
    return this.addPkgForm.controls
  }

  updateBtn(){
    this.addPkgForm.markAllAsTouched();
    this.submitted = true;
    if(this.addPkgForm.valid){
      if(this.pageName === 'ADD') {
        this.addPackageType();
      } else if(this.pageName === 'update') {
        this.updatePackageType();
      }
    }
  }

  addValidations() {
    let returnVal = false;
    const dublicate = this.tableData.find((row) => row.packagetypecode ===  this.f['code']?.value);
    if (dublicate) {
      returnVal  = true
      this.errorMessage = `Duplicate Data Error [ ${this.f['code']?.value} ]`;
    }
    return returnVal;
  }

  addPackageType () {
    this.errorMessage = '';
    if(this.addValidations()) {
      return
    }

    const body = {
      PackageTypeCode: this.f['code']?.value,
      PackageType: this.f['name']?.value,
      PackageTypeFlag: this.f['component']?.value,
      OldPackageTypeCode: ""
    }
    this.spinnerService.start();
    this.packageTypeService.addPackageType(body).subscribe({
      next: (_res:any)=>{
        if(_res?.status === "SUCCESS"){
          this.dialogRef.close({data: {result: 'success'}})
        }
        else{
          this.errorMessage = _res?.message;
        }
        this.spinnerService.stop();
      },
      error: (_err:any) => {
        this.spinnerService.stop();
      }
    })

  }

  updateValidations() {
    let value = true;
    this.errorMessage = '';
    const rowIndex = this.tableData.findIndex((item) => item.packagetypecode === this.dataStore.field.packagetypecode);
    const filteredArr = this.tableData.filter((item, index) => item.packagetypecode === this.f['code']?.value && index !== rowIndex)
    if(filteredArr.length > 0) {
      this.errorMessage = `Duplicate Data Error [ ${this.f['code']?.value} ]`;

      value = false;
    }
    return value;
  }

  updatePackageType() {
    this.errorMessage = '';
    if(!this.updateValidations()) {
      return
    }
    const body = {
      PackageTypeCode: this.f['code']?.value,
      PackageType: this.f['name']?.value,
      PackageTypeFlag: this.f['component']?.value,
      OldPackageTypeCode: this.oldPackageTypeCode
    }
    this.spinnerService.start();
    this.packageTypeService.updatePackageType(body).subscribe({
      next: (_res:any)=>{
        if(_res?.status === "SUCCESS"){
          this.dialogRef.close({data: {result: 'success'}})
        }
        else{
          this.errorMessage = _res?.message;
        }
        this.spinnerService.stop();
      },
      error: (_err:any) => {
        this.spinnerService.stop();
      }
    })
  }


  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' });
  }

  deleteMaterial(){
    this.spinnerService.start();
    const body = {
      PackageTypeCode : this.dataStore?.field?.packagetypecode
    }
    
    this.packageTypeService.deletePackageType(body).subscribe({
      next: (_res:any)=>{
        this.dialogRef.close({data: {result: 'success'}})
        this.spinnerService.stop();
      },
      error: (_err:any) => {
        this.spinnerService.stop();
      }
    })
  }

  errorClose() {
    this.errorMessage = '';
  }
  
  help(){
    this.dialogRef.close()
    if( this.modalHeader === 'Add Package Type'){
      this.router.navigate(['./master-help',5,"Master Maintenance",104])
    }
    else if( this.modalHeader === 'Update Package Type'){
      this.router.navigate(['./master-help',5,"Master Maintenance",105])
    }
  }
}
