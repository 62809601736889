<p class="page-title mx-2 px-1">Admin Access</p>
<div class="vpacs-shadow mx-2 p-2" *ngIf="unAuthorized === false">
    <div class="filter mx-1 mb-2 d-flex" (keyup.enter)="onSearch()">
        <div class="d-flex align-items-center">
            <label class="me-2">Code</label>
            <input type="text" [(ngModel)]="code"  maxlength="5"/>
        </div>
        <div class="d-flex  align-items-center">
            <label class="me-2">Short Name</label>
            <input type="text" [(ngModel)]="shortName"/>
        </div>
        <div class="d-flex  align-items-center">
            <label class="me-2">Supplier Name</label>
            <input type="text" [(ngModel)]="supplierName"/>
        </div>
        <div class="d-flex  align-items-center">
            <input type="button" value="Search" class="black-button" (click)="onSearch()" />
        </div>
    </div>
</div>
<div class="grid-agg grid-height" *ngIf="unAuthorized === false">
    <ag-grid-angular style="width:100%;height:100%;"
         class="ag-theme-balham"
        (gridReady)="onGridReady($event);" 
        (window:resize)="sizeToFit()" 
        [rowHeight]="rowHeight"
        [animateRows]="true"
        [defaultColDef]="defaultColDef" 
        [columnDefs]="columnDefs" 
        [rowData]="displayRowData"
        [suppressCellSelection]="true"
        [suppressHorizontalScroll]="true"
        [pagination]="true" 
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel] = "true"
        [icons]="icons"
        [headerHeight]="40"
        [floatingFiltersHeight]="40"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (paginationChanged)="onPaginationChanged()"
        >       
    </ag-grid-angular>
    <div class="d-flex pagination align-items-center justify-content-between" *ngIf="rowCount >0">
        <div >
            <div>
                <label class=" mx-2">Records/Page: </label>
                <select style="height: 30px;" (change)="onPageSizeChanged($event)">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50" selected>50</option>
                    <option value="{{rowCount}}">ALL</option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div>Viewing Records</div>
            <mat-icon (click)="goToFirstPage()" class="text-primary"
            [ngClass]="{'disable_icon':getCurrentPage() === true,'enable_icon':getCurrentPage()!== true}"
                >first_page</mat-icon>
            <mat-icon (click)="goToPreviousPage()" class="text-primary"
                [ngClass]="{'disable_icon':getCurrentPage()=== true,'enable_icon':getCurrentPage()!== true}"
                >fast_rewind</mat-icon>
            <div>
                (
                <span class="value" id="lbCurrentPage"></span>-
                <span class="value" id="lbTotalPages"></span>)
                <span>OF {{rowCount}}</span>
            </div>
            <mat-icon (click)="goToNexpage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"                
                >fast_forward</mat-icon>
            <mat-icon (click)="goToLastPage()" class="text-primary"
                [ngClass]="{'disable_icon':isLastPage()=== true,'enable_icon':isLastPage()!== true}"
                >last_page</mat-icon>
        </div>
    </div>
</div>
<!-- {{currentPageForTotalPage}}-{{totalPage}} of({{rowCount}} -->
<div class="alert alert-danger" *ngIf="unAuthorized">
    You're not authorized to access this page
</div>
<router-outlet></router-outlet>   