import { Component, OnInit, ViewChild } from '@angular/core'
import { GridOptions } from 'ag-grid-community'
import { RcafQuantityComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-quantity/rcaf-quantity.component'
import { RcafUnitComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-unit/rcaf-unit.component'
import { RcafCostComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/rcaf-cost/rcaf-cost.component'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthListService } from 'src/app/internal-vpacs/services-internal/cost-management/auth-list/auth-list.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { formatDate, DatePipe } from '@angular/common'
import { ExportStateService } from 'src/app/services/export-state.service'
import { SelectPackageCostComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/select-package-cost/select-package-cost.component'
import { MatDialog } from '@angular/material/dialog'
import { NgForm } from '@angular/forms'
import { RingiListService } from 'src/app/internal-vpacs/services-internal/cost-management/ringi-list.service'

@Component({
  selector: 'app-create-rcaf',
  templateUrl: './create-rcaf.component.html',
  styleUrls: ['./create-rcaf.component.scss'],
})
export class CreateRcafComponent implements OnInit {
  trackingUrl = '/tracking-list'
  arrofkeys: string[] = []
  sample = "$%'{}*_"
  errorList: any
  contactform: any
  panelOpenState = true
  panelOpenState2 = true
  panelOpenState3 = true
  panelOpenState4 = true
  partNumber: any
  ringi: any
  ringiList: any[] = []
  ringiType: any
  reason: any
  reasonList: any[] = []
  labelInfo: any[] = []
  bgColorList: any[] = []
  fontColorList: any[] = []
  bgColor: any
  fontColor: any
  gridApi: any
  defaultColDef: {}
  public rowHeight = 40
  public columnDefs: any[] = []
  public domLayout: any = ''
  public displayRowData: any
  public gridOptions: GridOptions = {}
  sideHeaders: any[] = []
  rowClassRules: any
  authNum: any
  spCode: any
  plantCode: any
  specialistCode: any
  flag: any
  pkgstartdate: any
  plants: any
  resPlant: any
  cPage: any
  rpPage: any
  crafDetails: any
  deliveryDate: any
  namc: any
  supplier: any
  shortName: any
  totalCost: any[] = []
  userPlantCode: any
  modelCode: any
  familyCode: any
  supplierCode: any
  screen: any
  backupArray: any[] = []
  txtPackageQty: any
  tblCalcResp: any
  txtLabelTotalCost: any
  txtOtherCost: any
  calcUnitCost: any
  tdCalcContainer: any
  tdCalcDunnage1: any
  tdCalcDunnage2: any
  tdCalcTopCap: any
  tdCalcPallet: any
  tdCalcStretchWrapStrap: any
  tdCalcKanbanCardHolder: any
  tdCalcOther: any
  txtLabelQty: any
  txtLabelUnitCost: any
  txtContainerQty: any
  txtContainerUnitCost: any
  txtContainerTotalCost: any
  txtDunnage1Qty: any
  txtDunnage1UnitCost: any
  txtDunnage1TotalCost: any
  txtDunnage2Qty: any
  txtDunnage2UnitCost: any
  txtDunnage2TotalCost: any
  txtTopCapUnitCost: any
  txtPalletUnitCost: any
  txtStretchWrapStrapUnitCost: any
  txtKanbanCardHolderUnitCost: any
  txtOtherUnitCost: any
  txtTopCapTotalCost: any
  txtPalletTotalCost: any
  txtStretchWrapStrapTotalCost: any
  txtKanbanCardHolderTotalCost: any
  txtOtherTotalCost: any
  txtStretchWrapStrapQty: any
  txtPalletQty: any
  txtTopCapQty: any
  txtKanbanCardHolderQty: any
  txtOtherQty: any
  taExtComment: any
  mode: any
  details: any
  btnVisibility: any
  routeParams: any
  commentforsupplier: any
  internalUse: any
  @ViewChild('contactForm') myformforsetting!: NgForm
  isSubmitted = false
  reqArr: any[] = []
  plantTrackingList: any[] = []
  qtyError = 'Range Error [Qty] is out of range. ( 0 < [Qty] < 99999 )'
  costError = 'Range Error [Unit Cost] is out of range. ( 0 < [Unit Cost] < 999999 )'
  dateFormat = 'MM/dd/YYYY'
  reasonVal: any

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authListService: AuthListService,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly dialog: MatDialog,
    public datepipe: DatePipe,
    private readonly ringiListService: RingiListService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      suppressHorizontalScroll: true,
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.spinnerService.start()

    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.backupArray = res.role?.backupDetails?.map((a: any) => a.specialistcode);
        this.routeParams = JSON.parse(
          sessionStorage.getItem('createRCAF') || '{}',
        )
        this.authNum = this.routeParams?.authNumber
        this.plantCode = this.routeParams?.plantCode
        this.specialistCode = this.routeParams?.specialistCode
        this.flag = this.routeParams?.flag
        this.pkgstartdate = this.routeParams?.pkgstartdate
        this.partNumber = this.routeParams?.partNumber
        this.modelCode = this.routeParams?.modelcode
        this.familyCode = this.routeParams?.familycode
        this.supplierCode = this.routeParams?.suppliercode
        this.screen = this.routeParams?.screen
        this.mode = this.routeParams?.mode
        this.spCode = res?.info?.specialistcode;
        this.userPlantCode = JSON.parse(
          sessionStorage.getItem('plantCode') || '',
        )

        this.changeMode(res)

      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
    this.labelInfo = [
      'Background Color',
      'Font Color',
      'NAMC Code',
      'Supplier Code',
      'Short Name',
    ]
    this.sideHeaders = [
      'CONTAINER',
      'DUNNAGE 1',
      'DUNNAGE 2',
      'TOP CAP',
      'PALLET',
      'STRETCH WRAP/ STRAP',
      'KANBAN CARD HOLDER',
      'OTHER PACKAGE',
      'LABEL',
      'OTHER',
    ]
    this.bgColorList = [
      { id: '1', label: 'Blue (Process Blue)' },
      { id: '2', label: 'Red (Red 187)' },
      { id: '3', label: 'Purple (Pantone Purple)' },
      { id: '4', label: 'White (White)' },
      { id: '5', label: 'Yellow (Pantone Yellow)' },
      { id: '6', label: 'Pink (Pantone 223C)' },
      { id: '7', label: 'Lt Grey (Pantone 441C)' },
      { id: '8', label: 'Orange (Orange 144)' },
      { id: '9', label: 'Black (Black)' },
    ]

    this.fontColorList = [
      { id: '1', label: 'White' },
      { id: '2', label: 'Black' },
    ]

    this.columnDefs = [
      {
        headerName: '',
        autoHeight: true,
        field: '',
        minWidth: 170,
        maxWidth: 170,
        pinned: 'left',
        cellRenderer: (params: any) => {
          const index = this.sideHeaders[params.node.rowIndex]
          return `<span>${index}</span>`
        },
      },
      {
        headerName: 'PACKAGE CODE',
        autoHeight: true,
        field: 'packagecode',
        cellRenderer: (params: any) => {
          if (params.data.rowHeader !== '') {
            return `<span>${params.data.packagecode}</span>`
          } else {
            return ''
          }
        },
      },
      {
        headerName: 'QUANTITY',
        autoHeight: true,
        field: 'qty',
        cellRendererFramework: RcafQuantityComponent,
      },
      {
        headerName: 'UNIT$(US)',
        autoHeight: true,
        field: 'unitcost',
        cellRendererFramework: RcafUnitComponent,
        cellRendererParams: {
          clicked: (_colId: string, _data: any) => {
            this.calcPackage()
          },
        },
      },
      {
        headerName: 'COST',
        autoHeight: true,
        field: 'cost',
        cellRendererFramework: RcafCostComponent,
      },
    ]

    this.displayRowData = []
  }

  changeMode(res: any) {
    this.errorList = []
    const authError = 'Authority error';
    if (this.mode === 0) { // mode: new model
      if (!res.role?.p_trackingview) {
        this.showMessage([authError])
        this.spinnerService.stop()
      }
      else if (this.routeParams) {
        this.loadRCAF()
      }
    }
    else {
      if (!res.role?.p_qtyauth) {
        this.showMessage([authError])
        this.spinnerService.stop()
      }
      else if (this.routeParams) {
        if (this.mode !== 2) { //mode = 1 : add purchase
          this.plantTrackingList = res.role?.p_plantTrackingList;

          if (!this.containPlantTrackingList(this.plantCode)) {
            this.showMessage([authError])
            this.spinnerService.stop()
          }
          else {
            this.loadRCAF()
          }
        }
        else { //mode: update
          this.loadRCAF()
        }
      }
    }
  }

  containPlantTrackingList(plantCode: any) {
    for (let i = 0; i < this.plantTrackingList?.length; i++) {
      if (this.plantTrackingList[i]["plantcode"] === plantCode) {
        return true;
      }
    }
    return false;
  }

  calculate() {
    const pkgQty = Number(this.txtPackageQty)
    const checkVal = this.crafDetails?.HtmlPackageResult
    const qppVal = this.crafDetails?.HtmlPackageResult
    return { pkgQty: pkgQty, checkVal: checkVal, qppVal: qppVal }
  }

  calcPackage() {
    this.totalCost = []
    const { pkgQty, checkVal, qppVal } = this.calculate()
    this.calcPackageOne()
    if (checkVal.PalletCode !== '' && checkVal.PalletCode !== null && checkVal.PalletCode !== undefined) {
      if(qppVal?.PalletQPP!==undefined && pkgQty !==undefined){
        this.txtPalletQty = Math.ceil(pkgQty * Number(qppVal?.PalletQPP))
      this.calcPackageCost(
        this.txtPalletQty,
        this.txtPalletUnitCost,
        this.txtPalletTotalCost,
      )
      }
    }
    if (
      checkVal.StretchWrapStrapCode !== '' &&
      checkVal.StretchWrapStrapCode !== null &&
      checkVal.StretchWrapStrapCode !== undefined
    ) {
      if(qppVal?.WrapQPP!==undefined && pkgQty !==undefined){

      this.txtStretchWrapStrapQty = Math.ceil(pkgQty * Number(qppVal?.WrapQPP))
      this.calcPackageCost(
        this.txtStretchWrapStrapQty,
        this.txtStretchWrapStrapUnitCost,
        this.txtStretchWrapStrapTotalCost,
      )
      }
    }
    if (
      checkVal.KanbanCardHolderCode !== '' &&
      checkVal.KanbanCardHolderCode !== null &&
      checkVal.KanbanCardHolderCode !== undefined
    ) {
      if(qppVal?.KHolderQPP!==undefined && pkgQty !==undefined){

      this.txtKanbanCardHolderQty = Math.ceil(
        pkgQty * Number(qppVal?.KHolderQPP),
      )
      this.calcPackageCost(
        this.txtKanbanCardHolderQty,
        this.txtKanbanCardHolderUnitCost,
        this.txtKanbanCardHolderTotalCost,
      )
      }
    }
    if (checkVal.OtherCode !== '' && checkVal.OtherCode !== null  && checkVal.OtherCode !== undefined) {
      if(qppVal?.OtherPkgQPP!==undefined && pkgQty !==undefined){
      this.txtOtherQty = Math.ceil(pkgQty * Number(qppVal?.OtherPkgQPP))
      this.calcPackageCost(
        this.txtOtherQty,
        this.txtOtherUnitCost,
        this.txtOtherTotalCost,
      )
      }
    }
    if(qppVal?.LabelQPP!==undefined && qppVal?.LabelQPP!=='' && qppVal?.LabelQPP!==null){
      if(qppVal?.LabelQPP!==undefined && pkgQty !==undefined){

      this.txtLabelQty = Number(Math.ceil(pkgQty * Number(qppVal?.LabelQPP)))
    this.calcPackageCost(
      this.txtLabelQty,
      this.txtLabelUnitCost,
      this.txtLabelTotalCost,
    )
      }
    }
  }

  calcPackageOne() {
    const { pkgQty, checkVal, qppVal } = this.calculate()
    if (checkVal.ContainerCode !== '' && checkVal.ContainerCode !== null && checkVal.ContainerCode !== undefined) {
      this.txtContainerQty = pkgQty
      this.calcPackageCost(
        this.txtContainerQty,
        this.txtContainerUnitCost,
        this.txtContainerTotalCost,
      )
    }
    if (checkVal.Dunnage1Code !== '' && checkVal.Dunnage1Code !== null && checkVal.Dunnage1Code !== undefined) {
      if(qppVal?.Dunnage1QPP!==undefined && pkgQty !==undefined){
      this.txtDunnage1Qty = Math.ceil(pkgQty * Number(qppVal?.Dunnage1QPP))
      this.calcPackageCost(
        this.txtDunnage1Qty,
        this.txtDunnage1UnitCost,
        this.txtDunnage1TotalCost,
      )
      }
    }
    if (checkVal.Dunnage2Code !== '' && checkVal.Dunnage2Code !== null && checkVal.Dunnage2Code !== undefined) {
      if(qppVal?.Dunnage2QPP!==undefined && pkgQty !==undefined){
      this.txtDunnage2Qty = Math.ceil(pkgQty * Number(qppVal?.Dunnage2QPP))
      this.calcPackageCost(
        this.txtDunnage2Qty,
        this.txtDunnage2UnitCost,
        this.txtDunnage2TotalCost,
      )
      }
    }
    if (checkVal.TopCapCode !== '' && checkVal.TopCapCode !== null && checkVal.TopCapCode !== undefined) {
      if(qppVal?.TopCapQPP!==undefined && pkgQty !==undefined){
      this.txtTopCapQty = Math.ceil(pkgQty * Number(qppVal?.TopCapQPP))
      this.calcPackageCost(
        this.txtTopCapQty,
        this.txtTopCapUnitCost,
        this.txtTopCapTotalCost,
      )
      }
    }
  }

  calcPackageCost(_pkgQty: any, _unitcost: any, _total: any) {
    this.calcTotalCost()
  }

  calcTotalCost() {
    this.txtContainerTotalCost =
      Number((this.nullDouble(this.txtContainerUnitCost) *
      this.nullDouble(this.txtContainerQty)).toFixed(2))
    this.txtDunnage1TotalCost =
      Number((this.nullDouble(this.txtDunnage1UnitCost) *
      this.nullDouble(this.txtDunnage1Qty)).toFixed(2))
    this.txtDunnage2TotalCost =
      Number((this.nullDouble(this.txtDunnage2UnitCost) *
      this.nullDouble(this.txtDunnage2Qty)).toFixed(2))
    this.txtTopCapTotalCost =
      Number((this.nullDouble(this.txtTopCapUnitCost) *
      this.nullDouble(this.txtTopCapQty)).toFixed(2))
    this.txtPalletTotalCost =
      Number((this.nullDouble(this.txtPalletUnitCost) *
      this.nullDouble(this.txtPalletQty)).toFixed(2))
    this.txtStretchWrapStrapTotalCost =
      Number((this.nullDouble(this.txtStretchWrapStrapUnitCost) *
      this.nullDouble(this.txtStretchWrapStrapQty)).toFixed(2))
    this.txtKanbanCardHolderTotalCost =
      Number((this.nullDouble(this.txtKanbanCardHolderUnitCost) *
      this.nullDouble(this.txtKanbanCardHolderQty)).toFixed(2))
    this.txtOtherTotalCost =
      Number((this.nullDouble(this.txtOtherUnitCost) * this.nullDouble(this.txtOtherQty)).toFixed(2))
    this.txtLabelTotalCost =
      Number((this.nullDouble(this.txtLabelUnitCost) * this.nullDouble(this.txtLabelQty)).toFixed(2))
    this.totalCost = (
      this.txtContainerTotalCost +
      this.txtDunnage1TotalCost +
      this.txtDunnage2TotalCost +
      this.txtTopCapTotalCost +
      this.txtPalletTotalCost +
      this.txtStretchWrapStrapTotalCost +
      this.txtKanbanCardHolderTotalCost +
      this.txtOtherTotalCost +
      this.txtLabelTotalCost +
     Number(this.txtOtherCost)
    ).toFixed(2)
  }

  selectPackageCost(pkgCode: any, index: any) {
    const dialogRef = this.dialog.open(SelectPackageCostComponent, {
      data: {
        packageCode: pkgCode,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (index === 1) {
        this.txtContainerUnitCost = result.data
      } else if (index === 2) {
        this.txtDunnage1UnitCost = result.data
      } else if (index === 3) {
        this.txtDunnage2UnitCost = result.data
      } else if (index === 4) {
        this.txtTopCapUnitCost = result.data
      } else if (index === 5) {
        this.txtPalletUnitCost = result.data
      } else if (index === 6) {
        this.txtStretchWrapStrapUnitCost = result.data
      } else if (index === 7) {
        this.txtKanbanCardHolderUnitCost = result.data
      } else if (index === 8) {
        this.txtOtherUnitCost = result.data
      }
      this.calcPackage()
    })
  }

  formatCost(cost: any): any {
    return cost.toFixed(2).toString()
  }

  nullDouble(obj: any): number {
    if (obj === null || obj === '' || obj === undefined ) {
      return 0
    } else {
      try {
        if (typeof obj === 'number') {
          return obj
        } else if (typeof obj === 'string') {
          return parseFloat(obj)
        } else {
          return parseInt(obj)
        }
      } catch {
        return 0
      }
    }
  }

  changeNullData(data: string) {
    if (data === null || data.length === 0 || data===undefined) {
      return ' '
    } else {
      return data
    }
  }

  loadRCAF() {
    const rcafObj = this.generatePayload()
    this.authListService.loadCRAFDetails(rcafObj).subscribe({
      next: (res: any) => {
        if (res?.status === false) {
          this.showMessage(['No contents to display'])
        }
        else {
          if (res?.HtmlPackageResult?.errorMessage && res?.HtmlPackageResult?.errorMessage.length>0) {
          this.showMessage([res?.HtmlPackageResult?.errorMessage])
          }
          this.crafDetails = res
          this.btnVisibility = this.crafDetails?.HtmlPackageResult
          this.ringiList = res?.Mode === 1 ? res?.RingiData : res?.RingiResult
          this.ringi = res?.RingiNumber
          this.ringiType = this.crafDetails?.RingiType ?? this.crafDetails?.Lbl_PartInfo?.RingiType
          this.reasonList = res?.ReasonData
          this.reason = res?.ReasonCode
          this.reasonVal = res?.ReasonValue
          this.bgColor = res?.LabelInfo?.LabelBGColor ?? this.bgColorList[3]?.id
          this.fontColor =
            res?.LabelInfo?.LabelFontColor ?? this.fontColorList[1]?.id
          this.deliveryDate = res?.ActualDate
          this.namc = res?.LabelInfo?.NAMC
          this.supplier = res?.LabelInfo?.SupplierCode
          this.shortName = res?.LabelInfo?.SupplierName
          this.commentforsupplier = res?.Comment1
          this.internalUse = res?.Comment2
          this.txtLabelQty = this.crafDetails?.LabelQty
          this.txtLabelUnitCost =
            this.crafDetails?.LabelUnitCost ??
            this.crafDetails?.HtmlPackageResult?.LabelUnitCost
          this.txtOtherCost = (this.crafDetails?. OtherCost)?(this.crafDetails?. OtherCost):''
          const dv = this.crafDetails?.AuthDetailData
          if (this.crafDetails?.HtmlPackageResult) {
            this.requiredItems();
          }
          this.firstcalculation(dv)
          if (res?.Mode === 2) {
            this.calcTotalCost()
          }
        
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  requiredItems() {
    const htmlPkg = this.crafDetails?.HtmlPackageResult;
    if (htmlPkg) {
      if (htmlPkg?.ContainerCode !== null && htmlPkg?.ContainerCode !== '') {
        this.reqArr.push('txtContainerQty')
      }
      if (htmlPkg?.Dunnage1Code !== null && htmlPkg?.Dunnage1Code !== '') {
        this.reqArr.push('txtDunnage1Qty')
      }
      if (htmlPkg?.Dunnage2Code !== null && htmlPkg?.Dunnage2Code !== '') {
        this.reqArr.push('txtDunnage2Qty')
      }
      if (htmlPkg?.TopCapCode !== null && htmlPkg?.TopCapCode !== '') {
        this.reqArr.push('txtTopCapQty')
      }
      if (htmlPkg?.PalletCode !== null && htmlPkg?.PalletCode !== '') {
        this.reqArr.push('txtPalletQty')
      }
      if (htmlPkg?.StretchWrapStrapCode !== null && htmlPkg?.StretchWrapStrapCode !== '') {
        this.reqArr.push('txtStretchWrapStrapQty')
      }
      if (htmlPkg?.KanbanCardHolderCode !== null && htmlPkg?.KanbanCardHolderCode !== '') {
        this.reqArr.push('txtKanbanCardHolderQty')
      }
      if (htmlPkg?.OtherCode !== null && htmlPkg?.OtherCode !== '') {
        this.reqArr.push('txtOtherQty')
      }
    }
    else {
      return;
    }
  }

  generatePayload() {
    let rcafObj: {}
    if (this.screen === 'tracking-R') {
      rcafObj = {
        AuthNumber: '',
        ModelCode: this.modelCode,
        FamilyCode: this.familyCode,
        PlantCode: this.plantCode,
        PartNumber: this.partNumber,
        SupplierCode: this.supplierCode,
        PkgStartDate: this.pkgstartdate,
        Flag: '',
        UserSpecialistCode: this.spCode,
        UserPlantCode: this.userPlantCode,
        BackUp: this.backupArray,
      }
    } else if (this.screen === 'tracking-AU') {
      rcafObj = {
        AuthNumber: '',
        ModelCode: this.modelCode,
        FamilyCode: this.familyCode,
        PlantCode: this.plantCode,
        PartNumber: this.partNumber,
        SupplierCode: this.supplierCode,
        PkgStartDate: this.pkgstartdate,
        Flag: 'new',
        UserSpecialistCode: this.spCode,
        UserPlantCode: this.userPlantCode,
        BackUp: this.backupArray,
      }
    } else if (this.router?.url.includes('quantityConrtol/create-rcaf')) {
      rcafObj = {
        AuthNumber: '',
        PlantCode: this.plantCode ?? '',
        PartNumber: this.partNumber ?? '',
        SupplierCode: this.supplierCode ?? '',
        Flag: '',
        UserSpecialistCode: this.spCode ?? '',
        UserPlantCode: this.userPlantCode ?? '',
        ModelCode: this.modelCode ?? '',
        Year: this.routeParams.year ?? '',
        Week: this.routeParams.Week ?? '',
        BackUp: this.backupArray,
        PackageCode: this.routeParams.PackageCode ?? '',
        Dunnage1Code: this.routeParams.Dunnage1Code ?? '',
        Dunnage2Code: this.routeParams.Dunnage2Code ?? '',
        PkgStartDate: this.pkgstartdate ?? '',
      }
    } else {
      rcafObj = {
        AuthNumber: this.authNum,
        UserSpecialistCode: this.spCode,
        UserPlantCode: this.userPlantCode,
        BackUp: this.backupArray,
      }
    }
    return rcafObj
  }

  firstcalculation(dv: any) {
    if (dv) {
      for (let i = 0; i < dv.length; i++) {
        const row = this.nullInt(dv[i]['rownumber'])
        if (
          this.nullStr(dv[i]['packagecode']) !== '-' &&
          this.nullStr(dv[i]['packagecode']) !== '*'
        ) {
          if (row === 1) {
            this.tdCalcContainer = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 2) {
            this.tdCalcDunnage1 = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 3) {
            this.tdCalcDunnage2 = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 4) {
            this.tdCalcTopCap = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 5) {
            this.tdCalcPallet = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 6) {
            this.tdCalcStretchWrapStrap = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 7) {
            this.tdCalcKanbanCardHolder = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          } else if (row === 8) {
            this.tdCalcOther = this.changeNullData(
              this.nullStr(dv[i]['packagecode']),
            )
          }
        }
        this.secondCalculation(row, dv, i)
        if (row === 1) {
          this.txtPackageQty = this.nullInt(dv[i]['qty']).toString()
        }
      }
    } else {
      const drv = this.crafDetails?.HtmlPackageResult
      this.tdCalcContainer = this.changeNullData(
        this.nullStr(drv['ContainerCode']),
      )
      this.tdCalcDunnage1 = this.changeNullData(
        this.nullStr(drv['Dunnage1Code']),
      )
      this.tdCalcDunnage2 = this.changeNullData(
        this.nullStr(drv['Dunnage2Code']),
      )
      this.tdCalcTopCap = this.changeNullData(this.nullStr(drv['TopCapCode']))
      this.tdCalcPallet = this.changeNullData(this.nullStr(drv['PalletCode']))
      this.tdCalcStretchWrapStrap = this.changeNullData(
        this.nullStr(drv['StretchWrapStrapCode']),
      )
      this.tdCalcKanbanCardHolder = this.changeNullData(
        this.nullStr(drv['KanbanCardHolderCode']),
      )
      this.tdCalcOther = this.changeNullData(this.nullStr(drv['OtherCode']))
      this.txtPackageQty = this.crafDetails?.PackageQty ?? 0
      this.txtContainerQty = ''
      this.txtDunnage1Qty = ''
      this.txtDunnage2Qty = ''
      this.txtTopCapQty = ''
      this.txtPalletQty = ''
      this.txtStretchWrapStrapQty = ''
      this.txtKanbanCardHolderQty = ''
      this.txtOtherQty = ''
      this.txtContainerUnitCost = this.txtContainerUnitCost ?? drv['txtContainerUnitCost']
      this.txtDunnage1UnitCost = this.txtDunnage1UnitCost ?? drv['txtDunnage1UnitCost']
      this.txtDunnage2UnitCost = this.txtDunnage2UnitCost ?? drv['txtDunnage2UnitCost']
      this.txtTopCapUnitCost = this.txtTopCapUnitCost ?? drv['txtTopCapUnitCost']
      this.txtPalletUnitCost = this.txtPalletUnitCost ?? drv['txtPalletUnitCost']
      this.txtStretchWrapStrapUnitCost = this.txtStretchWrapStrapUnitCost ?? drv['txtStretchWrapStrapUnitCost']
      this.txtKanbanCardHolderUnitCost = this.txtKanbanCardHolderUnitCost ?? drv['txtKanbanCardHolderUnitCost']
      this.txtOtherUnitCost = this.formatCost(
        this.nullDouble(drv['txtOtherUnitCost']),
      )
    }
  }

  secondCalculation(row: any, dv: any, i: any) {
    if (row === 1) {
      this.txtContainerQty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 2) {
      this.txtDunnage1Qty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 3) {
      this.txtDunnage2Qty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 4) {
      this.txtTopCapQty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 5) {
      this.txtPalletQty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 6) {
      this.txtStretchWrapStrapQty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 7) {
      this.txtKanbanCardHolderQty = this.nullInt(dv[i]['qty']).toString()
    } else if (row === 8) {
      this.txtOtherQty = this.nullInt(dv[i]['qty']).toString()
    }
    const rate = this.crafDetails?.rate ? Number(this.crafDetails?.rate) : 0
    if (row === 1) {
      this.txtContainerUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 2) {
      this.txtDunnage1UnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 3) {
      this.txtDunnage2UnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 4) {
      this.txtTopCapUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 5) {
      this.txtPalletUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 6) {
      this.txtStretchWrapStrapUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 7) {
      this.txtKanbanCardHolderUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    } else if (row === 8) {
      this.txtOtherUnitCost = this.formatCost(
        rate * this.nullDouble(dv[i]['unitcost']),
      )
    }
  }

  nullInt(obj: any) {
    if (obj == null) {
      return 0
    } else {
      return parseInt(obj)
    }
  }

  nullStr(value: any): string {
    if (value === undefined || value === 'undefined' || value === null) {
      return ''
    } else {
      return value.toString()
    }
  }

  showMessage(message: string[]) {
    window.scrollTo(0, 0);
    this.errorList = message;
  }

  onSubmit(form: any) {
    this.errorList = []
    this.isSubmitted = true
    this.arrofkeys = []
    for (const key in this.myformforsetting.form.controls) {
      this.arrofkeys.push(key)
    }

    const today = formatDate(new Date(), 'MM-dd-YYYY', 'en-US')
    if (this.deliveryDate >= today) {
      const arr = []
      for (const index of this.sample) {
        if (form.controls['commentforsupplier']?.value?.includes(index)) {
          arr.push(
            `Data Format Error [Comment for Supplier] has prohibited character.["$%'\{}*_]`,
          )
          break
        }
      }
      for (const index of this.sample) {
        if (form.controls['internalUse']?.value?.includes(index)) {
          arr.push(
            `Data Format Error [Internal use only] has prohibited character.["$%'\{}*_]`,
          )
          break
        }
      }
      this.reqArr.push('reason', 'ringi', 'deliveryDate')

      for (const index of this.arrofkeys) {
        if (
          this.reqArr.includes(index) &&
          (form.controls[index]?.value === undefined ||
            form.controls[index]?.value === '')
        ) {
          arr.push(`Required Item Error [ ${index} ] is not selected`)
        }
      }

      if (Math.sign(Number(this.txtPackageQty)) === -1) {
        arr.push('Range Error [Pkg Qty] is out of range. ( 0 < [Pkg Qty] < 99999 )')
      }

      if (Math.sign(Number(this.txtContainerQty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtDunnage1Qty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtDunnage2Qty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtTopCapQty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtPalletQty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtStretchWrapStrapQty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtKanbanCardHolderQty)) === -1) {
        arr.push(this.qtyError)
      }

      if (Math.sign(Number(this.txtLabelQty)) === -1) {
        arr.push('Range Error [Lable Qty] is out of range. ( 0 < [Lable Qty] < 99999 )')
      }

      if (Math.sign(Number(this.txtContainerUnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtDunnage1UnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtDunnage2UnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtTopCapUnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtPalletUnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtStretchWrapStrapUnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.txtKanbanCardHolderUnitCost)) === -1) {
        arr.push(this.costError)
      }

      if (Math.sign(Number(this.totalCost)) === -1 || Number(this.totalCost) < 0 || Number(this.totalCost) > 99999999) {
        arr.push('Range Error [Total Cost] is out of range. ( 0 < [Total Cost] < 99999999 ')
      }

      if (Math.sign(Number(this.txtOtherCost)) === -1 || Number(this.txtOtherCost) < 0 || Number(this.txtOtherCost) > 99999999) {
        arr.push('Range Error [Other Cost] is out of range. ( 0 < [Other Cost] < 999999 ')
      }

      if (Math.sign(Number(this.txtLabelUnitCost)) === -1 || Number(this.txtLabelUnitCost) < 0 || Number(this.txtLabelUnitCost) > 99999999) {
        arr.push('Range Error [Lable Unit Cost] is out of range. ( 0 < [Lable Unit Cost] < 999999 )')
      }

      if (Math.sign(Number(this.totalCost)) === 0) {
        arr.push('This authorization has no cost.  Zero cost authorizations are not allowed.');
      }

      this.showMessage(arr)
      if (arr?.length === 0) {
        let qtyArr: any[] = []
        let costArr: any[] = []
        let pkgArr: any[] = []

        this.txtContainerQty =
          this.txtContainerQty !== '' ? this.txtContainerQty : 0
        this.txtDunnage1Qty =
          this.txtDunnage1Qty !== '' ? this.txtDunnage1Qty : 0
        this.txtDunnage2Qty =
          this.txtDunnage2Qty !== '' ? this.txtDunnage2Qty : 0
        this.txtTopCapQty = this.txtTopCapQty !== '' ? this.txtTopCapQty : 0
        this.txtPalletQty = this.txtPalletQty !== '' ? this.txtPalletQty : 0
        this.txtStretchWrapStrapQty =
          this.txtStretchWrapStrapQty !== '' ? this.txtStretchWrapStrapQty : 0
        this.txtKanbanCardHolderQty =
          this.txtKanbanCardHolderQty !== '' ? this.txtKanbanCardHolderQty : 0
        this.txtOtherQty = this.txtOtherQty !== '' ? this.txtOtherQty : 0

        qtyArr = [
          Number(this.txtContainerQty),
          Number(this.txtDunnage1Qty),
          Number(this.txtDunnage2Qty),
          Number(this.txtTopCapQty),
          Number(this.txtPalletQty),
          Number(this.txtStretchWrapStrapQty),
          Number(this.txtKanbanCardHolderQty),
          Number(this.txtOtherQty),
        ]

        costArr = [
          Number(this.txtContainerUnitCost),
          Number(this.txtDunnage1UnitCost),
          Number(this.txtDunnage2UnitCost),
          Number(this.txtTopCapUnitCost),
          Number(this.txtPalletUnitCost),
          Number(this.txtStretchWrapStrapUnitCost),
          Number(this.txtKanbanCardHolderUnitCost),
          Number(this.txtOtherUnitCost),
        ]

        pkgArr = [
          this.nullStr(this.tdCalcContainer),
          this.nullStr(this.tdCalcDunnage1),
          this.nullStr(this.tdCalcDunnage2),
          this.nullStr(this.tdCalcTopCap),
          this.nullStr(this.tdCalcPallet),
          this.nullStr(this.tdCalcStretchWrapStrap),
          this.nullStr(this.tdCalcKanbanCardHolder),
          this.nullStr(this.tdCalcOther),
        ]

        const rcafObj: any = {
          AuthNumber: this.crafDetails.Mode === 2 ? this.authNum : '',
          Mode: this.crafDetails.Mode ?? '',
          UserPlantCode: this.userPlantCode ?? '',
          UserSpecialistCode: this.spCode ?? '',
          RingiNumber: this.ringi ?? '',
          SupplierCode: this.supplier ?? '',
          PartNumber: this.partNumber ?? '',
          ActualDate:
            this.datepipe.transform(this.deliveryDate, this.dateFormat, 'UTC') ?? '',
          ReasonCode: this.reason ? Number(this.reason) : 0,
          Reason: this.reasonVal,
          LabelQty: this.txtLabelQty ?? (this.crafDetails.LabelQty
            ? Number(this.crafDetails.LabelQty)
            : 0),
          LabelCost: Number(this.txtLabelUnitCost) || 
            (Number(this.crafDetails.LabelUnitCost) || 
            Number(this.crafDetails?.HtmlPackageResult?.LabelUnitCost) || 
            0),
          OtherCost: Number(this.txtOtherCost) || Number(this.crafDetails.OtherCost) || 0,
          Comment1: this.commentforsupplier ?? '',
          Comment2: this.internalUse ?? '',
          PlantCode: this.plantCode ?? '',
          TotalCost: this.totalCost ? Number(this.totalCost) : 0,
          LabelBGColor: this.bgColor ? this.bgColor.toString() : '',
          LabelFontColor: this.fontColor ? this.fontColor.toString() : '',
          PkgCodeArr: pkgArr,
          QtyArr: qtyArr,
          CostArr: costArr,
          PkgStartDate: this.pkgstartdate
            ? this.datepipe.transform(this.pkgstartdate, this.dateFormat, 'UTC')
            : '',
          ModelCode: this.crafDetails?.ModelCode
            ? this.crafDetails?.ModelCode
            : this.modelCode ?? '',
          PackageQty: Number(this.txtPackageQty) ?? 0,
          PackageCode: this.tdCalcContainer,
          Dunnage1Code: this.tdCalcDunnage1 ?? '',
          Dunnage2Code: this.tdCalcDunnage2 ?? '',
        }
        let flag = true

        for (const index of this.sample) {
          if (form.controls['commentforsupplier']?.value?.includes(index)) {
            flag = false
            break
          }
        }
        for (const index of this.sample) {
          if (form.controls['internalUse']?.value?.includes(index)) {
            flag = false
            break
          }
        }
        if (flag) {
          this.spinnerService.start()
          this.authListService.rcafAuthorize(rcafObj).subscribe({
            next: (res: any) => {
              if (res.message === 'Success') {
                if (
                  this.screen === 'tracking-R' ||
                  this.screen === 'tracking-AU'
                ) {
                  this.router.navigateByUrl(this.trackingUrl)
                } else {
                  this.router.navigateByUrl('/auth-list')
                }
              } else {
                this.showMessage([res?.message])
              }
              this.spinnerService.stop()
            },
            error: () => {
              this.spinnerService.stop()
            },
          })
        }
      }
    } else {
      this.showMessage(['[Actual Date] must be after or equal to Today'])
      this.spinnerService.stop()
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDomLayout('autoHeight')
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  changeRingi(ringiNum: any) {
    this.spinnerService.start()
    this.ringiListService.getRingi(ringiNum).subscribe({
      next: (res: any) => {
        const type = res?.result[0]?.ringitype;
        if (type === '1') {
          this.ringiType = "New Model";
        } else if (type === '2') {
          this.ringiType = "Maintenance";
        } else {
          this.ringiType = "-";
        }
        this.spinnerService.stop()
      },
      error: (_err: any) => {
        this.spinnerService.stop()
      },
    })
  }

  cancel() {
    let uri = this.trackingUrl
    if (this.router.url.includes('auth-list')) {
      uri = '/auth-list'
    } else if (this.router.url.includes('tracking-list')) {
      uri = this.trackingUrl
    } else if (this.router.url.includes('quantityConrtol')) {
      uri = '/quantityConrtol'
    }
    this.router.navigateByUrl(uri)
  }

  help() {
    this.router.navigate(['./master-help', 3, 'Cost Management', 43])
  }
  
  numValidatorForm(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const enteredKey = event.key;
    if (target.value.includes('.') && enteredKey === '.') {
      event.preventDefault();
      return;
    }
    const keyArray = ['.', 'Backspace', 'ArrowLeft', 'Tab', 'ArrowRight', 'Delete']
    if (isNaN(Number(enteredKey)) && !keyArray.includes(enteredKey)) {
      event.preventDefault();
      const numericValue = parseFloat(target.value);
      target.value = isNaN(numericValue) ? '' : String(numericValue);
    }
  }

  preventSpecialCharacters(event: any) {
    const enteredKey = event.key;
    if (enteredKey === '"' || enteredKey === `'` || enteredKey === '%' || enteredKey === '$' || enteredKey === '{' || enteredKey === '}' || enteredKey === '*' || enteredKey === '_') {
      event.preventDefault();
    }
  }

  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'deliveryDate') {
        this.deliveryDate = '';
      }
    }
  }

}
