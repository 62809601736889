import { Component, EventEmitter, Output } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-comment-editor',
  template: `
  <img title="{{comment}}" style="cursor:pointer" src="../../../assets/images/copyComment.svg" alt="NoData" 
  *ngIf="!isdisabled" (click)="viewComment()" />
    <input  class="columnCont"
      type="text" 
      [(ngModel)]="comment"
      [disabled]="isdisabled"
      (input)="onEnter()"
    />
  `,
  styles: [
    '.columnCont { width: 140px}',
  ]
})
export class CommentEditor implements ICellEditorAngularComp {
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  refresh(_params: ICellRendererParams): boolean {
    return true;
  }
  params: any;
  comment: any;
  isdisabled: any;
  agInit(params: any): void {
    this.params = params;
    this.comment = params.value;
    this.isdisabled = params.data?.selectedValue === 'reserve';
  }

  getValue(): any {
    return this.comment;
  }

  onEnter(): void {
    this.params.clicked(this.comment, this.params.data?.authnumber);
  }
  viewComment(){
    navigator.clipboard.writeText(this.comment).catch(() => {
    });
  }
}
