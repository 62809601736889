import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { RouterLinkCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/router-link-cell-renderer';
import { LegendsComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/legends/legends.component';
import { PpfBtnComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/ppf-btn/ppf-btn.component';
import { ActionBtnComponent } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/action-btn/action-btn.component';
import { TrackingListService, dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { UpdateFabricatorComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/update-fabricator/update-fabricator.component';
import { ConfirmationComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/confirmation/confirmation.component';
import { SelectLeaderComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-leader/select-leader.component';
import { formatDate } from '@angular/common';
import { SupplierInfoComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/supplier-info/supplier-info.component';
import { TrackingListCheckBoxRendered } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/tracking-list/trackingListCheckBoxRender';
import { PartListService } from 'src/app/internal-vpacs/services-internal/tracking/part-list.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-tracking-list',
  templateUrl: './tracking-list.component.html',
  styleUrls: ['./tracking-list.component.scss'],
})
export class TrackingListComponent implements OnInit {
  plantFlag = false;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 40;
  headerHeight = 50;
  hideBUCL = false
  isAccessible = false;
  plant: any;
  plants: any[] = [];
  stepList: any[] = [];
  activeChangesArray: any = []
  showActive = true;
  loadResponse: any;
  originalPage: any
  flag = false;

  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal = 1;
  stepNo: any;
  specialistCode = '';
  familyCode = '';
  modelCode = '';
  partNumber = '';
  supplier = '';
  fabricator = '';
  inDevelopment = true;
  complete = true;
  delay = 1;
  warn = 1;
  good = 1;
  notDefined = true;
  needApproval = true;
  backApproval = true;
  needApproval1 = true;
  noActionRequired = true;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  viewAccess: any;
  addAccess: any;
  modifyAccess: any;
  deleteAccess: any;
  userInfo: any
  selectAll: any;
  errorMessage: any;
  screenFlg: any;
  isAll = false;
  isSearch = false;
  page = '';
  routeParams: any;
  Plant: any;
  dfabricator: any;
  headerClassNoBorder = 'child-header-color no-border'
  workingSupplier = "working Supplier"
  radioGroup = [
    'Is Active',
  ];
  legends: any[] = [
    { css: 'btn1', label: '', desc: 'Plant Share' },
    { css: 'btn2', label: '', desc: 'Confirm Reject' },
    { css: 'btn3', label: '', desc: 'Delay(N < 0)' },
    { css: 'btn4', label: '', desc: 'Warn(0<=N<=5)' },
    { css: 'btn5', label: '', desc: 'Good(5 < N)' },
    { css: 'btn6', label: '', desc: 'Alert(Pass 5 days)' },
    { css: '', label: '', desc: '(N = Days)' },
  ]
  filteredData: any;
  spCode: any;
  filteredOptions: any[] = [];
  pkgStartDate: any;
  dateFormat = 'MM/dd/yyyy';
  format = 'en-US';
  timeStd = 'UTC';
  pkgChecked = false;
  searchFlag = false;
  sortModel: any[] = [];
  gridColumnApi: any;
  sortName: any[] = [];
  sortLogic: any;
  sortStart = false;
  getRowClass: any;

  constructor(
    private readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    private readonly trackingListService: TrackingListService,
    private readonly PartListService: PartListService,
    private readonly spinner: NgxUiLoaderService,
    private readonly state: ExportStateService,
    private readonly constants: ConstantsService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      filterParams: { newRowsAction: 'keep' },
      enableCellTextSelection: true,
      sortable: true,
      unSortIcon: true
    };
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    };
    this.rowClassRules = {
      'row-even': 'data.rowstatus ===0 && node.rowIndex % 2 === 0',
      'row-odd': 'data.rowstatus ===0 && node.rowIndex % 2 !== 0',
    };
    this.getRowClass = (params: any) => {
      if (this.nullInt(params.data.leaderflag) === 1 || this.nullStr(params.data.follower)!=="") {
        return 'row-btn1'; // share
      }
      if (this.nullInt(params.data.rejectflag) !== 0) {
        return 'row-btn2'; // reject
      }
      
      if (params.data.step10status === "1") {
        const dt = new Date(params.data.step09date);
        const today = new Date();
        const gap = Math.abs(today.getTime() - dt.getTime());
        const daysDifference = Math.ceil(gap / (1000 * 60 * 60 * 24));
        if (daysDifference > 5) {
          return 'row-btn6'; // alert
        }
      }
      if (params.data.rowstatus === 1) { // warn
        return 'row-btn4';
      }
      else if (params.data.rowstatus === 2) { // delay
        return 'row-btn3';
      }
      return '';
    };
  }
  ngOnInit(): void {
    if(this.trackingListService.trackingGridSate.pagesize){
      this.rpPage=this.trackingListService.trackingGridSate.pagesize           
    }
    if(this.trackingListService.trackingGridSate.currentpage){
      this.cPage=this.trackingListService.trackingGridSate.currentpage           
    }
    if(this.trackingListService.trackingGridSate.totalpage){
      this.tPage=this.trackingListService.trackingGridSate.totalpage           
    }
    this.spinner.start();
    this.errorMessage = '';
    this.stepList = [
      { name: 'step1', value: 1, checked: true },
      { name: 'step2', value: 2, checked: true },
      { name: 'step3', value: 3, checked: true },
      { name: 'step4', value: 4, checked: true },
      { name: 'step5', value: 5, checked: true },
      { name: 'step6', value: 6, checked: true },
      { name: 'step7', value: 7, checked: true },
      { name: 'step8', value: 8, checked: true },
      { name: 'step9', value: 9, checked: true },
      { name: 'step10', value: 10, checked: true },
      { name: 'step11', value: 11, checked: true },
      { name: 'step12', value: 12, checked: true },
    ];
    this.route.params.subscribe((data: any) => {
      this.routeParams = data;
    })
    if (Object.keys(this.routeParams).length !== 0) {
      this.stepNo = Number(this.routeParams.side);
      this.warn = Number(this.routeParams.warn);
      this.delay = Number(this.routeParams.delay);
      this.good = Number(this.routeParams.good);
      this.modelCode = (this.routeParams.model) || '';
      this.plant = Number(this.routeParams.scr_flg) === 0 ? this.routeParams.plant : '*';
      this.specialistCode = this.routeParams.specialist || '';
      this.selectAll = Boolean(this.routeParams.selectAll);
      this.page = this.routeParams.page || '';
    }
    else {
      this.screenFlg = 2;
    }
    if (this.stepNo !== undefined) {
      if (this.stepNo !== 20 && this.stepNo !== 30) {
        this.stepList.forEach((e: any, _i: any) => {
          e.checked = true;
        });
      }
      this.stepList.forEach((e: any, i: any) => {
        if (this.stepNo !== 20 && this.stepNo !== 30) {
          if ((i + 1) === this.stepNo) {
            e.checked = true;
          }
          else {
            e.checked = false;
          }
        }
        else if (this.stepNo === 20) { // Update PPF Approval
          this.needApproval = true;
          this.backApproval = false;
          this.notDefined = false;
        }
        else if (this.stepNo === 30) { // Backup Need Approval
          this.needApproval1 = true;
          this.noActionRequired = false;
        }
      });
    }
    this.selectAll && this.toggleAllCheckbox();
    const plantData = this.trackingListService.getTrackingplant();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        this.userInfo = res;
        this.viewAccess = res.role?.p_trackingview;
        this.addAccess = res.role?.p_trackingsave;
        this.modifyAccess = res.role?.p_trackingcorrect;
        this.deleteAccess = res.role?.p_trackingdelete;
        if (this.viewAccess) {
          this.spCode = res.info.specialistcode;
          if (plantData && this.screenFlg === 2) {
            const filterData = this.trackingListService?.gettrackingFilters();
            this.filteredData = filterData
            if (filterData) {
              this.spinner.start();
              this.plant = (filterData?.PlantCodes?.length > 1) ? '*' : filterData?.PlantCodes[0];
              this.familyCode = filterData.family;
              this.modelCode = filterData.model;
              this.specialistCode = filterData.specialist;
              this.partNumber = filterData.partnumber1;
              this.supplier = filterData.supplier;
              this.fabricator = filterData.fabricator;
              this.dfabricator = filterData.dfabricator;
              this.pkgStartDate = filterData.pkgstartdate;
              this.inDevelopment = filterData.under_dev;
              this.complete = filterData.complete;
              this.delay = filterData.delay;
              this.warn = filterData.warn;
              this.good = filterData.good;
              this.radioVal = filterData.valid;
              this.notDefined = filterData.back_nodefine;
              this.needApproval = filterData.back_define;
              this.backApproval = filterData.back_appdefine;
              this.needApproval1 = filterData.ppf_needapp;
              this.noActionRequired = filterData.ppf_noaction;
              this.stepList[0].checked = filterData.step1 === 1 ? true : false;
              this.stepList[1].checked = filterData.step2 === 1 ? true : false;
              this.stepList[2].checked = filterData.step3 === 1 ? true : false;
              this.stepList[3].checked = filterData.step4 === 1 ? true : false;
              this.stepList[4].checked = filterData.step5 === 1 ? true : false;
              this.stepList[5].checked = filterData.step6 === 1 ? true : false;
              this.stepList[6].checked = filterData.step7 === 1 ? true : false;
              this.stepList[7].checked = filterData.step8 === 1 ? true : false;
              this.stepList[8].checked = filterData.step9 === 1 ? true : false;
              this.stepList[9].checked = filterData.step10 === 1 ? true : false;
              this.stepList[10].checked = filterData.step11 === 1 ? true : false;
              this.stepList[11].checked = filterData.step12 === 1 ? true : false;
            }
          }
          this.pkgChecked = this.pkgStartDate !== '' ? true : false;
          this.getPlantDropDown();
        } else {
          this.spinner.stop();
          this.errorMessage = "You do not have permission to access the requested resource.";
        }
      },
      error: (_err: any) => {
        this.viewAccess = false;
        this.addAccess = false;
        this.modifyAccess = false;
      },
    });
    this.columnDefContents();
  }

  alertStatusClass(data: any) {
    return this.alertstatus(data.data) ? 'row-btn6' : '';
  }

  alertstatus(data: any): boolean {
    if (data.step10status === "1") {
      const dt = new Date(data.step09date);
      const today = new Date();
      const gap = Math.abs(today.getTime() - dt.getTime());
      const daysDifference = Math.ceil(gap / (1000 * 60 * 60 * 24));
      if (daysDifference > 5) {
        return true;
      }
    }
    return false;
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant?.toLowerCase().includes(this.plant?.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) => option.plantcode === selectId);
    this.pkgStartDate = this.setPkgDate(selectedOption?.plantcode);
    return selectedOption ? selectedOption?.plant : '';
  }

  changePkgStartDate(event: any) {
    this.pkgStartDate = event;
    this.trackingListService.setPkgStartDate(event);
  }

  getPlantDropDown() {
    this.errorMessage = '';
    this.spinner.start();
    this.plants = [];
    const obj = {
      SpecialistCode: this.spCode,
      Type: 1,
    };
    this.PartListService.plant(obj).subscribe({
      next: (res: any) => {
        if (res) {
          const response = res.sort((a: any, b: any) => a.plant.localeCompare(b.plant));
          this.plants = response;
          const obj = this.plants.find((obj) => obj.plantcode === '*');
          if (!obj) {
            this.plants.unshift({ plant: 'ALL', plantcode: '*' });
            this.plant = this.plant ?? (this.userInfo.info.plantcode);
          }
          this.filteredOptions = this.plants;
          this.plantFlag = true;
          this.displayFn(this.plants);
          this.trackingListService.setTrackingplant(this.plants)
          this.pkgStartDate = this.setPkgDate();
          if (Object.keys(this.routeParams).length !== 0 || this.filteredData) {
            this.onLoad();
          }
          this.toggleCheckBox()
        }
      },
      error: () => {
        this.spinner.stop();
      },
    });
  }

  toggleAllCheckbox() {
    this.stepList.forEach((x: any) => {
      x.checked = this.selectAll;
    })
  }

  toggleCheckBox() {
    this.selectAll = this.stepList.every((item: any) => {
      return item.checked;
    })
  }

  onChange(event: any, item: any) {
    if (item === 'delay') {
      this.delay = event.target.checked ? 1 : 0
    }
    if (item === 'warn') {
      this.warn = event.target.checked ? 1 : 0
    }
    if (item === 'good') {
      this.good = event.target.checked ? 1 : 0
    }
  }

  onChangeNotDefined(event: any) {
    this.notDefined = event.target.checked;
  }

  onChangeNeedApproval(event: any) {
    this.needApproval = event.target.checked;
  }

  onChangeBackApproval(event: any) {
    this.backApproval = event.target.checked;
  }

  onChangeApproval(event: any) {
    this.needApproval1 = event.target.checked;
  }

  onChangeAction(event: any) {
    this.noActionRequired = event.target.checked;
  }

  onChangePkgStartDate(event: any) {
    this.pkgChecked = event.target.checked;
    const pkg = this.trackingListService.getPkgStartDate();
    this.pkgStartDate = this.pkgChecked ? (pkg ?? this.setPkgDate()) : ''
  }

  setPkgDate(plantCode?:any) {
    if((plantCode || this.plant) === "50SER" || (plantCode || this.plant) === "51ACC"){
      return new Date(new Date().setMonth(new Date().getMonth() - 18));
    }
    else{
      return new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    }
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 17])

  }

  plantCode() {
    let plantCode: any[] = [];
    if (this.plant === '*') {
      plantCode = this.plants
        .filter((item) => {
          return item.code !== '*';
        })
        .map((val) => val.code);
    } else {
      plantCode.push(this.plant);
    }
    return plantCode;
  }

  transformGridData(gridData: any) {
    gridData.forEach((row: any, index: any) => {
      row.index = index;
      row.saveAccess = this.addAccess;
      row.leadname = (row.leaderflag === "1") ? 'Leader' : (row.leadname) ? this.changeNullData(row.leadname) : ' - ';
      row.plantname = (row.plantname) ? this.changeNullData(row.plantname) : '-';
      row.familycode = (row.familycode) ? this.changeNullData(row.familycode) : '-';
      row.modelcode = (row.modelcode) ? this.changeNullData(row.modelcode) : '-';
      row.suppliername = (row.suppliername) ? this.changeNullData(row.suppliername) : '-';
      row.fabricator = row.fabricator ? this.changeNullData(row.fabricator) : '-';
      row.dfabricator = row.dfabricator ? this.changeNullData(row.dfabricator) : '-';
      row.partnumber = (row.partnumber) ? this.changeNullData(row.partnumber) : '-';
      row.specialist = (row.specialist) ? this.changeNullData(row.specialist) : '-';
      row.step01date = this.createHtmlStepCell(row, 'step01');
      const plantCode = this.constants.getTypeFromPlantCode(row.plantcode)
      row.step03date = ((plantCode === 'SERVICE' || plantCode === 'ASSY') && row.step02status === '4') ? 'skip' : this.createHtmlStepCell(row, 'step03');
      row.step03due = (row.step03due) ? row.step03due : '-';
      row.step06date = ((plantCode === 'SERVICE' || plantCode === 'ASSY') && row.step02status === '4') ? 'skip' : this.createHtmlStepCell(row, 'step06');
      row.step06due = (row.step06due) ? row.step06due : '-';
      row.step08date = this.createHtmlStepCell(row, 'step08');
      row.step09due = (row.step09due) ? row.step09due : '-';
      row.step11date = ((plantCode === 'SERVICE' || plantCode === 'ASSY') && row.step09status === '4') ? 'skip' : this.createHtmlStepCell(row, 'step11');
      row.step12date = ((plantCode === 'SERVICE' || plantCode === 'ASSY') && row.step09status === '4') ? 'skip' : this.createHtmlStepCell(row, 'step12');
      row.userInfo = this.userInfo;
      row.dispQ = true
    });
  }

  changeNullData(data: any) {
    if (data === null || data?.length === 0 || data === undefined) {
      return " - ";
    } else {
      return data;
    }
  }


  onloadPayload() {
    let updatedPart = this.partNumber;

    if (this.partNumber.includes('-')) {
      updatedPart = (updatedPart + "").replace('-', '');
    } else if (this.partNumber.includes(' ')) {
      updatedPart = (updatedPart + "").replace(' ', '');
    }
    const plant = this.filteredOptions.filter((item: any) => ((item.plant.toLowerCase() === this.plant.toLowerCase()) || (item.plantcode.toLowerCase() === this.plant.toLowerCase())))
    this.plant = plant[0]?.plantcode;
    if(!this.sortStart){
      this.sortName = [];
      this.sortLogic = '';
    }
    const payload = {
      PlantCodes: this.plant === '*' ? 'ALL' : [this.plant], 
      family: this.familyCode,
      model: this.modelCode ? this.modelCode : "",
      partnumber1: updatedPart,
      partnumber2: '', // design changed
      specialist: this.specialistCode,
      supplier: this.supplier,
      fabricator: this.fabricator,
      dfabricator: this.dfabricator, // no property
      delay: this.delay,
      warn: this.warn,
      good: this.good,
      step1: this.stepList[0].checked ? 1 : 0,
      step2: this.stepList[1].checked ? 1 : 0,
      step3: this.stepList[2].checked ? 1 : 0,
      step4: this.stepList[3].checked ? 1 : 0,
      step5: this.stepList[4].checked ? 1 : 0,
      step6: this.stepList[5].checked ? 1 : 0,
      step7: this.stepList[6].checked ? 1 : 0,
      step8: this.stepList[7].checked ? 1 : 0,
      step9: this.stepList[8].checked ? 1 : 0,
      step10: this.stepList[9].checked ? 1 : 0,
      step11: this.stepList[10].checked ? 1 : 0,
      step12: this.stepList[11].checked ? 1 : 0,
      under_dev: this.inDevelopment ? 1 : 0,
      complete: this.complete ? 1 : 0,
      valid: this.radioVal,
      back_nodefine: this.notDefined ? 1 : 0,
      back_define: this.needApproval ? 1 : 0,
      back_appdefine: this.backApproval ? 1 : 0,
      ppf_needapp: this.needApproval1 ? 1 : 0,
      ppf_noaction: this.noActionRequired ? 1 : 0,
      sortname: this.sortName,
      sortlogic: this.sortLogic,
      step03due: 1, // expand btn1
      step06due: 1, // expand btn2
      step09due: 1, // expand btn3
      stepSOP: 1, // expand btn4
      WARN_BEFORE_DAYS: 5, // default
      supplierType: this.userInfo?.role?.supType,
      page: this.cPage,
      limit: this.rpPage,
      pkgstartdate: this.pkgStartDate ? formatDate(this.pkgStartDate, this.dateFormat, this.format, this.timeStd) : ''
    }
    this.trackingListService.settrackingFilters(payload)
    return payload
  }

  onLoad() {
    this.spinner.start();
    const plantCode = this.constants.getTypeFromPlantCode(this.plant)
    if (plantCode === 'SERVICE' || plantCode === 'ASSY') {
      this.hideBUCL = true;
      this.showActive = false;
      this.columnDefContents()
    }
    else {
      this.hideBUCL = false
      this.showActive = true;
      this.columnDefContents()
    }
    this.errorMessage = "";
    if (this.isAll && this.searchFlag) {
      this.rpPage = 50;
      this.isAll = false;
    }
    this.cPage = this.isSearch ? 1 : this.cPage;
    const payload = this.onloadPayload();
    this.gridApi?.showLoadingOverlay();
    this.trackingListService.loadTrackingListData(payload).subscribe({
      next: (trackingList: any) => {
          this.loadResponse = trackingList.pagination.TotalRecords;
          const gridData = trackingList.data;
          this.transformGridData(gridData);
          this.rowCount = gridData.length;
          if (!this.flag) {
            this.totalRecords = trackingList.pagination.TotalRecords;
            this.tPage = trackingList.pagination.TotalPages;
          }
          this.displayRowData = gridData;
          this.gridApi?.hideOverlay();
          this.isSearch = false;
        this.spinner.stop();
      },
      error: () => {
        this.spinner.stop();
      }
    });
  }

  nullInt(obj: any) {
    if (obj == null) {
      return 0;
    }
    else {
      return parseInt(obj)
    }
  }

  createHtmlStepCell(row: any, step: string) {
    let returnVal = "";
    const status = row[step + 'status'];
    const working = row.working;
    if (status === "1") {
      returnVal = "not open";
      if ((step !== "step06") && (step !== "step11") && (step !== "step12")) {
        if (working === "6") {
          returnVal = "not open Supplier";
        } else if (working === "2") {
          returnVal = "not open Container Fabricator";
        } else if (working === "8") {
          returnVal = "not open Dunnage Fabricator";
        }
      }
    } else if (status === "2") {
      returnVal = "working";
      if ((step !== "step06") && (step !== "step11") && (step !== "step12")) {
        if (working === "6") {
          returnVal = this.workingSupplier;
        } else if (working === "2") {
          returnVal = "working Container Fabricator";
        } else if (working === "8") {
          returnVal = "working Dunnage Fabricator";
        }
      }
    } else if (status === "4") {
      if (step === "step11") {
        row.forecast = (row?.forecast) ? formatDate(row?.forecast, this.dateFormat, this.format, this.timeStd) : '-';
        returnVal = row?.forecast;
      } else {
        returnVal = row[step + 'date'];
      }
    } else if (status === "5") {
      returnVal = "skip";
    } else if (status === "6" || status === "7") {
      returnVal = "working";
      if ((step !== "step06") && (row.step !== "step11") && (step !== "step12")) {
        if (working === "6") {
          returnVal = this.workingSupplier;
        } else if (working === "2") {
          returnVal = "working Container Fabricator";
        } else if (working === "8") {
          returnVal = "working Dunnage Fabricator";
        }
      }
    } else if (status === "8" || status === "12") {
      returnVal = "not open";
      if ((step !== "step06") && (step !== "step11") && (step !== "step12")) {
        returnVal = "not open Supplier";
      }
    } else if (status === "9" || status === "10" || status === "11") {
      returnVal = "confirm";
      if ((step !== "step06") && (step !== "step11") && (step !== "step12")) {
        returnVal = "confirm Supplier";
      }
    } else if (status === "13") {
      returnVal = "working";
      if ((step !== "step06") && (step !== "step11") && (step !== "step12")) {
        returnVal = this.workingSupplier;
      }
    } else {
      returnVal = "-";
    }
    return returnVal;
  }

  changePartNumber(partNumber: any) {
    let returnVal = "";
    if (partNumber && partNumber.length > 0) {
      returnVal = partNumber.substring(0, 10);
    } else {
      returnVal = '-'
    }

    return returnVal
  }
  columnDefContents() {
    this.columnDefs = [
      {
        headerName: 'LEAD',
        field: 'leadname',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellRendererParams: {
          clicked: (col: string, data: any) => {
            this.leaseLeader(col, data);
          },
        },
        comparator: () => 0
      },
      {
        headerName: 'PLANT',
        field: 'plantname',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: () => 0
      },
      {
        headerName: 'FAMILY CODE',
        field: 'familycode',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: () => 0
      },
      {
        headerName: 'MODEL CODE',
        field: 'modelcode',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: () => 0
      },
      {
        headerName: 'SUPPLIER',
        field: 'suppliername',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any) => {
            this.openSupplierInfo(colId, data);
          }
        },
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: () => 0
      },
      {
        headerName: 'C.FAB',
        field: 'fabricator',
        minWidth: 70,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, fab: any) => {
            this.openDialog(colId, data, fab);
          },
        },
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: () => 0
      },
      {
        headerName: 'D.FAB',
        field: 'dfabricator',
        minWidth: 70,
        pinned: 'left',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RouterLinkCellRenderer,
        cellRendererParams: {
          clicked: (colId: string, data: any, fab: any) => {
            this.openDialog(colId, data, fab);
          },
        },
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: () => 0
      },
      {
        headerName: 'PART NUMBER',
        field: 'partnumber',
        minWidth: 90,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: () => 0
      },
      {
        headerName: 'SPECIALIST',
        field: 'specialist',
        minWidth: 120,
        pinned: 'left',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellRendererParams: {
          clicked: (_colId: string, data: any) => {
            this.openSpecialistDialog(data);
          }
        },
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: () => 0
      },
      {
        headerName: 'PART DESCRIPTION',
        field: 'partdescription',
        minWidth: 250,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: '1. INITIAL PART LIST',
        field: 'step01date',
        minWidth: 90,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: '2. SEND PPF TO SUPPLIER',
        field: 'step02date',
        minWidth: 120,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: '3. TOYOTA RECEIVES PRE PPF',
        field: 'step03date',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: 'PRE PPF DUE',
        field: 'step03due',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        cellRendererParams: {
          clicked: () => {
            this.onLoad();
          }
        },
        comparator: dateComparator
      },
      {
        headerName: '4. TOYOTA APPROVES PRE PPF',
        field: 'step04date',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: '5. TRIAL ORDER',
        field: 'step05date',
        minWidth: 90,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: '6. SHIP TO TOYOTA',
        field: 'step06date',
        minWidth: 90,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: 'PACKAGE TRIAL DUE',
        field: 'step06due',
        minWidth: 90,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: dateComparator
      },
      {
        headerName: '7. PACKAGE TRIAL APPROVED',
        field: 'step07date',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: '8. TOYOTA RECEIVES FINAL PPF',
        field: 'step08date',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: '9. FINAL PPF APPROVAL',
        field: 'step09date',
        minWidth: 90,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: 'PACKAGE AUTHORIZED DUE',
        field: 'step09due',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: dateComparator
      },
      {
        headerName: '10. PACKAGE AUTHORIZED',
        field: 'step10date',
        minWidth: 130,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: PpfBtnComponent,
        comparator: dateComparator
      },
      {
        headerName: '11. FORECAST READY DATE',
        field: 'step11date',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: '12. SUPPLIER READINESS',
        field: 'step12date',
        minWidth: 120,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        comparator: dateComparator
      },
      {
        headerName: 'READINESS DUE',
        field: 'step12due',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: dateComparator
      },
      {
        headerName: 'PACKAGE START DATE',
        field: 'pkgstartdate',
        minWidth: 140,
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: RouterLinkCellRenderer,
        cellStyle: { cursor: 'pointer', color: '#2688DA' },
        comparator: dateComparator
      },
      {
        headerName: 'Is Active', field: 'isActive', floatingFilterComponentParams: { suppressFilterButton: false },
        floatingFilter: false, minWidth: 80, maxWidth: 100, hide: this.showActive,
        cellRendererFramework: TrackingListCheckBoxRendered,
        cellRendererParams: {
          radioGroup: this.radioGroup,
          showActive: this.showActive,
          clicked: (data: any) => {
            this.updateDecision(data);
          },
        },
      },
      {
        headerName: 'ACTION',
        field: 'action',
        minWidth: 280,
        cellRendererFramework: ActionBtnComponent,
        cellRendererParams: {
          hideBUCL: this.hideBUCL,
          clicked: (_colId: string, data: any) => {
            this.confirm(data, 'DELETE TRACKING LIST');
          }
        },
        sortable: false
      }
    ];
  }

  updateDecision(_data: any) {
    if (this.activeChangesArray.includes(_data)) {
      const i = this.activeChangesArray.indexOf(_data);
      this.activeChangesArray.splice(i, 1)
    }
    else {
      this.activeChangesArray.push(_data)
    }
  }

  apiCall() {
    this.rowCount = this.displayRowData.length;
    this.gridApi?.redrawRows();
  }

  setText(selector: string, text: any) {
    if (this.rowCount > 0) {
      const element = document.querySelector(selector);
      if (element !== null) {
        (element as HTMLElement).innerHTML = text;
      }
    }
  }
  setLastButtonDisabled() {
    this.isLastPageFound = true;
  }

  onSearch() {
    this.displayRowData = [];
    this.totalRecords = 0;
    this.isSearch = true
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    if ((plantCheck?.length === 0) || (!plantCheck)) {
      this.errorMessage = ["Please select valid plant name"];
    } else if (this.plant === '*' && !(this.supplier || this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.dfabricator)) {
      this.errorMessage = 'For `ALL` plant , Please select another field as well to search'
      return
    } else {
      this.onLoad();
    }
    this.activeChangesArray = []
    const plantCode = this.constants.getTypeFromPlantCode(this.plant)
    if (plantCode === 'SERVICE' || plantCode === 'ASSY') {
      this.hideBUCL = true;
      this.showActive = false;
      this.columnDefContents()
    }
    else {
      this.hideBUCL = false
      this.showActive = true;
      this.columnDefContents()
    }
    const allFilterModels1 = this.gridApi?.getFilterModel();
    const isFilterCleared1 = Object.keys(allFilterModels1).length === 0;
    if (!isFilterCleared1) {
      this.onFilterChanged("");
    }
  }

  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specialistCode
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialistCode = result;
    })
  }

  openLegends() {
    this.dialog.open(LegendsComponent, {
      data: {
        data: 'tracking-list'
      },
    });
  }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;

    this.spinner.start();
    if (this.isAll && (this.plant || this.supplier || this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.dfabricator)) {
      this.spinner.start()
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 4000); i++) {
        this.cPage = i + 1;
        this.tPage = 1;
        this.rpPage = 4000;
        await this.onLoadAll();
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
      this.spinner.stop();
    } else if (this.plant === '*' && !(this.supplier || this.partNumber || this.specialistCode || this.modelCode || this.familyCode || this.fabricator || this.dfabricator)) {
      this.spinner.stop();
      this.errorMessage = 'For `ALL` plant , Please select another field as well to search';
      return

    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onLoad();
    }
    this.saveGridState();
  }

  async onLoadAll() {
    const payload = this.onloadPayload();
    const gridData: any[] = []

    const trackingList = await this.trackingListService.getDataSynchronous(payload)
    gridData.push(...trackingList.data)
    this.transformGridData(gridData);
    this.displayRowData.push(...gridData)
    this.gridApi.setRowData(this.displayRowData)
    if (this.isSearch) {
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.isSearch = false;
    }
    if(this.cPage === Math.ceil(this.totalRecords / 4000)){
      this.spinner.stop();
    }
  }
  
  filterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.trackingListService.setTrackingGridfilter(allFilterModels);
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  sortChanged(event:any){
    this.saveGridState();

    const sortedColumn = event.columnApi.getColumnState().find((col: any) => Boolean(col.sort));
    if(sortedColumn){
      if(sortedColumn?.pinned === 'left'){
        this.sortStart = true;
        this.searchFlag = false;
        if(!this.sortName.includes(sortedColumn?.colId)){
          this.sortName.push(sortedColumn?.colId);
        }
        this.sortLogic = sortedColumn?.sort;
        this.onLoad();  
      }
    }
    else{
      this.sortStart = false;
      this.onLoad();  
    }
  }

  saveGridState(){
    this.trackingListService.trackingGridSate.sort = this.gridColumnApi?.getColumnState();
    this.trackingListService.trackingGridSate.filter = this.gridApi?.getFilterModel();
    this.trackingListService.trackingGridSate.currentpage = this.cPage;
    this.trackingListService.trackingGridSate.totalPage = this.gridApi?.paginationGetTotalPages();
    this.trackingListService.trackingGridSate.rowcount = this.gridApi?.paginationGetRowCount();
    this.trackingListService.trackingGridSate.pagesize = this.rpPage;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.gridApi.addEventListener('firstDataRendered', () => {
        if (this.trackingListService.trackingGridSate.filter) {
          const retrievedValue = this.trackingListService.trackingGridSate.filter;
          this.gridApi?.setFilterModel(retrievedValue);
          this.gridApi?.onFilterChanged();
        }
        if (this.trackingListService.trackingGridSate.sort) {
          const retrievedValue = this.trackingListService.trackingGridSate.sort;
          this.gridColumnApi?.applyColumnState({
            state: retrievedValue,
            applyOrder: true
          });
        }
      })
    });
  }

  onFilterChanged(_params: any) {
    const allFilterModels1 = this.gridApi?.getFilterModel();
    this.trackingListService.setTrackingGridfilter(allFilterModels1);
    const isFilterCleared1 = Object.keys(allFilterModels1).length === 0;
    const data = this.trackingListService.getPaginationValues();
    if (!isFilterCleared1 && data !== undefined) {
      this.cPage = data.cPage;
      this.totalRecords = this.gridApi?.getDisplayedRowCount();
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
      this.flag = true;
    }
    else {
      if (this.originalPage === undefined) {
        this.originalPage = this.cPage;
      }
      this.cPage = 1;
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
      const obj = {
        cPage: this.cPage,
        tPgae: this.tPage,
        totalRecords: this.totalRecords
      }
      this.trackingListService.setPaginationValues(obj);
      const allFilterModels = this.gridApi?.getFilterModel();
      this.trackingListService.setTrackingGridfilter(allFilterModels);
      const isFilterCleared = Object.keys(allFilterModels).length === 0;
      if (isFilterCleared) {
        this.cPage = this.originalPage;
        this.originalPage = undefined;
        this.totalRecords = this.loadResponse;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.flag = false;
        this.trackingListService.clearPaginationValues()
      }
    }
    this.saveGridState();
  }

  openSupplierInfo(_id: string, data: any) {
    const params = {
      plant: data.plantname,
      partNumber: data.partnumber,
      supplier: data.suppliername,
      fromDate: data?.pkgstartdate,
      plantC: data.plantcode,
      model: data.modelcode,
      pkgStartDate: data.pkgstartdate,
      supplierC: data.suppliercode
    }
    const dialogRef = this.dialog.open(SupplierInfoComponent, {
      data: params
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result?.event === 'UpdateEvent') {
        this.spinner.start();
        this.onLoad();
      }
    });
  }

  openDialog(pageName: string, data: any, fab: any) {
    const params = {
      pageName: pageName,
      data: data,
      fab: fab
    }
    const dialogRef = this.dialog.open(UpdateFabricatorComponent, {
      data: params,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result?.event === 'Fabricator CloseEvent') {
        this.spinner.start();
        this.onLoad();
      }
    });
  }

  openSpecialistDialog(data: any) {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: {
        isGrid: true,
        PlantCode: data?.plantcode,
        PartNumber: data?.partnumber,
        SupplierCode: data?.suppliercode,
        PkgStartDate: data?.pkgstartdate,
        specialist: data?.specialist,
        specialistcode: data?.specialistcode
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result?.event === 'Specialist CloseEvent') {
        this.spinner.start();
        this.onLoad();
      }
    });
  }

  confirm(data: any, action: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        action: action,
        data: data
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result?.data) {
        this.onLoad();
      }
    })
  }
  leaseLeader(col: any, data: any) {
    const dialogRef = this.dialog.open(SelectLeaderComponent, {
      data: {
        id: col.instanceId,
        model: data.modelcode,
        plant: data.plantname,
        part: data.partnumber,
        supplier: data.suppliername,
        psd: data?.pkgstartdate,
        plantC: data.plantcode,
        supplierC: data.suppliercode,
        lead: data.leadname
      }
    })
    dialogRef.afterClosed().subscribe((_result: any) => {
      if(_result && _result?.event === "CloseEvent"){
        this.onLoad();
      }
    })
  }

  nullStr(value: any) {
    if (value === undefined || value === 'undefined' || value === null) {
      return "";
    }
    else {
      return value.toString();
    }
  }


  onKeyDown(event: any, id: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (id === 'pkgStartDate') {
        this.pkgStartDate = '';
      }
    }
  }

}
