import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from './users.service';
import { ConstantsService } from './constant.service';
import { GlobalStateService } from './service/global-state.service';
import { Authoriy, UserRoles, Specialist, createEmptyUserRoles } from '../internal-vpacs/interface/LoginInterface';
@Injectable({
  providedIn: 'root',
})
export class ExportStateService {
  private readonly guidStateSource = new BehaviorSubject<string>('');
  guidState = this.guidStateSource.asObservable();
  private readonly roleStateSource = new BehaviorSubject<string>('');
  roleState = this.roleStateSource.asObservable();
  private readonly userInfoSource = new BehaviorSubject<string>('');
  userInfoState = this.userInfoSource.asObservable();
  unAuthorizeUrl = '/unauthorized';
  toyotaGUID!:any;
  constructor(
    private readonly userService: UsersService,
    private readonly router: Router,
    public constants: ConstantsService,
    public globalStateService: GlobalStateService
  ) { }

  getGUIDState() {
    return this.guidStateSource.getValue();
  }

  setGUIDState(name: string) {
    this.guidStateSource.next(name);
  }

  getroleState() {
    return this.roleStateSource.getValue();
  }
  setroleState(role: string) {
    this.roleStateSource.next(role);
  }

  getuserInfoState() {
    return this.userInfoSource.getValue();
  }

  setuserInfoState(value: any) {
    this.userInfoSource.next(value);
  }

  async getRoles() {
    const areRolesPresent = this.getroleState();
    if (!areRolesPresent) {
      if (!this.getGUIDState()){
        await new Promise(f => setTimeout(f, 2000));
      }
      await this.getUserInfo(localStorage.getItem('toyotaGUID')).then((x: any) => {
        if (!x) {
          this.router.navigate([this.unAuthorizeUrl])
        }
      })
    }
  }

  async getUserInfo(toyotaGUID: any) {
    let rolesArray: any = [];
    let valid = false;
    let type = '';
        if (localStorage.getItem('roles')) {
      rolesArray = JSON.parse(localStorage.getItem('roles') ?? '');
      if(sessionStorage.getItem('preferredRoles')){
        const arr1 = JSON.parse(sessionStorage.getItem('preferredRoles') || '{}').sort();
        const arr2 = rolesArray.sort();
        if(JSON.stringify(arr1) === JSON.stringify(arr2)){
          rolesArray = JSON.parse(sessionStorage.getItem('preferredRoles') || '{}');
        }
      }
    }
    this.setroleState(rolesArray);
    if (rolesArray && (this.constants.vpacsAll.some((ele: any) => rolesArray.includes(ele)))) {
      valid = true;
      this.globalStateService.setValidUser(true);
      //check whether the user has both app access
      type = this.checkAppType(rolesArray);
      if (type === "External") {
        await this.loginAsExternalUser(rolesArray, toyotaGUID, valid);
      }
      if (type === "Internal") {
        await this.loginAsInternalUser(toyotaGUID, valid);
      }
    } else {
      this.globalStateService.setValidUser(false);
    }
    return valid;
  }

  checkAppType(rolesArray:any[]){
    const possibleRoles = this.constants.allRoles;
    const internalRoles = this.constants.allInternal;
    let type = '';
    if ((possibleRoles.some((ele: any) => rolesArray.includes(ele)) && (internalRoles.some((ele: any) => rolesArray.includes(ele))))) {
      if (possibleRoles.some(role => rolesArray[0].includes(role))) {
        type = 'External';
      } else if (internalRoles.some(role => rolesArray[0].includes(role))) {
        type = 'Internal';
      }
    } else {
      if (possibleRoles.some((ele: any) => rolesArray.includes(ele))) {
        type = 'External';
      } else if (internalRoles.some((ele: any) => rolesArray.includes(ele))) {
        type = "Internal";
      }
    }
    return type;
  }

  async loginAsExternalUser(rolesArray: any, _toyotaGUID: any, valid: boolean) {
    valid && this.userService.getUserInfoFreeze(JSON.parse(_toyotaGUID)).subscribe({
      next: (result: any) => {
        if(result){
          valid = true;
          let supplierCodeArray: Array<string> = [];
          if (result?.readonlyaccess === true) {
            window.alert('New users to VPACS must obtain Write and/or Submit access to VPACS from Supplier System Administrator')
          }
          this.setuserInfoState(result);
          sessionStorage.removeItem('authSend');
          if (!(this.constants.adminSystemRoles.some((ele: any) => rolesArray.includes(ele)))) {
            //to set auth value for supplier side
            this.globalStateService.setSecurityInfo(result.secruityInfo);
            if (Array.isArray(result.secruityInfo) && result.secruityInfo[0]?.AUTHSEND && result.secruityInfo[0]?.AUTHSEND === "0") {
              sessionStorage.setItem('authSend', '0');
            } else {
              sessionStorage.setItem('authSend', '1');
            }
            if (result.supplierCode && result.supplierCode.length > 0) {
              supplierCodeArray = this.setSupplierCode(result.supplierCode);
            }
            localStorage.setItem('tracking', JSON.stringify(supplierCodeArray));
          }
        }
      },
      error: (_error) => {
        valid = false;
        this.router.navigate([this.unAuthorizeUrl])
      },
    });

  }

  async loginAsInternalUser(toyotaGUID: any, valid: boolean) {
    valid && this.userService.getInternalUserInfo(JSON.parse(toyotaGUID)).subscribe({
      next: async (res: any) => {
        const userData: Specialist = res.specialist;
        userData['p_specialistcode'] = userData.specialistcode;
        userData['p_plantcode'] = userData.plantcode;
        sessionStorage.setItem(
          'specialistCode',
          JSON.stringify(userData.specialistcode)
        )
        sessionStorage.setItem('plantCode', JSON.stringify(userData.plantcode))
        valid = true;
        let roles:any;
        let authority: Authoriy[];
        const defaultAuth: UserRoles = createEmptyUserRoles();
        let filteredCodes:any;
        if(res.specialistRole.length > 0){
          roles = res.specialistRole[0].role.map((x:any) => x.name);
          authority = res.specialistRole[0].authoriy;
          filteredCodes = authority.map((x:any) => x.authoritycode);
          defaultAuth.p_trackingview = filteredCodes.includes('1');
          defaultAuth.p_trackingsave = filteredCodes.includes('2');
          defaultAuth.p_trackingdelete = filteredCodes.includes('3');
          defaultAuth.p_trackingcorrect = filteredCodes.includes('4');
          defaultAuth.p_costview = filteredCodes.includes('5');
          defaultAuth.p_costsave = filteredCodes.includes('6');
          defaultAuth.p_costdelete = filteredCodes.includes('7');
          defaultAuth.p_qtyview = filteredCodes.includes('8');
          defaultAuth.p_qtyauth = filteredCodes.includes('9');
          defaultAuth.p_userview = filteredCodes.includes('10');
          defaultAuth.p_usersave = filteredCodes.includes('11');
          defaultAuth.p_userdelete = filteredCodes.includes('12');
          defaultAuth.p_pkgview = filteredCodes.includes('13');
          defaultAuth.p_pkgsave = filteredCodes.includes('14');
          defaultAuth.p_pkgmodify = filteredCodes.includes('15');
          defaultAuth.p_pkgdelete = filteredCodes.includes('16');
          defaultAuth.p_plantList = res.specialistRole[0].roleplant;
          defaultAuth.p_plantTrackingList = res.specialistRole[0]?.roleplanttracking;
          defaultAuth.supType = res?.supplierType[0]?.suppliercodetype;
          defaultAuth.monetaryUnit = res?.supplierType[0]?.suppliercodetype;
          defaultAuth.backupDetails = res?.backupDetails
          defaultAuth.u_roles = roles;
          defaultAuth.hazmatPlants = res?.hazmatPlants;
        }
        this.setuserInfoState({ info: userData, role : defaultAuth });
      },
      error: (_err: any) => {
        valid = false;
        this.router.navigate([this.unAuthorizeUrl])
      },
    })
  }

  setSupplierCode(supArray:any){
    const supplierCodeArray:any [] = [];
    let distinctCodes:any [] = [];
    if (supArray.length > 0) {
      supArray.map((res: any) => {
        if (res.SUPPLIERNUMBER && res?.SUPPLIERNUMBER.substring(0, 2) === '00') {
          supplierCodeArray.push(res.SUPPLIERNUMBER.substring(2, 6));
        }else if(res.SUPPLIERNUMBER && res.SUPPLIERNUMBER.startsWith('i') && res.SUPPLIERNUMBER.length === 11){
          supplierCodeArray.push(res.SUPPLIERNUMBER.substring(6, 11));
        }
      });
      distinctCodes = [...new Set(supplierCodeArray)];
    }
    return distinctCodes;
  }

  swapRoles(inputArray:any,orderArray:any){
    const order = orderArray;  
    const input = inputArray;
    const sortedArray = input.sort((a:any,b:any) =>{
      const aI = order.indexOf(a);
      const bI = order.indexOf(b);
      if(aI === -1){
        return 1;
      } 
      if(bI === -1){
        return -1;
      } 
      return aI - bI;
    })
    localStorage.setItem('roles',JSON.stringify(sortedArray));
    return sortedArray;
  }

  checkExternalAccess(currentRole:any){
    let adminFlag = false;
    if(this.constants.adminSystemRoles.includes(currentRole)){
      adminFlag = true;
    }else{
      if(this.constants.allExceptSystemAdminRoles.includes(currentRole)){
        adminFlag = false;
      }
    }
    return adminFlag;
  }
}
