import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierPlantService {
  URL = this.constantsService.MASTERCOST;
  URL2 = this.constantsService.INTERNAL;

  constructor(
    private readonly http: HttpClient,
    public constantsService: ConstantsService
  ) {}

  getPlantList(body: any): Observable<any> {
    return this.http
      .post(this.URL2 + 'common/getPlant', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  getSupplierPlantInfo(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/getSupplierPlantInfo', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  subOnLoad(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/subOnLoad', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  getCalculation(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/getCalculation', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  getPlantSpecialistDropdown(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/getPlantSpecialistDropdown', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  updateSupplierPlant(body: any): Observable<any> {
    return this.http
      .put(this.URL + 'master/updateSupplierPlant', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  updateCalculation(body: any): Observable<any> {
    return this.http
      .put(this.URL + 'master/updateCalculation', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  downloadSupplierPlant(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/downloadSupplierPlant', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  uploadSupplierPlant(body: any): Observable<any> {
    return this.http
      .post(this.URL + 'master/uploadData', body, {
        headers: this.constantsService.internalHttpOptions,
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error || 'Server Error');
  }
}
